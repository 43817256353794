import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';
import { CSSProperties } from 'react';

interface Props {
  headerStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  footerStyle?: CSSProperties;
  contentStyle?: CSSProperties;
}

export default styled(Modal)<ModalProps & Props>`
  border-radius: 8px;
  margin: 10px;
  ${(props) => props.contentStyle as string};
  .ant-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    ${(props) => props.headerStyle as string};
    .ant-modal-title {
      font-size: 18px;
      color: #424242;
      font-weight: 400;
    }
  }
  .ant-modal-body {
    padding: 20px;
    ${(props) => props.bodyStyle as string};
  }
  .ant-modal-footer {
    display: flex;
    justify-content: center;
    border-top: none;
    border-radius: 0 0 8px 8px;
    padding-top: 12px;
    padding-bottom: 20px;
    ${(props) => props.footerStyle as string};
  }
`;

export const ButtonStyled = styled.button`
  cursor: pointer;
  &.button-primary {
    background: #f6ac00;
    box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
    border-radius: 5px;
    border: none;
    padding: 9px 60px;
    margin: 0 5px;
    .button-label {
      color: #ffffff;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }
  }
  &.button-other {
    background: #ffffff;
    box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
    border-radius: 5px;
    border: 1px solid #f6ac00;
    padding: 9px 60px;
    margin: 0 5px;
    .button-label {
      color: #f6ac00;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }
  }
  &.disabled {
    background-color: #acacac;
    cursor: not-allowed;
    border: none;
    .button-label {
      color: #ffffff;
    }
  }
  &.button-secondary {
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    margin: 0 5px;
    padding: 9px 35px;
    .button-label {
      color: #777777;
      font-size: 13px;
      text-align: center;
      font-weight: 400;
    }
  }
`;
