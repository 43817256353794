import React, { useEffect, useState } from 'react';
import { Input } from 'antd';

import { Modal } from 'components';

interface Props {
  visible: boolean;
  defaultValue: string;
  onCancel: () => void | Promise<void>;
  onSubmit: (value: string) => void | Promise<void>;
}

const EnlargeInput: React.FC<Props> = ({ visible, onCancel, onSubmit, defaultValue }) => {
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    if (visible) {
      setContent(defaultValue);
    }
  }, [visible, defaultValue]);

  return (
    <Modal
      width={860}
      title="解説"
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: () => onSubmit(content),
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: onCancel,
      }}
      onCancel={onCancel}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: '40px 40px 25px' }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <Input.TextArea
        showCount
        rows={25}
        value={content}
        maxLength={1050}
        placeholder="全角：最大1050文字"
        onChange={(e) => setContent(e.target.value)}
      />
    </Modal>
  );
};

export default EnlargeInput;
