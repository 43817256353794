import { Input, InputProps } from 'formik-antd';
import styled from 'styled-components';

export default styled(Input)<InputProps>`
  font-size: 14px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px;
`;
