import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import fileSaver from 'file-saver';
import { some } from 'lodash';
import dayjs from 'dayjs';
import {
  CaretLeftOutlined,
  PaperClipOutlined,
  MessageOutlined,
  DeleteOutlined,
  CheckOutlined,
  EditOutlined,
  LikeOutlined,
  StarFilled,
  ExclamationOutlined,
  CaretRightFilled,
  TeamOutlined,
  LikeFilled,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';

import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import CompletedDelete from 'components/Modal/CompletedDelete';
import CreateQuestionAnswer from './../createQuestionAnswer';
import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { createMarkup } from 'libs/utils/createMarkup';
import { knowledgeTopSelector } from '../../selectors';
import { extractFileName } from 'libs/utils/format';
import useImagePreview from 'hooks/useImagePreview';
import CreateQAAnswer from './../createQAAnswer';
import { routes } from 'navigations/routes';
import { resetData } from '../../slice';
import ExpandingStyled from './styles';
import { adminAvatar } from 'assets';
import { Header } from 'components';
import * as Types from 'types';
import {
  createKnowledgeQAAnswers,
  updateKnowledge,
  deleteKnowledgeQAAnswers,
  createKnowledgeQAAddQuestion,
  updateKnowledgeQAAddQuestion,
  deleteKnowledgeQAAddQuestion,
  createKnowledgeQAAddQuestionAnswer,
  updateKnowledgeQAAddQuestionAnswer,
  deleteKnowledgeQAAddQuestionAnswer,
  deleteKnowledge,
  getKnowledgeLikeHistory,
  createKnowLedgeLikeHistory,
  deleteKnowLedgeLikeHistory,
  getKnowledgeFavoriteHistory,
  createFavoriteKnowledge,
  deleteFavoriteKnowledge,
  updateKnowledgeQAAnswers,
  getSelectDepartmentUser,
  getKnowledgeQADetailOriginal,
  getKnowledgeQADetailAdditional,
  deleteFileAttachByConditions,
  createFileAttach,
} from '../../thunk';

const ExpandingPDF: React.FC = () => {
  const { handlePreviewImage, ImagePreview } = useImagePreview();

  const [openModalDeleteKnowledge, setOpenModalDeleteKnowledge] = useState<boolean>(false);
  const [openModalDeleteComplete, setOpenModalDeleteComplete] = useState<boolean>(false);
  const [visibleCreateQuestion, setVisibleCreateQuestion] = useState<boolean>(false);
  const [visibleEditQAAnswer, setVisibleEditQAAnswer] = useState<boolean>(false);
  const [actionType, setActionType] = useState<'question' | 'answer'>('question');
  const [openUpdateKnowledge, setOpenUpdateKnowledge] = useState<boolean>(false);
  const [visibleEditQuestion, setVisibleEditQuestion] = useState<boolean>(false);
  const [visibleEditAnswer, setVisibleEditAnswer] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [textQAAnswer, setTextQAAnswer] = useState<string>('');
  const [completed, setCompleted] = useState<boolean>(false);
  const [idSelected, setIDSelected] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [text, setText] = useState<string>('');
  const [file, setFile] = useState<UploadFile<File> | undefined>();
  const [openModalDeleteKnowledgeComplete, setOpenModalDeleteKnowledgeComplete] =
    useState<boolean>(false);

  const { permissionNumber } = usePermission();

  const dataRecord = useLocation().state as Types.KnowledgeList.ResponseType;

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    dataKnowledgeLikeHistory,
    dataKnowledgeFavoriteHistory,
    dataSelectDepartmentUser,
    dataKnowledgeQADetailOriginal,
    dataKnowledgeQADetailAdditional,
  } = useSelector(knowledgeTopSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      text: '',
      input_name: '',
    },
    onSubmit: (values) => {},
  });

  const handleCreateQAAnswer = async () => {
    dispatch(startLoading());
    const resultAction = await Promise.all([
      dispatch(
        createKnowledgeQAAnswers({
          item: {
            company_id: userInfo?.company_id,
            knowledge_id: dataRecord.knowledge_id,
            respondent: userInfo?.login_id,
            answer_content: textQAAnswer,
            createdat: new Date(),
          },
        })
      ),
      dispatch(
        updateKnowledge({
          id: dataKnowledgeQADetailOriginal[0]!.item_ref!.knowledge_id.i_id,
          data: {
            item: {
              qa_status: 1,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      ),
    ]);
    if (createKnowledgeQAAnswers.fulfilled.match(resultAction[0])) {
      setOpenUpdateKnowledge(false);
      fetchDataKnowledgeQADetailOriginal();
    }
    dispatch(stopLoading());
  };

  const handleUpdateQAAnswer = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateKnowledgeQAAnswers({
        id: dataKnowledgeQADetailOriginal[0]!.item_ref!.answer_content.i_id,
        data: {
          item: {
            company_id: userInfo?.company_id,
            knowledge_id: dataKnowledgeQADetailOriginal[0].knowledge_id,
            respondent: dataKnowledgeQADetailOriginal[0].respondent,
            answer_content: textQAAnswer,
          },
          is_force_update: true,
        },
      })
    );

    if (updateKnowledgeQAAnswers.fulfilled.match(resultAction)) {
      fetchDataKnowledgeQADetailOriginal();
      setVisibleEditQAAnswer(false);
    }
    dispatch(stopLoading());
  };

  const handleDeleteKnowledge = async () => {
    if (!dataKnowledgeQADetailOriginal[0].item_ref?.knowledge_id.i_id) return;
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteKnowledge({
        id: dataKnowledgeQADetailOriginal[0].item_ref.knowledge_id.i_id,
      })
    );
    if (dataKnowledgeQADetailOriginal[0].files) {
      dataKnowledgeQADetailOriginal[0].files.map((item) => {
        deletedFileInMinIO(item.uid);
        dispatch(
          deleteFileAttachByConditions({
            conditions: [
              {
                id: 'fileID',
                search_value: [item.uid],
              },
            ],
            use_display_id: true,
          })
        );
      });
    }
    if (dataKnowledgeQADetailOriginal[0].answer_file) {
      deletedFileInMinIO(dataKnowledgeQADetailOriginal[0].answer_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [dataKnowledgeQADetailOriginal[0].answer_file.uid],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteKnowledge.fulfilled.match(resultAction)) {
      fetchDataKnowledgeQADetailOriginal();
      setOpenModalDeleteKnowledgeComplete(true);
    }
    dispatch(stopLoading());
  };

  const handleDeleteAnswer = async () => {
    if (!dataKnowledgeQADetailOriginal[0].item_ref?.answer_content.i_id) return;
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteKnowledgeQAAnswers({
        id: dataKnowledgeQADetailOriginal[0].item_ref.answer_content.i_id,
      })
    );
    if (dataKnowledgeQADetailOriginal[0].answer_file) {
      deletedFileInMinIO(dataKnowledgeQADetailOriginal[0].answer_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [dataKnowledgeQADetailOriginal[0].answer_file.uid],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteKnowledgeQAAnswers.fulfilled.match(resultAction)) {
      const resultActionUpdate = await dispatch(
        updateKnowledge({
          id: dataKnowledgeQADetailOriginal[0]!.item_ref!.knowledge_id.i_id,
          data: {
            item: {
              qa_status: 0,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateKnowledge.fulfilled.match(resultActionUpdate)) {
        fetchDataKnowledgeQADetailOriginal();
        setOpenModalDeleteComplete(true);
        setTextQAAnswer('');
      }
    }
    dispatch(stopLoading());
  };

  const handleCreateQuestion = async () => {
    if (!userInfo || !dataRecord.knowledge_id) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());
    const resultAction = await dispatch(
      createKnowledgeQAAddQuestion({
        item: {
          company_id: userInfo.company_id,
          knowledge_id: dataRecord?.knowledge_id,
          additional_questioner: userInfo.login_id,
          contents_of_question: text,
          answer_status: 0,
          createdat: new Date(),
          fileID,
        },
        realtime_auto_link: true,
      })
    );
    if (createKnowledgeQAAddQuestion.fulfilled.match(resultAction))
      fetchDataKnowledgeQADetailAdditional();
    dispatch(stopLoading());
  };

  const handleUpdateQuestion = async (id: string) => {
    if (!userInfo) return;
    const fileID = await uploadFile('5');
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateKnowledgeQAAddQuestion({
        id: id,
        data: {
          item: {
            contents_of_question: text,
            updatedat: new Date(),
            ...(fileID ? { fileID: fileID } : {}),
          },
          is_force_update: true,
        },
      })
    );
    if (updateKnowledgeQAAddQuestion.fulfilled.match(resultAction))
      fetchDataKnowledgeQADetailAdditional();
    dispatch(stopLoading());
  };

  const handleDeleteQuestion = async (id: string) => {
    if (!id) return;
    dispatch(startLoading());
    const actionResult = await dispatch(
      deleteKnowledgeQAAddQuestion({
        id: id,
      })
    );
    if (dataKnowledgeQADetailAdditional[0].additional_question_file) {
      deletedFileInMinIO(dataKnowledgeQADetailAdditional[0].additional_question_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [dataKnowledgeQADetailAdditional[0].additional_question_file.uid],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteKnowledgeQAAddQuestion.fulfilled.match(actionResult)) {
      setCompleted(true);
    }
    dispatch(stopLoading());
  };

  const uploadFile = async (fileLocation: string) => {
    let fileID = '';
    if (file) {
      const uploadToMinIo = await uploadFileToMinIO(file);
      await dispatch(
        createFileAttach({
          item: {
            fileID: uploadToMinIo,
            filename: file?.name,
            file_location: fileLocation,
            file_extension: file?.type,
            file_size: `${file?.size}`,
            company_id: userInfo?.company_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
        })
      );
      fileID = uploadToMinIo;
    }
    return fileID;
  };

  const handleCreateAddQuestionAnswer = async (additional_question_id: string) => {
    if (!userInfo || !dataRecord.knowledge_id) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());
    const resultAction = await dispatch(
      createKnowledgeQAAddQuestionAnswer({
        item: {
          company_id: userInfo.company_id,
          knowledge_id: dataRecord?.knowledge_id,
          additional_question_id: additional_question_id,
          additional_question_respondent: userInfo.login_id,
          additional_question_answer_content: text,
          createdat: new Date(),
          fileID,
        },
      })
    );
    if (
      createKnowledgeQAAddQuestionAnswer.fulfilled.match(resultAction) &&
      dataKnowledgeQADetailAdditional[index]?.item_ref?.question_answer_status.i_id
    ) {
      const resultAction2 = await dispatch(
        updateKnowledgeQAAddQuestion({
          id: dataKnowledgeQADetailAdditional[index].item_ref!.question_answer_status.i_id,
          data: {
            item: {
              answer_status: 1,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateKnowledgeQAAddQuestion.fulfilled.match(resultAction2)) {
        fetchDataKnowledgeQADetailAdditional();
      }
    }
    dispatch(stopLoading());
  };

  const handleUpdateQuestionAnswer = async (id: string) => {
    if (!userInfo) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateKnowledgeQAAddQuestionAnswer({
        id: id,
        data: {
          item: {
            additional_question_answer_content: text,
            updatedat: new Date(),
            fileID,
          },
          is_force_update: true,
        },
      })
    );
    if (updateKnowledgeQAAddQuestionAnswer.fulfilled.match(resultAction)) {
      fetchDataKnowledgeQADetailAdditional();
    }
    dispatch(stopLoading());
  };

  const handleDeleteQuestionAnswer = async (id: string) => {
    if (!id) return;
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteKnowledgeQAAddQuestionAnswer({
        id: id,
      })
    );
    if (dataKnowledgeQADetailAdditional[0].additional_question_answer_file) {
      deletedFileInMinIO(dataKnowledgeQADetailAdditional[0].additional_question_answer_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [
                dataKnowledgeQADetailAdditional[0].additional_question_answer_file.uid,
              ],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteKnowledgeQAAddQuestionAnswer.fulfilled.match(resultAction)) {
      const resultAction2 = await dispatch(
        updateKnowledgeQAAddQuestion({
          id: dataKnowledgeQADetailAdditional[index].item_ref!.question_answer_status.i_id,
          data: {
            item: {
              answer_status: 0,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateKnowledgeQAAddQuestion.fulfilled.match(resultAction2)) {
        setCompleted(true);
      }
    }
    dispatch(stopLoading());
  };

  const fetchDataKnowledgeQADetailOriginal = useCallback(async () => {
    if (userInfo && dataRecord.knowledge_id) {
      dispatch(startLoading());
      await dispatch(
        getKnowledgeQADetailOriginal({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    }
  }, [userInfo, dispatch, dataRecord.knowledge_id]);

  const fetchDataKnowledgeQADetailAdditional = useCallback(async () => {
    if (userInfo && dataRecord.knowledge_id) {
      dispatch(startLoading());
      await dispatch(
        getKnowledgeQADetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    }
  }, [userInfo, dispatch, dataRecord.knowledge_id]);

  const fetchKnowledgeLikeHistory = useCallback(() => {
    if (userInfo && dataRecord.knowledge_id) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getKnowledgeLikeHistory({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'knowledge_id',
                search_value: [dataRecord.knowledge_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, dataRecord.knowledge_id]);

  const fetchKnowledgeFavoriteHistory = useCallback(() => {
    if (userInfo && dataRecord.knowledge_id) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getKnowledgeFavoriteHistory({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'favorite_knowledge_id',
                search_value: [dataRecord.knowledge_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, dataRecord.knowledge_id]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;

    dispatch(
      getSelectDepartmentUser({
        conditions: [
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(fetchKnowledgeLikeHistory, [fetchKnowledgeLikeHistory]);

  useEffect(fetchKnowledgeFavoriteHistory, [fetchKnowledgeFavoriteHistory]);

  useEffect(() => {
    (async () => {
      await fetchDataKnowledgeQADetailOriginal();
    })();
  }, [fetchDataKnowledgeQADetailOriginal]);

  useEffect(() => {
    (async () => {
      await fetchDataKnowledgeQADetailAdditional();
    })();
  }, [fetchDataKnowledgeQADetailAdditional]);

  return (
    <ExpandingStyled visibleEditQAAnswer={visibleEditQAAnswer}>
      <Header title={headerTitle} className="header" />
      <FormikProvider value={formik}>
        <div className="container">
          <div className="btn-header">
            <div className="group-btn-left">
              {dataKnowledgeQADetailOriginal[0]?.answer_content ? (
                <div className="btn check">
                  <CheckCircleOutlined className="icon" />
                  回答済み
                </div>
              ) : dataRecord.qa_status === 2 ? (
                <>
                  <div className="btn check">
                    <CheckCircleOutlined className="icon" />
                    回答済み
                  </div>
                  <div className="btn comment">
                    <MessageOutlined className="icon" />
                    追加質問あり
                  </div>
                </>
              ) : (
                <div className="btn un-answer">
                  <ExclamationOutlined className="icon" />
                  未回答
                </div>
              )}

              <div
                className="btn-back"
                onClick={() => {
                  navigate(
                    dataRecord.isScreen === 'favorite'
                      ? routes.KnowledgeFavorite.path
                      : dataRecord.isScreen === 'to_login_id'
                      ? routes.KnowledgeToLoginId.path
                      : dataRecord.isScreen === 'created_by'
                      ? routes.KnowledgeCreatedBy.path
                      : routes.KnowledgeTop.path
                  );
                  dispatch(resetData());
                }}
              >
                <CaretLeftOutlined className="icon" />
                一覧に戻る
              </div>
            </div>
            <div className="group-btn-right">
              <Tooltip
                placement="top"
                title={dataKnowledgeLikeHistory.length ? 'いいね！しました' : ''}
              >
                <div
                  className="btn like"
                  onClick={async () => {
                    dispatch(startLoading());
                    if (!dataKnowledgeLikeHistory.length) {
                      const resultAction = await Promise.all([
                        dispatch(
                          createKnowLedgeLikeHistory({
                            item: {
                              knowledge_id: dataKnowledgeQADetailOriginal[0].knowledge_id,
                              login_id: userInfo?.login_id,
                              created_month: dayjs().format('YYYYMM'),
                            },
                          })
                        ),
                        dispatch(
                          updateKnowledge({
                            id: dataKnowledgeQADetailOriginal[0].item_ref!.knowledge_id.i_id,
                            data: {
                              item: {
                                likes: dataKnowledgeQADetailOriginal[0].number_of_likes + 1,
                                updatedat: new Date(),
                              },
                              is_force_update: true,
                            },
                          })
                        ),
                      ]);
                      if (createKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                        await fetchDataKnowledgeQADetailOriginal();
                        await fetchKnowledgeLikeHistory();
                      }
                    } else {
                      const resultAction = await Promise.all([
                        dispatch(
                          deleteKnowLedgeLikeHistory({
                            id: dataKnowledgeLikeHistory[0].item_ref!.knowledge_id.i_id,
                          })
                        ),
                        dispatch(
                          updateKnowledge({
                            id: dataKnowledgeQADetailOriginal[0].item_ref!.knowledge_id.i_id,
                            data: {
                              item: {
                                likes: Number(dataKnowledgeQADetailOriginal[0].number_of_likes) - 1,
                                updatedat: new Date(),
                              },
                              is_force_update: true,
                            },
                          })
                        ),
                      ]);
                      if (deleteKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                        await fetchDataKnowledgeQADetailOriginal();
                        await fetchKnowledgeLikeHistory();
                      }
                    }
                    dispatch(stopLoading());
                  }}
                >
                  {dataKnowledgeLikeHistory.length ? (
                    <LikeFilled className="icon" />
                  ) : (
                    <LikeOutlined className="icon" />
                  )}

                  {dataKnowledgeQADetailOriginal[0]?.number_of_likes}
                </div>
              </Tooltip>
              <Tooltip
                placement="top"
                title={dataKnowledgeFavoriteHistory.length ? 'お気に入りしました' : ''}
              >
                <div
                  className="btn star"
                  onClick={async () => {
                    dispatch(startLoading());
                    if (!dataKnowledgeFavoriteHistory.length) {
                      const resultAction = await Promise.all([
                        dispatch(
                          createFavoriteKnowledge({
                            item: {
                              company_id: userInfo?.company_id,
                              knowledge_id: dataRecord.knowledge_id,
                              login_id: userInfo?.login_id,
                            },
                          })
                        ),
                      ]);
                      if (createFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                        fetchDataKnowledgeQADetailOriginal();
                        fetchKnowledgeFavoriteHistory();
                      }
                    } else {
                      const resultAction = await Promise.all([
                        dispatch(
                          deleteFavoriteKnowledge({
                            id: dataKnowledgeFavoriteHistory[0].item_ref!.favorite_knowledge_id
                              .i_id,
                          })
                        ),
                      ]);
                      if (deleteFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                        fetchDataKnowledgeQADetailOriginal();
                        fetchKnowledgeFavoriteHistory();
                      }
                    }
                    dispatch(stopLoading());
                  }}
                >
                  <StarFilled
                    className={dataKnowledgeFavoriteHistory.length ? 'star-gold' : 'icon-star'}
                  />
                  お気に入り
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="recommended">
            <div className="wrap-header">
              <div className="title">
                <div className="title-header">
                  <span className="label-QA">Q&A</span>
                  <span className="content-header">
                    {dataKnowledgeQADetailOriginal[0]?.knowledge_title}
                  </span>
                </div>
              </div>
              <div className="info">
                <div className="item-from">
                  <div className="avatar">
                    <div className="label-QA">投稿者</div>
                    {dataKnowledgeQADetailOriginal[0]?.author_icon_file ? (
                      <img
                        className="avt-icon"
                        src={dataKnowledgeQADetailOriginal[0]?.author_icon_file}
                        alt="avt-user"
                      />
                    ) : (
                      <img className="avt-icon" src={adminAvatar} alt="avt-user" />
                    )}
                  </div>
                  <div className="item-name">
                    <div className="label-name">
                      {dataKnowledgeQADetailOriginal[0]?.author_affiliation_id.replace(/^\d+ /, '')}
                    </div>
                    <div className="name"> {dataKnowledgeQADetailOriginal[0]?.author_name}</div>
                  </div>
                </div>
                <CaretRightFilled className="icon-caret" />
                <div className="item-from">
                  <div className="avatar">
                    <div className="label-QA to">To</div>
                    <div className="avt">
                      {dataKnowledgeQADetailOriginal[0]?.to_user_icon_file ? (
                        <img
                          className="avt-icon"
                          src={dataKnowledgeQADetailOriginal[0]?.to_user_icon_file}
                          alt="avt-user"
                        />
                      ) : (
                        <TeamOutlined className="icon" />
                      )}
                    </div>
                  </div>
                  <div className="item-name">
                    <div className="name">
                      {dataKnowledgeQADetailOriginal[0]?.to_user_name
                        ? dataKnowledgeQADetailOriginal[0]?.to_user_name
                        : 'ALL'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!openUpdateKnowledge && (
              <>
                <div className="item-content">
                  <div className="item-info">
                    <div className="info">
                      {dataKnowledgeQADetailOriginal[0]?.author_icon_file ? (
                        <img
                          src={dataKnowledgeQADetailOriginal[0].author_icon_file}
                          className="avt-icon"
                          alt="avt"
                        />
                      ) : (
                        <div className="avt">
                          <TeamOutlined className="icon" />
                        </div>
                      )}
                      <div className="item-name">
                        <div className="label-name">
                          {dataKnowledgeQADetailOriginal[0]?.author_affiliation_id.replace(
                            /^\d+ /,
                            ''
                          )}
                        </div>
                        <div className="name">{dataKnowledgeQADetailOriginal[0]?.author_name}</div>
                      </div>
                    </div>
                    <p className="date">
                      {dayjs(dataKnowledgeQADetailOriginal[0]?.knowledge_updatedat).format(
                        'YYYY/MM/DD HH:mm'
                      )}
                      更新
                    </p>
                  </div>
                </div>
                <div className="comment">
                  <div
                    className="text"
                    style={{
                      display: 'block',
                      whiteSpace: 'pre',
                    }}
                    dangerouslySetInnerHTML={createMarkup(
                      dataKnowledgeQADetailOriginal[0]?.knowledge_text
                    )}
                    onClick={handlePreviewImage}
                  />
                  <div
                    className={
                      dataKnowledgeQADetailOriginal[0]?.files?.length
                        ? 'group-btn-paper'
                        : 'group-btn'
                    }
                  >
                    {dataKnowledgeQADetailOriginal[0]?.files?.length && (
                      <div className="group-paper">
                        {dataKnowledgeQADetailOriginal[0]?.files.map((e, i) => (
                          <button
                            className="btn-paper"
                            key={i}
                            onClick={() =>
                              e.originFileObj
                                ? fileSaver(e.originFileObj, extractFileName(e?.name))
                                : undefined
                            }
                          >
                            <div className="file">
                              <PaperClipOutlined className="icon" /> {e.name}
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                    {userInfo?.login_id === dataKnowledgeQADetailOriginal[0]?.author_id && (
                      <div className="btn-right">
                        <div
                          className={permissionNumber === 1 ? 'disabled' : 'btn-delete'}
                          onClick={() => setOpenModalDeleteKnowledge(true)}
                        >
                          <DeleteOutlined className="icon-delete" />
                          Q&A削除
                        </div>
                        <div
                          className={permissionNumber === 1 ? 'disabled' : 'btn-edit'}
                          onClick={() =>
                            navigate(
                              generatePath(routes.EditKnowledgeQA.path, {
                                id: dataKnowledgeQADetailOriginal[0].i_id,
                              }),
                              {
                                state: {
                                  ...dataKnowledgeQADetailOriginal[0],
                                  isScreen: dataRecord.isScreen,
                                },
                              }
                            )
                          }
                        >
                          <EditOutlined className="icon-edit" />
                          内容を更新する
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {dataKnowledgeQADetailOriginal[0]?.answer_content && !visibleEditQAAnswer && (
              <>
                <div className="btn-answer">
                  <div className="divider" />
                  <div className="answer">回答</div>
                </div>
                <div className="item-content">
                  <div className="item-info">
                    <div className="info">
                      {dataKnowledgeQADetailOriginal[0]?.respondent_icon_file ? (
                        <img
                          src={dataKnowledgeQADetailOriginal[0].respondent_icon_file}
                          className="avt-icon"
                          alt="avt"
                        />
                      ) : (
                        <div className="avt">
                          <TeamOutlined className="icon" />
                        </div>
                      )}
                      <div className="item-name">
                        <div className="label-name">
                          {dataKnowledgeQADetailOriginal[0]?.to_department_name}
                        </div>
                        <div className="name">
                          {dataKnowledgeQADetailOriginal[0]?.respondent_name}
                        </div>
                      </div>
                    </div>
                    <p className="date">
                      {dayjs(dataKnowledgeQADetailOriginal[0]?.answer_date).format(
                        'YYYY/MM/DD HH:mm'
                      )}
                    </p>
                  </div>
                </div>
                <div className="comment">
                  <div
                    className="text"
                    style={{
                      display: 'block',
                      whiteSpace: 'pre',
                    }}
                    dangerouslySetInnerHTML={createMarkup(
                      dataKnowledgeQADetailOriginal[0]?.answer_content
                    )}
                    onClick={handlePreviewImage}
                  />
                  <div
                    className={
                      dataKnowledgeQADetailOriginal[0]?.answer_file
                        ? 'group-btn-paper'
                        : 'group-btn'
                    }
                  >
                    {dataKnowledgeQADetailOriginal[0]?.answer_file && (
                      <button
                        className="btn-paper"
                        onClick={() =>
                          dataKnowledgeQADetailOriginal[0]?.answer_file?.originFileObj
                            ? fileSaver(
                                dataKnowledgeQADetailOriginal[0]?.answer_file?.originFileObj,
                                extractFileName(dataKnowledgeQADetailOriginal[0]?.answer_file?.name)
                              )
                            : undefined
                        }
                      >
                        <div className="file">
                          <PaperClipOutlined className="icon" />
                          {dataKnowledgeQADetailOriginal[0]?.answer_file?.name}
                        </div>
                      </button>
                    )}
                    {userInfo?.login_id === dataKnowledgeQADetailOriginal[0]?.respondent && (
                      <div className="btn-right">
                        <div className="btn-delete" onClick={() => setOpenModalDelete(true)}>
                          <DeleteOutlined className="icon-delete" />
                          回答削除
                        </div>
                        <div
                          className="btn-edit"
                          onClick={() => {
                            setVisibleEditQAAnswer(true);
                            setTextQAAnswer(dataKnowledgeQADetailOriginal[0]?.answer_content);
                          }}
                        >
                          <EditOutlined className="icon-edit" />
                          内容を更新する
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {!dataKnowledgeQADetailOriginal[0]?.answer_content && (
              <div className="no-comment">まだ、回答がありません。</div>
            )}
            {((userInfo?.login_id !== dataKnowledgeQADetailOriginal[0]?.author_id &&
              !dataKnowledgeQADetailOriginal[0]?.answer_content &&
              !dataKnowledgeQADetailOriginal[0]?.to_department_id &&
              !dataKnowledgeQADetailOriginal[0]?.to_user_id) ||
              (some(dataSelectDepartmentUser, {
                department_code: dataKnowledgeQADetailOriginal[0]?.to_department_id,
              }) &&
                !dataKnowledgeQADetailOriginal[0]?.to_user_id &&
                !dataKnowledgeQADetailOriginal[0]?.answer_content) ||
              (dataKnowledgeQADetailOriginal[0]?.to_user_id === userInfo?.login_id &&
                !dataKnowledgeQADetailOriginal[0]?.answer_content)) && (
              <>
                <div className="btn-answer">
                  <div className="divider" />
                  <div className="answer">回答</div>
                </div>
                <CreateQAAnswer
                  action="create"
                  onSubmit={() => handleCreateQAAnswer()}
                  setVisibleEditQAAnswer={setVisibleEditQAAnswer}
                  setTextQAAnswer={setTextQAAnswer}
                  textQAAnswer={textQAAnswer}
                  data={dataKnowledgeQADetailOriginal[0]}
                />
              </>
            )}
            {visibleEditQAAnswer && (
              <>
                <div className="btn-answer">
                  <div className="divider" />
                  <div className="answer">回答</div>
                </div>
                <CreateQAAnswer
                  action="edit"
                  onSubmit={() => handleUpdateQAAnswer()}
                  setVisibleEditQAAnswer={setVisibleEditQAAnswer}
                  setTextQAAnswer={setTextQAAnswer}
                  textQAAnswer={textQAAnswer}
                  data={dataKnowledgeQADetailOriginal[0]}
                />
              </>
            )}
          </div>
          {dataKnowledgeQADetailAdditional[0]?.question_content && (
            <div className="wrap-answer">
              <div className="title-answer">
                <MessageOutlined className="icon-message" />
                <span className="title">追加質問</span>
              </div>
              {dataKnowledgeQADetailAdditional?.map((item, i) => {
                return (
                  <div className="body" key={i}>
                    <div className="comment">
                      {item.question_answer_content ? (
                        <div className="has-answer">
                          <CheckOutlined className="icon" />
                          <span>回答済</span>
                        </div>
                      ) : (
                        <div className="not-answer">! 未回答 </div>
                      )}

                      {visibleEditQuestion && index === i ? (
                        <CreateQuestionAnswer
                          action="edit_question"
                          onSubmit={() =>
                            handleUpdateQuestion(item.item_ref!.question_content!.i_id)
                          }
                          setVisible={setVisibleEditQuestion}
                          setText={setText}
                          text={text}
                          data={item}
                          setFile={setFile}
                          file={file}
                        />
                      ) : (
                        <>
                          <div className="question">
                            <div className="left-question">
                              <div className="user">
                                {dataKnowledgeQADetailAdditional[0]
                                  ?.additional_questioner_icon_file ? (
                                  <img
                                    src={
                                      dataKnowledgeQADetailAdditional[0]
                                        ?.additional_questioner_icon_file
                                    }
                                    className="avt-icon"
                                    alt="avt"
                                  />
                                ) : (
                                  <div className="avt">
                                    <TeamOutlined className="icon" />
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 24px 0 8px',
                                  }}
                                >
                                  <span className="department">
                                    {item.questioner_affiliation_id.replace(/^\d+ /, '')}
                                  </span>
                                  <span className="name">{item.questioner_name}</span>
                                </div>
                              </div>
                              <div
                                className="content"
                                style={{
                                  display: 'block',
                                  whiteSpace: 'pre',
                                }}
                                dangerouslySetInnerHTML={createMarkup(item.question_content)}
                                onClick={handlePreviewImage}
                              />
                            </div>
                            <div className="time">
                              {dayjs(item.question_updatedat).format('YYYY/MM/DD HH:mm')}
                            </div>
                          </div>
                          <div
                            className={
                              item.additional_question_file
                                ? 'has-file has-file-question'
                                : 'wrap-button-add'
                            }
                          >
                            {item.additional_question_file && (
                              <div
                                className="file"
                                onClick={() =>
                                  item?.additional_question_file?.originFileObj
                                    ? fileSaver(
                                        item?.additional_question_file?.originFileObj,
                                        extractFileName(item?.additional_question_file?.name)
                                      )
                                    : undefined
                                }
                              >
                                <PaperClipOutlined /> {item?.additional_question_file?.name}
                              </div>
                            )}
                            {userInfo?.login_id !== item.questioner_id &&
                            !item.question_answer_content &&
                            !visible ? (
                              <Button
                                className="add-answer"
                                onClick={() => {
                                  setIndex(i);
                                  setVisible(true);
                                }}
                              >
                                回答する
                              </Button>
                            ) : item.questioner_id === userInfo?.login_id ? (
                              <div className="edit-delete">
                                <div
                                  className={permissionNumber === 1 ? 'disabled' : 'action'}
                                  onClick={() => {
                                    setIDSelected(item.item_ref!.questioner_id.i_id);
                                    setActionType('question');
                                    setVisibleDelete(true);
                                  }}
                                >
                                  <DeleteOutlined className="icon" />
                                  <span className="text">追加質問削除</span>
                                </div>
                                <button
                                  className={permissionNumber === 1 ? 'disabled' : 'action edit'}
                                  disabled={visibleCreateQuestion}
                                  onClick={() => {
                                    setIndex(i);
                                    setText(item.question_content);
                                    setVisibleEditQuestion(true);
                                  }}
                                >
                                  <EditOutlined className="icon" />
                                  <span className="text">内容を更新する</span>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}

                      {!!item.question_answer_content && !visibleEditAnswer ? (
                        <>
                          <div className="answer-question">
                            <div className="answer-label">回答</div>
                            <div>
                              <div className="user">
                                {item?.additional_question_respondent_icon_file ? (
                                  <img
                                    src={item.additional_question_respondent_icon_file}
                                    className="avt-icon"
                                    alt="avt"
                                  />
                                ) : (
                                  <div className="avt">
                                    <TeamOutlined className="icon" />
                                  </div>
                                )}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: '0 24px 0 8px',
                                  }}
                                >
                                  <span className="department">
                                    {item.question_respondent_affiliation_id.replace(/^\d+ /, '')}
                                  </span>
                                  <span className="name">{item.question_respondent_name}</span>
                                </div>
                              </div>
                              <div
                                className="content"
                                style={{
                                  display: 'block',
                                  whiteSpace: 'pre',
                                }}
                                dangerouslySetInnerHTML={createMarkup(item.question_answer_content)}
                                onClick={handlePreviewImage}
                              />
                            </div>
                            <span className="time">
                              {dayjs(item.question_answer_updatedat).format('YYYY/MM/DD HH:mm')}
                            </span>
                          </div>
                          <div className="wrap-action">
                            <div
                              className={
                                item.additional_question_answer_file
                                  ? 'has-file'
                                  : 'wrap-button-add'
                              }
                            >
                              {item.additional_question_answer_file && (
                                <div
                                  className="file"
                                  onClick={() =>
                                    item?.additional_question_answer_file?.originFileObj
                                      ? fileSaver(
                                          item?.additional_question_answer_file?.originFileObj,
                                          extractFileName(
                                            item?.additional_question_answer_file?.name
                                          )
                                        )
                                      : undefined
                                  }
                                >
                                  <PaperClipOutlined />
                                  {item?.additional_question_answer_file?.name}
                                </div>
                              )}
                            </div>
                            {item.question_respondent_id === userInfo?.login_id && (
                              <div className="edit-delete">
                                <div
                                  className="action"
                                  onClick={() => {
                                    setIDSelected(item.item_ref!.question_answer_content.i_id);
                                    setActionType('answer');
                                    setVisibleDelete(true);
                                  }}
                                >
                                  <DeleteOutlined className="icon" />
                                  <span className="text">回答削除</span>
                                </div>
                                <button
                                  className="action edit"
                                  disabled={visibleCreateQuestion}
                                  onClick={() => {
                                    setIndex(i);
                                    setText(item.question_answer_content);
                                    setVisibleEditAnswer(true);
                                  }}
                                >
                                  <EditOutlined className="icon" />
                                  <span className="text">内容を更新する</span>
                                </button>
                              </div>
                            )}
                          </div>
                        </>
                      ) : !!item.question_answer_content && visibleEditAnswer && index === i ? (
                        <CreateQuestionAnswer
                          action="edit_answer"
                          onSubmit={() =>
                            handleUpdateQuestionAnswer(item.item_ref!.question_answer_content!.i_id)
                          }
                          setVisible={setVisibleEditAnswer}
                          setText={setText}
                          text={text}
                          data={item}
                          setFile={setFile}
                          file={file}
                        />
                      ) : null}
                    </div>
                    {visible && i === index && (
                      <CreateQuestionAnswer
                        action="create_answer"
                        onSubmit={() => handleCreateAddQuestionAnswer(item.question_id)}
                        setVisible={setVisible}
                        setText={setText}
                        text={text}
                        setFile={setFile}
                        file={file}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {visibleCreateQuestion && (
            <>
              <div className="wrap-answer">
                <div className="body">
                  <CreateQuestionAnswer
                    action="create_question"
                    onSubmit={handleCreateQuestion}
                    setVisible={setVisibleCreateQuestion}
                    setText={setText}
                    text={text}
                    setFile={setFile}
                    file={file}
                  />
                </div>
              </div>
            </>
          )}
          <div className="add-question">
            <Button
              disabled={
                permissionNumber === 1 || visibleEditQuestion || visible || visibleEditAnswer
              }
              className={permissionNumber === 1 ? 'disabled' : ''}
              onClick={() => setVisibleCreateQuestion(true)}
            >
              ＋ 追加質問
            </Button>
          </div>
          <ImagePreview />
          <ConfirmDeleteModal
            title="削除確認"
            visible={visibleDelete}
            setVisible={setVisibleDelete}
            subTitle="削除を実行します。"
            onSubmit={() =>
              actionType === 'question'
                ? handleDeleteQuestion(idSelected)
                : handleDeleteQuestionAnswer(idSelected)
            }
          />
          <CompletedDelete
            visible={completed}
            setVisible={setCompleted}
            onSubmit={() => {
              setCompleted(false);
              fetchDataKnowledgeQADetailAdditional();
            }}
          />
          <ConfirmDeleteModal
            visible={openModalDelete}
            setVisible={setOpenModalDelete}
            title="削除確認"
            subTitle="質問の削除を実行します。"
            description="削除を実行すると、この質問に対する回答も同時に削除されます。"
            onSubmit={handleDeleteAnswer}
          />
          <CompletedDelete
            visible={openModalDeleteComplete}
            setVisible={setOpenModalDeleteComplete}
            onSubmit={() => {
              setOpenModalDeleteComplete(false);
              fetchDataKnowledgeQADetailAdditional();
            }}
          />
          <ConfirmDeleteModal
            visible={openModalDeleteKnowledge}
            setVisible={setOpenModalDeleteKnowledge}
            title="削除確認"
            subTitle="Q&Aの削除を実行します。"
            description="削除を実行すると、このQ&A内のすべての回答・質問も同時に削除されます。"
            onSubmit={handleDeleteKnowledge}
          />
          <CompletedDelete
            visible={openModalDeleteKnowledgeComplete}
            setVisible={setOpenModalDeleteKnowledgeComplete}
            onSubmit={() => navigate(-1)}
          />
        </div>
      </FormikProvider>
    </ExpandingStyled>
  );
};

export default ExpandingPDF;
