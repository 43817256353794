import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  title: string;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteComplete: React.FC<Props> = ({ visible, title, setVisible }) => {
  const handleClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      width={450}
      okButton={{
        text: 'OK',
        onClick: handleClose,
      }}
      onCancel={handleClose}
    >
      <SectionStyled>
        <p className="sub-title">
          <DeleteOutlined className="icon" />
          {title}
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default DeleteComplete;
