import React from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Header } from 'components';
import { Table } from 'antd';
import dayjs from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import UsageStatusStyled from '../../styles';
import * as Types from 'types';

interface Props {
  dataUsageList: Types.UsageList.DataConverted[];
  page: number;
  perPage: number;
}

const UsageStatusPDF: React.FC<Props> = ({ dataUsageList, page, perPage }) => {
  const { headerTitle } = useSelector(settingSelector);

  const columns: ColumnsType<Types.UsageList.DataConverted> = [
    {
      title: 'サービス利用年月',
      className: 'contract_date',
      dataIndex: 'contract_date',
      key: 'contract_date',
      width: '8%',
      render: (contract_date: string) => (
        <p className="label-number">{dayjs(contract_date).format('YYYY年MM月')}</p>
      ),
    },
    {
      title: 'ユーザー利用料',
      className: 'user-fee',
      children: [
        {
          title: 'ユーザー数',
          className: 'user-fee-item',
          dataIndex: 'max_users',
          key: 'max_users',
          width: '6%',
          render: (max_users: number) => (
            <div className="item-table">
              <p className="label-number">{max_users.toLocaleString()}</p>
              <p className="label">人</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'user-fee-item',
          dataIndex: 'unit_price_user',
          key: 'unit_price_user',
          width: '5%',
          render: (unit_price_user: number) => (
            <div className="item-table">
              <p className="label-number">{unit_price_user.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'user-fee-item',
          dataIndex: 'user_fee',
          key: 'user_fee',
          width: '10%',
          render: (user_fee: number) => (
            <div className="item-table">
              <p className="label-number">{user_fee.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'ストレージ利用料',
      className: 'storage-user-fee',
      children: [
        {
          title: '利用ストレージ',
          className: 'users-storage',
          dataIndex: 'usage_storage',
          key: 'usage_storage',
          width: '9%',
          render: (usage_storage: number) => (
            <div className="item-table">
              <p className="label-number">{usage_storage}</p>
              <p className="label">GB</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'users-storage',
          dataIndex: 'usage_storage_unit_price',
          key: 'usage_storage_unit_price',
          width: '10%',
          render: (usage_storage_unit_price: number) => (
            <div className="item-table">
              <p className="label-number">{usage_storage_unit_price?.toLocaleString()}</p>
              <p className="label">円 / 50GB</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'users-storage',
          dataIndex: 'user_fee_storage',
          key: 'user_fee_storage',
          width: '9%',
          render: (user_fee_storage: number) => (
            <div className="item-table">
              <p className="label-number">{user_fee_storage}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: '面接スキルチェック利用料',
      className: 'skill-check',
      children: [
        {
          title: '利用回数',
          className: 'skill-check-item',
          dataIndex: 'skill_check_usage_count',
          key: 'skill_check_usage_count',
          width: '12%',
          render: (skill_check_usage_count: number) => (
            <div className="item-table">
              <p className="label-number">{skill_check_usage_count.toLocaleString()}</p>
              <p className="label">回</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'skill-check-item',
          dataIndex: 'unit_price',
          key: 'unit_price',
          width: '5%',
          render: (unit_price: number) => (
            <div className="item-table">
              <p className="label-number">{unit_price.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'skill-check-item',
          dataIndex: 'skill_check_usage_fee',
          key: 'skill_check_usage_fee',
          width: '12%',
          render: (skill_check_usage_fee: number) => (
            <div className="item-table">
              <p className="label-number">{skill_check_usage_fee.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: '合計利用料（税抜）',
      dataIndex: 'total_usage_fee',
      key: 'total_usage_fee',
      width: '9%',
      render: (total_usage_fee: number) => (
        <div className="item-table">
          <p className="label-number bold">{total_usage_fee.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: '詳細',
      className: 'detail',
      dataIndex: 'detail',
      key: 'detail',
      width: '5%',
      render: (_, item) => <FileTextOutlined className="icon" />,
    },
  ];

  return (
    <UsageStatusStyled dataUsageStatus={dataUsageList.length > 0}>
      <Header title={headerTitle} exportPdf />
      <div className="container">
        <div className="wrap-table">
          <div className="total-record-table-pdf">
            <span className="text-static">
              {page * perPage > dataUsageList.length ? dataUsageList.length : page * perPage}
              <small> 件表示</small> / {dataUsageList.length}
              <small> 件中</small>
            </span>
          </div>
          <Table
            className="table-pdf"
            dataSource={dataUsageList}
            columns={columns}
            rowKey="index"
            pagination={false}
          />
        </div>
      </div>
    </UsageStatusStyled>
  );
};

export default UsageStatusPDF;
