import React, { useEffect, useMemo, useState } from 'react';
import { Col, Radio, Row, Select } from 'antd';
import { RadioChangeEvent } from 'antd/es';
import { useSelector } from 'react-redux';

import { officialCurriculumSelector } from 'pages/OfficialCurriculum/selectors';
import {
  getDataProviderInformation,
  getDataReleaseNoteDetail,
} from 'pages/OfficialCurriculum/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import JoditEditor from 'jodit-pro-react';

interface Props {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  visible: boolean;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataItemDetail?: Types.OfficialCurriculumList2.ResponseType;
}
const { Option } = Select;

const CurriculumNotes: React.FC<Props> = ({ visible, setVisible, dataItemDetail }) => {
  const [value, setValue] = useState<Types.ReleaseNoteDetail.ResponseType>();
  const [valueReleaseVersion, setValueReleaseVersion] = useState<string>();
  const dispatch = useAppDispatch();

  const { dataReleaseNoteDetail, dataInformationCurriculum } = useSelector(
    officialCurriculumSelector
  );
  const dataReleaseNote = useMemo(() => {
    const array = dataReleaseNoteDetail?.map((i) => i.release_note_version);
    return Array.from(new Set(array));
  }, [dataReleaseNoteDetail]);

  const { userInfo } = useSelector(authSelector);

  const dataSelect = useMemo(() => {
    const data = dataReleaseNoteDetail.filter(
      (item) =>
        item.release_note_version ===
        (valueReleaseVersion || dataReleaseNoteDetail[0]?.release_note_version)
    );
    if (data) {
      setValue(data[0]);
    }
    return data;
  }, [valueReleaseVersion, dataReleaseNoteDetail]);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!userInfo || !visible) return;

    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataReleaseNoteDetail({
          conditions: [
            {
              id: 'official_curriculum_code',
              search_value: [dataItemDetail?.official_curriculum_code],
            },
          ],
          page: 1,
          per_page: 0,
          sort_fields: [{ id: 'official_curriculum_code', order: 'asc' }],
        })
      );
      await dispatch(
        getDataProviderInformation({
          conditions: [
            {
              id: 'company_id',
              search_value: [dataItemDetail?.provider_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dataItemDetail, dispatch, userInfo, visible]);

  return (
    <Modal
      title="リリースノート"
      open={visible}
      width={1300}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <Row className="wrap-header">
          <Col span={13}>
            <p className="title">実践経理_STANDARD</p>
          </Col>
          <Col span={11}>
            <div className="select">
              <p className="item-title">制作者：{dataInformationCurriculum[0]?.creator}</p>
              <div className="select-item">
                <p className="label">バージョン</p>
                <Select
                  style={{ width: 200 }}
                  allowClear
                  defaultValue={dataItemDetail?.release_version.toString() || '1'}
                  onChange={setValueReleaseVersion}
                >
                  {dataReleaseNote?.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
        <div className="wrap-body">
          <div className="title-note">
            <p className="title">{dataReleaseNoteDetail[0]?.release_note_title}</p>
            <p className="version">2022.07.30</p>
          </div>
          <Row gutter={[40, 0]} className="item">
            <Col span={16} className="item-left">
              <div className="number-question">
                <div className="label-number">
                  <p className="number">6</p>
                </div>
                <p className="text">{value?.section_name}</p>
              </div>
              <JoditEditor config={defaultConfig} value={value?.section_text || ''} />
            </Col>
            <Col span={8} className="item-right">
              <div className="overview">
                <p className="label-overview">概要：</p>
                <p className="overview-content">{dataReleaseNoteDetail[0]?.description}</p>
              </div>
              <div className="select-item">
                <div className="label-select">
                  <p>セクション</p>
                </div>
                <Radio.Group onChange={onChange} value={value}>
                  {dataSelect?.map((item, index) => (
                    <Radio.Button value={item} key={index}>
                      <div className="item">
                        <p className="label-item">{index + 1}</p>
                        <p className="item-text">{item.section_name}</p>
                      </div>
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
            </Col>
          </Row>
        </div>
      </SectionStyled>
    </Modal>
  );
};

const defaultConfig = {
  license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
  readonly: true,
  toolbar: false,
  statusbar: false,
  styleValues: {
    colorBorder: '#ffffff',
  },
};

export default CurriculumNotes;
