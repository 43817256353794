import styled from 'styled-components';

export default styled.section`
  height: 100%;
  width: 100%;
  overflow: auto;
  .e-control {
    height: 100%;
  }
  .e-toolbar-item {
    display: none;
  }
  .e-sheet-panel {
    height: 74vh !important;
  }
  .e-tab-header {
    display: none;
  }
  .sub-title {
    background: #ffffff;
    padding: 11px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-publish {
        margin-right: 14px;
      }
      .payment-schedule {
        font-size: 12px;
        color: #777777;
      }
      .wrap-plan {
        display: flex;
        align-items: center;
        padding-left: 6px;
        padding-bottom: 7px;
        .plan {
          font-size: 20px;
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      .version {
        color: #999999;
        font-size: 10px;
        margin-right: 16px;
      }
      .btn {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f6ac00;
        font-size: 13px;
        &-edit {
          background: #f6ac00;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .close-up {
        font-size: 13px;
        color: #777777;
      }
      .close-icon {
        color: #777777;
        margin: 0 6px 0 14px;
      }
    }
  }
`;
