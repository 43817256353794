import { get, groupBy, orderBy, sortBy, uniqBy } from 'lodash';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/affiliation/memoized-tree-data-utils';
import { getAffiliationAssignRole, getAffiliations, getPositionSelect, getUserList } from './thunk';
import { checkExistItem } from 'libs/utils/affiliation/tree-data-utils';
import * as Types from 'types';

export interface InitialState {
  affiliations: Array<Types.TreeItem<Types.AffiliationItemType>>;
  affiliationsMain?: Types.AffiliationAssignRole.ResponseType;
  positionSelect: Array<Types.SelectPosition.ResponseType>;
  userList: Array<Types.EmployeeUser.ResponseType>;
}

const initialState: InitialState = {
  affiliations: [],
  userList: [],
  positionSelect: [],
};

export const affiliationSlice = createSlice({
  name: 'affiliation-page',
  initialState,
  reducers: {
    updateAffiliationsMain: (
      state,
      action: PayloadAction<Types.AffiliationAssignRole.ResponseType>
    ) => {
      state.affiliationsMain = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAffiliations.fulfilled, (state, action) => {
      const affiliation: Array<Types.TreeItem<Types.AffiliationItemType>> = [];
      const subPosition: {
        [key: string]: Array<Types.SubPositionType>;
      } = {};
      const userGroup = groupBy(action.payload.report_results, (item) =>
        get(item, 'item_ref.user_name.i_id', '')
      );
      Object.keys(userGroup).forEach((key) => {
        subPosition[key] = orderBy(
          userGroup[key]
            .filter((user) => user.user_departments || user.user_positions)
            .map((user) => ({
              affialition_id: user.lookup_items?.user_departments?.i_id,
              user_departments: user.user_departments.replace(/^\d+ /, ''),
              user_positions: user.user_positions.replace(/^\d+ /, ''),
              user_role_order: user.user_role_order,
              user_sort_order: user.user_sort_order,
            })),
          ['user_role_order'],
          ['asc']
        );
      });
      sortBy(action.payload.report_results, 'level').forEach((item) => {
        const index = affiliation.findIndex(
          (a) => a.i_id === item.i_id || checkExistItem({ item, treeData: a })
        );
        if (index >= 0) {
          affiliation[index] = memoizedConvertFlatDataFromTree({
            treeData: affiliation[index],
            subPosition:
              item.item_ref?.user_name?.i_id && subPosition[item.item_ref?.user_name?.i_id]
                ? uniqBy(subPosition[item.item_ref?.user_name?.i_id], 'affialition_id')
                : [],
            item,
          });
        } else {
          if (!item.affiliation_parent_id && item.user_role_order === item.user_sort_order) {
            affiliation.push({
              i_id: item.i_id,
              code: item.code,
              company_id: item.company_id,
              name: item.name,
              sort_order: item.sort_order,
              user_role_order: item.user_role_order,
              user_sort_order: item.user_sort_order,
              columnIndex: 0,
              affiliation_id: item.affiliation_id,
              affiliation_i_id: item.item_ref?.affiliation_id.i_id,
              affiliation_parent_id: item.affiliation_parent_id,
              user_children:
                item.item_ref?.user_name?.i_id &&
                item.lookup_items?.user_positions?.rank_order &&
                item.user_role_order === item.user_sort_order
                  ? [
                      {
                        i_id: item.item_ref?.user_name?.i_id,
                        user_name: item.user_name,
                        user_icon_fileID: item.user_icon_fileID,
                        user_role_order: item.user_role_order,
                        user_sort_order: item.user_sort_order,
                        sub_positions:
                          uniqBy(subPosition[item.item_ref?.user_name?.i_id], 'affialition_id') ||
                          [],
                        item_ref: {
                          user_role_order: item.item_ref.user_role_order,
                          user_sort_order: item.item_ref.user_sort_order,
                        },
                        lookup_items: {
                          user_positions: {
                            rank_order: item.lookup_items.user_positions.rank_order,
                          },
                        },
                      },
                    ]
                  : [],
              children: [],
            });
          }
        }
      });
      state.affiliations = orderBy(affiliation, ['sort_order'], ['asc']);
    });

    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.userList = action.payload.report_results;
    });
    builder.addCase(getPositionSelect.fulfilled, (state, action) => {
      state.positionSelect = action.payload.report_results;
    });
    builder.addCase(getAffiliationAssignRole.fulfilled, (state, action) => {
      state.affiliationsMain = action.payload.items[0];
    });
  },
});

export const { resetInitialState, updateAffiliationsMain } = affiliationSlice.actions;

export default affiliationSlice.reducer;
