import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, isEmpty } from 'lodash';

import { addFolderToTree, getPathFolder, removeFolder } from 'libs/utils/explorer/tree-data-utils';
import * as Types from 'types';
import {
  getListDepartment,
  getListFolderPermission,
  createNewFolder,
  getManualFolder,
  getListManual,
  getListManualFile,
  getDetailManual,
  getListAffiliationUser,
  getDataManualSelect,
  getDataManualFileSelect,
  getDataListAffiliation,
} from './thunk';

export interface InitialState {
  listFolderPermissions: Array<Types.ManualFolderPermissions.ResponseType>;
  listUser: Array<Types.ListAffiliationAssignUser>;
  manualFolderList: Array<Types.FileExplorer.TreeItem>;
  manualFileList: Array<Types.ManualConvertType>;
  listDepartment: Array<Types.SelectDepartment.ResponseType>;
  manualList: Array<Types.ManualConvertType>;
  activeFolder?: Types.FileExplorer.TreeItem;
  totalUser: number;
  totalDepartment: number;
  manualDetail?: Types.Manual.ManualFormik;
  filterOptions: {
    publish?: string;
    name?: string;
  };
  folderCreated: Types.FileExplorer.TreeItem;
  idManualDeleted: Array<string>;
  manualFileSelect: Array<Types.ManualConvertType>;
  manualSelect: Array<Types.ManualConvertType>;
  dataAffiliations: Array<Types.AffiliationLevel.ResponseType>;
}

const initialState: InitialState = {
  manualFolderList: [],
  manualFileList: [],
  manualList: [],
  listUser: [],
  listDepartment: [],
  listFolderPermissions: [],
  filterOptions: {},
  totalUser: 0,
  totalDepartment: 0,
  folderCreated: {},
  idManualDeleted: [],
  manualFileSelect: [],
  manualSelect: [],
  dataAffiliations: [],
};

export const manualSlice = createSlice({
  name: 'manual-page',
  initialState,
  reducers: {
    setManualFolderList: (state, action: PayloadAction<Array<Types.FileExplorer.TreeItem>>) => {
      state.manualFolderList = action.payload;
    },
    setActiveFolder: (state, action: PayloadAction<Types.FileExplorer.TreeItem | undefined>) => {
      state.activeFolder = action.payload;
    },

    setFilterConditions(
      state,
      action: PayloadAction<{
        publish?: string;
        name?: string;
      }>
    ) {
      if (action.payload.publish) {
        state.filterOptions.publish = action.payload.publish;
      } else if (action.payload.name) {
        state.filterOptions.name = action.payload.name;
      }
    },
    clearFilterConditions(state) {
      state.filterOptions = {
        name: undefined,
        publish: undefined,
      };
    },
    setIdDeleted: (state, action) => {
      state.idManualDeleted = action.payload ? [...state.idManualDeleted, action.payload] : [];
    },
    setFolderCreated: (state, action) => {
      state.folderCreated = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getManualFolder.fulfilled, (state, action) => {
      const convert = (
        nodes: Types.FileExplorer.TreeItem[],
        parentId?: string,
        pathActiveFolder?: Types.FileExplorer.TreeItem[]
      ): Types.FileExplorer.TreeItem[] =>
        nodes
          .filter((node) => node.parent_folder_id === parentId)
          .reduce(
            (
              tree: Types.FileExplorer.TreeItem[],
              node: Types.FileExplorer.TreeItem,
              currentIndex: number
            ) => {
              const newNode: Types.FileExplorer.TreeItem = {
                ...node,
                expanded: pathActiveFolder?.some((item) => item.i_id === node.i_id),
                children: convert(nodes, node.folder_id, pathActiveFolder),
              };
              if (
                !action.payload.ignoreSetActiveFolder &&
                !state.activeFolder &&
                ((currentIndex === 0 && !node.parent_folder_id) ||
                  action.payload.id === newNode.i_id)
              ) {
                state.activeFolder = { ...newNode, expanded: true };
              }

              return [...tree, newNode];
            },
            []
          );
      const manualFolderList = convert(action.payload.items);
      const pathActiveFolder = getPathFolder(manualFolderList, state.activeFolder);
      const newManualFolders = convert(action.payload.items, undefined, pathActiveFolder);

      state.manualFolderList = newManualFolders
        .map((e) => {
          const index = findIndex(state.manualFolderList, (val) => val?.i_id === e?.i_id);
          if (index > -1) {
            if (!isEmpty(state.folderCreated) && isEmpty(state.idManualDeleted)) {
              return addFolderToTree(state.manualFolderList[index], state.folderCreated);
            }
            if (!isEmpty(state.idManualDeleted) && isEmpty(state.folderCreated)) {
              const result = {};
              state.idManualDeleted.forEach((id, idex) => {
                Object.assign(
                  result,
                  removeFolder(idex ? result : state.manualFolderList[index], id)
                );
              });
              return result;
            }
            if (!isEmpty(state.folderCreated) && state.idManualDeleted.length) {
              const result = {};
              state.idManualDeleted.forEach((id, idex) => {
                Object.assign(
                  result,
                  removeFolder(idex ? result : state.manualFolderList[index], id)
                );
              });
              Object.assign(result, addFolderToTree(result, state.folderCreated));
              return result;
            }
            return {
              ...state.manualFolderList[index],
              children: newManualFolders[index].children,
            };
          }
          return e;
        })
        .filter(Boolean);
      state.folderCreated = {};
      state.idManualDeleted = [];
    });
    builder.addCase(getListManual.fulfilled, (state, action) => {
      state.manualList = action.payload.items;
    });
    builder.addCase(getDataManualSelect.fulfilled, (state, action) => {
      state.manualSelect = action.payload.items.map((i) => ({
        i_id: i.i_id,
        i_id_file_id: i.i_id,
        company_id: i.company_id,
        folder_id: i.folder_id,
        file_id: i.manual_id,
        file_title: i.manual_title,
        publish: i.publish,
        file_version: i.version,
        file_description: i.description,
      }));
    });
    builder.addCase(getDataManualFileSelect.fulfilled, (state, action) => {
      state.manualFileSelect = action.payload.items.map((i) => ({
        i_id: i.i_id,
        i_id_file_id: i.i_id,
        company_id: i.company_id,
        folder_id: i.folder_id,
        file_id: i.attachment_id,
        file_title: i.attachment_title,
        publish: i.publish,
        file_version: i.attachment_version,
        file_description: i.attachment_description,
      }));
    });
    builder.addCase(getListManualFile.fulfilled, (state, action) => {
      state.manualFileList = action.payload.items;
    });
    builder.addCase(getDetailManual.fulfilled, (state, action) => {
      state.manualDetail = {
        i_id: action.payload.items[0].i_id,
        title: action.payload.items[0].manual_title,
        manual_id: action.payload.items[0].manual_id,
        publish: action.payload.items[0].publish,
        description: action.payload.items[0].description,
        version: action.payload.items[0].version,
        folder_id: action.payload.items[0].folder_id,
        item_links: action.payload.items[0]?.item_links,
        sections: action.payload.items.map((manual) => ({
          i_id: manual.i_id_section,
          section_name: manual.section_name,
          text: manual.text,
        })),
      };
    });
    builder.addCase(getListAffiliationUser.fulfilled, (state, action) => {
      state.listUser = action.payload.items;
      state.totalUser = action.payload.totalItems;
    });
    builder.addCase(getListDepartment.fulfilled, (state, action) => {
      state.listDepartment = action.payload.report_results;
      state.totalDepartment = action.payload.totalItems;
    });
    builder.addCase(getListFolderPermission.fulfilled, (state, action) => {
      state.listFolderPermissions = action.payload.items;
    });
    builder.addCase(createNewFolder.fulfilled, (state, action) => {
      state.folderCreated = action.payload.item!;
    });
    builder.addCase(getDataListAffiliation.fulfilled, (state, action) => {
      state.dataAffiliations = action.payload.items;
    });
  },
});

export const {
  clearFilterConditions,
  setManualFolderList,
  setFilterConditions,
  resetInitialState,
  setFolderCreated,
  setActiveFolder,
  setIdDeleted,
} = manualSlice.actions;

export default manualSlice.reducer;
