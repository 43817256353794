import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BarChartOutlined, CloudDownloadOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Select, Radio } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { ITEM_COMPARISION_ANALYSIS, REPORT_TYPE } from 'constant/select.constants';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { skillCheckReportPageSelector } from '../../selectors';
import MultipleComparison from './MultipleComparison';
import exportPDF from 'libs/utils/exportPdf';
import { resetInitialState } from './slice';
import FilePDFExport from './FilePDFExport';
import { UserType } from 'types/config';
import { DatePicker } from 'components';
import { useAppDispatch } from 'hooks';
import Comparison from './Comparison';
import Wrapper from './styles';

const { Option } = Select;

interface Props {
  reportType: (typeof REPORT_TYPE)[number]['value'];
  userType: UserType;
}

const SkillCheckAnalysisReport: React.FC<Props> = ({ userType, reportType }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [startPeriod, setStartPeriod] = useState<string>();
  const [compareType, setCompareType] = useState<number>(0);
  const [segmented, setSegmented] = useState<number>(0);
  const [endPeriod, setEndPeriod] = useState<string>();
  const [groupCode, setGroupCode] = useState<string>();
  const [loginID, setLoginID] = useState<string>();
  const [itemComparision, setItemComparision] =
    useState<(typeof ITEM_COMPARISION_ANALYSIS)[number]['value']>('accuracy_rate');

  const ref = useRef(null);

  const { selectAnalysisGroup, selectEmployeeUser, selectInterviewUser } = useSelector(
    skillCheckReportPageSelector
  );

  const dispatch = useAppDispatch();

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          selectAnalysisGroup={selectAnalysisGroup}
          selectInterviewUser={selectInterviewUser}
          selectEmployeeUser={selectEmployeeUser}
          itemComparision={itemComparision}
          compareType={compareType}
          startPeriod={startPeriod}
          reportType={reportType}
          groupCode={groupCode}
          segmented={segmented}
          endPeriod={endPeriod}
          userType={userType}
          loginID={loginID}
        />
      </div>
    );
  }, [
    compareType,
    endPeriod,
    groupCode,
    itemComparision,
    loginID,
    reportType,
    segmented,
    selectAnalysisGroup,
    selectEmployeeUser,
    selectInterviewUser,
    startPeriod,
    userType,
  ]);

  const handleExportCSV = (value: string) => {
    dispatch(startLoading());
    if (value === 'pdf') {
      if (!ref.current) return;

      exportPDF(ref, 'スキルチェックレポート.pdf');
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    setGroupCode(undefined);
    setLoginID(undefined);
    dispatch(resetInitialState());
    setSegmented(0);
    return () => {
      setGroupCode(undefined);
      setLoginID(undefined);
      dispatch(resetInitialState());
      setSegmented(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <Wrapper>
      {component}
      <div className="header-container">
        <span className="title">
          スキルチェック【社内】の実施結果を、分析グループを基準に集計したレポートです。
        </span>
        <Button
          className="button-export"
          onClick={() => setShowConfirmExportFileModal(true)}
          icon={<CloudDownloadOutlined />}
        >
          エクスポート
        </Button>
      </div>
      <div className="wrap-filter">
        <span className="label">集計条件</span>
        <div className="aggregation-conditions">
          <div className="form-select">
            <div className="wrap-select">
              <div className="item">
                <span className="text-label">分析グループ（成長比較）</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  value={groupCode}
                  onSelect={(value: string) => {
                    if (value === 'ALL' && loginID === 'ALL') {
                      setLoginID(undefined);
                    }
                    setGroupCode(value);
                  }}
                >
                  <Option disabled={loginID === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {selectAnalysisGroup.map((item, index) => (
                    <Option key={index} value={item.group_code}>
                      {item.group_name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">ユーザー</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  value={loginID}
                  onSelect={(value: string) => {
                    if (value === 'ALL' && groupCode === 'ALL') {
                      setGroupCode(undefined);
                    }
                    setLoginID(value);
                  }}
                >
                  <Option disabled={groupCode === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                    (item, index) => (
                      <Option key={index} value={item.login_id}>
                        {item.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
            </div>
            <div className="wrap-time-select">
              <div className="radio-item">
                <div className="three-border" />
                <Radio.Group
                  className="radio-select"
                  onChange={(e) => setCompareType(e.target.value)}
                  value={compareType}
                >
                  <Radio value={0}>２期間比較</Radio>
                  <Radio value={1}>推移比較</Radio>
                </Radio.Group>
              </div>
              <div className="item">
                <span className="text-label">実施期間①</span>
                <DatePicker
                  allowClear
                  name="date-item"
                  className="date-input"
                  format="YYYY/MM/DD"
                  value={startPeriod ? dayjs(startPeriod) : undefined}
                  onChange={(value) => setStartPeriod(value?.format('YYYY/MM/DD'))}
                />
              </div>
              <div className="item">
                <span className="text-label">実施期間②</span>
                <DatePicker
                  allowClear
                  name="date-item"
                  className="date-input"
                  format="YYYY/MM/DD"
                  value={endPeriod ? dayjs(endPeriod) : undefined}
                  disabledDate={(current) =>
                    current && current < dayjs(startPeriod, 'YYYY/MM/DD').add(1, 'day')
                  }
                  onChange={(value) => setEndPeriod(value?.format('YYYY/MM/DD'))}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="note-compareType">
        {compareType === 0 && segmented === 1
          ? '※選択した期間における設問平均と設問別の２期間比較が表示されています。'
          : compareType === 1
          ? '※選択した実施期間における設問別の推移比較が表示されています。比較項目はプルダウンで変更できます。'
          : ''}
      </p>
      <div className="sub-container">
        <div className="wrap-segmented">
          <div className="left-side">
            <span className="label">レポートタイプ：</span>
            <div className="segmented">
              <div
                className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                onClick={() => {
                  setSegmented(0);
                  setItemComparision('accuracy_rate');
                }}
              >
                <TableOutlined className="icon" />
                <span>表</span>
              </div>
              <div
                className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                onClick={() => {
                  setSegmented(1);
                  setItemComparision('accuracy_rate');
                }}
              >
                <BarChartOutlined className="icon" />
                <span>グラフ</span>
              </div>
            </div>
            {compareType === 1 ? (
              <>
                <span className="cross-boiled">/</span>
                <span className="label">比較項目：</span>
                <div className="item">
                  <Select
                    className="select"
                    placeholder="---"
                    onSelect={setItemComparision}
                    value={itemComparision}
                  >
                    {ITEM_COMPARISION_ANALYSIS.map(({ label, value }, index) => (
                      <Option key={index} value={value}>
                        {label}推移
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {compareType === 0 ? (
          <MultipleComparison
            segmented={segmented}
            userType={userType}
            startPeriod={startPeriod}
            endPeriod={endPeriod}
            groupCode={groupCode}
            loginID={loginID}
          />
        ) : (
          <Comparison
            segmented={segmented}
            itemComparision={itemComparision}
            userType={userType}
            startPeriod={startPeriod}
            endPeriod={endPeriod}
            groupCode={groupCode}
            loginID={loginID}
          />
        )}
      </div>

      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </Wrapper>
  );
};

export default SkillCheckAnalysisReport;
