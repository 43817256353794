import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '..';
interface Props {
  visible: boolean;
  onSubmit?: () => void;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  setVisible: (visible: boolean) => void;
}

const UnableAddQuestionModal: React.FC<Props> = ({
  visible,
  title,
  subTitle,
  description,
  setVisible,
  onSubmit,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    handleToggleModal();
  };

  return (
    <Modal
      title={
        title && (
          <h3
            style={{
              display: 'flex',
              backgroundColor: '#ffffff',
              fontFamily: 'Noto Sans Javanese',
              fontSize: 18,
              justifyContent: 'center',
              alignItems: 'center',
              color: '#424242',
              margin: 0,
            }}
          >
            {title}
          </h3>
        )
      }
      open={visible}
      width={720}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};

export default UnableAddQuestionModal;
