import React, { useEffect, useRef, useState } from 'react';
import { LeftOutlined, RightOutlined, ZoomInOutlined } from '@ant-design/icons';
import { CarouselRef } from 'antd/lib/carousel';
import { Carousel, Image } from 'antd';
import ReactPlayer from 'react-player';

import ModalPreview from 'pages/Settings/SkillCheckSelection/SkillCheckStatus/ModalPreview';
import ConfirmRightStyled from './styles';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  answer: string;
  correct: number;
  tabIndex: number;
  visible: boolean;
  numQuestion: number;
  numAllQuestion: number;
  title?: React.ReactNode;
  handleNextTab: () => void;
  numCorrectQuestion: number;
  subTitle?: React.ReactNode;
  numAcquisitionScore: number;
  onSubmit?: () => Promise<void> | void;
  data: Types.CurriculumImple.ResponseType;
  fileAnswer: string | undefined;
  setRenew: React.Dispatch<React.SetStateAction<boolean>>;
  setCorrect: React.Dispatch<React.SetStateAction<number>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setNumAcquisitionScore: React.Dispatch<React.SetStateAction<number>>;
}

const ConfirmRight: React.FC<Props> = ({
  data,
  answer,
  visible,
  correct,
  tabIndex,
  setRenew,
  fileAnswer,
  setVisible,
  setCorrect,
  handleNextTab,
  numQuestion,
  numAllQuestion,
  numCorrectQuestion,
  numAcquisitionScore,
  setNumAcquisitionScore,
}) => {
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [isPreviewVisible, setPreviewVisible] = useState<number>();

  const slider = useRef<CarouselRef>(null);

  const handleNext = () => slider.current?.next();

  const handlePrev = () => slider.current?.prev();

  const handleToggleModal = () => {
    setRenew(true);
    setCorrect(correct - 1);
    setVisible(false);
    setNumAcquisitionScore(numAcquisitionScore - data?.score);
  };

  useEffect(() => {
    if (visible) {
      setRenew(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      visible={visible}
      width={744}
      zIndex={1}
      onCancel={handleToggleModal}
      bodyStyle={{
        backgroundColor: '#ffffff',
        padding: 0,
      }}
    >
      <ConfirmRightStyled>
        <div className="check-answer">
          <div className="circle">
            <p className="label">正解</p>
          </div>
          <p className="correct-answer">正解は{answer}です</p>
        </div>
        <div className="confirm-answer">
          <p className="explanation">- 解説 -</p>
          {data?.comment}
          <div className="item-question">
            {data?.comment_attach_files && data?.comment_attach_files?.length > 1 ? (
              <>
                <button className="btn-next" onClick={handlePrev}>
                  <LeftOutlined className="icon" />
                </button>
                <Carousel
                  beforeChange={(_currentSlide, nextSlide) => {
                    setCarouselIndex(nextSlide);
                  }}
                  className="carousel"
                  ref={slider}
                >
                  {data?.comment_attach_files.map((url, i) => (
                    <div className="wrap-item" key={i}>
                      <div className="item-image">
                        {url.includes('mp4') ? (
                          <>
                            <ReactPlayer
                              width={'100%'}
                              height={'100%'}
                              url={url}
                              controls
                              config={{
                                file: {
                                  attributes: {
                                    disablePictureInPicture: true,
                                    controlsList: 'nodownload noplaybackrate',
                                  },
                                },
                              }}
                            />
                          </>
                        ) : url.includes('png') || url.includes('jpg') || url.includes('jpeg') ? (
                          <>
                            <Image className="image-attach" src={url} preview={false} />
                            <ModalPreview
                              index={i}
                              isPreviewVisible={isPreviewVisible}
                              item={url}
                              setPreviewImageAnswer={setPreviewVisible}
                            />
                            <div className="zoom-image">
                              <ZoomInOutlined
                                onClick={() => setPreviewVisible(carouselIndex)}
                                className="icon-zoom"
                              />
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </Carousel>
                <button className="btn-next" onClick={handleNext}>
                  <RightOutlined className="icon" />
                </button>
              </>
            ) : data?.comment_attach_files?.length ? (
              <>
                <div className="item-image">
                  {data?.comment_attach_files[0].includes('mp4') ? (
                    <>
                      <ReactPlayer
                        width={'100%'}
                        height={'100%'}
                        url={data?.comment_attach_files[0]}
                        controls
                        config={{
                          file: {
                            attributes: {
                              disablePictureInPicture: true,
                              controlsList: 'nodownload noplaybackrate',
                            },
                          },
                        }}
                      />
                    </>
                  ) : data?.comment_attach_files[0].includes('png') ||
                    data?.comment_attach_files[0].includes('jpg') ||
                    data?.comment_attach_files[0].includes('jpeg') ? (
                    <>
                      <Image
                        className="image-attach"
                        src={data?.comment_attach_files[0]}
                        preview={false}
                      />
                      <div className="zoom-image">
                        <ZoomInOutlined
                          onClick={() => setPreviewVisible(0)}
                          className="icon-zoom"
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                <ModalPreview
                  index={0}
                  isPreviewVisible={isPreviewVisible}
                  setPreviewImageAnswer={setPreviewVisible}
                  item={data?.comment_attach_files[0]}
                />
              </>
            ) : null}
          </div>
        </div>
        <div className="group-btn">
          <button className="btn btn-close" onClick={handleToggleModal}>
            リトライ
          </button>
          <button className="btn btn-active" onClick={handleNextTab}>
            {numQuestion === tabIndex + 1 &&
            numQuestion <= numAllQuestion &&
            numCorrectQuestion < numAllQuestion
              ? '選択画面に戻る'
              : '次の設問へ'}
          </button>
        </div>
      </ConfirmRightStyled>
    </Modal>
  );
};

export default ConfirmRight;
