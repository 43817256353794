import { HEADER_HEIGHT } from 'constant';
import styled from 'styled-components';

export default styled.section`
  p {
    margin-right: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .header {
    .filter-icon {
      font-size: 18px;
      color: #777777;
      margin-right: 5px;
    }
    .form {
      display: flex;
      align-items: center;
      .form-input {
        margin-bottom: unset;
        &:nth-child(2) {
          .ant-select-selector {
            width: 240px;
            height: 36px;
            margin-right: 8px;
          }
        }
        &:nth-child(3) {
          .ant-select-selector {
            width: 120px;
            height: 36px;
          }
        }
        &:nth-child(4) {
          .ant-select-selector {
            width: 120px;
            height: 36px;
          }
        }
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
      }
    }
  }
  .wrap-body {
    display: flex;
    background: #f9f8f8;
    height: calc(100vh - ${HEADER_HEIGHT}px);
    .item-left {
      width: 85%;
      overflow: auto;
      .wrap-tab {
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        position: relative;
        .tab-container {
          .ant-tabs-nav {
            margin: 18px 0 0 0;
            &::before {
              border: none;
            }
          }
          .ant-tabs-tab-active .ant-tabs-tab-btn {
            color: #08a3a5;
          }
          .ant-tabs-tab {
            padding: 20px;
            &:hover {
              color: #2a2a2a;
            }
          }
          .ant-tabs-ink-bar {
            background: #08a3a5;
          }
        }
        .group-btn {
          position: absolute;
          top: 20px;
          right: 29px;
          .disabled {
            color: #777777 !important;
            margin-left: 8px;
          }
          .btn {
            width: 120px;
            height: 40px;
            border: none;
            border-radius: 5px;
            font-weight: 700;
            font-size: 13px;
            color: #ffffff;
            cursor: pointer;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          }
          .btn-tell {
            background-color: #0eaf58;
            margin-right: 8px;
          }
          .btn-recommended {
            background-color: #1e7ed6;
          }
        }
      }
      .wrap-table {
        position: relative;
        .text-count {
          position: absolute;
          font-size: 14px;
          color: #424242;
          top: 35px;
          left: 15px;
          .text-static {
            font-size: 11px;
            padding-left: 3px;
          }
        }
        .sort-column {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 25px;
          right: 15px;
          z-index: 9;
          .select-perpage {
            display: flex;
            align-items: center;
            padding-right: 10px;
            .label {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
          }
          .item-sort {
            display: flex;
            align-items: center;
            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
            .btn-sort {
              width: 18px;
              height: 18px;
              border-radius: 4px;
              padding: 0;
              .wrap-sort {
                display: flex;
                flex-direction: column;
                align-items: center;
                .icon-caret {
                  font-size: 9px;
                  color: #bfbfbf;
                }
                .active {
                  color: #08a3a5;
                }
              }
            }
          }
        }
        .table-knowledge-inf {
          padding: 0 15px;
          margin: 15px 0;
          .ant-spin-nested-loading {
            .ant-spin-container {
              .ant-table {
                overflow-y: auto;
                max-height: calc(100vh - 306px);
              }
            }
          }
          .ant-table-cell {
            border-bottom: 6px solid #f9f8f8;
            padding: 10px 10px;
          }
          .ant-table-cell.item-receiver {
            display: revert;
          }
          .star {
            .yellow {
              color: #f6ac00;
            }
            .gray {
              color: #e1e1e1;
            }
          }
          .receiver {
            display: flex;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: auto;
            border: 1px solid #dddddd;
            border-radius: 4px;
            padding: 5px 10px;
            .label {
              width: 170px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 11px;
              color: #777;
              .label-all {
                font-size: 14px;
                color: #424242;
              }
            }
            .text {
              width: 160px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
              color: #424242;
            }
          }
          .time {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            .number-like {
              font-family: Lato;
              font-size: 14px;
              color: #424242;
              margin: 0;
              .like-icon {
                color: #08a3a5;
                font-size: 14px;
                padding-right: 5px;
              }
            }
            .label-time {
              font-family: Lato;
              font-size: 11px;
              color: #aaaaaa;
            }
            .detail {
              font-family: Lato;
              font-size: 11px;
              color: #424242;
            }
          }
          .item-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            .avatar {
              width: 55px;
              height: 55px;
              border-radius: 50%;
            }
            .user-info {
              width: 100px;
              margin-left: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              .department-name {
                font-size: 11px;
                color: #999999;
              }
              .username {
                font-size: 12px;
                color: #424242;
              }
            }
          }
          .item-title {
            display: flex;
            flex-direction: column;
            .knowledge-text {
              display: inline-block;
              max-width: 450px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .item-recommend {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            .knowledge-label {
              border: 1px solid #dddddd;
              border-radius: 20px;
              text-align: center;
              font-size: 11px;
              color: #424242;
              margin: 3px 0;
              width: 70px;
            }
            .qa-status-label {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid;
              border-radius: 11px;
              font-weight: 700;
              font-size: 11px;
              color: #ffffff;
              width: 70px;
              .label {
                font-weight: 700;
              }
              .icon {
                margin-right: 5px;
              }
            }
            .extend-question {
              border: 1px solid #ed7200;
              background-color: #ed7200;
              margin-top: 3px;
            }
            .recommendation-status-label {
              border-radius: 20px;
              text-align: center;
              font-size: 11px;
              color: #ffffff;
              margin: 3px 0;
              width: 70px;
              .label {
                font-weight: 700;
              }
              .icon {
                margin-right: 5px;
              }
            }
            .red {
              background-color: #f03060;
              border-color: #f03060;
            }
            .blue {
              background-color: #1fb9db;
              border-color: #1fb9db;
            }
          }
          .star {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            &::before {
              position: absolute;
              content: '';
              width: 4px;
              height: 58px;
              border-radius: 2px;
              background: #d9d9d9;
              top: -20px;
              left: -5px;
            }
          }
          .border-blue::before {
            background: #1e7ed6;
          }
          .border-green::before {
            background: #0eaf58;
          }
        }
      }
    }
    .item-right {
      width: 15%;
      min-height: calc(100vh - 56px);
      background: #ffffff;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
      .header-rank {
        background: linear-gradient(0deg, #caa549, #caa549), #ffffff;
        padding: 10px 0;
        font-weight: 700;
        font-size: 13px;
        color: #ffffff;
        .icon {
          font-size: 18px;
          padding: 0 8px;
        }
      }
      .group-btn {
        display: flex;
        justify-content: center;
        padding: 15px 0;
        .btn {
          width: 60px;
          height: 28px;
          font-size: 13px;
          color: #424242;
          background: #ffffff;
          border: 1px solid #cccccc;
          cursor: pointer;
          &:nth-child(1) {
            border-radius: 3px 0 0 3px;
            border-right-color: #08a3a5;
          }
          &:nth-child(2) {
            border-radius: 0 3px 3px 0;
            border-left-width: 0;
          }
        }
        .btn-active {
          font-weight: 700;
          color: #08a3a5;
          border: 1px solid #08a3a5;
        }
      }
      .list-rank {
        border-top: 1px solid #eaeaea;
        .empty {
          padding: 20px;
          .ant-empty-image svg {
            width: 100%;
          }
        }
      }
    }
  }
  @media (max-width: 1440px) {
    .wrap-body {
      .item-right {
        width: 180px;
      }
      .item-left {
        .wrap-tab {
        }
        .wrap-table {
          .table-knowledge-inf {
            .receiver {
              .label {
                width: 125px;
              }
              .text {
                width: 115px;
              }
            }
          }
        }
      }
    }
  }
`;

interface ButtonLabelProps {
  tabRank: number;
  index: number;
}

export const ButtonLabel = styled.button<ButtonLabelProps>`
  width: 50%;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: ${(props) => (props.tabRank === props.index ? 'bold' : 'unset')};
  font-size: 12px;
  box-shadow: ${(props) =>
    props.tabRank === props.index ? 'unset' : ' -3px -1px 0 rgba(0, 0, 0, 0.05)'};
  border: none;
  background-color: ${(props) => (props.tabRank === props.index ? '#FFFFFF' : '#F5F5F5')};
  color: ${(props) => (props.tabRank === props.index ? '#00A3A5' : 'color: #424242')};
  @media (max-width: 1440px) {
    height: 32px;
    padding: 0px;
  }
`;

interface ButtonProps {
  tabButton: number;
  index: number;
}

export const ButtonRank = styled.button<ButtonProps>`
  width: 60px;
  height: 38px;

  cursor: pointer;
  font-weight: ${(props) => (props.tabButton === props.index ? 'bold' : 'unset')};
  font-size: 13px;
  background: #ffffff;
  border: ${(props) =>
    props.tabButton === props.index ? '1px solid #08a3a5' : '1px solid #CCCCCC'};
  border-radius: 3px;
  color: ${(props) => (props.tabButton === props.index ? '#00A3A5' : '#424242')};
`;

export const ItemKnowledgeComponent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  .left-side {
    width: 10%;
    .numerical-order-label {
      font-family: Lato;
      font-weight: 700;
      font-size: 15px;
      text-align: center;
      color: #424242;
      margin: 0;
    }
  }
  .right-side {
    width: 90%;
    padding-right: 20px;
    .recommend {
      position: relative;
      width: 100%;
      .label {
        padding: 0 8px;
        font-size: 10px;
        color: #777777;
        position: relative;
        &::before {
          content: '';
          width: 4px;
          height: 15px;
          background: #d9d9d9;
          border-radius: 3px;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
      .green::before {
        content: '';
        width: 4px;
        height: 15px;
        background: #0eaf58;
        border-radius: 3px;
        position: absolute;
        top: 3px;
        left: 0;
      }
      .blue::before {
        content: '';
        width: 4px;
        height: 15px;
        background: #1e7ed6;
        border-radius: 3px;
        position: absolute;
        top: 3px;
        left: 0;
      }
      .text {
        font-size: 12px;
        color: #2a2a2a;
      }
      .wrap-count-like {
        position: absolute;
        top: 0;
        right: 0;
        .like-icon {
          color: #08a3a5;
          padding-right: 3px;
        }
        .count-like {
          font-weight: 700;
          font-size: 13px;
          color: #2a2a2a;
        }
      }
    }
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    .wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      background-color: #f7f7f7;
      border: 1px solid #eaeaea;
      padding: 8px 5px 25px 5px;
      .crown-icon {
        position: absolute;
        top: -28px;
        color: #d7b96d;
        font-size: 30px;
      }
      .item-label {
        display: flex;
        align-items: center;
        justify-content: space-around;
        min-width: 55px;
        background-color: #ffffff;
        border-radius: 8px;
        padding: 0 5px;
        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #d9d9d9;
        }
        .green {
          background: #0eaf58;
        }
        .blue {
          background: #1e7ed6;
        }
        .text {
          font-size: 12px;
          color: #777777;
        }
      }
      .title {
        margin-top: 10px;
        text-align: center;
        line-break: anywhere;
      }
      .ribbon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 70%;
        height: 24px;
        bottom: -12px;
        .ribbon-image {
          width: 100%;
          height: 100%;
        }
        .number-of-likes {
          position: absolute;
          top: 0;
          font-weight: 700;
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
      cursor: pointer;
      .avatar {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
      .empty-avatar {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #eaeaea;
        .icon {
          color: #bbbbbb;
        }
      }
      .user-name {
        font-size: 11px;
        color: #424242;
        margin-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const ItemUserComponent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 7px 0;
  border-bottom: 1px solid #eaeaea;
  position: relative;
  .left-side {
    width: 10%;
    .numerical-order-label {
      font-family: Lato;
      font-weight: 700;
      font-size: 15px;
      text-align: center;
      color: #424242;
      margin: 0;
    }
  }
  .right-side {
    width: 90%;
    padding-right: 20px;
    .recommend {
      display: flex;
      flex-direction: row;
      position: relative;
      width: 100%;
      .image-avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      .no-avatar {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .user-info {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        .department {
          max-width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 10px;
          color: #999999;
        }
        .user-name {
          max-width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 12px;
          color: #424242;
          line-break: anywhere;
        }
      }
      .wrap-count-like {
        position: absolute;
        top: 0;
        right: 0;
        .like-icon {
          color: #08a3a5;
          padding-right: 3px;
        }
        .count-like {
          font-weight: 700;
          font-size: 13px;
          color: #2a2a2a;
        }
      }
    }
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 20px;
      align-items: center;
      width: 100%;
      position: relative;
      .crown-icon {
        position: absolute;
        top: -28px;
        color: #d7b96d;
        font-size: 30px;
      }
      .image-avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
      .no-avatar {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          font-size: 40px;
        }
      }
      .ribbon {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 70%;
        height: 24px;
        bottom: -12px;
        .ribbon-image {
          width: 100%;
          height: 100%;
        }
        .number-of-likes {
          position: absolute;
          top: 0;
          font-weight: 700;
          font-size: 13px;
          color: #ffffff;
        }
      }
    }
    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      .department {
        max-width: 205px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        color: #999999;
      }
      .user-name {
        max-width: 205px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #424242;
        line-break: anywhere;
      }
    }
  }
`;
