import React, { useEffect, useMemo, useState } from 'react';
import { SectionStyled } from './styles';
import { AnyObject, HeaderCSVs } from 'types/config';
import { Table, Tooltip } from 'antd';
import _ from 'lodash';
import { ColumnsType } from 'antd/es/table';
import { IconCsvError } from 'assets';

interface ImportedDataTableProps {
  attached?: boolean;
  headerCSVs: HeaderCSVs;
  dataImported?: AnyObject[];
  headersImported?: string[];
  errors?: AnyObject;
}

const DEFAULT_TABLE_ITEMS = 9;

function CustomCell(props: AnyObject) {
  const { error } = props;
  if (error) {
    return (
      <Tooltip
        title={error}
        color="#ffffff"
        placement="bottomLeft"
        overlayInnerStyle={{
          backgroundColor: '#FFFFFF',
          background: '#FFFFFF',
          color: '#424242',
          padding: '8px',
        }}
      >
        <td {...props} />
      </Tooltip>
    );
  }
  return <td {...props} />;
}

const ImportedDataTable: React.FC<ImportedDataTableProps> = (props) => {
  const { attached, headerCSVs, errors, dataImported, headersImported } = props;
  const [dataSource, setDataSource] = useState<any>([]);
  const [headerErrors, setHeaderErrors] = useState<string[]>([]);
  const [showOnlyError, setShowOnlyError] = useState<boolean>(false);
  const [lineErrors, setLineErrors] = useState<number>(0);

  const columns: ColumnsType<AnyObject> = useMemo(
    () => [
      {
        title: '行数',
        width: 70,
        dataIndex: 'index',
        render: (value, row) => {
          return (
            <div className="rowNumber">
              {row.hasError && (
                <Tooltip
                  title="この行にエラーがあります。"
                  color="#ffffff"
                  placement="bottomLeft"
                  overlayInnerStyle={{
                    backgroundColor: '#FFFFFF',
                    background: '#FFFFFF',
                    color: '#424242',
                    padding: '8px',
                  }}
                >
                  <img src={IconCsvError} alt="iconError" />
                </Tooltip>
              )}
              <div className="rowIndex">{value}</div>
            </div>
          );
        },
      },
      ...(headerCSVs || []).map((obj) => {
        const isErrorHeader = headerErrors.includes(obj.label);
        return {
          title: isErrorHeader ? (
            <Tooltip
              title={`${obj.label}は必須項目です。`}
              color="#ffffff"
              placement="bottomLeft"
              overlayInnerStyle={{
                backgroundColor: '#FFFFFF',
                background: '#FFFFFF',
                color: '#424242',
                padding: '8px',
              }}
            >
              {obj.label}
            </Tooltip>
          ) : (
            obj.label
          ),
          width: obj.width || 150,
          dataIndex: obj.key,
          className: isErrorHeader ? 'cell-error' : undefined,
          render: (value: string, record: AnyObject) => {
            const errorKey = `${obj.key}_error`;
            const error = record[errorKey];
            return {
              props: {
                style: { background: error ? 'rgba(217, 155, 255, 0.4)' : '#FFFFFF' },
                error: error,
              },
              children: value,
            };
          },
        };
      }),
    ],
    [headerCSVs, headerErrors]
  );

  useEffect(() => {
    if (!attached) {
      setShowOnlyError(false);
      const items = _.range(1, DEFAULT_TABLE_ITEMS).map(
        (idx) =>
          headerCSVs.reduce((o, item) => ({ ...o, [item.key]: '' }), { index: '', rowKey: idx }),
        {}
      );
      setHeaderErrors([]);
      setDataSource(items);
    } else {
      setLineErrors(errors ? Object.keys(errors).length : 0);
      const diffHeaders = _.difference(
        headerCSVs.map((obj) => obj.label),
        headersImported || []
      );
      setHeaderErrors(diffHeaders);
      const headerErrorObj =
        diffHeaders && diffHeaders.length
          ? headerCSVs
              .filter((obj) => diffHeaders.includes(obj.label))
              .reduce(
                (obj, item) => ({ ...obj, [`${item.key}_error`]: `${item.label}は必須項目です。` }),
                {}
              )
          : {};
      const items = (dataImported || []).map((obj, index) => {
        const err = (errors || {})[index];
        const hasError = !!err || !_.isEmpty(headerErrorObj);
        return {
          ...obj,
          index: index + 1,
          rowKey: index,
          hasError,
          ...(err || {}),
          ...headerErrorObj,
        };
      });
      setDataSource(showOnlyError ? items.filter((obj) => obj.hasError) : items);
    }
  }, [attached, headerCSVs, dataImported, headersImported, errors, showOnlyError]);

  const switchOnlyError = () => {
    setShowOnlyError((pre) => !pre);
  };

  return (
    <SectionStyled>
      <div className="header">
        {attached && (
          <div>
            インポート予定件数：{(dataImported || []).length}件{'　'}/{'　'}エラー件数：{lineErrors}
            件
          </div>
        )}
        <div className="dataTitle">インポートするCSVファイルを確認します。</div>
        {attached && (
          <button type="button" className="btn-upload" onClick={switchOnlyError}>
            {showOnlyError ? '全て表示する' : 'エラーのみ表示する'}
          </button>
        )}
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        rowKey="rowKey"
        scroll={{ x: 1440, y: 720 }}
        pagination={false}
        bordered
        components={{
          body: {
            cell: CustomCell,
          },
        }}
        locale={{
          emptyText: attached ? (
            <span className="empty-error">インポートするデータが存在しません。</span>
          ) : null,
        }}
      />
    </SectionStyled>
  );
};

export default ImportedDataTable;
