import React, { useEffect, useState } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { ITEM_COMPARISION_ANALYSIS } from 'constant/select.constants';
import { skillCheckAnalysisReportSelector } from '../../selectors';
import Wrapper from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  itemComparision: (typeof ITEM_COMPARISION_ANALYSIS)[number]['value'];
  exportPdf?: boolean;
}

const UserAnalysisReport: React.FC<Props> = ({ itemComparision, exportPdf }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<
    Types.ReportSkillCheck.SkillCheckAnalysisTableComparisonType[]
  >([]);

  const { skillCheckAnalysisComparison } = useSelector(skillCheckAnalysisReportSelector);

  const columns: ColumnsType<Types.ReportSkillCheck.SkillCheckAnalysisTableComparisonType> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '50px',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
    },
    {
      key: 'question_name',
      title: '設問',
      dataIndex: 'question_name',
      width: '300px',
    },
    ...skillCheckAnalysisComparison.columnName.map(
      (item) =>
        ({
          key: item,
          title: item,
          dataIndex: item,
          align: 'center',
          render: (text: string) =>
            text ? (
              <span>
                {itemComparision === 'average_answer_time_per_question'
                  ? text
                  : formatNumber(Number(text))}
                <small style={{ color: '#999999' }}>
                  {itemComparision === 'average_answer_time_per_question' ? '' : '%'}
                </small>
              </span>
            ) : (
              '-'
            ),
        } as ColumnType<Types.ReportSkillCheck.SkillCheckAnalysisTableComparisonType>)
    ),
  ];

  useEffect(() => {
    const data: Types.ReportSkillCheck.SkillCheckAnalysisTableComparisonType[] = [];
    skillCheckAnalysisComparison.data?.forEach((item) => {
      if (!item || !item.implementation_date || !dayjs(item.implementation_date).isValid()) return;
      Object.keys(item!.date!).forEach((key) => {
        const date = dayjs(key).format('YYYY/MM/DD');
        const itemIndex = data.findIndex((d) => d.question_name === item.question_name);
        let value = '';
        switch (itemComparision) {
          case 'accuracy_rate':
            value = (item?.date![key]!.accuracy_rate * 100).toString();
            break;
          case 'wrong_rate':
            value = (item?.date![key]!.wrong_rate * 100).toString();
            break;
          case 'inexperienced_rate':
            value = (item?.date![key]!.inexperienced_rate * 100).toString();
            break;
          case 'average_answer_time_per_question':
            value = formatNumberToMinute(item?.date![key]!.average_answer_time_per_question);
            break;
          case 'acquisition_score_rate':
            value = (item?.date![key]!.acquisition_score_rate * 100).toString();
            break;
          default:
            break;
        }

        if (itemIndex >= 0) {
          data[itemIndex][date] = value;
        } else {
          data.push({
            question_name: item.question_name,
            [date]: value,
          });
        }
      });
    });
    setTableData(data);
  }, [skillCheckAnalysisComparison, itemComparision]);

  return (
    <Wrapper>
      {tableData.length ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {tableData.length} <span className="text-static"> 件表示</span> /{tableData.length}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={tableData.length}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}

      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          exportPdf
            ? tableData.map((item, index) => ({ ...item, index }))
            : tableData
                .map((item, index) => ({ ...item, index }))
                .slice((page - 1) * pageSize, page * pageSize)
        }
        columns={columns}
        bordered
        pagination={false}
      />
    </Wrapper>
  );
};

export default UserAnalysisReport;
