export const HEADER_CURRICULUM_CSV = [
  { label: 'カリキュラムコード', key: 'curriculum_code' },
  { label: 'カリキュラム名', key: 'curriculum_name' },
  { label: 'カリキュラム説明', key: 'curriculum_description' },
  { label: '第１階層名', key: 'level1_name' },
  { label: '第１階層コード', key: 'level1_code' },
  { label: '第２階層名', key: 'level2_name' },
  { label: '第2階層コード', key: 'level2_code' },
  { label: '第３階層名', key: 'level3_name' },
  { label: '第3階層コード', key: 'level3_code' },
  { label: '第４階層名', key: 'level4_name' },
  { label: '第4階層コード', key: 'level4_code' },
  { label: '設問名', key: 'question_name' },
  { label: '設問コード', key: 'question_code' },
  { label: '設問内容', key: 'question_description' },
  { label: '設問添付ファイルID', key: 'question_attach' },
  { label: '設問添付ファイルID2', key: 'question2_attach' },
  { label: '設問添付ファイルID3', key: 'question3_attach' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A添付ファイルID', key: 'problems1_attach' },
  { label: '選択肢B添付ファイルID', key: 'problems2_attach' },
  { label: '選択肢C添付ファイルID', key: 'problems3_attach' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説添付ファイルID1', key: 'problems1_attach_fileID' },
  { label: '解説添付ファイルID2', key: 'problems2_attach_fileID' },
  { label: '解説添付ファイルID3', key: 'problems3_attach_fileID' },
  { label: 'ファイルID', key: 'fileID' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア', key: 'score' },
];

export const HEADER_JOB_TITLE_MASTER_CSV = [
  { label: '会社ID', key: 'company_id' },
  { label: '役職コード', key: 'code' },
  { label: '名称', key: 'name' },
  { label: '序列順序', key: 'rank_order' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日時', key: 'createdat' },
];
export const HEADER_SKILL_CHECK_MASTER_CSV = [
  { label: 'company_id', key: 'company_id' },
  { label: 'createdby', key: 'createdby' },
  { label: 'description', key: 'description' },
  { label: 'end_period', key: 'end_period' },
  { label: 'grouping_code', key: 'grouping_code' },
  { label: 'implementation_settings', key: 'implementation_settings' },
  { label: 'problems4', key: 'problems4' },
  { label: 'publish', key: 'publish' },
  { label: 'question_count', key: 'question_count' },
  { label: 'question_time_limit', key: 'question_time_limit' },
  { label: 'skill_check_code', key: 'skill_check_code' },
  { label: 'skill_check_code_assign', key: 'skill_check_code_assign' },
  { label: 'skill_check_name', key: 'skill_check_name' },
  { label: 'start_period', key: 'start_period' },
  { label: 'user_type', key: 'user_type' },
];

export const HEADER_CURRICULUM_MASTER_TABLE_CSV = [
  { label: 'ステータス', key: 'publish' },
  { label: 'カリキュラムコード カリキュラム名', key: 'curriculum_description' },
  { label: '第１階層コード 第１階層名', key: 'creator' },
  { label: '第２階層コード 第２階層名', key: 'curriculum_code' },
  { label: '第３階層コード 第３階層名', key: 'curriculum_name' },
  { label: '第４階層コード 第４階層名', key: 'curriculum_sort_order' },
  { label: '設問コード 設問名', key: 'official_curriculum_code' },
  { label: 'problems_count', key: 'problems_count' },
  { label: 'required_curricullum', key: 'required_curricullum' },
];

export const HEADER_AFFILIATION_MASTER_CSV = [
  { label: 'affiliation_id', key: 'affiliation_id' },
  { label: 'affiliation_parent_id', key: 'affiliation_parent_id' },
  { label: 'code', key: 'code' },
  { label: 'company_id', key: 'company_id' },
  { label: 'level', key: 'level' },
  { label: 'login_id', key: 'login_id' },
  { label: 'name', key: 'name' },
  { label: 'sort_order', key: 'sort_order' },
  { label: 'user_icon_fileID', key: 'user_icon_fileID' },
  { label: 'user_name', key: 'user_name' },
  { label: 'user_sort_order', key: 'user_sort_order' },
];

export const HEADER_ROLES_MASTER_CSV = [
  { label: '権限コード', key: 'code' },
  { label: '会社ID', key: 'company_id' },
  { label: '権限名', key: 'name' },
  { label: '管理者ダッシュボード権限', key: 'admin_dashboard_permission' },
  { label: '部署マスタ権限', key: 'departments_master_permission' },
  { label: 'ユーザーマスタ権限', key: 'users_master_permission' },
  { label: '管理者マスタ権限', key: 'admin_master_permission' },
  { label: 'カリキュラムマスタ権限', key: 'curricullum_master_permission' },
  { label: '分析グループマスタ権限', key: 'groups_master_permission' },
  { label: 'スキルチェックマスタ権限', key: 'skill_check_master_permission' },
  { label: 'バンドルマスタ権限', key: 'bundle_master_permission' },
  { label: 'レポート閲覧権限', key: 'report_view_permission' },
  { label: '権限マスタ権限', key: 'roles_master_permission' },
  { label: '役職マスタ権限', key: 'positions_master_permission' },
  { label: '支払いマスタ権限', key: 'payment_master_permission' },
  { label: 'タイマーマスタ権限', key: 'timer_master_permission' },
  { label: '設問マスタ権限', key: 'question_master_permission' },
  { label: 'スキルチェックツリー権限', key: 'skill_check_tree_permission' },
  { label: '面接ユーザーマスタ権限', key: 'inter_users_master_permission' },
  { label: '更新者', key: 'updatedby' },
  { label: '登録者', key: 'createdby' },
];

export const HEADER_EMPLOYEE_CSV = [
  { label: '社員番号', key: 'employee_code' },
  { label: 'ユーザ名', key: 'name' },
  { label: 'ユーザ名（カナ）', key: 'kana' },
  { label: 'メールアドレス', key: 'email' },
  { label: 'パスワード', key: 'password' },
  { label: '所属ID', key: 'department_name' },
  { label: '役職コード', key: 'position_code' },
  { label: '誕生日', key: 'birthday' },
  { label: '入社日', key: 'hire_date' },
  { label: '権限コード', key: 'role_code' },
  { label: 'インポート用権限コード', key: 'i_id_role_code' },
];

export const HEADER_INTERVIEW_CSV = [
  {
    label: '管理番号',
    key: 'manage_code',
  },
  {
    label: '氏名',
    key: 'name',
  },
  {
    label: 'フリガナ',
    key: 'kana',
  },
  {
    label: '募集所属',
    key: 'department_name',
  },
  {
    label: '募集役職',
    key: 'position_code',
  },
  {
    label: '年齢',
    key: 'birthday',
  },
  {
    label: '職歴',
    key: 'work_history',
  },
  {
    label: '採用予定年月',
    key: 'hire_date',
  },
  {
    label: 'メールアドレス',
    key: 'email',
  },
  {
    label: 'スキルチェック',
    key: 'implementation_date',
  },
];

export const HEADER_QUESTION_MASTER_CSV = [
  { label: '設問名称', key: 'name' },
  { label: '設問ID', key: 'i_id' },
  { label: '設問内容', key: 'description' },
  { label: '設問画像・動画ID①', key: 'attach_fileID1' },
  { label: '設問画像・動画ID②', key: 'attach_fileID2' },
  { label: '設問画像・動画ID③', key: 'attach_fileID3' },
  { label: '選択肢A', key: 'problems1' },
  { label: '選択肢B', key: 'problems2' },
  { label: '選択肢C', key: 'problems3' },
  { label: '選択肢A画像・動画ID', key: 'problems1_attach_fileID' },
  { label: '選択肢B画像・動画ID', key: 'problems2_attach_fileID' },
  { label: '選択肢C画像・動画ID', key: 'problems3_attach_fileID' },
  { label: '解答', key: 'answer' },
  { label: '解説', key: 'comment' },
  { label: '解説画像・動画ID①', key: 'comment_attach_fileID1' },
  { label: '解説画像・動画ID②', key: 'comment_attach_fileID2' },
  { label: '解説画像・動画ID③', key: 'comment_attach_fileID3' },
  { label: '制限時間', key: 'time_limit' },
  { label: 'スコア（設問難易度）', key: 'score' },
  { label: '会社ID', key: 'company_id' },
  { label: '登録者', key: 'createdby' },
  { label: '登録日', key: 'createdat' },
  { label: '作成ユーザ名', key: 'creator' },
  { label: 'provider_id', key: 'provider_id' },
  { label: 'provider_question_code', key: 'provider_question_code' },
  { label: '設問画像・動画名①', key: 'attach_file_name1' },
  { label: '設問画像・動画名②', key: 'attach_file_name2' },
  { label: '設問画像・動画名③', key: 'attach_file_name3' },
  { label: '選択肢A画像・動画名', key: 'problems1_attach_file_name' },
  { label: '選択肢B画像・動画名', key: 'problems2_attach_file_name' },
  { label: '選択肢C画像・動画名', key: 'problems3_attach_file_name' },
  { label: '解説画像・動画名①', key: 'comment_attach_file_name1' },
  { label: '解説画像・動画名②', key: 'comment_attach_file_name2' },
  { label: '解説画像・動画名③', key: 'comment_attach_file_name3' },
];

export const HEADER_MY_CHART_DASH_BROARD_CSV = [
  { label: '設問', key: 'name' },
  { label: '正答', key: 'answer' },
  { label: '解答', key: 'user_answer' },
  { label: '解答結果', key: 'accuracy_rate' },
  { label: '解答時間', key: 'answer_time' },
  { label: 'ユーザー平均解答時間', key: 'average_answer_time' },
  { label: 'ユーザー平均正解率', key: 'correct' },
  { label: '設定スコア', key: 'score' },
  { label: '獲得スコア', key: 'acquisition_score' },
];

export const HEADER_EDUCATION_PROGRAM_CSV = [
  { label: '社員番号', key: 'employee_code' },
  { label: '氏名', key: 'name' },
  { label: 'フリガナ', key: 'kana' },
  { label: '所属', key: 'department_name' },
  { label: '役職', key: 'position_code' },
];

export const HEADER_SKILL_CHECK_SELECTION_CSV = [
  { label: 'スキルチェック名', key: 'skill_check_name' },
  { label: '実施期間', key: 'period' },
  { label: '所属', key: 'department_name' },
  { label: 'ユーザー名', key: 'login_id' },
  { label: '実施日時', key: 'implementation_date' },
];

export const HEADER_USAGE_STATUS_CSV = [
  { label: 'サービス利用年月', key: 'service_usage_date' },
  { label: 'ユーザー数', key: 'number_show' },
  { label: '単価', key: 'unit_price_user_show' },
  { label: 'ユーザー利用料', key: 'user_show' },
  { label: 'スキルチェック利用回数', key: 'skill_check_usage_count_show' },
  { label: '単価', key: 'unit_price_show' },
  { label: 'スキルチェック利用料', key: 'skill_check_usage_fee_show' },
  { label: '合計利用料（税抜）', key: 'total_usage_fee_show' },
];

export const HEADER_STORAGE_MANAGER = [
  { label: 'ファイル名', key: 'filename' },
  { label: '更新日時', key: 'createdAt' },
  { label: '種類', key: 'file_extension' },
  { label: 'サイズ', key: 'file_size' },
  { label: '利用場所', key: 'file_location' },
];

export const HEADER_SKILL_CHECK_CSV = [
  { label: '同一スキルチェックフラグ', key: 'same_skill_check_flag' },
  { label: 'スキルチェック名称', key: 'name' },
  { label: '実施可否設定', key: 'implementation_settings' },
  { label: '実施期間設定（開始）', key: 'start_period' },
  { label: '実施期間設定（終了）', key: 'end_period' },
  { label: '分析グループコード', key: 'grouping_code' },
  { label: 'レポート用基準日付', key: 'base_date_for_report' },
  { label: 'ユーザー種別', key: 'user_type' },
  { label: '制限時間', key: 'time_limit' },
  { label: '選択肢D', key: 'problems4' },
  { label: '設問コード', key: 'question_code' },
  { label: '説明', key: 'description' },
];
