import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getEmployeeUser,
  getInterviewUser,
  getSelectSkillCheck,
  getSelectAnalysisGroup,
} from './thunk';

export interface InitialState {
  selectInterviewUser: Types.SelectInterviewUser.ResponseType[];
  selectAnalysisGroup: Types.SelectAnalysisGroup.ResponseType[];
  selectEmployeeUser: Types.SelectEmployeeUser.ResponseType[];
  selectSkillCheck: Types.SelectSkillCheck.ResponseType[];
}

const initialState: InitialState = {
  selectInterviewUser: [],
  selectAnalysisGroup: [],
  selectEmployeeUser: [],
  selectSkillCheck: [],
};

export const skillCheckReportPageSlice = createSlice({
  name: 'skillCheckReportPage-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelectAnalysisGroup.fulfilled, (state, action) => {
      state.selectAnalysisGroup = action.payload.report_results;
    });

    builder.addCase(getSelectSkillCheck.fulfilled, (state, action) => {
      state.selectSkillCheck = action.payload.report_results;
    });

    builder.addCase(getInterviewUser.fulfilled, (state, action) => {
      state.selectInterviewUser = action.payload.report_results;
    });

    builder.addCase(getEmployeeUser.fulfilled, (state, action) => {
      state.selectEmployeeUser = action.payload.report_results;
    });
  },
});

export const { resetInitialState } = skillCheckReportPageSlice.actions;

export default skillCheckReportPageSlice.reducer;
