import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { getReportSkillCheckUserTrans2 } from '../thunk';
import { UserType } from 'types/config';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Table from './Table';
import Chart from './Chart';

interface Props {
  secondSkillcheck?: string;
  firstSkillcheck?: string;
  exportPdf?: boolean;
  groupCode?: string;
  userType: UserType;
  segmented: number;
  loginID?: string;
}

const MultipleComparisonSkillcheck: React.FC<Props> = ({
  secondSkillcheck,
  firstSkillcheck,
  exportPdf,
  segmented,
  groupCode,
  loginID,
  userType,
}) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);

  useEffect(() => {
    if (!userInfo || !loginID || !groupCode || !firstSkillcheck || !secondSkillcheck) return;
    (async () => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
        {
          id: 'user_type',
          search_value: [userType],
        },
        {
          id: 'skill_check_code',
          search_value: [`${firstSkillcheck}|${secondSkillcheck}`],
          exact_match: true,
        },
      ];

      if (loginID && loginID !== 'ALL') {
        conditions.push({
          id: 'login_id',
          search_value: [loginID],
          exact_match: true,
        });
      }
      if (groupCode && groupCode !== 'ALL') {
        conditions.push({
          id: 'grouping_code',
          search_value: [groupCode],
        });
      }

      await dispatch(
        getReportSkillCheckUserTrans2({
          conditions,
          page: 1,
          per_page: 0,
          firstSkillcheck,
          secondSkillcheck,
          sort_fields: [
            {
              id: 'base_date_for_report',
              order: 'desc',
            },
            {
              id: 'skill_check_created_at',
              order: 'desc',
            },
          ],
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, loginID, groupCode, userType, firstSkillcheck, secondSkillcheck]);

  return !segmented ? (
    <Table
      secondSkillcheck={secondSkillcheck}
      firstSkillcheck={firstSkillcheck}
      exportPdf={exportPdf}
    />
  ) : (
    <Chart
      secondSkillcheck={secondSkillcheck}
      firstSkillcheck={firstSkillcheck}
      exportPdf={exportPdf}
    />
  );
};

export default MultipleComparisonSkillcheck;
