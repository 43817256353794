import styled from 'styled-components';

interface Props {
  isEmptyData: boolean;
  isEmptyDataCurriculum: boolean;
}

export default styled.section<Props>`
  position: relative;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  .text-note {
    font-size: 12px;
    color: #424242;
  }

  .wrap-basic-inf {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 10px;
    .header {
      height: 48px;
      font-size: 16px;
      color: #2a2a2a;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    .body {
      padding: 40px;
    }
  }
  .wrap-training-inf {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 2px;
    border-radius: 5px;
    .item-inf {
      text-align: center;
      border: 1px solid #eaeaea;
      .text-number-of-curriculum {
        margin-top: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #424242;
      }
      .item-child-left {
        border-top: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .number-curriculum {
          margin-bottom: 0;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          line-height: 160%;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
        }
      }
      .item-child-right {
        border-top: 1px solid #eaeaea;
        .number-curriculum {
          margin-bottom: 0;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
        }
      }
      .item-inf-left {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .number-curriculum {
          margin-bottom: 0;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          line-height: 160%;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
        }
      }
      .item-inf-right {
        .number-curriculum {
          margin-bottom: 20px;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
  .complete-curriculum {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 2px;
    border-radius: 5px;
    .ant-table-thead {
      .ant-table-cell {
        border-bottom: 1px solid #c4c4c4;
      }
    }
    .header-complete-curriculum {
      display: flex;
      padding: 20px;
      align-items: center;
      .icon {
        color: #08a3a5;
        font-size: 28px;
        margin-right: 10px;
      }
      .tx-complete-curriculum {
        font-size: 14px;
        font-weight: 700;
        color: #2a2a2a;
        margin: 0;
      }
    }
    .item-inf {
      text-align: center;
      border: 1px solid #eaeaea;
      .text-number-of-curriculum {
        margin-top: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #424242;
      }
      .item-inf-left {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .number-curriculum {
          margin-bottom: 0;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          line-height: 160%;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
        }
      }
      .item-inf-right {
        .number-curriculum {
          margin-bottom: 20px;
          font-family: Lato;
          font-weight: 400;
          font-size: 36px;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
          margin-top: 16px;
          margin-bottom: 0;
        }
      }
    }
  }
  .knowledge-info {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 2px;
    border-radius: 5px;
    .item-inf {
      text-align: center;
      border: 1px solid #eaeaea;
      .text-number-of-curriculum {
        margin-top: 16px;
        font-weight: 700;
        font-size: 14px;
        line-height: 150%;
        text-align: center;
        color: #424242;
      }

      .item-inf-left {
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .number-curriculum {
          margin-bottom: 0;
          font-family: Lato;
          font-size: 36px;
          line-height: 160%;
          text-align: center;
          color: #08a3a5;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
        }
      }
      .item-inf-center {
        display: flex;
        align-items: center;
        justify-content: center;
        .border {
          transform: rotate(-150deg);
          height: 40px;
          width: 1px;
          background-color: #eaeaea;
        }
      }
      .item-inf-right {
        margin: 14px 0;
        .number-curriculum {
          font-family: Lato;
          font-size: 36px;
          text-align: center;
          color: #08a3a5;
          margin: 0;
        }
        .confirm-text {
          font-size: 14px;
          text-align: center;
          color: #2a2a2a;
          margin: 0;
        }
      }
    }
  }
  .wrap-basic-inf {
    .body {
      display: flex;
      justify-content: space-between;
      padding-right: 16px;
      align-items: center;
      .wrap-user {
        width: 40%;
        display: flex;
        padding: 30px 0 0 10px;
        .user-info {
          display: flex;
          flex-direction: column;
          margin-left: 40px;
          .mail-icon {
            color: #cccccc;
          }
          .mail-item {
            display: flex;
            align-items: center;
            margin-top: 13px;
            .mail-user {
              margin-left: 8px;
              font-family: Lato;
              font-size: 16px;
              color: #424242;
            }
          }
          .account-user {
            margin-top: 16px;
            font-family: Lato;
            font-size: 13px;
            color: #999999;
          }
          .divider {
            width: 20px;
            border: 0.5px solid #e0e0e0;
            margin-left: initial;
            margin-top: 32px;
          }
          .user-name {
            font-family: Lato;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: #bbbbbb;
          }
          .name-ja {
            margin-top: 16px;
            font-family: Lato;
            font-size: 24px;
            color: #2a2a2a;
          }
        }
        .avatar {
          .avatar-user {
            width: 180px;
            height: 180px;
            display: flex;
            flex-direction: column;
            background-color: #f0efef;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            .text-content {
              font-family: Lato;
              font-size: 16px;
              text-align: center;
              color: #bbbbbb;
            }
          }
          .icon-user {
            font-size: 78px;
            color: #cccccc;
          }
        }
        .text-help {
          width: 180px;
          font-size: 11px;
          line-height: 140%;
          text-align: center;
          color: #999999;
          padding-top: 12px;
        }
      }

      .form-right {
        width: 38%;
        height: 180px;
        display: flex;
        border-left: 1px solid #e0e0e0;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .form-right-btn {
          position: absolute;
          right: 10px;
          top: -10px;
          .bth-edit {
            width: 80px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #d9d9d9;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            cursor: pointer;
            .icon-edit {
              margin-right: 3px;
            }
          }
        }
        .form-right-item {
          width: 90%;
          word-break: break-word;
          margin-left: 32px;
          .profile-item {
            padding-top: 1px;
            padding-bottom: 1px;
            .profile-item-title {
              font-size: 14px;
              color: #777777;
            }
            .profile-item-content {
              font-size: 14px;
              color: #2a2a2a;
            }
          }
          .profile-input {
            margin-top: 3px;
            .text-label {
              margin-right: 5px;
            }
            .birth-place-label {
              display: flex;
              align-items: center;
              flex-direction: row;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .training-inf {
    display: flex;
    margin-top: 43px;
    margin-bottom: 12px;
    align-items: center;
    .tx-training-inf {
      font-weight: 400;
      font-size: 18px;
      color: #2a2a2a;
      margin: 0;
    }
  }
  .icon {
    color: #c4c4c4;
    font-size: 28px;
    margin-right: 10px;
  }
  .tab-complete-curriculum {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    border-radius: 5px;
    .tab-container {
      position: relative;
      .ant-tabs-nav {
        padding-top: 30px;
        z-index: 9;
        margin-left: 30px;
        position: ${(props) => (props.isEmptyDataCurriculum ? 'absolute' : 'unset')};
        top: -12px;
      }
      .ant-tabs-nav::before {
        border: none;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #08a3a5;
      }
    }
    .ant-table-wrapper {
      margin: 0;
    }
    .ant-tabs-tab:hover {
      color: #2a2a2a;
    }
    .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-tab {
      padding: 12px 30px;
    }
  }
  .table-knowledge-inf {
    padding: 0 15px;
    margin: 15px 0;
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          overflow-y: auto;
          max-height: calc(100vh - 306px);
        }
      }
    }
    .ant-table-cell {
      border-bottom: 6px solid #f9f8f8;
      padding: 10px 10px;
    }
    .ant-table-cell.item-receiver {
      display: revert;
    }
    .star {
      .yellow {
        color: #f6ac00;
      }
      .gray {
        color: #e1e1e1;
      }
    }
    .receiver {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: auto;
      border: 1px solid #dddddd;
      border-radius: 4px;
      padding: 5px 10px;
      .label {
        width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 11px;
        color: #777;
        .label-all {
          font-size: 14px;
          color: #424242;
        }
      }
      .text {
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        color: #424242;
      }
    }
    .time {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .number-like {
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        margin: 0;
        .like-icon {
          color: #08a3a5;
          font-size: 14px;
          padding-right: 5px;
        }
      }
      .label-time {
        font-family: Lato;
        font-size: 11px;
        color: #aaaaaa;
      }
      .detail {
        font-family: Lato;
        font-size: 11px;
        color: #424242;
      }
    }
    .item-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      .avatar {
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }
      .user-info {
        width: 100px;
        margin-left: 3px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .department-name {
          font-size: 11px;
          color: #999999;
        }
        .username {
          font-size: 12px;
          color: #424242;
        }
      }
    }
    .item-title {
      display: flex;
      flex-direction: column;
      .knowledge-text {
        display: inline-block;
        max-width: 450px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .item-recommend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      .knowledge-label {
        border: 1px solid #dddddd;
        border-radius: 20px;
        text-align: center;
        font-size: 11px;
        color: #424242;
        margin: 3px 0;
        width: 70px;
      }
      .qa-status-label {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        border-radius: 11px;
        font-weight: 700;
        font-size: 11px;
        color: #ffffff;
        width: 70px;
        .label {
          font-weight: 700;
        }
        .icon-status {
          margin-right: 5px;
        }
      }
      .extend-question {
        border: 1px solid #ed7200;
        background-color: #ed7200;
      }
      .recommendation-status-label {
        border-radius: 20px;
        text-align: center;
        font-size: 11px;
        color: #ffffff;
        margin: 3px 0;
        width: 70px;
        .label {
          font-weight: 700;
        }
        .icon {
          margin-right: 5px;
        }
      }
      .red {
        background-color: #f03060;
        border-color: #f03060;
      }
      .blue {
        background-color: #1fb9db;
        border-color: #1fb9db;
      }
    }
    .star {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &::before {
        position: absolute;
        content: '';
        width: 4px;
        height: 58px;
        border-radius: 2px;
        background: #d9d9d9;
        top: -20px;
        left: -5px;
      }
    }
    .border-blue::before {
      background: #1e7ed6;
    }
    .border-green::before {
      background: #0eaf58;
    }
  }
  .table {
    margin-bottom: 80px;
    .ant-table-pagination.ant-pagination {
      margin-right: 33px;
    }
    .flex {
      display: flex;
      align-items: center;
      .text-content {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        padding: 11px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: Lato;
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
        padding: 10px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        .icon {
          color: #c4c4c4;
          font-size: 16px;
          cursor: pointer;
          margin-right: 5px;
        }
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
    .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
  }
  .like-icon {
    font-size: 25px;
  }
  .tab-knowledge-inf {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 2px;
    border-radius: 5px;
    position: relative;
    .tab-container {
      .ant-tabs-nav {
        position: ${(props) => (props.isEmptyData ? 'absolute' : 'unset')};
        padding-top: 30px;
        top: -12px;
        z-index: 10;
        .ant-tabs-nav-wrap {
          margin-left: 30px;
        }
      }
    }
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #08a3a5;
  }
  .ant-tabs-tab:hover {
    color: #2a2a2a;
  }
  .ant-tabs-nav::before {
    border: none;
  }
  .ant-tabs-tab {
    padding: 12px 30px;
  }
  .description {
    position: relative;
    &::before {
      content: 'To';
      position: absolute;
      top: 10px;
      left: 12px;
      z-index: 10;
      font-size: 11px;
      width: 30px;
      height: 30px;
    }
    .caret-icon {
      font-size: 38px;
      color: #f0efef;
    }
  }
  .avt-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .label-name {
    font-size: 11px;
    color: #999999;
  }
  .name-user {
    font-size: 13px;
    color: #424242;
  }
  .label-account {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    color: #999999;
  }
  .account-name {
    font-weight: 700;
    font-size: 11px;
    color: #2a2a2a;
  }
  .wrap-rcm-blue {
    border-left: 4px solid #1e7ed6;
    height: 28px;
    align-items: center;
    display: flex;
    .recommend {
      font-size: 13px;
      color: #424242;
      margin-left: 5px;
    }
  }
  .wrap-rcm-green {
    border-left: 4px solid #0eaf58;
    height: 28px;
    align-items: center;
    display: flex;
    .recommend {
      font-size: 13px;
      color: #424242;
      margin-left: 5px;
    }
  }
  .wrap-rcm-gray {
    border-left: 4px solid #d9d9d9;
    height: 28px;
    align-items: center;
    display: flex;
    .recommend {
      font-size: 13px;
      color: #424242;
      margin-left: 5px;
    }
  }
  .answer-question-issues {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: #fff7f0;
    border: 1px solid rgba(237, 114, 0, 0.2);
    border-radius: 2px;
    color: #ed7200;
    .icon-issues {
      margin: 5px;
      font-size: 15px;
    }
  }
  .answer-question-message {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: #fff3f6;
    border: 1px solid rgba(240, 48, 96, 0.2);
    border-radius: 2px;
    color: #f03060;
    .icon-mess {
      margin: 5px;
      font-size: 15px;
    }
    .detail {
      font-style: normal;
      font-size: 12px;
      display: flex;
      align-items: center;
      color: #f03060;
      margin: 5px;
    }
  }
  .answer-question-check {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: #f5fdfd;
    border: 1px solid rgba(8, 163, 165, 0.2);
    border-radius: 2px;
    color: #159193;
    .icon-check {
      margin: 5px;
      font-size: 15px;
    }
    .detail {
      font-style: normal;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 5px;
    }
  }
  .time {
    display: flex;
    flex-direction: column;
    .number-like {
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      margin: 0;
      text-align: right;
      .like-icon {
        color: #08a3a5;
        font-size: 14px;
      }
    }
    .label-time {
      font-family: Lato;
      font-size: 11px;
      text-align: right;
      color: #aaaaaa;
    }
    .detail {
      font-family: Lato;
      font-size: 11px;
      text-align: right;
      color: #424242;
    }
  }
  .item-inf + .item-inf {
    border-left: none;
  }
  .item-inf + .row-item-inf {
    border-bottom: none;
  }
  .item-inf-left + .item-inf-right {
    border-right: none;
  }
  .row-item-inf {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
  }
  .item-comment {
    position: relative;
    .comment {
      font-size: 13px;
      color: #222222;
      background: #fffcf4;
      border-radius: 0 0 5px 5px;
      border: 1px solid #f4e6c6;
      position: relative;
      &:before {
        content: '';
        width: 20px;
        height: 20px;
        background-color: #fffcf4;
        margin-left: 10%;
        position: absolute;
        border: 1px solid #f4e6c6;
        transform: rotate(45deg);
        border-bottom: none;
        border-right: none;
        top: -10px;
      }
      .title-comment {
        font-family: Lato;
        font-size: 11px;
        color: #f6ac00;
        padding: 10px 0 0 10px;
      }
      .content {
        width: 100%;
        word-wrap: break-word;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        padding: 0 40px;
      }
    }
  }
  .star-icon {
    color: #f6ac00;
  }
  .all-account {
    font-weight: 700;
    font-size: 13px;
    line-height: 34px;
    display: flex;
    color: #2a2a2a;
    p {
      margin: 0;
    }
  }
  .star-yellow {
    color: #f6ac00;
  }
  .star-gray {
    color: #e1e1e1;
  }
  .ant-tabs-ink-bar {
    height: 5px;
    background: #08a3a5;
  }
  .header-knowledge-info {
    display: flex;
    margin-top: 41px;
    margin-left: 20px;
    margin-bottom: 12px;
    .icon-mess {
      color: #c4c4c4;
      font-size: 28px;
      margin-right: 10px;
    }
    .tx-knowledge-info {
      font-size: 18px;
      margin: 0;
      color: #2a2a2a;
    }
  }
`;
