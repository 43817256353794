import styled from 'styled-components';

interface Props {
  disableSetting: boolean | '' | 0 | undefined;
  collapsedMenu: boolean;
  dataCompany: string | undefined;
}

export const Wrapper = styled.div<Props>`
  display: flex;
  flex-direction: row;
  min-width: 1440px;
  overflow-x: auto;
  .ant-layout {
    background: #f9f8f8;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
  }
  .ant-layout-sider {
    position: relative;
    min-width: 0;
    background: #001529;
    position: sticky;
    transition: all 0.2s;
    top: 0;
    left: 0;
    height: 100vh;
    overflow-y: auto;
  }
  .sider {
    min-height: 100vh;
    background-color: #08a3a5;
    border-right: 1px solid #e5e5e5;
    font-family: unset;
    ::-webkit-scrollbar {
      display: none;
    }
    .wrap-logo {
      display: flex;
      justify-content: center;
      user-select: none;
      background: #ffffff;
      .image-logo {
        margin: 10px 0 68px 0;
        cursor: pointer;
      }
      .image-logo-large {
        width: 100px;
        height: 84px;
        margin: 8px auto 46px;
        transition: width 0.3s;
        cursor: pointer;
      }
    }
    .site-layout-background {
      display: flex;
      justify-content: flex-end;
      padding: 10px 10px 0 10px;
      color: rgba(0, 0, 0, 0.85);
      background-color: #ffffff;
      color: #cccccc;
      .trigger {
        font-size: 20px;
      }
      .anticon-menu-unfold.trigger {
        display: block;
        margin: 0 auto;
      }
    }
    .ant-menu {
      margin-top: 16px;
      font-size: 12px;
      border-right: unset;
      background-color: #08a3a5;
      .ant-menu-item,
      .ant-menu-submenu {
        margin: 0;
        margin-bottom: 8px;
        color: #ffffff;
        font-weight: 400;
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.5);
        }
        .title-submenu {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          svg {
            width: 10px;
            height: 10px;
          }
        }
        .lable-official {
          line-height: 14px;
          margin: 0;
          font-size: 12px;
        }
        .official-text {
          width: 80%;
          display: grid;
          line-height: 20px;
        }
        &:hover {
          font-weight: 700;
          .anticon {
            color: #ffffff;
          }
        }
        .ant-menu-item-icon {
          width: 15%;
          font-size: 18px !important;
          min-width: 16px !important;
        }
      }

      .ant-menu-item-active,
      .ant-menu-submenu-active {
        .ant-menu-submenu-title {
          color: #ffffff;
          font-weight: 700;
        }
        .anticon {
          color: #ffffff;
        }
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
      }

      .ant-menu-item-selected {
        background-color: rgba(233, 224, 203, 0.15);
        font-weight: 700;
        .anticon,
        .ant-menu-submenu-arrow {
          color: #ffffff;
        }
        &::after {
          border-left: 4px solid #f6ac00;
          border-right: unset;
          left: 0;
        }
      }

      .ant-menu-submenu-selected {
        font-weight: 700;
        .ant-menu-submenu-title {
          .anticon,
          .ant-menu-submenu-arrow {
            color: #ffffff;
          }
          &:after {
            border-left: 4px solid #f6ac00;
            border-right: unset;
            left: 0;
          }
        }
      }

      .sub-menu-setting {
        display: ${(props) =>
          props.disableSetting ||
          props.dataCompany === 'standard' ||
          props.dataCompany === 'premium'
            ? 'flex'
            : 'none'};
        .ant-menu-item-group-title {
          padding: 0 34px 0 18px;
          font-weight: 700;
          color: #ffffff;
          font-size: 12px;
          .anticon {
            font-size: 18px;
            vertical-align: middle;
            margin-top: -2px;
          }
        }
        .icon {
          margin-right: 11.67px;
        }
      }
      .submenu-setting {
        padding-left: 4px;
      }

      .collapse {
        .ant-menu-title-content {
          color: white !important;
        }
      }
    }
    .ant-menu-item-only-child .icon-menu-collapse {
      width: 16px !important;
    }
    .ant-menu-item .icon-menu-left {
      width: 16px !important;
      color: rgba(255, 255, 255, 0.5) !important;
      opacity: 0.6;
    }
    .ant-menu .ant-menu-item:hover,
    .ant-menu-item .icon-menu-left:hover {
      opacity: 1;
    }
    .ant-menu-item-selected > img {
      opacity: 1 !important;
      color: ${(props) => (props.collapsedMenu ? '#424242' : '')} !important;
    }
    .ant-menu-inline-collapsed {
      .ant-menu-item,
      .ant-menu-submenu {
        .anticon,
        .ant-menu-submenu-arrow {
          color: rgba(255, 255, 255, 0.8);
        }
      }

      .ant-menu-item-selected {
        &::after {
          transform: scaleY(1);
          opacity: 1;
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
            opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
          content: '';
        }
        .ant-menu-submenu-title {
          &::after {
            transform: scaleY(1);
            opacity: 1;
            background-color: rgba(233, 224, 203, 0.15);
          }
        }
      }
    }
  }
`;

export const StyleMenuTooltip = styled.div`
   {
    display: grid;
    width: 175px;
    font-family: Lato;
    font-size: 12px;
    line-height: 14px;
    padding-top: 8px;
    .title {
      font-weight: 700;
      color: #fff;
    }
    .sub-menu {
      font-weight: 400;
      color: #ccc;
      cursor: pointer;
    }
    .sub-menu:hover {
      color: #fff;
    }
    .sub-menu-active {
      color: #fff;
    }
    .sub-menu-active-training {
      padding-left: 16px;
      cursor: pointer;
      font-weight: 400;
      color: #fff;
    }
    .sub-menu-training {
      padding-left: 16px;
      cursor: pointer;
      font-weight: 400;
      color: #ccc;
    }
    .sub-menu-training:hover {
      color: #fff;
    }
    .training {
      .title-training {
        padding-top: 8px;
      }
    }
    .dropdown-content {
      display: none;
    }
    .training:hover .dropdown-content {
      display: block;
    }
  }
`;
