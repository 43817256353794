import React, { useEffect, useState } from 'react';
import { differenceBy, flatten, maxBy } from 'lodash';
import { Avatar, Modal, Select, Button } from 'antd';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getSubPosition } from 'pages/Settings/Employee/thunk';
import { authSelector } from 'containers/Auth/selectors';
import { affiliationSelector } from '../../selectors';
import { SectionStyled } from './styles';
import { SpinLoading } from 'components';
import { useAppDispatch } from 'hooks';
import { adminAvatar } from 'assets';
import * as Types from 'types';
import {
  createLinkAffiliationLevel,
  createLinkAffiliationRole,
  getAffiliations,
} from '../../thunk';

const { Option } = Select;

interface Props {
  visible: boolean;
  data: Types.TreeItem<Types.AffiliationItemType>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConcurrentAdditionalModal: React.FC<Props> = ({ visible, setVisible, data }) => {
  const [subPosition, setSubPosition] = useState<Types.AffiliationAssignRole.ResponseType[]>([]);
  const [positionMain, setPositionMain] = useState<Types.AffiliationAssignRole.ResponseType[]>([]);
  const [userSelected, setUserSelected] = useState<Types.EmployeeUser.ResponseType>();
  const [usersData, setUsersData] = useState<Types.EmployeeUser.ResponseType[]>([]);
  const [positionCode, setPositionCode] = useState<string>();

  const { userList, positionSelect } = useSelector(affiliationSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleToggleSelectedUser = async (user: Types.EmployeeUser.ResponseType) => {
    if (userSelected?.i_id !== user.i_id) {
      dispatch(startLoading());
      setUserSelected(user);
      const resultAction = await dispatch(
        getSubPosition({
          conditions: [
            {
              id: 'company_id',
              search_value: [user.company_id],
            },
            {
              id: 'login_id',
              search_value: [user.login_id],
              exact_match: true,
            },
            {
              id: 'main_role',
              search_value: ['main'],
              not_match: true,
            },
          ],
          sort_fields: [
            {
              id: 'sort_order',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
      const resultActionMain = await dispatch(
        getSubPosition({
          conditions: [
            {
              id: 'company_id',
              search_value: [user.company_id],
            },
            {
              id: 'login_id',
              search_value: [user.login_id],
              exact_match: true,
            },
            {
              id: 'main_role',
              search_value: ['main'],
            },
          ],
          sort_fields: [
            {
              id: 'sort_order',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
      if (getSubPosition.fulfilled.match(resultAction)) {
        setSubPosition(resultAction.payload.items);
      }
      if (getSubPosition.fulfilled.match(resultActionMain)) {
        setPositionMain(resultActionMain.payload.items);
      }
      dispatch(stopLoading());
    } else {
      setUserSelected(undefined);
    }
  };

  const onSubmit = async () => {
    dispatch(startLoading());
    await Promise.all([
      dispatch(
        createLinkAffiliationLevel({
          item: {
            company_id: userInfo?.company_id,
            affiliation_id: data.affiliation_id,
            login_id: userSelected?.login_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
            sort_order:
              (maxBy(
                flatten(data.user_children?.map((item) => item.sub_positions)),
                'user_sort_order'
              )?.user_sort_order || 0) + 1,
          },
        })
      ),
      dispatch(
        createLinkAffiliationRole({
          item: {
            company_id: userInfo?.company_id,
            affiliation_id: data.affiliation_i_id,
            login_id: userSelected?.login_id,
            positions_code: positionCode,
            createdat: new Date(),
            createdby: userInfo?.login_id,
            sort_order:
              (maxBy(
                flatten(data.user_children?.map((item) => item.sub_positions)),
                'user_role_order'
              )?.user_role_order || 1) + 1,
          },
        })
      ),
    ]);
    await dispatch(
      getAffiliations({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
      })
    );
    dispatch(stopLoading());
    onClose();
  };

  const onClose = () => {
    setPositionCode(undefined);
    setUserSelected(undefined);
    setVisible(false);
  };

  useEffect(() => {
    if (visible && data.user_children) {
      setUsersData(differenceBy(userList, data.user_children, 'i_id'));
    }
  }, [visible, data.user_children, userList]);

  return (
    <Modal
      open={visible}
      footer={null}
      closable={false}
      width={860}
      className="modal-concurrent-additional"
      onCancel={onClose}
    >
      <SectionStyled>
        <div className="modal">
          <div className="header">
            <span className="title">
              {data.name}
              <small className="small-text">（ {data.affiliation_id} ）</small>
              兼務ユーザー追加
            </span>
          </div>
          <div className="section">
            <div className="section-item section-item-left">
              <div className="label-number">01</div>
              <div className="label-text">ユーザー検索</div>
              <div />
            </div>
            <div className="section-item section-item-right">
              <div className="label-number">02</div>
              <div className="label-text">選択ユーザー情報</div>
              <div />
            </div>
          </div>
          <div className="body">
            <div className="user-search">
              <p className="text-content">
                ユーザーを検索し、クリックで選択してください。
                <br />
                選択したユーザー情報が右に表示されます。
              </p>
              <div className="form">
                <Select
                  showSearch
                  allowClear
                  className="input-select"
                  placeholder="フリーワード検索"
                  onSelect={(value: string) =>
                    setUsersData((prevState) =>
                      prevState.filter((user) => user.name?.includes(value))
                    )
                  }
                  onClear={() => {
                    setUsersData(
                      data.user_children
                        ? differenceBy(userList, data.user_children, 'i_id')
                        : userList
                    );
                  }}
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {usersData?.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <p className="text-note">※検索結果はメイン所属部署のみ表示しています。</p>
              <div className="wrap-list-user">
                {usersData?.map((user, index) => (
                  <div
                    className={`item-user ${user.i_id === userSelected?.i_id ? 'active' : ''}`}
                    key={user.i_id}
                    onClick={() => handleToggleSelectedUser(user)}
                  >
                    <Avatar
                      src={user.icon_file ? user.icon_file : adminAvatar}
                      size={40}
                      className="avatar"
                    />
                    <p className="name">{user.name}</p>
                    <p className="department_name">
                      {user.department_name.replace(/^\d+ /, '')} <br />
                      {user.position_code.replace(/^\d+ /, '')}
                    </p>
                  </div>
                ))}
              </div>
              <button className="btn btn-cancel" onClick={onClose}>
                キャンセル
              </button>
            </div>
            <div className="selected-user">
              <p className="text-content">
                追加先の役職を選択して、兼務ユーザー追加ボタンを
                <br />
                クリックしてください。兼務ユーザーとして登録されます。
              </p>
              <SpinLoading size="large" loading={loading}>
                <div className={`wrap-info${userSelected ? ' j-start' : ''}`}>
                  {!userSelected ? (
                    <p className="text-not-selected">ユーザーを選択してください</p>
                  ) : (
                    <div className="item-select">
                      <Avatar
                        src={userSelected.icon_file ? userSelected.icon_file : adminAvatar}
                        className="avatar"
                        alt="avatar"
                      />
                      <p className="name">{userSelected.name}</p>
                      <div className="main">
                        <span className="label">メイン</span>
                        <div className="wrap-item">
                          <span className="text-item">
                            {positionMain[0]?.affiliation_id?.replace(/^\d+ /, '')} /{' '}
                            {positionMain[0]?.positions_code?.replace(/^\d+ /, '')}
                          </span>
                        </div>
                      </div>
                      <div className="concurrent-post">
                        <span className="label">兼務</span>
                        <div className="wrap-item">
                          {subPosition?.map((item, index) => (
                            <p className="text-item" key={index}>
                              <span className="text-number">{index + 1}</span>
                              {item.affiliation_id?.replace(/^\d+ /, '')} /{' '}
                              {item.positions_code?.replace(/^\d+ /, '')}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </SpinLoading>
              <div className="wrap-select-sub-position">
                <span className="label-form">追加先役職選択</span>
                <span className="required">*</span>
                <Select
                  allowClear
                  value={positionCode}
                  className="input-select"
                  placeholder="フリーワード検索"
                  onChange={setPositionCode}
                >
                  {positionSelect
                    ?.filter(
                      (position) =>
                        !subPosition.some(
                          (subP) => subP.lookup_items?.positions_code?.i_id === position.i_id
                        )
                    )
                    .map((position, index) => (
                      <Option key={index} value={position.i_id}>
                        {position.name}
                      </Option>
                    ))}
                </Select>
                <Button
                  disabled={!userSelected || !positionCode}
                  className={`btn ${userSelected && positionCode ? 'btn-active' : 'btn-disabled'}`}
                  onClick={onSubmit}
                  loading={loading}
                >
                  兼務ユーザー追加
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ConcurrentAdditionalModal;
