import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import zipcodeJa from 'zipcode-ja';

import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { administratorMasterSchema } from 'libs/validations';
import { administratorMasterSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import { getUserSearch, getCompanies } from './thunk';
import ConfirmAdministratorMaster from './Confirm';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { Header, Input } from 'components';
import { Wrapper } from './styles';

const AdministratorMaster: React.FC = () => {
  const [nextScreen, setNextScreen] = useState<boolean>(false);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { information } = useSelector(administratorMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { messages } = useIntl();

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setInformationRegister(values));
      toggleScreen();
      setSubmitting(false);
    },
  });

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as number]?.address;
      formik.setFieldValue('prefecture', address ? address[0] : '');
      formik.setFieldValue('address', address ? `${address[1]} ${address[2]}` : '');
      if (address) {
        formik.setErrors({
          prefecture: undefined,
          address: undefined,
        });
        formik.setFieldTouched('prefecture', false);
        formik.setFieldTouched('address', false);
      }
    }
  };

  const toggleScreen = () => setNextScreen((prevState) => !prevState);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getUserSearch({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCompanies({
            conditions: [
              {
                id: 'id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 1,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      {nextScreen ? (
        <ConfirmAdministratorMaster goBack={toggleScreen} />
      ) : (
        <div className="container">
          <div className="description">
            <p>
              管理者情報の確認・編集を行う画面です。
              <br />
              編集完了後に確認画面へボタンをクリックしてください。
            </p>
          </div>
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '25%',
              }}
              colon={false}
            >
              <div className="form">
                <Form.Item
                  name="plan_name"
                  label={<span className="text-label">利用プラン</span>}
                  className="form-input"
                >
                  <Input name="plan_name" className="input__disabled" type="text" disabled />
                </Form.Item>
                <Form.Item
                  name="number_of_users"
                  label={<span className="text-label">利用ユーザー数</span>}
                  className="form-input wrap-number-users"
                >
                  <Input
                    name="number_of_users"
                    className="input__disabled number-users"
                    type="number"
                    inputMode="numeric"
                    disabled
                  />
                  <span className="suffix">人</span>
                </Form.Item>
                <Form.Item
                  name="name"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-7']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="name_furigana"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-8']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="name_furigana"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="postal_code"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-9']}
                      <span className="require">*</span>
                    </span>
                  }
                  className={`form-input wrap-input-zip-code ${
                    formik.touched.postal_code && formik.errors.postal_code
                      ? 'error-input-zip-code'
                      : ''
                  }`}
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="postal_code"
                    className="input"
                    type="text"
                    placeholder="数字：７文字（ハイフンなし）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.postal_code!.toString().length > 6
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <button
                    type="button"
                    className="btn-check-zipCode"
                    onClick={convertZipCodeToAddress}
                  >
                    {messages['AJ-02-10']}
                  </button>
                </Form.Item>
                <Form.Item
                  name="prefecture"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-11']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="prefecture"
                    type="text"
                    placeholder="全角：最大100文字"
                    className="input"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-12']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="address"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="plot_number"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-13']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="plot_number"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="building_name"
                  label={<span className="text-label">{messages['AJ-02-14']}</span>}
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="building_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-15']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_name"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name_furigana"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-16']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_name_furigana"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_department"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-17']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_department"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_position"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-18']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_position"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_phone"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-19']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_phone"
                    className="input"
                    type="text"
                    placeholder="数字：最大11文字（ハイフン無し）"
                    onKeyPress={(e) => {
                      if (
                        isNaN(parseInt(e.key)) ||
                        formik.values.admin_phone!.toString().length > 10
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-20']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_email"
                    className="input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email_confirm"
                  label={
                    <span className="text-label">
                      管理者メールアドレス（確認)<span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={permissionNumber === 1}
                    name="admin_email_confirm"
                    className="input"
                    type="text"
                  />
                </Form.Item>
              </div>
              <div className="wrap-button">
                <SubmitButton
                  disabled={permissionNumber === 1}
                  className={permissionNumber === 1 ? 'disabled' : 'btn-submit'}
                >
                  {messages['AJ-02-24']}
                </SubmitButton>
              </div>
            </Form>
          </FormikProvider>
        </div>
      )}
    </Wrapper>
  );
};

export default AdministratorMaster;
