import BMPRenderer from './bmp';
import HTMLRenderer from './html';
import JPGRenderer from './jpg';
import MSDocRenderer from './msdoc';
import PDFRenderer from './pdf';
import PNGRenderer from './png';
import TXTRenderer from './txt';
import GIFRenderer from './gif';
import MP4Renderer from './mp4';

export const DocViewerRenderers = [
  BMPRenderer,
  HTMLRenderer,
  JPGRenderer,
  MSDocRenderer,
  PDFRenderer,
  PNGRenderer,
  TXTRenderer,
  GIFRenderer,
  MP4Renderer,
];
