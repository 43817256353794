import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'rgba(221, 221, 221, 1)',
    padding: 26,
    fontFamily: 'NotosanJP',
  },
  container: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    padding: '0 48px',
    width: '100%',
  },
  receiptInfo: {
    position: 'relative',
    margin: '30px 0',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    color: '#000000',
    margin: 0,
  },
  info: {
    position: 'absolute',
    top: 0,
    right: 0,
    fontSize: 12,
    textAlign: 'left',
    color: '#000000',
  },
  sent: {
    display: 'flex',
    width: '70%',
    marginBottom: 30,
    paddingBottom: 10,
    borderBottom: '1px solid #000000',
    fontSize: 18,
    color: '#000000',
    margin: 0,
  },
  amountMoney: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    borderRadius: 2,
    backgroundColor: '#eaf2f9',
    marginTop: 20,
  },
  label: {
    margin: 0,
    fontSize: 15,
    color: '#000000',
  },
  labelNumber: {
    margin: 0,
    fontSize: 33,
    textAlign: 'center',
    color: '#000000',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '20px 0',
  },
  leftSide: {
    width: '120%',
    marginRight: '5%',
  },
  textLabel: {
    fontSize: 11,
    color: '#000000',
  },
  paymentMethod: {
    marginTop: 16,
    marginBottom: 16,
    fontSize: 11,
    color: '#000000',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderBottom: '1px solid #000000',
    padding: '5px 0',
  },
  labelSubLeft: {
    margin: 0,
    fontSize: '11px',
    color: '#000000',
  },
  value: {
    margin: 0,
    fontSize: 15,
    color: '#000000',
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    position: 'relative',
    fontSize: '11px',
    color: '#000000',
    margin: 0,
  },
  logo: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
});
