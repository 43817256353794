import React, { useCallback, useEffect, useState } from 'react';
import { UploadFile, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import dayjs from 'dayjs';
import {
  CalendarOutlined,
  CaretRightOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FileTextOutlined,
} from '@ant-design/icons';

import { IconEdit, IconEmployee, IconInterview, IconLocked, IconPublish } from 'assets';
import { deletedFileInMinIO, sharedFileInMinIO } from 'services/minioService';
import { skillCheckSelector } from 'pages/Settings/SkillCheck/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { convertFileResponse } from 'libs/utils/question';
import { useAppDispatch, usePermission } from 'hooks';
import { Button, Wrapper } from './styles';
import * as Types from 'types';
import {
  DuplicateSkillCheck,
  PopupConfirmPublish,
  ModalCreateEdit,
} from 'pages/Settings/SkillCheck/Modal';
import {
  deleteLinkSkillCheckAssignQuestion,
  deleteUserAssignSkillCheck,
  deleteSkillCheck,
  updateSkillCheck,
  deleteUserAssignSkillCheckByCondition,
} from 'pages/Settings/SkillCheck/thunk';

const TooltipQuestionSetting: React.FC<{
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSwitch: (value: boolean) => void;
  fetchDataUserSkillCheck: () => void;
  data: Types.SkillCheckTreeViewType;
  fetchDataSkillCheck: () => void;
  itemSelected: boolean;
}> = ({
  fetchDataUserSkillCheck,
  fetchDataSkillCheck,
  itemSelected,
  handleSwitch,
  setVisible,
  data,
}) => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [openPopupConfirmPublish, setOpenPopupConfirmPublish] = useState<boolean>(false);
  const [isOpenModalDuplicate, setOpenModalDuplicate] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFileAntd<File>>();
  const [openModalCreateEdit, setOpenModalCreateEdit] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
  }>({ visible: false, type: 'edit' });

  const { time_limit } = useSelector(skillCheckSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const handlePublishItem = useCallback(async () => {
    dispatch(startLoading());
    await dispatch(
      updateSkillCheck({
        id: data.i_id,
        data: {
          item: {
            publish: data.publish ? 0 : 1,
          },
          is_force_update: true,
          return_item_result: true,
        },
      })
    );
    setOpenPopupConfirmPublish(false);
    setVisible(false);
    fetchDataSkillCheck();
    dispatch(stopLoading());
  }, [data.i_id, data.publish, dispatch, fetchDataSkillCheck, setVisible]);

  const handleDeleteSkillcheck = async () => {
    dispatch(startLoading());

    if (data.fileID) {
      deletedFileInMinIO(data.fileID);
    }

    const resultAction = await Promise.all([
      dispatch(
        deleteSkillCheck({
          id: data.i_id,
        })
      ),
      ...data.children.map((question) =>
        dispatch(
          deleteLinkSkillCheckAssignQuestion({
            id: question.i_id,
          })
        )
      ),
      ...data.user_children.flatMap((department) =>
        department.children?.map((user) =>
          dispatch(
            deleteUserAssignSkillCheck({
              id: user.i_id!,
            })
          )
        )
      ),
    ]);
    if (deleteSkillCheck.fulfilled.match(resultAction[0])) {
      dispatch(
        deleteUserAssignSkillCheckByCondition({
          conditions: [
            {
              id: 'skill_check_code',
              search_value: [data.skill_check_code],
            },
          ],
          use_display_id: true,
        })
      );
    }
    setOpenModalConfirmDeleteItem(false);
    setVisible(false);
    fetchDataSkillCheck();
    fetchDataUserSkillCheck();
    dispatch(stopLoading());
  };

  useEffect(() => {
    (async () => {
      const fileQuestion: UploadFile<File> = { uid: '', name: '' };
      dispatch(startLoading());
      if (data.fileID) {
        const fileName = extractFileName(data.fileID);
        const nodeFileUrl = await sharedFileInMinIO(data.fileID);
        const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
        Object.assign(
          fileQuestion,
          convertFileResponse({
            file: fileFromUrl,
            fileID: data.fileID,
            fileName: fileName,
          })
        );
        setFiles(fileQuestion);
        // const resultActionGetAttach = await dispatch(
        //   getAttachQuestionFile({
        //     conditions: [
        //       {
        //         id: 'fileID',
        //         search_value: [data.fileID],
        //       },
        //     ],
        //     page: 1,
        //     per_page: 1,
        //   })
        // );
        // if (
        //   getAttachQuestionFile.fulfilled.match(resultActionGetAttach) &&
        //   resultActionGetAttach.payload.items[0]?.file
        // ) {
        //   const fileAction = await dispatch(
        //     getFileAttach({ file_id: resultActionGetAttach.payload.items[0].file })
        //   );
        //   if (getFileAttach.fulfilled.match(fileAction)) {
        //     Object.assign(
        //       fileQuestion,
        //       convertFileResponse({
        //         file: fileAction.payload,
        //         fileID: resultActionGetAttach.payload.items[0]?.fileID,
        //         fileName: resultActionGetAttach.payload.items[0]?.filename,
        //       })
        //     );
        //   }
        // }
      }
      dispatch(stopLoading());
    })();
  }, [data.fileID, dispatch]);

  return (
    <Wrapper>
      <div className="rowWrapper bg-blue">
        <div className="rowContentHead">
          <div className="spanHead">
            {data.publish ? (
              <>
                <img src={IconPublish} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : (
              <>
                <img src={IconLocked} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          <div className="spanHead">
            {data.user_type === 'member' ? (
              <>
                <img src={IconEmployee} className="icon" alt="employee-icon" />
                <span className="spanText">社内</span>
              </>
            ) : (
              <>
                <img src={IconInterview} className="icon" alt="interview-icon" />
                <span className="spanText">面接</span>
              </>
            )}
          </div>
          <div className="spanHead">
            {data.implementation_settings === 2 && (
              <>
                <img src={IconEdit} className="icon" alt="edit-icon" />
                <span className="spanText">実施可</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="rowWrapper">
        {files && (
          <div className="item-image">
            <img
              src={URL.createObjectURL(new Blob([files.originFileObj as Blob]))}
              className="image"
              alt={files.name}
            />
          </div>
        )}
        <p className="text-content">{data.description}</p>

        <div className="rowContent">
          <Button
            permission={permissionNumber}
            disabled={permissionNumber === 1}
            onClick={() => setOpenModalDuplicate(true)}
          >
            + コピー追加
          </Button>
          {!data.publish && (
            <div className="icons">
              <EditOutlined
                className="icon"
                onClick={() =>
                  permissionNumber !== 1 &&
                  setOpenModalCreateEdit({
                    visible: true,
                    type: 'edit',
                  })
                }
              />
              <DeleteOutlined
                className="icon"
                onClick={() => permissionNumber !== 1 && setOpenModalConfirmDeleteItem(true)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="container">
        <div className="flex">
          <div className="time-limit">
            <p className="label">
              <ClockCircleOutlined className="icon" />
              制限時間：
            </p>
            <p className="content">{time_limit[data.question_time_limit - 1]?.name}</p>
          </div>
          <div className="question-count">
            <p className="label">
              <FileTextOutlined className="icon" />
              設問数：
            </p>
            <p className="content">{data.children.length || 0}問</p>
          </div>
        </div>
        <div className="period">
          <p className="label">
            <CalendarOutlined className="icon" />
            実施期間：
            {`${
              data.implementation_settings === 2
                ? '実施可'
                : data.implementation_settings === 3
                ? '実施不可'
                : ''
            }`}
          </p>
          <p className="content">
            {data.start_period && dayjs(data.start_period).format('YYYY/MM/DD HH:mm')}
            {(data.start_period || data.end_period) && <span>〜</span>} <br />
            {data.end_period && dayjs(data.end_period).format('YYYY/MM/DD HH:mm')}
          </p>
        </div>
        <div className="period">
          <p className="label">分析グループ：</p>
          <p className="content">{data.grouping_code || ''}</p>
        </div>
      </div>
      <div className={`rowWrapper ${data.publish ? 'blGreen' : 'blViolet'}`}>
        <div
          className="rowContent"
          onClick={() => permissionNumber !== 1 && setOpenPopupConfirmPublish(true)}
        >
          <span className="text-content">
            {data.publish ? 'スキルチェックを編集する' : 'スキルチェックを公開する'}
          </span>
          <div className="icons">
            {data.publish ? (
              <img src={IconPublish} alt="publish-icon" />
            ) : (
              <img src={IconLocked} alt="edit-icon" />
            )}
            <CaretRightOutlined className="caret-icon" />
            {data.publish ? (
              <img src={IconLocked} alt="edit-icon" />
            ) : (
              <img src={IconPublish} alt="publish-icon" />
            )}
          </div>
        </div>
      </div>
      {!data.publish && (
        <div className="rowWrapper blBlue">
          <div className="rowContent">
            <span className="text-content">設問追加先に設定</span>
            <Switch
              disabled={permissionNumber === 1}
              checked={itemSelected}
              size="small"
              onChange={(value) => {
                handleSwitch(value);
                setVisible(false);
              }}
            />
          </div>
        </div>
      )}
      <DuplicateSkillCheck
        data={data}
        visible={isOpenModalDuplicate}
        setVisible={setOpenModalDuplicate}
        setVisibleTooltip={setVisible}
      />
      <ModalCreateEdit
        skillCheck={data}
        dataImage={files}
        setOpenModal={setOpenModalCreateEdit}
        fetchDataSkillCheck={fetchDataSkillCheck}
        openModalCreateEdit={openModalCreateEdit}
        fetchDataUserSkillCheck={fetchDataUserSkillCheck}
        setFiles={setFiles}
      />
      <PopupConfirmPublish
        onSubmit={handlePublishItem}
        nodeLocked={!!data.publish}
        visible={openPopupConfirmPublish}
        setVisible={setOpenPopupConfirmPublish}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します。"
        onSubmit={handleDeleteSkillcheck}
        visible={openModalConfirmDeleteItem}
        setVisible={setOpenModalConfirmDeleteItem}
        description={
          <>
            データの削除を実行すると、復元できませんのでご注意ください。
            <br />
            <span style={{ color: 'red' }}>※ただし、設問は削除されません</span>
          </>
        }
      />
    </Wrapper>
  );
};

export default TooltipQuestionSetting;
