import styled from 'styled-components';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
  showButton: boolean;
}

export default styled.div<Props>`
  width: 100%;
  form {
    height: calc(100vh - ${(props) => (props.showButton ? 151 : 56)}px);
    overflow-y: scroll;
  }
  .file-info {
    background: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
      display: flex;
      .icon-status {
        margin-right: 10px;
      }
      .wrap-info {
        .info {
          display: flex;
          align-items: center;
          .file-name {
            font-size: 20px;
            margin-left: 5px;
          }
          .icon-excel {
            color: #107c41;
            font-size: 24px;
          }
          .icon-word {
            color: #185abd;
            font-size: 24px;
          }
          .icon-ppt {
            color: #c43f1b;
            font-size: 24px;
          }
          .icon-pdf {
            color: #b30b00;
            font-size: 24px;
          }
          .icon-image {
            color: #cc9f00;
            font-size: 24px;
          }
          .icon-play {
            color: #5c5b61;
            font-size: 24px;
          }
          .icon-file {
            color: #777777;
            font-size: 24px;
          }
        }
        .description {
          font-size: 12px;
          color: #777777;
          margin: 0;
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      .version {
        color: #999999;
        font-size: 10px;
        margin-right: 15px;
      }
      .btn {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f6ac00;
        font-size: 13px;
        &-edit {
          background: #f6ac00;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .btn-close {
        background: unset;
        border: unset;
        box-shadow: unset;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        .close-up {
          font-size: 13px;
          color: #777777;
        }
        .close-icon {
          color: #777777;
          margin: 0 10px;
        }
      }
    }
  }
  .while-editing {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;
    padding: 10px;
    .wrap-input {
      width: 96.5%;
      .wrap-input-version {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        .ant-form-item {
          margin-bottom: 0;
        }
        .title {
          width: 70%;
          .ant-form-item-label {
            display: flex;
            justify-content: center;
            align-items: center;
            .icon-excel {
              color: #107c41;
              font-size: 40px;
            }
            .icon-word {
              color: #185abd;
              font-size: 40px;
            }
            .icon-ppt {
              color: #c43f1b;
              font-size: 40px;
            }
            .icon-pdf {
              color: #b30b00;
              font-size: 40px;
            }
            .icon-image {
              color: #cc9f00;
              font-size: 40px;
            }
            .icon-play {
              color: #5c5b61;
              font-size: 40px;
            }
            .icon-file {
              color: #777777;
              font-size: 40px;
            }
            .logo-label {
              height: 32px;
              padding-left: 10px;
            }
            label::after {
              content: '';
            }
          }
          .ant-input-suffix {
            display: none;
          }
          &_error {
            .ant-form-item-control {
              .ant-input-affix-wrapper {
                background: #fffaf9 !important;
                border-color: #ff4d4f;
                input {
                  background: #fffaf9 !important;
                }
              }
            }
          }
        }
        .version .ant-form-item-label {
          display: flex;
          align-items: center;
        }
      }
      .description {
        margin-bottom: 0;
        .ant-form-item-label {
          width: 50px;
          label::after {
            content: '';
          }
        }
        textarea {
          resize: vertical;
        }
      }
    }
  }
  .file-name {
    flex: 1;
    text-align: left;
    color: #000;
    font-weight: bold;
    padding: 10px 10px;
    overflow: hidden;
    background-color: white;
  }
  .wrap-view {
    height: 100vh;
    .item-video {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .wrap-video {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
        width: 640px;
        height: 340px;
        background-color: #f9f8f8;
        background-size: 20px 20px;
        background-position: 0 0, 0 10px, 10px -10px, -10px 0;
        .video {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  .wrap-submit {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .wrap-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      .btn {
        border-radius: 5px;
        border: none;
        width: 30%;
        height: 40px;
        cursor: pointer;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        &_submit {
          color: #ffffff;
          background-color: #f6ac00;
        }
        &_close {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          color: #777777;
        }
        &_outline {
          background-color: #ffffff;
          border: 1px solid #f6ac00;
          color: #f1a900;
        }
      }
    }
  }
  .wrap-btn-zoom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    background-color: #f4f4f4;
    .number-scale {
      font-size: 13px;
      color: #2a2a2a;
      padding-right: 5px;
    }
    .zoom {
      color: #2a2a2a;
      border: none;
      background-color: #f4f4f4;
      padding: 0 10px;
    }
  }
  .wrap-image {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .background {
      background-color: #dddddd;
      overflow: hidden;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .item-image {
        width: 640px;
        height: 360px;
      }
    }
  }
`;
