import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Menu, Dropdown } from 'antd';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import {
  DownOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FolderOpenFilled,
  LinkOutlined,
  LockOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { changeNodeAtID, checkExistItem } from 'libs/utils/explorer/tree-data-utils';
import { setActiveFolder, setManualFolderList } from 'pages/Manual/slice';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { FolderPermissionSetting } from 'pages/Manual/Modal';
import AlertPermission from 'pages/Manual/AlertPermission';
import { authSelector } from 'containers/Auth/selectors';
import { contextMenuRef } from 'components/ContextMenu';
import { manualSelector } from 'pages/Manual/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import { alertCustomRef } from 'components/Alert';
import { NodeContentWrapper } from './styles';
import { FileExplorer } from 'types';
import { Input } from 'components';
import {
  createNewFolder,
  getListFolderPermission,
  getManualFolder,
  setFolderPermission,
  updateFolder,
} from 'pages/Manual/thunk';

const scaffoldBlockPxWidth = 30;

interface Props {
  treeIndex: number;
  listIndex: number;
  scaleNum: number;
  disabled?: boolean;
  lowerSiblingMax?: number;
  node: FileExplorer.TreeItem;
  lowerSiblingCounts: number[];
  parentNode?: FileExplorer.TreeItem;
  clickChildrenNode: (i_id: string) => void;
  resizeRef?: React.RefObject<HTMLDivElement>;
  toggleChildrenVisibility: (i_id: string) => void;
  handleMoveFolder?: (dragItem: FileExplorer.TreeItem, dropItem: FileExplorer.TreeItem) => void;
  handleMoveFile?: (dragItem: FileExplorer.DataTableType, dropItem: FileExplorer.TreeItem) => void;
}

export const NodeContentRenderer: React.FC<Props> = ({
  node,
  disabled,
  treeIndex,
  listIndex,
  scaleNum,
  resizeRef,
  parentNode,
  handleMoveFile,
  lowerSiblingMax,
  handleMoveFolder,
  clickChildrenNode,
  lowerSiblingCounts,
  toggleChildrenVisibility,
}) => {
  const [isEdit, setIsEdit] = useState<Boolean>(false);
  const [openModalFolderPermissionSetting, setOpenModalFolderPermissionSetting] =
    useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { activeFolder, manualFolderList, listFolderPermissions } = useSelector(manualSelector);
  const { userInfo } = useSelector(authSelector);

  const accessPermission = useMemo(
    () =>
      listFolderPermissions.filter(
        (folder) => folder.folder_id === node.folder_id && userInfo?.login_id === folder.login_id
      ),
    [listFolderPermissions, node.folder_id, userInfo?.login_id]
  );

  const scaffold: React.ReactElement[] = [];
  lowerSiblingCounts.forEach((_: any, i: number) => {
    scaffold.push(
      <div key={`pre_${1 + i}`} style={{ width: scaffoldBlockPxWidth }} className="lineBlock" />
    );

    if (treeIndex !== listIndex) {
      scaffold.push(
        <div
          key={`highlight_${1 + i}`}
          style={{
            width: scaffoldBlockPxWidth,
            left: scaffoldBlockPxWidth * i,
          }}
          className="absoluteLineBlock"
        />
      );
    }
  });

  const handleBlurInput = () => {
    if (node.isVirtualCreating) {
      const newTreeData = changeNodeAtID({
        treeData: manualFolderList,
        i_id: node.i_id!,
        newNode: () => undefined,
      });
      dispatch(setManualFolderList(newTreeData));
    }
  };

  const [, dropMoveFile] = useDrop<
    { type: string; record: FileExplorer.DataTableType },
    void,
    void
  >({
    accept: 'move-file',
    canDrop: ({ record }) =>
      !disabled &&
      !!resizeRef &&
      record.folder_id !== node.folder_id &&
      (record.type === 'folder' ? !checkExistItem({ treeData: record, item: node }) : true),
    drop: ({ record }) => {
      if (!ref.current || !handleMoveFile || !handleMoveFolder) {
        return;
      }
      if (record.type === 'folder') {
        handleMoveFolder(record, node);
      } else {
        handleMoveFile(record, node);
      }
    },
  });

  const [, drop] = useDrop<{ type: string; node: FileExplorer.TreeItem }, void, void>({
    accept: 'move-folder',
    canDrop: (item) =>
      !disabled &&
      !!resizeRef &&
      item.node.i_id !== node.i_id &&
      item.node.parent_folder_id !== node.folder_id &&
      !checkExistItem({ treeData: item.node, item: node }),
    drop: (item) => {
      if (!ref.current || !handleMoveFolder) {
        return;
      }
      handleMoveFolder(item.node, node);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { node, type: 'move-folder' },
    canDrag: !disabled && !!node.parent_folder_id,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(dropMoveFile(drop(ref)));
  useEffect(() => handleBlurInput, [handleBlurInput]);

  return (
    <Dropdown
      overlay={
        <Menu
          items={
            accessPermission.length
              ? [
                  {
                    key: 'copy',
                    icon: <CopyOutlined className="icon-menu" />,
                    label: 'コピー',
                    style: {
                      color: `${permissionNumber === 1 ? '#cccccc' : '#424242'}`,
                    },
                    onClick: () =>
                      permissionNumber !== 1 && contextMenuRef.current?.handleCopy(node),
                  },
                  {
                    key: 'edit',
                    icon: <EditOutlined className="icon-menu" />,
                    label: '名称変更',
                    style: {
                      color: `${permissionNumber === 1 ? '#cccccc' : '#424242'}`,
                    },
                    onClick: () => permissionNumber !== 1 && setIsEdit(true),
                  },
                  {
                    key: 'lock',
                    icon: <LockOutlined className="icon-menu" />,
                    label: 'フォルダ権限設定',
                    style: {
                      color: `${permissionNumber === 1 ? '#cccccc' : '#424242'}`,
                    },
                    onClick: () =>
                      permissionNumber !== 1 && setOpenModalFolderPermissionSetting(true),
                  },
                  {
                    key: 'link',
                    icon: <LinkOutlined className="icon-menu" />,
                    label: 'リンクを取得',
                    style: {
                      color: `${permissionNumber === 1 ? '#cccccc' : '#424242'}`,
                    },
                    onClick: () =>
                      permissionNumber !== 1 && contextMenuRef.current?.handleCopyLink(node),
                  },
                  {
                    key: 'delete',
                    icon: <DeleteOutlined className="icon-menu" />,
                    label: '削除',
                    style: {
                      color: `${permissionNumber === 1 ? '#cccccc' : '#424242'}`,
                    },
                    onClick: () =>
                      permissionNumber !== 1 && contextMenuRef.current?.handleDelete(node),
                  },
                ]
              : []
          }
        />
      }
      trigger={['contextMenu']}
      disabled={disabled}
      onVisibleChange={(open) => {
        if (open && !accessPermission.length) {
          alertCustomRef.current?.showAlert({
            children: <AlertPermission />,
          });
        }
      }}
    >
      <NodeContentWrapper
        ref={ref}
        scaleNum={scaleNum}
        isDragging={isDragging}
        lowerSiblingMax={lowerSiblingMax}
        active={node.folder_id === activeFolder?.folder_id}
        isEditOrCreate={!disabled && !!(isEdit || node.isVirtualCreating)}
      >
        {toggleChildrenVisibility && node.children && node.children.length > 0 && (
          <button
            type="button"
            aria-label={node.expanded ? 'Collapse' : 'Expand'}
            className={node.expanded ? 'collapseButton' : 'expandButton'}
            style={{
              left: (lowerSiblingCounts.length - 0.5) * scaffoldBlockPxWidth,
            }}
            onClick={() => {
              if (!node.i_id) return;
              toggleChildrenVisibility(node.i_id);
            }}
          >
            {node.expanded ? <DownOutlined className="icon" /> : <RightOutlined className="icon" />}
          </button>
        )}

        <div
          className="rowWrapper"
          onClick={() => {
            if (!node.i_id) return;
            clickChildrenNode(node.i_id);
          }}
        >
          {scaffold}
          <div className="row">
            <div className="rowContents">
              <div className="rowToolbar">
                <FolderOpenFilled className="icon-folder" />
              </div>
              <div className="rowLabel">
                {!disabled && (isEdit || node.isVirtualCreating) ? (
                  <Formik
                    initialValues={{ folder_name: node.folder_name }}
                    onSubmit={async (values) => {
                      if (!node.i_id || !values.folder_name || !userInfo) return;
                      dispatch(startLoading());
                      const resultAction = await (node.isVirtualCreating
                        ? dispatch(
                            createNewFolder({
                              item: {
                                parent_folder_id: node.parent_folder_id,
                                company_id: node.company_id,
                                folder_name: values.folder_name,
                                display_order: (parentNode?.children?.length || 0) + 1,
                                createdby: userInfo.login_id,
                                createdat: new Date(),
                              },
                              return_item_result: true,
                              return_display_id: true,
                            })
                          )
                        : dispatch(
                            updateFolder({
                              id: node.i_id,
                              data: {
                                item: {
                                  folder_name: values.folder_name,
                                  updatedby: userInfo.login_id,
                                  updatedat: new Date(),
                                },
                                is_force_update: true,
                              },
                            })
                          ));
                      if (node.isVirtualCreating && createNewFolder.fulfilled.match(resultAction)) {
                        await dispatch(
                          setFolderPermission({
                            item: {
                              company_id: userInfo.company_id,
                              folder_id: resultAction.payload.item?.folder_id,
                              login_id: userInfo.login_id,
                            },
                          })
                        );
                        await Promise.all([
                          dispatch(
                            getListFolderPermission({
                              conditions: [
                                {
                                  id: 'company_id',
                                  search_value: [userInfo.company_id],
                                },
                              ],
                              page: 1,
                              per_page: 0,
                            })
                          ),
                          dispatch(
                            getManualFolder({
                              conditions: [
                                {
                                  id: 'company_id',
                                  search_value: [userInfo.company_id],
                                },
                              ],
                              sort_fields: [{ id: 'display_order', order: 'asc' }],
                              page: 1,
                              per_page: 0,
                            })
                          ),
                        ]);
                      } else if (
                        !node.isVirtualCreating &&
                        updateFolder.fulfilled.match(resultAction)
                      ) {
                        const newTreeData = changeNodeAtID({
                          treeData: manualFolderList,
                          i_id: node.i_id!,
                          newNode: (nodeUpdate: FileExplorer.TreeItem) => ({
                            ...nodeUpdate,
                            folder_name: values.folder_name,
                          }),
                        });
                        dispatch(
                          setActiveFolder(newTreeData.find((i: FileExplorer.TreeItem) => i))
                        );
                        dispatch(setManualFolderList(newTreeData));
                      }
                      dispatch(stopLoading());
                      setIsEdit(false);
                    }}
                  >
                    <Form onBlur={handleBlurInput}>
                      <Form.Item name="folder_name" style={{ margin: 0 }}>
                        <Input name="folder_name" style={{ padding: 4 }} autoFocus />
                      </Form.Item>
                    </Form>
                  </Formik>
                ) : (
                  <span className="folder-name">{node.folder_name}</span>
                )}
              </div>
            </div>
          </div>
        </div>
        <FolderPermissionSetting
          fileSelected={node}
          visible={openModalFolderPermissionSetting}
          setVisible={setOpenModalFolderPermissionSetting}
        />
      </NodeContentWrapper>
    </Dropdown>
  );
};

export default NodeContentRenderer;
