import React from 'react';
import { Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import { formatNumberComma } from 'libs/utils/format';
import { LogoRStandard, LogoNav } from 'assets';
import * as Types from 'types';
import styles from './styles';

interface Props {
  dataTableMock: {
    content: string;
    unitPrice: string;
    quantity: string;
    total: string;
  }[];
  information: Types.AdministratorMasterFormik;
  handleTotalProduct: () => number;
  handleTotalProductFinal: () => number;
}

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const FileExportInvoicePDF: React.FC<Props> = ({
  dataTableMock,
  information,
  handleTotalProduct,
  handleTotalProductFinal,
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.wrapBody}>
          <View style={styles.content}>
            <View style={styles.contentHeader}>
              <View style={styles.contentHeaderLogo}>
                <Image style={styles.logoHeader} src={LogoNav} />
                <View style={styles.contentHeaderText}>
                  <Text style={styles.textVersion}>請求書No.&nbsp;&nbsp;&nbsp;&nbsp;123456789</Text>
                  <Text style={styles.textVersion}>
                    請求日&nbsp;&nbsp;&nbsp;&nbsp;2022年9月30日
                  </Text>
                </View>
              </View>
              <View style={styles.title}>
                <Text style={styles.titleModal}>請求書</Text>
              </View>
              <View style={styles.wrapTitleHeader}>
                <Text style={styles.nameCompany}>{information.name}</Text>
                <View style={styles.wrapContentStandard}>
                  <Text style={styles.titleStandard}>アールスタンダード</Text>
                  <Text style={styles.subTitleStandard}>株式会社RSTANDARD</Text>
                </View>
              </View>
              <View style={styles.infoContent}>
                <View style={styles.infoContentLeft}>
                  <Text style={styles.textLabel}>〒 {information.postal_code}</Text>
                  <Text style={styles.textLabel}>
                    {information.prefecture} - {information.address} -{information.plot_number}-{' '}
                    {information.building_name}
                  </Text>
                  <Text style={styles.textLabel}>TEL：</Text>
                  <Text style={styles.textLabel}>FAX：</Text>
                  <Text style={styles.textLabel}>登録番号：</Text>
                </View>
                <View style={styles.infoContentRight}>
                  <Text style={styles.textPhone}>〒 123-1234</Text>
                  <Text style={styles.textPhone}>東京都台東区上野3-17-7</Text>
                  <Text style={styles.textPhone}>G-SQUARE上野4F</Text>
                  <Text style={styles.textPhone}>TEL：03-6803-2893</Text>
                  <Text style={styles.textPhone}>FAX：03-6803-2894</Text>
                  <Text style={styles.textPhone}>登録番号：T1234567890123</Text>
                  <Image style={styles.imageLogo} src={LogoRStandard} />
                </View>
              </View>
              <View style={styles.totalHeader}>
                <Text style={styles.totalHeaderTitle}>ご請求金額（税込）</Text>
                <Text style={styles.totalNumber}>
                  {formatNumberComma(handleTotalProductFinal())}
                  <Text style={styles.currencyUnit}>円</Text>
                </Text>
              </View>
            </View>
            <View style={styles.contentBody}>
              <View style={styles.section}>
                <View style={styles.table}>
                  <View style={styles.tableRowHeader}>
                    <Text style={styles.tableCell1}>内容</Text>
                    <Text style={styles.tableCell2}>単価</Text>
                    <Text style={styles.tableCell2}>数量</Text>
                    <Text style={styles.tableCell3}>合計</Text>
                  </View>
                  {dataTableMock.map((item, index) => (
                    <View style={styles.tableRow} key={index}>
                      <Text style={styles.tableCell1}>{item.content}</Text>
                      <Text style={styles.tableCell2}>{item.unitPrice}</Text>
                      <Text style={styles.tableCell2}>{item.quantity}</Text>
                      <Text style={styles.tableCell3}>{item.total}</Text>
                    </View>
                  ))}
                </View>
              </View>
              <View style={styles.subTable}>
                <View style={styles.wrapSubItem}>
                  <View style={styles.subItem}>
                    <Text style={styles.subTitle}>小計</Text>
                    <Text style={styles.subPrice}>{formatNumberComma(handleTotalProduct())}</Text>
                  </View>
                  <View style={styles.subItem}>
                    <Text style={styles.subTitle}>消費税等（10%）</Text>
                    <Text style={styles.subPrice}>
                      {formatNumberComma(handleTotalProduct() * 0.1)}
                    </Text>
                  </View>
                  <View style={styles.subItem}>
                    <Text style={styles.subTitle}>合計</Text>
                    <Text style={styles.subPrice}>
                      {formatNumberComma(handleTotalProductFinal())}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.contentFooter}>
              <View style={styles.itemFooter}>
                <Text style={styles.textFist}>【お振込先】</Text>
                <View style={styles.itemText}>
                  <Text style={styles.text}>みずほ銀行（0001）上野支店（107）</Text>
                  <Text style={styles.text}>（普通）2689387</Text>
                  <Text style={styles.text}>株式会社RSTANDARD</Text>
                </View>
                <Text style={styles.textLast}>※お振込手数料は御社負担にてお願いいたします。</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FileExportInvoicePDF;
