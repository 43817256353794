import { createAsyncThunk } from '@reduxjs/toolkit';

import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { sharedFileInMinIO } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import { services } from 'services';
import * as Types from 'types';
import {
  ATTACH,
  KNOWLEDGE_FAVORITE_HISTORY,
  KNOWLEDGE_LIKES_HISTORY,
  KNOWLEDGE_LIST,
  PROFILE_CURRICULUMS,
  RANKING_LIKES_USER_ALL,
  REQUIRED_CURRICULUM_SKILL_CHECK,
  SELECT_DEPARTMENT_USER,
  USERS,
} from 'configs';

export const getDataKnowledgeInfo = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RequiredCurriculumSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getDataKnowledgeInfo', async (req, { rejectWithValue }) => {
  try {
    //TODO: change dataStore
    const { data } = await services.filter<Types.RequiredCurriculumSkillCheck.ResponseType>(
      REQUIRED_CURRICULUM_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUserInfo = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/updateUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/updatFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createImage = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/createImage', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getProfileAttachFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getProfileAttachFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfoDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getUserInfoDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = await sharedFileInMinIO(data.items[0]?.icon_fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
      Object.assign(data.items[0], {
        ...data.items[0],
        avatar: convertFileResponse({
          file: fileFromUrl,
          fileID: data.items[0]?.icon_fileID,
          fileName: fileName,
        }),
      });
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectDepartmentName = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartmentUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartmentUser.ResponseType>(
      SELECT_DEPARTMENT_USER.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataProfileCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ProfileCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getDataProfileCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ProfileCurriculum.ResponseType>(
      PROFILE_CURRICULUMS.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataProfileCurriculumTable = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ProfileCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getDataProfileCurriculumTable', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ProfileCurriculum.ResponseType>(
      PROFILE_CURRICULUMS.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttachByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/deleteFileAttachByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRankingUserAll = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RankingLikeUserAll.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getDataRankingUserAll', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.RankingLikeUserAll.ResponseType>(
      RANKING_LIKES_USER_ALL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataKnowledgeList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeList.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getDataKnowledgeList', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeList.ResponseType>(
      KNOWLEDGE_LIST.name,
      req
    );

    const dataConvert = await Promise.all(
      data.report_results
        .filter(
          (item, index, arr) => arr.map((i) => i.knowledge_id).indexOf(item.knowledge_id) === index
        )
        .map(async (val) => {
          const obj = val;

          if (val.author_file_icon) {
            const fileName = extractFileName(val.author_file_icon);
            const nodeFileUrl = await sharedFileInMinIO(val.author_file_icon);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              author_avatar: convertFileResponse({
                file: fileFromUrl,
                fileID: val.author_file_icon,
                fileName: fileName,
              }),
            });
          }

          return obj;
        })
    );

    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeFavoriteHistory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeFavoriteHistory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getKnowledgeFavoriteHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeFavoriteHistory.ResponseType>(
      KNOWLEDGE_FAVORITE_HISTORY.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeLikeHistory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeLikesHistory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('profile/thunk/getKnowledgeLikeHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeLikesHistory.ResponseType>(
      KNOWLEDGE_LIKES_HISTORY.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
