import React, { useRef, useState } from 'react';
import { Input as AntInput, InputRef, Select } from 'antd';
import { Form, Radio, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';

import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import ConfirmSuccessModal from 'components/Modal/ConfirmSuccess';
import { CREDIT_CARD_MONTH, IMAGE_CREDIT_CARD } from 'constant';
import { RegisterInformationPaymentFormik } from 'types';
import { Input, InputPassword, Modal } from 'components';
import { fourStepSchema } from 'libs/validations';
import { RegisterCardStyled } from './styles';
import { SecurityCode } from 'assets';

interface Props {
  visibleRegisterCreditCard: boolean;
  setVisibleRegisterCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const ModalRegisterCreditCard: React.FC<Props> = ({
  visibleRegisterCreditCard,
  setVisibleRegisterCreditCard,
}) => {
  const [modalSuccess, setModalSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [cardNumber, setCardNumber] = useState<{
    input1: string;
    input2: string;
    input3: string;
    input4: string;
  }>({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });
  const [expiredDate, setExpiredDate] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const { creditCard } = useSelector(informationRegisterSelector);

  const cardNumber1 = useRef<InputRef>(null);
  const cardNumber2 = useRef<InputRef>(null);
  const cardNumber3 = useRef<InputRef>(null);
  const cardNumber4 = useRef<InputRef>(null);

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fourStepSchema,
    validateOnBlur: false,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const handleInputCardNumber = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value } = e.target;

    switch (index) {
      case 1:
        if (parseInt(value[0]) !== 0) {
          setCardNumber((prevState) => ({ ...prevState, input1: value }));
          formik.setFieldValue(
            'cardNumber',
            `${value}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`
          );
          if (value.length === maxLength) {
            cardNumber2.current && cardNumber2.current.focus();
          }
        }
        break;
      case 2:
        setCardNumber((prevState) => ({ ...prevState, input2: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${value}${cardNumber.input3}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber3.current && cardNumber3.current.focus();
        }
        break;
      case 3:
        setCardNumber((prevState) => ({ ...prevState, input3: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${value}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber4.current && cardNumber4.current.focus();
        }
        break;
      case 4:
        setCardNumber((prevState) => ({ ...prevState, input4: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${value}`
        );
        break;
      default:
        break;
    }
  };

  const handleSelectExpiredDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setExpiredDate((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('expirationDate', `${e}${expiredDate.year}`);
    } else {
      setExpiredDate((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('expirationDate', `${expiredDate.month}${e}`);
    }
  };

  const handleChangeOpenDisable = () => {
    setVisibleRegisterCreditCard(!visibleRegisterCreditCard);
    setModalSuccess(true);
  };

  const handleResetForm = () => {
    formik.resetForm();
    setCardNumber({
      input1: '',
      input2: '',
      input3: '',
      input4: '',
    });
    setExpiredDate({
      month: '',
      year: '',
    });
  };

  const handleToggleModal = () => {
    setVisibleRegisterCreditCard(!visibleRegisterCreditCard);
    handleResetForm();
  };

  return (
    <>
      <Modal
        width={860}
        title="クレジットカード情報の登録"
        visible={visibleRegisterCreditCard}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
        onCancel={handleToggleModal}
      >
        <RegisterCardStyled>
          <FormikProvider value={formik}>
            <Form layout="vertical" colon={false}>
              {disabled && (
                <div className="text-header">
                  内容に問題がなければ、更新ボタンをクリックしてください。
                </div>
              )}
              <div className="from">
                <Form.Item
                  name="companyName"
                  label={
                    <span className="text-label">
                      カード会社
                      {!disabled && <span className="require">*</span>}
                    </span>
                  }
                  className="form-input"
                >
                  <Radio.Group name="companyName" className="radio_group">
                    {IMAGE_CREDIT_CARD.map((item, index) =>
                      !disabled ? (
                        <div key={index} className="wrap-radio">
                          <Radio
                            name="companyName"
                            value={item.name}
                            className={`radio_item ${
                              index === 0
                                ? 'border-radius-left'
                                : index >= 1 && index <= 3
                                ? 'border-right'
                                : 'border-radius-right'
                            }`}
                          >
                            <img
                              className="image-card-company"
                              aria-hidden
                              src={item.image}
                              alt={item.name}
                            />
                          </Radio>
                          <p className="label-text">{item.name}</p>
                        </div>
                      ) : item.name === formik.values.companyName ? (
                        <div key={index} className="wrap-radio">
                          <div className="wrap-radio-image">
                            <img
                              className="image-card-company"
                              aria-hidden
                              src={item.image}
                              alt={item.name}
                            />
                          </div>
                          <p className="label-text">{formik.values.companyName}</p>
                        </div>
                      ) : null
                    )}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="cardNumber"
                  label={
                    <span className="text-label">
                      カード番号
                      {!disabled && <span className="require">*</span>}
                    </span>
                  }
                  className="form-input"
                >
                  <AntInput
                    disabled={disabled}
                    ref={cardNumber1}
                    value={cardNumber.input1}
                    suffix={<span />}
                    className="input-card-number input_small"
                    type="number"
                    maxLength={4}
                    onChange={handleInputCardNumber(1)}
                    onKeyPress={(e) => {
                      if (isNaN(parseInt(e.key)) || cardNumber.input1.toString().length > 3) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span> - </span>
                  <AntInput
                    disabled={disabled}
                    ref={cardNumber2}
                    value={cardNumber.input2}
                    suffix={<span />}
                    className="input-card-number input_small"
                    type="number"
                    maxLength={4}
                    onChange={handleInputCardNumber(2)}
                    onKeyPress={(e) => {
                      if (isNaN(parseInt(e.key)) || cardNumber.input2.toString().length > 3) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span> - </span>
                  <AntInput
                    disabled={disabled}
                    ref={cardNumber3}
                    value={cardNumber.input3}
                    suffix={<span />}
                    className="input-card-number input_small"
                    type="number"
                    maxLength={4}
                    onChange={handleInputCardNumber(3)}
                    onKeyPress={(e) => {
                      if (isNaN(parseInt(e.key)) || cardNumber.input3.toString().length > 3) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <span> - </span>
                  <AntInput
                    disabled={disabled}
                    ref={cardNumber4}
                    value={cardNumber.input4}
                    suffix={<span />}
                    className="input-card-number input_small"
                    type="number"
                    maxLength={4}
                    onChange={handleInputCardNumber(4)}
                    onKeyPress={(e) => {
                      if (isNaN(parseInt(e.key)) || cardNumber.input4.toString().length > 3) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="customerName"
                  label={
                    <span className="text-label">
                      カード名義人
                      {!disabled && <span className="require">*</span>}
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    disabled={disabled}
                    name="customerName"
                    className="input"
                    type="text"
                    placeholder="全角：最大100文字"
                    onInput={(e) =>
                      ((e.target as HTMLInputElement).value = (
                        e.target as HTMLInputElement
                      ).value.toUpperCase())
                    }
                    maxLength={100}
                  />
                </Form.Item>
                <Form.Item
                  name="expirationDate"
                  label={
                    <span className="text-label">
                      有効期限
                      {!disabled && <span className="require">*</span>}
                    </span>
                  }
                  className="form-input"
                >
                  {!disabled ? (
                    <div>
                      <Select
                        value={expiredDate.month}
                        className="pull_down input_small"
                        onSelect={handleSelectExpiredDate('month')}
                      >
                        {CREDIT_CARD_MONTH.map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                      <span className="text-label-content">月 / </span>
                      <Select
                        value={expiredDate.year}
                        className="pull_down input_small"
                        onSelect={handleSelectExpiredDate('year')}
                      >
                        {Array.from(
                          {
                            length: 10,
                          },
                          (_, i) => (i + new Date().getFullYear()).toString()
                        ).map((item, index) => (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                      <span className="text-label-content">年</span>
                    </div>
                  ) : (
                    <div>
                      <Input
                        name="month"
                        value={expiredDate.month}
                        className="pull_down input_small"
                        disabled
                      />
                      <span className="text-label-content">月 / </span>
                      <Input
                        name="year"
                        value={expiredDate.year}
                        className="pull_down input_small"
                        disabled
                      />
                      <span className="text-label-content">年</span>
                    </div>
                  )}
                </Form.Item>
                <Form.Item
                  name="securityNumber"
                  label={
                    <span className="text-label">
                      セキュリティコード
                      {!disabled && <span className="require">*</span>}
                    </span>
                  }
                  className="form-input flex"
                >
                  {disabled ? (
                    <Input
                      name="securityNumber"
                      className="input_small_two"
                      type="password"
                      value={formik.values.securityNumber}
                      disabled
                    />
                  ) : (
                    <InputPassword
                      name="securityNumber"
                      className="input_small_two"
                      onKeyPress={(e) => {
                        if (
                          isNaN(parseInt(e.key)) ||
                          formik.values.securityNumber.toString().length > 3
                        ) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={4}
                    />
                  )}
                  <p className="content-text">
                    カード裏側のご署名欄に印字された数字の末尾3桁の数字を入力してください。
                    <br />
                    AMEXの場合、はカード表側の4桁を入力してください
                  </p>
                </Form.Item>
              </div>
              <div className="wrap-security">
                <img src={SecurityCode} alt="security-code" />
              </div>
              {!disabled ? (
                <div className="wrap-button">
                  <SubmitButton disabled={!formik.isValid && !formik.dirty} className="btn-submit">
                    確認画面へ
                  </SubmitButton>
                  <div className="btn-back-info" onClick={() => handleToggleModal()}>
                    キャンセル
                  </div>
                </div>
              ) : (
                <div className="wrap-button">
                  <div className="btn-submit" onClick={() => handleChangeOpenDisable()}>
                    登録
                  </div>
                  <div className="btn-cancel" onClick={() => setDisabled(false)}>
                    キャンセル
                  </div>
                </div>
              )}
            </Form>
          </FormikProvider>
        </RegisterCardStyled>
      </Modal>
      <ConfirmSuccessModal
        visible={modalSuccess}
        setVisible={setModalSuccess}
        subTitle="お支払い方法の変更が完了しました"
        description={
          <span>
            クレジットカード情報に変更があった際は
            <br />
            必ず登録情報の更新を行ってください。
          </span>
        }
      />
    </>
  );
};

export default ModalRegisterCreditCard;
