import React from 'react';
import { LikeOutlined, CrownFilled, UserOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { Avatar } from 'antd';

import { ItemUserComponent } from './styles';
import { routes } from 'navigations/routes';
import { LabelNumber } from 'assets';
import * as Types from 'types';

interface Props {
  index: number;
  item: Types.RankingLikeUserAll.ResponseType;
}

const UserComponent: React.FC<Props> = ({ index, item }) => {
  const navigate = useNavigate();

  return (
    <ItemUserComponent
      onClick={() => {
        item.item_ref?.author_name.i_id &&
          navigate(
            generatePath(routes.UserProfile.path, {
              id: item.item_ref.author_name.i_id,
              entity: 'receiving',
            })
          );
      }}
    >
      <div className="left-side">
        <p className="numerical-order-label">{index + 1}</p>
      </div>
      {index === 0 ? (
        <div className="right-side top">
          <div className="wrapper">
            <CrownFilled className="crown-icon" />
            {item.fileAvatar ? (
              <img className="image-avatar" src={item?.fileAvatar} alt="avatar" />
            ) : (
              <Avatar className="no-avatar">
                <UserOutlined className="icon" />
              </Avatar>
            )}

            <div className="ribbon">
              <img className="ribbon-image" src={LabelNumber} alt="ribbon" />
              <span className="number-of-likes">{item?.number_of_likes}</span>
            </div>
          </div>
          <div className="user-info">
            <span className="department">{item?.author_department_name?.replace(/^\d+ /, '')}</span>
            <span className="user-name">{item?.author_name}</span>
          </div>
        </div>
      ) : (
        <div className="right-side">
          <div className="recommend">
            {item.fileAvatar ? (
              <img className="image-avatar" src={item?.fileAvatar} alt="avatar" />
            ) : (
              <Avatar className="no-avatar">
                <UserOutlined className="icon" />
              </Avatar>
            )}
            <div className="user-info">
              <span className="department">
                {item?.author_department_name?.replace(/^\d+ /, '')}
              </span>
              <span className="user-name">{item.author_name}</span>
            </div>
            <div className="wrap-count-like">
              <LikeOutlined className="like-icon" />
              <span className="count-like">{item.number_of_likes}</span>
            </div>
          </div>
        </div>
      )}
    </ItemUserComponent>
  );
};

export default UserComponent;
