import React, { useEffect, useMemo, useState, Dispatch, SetStateAction } from 'react';
import { UploadFile, UploadChangeParam, RcFile } from 'antd/lib/upload/interface';
import { Row, Col, Tooltip, Select, Image as ImageAntd, Upload } from 'antd';
import locale from 'antd/es/date-picker/locale/de_DE';
import { FormikProvider, useFormik } from 'formik';
import { Form, Radio } from 'formik-antd';
import { useSelector } from 'react-redux';
import { find, isEmpty } from 'lodash';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { Input, Modal, SelectField, SpinLoading, TextArea } from 'components';
import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { createSkillcheckSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, useCheckUpload } from 'hooks';
import { skillCheckSelector } from '../../selectors';
import { FileType } from 'constant/enum.constant';
import DatePicker from 'components/DatePicker';
import EnlargeInput from '../EnlargeInput';
import { SectionStyled } from './styles';
import { IconEnalarge } from 'assets';
import * as Types from 'types';
import {
  deleteFileAttachByConditions,
  updateSkillCheck,
  createSkillcheck,
  createFileAttach,
  getSelectGroups,
  getSelectTypes,
} from '../../thunk';

const { Dragger } = Upload;

interface Props {
  dataImage?: UploadFile<File>;
  fetchDataSkillCheck: () => void;
  fetchDataUserSkillCheck: () => void;
  skillCheck?: Types.SkillCheckTreeViewType;
  openModalCreateEdit: { visible: boolean; type: 'create' | 'edit' };
  setOpenModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
    }>
  >;
  setFiles?: Dispatch<SetStateAction<UploadFile<File> | undefined>>;
}

const { RangePicker } = DatePicker;

const { Option } = Select;

const ModalCreateEdit: React.FC<Props> = ({
  dataImage,
  skillCheck,
  setOpenModal,
  fetchDataSkillCheck,
  fetchDataUserSkillCheck,
  openModalCreateEdit: { type, visible },
  setFiles,
}) => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [visibleEnlargeInput, setVisibleEnlarge] = useState<boolean>(false);
  const [isShowImage, setIsShowImage] = useState<boolean>(false);

  const { time_limit, groups } = useSelector(skillCheckSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const { isDisabledUpload } = useCheckUpload();

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const onClose = () => {
    setOpenModal({ visible: false, type });
    formik.resetForm();
  };

  const initialValues = useMemo(() => {
    const isEdit = type === 'edit';
    return {
      name: isEdit ? skillCheck?.skill_check_name : '',
      grouping_code: isEdit ? skillCheck?.lookup_items?.grouping_code?.i_id : '',
      implementation_settings: isEdit ? skillCheck?.implementation_settings : 1,
      description: isEdit ? skillCheck?.description : '',
      problems4: isEdit ? skillCheck?.problems4 : 0,
      user_type: isEdit ? skillCheck?.user_type : 'member',
      fileID: isEdit ? skillCheck?.fileID : '',
      file: dataImage,
      time_limit: isEdit ? `${skillCheck?.question_time_limit}分` : '',
      base_date_for_report: isEdit ? skillCheck?.base_date_for_report : undefined,
      start_period: isEdit ? skillCheck?.start_period || undefined : undefined,
      end_period: isEdit ? skillCheck?.end_period || undefined : undefined,
    };
  }, [dataImage, skillCheck, type]);

  const formik = useFormik<Partial<Types.SkillCheck.ResponseType>>({
    initialValues,
    enableReinitialize: true,
    validationSchema: createSkillcheckSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      let file = '';

      dispatch(startLoading());
      if (!isEmpty(values.file) && values?.file?.type) {
        if (skillCheck?.fileID) {
          deletedFileInMinIO(skillCheck.fileID);
          dispatch(
            deleteFileAttachByConditions({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [skillCheck.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
        const uploadToMinIo = await uploadFileToMinIO(values.file);
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadToMinIo,
              filename: values.file?.name,
              file_location: '3',
              file_extension: values.file?.type,
              file_size: `${values.file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        file = uploadToMinIo;
        //   const formData = new FormData();
        //   formData.append('filename', values.file!.name);

        //   formData.append('file', new Blob([values.file as unknown as Blob]));
        //   const uploadFileToS3Result = await dispatch(uploadFileToS3({ formData }));
        //   if (uploadFileToS3.fulfilled.match(uploadFileToS3Result)) {
        //     file = uploadFileToS3Result;

        //     const createFileAttachResult = await dispatch(
        //       createFileAttach({
        //         item: {
        //           fileID: uploadFileToS3Result.payload.file_id,
        //           filename: values.file?.name,
        //         },
        //       })
        //     );
        //     if (createFileAttach.fulfilled.match(createFileAttachResult)) {
        //       dispatch(
        //         executeAction({
        //           itemId: createFileAttachResult.payload.item_id,
        //           data: {
        //             changes: [
        //               {
        //                 id: 'file',
        //                 value: [uploadFileToS3Result.payload.file_id],
        //               },
        //             ],
        //             use_display_id: true,
        //             is_force_update: true,
        //           },
        //         })
        //       );
        //     }
        //   }
      }
      if (type === 'edit' && skillCheck) {
        await dispatch(
          updateSkillCheck({
            id: skillCheck.i_id,
            data: {
              item: {
                ...values,
                question_time_limit: Number(values?.time_limit?.replace('分', '')),
                start_period: values.start_period || null,
                end_period: values.end_period || null,
                fileID: file,
              },
              is_force_update: true,
            },
          })
        );
      } else {
        await dispatch(
          createSkillcheck({
            item: {
              company_id: userInfo?.company_id,
              createdby: userInfo?.login_id,
              createdat: new Date(),
              probs_count: 0,
              user_count: 0,
              setting_score: 0,
              publish: 0,
              question_time_limit: Number(values?.time_limit?.replace('分', '')),
              ...values,
              fileID: file,
            },
          })
        );
      }
      fetchDataSkillCheck();
      fetchDataUserSkillCheck();
      dispatch(stopLoading());
      onClose();
    },
  });

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPngOrMp4 = file.type === FileType.PNG || file.type === FileType.JPEG;

    const isAccept = await new Promise<boolean>((resolve) => {
      const src = URL.createObjectURL(new Blob([file]));
      if (file.type === FileType.PNG || file.type === FileType.JPEG) {
        const image = new Image();
        image.src = src;
        image.onload = function () {
          URL.revokeObjectURL(src);
          resolve(file.size < 10485760);
        };
      }
    });

    if (!isJpgOrPngOrMp4 || !isAccept) {
      alert(`※画像はjpegまたはpngである必要があり、最大10MBまで可能です。
ビデオはmp4である必要があり、最大100MBにすることができます。`);
    }

    return isJpgOrPngOrMp4 && isAccept ? false : Upload.LIST_IGNORE;
  };

  useEffect(() => {
    (async () => {
      if (visible && userInfo) {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectGroups({
              page: 1,
              per_page: 0,
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
            })
          ),
          dispatch(
            getSelectTypes({
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        // if (skillCheck && type === 'edit') {
        //   formik.setValues({
        //     name: skillCheck.skill_check_name,
        //     grouping_code: skillCheck.lookup_items?.grouping_code?.i_id || '',
        //     implementation_settings: skillCheck.implementation_settings,
        //     description: skillCheck.description,
        //     problems4: skillCheck.problems4,
        //     start_period: skillCheck.start_period,
        //     end_period: skillCheck.end_period,
        //     time_limit: `${skillCheck.question_time_limit}分`,
        //     user_type: skillCheck.user_type,
        //     fileID: skillCheck.fileID,
        //     file: dataImage,
        //   });
        // }
        dispatch(stopLoading());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, skillCheck, visible, userInfo]);

  return (
    <Modal
      title={type === 'create' ? 'スキルチェック 新規作成' : 'スキルチェック 編集'}
      width={860}
      visible={visible}
      okButton={{
        text: type === 'create' ? '登録' : '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: onClose,
      }}
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SpinLoading size="large" loading={loading}>
        <SectionStyled>
          <p className="sub-title">
            {type === 'create'
              ? 'スキルチェックの新規作成画面です。入力後に登録ボタンをクリックしてください。'
              : 'スキルチェックの編集画面です。入力後に更新ボタンをクリックしてください。'}
          </p>
          <FormikProvider value={formik}>
            <Form colon={false} labelCol={{ flex: '100%' }} layout="vertical" className="form">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    スキルチェック名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="name"
                  className="input"
                  type="text"
                  showCount
                  maxLength={30}
                  placeholder={messages['M-21-63'] as string}
                />
              </Form.Item>
              <Row>
                <Col span={24}>
                  <span className="text-label">実施設定</span>
                  <div className="wrap-implement-setting">
                    <Form.Item
                      name="implementation_settings"
                      label={
                        <span className="text-label">
                          実施可否設定
                          <span className="require">*</span>
                          <span className="require-text">
                            <small>※実施期間を設定すると、期間中のみ実施可の状態になります。</small>
                          </span>
                        </span>
                      }
                      className="form-input-radio"
                    >
                      <Radio.Group
                        name="implementation_settings"
                        onChange={(e) => {
                          e.target.value !== 1 &&
                            formik.setValues({
                              ...formik.values,
                              implementation_settings: e.target.value,
                              start_period: undefined,
                              end_period: undefined,
                            });
                        }}
                        className="radio_group"
                      >
                        <Radio name="implementation_settings" value={1} className="radio-text">
                          実施期間を設定
                        </Radio>
                        <Radio name="implementation_settings" value={2} className="radio-text">
                          実施可
                        </Radio>
                        <Radio name="implementation_settings" value={0} className="radio-text">
                          実施不可
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      name="period"
                      label={
                        <span className="text-label">
                          実施期間設定
                          {formik.values.implementation_settings === 1 ? (
                            <span className="require">*</span>
                          ) : null}
                        </span>
                      }
                    >
                      <RangePicker
                        disabled={
                          formik.values.implementation_settings === 2 ||
                          formik.values.implementation_settings === 0
                        }
                        showTime
                        format="YYYY/MM/DD HH:mm"
                        dropdownClassName="datetime-picker-skill-check"
                        ranges={{
                          今日: [dayjs().startOf('day'), dayjs().endOf('day')],
                          今月: [dayjs().startOf('month'), dayjs().endOf('month')],
                        }}
                        placeholder={['開始日時', '終了日時']}
                        allowEmpty={[true, true]}
                        defaultValue={
                          skillCheck && skillCheck.start_period && skillCheck.end_period
                            ? [
                                dayjs(skillCheck.start_period, 'YYYY/MM/DD HH:mm'),
                                dayjs(skillCheck.end_period, 'YYYY/MM/DD HH:mm'),
                              ]
                            : undefined
                        }
                        value={
                          formik.values.start_period && formik.values.end_period
                            ? [dayjs(formik.values.start_period), dayjs(formik.values.end_period)]
                            : undefined
                        }
                        onChange={(dates) => {
                          if (dates) {
                            formik.setValues({
                              ...formik.values,
                              start_period: dates[0]?.toDate(),
                              end_period: dates[1]?.toDate(),
                            });
                          } else {
                            formik.setValues({
                              ...formik.values,
                              start_period: undefined,
                              end_period: undefined,
                            });
                          }
                        }}
                        locale={{
                          ...locale,
                          lang: {
                            ...locale.lang,
                            ok: 'OK',
                          },
                        }}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="grouping_code"
                    label={
                      <span className="text-label">
                        分析グループ
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <SelectField
                      name="grouping_code"
                      onChange={(e) => {
                        formik.setFieldValue(
                          'group_code',
                          find(groups, (val) => val.i_id === e)?.code
                        );
                        formik.setFieldValue(
                          'grouping_code',
                          find(groups, (val) => val.i_id === e)?.i_id
                        );
                      }}
                      placeholder="選択してください"
                    >
                      {groups.map((item, index) => (
                        <Option key={index} value={item.i_id}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                    <div />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="base_date_for_report"
                    label={
                      <span className="text-label">
                        レポート用基準日付
                        <span className="require">*</span>
                        <Tooltip
                          trigger={['click']}
                          color="#ffffff"
                          overlayStyle={{
                            width: 404,
                          }}
                          overlayInnerStyle={{
                            color: '#424242',
                            padding: 10,
                            width: 404,
                          }}
                          title={
                            <>
                              レポート用基準日付はレポート機能で日付を基準にスキルチェックの実施結果を集計する際に使用する日付です。
                              <br />
                              <br />
                              これは、スキルチェックの実施日の設定が「期間で設定」または「実施可」になるため、特定の日付に限定されません。
                              <br />
                              このため、基準となる日付を設定します。
                              <br />
                              <br />
                              主に分析グループを使用したレポートで使用されます。
                            </>
                          }
                        >
                          <QuestionCircleOutlined className="imageQA" />
                        </Tooltip>
                      </span>
                    }
                    className="form-input"
                  >
                    <DatePicker
                      allowClear
                      name="base_date_for_report"
                      style={{ width: '245px' }}
                      format="YYYY/MM/DD"
                      placeholder="日付を指定する"
                      defaultValue={
                        skillCheck && skillCheck.base_date_for_report
                          ? dayjs(skillCheck.base_date_for_report, 'YYYY/MM/DD')
                          : undefined
                      }
                      value={
                        formik.values.base_date_for_report
                          ? dayjs(formik.values.base_date_for_report)
                          : undefined
                      }
                      onChange={(date) => {
                        if (!date) return;
                        formik.setFieldValue(
                          'base_date_for_report',
                          date.startOf('date').tz('Asia/Tokyo').toISOString()
                        );
                      }}
                      locale={{
                        ...locale,
                        lang: {
                          ...locale.lang,
                          ok: 'OK',
                        },
                      }}
                    />
                    <div />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="user_type"
                    label={
                      <span className="text-label">
                        ユーザー種別
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input-radio"
                  >
                    <Radio.Group name="user_type" className="radio_group" defaultValue={1}>
                      <Radio name="user_type" value="member" className="radio-text half-width">
                        社内
                      </Radio>
                      <Radio name="user_type" value="interview" className="radio-text half-width">
                        面接
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    className="form-input"
                    label={
                      <div className="wrap-text-area">
                        <span className="text-label">説明</span>
                        <span className="text-enlarge" onClick={() => setVisibleEnlarge(true)}>
                          <img src={IconEnalarge} alt="enlarge" />
                          入力欄を拡大
                        </span>
                      </div>
                    }
                  >
                    <TextArea
                      name="description"
                      className="text-input"
                      placeholder="全角：最大1050文字"
                      rows={4}
                      showCount
                      maxLength={1050}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    name="time_limit"
                    label={
                      <span className="text-label">
                        制限時間
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <SelectField name="time_limit" placeholder="選択してください">
                      {time_limit.map((item, index) => (
                        <Option key={index} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                    <div />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={16}>
                  <Form.Item
                    name="problems4"
                    label={
                      <span className="text-label">
                        選択肢D（未経験）
                        <Tooltip
                          trigger={['click']}
                          color="#ffffff"
                          overlayStyle={{
                            width: 280,
                          }}
                          overlayInnerStyle={{
                            color: '#424242',
                            padding: 10,
                          }}
                          title={
                            <>
                              選択肢D（未経験）は、設問の内容が実施ユーザーの業務上未経験の領域の場合に使用する項目です。
                              <br />
                              <br />
                              選択肢D（未経験）を使用することで、未経験者が勘で回答することを防ぎ、より正確な分析が可能になります。
                            </>
                          }
                        >
                          <QuestionCircleOutlined className="imageQA" />
                        </Tooltip>
                      </span>
                    }
                    className="form-input-radio"
                  >
                    <Radio.Group name="problems4" className="radio_group">
                      <Radio name="problems4" value={1} className="radio-text half-width">
                        表示する
                      </Radio>
                      <Radio name="problems4" value={0} className="radio-text half-width">
                        表示しない
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <div className="label-image">表紙画像</div>
              <div className="form-upload">
                <div className="file-upload">
                  <div>
                    <Dragger
                      name="file"
                      accept=".jpeg, .png, .mp4"
                      multiple
                      beforeUpload={beforeUpload}
                      onChange={(info: UploadChangeParam<UploadFile<File>>) => {
                        formik.setFieldValue('file', info.file);
                      }}
                      disabled={!isDisabledUpload}
                    >
                      {formik.values.file ? (
                        <div className="info-file">
                          <p className="name-image">{formik.values.file.name.split(',')}</p>
                          <ImageAntd
                            onClick={(e) => e.stopPropagation()}
                            className="image-file"
                            src={URL.createObjectURL(
                              new Blob([
                                (formik.values.file.originFileObj ?? formik.values.file) as Blob,
                              ])
                            )}
                            alt={formik.values.file.name}
                            preview={{
                              visible: isShowImage,
                              src: URL.createObjectURL(
                                new Blob([
                                  (formik.values.file.originFileObj ?? formik.values.file) as Blob,
                                ])
                              ),
                              onVisibleChange: (value) => {
                                setIsShowImage(value);
                              },
                              mask: (
                                <ZoomInOutlined
                                  className="zoom-image"
                                  onClick={() => setIsShowImage(!isShowImage)}
                                />
                              ),
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          <CloudUploadOutlined className="icon" />
                          <p className="ant-upload-text">
                            アップロードするファイルをここにドロップ
                            <br />
                            または
                          </p>
                        </>
                      )}
                      <div className="wrap-button-upload">
                        <button type="button" className="btn-upload">
                          ファイルを選択
                        </button>
                        {formik.values.file ? (
                          <button
                            className="btn-delete"
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              formik.setFieldValue('file', undefined);
                              setFiles && setFiles(undefined);
                            }}
                          >
                            <DeleteOutlined className="icon-delete-outlined" />
                            <span className="text-delete-outlined">ファイルを削除</span>
                          </button>
                        ) : null}
                      </div>
                    </Dragger>
                  </div>
                </div>
              </div>

              <p className="text-content">
                ※画像は16:4でjpegまたはpngで、10MBまで。
                <br />
                ※画像はユーザがOFFICIALカリキュラムを管理する際に表紙として表示されます。
              </p>
              <EnlargeInput
                visible={visibleEnlargeInput}
                onCancel={() => setVisibleEnlarge(false)}
                defaultValue={formik.values.description || ''}
                onSubmit={(value) => {
                  formik.setFieldValue('description', value);
                  setVisibleEnlarge(false);
                }}
              />
            </Form>
          </FormikProvider>
        </SectionStyled>
      </SpinLoading>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="更新に失敗しました"
        description={
          <>
            設問情報の更新に失敗しました。
            <br />
            再度入力し、お試しください。
          </>
        }
      />
    </Modal>
  );
};

export default ModalCreateEdit;
