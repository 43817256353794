import React, { useEffect, useState } from 'react';
import { every, maxBy, omit, some } from 'lodash';
import { useSelector } from 'react-redux';

import { downloadFileFromMinio, uploadFileToMinIO } from 'services/minioService';
import { Wrapper as WrapperColumnRoot } from './TreeNodeRenderer/styles';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import UnableAddQuestionModal from 'components/Modal/UnableAddQuestion';
import { searchQuestionCurriculumSelector } from '../Search/selectors';
import { CustomDragLayer } from './NodeRenderer/CustomDragLayer';
import { removeNodeLevel4Selected } from '../Search/slice';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import * as Types from 'types';
import {
  memoizedGetChildrenItemIDFromTree,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  createLinkQuestionAssignLevel,
  deleteLinkQuestion,
} from 'containers/CreateEditQuestion/thunk';
import {
  getCurriculum,
  createLevelCurriculum,
  deleteLevelCurriculum,
  updateCurriculum,
  createCurriculum,
} from 'pages/Settings/Curriculum/thunk';

const NODE_HEIGHT = 45;

interface Props {
  tabActive: number;
  columnClosed?: number;
  treeViewIndex: number;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  setItemMoveCopySelected: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<
      {
        i_id: string;
        name: string;
        code: string;
      }[]
    >
  >;
}

const filterTreeDataByColumn = (
  data: Types.TreeItem<Types.CurriculumItemType>,
  columnClosed: number
): Types.TreeItem<Types.CurriculumItemType> => {
  if (data.columnIndex! > columnClosed - 1) return omit(data, 'children');

  const filteredChildren: Types.TreeItem<Types.CurriculumItemType>[] | undefined = data.children
    ? data.children.map((child) => filterTreeDataByColumn(child, columnClosed))
    : undefined;

  return {
    ...data,
    children: filteredChildren,
  };
};

const TreeView: React.FC<Props> = ({
  treeData,
  tabActive,
  columnClosed,
  treeViewIndex,
  selectedQuestion,
  itemMoveCopySelected,
  setSelectedQuestion,
  setItemMoveCopySelected,
}) => {
  const [data, setData] = useState<{ [key: string]: Types.TreeItem<Types.CurriculumItemType>[] }>(
    {}
  );
  const [dataTreeFilter, setDataTreeFilter] = useState<{
    [key: string]: Types.TreeItem<Types.CurriculumItemType>[];
  }>({});
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { filter_conditions, questionCurriculumSelected } = useSelector(curriculumSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const onDrop = async (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => {
    dispatch(startLoading());
    const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
      memoizedGetChildrenItemIDFromTree({
        treeData: currentItem.node,
      });

    for (let index = 0; index < listChildItemID.length; index++) {
      const item = listChildItemID[index];
      if (item.columnIndex < 5) {
        const resultAction = await dispatch(
          createLevelCurriculum({
            level: item.columnIndex,
            item: {
              company_id: userInfo?.company_id,
              name: item.name,
              sort_order:
                Number(maxBy(targetItem.children || [], 'sort_order')?.sort_order || 0) + 1,
              [`level${item.columnIndex - 1}_code`]:
                index === 0
                  ? targetItem.code
                  : listChildItemID
                      .slice(0, index)
                      .findLast((i) => i.columnIndex === item.columnIndex - 1).code,
            },
            return_item_result: true,
            realtime_auto_link: true,
          })
        );
        if (createLevelCurriculum.fulfilled.match(resultAction)) {
          switch (item.columnIndex) {
            case 1:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 2:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 3:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            case 4:
              listChildItemID[index].code = resultAction.payload.item['code'];
              break;
            default:
              break;
          }
          if (type === 'move') {
            await dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          }
        }
      } else {
        const resultAction = await dispatch(
          createLinkQuestionAssignLevel({
            item: {
              company_id: userInfo?.company_id,
              level4_code:
                listChildItemID
                  .slice(0, index)
                  .findLast((i) => i.columnIndex === item.columnIndex - 1)?.code || targetItem.code,
              code: item.code,
              sort_order:
                Number(maxBy(targetItem.children || [], 'sort_order')?.sort_order || 0) + 1,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        if (createLinkQuestionAssignLevel.fulfilled.match(resultAction) && type === 'move') {
          await dispatch(
            deleteLinkQuestion({
              id: item.question_assign_level_i_id!,
            })
          );
        }
      }
    }

    const question_copy_length = listChildItemID.filter((item) => item.columnIndex === 5).length;
    if (type === 'copy' && question_copy_length) {
      const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
      if (curriculum) {
        await dispatch(
          updateCurriculum({
            id: curriculum.curriculum_id!,
            data: {
              item: {
                probs_count: (curriculum?.problems_count || 0) + question_copy_length,
                updatedat: new Date(),
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
      }
    }

    await dispatch(
      getCurriculum({
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
    setItemMoveCopySelected(undefined);
  };

  const onDropNode = async (currentNode: Types.TreeItem<Types.CurriculumItemType>) => {
    if (!userInfo) return;

    const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
      memoizedGetChildrenItemIDFromTree({
        treeData: currentNode.node,
      });

    let fileId = '';
    if (currentNode.node?.fileID) {
      const nodeFile = await downloadFileFromMinio(currentNode.node?.fileID);
      if (nodeFile) {
        fileId = await uploadFileToMinIO(nodeFile);
      }
    }

    dispatch(startLoading());

    const createCurriculumResult = await dispatch(
      createCurriculum({
        item: {
          company_id: userInfo?.company_id,
          name: `${currentNode?.node?.name} コピー`,
          description: currentNode.node?.description,
          sort_order: 1,
          required_curriculum: currentNode.node?.required_curriculum,
          fileID: fileId,
          publish: currentNode.node?.publish,
          probs_count: currentNode.node?.problems_count,
          createdat: new Date(),
          createdby: userInfo?.login_id,
          creator: userInfo?.login_id,
          official_curriculum: 0,
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (createCurriculum.fulfilled.match(createCurriculumResult)) {
      for (let index = 1; index < listChildItemID.length; index++) {
        const item = listChildItemID[index];
        if (item.columnIndex < 5) {
          const resultAction = await dispatch(
            createLevelCurriculum({
              level: item.columnIndex,
              item: {
                company_id: userInfo?.company_id,
                name: item.name,
                sort_order: item.sort_order,
                curricullum_code: createCurriculumResult.payload?.item?.code,
                [`level${item.columnIndex - 1}_code`]:
                  index === 0
                    ? createCurriculumResult.payload?.item?.code
                    : listChildItemID
                        .slice(0, index)
                        .findLast((i) => i.columnIndex === item.columnIndex - 1).code,
              },
              return_item_result: true,
              realtime_auto_link: true,
            })
          );
          if (createLevelCurriculum.fulfilled.match(resultAction)) {
            switch (item.columnIndex) {
              case 1:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 2:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 3:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              case 4:
                listChildItemID[index].code = resultAction.payload.item['code'];
                break;
              default:
                break;
            }
          }
        } else {
          await dispatch(
            createLinkQuestionAssignLevel({
              item: {
                company_id: userInfo?.company_id,
                level4_code:
                  listChildItemID
                    .slice(0, index)
                    .findLast((i) => i.columnIndex === item.columnIndex - 1)?.code || '',
                code: item.code,
                sort_order:
                  Number(item.sort_order) +
                  Number(maxBy(listChildItemID || [], 'sort_order')?.sort_order || 0) +
                  1,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
              realtime_auto_link: true,
            })
          );
        }
      }
    }

    await dispatch(
      getCurriculum({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    setShowCompleteModal(true);
    dispatch(stopLoading());
  };
  const onDropQuestion = async () => {
    if (!nodeLevel4Selected) return;

    const selectedQuestionCodes = selectedQuestion.map((question) => question.code);

    const allSelectedQuestionsExist = every(selectedQuestionCodes, (code) =>
      some(questionCurriculumSelected, { question_code: code })
    );

    if (allSelectedQuestionsExist) {
      setShowActionErrorModal(true);
      setSelectedQuestion([]);

      return;
    }

    dispatch(startLoading());

    const resultActions = await Promise.all(
      selectedQuestion.map(
        (item) =>
          !nodeLevel4Selected?.children?.some(({ code }) => code === item.code) &&
          dispatch(
            createLinkQuestionAssignLevel({
              item: {
                company_id: userInfo?.company_id,
                level4_code: nodeLevel4Selected?.code,
                code: item.code,
                sort_order:
                  Number(maxBy(nodeLevel4Selected.children || [], 'sort_order')?.sort_order || 0) +
                  1,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          )
      )
    );
    await dispatch(removeNodeLevel4Selected());

    await dispatch(
      updateCurriculum({
        id: nodeLevel4Selected?.curriculum_id!,
        data: {
          item: {
            probs_count:
              (nodeLevel4Selected?.problems_count || 0) +
              resultActions.filter((r) => r && createLinkQuestionAssignLevel.fulfilled.match(r))
                .length,
            updatedat: new Date(),
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );

    setSelectedQuestion([]);
    await dispatch(
      getCurriculum({
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    setData(column);
  }, [treeData]);

  useEffect(() => {
    if (!columnClosed) return;

    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};

    const filteredTreeData = filterTreeDataByColumn(treeData, columnClosed);

    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData: filteredTreeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });

    setDataTreeFilter(column);
  }, [treeData, columnClosed]);

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      <WrapperColumnRoot nodeHeight={NODE_HEIGHT} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            index={0}
            onDrop={onDrop}
            node={data[0][0]}
            onDropNode={onDropNode}
            tabActive={tabActive}
            nodeHeight={50}
            treeViewIndex={treeViewIndex}
            accept={data[0][0].node!.i_id!}
            maxSortOrder={(maxBy(data[1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1}
          />
        </div>
      </WrapperColumnRoot>
      <div className="wrap_node_tree" style={{ display: 'flex' }}>
        {Array.from({
          length: columnClosed !== undefined ? columnClosed + 1 : 6,
        }).map((_, index) =>
          index > 0 ? (
            <ColumnRenderer
              key={index}
              onDrop={onDrop}
              columnIndex={index}
              column={columnClosed ? dataTreeFilter[index] : data[index]}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              treeViewIndex={treeViewIndex}
              onDropQuestion={onDropQuestion}
              accept={data[0][0].node!.i_id!}
              isPublish={!!data[0][0].node?.publish}
              itemMoveCopySelected={itemMoveCopySelected}
              setItemMoveCopySelected={setItemMoveCopySelected}
              maxSortOrder={
                (maxBy(data[index + 1], (o) => o.node?.sort_order)?.node?.sort_order || 0) + 1
              }
            />
          ) : (
            <div style={{ width: '3.32%' }} className="column" key={index} />
          )
        )}
      </div>
      <CustomDragLayer nodeHeight={NODE_HEIGHT} />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="コピーが完了しました"
        onSubmit={() => setShowCompleteModal(false)}
      />
      <UnableAddQuestionModal
        visible={showActionErrorModal}
        title="設問追加できません"
        subTitle="指定した設問は既に設定先のカリキュラムに設定されています。"
        setVisible={setShowActionErrorModal}
      />
    </Wrapper>
  );
};

export default TreeView;
