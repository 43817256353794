import styled from 'styled-components';

export default styled.section`
  .item-upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px;
    align-items: center;
    background: #efefef;
    border: 2px dashed #d9d9d9;
    cursor: pointer;
    .ant-upload.ant-upload-select-picture-card {
      width: 160px;
      background-color: unset;
      border: none;
    }
    .crop-avt {
      text-align: center;
    }
    .icon-upload {
      color: #ffffff;
      font-size: 35px;
      padding: 10px;
    }
    .text-upload {
      text-align: center;
      font-size: 13px;
      color: #424242;
    }
    .btn-upload {
      width: 160px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #00a3a5;
      cursor: pointer;
    }
  }
  .photo-detail {
    padding: 12px 0;
    font-size: 11px;
    color: #777777;
  }
  .ant-modal-content {
    .ant-modal-footer {
      padding-top: 0;
    }
  }
  .ant-upload.ant-upload-select.ant-upload-select-picture-card {
    height: unset;
    margin: 0;
  }
  .ant-upload-drag {
    border: none;
  }
`;
