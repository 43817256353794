import React from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { dashboardSelector } from 'pages/Dashboard/selectors';
import { Wrapper, Item } from './styles';
import { adminAvatar } from 'assets';

const KnowledgeToYourself: React.FC = () => {
  const { directedKnowledge } = useSelector(dashboardSelector);

  return (
    <Wrapper>
      <div className="header pd-10">
        <MessageOutlined className="icon" />
        <span className="title">ナレッジ to 自分</span>
      </div>
      <div className="wrap-items">
        {directedKnowledge.map((value, index) => (
          <Item key={index} className="pd-10" knowledge_type={value.knowledge_type}>
            <img
              src={
                value.author_avt
                  ? URL.createObjectURL(new Blob([value.author_avt.originFileObj as Blob]))
                  : adminAvatar
              }
              className="avt-icon"
              alt="avt-user"
            />
            <div className="info">
              <span className="from-name">From：{value.author_name}</span>
              <span className="content">{value.knowledge_title}</span>
            </div>
            <div className="wrap-label">
              <span className="label">
                {value.knowledge_type === 1
                  ? 'カリキュラム'
                  : value.knowledge_type === 2
                  ? 'Q&A'
                  : 'リコメンド'}
              </span>
            </div>
          </Item>
        ))}
      </div>
    </Wrapper>
  );
};

export default KnowledgeToYourself;
