import styled from 'styled-components';
import { Checkbox, CheckboxProps } from 'formik-antd';

export default styled(Checkbox)<CheckboxProps>`
  color: #000000;
  font-size: 16px;
  .ant-checkbox {
    .ant-checkbox-inner {
      height: 18px;
      width: 18px;
      transform: scaleX(-1);
      border: 2px solid #00000078;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #2666bf;
        border: 2px solid #2666bf;
        &:after {
          width: 11.714286px;
          height: 7.142857px;
          transform: rotate(45deg) scale(1) translate(-50%, -38%);
        }
      }
    }
  }

  .ant-checkbox + span {
    padding-left: 11px;
    padding-right: 0;
  }
`;
