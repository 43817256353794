import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateRolesMasterSchema } from 'libs/validations';
import RolesMasterFrom from 'containers/RolesMasterForm';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { CreateRolesMasterFormik, Roles } from 'types';
import { getRoleDetail, updateRole } from '../thunk';
import EditRolesMasterStyled from './styles';
import { routes } from 'navigations/routes';
import PDFDocument from '../PDFDocument';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';

const EditRolesMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [roleDetail, setRoleDetail] = useState<Roles.ResponseType>();

  const { userInfo } = useSelector(authSelector);

  const { messages } = useIntl();

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formikEdit = useFormik<CreateRolesMasterFormik>({
    initialValues: {
      name: roleDetail?.name || '',
      admin_dashboard_permission: Number(roleDetail?.admin_dashboard_permission) ?? 1,
      skill_check_implementation_permission:
        Number(roleDetail?.skill_check_implementation_permission) ?? 1,
      training_report_permission: Number(roleDetail?.training_report_permission) ?? 1,
      skill_check_report_permission: Number(roleDetail?.skill_check_report_permission) ?? 1,
      my_chart_permission: Number(roleDetail?.my_chart_permission) ?? 1,
      manual_permission: Number(roleDetail?.manual_permission) ?? 1,
      knowledge_permission: Number(roleDetail?.knowledge_permission) ?? 1,
      official_curriculum_permission: Number(roleDetail?.official_curriculum_permission) ?? 1,
      curricullum_master_permission: Number(roleDetail?.curricullum_master_permission) ?? 1,
      question_master_permission: Number(roleDetail?.question_master_permission) ?? 1,
      skill_check_master_permission: Number(roleDetail?.skill_check_master_permission) ?? 1,
      inter_users_master_permission: Number(roleDetail?.inter_users_master_permission) ?? 1,
      groups_master_permission: Number(roleDetail?.groups_master_permission) ?? 1,
      users_master_permission: Number(roleDetail?.users_master_permission) ?? 1,
      roles_master_permission: Number(roleDetail?.roles_master_permission) ?? 1,
      departments_master_permission: Number(roleDetail?.departments_master_permission) ?? 1,
      positions_master_permission: Number(roleDetail?.positions_master_permission) ?? 1,
      admin_master_permission: Number(roleDetail?.admin_master_permission) ?? 1,
      payment_master_permission: Number(roleDetail?.payment_master_permission) ?? 1,
      report_view_permission: Number(roleDetail?.report_view_permission) ?? 1,
    },
    enableReinitialize: true,
    validationSchema: CreateRolesMasterSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!id) return;
      dispatch(startLoading());
      const resultAction = await dispatch(
        updateRole({
          id,
          data: {
            item: { ...values, updatedby: userInfo?.login_id, updatedat: new Date() },
            is_force_update: true,
          },
        })
      );
      dispatch(stopLoading());
      if (updateRole.fulfilled.match(resultAction)) {
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
    },
  });

  const handleExportPDF = async (value: string) => {
    if (value !== 'pdf' || !roleDetail) return;

    const blob = await pdf(<PDFDocument role={roleDetail} />).toBlob();
    saveAs(blob, `${roleDetail.code}_${roleDetail.name}.pdf`);
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    (async () => {
      if (id) {
        dispatch(startLoading());
        const resultAction = await dispatch(
          getRoleDetail({
            conditions: [
              {
                id: 'i_id',
                search_value: [id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 1,
          })
        );
        dispatch(stopLoading());
        if (getRoleDetail.fulfilled.match(resultAction) && resultAction.payload.items[0]) {
          setRoleDetail(resultAction.payload.items[0]);
          return;
        }
      }
      navigate(routes.RolesMaster.path);
    })();
  }, [dispatch, id, navigate]);

  return (
    <EditRolesMasterStyled>
      <Header title={messages['M-03-1'] as string} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            {messages['M-03-2']}
            <br />
            {messages['M-03-3']}。
          </p>
          <div className="btn-div">
            <Button
              className="btn btn-active"
              icon={<CloudDownloadOutlined className="icon-cloud" />}
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              {messages['M-01-10']}
            </Button>
          </div>
        </div>
        <RolesMasterFrom formik={formikEdit} type="edit" />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
        onSubmit={() => navigate(generatePath(routes.RolesMaster.path, { entity: 'receiving' }))}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="権限の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の更新に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
      <PopupConfirmExportFile
        disableCsv
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportPDF}
      />
    </EditRolesMasterStyled>
  );
};

export default EditRolesMaster;
