import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },

  body: {
    paddingHorizontal: 11.7,
    paddingVertical: 5.6,
    flexDirection: 'column',
    gap: 10,
  },

  subscription: {
    paddingVertical: 17.54,
    paddingHorizontal: 11.7,
  },

  textSubscription: {
    fontSize: 7,
    color: '#424242',
  },

  lineBorder: {
    width: 24,
    height: 1,
    backgroundColor: '#dddddd',
    marginLeft: 11.7,
  },

  textCountGeneral: {
    flexDirection: 'row',
    gap: 2,
    alignItems: 'flex-end',
  },

  textCountNumber: {
    fontSize: 8.186,
    color: '#424242',
  },

  textCountDescription: {
    fontSize: 6.432,
    color: '#424242',
  },

  table: {
    flexDirection: 'column',
    textAlign: 'left',
    width: '100%',
    backgroundColor: '#fff',
  },

  tableHeaderRow: {
    backgroundColor: '#ebebeb',
  },

  tableRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 25.728,
    border: '1px solid #e5e5e5',
  },

  wrapCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    padding: 6.35,
    maxLines: 1,
  },

  tableCell: {
    textAlign: 'left',
    fontSize: 7.6,
    fontColor: '#424242',
  },

  headerCell: {
    color: '#2a2a2a',
    fontSize: 7.017,
    fontWeight: 'bold',
  },
});
