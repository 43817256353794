import styled from 'styled-components';

export default styled.section`
  background-color: #f9f8f8;
  .btn-header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    .group-btn-left {
      display: flex;
      align-items: center;
      .btn-un-answer {
        padding: 5px 10px;
        background: #f03060;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        .icon {
          font-size: 16px;
          padding-right: 5px;
        }
      }
      .btn-back {
        padding-left: 10px;
        font-size: 14px;
        color: #777777;
        cursor: pointer;
        border: none;
        .icon {
          color: #c4c4c4;
        }
      }
    }
    .group-btn-right {
      display: flex;
      align-items: flex-end;
      .btn {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        cursor: pointer;
      }
      .like {
        margin-right: 15px;
        font-size: 13px;
        color: #08a3a5;
        .icon {
          padding-right: 5px;
        }
      }
      .star {
        font-size: 12px;
        color: #777777;
        .icon {
          font-size: 16px;
          padding-right: 5px;
          color: #e1e1e1;
        }
      }
      .icon-star,
      .star-gold {
        font-size: 16px;
        padding-right: 10px;
      }
      .star-gold {
        color: #f6ac00 !important;
      }
      .export-btn {
        border: 1px solid #f6ac00;
        color: #f6ac00;
        font-weight: 700;
        padding: 8px 13px;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        height: 36px;
        margin-left: 20px;
      }
    }
  }
  .container {
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin: 20px;
    .wrap-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      .title {
        display: flex;
        align-items: center;
        padding-left: 3px;
        height: 60px;
        .divider {
          height: 95%;
          width: 3px;
          background: #d9d9d9;
          margin-right: 15px;
          border-radius: 2px;
        }
        .title-header {
          display: flex;
          flex-direction: column;
          .label-QA {
            font-size: 11px;
            color: #999999;
          }
          .content-header {
            font-size: 17px;
            color: #2a2a2a;
          }
        }
      }
      .info {
        display: flex;
        align-items: center;
        padding-right: 50px;
        height: 60px;
        .divider {
          height: 100%;
          width: 1px;
          background: #dddddd;
          margin-right: 30px;
        }
        .item-from {
          display: flex;
          align-items: center;
          .avatar {
            position: relative;
            .label-QA {
              width: 36px;
              height: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #08a3a5;
              border-radius: 2px;
              font-size: 11px;
              color: #ffffff;
              position: absolute;
              top: -3px;
              left: -25px;
            }
            .to {
              left: -25px;
            }
            .avt-icon {
              border-radius: 50%;
              padding: 0 5px;
            }
            .avt {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: #eaeaea;
              .icon {
                color: #bbbbbb;
              }
            }
          }
          .item-name {
            .label-name {
              font-size: 11px;
              color: #777777;
            }
            .name {
              max-width: 130px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 13px;
              color: #424242;
            }
          }
        }
        .icon-caret {
          color: #cccccc;
          font-size: 16px;
          padding: 0 30px 0 15px;
        }
      }
    }
    .description {
      margin: 0;
      padding: 40px 20px 0 20px;
    }
    .wrap-body {
      padding: 40px 20px;
      .content {
        .title {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 14px;
          color: #2a2a2a;
          .divider {
            width: 1px;
            height: 10px;
            background: #cccccc;
            margin: 0 5px;
          }
        }
        .question-content {
          padding: 30px 0;
          margin: 0;
        }
      }
    }
  }
`;
