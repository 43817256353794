import React from 'react';

import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Bar } from '@ant-design/plots';
import { isNil } from 'lodash';
import { Table } from 'antd';

import { ITEM_COMPARE_BAR_CHART, ITEM_COMPARE_PIE_CHART } from 'constant/select.constants';
import { skillCheckUserReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';
import StyledTableChart from './styles';
import * as Types from 'types';

interface Props {
  isUserTransTable: boolean;
  exportPdf?: boolean;
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
}

const TableChart: React.FC<Props> = ({ isUserTransTable, itemComparision, exportPdf }) => {
  const { reportSkillCheckUserTrans, totalReportSkillCheckUserTrans } = useSelector(
    skillCheckUserReportSelector
  );

  const columns: ColumnsType<Types.ReportSkillCheckUserTrans.UserReportSurfaceTable> = [
    {
      title: 'No.',
      width: '3%',
      align: 'center',
      render: (_, _record, index) => index + 1,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'index',
                align: 'center',
                width: '1%',
                render: (_, _record, index) => index + 1,
              },
            ]
          : undefined,
    },
    {
      title: 'スキルチェック名',
      dataIndex: isUserTransTable ? 'user_name' : 'skill_check_name',
      key: isUserTransTable ? 'user_name' : 'skill_check_name',
      align: 'left',
      width: '70%',
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: 'ユーザー平均',
                dataIndex: 'user_name',
                key: 'user_name',
                align: 'center',
                width: '70%',
              },
            ]
          : undefined,
    },
    {
      title: itemComparision === 'correct_answer_rate' ? '正解率' : '進捗率',
      align: 'center',
      dataIndex: '',
      key: '',
      width: '27%',
      render: (_: string, record) => {
        return (
          <span>
            {itemComparision === 'correct_answer_rate'
              ? formatNumber(record.accuracy_rate * 100)
              : formatNumber((record.responses_num / record.probs_count) * 100)}
            %
          </span>
        );
      },
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title:
                  itemComparision === 'correct_answer_rate'
                    ? `${formatNumber(reportSkillCheckUserTrans[0].accuracy_rate * 100)}%`
                    : `${formatNumber(
                        (reportSkillCheckUserTrans[0].responses_num /
                          reportSkillCheckUserTrans[0].probs_count) *
                          100
                      )}%`,
                align: 'center',
                dataIndex: 'progress_rate',
                key: 'progress_rate',
                width: '27%',
                render: (_, record) =>
                  isNil(record.responses_num) ? (
                    <span style={{ color: '#F0330A' }}>未実施</span>
                  ) : (
                    <span>
                      {itemComparision === 'correct_answer_rate'
                        ? formatNumber(record.accuracy_rate * 100)
                        : formatNumber((record.responses_num / record.probs_count) * 100)}
                      %
                    </span>
                  ),
              },
            ]
          : undefined,
    },
  ];
  return (
    <StyledTableChart>
      <div className="container">
        <Table
          rowKey="index"
          rowClassName={(_, index) => (index % 2 === 1 ? 'table-row-hightlight' : '')}
          className="table"
          columns={columns}
          dataSource={reportSkillCheckUserTrans
            .map((item, index) => ({
              ...item,
              index,
            }))
            .slice(1)}
          bordered
          pagination={false}
        />
        <div className="chart">
          <div className="header-column" />
          <Bar
            data={reportSkillCheckUserTrans
              .slice(isUserTransTable ? 0 : 1)
              .map((data) => [
                itemComparision === 'correct_answer_rate'
                  ? {
                      key: isUserTransTable ? data.login_id : data.skill_check_code,
                      name: isUserTransTable ? data.user_name : data.skill_check_name,
                      value: Number(formatNumber(data.accuracy_rate * 100)),
                      type: '正解率',
                    }
                  : {
                      key: isUserTransTable ? data.login_id : data.skill_check_code,
                      name: isUserTransTable ? data.user_name : data.skill_check_name,
                      value: Number(formatNumber((data.responses_num / data.probs_count) * 100)),
                      type: '進捗率',
                    },
              ])
              .flat()}
            isStack
            xField="value"
            yField="key"
            seriesField="type"
            autoFit
            padding={[20, 0, 0, 0]}
            xAxis={{
              position: 'left',
              min: 0,
              max: 100,
              grid: {
                line: {
                  style: {
                    stroke: '#ddd',
                    lineDash: [2, 2],
                  },
                },
              },
              label: {
                formatter: (_item, _, index) => (index === 5 ? '50%' : index === 10 ? '100%' : ''),
                style: {
                  fontSize: 11,
                  textAlign: 'right',
                },
              },
              showLast: true,
              tickCount: 10,
            }}
            yAxis={{
              label: null,
            }}
            color={['#8FD692']}
            legend={false}
            maxBarWidth={12}
            style={{
              width: '100%',
              height:
                39 *
                  reportSkillCheckUserTrans.slice(
                    totalReportSkillCheckUserTrans && isUserTransTable ? 0 : 1
                  ).length +
                19,
            }}
          />
        </div>
      </div>
    </StyledTableChart>
  );
};

export default TableChart;
