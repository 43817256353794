import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '../index';

interface Props {
  title: string;
  visible: boolean;
  subTitle?: string | React.ReactNode;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CompletedModal: React.FC<Props> = ({ title, subTitle, visible, setVisible, onSubmit }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    handleToggleModal();
    onSubmit && (await onSubmit());
  };

  return (
    <Modal
      visible={visible}
      width={450}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
    >
      <SectionStyled>
        <p className="title">
          <CheckCircleOutlined className="icon" />
          {title}
        </p>
        {subTitle && <span className="sub-title">{subTitle}</span>}
      </SectionStyled>
    </Modal>
  );
};

export default CompletedModal;
