import React from 'react';
import { InputProps } from 'formik-antd';

import InputStyled from './styles';

const Input: React.FC<InputProps> = (props) => {
  return <InputStyled suffix={<span />} {...props} />;
};

export default Input;
