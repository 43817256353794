import styled from 'styled-components';

export default styled.section`
  background-color: #ffff;

  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
      font-size: 14px;
      font-weight: 400;
    }
    .ant-table-thead tr:first-child .ant-table-cell {
      font-size: 12px;
      padding: 15px 2px;

      .ant-table-column-sorters {
        justify-content: center;
        .ant-table-column-title {
          flex: none;
        }
      }
    }
    .ant-table-tbody .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr .average {
      background: #f4fbfb;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
    .ant-table-row:nth-child(even) {
      background: #f9f9f9;
    }
  }

  .table-row-dark {
    background: #ffffff;
  }
  .table-row-dark {
    background: #f9f9f9;
  }
  .ant-table-tbody .ant-table-cell.average_answer_time {
    font-family: Lato;
    font-size: 20px;
    text-align: center;
    color: #2a2a2a;
  }
  .ant-table-tbody .ant-table-cell.acquisition_score {
    font-family: Lato;
    font-size: 20px;
    text-align: center;
    color: #2a2a2a;
  }
  .ant-table-tbody .ant-table-cell {
    font-family: Lato;
  }
  .ant-table-tbody .ant-table-cell.question {
    font-size: 14px;
    text-align: left;
    color: #424242;
  }

  .ant-table-cell {
    text-align: center;
  }

  .ant-pagination-total-text {
    left: auto !important;
    right: 0;
  }

  .ant-pagination-options {
    position: absolute;
    left: 0;

    ::before {
      content: '表示件数：';
      font-size: 11px;
      padding-right: 3px;
    }
  }
`;
