import React, { useState } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckAnalysisReportSelector } from '../../selectors';
import QuestionDetail from '../../../../QuestionDetail';
import Wrapper from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  isQuestionCodeTable: boolean;
  startPeriod?: string;
  exportPdf?: boolean;
  endPeriod?: string;
}

const UserAnalysisReport: React.FC<Props> = ({
  isQuestionCodeTable,
  startPeriod,
  endPeriod,
  exportPdf,
}) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [questionDetail, setQuestionDetail] = useState<{
    visible: boolean;
    question_id?: string;
  }>({
    visible: false,
  });

  const { reportSkillCheckAnalysis, totalReportSkillCheckAnalysis } = useSelector(
    skillCheckAnalysisReportSelector
  );

  const columns: ColumnsType<Types.ReportSkillCheck.SkillCheckAnalysisReportTable> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '2%',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
      children: isQuestionCodeTable
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'index',
              align: 'center',
              width: '1%',
              render: (_text, _record, index) => (
                <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
                  {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
                </span>
              ),
            },
          ]
        : undefined,
    },
    {
      key: 'question_name',
      dataIndex: 'question_name',
      title: '設問',
      width: '20%',
      children: isQuestionCodeTable
        ? [
            {
              className: 'average br-1',
              title: '合計',
              dataIndex: 'question_name',
              key: 'question_name',
              width: '20%',
            },
          ]
        : undefined,
    },
    {
      key: 'question_id',
      dataIndex: 'question_id',
      align: 'center',
      width: '2%',
      title: (
        <span>
          設
          <br />
          問
          <br />
          詳
          <br />細
        </span>
      ),
      render: (question_id) => (
        <FileTextOutlined
          className="icon"
          onClick={() =>
            setQuestionDetail({
              visible: true,
              question_id,
            })
          }
        />
      ),
      children: isQuestionCodeTable
        ? [
            {
              className: 'average br-1',
              key: 'question_id',
              dataIndex: 'question_id',
              align: 'center',
              width: '2%',
              render: (question_id) => (
                <FileTextOutlined
                  className="icon"
                  onClick={() =>
                    setQuestionDetail({
                      visible: true,
                      question_id,
                    })
                  }
                />
              ),
            },
          ]
        : undefined,
    },
    {
      title: `実施期間①：${startPeriod ? dayjs(startPeriod).format('YYYY年MM月DD日') : ''}`,
      className: 'implementation-period',
      width: '27%',
      children: [
        {
          title: '正解率',
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(
                          (reportSkillCheckAnalysis[0]?.start.accuracy_rate || 0) * 100
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.start.accuracy_rate - b.start.accuracy_rate,
        },
        {
          title: (
            <>
              不正
              <br />
              解率
            </>
          ),
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber((reportSkillCheckAnalysis[0]?.start.wrong_rate || 0) * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.start.wrong_rate - b.start.wrong_rate,
        },
        {
          title: (
            <>
              未経
              <br />
              験率
            </>
          ),
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(
                          (reportSkillCheckAnalysis[0]?.start.inexperienced_rate || 0) * 100
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.start.inexperienced_rate - b.start.inexperienced_rate,
        },
        {
          title: (
            <>
              設問あたり
              <br />
              平均解答時間
            </>
          ),
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          width: '7%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table jc-center">
              <p className="label-number">
                {formatNumberToMinute(Number(item.average_answer_time_per_question))}
              </p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumberToMinute(
                          reportSkillCheckAnalysis[0]?.start.average_answer_time_per_question
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '7%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table jc-center">
                      <p className="label-number">
                        {formatNumberToMinute(item.average_answer_time_per_question)}
                      </p>
                    </div>
                  ),
                },
              ]
            : undefined,
        },
        {
          title: (
            <>
              獲得ス
              <br />
              コア率
            </>
          ),
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.acquisition_score_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(
                          (reportSkillCheckAnalysis[0]?.start.acquisition_score_rate || 0) * 100
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(item.acquisition_score_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.start.acquisition_score_rate - b.start.acquisition_score_rate,
        },
      ],
    },
    {
      title: `実施期間②：${endPeriod ? dayjs(endPeriod).format('YYYY年MM月DD日') : ''}`,
      className: 'implementation-period',
      width: '27%',
      children: [
        {
          title: '正解率',
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.end.accuracy_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.end?.accuracy_rate - b.end?.accuracy_rate,
        },
        {
          title: (
            <>
              不正
              <br />
              解率
            </>
          ),
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.end.wrong_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.end?.wrong_rate - b.end?.wrong_rate,
        },
        {
          title: (
            <>
              未経
              <br />
              験率
            </>
          ),
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.end.inexperienced_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.end?.inexperienced_rate - b.end?.inexperienced_rate,
        },
        {
          title: (
            <>
              設問あたり
              <br />
              平均解答時間
            </>
          ),
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          width: '7%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table jc-center">
              <p className="label-number">
                {formatNumberToMinute(item.average_answer_time_per_question)}
              </p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumberToMinute(
                          reportSkillCheckAnalysis[0]?.end.average_answer_time_per_question
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '7%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table jc-center">
                      <p className="label-number">
                        {formatNumberToMinute(item.average_answer_time_per_question)}
                      </p>
                    </div>
                  ),
                },
              ]
            : undefined,
        },
        {
          title: (
            <>
              獲得ス
              <br />
              コア率
            </>
          ),
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.acquisition_score_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(
                          reportSkillCheckAnalysis[0]?.end.acquisition_score_rate * 100
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(item.acquisition_score_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.end?.acquisition_score_rate - b.end?.acquisition_score_rate,
        },
      ],
    },
    {
      title: '増減',
      className: 'order',
      width: '22%',
      children: [
        {
          title: '正解率',
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table color-green">
              <p className="label-number">
                {item.accuracy_rate > 0 ? '+' : ''}
                {formatNumber(item.accuracy_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.change.accuracy_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table color-green">
                      <p className="label-number">
                        {item.accuracy_rate > 0 ? '+' : ''}
                        {formatNumber(item.accuracy_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.change.accuracy_rate - b.change.accuracy_rate,
        },
        {
          title: (
            <>
              不正
              <br />
              解率
            </>
          ),
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table color-green">
              <p className="label-number">
                {item.wrong_rate > 0 ? '+' : ''}
                {formatNumber(item.wrong_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.change.wrong_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table color-green">
                      <p className="label-number">
                        {item.wrong_rate > 0 ? '+' : ''}
                        {formatNumber(item.wrong_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.change.wrong_rate - b.change.wrong_rate,
        },
        {
          title: (
            <>
              未経
              <br />
              験率
            </>
          ),
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table color-green">
              <p className="label-number">
                {item.inexperienced_rate > 0 ? '+' : ''}
                {formatNumber(item.inexperienced_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumber(reportSkillCheckAnalysis[0]?.change.inexperienced_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '5%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table color-green">
                      <p className="label-number">
                        {item.inexperienced_rate > 0 ? '+' : ''}
                        {formatNumber(item.inexperienced_rate * 100)}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                },
              ]
            : undefined,
          sorter: (a, b) => a.change.inexperienced_rate - b.change.inexperienced_rate,
        },
        {
          title: (
            <>
              設問あたり
              <br />
              平均解答時間
            </>
          ),
          dataIndex: 'change',
          key: 'change',
          align: 'center',
          width: '7%',
          render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
            <div className="item-table jc-center">
              <p className="label-number">
                {item.average_answer_time_per_question > 0 ? '+' : ''}
                {formatNumberToMinute(item.average_answer_time_per_question)}
              </p>
            </div>
          ),
          children: isQuestionCodeTable
            ? [
                {
                  className: 'average',
                  title: (
                    <div className="item-table">
                      <p className="label-number">
                        {formatNumberToMinute(
                          reportSkillCheckAnalysis[0]?.change.average_answer_time_per_question
                        )}
                      </p>
                      <p className="label">%</p>
                    </div>
                  ),
                  dataIndex: 'start',
                  key: 'start',
                  align: 'right',
                  width: '7%',
                  render: (item: Types.ReportSkillCheck.AnalysisItemType) => (
                    <div className="item-table jc-center">
                      <p className="label-number">
                        {item.average_answer_time_per_question > 0 ? '+' : ''}
                        {formatNumberToMinute(item.average_answer_time_per_question)}
                      </p>
                    </div>
                  ),
                },
              ]
            : undefined,
        },
      ],
    },
  ];

  return (
    <Wrapper>
      {totalReportSkillCheckAnalysis ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportSkillCheckAnalysis} <span className="text-static"> 件表示</span> /
                {totalReportSkillCheckAnalysis}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportSkillCheckAnalysis}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}

      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          exportPdf
            ? reportSkillCheckAnalysis.map((item, index) => ({ ...item, index }))
            : reportSkillCheckAnalysis
                .map((item, index) => ({ ...item, index }))
                .slice((page - 1) * pageSize, page * pageSize)
        }
        columns={columns}
        bordered
        pagination={false}
      />
      <QuestionDetail visible={questionDetail} setVisible={setQuestionDetail} />
    </Wrapper>
  );
};

export default UserAnalysisReport;
