import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { Radio, Button, Form, Modal } from 'antd';
import { differenceBy, find, get } from 'lodash';
import JoditEditor from 'jodit-pro-react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CloseOutlined,
  CloudDownloadOutlined,
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { IconEditLocked, LogoNavSmall, IconPublishOpen } from 'assets';
import { settingSelector } from 'containers/AppSettings/selectors';
import { handleUpdateParentFolderManual } from 'libs/utils/manual';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { createMarkup } from 'libs/utils/createMarkup';
import { Header, Input, TextArea } from 'components';
import useImagePreview from 'hooks/useImagePreview';
import ModalSelectStorageSaveManual from './Modal';
import { manualSchema } from 'libs/validations';
import { manualSelector } from '../selectors';
import CreateEditManualStyled from './styles';
import { routes } from 'navigations/routes';
import { MANUAL_FOLDER } from 'configs';
import { useAppDispatch } from 'hooks';
import VideoPopup from './UploadVideo';
import { Manual } from 'types';
import {
  createFileAttach,
  createManualSection,
  deleteManualSection,
  getDetailManual,
  updateManual,
  updateManualSection,
} from '../thunk';
import { useCheckUpload } from 'hooks/useCheckUpload';

interface Props {
  isMyManual?: boolean;
}

const CreateEditManual: React.FC<Props> = ({ isMyManual }) => {
  const { handlePreviewImage, ImagePreview } = useImagePreview();

  const [visibleModalDownload, setVisibleModalDownload] = useState<boolean>(false);
  const [visibleModalComplete, setVisibleModalComplete] = useState<boolean>(false);
  const [indexSection, setIndexSection] = useState<number>(0);
  const [isViewMode, setViewMode] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [openModalSelectStorageSaveManual, setOpenModalSelectStorageSaveManual] =
    useState<boolean>(false);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { manualDetail } = useSelector(manualSelector);
  const { userInfo } = useSelector(authSelector);

  const { isDisabledUpload } = useCheckUpload();
  const { id } = useParams<{ id: string }>();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const formik = useFormik<Manual.ManualFormik>({
    initialValues:
      id && manualDetail
        ? manualDetail
        : {
            title: '',
            description: '',
            version: 1.0,
            folder_id: '',
            publish: 0,
            sections: [
              {
                section_name: '',
                text: '',
              },
            ],
          },
    enableReinitialize: true,
    validationSchema: manualSchema,
    validateOnBlur: false,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      if (!userInfo) return;
      dispatch(startLoading());
      if (isEdit) {
        if (!manualDetail) return;
        const resultAction = await dispatch(
          updateManual({
            id: values.i_id!,
            data: {
              item: {
                manual_title: values.title,
                description: values.description,
                version: values.version,
                publish: values.publish,
                updatedby: userInfo.login_id,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );

        if (updateManual.fulfilled.match(resultAction)) {
          const folder_i_id = get(
            find(manualDetail.item_links?.links, { d_id: MANUAL_FOLDER.id }),
            'items[0].i_id',
            undefined
          );

          folder_i_id && handleUpdateParentFolderManual(folder_i_id, dispatch);

          const listDeleteIem = differenceBy(manualDetail.sections, values.sections, 'i_id');
          const resultAction2 = await Promise.all([
            ...values.sections.map((section, index) =>
              section.i_id
                ? dispatch(
                    updateManualSection({
                      id: section.i_id,
                      data: {
                        item: {
                          section_name: section.section_name,
                          text: section.text,
                          display_order: index,
                          updatedby: userInfo.login_id,
                          updatedat: new Date(),
                        },
                        is_force_update: true,
                      },
                    })
                  )
                : dispatch(
                    createManualSection({
                      item: {
                        company_id: userInfo.company_id,
                        manual_id: values.manual_id,
                        section_name: section.section_name,
                        text: formik.values.sections[indexSection]?.text,
                        display_order: index,
                        createdby: userInfo.login_id,
                        createdat: new Date(),
                      },
                    })
                  )
            ),
            ...listDeleteIem.map((i) => dispatch(deleteManualSection({ id: i.i_id! }))),
          ]);
          if (
            resultAction2.every(
              (result) =>
                updateManualSection.fulfilled.match(result) ||
                createManualSection.fulfilled.match(result) ||
                deleteManualSection.fulfilled.match(result)
            )
          ) {
            setVisibleModalComplete(true);
          }
        }
      } else {
        setOpenModalSelectStorageSaveManual(true);
      }
      dispatch(stopLoading());
      setSubmitting(false);
    },
  });

  const handleExportCSV = () => {
    // if (value === 'csv') {
    //   const resultAction = await dispatch(
    //     curriculumExportDataCSV({
    //       include_lookups: true,
    //       page: 1,
    //       per_page: 0,
    //     })
    //   );
    //   if (curriculumExportDataCSV.fulfilled.match(resultAction)) {
    //     exportDataCurricullumRef.current?.link.click();
    //   }
    // }
    setVisibleModalDownload(false);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block', 'add-new-line'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      readonly: id ? !isEdit : false,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    [isEdit, id]
  );

  useEffect(() => {
    if (!id || !userInfo) return;
    (async () => {
      dispatch(startLoading());
      setViewMode(true);
      const resultAction = await dispatch(
        getDetailManual({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'i_id',
              search_value: [id],
              exact_match: true,
            },
          ],
          sort_fields: [
            {
              id: 'display_order',
              order: 'asc',
            },
          ],
          include_links: true,
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      if (getDetailManual.rejected.match(resultAction) || !resultAction.payload.items.length) {
        navigate(isMyManual ? routes.MyManual.path : routes.Manual.path);
      }
      dispatch(stopLoading());
    })();
  }, [dispatch, id, navigate, userInfo, isMyManual]);

  const base64Array: Array<string> = useMemo(() => {
    if (!formik.values.sections || indexSection >= formik.values.sections.length) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.sections[indexSection].text, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [formik.values.sections, indexSection]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '4',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.sections[indexSection]?.text.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      formik.setFieldValue(`sections.${indexSection}.text`, replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  return (
    <CreateEditManualStyled
      collapsedMenu={collapsedMenu}
      showButton={!isViewMode || (isViewMode && isEdit)}
      errors={formik.errors?.version || ''}
      isCheckUpload={isDisabledUpload}
    >
      <Header title={headerTitle} />
      <FormikProvider value={formik}>
        <Form>
          {isViewMode && !isEdit ? (
            <div className="file-info">
              <div className="left-side">
                <img
                  className="icon-status"
                  src={
                    (isViewMode || isEdit) && formik.values.publish
                      ? IconPublishOpen
                      : IconEditLocked
                  }
                  alt="icon-publish"
                />
                <div className="wrap-info">
                  <div className="info">
                    <img className="logo-file" src={LogoNavSmall} alt="file-icon" />
                    <span className="file-name">{formik.values.title}</span>
                  </div>
                  <p className="description">{formik.values.description}</p>
                </div>
              </div>
              <div className="right-side">
                <span className="version">バージョン：v {formik.values.version}</span>
                <Button
                  htmlType="button"
                  className="btn"
                  onClick={() => setVisibleModalDownload(true)}
                >
                  <CloudDownloadOutlined />
                  エクスポート
                </Button>
                <Button
                  disabled={location.state === 1}
                  htmlType="button"
                  className={`btn ${location.state === 1 ? 'disabled' : 'btn-edit'}`}
                  onClick={() => setIsEdit(true)}
                >
                  <FormOutlined />
                  編集する
                </Button>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => navigate(isMyManual ? routes.MyManual.path : routes.Manual.path)}
                >
                  <CloseOutlined className="close-icon" />
                  <span className="close-up">閉じる</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="while-editing">
              <img
                src={
                  (isViewMode || isEdit) && formik.values.publish ? IconPublishOpen : IconEditLocked
                }
                alt="icon-publish"
              />
              <div className="wrap-input">
                <div className="wrap-input-version">
                  <Form.Item
                    className={`title ${formik.errors.title ? 'title_error' : ''}`}
                    label={<img className="logo-label" src={LogoNavSmall} alt="logo" />}
                  >
                    <Input
                      fast={true}
                      name="title"
                      placeholder="【タイトル】最大60文字"
                      maxLength={60}
                    />
                  </Form.Item>
                  <Form.Item name="version" className="version" label="バーション:">
                    <Input name="version" />
                    <div className="version-errors">{formik.errors?.version}</div>
                  </Form.Item>
                </div>
                <Form.Item className="description" label="概要">
                  <TextArea name="description" rows={2} placeholder="最大480文字" maxLength={480} />
                </Form.Item>
              </div>
            </div>
          )}
          <FieldArray
            name="sections"
            render={({ remove, push }) => (
              <div className="wrap-editor">
                <div className="left-side">
                  <div
                    className={`title-editor ${
                      !isViewMode && formik.errors.sections && formik.errors.sections[indexSection]
                        ? 'title-editor-error'
                        : ''
                    }`}
                  >
                    <div className="no-editor">{indexSection + 1}</div>
                    <Input
                      name={`sections.${indexSection}.section_name`}
                      className="input"
                      suffix={null}
                      placeholder="セクション名を入力..."
                      readOnly={isViewMode && !isEdit}
                    />
                  </div>
                  {id && !isEdit ? (
                    <div className="wrap-view-jodit">
                      <div
                        className="content"
                        style={{
                          display: 'block',
                        }}
                        dangerouslySetInnerHTML={createMarkup(
                          formik.values.sections[indexSection].text
                        )}
                        onClick={handlePreviewImage}
                      />
                    </div>
                  ) : (
                    <JoditEditor
                      value={formik.values.sections[indexSection]?.text}
                      config={defaultConfig}
                      onBlur={(newValue) =>
                        formik.setFieldValue(`sections.${indexSection}.text`, newValue)
                      }
                      onChange={(newValue) => {
                        formik.setFieldValue(`sections.${indexSection}.text`, newValue);
                      }}
                    />
                  )}

                  <ImagePreview />

                  {showPopup && (
                    <Modal
                      visible={showPopup}
                      closable={false}
                      onOk={handlePopupClose}
                      onCancel={handlePopupClose}
                      footer={null}
                    >
                      <VideoPopup
                        indexSection={indexSection}
                        formik={formik}
                        setOpen={setShowPopup}
                      />
                    </Modal>
                  )}
                  <div className="wrap-bottom-editor">
                    <div className="wrap-button-editor">
                      <Button
                        className="btn button-prev"
                        htmlType="button"
                        disabled={indexSection === 0}
                        onClick={() => setIndexSection((prevState) => prevState - 1)}
                      >
                        <CaretLeftOutlined />
                        前のセクション
                      </Button>
                      <Button
                        className="btn button-next"
                        htmlType="button"
                        disabled={indexSection === formik.values.sections.length - 1}
                        onClick={() => setIndexSection((prevState) => prevState + 1)}
                      >
                        次のセクション
                        <CaretRightOutlined />
                      </Button>
                    </div>
                    {!isViewMode || (isViewMode && isEdit) ? (
                      <button
                        onClick={() => {
                          setIndexSection((prevState) => (prevState > 0 ? prevState - 1 : 0));
                          remove(indexSection);
                        }}
                        type="button"
                        className="delete-editor"
                        disabled={formik.values.sections.length === 1}
                      >
                        <DeleteOutlined className="icon-delete" />
                        <span>このセクションを削除</span>
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="right-side">
                  <div className="right-top">
                    <span className="title-right">セクション</span>
                    {!isViewMode || (isViewMode && isEdit) ? (
                      <div className="wrap-button-add">
                        <Button
                          className="add-section"
                          htmlType="button"
                          onClick={() =>
                            push({
                              section_name: '',
                              text: '',
                            })
                          }
                        >
                          + セクションの追加
                        </Button>
                      </div>
                    ) : null}
                  </div>

                  <div className="dashed-line" />
                  <div className="wrap-radio">
                    {isViewMode && !isEdit ? (
                      <div className="view-mode">
                        {formik.values.sections.map((_, index) => (
                          <div
                            key={index}
                            onClick={() => setIndexSection(index)}
                            className={`input-radio ${index === indexSection && 'section-checked'}`}
                          >
                            <span className="label-radio">{index + 1}</span>
                            <span className="section-name">
                              {formik.values.sections[index].section_name}
                            </span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Radio.Group
                        className="list-section"
                        onChange={(e) => setIndexSection(e.target.value)}
                        value={indexSection}
                      >
                        {formik.values.sections.map((_, index) => (
                          <div
                            className={`input-radio ${
                              !isViewMode && formik.errors.sections && formik.errors.sections[index]
                                ? 'error'
                                : ''
                            } ${index === indexSection && 'section-checked'}`}
                            key={index}
                          >
                            <span className="label-radio">{index + 1}</span>
                            <Input
                              fast={true}
                              placeholder="セクション名を入力..."
                              bordered={false}
                              name={`sections.${index}.section_name`}
                              onClick={() => setIndexSection(index)}
                              suffix={<Radio value={index} />}
                            />
                          </div>
                        ))}
                      </Radio.Group>
                    )}
                  </div>
                </div>
              </div>
            )}
          />
          {!isViewMode || (isViewMode && isEdit) ? (
            <div className="wrap-submit">
              <div className="wrap-button">
                <Button
                  className="btn btn_outline"
                  htmlType="button"
                  loading={formik.isSubmitting}
                  onClick={() => {
                    formik.setFieldValue('publish', 0);
                    formik.handleSubmit();
                  }}
                >
                  一時保存
                </Button>
                <Button
                  className="btn btn_submit"
                  htmlType="button"
                  loading={formik.isSubmitting}
                  onClick={() => {
                    formik.setFieldValue('publish', 1);
                    formik.handleSubmit();
                  }}
                >
                  公開
                </Button>
                <Button
                  className="btn btn_close"
                  htmlType="button"
                  onClick={() => {
                    isEdit
                      ? setIsEdit(false)
                      : navigate(isMyManual ? routes.MyManual.path : routes.Manual.path, {
                          replace: true,
                        });
                    formik.resetForm();
                  }}
                >
                  キャンセル
                </Button>
              </div>
            </div>
          ) : null}
        </Form>
        <ModalSelectStorageSaveManual
          isMyManual={false}
          visible={openModalSelectStorageSaveManual}
          setVisible={setOpenModalSelectStorageSaveManual}
        />
        <PopupConfirmExportFile
          visible={visibleModalDownload}
          setVisible={setVisibleModalDownload}
          onSubmit={handleExportCSV}
        />
        <CompletedModal
          title={formik.values.publish ? '公開しました' : '一時保存が完了しました'}
          subTitle={formik.values.publish ? undefined : 'ステータスは編集中として表示されます。'}
          visible={visibleModalComplete}
          setVisible={setVisibleModalComplete}
          onSubmit={() => navigate(isMyManual ? routes.MyManual.path : routes.Manual.path)}
        />
      </FormikProvider>
    </CreateEditManualStyled>
  );
};

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];

export default CreateEditManual;
