import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  .wrap_node_tree {
    display: flex;
    flex-direction: row;
    position: relative;
    .column {
      width: 250px;
      user-select: none;
    }
  }
`;

interface Props {
  nodeHeight: number;
}

export const EmptyWrapper = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.nodeHeight}px;
`;
