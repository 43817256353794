import React from 'react';
import { Steps } from 'antd';
import { useIntl } from 'react-intl';

import { LIST_TITLE_HEADER, LIST_TITLE_HEADER_INFORMATION_REGISTER } from 'constant';
import { Wrapper } from './styles';

const { Step } = Steps;

interface Props {
  currentStep: number;
  planSelectedIndex: number | undefined;
  success: boolean;
}

const HeaderInformationRegister: React.FC<Props> = ({
  currentStep,
  planSelectedIndex,
  success,
}) => {
  const { messages } = useIntl();
  return (
    <Wrapper planSelectedIndex={planSelectedIndex} success={success}>
      <div className="header-information-register">
        <Steps
          current={currentStep}
          className={`site-navigation-steps ${currentStep === 0 ? 'display-none' : ''}`}
        >
          {planSelectedIndex === 1
            ? LIST_TITLE_HEADER_INFORMATION_REGISTER.map((text, index) => (
                <Step key={index} title={messages[text]} />
              ))
            : LIST_TITLE_HEADER.map((text, index) => <Step key={index} title={messages[text]} />)}
        </Steps>
        <div className="wrap-step-info">
          <span className="step">Step {currentStep + 1}:</span>
          <span className="step-name">
            {
              messages[
                planSelectedIndex === 1
                  ? LIST_TITLE_HEADER_INFORMATION_REGISTER[currentStep]
                  : LIST_TITLE_HEADER[currentStep]
              ]
            }
          </span>
        </div>
      </div>
    </Wrapper>
  );
};

export default HeaderInformationRegister;
