import React, {
  createRef,
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { WrapAlert } from './styles';

interface AlertCustomRef {
  showAlert: ({
    children,
    style,
    timeout,
  }: {
    children: React.ReactNode;
    style?: CSSProperties;
    timeout?: number;
  }) => void;
}

export const alertCustomRef = createRef<AlertCustomRef>();

const AlertCustom = forwardRef<AlertCustomRef>((_, ref) => {
  const [visibleAlert, setVisibleAlert] = useState<boolean>(false);
  const [wrapStyle, setWrapStyle] = useState<CSSProperties>();
  const [timeoutNum, setTimeoutNum] = useState<number>(1000);
  const [child, setChildren] = useState<React.ReactNode>();

  useEffect(() => {
    const onClick = () => {
      if (visibleAlert) {
        setVisibleAlert(false);
      }
    };

    // eslint-disable-next-line no-undef
    let timeout: NodeJS.Timer;

    if (visibleAlert) {
      document.addEventListener('click', onClick);
      timeout = setTimeout(() => {
        setVisibleAlert(false);
      }, timeoutNum);
    }

    return () => {
      document.removeEventListener('click', onClick);
      clearTimeout(timeout);
    };
  }, [visibleAlert, timeoutNum]);

  useImperativeHandle(ref, () => ({
    showAlert({ children, style, timeout }) {
      timeout && setTimeoutNum(timeout);
      setChildren(children);
      setWrapStyle(style);
      setVisibleAlert(true);
    },
  }));

  return visibleAlert ? <WrapAlert style={wrapStyle}>{child}</WrapAlert> : null;
});

export default AlertCustom;
