import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/officialCurriculum/memoized-tree-data-utils';
import * as Types from 'types';
import {
  getOfficialCurriculumHierarchyList,
  getSelectOfficialCurriculumProvider,
  getDataOfficialCurriculumList,
  getDataProductionCurriculum,
  getSelectOfficialCurriculum,
  getCurriculum,
  getDataReleaseNoteManagement,
  getDataReleaseNoteDetail,
  getDataProviderInformation,
  getDataReleaseNoteID,
  getCurriculumPublishedHistory,
} from './thunk';

export interface InitialState {
  dataCurriculumHierarchy: Array<Types.TreeItem<Types.CurriculumHierarchy.DataTreeConverted>>;
  officialCurriculumProviderSelect: Array<Types.SelectOfficialCurriculumProvider.ResponseType>;
  dataOfficialCurriculumList: Array<Types.OfficialCurriculumList2.ResponseType>;
  dataSelectOfficialCurriculum: Array<Types.SelectOfficialCurriculumName.ResponseType>;
  dataInformationCurriculum: Array<Types.CurriculumTransInformation.ResponseType>;
  dataReleaseNoteManagement: Array<Types.ReleaseNoteManagement.ResponseType>;
  data1: Array<Types.ReleaseNoteManagement.ResponseType>;
  dataFuture: Array<Types.ReleaseNoteManagement.ResponseType>;
  dataUnUpdate: Array<Types.ReleaseNoteManagement.ResponseType>;
  dataKeepUpdate: Array<Types.ReleaseNoteManagement.ResponseType>;
  dataReleaseNoteDetail: Array<Types.ReleaseNoteDetail.ResponseType>;
  dataProductionCurriculum: Array<Types.SkillCheckImple.ResponseType>;
  officialCurriculumHierarchyList: Array<
    Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>
  >;
  total: number;
  dataReleaseNoteID: Array<Types.ReleaseNote.ResponseType>;
  curriculumPublishedHistory: Array<Types.CurriculumPublishedHistory.ResponseType>;
}

const initialState: InitialState = {
  officialCurriculumHierarchyList: [],
  officialCurriculumProviderSelect: [],
  dataSelectOfficialCurriculum: [],
  dataOfficialCurriculumList: [],
  dataInformationCurriculum: [],
  dataProductionCurriculum: [],
  dataCurriculumHierarchy: [],
  dataReleaseNoteManagement: [],
  dataReleaseNoteDetail: [],
  data1: [],
  dataFuture: [],
  dataUnUpdate: [],
  dataKeepUpdate: [],
  total: 0,
  dataReleaseNoteID: [],
  curriculumPublishedHistory: [],
};

export const officialCurriculumSlice = createSlice({
  name: 'officialCurriculum-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelectOfficialCurriculum.fulfilled, (state, action) => {
      state.dataSelectOfficialCurriculum = action.payload.report_results;
    });
    builder.addCase(getSelectOfficialCurriculumProvider.fulfilled, (state, action) => {
      state.officialCurriculumProviderSelect = action.payload.report_results;
    });
    builder.addCase(getDataOfficialCurriculumList.fulfilled, (state, action) => {
      state.dataOfficialCurriculumList = unionBy(
        action.payload.report_results,
        'official_curriculum_code'
      );
      state.total = action.payload.totalItems;
    });
    builder.addCase(getOfficialCurriculumHierarchyList.fulfilled, (state, action) => {
      const newOfficialCurriculum: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newOfficialCurriculum.findIndex((c) => c.i_id === item.i_id);
        if (itemIndex >= 0) {
          newOfficialCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newOfficialCurriculum[itemIndex],
            item: { ...item, curriculum_code: item.official_curriculum_code },
          });
        } else {
          newOfficialCurriculum.push({
            i_id: item.i_id,
            curriculum_id: item.i_id,
            probs_count: item.probs_count,
            code: item.official_curriculum_code,
            name: item.curriculum_name,
            curriculum_description: item.description,
            sort_order: item.curriculum_sort_order,
            publish: item.publish,
            required_curriculum: item.required_curriculum,
            columnIndex: 0,
            fileID: item.fileID,
            children: item.item_ref?.level1_code?.i_id
              ? [
                  {
                    i_id: item.item_ref.level1_code.i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    curriculum_id: item.i_id,
                    probs_count: item.probs_count,
                    sort_order: item.level1_sort_order,
                    publish: item.publish,
                    required_curriculum: item.required_curriculum,
                    columnIndex: 1,
                    children: item.item_ref.level2_code?.i_id
                      ? [
                          {
                            i_id: item.item_ref.level2_code.i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            probs_count: item.probs_count,
                            curriculum_id: item.i_id,
                            sort_order: item.level2_sort_order,
                            publish: item.publish,
                            required_curriculum: item.required_curriculum,
                            columnIndex: 2,
                            children: item.item_ref.level3_code?.i_id
                              ? [
                                  {
                                    i_id: item.item_ref.level3_code.i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    probs_count: item.probs_count,
                                    curriculum_id: item.i_id,
                                    sort_order: item.level3_sort_order,
                                    publish: item.publish,
                                    required_curriculum: item.required_curriculum,
                                    columnIndex: 3,
                                    children: item.item_ref.level4_code?.i_id
                                      ? [
                                          {
                                            i_id: item.item_ref.level4_code.i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            probs_count: item.probs_count,
                                            curriculum_id: item.i_id,
                                            sort_order: item.level4_sort_order,
                                            publish: item.publish,
                                            required_curriculum: item.required_curriculum,
                                            columnIndex: 4,
                                            children: item.item_ref.question_name
                                              ? [
                                                  {
                                                    i_id: item.item_ref.question_name.i_id,
                                                    name: item.question_name,
                                                    code: item.question_code,
                                                    curriculum_id: item.i_id,
                                                    probs_count: item.probs_count,
                                                    sort_order: item.question_sort_order,
                                                    question_assign_level_i_id:
                                                      item.item_ref.question_sort_order.i_id,
                                                    publish: item.publish,
                                                    required_curriculum: item.required_curriculum,
                                                    columnIndex: 5,
                                                  },
                                                ]
                                              : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });

      state.officialCurriculumHierarchyList = newOfficialCurriculum;
    });

    builder.addCase(getCurriculum.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.TreeItem<Types.CurriculumHierarchy.DataTreeConverted>> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newCurriculum.findIndex((c) => c.i_id === item.i_id);
        if (itemIndex >= 0) {
          newCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newCurriculum[itemIndex],
            item,
          });
        } else {
          newCurriculum.push({
            i_id: item.i_id,
            code: item.official_curriculum_code,
            name: item.curriculum_name,
            curriculum_description: item.curriculum_description,
            columnIndex: 0,
            sort_order: item.curriculum_sort_order,
            children: item.item_ref?.level1_code?.i_id
              ? [
                  {
                    i_id: item.item_ref.level1_code.i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    columnIndex: 1,
                    sort_order: item.level1_sort_order,
                    children: item.item_ref.level2_code?.i_id
                      ? [
                          {
                            i_id: item.item_ref.level2_code.i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            columnIndex: 2,
                            sort_order: item.level2_sort_order,
                            children: item.item_ref.level3_code?.i_id
                              ? [
                                  {
                                    i_id: item.item_ref.level3_code.i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    columnIndex: 3,
                                    sort_order: item.level3_sort_order,
                                    children: item.item_ref.level4_code?.i_id
                                      ? [
                                          {
                                            i_id: item.item_ref.level4_code.i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            columnIndex: 4,
                                            sort_order: item.level4_sort_order,
                                            children: item.item_ref.question_sort_order
                                              ? [
                                                  {
                                                    i_id: item.item_ref.question_sort_order.i_id,
                                                    name: item.question_name,
                                                    code: item.question_code,
                                                    sort_order: item.question_sort_order,
                                                    columnIndex: 5,
                                                  },
                                                ]
                                              : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });
      state.dataCurriculumHierarchy = newCurriculum;
    });
    builder.addCase(getDataReleaseNoteManagement.fulfilled, (state, action) => {
      state.dataReleaseNoteManagement = action.payload.report_results;
      state.dataFuture = action.payload.report_results.filter(
        (item) => item.release_note_version > item.release_version
      );
      state.dataUnUpdate = action.payload.report_results.filter(
        (item) => item.release_note_version <= item.release_version
      );
      state.dataKeepUpdate = action.payload.report_results.filter(
        (item) => item.published_version === item.use_version
      );
    });
    builder.addCase(getDataReleaseNoteDetail.fulfilled, (state, action) => {
      state.dataReleaseNoteDetail = action.payload.report_results;
    });

    builder.addCase(getDataProductionCurriculum.fulfilled, (state, action) => {
      state.dataProductionCurriculum = action.payload.report_results;
    });
    builder.addCase(getDataProviderInformation.fulfilled, (state, action) => {
      state.dataInformationCurriculum = action.payload.items;
    });
    builder.addCase(getDataReleaseNoteID.fulfilled, (state, action) => {
      state.dataReleaseNoteID = action.payload.items;
    });
    builder.addCase(getCurriculumPublishedHistory.fulfilled, (state, action) => {
      state.curriculumPublishedHistory = action.payload.items;
    });
  },
});

export const { resetInitialState } = officialCurriculumSlice.actions;

export default officialCurriculumSlice.reducer;
