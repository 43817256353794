import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Locale } from 'constant/enum.constant';

export interface InitialState {
  locale: keyof typeof Locale;
  collapsedMenu: boolean;
  hiddenSidebar: boolean;
  loadingCount: number;
  headerTitle: string;
  loading: boolean;
}

const initialState: InitialState = {
  locale: 'ja',
  collapsedMenu: false,
  hiddenSidebar: false,
  headerTitle: '',
  loadingCount: 0,
  loading: false,
};

export const SettingSlice = createSlice({
  name: 'setting-container',
  initialState,
  reducers: {
    setDefaultLocale(state, action: PayloadAction<keyof typeof Locale>) {
      state.locale = action.payload;
    },
    setCollapsed(state, action: PayloadAction<boolean>) {
      state.collapsedMenu = action.payload;
    },
    toggleHiddenSidebar(state, action: PayloadAction<boolean>) {
      state.hiddenSidebar = action.payload;
    },
    setHeaderTitle(state, action: PayloadAction<string>) {
      state.headerTitle = action.payload;
    },
    startLoading(state) {
      state.loading = true;
      state.loadingCount += 1;
    },
    stopLoading(state) {
      state.loadingCount -= 1;
      if (state.loadingCount < 1) {
        state.loading = false;
        state.loadingCount = 0;
      }
    },
  },
});

export const {
  setDefaultLocale,
  setCollapsed,
  toggleHiddenSidebar,
  setHeaderTitle,
  startLoading,
  stopLoading,
} = SettingSlice.actions;

export default SettingSlice.reducer;
