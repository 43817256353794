import styled from 'styled-components';

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #424242;
  padding: 0 20px;
  .sub-title {
    text-align: center;
  }
  .ant-upload.ant-upload-drag {
    background: none !important;
    border: none !important;
  }

  .wrap-button-upload {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .form {
    .text-label {
      font-size: 13px;
      display: block;
      color: #424242;
      margin-top: 10px;
      .require {
        color: #f0330a;
      }
      .require-text {
        color: #f0330a;
        margin-left: 20px;
      }
      .imageQA {
        color: #08a3a5;
        margin-left: 10px;
      }
    }
    .wrap-implement-setting {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 3px;
      padding: 20px;
      .ant-picker:hover {
        border-color: #08a3a5;
      }
      .ant-picker-focused {
        border-color: #d9d9d9;
        box-shadow: unset;
        .ant-picker-active-bar {
          background: #08a3a5;
        }
      }
    }
    .form-input-radio {
      .radio_group {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        border: 1px solid #d9d9d9;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        .radio-text {
          font-size: 14px;
          color: #2a2a2a;
          width: 33%;
          display: flex;
          height: 40px;
          justify-content: space-around;
          align-items: center;
        }
        .radio-text + .radio-text {
          border-left: 1px solid #e2e2e2;
        }
        .half-width {
          width: 50%;
        }
      }
    }
    .form-input {
      .ant-form-item-no-colon {
        width: 100%;
      }
      .ant-select-selection-item {
        text-align: left;
      }
      .wrap-text-area {
        display: flex;
        justify-content: space-between;
        .text-label {
          margin: 0;
        }
        width: 100%;
        .text-enlarge {
          color: #00989a;
          cursor: pointer;
          font-size: 13px;
        }
      }
      .btn_primary {
        background: #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        border: none;
        width: 140px;
        height: 40px;
        color: #ffffff;
        cursor: pointer;
        .icon {
          margin-right: 5px;
        }
      }
      .active {
        color: #00989a;
      }
      .input {
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;

        color: #424242;
        ::placeholder {
          color: #cccccc;

          font-size: 14px;
        }
      }
      .ant-input-show-count-suffix {
        position: absolute;
        top: 46px;
        right: 0;
      }
    }
  }
  .text-content {
    font-size: 11px;
    color: #777777;
    padding-top: 10px;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
  }
  .ant-upload-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .form-upload {
    position: relative;
    border: 3px solid #ffffff;
    .icon-prev,
    .icon-next {
      color: #c4c4c4;
      font-size: 25px;
      cursor: pointer;
    }
    .icon-prev {
      position: absolute;
      left: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
    .icon-next {
      position: absolute;
      right: -30px;
      top: calc(465px / 2);
      z-index: 9;
    }
  }
  .file-upload {
    display: flex;
    flex-direction: column;
    min-height: 260px;
    border: 2px dashed #d9d9d9;
    background-color: #efefef;
    justify-content: center;
    padding: 20px 0;
    .info-file {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 15px 0;
      position: relative;
      .name-image {
        font-size: 13px;
        text-align: center;
        margin-bottom: 10px;
        color: #424242;
      }
      .image-file {
        height: 338px;
      }
      .ant-image-mask {
        .zoom-image {
          font-size: 18px;
          color: #c4c4c4;
          position: absolute;
          right: 20px;
          bottom: 20px;
          z-index: 99;
          cursor: pointer;
        }
      }
    }
  }
  .ant-upload-list {
    display: none;
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .text-delete-outlined {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .label-image {
    font-size: 13px;
    color: #222222;
    padding-top: 25px;
  }
`;
