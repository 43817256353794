import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Select, Table, Tabs, Button, Empty } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { routes } from 'navigations/routes';
import { findIndex, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  ExclamationOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  MessageOutlined,
  SearchOutlined,
  CrownFilled,
  StarFilled,
  LikeOutlined,
  LikeFilled,
} from '@ant-design/icons';

import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import KnowledgeTopStyled, { ButtonLabel } from './styles';
import { authSelector } from 'containers/Auth/selectors';
import { resetData, sortDataKnowledge } from './slice';
import KnowledgeComponent from './KnowledgeComponent';
import { knowledgeTopSelector } from './selectors';
import UserComponent from './UserComponent';
import { adminAvatar } from 'assets';
import { Header } from 'components';
import * as Types from 'types';
import {
  getKnowledgeFavoriteHistory,
  createFavoriteKnowledge,
  deleteFavoriteKnowledge,
  getKnowledgeLikeHistory,
  getSelectDepartment,
  getSelectUser,
  getDataRankingLikeKnowledgeAll,
  getDataRankingLikeUserAll,
  getDataListKnowledge,
} from './thunk';

const { Option } = Select;

const { TabPane } = Tabs;

interface Props {
  isScreen?: 'favorite' | 'created_by' | 'to_login_id';
}

const KnowledgeTop: React.FC<Props> = ({ isScreen }) => {
  const [sortByCorrect, setSortByCorrect] = useState<'asc' | 'desc' | undefined>();
  const [selectKnowledge, setSelectKnowLedge] = useState<string>();
  const [knowledgeType, setKnowledgeType] = useState<string>();
  const [department, setDepartment] = useState<string>();
  const [tabButton, setTabButton] = useState<number>(0);
  const [perPage, setPerPage] = useState<number>(100);
  const [tabRank, setTabRank] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [user, setUser] = useState<string>();

  const { headerTitle } = useSelector(settingSelector);
  const { permissionNumber } = usePermission();
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    dataKnowledgeFavoriteHistory,
    dataKnowledgeLikeHistory,
    dataRankingKnowledgeAll,
    dataSelectDepartment,
    dataRankingUserAll,
    dataKnowledgeList,
    data_user,
  } = useSelector(knowledgeTopSelector);

  const filteredItemsFavorite = dataKnowledgeList.filter((itemList) => {
    return dataKnowledgeFavoriteHistory.some((favoriteItem) => {
      return itemList.knowledge_id === favoriteItem.favorite_knowledge_id;
    });
  });

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const parser = new DOMParser();

  const onChange = (key: string) => {
    if (key === 'ALL') {
      setKnowledgeType('');
    } else {
      setKnowledgeType(key);
    }
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const columns: ColumnsType<Types.KnowledgeListType> = [
    {
      title: '概要',
      dataIndex: 'knowledge_type',
      key: 'knowledge_type',
      width: '5%',
      render: (icon: number, item) => (
        <div
          onClick={async (event) => {
            event.stopPropagation();
            const id = dataKnowledgeFavoriteHistory.find(
              (favorite) => favorite.favorite_knowledge_id === item.knowledge_id
            )?.item_ref?.favorite_knowledge_id.i_id;
            if (
              findIndex(
                dataKnowledgeFavoriteHistory,
                (e) => e.favorite_knowledge_id === item.knowledge_id
              ) > -1 &&
              id
            ) {
              const result = await dispatch(
                deleteFavoriteKnowledge({
                  id: id,
                })
              );
              if (deleteFavoriteKnowledge.fulfilled.match(result)) {
                fetchDataKnowledgeFavoriteHistory();
              }
            } else {
              const result = await dispatch(
                createFavoriteKnowledge({
                  item: {
                    company_id: userInfo?.company_id,
                    knowledge_id: item.knowledge_id,
                    login_id: userInfo?.login_id,
                  },
                })
              );
              if (createFavoriteKnowledge.fulfilled.match(result)) {
                fetchDataKnowledgeFavoriteHistory();
              }
            }
          }}
          className={`star ${icon === 1 ? '' : icon === 2 ? 'border-green' : 'border-blue'}`}
        >
          {findIndex(
            dataKnowledgeFavoriteHistory,
            (e) => e.favorite_knowledge_id === item.knowledge_id
          ) > -1 ? (
            <StarFilled className="icon-star yellow" />
          ) : (
            <StarFilled className="icon-star gray" />
          )}
        </div>
      ),
    },
    {
      title: '概要',
      dataIndex: 'knowledge_title',
      key: 'knowledge_title',
      width: '30%',
      ellipsis: true,
      render: (_, item) => (
        <div className="item-title">
          <span className="knowledge-text">{item.knowledge_title}</span>
          <span className="knowledge-text">
            {parser.parseFromString(item.knowledge_text, 'text/html').body.textContent}
          </span>
        </div>
      ),
    },
    {
      title: '種別',
      dataIndex: 'author_name',
      key: 'author_name',
      width: '15%',
      render: (author_name, item) => (
        <div className="item-info">
          <img
            className="avatar"
            src={item?.author_file_icon_url ? item.author_file_icon_url : adminAvatar}
            alt="avatar"
          />
          <div className="user-info">
            <span className="department-name">
              {item.author_department_name?.replace(/^\d+ /, '')}
            </span>
            <br />
            <span className="username">{author_name}</span>
          </div>
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'receiver',
      key: 'receiver',
      className: 'item-receiver',
      width: '15%',
      render: (_, item) => (
        <div className="receiver">
          <span className="label">
            To.
            {item.to_department_id === 'ALL' && item.to_user_id === 'ALL' ? (
              <span className="label-all"> ALL</span>
            ) : (
              <span>&nbsp;{item.to_department_name?.replace(/^\d+ /, '')}</span>
            )}
          </span>
          {item.to_department_id && item.to_user_id ? (
            <span className="text">{item.to_user_name}</span>
          ) : null}
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'knowledge_type',
      key: 'knowledge_type',
      width: '15%',
      render: (knowledge_type: number, item) => (
        <div className="item-recommend">
          <span className="knowledge-label">
            {knowledge_type === 1 ? 'カリキュラム' : knowledge_type === 2 ? 'Q&A' : 'リコメンド'}
          </span>
          {knowledge_type === 2 && (
            <>
              {!item.qa_status ? (
                <div className="qa-status-label red">
                  <ExclamationOutlined className="icon" />
                  未回答
                </div>
              ) : item.qa_status === 1 ? (
                <>
                  <div className="qa-status-label red">
                    <ExclamationOutlined className="icon" />
                    未回答
                  </div>
                  <div className="qa-status-label extend-question">
                    <span className="label">追加質問あり</span>
                  </div>
                </>
              ) : item.qa_status === 2 ? (
                <>
                  <div className="qa-status-label blue">
                    <CheckCircleOutlined className="icon" />
                    回答済み
                  </div>
                  <div className="qa-status-label extend-question">
                    <span className="label">追加質問あり</span>
                  </div>
                </>
              ) : (
                <div className="qa-status-label blue">
                  <CheckCircleOutlined className="icon" />
                  回答済み
                </div>
              )}
            </>
          )}

          {knowledge_type === 3 && item.recommendation_status >= 0 ? (
            <div
              className={`recommendation-status-label ${
                item.recommendation_status ? 'red' : 'blue'
              }`}
            >
              {item.recommendation_status ? (
                <MessageOutlined className="icon" />
              ) : (
                <CheckCircleOutlined className="icon" />
              )}
              <span className="label">{item.recommendation_status ? '質問あり' : '回答済'}</span>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '20%',
      render: (updatedat: string, item) => (
        <div className="time">
          <span
            className="number-like"
            //TODO: remove like
            // onClick={() =>
            //   setChangeLike((prevState) => {
            //     const newState = !prevState;
            //     if (newState) {
            //       Promise.all([
            //         dispatch(
            //           createKnowLedgeLikeHistory({
            //             item: {
            //               knowledge_id: item.knowledge_id,
            //               login_id: userInfo?.login_id,
            //             },
            //           })
            //         ),
            //         dispatch(
            //           updateKnowledge({
            //             id: item.item_ref!.knowledge_id.i_id,
            //             data: {
            //               item: {
            //                 like: item.number_of_likes + 1,
            //                 updatedat: new Date(),
            //               },
            //               is_force_update: true,
            //             },
            //           })
            //         ),
            //       ]);
            //     }
            //     return newState;
            //   })
            // }
          >
            {/* {changeLike ? (
              <LikeFilled className="like-icon" />
            ) : ( */}
            {findIndex(dataKnowledgeLikeHistory, (e) => e.knowledge_id === item.knowledge_id) >
            -1 ? (
              <LikeFilled className="like-icon" />
            ) : (
              <LikeOutlined className="like-icon" />
            )}
            {/* )} */}
            {item.number_of_likes || 0}
          </span>
          <div className="label-time">Last update:</div>
          <div className="detail">
            {updatedat
              ? dayjs(updatedat).format('YYYY/MM/DD HH:mm')
              : dayjs(item.createdat).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      ),
    },
  ];

  const fetchDataKnowledgeFavoriteHistory = useCallback(() => {
    if (userInfo) {
      dispatch(startLoading());
      dispatch(
        getKnowledgeFavoriteHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, userInfo]);

  const fetchDataKnowledgeLikeHistory = useCallback(() => {
    if (userInfo) {
      dispatch(startLoading());
      dispatch(
        getKnowledgeLikeHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    (async () => {
      if (!tabRank && userInfo) {
        dispatch(startLoading());
        await dispatch(
          getDataRankingLikeKnowledgeAll({
            conditions: [
              ...(tabButton
                ? [
                    {
                      id: 'created_month',
                      search_value: [dayjs().format('YYYYMM')],
                      exact_match: true,
                    },
                  ]
                : []),
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      }
    })();
  }, [dispatch, tabRank, tabButton, userInfo]);

  useEffect(() => {
    (async () => {
      if (tabRank === 1 && userInfo) {
        dispatch(startLoading());
        await dispatch(
          getDataRankingLikeUserAll({
            conditions: [
              ...(tabButton
                ? [
                    {
                      id: 'created_month',
                      search_value: [dayjs().format('YYYYMM')],
                      exact_match: true,
                    },
                  ]
                : []),
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      }
    })();
  }, [dispatch, tabRank, userInfo, tabButton]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(
      getDataListKnowledge({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
            exact_match: true,
          },
          ...(isScreen === 'created_by'
            ? [
                {
                  id: 'createdby',
                  search_value: [userInfo.login_id],
                  exact_match: true,
                },
              ]
            : []),
          ...(knowledgeType
            ? [
                {
                  id: 'knowledge_type',
                  search_value: [knowledgeType],
                  exact_match: true,
                },
              ]
            : []),
          ...(selectKnowledge
            ? [
                {
                  id: 'knowledge_id',
                  search_value: [selectKnowledge],
                  exact_match: true,
                },
              ]
            : []),
        ],
        to_login_id: isScreen === 'to_login_id',
        user: user,
        department: department,
        page: 1,
        per_page: 0,
      })
    );
  }, [department, dispatch, isScreen, knowledgeType, selectKnowledge, user, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getSelectUser({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          ...(department
            ? [
                {
                  id: 'department_code',
                  search_value: [department],
                  exact_match: true,
                },
              ]
            : []),
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
    setPage(1);
    setPerPage(100);
  }, [dispatch, department, userInfo]);

  useEffect(fetchDataKnowledgeFavoriteHistory, [fetchDataKnowledgeFavoriteHistory]);
  useEffect(fetchDataKnowledgeLikeHistory, [fetchDataKnowledgeLikeHistory]);
  useEffect(() => {
    dispatch(resetData());
    return () => {
      dispatch(resetData());
    };
  }, []);

  return (
    <KnowledgeTopStyled>
      <Header title={headerTitle} className="header">
        <form className="form">
          <SearchOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              placeholder="ナレッジ検索"
              className="select-input"
              value={selectKnowledge}
              onSelect={setSelectKnowLedge}
            >
              {dataKnowledgeList?.map((item, i) => (
                <Option key={i} value={item.knowledge_id}>
                  {item.knowledge_title}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <Select
              placeholder="To 部署"
              className="select-input"
              value={department}
              onSelect={setDepartment}
            >
              <Option value="ALL">ALL</Option>
              {dataSelectDepartment?.map((item, index) => (
                <Option key={index} value={item.affiliation_id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <Select
              showSearch
              placeholder="To ユーザー"
              className="select-input"
              value={user}
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={setUser}
            >
              <Option value="ALL">ALL</Option>
              {uniqBy(data_user, 'login_id')?.map((item, index) => (
                <Option value={item.login_id} key={index}>
                  {item.user_name}
                </Option>
              ))}
            </Select>
          </div>
          <button
            type="button"
            className="text-reset"
            onClick={() => {
              setUser(undefined);
              setDepartment(undefined);
              setSelectKnowLedge(undefined);
            }}
          >
            クリア
          </button>
        </form>
      </Header>
      <div className="wrap-body">
        <div className="item-left">
          <div className="wrap-tab">
            <Tabs className="tab-container" defaultActiveKey="ALL" onChange={onChange}>
              <TabPane tab="全ての投稿" key="ALL" />
              <TabPane tab="カリキュラム" key="1" />
              <TabPane tab="Q&A" key="2" />
              <TabPane tab="リコメンド" key="3" />
            </Tabs>
            <div className="group-btn">
              <button
                disabled={permissionNumber === 1}
                onClick={() => navigate(generatePath(routes.CreateKnowledgeQA.path))}
                className={`btn ${permissionNumber === 1 ? 'disabled' : 'btn-tell'}`}
              >
                教えて！
              </button>
              <button
                disabled={permissionNumber === 1}
                className={`btn ${permissionNumber === 1 ? 'disabled' : ' btn-recommended'}`}
                onClick={() => navigate(generatePath(routes.CreateKnowledgeRecommend.path))}
              >
                リコメンド
              </button>
            </div>
          </div>
          <div className="wrap-table">
            {(isScreen === 'favorite' ? filteredItemsFavorite : dataKnowledgeList).length ? (
              <>
                <div className="text-count">
                  {(page - 1) * perPage + 1} - {''}
                  {page * perPage >
                  (isScreen === 'favorite' ? filteredItemsFavorite : dataKnowledgeList).length
                    ? (isScreen === 'favorite' ? filteredItemsFavorite : dataKnowledgeList).length
                    : page * perPage}
                  <span className="text-static"> </span> / {''}
                  {(isScreen === 'favorite' ? filteredItemsFavorite : dataKnowledgeList).length}
                  <span className="text-static"> 件</span>
                </div>
                <div className="sort-column">
                  <div className="select-perpage">
                    <div className="label">
                      <span>表示件数</span>：
                    </div>
                    <Select defaultValue={perPage} onChange={handleSelectChange} value={perPage}>
                      {[10, 20, 50, 100].map((value, index) => (
                        <Option key={index} value={value}>
                          {value}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="item-sort">
                    <span className="legend-name">新着順</span>
                    <Button
                      className="btn-sort"
                      onClick={() => {
                        setSortByCorrect((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                        dispatch(
                          sortDataKnowledge({
                            sort_field: 'createdat',
                            sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                          })
                        );
                      }}
                    >
                      <div className="wrap-sort">
                        <CaretUpOutlined
                          className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
                        />
                        <CaretDownOutlined
                          className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
                        />
                      </div>
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
            <Table
              className="table-knowledge-inf"
              showHeader={false}
              dataSource={
                isScreen === 'favorite'
                  ? filteredItemsFavorite.map((item, index) => ({ ...item, index }))
                  : dataKnowledgeList.map((item, index) => ({ ...item, index }))
              }
              columns={columns}
              pagination={{
                pageSize: perPage,
                current: page,
                total:
                  isScreen === 'favorite' ? filteredItemsFavorite.length : dataKnowledgeList.length,
                onChange: setPage,
                position: ['topCenter'],
              }}
              onRow={(record: Types.KnowledgeListType) => ({
                record,
                onClick: () => {
                  if (record.knowledge_type === 1) {
                    navigate(routes.KnowledgeCurriculum.path, {
                      state: record,
                    });
                  } else if (record.knowledge_type === 2) {
                    navigate(
                      isScreen === 'favorite'
                        ? routes.ExpandingFavorite.path
                        : isScreen === 'to_login_id'
                        ? routes.ExpandingToLoginId.path
                        : isScreen === 'created_by'
                        ? routes.ExpandingCreatedBy.path
                        : routes.Expanding.path,
                      {
                        state: {
                          ...record,
                          isScreen: isScreen,
                        },
                      }
                    );
                  } else if (record.knowledge_type === 3) {
                    navigate(
                      isScreen === 'favorite'
                        ? routes.KnowledgeTopRecommendFavorite.path
                        : isScreen === 'to_login_id'
                        ? routes.KnowledgeTopRecommendToLoginId.path
                        : isScreen === 'created_by'
                        ? routes.KnowledgeTopRecommendCreatedBy.path
                        : routes.KnowledgeTopRecommend.path,
                      {
                        state: {
                          ...record,
                          isScreen: isScreen,
                        },
                      }
                    );
                  }
                },
              })}
              rowKey="index"
            />
          </div>
        </div>
        <div className="item-right">
          <div className="header-rank">
            <CrownFilled className="icon" />
            いいね！ランキング
          </div>
          <div className="flex-label">
            {['ナレッジ', 'ユーザー'].map((item, index) => (
              <ButtonLabel
                key={index}
                tabRank={tabRank}
                index={index}
                onClick={() => {
                  setTabRank(index);
                  setTabButton(0);
                }}
              >
                {[item]}
              </ButtonLabel>
            ))}
          </div>
          <div className="group-btn">
            {['総合', '今月'].map((item, index) => (
              <button
                key={index}
                className={`btn ${tabButton === index ? 'btn-active' : ''}`}
                onClick={() => setTabButton(index)}
              >
                {item}
              </button>
            ))}
          </div>
          {tabRank === 1 ? (
            <div className="list-rank">
              {tabButton === 0 && dataRankingUserAll.length ? (
                dataRankingUserAll.map((item, index) => (
                  <UserComponent key={index} index={index} item={item} />
                ))
              ) : tabButton === 1 && dataRankingUserAll.length ? (
                dataRankingUserAll.map((item, index) => (
                  <UserComponent key={index} index={index} item={item} />
                ))
              ) : (
                <Empty className="empty" />
              )}
            </div>
          ) : (
            <div className="list-rank">
              {tabButton === 0 && dataRankingKnowledgeAll?.length ? (
                dataRankingKnowledgeAll?.map((item, index) => (
                  <KnowledgeComponent key={index} index={index} item={item} />
                ))
              ) : tabButton === 1 && dataRankingKnowledgeAll.length ? (
                dataRankingKnowledgeAll.map((item, index) => (
                  <KnowledgeComponent key={index} index={index} item={item} />
                ))
              ) : (
                <Empty className="empty" />
              )}
            </div>
          )}
        </div>
      </div>
    </KnowledgeTopStyled>
  );
};

export default KnowledgeTop;
