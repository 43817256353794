import React from 'react';

import { BarChartOutlined, CloseOutlined, TableOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { settingSelector } from 'containers/AppSettings/selectors';
import { ITEM_GRAPH } from 'constant/select.constants';
import TableLevels from '../TableLevels';
import ChartLevels from '../ChartLevels';
import { Header } from 'components';
import SurfaceTable from '../Table';
import Wrapper from './styles';
import * as Types from 'types';
import Chart from '../Chart';

const { Option } = Select;

interface Props {
  selectLevel1: Array<Types.SelectLevel.ResponseType>;
  selectLevel2: Array<Types.SelectLevel.ResponseType>;
  selectLevel3: Array<Types.SelectLevel.ResponseType>;
  selectLevel4: Array<Types.SelectLevel.ResponseType>;
  curriculums: Array<Types.Curriculums.ResponseType>;
  users: Array<Types.Users.ResponseType>;
  itemGraph?: 'bar_chart' | 'pie_chart';
  valueRequiredCurriculum: number;
  isUserTransTable: boolean;
  curriculum?: string;
  level1Code?: string;
  level2Code?: string;
  level3Code?: string;
  level4Code?: string;
  username?: string;
  segmented: number;
  itemComparision?:
    | 'actual_accuracy_rate'
    | 'progress_rate'
    | 'solution_ratio'
    | 'number_of_solutions';
}

const FilePDFExport: React.FC<Props> = ({
  valueRequiredCurriculum,
  isUserTransTable,
  curriculum,
  curriculums,
  level1Code,
  selectLevel1,
  level2Code,
  selectLevel2,
  level3Code,
  level4Code,
  selectLevel3,
  selectLevel4,
  username,
  segmented,
  itemGraph,
  users,
}) => {
  const { headerTitle } = useSelector(settingSelector);

  return (
    <Wrapper>
      <Header title={headerTitle} exportPdf />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">トレーニング種類</span>
            <Select value={valueRequiredCurriculum} className="select">
              <Option value={0}>カリキュラム</Option>
              <Option value={1}>必修カリキュラム</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの選択</span>
            <Select className="select" defaultValue="user-report">
              <Option value="user-report">ユーザーレポート</Option>
              <Option value="curriculumn-report">カリキュラムレポート</Option>
            </Select>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="header-container">
          <span className="title">
            カリキュラム実施結果を、ユーザーを基準に集計したレポートを表示しています
          </span>
        </div>
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <div className="form-select">
              <div className="item">
                <span className="text-label">カリキュラム名</span>
                <Select className="select" placeholder="指定なし" value={curriculum}>
                  <Option value="ALL">ALL</Option>
                  {curriculums.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第 1 階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !curriculum}
                  value={level1Code}
                >
                  {selectLevel1?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第 2 階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level1Code}
                  value={level2Code}
                >
                  {selectLevel2?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第 3 階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level2Code}
                  value={level3Code}
                >
                  {selectLevel3?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第 4 階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level3Code}
                  value={level4Code}
                >
                  {selectLevel4?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="divider" />
              <div className="item username">
                <span className="text-label">ユーザー名</span>
                <Select className="select" placeholder="指定なし" value={username}>
                  <Option value="ALL">ALL</Option>
                  {users.map((item, index) => (
                    <Option key={index} value={item.login_id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              {segmented ? (
                <>
                  <span className="cross-boiled">/</span>
                  <div className="wrapper-options">
                    <div className="wrapper-option">
                      <span className="label">グラフ：</span>
                      <div className="item">
                        <Select className="select" placeholder="---" value={itemGraph}>
                          {ITEM_GRAPH.map(({ label, value }, index) => (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {!segmented ? (
            isUserTransTable ? (
              <TableLevels
                level1Code={level1Code}
                level2Code={level2Code}
                level3Code={level3Code}
                level4Code={level4Code}
                exportPdf
              />
            ) : (
              <SurfaceTable
                valueRequiredCurriculum={valueRequiredCurriculum}
                isAllCurriculumUsers={
                  !!username && !!curriculum && username === 'ALL' && curriculum === 'ALL'
                }
                exportPdf
              />
            )
          ) : isUserTransTable ? (
            <ChartLevels
              level1Code={level1Code}
              level2Code={level2Code}
              level3Code={level3Code}
              level4Code={level4Code}
              itemGraph={itemGraph}
              exportPdf
            />
          ) : (
            <Chart itemGraph={itemGraph} curriculum={curriculum} username={username} exportPdf />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default FilePDFExport;
