import styled from 'styled-components';
import Modal from 'components/Modal';

export const FreeTrialStyled = styled(Modal)`
  width: calc(100% - 40px) !important;
  .ant-modal-content {
    background: rgba(249, 248, 248, 1);
  }
  .body {
    height: calc(100vh - 75px) !important;
    text-align: center;
    display: grid;
    justify-content: center;
    .body-content {
      .notice-file-size {
        color: #ed2121;
        font-size: 13px;
      }
      width: 908px;
      height: 390px;
      background: rgba(255, 255, 255, 1);
      margin-top: 16px;
      .content {
        padding: 50px 90px;
        .content-left {
          text-align: left;
          .btn-to {
            padding-top: 16px;
          }
          .content-footer {
            padding-top: 32px;
          }
        }
        .content-footer-text {
          display: flex;
        }
        .content-footer-right {
          margin-left: 32px;
          font-size: 13px;
          color: rgba(66, 66, 66, 1);
        }
        .btn-content {
          width: 199px;
          height: 28px;
          border-radius: 4px;
          background: rgba(0, 163, 165, 1);
          color: white;
          font-weight: 700;
          font-size: 12px;
        }
      }
    }
    .image-logo {
      padding-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sub-content {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 20px;
      .free-day {
        color: rgba(246, 172, 0, 1);
      }
    }
    .sub-footer {
      font-size: 16px;
      color: rgba(119, 119, 119, 1);
    }
    .sub-back {
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .back {
      width: 50px;
    }
    .back:after {
      content: '';
      position: relative;
      top: 0;
      width: 46px;
      height: 1px;
      display: flex;
      background-color: rgba(119, 119, 119, 1);
    }
    .btn-submit {
      text-align: center;
      .btn {
        font-weight: 700;
        border-radius: 5px;
        font-size: 15px;
        height: 48px;
        width: 280px;
        color: white;
        background: rgba(246, 172, 0, 1);
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
`;
