import styled from 'styled-components';

export const UploadFileWrapper = styled.div`
  margin: 8px 16px;
  .text-content {
    font-size: 11px;
    color: #777777;
  }
  .wrap-button-upload {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .ant-upload.ant-upload-drag {
    background: #efefef;
    border: none !important;
    display: flex;
    flex-direction: column;
  }
  .ant-upload.ant-upload-drag {
    border: none;
  }
  .icon {
    font-size: 40px;
    color: #ffffff;
  }
  .ant-upload-text,
  .ant-uploaded-text {
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .ant-uploaded-text {
    background: #ffffff;
    margin: 20px;
    padding: 15px;
  }
  .form-upload {
    .form-upload-border {
      border: 2px solid #ffffff;
    }
    .ant-upload-list {
      display: none;
    }
    .file-upload {
      display: flex;
      flex-direction: column;
      min-height: 160px;
      border: 2px dashed #d9d9d9;
      background-color: #efefef;
      justify-content: center;
      padding: 20px 0;
    }
  }
  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-delete {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 20px;
    .icon-delete-outlined {
      color: #00a3a5;
      font-size: 15px;
      margin-right: 5px;
    }
    .label-delete {
      font-size: 12px;
      text-decoration: underline;
      color: #00989a;
    }
  }
  .btn-upload {
    background: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00a3a5;
    padding: 6px 36px;
    cursor: pointer;
  }
  .note {
    margin-top: 12px;
    font-size: 11px;
    color: #777777;
  }
  .label-note {
    margin-top: 40px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 5px;
    font-size: 13px;
    color: #424242;
  }
  .form {
    .text-label {
      font-size: 13px;
      display: block;
      color: #222222;
      margin: 0;
      .require {
        color: #f0330a;
      }
      .title {
        color: #777777;
        font-size: 11px;
        padding-left: 10px;
      }
    }
    .form-input {
      .input,
      .input-textarea {
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        color: #424242;
        ::placeholder {
          color: #cccccc;
          font-size: 14px;
        }
      }
      .ant-input-show-count-suffix {
        position: absolute;
        top: 46px;
        margin-right: 0;
        right: 0;
      }
      .input-textarea {
        height: 100px;
      }
      .input-textarea-small {
        height: 60px;
      }
    }
  }
`;
