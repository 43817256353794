import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Select } from 'antd';
import { sumBy, uniqBy } from 'lodash';
import { useIntl } from 'react-intl';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretUpOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FilterOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { setCollapsed, startLoading, stopLoading } from 'containers/AppSettings/slice';
import MenuRightQuestionSkillCheck from 'containers/SkillCheck/TreeView/MenuRight';
import { questionMasterSelector } from 'pages/Settings/QuestionMaster/selectors';
import {
  HEADER_SKILL_CHECK_CSV,
  HEADER_SKILL_CHECK_MASTER_CSV,
} from 'constant/header.export.constant';
import MenuRightUser from 'containers/SkillCheck/UserTreeView/MenuRight';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { CreateQuestion } from 'pages/Settings/QuestionMaster/Modal';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getCompany } from 'pages/Settings/QuestionMaster/thunk';
import { PUBLISH, USER_TYPE } from 'constant/select.constants';
import UserTreeView from 'containers/SkillCheck/UserTreeView';
import PDFSkillCheckDocument from '../PDFSkillCheckDocument';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import TreeView from 'containers/SkillCheck/TreeView';
import { ModalCreateEdit } from '../Modal';
import { useAppDispatch, usePermission } from 'hooks';
import SkillCheckStyled, { Button } from './styles';
import { skillCheckSelector } from '../selectors';
import { DatePicker, Header, UploadCSV } from 'components';
import exportPDF from 'libs/utils/exportPdf';
import { HEADER_HEIGHT, MAX_REQUESTS_PER_TIMES } from 'constant';
import * as Types from 'types';
import {
  createSkillcheck,
  createUserAssignSkillCheck,
  getDataSkillCheckTree,
  getSelectTypes,
  getSkillcheckMaster,
  getUsersSkillCheck,
  updateSkillCheck,
} from '../thunk';
import { uploadSkillCheckCSV } from 'libs/validations';
import { AnyObject } from 'types/config';

const { Option } = Select;

interface Props {
  setOpenSkillCheckMaster: React.Dispatch<React.SetStateAction<boolean>>;
}

const Treeview: React.FC<Props> = ({ setOpenSkillCheckMaster }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showConfirmImportFileModal, setShowConfirmImportFileModal] = useState<boolean>(false);
  const [skillCheckSelected, setSkillCheckSelected] = useState<Types.SkillCheckTreeViewType>();
  const [usersSelected, setUsersSelected] = useState<Array<Types.Users.ResponseType>>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<Types.QuestionProps[]>([]);
  const [isShowMenuRight, setShowMenuRight] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [columnClosed, setColumnClosed] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);
  const [selectOpen, setSelectOpen] = useState<number>(0);
  const [tabActive, setTabActive] = useState<number>(0);
  const [valueSkillCheckCode, setValueSkillCheckCode] = useState<string>();
  const [valueGroupingCode, setValueGroupingCode] = useState<string>();
  const [valueStartPeriod, setValueStartPeriod] = useState<string>();
  const [valueCreatedBy, setValueCreatedBy] = useState<string>();
  const [valueUserType, setValueUserType] = useState<string>();
  const [valuePublish, setValuePublish] = useState<number>();
  const [openModalCreateEdit, setOpenModalCreateEdit] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });
  const [openModalCreateEditQuestion, setOpenModalCreateEditQuestion] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { headerTitle } = useSelector(settingSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { skillCheck, userSkillCheck, filter_conditions, filter_option } =
    useSelector(skillCheckSelector);
  const { defaultTimeLimit } = useSelector(questionMasterSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const ref = useRef(null);

  const handleScroll = () => {
    if (window.pageYOffset >= HEADER_HEIGHT) {
      setPageYOffset(HEADER_HEIGHT);
    } else {
      setPageYOffset(window.pageYOffset);
    }
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    try {
      if (value === 'csv') {
        const resultAction = await dispatch(
          getSkillcheckMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            include_lookups: true,
            page: 1,
            per_page: 0,
          })
        );
        if (getSkillcheckMaster.fulfilled.match(resultAction)) {
          const listCsv = resultAction.payload.report_results.map((item) => ({
            company_id: item.company_id,
            createdby: item.createdby,
            description: item.description,
            end_period: item.end_period,
            grouping_code: item.grouping_code,
            implementation_settings: item.implementation_settings,
            problems4: item.problems4,
            publish: item.publish,
            question_count: item.question_count,
            question_time_limit: item.question_time_limit,
            skill_check_code: item.skill_check_code,
            skill_check_code_assign: item.skill_check_code_assign,
            skill_check_name: item.skill_check_name,
            start_period: item.start_period,
            user_type: item.user_type,
          }));

          const csvString = [
            HEADER_SKILL_CHECK_MASTER_CSV.map(({ label }) => label),
            ...listCsv.map((item) => Object.values(item)),
          ]
            .map((e) => e.join(','))
            .join('\n');
          const bom = '\uFEFF';
          const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
          saveAs(file, 'Skill_Check_Tree_export.csv');
        }
      } else {
        if (!ref.current) return;

        exportPDF(ref, 'スキルチェックツリー.pdf');
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  const handleAddUserSelected = () => {
    usersSelected[0] && skillCheckSelected && handleAddUser(usersSelected);
  };

  const handleAddUser = async (data: Types.Users.ResponseType[]) => {
    if (!data[0] || !skillCheckSelected) return;
    dispatch(startLoading());
    const resultActions = await Promise.all(
      data.map((user) => {
        if (
          !skillCheckSelected.user_children?.some((c) =>
            c.children?.some((userChild) => userChild.user_id === user.i_id)
          )
        ) {
          dispatch(
            createUserAssignSkillCheck({
              item: {
                company_id: userInfo?.company_id,
                skill_check_code: skillCheckSelected?.skill_check_code,
                login_id: user.login_id,
                hidden: 'off',
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          );

          return true;
        } else {
          return false;
        }
      })
    );

    if (resultActions.some((action) => action)) {
      await dispatch(
        updateSkillCheck({
          id: skillCheckSelected?.i_id!,
          data: {
            item: {
              user_count:
                (sumBy(skillCheckSelected.user_children, (department) =>
                  sumBy(department.children, (child) => (child.user_id ? 1 : 0))
                ) || 0) + resultActions.filter((r) => r).length,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );

      fetchDataUserSkillCheck();
    }
    setSkillCheckSelected(undefined);
    setUsersSelected([]);
    dispatch(stopLoading());
  };

  const handleResetFilter = () => {
    setValueSkillCheckCode(undefined);
    setValueGroupingCode(undefined);
    setValueStartPeriod(undefined);
    setValuePublish(undefined);
    setValueUserType(undefined);
    setValueCreatedBy(undefined);
  };

  const fetchDataUserSkillCheck = useCallback(() => {
    if (tabActive && userInfo) {
      dispatch(startLoading());
      dispatch(
        getUsersSkillCheck({
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(valueSkillCheckCode
              ? [
                  {
                    id: 'code',
                    search_value: [valueSkillCheckCode],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valueGroupingCode
              ? [
                  {
                    id: 'group_code',
                    search_value: [valueGroupingCode],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valueStartPeriod
              ? [
                  {
                    id: 'start_period',
                    search_value: [valueStartPeriod],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valuePublish
              ? [
                  {
                    id: 'publish',
                    search_value: [String(valuePublish)],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valueUserType
              ? [
                  {
                    id: 'user_type',
                    search_value: [valueUserType],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valueCreatedBy
              ? [
                  {
                    id: 'createdby',
                    search_value: [valueCreatedBy],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          sort_fields: [
            {
              id: 'code',
              order: 'asc',
            },
          ],
        })
      );
      dispatch(stopLoading());
    }
  }, [
    tabActive,
    userInfo,
    dispatch,
    valueSkillCheckCode,
    valueGroupingCode,
    valueStartPeriod,
    valuePublish,
    valueUserType,
    valueCreatedBy,
  ]);

  const fetchDataSkillCheckTree = useCallback(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSkillCheckTree({
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(valueSkillCheckCode
                ? [
                    {
                      id: 'code',
                      search_value: [valueSkillCheckCode],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valueGroupingCode
                ? [
                    {
                      id: 'group_code',
                      search_value: [valueGroupingCode],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valueStartPeriod
                ? [
                    {
                      id: 'start_period',
                      search_value: [valueStartPeriod],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valuePublish
                ? [
                    {
                      id: 'publish',
                      search_value: [String(valuePublish)],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valueUserType
                ? [
                    {
                      id: 'user_type',
                      search_value: [valueUserType],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valueCreatedBy
                ? [
                    {
                      id: 'createdby',
                      search_value: [valueCreatedBy],
                      exact_match: true,
                    },
                  ]
                : []),
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
          })
        ),
        dispatch(
          getCompany({
            conditions: [
              {
                id: 'id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [
    dispatch,
    valueSkillCheckCode,
    userInfo,
    valueGroupingCode,
    valueStartPeriod,
    valuePublish,
    valueUserType,
    valueCreatedBy,
  ]);

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <PDFSkillCheckDocument
          fetchDataUserSkillCheck={fetchDataUserSkillCheck}
          setSkillCheckSelected={setSkillCheckSelected}
          setSelectedQuestion={setSelectedQuestion}
          fetchDataSkillCheck={fetchDataSkillCheckTree}
          valueSkillCheckCode={valueSkillCheckCode}
          skillCheck={Array.from([skillCheck[0]])}
          skillCheckSelected={skillCheckSelected}
          valueGroupingCode={valueGroupingCode}
          filter_conditions={filter_conditions}
          valueStartPeriod={valueStartPeriod}
          selectedQuestion={selectedQuestion}
          valueUserType={valueUserType}
          filter_option={filter_option}
          columnClosed={columnClosed}
          valuePublish={valuePublish}
        />
      </div>
    );
  }, [
    fetchDataUserSkillCheck,
    fetchDataSkillCheckTree,
    valueSkillCheckCode,
    skillCheckSelected,
    filter_conditions,
    valueGroupingCode,
    selectedQuestion,
    valueStartPeriod,
    valueUserType,
    filter_option,
    columnClosed,
    valuePublish,
    skillCheck,
  ]);

  useEffect(fetchDataSkillCheckTree, [fetchDataSkillCheckTree]);
  useEffect(fetchDataUserSkillCheck, [fetchDataUserSkillCheck]);

  useEffect(() => {
    if (skillCheckSelected) {
      setShowMenuRight(true);
    }
  }, [skillCheckSelected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleResetFilter();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isShowMenuRight && !collapsedMenu) {
      dispatch(setCollapsed(!collapsedMenu));
    }
  }, [isShowMenuRight]);

  const handleSubmitCsv = async (dataImport: AnyObject[]) => {
    if (!dataImport.length) {
      return;
    }

    for (let i = 0; i < dataImport.length; i += MAX_REQUESTS_PER_TIMES) {
      const chunk: AnyObject[] = dataImport.slice(i, i + MAX_REQUESTS_PER_TIMES);
      await Promise.all(
        chunk.map((obj: AnyObject) =>
          dispatch(
            createSkillcheck({
              item: {
                company_id: userInfo?.company_id,
                createdby: userInfo?.login_id,
                createdat: new Date(),
                probs_count: 0,
                user_count: 0,
                setting_score: 0,
                publish: 0,
                question_time_limit: Number(obj?.time_limit?.replace('分', '')),
                ...obj,
                start_period: obj.start_period
                  ? dayjs(obj.start_period, 'YYYY/MM/DD HH:mm', true).toDate()
                  : undefined,
                end_period: obj.end_period
                  ? dayjs(obj.end_period, 'YYYY/MM/DD HH:mm', true).toDate()
                  : undefined,
                base_date_for_report: obj.base_date_for_report
                  ? dayjs(obj.base_date_for_report, 'YYYY/MM/DD HH:mm', true).toDate()
                  : undefined,
              },
            })
          )
        )
      );
    }
    fetchDataSkillCheckTree();
    fetchDataUserSkillCheck();
    return true;
  };

  return (
    <SkillCheckStyled
      isShowMenuRight={isShowMenuRight}
      collapsedMenu={collapsedMenu}
      selectOpen={selectOpen}
    >
      <Header title={headerTitle} className="header">
        <div className="filter">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            {(selectOpen === 1 || selectOpen === 0) && (
              <Select
                allowClear
                showSearch
                className="select-input"
                placeholder="スキルチェック名"
                value={valueSkillCheckCode}
                onChange={(value) => {
                  setValueSkillCheckCode(value);
                  setSelectOpen(0);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    setSelectOpen(1);
                  } else {
                    setSelectOpen(0);
                  }
                }}
                onBlur={() => setSelectOpen(0)}
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'skill_check_name').map(
                  ({ skill_check_name, skill_check_code }, index) => (
                    <Option key={index} value={skill_check_code} title={skill_check_name}>
                      {skill_check_name}
                    </Option>
                  )
                )}
              </Select>
            )}
          </div>
          <div className="form-input">
            {(selectOpen === 2 || selectOpen === 0) && (
              <Select
                allowClear
                showSearch
                className="select-input"
                placeholder="分析グループ"
                value={valueGroupingCode}
                onChange={(value) => {
                  setValueGroupingCode(value);
                  setSelectOpen(0);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    setSelectOpen(2);
                  } else {
                    setSelectOpen(0);
                  }
                }}
                onBlur={() => setSelectOpen(0)}
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'grouping_code2').map((item, index) => (
                  <Option key={index} value={item.grouping_code2} title={item.grouping_code2}>
                    {item.grouping_code}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <div className="form-input">
            {(selectOpen === 3 || selectOpen === 0) && (
              <DatePicker
                allowClear
                className="select-input"
                placeholder="開始日"
                format="YYYY/MM/DD"
                onFocus={() => setSelectOpen(3)}
                onBlur={() => setSelectOpen(0)}
                value={valueStartPeriod ? dayjs(valueStartPeriod) : undefined}
                onChange={(value) => {
                  setValueStartPeriod(value?.toISOString() || '');
                  setSelectOpen(0);
                }}
              />
            )}
          </div>
          <div className="form-input">
            {(selectOpen === 4 || selectOpen === 0) && (
              <Select
                allowClear
                className="select-input"
                placeholder="公開ステータス"
                onBlur={() => setSelectOpen(0)}
                value={valuePublish}
                onChange={(value) => {
                  setValuePublish(value);
                  setSelectOpen(0);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    setSelectOpen(4);
                  } else {
                    setSelectOpen(0);
                  }
                }}
              >
                {PUBLISH.map(({ label, value }, index) => (
                  <Option key={index} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <div className="form-input">
            {(selectOpen === 5 || selectOpen === 0) && (
              <Select
                allowClear
                className="select-input"
                placeholder="ユーザー種別"
                onBlur={() => setSelectOpen(0)}
                value={valueUserType}
                onChange={(value) => {
                  setValueUserType(value);
                  setSelectOpen(0);
                }}
                onDropdownVisibleChange={(open) => {
                  if (open) {
                    setSelectOpen(5);
                  } else {
                    setSelectOpen(0);
                  }
                }}
              >
                {USER_TYPE.map(({ label, value }, index) => (
                  <Option key={index} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          {(selectOpen === 6 || selectOpen === 0) && (
            <div className="form-input">
              <Checkbox
                onChange={(e) => {
                  setValueCreatedBy(e.target.checked && userInfo ? userInfo.login_id : undefined);
                }}
              >
                自作のみ表示
              </Checkbox>
            </div>
          )}
          <span className="text-reset" onClick={handleResetFilter}>
            {messages['M-21-6']}
          </span>
        </div>
      </Header>
      {component}
      <div className="wrap-title">
        <div className="wrap-button">
          <div>
            {['設問設定', 'ユーザー設定'].map((item, index) => (
              <Button
                key={index}
                tabActive={tabActive}
                index={index}
                onClick={() => {
                  setTabActive(index);
                  setShowMenuRight(false);
                  setColumnClosed(false);
                }}
              >
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {item}
              </Button>
            ))}
          </div>
          <div className="button-function">
            <button className="btn btn-outline" onClick={() => setOpenSkillCheckMaster(true)}>
              {messages['M-21-108']}
              <RightOutlined className="size-icon-down-outline" />
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmImportFileModal(true)}>
              <CloudUploadOutlined className="size-icon" />
              {messages['M-21-109']}
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              {messages['M-21-110']}
            </button>
          </div>
        </div>
        <div className="flex-label">
          <p className="label-text" onClick={() => setColumnClosed((prevState) => !prevState)}>
            {columnClosed ? (
              <CaretUpOutlined className="icon-label" />
            ) : (
              <CaretDownOutlined className="icon-label" />
            )}
            スキルチェック
          </p>
        </div>
        <div className={`setting-border ${pageYOffset >= HEADER_HEIGHT ? 'on-top' : ''}`}>
          <div className="border-green" />
          <div className="setting" onClick={() => setShowMenuRight(!isShowMenuRight)}>
            <div className="title">
              <CaretLeftOutlined className={isShowMenuRight ? 'opened' : ''} />
              {!tabActive ? <p>設問リスト</p> : <p>ユーザーリスト</p>}
            </div>
          </div>
        </div>
        <div className="header-body">
          {!(!tabActive ? skillCheck : userSkillCheck).length && (
            <p className="title">スキルチェックがまだ登録されていません。</p>
          )}
          <button
            disabled={permissionNumber === 1}
            className={permissionNumber === 1 ? 'disabled' : 'btn-add'}
            onClick={() =>
              setOpenModalCreateEdit({
                type: 'create',
                visible: true,
              })
            }
          >
            <PlusOutlined color="#ffffff" className="plus-icon" /> スキルチェック新規作成
          </button>
        </div>
      </div>
      <div className="flex-tree">
        <div className="dashboard">
          <div className="wrap-body">
            {!tabActive
              ? skillCheck.map((treeData, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${index < skillCheck?.length - 1 ? 'bordered' : ''}`}
                  >
                    <TreeView
                      treeData={treeData}
                      columnClosed={columnClosed}
                      selectedQuestion={selectedQuestion}
                      skillCheckSelected={skillCheckSelected}
                      setSelectedQuestion={setSelectedQuestion}
                      fetchDataSkillCheck={fetchDataSkillCheckTree}
                      setSkillCheckSelected={setSkillCheckSelected}
                      fetchDataUserSkillCheck={fetchDataUserSkillCheck}
                      isShowMenuRight={isShowMenuRight}
                    />
                  </div>
                ))
              : userSkillCheck.map((treeData, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${index < userSkillCheck?.length - 1 ? 'bordered' : ''}`}
                  >
                    <UserTreeView
                      treeData={treeData}
                      columnClosed={columnClosed}
                      skillCheckSelected={skillCheckSelected}
                      handleAddUserSelected={handleAddUserSelected}
                      fetchDataSkillCheck={fetchDataSkillCheckTree}
                      setSkillCheckSelected={setSkillCheckSelected}
                      fetchDataUserSkillCheck={fetchDataUserSkillCheck}
                    />
                  </div>
                ))}
          </div>
        </div>
        {isShowMenuRight ? (
          !tabActive ? (
            <MenuRightQuestionSkillCheck
              pageYOffset={pageYOffset}
              selectedQuestion={selectedQuestion}
              skillCheckSelected={skillCheckSelected}
              setSelectedQuestion={setSelectedQuestion}
              fetchDataSkillCheck={fetchDataSkillCheckTree}
              setSkillCheckSelected={setSkillCheckSelected}
              openModalCreateEditQuestion={openModalCreateEditQuestion}
              setOpenModalCreateEditQuestion={setOpenModalCreateEditQuestion}
            />
          ) : (
            <MenuRightUser
              pageYOffset={pageYOffset}
              usersSelected={usersSelected}
              setUsersSelected={setUsersSelected}
              skillCheckSelected={skillCheckSelected}
              fetchDataUserSkillCheck={fetchDataUserSkillCheck}
            />
          )
        ) : null}
      </div>
      <ModalCreateEdit
        setOpenModal={setOpenModalCreateEdit}
        openModalCreateEdit={openModalCreateEdit}
        fetchDataSkillCheck={fetchDataSkillCheckTree}
        fetchDataUserSkillCheck={fetchDataUserSkillCheck}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <UploadCSV
        visible={showConfirmImportFileModal}
        setVisible={setShowConfirmImportFileModal}
        onSubmit={handleSubmitCsv}
        title="カリキュラムマスタ インポート"
        headerCSVs={HEADER_SKILL_CHECK_CSV}
        dataSchema={uploadSkillCheckCSV}
      />
      <CreateQuestion
        setVisibleSuccess={setVisibleSuccess}
        defaultTimeLimit={defaultTimeLimit}
        openModalCreateEditQuestion={openModalCreateEditQuestion}
        setOpenModalCreateEditQuestion={setOpenModalCreateEditQuestion}
      />
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
    </SkillCheckStyled>
  );
};

export default Treeview;
