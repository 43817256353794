import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import { Setting } from 'assets';

interface Props {
  isShowMenuRight: boolean;
  collapsedMenu: boolean;
  selectOpen: number;
}
export default styled.section<Props>`
  @media (max-width: 1440px) {
    .header-body .btn-add {
      width: 200px;
      height: 32px;
    }
    .wrap-tree {
      width: 988px;
    }
  }
  height: 100%;
  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 1000;
    @media (max-width: 1439px) {
      width: calc(
        1440px - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px
      );
    }
    .filter-icon {
      color: #aaaaaa;
      margin-right: 5px;
      font-size: 17px;
    }
    .filter {
      display: flex;
      align-items: center;
      .form-input {
        display: flex;
        margin-bottom: unset;
        .ant-select {
          font-size: 12px;
          .ant-select-selector {
            height: 36px;
            align-items: center;
            border-right: none;
            border-radius: 0;
          }
        }
        .ant-select-arrow {
          font-size: 9px;
        }
        .ant-select-selection-placeholder {
          padding-right: 0;
          font-size: 13px;
        }
        &:nth-child(1) {
          .ant-select-selector {
            width: 139px;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
          }
        }
        &:nth-child(2) {
          .ant-select-selector {
            width: ${(props) => (props.selectOpen !== 1 ? '139' : '726')}px;
            transition: width 0s ease-in-out;
          }
        }
        &:nth-child(3) {
          .ant-select-selector {
            width: ${(props) => (props.selectOpen !== 2 ? '114' : '726')}px;
            transition: width 0s ease-in-out;
          }
        }
        &:nth-child(4) {
          .select-input {
            width: ${(props) => (props.selectOpen !== 3 ? '106' : '726')}px;
            transition: width 0s ease-in-out;
            height: 36px;
            border-right: none;
          }
        }
        &:nth-child(5) {
          .ant-select-selector {
            width: ${(props) => (props.selectOpen !== 4 ? '126' : '726')}px;
            transition: width 0s ease-in-out;
          }
        }
        &:nth-child(6) {
          .ant-select-selector {
            width: ${(props) => (props.selectOpen !== 5 ? '113' : '726')}px;
            transition: width 0s ease-in-out;
          }
        }
        .ant-checkbox-wrapper {
          position: relative;
          height: 36px;
          width: 117px;
          padding: 0 4px;
          border: 1px solid #d9d9d9;
          background-color: #ffffff;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          align-items: center;
          font-size: 12px;
          width: ${(props) => (props.selectOpen !== 6 ? 'auto' : '726')}px;
          .ant-radio {
            position: absolute;
            top: 6px;
            left: 6px;
          }
          & > span:nth-child(2) {
            line-height: 29px;
          }
          &:after {
            content: '';
          }
        }
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  .wrap-title {
    position: sticky;
    top: 56px;
    right: ${(props) => (props.isShowMenuRight ? '320px' : 0)};
    width: calc(100% - ${(props) => (props.isShowMenuRight ? 320 : 0)}px);
    z-index: 1000;
    background: #ffffff;
    border-bottom: 1px solid #ebeaea;
    .wrap-button {
      display: flex;
      justify-content: space-between;
      padding: 25px 40px 25px 25px;
    }
    .button-function {
      .btn {
        padding: 5px 12px;
        width: 140px;
        cursor: pointer;
        font-size: 13px;
        border-radius: 5px;
        color: #f1a900;
        font-weight: bold;
        margin-left: 8px;
      }
      .btn-active {
        background: #f6ac00;
        border: 1px solid #f6ac00;
        color: #ffffff;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        text-align: start;
        width: ${(props) => (!props.isShowMenuRight ? 160 : 140)}px;
        height: 32px;
        .size-icon {
          font-size: 14px;
          margin-right: 20px;
        }
      }
      .btn-outline {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        text-align: end;
        width: ${(props) => (!props.isShowMenuRight ? 160 : 140)}px;
        height: 32px;
        .size-icon-down-outline {
          color: #f6ac00;
          font-size: 10px;
          margin-left: 20px;
        }
      }
    }
    .flex-label {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #bbbbbb;
      padding-bottom: 13px;
      .label-text {
        font-size: 13px;
        color: #777777;
        width: 16.67%;
        cursor: pointer;
        text-align: center;
        margin: 0;
        .icon-label {
          margin-right: 7px;
        }
      }
      .active {
        color: #00a3a5;
        font-weight: 700;
      }
    }
  }
  .on-top {
    position: fixed;
    top: 56px;
    right: ${(props) => (props.isShowMenuRight ? '320px' : 0)};
    z-index: 999;
  }
  .setting-border {
    .setting {
      position: absolute;
      top: 7px;
      right: 1px;
      z-index: 10;
      cursor: pointer;
      .title {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 40px;
        height: 163px;
        background-image: url(${Setting});
        background-repeat: no-repeat;
        padding: 10px 0;
        font-size: 13px;
        color: #ffffff;
        .opened {
          transform: rotate(180deg);
        }
        p {
          writing-mode: tb-rl;
          font-weight: 700;
          margin: 0;
        }
        span {
          font-weight: 700;
          margin: 0;
        }
      }
    }
    .border-green {
      width: 5px;
      height: 186px;
      position: absolute;
      top: 0;
      right: 0;
      background: #00a3a5;
    }
  }
  .header-body {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 20px;
    background: #f8f8f8;
    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;
      color: #424242;
      margin: 0;
    }
    .btn-add,
    .disabled {
      background: #f6ac00;
      font-weight: bold;
      font-size: 12px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      padding: 5px 15px;
      align-items: center;
      .plus-icon {
        font-size: 15px;
      }
    }
    .disabled {
      background: #cccccc;
      color: #777777;
    }
  }

  .flex-tree {
    display: flex;
    .dashboard {
      width: calc(100% - ${(props) => (props.isShowMenuRight ? '320px' : '0px')});
      height: calc(100vh - 243px);
      overflow-y: auto;
      position: relative;
      .wrap-body {
        .header-body {
          display: flex;
          align-items: center;
          margin: 20px;
          .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 13px;
            color: #424242;
            margin: 0;
          }
          .btn-add,
          .disabled {
            background: #f6ac00;
            font-weight: bold;
            font-size: 12px;
            color: #ffffff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            padding: 5px 15px;
          }
          .disabled {
            background: #cccccc;
            color: #777777;
          }
        }
        .wrap-tree {
          width: 100%;
          background: #f2f2f2;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 24px 47px 20px 20px;
        }
        .bordered {
          border-bottom: 4px solid #eeeeee;
        }
      }
    }
  }
`;

interface ButtonProps {
  tabActive: number;
  index: number;
}

export const Button = styled.button<ButtonProps>`
  position: relative;
  width: 200px;
  height: 32px;

  cursor: pointer;
  font-weight: ${(props) => (props.tabActive === props.index ? 'bold' : 'unset')};
  font-size: 13px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  border-left: none;
  background-color: ${(props) =>
    props.tabActive === props.index ? (props.tabActive === 0 ? '#00a3a5' : '#EB8B55') : '#ffffff'};
  color: ${(props) =>
    props.tabActive === props.index ? '#ffffff' : props.tabActive === 0 ? '#00a3a5' : '#EB8B55'};
  border: 1px solid ${(props) => (props.tabActive === 0 ? '#00a3a5' : '#EB8B55')};
  .size-icon {
    position: absolute;
    left: 10px;
    top: 30%;
  }
  &:nth-child(2) {
    border-radius: 0 3px 3px 0;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
`;
