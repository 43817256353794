import styled from 'styled-components';

export default styled('div')`
  .wrap-img-crop {
    background: #efefef !important;
    padding-bottom: 40px;
    background: #ffffff;
    border: 2px dashed #d9d9d9;
    .name-file {
      text-align: center;
      padding: 10px 0;
    }
    .ant-slider-handle {
      border: solid 2px #08a3a5;
    }
  }
  .img-crop-modal {
    .ant-modal-body {
      padding: 30px 40px !important;
    }
  }
  .note {
    margin-top: 12px;
    color: #777777;
    font-size: 11px;
  }
  .img-crop-container {
    position: relative;
    width: 100%;
    height: 45vh;
  }
  .img-crop-media {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
  }
  .img-crop-control {
    display: flex;
    align-items: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    &:first-of-type {
      margin-top: 16px;
    }

    &:last-of-type {
      margin-bottom: -8px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      height: 34px;
      padding: 0;
      font-style: normal;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;

      &[disabled] {
        cursor: default;
      }
    }

    button + div:only-of-type {
      flex: 1;
      margin: 0 8px;
    }
  }

  .img-crop-control-zoom button {
    font-size: 18px;
  }

  .img-crop-control-rotate button {
    font-size: 16px;

    &:first-of-type {
      transform: rotate(-20deg);
    }

    &:last-of-type {
      transform: rotate(20deg);
    }
  }
`;
