import React, { useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import { getUserInfo, isFirstLogin, updateLoginTime } from 'containers/Auth/thunk';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Locale } from 'constant/enum.constant';
import setupInterceptors from './libs/axios';
import AppLocale from 'configs/translation';
import { useAppDispatch } from 'hooks';
import { store } from 'store';

setupInterceptors(store);

const AppProvider: React.FC = ({ children }) => {
  const { locale } = useSelector(settingSelector);
  const [localeDefault, setLocaleDefault] = useState<keyof typeof Locale>(locale);

  const currentAppLocale = AppLocale[localeDefault] || AppLocale.ja;

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocaleDefault(locale);
  }, [locale]);

  useEffect(() => {
    (async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('sk_access_token');
      if (email && token) {
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            include_links: true,
            page: 1,
            per_page: 1,
          })
        );
        if (
          isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
          resultActionIsFirstLogin.payload.totalItems
        ) {
          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
              un_realtime: true,
            })
          );
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const email = localStorage.getItem('email');
    dispatch(
      getUserInfo({
        conditions: [
          {
            id: 'email',
            search_value: [email],
            exact_match: true,
          },
        ],
        include_lookups: true,
        include_links: true,
        page: 1,
        per_page: 1,
      })
    );
  }, [dispatch, window.location.pathname]);

  return (
    <ConfigProvider locale={currentAppLocale.antd || currentAppLocale}>
      <IntlProvider
        locale={currentAppLocale.locale || currentAppLocale}
        messages={currentAppLocale.messages}
      >
        <DndProvider backend={HTML5Backend}>{children}</DndProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default AppProvider;
