import React, { useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Bar } from '@ant-design/plots';
import { Button, Table } from 'antd';

import { sortDataReportUserTableChart, sortDataReportUser } from '../../../slice';
import { trainingReportSelector } from '../../../selectors';
import { formatNumber } from 'libs/utils/format';
import StyledTableChart from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  isUserTransTable?: boolean;
}

const TableChart: React.FC<Props> = ({ isUserTransTable }) => {
  const [sortByInProgress, setSortByInProgress] = useState<'asc' | 'desc' | undefined>();
  const [sortByCorrect, setSortByCorrect] = useState<'asc' | 'desc' | undefined>();

  const dispatch = useAppDispatch();

  const { totalReportCurriculumUser, dataReportCurriculumUser } =
    useSelector(trainingReportSelector);

  const columns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> = [
    {
      title: 'No.',
      width: '15%',
      align: 'center',
      render: (_, _record, index) => index + 1,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'index',
                align: 'center',
                width: '15%',
                render: (_text, _record, index) => (
                  <span style={{ color: '#2A2A2A' }}>{index + 1 + (1 - 1) * 10}</span>
                ),
              },
            ]
          : undefined,
    },
    {
      title: isUserTransTable ? 'ユーザー名' : 'カリキュラム名',
      dataIndex: isUserTransTable ? 'user_name' : 'curriculum_name',
      key: 'user_name',
      ellipsis: true,
      render: (record) =>
        record ? (
          <span style={{ color: '#2A2A2A' }}>{record}</span>
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: 'ユーザー平均',
                dataIndex: 'user_name',
                key: 'user_name',
                width: '50%',
                ellipsis: true,
              },
            ]
          : undefined,
    },
    {
      title: (
        <>
          正解進捗率
          <br />/ 進捗率
        </>
      ),
      align: 'center',
      dataIndex: 'progress_rate',
      key: 'progress_rate',
      width: '35%',
      render: (_, record) =>
        !record.accuracy_rate && !record.progress_rate ? (
          <span style={{ color: '#F0330A' }}>未実施</span>
        ) : (
          <span>
            {formatNumber(record.accuracy_rate * 100)}%/
            {formatNumber(record.progress_rate * 100)}%
          </span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: `${formatNumber(
                  dataReportCurriculumUser[0]?.accuracy_rate * 100
                )}%/${formatNumber(dataReportCurriculumUser[0]?.progress_rate * 100)}%`,
                align: 'center',
                dataIndex: 'progress_rate',
                key: 'progress_rate',
                width: '35%',
                render: (_, record) => (
                  <span>
                    {formatNumber(record.accuracy_rate * 100)}%/
                    {formatNumber(record.progress_rate * 100)}%
                  </span>
                ),
              },
            ]
          : undefined,
    },
  ];

  return (
    <StyledTableChart>
      <div className="legend">
        <div className="sort-column">
          <div className="legend-filter green" />
          <span className="legend-name">正解進捗率</span>
          <Button
            className="btn-sort"
            onClick={() => {
              setSortByCorrect((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
              dispatch(
                sortDataReportUser({
                  sort_field: 'accuracy_rate',
                  sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                })
              );
            }}
            onBlur={() => setSortByCorrect(undefined)}
          >
            <div className="wrap-sort">
              <CaretUpOutlined
                className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
              />
              <CaretDownOutlined
                className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
              />
            </div>
          </Button>
        </div>
        <div className="sort-column">
          <div className="legend-filter" />
          <span className="legend-name">進捗率</span>
          <Button
            className="btn-sort"
            onClick={() => {
              setSortByInProgress((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
              dispatch(
                sortDataReportUserTableChart({
                  sort_order: sortByInProgress === 'asc' ? 'desc' : 'asc',
                })
              );
            }}
            onBlur={() => setSortByInProgress(undefined)}
          >
            <div className="wrap-sort">
              <CaretUpOutlined
                className={`icon-caret${sortByInProgress === 'asc' ? ' active' : ''}`}
              />
              <CaretDownOutlined
                className={`icon-caret${sortByInProgress === 'desc' ? ' active' : ''}`}
              />
            </div>
          </Button>
        </div>
      </div>
      <div className="container-table">
        <Table
          className="table"
          rowKey="index"
          rowClassName={(_, index) => (index % 2 === 1 ? 'table-row-hightlight' : '')}
          size="small"
          columns={columns}
          dataSource={dataReportCurriculumUser.map((item, index) => ({ ...item, index })).slice(1)}
          bordered
          pagination={false}
        />
        <div className="chart">
          <div className="header-column" />
          <Bar
            data={dataReportCurriculumUser
              .slice(dataReportCurriculumUser && isUserTransTable ? 0 : 1)
              .map((data) => [
                {
                  key: isUserTransTable ? data.login_id : data.curriculum_code,
                  name: isUserTransTable ? data.user_name : data.curriculum_name,
                  value: Number(formatNumber(data.accuracy_rate * 100)),
                  type: '正解進捗率',
                },
                {
                  key: isUserTransTable ? data.login_id : data.curriculum_code,
                  name: isUserTransTable ? data.user_name : data.curriculum_name,
                  value:
                    Number(formatNumber(data.progress_rate * 100)) -
                    Number(formatNumber(data.accuracy_rate * 100)),
                  initialValue: Number(formatNumber(data.progress_rate * 100)),
                  type: '進捗率',
                },
              ])
              .flat()}
            isStack
            xField="value"
            yField="key"
            seriesField="type"
            autoFit
            padding={[20, 0, 0, 0]}
            xAxis={{
              position: 'left',
              min: 0,
              max: 100,
              grid: {
                line: {
                  style: {
                    stroke: '#ddd',
                    lineDash: [2, 2],
                  },
                },
              },
              label: {
                formatter: (_item, _, index) =>
                  index === 5 ? '50%' : index === 10 ? '100%            ' : '',
                style: {
                  fontSize: 11,
                },
              },
              showLast: true,
              tickCount: 10,
            }}
            yAxis={{
              label: null,
            }}
            tooltip={{
              customContent: (title, items) => {
                return (
                  <>
                    <h5 style={{ marginTop: 16 }}>{title}</h5>
                    <ul style={{ paddingLeft: 0 }}>
                      {items?.map((item, index) => {
                        const { name, value, color } = item;
                        return (
                          <li
                            key={item.year}
                            className="g2-tooltip-list-item"
                            data-index={index}
                            style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                          >
                            <span
                              className="g2-tooltip-marker"
                              style={{ backgroundColor: color }}
                            ></span>
                            <span
                              style={{
                                display: 'inline-flex',
                                flex: 1,
                                justifyContent: 'space-between',
                              }}
                            >
                              <span style={{ marginRight: 16 }}>{name}:</span>
                              <span className="g2-tooltip-list-item-value">
                                {index === 1 ? item.data?.initialValue : value}%
                              </span>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                );
              },
            }}
            color={['#8FD692', '#DDEACD']}
            legend={false}
            maxBarWidth={12}
            style={{
              width: '100%',
              height:
                39 *
                  dataReportCurriculumUser.slice(
                    dataReportCurriculumUser && isUserTransTable ? 0 : 1
                  ).length +
                19,
            }}
          />
        </div>
      </div>
    </StyledTableChart>
  );
};

export default TableChart;
