import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Modal, Select, Tag, Upload } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import JoditEditor from 'jodit-pro-react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy } from 'lodash';
import {
  CaretLeftOutlined,
  CheckCircleOutlined,
  LikeOutlined,
  MessageOutlined,
} from '@ant-design/icons';

import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { createUpdateKnowledgeSchema } from 'libs/validations/knowledgeTop';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { Header, SelectField, TextArea } from 'components';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import VideoPopup from 'pages/KnowledgeTop/UploadVideo';
import { knowledgeTopSelector } from '../../selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { routes } from 'navigations/routes';
import { adminAvatar } from 'assets';
import * as Types from 'types';
import Styled from './styles';
import {
  getSelectDepartment,
  updateKnowledgeTo,
  updateKnowledge,
  getSelectUser,
  createFileAttach,
  deleteFileAttachByConditions,
} from '../../thunk';
import { resetData } from 'pages/KnowledgeTop/slice';

const { Option } = Select;
const { Dragger } = Upload;

const EditKnowledge: React.FC = () => {
  const [files, setFiles] = useState<Array<UploadFile<File>>>([]);
  const [idDeleted, setIDDeleted] = useState<Array<string>>([]);
  const [showPopup, setShowPopup] = useState(false);

  const state = useLocation().state as Types.KnowledgeRecommendDetail.ResponseType;

  const { dataSelectDepartment, data_user } = useSelector(knowledgeTopSelector);

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uniqueUsers = useMemo(() => uniqBy(data_user, 'login_id'), [data_user]);

  const formik = useFormik<Types.CreateProfileFormik>({
    initialValues: {
      title: '',
      to_department: '',
      to_user: '',
      content: '',
    },
    validationSchema: createUpdateKnowledgeSchema,
    validateOnBlur: false,
    onSubmit: async ({ title, to_department, to_user, content }) => {
      if (!userInfo || !state) return;
      dispatch(startLoading());

      const fileIDs = [state.fileID1, state.fileID2, state.fileID3, state.fileID4, state.fileID5];
      for (const fileID of fileIDs) {
        if (fileID) {
          deletedFileInMinIO(fileID);
          dispatch(
            deleteFileAttachByConditions({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
      }
      let fileUpload: Array<string | undefined> = [];

      if (files.length) {
        fileUpload = await Promise.all(
          files
            .map(async (file) => {
              const uploadToMinIo = await uploadFileToMinIO(file.originFileObj as UploadFile<File>);
              await dispatch(
                createFileAttach({
                  item: {
                    fileID: uploadToMinIo,
                    filename: file?.name,
                    file_location: '1',
                    file_extension: file?.type,
                    file_size: `${file?.size}`,
                    company_id: userInfo?.company_id,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                  },
                })
              );
              return uploadToMinIo;
            })
            .filter(Boolean)
        );
      }

      const actionResult = await Promise.all([
        dispatch(
          updateKnowledge({
            id: state.i_id,
            data: {
              item: {
                knowledge_title: title,
                knowledge_text: content,
                updatedat: new Date(),
                ...Array.from({ length: 5, 5: {} }).reduce((result, _, index) => {
                  return { ...result, [`fileID${index + 1}`]: fileUpload[index] || '' };
                }, {}),
              },
              is_force_update: true,
            },
          })
        ),
        ...(state.item_ref?.to_department_id.i_id
          ? [
              dispatch(
                updateKnowledgeTo({
                  id: state.item_ref.to_department_id.i_id,
                  data: {
                    item: {
                      ...(to_department !== 'ALL'
                        ? { to_department_id: to_department }
                        : { to_department_id: '' }),
                      ...(to_user !== 'ALL' && to_department !== 'ALL' && { to_user_id: to_user }),
                    },
                    is_force_update: true,
                  },
                })
              ),
            ]
          : []),
      ]);

      if (updateKnowledge.fulfilled.match(actionResult[0])) {
        navigate(
          state.isScreen === 'favorite'
            ? routes.KnowledgeTopRecommendFavorite.path
            : state.isScreen === 'to_login_id'
            ? routes.KnowledgeTopRecommendToLoginId.path
            : state.isScreen === 'created_by'
            ? routes.KnowledgeTopRecommendCreatedBy.path
            : routes.KnowledgeTopRecommend.path,
          {
            state: {
              knowledge_id: state.knowledge_id,
              isScreen: state.isScreen,
            },
          }
        );
      }
      dispatch(stopLoading());
    },
  });

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block', 'add-new-line'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      // readonly: !isEdit && isViewMode,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const base64Array: Array<string> = useMemo(() => {
    if (!formik.values.content) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.content, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [formik.values.content]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '5',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.content?.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      formik.setFieldValue('content', replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getSelectUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(formik.values.to_department
              ? [
                  {
                    id: 'department_code',
                    search_value: [formik.values.to_department],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [formik.values.to_department, dispatch, userInfo]);

  useEffect(() => {
    if (!state) return;
    formik.setFieldValue('title', state.knowledge_title);
    formik.setFieldValue('content', state.knowledge_text);
    formik.setFieldValue('to_department', state.to_department_id || 'ALL');
    formik.setFieldValue('to_user', state.to_user_name || 'ALL');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      <Header title={headerTitle} />
      <Styled isCheckUpload={isDisabledUpload}>
        <div className="wrap-head">
          {!state?.recommendation_status ? (
            <div className="label check">
              <CheckCircleOutlined className="icon" />
              回答済み
            </div>
          ) : (
            <div className="label">
              <MessageOutlined className="icon" />
              質問あり
            </div>
          )}

          <div
            className="goback"
            onClick={() => {
              navigate(
                state.isScreen === 'favorite'
                  ? routes.KnowledgeFavorite.path
                  : state.isScreen === 'to_login_id'
                  ? routes.KnowledgeToLoginId.path
                  : state.isScreen === 'created_by'
                  ? routes.KnowledgeCreatedBy.path
                  : routes.KnowledgeTop.path
              );
              dispatch(resetData());
            }}
          >
            <CaretLeftOutlined className="icon-back" />
            一覧に戻る
          </div>
        </div>
        <div className="body">
          <div className="wrap-title">
            <div className="left-side">リコメンド</div>
            <div className="right-side">
              <span>投稿者：</span>
              <div className="wrap-user">
                <Avatar
                  size={40}
                  src={
                    userInfo?.avatar?.originFileObj
                      ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
                      : adminAvatar
                  }
                />
                <div className="wrap-information">
                  <div className="information">
                    <span className="department">
                      {state?.author_affliation_id.replace(/^\d+ /, '')}
                    </span>
                    <span className="name">{state?.author_name}</span>
                  </div>
                  <span className="wrap-like">
                    <LikeOutlined className="icon-like" /> {state.number_of_likes}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <FormikProvider value={formik}>
            <div className="form">
              <Form layout="vertical">
                <Form.Item
                  name="title"
                  className="item"
                  label={
                    <span>
                      タイトル <span className="require"> *</span>
                    </span>
                  }
                >
                  <TextArea
                    className="text-area"
                    name="title"
                    rows={5}
                    showCount
                    maxLength={100}
                    placeholder="全角：最大100文字"
                  />
                </Form.Item>
                <div className="wrap-select">
                  <Form.Item
                    name="to_department"
                    className="item"
                    label={
                      <span>
                        To 部署 <span className="require"> *</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="to_department"
                      onChange={() => {
                        formik.setFieldValue('to_user', 'ALL');
                      }}
                    >
                      <Option value="ALL">ALL</Option>
                      {dataSelectDepartment.map((e) => (
                        <Option value={e.affiliation_id} key={e.i_id}>
                          {e.name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item name="to_user" className="item" label={<span>To ユーザー署</span>}>
                    <SelectField
                      name="to_user"
                      className={formik.values.to_department === 'ALL' ? 'disable' : 'active'}
                      disabled={formik.values.to_department === 'ALL'}
                    >
                      <Option value="ALL">ALL</Option>
                      {uniqueUsers?.map((e, index) => (
                        <Option value={e.login_id} key={index}>
                          {e.user_name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
                {/* <Form.Item
                  name="content"
                  className="item"
                  label={
                    <span>
                      内容 <span className="require"> *</span>
                    </span>
                  }
                >
                  <TextArea
                    className="text-area"
                    name="content"
                    rows={15}
                    showCount
                    maxLength={1500}
                    placeholder="全角：最大1500文字"
                  />
                </Form.Item> */}
                <div className="wrap-jodit">
                  <JoditEditor
                    value={formik.values.content}
                    config={defaultConfig}
                    onBlur={(newContent) => formik.setFieldValue('content', newContent)}
                    onChange={(newContent) => {
                      formik.setFieldValue('content', newContent);
                    }}
                  />
                </div>
                {showPopup && (
                  <Modal
                    visible={showPopup}
                    closable={false}
                    onOk={handlePopupClose}
                    onCancel={handlePopupClose}
                    footer={null}
                  >
                    <VideoPopup formik={formik} setOpen={setShowPopup} />
                  </Modal>
                )}
                <div className="wrap-upload">
                  <p className="note">ファイル添付（PDF, Excel, Word, Jpeg, png, gif）※10MBまで</p>
                  <div className={`upload ${!isDisabledUpload ? 'disabled' : ''}`}>
                    <div className="wrap-dragger">
                      <Dragger
                        maxCount={5}
                        showUploadList={false}
                        onChange={(e) => setFiles(e.fileList)}
                        fileList={files}
                      >
                        <div className="file-name">
                          {files.map((e, index) => (
                            <Tag
                              key={index}
                              closable
                              onClose={(event) => {
                                event.preventDefault();
                                setFiles(files.filter((_, i) => index !== i));
                                setIDDeleted([...idDeleted, files[index]?.uid]);
                              }}
                            >
                              {e.name.length > 12 ? `${e.name.slice(0, 13)}...` : e.name}
                            </Tag>
                          ))}
                        </div>
                      </Dragger>
                    </div>
                    <Upload
                      maxCount={5}
                      showUploadList={false}
                      className="upload-button"
                      onChange={(e) => setFiles(e.fileList)}
                      fileList={files}
                    >
                      参照
                    </Upload>
                  </div>
                </div>
                <div className="wrap-button">
                  <SubmitButton className="submit">更新する</SubmitButton>
                  <Button className="cancle" onClick={() => navigate(-1)}>
                    キャンセル
                  </Button>
                </div>
                <p className="please">リコメンドクリックで、投稿が完了します。</p>
              </Form>
            </div>
          </FormikProvider>
        </div>
      </Styled>
    </>
  );
};

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];

export default EditKnowledge;
