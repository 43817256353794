import { createSlice } from '@reduxjs/toolkit';
import { groupBy, unionBy } from 'lodash';

import * as Types from 'types';
import {
  getDataKnowledgeInfo,
  getDataKnowledgeList,
  getDataProfileCurriculum,
  getDataProfileCurriculumTable,
  getDataRankingUserAll,
  getKnowledgeFavoriteHistory,
  getKnowledgeLikeHistory,
  getSelectDepartmentName,
  getUserInfoDetail,
} from './thunk';

export interface InitialState {
  dataProfileCurriculumUnRequiredCompleted: Array<Types.ProfileCurriculum.ResponseType>;
  dataProfileCurriculumRequiredCompleted: Array<Types.ProfileCurriculum.ResponseType>;
  dataProfileOfficialCurriculumCompleted: Array<Types.ProfileCurriculum.ResponseType>;
  dataKnowledgeFavoriteHistory: Array<Types.KnowledgeFavoriteHistory.ResponseType>;
  dataProfileCurriculumUnRequired: Array<Types.ProfileCurriculum.ResponseType>;
  dataProfileOfficialCurriculum: Array<Types.ProfileCurriculum.ResponseType>;
  dataProfileCurriculumRequired: Array<Types.ProfileCurriculum.ResponseType>;
  dataKnowledgeLikeHistory: Array<Types.KnowledgeLikesHistory.ResponseType>;
  dataKnowledgeInfo: Array<Types.RequiredCurriculumSkillCheck.ResponseType>;
  dataProfileCurriculumTable: Array<Types.ProfileCurriculum.ResponseType>;
  dataRankingUserAll: Array<Types.RankingLikeUserAll.ResponseType>;
  dataKnowledgeCurriculum: Array<Types.KnowledgeList.ResponseType>;
  dataKnowledgeRecommend: Array<Types.KnowledgeList.ResponseType>;
  dataKnowledgeList: Array<Types.KnowledgeList.ResponseType>;
  dataKnowledgeQA: Array<Types.KnowledgeList.ResponseType>;
  departmentNames?: Types.SelectDepartmentUser.ResponseType;
  userInfoDetail?: Types.Users.ResponseType;
  total: number;
}

const initialState: InitialState = {
  dataKnowledgeInfo: [],
  dataProfileCurriculumUnRequiredCompleted: [],
  dataProfileCurriculumRequiredCompleted: [],
  dataProfileOfficialCurriculumCompleted: [],
  dataProfileCurriculumUnRequired: [],
  dataProfileOfficialCurriculum: [],
  dataProfileCurriculumRequired: [],
  dataKnowledgeFavoriteHistory: [],
  dataProfileCurriculumTable: [],
  dataKnowledgeLikeHistory: [],
  dataKnowledgeCurriculum: [],
  dataKnowledgeRecommend: [],
  dataRankingUserAll: [],
  dataKnowledgeList: [],
  dataKnowledgeQA: [],
  total: 0,
};

export const profileSlice = createSlice({
  name: 'profile-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataKnowledgeInfo.fulfilled, (state, action) => {
      state.dataKnowledgeInfo = action.payload.report_results;
    });

    builder.addCase(getUserInfoDetail.fulfilled, (state, action) => {
      state.userInfoDetail = action.payload.items[0];
    });

    builder.addCase(getSelectDepartmentName.fulfilled, (state, action) => {
      state.departmentNames = action.payload.report_results[0];
    });
    builder.addCase(getDataProfileCurriculum.fulfilled, (state, action) => {
      state.dataProfileCurriculumUnRequiredCompleted = action.payload.report_results.filter(
        (item) =>
          item.curriculum_trans_status === 1 &&
          item.required_curriculum === 0 &&
          !item.official_curriculum
      );
      state.dataProfileCurriculumUnRequired = action.payload.report_results.filter(
        (item) =>
          item.curriculum_trans_status === 0 &&
          item.required_curriculum === 0 &&
          !item.official_curriculum
      );
      state.dataProfileCurriculumRequiredCompleted = action.payload.report_results.filter(
        (item) => item.curriculum_trans_status === 1 && item.required_curriculum === 1
      );
      state.dataProfileCurriculumRequired = action.payload.report_results.filter(
        (item) => item.curriculum_trans_status === 0 && item.required_curriculum === 1
      );
      state.dataProfileOfficialCurriculumCompleted = action.payload.report_results.filter(
        (item) => item.curriculum_trans_status === 1 && item.official_curriculum === 1
      );
      state.dataProfileOfficialCurriculum = action.payload.report_results.filter(
        (item) => item.curriculum_trans_status === 0 && item.official_curriculum === 1
      );
    });
    builder.addCase(getDataProfileCurriculumTable.fulfilled, (state, action) => {
      state.dataProfileCurriculumTable = unionBy(action.payload.report_results, 'curriculum_code');
    });
    builder.addCase(getDataRankingUserAll.fulfilled, (state, action) => {
      const dataResult: Types.RankingLikeUserAll.ResponseType[] = Object.values(
        groupBy(action.payload.report_results, 'author_user_id')
      ).map((group) => ({
        ...group[0],
        number_of_likes: group.length.toString(),
      }));
      state.dataRankingUserAll = dataResult;
    });
    builder.addCase(getDataKnowledgeList.fulfilled, (state, action) => {
      state.dataKnowledgeList = action.payload.report_results;
      state.dataKnowledgeCurriculum = action.payload.report_results.filter(
        (item) => item.knowledge_type === 1
      );
      state.dataKnowledgeQA = action.payload.report_results.filter(
        (item) => item.knowledge_type === 2
      );
      state.dataKnowledgeRecommend = action.payload.report_results.filter(
        (item) => item.knowledge_type === 3
      );
    });
    builder.addCase(getKnowledgeFavoriteHistory.fulfilled, (state, action) => {
      state.dataKnowledgeFavoriteHistory = action.payload.report_results;
    });
    builder.addCase(getKnowledgeLikeHistory.fulfilled, (state, action) => {
      state.dataKnowledgeLikeHistory = action.payload.report_results;
    });
  },
});

export const { resetInitialState } = profileSlice.actions;

export default profileSlice.reducer;
