import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  padding: 30px;
  margin-bottom: 90px;
  .wrap-intro {
    display: flex;
    justify-content: space-between;
    .button-export {
      background: #f6ac00;
      width: 200px;
      height: 40px;
      border: none;
      color: #ffffff;
      font-weight: 700;
      font-size: 13px;
      border-radius: 5px;
    }
  }
  .wrap-basic-information,
  .wrap-department,
  .wrap-curriculum-setting,
  .wrap-skill-check {
    width: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-top: 30px;
    .header {
      height: 48px;
      font-size: 16px;
      color: #2a2a2a;
      padding: 0 20px;
      display: flex;
      align-items: center;
    }
    .body {
      border-top: 1px solid #cccccc;
      padding: 40px;
    }
  }
  .wrap-basic-information {
    .body {
      display: flex;
      font-size: 13px;
      justify-content: space-between;
      .wrap-user {
        width: 15%;
        margin-top: 30px;
        margin-left: 10px;
        .avatar-user {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #f0efef;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .avatar {
            width: 100%;
            height: 100%;
          }
          .icon-user {
            font-size: 78px;
            color: #cccccc;
          }
          .text-content {
            font-family: Lato;
            font-size: 11px;
            color: #999999;
            text-align: center;
            display: block;
            margin-top: 15px;
          }
          .ant-upload {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
      .form-left,
      .form-right {
        width: 38%;
        .text-label {
          font-size: 13px;
          color: #424242;
        }
        .form-input {
          .text-input {
            font-size: 14px;
          }
          .input_small {
            width: 120px;
          }
          .input-month-day {
            width: 80px;
          }
          .text-label-content {
            font-size: 12px;
            color: #777777;
            margin: 0 10px 0 7px;
          }
        }
      }
    }
  }

  .wrap-department .body {
    font-size: 13px;
    .recruitment-information {
      .form-input {
        .text-input {
          font-size: 14px;
        }
        .input_small {
          width: 120px;
        }
        .input-month-day {
          width: 80px;
        }
        .text-label-content {
          font-size: 12px;
          color: #777777;
          margin: 0 10px 0 7px;
        }
      }
    }
    .wrap-recruitment {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      .form-input {
        width: 48%;
        .text-input {
          font-size: 14px;
        }
        .text-label-content {
          font-size: 12px;
          color: #777777;
          margin: 0 10px 0 7px;
        }
      }
    }

    .work-history {
      .wrap-text-area {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      label {
        width: 100%;
        font-size: 13px;
      }
      .text-enlarge {
        color: #00989a;
        cursor: pointer;
      }
    }
  }

  .empty-text {
    background-color: #fbfbfb;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #d9d9d9;
    color: #bbbbbb;
    padding: 8px 0;
    cursor: not-allowed;
  }
  .wrap-skill-check,
  .wrap-curriculum-setting {
    .body {
      .wrap-form {
        background-color: #f2f2f2;
        border: 1px solid #eaeaea;
        border-radius: 4px;
        padding: 20px;
        height: auto;
        margin-bottom: 40px;
        .form-row {
          display: flex;
          align-items: center;
          .form-input {
            width: 100%;
            margin-right: 12px;
          }
        }
      }
      .disabled + .disabled,
      .item-result + .item-result {
        border-top: none;
      }
      .wrap-label-hide {
        text-align: right;
        margin-bottom: 0;
        .label-hide {
          font-size: 13px;
          text-decoration-line: underline;
          color: #159193;
          cursor: pointer;
          margin: 0 10px;
          .icon {
            font-size: 17px;
            color: #00a3a5;
            margin: 0 6px;
          }
        }
        .select-sort-order .ant-select-selector {
          border-radius: 3px 3px 0 0;
          border-bottom: none;
          width: 180px;
        }
      }
      .item-result,
      .item-result-add {
        border: 2px solid #d9d9d9;
        background-color: #ffffff;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .text-add {
          font-family: Lato;
          font-size: 13px;
          padding: 0 15px;
          color: #999999;
        }
        .content {
          font-family: Lato;
          font-size: 14px;
          color: #2a2a2a;
        }
        .delete {
          color: #bbbbbb;
          font-size: 18px;
          padding: 0 15px;
          cursor: pointer;
        }
        .active {
          color: #00a3a5;
        }
      }
      .item-result-add {
        border: 2px dashed #92cecf;
      }
    }
  }
  .require {
    font-family: Lato;
    font-size: 20px;
    color: #f0330a;
  }
  .button {
    padding: 7px 25px;
    background-color: #ffffff;
    border: 1px solid #00a3a5;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: 700;
    font-size: 12px;
    color: #00a3a5;
    cursor: pointer;
    margin: 20px;
  }

  .pull_down {
    font-size: 14px;
    color: #424242;
    border-radius: 4px;
    :not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: auto;
      padding: 6px 11px;
    }
  }
  .label {
    font-weight: 700;
    font-size: 13px;
    color: #424242;
    display: block;
    margin-bottom: 5px;
  }
  .form-group {
    display: flex;
    align-items: center;
    .item {
      width: 240px;
      margin-right: 8px;
    }
    .reset {
      font-size: 12px;
      text-decoration-line: underline;
      color: #00a3a5;
      cursor: pointer;
    }
  }

  .ant-radio-group {
    width: 100%;
    .ant-radio-wrapper {
      width: 100%;
      margin-left: 20px;
    }
    .ant-radio {
      position: absolute;
      top: 35px;
      z-index: 99;
      left: -17px;
    }
    span.ant-radio + * {
      width: 100%;
    }
  }
  .ant-select-disabled .ant-select-selection-item {
    font-size: 14px;
    color: #2a2a2a;
  }
  .wrap-submit {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .wrap-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .btn {
        border-radius: 5px;
        border: none;
        width: 16%;
        height: 40px;
        cursor: pointer;
        &_submit {
          margin-right: 12px;
          color: #ffffff;
          background-color: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        }
        &_close {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          box-sizing: border-box;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          color: #777777;
        }
      }
    }
  }
`;
