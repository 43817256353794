import { useEffect, useRef } from 'react';
import { isEqual, omit } from 'lodash';

import * as Types from 'types';

export const useUserInfoChanged = (userInfo?: Types.Users.ResponseType): boolean => {
  const prevUserInfo = useRef<Types.Users.ResponseType | undefined>(undefined);

  useEffect(() => {
    if (userInfo && !isEqual(omit(userInfo, ['avatar']), omit(prevUserInfo.current, ['avatar']))) {
      prevUserInfo.current = userInfo;
    } else if (!userInfo) {
      prevUserInfo.current = undefined;
    }
  }, [userInfo]);

  return !prevUserInfo.current;
};
