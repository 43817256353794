import React from 'react';
import { DownOutlined, DeleteOutlined, FormOutlined } from '@ant-design/icons';
import { Form, Button, Table, Select, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import InterviewUsersStyled from '../../styles';
import { usePermission } from 'hooks';
import { Header } from 'components';
import * as Types from 'types';

const { Option } = Select;

interface Props {
  page: number;
  perPage: number;
  dataInterviewUser: Types.InterviewUser.ResponseType[];
  total: number;
}

const InterviewUserPDF: React.FC<Props> = ({ page, perPage, dataInterviewUser, total }) => {
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const { permissionNumber } = usePermission();

  const columns: ColumnsType<Types.InterviewUser.ResponseType> = [
    {
      title: '管理番号',
      dataIndex: 'manage_code',
      key: 'manage_code',
      width: '8%',
      align: 'left',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '10%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: '募集所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      ellipsis: true,
      align: 'left',
      render: (title: string) => <span>{title.replace(/^\d+ /, '')}</span>,
    },
    {
      title: '募集役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '10%',
      ellipsis: true,
      align: 'left',
      render: (title: string) => <span>{title.replace(/^\d+ /, '')}</span>,
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '7%',
      align: 'left',
      render: (text: string) => text && `${dayjs().diff(text, 'years', false)}歳`,
    },
    {
      title: '職歴',
      dataIndex: 'work_history',
      key: 'work_history',
      width: '5%',
      align: 'left',
      render: (text, _, index) =>
        text && (
          <Tooltip
            trigger={['click']}
            title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
            color="#ffffff"
          ></Tooltip>
        ),
    },
    {
      title: '採用予定年月',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '8%',
      align: 'left',
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM')}`,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      align: 'left',
      ellipsis: true,
    },
    {
      title: (
        <span>
          スキルチェック
          <br />
          実施日
        </span>
      ),
      dataIndex: 'implementation_date',
      key: 'implementation_date',
      width: '10%',
      align: 'left',
      ellipsis: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      align: 'left',
      render: (_, item) => {
        return <FormOutlined className="icon" />;
      },
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '5%',
      align: 'left',
      render: (_, item) => <DeleteOutlined className="icon" />,
    },
  ];
  return (
    <>
      <Header title={headerTitle} />
      <InterviewUsersStyled collapsedMenu={collapsedMenu} isEmptyData={!dataInterviewUser.length}>
        <p className="text-note">
          面接ユーザーの作成・管理を行う画面です。面接時にスキルチェックを行うユーザーを登録します。
          <br />
          ユーザー登録後に実施していただくスキルチェックを設定します。
        </p>
        <div className="border-line" />
        <Form layout="vertical" colon={false}>
          <div className="form-search-pdf">
            <Form.Item
              name="manage_code"
              className="item"
              label={<span className="text-label">管理番号</span>}
            >
              <Select showSearch allowClear></Select>
            </Form.Item>
            <Form.Item
              name="name"
              className="item"
              label={<span className="text-label">氏名</span>}
            >
              <Select showSearch allowClear>
                {dataInterviewUser?.map(({ name }, i) => (
                  <Option value={name} key={i}>
                    {name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="department_name"
              className="item"
              label={<span className="text-label">募集所属</span>}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              ></Select>
            </Form.Item>
            <Form.Item
              name="position_code"
              className="item"
              label={<span className="text-label">募集役職</span>}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              ></Select>
            </Form.Item>
            <Form.Item
              name="hire_date"
              className="item"
              label={<span className="text-label">採用予定年月</span>}
            >
              <Select
                showSearch
                allowClear
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              ></Select>
            </Form.Item>
          </div>
        </Form>

        <div
          className={
            dataInterviewUser.length > 0
              ? 'text-count-general'
              : 'text-count-general text-count-no-data'
          }
        >
          {page * perPage > total ? total : page * perPage}
          <span className="text-static">件表示</span> / {total}
          <span className="text-static">名</span>
        </div>
        <Table
          rowKey="index"
          className="table-pdf"
          dataSource={dataInterviewUser.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザを処理：
              <Button className="btn btn-active">エクスポート</Button>
              <Button className="btn btn-active ml-5">ログインURL送信</Button>
            </div>
            <Button className="btn btn-outline">
              スキルチェック一括設定
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
      </InterviewUsersStyled>
    </>
  );
};

export default InterviewUserPDF;
