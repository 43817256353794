import styled from 'styled-components';

export const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .check-answer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px;
    border-bottom: 1px solid #dddddd;
    .close {
      display: flex;
      align-items: center;
      position: relative;
      img {
        width: 92px;
      }
      .icon {
        position: absolute;
        font-size: 100px;
        color: #ea8c92;
        top: -25px;
        left: -10px;
        z-index: 1;
      }
      .label {
        font-weight: 700;
        font-size: 26px;
        color: #de1e2a;
        margin: 0;
        -webkit-text-stroke: 2px #ffff;
        z-index: 2;
      }
      .correct-answer {
        font-weight: 700;
        font-size: 28px;
        color: #424242;
        margin: 0;
        padding-left: 38px;
      }
    }
  }
  .confirm-answer {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    .explanation {
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      color: #424242;
    }
    .answer {
      font-size: 15px;
      color: #424242;
    }
    .item-question {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-top: 30px;
      cursor: default;
      position: relative;
      .wrap-item {
        width: unset !important;
        display: flex !important;
        justify-content: center !important;
        background: #e1dcdc;
        .item-image {
          display: flex !important;
          justify-content: center;
          max-height: 324px;
          max-width: 100%;
          position: relative;
          flex-direction: column;
          .video-attach {
            max-height: 324px;
          }
          .ant-image {
            height: 100%;
            .image-attach {
              background: #e1dcdc;
              max-width: 100%;
              height: 324px;
              object-fit: contain;
            }
          }
        }
      }

      .zoom-image {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        bottom: 15px;
        right: 15px;
        .icon-zoom {
          color: #aaaaaa;
        }
      }
      .btn-next {
        background: #ffffff;
        border: none;
        cursor: pointer;
        .icon {
          color: #c4c4c4;
          font-size: 20px;
        }
      }
      .carousel {
        width: 576px;
      }
      @media (max-width: 1440px) {
        .carousel {
          width: 576px;
          .item-image {
            display: flex !important;
            justify-content: center;
            flex-direction: column;
            position: relative;
          }
        }
        .slide {
          width: 350px;
        }
      }
      .zoom-video {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.8);
        align-self: end;
        margin-left: -45px;
        margin-bottom: 5px;
        z-index: 1;
        .icon-zoom {
          color: #aaaaaa;
        }
      }
      .zoom-image {
        width: 37px;
        height: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.8);
        position: absolute;
        bottom: 15px;
        right: 15px;
        .icon-zoom {
          color: #aaaaaa;
        }
      }
    }
  }
  .group-btn {
    margin: 20px auto;
    .btn {
      width: 200px;
      height: 52px;
      border-radius: 5px;
      border: none;
      font-weight: 700;
      font-size: 18px;
      text-align: center;
      cursor: pointer;
    }
    .btn-close {
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      background: #ffffff;
      color: #00a3a5;
      margin-right: 20px;
    }
    .btn-active {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      color: #ffffff;
    }
  }

  .ant-image-preview-mask {
    z-index: 1001;
  }
  .ant-modal-wrap {
    z-index: 999 !important;
  }
  .ant-modal-mask {
    z-index: 999 !important;
  }
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-image-mask {
    align-items: flex-end;
    justify-content: end;
  }
  .ant-image-mask {
    display: none;
  }
  .ant-image-preview-operations {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
`;
