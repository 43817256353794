import styled from 'styled-components';

export const UsageDetailStyled = styled.div`
  .wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time-label {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #cccccc;
      padding: 10px;
      .label {
        margin: 0;
        font-size: 11px;
        text-align: center;
        color: #777777;
      }
      .time-text {
        margin: 0;
        font-size: 20px;
        color: #2a2a2a;
      }
    }
    .button-export {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 700;
      padding: 20px;
      border-radius: 5px;
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      color: #ffffff;
      .download-icon {
        font-size: 18px;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    background: #dddddd;
    border-radius: 2px;
    .sub-container {
      width: 100%;
      height: 100%;
      background: #ffffff;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
      padding: 20px 40px;
      margin: 25px;
      .receipt-info {
        position: relative;
        margin: 30px 0;
        .title {
          font-size: 24px;
          text-align: center;
          color: #000000;
          margin: 0;
        }
        .info {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 12px;
          text-align: left;
          color: #000000;
        }
      }
      .sent {
        display: inline-block;
        width: 50%;
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000000;
        p {
          font-size: 18px;
          color: #000000;
          margin: 0;
        }
      }
      .amount-money {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border-radius: 2px;
        background: #eaf2f9;
        .label {
          margin: 0;
          font-size: 15px;
          color: #000000;
        }
        .label-number {
          margin: 0;
          font-size: 33px;
          text-align: center;
          color: #000000;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;
        .left-side {
          .text-label,
          .payment-method {
            font-size: 15px;
            color: #000000;
          }
          .row {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #000000;
            padding: 5px 0;
            .label {
              margin: 0;
              font-size: 15px;
              color: #000000;
            }
            .value {
              margin: 0;
              font-size: 15px;
              color: #000000;
            }
          }
        }
        .right-side {
          display: flex;
          align-items: end;
          position: relative;
          p {
            font-size: 15px;
            color: #000000;
            margin: 0;
          }
          .logo {
            position: absolute;
            bottom: -10px;
            right: -10px;
          }
        }
      }
    }
  }
`;
