import styled from 'styled-components';

export default styled.section`
  .label-name {
    font-size: 12px;
    color: #777777;
  }
  .item-name {
    border-bottom: 1px solid #cccccc;
    .item-text {
      font-size: 15px;
      color: #222222;
    }
  }
  .explanation {
    margin-top: 35px;
    .text-explanation {
      background: #ffffff;
      border: 1px solid #ebebeb;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      padding: 14px;
    }
  }
`;
