import React, { useMemo, useState } from 'react';
import { DeleteOutlined, DownOutlined, UpOutlined, FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { orderBy } from 'lodash';
import { Button } from 'antd';

import ConcurrentAdditionalModal from 'pages/Settings/AffiliationMaster/Modal/ConcurrentAdditional';
import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/affiliation/memoized-tree-data-utils';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Edit } from 'pages/Settings/AffiliationMaster/Modal';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import ItemDropDown from './ItemDropdown';
import PopupStyled from './styles';
import * as Types from 'types';
import {
  deleteAffiliationLevel,
  editLinkAffiliationLevel,
  editLinkAffiliationRole,
  getAffiliations,
  getPositionSelect,
  getUserList,
} from 'pages/Settings/AffiliationMaster/thunk';

interface Props {
  activeCollapse?: boolean;
  data: Types.TreeItem<Types.AffiliationItemType>;
  handleShrink?: () => void;
  setOpenDetail?: React.Dispatch<React.SetStateAction<boolean>>;
}

const DepartmentDetailPopup: React.FC<Props> = ({
  data,
  handleShrink,
  setOpenDetail,
  activeCollapse,
}) => {
  const [visibleConcurrentAdditional, setVisibleConcurrentAdditional] = useState<boolean>(false);
  const [isShowPopupDelete, setIsShowPopupDelete] = useState<boolean>(false);
  const [isShowPopupEdit, setIsShowPopupEdit] = useState<boolean>(false);
  const [isExpand, setExpand] = useState<boolean>(true);

  const userChildren = useMemo(() => {
    const userOrder = orderBy(
      data.user_children,
      ['lookup_items.user_positions.rank_order', 'user_name'],
      ['asc', 'desc']
    );
    return userOrder;
  }, [data]);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const handleDeleteAffiliationLevel = async () => {
    dispatch(startLoading());
    const listChildItemID = memoizedGetChildrenItemIDFromTree({
      treeData: data,
    });
    await Promise.all(
      listChildItemID.map((item: Types.FlatChildrenItemID<Types.AffiliationItemType>) =>
        dispatch(
          deleteAffiliationLevel({
            id: item.i_id!,
          })
        )
      )
    );
    await dispatch(
      getAffiliations({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
      })
    );
    dispatch(stopLoading());
  };

  const [, drop] = useDrop<
    { type: string; user: Types.UserAffiliation; affiliation_id: string },
    void,
    void
  >({
    accept: 'user-department',
    canDrop: (item) => data && item.affiliation_id !== data.affiliation_i_id,
    drop: (item) => {
      (async () => {
        if (!item.user?.item_ref || !data) return;
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            editLinkAffiliationLevel({
              id: item.user.item_ref.user_sort_order.i_id,
              data: {
                item: {
                  affiliation_id: data.affiliation_id,
                },
                is_force_update: true,
              },
            })
          ),
          dispatch(
            editLinkAffiliationRole({
              id: item.user.item_ref.user_role_order.i_id,
              data: {
                item: {
                  affiliation_id: data.affiliation_i_id,
                },
                is_force_update: true,
              },
            })
          ),
        ]);
        await dispatch(
          getAffiliations({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        );
        dispatch(stopLoading());
      })();
    },
  });

  return (
    <PopupStyled ref={drop} activeCollapse={activeCollapse} isExpand={isExpand}>
      <div className="header-tooltip">
        <div className="header-title">
          <p
            className="title"
            onClick={() => {
              if (activeCollapse && handleShrink) {
                handleShrink();
                setExpand((prevState) => !prevState);
              }
            }}
          >
            <small>
              {isExpand ? (
                <DownOutlined className="icon-outlined" />
              ) : (
                <UpOutlined className="icon-outlined" />
              )}
            </small>
            {data.name}
            <small className="text-code">（{data.affiliation_id}）</small>
          </p>
        </div>
        <div className="header-information">
          <FormOutlined
            className="icon"
            onClick={() => permissionNumber !== 1 && setIsShowPopupEdit(true)}
          />
          <DeleteOutlined
            className="icon"
            onClick={() => permissionNumber !== 1 && setIsShowPopupDelete(true)}
          />
          <div className="wrap-amount-user">
            <span className="title">
              {userChildren?.length || 0} <small>人</small>
            </span>
            <span className="sub-title">（下位階層含む： {data.total_user || 0} 人）</span>
          </div>
        </div>
        <div className="header-btn">
          <Button
            disabled={permissionNumber === 1}
            className={permissionNumber === 1 ? 'disabled' : 'btn-add-user'}
            loading={loading}
            onClick={async () => {
              dispatch(startLoading());
              await Promise.all([
                dispatch(
                  getUserList({
                    page: 1,
                    per_page: 0,
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [userInfo?.company_id],
                      },
                    ],
                  })
                ),
                dispatch(
                  getPositionSelect({
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [userInfo?.company_id],
                      },
                    ],
                    page: 1,
                    per_page: 0,
                  })
                ),
              ]);
              dispatch(stopLoading());
              setVisibleConcurrentAdditional(true);
            }}
          >
            ＋ 兼務ユーザー追加
          </Button>
        </div>
      </div>
      <div className="body">
        <div className="wrap-item">
          {userChildren &&
            userChildren.map((user, i) => (
              <div key={i} className="item">
                <ItemDropDown
                  index={i}
                  user={user}
                  affiliation_id={data.i_id}
                  setOpenDetail={setOpenDetail}
                />
              </div>
            ))}
        </div>
      </div>
      <ConcurrentAdditionalModal
        data={data}
        visible={visibleConcurrentAdditional}
        setVisible={setVisibleConcurrentAdditional}
      />
      <ConfirmDeleteModal
        visible={isShowPopupDelete}
        setVisible={setIsShowPopupDelete}
        onSubmit={handleDeleteAffiliationLevel}
        okButtonDisabled={!!data.total_user}
        title={!data.total_user ? '削除確認' : '所属削除確認'}
        subTitle={!data.total_user ? 'データの削除は実行できません。' : '所属の削除を実行します。'}
        description={
          !data.total_user ? (
            <>
              削除を実行すると、復元できませんのでご注意ください。
              <br />
              ※メイン所属のユーザーが設定されている場合削除できません。所属移動後に再度実行してください。
            </>
          ) : (
            <>
              指定した所属と配下の所属にメイン所属のユーザーが設定されているため削除できません。
              <br />
              所属移動後に再度実行してください。
            </>
          )
        }
      />
      <Edit data={data} visible={isShowPopupEdit} setVisible={setIsShowPopupEdit} />
    </PopupStyled>
  );
};

export default DepartmentDetailPopup;
