import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  .header .title-page {
    font-weight: normal;
  }
  .wrap-select-record,
  .wrap-select-record-pdf {
    display: flex;
    align-items: flex-end;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .wrap-select-record-pdf {
    margin-bottom: 8px;
  }
  .select-option {
    width: 66px;
    height: 28px;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .container-pdf {
    padding: 24px;
  }
  .content-pdf {
    font-size: 12px;
    color: #424242;
    margin: 0;
    margin-bottom: 24px;
  }
  .text-count-pdf {
    font-size: 11px;
    color: #424242;
    margin: 20px 0;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
      [colspan]
    )::before {
    height: 0;
  }
  .ant-table-container {
    border: 1px solid #e5e5e5;
    border-radius: 3px;
  }
  .container {
    padding: 30px;
    margin-bottom: 190px;
    position: relative;
    .description .content {
      font-size: 12px;
      color: #424242;
    }
    .border {
      width: 40px;
      margin: 30px 0;
      border-top: 1px solid #dddddd;
    }
    .form-search {
      margin: 20px 0;
      display: flex;
      align-items: flex-end;
      .form-input {
        margin-bottom: 0;
        .label {
          font-family: Lato;
          font-size: 12px;
          color: #424242;
        }
        .select-input {
          width: 400px;
          font-family: Lato;
        }
      }
      .wrap-btn {
        margin-left: 30px;
        .btn-search {
          background-color: #ffffff;
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-size: 14px;
          color: #00a3a5;
          padding-right: 25px;
          cursor: pointer;
          .icon-search {
            font-size: 18px;
            margin-right: 5px;
          }
        }
        .btn-reset {
          border: none;
          background: none;
          cursor: pointer;
          margin-right: 12px;
          .label-reset {
            font-size: 12px;
            text-decoration: underline;
            color: #00a3a5;
          }
        }
      }
    }
    .text-count {
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
    }
    .text-count-pdf {
    }
    .btn-div {
      display: flex;
      position: absolute;
      right: 26px;
      justify-content: space-between;
      margin-top: 10px;
      z-index: 9;
      .btn {
        padding: 0 27px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        margin-right: 8px;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        font-weight: bold;
        border-radius: 5px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        color: #ffffff;
        .size-icon {
          margin-right: 12px;
        }
      }
      .btn-download {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .table {
      .wrap-edit-submit {
        position: absolute;
        top: 14px;
        left: 30px;
        z-index: 5;
      }
      .btn {
        border-radius: 5px;
        border: none;
        width: 101px;
        height: 30px;
        cursor: pointer;
        &_submit {
          margin-bottom: 10px;
          color: #ffffff;
          display: flex;
          align-items: center;
          background-color: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          column-gap: 5px;
          font-size: 12px;
          font-weight: 500;
        }
        &_close {
          font-size: 12px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          color: #777777;
        }
      }
      &_mr {
        margin-top: 85px;
      }
      .ant-table-tbody .ant-table-cell.column {
        font-size: 14px;
        color: #424242;
      }
      .ant-table-pagination.ant-pagination {
        justify-content: center;
      }
      .ant-table-thead .ant-table-cell {
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        ::before {
          display: none;
        }
      }
      .icon {
        font-size: 20px;
        color: #c4c4c4;
        cursor: pointer;
      }
    }
    .ant-input-suffix {
      position: absolute;
      right: 0;
      top: 45px;
    }
    .wrap-create {
      height: 181px;
      padding: 23px 31px;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .title {
        font-size: 16px;
        color: #2a2a2a;
      }
      .form {
        display: flex;
        align-items: center;
        .text-label {
          font-size: 13px;
          display: block;
          text-align: right;
          color: #424242;
          .require {
            color: #f0330a;
          }
        }
        .form-input {
          width: 70%;
          margin-right: 20px;
        }
        .btn_submit,
        .disabled {
          border-radius: 5px;
          border: none;
          width: 13.5%;
          height: 40px;
          cursor: pointer;
          color: #ffffff;
          background-color: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        }
        .disabled {
          background: #cccccc !important;
          color: #777777 !important;
        }
      }
    }
  }
`;
