import React, { useState } from 'react';
import { Radio, RadioChangeEvent } from 'antd';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  title: string;
  visible: boolean;
  subTitle: string;
  onSubmit: (value: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalExportFileStorage: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  onSubmit,
  setVisible,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [value, setValue] = useState<'csv' | 'pdf'>('csv');

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  const onChange = (e: RadioChangeEvent): void => {
    setValue(e.target.value);
  };

  return (
    <Modal
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#424242',
            margin: 0,
          }}
        >
          {title}
        </h3>
      }
      visible={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: async () => {
          setSubmitting(true);

          await onSubmit(value);

          setSubmitting(false);
        },
        loading: submitting,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p className="text-content">{subTitle}</p>
          <Radio.Group onChange={onChange} value={value} className="radio-group">
            <Radio value="csv" className="radio-item">
              CSV出力
            </Radio>
            <Radio value="file" className="radio-item">
              ファイル出力
            </Radio>
          </Radio.Group>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ModalExportFileStorage;
