import React, { useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import { sumBy } from 'lodash';
import {
  LeftOutlined,
  PlusOutlined,
  SearchOutlined,
  DoubleLeftOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { createUserAssignSkillCheck, updateSkillCheck } from 'pages/Settings/SkillCheck/thunk';
import ConfirmCreateUserModal from 'containers/Curriculum/ModalConfirmCreateUser';
import { menuRightUserSelector } from 'containers/MenuRightUser/selectors';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import StyledSearch, { Button, StylesContent } from './styles';
import { authSelector } from 'containers/Auth/selectors';
import { Input, SelectField } from 'components';
import { routes } from 'navigations/routes';
import * as Types from 'types';
import Item from './Item';
import {
  getSelectUser,
  getPositionUser,
  getDepartmentUser,
  getDataSearchUsers,
} from 'containers/MenuRightUser/thunk';

const { Option } = Select;

interface Props {
  pageYOffset: number;
  fetchDataUserSkillCheck: () => void;
  usersSelected: Array<Types.Users.ResponseType>;
  skillCheckSelected: Types.SkillCheckTreeViewType | undefined;
  setUsersSelected: React.Dispatch<React.SetStateAction<Types.Users.ResponseType[]>>;
}

const MenuRightUser: React.FC<Props> = ({
  pageYOffset,
  usersSelected,
  setUsersSelected,
  skillCheckSelected,
  fetchDataUserSkillCheck,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { list_users, data_user, data_departments, position_code } =
    useSelector(menuRightUserSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<Types.SearchUserCurriculumFormik>({
    initialValues: {
      name: '',
      email: '',
      position_code: '',
      department_code: '',
      login_id: '',
    },
    onSubmit: async (values: Types.SearchUserCurriculumFormik) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
        ...(skillCheckSelected?.user_type === 'member'
          ? [
              {
                id: 'user_type',
                search_value: ['interview'],
                not_match: true,
              },
            ]
          : []),
      ];
      Object.keys(values).forEach((key) => {
        if (
          values[key as keyof typeof values] &&
          key !== 'position_code' &&
          key !== 'department_code'
        ) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: (key as keyof typeof values) !== 'login_id',
          });
        }
      });
      setUsersSelected([]);
      await dispatch(
        getDataSearchUsers({
          conditions,
          include_lookups: true,
          page: 1,
          per_page: 0,
          sort_fields: [{ id: 'createdat', order: 'asc' }],
          position_code: formik.values.position_code,
          affiliation: formik.values.department_code,
        })
      );
      dispatch(stopLoading());
    },
  });

  const handleAddUser = async (data: Types.Users.ResponseType[]) => {
    if (!data[0] || !skillCheckSelected) return;
    dispatch(startLoading());
    const resultActions = await Promise.all(
      data.map((user) => {
        if (
          !skillCheckSelected.user_children?.some((c) =>
            c.children?.some((userChild) => userChild.user_id === user.i_id)
          )
        ) {
          dispatch(
            createUserAssignSkillCheck({
              item: {
                company_id: userInfo?.company_id,
                skill_check_code: skillCheckSelected?.skill_check_code,
                login_id: user.login_id,
                hidden: 'off',
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          );

          return true;
        } else {
          return false;
        }
      })
    );
    if (resultActions.some((action) => action)) {
      await dispatch(
        updateSkillCheck({
          id: skillCheckSelected?.i_id!,
          data: {
            item: {
              user_count:
                (sumBy(skillCheckSelected.user_children, (department) =>
                  sumBy(department.children, (child) => (child.user_id ? 1 : 0))
                ) || 0) + resultActions.filter((r) => r).length,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );

      fetchDataUserSkillCheck();
    }
    setUsersSelected([]);
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdAt', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getPositionUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDepartmentUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSearchUsers({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            include_lookups: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (skillCheckSelected && userInfo) {
      dispatch(
        getDataSearchUsers({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(skillCheckSelected.user_type === 'member'
              ? [
                  {
                    id: 'user_type',
                    search_value: ['interview'],
                    not_match: true,
                  },
                ]
              : []),
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, skillCheckSelected, userInfo]);

  return (
    <StyledSearch pageYOffset={pageYOffset}>
      <FormikProvider value={formik}>
        <Form
          labelCol={{
            flex: '27%',
          }}
          colon={false}
        >
          <div className="card-tab-pane">
            <p className="title-bold">ユーザー検索</p>
            <p className="text-content">条件を絞ってユーザーを検索できます。</p>
            <Form.Item
              name="name"
              label={<span className="label">氏名</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="name">
                {data_user?.map((value, index) => (
                  <Option value={value.name} key={index}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="email"
              label={<span className="label label-long">メールアドレス</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="email">
                {data_user?.map((value, index) => (
                  <Option value={value.email} key={index}>
                    {value.email}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="position_code"
              label={<span className="label">役職</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="position_code">
                {position_code?.map((value, index) => (
                  <Option key={index} value={value.i_id}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="department_code"
              label={<span className="label">部署</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="department_code">
                {data_departments?.map((value, index) => (
                  <Option key={index} value={value.i_id}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="login_id"
              label={<span className="label">フリーワード</span>}
              className="form-input"
            >
              <Input className="select-input" name="login_id" />
            </Form.Item>
          </div>
          <div className="wrap-center">
            <SubmitButton className="btn-search" loading={false}>
              <SearchOutlined className="icon-search" />
              検索
            </SubmitButton>
            <ResetButton className="label-reset">リセット</ResetButton>
          </div>
        </Form>
      </FormikProvider>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={!!skillCheckSelected && !!list_users.length}
            disabled={!list_users?.length || !skillCheckSelected}
            onClick={() => handleAddUser(list_users)}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!usersSelected?.length && !!skillCheckSelected}
            disabled={!usersSelected?.length || !skillCheckSelected}
            onClick={() => handleAddUser(usersSelected)}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={
              <StylesContent>
                <p className="text-content">
                  全て追加：
                  <span className="btn">
                    <DoubleLeftOutlined className="label-icon" />
                  </span>
                  をクリック
                </p>
                <p className="text-content">
                  選択した設問のみ追加：
                  <span className="btn">
                    <LeftOutlined className="label-icon" />
                  </span>
                  をクリック
                </p>
              </StylesContent>
            }
            overlayStyle={{
              width: 293,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {list_users?.length ? (
              list_users.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  usersSelected={usersSelected}
                  setUsersSelected={setUsersSelected}
                  skillCheckSelected={skillCheckSelected}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <button
              className={permissionNumber === 1 ? 'disabled' : 'btn'}
              disabled={permissionNumber === 1}
              onClick={() => setOpen(true)}
            >
              <PlusOutlined className="icon" />
              ユーザー新規作成
            </button>
          </div>
        </div>
      </div>
      <ConfirmCreateUserModal
        isOpen={open}
        setIsOpen={setOpen}
        onSubmit={() => navigate(routes.CreateEmployee.path)}
      />
    </StyledSearch>
  );
};

export default MenuRightUser;
