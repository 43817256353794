import React, { useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { RcFile } from 'antd/lib/upload';
import { FormikProps } from 'formik';
import { Button, Upload } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ActionErrorModal from 'components/Modal/ActionError';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { createFileAttach } from 'pages/Manual/thunk';
import { FileType } from 'constant/enum.constant';
import { CreateEditKnowledgeQA } from 'types';
import VideoPopupStyled from './styles';
import { useAppDispatch } from 'hooks';

interface Props {
  formik: FormikProps<CreateEditKnowledgeQA>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const VideoPopup: React.FC<Props> = ({ formik, setOpen }) => {
  const { userInfo } = useSelector(authSelector);

  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [valueInputVideo, setValueInputVideo] = useState<string>('');

  const [tabButton, setTabButton] = useState<boolean>(false);
  const [fileVideo, setFileVideo] = useState<File>();

  const dispatch = useAppDispatch();

  const concatenateValueText = () => {
    const valueText = formik.values.knowledge_text.concat(
      `<video controls width="400" height="345"><source src=${valueInputVideo}></video>`
    );
    formik.setFieldValue('knowledge_text', valueText);
    setOpen(false);
  };

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPngOrMp4 = file.type === FileType.MP4;

    const isAccept = await new Promise<boolean>((resolve) => {
      const src = URL.createObjectURL(new Blob([file]));
      if (file.type === FileType.MP4) {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          URL.revokeObjectURL(src);
          resolve(file.size < 104857600);
        };
        video.src = src;
        video.load();
      }
    });

    if (!isJpgOrPngOrMp4 || !isAccept) {
      setShowActionErrorModal(true);
    }

    return isJpgOrPngOrMp4 && isAccept ? false : Upload.LIST_IGNORE;
  };

  const handleUploadVideo = async () => {
    dispatch(startLoading());
    setOpen(false);
    if (fileVideo && userInfo) {
      const uploadFileToMinio = await uploadFileToMinIO(fileVideo);
      if (uploadFileToMinio) {
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadFileToMinio,
              filename: fileVideo.name,
              file_location: '5',
              file_extension: fileVideo.type,
              file_size: `${fileVideo.size}`,
              company_id: userInfo.company_id,
              createdat: new Date(),
              createdby: userInfo.login_id,
            },
          })
        );

        const valueText = formik.values.knowledge_text.concat(
          `<video controls width="400" height="345"><source src=${`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`}></video>`
        );
        dispatch(stopLoading());
        formik.setFieldValue('knowledge_text', valueText);
      }
    }
    dispatch(stopLoading());
  };

  return (
    <VideoPopupStyled tabButton={tabButton}>
      <div className="container">
        <div className="group-btn-tab">
          <button
            className={`btn ${!tabButton ? 'btn-active' : ''}`}
            onClick={() => setTabButton((prevState) => !prevState)}
          >
            Upload
          </button>
          <button
            className={`btn ${tabButton ? 'btn-active' : ''}`}
            onClick={() => setTabButton((prevState) => !prevState)}
          >
            URL
          </button>
        </div>
        {!tabButton ? (
          <>
            <Dragger
              accept=".mp4"
              multiple
              beforeUpload={beforeUpload}
              onChange={(info: UploadChangeParam<UploadFile<File>>) => {
                setFileVideo(info.file as unknown as File);
              }}
              style={{
                marginTop: 5,
                marginBottom: 15,
                padding: 20,
              }}
            >
              {fileVideo ? (
                <p className="ant-uploaded-text">{fileVideo.name}</p>
              ) : (
                <>
                  <span>Drop Video</span>
                  <br />
                  <span>or click</span>
                </>
              )}
            </Dragger>
            <Button onClick={handleUploadVideo} type="primary">
              Insert
            </Button>
          </>
        ) : (
          <div className="body-item">
            <span className="title">URL</span>
            <input
              className="input"
              placeholder="https://"
              value={valueInputVideo}
              onChange={(e) => setValueInputVideo(e.target.value)}
            />
            <div>
              <Button onClick={concatenateValueText} type="primary">
                Insert
              </Button>
            </div>
          </div>
        )}
      </div>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="カード情報の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </VideoPopupStyled>
  );
};

export default VideoPopup;
