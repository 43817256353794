import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row } from 'antd';
import { useIntl } from 'react-intl';

import { settingSelector } from 'containers/AppSettings/selectors';
import { informationRegisterSelector } from '../selectors';
import { setPlansSelection } from '../slice';
import { SpinLoading } from 'components';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import { Plans } from 'types';

interface Props {
  nextStep: () => void;
  planSelectedIndex: number | undefined;
  setPlanSelectedIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const FirstStep: React.FC<Props> = ({ nextStep, setPlanSelectedIndex, planSelectedIndex }) => {
  const [hightlightIndex, setHightlightIndex] = useState<number | undefined>(undefined);
  const dispatch = useAppDispatch();
  const { messages } = useIntl();

  const { plans } = useSelector(informationRegisterSelector);
  const { loading } = useSelector(settingSelector);

  const mouseEnter = (index: number) => {
    if (planSelectedIndex === undefined) {
      setHightlightIndex(index);
    }
  };

  const mouseLeave = () => {
    if (hightlightIndex !== undefined) {
      setHightlightIndex(undefined);
    }
  };

  const handleSelect = (index: number, item: Plans.ResponseType) => {
    setHightlightIndex(undefined);
    setPlanSelectedIndex(index);
    dispatch(setPlansSelection(item));
  };

  const resetSelected = () => {
    setPlanSelectedIndex(undefined);
  };

  return (
    <Wrapper>
      <SpinLoading size="large" loading={loading}>
        <div className="remind">
          <h3>{planSelectedIndex !== undefined ? messages['AJ-01-1.2'] : messages['AJ-01-1.1']}</h3>
        </div>
        <Row className={`card-plan ${planSelectedIndex !== undefined ? 'selected' : ''}`}>
          {plans.map(
            (item, index) =>
              planSelectedIndex === undefined && (
                <Card
                  key={index}
                  className={`card ${
                    hightlightIndex === index
                      ? 'active'
                      : Number(hightlightIndex) > -9999 && hightlightIndex !== index
                      ? 'inactive'
                      : ''
                  }`}
                  bordered={false}
                  onMouseEnter={() => mouseEnter(index)}
                  onMouseLeave={mouseLeave}
                >
                  <p className="category-plan">{item.plan_sub_name}</p>
                  <p className="duration">{item.title}</p>
                  <div className="wrap-container">
                    <div>
                      <p className="title">基本利用料</p>
                      <div className="name">ユーザー利用料</div>
                      <p className="title-name-border">
                        {index === 0 ? '※作成ユーザーに上限あり' : '1ユーザー'}
                      </p>
                      <div className="title-border">
                        {item.user_monthly_fee}
                        <span className="month">円</span>
                      </div>
                    </div>
                    <div className="total">+</div>
                    <div>
                      <div className="name">ストレージ利用料</div>
                      <p className="title-name-border">
                        {index === 0 ? '1企業3GB上限' : '1企業50GBにつき'}
                      </p>
                      <div className="title-border">
                        {item.usage_storage_unit_price}
                        <span className="month">円</span>
                      </div>
                    </div>
                  </div>
                  <div className="total">+</div>
                  <div className="wrap-container footer">
                    <p className="title">オプション</p>
                    <div className="name">面接スキルチェック利用料</div>
                    <p className="title-name-border">
                      {index === 0 ? '実施制限なしで' : '1回実施につき'}
                    </p>
                    <div className="title-border">
                      {item.skill_check_unit_price}
                      <span className="month">円</span>
                    </div>
                  </div>
                  {planSelectedIndex === undefined ? (
                    <button className="btn-submit" onClick={() => handleSelect(index, item)}>
                      {messages['AJ-01-5']}
                    </button>
                  ) : null}
                </Card>
              )
          )}
        </Row>
        {planSelectedIndex !== undefined && (
          <div className="step-top">
            <div className="step-title">
              <div className="gourd-left">
                <div className="form-step">
                  <p className="title-gourd">基本利用料</p>
                  <div className="title">
                    <div className="title-left">
                      <p className="name">ユーザー利用料</p>
                      {planSelectedIndex ? <p className="title-name-border">1ユーザー</p> : null}
                      <p className="title-border">
                        {plans[planSelectedIndex].user_monthly_fee.toLocaleString()}
                        <span className="month">円</span>
                      </p>
                      {planSelectedIndex ? (
                        <div>
                          <p>
                            ・SKILL FAMILIARのすべての
                            <br /> 機能が利用できます。
                          </p>
                          <p>
                            ・社内ユーザーの1か月の最大 <br /> 登録人数が対象となります。
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>ご利用可能ユーザー数</p>
                          <p>
                            ・管理者ユーザー ×1 <br /> ・社内ユーザー ×5 <br /> ・面接ユーザー ×3
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="total">+</div>
                    <div className="title-left title-left-center">
                      <p className="name">ストレージ利用料</p>
                      {planSelectedIndex ? (
                        <p className="title-name-border">1企業50GBにつき</p>
                      ) : null}
                      <p className="title-border">
                        {plans[planSelectedIndex].usage_storage_unit_price.toLocaleString()}
                        <span className="month">円</span>
                      </p>
                      {planSelectedIndex ? (
                        <div>
                          <p>
                            ・ストレージ利用量50GBごとに
                            <br /> 自動課金されます。
                          </p>
                          <p>
                            ・ストレージ利用量はいつでも <br /> 確認できます。
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>1企業3GB上限</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="total total-right">+</div>
              <div className="gourd-left">
                <div className="form-step">
                  <p className="title-gourd">オプション</p>
                  <div className="title">
                    <div className="title-left">
                      <p className="name">面接スキルチェック利用料</p>
                      {planSelectedIndex ? (
                        <p className="title-name-border">1回実施につき</p>
                      ) : null}
                      <p className="title-border">
                        {plans[planSelectedIndex].skill_check_unit_price.toLocaleString()}
                        <span className="month">円</span>
                      </p>
                      {planSelectedIndex ? (
                        <div>
                          <p>
                            ・面接スキルチェック1回実施 <br /> に対してカウントされます。
                          </p>
                          <p>
                            ・面接ユーザーは月額利用料に <br /> カウントされません。
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>実施制限なし</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {planSelectedIndex === 0 && (
              <div className="bottom">
                <p className="title-bottom">● ご利用の留意点</p>
                <div className="bottom-text">
                  <div className="name">ご利用継続の場合</div>
                  <div className="text">
                    無料トライアル期間終了後10日以内にサービス利用を開始していただけると、
                    <br /> 無料トライアル期間に作成したデータをそのままご利用いただけます。
                    <br /> 期間を過ぎるとデータは削除されますのでご留意ください。
                  </div>
                </div>
              </div>
            )}

            <div className="wrap-button">
              <button className="btn-submit" onClick={nextStep}>
                {messages['AJ-apply']}
              </button>
              <button type="button" className="reset" onClick={resetSelected}>
                {`<  ${messages['back']}`}
              </button>
            </div>
          </div>
        )}
      </SpinLoading>
    </Wrapper>
  );
};

export default FirstStep;
