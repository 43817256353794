import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupBy, unionBy, sortBy } from 'lodash';

import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { sharedFileInMinIO } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import { services } from 'services';
import * as Types from 'types';
import {
  KNOWLEDGE_RE_QUESTION_ANSWER,
  KNOWLEDGE_LIKES_HISTORY,
  KNOWLEDGE_LIKE_HISTORY,
  SELECT_DEPARTMENT_USER,
  KNOWLEDGE_RE_QUESTION,
  KNOWLEDGE_QA_ANSWERS,
  FAVORITE_KNOWLEDGE,
  SELECT_DEPARTMENT,
  KNOWLEDGE_TO,
  KNOWLEDGE,
  ATTACH,
  SELECT_USERS,
  KNOWLEDGE_QA_ADDITIONAL_QUESTION,
  KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER,
  KNOWLEDGE_FAVORITE_HISTORY,
  KNOWLEDGE_CURRICULUM_DETAIL,
  DEPARTMENTS,
  KNOWLEDGE_RECOMMEND_DETAIL_ORIGINAL,
  KNOWLEDGE_RECOMMEND_DETAIL_ADDITIONAL,
  KNOWLEDGE_QA_DETAIL_ORIGINAL,
  KNOWLEDGE_QA_DETAIL_ADDITIONAL,
  USERS,
  AFFILIATION_ASSIGN_ROLE,
} from 'configs';

export const getKnowledgeTo = createAsyncThunk<
  Types.GetItemResponseType<Types.KnowledgeTo.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getKnowledgeTo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.KnowledgeTo.ResponseType>(KNOWLEDGE_TO.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getDataAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataListKnowledge = createAsyncThunk<
  Types.GetItemResponseType<Types.KnowledgeListType>,
  Types.GetItemRequestType & { to_login_id?: boolean; user?: string; department?: string },
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getDataKnowledge', async (req, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await services.search<Types.Knowledge.ResponseType>(KNOWLEDGE.id, req);

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getKnowledgeTo({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            ...(req.to_login_id
              ? [
                  {
                    id: 'to_user_id',
                    search_value: [authContainer?.userInfo?.login_id],
                    exact_match: true,
                  },
                ]
              : []),
            ...(req.department
              ? [
                  {
                    id: 'to_department_id',
                    search_value: [req.department],
                    exact_match: true,
                  },
                ]
              : []),
            ...(req.user
              ? [
                  {
                    id: 'to_user_id',
                    search_value: [req.user],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataAffiliationAssignRole({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<Types.KnowledgeListType> = [];
    if (
      getKnowledgeTo.fulfilled.match(responseStatus[0]) &&
      getDataUser.fulfilled.match(responseStatus[1]) &&
      getDataAffiliationAssignRole.fulfilled.match(responseStatus[2])
    ) {
      const dataKnowledgeTo = responseStatus[0].payload.items;
      const dataUser = responseStatus[1].payload.items;
      const dataAffiliationAssignRole = responseStatus[2].payload.items;

      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const matchKnowledge = dataKnowledgeTo.filter(
            (c) => c.knowledge_id === item.knowledge_id
          );

          const mergedObject = {
            i_id: item.i_id,
            company_id: item.company_id,
            knowledge_id: item.knowledge_id,
            knowledge_title: item.knowledge_title,
            knowledge_text: item.knowledge_text,
            knowledge_type: Number(item.knowledge_type),
            qa_status: Number(item.qa_status),
            recommendation_status: Number(item.recommendation_status),
            number_of_likes: Number(item.likes),
            updatedat: item.updatedat,
            createdat: item.createdat,
            createdby: item.createdby,
          };
          return matchKnowledge.flatMap((knowledge) => {
            const mergedObject1 = {
              ...mergedObject,
              to_user_id: knowledge.to_user_id || '',
              to_department_id: knowledge.to_department_id || '',
            };

            const matchUser = dataUser.filter((u) => u.login_id === item.createdby);
            return matchUser.flatMap((u) => {
              const mergedObject2 = {
                ...mergedObject1,
                author_name: u.name,
                author_file_icon: u.icon_fileID,
              };
              const matchedAffiliationAssignRole = dataAffiliationAssignRole.filter(
                (affiliationRole) =>
                  affiliationRole.main_role === 'main' &&
                  affiliationRole.login_id === item.createdby
              );
              return matchedAffiliationAssignRole.flatMap((matchedAffiliation) => {
                const mergedObject3 = {
                  ...mergedObject2,
                  author_department_name: matchedAffiliation.affiliation_id,
                };
                const matchedAffiliationAssignRoleToUser = unionBy(
                  dataAffiliationAssignRole,
                  'affiliation_id'
                ).filter(
                  (affiliationRole) =>
                    affiliationRole.main_role === 'main' &&
                    affiliationRole.affiliation_id.split(' ')[0] === knowledge.to_department_id
                );
                if (!matchedAffiliationAssignRoleToUser.length) {
                  return mergedObject3;
                }
                return matchedAffiliationAssignRoleToUser.flatMap((matchAffiliationToUser) => {
                  const mergedObject4 = {
                    ...mergedObject3,
                    to_department_name: matchAffiliationToUser.affiliation_id,
                  };
                  const matchToUser = dataUser.filter((i) => i.login_id === knowledge.to_user_id);
                  if (!matchToUser.length) {
                    return mergedObject4;
                  }
                  return matchToUser.flatMap((toUser) => ({
                    ...mergedObject4,
                    to_user_name: toUser.name,
                  }));
                });
              });
            });
          });
        })
      );
    }

    const dataConvert = await Promise.all(
      resultArray.map(async (val) => {
        const obj = val;
        if (val.author_file_icon) {
          const nodeFileUrl = await sharedFileInMinIO(val.author_file_icon);
          Object.assign(obj, {
            author_file_icon_url: nodeFileUrl,
          });
        }
        return obj;
      })
    );

    const dataSort = sortBy(dataConvert, 'createdat');

    return {
      ...data,
      items: [...dataSort],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeReQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createAdditionalQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_RE_QUESTION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createReAnswer = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeReQuestionAnswer.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createAdditionalAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_RE_QUESTION_ANSWER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachKnowledgeFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getAttachKnowledgeFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledge = createAsyncThunk<
  Types.GetItemResponseType<Types.Knowledge.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Knowledge.ResponseType>(KNOWLEDGE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getDataUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRankingLikeKnowledgeAll = createAsyncThunk<
  Types.GetItemResponseType<Types.RankingLikesKnowledgeAllType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'knowledgeTop/thunk/getDataRankingLikeKnowledgeAll',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await services.search<Types.KnowledgeLikeHistory.ResponseType>(
        KNOWLEDGE_LIKE_HISTORY.id,
        req
      );

      const { authContainer } = getState() as Types.RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getKnowledge({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      let resultArray: Array<Types.RankingLikesKnowledgeAllType> = [];
      if (
        getKnowledge.fulfilled.match(responseStatus[0]) &&
        getDataUser.fulfilled.match(responseStatus[1])
      ) {
        const dataAssignKnowledge = responseStatus[0].payload.items;
        const dataUser = responseStatus[1].payload.items;

        resultArray = await Promise.all(
          unionBy(data.items, 'knowledge_id').flatMap((item) => {
            const matchKnowledge = dataAssignKnowledge.filter(
              (c) => c.knowledge_id === item.knowledge_id
            );
            return matchKnowledge.flatMap((knowledge) => {
              const mergedObject1 = {
                i_id: item.i_id,
                knowledge_id: item.knowledge_id,
                company_id: knowledge.company_id,
                knowledge_title: knowledge.knowledge_title,
                knowledge_text: knowledge.knowledge_text,
                knowledge_type: Number(knowledge.knowledge_type),
                number_of_likes: String(knowledge.likes),
                created_month: item.created_month,
                createdby: knowledge.createdby,
              };
              const matchUser = dataUser.filter((u) => u.login_id === knowledge.createdby);
              return matchUser.map((u) => ({
                ...mergedObject1,
                user_name: u.name,
                icon_fileID: u.icon_fileID,
              }));
            });
          })
        );
        resultArray.sort((a, b) => Number(b.number_of_likes) - Number(a.number_of_likes));
      }
      if (resultArray.length) {
        if (resultArray[0].icon_fileID) {
          const fileName = extractFileName(resultArray[0].icon_fileID);
          const nodeFileUrl = await sharedFileInMinIO(resultArray[0].icon_fileID);
          const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
          Object.assign(resultArray[0], {
            ...resultArray[0],
            fileAvatar: convertFileResponse({
              file: fileFromUrl,
              fileID: resultArray[0].icon_fileID,
              fileName: fileName,
            }),
          });
        }
      }
      return {
        ...data,
        items: [...resultArray],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/uploadFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReQuestionAnswer = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeReQuestionAnswer.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateAdditionQuestionAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_RE_QUESTION_ANSWER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateReQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeReQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateAdditionQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_RE_QUESTION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReQuestionAnswer = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteAdditionalQuestionAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_RE_QUESTION_ANSWER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteReQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteAdditionalQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_RE_QUESTION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeLikeHistory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeLikesHistory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getKnowledgeLikeHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeLikesHistory.ResponseType>(
      KNOWLEDGE_LIKES_HISTORY.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRankingLikeUserAll = createAsyncThunk<
  Types.GetItemResponseType<Types.RankingLikeUserAll.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'knowledgeTop/thunk/getDataRankingLikeUserAll',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await services.search<Types.KnowledgeLikeHistory.ResponseType>(
        KNOWLEDGE_LIKE_HISTORY.id,
        req
      );

      const { authContainer } = getState() as Types.RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getKnowledge({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataAffiliationAssignRole({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      let resultArray: Array<Types.RankingLikeUserAll.ResponseType> = [];
      if (
        getKnowledge.fulfilled.match(responseStatus[0]) &&
        getDataUser.fulfilled.match(responseStatus[1]) &&
        getDataAffiliationAssignRole.fulfilled.match(responseStatus[2])
      ) {
        const dataAssignKnowledge = responseStatus[0].payload.items;
        const dataUser = responseStatus[1].payload.items;
        const dataAffiliationAssignRole = responseStatus[2].payload.items;

        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const matchKnowledge = dataAssignKnowledge.filter(
              (c) => c.knowledge_id === item.knowledge_id
            );
            return matchKnowledge.flatMap((knowledge) => {
              const mergedObject1 = {
                i_id: item.i_id,
                author_user_id: knowledge.createdby,
                number_of_likes: item.knowledge_id,
              };
              const matchedAffiliationAssignRole = dataAffiliationAssignRole.filter(
                (affiliationRole) =>
                  affiliationRole.main_role === 'main' &&
                  affiliationRole.login_id === knowledge.createdby
              );
              return matchedAffiliationAssignRole.flatMap((matchedAffiliation) => {
                const mergedObject2 = {
                  ...mergedObject1,
                  author_department_name: matchedAffiliation.affiliation_id,
                };
                const matchUser = dataUser.filter((u) => u.login_id === knowledge.createdby);
                return matchUser.map(async (u) => {
                  const nodeFileUrl = await sharedFileInMinIO(u.icon_fileID);
                  return {
                    ...mergedObject2,
                    company_id: u.company_id,
                    author_name: u.name,
                    icon_fileID: u.icon_fileID,
                    fileAvatar: nodeFileUrl,
                  };
                });
              });
            });
          })
        );
      }
      const dataResult: Types.RankingLikeUserAll.ResponseType[] = Object.values(
        groupBy(resultArray, 'author_user_id')
      )
        .map((group) => ({
          ...group[0],
          number_of_likes: group.length.toString(),
        }))
        .sort((a, b) => Number(b.number_of_likes) - Number(a.number_of_likes));
      return {
        ...data,
        items: [...dataResult],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFavoriteKnowledge = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.FavoriteKnowledge.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createFavoriteKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(FAVORITE_KNOWLEDGE.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteFavoriteKnowledge = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteFavoriteKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(FAVORITE_KNOWLEDGE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowLedgeLikeHistory = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeLikeHistory.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowLedgeLikeHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_LIKE_HISTORY.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteKnowLedgeLikeHistory = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteKnowLedgeLikeHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_LIKE_HISTORY.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowLedge = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Knowledge.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowLedge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createKnowLedgeTo = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeTo.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowLedgeTo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_TO.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartmentUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartmentUser.ResponseType>(
      SELECT_DEPARTMENT_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListDepartment = createAsyncThunk<
  Types.GetItemResponseType<Types.Departments.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getListDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Departments.ResponseType>(DEPARTMENTS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowledgeQAAnswers = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeQaAnswers.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowledgeQAAnswers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_QA_ANSWERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateKnowledgeQAAnswers = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeQaAnswers.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateKnowledgeQAAnswers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_QA_ANSWERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getSelectDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDepartmentCreate = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getDepartmentCreate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectUsers = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectUsers.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getSelectUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectUsers.ResponseType>(SELECT_USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowledgeRecommend = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Knowledge.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowledgeRecommend', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateKnowledge = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Knowledge.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateKnowledgeTo = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeTo.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateKnowledgeTo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_TO.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteKnowledgeQAAnswers = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteKnowledgeQAAnswers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_QA_ANSWERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowledgeQAAddQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeReAdditionalQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowledgeQAAddQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_QA_ADDITIONAL_QUESTION.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateKnowledgeQAAddQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeReAdditionalQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateKnowledgeQAAddQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_QA_ADDITIONAL_QUESTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteKnowledgeQAAddQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteKnowledgeQAAddQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_QA_ADDITIONAL_QUESTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createKnowledgeQAAddQuestionAnswer = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.KnowledgeReAdditionalQuestionAnswer.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/createKnowledgeQAAddQuestionAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateKnowledgeQAAddQuestionAnswer = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.KnowledgeReAdditionalQuestionAnswer.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/updateKnowledgeQAAddQuestionAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteKnowledgeQAAddQuestionAnswer = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteKnowledgeQAAddQuestionAnswer', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteKnowledge = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(KNOWLEDGE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeFavoriteHistory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeFavoriteHistory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getKnowledgeFavoriteHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeFavoriteHistory.ResponseType>(
      KNOWLEDGE_FAVORITE_HISTORY.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAttachKnowledge = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteAttachKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataKnowledgeCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeCurriculumDetail.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getDataKnowledgeCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeCurriculumDetail.ResponseType>(
      KNOWLEDGE_CURRICULUM_DETAIL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectDepartmentUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getSelectDepartmentUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeDepartment.ResponseType>(
      SELECT_DEPARTMENT_USER.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeRecommendDetailOriginal = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeRecommendDetailOriginal.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'knowledgeTop/thunk/getKnowledgeRecommendDetailOriginal',
  async (req, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await services.filter<Types.KnowledgeRecommendDetailOriginal.ResponseType>(
        KNOWLEDGE_RECOMMEND_DETAIL_ORIGINAL.id,
        req
      );

      const dataConvert = await Promise.all(
        data.report_results.map(async (val) => {
          const obj = val;

          if (val.fileID1 || val.fileID2 || val.fileID3 || val.fileID4 || val.fileID5) {
            const files = await Promise.all(
              [val.fileID1, val.fileID2, val.fileID3, val.fileID4, val.fileID5].map(async (e) => {
                if (!e) return;
                const fileName = extractFileName(e);
                const nodeFileUrl = await sharedFileInMinIO(e);
                const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

                return convertFileResponse({
                  file: fileFromUrl,
                  fileID: e,
                  fileName: fileName,
                });
              })
            );

            Object.assign(obj, { ...val, files: files.filter(Boolean) });
          }

          if (val.to_user_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.to_user_icon_fileID);
            Object.assign(obj, {
              ...val,
              to_user_icon_file: nodeFileUrl,
            });
          }

          if (val.author_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.author_icon_fileID);
            Object.assign(obj, {
              ...val,
              author_icon_file: nodeFileUrl,
            });
          }

          return obj;
        })
      );
      return { ...data, report_results: dataConvert };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getKnowledgeRecommendDetailAdditional = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeRecommendDetailAdditional.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'knowledgeTop/thunk/getKnowledgeRecommendDetailAdditional',
  async (req, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await services.filter<Types.KnowledgeRecommendDetailAdditional.ResponseType>(
        KNOWLEDGE_RECOMMEND_DETAIL_ADDITIONAL.id,
        req
      );

      const dataConvert = await Promise.all(
        data.report_results.map(async (val) => {
          const obj = val;

          if (val.questioner_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.questioner_icon_fileID);
            Object.assign(obj, {
              ...val,
              questioner_icon_file: nodeFileUrl,
            });
          }

          if (val.question_fileID) {
            const fileName = extractFileName(val.question_fileID);
            const nodeFileUrl = await sharedFileInMinIO(val.question_fileID);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              question_file: convertFileResponse({
                file: fileFromUrl,
                fileID: val.question_fileID,
                fileName: fileName,
              }),
            });
          }

          if (val.question_answer_fileID) {
            const fileName = extractFileName(val.question_answer_fileID);
            const nodeFileUrl = await sharedFileInMinIO(val.question_answer_fileID);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              question_answer_file: convertFileResponse({
                file: fileFromUrl,
                fileID: val.question_answer_fileID,
                fileName: fileName,
              }),
            });
          }

          if (val.question_respondent_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.question_respondent_icon_fileID);
            Object.assign(obj, {
              ...val,
              question_respondent_icon_file: nodeFileUrl,
            });
          }

          return obj;
        })
      );

      return { ...data, report_results: dataConvert };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getKnowledgeQADetailOriginal = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeQADetailOriginal.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/getKnowledgeQADetailOriginal', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.KnowledgeQADetailOriginal.ResponseType>(
      KNOWLEDGE_QA_DETAIL_ORIGINAL.id,
      req
    );

    const dataConvert = await Promise.all(
      data.report_results.map(async (val) => {
        const obj = val;

        if (val.fileID1 || val.fileID2 || val.fileID3 || val.fileID4 || val.fileID5) {
          const files = await Promise.all(
            [val.fileID1, val.fileID2, val.fileID3, val.fileID4, val.fileID5].map(async (e) => {
              if (!e) return;
              const fileName = extractFileName(e);
              const nodeFileUrl = await sharedFileInMinIO(e);
              const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

              return convertFileResponse({
                file: fileFromUrl,
                fileID: e,
                fileName: fileName,
              });
            })
          );

          Object.assign(obj, { ...val, files: files.filter(Boolean) });
        }

        if (val.to_user_icon_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.to_user_icon_fileID);
          Object.assign(obj, {
            ...val,
            to_user_icon_file: nodeFileUrl,
          });
        }

        if (val.author_icon_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.author_icon_fileID);
          Object.assign(obj, {
            ...val,
            author_icon_file: nodeFileUrl,
          });
        }

        if (val.answer_fileID) {
          const fileName = extractFileName(val.answer_fileID);
          const nodeFileUrl = await sharedFileInMinIO(val.answer_fileID);
          const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
          Object.assign(obj, {
            ...val,
            answer_file: convertFileResponse({
              file: fileFromUrl,
              fileID: val.answer_fileID,
              fileName: fileName,
            }),
          });
        }

        if (val.respondent_icon_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.respondent_icon_fileID);
          Object.assign(obj, {
            ...val,
            respondent_icon_file: nodeFileUrl,
          });
        }

        return obj;
      })
    );
    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getKnowledgeQADetailAdditional = createAsyncThunk<
  Types.ReportsItemResponseType<Types.KnowledgeQADetailAdditional.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'knowledgeTop/thunk/getKnowledgeQADetailAdditional',
  async (req, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await services.filter<Types.KnowledgeQADetailAdditional.ResponseType>(
        KNOWLEDGE_QA_DETAIL_ADDITIONAL.id,
        req
      );

      const dataConvert = await Promise.all(
        data.report_results.map(async (val) => {
          const obj = val;

          if (val.questioner_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.questioner_icon_fileID);
            Object.assign(obj, {
              additional_questioner_icon_file: nodeFileUrl,
            });
          }

          if (val.question_fileID) {
            const fileName = extractFileName(val.question_fileID);
            const nodeFileUrl = await sharedFileInMinIO(val.question_fileID);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              additional_question_file: convertFileResponse({
                file: fileFromUrl,
                fileID: val.question_fileID,
                fileName: fileName,
              }),
            });
          }

          if (val.question_answer_fileID) {
            const fileName = extractFileName(val.question_answer_fileID);
            const nodeFileUrl = await sharedFileInMinIO(val.question_answer_fileID);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              additional_question_answer_file: convertFileResponse({
                file: fileFromUrl,
                fileID: val.question_answer_fileID,
                fileName: fileName,
              }),
            });
          }

          if (val.question_respondent_icon_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.question_respondent_icon_fileID);
            Object.assign(obj, {
              additional_question_respondent_icon_file: nodeFileUrl,
            });
          }

          return obj;
        })
      );
      return { ...data, report_results: dataConvert };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFileAttachByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('knowledgeTop/thunk/deleteFileAttachByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
