import yup from '../yup';
import dayjs from 'dayjs';
import { REGEX_HALF_WIDTH } from 'constant';

export const createSkillcheckSchema = yup.object().shape({
  name: yup.string().required(),
  implementation_settings: yup.number().required(),
  start_period: yup.string().when('implementation_settings', {
    is: 1,
    then: yup.string().required(),
  }),
  end_period: yup.string().when('implementation_settings', {
    is: 1,
    then: yup.string().required(),
  }),
  grouping_code: yup.string().required(),
  user_type: yup.string().required().oneOf(['admin', 'member', 'interview']),
  description: yup.string(),
  time_limit: yup.string().required(),
  problems4: yup.number(),
  base_date_for_report: yup.string().required(),
});

export const uploadSkillCheckCSV = yup.array().of(
  yup.object().shape({
    same_skill_check_flag: yup.number().required('同一スキルチェックフラグは必須項目です。'),
    name: yup
      .string()
      .required('スキルチェック名称は必須項目です。')
      .max(30, 'スキルチェック名称は30以内の値を入力してください。'),
    implementation_settings: yup
      .number()
      .required('実施可否設定は必須項目です。')
      .oneOf([0, 1, 2], '実施可否設定が不正です。'),
    start_period: yup
      .string()
      .when('implementation_settings', {
        is: 1,
        then: yup.string().required('実施期間設定（開始）は必須項目です。'),
      })
      .test(
        '実施期間設定（開始）',
        '実施期間設定（開始）が不正です。',
        (value) => !value || dayjs(value, 'YYYY/MM/DD HH:mm', true).isValid()
      ),
    end_period: yup
      .string()
      .when('implementation_settings', {
        is: 1,
        then: yup.string().required('実施期間設定（終了）は必須項目です。'),
      })
      .test(
        '実施期間設定（終了）',
        '実施期間設定（終了）が不正です。',
        (value) => !value || dayjs(value, 'YYYY/MM/DD HH:mm', true).isValid()
      ),
    grouping_code: yup
      .string()
      .required('分析グループコードは必須項目です。')
      .matches(REGEX_HALF_WIDTH, {
        message: '分析グループコードは半角英数字、記号で入力してください。',
      }),
    user_type: yup
      .string()
      .required('ユーザー種別は必須項目です。')
      .oneOf(['admin', 'member', 'interview'], 'ユーザー種別が不正です。'),
    description: yup.string().max(1050, '説明は1050以内の値を入力してください。'),
    time_limit: yup
      .string()
      .required('制限時間は必須項目です。')
      .test('制限時間', '制限時間が不正です。', (value) => {
        if (!value) {
          return true;
        }
        const number = Number(value.replace('分', ''));
        return !isNaN(number) && number >= 1 && number <= 180;
      }),
    problems4: yup
      .number()
      .required('選択肢Dは必須項目です。')
      .oneOf([0, 1], '選択肢Dが不正です。'),
    question_code: yup.string().required('設問コードは必須項目です。').matches(REGEX_HALF_WIDTH, {
      message: '設問コードは半角英数字、記号で入力してください。',
    }),
    base_date_for_report: yup
      .string()
      .required('レポート用基準日付は必須項目です。')
      .test(
        'レポート用基準日付',
        'レポート用基準日付が不正です。',
        (value) => !value || dayjs(value, 'YYYY/MM/DD HH:mm', true).isValid()
      ),
  })
);
