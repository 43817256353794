import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { interviewUsersSelector } from '../../selectors';
import { sendLoginUrl } from '../../thunk';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow: Types.InterviewUser.ResponseType[];
}

const useConfirmSendURL = (props: ReceivedProps) => {
  const { setIsOpen, selectedRow } = props;
  const { companies } = useSelector(interviewUsersSelector);

  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(async () => {
    dispatch(startLoading());
    await Promise.all(
      selectedRow.map((row) =>
        dispatch(
          sendLoginUrl({
            login_id: [row.email],
            company: companies[0].name,
            url: 'https://stg-rsweb.hexabase.com/',
          })
        )
      )
    );
    dispatch(stopLoading());
    handleClose();
  }, [companies, dispatch, selectedRow, handleClose]);

  return { ...props, handleClose, handleSubmit };
};

export type Props = ReturnType<typeof useConfirmSendURL>;

export default useConfirmSendURL;
