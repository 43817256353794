import React from 'react';
import { Bar, Tooltip } from '@ant-design/plots';
import { ColumnsType } from 'antd/es/table';
import { Datum } from '@ant-design/charts';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { ITEM_COMPARE_BAR_CHART, ITEM_COMPARE_PIE_CHART } from 'constant/select.constants';
import { skillCheckReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';
import StyledTableChart from './styles';
import * as Types from 'types';

interface Props {
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
}

const TableChart: React.FC<Props> = ({ itemComparision }) => {
  const { reportSkillCheck } = useSelector(skillCheckReportSelector);

  const columns: ColumnsType<Types.ReportSkillCheck.SurfaceTableByUserType> = [
    {
      title: 'No.',
      width: '10%',
      align: 'center',
      render: (_, _record, index) => index + 1,
    },
    {
      title: 'スキルチェック名',
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      align: 'center',
      width: '65%',
      ellipsis: true,
    },
    {
      title: itemComparision === 'correct_answer_rate' ? '正解率' : '進捗率',
      align: 'center',
      dataIndex: '',
      key: '',
      width: '25%',
      render: (_, record) => {
        return (
          <span>
            {itemComparision === 'correct_answer_rate'
              ? formatNumber(record.accuracy_rate * 100)
              : formatNumber((record.responses_num / record.probs_count) * 100)}
            %
          </span>
        );
      },
    },
  ];

  return (
    <StyledTableChart>
      <div className="container">
        <Table
          rowKey="index"
          rowClassName={(_, index) => (index % 2 === 1 ? 'table-row-hightlight' : '')}
          className="table"
          columns={columns}
          dataSource={reportSkillCheck.slice(1).map((item, index) => ({
            ...item,
            index,
          }))}
          bordered
          pagination={false}
        />
        <div className="chart">
          <div className="header-column" />
          <Bar
            data={reportSkillCheck
              .slice(1)
              .map((data, index) => [
                itemComparision === 'correct_answer_rate'
                  ? {
                      index,
                      name: data.skill_check_name,
                      value: Number(formatNumber(data.accuracy_rate * 100)),
                      total: Number(formatNumber(data.accuracy_rate * 100)),
                      type: '正解率',
                    }
                  : {
                      index,
                      name: data.skill_check_name,
                      value: Number(formatNumber((data.responses_num / data.probs_count) * 100)),
                      total: Number(formatNumber((data.responses_num / data.probs_count) * 100)),
                      type: '進捗率',
                    },
              ])
              .flat()}
            isStack
            xField="value"
            yField="index"
            seriesField="type"
            autoFit
            padding={[20, 0, 0, 0]}
            xAxis={{
              position: 'left',
              min: 0,
              max: 100,
              grid: {
                line: {
                  style: {
                    stroke: '#ddd',
                    lineDash: [2, 2],
                  },
                },
              },
              label: {
                formatter: (_item, _, index) =>
                  index === 5 ? '50%' : index === 10 ? '100%            ' : '',
                style: {
                  fontSize: 11,
                },
              },
              showLast: true,
              tickCount: 10,
            }}
            tooltip={
              {
                showTitle: false,
                fields: ['type', 'total'],
                formatter: (datum: Datum) => ({
                  name: datum.type,
                  value: datum.total + '%',
                }),
              } as Tooltip
            }
            yAxis={{
              label: null,
            }}
            color={['#8FD692']}
            legend={false}
            maxBarWidth={12}
            style={{
              width: '100%',
              height: 39 * reportSkillCheck.slice(1).length + 19,
            }}
          />
        </div>
      </div>
    </StyledTableChart>
  );
};

export default TableChart;
