import { AmexCard, JcbCard, MasterCard, VisaCard, DinersClub } from 'assets';

export const LIST_TITLE_HEADER_INFORMATION_REGISTER = [
  'AJ-Step-1',
  'AJ-Step-2',
  'AJ-Step-3',
  'AJ-Step-4',
  'AJ-Step-5',
  'AJ-Step-6',
];

export const LIST_TITLE_HEADER = ['AJ-Step-1', 'AJ-Step-2', 'AJ-Step-3'];

export const LIST_TITLE_HEADER_CONDITION_REGISTER = [
  'AJ-Step-2',
  'AJ-Step-3',
  'AJ-Step-4',
  'AJ-Step-5',
  'AJ-Step-6',
];

export const LIST_TITLE_HEADER_TREE_TRIAL = ['AJ-Step-modal-1', 'AJ-Step-modal-2'];

export const IMAGE_CREDIT_CARD = [
  {
    name: 'VISA',
    image: VisaCard,
  },
  {
    name: 'マスターカード',
    image: MasterCard,
  },
  {
    name: 'AMEX',
    image: AmexCard,
  },
  {
    name: 'JCB',
    image: JcbCard,
  },
  {
    name: 'ダイナーズ',
    image: DinersClub,
  },
];

export const CHECK_BOX_MODAL_CONFIRM_WITHDRAWAL = [
  '利用料金が高いため',
  '機能が不十分なため',
  '動作が重い',
  '別のサービスを利用するため',
  'その他',
];

export const CREDIT_CARD_MONTH = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
];

export const LIST_LABEL = ['M-21-12', 'M-21-15', 'M-21-16', 'M-21-17', 'M-21-18', 'M-21-19'];

export const LIST_TAB_BAR = ['M-21-7', 'M-21-8', 'M-21-9'];

export const MENU_WIDTH = 196;

export const MENU_COLLAPSED_WIDTH = 60;

export const HEADER_HEIGHT = 56;

export const REGEX_HALF_WIDTH = /^(?=\s*$)|^[a-zA-Z0-9!"#$%&'()*+,-.\\:/;<=>?@[\]^_`{|}~]+|^\w$/;

export const MAX_REQUESTS_PER_TIMES = 10;
