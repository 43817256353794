import styled from 'styled-components';

interface Props {
  dataSkillCheck: boolean;
}

export default styled.div<Props>`
  padding: 30px 20px 0 20px;
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #424242;
    padding-bottom: 30px;
    border-bottom: 1px solid #eaeaea;
  }
  .form-search {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .item {
      width: 19.3%;
      margin-right: 8px;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .wrap-search {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
  }
  .type-icon {
    display: flex;
    align-items: center;
    .icon {
      margin: 0 8px 0 0;
      width: 24px;
      height: 24px;
    }
  }
  .text-user-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 13px;
    margin: 0;
    .text-icon-type {
      width: 18px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      padding: 3px;
      border-radius: 2px;
      margin-right: 8px;
    }
    .interview {
      background: #4e83d3;
    }
    .member {
      background: #f08b84;
    }
  }
  .text-count {
    position: absolute;
    margin-top: 22px;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
  }
  .table {
    position: relative;
    margin-bottom: 80px;
    margin-top: ${(props) => (props.dataSkillCheck ? 0 : 65)}px;
    .ant-table-tbody .ant-table-cell.date {
      text-align: start;
    }
    .ant-table-tbody .ant-table-cell.color-code {
      color: #999999;
    }
    .type-icon {
      display: flex;
      align-items: center;
      .icon {
        margin: 0 8px 0 0;
        width: 24px;
        height: 24px;
      }
    }
    .item-type {
      display: flex;
      align-items: center;
      .type {
        background: #4e83d3;
        width: 24px;
        height: 24px;
        border-radius: 2px;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        color: #ffffff;
        margin: 0 8px 0 0;
      }

      .exception {
        background: #f08b84;
      }
    }
    .flex {
      display: flex;
      align-items: center;
    }
    .ant-table-thead .ant-table-selection-column {
      padding: 5px 16px 11px 16px !important;
    }
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 15px;
      margin-top: 40px;
      font-size: 11px;
    }
    .ant-table-thead .ant-checkbox-wrapper {
      margin-top: -3px !important;
      padding-bottom: 13px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        text-align: center;
        padding: 11px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: Lato;
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
        text-align: center;
        padding: 10px 16px;
        &:nth-child(3) {
          text-align: left;
        }
        .icon {
          color: #c4c4c4;
          font-size: 16px;
          cursor: pointer;
        }
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
    .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
  }
`;
