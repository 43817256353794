import React, { useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { orderBy } from 'lodash';

import ItemDropDown from '../ItemDropDownPDF';
import PopupStyled from './styles';
import * as Types from 'types';

interface Props {
  activeCollapse?: boolean;
  data: Types.TreeItem<Types.AffiliationItemType>;
  handleShrink?: () => void;
  setOpenDetail?: React.Dispatch<React.SetStateAction<boolean>>;
}

const AffiliationDetail: React.FC<Props> = ({ data }) => {
  const userChildren = useMemo(() => {
    const userOrder = orderBy(
      data.user_children,
      ['lookup_items.user_positions.rank_order', 'user_name'],
      ['asc', 'desc']
    );
    return userOrder;
  }, [data]);

  return (
    <PopupStyled activeCollapse={true} isExpand={true}>
      <div className="header-tooltip">
        <div className="header-title">
          <p className="title">
            <small>
              <DownOutlined className="icon-outlined" />
            </small>
            {data.name}
            <small className="text-code">（{data.affiliation_id}）</small>
          </p>
        </div>
        <div className="header-information">
          <div className="wrap-amount-user">
            <span className="title">
              {userChildren?.length || 0} <small>人</small>
            </span>
            <span className="sub-title">（下位階層含む： {data.total_user || 0} 人）</span>
          </div>
        </div>
      </div>
      <div className="body">
        <div className="wrap-item">
          {userChildren &&
            userChildren.map((user, i) => (
              <div key={i} className="item">
                <ItemDropDown index={i} user={user} affiliation_id={data.i_id} />
              </div>
            ))}
        </div>
      </div>
    </PopupStyled>
  );
};

export default AffiliationDetail;
