import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Progress, Row } from 'antd';

import { dashboardSelector } from 'pages/Dashboard/selectors';
import { Wrapper } from './styles';

const WithoutSkillCheck: React.FC = () => {
  const { skillCheck } = useSelector(dashboardSelector);

  return (
    <Wrapper>
      <Row className="header pd-10">
        <ExclamationCircleOutlined className="icon" />
        <span className="title">スキルチェック実施状況</span>
        <span className="title-sub">（実施可能のみ表示）</span>
      </Row>
      <div className="wrap-items">
        {skillCheck.map((value, index) => (
          <Row key={index} className="item pd-10">
            <span className="title">{value.skill_check_name}</span>
            <div className="wrap-progress">
              <Progress
                showInfo={false}
                percent={(value.users / value.total) * 100}
                strokeColor={{ '0%': '#E5DAA4', '100%': '#E8CD6D' }}
              />
              <span className="label">
                {value.users}
                <small className="small">/{value.total}人</small>
              </span>
            </div>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default WithoutSkillCheck;
