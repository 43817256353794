import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WarningFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ModalChangePaymentMethod from './Modal/ChangePaymentMethod';
import ModalRegisterCreditCard from './Modal/RegisterCreditCard';
import { getPaymentMaster, updateCreditCardInfo } from './thunk';
import ModalConfirmWithdraw from './Modal/ConfirmWithdraw';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { paymentMasterSelector } from './selectors';
import PaymentMasterStyled from './styles';
import InvoiceInformation from './Invoice';
import CreditInformation from './Credit';
import { Header } from 'components';
import { COMPANIES } from 'configs';
import { find } from 'lodash';

const PaymentMaster: React.FC = () => {
  const [visibleRegisterCreditCard, setVisibleRegisterCreditCard] = useState<boolean>(false);
  const [visiblePaymentComplete, setVisiblePaymentComplete] = useState<boolean>(false);
  const [visibleWithdrawal, setVisibleWithdrawal] = useState<boolean>(false);
  const [visiblePayment, setVisiblePayment] = useState<boolean>(false);
  const [isEditing, setStatusEditing] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const { PaymentMaster: payment } = useSelector(paymentMasterSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  const paymentMethodCd: 1 | 2 = useMemo(
    () => (payment?.payment_method_cd === 2 ? 2 : 1),
    [payment]
  );

  const updatePaymentMethodCd = async (payment_method_cd: 1 | 2) => {
    if (!userInfo) return;

    const id = find(userInfo.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
      ?.i_id;

    dispatch(startLoading());
    const resultAction = await dispatch(
      updateCreditCardInfo({
        id: id || '',
        data: {
          item: {
            payment_method_cd,
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );

    if (updateCreditCardInfo.fulfilled.match(resultAction)) {
      setVisiblePaymentComplete(true);
      await fetchDataPaymentMaster();
    }

    dispatch(stopLoading());
  };

  const fetchDataPaymentMaster = useCallback(() => {
    if (!userInfo) return;
    dispatch(
      getPaymentMaster({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await fetchDataPaymentMaster();
      dispatch(stopLoading());
    })();
  }, [dispatch, fetchDataPaymentMaster, userInfo, isUserInfoChanged]);

  return (
    <>
      <Header title={headerTitle} />
      <PaymentMasterStyled>
        <div className="wrap-information">
          <div className="header">
            <p className="title">契約情報</p>
          </div>
          <div className="container">
            <div className="item">
              <div className="wrap-label">
                <span className="label">ご契約プラン</span>
              </div>
              <div className="wrap-value">
                <span className="label">{payment?.plan_id}</span>
                <span className="time">
                  {payment?.contract_date && dayjs(payment.contract_date).format('YYYY/MM/DD')}〜
                  {payment?.plan_expiration_date &&
                    dayjs(payment.plan_expiration_date).format('YYYY/MM/DD')}
                </span>
                {payment?.deletedat && (
                  <div className="warning">
                    <WarningFilled className="icon-warning" />
                    <span className="content">
                      退会手続き済：
                      {dayjs(payment.deletedat).format('YYYY年MM月DD日')}
                      まで
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="item">
              <div className="wrap-label">
                <span className="label">期間最大利用ユーザー数</span>
              </div>
              <div className="wrap-value">
                <span className="label">{payment?.max_users} 名</span>
              </div>
            </div>
            <div className="item">
              <div className="wrap-label">
                <span className="label">お支払い方法</span>
              </div>
              <div className="wrap-value jc-sb">
                <span className="label">{paymentMethodCd === 2 ? '請求書発行' : 'クレジット'}</span>
                {!isEditing ? (
                  <button
                    className="button"
                    onClick={() => setVisiblePayment((prevState) => !prevState)}
                  >
                    お支払い方法の変更
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {paymentMethodCd === 1 ? (
          <CreditInformation
            isSubmit={isSubmit}
            isEditing={isEditing}
            setIsSubmit={setIsSubmit}
            setStatusEditing={setStatusEditing}
          />
        ) : (
          <InvoiceInformation
            isSubmit={isSubmit}
            isEditing={isEditing}
            setIsSubmit={setIsSubmit}
            setStatusEditing={setStatusEditing}
          />
        )}
        <div className="wrap-withdrawal-button">
          <button
            type="button"
            className="btn-withdrawal"
            onClick={() => setVisibleWithdrawal((prevState) => !prevState)}
          >
            退会のお手続きはこちら
          </button>
        </div>
        <ModalChangePaymentMethod
          visible={visiblePayment}
          paymentMethod={paymentMethodCd}
          setVisible={setVisiblePayment}
          onSubmit={() => {
            updatePaymentMethodCd(paymentMethodCd === 1 ? 2 : 1);
            setVisiblePayment(!visiblePayment);
          }}
          disabled={false}
        />
        <CompletedModal
          title="お支払い方法の変更が完了しました"
          subTitle="必要に応じて請求書情報の編集を行ってください。"
          visible={visiblePaymentComplete}
          setVisible={setVisiblePaymentComplete}
          onSubmit={() => {
            setVisiblePaymentComplete(!visiblePaymentComplete);
          }}
        />
        <ModalConfirmWithdraw visible={visibleWithdrawal} setVisible={setVisibleWithdrawal} />
        <ModalRegisterCreditCard
          visibleRegisterCreditCard={visibleRegisterCreditCard}
          setVisibleRegisterCreditCard={setVisibleRegisterCreditCard}
        />
      </PaymentMasterStyled>
    </>
  );
};

export default PaymentMaster;
