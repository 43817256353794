import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFormik, FormikProvider } from 'formik';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import fileSaver from 'file-saver';
import { Button, Form } from 'antd';
import {
  CloseOutlined,
  CloudDownloadOutlined,
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileWordFilled,
  FormOutlined,
  PlayCircleFilled,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import DocViewer, { DocViewerRenderers } from 'components/ManualViewer';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { settingSelector } from 'containers/AppSettings/selectors';
import PopupConfirmDownloadFile from './ConfirmDownloadFile';
import { getManualFile, updateManualFile } from '../thunk';
import { sharedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { IconEditLocked, IconPublishOpen } from 'assets';
import CompletedModal from 'components/Modal/Completed';
import { useAppDispatch, usePermission } from 'hooks';
import { Header, Input, TextArea } from 'components';
import { FileType } from 'constant/enum.constant';
import { routes } from 'navigations/routes';
import ViewManualStyled from './styles';
import * as Types from 'types';

interface Props {
  isMyManual?: boolean;
}

const ViewManual: React.FC<Props> = ({ isMyManual }) => {
  const [visibleModalComplete, setVisibleModalComplete] = useState<boolean>(false);
  const [visibleDownloadFile, setVisibleDownloadFile] = useState<boolean>(false);
  const [isViewMode, setViewMode] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(100);
  const [documents, setDocuments] = useState<
    { uri: string; fileName?: string; fileType?: string }[]
  >([]);
  const [manualViewerDetail, setManualViewerDetail] = useState<{
    manualFile?: Types.ManualFile.ResponseType;
    file?: File;
    uri?: string;
  }>({});

  const imageRef = useRef<HTMLImageElement>(null);

  const { userInfo } = useSelector(authSelector);

  const state = useLocation().state as Types.FileExplorer.DataTableType;

  const { permissionNumber } = usePermission();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { headerTitle, collapsedMenu } = useSelector(settingSelector);

  const { id } = useParams<{ id: string }>();

  const formik = useFormik<Types.ManualFileFormik>({
    initialValues: {
      title: manualViewerDetail.manualFile?.attachment_title,
      description: manualViewerDetail.manualFile?.attachment_description,
      version: manualViewerDetail.manualFile?.attachment_version,
      publish: state.publish,
    },
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      if (!id) return;
      const resultActionUpdate = await dispatch(
        updateManualFile({
          id: id,
          data: {
            item: {
              attachment_title: values.title,
              attachment_version: values.version,
              attachment_description: values.description,
              publish: values.publish,
              updatedby: userInfo?.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateManualFile.fulfilled.match(resultActionUpdate)) {
        const resultAction = await dispatch(
          getManualFile({ page: 1, per_page: 1, conditions: [{ id: 'i_id', search_value: [id] }] })
        );
        if (getManualFile.fulfilled.match(resultAction) && resultAction.payload.totalItems === 1) {
          const fileName = extractFileName(resultAction.payload.items[0].fileID);
          const nodeFileUrl = await sharedFileInMinIO(resultAction.payload.items[0].fileID);
          const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

          const manualViewerFile = new File([fileFromUrl], fileName || '', {
            type: resultAction.payload.items[0].extension,
          });

          setManualViewerDetail({
            file: manualViewerFile,
            manualFile: resultAction.payload.items[0],
            uri:
              resultAction.payload.items[0].extension === 'application/vnd.ms-excel' ||
              resultAction.payload.items[0].extension ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
              resultAction.payload.items[0].extension === 'application/msword' ||
              resultAction.payload.items[0].extension === 'application/doc' ||
              resultAction.payload.items[0].extension ===
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
              resultAction.payload.items[0].extension === 'application/ms-doc' ||
              resultAction.payload.items[0].extension === 'application/pdf' ||
              resultAction.payload.items[0].extension === 'application/vnd.ms-powerpoint'
                ? nodeFileUrl
                : URL.createObjectURL(
                    new Blob([
                      new File([fileFromUrl], fileName || '', {
                        type: resultAction.payload.items[0].extension,
                      }) as Blob,
                    ])
                  ),
          });
          setVisibleModalComplete(true);
          setIsEdit(false);
        } else {
          navigate(routes.Manual.path);
        }
      }
    },
  });

  const handleZoomIn = () => {
    setScale((prevPercentage) => Math.min(prevPercentage + 10, 200));
  };

  const handleZoomOut = () => {
    setScale((prevPercentage) => Math.max(prevPercentage - 10, 10));
  };

  const handleExportCSV = () => {
    // if (value === 'csv') {
    //   const resultAction = await dispatch(
    //     curriculumExportDataCSV({
    //       include_lookups: true,
    //       page: 1,
    //       per_page: 0,
    //     })
    //   );
    //   if (curriculumExportDataCSV.fulfilled.match(resultAction)) {
    //     exportDataCurricullumRef.current?.link.click();
    //   }
    // }
    setVisible(false);
  };

  // useEffect(() => {
  //   (async () => {
  //     if (!id) {
  //       navigate(routes.Manual.path);
  //     }
  //     dispatch(startLoading());
  //     const resultAction = await dispatch(
  //       getManualFile({ page: 1, per_page: 1, conditions: [{ id: 'i_id', search_value: [id] }] })
  //     );
  //     if (getManualFile.fulfilled.match(resultAction) && resultAction.payload.totalItems === 1) {
  //       const resultActions2 = await dispatch(
  //         getAttachManualFile({
  //           page: 1,
  //           per_page: 1,
  //           conditions: [{ id: 'fileID', search_value: [resultAction.payload.items[0].fileID] }],
  //         })
  //       );
  //       if (
  //         getAttachManualFile.fulfilled.match(resultActions2) &&
  //         resultAction.payload.totalItems === 1
  //       ) {
  //         const fileAction = await dispatch(
  //           getFileAttach({ file_id: resultActions2.payload.items[0].file })
  //         );
  //         if (getFileAttach.fulfilled.match(fileAction)) {
  //           setManualViewerDetail({
  //             attach: resultActions2.payload.items[0],
  //             file: new File([fileAction.payload], resultActions2.payload.items[0].filename || '', {
  //               type: resultAction.payload.items[0].extension,
  //             }),
  //             manualFile: resultAction.payload.items[0],
  //             uri: URL.createObjectURL(
  //               new Blob([
  //                 new File([fileAction.payload], resultActions2.payload.items[0].filename || '', {
  //                   type: resultAction.payload.items[0].extension,
  //                 }) as Blob,
  //               ])
  //             ),
  //           });
  //         }
  //       }
  //     } else {
  //       navigate(routes.Manual.path);
  //     }
  //     dispatch(stopLoading());
  //   })();
  // }, [dispatch, id, navigate]);

  useEffect(() => {
    if (manualViewerDetail.uri) {
      const newDocuments = [
        {
          uri: manualViewerDetail.uri,
          fileName: extractFileName(manualViewerDetail.manualFile?.fileID),
          fileType: manualViewerDetail.manualFile?.extension,
        },
      ];
      setDocuments(newDocuments);
    } else {
      setDocuments([]);
    }
  }, [manualViewerDetail.uri]);

  useEffect(() => {
    (async () => {
      if (!id) {
        navigate(routes.Manual.path);
      }
      dispatch(startLoading());
      setViewMode(true);
      const resultAction = await dispatch(
        getManualFile({ page: 1, per_page: 1, conditions: [{ id: 'i_id', search_value: [id] }] })
      );
      if (getManualFile.fulfilled.match(resultAction) && resultAction.payload.totalItems === 1) {
        const fileName = extractFileName(resultAction.payload.items[0].fileID);
        const nodeFileUrl = await sharedFileInMinIO(resultAction.payload.items[0].fileID);
        const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

        const manualViewerFile = new File([fileFromUrl], fileName || '', {
          type: resultAction.payload.items[0].extension,
        });

        setManualViewerDetail({
          file: manualViewerFile,
          manualFile: resultAction.payload.items[0],
          uri:
            resultAction.payload.items[0].extension === 'application/vnd.ms-excel' ||
            resultAction.payload.items[0].extension ===
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            resultAction.payload.items[0].extension === 'application/msword' ||
            resultAction.payload.items[0].extension === 'application/doc' ||
            resultAction.payload.items[0].extension ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
            resultAction.payload.items[0].extension === 'application/ms-doc' ||
            resultAction.payload.items[0].extension === 'application/pdf' ||
            resultAction.payload.items[0].extension === 'application/vnd.ms-powerpoint'
              ? nodeFileUrl
              : URL.createObjectURL(
                  new Blob([
                    new File([fileFromUrl], fileName || '', {
                      type: resultAction.payload.items[0].extension,
                    }) as Blob,
                  ])
                ),
        });
      } else {
        navigate(routes.Manual.path);
      }

      dispatch(stopLoading());
    })();
  }, [dispatch, id, navigate]);

  return (
    <ViewManualStyled
      collapsedMenu={collapsedMenu}
      showButton={!isViewMode || (isViewMode && isEdit)}
    >
      <Header title={headerTitle} />
      <FormikProvider value={formik}>
        <Form>
          {isViewMode && !isEdit ? (
            <div className="file-info">
              <div className="left-side">
                <img
                  className="icon-status"
                  src={
                    (isViewMode || isEdit) && formik.values.publish
                      ? IconPublishOpen
                      : IconEditLocked
                  }
                  alt="icon-publish"
                />
                <div className="wrap-info">
                  <div className="info">
                    {manualViewerDetail.manualFile?.extension === 'application/vnd.ms-excel' ||
                    manualViewerDetail.manualFile?.extension ===
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                      <FileExcelFilled className="icon-excel" />
                    ) : manualViewerDetail.manualFile?.extension === 'application/msword' ? (
                      <FileWordFilled className="icon-word" />
                    ) : manualViewerDetail.manualFile?.extension ===
                      'application/vnd.ms-powerpoint' ? (
                      <FilePptFilled className="icon-ppt" />
                    ) : manualViewerDetail.manualFile?.extension === 'application/pdf' ? (
                      <FilePdfFilled className="icon-pdf" />
                    ) : manualViewerDetail.manualFile?.extension.includes('image') ? (
                      <FileImageFilled className="icon-image" />
                    ) : manualViewerDetail.manualFile?.extension.includes('video') ? (
                      <PlayCircleFilled className="icon-play" />
                    ) : (
                      <FileFilled className="icon-file" />
                    )}
                    <span className="file-name">
                      {manualViewerDetail.manualFile?.attachment_title}
                    </span>
                  </div>
                  <p className="description">
                    {manualViewerDetail.manualFile?.attachment_description}
                  </p>
                </div>
              </div>
              <div className="right-side">
                <span className="version">
                  バージョン：v {manualViewerDetail.manualFile?.attachment_version}
                </span>
                <Button
                  htmlType="button"
                  className="btn"
                  onClick={() => {
                    if (
                      manualViewerDetail.manualFile?.extension.includes('image') ||
                      manualViewerDetail.manualFile?.extension.includes('video') ||
                      manualViewerDetail.manualFile?.extension === 'application/pdf'
                    ) {
                      setVisibleDownloadFile(true);
                    } else {
                      setVisible(true);
                    }
                  }}
                >
                  <CloudDownloadOutlined />
                  エクスポート
                </Button>
                <Button
                  disabled={permissionNumber === 1}
                  htmlType="button"
                  className={`btn ${permissionNumber === 1 ? 'disabled' : 'btn-edit'}`}
                  onClick={() => setIsEdit(true)}
                >
                  <FormOutlined />
                  編集する
                </Button>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => navigate(routes.Manual.path)}
                >
                  <CloseOutlined className="close-icon" />
                  <span className="close-up">閉じる</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="while-editing">
              <img
                src={
                  (isViewMode || isEdit) && formik.values.publish === 1
                    ? IconPublishOpen
                    : IconEditLocked
                }
                alt="icon-publish"
              />
              <div className="wrap-input">
                <div className="wrap-input-version">
                  <Form.Item
                    className={`title ${formik.errors.title ? 'title_error' : ''}`}
                    label={
                      manualViewerDetail.manualFile?.extension === 'application/vnd.ms-excel' ||
                      manualViewerDetail.manualFile?.extension ===
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                        <FileExcelFilled className="icon-excel" />
                      ) : manualViewerDetail.manualFile?.extension === 'application/msword' ? (
                        <FileWordFilled className="icon-word" />
                      ) : manualViewerDetail.manualFile?.extension ===
                        'application/vnd.ms-powerpoint' ? (
                        <FilePptFilled className="icon-ppt" />
                      ) : manualViewerDetail.manualFile?.extension === 'application/pdf' ? (
                        <FilePdfFilled className="icon-pdf" />
                      ) : manualViewerDetail.manualFile?.extension.includes('image') ? (
                        <FileImageFilled className="icon-image" />
                      ) : manualViewerDetail.manualFile?.extension.includes('video') ? (
                        <PlayCircleFilled className="icon-play" />
                      ) : (
                        <FileFilled className="icon-file" />
                      )
                    }
                  >
                    <Input
                      fast={true}
                      name="title"
                      placeholder="【タイトル】最大60文字"
                      maxLength={60}
                    />
                  </Form.Item>
                  <Form.Item className="version" label="バーション:">
                    <Input name="version" />
                  </Form.Item>
                </div>
                <Form.Item className="description" label="概要">
                  <TextArea name="description" rows={2} placeholder="最大480文字" maxLength={480} />
                </Form.Item>
              </div>
            </div>
          )}

          {manualViewerDetail.file?.type === FileType.MP4 && (
            <div className="file-name">{manualViewerDetail.file.name}</div>
          )}
          {manualViewerDetail.file?.type === FileType.MP4 ? (
            <div className="wrap-view">
              <div className="item-video">
                <div className="wrap-video">
                  <ReactPlayer
                    max-width={'640px'}
                    height={'100%'}
                    url={manualViewerDetail.uri}
                    controls
                    config={{
                      file: {
                        attributes: {
                          disablePictureInPicture: true,
                          controlsList: 'nodownload noplaybackrate',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          ) : manualViewerDetail.file?.type === FileType.PNG ||
            manualViewerDetail.file?.type === FileType.JPEG ? (
            <>
              <div className="wrap-btn-zoom">
                <span className="number-scale">{scale}%</span>
                <button className="zoom" onClick={handleZoomIn}>
                  <ZoomInOutlined />
                </button>
                <button className="zoom" onClick={handleZoomOut}>
                  <ZoomOutOutlined />
                </button>
              </div>
              <div className="wrap-image">
                <div className="background">
                  <div className="item-image">
                    <img
                      ref={imageRef}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain',
                        cursor: 'move',
                        transform: `scale(${scale / 100})`,
                      }}
                      src={manualViewerDetail.uri}
                      alt=""
                      draggable={false}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {manualViewerDetail.uri && (
                <DocViewer pluginRenderers={DocViewerRenderers} documents={documents} />
              )}
            </>
          )}
        </Form>
        {!isViewMode || (isViewMode && isEdit) ? (
          <div className="wrap-submit">
            <div className="wrap-button">
              <Button
                className="btn btn_outline"
                htmlType="button"
                loading={formik.isSubmitting}
                onClick={() => {
                  formik.setFieldValue('publish', 0);
                  formik.handleSubmit();
                }}
              >
                一時保存
              </Button>
              <Button
                className="btn btn_submit"
                htmlType="button"
                loading={formik.isSubmitting}
                onClick={() => {
                  formik.setFieldValue('publish', 1);
                  formik.handleSubmit();
                }}
              >
                公開
              </Button>
              <Button
                className="btn btn_close"
                onClick={() =>
                  isEdit
                    ? setIsEdit(false)
                    : navigate(isMyManual ? routes.MyManual.path : routes.Manual.path, {
                        replace: true,
                      })
                }
              >
                キャンセル
              </Button>
            </div>
          </div>
        ) : null}

        <CompletedModal
          title={formik.values.publish ? '公開しました' : '一時保存が完了しました'}
          subTitle={formik.values.publish ? undefined : 'ステータスは編集中として表示されます。'}
          visible={visibleModalComplete}
          setVisible={setVisibleModalComplete}
          onSubmit={() => navigate(isMyManual ? routes.MyManual.path : routes.Manual.path)}
        />

        <PopupConfirmExportFile
          visible={visible}
          setVisible={setVisible}
          onSubmit={handleExportCSV}
        />
        <PopupConfirmDownloadFile
          visible={visibleDownloadFile}
          setVisible={setVisibleDownloadFile}
          onSubmit={() =>
            manualViewerDetail.file
              ? fileSaver(
                  manualViewerDetail.file,
                  extractFileName(manualViewerDetail.manualFile?.fileID)
                )
              : undefined
          }
        />
      </FormikProvider>
    </ViewManualStyled>
  );
};

export default ViewManual;
