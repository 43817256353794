import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, Table, Select, Tooltip } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { parse, ParseResult } from 'papaparse';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { orderBy } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudUploadOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import PDFSingleEmployeeDocument from './PDFSingleEmployeeDocument';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import PDFManyEmployeeDocument from './PDFManyEmployeeDocument';
import ActionErrorModal from 'components/Modal/ActionError';
import { sharedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import { AFFILIATION_ASSIGN_ROLE } from 'configs';
import { Header, SelectField } from 'components';
import { employeeSelector } from './selectors';
import { routes } from 'navigations/routes';
import EmployeeStyled from './styles';
import * as Types from 'types';
import {
  deleteItemByConditionsAffiliationLevel,
  deleteItemByConditionsAffiliationRole,
  getDataRequiredCurriculumSettings,
  getDataRequiredSkillcheckSettings,
  deleteItemByConditionsCurriculum,
  deleteItemByConditionsSkillCheck,
  createAffiliationAssignLevel,
  getDataEmployeeExport,
  createAffiliationRole,
  getSelectDepartment,
  getSelectEmployee,
  getSelectPosition,
  addLinkAffiliation,
  getDataEmployee,
  getDataAllGmail,
  createEmployee,
  deleteEmployee,
  getSubPosition,
  addUser,
} from './thunk';

const { Option } = Select;

const Employee: React.FC = () => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.EmployeeUser.ResponseType[]>([]);
  const [itemSelected, setItemSelected] = useState<Types.EmployeeUser.ResponseType>();
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false);
  const [openPopupCreate, setOpenPopupCreate] = useState<boolean>(false);

  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo, dataCompany } = useSelector(authSelector);
  const {
    total,
    dataEmployee,
    dataSelectPosition,
    dataSelectDepartment,
    dataSelectEmployeeCode,
    dataSelectEmployeeName,
    dataAllGmail,

    countUserMember,
  } = useSelector(employeeSelector);

  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { permissionNumber } = usePermission();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isDisableAddUser = useMemo(() => {
    return countUserMember >= 5 && dataCompany?.plan_id === 'standard';
  }, [countUserMember, dataCompany?.plan_id]);

  const columns: ColumnsType<Types.EmployeeUser.ResponseType> = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '10%',
      align: 'left',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '10%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '10%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text.replace(/^\d+ /, ''),
      align: 'left',
    },
    {
      title: '役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text.replace(/^\d+ /, ''),
      align: 'left',
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '10%',
      ellipsis: true,
      render: (text: string) => `${dayjs().diff(text, 'years', false)}歳`,
      align: 'left',
    },
    {
      title: '入社年月日',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM')}`,
      align: 'left',
    },
    {
      title: '最終ログイン',
      dataIndex: 'last_login_date',
      key: 'last_login_date',
      width: '10%',
      ellipsis: true,
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM/DD HH:mm')}`,
      align: 'left',
    },
    {
      title: '権限',
      dataIndex: 'role_code',
      key: 'role_code',
      width: '10%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      render: (_, item) => (
        <FormOutlined
          className="icon"
          onClick={() =>
            permissionNumber !== 1 &&
            navigate(
              generatePath(routes.EditEmployee.path, {
                id: item.i_id,
              })
            )
          }
        />
      ),
      align: 'left',
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '5%',
      align: 'left',
      render: (_, item) => (
        <DeleteOutlined
          className="icon"
          onClick={() => {
            if (permissionNumber !== 1) {
              setVisiblePopupConfirmDelete(true);
              setItemSelected(item);
            }
          }}
        />
      ),
    },
  ];

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const fetchEmployee = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataEmployee({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'employee_code',
                order: 'asc',
              },
            ],
            page: page,
            per_page: perPage,
            include_lookups: true,
            include_item_ref: true,
            omit_total_items: false,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, page, perPage, userInfo]);

  const isEmailDuplicate = (email: string, allEmails: Array<string>) => {
    return allEmails.includes(email);
  };

  const handleImportCSV = (file: File) => {
    parse(file, {
      complete: ({ data }: ParseResult<string>) => {
        const resultsDataCSV: Array<Types.EmployeeExportType> = data
          .map((item) =>
            Object.fromEntries(HEADER_EMPLOYEE_CSV.map(({ key }, i) => [key, item[i]]))
          )
          .splice(1) as unknown as Array<Types.EmployeeExportType>;
        resultsDataCSV.map(async (item) => {
          if (!isEmailDuplicate(item.email, dataAllGmail)) {
            const resultAddUserAction = await dispatch(
              addUser({
                user_code: item.email,
                email: item.email,
                username: item.name,
                passowrd: item.password,
                email_template_id: '621ec0914f2f5cb7f71d0c58',
              })
            );
            if (
              addUser.fulfilled.match(resultAddUserAction) &&
              (resultAddUserAction.payload.added || !resultAddUserAction.payload.exists)
            ) {
              const affiliation_id = dataSelectDepartment.find(
                (i) => i.affiliation_id === item.department_name.split(' ')[0]
              )?.i_id;
              const positions_id = dataSelectPosition.find(
                (i) => i.position_code === item.position_code.split(' ')[0]
              )?.i_id;
              const resultAction2 = await Promise.all([
                dispatch(
                  createAffiliationRole({
                    item: {
                      company_id: userInfo?.company_id,
                      affiliation_id: affiliation_id,
                      login_id: item.email,
                      positions_code: positions_id,
                      sort_order: 1,
                      main_role: 'main',
                      createdby: userInfo?.login_id,
                      createdat: new Date(),
                    },
                  })
                ),
                dispatch(
                  createAffiliationAssignLevel({
                    item: {
                      company_id: userInfo?.company_id,
                      affiliation_id: item.department_name.split(' ')[0],
                      login_id: item.email,
                      sort_order: 1,
                      createdby: userInfo?.login_id,
                      createdat: new Date(),
                    },
                  })
                ),
              ]);
              if (
                createAffiliationAssignLevel.fulfilled.match(resultAction2[1]) &&
                createAffiliationRole.fulfilled.match(resultAction2[0])
              ) {
                await dispatch(
                  addLinkAffiliation({
                    id: resultAction2[1].payload.item_id,
                    data: {
                      link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                      link_item_id: resultAction2[0].payload.item_id,
                    },
                  })
                );
              }
              await dispatch(
                createEmployee({
                  item: {
                    employee_code: item.employee_code,
                    company_id: userInfo?.company_id,
                    login_id: item.email,
                    email: item.email,
                    name: item.name,
                    name_furigana: item.kana,
                    user_type: 'member',
                    role_code: item.i_id_role_code,
                    password: item.password,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              );
            }
            fetchEmployee();
          }
        });
      },
    });
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    console.log(selectedRow);
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataEmployeeExport({
          page: 1,
          per_page: 0,
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'i_id',
              search_value: selectedRow.map((row) => row.i_id),
            },
          ],
          include_lookups: true,
          include_item_ref: true,
        })
      );
      if (getDataEmployeeExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          employee_code: item.employee_code,
          name: item.name,
          kana: item.kana,
          email: item.email,
          password: item.password,
          department_name: item.department_name,
          position_code: item.position_code,
          birthday: item.birthday,
          hire_date: item.hire_date,
          role_code: item.role_code,
          i_id_role_code: item?.lookup_items?.role_code.i_id,
        }));

        const csvString = [
          HEADER_EMPLOYEE_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '内部ユーザー.csv');
      }
    } else {
      if (!selectedRow.length) return;

      if (selectedRow.length === 1) {
        const userObject: Types.EmployeeUser.EmployeeExportPDF = {
          srcAvatar: '',
          employee_code: selectedRow[0].employee_code || '',
          name: selectedRow[0].name || '',
          kana: selectedRow[0].kana || '',
          login_id: selectedRow[0].login_id || '',
          birthday: selectedRow[0].birthday || '',
          hire_date: selectedRow[0].hire_date || '',
          main_position: {
            affiliation_name: '',
            position_name: '',
            main_role: '',
          },
          sub_position: [],
          curriculum: [],
          skillcheck: [],
          role_code: selectedRow[0].role_code,
        };

        if (selectedRow[0].icon_fileID) {
          const srcAvatar = await sharedFileInMinIO(selectedRow[0].icon_fileID);
          userObject.srcAvatar = srcAvatar;
        }

        const promises = [
          dispatch(
            getSubPosition({
              conditions: [
                { id: 'company_id', search_value: [selectedRow[0].company_id] },
                { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
              ],
              sort_fields: [{ id: 'sort_order', order: 'asc' }],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
              include_links: true,
            })
          ),
          dispatch(
            getDataRequiredCurriculumSettings({
              conditions: [
                { id: 'company_id', search_value: [selectedRow[0].company_id] },
                { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
              ],
              page: 1,
              per_page: 0,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataRequiredSkillcheckSettings({
              conditions: [
                { id: 'company_id', search_value: [selectedRow[0].company_id] },
                { id: 'login_id', search_value: [selectedRow[0].login_id], exact_match: true },
              ],
              page: 1,
              per_page: 0,
              include_item_ref: true,
            })
          ),
        ];

        const [resultAction, resultCurriculum, resultSkillcheck] = await Promise.all(promises);

        if (getSubPosition.fulfilled.match(resultAction)) {
          const position = resultAction.payload.items.map((item) => ({
            affiliation_name: item.lookup_items?.affiliation_id?.name || '',
            position_name: item.lookup_items?.positions_code?.name || '',
            main_role: item.main_role || '',
          }));
          const sub_position = position.filter((pos) => pos.main_role !== 'main');
          const main_position = position.find((pos) => pos.main_role === 'main');
          Object.assign(userObject, { main_position, sub_position });
        }

        if (getDataRequiredCurriculumSettings.fulfilled.match(resultCurriculum)) {
          const curriculum = orderBy(
            resultCurriculum.payload.report_results.filter(
              (curr) => curr.hidden_curricullum === 'off'
            ),
            ['curriculum_name'],
            ['asc']
          );
          Object.assign(userObject, { curriculum });
        }

        if (getDataRequiredSkillcheckSettings.fulfilled.match(resultSkillcheck)) {
          const skillcheck = orderBy(
            resultSkillcheck.payload.report_results.filter(
              (user) => user.hidden_skillcheck === 'off'
            ),
            ['skill_check_name'],
            ['asc']
          );
          Object.assign(userObject, { skillcheck });
        }

        const blob = await pdf(<PDFSingleEmployeeDocument employee={userObject} />).toBlob();
        saveAs(blob, `${selectedRow[0].employee_code}_${selectedRow[0].name}.pdf`);
      } else {
        const blob = await pdf(
          <PDFManyEmployeeDocument employeeSelected={selectedRow} total={dataEmployee.length} />
        ).toBlob();
        saveAs(blob, 'employee_table.pdf');
      }
    }
    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleSubmitDelete = async () => {
    if (itemSelected?.i_id) {
      dispatch(startLoading());

      const resultActions = await Promise.all([
        dispatch(
          deleteEmployee({
            id: itemSelected?.i_id,
          })
        ),
        dispatch(
          deleteItemByConditionsAffiliationRole({
            conditions: [
              {
                id: 'login_id',
                search_value: [itemSelected.login_id],
                exact_match: true,
              },
            ],
            use_display_id: true,
          })
        ),
        dispatch(
          deleteItemByConditionsAffiliationLevel({
            conditions: [
              {
                id: 'login_id',
                search_value: [itemSelected.login_id],
                exact_match: true,
              },
            ],
            use_display_id: true,
          })
        ),
        dispatch(
          deleteItemByConditionsCurriculum({
            conditions: [
              {
                id: 'login_id',
                search_value: [itemSelected.login_id],
                exact_match: true,
              },
            ],
            use_display_id: true,
          })
        ),
        dispatch(
          deleteItemByConditionsSkillCheck({
            conditions: [
              {
                id: 'login_id',
                search_value: [itemSelected.login_id],
                exact_match: true,
              },
            ],
            use_display_id: true,
          })
        ),
      ]);
      if (deleteEmployee.fulfilled.match(resultActions[0])) {
        await fetchEmployee();
        dispatch(stopLoading());
        setVisiblePopupConfirmDelete(false);
      }
      dispatch(stopLoading());
    }
  };

  const formik = useFormik<Types.EmployeeFormSearchFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      department_name: '',
      position_code: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      await dispatch(
        getDataEmployee({
          conditions,
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: perPage,
          include_lookups: true,
          include_item_ref: true,
          omit_total_items: false,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectEmployee({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              sort_fields: [
                {
                  id: 'employee_code',
                  order: 'asc',
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (userInfo) {
      dispatch(startLoading());
      dispatch(
        getDataAllGmail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, userInfo, visiblePopupUploadCSV]);

  useEffect(fetchEmployee, [fetchEmployee]);

  return (
    <>
      <Header title={headerTitle} />
      <EmployeeStyled collapsedMenu={collapsedMenu} isEmptyData={!dataEmployee.length}>
        <p className="text-note">
          社内ユーザーマスタの作成・管理を行う画面です。
          <br />
          ユーザーに紐づける必修カリキュラム・スキルチェックの設定もこの画面から行います。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="employee_code"
                className="item"
                label={<span className="text-label">社員番号</span>}
              >
                <SelectField
                  name="employee_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectEmployeeCode?.map((value, i) => (
                    <Option value={value} key={i}>
                      {value}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectEmployeeName?.map((value, i) => (
                    <Option value={value} key={i}>
                      {value}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectDepartment?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 13,
                  color: '#424242',
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    color: '#F6AC00',
                    fontSize: 15,
                  }}
                />
                &nbsp; 社内ユーザーの作成上限です。
              </span>
            }
            color="white"
            trigger={['hover']}
            onOpenChange={(open) => {
              if (open && isDisableAddUser) {
                setOpenPopupImport(true);
                return;
              }
              setOpenPopupImport(false);
            }}
            open={openPopupImport}
            placement="bottomLeft"
            overlayInnerStyle={{
              padding: '12px 28px',
              fontSize: 13,
              width: 261,
            }}
            overlayStyle={{
              top: 309,
              width: 300,
            }}
          >
            <Button
              disabled={isDisableAddUser}
              className={`btn ${
                permissionNumber === 1 || isDisableAddUser ? 'disabled' : 'btn-active'
              }`}
              icon={<CloudUploadOutlined className="icon" />}
              onClick={() => setVisiblePopupUploadCSV(true)}
            >
              インポート
            </Button>
          </Tooltip>

          <Tooltip
            title={
              <span
                style={{
                  fontSize: 13,
                  color: '#424242',
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    color: '#F6AC00',
                    fontSize: 15,
                  }}
                />
                &nbsp; 社内ユーザーの作成上限です。
              </span>
            }
            color="white"
            trigger={['hover']}
            onOpenChange={(open) => {
              if (open && isDisableAddUser) {
                setOpenPopupCreate(true);
                return;
              }
              setOpenPopupCreate(false);
            }}
            open={openPopupCreate}
            placement="bottomLeft"
            overlayInnerStyle={{
              padding: '12px 28px',
              fontSize: 13,
              width: 261,
            }}
            overlayStyle={{
              top: 309,
              width: 300,
            }}
          >
            <Button
              className={`btn ${
                permissionNumber === 1 || isDisableAddUser ? 'disabled' : 'btn-active'
              }`}
              icon={<PlusOutlined className="icon" />}
              onClick={() =>
                navigate(generatePath(routes.CreateEmployee.path, { entity: 'receiving' }))
              }
            >
              新規登録
            </Button>
          </Tooltip>
        </div>
        <div
          className={
            dataEmployee.length > 0 ? 'text-count-general' : 'text-count-general text-count-no-data'
          }
        >
          {(page - 1) * perPage + 1} - {''}
          {page * perPage > total ? total : page * perPage}
          <span className="text-static"></span> / {total}
          <span className="text-static">件</span>
          <div className="page-select">
            <div className="label">
              <span>表示件数</span>：
            </div>
            <Select value={perPage} onSelect={handleSelectChange}>
              {[10, 20, 50, 100].map((value, index) => (
                <Option key={index} value={value} label={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          rowKey="index"
          className="table"
          dataSource={dataEmployee.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows) => setSelectedRow(selectedRows),
            getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
          }}
          pagination={{
            pageSize: perPage,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button
              className="btn btn-outline"
              onClick={() => navigate(generatePath(routes.EducationProgram.path))}
            >
              <span>
                必修カリキュラム・
                <br />
                スキルチェック一括設定
              </span>
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <UploadCSV
          onSubmit={handleImportCSV}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={setVisiblePopupConfirmDelete}
        />
      </EmployeeStyled>
    </>
  );
};

export default Employee;
