import styled from 'styled-components';

export default styled.section`
  overflow: hidden;
  .header .title-page {
    font-weight: normal;
  }
  .container {
    padding: 30px;
    .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content {
        font-size: 12px;
        color: #424242;
      }
    }
  }
`;
