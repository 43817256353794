import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import saveAs from 'file-saver';
import { maxBy } from 'lodash';
import { Select } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CaretLeftOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FilterOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { ItemMoveCopySelectedType, UserTreeviewType } from 'types/services/curriculum';
import { curriculumExportDataCSV, getCurriculum, getDataUserSetting } from '../thunk';
import { CURRICULUM_TYPE, CURRICULUM_STATUS } from 'constant/select.constants';
import { removeNodeLevel4Selected } from 'containers/Curriculum/Search/slice';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { HEADER_CURRICULUM_CSV } from 'constant/header.export.constant';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { setFilterConditions, clearFilterConditions } from '../slice';
import { CreateQuestion } from 'pages/Settings/QuestionMaster/Modal';
import { settingSelector } from 'containers/AppSettings/selectors';
import { HEADER_HEIGHT, LIST_LABEL, LIST_TAB_BAR } from 'constant';
import NodeTreeView from 'containers/Curriculum/NodeTreeView';
import UserTreeView from 'containers/Curriculum/UserTreeView';
import MenuRightUser from 'containers/Curriculum/SearchUser';
import SearchCurriculum from 'containers/Curriculum/Search';
import { CreateEditCurriculum, UploadCSV } from '../Modal';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import { ErrorBoundary, Header } from 'components';
import { curriculumSelector } from '../selectors';
import exportPDF from 'libs/utils/exportPdf';
import FileExportPDF from '../FileExportPDF';
import Wrapper, { Button } from './styles';
import { SortByDesc } from 'assets';

const { Option } = Select;

interface Props {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
}

const Treeview: React.FC<Props> = ({ setOpenCurriculumMaster }) => {
  const [itemMoveCopySelected, setItemMoveCopySelected] = useState<ItemMoveCopySelectedType>();
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showConfirmImportFileModal, setShowConfirmImportFileModal] = useState<boolean>(false);
  const [openModalCreateCurriculum, setOpenModalCreateCurriculum] = useState<boolean>(false);
  const [curriculumSelected, setCurriculumSelected] = useState<UserTreeviewType>();
  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [isOpenMenuRight, setOpenMenuRight] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);
  const [tabActive, setTabActive] = useState<number>(0);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });
  const [selectedQuestion, setSelectedQuestion] = useState<
    Array<{
      i_id: string;
      name: string;
      code: string;
    }>
  >([]);

  const { curricullum, data_user_setting, filter_conditions } = useSelector(curriculumSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const ref = useRef(null);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const handleChangeFilter = (
    type?: keyof typeof CurriculumType,
    status?: keyof typeof CurriculumStatus
  ) => {
    if (type) {
      dispatch(
        setFilterConditions({
          type,
        })
      );
    }
    if (status) {
      dispatch(
        setFilterConditions({
          status,
        })
      );
    }
  };

  const handleChangeCurriculumName = (value: string) => {
    dispatch(
      setFilterConditions({
        name: value,
      })
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset >= HEADER_HEIGHT) {
      setPageYOffset(HEADER_HEIGHT);
    } else {
      setPageYOffset(window.pageYOffset);
    }
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FileExportPDF
          data={curricullum}
          tabActive={tabActive}
          columnClosed={columnClosed}
          selectedQuestion={selectedQuestion}
          curriculumSelected={curriculumSelected}
          itemMoveCopySelected={itemMoveCopySelected}
        />
      </div>
    );
  }, [
    columnClosed,
    curricullum,
    curriculumSelected,
    itemMoveCopySelected,
    selectedQuestion,
    tabActive,
  ]);

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        curriculumExportDataCSV({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );
      if (curriculumExportDataCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          curriculum_code: item.curriculum_code,
          curriculum_name: item.curriculum_name,
          curriculum_description: item.curriculum_description,
          level1_name: item.level1_name,
          level1_code: item.level1_code,
          level2_name: item.level2_name,
          level2_code: item.level2_code,
          level3_name: item.level3_name,
          level3_code: item.level3_code,
          level4_name: item.level4_name,
          level4_code: item.level4_code,
          question_name: item.question_name,
          question_code: item.question_code,
          question_description: item.question_description,
          question_attach: item.question_attach,
          question2_attach: item.question2_attach,
          question3_attach: item.question3_attach,
          problems1: item.problems1,
          problems2: item.problems2,
          problems3: item.problems3,
          problems1_attach: item.problems1_attach,
          problems2_attach: item.problems2_attach,
          problems3_attach: item.problems3_attach,
          answer: item.answer,
          comment: item.comment,
          problems1_attach_fileID: item.problems1_attach_fileID,
          problems2_attach_fileID: item.problems2_attach_fileID,
          problems3_attach_fileID: item.problems3_attach_fileID,
          fileID: item.fileID,
          time_limit: item.time_limit,
          score: item.score,
        }));

        const csvString = [
          HEADER_CURRICULUM_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, 'data_curricullum_export.csv');
      }
    } else {
      if (!ref.current) return;

      exportPDF(ref, 'カリキュラムツリー.pdf');
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (userInfo && tabActive === 2) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataUserSetting({
              conditions: [
                ...filter_conditions.conditions,
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'required_curriculum',
                  search_value: ['1'],
                  exact_match: true,
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, filter_conditions.conditions, tabActive]);

  useEffect(() => {
    if (tabActive === 2) return;
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getCurriculum({
              conditions: [
                ...filter_conditions.conditions,
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, filter_conditions.conditions, tabActive]);

  useEffect(() => {
    if (nodeLevel4Selected || curriculumSelected) {
      setOpenMenuRight(true);
    }
  }, [nodeLevel4Selected, curriculumSelected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      dispatch(removeNodeLevel4Selected());
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearFilterConditions());
    };
  }, [dispatch]);

  return (
    <Wrapper
      isOpenMenuRight={tabActive !== 1 && isOpenMenuRight}
      collapsedMenu={collapsedMenu}
      tabActive={tabActive}
    >
      {component}
      <Header title={headerTitle} className="header">
        <form className="form">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              className="select-input"
              placeholder={messages['M-21-3']}
              value={filter_conditions.type}
              onChange={(value: keyof typeof CurriculumType) =>
                handleChangeFilter(value, undefined)
              }
            >
              {CURRICULUM_TYPE.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <Select
              className="select-input"
              placeholder={messages['M-21-4']}
              value={filter_conditions.status}
              onChange={(value: keyof typeof CurriculumStatus) =>
                handleChangeFilter(undefined, value)
              }
            >
              {CURRICULUM_STATUS.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))}
            </Select>
          </div>
          <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
          <div className="form-input">
            <Select
              showSearch
              className="select-input"
              placeholder={messages['M-21-5']}
              value={filter_conditions.name}
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChangeCurriculumName}
            >
              {curricullum.map((curr, index) => (
                <Option key={index} value={curr.i_id}>
                  {curr.name}
                </Option>
              ))}
            </Select>
          </div>
          <button
            type="button"
            className="text-reset"
            onClick={() => dispatch(clearFilterConditions())}
          >
            {messages['M-21-6']}
          </button>
        </form>
      </Header>
      <div className="wrap-title">
        <div className="wrap-button">
          <div>
            {LIST_TAB_BAR.map((item, index) => (
              <Button
                key={index}
                tabActive={tabActive}
                index={index}
                onClick={() => {
                  setTabActive(index);
                  setOpenMenuRight(false);
                  setColumnClosed(undefined);
                  dispatch(removeNodeLevel4Selected());
                  if (itemMoveCopySelected) {
                    setItemMoveCopySelected(undefined);
                  }
                }}
              >
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {messages[item]}
              </Button>
            ))}
          </div>
          <div className="button-function">
            <button
              className="btn btn-outline"
              onClick={() =>
                // navigate(generatePath(routes.CurriculumMaster.path, { entity: 'receiving' }))
                setOpenCurriculumMaster(true)
              }
            >
              階層リスト
              <RightOutlined className="size-icon-down-outline" />
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmImportFileModal(true)}>
              <CloudUploadOutlined className="size-icon" />
              {messages['M-21-109']}
            </button>
            <button className="btn btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
              <CloudDownloadOutlined className="size-icon" />
              {messages['M-21-110']}
            </button>
          </div>
        </div>
        <div className="flex-label">
          {tabActive !== 2 ? (
            LIST_LABEL.map((item, index) => (
              <p
                style={{ textAlign: 'left', paddingLeft: '30px' }}
                key={index}
                className={`label-text${index === columnClosed ? ' active' : ''}`}
                onClick={() =>
                  setColumnClosed((prevState) =>
                    prevState === index || index > 4 ? undefined : index
                  )
                }
              >
                {index < 5 ? (
                  index === columnClosed ? (
                    <CaretUpOutlined className="icon-label" />
                  ) : (
                    <CaretDownOutlined className="icon-label" />
                  )
                ) : null}
                {messages[item]}
              </p>
            ))
          ) : (
            <p
              className="label-text"
              onClick={() => setColumnClosed((prevState) => (prevState === 0 ? undefined : 0))}
            >
              {columnClosed === 0 ? (
                <CaretUpOutlined className="icon-label" />
              ) : (
                <CaretDownOutlined className="icon-label" />
              )}
              必修カリキュラム
            </p>
          )}
        </div>
        {tabActive !== 1 && (
          <div className={`setting-border ${pageYOffset >= HEADER_HEIGHT ? 'on-top' : ''}`}>
            <div className="border-green" />
            <div className="setting" onClick={() => setOpenMenuRight(!isOpenMenuRight)}>
              <div className="title">
                <CaretLeftOutlined className={isOpenMenuRight ? 'opened' : ''} />
                <p>{tabActive === 0 ? messages['M-21-10'] : 'ユーザーリスト'}</p>
              </div>
            </div>
          </div>
        )}
        {tabActive === 0 && (
          <p className="title-add">
            {curricullum?.length === 0 && messages['M-21-41']}
            <button
              disabled={permissionNumber === 1}
              className={permissionNumber === 1 ? 'disabled' : 'btn-add'}
              onClick={() => setOpenModalCreateCurriculum(true)}
            >
              + {messages['M-21-111']}
            </button>
          </p>
        )}
      </div>
      <div className="flex">
        <div className="dashboard">
          <div className="wrap-body">
            {tabActive === 1 && (
              <div className="wrap-title-tab-1">
                <p className="title">
                  {itemMoveCopySelected ? (
                    <div className="wrap-step-text">
                      <p className="step1">Step.2</p>
                      <p className="step1-content">ペースト先の階層を選択してください</p>
                      <span
                        className="sub-title"
                        onClick={() => setItemMoveCopySelected(undefined)}
                      >
                        やり直す
                      </span>
                    </div>
                  ) : (
                    <div className="wrap-step-text">
                      <p className="step1">Step.1</p>
                      <p className="step1-content">
                        移動 or コピーしたいアイテムを選択してください。
                      </p>
                    </div>
                  )}
                </p>
              </div>
            )}
            <div className="wrap-body-tree">
              {tabActive < 2
                ? curricullum.map((c, index) => (
                    <div
                      key={index}
                      className={`wrap-tree ${index < curricullum?.length - 1 ? 'bordered' : ''} ${
                        !index ? 'first' : ''
                      }`}
                    >
                      <NodeTreeView
                        treeData={c}
                        treeViewIndex={index}
                        tabActive={tabActive}
                        columnClosed={columnClosed}
                        selectedQuestion={selectedQuestion}
                        itemMoveCopySelected={itemMoveCopySelected}
                        setItemMoveCopySelected={setItemMoveCopySelected}
                        setSelectedQuestion={setSelectedQuestion}
                      />
                    </div>
                  ))
                : data_user_setting?.map((u, index) => (
                    <div
                      key={index}
                      className={`wrap-tree ${
                        index < data_user_setting?.length - 1 ? 'bordered' : ''
                      }`}
                    >
                      <UserTreeView
                        treeData={u}
                        columnClosed={columnClosed}
                        curriculumSelected={curriculumSelected}
                        setCurriculumSelected={setCurriculumSelected}
                      />
                    </div>
                  ))}
            </div>
          </div>
        </div>
        {isOpenMenuRight ? (
          tabActive === 2 ? (
            <MenuRightUser
              pageYOffset={pageYOffset}
              curriculumSelected={curriculumSelected}
              setCurriculumSelected={setCurriculumSelected}
            />
          ) : (
            tabActive === 0 && (
              <SearchCurriculum
                pageYOffset={pageYOffset}
                openModalCreateQuestion={openModalCreateQuestion}
                setOpenModalCreateQuestion={setOpenModalCreateQuestion}
                setSelectedQuestion={setSelectedQuestion}
                selectedQuestion={selectedQuestion}
              />
            )
          )
        ) : null}
      </div>
      <CreateEditCurriculum
        title={messages['M-21-48']}
        maxSortOrder={(maxBy(curricullum, (o) => o.sort_order)?.sort_order || 0) + 1}
        subTitle={
          <>
            カリキュラム名・説明の新規作成が可能です。
            <br />
            入力後、新規作成ボタンをクリックしてください。
          </>
        }
        textSubmit={messages['M-21-53']}
        type="create"
        visible={openModalCreateCurriculum}
        setVisible={setOpenModalCreateCurriculum}
      />
      <ErrorBoundary>
        <CreateQuestion
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
          setVisibleSuccess={() => {}}
        />
      </ErrorBoundary>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <UploadCSV visible={showConfirmImportFileModal} setVisible={setShowConfirmImportFileModal} />
    </Wrapper>
  );
};

export default Treeview;
