import React, { useEffect, useState } from 'react';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDrop } from 'react-dnd';
import { orderBy } from 'lodash';
import { Popover } from 'antd';

import { Wrapper as WrapperColumnRoot } from '../NodeTreeView/TreeNodeRenderer/styles';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { UserTreeviewType } from 'types/services/curriculum';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import { Wrapper, WrapperToolTip } from './styles';
import { routes } from 'navigations/routes';
import NodeRenderer from './NodeRenderer';
import {
  deleteLinkUserAssignCurriculum,
  getDataUserSetting,
} from 'pages/Settings/Curriculum/thunk';

interface Props {
  columnClosed?: number;
  treeData: UserTreeviewType;
  curriculumSelected?: UserTreeviewType;
  setCurriculumSelected: React.Dispatch<React.SetStateAction<UserTreeviewType | undefined>>;
}

const UserTreeView: React.FC<Props> = ({
  treeData,
  columnClosed,
  curriculumSelected,
  setCurriculumSelected,
}) => {
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');
  const [userID, setUserId] = useState<string>('');

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const { permissionNumber } = usePermission();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const [, drop] = useDrop<
    { type: string; user_id: string; department_name: string },
    {
      curricullum_code?: string;
      publish?: number;
    },
    void
  >({
    accept: 'move-user',
    canDrop: (item) =>
      curriculumSelected?.i_id === treeData.i_id &&
      !treeData.children?.some(
        (c) =>
          c.department_name === item.department_name &&
          c.children?.some((user) => user.user_id === item.user_id)
      ),
    drop: () => ({
      curricullum_code: treeData.curricullum_code,
      publish: treeData.publish,
    }),
  });

  const handleRemoveLinkUser = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteLinkUserAssignCurriculum({
        id: itemId,
      })
    );
    if (deleteLinkUserAssignCurriculum.fulfilled.match(resultAction)) {
      await dispatch(
        getDataUserSetting({
          include_lookups: true,
          include_item_ref: true,
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'required_curriculum',
              search_value: ['1'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
    dispatch(stopLoading());
  };

  const handleEditLinkUser = () => {
    navigate(
      generatePath(routes.EditEmployee.path, {
        id: userID,
      })
    );
  };

  useEffect(() => {
    return () => {
      setCurriculumSelected(undefined);
    };
  }, [setCurriculumSelected]);

  return (
    <Wrapper
      className="rst__tree"
      treeActive={
        curriculumSelected !== undefined &&
        curriculumSelected.curricullum_code === treeData.curricullum_code
      }
    >
      <WrapperColumnRoot nodeHeight={56} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            isPublish={treeData?.publish!}
            data={treeData}
            curricullum_name={treeData?.curricullum_name!}
            curricullum_description={treeData?.curricullum_description!}
            curriculumSelected={
              curriculumSelected !== undefined &&
              curriculumSelected.curricullum_code === treeData?.curricullum_code
            }
            handleSwitch={(value: boolean) => setCurriculumSelected(value ? treeData : undefined)}
          />
        </div>
      </WrapperColumnRoot>
      {columnClosed !== 0 && (
        <div ref={drop} className="tree">
          {treeData?.children?.length ? (
            orderBy(treeData.children, 'department_name', 'asc').map(
              ({ department_name, department_code, children }, i) => (
                <div key={i}>
                  <p className="text-name">{department_name}</p>
                  <div className="wrap_node_tree">
                    {children?.map((value, index2) => (
                      <Popover
                        key={index2}
                        placement="bottomLeft"
                        overlayStyle={{
                          width: 204,
                          marginTop: '-25px',
                        }}
                        trigger="click"
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                        content={
                          <WrapperToolTip>
                            <span className="text-code">{department_name}</span>
                            <br />
                            <span className="text-bold">{value.user_name}</span>
                            {!treeData.publish && (
                              <div className="text_icon">
                                <EditOutlined
                                  className="icon"
                                  onClick={() => {
                                    if (permissionNumber !== 1) {
                                      setUserId(value.user_id!);
                                      setVisibleModalEdit(true);
                                    }
                                  }}
                                />
                                <MinusOutlined
                                  className="icon"
                                  onClick={() => {
                                    if (permissionNumber !== 1) {
                                      setItemId(value.i_id!);
                                      setVisibleModalDelete(true);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </WrapperToolTip>
                        }
                      >
                        <div className="node-item">
                          <span className="text-code">{department_code}</span>
                          <span className="text-bold">{value.user_name}</span>
                        </div>
                      </Popover>
                    ))}
                  </div>
                </div>
              )
            )
          ) : (
            <p className="text-not-set">未設定</p>
          )}
        </div>
      )}
      <ConfirmDeleteModal
        title="ユーザー解除"
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
        onCancel={() => setItemId('')}
        onSubmit={handleRemoveLinkUser}
        subTitle="指定したユーザーの必修カリキュラム設定を解除します。"
      />
      <ConfirmDeleteModal
        title="社内ユーザー編集"
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        onSubmit={handleEditLinkUser}
        onCancel={() => setItemId('')}
        subTitle="社内ユーザーマスタに移動します。"
      />
    </Wrapper>
  );
};

export default UserTreeView;
