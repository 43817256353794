import React, { useEffect, useMemo, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { unionBy, maxBy, differenceBy } from 'lodash';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
  PlusOutlined,
  LeftOutlined,
  SearchOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import { memoizedGetFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CompletedDeleteModal from 'components/Modal/CompletedDelete';
import { updateSkillCheck } from 'pages/Settings/SkillCheck/thunk';
import { menuRightQuestionSkillCheckSelector } from './selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import StyledSearch, { Button, StylesContent } from './styles';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { Input, SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Item from './Item';
import {
  deleteQuestion,
  createLinkSkillCheckAssignQuestion,
} from 'containers/CreateEditQuestion/thunk';
import {
  getCreatorQuestionOption,
  getCurriculumLevelOption,
  getCurriculumQuestionOption,
  getListQuestion,
} from './thunk';
import {
  filterCreatedBy,
  filterCurriculum,
  filterLevel1,
  filterLevel2,
  filterLevel3,
  filterLevel4,
  filterQuestionName,
  resetInitialState,
} from './slice';

const { Option } = Select;

interface Props {
  pageYOffset: number;
  fetchDataSkillCheck: () => void;
  selectedQuestion: Types.QuestionProps[];
  skillCheckSelected?: Types.SkillCheckTreeViewType;
  setOpenModalCreateEditQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
  setSkillCheckSelected: React.Dispatch<
    React.SetStateAction<Types.SkillCheckTreeViewType | undefined>
  >;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<Types.QuestionProps[]>>;
  openModalCreateEditQuestion?: {
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: (() => void) | undefined;
  };
}

const MenuRightSkillCheck: React.FC<Props> = ({
  pageYOffset,
  selectedQuestion,
  skillCheckSelected,
  fetchDataSkillCheck,
  setSelectedQuestion,
  setSkillCheckSelected,
  openModalCreateEditQuestion,
  setOpenModalCreateEditQuestion,
}) => {
  const [lvl1Option, setLvl1Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl2Option, setLvl2Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl3Option, setLvl3Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl4Option, setLvl4Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [dataPrimitive, setDataPrimitive] = useState<Types.TreeItem<Types.CurriculumItemType>[]>(
    []
  );
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });
  const [sortOrder, setSortOrder] = useState<number>();

  const dispatch = useAppDispatch();

  const { curricullum } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    questionSearch,
    curriculumOption,
    level1Option,
    level2Option,
    level3Option,
    level4Option,
    creatorOption,
  } = useSelector(menuRightQuestionSkillCheckSelector);

  const { messages } = useIntl();

  const dataList = useMemo(() => {
    return unionBy(questionSearch, 'question_code');
  }, [questionSearch]);

  const formik = useFormik<Types.QuestionSearchFormik>({
    initialValues: {
      curriculum_code: '',
      level1_code: '',
      level2_code: '',
      level3_code: '',
      level4_code: '',
      created_by: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        getListQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],

          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      if (getListQuestion.fulfilled.match(resultAction)) {
        if (values.curriculum_code) {
          dispatch(filterCurriculum(values.curriculum_code));
        }
        if (values.level1_code) {
          dispatch(filterLevel1(values.level1_code));
        }
        if (values.level2_code) {
          dispatch(filterLevel2(values.level2_code));
        }
        if (values.level3_code) {
          dispatch(filterLevel3(values.level3_code));
        }
        if (values.level4_code) {
          dispatch(filterLevel4(values.level4_code));
        }
        if (values.created_by) {
          dispatch(filterCreatedBy(values.created_by));
        }
        if (values.question_name) {
          dispatch(filterQuestionName(values.question_name));
        }
      }
    },
  });

  const lastSortOrder = useMemo(() => {
    if (sortOrder) return sortOrder;

    return maxBy(skillCheckSelected?.children, 'sort_order')?.sort_order ?? 0;
  }, [skillCheckSelected, sortOrder]);

  const selectedQuestionCanAdd: Types.QuestionProps[] = useMemo(() => {
    if (selectedQuestion[0] && skillCheckSelected) {
      return differenceBy(selectedQuestion, skillCheckSelected.children, 'question_code');
    }
    return [];
  }, [selectedQuestion, skillCheckSelected]);

  const questionSearchCanAdd: Types.TreeTraining[] = useMemo(() => {
    if (dataList[0] && skillCheckSelected) {
      return differenceBy(dataList, skillCheckSelected.children, 'question_code');
    }
    return [];
  }, [dataList, skillCheckSelected]);

  const handleLinkQuestion = async (type?: 'all') => {
    dispatch(startLoading());
    if (type === 'all') {
      const resultActions = await Promise.all(
        questionSearchCanAdd.map((item, index) =>
          dispatch(
            createLinkSkillCheckAssignQuestion({
              item: {
                company_id: item.company_id,
                skill_check_code: skillCheckSelected?.skill_check_code,
                question_code: item.question_code,
                createdat: new Date(),
                createdby: userInfo?.login_id,
                sort_order: lastSortOrder + index + 1,
              },
            })
          )
        )
      );
      await dispatch(
        updateSkillCheck({
          id: skillCheckSelected?.i_id!,
          data: {
            item: {
              probs_count:
                (skillCheckSelected?.children.length || 0) +
                resultActions.filter((r) => createLinkSkillCheckAssignQuestion.fulfilled.match(r))
                  .length,
              setting_score: questionSearchCanAdd.reduce((result, item) => {
                return result + item.score!;
              }, skillCheckSelected?.score || 0),
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );

      setSortOrder(lastSortOrder + questionSearchCanAdd.length);
    } else {
      const resultActions = await Promise.all(
        selectedQuestionCanAdd.map((item, index) =>
          dispatch(
            createLinkSkillCheckAssignQuestion({
              item: {
                company_id: userInfo?.company_id,
                skill_check_code: skillCheckSelected?.skill_check_code,
                question_code: item.question_code,
                createdat: new Date(),
                createdby: userInfo?.login_id,
                sort_order: lastSortOrder + index + 1,
              },
            })
          )
        )
      );
      await dispatch(
        updateSkillCheck({
          id: skillCheckSelected?.i_id!,
          data: {
            item: {
              probs_count:
                (skillCheckSelected?.children.length || 0) +
                resultActions.filter((r) => createLinkSkillCheckAssignQuestion.fulfilled.match(r))
                  .length,
              setting_score: selectedQuestion.reduce((result, item) => {
                return result + item.score!;
              }, skillCheckSelected?.score || 0),
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      setSortOrder(lastSortOrder + selectedQuestionCanAdd.length);
    }
    setSortOrder(undefined);
    setSelectedQuestion([]);
    setSkillCheckSelected(undefined);
    fetchDataSkillCheck();
    formik.handleSubmit();
    dispatch(stopLoading());
  };

  const handleDeleteQuestions = async () => {
    if (selectedQuestion.length) {
      dispatch(startLoading());
      const deleteResult = await Promise.all(
        selectedQuestion.map((item) => dispatch(deleteQuestion({ id: item.i_id })))
      );
      if (deleteQuestion.fulfilled.match(deleteResult[deleteResult.length - 1])) {
        setShowCompleteModal(true);
      } else {
        setActionModalState({
          subTitle: '削除に失敗しました',
          description: <p className="text-content">再度お試しください。</p>,
        });
        setShowActionErrorModal(true);
      }
      formik.handleSubmit();
      dispatch(stopLoading());
    }
  };

  const handleSelectCurriculumName = (value: string) => {
    setLvl1Option([]);
    setLvl2Option([]);
    setLvl3Option([]);
    setLvl4Option([]);
    formik.setFieldValue('level1_code', undefined);
    formik.setFieldValue('level2_code', undefined);
    formik.setFieldValue('level3_code', undefined);
    formik.setFieldValue('level4_code', undefined);

    const newDataPrimitive = memoizedGetFlatDataFromTree({
      treeData: curricullum.find((curr) => curr.code === value),
    });
    setDataPrimitive(newDataPrimitive);

    for (let index = 0; index < newDataPrimitive.length; index++) {
      const treeData: Types.TreeItem<Types.CurriculumItemType> = newDataPrimitive[index];
      const newOption = {
        code: treeData.node?.code!,
        company_id: treeData.node?.company_id!,
        i_id: treeData.node?.i_id!,
        name: treeData.node?.name!,
      };
      switch (treeData.columnIndex) {
        case 1:
          setLvl1Option((prevState) => [...prevState, newOption]);
          break;
        case 2:
          setLvl2Option((prevState) => [...prevState, newOption]);
          break;
        case 3:
          setLvl3Option((prevState) => [...prevState, newOption]);
          break;
        case 4:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  const handleSelectCurriculumLevel = (level: number, value: string) => {
    if (!formik.values.curriculum_code) return;
    switch (level) {
      case 1:
        formik.setFieldValue('level2_code', undefined);
        formik.setFieldValue('level3_code', undefined);
        formik.setFieldValue('level4_code', undefined);
        setLvl2Option([]);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 2:
        formik.setFieldValue('level3_code', undefined);
        formik.setFieldValue('level4_code', undefined);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 3:
        formik.setFieldValue('level4_code', undefined);
        setLvl4Option([]);
        break;
      case 4:
        break;
      default:
        break;
    }

    const children = dataPrimitive.find((d) => d.node?.code === value)?.node?.children;
    if (!children) return;

    for (let index = 0; index < children.length; index++) {
      const node: Types.TreeItem<Types.CurriculumItemType> = children[index];
      const newOption = {
        code: node.code || '',
        company_id: node.company_id || '',
        i_id: node.i_id || '',
        name: node.name || '',
      };
      switch (level) {
        case 1:
          setLvl2Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl3Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
                if (node2.children) {
                  for (let index3 = 0; index3 < node2.children.length; index3++) {
                    const node4: Types.TreeItem<Types.CurriculumItemType> = node2.children[index];
                    if (node4) {
                      setLvl4Option((prevState) => [
                        ...prevState,
                        {
                          code: node4.code || '',
                          company_id: node4.company_id || '',
                          i_id: node4.i_id || '',
                          name: node4.name || '',
                        },
                      ]);
                    }
                  }
                }
              }
            }
          }
          break;
        case 2:
          setLvl3Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl4Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
              }
            }
          }
          break;
        case 3:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getCreatorQuestionOption({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCurriculumQuestionOption({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 2,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 3,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 4,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getListQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'code', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
    return () => {
      setSelectedQuestion([]);
      dispatch(resetInitialState());
    };
  }, [dispatch, setSelectedQuestion, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getListQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, openModalCreateEditQuestion]);

  return (
    <StyledSearch pageYOffset={pageYOffset}>
      <FormikProvider value={formik}>
        <Form
          labelCol={{
            flex: '27%',
          }}
          colon={false}
          className="form"
        >
          <h4 className="title">設問検索</h4>
          <p className="sub-title">条件を絞って設問を検索できます。</p>
          <Form.Item
            name="curriculum_code"
            label={<span className="label">{messages['M-21-25']}</span>}
            className="form-input"
          >
            <SelectField
              allowClear
              className="select-input"
              name="curriculum_code"
              onChange={handleSelectCurriculumName}
              onClear={() => handleSelectCurriculumName('')}
            >
              {curriculumOption.map((curr, index) => (
                <Option key={index} value={curr.code}>
                  {curr.name}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="level1_code"
            label={<span className="label">{messages['M-21-26']}</span>}
            className="form-input"
          >
            <SelectField
              allowClear
              name="level1_code"
              className="select-input"
              onChange={(value: string) => handleSelectCurriculumLevel(1, value)}
              onClear={() => handleSelectCurriculumLevel(1, '')}
            >
              {(formik.values.curriculum_code ? lvl1Option : level1Option).map((lvl, index) => (
                <Option key={index} value={lvl.code}>
                  {lvl.name || '（空白）'}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="level2_code"
            label={<span className="label">{messages['M-21-27']}</span>}
            className="form-input"
          >
            <SelectField
              allowClear
              name="level2_code"
              className="select-input"
              onChange={(value: string) => handleSelectCurriculumLevel(2, value)}
              onClear={() => handleSelectCurriculumLevel(2, '')}
            >
              {(formik.values.curriculum_code ? lvl2Option : level2Option).map((lvl, index) => (
                <Option key={index} value={lvl.code}>
                  {lvl.name || '（空白）'}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="level3_code"
            label={<span className="label">{messages['M-21-28']}</span>}
            className="form-input"
          >
            <SelectField
              allowClear
              name="level3_code"
              className="select-input"
              onChange={(value: string) => handleSelectCurriculumLevel(3, value)}
              onClear={() => handleSelectCurriculumLevel(3, '')}
            >
              {(formik.values.curriculum_code ? lvl3Option : level3Option).map((lvl, index) => (
                <Option key={index} value={lvl.code}>
                  {lvl.name || '（空白）'}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="level4_code"
            label={<span className="label">{messages['M-21-29']}</span>}
            className="form-input"
          >
            <SelectField
              allowClear
              name="level4_code"
              className="select-input"
              onChange={(value: string) => handleSelectCurriculumLevel(4, value)}
              onClear={() => handleSelectCurriculumLevel(4, '')}
            >
              {(formik.values.curriculum_code ? lvl4Option : level4Option).map((lvl, index) => (
                <Option key={index} value={lvl.code}>
                  {lvl.name || '（空白）'}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="created_by"
            label={<span className="label">{messages['M-21-30']}</span>}
            className="form-input"
          >
            <SelectField allowClear className="select-input" name="created_by">
              {unionBy(creatorOption, 'creator').map(({ creator }, index) => (
                <Option key={index} value={creator}>
                  {creator}
                </Option>
              ))}
            </SelectField>
          </Form.Item>
          <Form.Item
            name="question_name"
            label={<span className="label">{messages['M-21-31']}</span>}
            className="form-input"
          >
            <Input className="select-input" name="question_name" />
          </Form.Item>
          <div className="wrap-center">
            <SubmitButton className="btn-search" loading={false}>
              <SearchOutlined className="icon-search" />
              {messages['M-21-36']}
            </SubmitButton>
            <ResetButton className="label-reset">{messages['M-21-37']}</ResetButton>
          </div>
        </Form>
        <div className="result-search">
          <div className="left-side">
            <Button
              active={
                !!dataList?.length &&
                !!skillCheckSelected &&
                !!questionSearchCanAdd.length &&
                !selectedQuestion.length
              }
              disabled={
                !dataList?.length ||
                !skillCheckSelected ||
                !questionSearchCanAdd.length ||
                !!selectedQuestion.length
              }
              onClick={() => handleLinkQuestion('all')}
            >
              <DoubleLeftOutlined className="label-icon" />
            </Button>
            <Button
              active={
                !!selectedQuestion.length && !!skillCheckSelected && !!selectedQuestionCanAdd.length
              }
              disabled={
                !selectedQuestion.length || !skillCheckSelected || !selectedQuestionCanAdd.length
              }
              onClick={() => handleLinkQuestion()}
            >
              <LeftOutlined className="label-icon" />
            </Button>
            <Popover
              overlayClassName="tooltip-QA"
              content={
                <StylesContent>
                  <p className="text-content">
                    全て追加：
                    <span className="btn">
                      <DoubleLeftOutlined className="label-icon" />
                    </span>
                    をクリック
                  </p>
                  <p className="text-content">
                    選択した設問のみ追加：
                    <span className="btn">
                      <LeftOutlined className="label-icon" />
                    </span>
                    をクリック
                  </p>
                </StylesContent>
              }
              overlayStyle={{
                width: 300,
              }}
              trigger="click"
              placement="bottom"
            >
              <QuestionCircleOutlined className="imageQA" />
            </Popover>
          </div>
          <div className="right-side">
            <div className="result">
              {dataList?.length ? (
                dataList.map((item, index) => (
                  <Item
                    key={index}
                    onSubmit={formik.handleSubmit}
                    selectedQuestion={selectedQuestion}
                    skillCheckSelected={skillCheckSelected}
                    setSelectedQuestion={setSelectedQuestion}
                    item={{
                      i_id: item.question_code_i_id || '',
                      question_code: item.question_code,
                      name: item.question_name,
                      score: item.score,
                    }}
                  />
                ))
              ) : (
                <p className="text-result">{messages['M-21-40']}</p>
              )}
            </div>
            <div className="footer">
              <Button
                active
                className="btn"
                onClick={() =>
                  setOpenModalCreateEditQuestion({
                    visible: true,
                    type: 'create',
                    onSubmit: formik.handleSubmit,
                  })
                }
              >
                <PlusOutlined className="icon" />
                {messages['M-21-38']}
              </Button>
              <Button
                active={!!selectedQuestion.length}
                className="btn"
                onClick={() => setOpenModalConfirmDeleteItem(true)}
              >
                <DeleteOutlined className="icon" />
                {messages['M-21-39']}
              </Button>
            </div>
          </div>
        </div>
      </FormikProvider>

      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedDeleteModal visible={showCompleteModal} setVisible={setShowCompleteModal} />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={() => {
          if (!selectedQuestion.length) return;
          handleDeleteQuestions();
        }}
        onCancel={() => {
          setSelectedQuestion([]);
        }}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </StyledSearch>
  );
};

export default MenuRightSkillCheck;
