import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Table, Select as SelectAntd, Popconfirm, Tooltip } from 'antd';
import { generatePath, useNavigate } from 'react-router-dom';
import { Select, SubmitButton, Form } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CloudUploadOutlined,
  FileTextOutlined,
  DeleteOutlined,
  DownOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { HEADER_EMPLOYEE_CSV, HEADER_INTERVIEW_CSV } from 'constant/header.export.constant';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import InterviewUsersStyled, { PopoverStyled } from './styles';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import InterviewUserPDF from './PDF/InterviewUserPDF';
import { interviewUsersSelector } from './selectors';
import ConfirmSendURL from './Modal/ConfirmSendURL';
import UploadCSV from 'components/Modal/UploadCSV';
import { Header, SelectField } from 'components';
import exportPDF from 'libs/utils/exportPdf';
import { routes } from 'navigations/routes';
import * as Types from 'types';
import {
  removeAffiliationInterview,
  getDataSelectInterviewUser,
  getDataInterviewExport,
  getDataSelectHireDate,
  getDataInterviewUser,
  getSelectAffiliation,
  deleteInterviewUser,
  getSelectPosition,
  getDataCompany,
} from './thunk';

const { Option } = Select;

const PER_PAGE = 10;

const InterviewUsers: React.FC = () => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.InterviewUser.ResponseType[]>([]);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [openPopupImport, setOpenPopupImport] = useState<boolean>(false);
  const [openPopupCreate, setOpenPopupCreate] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [idAffiliation, setIdAffiliation] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [itemIdDelete, setItemIdDelete] = useState<string>('');
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const pdfRef = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo, dataCompany } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    total,
    dataInterviewUser,
    dataSelectPosition,
    dataSelectDepartment,
    dataSelectInteviewUser,
    dataSelectHireDate,
    countUserInterview,
  } = useSelector(interviewUsersSelector);

  const { permissionNumber } = usePermission();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const isDisableAddUser = useMemo(() => {
    return countUserInterview >= 3 && dataCompany?.plan_id === 'standard';
  }, [countUserInterview, dataCompany?.plan_id]);

  const columns: ColumnsType<Types.InterviewUser.ResponseType> = [
    {
      title: '管理番号',
      dataIndex: 'manage_code',
      key: 'manage_code',
      width: '8%',
      align: 'left',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '12%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '10%',
      ellipsis: true,
      align: 'left',
    },
    {
      title: '募集所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      ellipsis: true,
      align: 'left',
      render: (title: string) => <span>{title.replace(/^\d+ /, '')}</span>,
    },
    {
      title: '募集役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '10%',
      ellipsis: true,
      align: 'left',
      render: (title: string) => <span>{title.replace(/^\d+ /, '')}</span>,
    },
    {
      title: '年齢',
      dataIndex: 'birthday',
      key: 'birthday',
      width: '7%',
      align: 'left',
      render: (text: string) => text && `${dayjs().diff(text, 'years', false)}歳`,
    },
    {
      title: '職歴',
      dataIndex: 'work_history',
      key: 'work_history',
      width: '5%',
      align: 'left',
      render: (text, _, index) =>
        text && (
          <Popconfirm
            trigger={['click']}
            title={
              <p style={{ color: '#000000', fontSize: 13, margin: 0, padding: '0 12px' }}>{text}</p>
            }
            color="#ffffff"
            onVisibleChange={(v) =>
              setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
            }
            showCancel={false}
            okButtonProps={{
              style: {
                display: 'none',
              },
            }}
            icon={null}
          >
            <PopoverStyled visiblePopover={visibleTooltip}>
              <FileTextOutlined
                className="icon"
                style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
              />
            </PopoverStyled>
          </Popconfirm>
        ),
    },
    {
      title: '採用予定年月',
      dataIndex: 'hire_date',
      key: 'hire_date',
      width: '8%',
      align: 'left',
      render: (text: string) => text && `${dayjs(text).format('YYYY/MM')}`,
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
      key: 'email',
      width: '10%',
      align: 'left',
      ellipsis: true,
    },
    {
      title: (
        <span>
          スキルチェック
          <br />
          実施日
        </span>
      ),
      dataIndex: 'implementation_date',
      key: 'implementation_date',
      width: '10%',
      align: 'left',
      ellipsis: true,
    },
    {
      title: '編集',
      dataIndex: 'operation',
      width: '5%',
      align: 'left',
      render: (_, item) => {
        return (
          <FormOutlined
            className="icon"
            onClick={() =>
              permissionNumber !== 1 &&
              navigate(
                generatePath(routes.EditInterview.path, {
                  id: item.i_id,
                })
              )
            }
          />
        );
      },
    },
    {
      title: '削除',
      dataIndex: 'operation',
      width: '5%',
      align: 'left',
      render: (_, item) => (
        <DeleteOutlined
          className="icon"
          onClick={() => {
            setVisiblePopupConfirmDelete(true);
            setItemIdDelete(item.i_id);
            setIdAffiliation(item?.item_ref?.position_code.i_id);
          }}
        />
      ),
    },
  ];

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const fetchInterviewUser = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataInterviewUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'manage_code',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            include_lookups: true,
            include_item_ref: true,
            use_display_id: true,
            omit_total_items: false,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, page, userInfo]);

  const handleImportCSV = (file: File) => {
    // parse(file, {
    //   complete: async ({ data }: ParseResult<string>) => {
    //     const resultsDataCSV: Array<EmployeeExportType> = data
    //       .map((item) =>
    //         Object.fromEntries(HEADER_EMPLOYEE_CSV.map(({ key }, i) => [key, item[i]]))
    //       )
    //       .splice(1) as unknown as Array<EmployeeExportType>;
    //     await Promise.all(
    //       resultsDataCSV.map((item) =>
    //         Promise.all([
    //           dispatch(
    //             createEmployee({
    //               item: {
    //                 ...item,
    //                 user_type: 'member',
    //                 login_id: item.email,
    //                 company_id: userInfo?.company_id,
    //               },
    //             })
    //           ),
    //           dispatch(
    //             createAffiliationRole({
    //               item: {
    //                 company_id: userInfo?.company_id,
    //                 affiliation_id: item.department_name,
    //                 login_id: item.email,
    //                 sort_order: 1,
    //                 main_role: 'main',
    //               },
    //             })
    //           ),
    //         ])
    //       )
    //     );
    //     fetchEmployee();
    //   },
    // });
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataInterviewExport({
          conditions: [
            {
              id: 'company_id',
              search_value: selectedRow.map((row) => row.company_id),
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
        })
      );
      if (getDataInterviewExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          company_id: item.company_id,
          manage_code: item.manage_code,
          department_name: item.department_name,
          name: item.name,
          kana: item.kana,
          birthday: item.birthday,
          hire_date: item.hire_history,
          email: item.email,
          implementation_date: item.implementation_date,
          work_history: item.work_history,
          position_code: item.position_code,
        }));

        const csvString = [
          HEADER_INTERVIEW_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '面接ユーザー.csv');
      }
    }

    if (value === 'pdf') {
      exportPDF(pdfRef, '面接ユーザーマスタ');
    }

    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleSendLoginUrl = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setOpenConfirm(true);
    }
  };

  const handleSubmitDelete = async () => {
    dispatch(startLoading());
    const resultAction = await Promise.all([
      dispatch(
        deleteInterviewUser({
          id: itemIdDelete,
        })
      ),
      dispatch(
        removeAffiliationInterview({
          id: idAffiliation,
        })
      ),
    ]);
    if (deleteInterviewUser.fulfilled.match(resultAction[0])) {
      setVisiblePopupConfirmDelete(false);
      fetchInterviewUser();
    }
    dispatch(stopLoading());
  };

  const handleResetForm = () => {
    formik.resetForm();
    fetchInterviewUser();
  };

  const component = useMemo(() => {
    return (
      <div
        ref={pdfRef}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <InterviewUserPDF
          dataInterviewUser={selectedRow}
          page={page}
          perPage={perPage}
          total={selectedRow.length || 0}
        />
      </div>
    );
  }, [selectedRow, page, perPage]);

  const formik = useFormik<Types.InterviewUsersFormSearchFormik>({
    initialValues: {
      manage_code: '',
      name: '',
      department_name: '',
      position_code: '',
      hire_date: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      await dispatch(
        getDataInterviewUser({
          conditions,
          sort_fields: [
            {
              id: 'manage_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
          omit_total_items: false,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSelectInterviewUser({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAffiliation({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectHireDate({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataCompany({
              conditions: [
                {
                  id: 'id',
                  search_value: [userInfo.company_id],
                  exact_match: true,
                },
              ],

              page: 1,
              per_page: 1,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(fetchInterviewUser, [fetchInterviewUser]);

  return (
    <>
      <>{component}</>
      <Header title={headerTitle} />
      <InterviewUsersStyled collapsedMenu={collapsedMenu} isEmptyData={!dataInterviewUser.length}>
        <p className="text-note">
          面接ユーザーの作成・管理を行う画面です。面接時にスキルチェックを行うユーザーを登録します。
          <br />
          ユーザー登録後に実施していただくスキルチェックを設定します。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" colon={false}>
            <div className="form-search">
              <Form.Item
                name="manage_code"
                className="item"
                label={<span className="text-label">管理番号</span>}
              >
                <SelectField
                  name="manage_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectInteviewUser?.map(({ code }, i) => (
                    <Option value={code} key={i}>
                      {code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectInteviewUser?.map(({ name }, i) => (
                    <Option value={name} key={i}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">募集所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectDepartment?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">募集役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition?.map(({ i_id, name }) => (
                    <Option value={i_id} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="hire_date"
                className="item"
                label={<span className="text-label">採用予定年月</span>}
              >
                <SelectField
                  name="hire_date"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectHireDate?.map(({ hire_date }, index) => (
                    <Option value={hire_date} key={index}>
                      {dayjs(hire_date).format('YYYY/MM')}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={handleResetForm}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <Tooltip
            title={
              <span
                style={{
                  fontSize: 13,
                  color: '#424242',
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    color: '#F6AC00',
                    fontSize: 15,
                  }}
                />
                &nbsp; 面接ユーザーの作成上限です
              </span>
            }
            color="white"
            trigger={['hover']}
            onOpenChange={(open) => {
              if (open && isDisableAddUser) {
                setOpenPopupImport(true);
                return;
              }
              setOpenPopupImport(false);
            }}
            open={openPopupImport}
            placement="bottomLeft"
            overlayInnerStyle={{
              padding: '12px 28px',
              fontSize: 13,
              width: 261,
            }}
            overlayStyle={{
              top: 309,
              width: 300,
            }}
          >
            <Button
              disabled={isDisableAddUser}
              className={`btn ${
                permissionNumber === 1 || isDisableAddUser ? 'disabled' : 'btn-active'
              }`}
              icon={<CloudUploadOutlined className="icon" />}
              onClick={() => setVisiblePopupUploadCSV(true)}
            >
              インポート
            </Button>
          </Tooltip>

          <Tooltip
            title={
              <span
                style={{
                  fontSize: 13,
                  color: '#424242',
                }}
              >
                <ExclamationCircleOutlined
                  style={{
                    color: '#F6AC00',
                    fontSize: 15,
                  }}
                />
                &nbsp; 面接ユーザーの作成上限です
              </span>
            }
            color="white"
            trigger={['hover']}
            onOpenChange={(open) => {
              if (open && isDisableAddUser) {
                setOpenPopupCreate(true);
                return;
              }
              setOpenPopupCreate(false);
            }}
            open={openPopupCreate}
            placement="bottomLeft"
            overlayInnerStyle={{
              padding: '12px 28px',
              fontSize: 13,
              width: 261,
            }}
            overlayStyle={{
              top: 309,
              width: 300,
            }}
          >
            <Button
              disabled={permissionNumber === 1 || isDisableAddUser}
              className={`btn ${
                permissionNumber === 1 || isDisableAddUser ? 'disabled' : 'btn-active'
              }`}
              icon={<PlusOutlined className="icon" />}
              onClick={() =>
                navigate(generatePath(routes.CreateInterviewUser.path, { entity: 'receiving' }))
              }
            >
              新規登録
            </Button>
          </Tooltip>
        </div>
        <div
          className={
            dataInterviewUser.length > 0
              ? 'text-count-general'
              : 'text-count-general text-count-no-data'
          }
        >
          {dataInterviewUser.length ? (page - 1) * perPage + 1 : null}{' '}
          {dataInterviewUser.length ? '-' : ''}
          {page * perPage > total ? total : page * perPage}
          <span className="text-static"></span> / {total}
          <span className="text-static">件</span>
          <div className="page-select">
            <div className="label">
              <span>表示件数</span>：
            </div>
            <SelectAntd value={perPage} onSelect={handleSelectChange}>
              {[10, 20, 50, 100].map((value, index) => (
                <Option key={index} value={value} label={value}>
                  {value}
                </Option>
              ))}
            </SelectAntd>
          </div>
        </div>
        <Table
          rowKey="index"
          className="table"
          dataSource={dataInterviewUser.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows: Types.InterviewUser.ResponseType[]) =>
              setSelectedRow(selectedRows),
            getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
          }}
          pagination={{
            pageSize: perPage,
            total,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
              <Button className="btn btn-active ml-5" onClick={handleSendLoginUrl}>
                ログインURL送信
              </Button>
            </div>
            <Button
              className="btn btn-outline"
              onClick={() => navigate(routes.InterviewBatchSetting.path)}
            >
              スキルチェック一括設定
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <UploadCSV
          onSubmit={handleImportCSV}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={<>対象のユーザーを選択し、再度実行してください。</>}
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="ユーザーの削除を実行します"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={() => setVisiblePopupConfirmDelete(false)}
        />
        <ConfirmSendURL isOpen={openConfirm} setIsOpen={setOpenConfirm} selectedRow={selectedRow} />
      </InterviewUsersStyled>
    </>
  );
};

export default InterviewUsers;
