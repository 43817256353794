import React, { useMemo } from 'react';

import { Col, Row, Select } from 'antd';
import { useSelector } from 'react-redux';

import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

import { myChartDashboardSelector } from 'pages/Reports/MyChart/selectors';
import { settingSelector } from 'containers/AppSettings/selectors';
import { formatNumber } from 'libs/utils/format';
import SurfaceTable from '../SurfaceTable';
import TableLevels from '../LevelsTable';
import { Header } from 'components';
import Wrapper from './styles';

const { Option } = Select;

interface Props {
  valueRequired: string;
  curriculum?: string;
  level1Code?: string;
  level2Code?: string;
  level3Code?: string;
  level4Code?: string;
}

const FilePDFExport: React.FC<Props> = ({
  valueRequired,
  curriculum,
  level1Code,
  level2Code,
  level3Code,
  level4Code,
}) => {
  const { headerTitle } = useSelector(settingSelector);
  const {
    dataTabUserCurriculum,
    curriculumNameSelect,
    dataTabAllCurriculum,
    selectLevel1,
    selectLevel2,
    selectLevel3,
    selectLevel4,
  } = useSelector(myChartDashboardSelector);

  const dataColumLevels: {
    title: string;
    key: string;
    code: 'level1_code' | 'level2_code' | 'level3_code' | 'level4_code' | 'question_code';
  } = useMemo(() => {
    if (level4Code) {
      return {
        title: '設問',
        key: 'question_name',
        code: 'question_code',
      };
    }

    if (level3Code) {
      return {
        title: '第4階層',
        key: 'level4_name',
        code: 'level4_code',
      };
    }

    if (level2Code) {
      return {
        title: '第3階層',
        key: 'level3_name',
        code: 'level3_code',
      };
    }

    if (level1Code) {
      return {
        title: '第2階層',
        key: 'level2_name',
        code: 'level2_code',
      };
    }

    return {
      title: '第1階層',
      key: 'level1_name',
      code: 'level1_code',
    };
  }, [level1Code, level2Code, level3Code, level4Code]);

  return (
    <Wrapper>
      <Header title={headerTitle} exportPdf />
      <div className="container">
        <div className="skill-check-results">
          <p className="title">トレーニング実施結果詳細</p>
        </div>
        <div className="wrap-body">
          <div className="group-select">
            <div className="select curriculum-type">
              <p className="label">カリキュラム種類</p>
              <Select value={valueRequired} className="item-select" allowClear>
                <Option value="ALL">ALL</Option>
                <Option value="0">カリキュラム</Option>
                <Option value="1">必修カリキュラム</Option>
              </Select>
            </div>
            <div className="select curriculum-selection">
              <p className="label">カリキュラム選択</p>
              <Select className="item-select" placeholder="指定なし" value={curriculum} allowClear>
                {curriculumNameSelect?.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select question">
              <p className="label">第１階層</p>
              <Select
                disabled={!curriculum}
                className="item-select"
                value={level1Code}
                placeholder="指定なし"
                allowClear
              >
                {selectLevel1?.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name || '（空白）'}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select question">
              <p className="label">第２階層</p>
              <Select
                disabled={!level1Code}
                value={level2Code}
                className="item-select"
                placeholder="指定なし"
                allowClear
              >
                {selectLevel2?.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name || '（空白）'}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select question">
              <p className="label">第３階層</p>
              <Select
                disabled={!level2Code}
                value={level3Code}
                className="item-select"
                placeholder="指定なし"
                allowClear
              >
                {selectLevel3?.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name || '（空白）'}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select question">
              <p className="label">第４階層</p>
              <Select
                disabled={!level3Code}
                value={level4Code}
                className="item-select"
                placeholder="指定なし"
                allowClear
              >
                {selectLevel4?.map((item, index) => (
                  <Option value={item.code} key={index}>
                    {item.name || '（空白）'}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="select answer-result">
              <p className="label">解答結果</p>
              <Select
                disabled={!level4Code}
                className="item-select"
                placeholder="指定なし"
                allowClear
              >
                <Option value="0">不正解</Option>
                <Option value="1">正解</Option>
              </Select>
            </div>
            <div className="select score">
              <p className="label">スコア</p>
              <Select
                disabled={!level4Code}
                className="item-select"
                placeholder="指定なし"
                allowClear
              >
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
              </Select>
            </div>
          </div>
          <Row className="table-result" gutter={[5, 5]}>
            <Col className="item-table" span={8}>
              <p className="table-label">実施結果サマリ</p>
              <Row className="row-label">
                <Col className="gutter-row" span={8}>
                  正解進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  未実施設問数
                </Col>
              </Row>
              <Row className="row-number">
                <Col className="gutter-row" span={8}>
                  {formatNumber(dataTabUserCurriculum?.accuracy_rate)}
                  <small>%</small>
                </Col>
                <Col className="gutter-row" span={8}>
                  {formatNumber(dataTabUserCurriculum?.progress_rate)}
                  <small>%</small>
                </Col>
                <Col className="gutter-row" span={8}>
                  {(dataTabUserCurriculum?.question_count || 0) -
                    (dataTabUserCurriculum?.responses_num || 0)}
                </Col>
              </Row>
            </Col>
            <Col className="item-table" span={8}>
              <p className="table-label">ユーザー平均 実施結果サマリ</p>
              <Row className="row-label">
                <Col className="gutter-row" span={8}>
                  正解進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  未実施設問数
                </Col>
              </Row>
              <Row className="row-number">
                <Col className="gutter-row" span={8}>
                  {formatNumber(dataTabAllCurriculum?.accuracy_rate)}
                  <small>%</small>
                </Col>
                <Col className="gutter-row" span={8}>
                  {formatNumber(dataTabAllCurriculum?.progress_rate)}
                  <small>%</small>
                </Col>

                <Col className="gutter-row" span={8}>
                  {(dataTabAllCurriculum?.question_count || 0) -
                    (dataTabAllCurriculum?.responses_num || 0)}
                </Col>
              </Row>
            </Col>
            <Col className="item-table" span={8}>
              <p className="table-label">対ユーザー平均 比較</p>
              <Row className="row-label">
                <Col className="gutter-row" span={8}>
                  正解進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  進捗率
                </Col>
                <Col className="gutter-row" span={8}>
                  未実施設問数
                </Col>
              </Row>
              <Row className="row-number">
                <Col className="gutter-row" span={8}>
                  <div className="item-column">
                    {Number(dataTabUserCurriculum?.accuracy_rate) -
                      Number(dataTabAllCurriculum?.accuracy_rate) <
                    0 ? (
                      <ArrowDownOutlined className="icon-down" />
                    ) : (
                      <ArrowUpOutlined className="icon-up" />
                    )}
                    <p className="label-number">
                      {formatNumber(
                        Math.abs(
                          Number(dataTabUserCurriculum?.accuracy_rate) -
                            Number(dataTabAllCurriculum?.accuracy_rate)
                        )
                      )}
                    </p>
                    <small>%</small>
                  </div>
                </Col>
                <Col className="gutter-row" span={8}>
                  <div className="item-column">
                    {Number(dataTabUserCurriculum?.progress_rate) -
                      Number(dataTabAllCurriculum?.progress_rate) <
                    0 ? (
                      <ArrowDownOutlined className="icon-down" />
                    ) : (
                      <ArrowUpOutlined className="icon-up" />
                    )}
                    <p className="label-number">
                      {formatNumber(
                        Math.abs(
                          Number(dataTabUserCurriculum?.progress_rate) -
                            Number(dataTabAllCurriculum?.progress_rate)
                        )
                      )}
                    </p>
                    <small>%</small>
                  </div>
                </Col>

                <Col className="gutter-row" span={6}>
                  <div className="item-column">
                    {Number(dataTabUserCurriculum?.acquisition_score) -
                      Number(dataTabAllCurriculum?.acquisition_score) <
                    0 ? (
                      <ArrowDownOutlined className="icon-down" />
                    ) : (
                      <ArrowUpOutlined className="icon-up" />
                    )}
                    <p className="label-number">
                      {formatNumber(
                        Math.abs(
                          Number(
                            (dataTabUserCurriculum?.question_count || 0) -
                              (dataTabUserCurriculum?.responses_num || 0)
                          ) -
                            Number(
                              (dataTabAllCurriculum?.question_count || 0) -
                                (dataTabAllCurriculum?.responses_num || 0)
                            )
                        )
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {!level4Code ? <TableLevels dataColumLevels={dataColumLevels} /> : <SurfaceTable />}
        </div>
      </div>
    </Wrapper>
  );
};

export default FilePDFExport;
