import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Button, Col, Row, Select } from 'antd';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { HEADER_MY_CHART_DASH_BROARD_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { myChartDashboardSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { formatNumber } from 'libs/utils/format';
import exportPDF from 'libs/utils/exportPdf';
import FilePDFExport from './FilePDFExport';
import RecordTrainingStyled from './styles';
import { ConfirmExport } from '../../Modal';
import SurfaceTable from './SurfaceTable';
import TableLevels from './LevelsTable';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getDataTableCurriculumUserAll,
  getDataTableCurriculumUser,
  getDataSelectCurriculum,
  getCurriculumLevelOption,
  getDataTrainingCurriculum,
} from '../../thunk';

const { Option } = Select;

interface Props {
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const RecordTraining: React.FC<Props> = ({ setTabIndex }) => {
  const [valueRequired, setValueRequired] = useState<string>('ALL');
  const [valueCorrect, setValueCorrect] = useState<string>();
  const [curriculum, setCurriculum] = useState<string>();
  const [valueScore, setValueScore] = useState<string>();
  const [level1Code, setLevel1Code] = useState<string>();
  const [level2Code, setLevel2Code] = useState<string>();
  const [level3Code, setLevel3Code] = useState<string>();
  const [level4Code, setLevel4Code] = useState<string>();

  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const {
    dataReportLevelsCurriculumUser,
    dataTabUserCurriculum,
    curriculumNameSelect,
    dataTabAllCurriculum,
    selectLevel1,
    selectLevel2,
    selectLevel3,
    selectLevel4,
  } = useSelector(myChartDashboardSelector);

  const dataColumLevels: {
    title: string;
    key: string;
    code: 'level1_code' | 'level2_code' | 'level3_code' | 'level4_code' | 'question_code';
  } = useMemo(() => {
    if (level4Code) {
      return {
        title: '設問',
        key: 'question_name',
        code: 'question_code',
      };
    }

    if (level3Code) {
      return {
        title: '第4階層',
        key: 'level4_name',
        code: 'level4_code',
      };
    }

    if (level2Code) {
      return {
        title: '第3階層',
        key: 'level3_name',
        code: 'level3_code',
      };
    }

    if (level1Code) {
      return {
        title: '第2階層',
        key: 'level2_name',
        code: 'level2_code',
      };
    }

    return {
      title: '第1階層',
      key: 'level1_name',
      code: 'level1_code',
    };
  }, [level1Code, level2Code, level3Code, level4Code]);

  const handleButtonExport = () => {
    setVisiblePopupConfirmExportFile(true);
  };

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          valueRequired={valueRequired}
          curriculum={curriculum}
          level1Code={level1Code}
          level2Code={level2Code}
          level3Code={level3Code}
          level4Code={level4Code}
        />
      </div>
    );
  }, [valueRequired, curriculum, level1Code, level2Code, level3Code, level4Code]);

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = dataReportLevelsCurriculumUser?.slice(1).map((item) => ({
        user_name: item.user_name,
        curriculum_name: item.curriculum_name,
        level1_name: item.level1_name,
        level2_name: item.level2_name,
        level3_name: item.level3_name,
        level4_name: item.level4_name,
        correct_answers_num: item.correct_answers_num,
        incorrect_answer: item.incorrect_answer,
        inexperienced: item.inexperienced,
        question_count: item.question_count,
        accuracy_rate: item.accuracy_rate,
        wrong_rate: item.wrong_rate,
        inexperienced_rate: item.inexperienced_rate,
        responses_num: item.responses_num,
        real_accuracy_rate: item.real_accuracy_rate,
        progress_rate: item.progress_rate,
      }));

      const csvString = [
        HEADER_MY_CHART_DASH_BROARD_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'record_training_export.csv');
    } else {
      if (!ref.current) return;

      exportPDF(ref, 'MYカルテ.pdf');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  useEffect(() => {
    if (!userInfo || !curriculum) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            payload: {
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'curriculum_code',
                  search_value: [curriculum],
                },
              ],
              page: 1,
              per_page: 0,
            },
          })
        ),
        dispatch(
          getDataTableCurriculumUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_code',
                search_value: [curriculum],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataTableCurriculumUserAll({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'curriculum_code',
                search_value: [curriculum],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [curriculum, dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataSelectCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(valueRequired && valueRequired !== 'ALL'
              ? [
                  {
                    id: 'required_curriculum',
                    search_value: [valueRequired],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, valueRequired]);

  useEffect(() => {
    if (!userInfo || !curriculum) return;

    const conditions: Types.ConditionsType[] = [
      {
        id: 'company_id',
        search_value: [userInfo.company_id],
      },

      {
        id: 'curriculum_code',
        search_value: [curriculum],
      },
    ];

    if (level1Code) {
      conditions.push({
        id: 'level1_code',
        search_value: [level1Code],
      });
    }
    if (level2Code) {
      conditions.push({
        id: 'level2_code',
        search_value: [level2Code],
      });
    }
    if (level3Code) {
      conditions.push({
        id: 'level3_code',
        search_value: [level3Code],
      });
    }
    if (level4Code) {
      conditions.push({
        id: 'level4_code',
        search_value: [level4Code],
      });
    }
    if (valueCorrect) {
      conditions.push({
        id: 'correct',
        search_value: [valueCorrect],
      });
    }
    if (valueScore) {
      conditions.push({
        id: 'score',
        search_value: [valueScore],
      });
    }

    dispatch(
      getDataTrainingCurriculum({
        conditions,
        page: 1,
        per_page: 0,
        level: dataColumLevels.code,
        sort_fields: [{ id: 'implementation_date', order: 'desc' }],
      })
    );
  }, [
    dispatch,
    userInfo,
    curriculum,
    level1Code,
    level2Code,
    level3Code,
    level4Code,
    dataColumLevels.code,
    valueCorrect,
    valueScore,
  ]);

  useEffect(() => {
    if (!userInfo || !level1Code) return;
    dispatch(
      getCurriculumLevelOption({
        level: 2,
        payload: {
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'level1_code',
              search_value: [level1Code],
            },
          ],
          page: 1,
          per_page: 0,
        },
      })
    );
  }, [dispatch, level1Code, userInfo]);

  useEffect(() => {
    if (!userInfo || !level2Code) return;
    dispatch(
      getCurriculumLevelOption({
        level: 3,
        payload: {
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'level2_code',
              search_value: [level2Code],
            },
          ],
          page: 1,
          per_page: 0,
        },
      })
    );
  }, [dispatch, level2Code, userInfo]);

  useEffect(() => {
    if (!userInfo || !level3Code) return;
    dispatch(
      getCurriculumLevelOption({
        level: 4,
        payload: {
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'level3_code',
              search_value: [level3Code],
            },
          ],
          page: 1,
          per_page: 0,
        },
      })
    );
  }, [dispatch, level3Code, userInfo]);

  useEffect(() => {
    if (curriculumNameSelect[0].code) return;
    setCurriculum(curriculumNameSelect[0].code);
  }, [curriculumNameSelect]);

  return (
    <RecordTrainingStyled collapsedMenu={collapsedMenu}>
      {component}
      <div className="skill-check-results">
        <p className="title">トレーニング実施結果詳細</p>
        <div className="group-btn">
          <button className="btn btn-result" onClick={() => setTabIndex(0)}>
            ダッシュボード
            <RightOutlined className="icon-right" />
          </button>
          <Button className=" btn btn-active" onClick={handleButtonExport}>
            <CloudDownloadOutlined className="icon-cloud" />
            エクスポート
          </Button>
        </div>
      </div>
      <div className="wrap-body">
        <div className="group-select">
          <div className="select curriculum-type">
            <p className="label">カリキュラム種類</p>
            <Select
              onChange={setValueRequired}
              value={valueRequired}
              className="item-select"
              allowClear
            >
              <Option value="ALL">ALL</Option>
              <Option value="0">カリキュラム</Option>
              <Option value="1">必修カリキュラム</Option>
            </Select>
          </div>
          <div className="select curriculum-selection">
            <p className="label">カリキュラム選択</p>
            <Select
              className="item-select"
              placeholder="指定なし"
              value={curriculum}
              onChange={(value) => {
                if (value === curriculum) return;
                setCurriculum(value);
                setLevel1Code(undefined);
                setLevel2Code(undefined);
                setLevel3Code(undefined);
                setLevel4Code(undefined);
                setValueCorrect(undefined);
                setValueScore(undefined);
              }}
              allowClear
            >
              {curriculumNameSelect?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select question">
            <p className="label">第１階層</p>
            <Select
              disabled={!curriculum}
              className="item-select"
              value={level1Code}
              onChange={(value: string) => {
                if (value === level1Code) return;
                setLevel1Code(value);
                setLevel2Code(undefined);
                setLevel3Code(undefined);
                setLevel4Code(undefined);
                setValueCorrect(undefined);
                setValueScore(undefined);
              }}
              placeholder="指定なし"
              allowClear
            >
              {selectLevel1?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name || '（空白）'}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select question">
            <p className="label">第２階層</p>
            <Select
              disabled={!level1Code}
              value={level2Code}
              className="item-select"
              onChange={(value: string) => {
                if (value === level2Code) return;
                setLevel2Code(value);
                setLevel3Code(undefined);
                setLevel4Code(undefined);
                setValueCorrect(undefined);
                setValueScore(undefined);
              }}
              placeholder="指定なし"
              allowClear
            >
              {selectLevel2?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name || '（空白）'}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select question">
            <p className="label">第３階層</p>
            <Select
              disabled={!level2Code}
              value={level3Code}
              className="item-select"
              onChange={(value) => {
                if (value === level3Code) return;
                setLevel3Code(value);
                setLevel4Code(undefined);
                setValueCorrect(undefined);
                setValueScore(undefined);
              }}
              placeholder="指定なし"
              allowClear
            >
              {selectLevel3?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name || '（空白）'}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select question">
            <p className="label">第４階層</p>
            <Select
              disabled={!level3Code}
              value={level4Code}
              className="item-select"
              onChange={(value) => {
                if (value === level4Code) return;
                setLevel4Code(value);
                setValueCorrect(undefined);
                setValueScore(undefined);
              }}
              placeholder="指定なし"
              allowClear
            >
              {selectLevel4?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name || '（空白）'}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select answer-result">
            <p className="label">解答結果</p>
            <Select
              disabled={!level4Code}
              onChange={(value) => setValueCorrect(value)}
              className="item-select"
              placeholder="指定なし"
              allowClear
            >
              <Option value="0">不正解</Option>
              <Option value="1">正解</Option>
            </Select>
          </div>
          <div className="select score">
            <p className="label">スコア</p>
            <Select
              disabled={!level4Code}
              onChange={(value) => setValueScore(value)}
              className="item-select"
              placeholder="指定なし"
              allowClear
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </div>
        </div>
        <Row className="table-result" gutter={[5, 5]}>
          <Col className="item-table" span={8}>
            <p className="table-label">実施結果サマリ</p>
            <Row className="row-label">
              <Col className="gutter-row" span={8}>
                正解進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                未実施設問数
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={8}>
                {formatNumber(dataTabUserCurriculum?.accuracy_rate)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={8}>
                {formatNumber(dataTabUserCurriculum?.progress_rate)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={8}>
                {(dataTabUserCurriculum?.question_count || 0) -
                  (dataTabUserCurriculum?.responses_num || 0)}
              </Col>
            </Row>
          </Col>
          <Col className="item-table" span={8}>
            <p className="table-label">ユーザー平均 実施結果サマリ</p>
            <Row className="row-label">
              <Col className="gutter-row" span={8}>
                正解進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                未実施設問数
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={8}>
                {formatNumber(dataTabAllCurriculum?.accuracy_rate)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={8}>
                {formatNumber(dataTabAllCurriculum?.progress_rate)}
                <small>%</small>
              </Col>

              <Col className="gutter-row" span={8}>
                {(dataTabAllCurriculum?.question_count || 0) -
                  (dataTabAllCurriculum?.responses_num || 0)}
              </Col>
            </Row>
          </Col>
          <Col className="item-table" span={8}>
            <p className="table-label">対ユーザー平均 比較</p>
            <Row className="row-label">
              <Col className="gutter-row" span={8}>
                正解進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                進捗率
              </Col>
              <Col className="gutter-row" span={8}>
                未実施設問数
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={8}>
                <div className="item-column">
                  {Number(dataTabUserCurriculum?.accuracy_rate) -
                    Number(dataTabAllCurriculum?.accuracy_rate) <
                  0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(
                        Number(dataTabUserCurriculum?.accuracy_rate) -
                          Number(dataTabAllCurriculum?.accuracy_rate)
                      )
                    )}
                  </p>
                  <small>%</small>
                </div>
              </Col>
              <Col className="gutter-row" span={8}>
                <div className="item-column">
                  {Number(dataTabUserCurriculum?.progress_rate) -
                    Number(dataTabAllCurriculum?.progress_rate) <
                  0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(
                        Number(dataTabUserCurriculum?.progress_rate) -
                          Number(dataTabAllCurriculum?.progress_rate)
                      )
                    )}
                  </p>
                  <small>%</small>
                </div>
              </Col>

              <Col className="gutter-row" span={6}>
                <div className="item-column">
                  {Number(dataTabUserCurriculum?.acquisition_score) -
                    Number(dataTabAllCurriculum?.acquisition_score) <
                  0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(
                        Number(
                          (dataTabUserCurriculum?.question_count || 0) -
                            (dataTabUserCurriculum?.responses_num || 0)
                        ) -
                          Number(
                            (dataTabAllCurriculum?.question_count || 0) -
                              (dataTabAllCurriculum?.responses_num || 0)
                          )
                      )
                    )}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {!level4Code ? <TableLevels dataColumLevels={dataColumLevels} /> : <SurfaceTable />}
      </div>

      <ConfirmExport
        visible={visiblePopupConfirmExportFile}
        setVisible={setVisiblePopupConfirmExportFile}
        onSubmit={handleExportCSV}
      />
    </RecordTrainingStyled>
  );
};

export default RecordTraining;
