import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { SearchOutlined } from '@ant-design/icons';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Card, List } from 'antd';
import { useSelector } from 'react-redux';

import ConfirmDeleteModalOfficial from './Modal/ConfirmDeleteModalOfficial';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { curriculumSelector } from '../Settings/Curriculum/selectors';
import { settingSelector } from 'containers/AppSettings/selectors';
import { Header, SelectField, SpinLoading } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { officialCurriculumSelector } from './selectors';
import ListCurriculumStyled from './styles';
import { NoImageOfficial } from 'assets';
import * as Types from 'types';
import {
  memoizedGetChildrenItemIDFromTree,
  memoizedGetFlatDataFromTree,
} from 'libs/utils/curriculum/memoized-tree-data-utils';
import {
  deleteUserAssignCurriculum,
  getCurriculum as getCurriculumSetting,
} from '../Settings/Curriculum/thunk';
import {
  getSelectOfficialCurriculumProvider,
  getDataOfficialCurriculumList,
  getSelectOfficialCurriculum,
  createLevelCurriculum,
  deleteLevelCurriculum,
  deleteLinkQuestion,
  createCurriculum,
  deleteCurriculum,
  createQuestion,
  updateCurriculum,
  updateLevelCurriculum,
  getOfficialCurriculumHierarchyList,
} from './thunk';
import {
  CurriculumContentUse,
  ProductInformation,
  CurriculumContent,
  CurriculumNotes,
  CompleteModal,
  UpdateConfirm,
  ConfirmModal,
} from './Modal';
import { get, map } from 'lodash';

const { Option } = Select;

const ListCurriculum: React.FC = () => {
  const [isUpdateConfirmComplete, setIsUpdateConfirmComplete] = useState<boolean>(false);
  const [openProductInformation, setOpenProductInformation] = useState<boolean>(false);
  const [isUpdateCompleteActive, setIsUpdateCompleteActive] = useState<boolean>(false);
  const [openCurriculumContentUse, setOpenCurriculumContentUse] = useState(false);
  const [openCurriculumNotes, setOpenCurriculumNotes] = useState<boolean>(false);
  const [openCurriculumContent, setOpenCurriculumContent] = useState(false);
  const [isUpdateComplete, setIsUpdateComplete] = useState<boolean>(false);
  const [isUpdateConfirm, setIsUpdateConfirm] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [valueStatus, setValueStatus] = useState<string>();
  const [valueUpdate, setValueUpdate] = useState<string>();
  const [perPage, setPerPage] = useState<number>(100);
  const [dataItemDetail, setDataItemDetail] =
    useState<Types.OfficialCurriculumList2.ResponseType>();
  const [visibleModalCurriculumSuspension, setVisibleModalCurriculumSuspension] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { permissionNumber } = usePermission();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    officialCurriculumProviderSelect,
    officialCurriculumHierarchyList,
    dataSelectOfficialCurriculum,
    dataOfficialCurriculumList,
  } = useSelector(officialCurriculumSelector);

  const { curricullum } = useSelector(curriculumSelector);

  const curriculumChange = useMemo(() => {
    return curricullum.find(
      (i) => i.official_curriculum_code === dataItemDetail?.official_curriculum_code
    );
  }, [dataItemDetail, curricullum]);

  const formik = useFormik({
    initialValues: {
      official_curriculum_code: '',
      provider_id: '',
      status: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getDataOfficialCurriculumList({
          conditions:
            values.provider_id || values.official_curriculum_code || values.status
              ? [...conditions]
              : [],
          page: 1,
          per_page: 0,
          company_id: userInfo?.company_id || '',
          sort_fields: [
            { id: 'official_curriculum_code', order: 'asc' },
            { id: 'publish_start_date', order: 'desc' },
          ],
        })
      );
      dispatch(stopLoading());
    },
    onReset: () => {
      fetchDataOfficialCurriculumList();
      setValueStatus(undefined);
      setValueUpdate(undefined);
    },
  });
  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPageCurrent(1);
  };

  const { dataUsed, dataUnUsed, dataUpdate, dataUnUpdate } = useMemo(() => {
    const dataUsedList = dataOfficialCurriculumList.filter((item) => item.curriculum_code);
    const dataUnUsedList = dataOfficialCurriculumList.filter((item) => !item.curriculum_code);
    const dataUpdateList = dataOfficialCurriculumList.filter(
      (item) => item.use_version < item.published_version || item.use_version < item.release_version
    );
    const dataUnUpdateList = dataOfficialCurriculumList.filter(
      (item) =>
        item.use_version >= item.published_version && item.use_version >= item.release_version
    );

    return {
      dataUsed: dataUsedList,
      dataUnUsed: dataUnUsedList,
      dataUpdate: dataUpdateList,
      dataUnUpdate: dataUnUpdateList,
    };
  }, [dataOfficialCurriculumList]);

  const handleCreateCurriculum = async () => {
    dispatch(startLoading());
    await Promise.all(
      officialCurriculumHierarchyList.map(async (curriculum) => {
        const resultActionCreateCurriculum = await dispatch(
          createCurriculum({
            item: {
              company_id: userInfo?.company_id,
              name: dataItemDetail?.curriculum_name,
              description: dataItemDetail?.description,
              sort_order: 1,
              required_curriculum: 0,
              publish: 1,
              probs_count: Number(officialCurriculumHierarchyList[0].probs_count || 0),
              official_curriculum: 1,
              official_curriculum_code: dataItemDetail?.official_curriculum_code,
              fileID: dataItemDetail?.fileID,
              version: dataItemDetail?.published_version,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );
        if (
          curriculum.children &&
          createCurriculum.fulfilled.match(resultActionCreateCurriculum) &&
          resultActionCreateCurriculum.payload.item.code
        ) {
          await Promise.all(
            curriculum.children.map(async (level_1) => {
              const resultActionLevel1 = await dispatch(
                createLevelCurriculum({
                  level: 1,
                  item: {
                    company_id: userInfo?.company_id,
                    name: level_1.name,
                    sort_order: Number(level_1.sort_order),
                    curricullum_code: resultActionCreateCurriculum.payload.item.code,
                    official_level1_code: level_1.code,
                  },
                  return_item_result: true,
                  return_display_id: true,
                  realtime_auto_link: true,
                })
              );
              if (
                level_1.children &&
                createLevelCurriculum.fulfilled.match(resultActionLevel1) &&
                resultActionLevel1.payload.item?.code
              ) {
                await Promise.all(
                  level_1.children.map(async (level_2) => {
                    const resultActionLevel2 = await dispatch(
                      createLevelCurriculum({
                        level: 2,
                        item: {
                          company_id: userInfo?.company_id,
                          name: level_2.name,
                          sort_order: Number(level_2.sort_order),
                          level1_code: resultActionLevel1.payload.item?.code,
                          official_level2_code: level_2.code,
                        },
                        return_item_result: true,
                        return_display_id: true,
                        realtime_auto_link: true,
                      })
                    );
                    if (
                      level_2.children &&
                      createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
                      resultActionLevel2.payload.item?.code
                    ) {
                      await Promise.all(
                        level_2.children.map(async (level_3) => {
                          const resultActionLevel3 = await dispatch(
                            createLevelCurriculum({
                              level: 3,
                              item: {
                                company_id: userInfo?.company_id,
                                name: level_3.name,
                                sort_order: Number(level_3.sort_order),
                                level2_code: resultActionLevel2.payload.item?.code,
                                official_level3_code: level_3.code,
                              },
                              return_item_result: true,
                              return_display_id: true,
                              realtime_auto_link: true,
                            })
                          );
                          if (
                            level_3.children &&
                            createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
                            resultActionLevel3.payload.item?.code
                          ) {
                            await Promise.all(
                              level_3.children.map(async (level_4) => {
                                const resultActionLevel4 = await dispatch(
                                  createLevelCurriculum({
                                    level: 4,
                                    item: {
                                      company_id: userInfo?.company_id,
                                      name: level_4.name,
                                      sort_order: Number(level_4.sort_order),
                                      level3_code: resultActionLevel3.payload.item?.code,
                                      official_level4_code: level_4.code,
                                    },
                                    return_item_result: true,
                                    return_display_id: true,
                                    realtime_auto_link: true,
                                  })
                                );
                                if (
                                  level_4.children &&
                                  createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
                                  resultActionLevel4.payload.item?.code
                                ) {
                                  await Promise.all(
                                    level_4.children.map(async (question) => {
                                      await dispatch(
                                        createQuestion({
                                          item: {
                                            company_id: userInfo?.company_id,
                                            level4_code: resultActionLevel4.payload.item?.code,
                                            code: question.code,
                                            sort_order: Number(question.sort_order),
                                            createdby: userInfo?.login_id,
                                            createdat: new Date(),
                                          },
                                        })
                                      );
                                    })
                                  );
                                }
                              })
                            );
                          }
                        })
                      );
                    }
                  })
                );
              }
            })
          );
        }
      })
    );
    fetchDataOfficialCurriculumList();
    setIsUpdateComplete(true);
    dispatch(stopLoading());
  };

  const handleDeleteCurriculum = useCallback(async () => {
    if (curriculumChange && dataItemDetail) {
      dispatch(startLoading());
      const listChildItemID = memoizedGetChildrenItemIDFromTree({
        treeData: curriculumChange,
      });
      await Promise.all([
        ...listChildItemID.map(async (item: Types.FlatChildrenItemID<Types.CurriculumItemType>) => {
          if (item.columnIndex === 0) {
            const resultAction = await dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
            if (deleteCurriculum.fulfilled.match(resultAction)) {
              dispatch(
                deleteUserAssignCurriculum({
                  conditions: [
                    {
                      id: 'curriculum_code',
                      search_value: [item.code],
                    },
                  ],
                  use_display_id: true,
                })
              );
            }
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
      ]);

      setIsUpdateCompleteActive(true);
      fetchDataOfficialCurriculumList();
      dispatch(stopLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, curriculumChange, dataItemDetail]);

  const handleUpdateCurriculum = (
    curriculumId: string,
    curriculumAdmin: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>
  ) => {
    dispatch(
      updateCurriculum({
        id: curriculumId,
        data: {
          item: {
            name: curriculumAdmin.name,
            description: curriculumAdmin.curriculum_description,
            sort_order: Number(curriculumAdmin.sort_order),
            version: dataItemDetail?.published_version,
            publish: curriculumAdmin.publish,
            updatedat: new Date(),
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );
  };

  const handleUpdateLevelCurriculum = (data: {
    id: string;
    level: number;
    name: string;
    sort_order: number;
  }) => {
    dispatch(
      updateLevelCurriculum({
        id: data.id,
        level: data.level,
        data: {
          item: {
            name: data.name,
            sort_order: data.sort_order,
          },
        },
      })
    );
  };

  const handleCreateLevel1 = async (
    dataLv1: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    curricullum_code: string
  ) => {
    const resultActionLevel1 = await dispatch(
      createLevelCurriculum({
        level: 1,
        item: {
          company_id: userInfo?.company_id,
          name: dataLv1.name,
          sort_order: Number(dataLv1.sort_order),
          curricullum_code: curricullum_code,
          official_level1_code: dataLv1.code,
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (
      dataLv1.children &&
      createLevelCurriculum.fulfilled.match(resultActionLevel1) &&
      resultActionLevel1.payload.item?.code
    ) {
      await Promise.all(
        dataLv1.children.map(async (level_2) => {
          const resultActionLevel2 = await dispatch(
            createLevelCurriculum({
              level: 2,
              item: {
                company_id: userInfo?.company_id,
                name: level_2.name,
                sort_order: Number(level_2.sort_order),
                level1_code: resultActionLevel1.payload.item?.code,
                official_level2_code: level_2.code,
              },
              return_item_result: true,
              return_display_id: true,
              realtime_auto_link: true,
            })
          );
          if (
            level_2.children &&
            createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
            resultActionLevel2.payload.item?.code
          ) {
            await Promise.all(
              level_2.children.map(async (level_3) => {
                const resultActionLevel3 = await dispatch(
                  createLevelCurriculum({
                    level: 3,
                    item: {
                      company_id: userInfo?.company_id,
                      name: level_3.name,
                      sort_order: Number(level_3.sort_order),
                      level2_code: resultActionLevel2.payload.item?.code,
                      official_level3_code: level_3.code,
                    },
                    return_item_result: true,
                    return_display_id: true,
                    realtime_auto_link: true,
                  })
                );
                if (
                  level_3.children &&
                  createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
                  resultActionLevel3.payload.item?.code
                ) {
                  await Promise.all(
                    level_3.children.map(async (level_4) => {
                      const resultActionLevel4 = await dispatch(
                        createLevelCurriculum({
                          level: 4,
                          item: {
                            company_id: userInfo?.company_id,
                            name: level_4.name,
                            sort_order: Number(level_4.sort_order),
                            level3_code: resultActionLevel3.payload.item?.code,
                            official_level4_code: level_4.code,
                          },
                          return_item_result: true,
                          return_display_id: true,
                          realtime_auto_link: true,
                        })
                      );
                      if (
                        level_4.children &&
                        createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
                        resultActionLevel4.payload.item?.code
                      ) {
                        await Promise.all(
                          level_4.children.map(async (question) => {
                            await dispatch(
                              createQuestion({
                                item: {
                                  company_id: userInfo?.company_id,
                                  level4_code: resultActionLevel4.payload.item?.code,
                                  code: question.code,
                                  sort_order: Number(question.sort_order),
                                  createdby: userInfo?.login_id,
                                  createdat: new Date(),
                                },
                              })
                            );
                          })
                        );
                      }
                    })
                  );
                }
              })
            );
          }
        })
      );
    }
  };

  const handleCreateLevel2 = async (
    dataLv2: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    lv1_code: string
  ) => {
    const resultActionLevel2 = await dispatch(
      createLevelCurriculum({
        level: 2,
        item: {
          company_id: userInfo?.company_id,
          name: dataLv2.name,
          sort_order: Number(dataLv2.sort_order),
          level1_code: lv1_code,
          official_level2_code: dataLv2.code,
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (
      dataLv2.children &&
      createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
      resultActionLevel2.payload.item?.code
    ) {
      await Promise.all(
        dataLv2.children.map(async (level_3) => {
          const resultActionLevel3 = await dispatch(
            createLevelCurriculum({
              level: 3,
              item: {
                company_id: userInfo?.company_id,
                name: level_3.name,
                sort_order: Number(level_3.sort_order),
                level2_code: resultActionLevel2.payload.item?.code,
                official_level3_code: level_3.code,
              },
              return_item_result: true,
              return_display_id: true,
              realtime_auto_link: true,
            })
          );
          if (
            level_3.children &&
            createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
            resultActionLevel3.payload.item?.code
          ) {
            await Promise.all(
              level_3.children.map(async (level_4) => {
                const resultActionLevel4 = await dispatch(
                  createLevelCurriculum({
                    level: 4,
                    item: {
                      company_id: userInfo?.company_id,
                      name: level_4.name,
                      sort_order: Number(level_4.sort_order),
                      level3_code: resultActionLevel3.payload.item?.code,
                      official_level4_code: level_4.code,
                    },
                    return_item_result: true,
                    return_display_id: true,
                    realtime_auto_link: true,
                  })
                );
                if (
                  level_4.children &&
                  createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
                  resultActionLevel4.payload.item?.code
                ) {
                  await Promise.all(
                    level_4.children.map(async (question) => {
                      await dispatch(
                        createQuestion({
                          item: {
                            company_id: userInfo?.company_id,
                            level4_code: resultActionLevel4.payload.item?.code,
                            code: question.code,
                            sort_order: Number(question.sort_order),
                            createdby: userInfo?.login_id,
                            createdat: new Date(),
                          },
                        })
                      );
                    })
                  );
                }
              })
            );
          }
        })
      );
    }
  };

  const handleCreateLevel3 = async (
    dataLv3: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    lv2_code: string
  ) => {
    const resultActionLevel3 = await dispatch(
      createLevelCurriculum({
        level: 3,
        item: {
          company_id: userInfo?.company_id,
          name: dataLv3.name,
          sort_order: Number(dataLv3.sort_order),
          level2_code: lv2_code,
          official_level3_code: dataLv3.code,
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (
      dataLv3.children &&
      createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
      resultActionLevel3.payload.item?.code
    ) {
      await Promise.all(
        dataLv3.children.map(async (level_4) => {
          const resultActionLevel4 = await dispatch(
            createLevelCurriculum({
              level: 4,
              item: {
                company_id: userInfo?.company_id,
                name: level_4.name,
                sort_order: Number(level_4.sort_order),
                level3_code: resultActionLevel3.payload.item?.code,
                official_level4_code: level_4.code,
              },
              return_item_result: true,
              return_display_id: true,
              realtime_auto_link: true,
            })
          );
          if (
            level_4.children &&
            createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
            resultActionLevel4.payload.item?.code
          ) {
            await Promise.all(
              level_4.children.map(async (question) => {
                await dispatch(
                  createQuestion({
                    item: {
                      company_id: userInfo?.company_id,
                      level4_code: resultActionLevel4.payload.item?.code,
                      code: question.code,
                      sort_order: Number(question.sort_order),
                      createdby: userInfo?.login_id,
                      createdat: new Date(),
                    },
                  })
                );
              })
            );
          }
        })
      );
    }
  };

  const handleCreateLevel4 = async (
    dataLv4: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    lv3_code: string
  ) => {
    const resultActionLevel4 = await dispatch(
      createLevelCurriculum({
        level: 4,
        item: {
          company_id: userInfo?.company_id,
          name: dataLv4.name,
          sort_order: Number(dataLv4.sort_order),
          level3_code: lv3_code,
          official_level4_code: dataLv4.code,
        },
        return_item_result: true,
        return_display_id: true,
        realtime_auto_link: true,
      })
    );

    if (
      dataLv4.children &&
      createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
      resultActionLevel4.payload.item?.code
    ) {
      await Promise.all(
        dataLv4.children.map(async (question) => {
          await dispatch(
            createQuestion({
              item: {
                company_id: userInfo?.company_id,
                level4_code: resultActionLevel4.payload.item?.code,
                code: question.code,
                sort_order: Number(question.sort_order),
                createdby: userInfo?.login_id,
                createdat: new Date(),
              },
            })
          );
        })
      );
    }
  };

  const handleCreateLevel5 = async (
    dataLv5: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    lv4_code: string
  ) => {
    await dispatch(
      createQuestion({
        item: {
          company_id: userInfo?.company_id,
          level4_code: lv4_code,
          code: dataLv5.code,
          sort_order: Number(dataLv5.sort_order),
          createdby: userInfo?.login_id,
          createdat: new Date(),
        },
      })
    );
  };

  const handleCreateUpdateCurriculum = (
    curriculumAdmin: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>,
    curriculumUser: Array<Types.FlatChildrenItemID<Types.CurriculumItemType>>
  ) => {
    const curriculumFounded = curriculumUser.find(
      (cur) => cur.official_curriculum_code === curriculumAdmin.code
    );

    if (!curriculumFounded) return;

    if (
      curriculumFounded.name !== curriculumAdmin.name ||
      curriculumFounded.description !== curriculumAdmin.curriculum_description ||
      curriculumFounded.sort_order != curriculumAdmin.sort_order ||
      curriculumFounded.publish !== curriculumAdmin.publish
    ) {
      handleUpdateCurriculum(curriculumFounded.i_id || '', curriculumAdmin);
    }

    curriculumAdmin.children?.map((lv1) => {
      const lv1Founded = curriculumUser.find((lv1User) => lv1User.official_code === lv1.code);

      if (lv1Founded) {
        if (lv1Founded.name !== lv1.name || lv1Founded.sort_order != lv1.sort_order) {
          handleUpdateLevelCurriculum({
            id: lv1Founded.i_id || '',
            level: lv1Founded.columnIndex,
            name: lv1.name || '',
            sort_order: Number(lv1.sort_order) || 0,
          });
        }

        lv1.children?.map((lv2) => {
          const lv2Founded = curriculumUser.find((lv2User) => lv2User.official_code === lv2.code);

          if (lv2Founded) {
            if (lv2Founded.name !== lv2.name || lv2Founded.sort_order != lv2.sort_order) {
              handleUpdateLevelCurriculum({
                id: lv2Founded.i_id || '',
                level: lv2Founded.columnIndex,
                name: lv2.name || '',
                sort_order: Number(lv2.sort_order) || 0,
              });
            }

            lv2.children?.map((lv3) => {
              const lv3Founded = curriculumUser.find(
                (lv3User) => lv3User.official_code === lv3.code
              );

              if (lv3Founded) {
                if (lv3Founded.name !== lv3.name || lv3Founded.sort_order != lv3.sort_order) {
                  handleUpdateLevelCurriculum({
                    id: lv3Founded.i_id || '',
                    level: lv3Founded.columnIndex,
                    name: lv3.name || '',
                    sort_order: Number(lv3.sort_order) || 0,
                  });
                }

                lv3.children?.map((lv4) => {
                  const lv4Founded = curriculumUser.find(
                    (lv4User) => lv4User.official_code === lv4.code
                  );

                  if (lv4Founded) {
                    if (lv4Founded.name !== lv4.name || lv4Founded.sort_order != lv4.sort_order) {
                      handleUpdateLevelCurriculum({
                        id: lv4Founded.i_id || '',
                        level: lv4Founded.columnIndex,
                        name: lv4.name || '',
                        sort_order: Number(lv4.sort_order) || 0,
                      });
                    }

                    lv4.children?.map((lv5) => {
                      const lv5Founded = curriculumUser.find(
                        (lv5User) => lv5User.question_code === lv5.code
                      );

                      if (!lv5Founded) {
                        handleCreateLevel5(lv5, lv4Founded.code || '');
                      }
                    });
                  } else {
                    handleCreateLevel4(lv4, lv3Founded.code || '');
                  }
                });
              } else {
                handleCreateLevel3(lv3, lv2Founded.code || '');
              }
            });
          } else {
            handleCreateLevel2(lv2, lv1Founded.code || '');
          }
        });
      } else {
        handleCreateLevel1(lv1, curriculumFounded.code || '');
      }
    });
  };

  const handleUpdateTreeCurriculum = async () => {
    const curriculumHierarchyFound = officialCurriculumHierarchyList.find(
      (curriculum) => curriculum.code === dataItemDetail?.official_curriculum_code
    );
    const listChildItemID: Array<Types.FlatChildrenItemID<Types.CurriculumItemType>> =
      await memoizedGetChildrenItemIDFromTree({
        treeData: curriculumChange,
      });

    const listChildDetail: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>[] =
      map(
        memoizedGetFlatDataFromTree({
          treeData: curriculumHierarchyFound,
        }),
        (item: Types.TreeItem<Types.OfficialCurriculumHierarchyList.DataTreeConverted>) => ({
          code: get(item, 'node.code'),
          name: get(item, 'node.name'),
          curriculum_description: get(item, 'node.curriculum_description'),
          sort_order: get(item, 'node.sort_order', 0),
          probs_count: get(item, 'node.probs_count', 0),
        })
      );

    const itemsDelete = listChildItemID.filter(
      (item2) => !listChildDetail.some((item1) => item1.code === item2.official_code)
    );

    if (itemsDelete.length) {
      await Promise.all(
        itemsDelete.map((item) => {
          if (item.columnIndex < 5) {
            dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          } else {
            dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        })
      );
    }

    if (curriculumHierarchyFound) {
      await handleCreateUpdateCurriculum(curriculumHierarchyFound, listChildItemID);
    }

    await fetchDataOfficialCurriculumList();

    setIsUpdateConfirmComplete(true);
  };

  const fetchDataOfficialCurriculumList = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataOfficialCurriculumList({
            conditions: [],
            page: 1,
            per_page: 0,
            company_id: userInfo.company_id,
            sort_fields: [
              { id: 'official_curriculum_code', order: 'asc' },
              { id: 'publish_start_date', order: 'desc' },
            ],
          })
        );
        await dispatch(
          getCurriculumSetting({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectOfficialCurriculum({
            conditions: [],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectOfficialCurriculumProvider({
            conditions: [],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo || !dataItemDetail) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getOfficialCurriculumHierarchyList({
            conditions: [
              {
                id: 'official_curriculum_code',
                search_value: [dataItemDetail.official_curriculum_code],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataItemDetail, dispatch, userInfo]);

  useEffect(fetchDataOfficialCurriculumList, [fetchDataOfficialCurriculumList]);

  return (
    <SpinLoading>
      <Header title={headerTitle} />
      <ListCurriculumStyled>
        <p className="text-note">
          OFFICIALカリキュラムを管理する画面です。
          <br />
          利用できるOFFICIALカリキュラムを一覧で確認することができ、利用開始・停止の設定、アップデートが可能です。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="official_curriculum_code"
                className="official-curriculum-name"
                label={<span className="text-label">OFFICIALカリキュラム名称</span>}
              >
                <SelectField
                  name="official_curriculum_code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectOfficialCurriculum?.map((item, index) => (
                    <Option value={item.official_curriculum_code} key={index}>
                      {item.official_curriculum_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="status"
                className="usage_situation"
                label={<span className="text-label">利用状況</span>}
              >
                <SelectField
                  name="status"
                  onChange={setValueStatus}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="利用中">利用中</Option>
                  <Option value="未利用">未利用</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="update"
                className="update"
                label={<span className="text-label">アップデート</span>}
              >
                <SelectField
                  name="update"
                  onChange={setValueUpdate}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="update">アップデート有り</Option>
                  <Option value="un-update">アップデート無し</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="provider_id"
                className="item"
                label={<span className="text-label">制作者</span>}
              >
                <SelectField
                  name="provider_id"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {officialCurriculumProviderSelect?.map((item, index) => (
                    <Option key={index} value={item.provider_id}>
                      {item.provider_name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="item-list">
          <div className="select-perpage">
            <div className="label">
              <span>表示件数</span>：
            </div>
            <Select defaultValue={perPage} onChange={handleSelectChange} value={perPage}>
              {[10, 20, 50, 100].map((value, index) => (
                <Option key={index} value={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
          <List
            grid={{ gutter: 16, column: 5 }}
            dataSource={
              valueStatus === '利用中'
                ? dataUsed
                : valueStatus === '未利用'
                ? dataUnUsed
                : valueUpdate === 'update'
                ? dataUpdate
                : valueUpdate === 'un-update'
                ? dataUnUpdate
                : dataOfficialCurriculumList
            }
            pagination={{
              pageSize: perPage,
              total: dataOfficialCurriculumList.length,
              current: pageCurrent,
              onChange: setPageCurrent,
              position: 'top',
              showTotal: () => (
                <div className="text-count">
                  {(pageCurrent - 1) * perPage + 1} - {''}
                  {pageCurrent * perPage > dataOfficialCurriculumList.length
                    ? dataOfficialCurriculumList.length
                    : pageCurrent * perPage}{' '}
                  <span className="text-static"></span> / {''}
                  {dataOfficialCurriculumList.length}
                  <span className="text-static">件</span>
                </div>
              ),
            }}
            renderItem={(item) => (
              <List.Item>
                <Card
                  onClick={() => {
                    if (!item.curriculum_code) {
                      setOpenCurriculumContentUse(true);
                    } else {
                      setOpenCurriculumContent(true);
                    }
                    setDataItemDetail(item);
                  }}
                  className="card-item"
                >
                  <div className="picture-item">
                    {!item.curriculum_code ? (
                      <button className="btn btn-default">未利用</button>
                    ) : (
                      <button className="btn btn-active">利用中</button>
                    )}
                    {item?.file?.originFileObj ? (
                      <img
                        src={URL.createObjectURL(new Blob([item?.file?.originFileObj as Blob]))}
                        alt=""
                        className="design-course"
                      />
                    ) : (
                      <img src={NoImageOfficial} alt="" className="design-course-no" />
                    )}
                    {officialCurriculumProviderSelect.map(
                      (i) =>
                        i.provider_id === item.provider_id && (
                          <p
                            className="title-picture"
                            onClick={(e) => {
                              e.stopPropagation();
                              setOpenProductInformation(true);
                              setDataItemDetail(item);
                            }}
                          >
                            {i.provider_name}
                          </p>
                        )
                    )}
                  </div>
                  <div className="content-item">
                    <p
                      className="version-skill"
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenCurriculumNotes(true);
                      }}
                    >
                      v {item.use_version}
                    </p>
                    <p className="name-content">{item.curriculum_name}</p>
                    <p className="title-content">{item.description}</p>
                  </div>
                  {!item.curriculum_code ? (
                    <div className="group-btn">
                      <button
                        disabled={permissionNumber === 1}
                        className={permissionNumber === 1 ? 'disabled' : 'btn-active'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsConfirmModal(true);
                          setDataItemDetail(item);
                        }}
                      >
                        利用する
                      </button>
                    </div>
                  ) : item.release_version > item.published_version ? (
                    <div className="group-btn">
                      <button
                        disabled={permissionNumber === 1}
                        className={permissionNumber === 1 ? 'disabled' : 'btn-default active'}
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsUpdateConfirm(true);
                          setDataItemDetail(item);
                        }}
                      >
                        アップデート予定
                      </button>
                      <p
                        className="text-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleModalCurriculumSuspension(true);
                          setDataItemDetail(item);
                        }}
                      >
                        利用停止
                      </p>
                    </div>
                  ) : item.use_version < item.published_version &&
                    item.release_version <= item.published_version &&
                    item.publish == 2 ? (
                    <div className="group-btn">
                      <button
                        className="btn-default"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsUpdateConfirm(true);
                          setDataItemDetail(item);
                        }}
                      >
                        アップデート
                      </button>
                      <p
                        className="text-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleModalCurriculumSuspension(true);
                          setDataItemDetail(item);
                        }}
                      >
                        利用停止
                      </p>
                    </div>
                  ) : (
                    <div className="group-btn text">
                      <p
                        className="text-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibleModalCurriculumSuspension(true);
                          setDataItemDetail(item);
                        }}
                      >
                        利用停止
                      </p>
                    </div>
                  )}
                </Card>
              </List.Item>
            )}
          />
        </div>
        <ConfirmModal
          title="OFFICIALカリキュラム 利用開始"
          visible={isConfirmModal}
          setVisible={setIsConfirmModal}
          onSubmit={handleCreateCurriculum}
          subTitle="選択した OFFICIALカリキュラムの利用を開始します。"
          description={
            <>
              OKをクリックでカリキュラムツリー上に
              <br />
              OFFICIALカリキュラムが追加されます。
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL カリキュラム 利用開始"
          visible={isUpdateComplete}
          setVisible={setIsUpdateComplete}
          handleToggleModalComplete={() => {
            setOpenCurriculumContentUse(false);
          }}
          subTitle="OFFICIAL カリキュラムが追加されました。"
        />
        <ConfirmDeleteModalOfficial
          title="OFFICIALカリキュラム 利用停止"
          visible={visibleModalCurriculumSuspension}
          setVisible={setVisibleModalCurriculumSuspension}
          onSubmit={handleDeleteCurriculum}
          subTitle="選択した OFFICIALカリキュラムの利用を停止します。"
          description={
            <>
              OFFICIALカリキュラムの利用を停止すると、カリキュラムマスタから削除されます。
              <br />
              カリキュラムに設定されていた設問、コピー作成したカリキュラムは削除されずに残ります。
              <br />
              再度利用開始は可能ですが、ユーザーの実施履歴は引き継がれませんのでご注意ください。
              <br />
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL OFFICIALカリキュラム 利用停止"
          visible={isUpdateCompleteActive}
          setVisible={setIsUpdateCompleteActive}
          subTitle="OFFICIAL OFFICIALカリキュラムの利用停止が完了しました。"
        />
        <UpdateConfirm
          title="アップデート"
          visible={isUpdateConfirm}
          setVisible={setIsUpdateConfirm}
          onSubmit={handleUpdateTreeCurriculum}
          dataItemDetail={dataItemDetail}
          subTitle="OFFICIALカリキュラムのアップデートを実行します。"
          description={
            <>
              実行すると、実施中のユーザーがいる場合もカリキュラムがアップデートされますのでご注意ください。
              <br />
              下記バージョンの内容がアップデートされます。
            </>
          }
        />
        <CompleteModal
          title="アップデート"
          visible={isUpdateConfirmComplete}
          setVisible={setIsUpdateConfirmComplete}
          subTitle="OFFICIALカリキュラムのアップデートが完了しました！"
        />
        <CurriculumContentUse
          visible={openCurriculumContentUse}
          setVisible={setOpenCurriculumContentUse}
          dataItemDetail={dataItemDetail}
          onSubmit={handleCreateCurriculum}
          dataOfficialCurriculumList={dataOfficialCurriculumList}
        />
        <CurriculumContent
          visible={openCurriculumContent}
          setVisible={setOpenCurriculumContent}
          dataItemDetail={dataItemDetail}
        />
        <ProductInformation
          visible={openProductInformation}
          setVisible={setOpenProductInformation}
          dataOfficialCurriculumList={dataOfficialCurriculumList}
          dataItemDetail={dataItemDetail}
        />
        <CurriculumNotes visible={openCurriculumNotes} setVisible={setOpenCurriculumNotes} />
      </ListCurriculumStyled>
    </SpinLoading>
  );
};

export default ListCurriculum;
