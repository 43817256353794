import React, { useEffect } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { Form } from 'formik-antd';

import { Modal, TextArea } from 'components';
import { SectionStyled } from './styles';
import { CommentFormik } from 'types';

interface Props {
  visible: boolean;
  commentValues: string;
  onSubmit: (params: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupComment: React.FC<Props> = ({ visible, setVisible, onSubmit, commentValues }) => {
  const { messages } = useIntl();

  const formik = useFormik<CommentFormik>({
    initialValues: {
      comment: '',
    },
    onSubmit: async (values) => {
      const { comment } = values;
      await onSubmit(comment);
    },
  });

  useEffect(() => {
    formik.setFieldValue('comment', commentValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentValues]);

  return (
    <Modal
      title="設問編集"
      width={720}
      visible={visible}
      onCancel={() => setVisible(false)}
      okButton={{
        text: messages['M-21-58'],
        onClick: formik.handleSubmit,
      }}
      cancelButton={{
        text: messages['M-21-59'],
        onClick: () => setVisible(false),
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <FormikProvider value={formik}>
          <Form colon={false} className="form">
            <Form.Item name="comment" className="form-input">
              <TextArea
                name="comment"
                className="input-TextArea"
                showCount
                maxLength={1050}
                placeholder={messages['M-21-51'] as string}
              />
            </Form.Item>
          </Form>
        </FormikProvider>
      </SectionStyled>
    </Modal>
  );
};

export default PopupComment;
