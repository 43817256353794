import React, { useState } from 'react';
import { Popover } from 'antd';

import { IconEdit, IconEmployee, IconInterview, IconLocked, IconPublish } from 'assets';
import TooltipUserSetting from '../Tooltip';
import { Wrapper } from './styles';
import * as Types from 'types';

const NodeRenderer: React.FC<{
  itemSelected: boolean;
  data: Types.SkillCheckTreeViewType;
  fetchDataSkillCheck: () => void;
  fetchDataUserSkillCheck: () => void;
  handleSwitch: (value: boolean) => void;
}> = ({ data, handleSwitch, itemSelected, fetchDataSkillCheck, fetchDataUserSkillCheck }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Wrapper>
      <Popover
        overlayStyle={{
          width: 340,
        }}
        placement="bottomLeft"
        content={
          <TooltipUserSetting
            data={data}
            itemSelected={itemSelected}
            setVisible={setVisible}
            handleSwitch={handleSwitch}
            fetchDataSkillCheck={fetchDataSkillCheck}
            fetchDataUserSkillCheck={fetchDataUserSkillCheck}
          />
        }
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
        trigger="click"
        onVisibleChange={setVisible}
        visible={visible}
      >
        <div className="row">
          <div className="box_icon">
            {data.publish ? (
              <img src={IconPublish} className="icon" alt="publish-icon" />
            ) : (
              <img src={IconLocked} className="icon" alt="locked-icon" />
            )}
            {data.user_type === 'member' ? (
              <img src={IconEmployee} className="icon" alt="employee-icon" />
            ) : (
              <img src={IconInterview} className="icon" alt="interview-icon" />
            )}
            {data.implementation_settings === 2 && (
              <img src={IconEdit} className="icon" alt="edit-icon" />
            )}
          </div>
          <span className="rowTitle">{data.skill_check_name || '（空白）'}</span>
        </div>
      </Popover>
    </Wrapper>
  );
};

export default NodeRenderer;
