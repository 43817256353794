import { groupBy, isNumber, reduce, unionBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { formatNumber } from 'libs/utils/format';
import * as Types from 'types';
import {
  getDataSelectCurriculumQuestion,
  getDataSelectSkillCheckQuestion,
  getDataDetailSkillCheckResult,
  getDataDetailCurriculumResult,
  getDataTableCurriculumUserAll,
  getDataSkillCheckUserMyChart,
  getDataReportCurriculumUser,
  getDataTableCurriculumUser,
  getDataSelectAnalysisGroup,
  getDataReportSkillCheckAll,
  getDataTrainingCurriculum,
  getCurriculumLevelOption,
  getDataSelectSkillCheck,
  getDataSelectCurriculum,
  getDataSkillCheckUser,
  getDataChartResult,
} from './thunk';

export interface InitialState {
  dataSelectAnalysisGroup: Array<Types.OptionsSkillCheckAnalysisGroupImplement.ResponseType>;
  dataReportLevelsCurriculumUser: Array<Types.ReportCurriculumUser.UserReportLevelsTable>;
  dataSelectSkillCheckQuestion: Array<Types.SelectSkillCheckQuestion.ResponseType>;
  dataSelectCurriculumQuestion: Array<Types.SelectCurriculumQuestion.ResponseType>;
  dataDetailCurriculumResult: Array<Types.DetailOfCurriculumResults.ResponseType>;
  dataDetailSkillCheckResult: Array<Types.DetailOfSkillCheckResults.ResponseType>;
  dataSelectSkillCheck: Array<Types.OptionsSkillCheckImplements.ResponseType>;
  dataTabUser: Types.ReportSkillCheckUserTrans.OneLoginIDAndOneSkillCheckCode;
  dataTabAll: Types.ReportSkillCheckUserTrans.OneLoginIDAndOneSkillCheckCode;
  dataReportCurriculumUser: Array<Types.ReportCurriculumUser.ResponseType>;
  dataSkillCheckUser: Array<Types.ReportSkillCheckUserTrans.ResponseType>;
  dataTabUserCurriculum?: Types.ReportCurriculumUser.CurriculumSummary;
  dataBefore?: Types.ReportSkillCheckUserTrans.GroupDateSkillCheckCode;
  dataAfter?: Types.ReportSkillCheckUserTrans.GroupDateSkillCheckCode;
  dataTabAllCurriculum?: Types.ReportCurriculumUser.CurriculumSummary;
  dataTableCurriculum: Array<Types.ReportCurriculumUser.ResponseType>;
  curriculumNameSelect: Array<Types.SelectCurriculums.ResponseType>;
  dataChartReportCurriculumUser: Array<Types.CurriculumChart>;
  dataAllUser?: Types.ReportSkillCheckUserTrans.ResponseType;
  dataUser?: Types.ReportSkillCheckUserTrans.ResponseType;
  selectLevel1: Array<Types.SelectLevel.ResponseType>;
  selectLevel2: Array<Types.SelectLevel.ResponseType>;
  selectLevel3: Array<Types.SelectLevel.ResponseType>;
  selectLevel4: Array<Types.SelectLevel.ResponseType>;
  dataChartResult: Array<Types.ColumnChart>;
  dataCircleChart: Array<Types.CircleChart>;
  totalReportLevelsCurriculumUser: number;
  totalDetailSkillCheckResult: number;
  total: number;
}

const initialState: InitialState = {
  dataReportLevelsCurriculumUser: [],
  totalReportLevelsCurriculumUser: 0,
  dataChartReportCurriculumUser: [],
  dataSelectSkillCheckQuestion: [],
  dataSelectCurriculumQuestion: [],
  dataDetailSkillCheckResult: [],
  dataDetailCurriculumResult: [],
  totalDetailSkillCheckResult: 0,
  dataReportCurriculumUser: [],
  dataSelectAnalysisGroup: [],
  dataSelectSkillCheck: [],
  curriculumNameSelect: [],
  dataTableCurriculum: [],
  dataSkillCheckUser: [],
  dataChartResult: [],
  dataCircleChart: [],
  selectLevel1: [],
  selectLevel2: [],
  selectLevel3: [],
  selectLevel4: [],
  total: 0,
  dataTabUser: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
  dataTabAll: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
};

export const myChartDashboardSlice = createSlice({
  name: 'myChartDashboard-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataSelectSkillCheck.fulfilled, (state, action) => {
      state.dataSelectSkillCheck = unionBy(action.payload.report_results, 'code');
    });
    builder.addCase(getDataSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = action.payload.report_results;
    });
    builder.addCase(getDataSelectAnalysisGroup.fulfilled, (state, action) => {
      state.dataSelectAnalysisGroup = unionBy(action.payload.report_results, 'group_code');
    });
    builder.addCase(getDataSelectSkillCheckQuestion.fulfilled, (state, action) => {
      state.dataSelectSkillCheckQuestion = action.payload.report_results;
    });
    builder.addCase(getDataSelectCurriculumQuestion.fulfilled, (state, action) => {
      state.dataSelectCurriculumQuestion = action.payload.report_results.filter(
        (item) => item.question_code
      );
    });

    builder.addCase(getDataSkillCheckUser.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            probs_count: prev.probs_count + (next.probs_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            acquisition_score: prev.acquisition_score || 0,
          }),
          {
            correct_answers_num: 0,
            probs_count: 0,
            responses_num: 0,
            acquisition_score: action.payload.report_results[0]?.acquisition_score!,
          }
        );
      state.dataTabUser = {
        correct_answers_num,
        accuracy_rate: correct_answers_num / probs_count || 0,
        wrong_rate: (responses_num - correct_answers_num) / probs_count || 0,
        inexperienced_rate: (probs_count - responses_num) / probs_count || 0,
        acquisition_score,
        question_time_limit: action.payload.report_results[0]?.question_time_limit || 0,
        probs_count: action.payload.report_results[0]?.probs_count || 0,
        setting_score: action.payload.report_results[0]?.setting_score || 0,
        implementation_date: action.payload.report_results[0]?.implementation_date,
      };
    });

    builder.addCase(getDataReportSkillCheckAll.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } = unionBy(
        action.payload.report_results,
        'login_id'
      ).reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
          probs_count: prev.probs_count + (next.probs_count || 0),
          responses_num: prev.responses_num + (next.responses_num || 0),
          acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
        }),
        {
          correct_answers_num: 0,
          probs_count: 0,
          responses_num: 0,
          acquisition_score: 0,
        }
      );
      const length = unionBy(action.payload.report_results, 'login_id').length;
      state.dataTabAll = {
        correct_answers_num: correct_answers_num / length,
        accuracy_rate: correct_answers_num / probs_count,
        wrong_rate: (responses_num - correct_answers_num) / probs_count,
        inexperienced_rate: (probs_count - responses_num) / probs_count,
        acquisition_score: acquisition_score / length,
      };
    });

    builder.addCase(getDataSkillCheckUserMyChart.fulfilled, (state, action) => {
      const data = action.payload.report_results;
      const {
        correct_answers_num_all,
        probs_count_all,
        responses_num_all,
        question_time_limit_all,
        answer_time_all,
        acquisition_score_all,
        setting_score_all,
      } = data.reduce(
        (prev, next) => ({
          correct_answers_num_all: prev.correct_answers_num_all + (next.correct_answers_num || 0),
          probs_count_all: prev.probs_count_all + (next.probs_count || 0),
          responses_num_all: prev.responses_num_all + (next.responses_num || 0),
          question_time_limit_all: prev.question_time_limit_all + (next.question_time_limit || 0),
          answer_time_all: prev.answer_time_all + (next.answer_time || 0),
          acquisition_score_all: prev.acquisition_score_all + (next.acquisition_score || 0),
          setting_score_all: prev.setting_score_all + (next.setting_score || 0),
        }),
        {
          correct_answers_num_all: 0,
          probs_count_all: 0,
          responses_num_all: 0,
          question_time_limit_all: 0,
          answer_time_all: 0,
          acquisition_score_all: 0,
          setting_score_all: 0,
        }
      );

      const newDataSkillCheckUser = action.payload.report_results.filter(
        (item) => item.login_id === action.payload.login_id
      );

      const groupArrayGroupingCode = groupBy(
        newDataSkillCheckUser.filter((item) => item.grouping_code),
        (e) => e.implementation_date
      );
      const SkillCheckBefore = groupArrayGroupingCode[Object.keys(groupArrayGroupingCode)[0]];
      const SkillCheckAfter = groupArrayGroupingCode[Object.keys(groupArrayGroupingCode)[1]];

      const dataBefore: Types.ReportSkillCheckUserTrans.GroupDateSkillCheckCode = reduce(
        SkillCheckBefore,
        (res, item) => ({
          ...res,
          probs_count: res?.probs_count + (item.probs_count || 0),
          responses_num: res?.responses_num + (item.responses_num || 0),
          correct_answers_num: res?.correct_answers_num + (item.correct_answers_num || 0),
          accuracy_rate:
            ((res?.correct_answers_num + (item.correct_answers_num || 0)) /
              (res?.probs_count + (item.probs_count || 0))) *
            100,
        }),
        {
          probs_count: 0,
          correct_answers_num: 0,
          responses_num: 0,
          accuracy_rate: 0,
        }
      );
      const dataAfter: Types.ReportSkillCheckUserTrans.GroupDateSkillCheckCode = reduce(
        SkillCheckAfter,
        (res, item) => ({
          ...res,
          probs_count: res?.probs_count + (item.probs_count || 0),
          responses_num: res?.responses_num + (item.responses_num || 0),
          correct_answers_num: res?.correct_answers_num + (item.correct_answers_num || 0),
          accuracy_rate:
            ((res?.correct_answers_num + (item.correct_answers_num || 0)) /
              (res?.probs_count + (item.probs_count || 0))) *
            100,
        }),
        {
          probs_count: 0,
          correct_answers_num: 0,
          responses_num: 0,
          accuracy_rate: 0,
        }
      );

      const {
        correct_answers_num,
        probs_count,
        responses_num,
        question_time_limit,
        answer_time,
        acquisition_score,
        setting_score,
      } = newDataSkillCheckUser.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
          probs_count: prev.probs_count + (next.probs_count || 0),
          responses_num: prev.responses_num + (next.responses_num || 0),
          question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
          answer_time: prev.answer_time + (next.answer_time || 0),
          acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
          setting_score: prev.setting_score + (next.setting_score || 0),
        }),
        {
          correct_answers_num: 0,
          probs_count: 0,
          responses_num: 0,
          question_time_limit: 0,
          answer_time: 0,
          acquisition_score: 0,
          setting_score: 0,
        }
      );

      const dataUser = {
        i_id: '',
        company_id: '',
        skill_check_code: '',
        skill_check_name: '',
        login_id: '',
        user_name: '',
        grouping_code: '',
        probs_count: probs_count / newDataSkillCheckUser.length || 0,
        responses_num: responses_num / newDataSkillCheckUser.length || 0,
        correct_answers_num: correct_answers_num / newDataSkillCheckUser.length || 0,
        question_time_limit: question_time_limit / newDataSkillCheckUser.length || 0,
        answer_time: answer_time / newDataSkillCheckUser.length || 0,
        acquisition_score: acquisition_score / newDataSkillCheckUser.length || 0,
        setting_score: setting_score / newDataSkillCheckUser.length || 0,
        incorrect_answer: (responses_num - correct_answers_num) / newDataSkillCheckUser.length || 0,
        inexperienced: (probs_count - responses_num) / newDataSkillCheckUser.length || 0,
        accuracy_rate: (correct_answers_num / probs_count) * 100 || 0,
        incorrect_answer_rate: ((responses_num - correct_answers_num) / probs_count) * 100 || 0,
        unanswered_rate: ((probs_count - responses_num) / probs_count) * 100 || 0,
      };

      state.dataAllUser = {
        i_id: data[0]?.i_id,
        company_id: data[0]?.company_id,
        skill_check_code: data[0]?.skill_check_code,
        user_type: 'interview',
        skill_check_name: data[0]?.skill_check_name,
        login_id: data[0]?.login_id,
        user_name: data[0]?.user_name,
        grouping_code: data[0]?.grouping_code,
        probs_count: probs_count_all / data.length || 0,
        responses_num: responses_num_all / data.length || 0,
        correct_answers_num: correct_answers_num_all / data.length || 0,
        question_time_limit: question_time_limit_all / data.length || 0,
        answer_time: answer_time_all / data.length || 0,
        acquisition_score: acquisition_score_all / data.length || 0,
        setting_score: setting_score_all / data.length || 0,
        incorrect_answer: (responses_num_all - correct_answers_num_all) / data.length || 0,
        inexperienced: (probs_count_all - responses_num_all) / data.length || 0,
      };
      state.dataUser = dataUser;
      state.dataCircleChart = [
        {
          type: '正解',
          value: Number(
            isNumber(dataUser.accuracy_rate) ? formatNumber(dataUser.accuracy_rate) : 0
          ),
        },
        {
          type: '不正解',
          value: Number(
            isNumber(dataUser.incorrect_answer_rate)
              ? formatNumber(dataUser.incorrect_answer_rate)
              : 0
          ),
        },
        {
          type: '未経験',
          value: Number(
            isNumber(dataUser.unanswered_rate) ? formatNumber(dataUser.unanswered_rate) : 0
          ),
        },
      ];
      state.dataAfter = dataAfter;
      state.dataBefore = dataBefore;
      state.dataSkillCheckUser = action.payload.report_results;
    });

    builder.addCase(getDataChartResult.fulfilled, (state, action) => {
      const groupArray = groupBy(
        action.payload.report_results,
        (e) => e.implementation_date?.split('T')[0]
      );
      const dataColumnChart: Types.ColumnChart[] = [];
      Object.keys(groupArray).forEach((key) => {
        ['正解', '不正解', '未経験'].forEach((type) => {
          dataColumnChart.push(
            reduce(
              groupArray[key],
              (res, item) => ({
                ...res,
                value:
                  type === '正解'
                    ? Number(
                        res?.value +
                          (((item.correct_answers_num || 0) / (item.probs_count || 0)) * 100) /
                            groupArray[key].length
                      )
                    : type === '不正解'
                    ? Number(
                        res?.value +
                          ((((item.responses_num || 0) - (item.correct_answers_num || 0)) /
                            (item.probs_count || 0)) *
                            100) /
                            groupArray[key].length
                      )
                    : Number(
                        res?.value +
                          ((((item.probs_count || 0) - (item.responses_num || 0)) /
                            (item.probs_count || 0)) *
                            100) /
                            groupArray[key].length
                      ),
              }),
              {
                year: key,
                value: 0,
                type: type,
              }
            )
          );
        });
      });
      state.dataChartResult = dataColumnChart;
    });

    builder.addCase(getDataReportCurriculumUser.fulfilled, (state, action) => {
      const newDataReportCurriculumUser: Array<Types.ReportCurriculumUser.ResponseType> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newDataReportCurriculumUser.findIndex(
          (c) => c.curriculum_code === item.curriculum_code
        );
        if (itemIndex >= 0) {
          if (
            item.curriculum_code &&
            !newDataReportCurriculumUser.some(
              ({ curriculum_code }) => curriculum_code === item.curriculum_code
            )
          ) {
            newDataReportCurriculumUser.push({
              company_id: item.company_id,
              curriculum_code: item.curriculum_code,
              curriculum_name: item.curriculum_name,
              question_count: item.question_count,
              login_id: item.login_id,
              user_name: item.user_name,
              responses_num: item.responses_num,
              correct_answers_num: item.correct_answers_num,
              updatedat: item.updatedat,
              acquisition_score: item.acquisition_score,
              answer_time: item.answer_time,
              code: item.code,
            });
          }
        } else {
          newDataReportCurriculumUser.push({
            company_id: item.company_id,
            curriculum_code: item.curriculum_code,
            curriculum_name: item.curriculum_name,
            question_count: item.question_count,
            login_id: item.login_id,
            user_name: item.user_name,
            responses_num: item.responses_num,
            correct_answers_num: item.correct_answers_num,
            updatedat: item.updatedat,
            acquisition_score: item.acquisition_score,
            answer_time: item.answer_time,
            code: item.code,
          });
        }
      });
      state.dataReportCurriculumUser = newDataReportCurriculumUser.filter(
        (c) => c.login_id === action.payload.login_id
      );

      const dataChartCurriculum = groupBy(action.payload.report_results, (e) => e.curriculum_code);
      const arrDataCurriculum = Object.values(dataChartCurriculum);

      const dataChart: Types.CurriculumChart[] = [];
      arrDataCurriculum.flatMap((item) => {
        const dataCurriculumUser = item.filter((c) => c.login_id === action.payload.login_id);
        const dataChartUser = reduce(
          dataCurriculumUser,
          (res, c) => ({
            ...res,

            numberCorrectNumUser: res?.numberCorrectNumUser + c.correct_answers_num / item.length,
            numberResponseNumUser: res?.numberResponseNumUser + c.responses_num / item.length,
            numberQuestionUser: res?.numberQuestionUser + c.question_count / item.length,
          }),
          {
            numberCorrectNumUser: 0,
            numberResponseNumUser: 0,
            numberQuestionUser: 0,
          }
        );
        const dataChartAll = reduce(
          item,
          (res, c) => ({
            ...res,

            numberCorrectNumAll: res?.numberCorrectNumAll + c.correct_answers_num / item.length,
            numberQuestionAll: res?.numberQuestionAll + c.question_count / item.length,
          }),
          {
            numberCorrectNumAll: 0,
            numberQuestionAll: 0,
          }
        );
        dataChart.push(
          {
            name: dataCurriculumUser[0]?.curriculum_name,
            value: Number(
              formatNumber(
                (dataChartUser.numberCorrectNumUser / dataChartUser.numberQuestionUser) * 100
              )
            ),
            type: '正解進捗率',
            type_group: '1',
          },
          {
            name: dataCurriculumUser[0]?.curriculum_name,
            value: Number(
              formatNumber(
                (dataChartUser.numberResponseNumUser / dataChartUser.numberQuestionUser) * 100
              )
            ),
            type: '進捗率',
            type_group: '1',
          },
          {
            name: dataCurriculumUser[0]?.curriculum_name,
            value: Number(
              formatNumber(
                (dataChartAll.numberCorrectNumAll / dataChartAll.numberQuestionAll) * 100
              )
            ),
            type: 'ユーザー平均正解進捗率',
            type_group: '2',
          }
        );
      });
      state.dataChartReportCurriculumUser = dataChart;
    });

    builder.addCase(getDataDetailSkillCheckResult.fulfilled, (state, action) => {
      const dataDetailSkillCheckUser = action.payload.report_results.filter(
        (item) => item.login_id === action.payload.login_id
      );
      const newDataDetailSkillCheckResult: Types.DetailOfSkillCheckResults.ResponseType[] = [];
      dataDetailSkillCheckUser.forEach((item) => {
        const dataAllCorrect = action.payload.report_results.filter((c) => c.code === item.code);
        const dataAccuracy = action.payload.report_results.filter(
          (c) => c.code === item.code && c.correct === 1
        );
        newDataDetailSkillCheckResult.push({
          ...item,
          accuracy_rate: dataAccuracy.length / dataAllCorrect.length,
          average_answer_time:
            dataAllCorrect.reduce(
              (previousValue, currentValue) =>
                previousValue + (Number(currentValue.answer_time) || 0),
              0
            ) / dataAllCorrect.length,
          acquisition_score: item.correct === 1 ? item.score : 0,
          question_id: item.item_ref?.answer.i_id || '',
        });
      });

      const dataLength = newDataDetailSkillCheckResult.length;

      const { accuracy_rate, answer_time, acquisition_score, average_answer_time, correct, score } =
        newDataDetailSkillCheckResult.reduce(
          (prev, next) => ({
            accuracy_rate: prev.accuracy_rate + (next.accuracy_rate || 0),
            answer_time: prev.answer_time + next.answer_time,
            acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
            average_answer_time: prev.average_answer_time + (next.average_answer_time || 0),
            correct: prev.correct + (next.correct || 0),
            score: prev.score + (next.score || 0),
          }),
          {
            accuracy_rate: 0,
            answer_time: 0,
            acquisition_score: 0,
            average_answer_time: 0,
            correct: 0,
            score: 0,
          }
        );
      state.dataDetailSkillCheckResult = [
        {
          accuracy_rate: accuracy_rate / dataLength,
          acquisition_score: acquisition_score,
          answer: '',
          answer_time: answer_time,
          average_answer_time: average_answer_time,
          code: '',
          company_id: '',
          correct: correct,
          description: '',
          i_id: '',
          login_id: '',
          name: '',
          score: score,
          skill_check_code: '',
          sort_order: 0,
          user_answer: '',
        },
        ...newDataDetailSkillCheckResult,
      ];
      state.totalDetailSkillCheckResult = dataLength;
    });
    builder.addCase(getDataTableCurriculumUser.fulfilled, (state, action) => {
      const { correct_answers_num, question_count, responses_num, answer_time, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            question_count: prev.question_count + (next.question_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            answer_time: prev.answer_time + (next.answer_time || 0),
            acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
          }),
          {
            correct_answers_num: 0,
            question_count: 0,
            responses_num: 0,
            answer_time: 0,
            acquisition_score: 0,
          }
        );
      const dataLength = action.payload.report_results.length;

      state.dataTabUserCurriculum = {
        i_id: '',
        company_id: '',
        curriculum_code: '',
        curriculum_name: '',
        login_id: '',
        user_name: '',
        question_count: question_count / dataLength,
        responses_num: responses_num / dataLength,
        correct_answers_num: correct_answers_num / dataLength,
        answer_time: answer_time,
        acquisition_score: acquisition_score,
        accuracy_rate: (correct_answers_num / question_count) * 100,
        progress_rate: (responses_num / question_count) * 100,
        time_rate: answer_time / responses_num,
      };

      state.dataTableCurriculum = action.payload.report_results;
    });

    builder.addCase(getDataTableCurriculumUserAll.fulfilled, (state, action) => {
      const { correct_answers_num, question_count, responses_num, answer_time, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            question_count: prev.question_count + (next.question_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            answer_time: prev.answer_time + (next.answer_time || 0),
            acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
          }),
          {
            correct_answers_num: 0,
            question_count: 0,
            responses_num: 0,
            answer_time: 0,
            acquisition_score: 0,
          }
        );

      state.dataTabAllCurriculum = {
        i_id: '',
        company_id: '',
        curriculum_code: '',
        curriculum_name: '',
        login_id: '',
        user_name: '',
        question_count: question_count,
        responses_num: responses_num,
        correct_answers_num: correct_answers_num,
        answer_time: answer_time,
        acquisition_score: acquisition_score,
        accuracy_rate: (correct_answers_num / question_count) * 100,
        progress_rate: (responses_num / question_count) * 100,
        time_rate: (answer_time / responses_num) * 100,
      };
    });

    builder.addCase(getDataDetailCurriculumResult.fulfilled, (state, action) => {
      const dataDetailCurriculumUser = action.payload.report_results.filter(
        (item) => item.login_id === action.payload.login_id
      );
      const newDataDetailCurriculumResult: Types.DetailOfCurriculumResults.ResponseType[] = [];
      dataDetailCurriculumUser.forEach((item) => {
        const dataAllCorrect = action.payload.report_results.filter((c) => c.code === item.code);
        const dataAccuracy = action.payload.report_results.filter(
          (c) => c.code === item.code && c.correct === 1
        );
        newDataDetailCurriculumResult.push({
          ...item,
          accuracy_rate: dataAccuracy.length / dataAllCorrect.length,
          average_answer_time:
            dataAllCorrect.reduce(
              (previousValue, currentValue) =>
                previousValue + (Number(currentValue.answer_time) || 0),
              0
            ) / dataAllCorrect.length,
          acquisition_score: item.correct === 1 ? item.score : 0,
        });
      });
      state.dataDetailCurriculumResult = newDataDetailCurriculumResult;
    });

    builder.addCase(getCurriculumLevelOption.fulfilled, (state, action) => {
      switch (action.payload.level) {
        case 1:
          state.selectLevel1 = action.payload.data.report_results;
          break;
        case 2:
          state.selectLevel2 = action.payload.data.report_results;
          break;
        case 3:
          state.selectLevel3 = action.payload.data.report_results;
          break;
        case 4:
          state.selectLevel4 = action.payload.data.report_results;
          break;
        default:
          break;
      }
    });

    builder.addCase(getDataTrainingCurriculum.fulfilled, (state, action) => {
      const dataLength = action.payload.report_results.length;
      const {
        correct_answers_num,
        question_count,
        inexperienced_rate,
        wrong_rate,
        accuracy_rate,
        inexperienced,
        incorrect_answer,
        responses_num,
        real_accuracy_rate,
        progress_rate,
        progress_rate_user_average,
        accuracy_rate_user_average,
        score,
      } = action.payload.report_results.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + (next.question_count || 0),
          inexperienced_rate: prev.inexperienced_rate + next.inexperienced_rate,
          wrong_rate: prev.wrong_rate + next.wrong_rate,
          accuracy_rate: prev.accuracy_rate + next.accuracy_rate,
          inexperienced: prev.inexperienced + (next.inexperienced || 0),
          incorrect_answer: prev.incorrect_answer + next.incorrect_answer,
          responses_num: prev.responses_num + next.responses_num,
          real_accuracy_rate: prev.real_accuracy_rate + next.real_accuracy_rate,
          progress_rate: prev.progress_rate + next.progress_rate,
          progress_rate_user_average:
            prev.progress_rate_user_average + (next.progress_rate_user_average || 0),
          accuracy_rate_user_average:
            prev.accuracy_rate_user_average + (next.accuracy_rate_user_average || 0),
          score: prev.score + (next.score || 0),
        }),
        {
          correct_answers_num: 0,
          question_count: 0,
          inexperienced_rate: 0,
          wrong_rate: 0,
          accuracy_rate: 0,
          inexperienced: 0,
          incorrect_answer: 0,
          responses_num: 0,
          real_accuracy_rate: 0,
          progress_rate: 0,
          progress_rate_user_average: 0,
          accuracy_rate_user_average: 0,
          score: 0,
        }
      );

      state.dataReportLevelsCurriculumUser = [
        {
          user_name: '',
          login_id: '',
          curriculum_name: '',
          curriculum_code: '',
          level1_code: '',
          level1_name: '',
          level2_code: '',
          level2_name: '',
          level3_code: '',
          level3_name: '',
          level4_code: '',
          level4_name: '',
          question_code: '',
          question_name: '',
          updatedat: new Date(),
          implementation_date: new Date(),
          correct_answers_num:
            action.payload.level === 'login_id'
              ? correct_answers_num / dataLength
              : correct_answers_num,
          incorrect_answer:
            action.payload.level === 'login_id' ? incorrect_answer / dataLength : incorrect_answer,
          inexperienced:
            action.payload.level === 'login_id' ? inexperienced / dataLength : inexperienced,
          question_count:
            action.payload.level === 'login_id' ? question_count / dataLength : question_count,
          responses_num:
            action.payload.level === 'login_id' ? responses_num / dataLength : responses_num,
          accuracy_rate: accuracy_rate / dataLength,
          wrong_rate: wrong_rate / dataLength,
          inexperienced_rate: inexperienced_rate / dataLength,
          real_accuracy_rate: real_accuracy_rate / dataLength,
          progress_rate: progress_rate / dataLength,
          progress_rate_user_average: progress_rate_user_average / dataLength,
          accuracy_rate_user_average: accuracy_rate_user_average / dataLength,
          score: score,
        },
        ...action.payload.report_results,
      ];

      state.totalReportLevelsCurriculumUser = dataLength;
    });
  },
});

export const { resetInitialState } = myChartDashboardSlice.actions;

export default myChartDashboardSlice.reducer;
