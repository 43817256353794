import styled from 'styled-components';

export const Wrapper = styled.div`
  .row {
    height: auto;
    display: flex;
    align-items: center;
    .box_icon {
      height: 36px;
      margin-left: 2px;
      border-radius: 1px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .rowTitle {
      margin-left: 10px;
    }
    .icon {
      margin: 0 2px;
    }
  }
`;
