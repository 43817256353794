import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { getDataUserReport, getUserAssignCurriculum } from './thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getAffiliationAssignRole } from 'containers/Auth/thunk';
import { authSelector } from 'containers/Auth/selectors';
import CurriculumReport from './CurriculumReport';
import TrainingReportStyled from './styles';
import { useAppDispatch } from 'hooks';
import UserReport from './UserReport';
import { Header } from 'components';
import * as Types from 'types';

const { Option } = Select;

const TrainingReport: React.FC = () => {
  const [valueRequiredCurriculum, setValueRequiredCurriculum] = useState<number>(0);
  const [type, setType] = useState<'user-report' | 'curriculumn-report'>('user-report');
  const [itemComparision] = useState<
    'progress_rate' | 'answer_breakdown' | 'answer_breakdown_percentage'
  >();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());

      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
      ];

      await dispatch(
        getAffiliationAssignRole({
          conditions,
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_links: true,
        })
      );

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());

      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
      ];

      if (valueRequiredCurriculum) {
        await dispatch(
          getUserAssignCurriculum({
            conditions,
            page: 1,
            per_page: 0,
          })
        );
      }

      await dispatch(
        getDataUserReport({
          conditions: [
            ...conditions,
            {
              id: 'required_curriculum',
              search_value: [String(valueRequiredCurriculum)],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, valueRequiredCurriculum]);

  return (
    <TrainingReportStyled itemComparision={itemComparision}>
      <Header title={headerTitle} />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">トレーニング種類</span>
            <Select
              value={valueRequiredCurriculum}
              onSelect={(value: number) => {
                setValueRequiredCurriculum(value);
              }}
              className="select"
            >
              <Option value={0}>カリキュラム</Option>
              <Option value={1}>必修カリキュラム</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの選択</span>
            <Select
              className="select"
              defaultValue="user-report"
              onChange={(e: typeof type) => setType(e)}
            >
              <Option value="user-report">ユーザーレポート</Option>
              <Option value="curriculumn-report">カリキュラムレポート</Option>
            </Select>
          </div>
        </div>
      </div>
      {type === 'user-report' ? (
        <UserReport type={type} valueRequiredCurriculum={valueRequiredCurriculum} />
      ) : (
        <CurriculumReport type={type} valueRequiredCurriculum={valueRequiredCurriculum} />
      )}
    </TrainingReportStyled>
  );
};

export default TrainingReport;
