import styled from 'styled-components';

export default styled.section`
  overflow: hidden;
  .header .title-page {
    font-weight: normal;
  }
  .container {
    padding: 30px;
    .description {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .content {
        font-size: 12px;
        color: #424242;
      }
      .authorization {
        font-size: 12px;
        color: #777777;
        padding-bottom: 3px;
        border-bottom: 1px solid #bbbbbb;
        &_code {
          font-family: Lato;
          font-size: 16px;
          color: #424242;
        }
      }
      .btn-div {
        display: flex;
        position: absolute;
        right: 0;
        margin: 12px 30px;
        z-index: 9;
        .btn {
          width: 160px;
          height: 40px;
          padding: 0 20px;
          height: 40px;
          cursor: pointer;
          font-size: 13px;
          margin-left: 8px;
        }
        .btn-active {
          display: flex;
          align-items: center;
          background-color: #f6ac00;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          font-weight: bold;
          border-radius: 5px;
          color: #ffffff;
          .size-icon {
            margin-right: 12px;
            font-size: 16px;
          }
          .icon-cloud {
            font-size: 22px;
          }
        }
        .ant-btn > .anticon {
          line-height: 0;
        }
      }
    }
  }
`;
