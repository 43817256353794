import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Select, Table } from 'antd';
import { findIndex } from 'lodash';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditAnalysisGroup } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { Header, Input, SelectField } from 'components';
import CompletedModal from 'components/Modal/Completed';
import { analysisGroupSelector } from './selectors';
import exportPDF from 'libs/utils/exportPdf';
import AnalysisGroupPDF from './AnalysisPDF';
import WrapperStyled from './styles';
import NotDelete from './Modal';
import * as Types from 'types';
import {
  createAnalysisGroup,
  deleteAnalysisGroup,
  getAnalysisGroup,
  getAnalysisGroupCSV,
  getAnalysisGroupOptions,
  getSkillCheck,
  updateAnalysisGroup,
} from './thunk';

const { Option } = Select;

const AnalysisGroup: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<Types.Groups.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.Groups.ResponseType>();
  const [completedModalTitle, setCompletedModalTitle] = useState<string>('');
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [isOpenNotDelete, setIsOpenNotDelete] = useState<boolean>(false);
  const [searchByName, setSearchByName] = useState<string>();
  const [perPage, setPerpage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { searchOptions, items, total, skillChecks } = useSelector(analysisGroupSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { messages } = useIntl();

  const ref = useRef(null);

  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const formikSearch = useFormik<Types.AnalysisGroupSearchFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }, { setSubmitting }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する権限を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        setSearchByName(name);
      }
      setSubmitting(false);
    },
    onReset: () => {
      setSearchByName(undefined);
    },
  });

  const isEditing = (record: Types.Groups.ResponseType) => record.i_id === itemSelected?.i_id;

  const columns: any = [
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: '13%',
      align: 'center',
      sorter: (a: Types.Groups.ResponseType, b: Types.Groups.ResponseType) =>
        Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
    {
      title: messages['M-07-14'],
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.Groups.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              {messages['M-07-21']}
            </SubmitButton>
            <button
              type="button"
              className="btn btn_close"
              onClick={() => setItemSelected(undefined)}
            >
              {messages['M-21-22']}
            </button>
          </div>
        ) : (
          <FormOutlined
            className="icon"
            onClick={() => permissionNumber !== 1 && setItemSelected(record)}
          />
        );
      },
    },
    {
      title: messages['M-07-15'],
      width: '7%',
      dataIndex: 'i_id',
      align: 'left',
      render: (_: string, record: Types.Groups.ResponseType) =>
        itemSelected?.i_id !== record.i_id ? (
          <DeleteOutlined
            className="icon"
            onClick={() => {
              const index = findIndex(skillChecks, { group_code: record.code });
              if (permissionNumber !== 1) {
                if (index > -1) {
                  setIsOpenNotDelete(true);
                } else {
                  setItemDeleted(record);
                  setOpenModalConfirmDeleteItem(true);
                }
              }
            }}
          />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => (
    <Formik<Types.CreateEditAnalysisGroupFormik>
      initialValues={{
        name: itemSelected?.name || '',
      }}
      enableReinitialize
      validationSchema={CreateEditAnalysisGroup}
      onSubmit={async (values) => {
        if (!itemSelected) return;
        dispatch(startLoading());
        const resultAction = await dispatch(
          updateAnalysisGroup({
            id: itemSelected.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        setItemSelected(undefined);
        if (updateAnalysisGroup.fulfilled.match(resultAction)) {
          fetchData();
          fetchDataOptions();
          setCompletedModalTitle('更新が完了しました');
          setShowCompleteModal(true);
        } else {
          setActionModalState({
            subTitle: '役職の更新に失敗しました',
            description: (
              <p className="text-content">
                役職の更新に失敗しました。
                <br />
                もう一度情報を入力し、再度お試しください。
              </p>
            ),
          });
          setShowActionErrorModal(true);
        }
        dispatch(stopLoading());
      }}
    >
      <tr {...restProps}>{children}</tr>
    </Formik>
  );

  const EditableCell: React.FC<Types.TypeCell<Types.Groups.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: '※役職名称が未入力です',
                },
              ]}
            >
              <Input showCount maxLength={30} name="name" />
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell<Types.Groups.ResponseType>) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Types.Groups.ResponseType) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <AnalysisGroupPDF items={items} page={page} total={total} perPage={perPage} />
      </div>
    );
  }, [items, page, total, perPage]);

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        getAnalysisGroupCSV({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getAnalysisGroupCSV.fulfilled.match(resultAction)) {
        const listCsv: Array<Omit<Types.Groups.ResponseType, 'i_id'>> =
          resultAction.payload.items.length > 0
            ? resultAction.payload.items.map((item) => ({
                company_id: item.company_id,
                code: item.code,
                name: item.name,
                createdat: item.createdat,
                createdby: item.createdby,
              }))
            : [];
        const csvString = [
          ['会社ID', '分析グループコード', '分析グループ名'],
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '分析グループマスタ.csv');
      }
    }

    if (value === 'pdf') {
      exportPDF(ref, '分析グループマスタ');
    }

    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  const fetchData = useCallback(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getAnalysisGroup({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(searchByName
              ? [
                  {
                    id: 'name',
                    search_value: [searchByName],
                  },
                ]
              : []),
          ],
          page,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, page, searchByName, userInfo]);

  const fetchDataOptions = useCallback(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getAnalysisGroupOptions({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getSkillCheck({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(fetchData, [fetchData]);

  useEffect(fetchDataOptions, [fetchDataOptions]);

  return (
    <WrapperStyled collapsedMenu={collapsedMenu}>
      {component}
      <Header title={headerTitle} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            分析グループマスタの作成・管理を行う画面です。作成した分析グループをスキルチェックに設定します。
            <br />
            レポート機能を使用し、同一の分析グループが設定されたスキルチェックでは比較分析が可能になります。
            <br />
            定期テスト等で同じ設問のスキルチェックを実施する際に前回比較で成長率等を確認することができます。
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form layout="vertical" colon={false} className="form-search">
              <Form.Item
                name="name"
                label={<span className="label">分析グループ検索</span>}
                className="form-input"
              >
                <SelectField
                  name="name"
                  showSearch
                  className="select-input"
                  placeholder="選択してください"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {searchOptions.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  {messages['M-07-6']}
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">{messages['M-07-7']}</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>

          <div className="btn-div">
            <button
              className="btn btn-active btn-download"
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              <CloudDownloadOutlined className="size-icon" />
              {messages['M-07-10']}
            </button>
          </div>
          <Table
            dataSource={items.map((item, index) => ({ ...item, index }))}
            columns={mergedColumns}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            className={items.length > 0 ? 'table' : 'table_mr'}
            pagination={{
              showSizeChanger: false,
              total,
              current: page,
              pageSize: perPage,
              onChange: setPage,
              position: ['bottomCenter', 'topCenter'],
              showTotal: () => (
                <>
                  {total > 0 ? (
                    <div className="wrap-select-record">
                      <div className="text-count">
                        {(page - 1) * perPage + 1} - {''}
                        {page * perPage > total ? total : page * perPage} / {total}件
                      </div>
                      <div>
                        <span className="label-select">表示件数：</span>
                        <Select className="select-option" onChange={setPerpage} value={perPage}>
                          {[100, 50, 20, 10].map((e) => (
                            <Option value={e}>{e}</Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  ) : null}
                </>
              ),
            }}
            rowKey="index"
          />
        </div>
        <div className="wrap-create">
          <p className="title">分析グループ新規登録</p>
          <Formik<Types.CreateEditAnalysisGroupFormik>
            initialValues={{
              name: '',
            }}
            enableReinitialize
            validationSchema={CreateEditAnalysisGroup}
            onSubmit={async (values, { resetForm }) => {
              dispatch(startLoading());
              await dispatch(
                createAnalysisGroup({
                  item: {
                    company_id: userInfo?.company_id,
                    name: values.name,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              );
              fetchDataOptions();
              fetchData();
              resetForm();
              dispatch(stopLoading());
            }}
          >
            <Form colon={false} layout="vertical" className="form">
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    分析グループ名称
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  disabled={permissionNumber === 1}
                  name="name"
                  showCount
                  maxLength={30}
                  placeholder="最大30文字"
                />
              </Form.Item>
              <SubmitButton className={permissionNumber === 1 ? 'disabled' : 'btn_submit'}>
                <PlusOutlined className="size-icon" />
                新規登録
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title={completedModalTitle}
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します"
        description="データの削除を実行すると、復元できませんのでご注意ください。"
        visible={openModalConfirmDeleteItem}
        onSubmit={async () => {
          if (!itemDeleted) return;
          dispatch(startLoading());
          const resultAction = await dispatch(deleteAnalysisGroup({ id: itemDeleted.i_id }));
          if (deleteAnalysisGroup.fulfilled.match(resultAction)) {
            fetchDataOptions();
            setCompletedModalTitle('削除が完了しました');
            setShowCompleteModal(true);
          } else {
            setActionModalState({
              subTitle: '削除に失敗しました',
              description: (
                <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
              ),
            });
            setShowActionErrorModal(true);
          }
          setItemDeleted(undefined);
          dispatch(stopLoading());
        }}
        onCancel={() => {
          setItemDeleted(undefined);
        }}
        setVisible={setOpenModalConfirmDeleteItem}
      />
      <NotDelete isOpen={isOpenNotDelete} setIsOpen={setIsOpenNotDelete} />
    </WrapperStyled>
  );
};

export default AnalysisGroup;
