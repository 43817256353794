import { createAsyncThunk } from '@reduxjs/toolkit';
import { filter, find, groupBy, map, maxBy } from 'lodash';

import { services } from 'services';
import * as Types from 'types';
import {
  USER_ASSIGN_REQUIRED_CURRICULUM,
  QUESTION_ASSIGN_LEVEL,
  CURRICULUM_TRANS,
  QUESTION_TRANS2,
  CURRICULUMS,
  QUESTIONS,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  USERS,
} from 'configs';

export const getDataQuestionTrans2 = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionTrans2.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataCorrectQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionTrans2.ResponseType>(
      QUESTION_TRANS2.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumTrans = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTrans.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataCurriculumTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CurriculumTrans.ResponseType>(
      CURRICULUM_TRANS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestionAssignLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataQuestionAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.id,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculums = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculums.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1s.ResponseType>(LEVEL_1.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2s.ResponseType>(LEVEL_2.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3s.ResponseType>(LEVEL_3.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4s.ResponseType>(LEVEL_4.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getDataQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserAssignCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingReport/thunk/getUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignRequiredCurriculum.ResponseType>(
      USER_ASSIGN_REQUIRED_CURRICULUM.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserReport = createAsyncThunk<
  Types.GetItemResponseType<Types.ReportCurriculumUser.DataUserReport> & {
    dataQuesAssign: Types.QuestionAssignLevel.ResponseType[];
    dataCurriculums: Types.Curriculums.ResponseType[];
    dataQuestions: Types.Questions.ResponseType[];
    dataLevel1s: Types.Level1s.ResponseType[];
    dataLevel2s: Types.Level2s.ResponseType[];
    dataLevel3s: Types.Level3s.ResponseType[];
    dataLevel4s: Types.Level4s.ResponseType[];
    dataUsers: Types.Users.ResponseType[];
  },
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'TrainingReport/thunk/getDataUserReport',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

      const { authContainer } = getState() as Types.RootState;

      const conditions = [
        { id: 'company_id', search_value: [authContainer?.userInfo?.company_id] },
      ];

      const responseStatus = await Promise.all([
        dispatch(
          getDataLevel1({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel2({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel3({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel4({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuestionAssignLevel({
            conditions,
            include_item_ref: true,
            include_links: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuestion({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuestionTrans2({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataCurriculumTrans({
            conditions,
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUser({
            conditions: [
              ...conditions,
              {
                id: 'user_type',
                search_value: ['interview'],
                not_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      const resultArray: Array<Types.ReportCurriculumUser.DataUserReport> = [];

      if (
        getDataLevel1.fulfilled.match(responseStatus[0]) &&
        getDataLevel2.fulfilled.match(responseStatus[1]) &&
        getDataLevel3.fulfilled.match(responseStatus[2]) &&
        getDataLevel4.fulfilled.match(responseStatus[3]) &&
        getDataQuestionAssignLevel.fulfilled.match(responseStatus[4]) &&
        getDataQuestion.fulfilled.match(responseStatus[5]) &&
        getDataQuestionTrans2.fulfilled.match(responseStatus[6]) &&
        getDataCurriculumTrans.fulfilled.match(responseStatus[7]) &&
        getDataUser.fulfilled.match(responseStatus[8])
      ) {
        const dataCurriculumTrans = responseStatus[7].payload.items;
        const dataAssignLevel = responseStatus[4].payload.items;
        const dataCorrectQues = responseStatus[6].payload.items;
        const dataLevel1 = responseStatus[0].payload.items;
        const dataLevel2 = responseStatus[1].payload.items;
        const dataLevel3 = responseStatus[2].payload.items;
        const dataLevel4 = responseStatus[3].payload.items;
        const dataUsers = responseStatus[8].payload.items;
        const dataQues = responseStatus[5].payload.items;

        data.items.flatMap((item) => {
          const matchedLevel1Items = filter(dataLevel1, { curricullum_code: item.code });

          if (matchedLevel1Items.length) {
            matchedLevel1Items.flatMap((level1Item) => {
              const matchedLevel2Items = filter(dataLevel2, { level1_code: level1Item.code });

              if (matchedLevel2Items.length) {
                matchedLevel2Items.flatMap((level2Item) => {
                  const matchedLevel3Items = filter(dataLevel3, { level2_code: level2Item.code });

                  if (matchedLevel3Items.length) {
                    matchedLevel3Items.flatMap((level3Item) => {
                      const matchedLevel4Items = filter(dataLevel4, {
                        level3_code: level3Item.code,
                      });

                      if (matchedLevel4Items.length) {
                        matchedLevel4Items.flatMap((level4Item) => {
                          const baseObject = {
                            company_id: item.company_id,
                            curriculum_code: item.code,
                            curriculum_name: item.name,
                            updated_at:
                              find(dataCurriculumTrans, { curricullum_code: item.code })
                                ?.updated_at || undefined,
                            implementation_date:
                              find(dataCurriculumTrans, {
                                curricullum_code: item.code,
                              })?.implementation_date || undefined,
                            level1_name: level1Item.name,
                            level1_code: level1Item.code,
                            level2_name: level2Item.name,
                            level2_code: level2Item.code,
                            level3_name: level3Item.name,
                            level3_code: level3Item.code,
                            level4_name: level4Item.name,
                            level4_code: level4Item.code,
                          };

                          const matchedQuestionItems = filter(dataAssignLevel, {
                            level4_code: level4Item.code,
                          });

                          if (matchedQuestionItems.length) {
                            matchedQuestionItems.flatMap((quesAssign) => {
                              const quesObject = {
                                ...baseObject,
                                question_code: quesAssign.code,
                                question_name: find(dataQues, { code: quesAssign.code })?.name,
                                question_count_lv4: matchedQuestionItems.length,
                              };
                              if (quesAssign.item_links?.links?.length) {
                                const questionTrans = map(
                                  quesAssign?.item_links?.links?.find(
                                    (link) => link.d_id === QUESTION_TRANS2.id
                                  )?.items || [],
                                  (tranId) => find(dataCorrectQues, { i_id: tranId.i_id }) || {}
                                ) as Types.QuestionTrans2.ResponseType[];

                                if (!questionTrans.length) {
                                  resultArray.push(quesObject);
                                } else {
                                  const questionTransUnique = map(
                                    groupBy(questionTrans, 'login_id'),
                                    (loginGroup) => maxBy(loginGroup, 'createdat')
                                  );
                                  questionTransUnique?.map((quesTran) => {
                                    resultArray.push({
                                      ...quesObject,
                                      question_code: quesAssign.code,
                                      answer_time: quesTran?.answer_time,
                                      login_id: quesTran?.login_id,
                                      user_name: find(dataUsers, { login_id: quesTran?.login_id })
                                        ?.name,
                                      correct: quesTran?.correct,
                                      user_answer: quesTran?.user_answer,
                                    });
                                  });
                                }
                              } else {
                                resultArray.push(quesObject);
                              }
                            });
                          } else {
                            resultArray.push(baseObject);
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }

      return {
        ...data,
        items: [...resultArray],
        dataLevel1s: getDataLevel1.fulfilled.match(responseStatus[0])
          ? responseStatus[0].payload.items
          : [],
        dataLevel2s: getDataLevel2.fulfilled.match(responseStatus[1])
          ? responseStatus[1].payload.items
          : [],
        dataLevel3s: getDataLevel3.fulfilled.match(responseStatus[2])
          ? responseStatus[2].payload.items
          : [],
        dataLevel4s: getDataLevel4.fulfilled.match(responseStatus[3])
          ? responseStatus[3].payload.items
          : [],
        dataQuesAssign: getDataQuestionAssignLevel.fulfilled.match(responseStatus[4])
          ? responseStatus[4].payload.items
          : [],
        dataQuestions: getDataQuestion.fulfilled.match(responseStatus[5])
          ? responseStatus[5].payload.items
          : [],
        dataUsers: getDataUser.fulfilled.match(responseStatus[8])
          ? responseStatus[8].payload.items
          : [],
        dataCurriculums: data.items,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
