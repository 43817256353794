import React, { useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import { includes } from 'lodash';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckUserReportSelector } from '../selectors';
import { ReportSkillCheckUserTrans } from 'types';
import Wrapper from './styles';

const PER_PAGE = 10;

interface Props {
  isUserTransTable: boolean;
  exportPdf?: boolean;
}

export const SurfaceTable: React.FC<Props> = ({ isUserTransTable, exportPdf }) => {
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);

  const { reportSkillCheckUserTrans, totalReportSkillCheckUserTrans, time_limit } = useSelector(
    skillCheckUserReportSelector
  );

  const columns: ColumnsType<ReportSkillCheckUserTrans.UserReportSurfaceTable> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '1%',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'index',
                align: 'center',
                width: '1%',
                render: (_text, _record, index) => (
                  <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
                    {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
                  </span>
                ),
              },
            ]
          : undefined,
    },
    {
      title: isUserTransTable ? 'ユーザー' : 'スキルチェック',
      dataIndex: isUserTransTable ? 'user_name' : 'skill_check_name',
      key: isUserTransTable ? 'user_name' : 'skill_check_name',
      className: 'br-1',
      width: '9%',
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: 'ユーザー平均',
                dataIndex: 'user_name',
                key: 'user_name',
                width: '9%',
              },
            ]
          : undefined,
    },
    {
      title: '正解',
      dataIndex: 'correct_answers_num',
      key: 'correct_answers_num',
      align: 'right',
      width: '6%',
      sorter: (a, b) => a.correct_answers_num! - b.correct_answers_num!,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(reportSkillCheckUserTrans[0]?.correct_answers_num),
                dataIndex: 'correct_answers_num',
                key: 'correct_answers_num',
                align: 'right',
                width: '6%',
              },
            ]
          : undefined,
    },
    {
      title: '不正解',
      dataIndex: 'incorrect_answer',
      key: 'incorrect_answer',
      align: 'right',
      width: '6%',
      sorter: (a, b) => a.incorrect_answer - b.incorrect_answer,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>{formatNumber(value)}</span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0</span>
        ),
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(reportSkillCheckUserTrans[0]?.incorrect_answer),
                dataIndex: 'incorrect_answer',
                key: 'incorrect_answer',
                align: 'right',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value)}
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '未経験',
      dataIndex: 'inexperienced',
      key: 'inexperienced',
      align: 'right',
      width: '6%',
      sorter: (a, b) => a.inexperienced - b.inexperienced,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>{formatNumber(value)}</span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0</span>
        ),
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(reportSkillCheckUserTrans[0]?.inexperienced),
                dataIndex: 'inexperienced',
                key: 'inexperienced',
                align: 'right',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value)}
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '総設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      className: 'br-1',
      align: 'right',
      width: '6%',
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: formatNumber(reportSkillCheckUserTrans[0]?.probs_count),
                dataIndex: 'probs_count',
                key: 'probs_count',
                align: 'right',
                width: '6%',
              },
            ]
          : undefined,
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      align: 'center',
      width: '6%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(reportSkillCheckUserTrans[0]?.accuracy_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'accuracy_rate',
                key: 'accuracy_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    '-'
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      align: 'center',
      width: '7%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(reportSkillCheckUserTrans[0]?.wrong_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'wrong_rate',
                key: 'wrong_rate',
                align: 'center',
                width: '7%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    '-'
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '未経験率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      className: 'br-1',
      align: 'right',
      width: '7%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '0%'
        ),
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(reportSkillCheckUserTrans[0]?.inexperienced_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'inexperienced_rate',
                key: 'inexperienced_rate',
                align: 'right',
                width: '7%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    '0%'
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '制限時間',
      dataIndex: 'question_time_limit',
      key: 'question_time_limit',
      align: 'center',
      width: '6%',
      render: (record) => convertTime(time_limit[record - 1]?.name) || '-',
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: convertTime(
                  time_limit[reportSkillCheckUserTrans[0]?.question_time_limit - 1]?.name
                ),
                dataIndex: 'question_time_limit',
                key: 'question_time_limit',
                align: 'center',
                width: '6%',
                render: (record) => convertTime(time_limit[record - 1]?.name) || '-',
              },
            ]
          : undefined,
    },
    {
      title: '解答時間',
      dataIndex: 'total_answer_time',
      key: 'total_answer_time',
      align: 'center',
      width: '7%',
      render: (record) => formatNumberToMinute(record) || '-',
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumberToMinute(reportSkillCheckUserTrans[0]?.total_answer_time),
                dataIndex: 'total_answer_time',
                key: 'total_answer_time',
                align: 'center',
                width: '7%',
                render: (record) => formatNumberToMinute(record) || '-',
              },
            ]
          : undefined,
    },
    {
      title: (
        <>
          設問あたり
          <br />
          平均解答時間
        </>
      ),
      dataIndex: 'average_answer_time_per_question',
      key: 'average_answer_time_per_question',
      align: 'left',
      width: '9%',
      className: 'br-1',
      render: (record) => formatNumberToMinute(record) || '-',
      sorter: (a, b) => a.average_answer_time_per_question - b.average_answer_time_per_question,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: formatNumberToMinute(
                  reportSkillCheckUserTrans[0]?.average_answer_time_per_question
                ),
                dataIndex: 'average_answer_time_per_question',
                key: 'average_answer_time_per_question',
                align: 'center',
                width: '9%',
                render: (record) => formatNumberToMinute(record) || '-',
              },
            ]
          : undefined,
    },
    {
      title: (
        <>
          設定
          <br />
          スコア
        </>
      ),
      dataIndex: 'setting_score',
      key: 'setting_score',
      align: 'left',
      width: '6%',
      render: (record) => record || '-',
      sorter: (a, b) => a.setting_score - b.setting_score,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(reportSkillCheckUserTrans[0]?.setting_score),
                dataIndex: 'setting_score',
                key: 'setting_score',
                align: 'center',
                width: '6%',
                render: (record) => record || '-',
              },
            ]
          : undefined,
    },
    {
      title: (
        <>
          獲得
          <br />
          スコア
        </>
      ),
      dataIndex: 'acquisition_score',
      key: 'acquisition_score',
      align: 'left',
      width: '6%',
      render: (record) => formatNumber(record) || '-',
      sorter: (a, b) => a.acquisition_score - b.acquisition_score,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(reportSkillCheckUserTrans[0]?.acquisition_score),
                dataIndex: 'acquisition_score',
                key: 'acquisition_score',
                align: 'center',
                width: '6%',
                render: (record) => formatNumber(record) || '-',
              },
            ]
          : undefined,
    },
    {
      title: (
        <>
          獲得
          <br />
          スコア率
        </>
      ),
      key: 'acquisition_score_rate',
      dataIndex: 'acquisition_score_rate',
      align: 'left',
      width: '6%',
      className: 'br-1',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.acquisition_score_rate - b.acquisition_score_rate,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(reportSkillCheckUserTrans[0]?.acquisition_score_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                key: 'acquisition_score_rate',
                dataIndex: 'acquisition_score_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    '-'
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '実施状況',
      dataIndex: 'implementation_date',
      align: 'center',
      key: 'implementation_date',
      width: '6%',
      render: (record) =>
        record ? (
          dayjs(record).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        ),
      sorter: (a, b) =>
        a.implementation_date && b.implementation_date
          ? new Date(a.implementation_date).getTime() - new Date(b.implementation_date).getTime()
          : -1,
      children:
        totalReportSkillCheckUserTrans && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'implementation_date',
                key: 'implementation_date',
                width: '6%',
                align: 'center',
                render: (record) =>
                  record ? (
                    dayjs(record).format('YYYY/MM/DD')
                  ) : (
                    <span style={{ color: '#F0330A' }}>未実施</span>
                  ),
              },
            ]
          : undefined,
    },
  ];

  return (
    <Wrapper>
      {totalReportSkillCheckUserTrans ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportSkillCheckUserTrans} <span className="text-static"> 件表示</span> /
                {totalReportSkillCheckUserTrans}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportSkillCheckUserTrans}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> {''} / {total}{' '}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}

      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={reportSkillCheckUserTrans.map((item, index) => ({ ...item, index })).slice(1)}
        columns={columns}
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        bordered
        scroll={{ x: 1180 }}
        pagination={false}
      />
    </Wrapper>
  );
};

export default SurfaceTable;

export const convertTime = (value: string) => {
  if (!value) return 0;
  if (includes(value, '分')) {
    const time = Number(value.split('分').join(''));
    return `${time} : 00`;
  }
  if (includes(value, '時間')) {
    const time = Number(value.split('時間').join('')) * 60;
    return `${time} : 00`;
  }
  const time = Number(value?.split('秒').join('')) || 60;
  return `00 : ${time}`;
};
