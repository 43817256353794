import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .ant-tabs-tab {
      height: 60px;
    }
  }
  .ant-tabs-tab {
    padding: 25px 0px 24px 0px;
    line-height: 11px;
  }
  .sub-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    width: 100%;
    padding: 0 32px;
    .tab-container {
      .ant-tabs-nav {
        margin: 0;
        color: #424242;

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-weight: 700;
            font-size: 14px;
            color: #08a3a5;
          }
        }
        .ant-tabs-tab:hover {
          color: #08a3a5;
        }
        .ant-tabs-ink-bar {
          background-color: #08a3a5;
        }
        &:before {
          content: none;
        }
      }
    }
    .filter {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        font-size: 12px;
        color: #424242;
      }
      .select {
        width: 228px;
        .ant-select-selector {
          border-radius: 3px;
        }
      }
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    padding: 40px 30px;
  }
`;
