import { createAsyncThunk } from '@reduxjs/toolkit';

import { CARD_COMPANY_NAME, COMPANIES, GMO, PAYMENT_MASTER } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const updateCreditCard = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<{ name: string }>,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/updateCreditCard', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(GMO.name, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateGMO = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.UpdateGMO,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/updateGMO', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.updateGMO('customerupdate', GMO.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPaymentMaster = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PaymentMaster.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/getPaymentMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PaymentMaster.ResponseType>(
      PAYMENT_MASTER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCreditCardInfo = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.PaymentMaster.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/updateSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCardCompanyName = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.GetCardCompanyNameType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/getCardCompanyName', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.GetCardCompanyNameType>(
      CARD_COMPANY_NAME.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getItemIdGMO = createAsyncThunk<
  Types.GetItemResponseType<Types.GMO.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/getItemIdGMO', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.GMO.ResponseType>(GMO.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAdminEmail = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PaymentMaster/thunk/getAdminEmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
