import React from 'react';
import { CreditCardOutlined, ExclamationCircleOutlined, FileOutlined } from '@ant-design/icons';

import { ChangePaymentStyled } from './styles';
import { ArrowRight } from 'assets';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  disabled: boolean;
  paymentMethod: 1 | 2;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => Promise<void> | void;
}

const ModalChangePaymentMethod: React.FC<Props> = ({
  paymentMethod,
  disabled,
  setVisible,
  visible,
  onSubmit,
}) => {
  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
  };

  return (
    <Modal
      title="お支払い方法の変更"
      visible={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={
        !disabled
          ? {
              text: '変更する',
              onClick: handleSubmit,
            }
          : undefined
      }
      cancelButton={{
        text: !disabled ? 'キャンセル' : '閉じる',
        onClick: handleToggleModal,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <ChangePaymentStyled>
        <div className="content">
          <div className="wrap-content-top">
            <div className="wrap-content-item">
              <div className="content-item">
                {paymentMethod === 1 ? (
                  <>
                    <CreditCardOutlined className="item-icon" />
                    <span className="content-item-text">クレジット</span>
                  </>
                ) : (
                  <>
                    <FileOutlined className="item-icon" />
                    <span className="content-item-text">請求書発行</span>
                  </>
                )}
              </div>
              <div>
                <img src={ArrowRight} alt="arrow-icon" />
              </div>
              <div className="content-item">
                {paymentMethod === 1 ? (
                  <>
                    <span className="content-item-text">請求書発行</span>
                    <FileOutlined className="item-icon" />
                  </>
                ) : (
                  <>
                    <span className="content-item-text">クレジット</span>
                    <CreditCardOutlined className="item-icon" />
                  </>
                )}
              </div>
            </div>
          </div>
          {!disabled ? (
            <div className="wrap-content-bottom">
              <div className="content-bottom-note">
                <span>※お支払い方法の変更を行った月の翌月利用分から変更になります。</span>
                <span>当月利用分は変更前のお支払い方法になりますので、ご注意ください。</span>
              </div>
              <span className="content-bottom-subtext">
                {paymentMethod === 1
                  ? '請求書発行は毎月1日にご利用状況画面から確認できます。'
                  : 'クレジットカード支払は有効期限や限度額にご注意ください。'}
              </span>
            </div>
          ) : (
            <div className="wrap-content-bottom">
              <div className="content-bottom-note-warning">
                <ExclamationCircleOutlined className="icon-warrning" />
                <span className="text-warrning">変更できません</span>
              </div>
              <div className="content-bottom-subtext-warning">
                <span>※請求書発行でのお支払いは、ユーザー数が500ユーザー以上の場合にのみ</span>
                <span>ご対応可能になっております。</span>
              </div>
            </div>
          )}
        </div>
      </ChangePaymentStyled>
    </Modal>
  );
};

export default ModalChangePaymentMethod;
