import { createSlice } from '@reduxjs/toolkit';

import { getSelectUser, getPositionUser, getDepartmentUser, getDataSearchUsers } from './thunk';
import * as Types from 'types';

interface InitialState {
  list_users: Types.Users.ResponseType[];
  data_departments: Types.AffiliationLevel.ResponseType[];
  position_code: Types.Positions.ResponseType[];
  data_user: Types.SelectUsers.ResponseType[];
}

const initialState: InitialState = {
  list_users: [],
  data_user: [],
  data_departments: [],
  position_code: [],
};

export const menuRightUserSlice = createSlice({
  name: 'menuRight-Slice',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSelectUser.fulfilled, (state, action) => {
      state.data_user = action.payload.report_results;
    });
    builder.addCase(getPositionUser.fulfilled, (state, action) => {
      state.position_code = action.payload.items;
    });
    builder.addCase(getDepartmentUser.fulfilled, (state, action) => {
      state.data_departments = action.payload.items;
    });
    builder.addCase(getDataSearchUsers.fulfilled, (state, action) => {
      state.list_users = action.payload.items;
    });
  },
});

export const { resetInitialState } = menuRightUserSlice.actions;

export default menuRightUserSlice.reducer;
