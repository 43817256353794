import { filter, unionBy, uniqBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

import { getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import * as Types from 'types';
import {
  getCompany,
  getSelectCurriculum,
  getSelectQuestionCreator,
  getSelectQuestion,
  getSelectTypes,
  getDataAllQuestion,
  getDataQuestionCurriculum,
} from './thunk';

export interface InitialState {
  dataQuestion: Array<Types.TreeTraining>;
  curriculumNameSelect: Array<Types.SelectCurriculums.ResponseType>;
  curriculumTypeSelect: Array<Types.SelectTypes.ResponseType>;
  curriculumCreatorSelect: Array<Types.SelectCurriculumCreator.ResponseType>;
  questionSelect: Array<Types.SelectQuestion.ResponseType>;
  dataAllQuestion: Array<Types.Questions.ResponseType>;
  scoreSelect: Array<Types.SelectTypes.ResponseType>;
  timeLimit: Array<Types.TimeLimitType>;
  defaultTimeLimit: number;
  dataContent: Array<any>;
  total: number;
}

const initialState: InitialState = {
  dataQuestion: [],
  curriculumCreatorSelect: [],
  curriculumNameSelect: [],
  curriculumTypeSelect: [],
  questionSelect: [],
  dataAllQuestion: [],
  scoreSelect: [],
  timeLimit: [],
  dataContent: [],
  defaultTimeLimit: 0,
  total: 0,
};

export const questionMasterSlice = createSlice({
  name: 'questionMaster-page',
  initialState,
  reducers: {
    filterCurriculum: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(
          state.dataQuestion,
          (e) => e.curriculum_code === action.payload
        );
      }
    },
    filterRequiredCurriculumCurriculum: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(
          state.dataQuestion,
          (e) => e.required_curriculum === action.payload
        );
      }
    },
    filterCreator: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.creator === action.payload);
      }
    },
    filterQuestion: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.question_code === action.payload);
      }
    },
    filterScore: (state, action) => {
      if (action.payload) {
        state.dataQuestion = filter(state.dataQuestion, (e) => e.score === action.payload);
      }
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataQuestionCurriculum.fulfilled, (state, action) => {
      state.dataQuestion = action.payload.items;
    });
    builder.addCase(getDataAllQuestion.fulfilled, (state, action) => {
      state.dataAllQuestion = action.payload.items;
    });
    builder.addCase(getSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = action.payload.report_results;
    });
    builder.addCase(getSelectQuestionCreator.fulfilled, (state, action) => {
      state.curriculumCreatorSelect = unionBy(action.payload.report_results, 'creator');
    });
    builder.addCase(getSelectQuestion.fulfilled, (state, action) => {
      state.questionSelect = action.payload.report_results;
    });
    builder.addCase(getCompany.fulfilled, (state, action) => {
      state.defaultTimeLimit = Number(action.payload.items[0].time_limit);
    });
    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.timeLimit =
        action.payload.fields['65598664baeaf8d6328c95f3']?.options
          ?.filter((o: Types.TimeLimitType) => o.enabled)
          .sort((a: Types.TimeLimitType, b: Types.TimeLimitType) => a.sort_id - b.sort_id) || [];
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      const curriculumTypeSelect: Types.SelectTypes.ResponseType[] = [];
      const scoreSelect: Types.SelectTypes.ResponseType[] = [];
      action.payload.items.forEach((item) => {
        if (item.type === 'required_curriculum') {
          curriculumTypeSelect.push(item);
        } else if (item.type === 'score') {
          scoreSelect.push(item);
        }
      });
      state.curriculumTypeSelect = uniqBy(curriculumTypeSelect, 'name');
      state.scoreSelect = uniqBy(scoreSelect, 'name').sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  },
});

export const {
  resetInitialState,
  filterCurriculum,
  filterRequiredCurriculumCurriculum,
  filterCreator,
  filterQuestion,
  filterScore,
} = questionMasterSlice.actions;

export default questionMasterSlice.reducer;
