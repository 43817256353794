import React, { PropsWithChildren } from 'react';
import { CheckboxProps } from 'formik-antd';

import SectionStyled from './styles';

type Props = PropsWithChildren<CheckboxProps & {}>;

const CheckboxField: React.FC<Props> = ({ children, ...props }) => {
  return <SectionStyled {...props}>{children}</SectionStyled>;
};

export default CheckboxField;
