import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 860px;
  .text-center {
    text-align: center;
    color: #777777;
  }
  .error {
    color: #f0330a;
  }
  .form {
    width: 860px;
    margin-top: 30px;
    padding: 60px;
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .text-err {
      text-align: center;
      margin-top: -40px;
      color: #f0330a;
      font-size: 13px;
    }
    .form-input {
      .radio_group {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        .wrap-radio {
          width: 20%;
          .radio_item {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            height: 72px;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            margin-right: 0;
            .ant-radio {
              position: absolute;
              top: 6px;
              left: 6px;
            }
            & > span:nth-child(2) {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80%;
              height: 100%;
            }
          }
        }
        .border-right {
          border-right: 1px solid #d9d9d9;
        }
        .border-radius-left {
          border: 1px solid #d9d9d9;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        .border-radius-right {
          border: 1px solid #d9d9d9;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: none;
        }
        .label-text {
          font-size: 11px;
          margin: 5px 0 0 0;
          text-align: center;
          color: #777777;
          line-height: 1;
        }
      }
      .input,
      .input_small {
        height: 40px !important;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
      }
      .input_small {
        width: 83px;
      }
      .input_small_two {
        width: 20%;
        height: 40px;
      }
      .input-card-number {
        height: 40px;
        color: #424242;
        font-size: 14px;
        border: 1px solid #ccc;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 10px;
      }
      .pull_down {
        font-size: 14px;
        color: #424242;
        border-radius: 3px;
        :not(.ant-select-customize-input) .ant-select-selector {
          width: 100%;
          height: 40px;
          padding: 3px 11px;
          border-color: #ccc;
          border-radius: 3px;
        }
      }
      .text-label-content {
        font-size: 12px;
        color: #777777;
        margin: 0 10px 0 7px;
      }
      .text-label {
        font-size: 13px;
        color: #777777;
        .require {
          color: #f0330a;
        }
      }
    }
    .flex {
      .ant-form-item-control-input-content {
        display: flex;
        .content-text {
          font-size: 12px;
          color: #00a3a5;
          margin: 0 0 0 20px;
          line-height: 160%;
        }
      }
    }
    .wrap-security {
      width: 100%;
    }
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 20px 0;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
  }
`;
