import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import { secondStepSchema } from 'libs/validations';
import { RegisterInformationFormik } from 'types';
import { Input } from 'components';
import { Wrapper } from './styles';

interface Props {
  nextStep: () => void;
  previousStep: () => void;
}

const SecondStep: React.FC<Props> = ({ nextStep, previousStep }) => {
  const { information } = useSelector(informationRegisterSelector);

  const { messages } = useIntl();

  const formik = useFormik<RegisterInformationFormik>({
    initialValues: information,
    validationSchema: secondStepSchema,
    validateOnBlur: false,
    onSubmit: () => {
      nextStep();
    },
  });

  return (
    <Wrapper>
      <p className="text-center">この内容でよろしければ「登録」ボタンをクリックしてください</p>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  {messages['AJ-02-7']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="name" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-8']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="name_furigana" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  {messages['AJ-02-9']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="postal_code" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="prefecture"
              label={
                <span className="text-label">
                  {messages['AJ-02-11']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="prefecture" type="text" className="input__disabled input" />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  {messages['AJ-02-12']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="address" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="plot_number"
              label={
                <span className="text-label">
                  {messages['AJ-02-13']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="plot_number" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">{messages['AJ-02-14']}</span>}
              className="form-input"
            >
              <Input readOnly name="building_name" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  {messages['AJ-02-15']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="admin_name" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-16']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                readOnly
                name="admin_name_furigana"
                className="input__disabled input"
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="admin_department"
              label={
                <span className="text-label">
                  {messages['AJ-02-17']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                readOnly
                name="admin_department"
                className="input__disabled input"
                type="text"
              />
            </Form.Item>
            <Form.Item
              name="admin_position"
              label={
                <span className="text-label">
                  {messages['AJ-02-18']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="admin_position" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  {messages['AJ-02-19']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input readOnly name="admin_phone" className="input__disabled input" type="text" />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={<span className="text-label">{messages['AJ-02-20']}</span>}
              className="form-input"
            >
              <Input readOnly name="admin_email" className="input__disabled input" type="text" />
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">確認画面へ</SubmitButton>
            <button type="button" className="go-back" onClick={previousStep}>
              {`<  ${messages['back']}`}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </Wrapper>
  );
};

export default SecondStep;
