import React, { useCallback, useMemo, useState } from 'react';

import HeaderCondition from './HeaderOfficial';
import { FreeTrialStyled } from './styles';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourStep from './FourStep';
import FiveStep from './FiveStep';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormCondition: React.FC<Props> = ({ visible, setVisible, setOpen }) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [success, setSuccess] = useState(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    }
  }, [currentStep]);

  const nextStep = useCallback(() => {
    if (currentStep < 4) {
      setCurrentStep((prevState) => prevState + 1);
    }
  }, [currentStep]);

  const steps = useMemo(() => {
    switch (currentStep) {
      case 0:
        return <FirstStep nextStep={nextStep} />;
      case 1:
        return <SecondStep nextStep={nextStep} previousStep={previousStep} />;
      case 2:
        return <ThirdStep nextStep={nextStep} />;
      case 3:
        return <FourStep nextStep={nextStep} previousStep={previousStep} />;
      case 4:
        return (
          <FiveStep
            setVisible={setVisible}
            setOpen={setOpen}
            setSuccess={setSuccess}
            success={success}
          />
        );
    }
  }, [currentStep, nextStep, previousStep, setVisible, setOpen, success]);

  return (
    <FreeTrialStyled open={visible} onCancel={handleCloseModal}>
      <div className={`${success ? 'container-logo' : 'container'}`}>
        <HeaderCondition currentStep={currentStep} success={success} />
        <div>{steps}</div>
      </div>
    </FreeTrialStyled>
  );
};

export default FormCondition;
