import styled from 'styled-components';

export default styled.section`
  @media (max-width: 1440px) {
    .header-body .btn-add {
      width: 200px;
      height: 32px;
    }
    .wrap-tree {
      width: 988px;
    }
  }
  height: 100%;
  .header {
    position: sticky;
    top: 0;
    right: 0;
    z-index: 1000;

    .filter-icon {
      color: #aaaaaa;
      margin-right: 5px;
      font-size: 17px;
    }
    .filter {
      display: flex;
      align-items: center;
      .form-input {
        display: flex;
        margin-bottom: unset;
        .ant-select {
          font-size: 12px;
          .ant-select-selector {
            height: 36px;
            align-items: center;
            border-right: none;
            border-radius: 0;
          }
        }
        .ant-select-arrow {
          font-size: 9px;
        }
        .ant-select-selection-placeholder {
          padding-right: 0;
          font-size: 13px;
        }
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
        margin-left: 5px;
        font-size: 12px;
      }
    }
    .header__right {
      .imageQA {
        display: none;
      }
      .border {
        display: none;
      }
      .drop-down {
        display: none;
      }
    }
  }

  .flex-tree {
    display: flex;
    .dashboard {
      width: 100%;
      height: calc(100vh - 243px);
      overflow-y: auto;
      position: relative;
      .wrap-body {
        .header-body {
          display: flex;
          align-items: center;
          margin: 20px;
          .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 13px;
            color: #424242;
            margin: 0;
          }
          .btn-add,
          .disabled {
            background: #f6ac00;
            font-weight: bold;
            font-size: 12px;
            color: #ffffff;
            border: none;
            cursor: pointer;
            border-radius: 5px;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            padding: 5px 15px;
          }
          .disabled {
            background: #cccccc;
            color: #777777;
          }
        }
        .wrap-tree {
          width: 100%;
          background: #f2f2f2;
          border: 1px solid rgba(0, 0, 0, 0.05);
          padding: 24px 47px 20px 20px;
        }
        .bordered {
          border-bottom: 4px solid #eeeeee;
        }
      }
    }
  }
`;

interface ButtonProps {
  tabActive: number;
  index: number;
}

export const Button = styled.button<ButtonProps>`
  position: relative;
  width: 200px;
  height: 32px;

  cursor: pointer;
  font-weight: ${(props) => (props.tabActive === props.index ? 'bold' : 'unset')};
  font-size: 13px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  border-left: none;
  background-color: ${(props) =>
    props.tabActive === props.index ? (props.tabActive === 0 ? '#00a3a5' : '#EB8B55') : '#ffffff'};
  color: ${(props) =>
    props.tabActive === props.index ? '#ffffff' : props.tabActive === 0 ? '#00a3a5' : '#EB8B55'};
  border: 1px solid ${(props) => (props.tabActive === 0 ? '#00a3a5' : '#EB8B55')};
  .size-icon {
    position: absolute;
    left: 10px;
    top: 30%;
  }
  &:nth-child(2) {
    border-radius: 0 3px 3px 0;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
`;
