import yup from '../yup';

export const createInterviewUserSchema = yup.object().shape({
  manage_code: yup.string().required(),
  name: yup.string().required(),
  name_phonetic: yup.string().required(),
  email_address: yup.string().email().required(),
  email_address_confirmation: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email_address'), null], 'Email confirmation is not the same as email'),
  password: yup.string().required().max(30).min(4),
  password_confirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30)
    .min(4),
  affiliation_id: yup.string().required(),
  position_code: yup.string().required(),
  work_history: yup.string(),
});

export const updateInterviewSchema = yup.object().shape({
  manage_code: yup.string().required(),
  name: yup.string().required(),
  email_address: yup.string().required(),
  name_phonetic: yup.string().required(),
  position_code: yup.string().required(),
  affiliation_id: yup.string().required(),
});
