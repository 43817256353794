import { createAsyncThunk } from '@reduxjs/toolkit';

import { REPORT_SKILL_CHECK_USER_TRANS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getReportSkillCheckUserTrans = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType> & {
    startPeriod: string;
    endPeriod: string;
  },
  Types.ReportsItemRequestType & { startPeriod: string; endPeriod: string },
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReport/thunk/getReportSkillCheckUserTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      req
    );

    return { ...data, startPeriod: req.startPeriod, endPeriod: req.endPeriod };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckUserTrans2 = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType> & {
    firstSkillcheck: string;
    secondSkillcheck: string;
  },
  Types.ReportsItemRequestType & { firstSkillcheck: string; secondSkillcheck: string },
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReport/thunk/getReportSkillCheckUserTrans2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      req
    );

    return {
      ...data,
      firstSkillcheck: req.firstSkillcheck,
      secondSkillcheck: req.secondSkillcheck,
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckUserTransComparison = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckReport/thunk/getReportSkillCheckUserTransComparison',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
        REPORT_SKILL_CHECK_USER_TRANS.id,
        req
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
