import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleModalComplete?: () => void;
}

const CompleteModal: React.FC<Props> = ({
  visible,
  title,
  subTitle,
  setVisible,
  handleToggleModalComplete,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
    handleToggleModalComplete && handleToggleModalComplete();
  };

  return (
    <Modal
      title={<span className="title">{title}</span>}
      open={visible}
      width={720}
      onCancel={handleToggleModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <CheckCircleOutlined className="icon" />
          {subTitle}
        </p>
        <button className="btn-confirm" onClick={handleToggleModal}>
          OK
        </button>
      </SectionStyled>
    </Modal>
  );
};

export default CompleteModal;
