import React from 'react';

import BreakdownStyled from './styles';
import BarChart from './BarChart';
import PieChart from './PieChart';

interface Props {
  startPeriod?: string;
  endPeriod?: string;
  exportPdf?: boolean;
}

const Chart: React.FC<Props> = ({ startPeriod, endPeriod, exportPdf }) => {
  return (
    <BreakdownStyled>
      <div className="pie-chart">
        <div className="wrap-chart">
          <PieChart isStart time={startPeriod} exportPdf={exportPdf} />
        </div>
        <div className="text-center">
          <p>ユーザー平均</p>
        </div>
        <div className="wrap-chart">
          <PieChart time={endPeriod} exportPdf={exportPdf} />
        </div>
      </div>
      <div className="bar-chart">
        <div className="wrap-chart">
          <BarChart isStart />
        </div>
        <div className="text-center">
          <p>ユーザー別</p>
        </div>
        <div className="wrap-chart">
          <BarChart />
        </div>
      </div>
    </BreakdownStyled>
  );
};

export default Chart;
