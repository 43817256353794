import React from 'react';
import { Column } from '@ant-design/plots';
import { useSelector } from 'react-redux';

import { skillCheckUserAnalysisReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';
import { ColumnChartStyle } from './styles';

interface Props {
  isStart?: boolean;
}

const ColumnChart: React.FC<Props> = ({ isStart }) => {
  const { reportSkillCheckUserTrans } = useSelector(skillCheckUserAnalysisReportSelector);

  const addLineBreaks = (text: string, maxLength: number): string => {
    const isLatin = /^[a-zA-Z0-9\s]*$/;
    const hasWhitespace = /\s/;
    const isJapanese = /[\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF]/;

    let result = '';
    let latinLength = 0;
    let nonLatinLength = 0;

    for (const char of text) {
      if (char.match(isLatin)) {
        result += char === ' ' ? char + '\n' : char;
        latinLength++;
      } else {
        result += char + '\n';
        nonLatinLength++;
      }
    }

    if ((latinLength > maxLength || nonLatinLength > maxLength) && !text.match(hasWhitespace)) {
      result = result.slice(0, maxLength) + '\n' + '...';
    }

    if (text.match(isJapanese)) {
      result = result.slice(0, maxLength) + '\n' + '...';
    }

    return result;
  };

  return (
    <ColumnChartStyle>
      <Column
        data={reportSkillCheckUserTrans.slice(1).flatMap((value) => {
          const data = isStart ? value.start : value.end;
          return [
            {
              username: value.user_name,
              value: Number(formatNumber(data.inexperienced_rate * 100)),
              type: '未実施',
            },
            {
              username: value.user_name,
              value: Number(formatNumber(data.wrong_rate * 100)),
              type: '不正解',
            },
            {
              username: value.user_name,
              value: Number(formatNumber(data.accuracy_rate * 100)),
              type: '正解',
            },
          ];
        })}
        xField="username"
        yField="value"
        seriesField="type"
        isStack
        color={['#EBEBEB', '#ea959b', '#41a1d6']}
        maxColumnWidth={20}
        appendPadding={20}
        yAxis={{
          position: 'left',
          max: 100,
          min: 0,
          line: {
            style: {
              stroke: '#ddd',
            },
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [2, 2],
              },
            },
          },
          label: {
            style: {
              color: '#999999',
              fontSize: 11,
            },
            formatter: (item, _, index) => (index === 0 ? '獲得\nｽｺｱ率' : `${item}%`),
          },
          showLast: true,
          tickCount: 10,
        }}
        xAxis={{
          label: {
            style: {
              fill: '#999999',
              fontSize: 11,
              textAlign: 'center',
            },
            formatter: (item, _, index) =>
              `${formatNumber(
                reportSkillCheckUserTrans[index + 1].start.acquisition_score_rate * 100
              )}%\n${addLineBreaks(item, 5)}`,
          },
          tickLine: null,
        }}
        label={{
          position: 'middle',
          style: {
            fontWeight: 'bold',
            fill: '#fff',
          },
        }}
        legend={false}
      />
    </ColumnChartStyle>
  );
};

export default ColumnChart;
