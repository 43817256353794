import styled from 'styled-components';

interface Props {
  tableHeight?: number;
}

export const SectionStyled = styled.div<Props>`
  height: 80vh;
  .sub-title {
    text-align: center;
    font-size: 14px;
    color: #424242;
    margin-bottom: 20px;
  }
  .wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ffffff;
    height: 90%;
    .list-folder {
      display: flex;
      background-color: #ffffff;
      overflow: auto;
      width: 20%;
      padding: 10px;
    }
    .divider-resize {
      width: 10px;
      height: 100%;
      background: #efefef;
    }
    .wrap-table {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: calc(80% - 5px);
      .table {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        .header-table {
          display: flex;
          flex-direction: row;
          width: 100%;
          background: #ffffff;
          border-bottom: 1px solid #eeeeee;
          .column {
            padding: 16px;
            .title {
              font-size: 11px;
              color: #777777;
            }
          }
        }
        .ant-table-wrapper {
          width: 100%;
          height: calc(100% - 90px);
          .ant-table-thead .ant-table-cell {
            font-size: 11px;
            color: #777777;
            background: #ffffff !important;
            height: 41px;
            border-bottom: 1px solid #eeeeee;
            ::before {
              display: none;
            }
          }
          .ant-table-thead,
          .ant-table-tbody {
            .center {
              text-align: center;
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              cursor: pointer;
              font-size: 13px;
              color: #777777;
            }
            .title {
              display: flex;
              align-items: center;
              .icon-folder {
                color: #287dbb;
                font-size: 16px;
              }
              .icon-excel {
                color: #107c41;
                font-size: 16px;
              }
              .icon-word {
                color: #185abd;
                font-size: 16px;
              }
              .icon-ppt {
                color: #c43f1b;
                font-size: 16px;
              }
              .icon-pdf {
                color: #b30b00;
                font-size: 16px;
              }
              .icon-image {
                color: #cc9f00;
                font-size: 16px;
              }
              .icon-play {
                color: #5c5b61;
                font-size: 16px;
              }
              .icon-file {
                color: #777777;
                font-size: 16px;
              }
              .icon-logo {
                width: 16px;
                height: 16px;
              }
              .label-name {
                padding-left: 10px;
                margin: 0;
              }
            }
          }
        }
        .access-denied {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 20px;
          width: 98%;
          padding: 10px 5px;
          justify-content: center;
          .icon-lock {
            color: #c4c4c4;
            font-size: 30px;
            margin: 0 10px;
          }
          .content {
            color: #424242;
            font-size: 13px;
          }
        }
        .active {
          height: 100%;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        border: 1px solid #287dbb;
        box-shadow: 0 1px 8px 1px rgba(0, 0, 0, 0.08), 0 2px 15px 10px rgba(0, 0, 0, 0.04);
        padding: 20px;
        align-items: center;
        .folder-selected {
          display: flex;
          flex-direction: row;
          align-items: center;
          .folder {
            dispaly: flex;
            flex-direction: row;
            align-items: center;
            background: #f1f6f9;
            border-radius: 3px;
            padding: 5px 10px;
            font-weight: 700;
            font-size: 12px;
            color: #424242;
            .icon-folder {
              font-size: 16px;
              margin-right: 5px;
              color: #287dbb;
            }
          }
          .title {
            margin-left: 10px;
            font-size: 14px;
            color: #424242;
          }
        }
        .buttons {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 40%;
          .btn {
            border-radius: 5px;
            border: none;
            width: 45%;
            height: 40px;
            cursor: pointer;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            &_submit {
              color: #ffffff;
              background-color: #f6ac00;
            }
            &_close {
              background: #ffffff;
              border: 1px solid #d9d9d9;
              color: #777777;
            }
          }
        }
        &_disabled {
          border-color: #ffffff;
          .buttons .btn_submit {
            background: #eeeeee;
            color: #bbbbbb;
            cursor: not-allowed;
          }
        }
      }
    }
  }
`;
