import React from 'react';
import { RcFile, UploadChangeParam, UploadFile as UploadFileAnt } from 'antd/lib/upload';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { Upload } from 'antd';

import { createFileAttach, createManualFile, getListManualFile } from 'pages/Manual/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { uploadFileToMinIO } from 'services/minioService';
import { createManualFileSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { manualSelector } from 'pages/Manual/selectors';
import { Input, Modal, TextArea } from 'components';
import { UploadFileWrapper } from './styles';
import { useAppDispatch } from 'hooks';
import { ManualFile } from 'types';

const initialValues = {
  title: '',
  version: 1.0,
  description: '',
};

interface Props {
  visible: boolean;
  isMyManual?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const UploadFile: React.FC<Props> = ({ isMyManual, visible, setVisible }) => {
  const dispatch = useAppDispatch();

  const { activeFolder } = useSelector(manualSelector);
  const { userInfo } = useSelector(authSelector);

  const beforeUpload = (fileValidate: RcFile) => {
    if (fileValidate.size >= 104857600) {
      alert(`※画像はjpegまたはpngである必要があり、最大10MBまで可能です。
ビデオはmp4である必要があり、最大100MBにすることができます。`);
    }

    return fileValidate.size < 104857600 ? false : Upload.LIST_IGNORE;
  };

  const formik = useFormik<ManualFile.CreateManualFileFomik>({
    initialValues,
    validationSchema: createManualFileSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!values.file || !userInfo || !activeFolder) return;
      dispatch(startLoading());
      // const formData = new FormData();
      // formData.append('filename', values.file?.name);
      // formData.append('file', new Blob([values.file as Blob]));

      // const resultActionUploadFileToS3 = await dispatch(uploadFileToS3({ formData }));
      // if (uploadFileToS3.fulfilled.match(resultActionUploadFileToS3)) {
      //   const resultActionCreateImages = await dispatch(
      //     createFileAttach({
      //       item: {
      //         fileID: resultActionUploadFileToS3.payload.file_id,
      //         filename: values.file?.name,
      //       },
      //     })
      //   );
      //   if (createFileAttach.fulfilled.match(resultActionCreateImages)) {
      //     await Promise.all([
      //   dispatch(
      //     executeAction({
      //       itemId: resultActionCreateImages.payload.item_id,
      //       data: {
      //         changes: [
      //           {
      //             id: 'file',
      //             value: [resultActionUploadFileToS3.payload.file_id],
      //           },
      //         ],
      //         use_display_id: true,
      //         is_force_update: true,
      //       },
      //     })
      //   ),
      // ]);
      const uploadFileToMinio = await uploadFileToMinIO(values.file);

      if (uploadFileToMinio) {
        await dispatch(
          createManualFile({
            item: {
              company_id: userInfo.company_id,
              folder_id: activeFolder?.folder_id,
              fileID: uploadFileToMinio,
              publish: 0,
              attachment_title: values.title.length
                ? values.title
                : values.file.name.substring(0, values.file.name.lastIndexOf('.')),
              attachment_version: values.version,
              attachment_description: values.description,
              extension: values.file.type as ManualFile.ExtensionType,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
          })
        );
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadFileToMinio,
              filename: values.file?.name,
              file_location: '4',
              file_extension: values.file?.type,
              file_size: `${values.file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
      }

      await dispatch(
        getListManualFile({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'folder_id',
              search_value: [activeFolder?.folder_id],
            },
          ],
          isMyManual: isMyManual,
          page: 1,
          per_page: 0,
        })
      );
      onClose();
      dispatch(stopLoading());
    },
  });

  const onClose = () => {
    formik.resetForm();
    setVisible(false);
  };

  return (
    <Modal
      title="ファイルアップロード"
      width={720}
      open={visible}
      okButton={
        formik.values.file
          ? {
              text: 'アップロード',
              onClick: formik.handleSubmit,
              loading: formik.isSubmitting,
            }
          : undefined
      }
      cancelButton={{
        text: 'キャンセル',
        onClick: onClose,
      }}
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <UploadFileWrapper>
        <div className="form-upload">
          <div className="form-upload-border">
            <div className="file-upload">
              <div>
                <Dragger
                  beforeUpload={beforeUpload}
                  onChange={(info: UploadChangeParam<UploadFileAnt<File>>) => {
                    formik.setFieldValue('file', info.file as unknown as File);
                  }}
                >
                  {formik.values.file ? (
                    <p className="ant-uploaded-text">
                      {formik.values.file.name.substring(
                        0,
                        formik.values.file.name.lastIndexOf('.')
                      )}
                    </p>
                  ) : (
                    <>
                      <CloudUploadOutlined className="icon" />
                      <p className="ant-upload-text">
                        アップロードするファイルをここにドロップ
                        <br />
                        または
                      </p>
                    </>
                  )}
                  <div className="wrap-button-upload">
                    <button type="button" className="btn-upload">
                      ファイルを選択
                    </button>
                    {formik.values.file ? (
                      <button
                        className="btn-delete"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          formik.setValues(initialValues);
                        }}
                      >
                        <DeleteOutlined className="icon-delete-outlined" />
                        <span className="label-delete">ファイルを削除</span>
                      </button>
                    ) : null}
                  </div>
                </Dragger>
              </div>
            </div>
          </div>
          {formik.values.file ? (
            <FormikProvider value={formik}>
              <Form colon={false} layout="vertical" className="form">
                <p className="label-note">アップロードファイルの情報設定</p>
                <Form.Item
                  name="title"
                  label={
                    <span className="text-label">
                      タイトル（自由設定項目）
                      <span className="title">
                        ※空白の場合ファイル名の拡張子より前の部分がタイトルとして登録されます。
                      </span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="title"
                    className="input"
                    showCount
                    maxLength={60}
                    defaultValue="年間納税スケジュール"
                    placeholder="半角：数字・記号 最大30文字"
                  />
                </Form.Item>
                <Form.Item
                  name="version"
                  label={<span className="text-label">バージョン（自由設定項目）</span>}
                  className="form-input"
                >
                  <Input
                    name="version"
                    className="input"
                    showCount
                    maxLength={30}
                    placeholder="半角：数字・記号 最大30文字"
                  />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={<span className="text-label">概要（自由設定項目）</span>}
                  className="form-input"
                >
                  <TextArea
                    name="description"
                    className="input-textarea"
                    showCount
                    maxLength={480}
                    placeholder="全角：最大480文字"
                  />
                </Form.Item>
              </Form>
            </FormikProvider>
          ) : (
            <div className="note">※アップロード可能・不可情報</div>
          )}
        </div>
      </UploadFileWrapper>
    </Modal>
  );
};

export default UploadFile;
