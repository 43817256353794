import React from 'react';

import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { BarChartOutlined, CloseOutlined, TableOutlined } from '@ant-design/icons';

import MultipleComparisonSkillcheck from '../MultipleComparisonSkillcheck';
import { settingSelector } from 'containers/AppSettings/selectors';
import {
  ITEM_COMPARISION_USER_ANALYSIS_CHART,
  ITEM_COMPARISION_USER_ANALYSIS_TABLE,
  REPORT_TYPE,
} from 'constant/select.constants';
import { DatePicker, Header } from 'components';
import { UserType } from 'types/config';
import Wrapper from './styles';
import * as Types from 'types';
import MultipleComparison from '../MultipleComparison';
import Comparison from '../Comparison';

const { Option } = Select;

interface Props {
  selectAnalysisGroup: Types.SelectAnalysisGroup.ResponseType[];
  selectInterviewUser: Types.SelectInterviewUser.ResponseType[];
  selectEmployeeUser: Types.SelectEmployeeUser.ResponseType[];
  skillcheckByGroup: Types.SelectSkillCheck.ResponseType[];
  reportType: (typeof REPORT_TYPE)[number]['value'];
  secondSkillcheck?: string;
  firstSkillcheck?: string;
  startPeriod?: string;
  compareType: number;
  optionType: number;
  userType: UserType;
  endPeriod?: string;
  groupCode?: string;
  segmented: number;
  loginID?: string;
  itemComparision: (
    | typeof ITEM_COMPARISION_USER_ANALYSIS_TABLE
    | typeof ITEM_COMPARISION_USER_ANALYSIS_CHART
  )[number]['value'];
}

const FilePDFExport: React.FC<Props> = ({
  selectInterviewUser,
  selectAnalysisGroup,
  selectEmployeeUser,
  skillcheckByGroup,
  secondSkillcheck,
  firstSkillcheck,
  itemComparision,
  compareType,
  startPeriod,
  optionType,
  reportType,
  endPeriod,
  groupCode,
  segmented,
  userType,
  loginID,
}) => {
  const { headerTitle } = useSelector(settingSelector);

  return (
    <Wrapper>
      <Header title={headerTitle} exportPdf />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">ユーザー種別</span>
            <Select className="select" placeholder="社内" value={userType}>
              <Option value="member">社内</Option>
              <Option value="interview">面接ユーザー</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの種類</span>
            <Select className="select-report-type" value={reportType}>
              {REPORT_TYPE.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="wrap-body">
        <div className="header-container">
          <span className="title">
            {!compareType
              ? 'スキルチェック【社内】の実施結果を、ユーザーと分析グループを基準に集計したレポートです。'
              : 'スキルチェック【社内】の実施結果を、ユーザと分析グループを基準に集計したレポートです。'}
          </span>
        </div>

        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <div className="form-select">
              <div className="wrap-select">
                <div className="item">
                  <span className="text-label">ユーザー</span>
                  <Select className="select" placeholder="指定なし" value={loginID} allowClear>
                    <Option disabled={groupCode === 'ALL'} value="ALL">
                      ALL
                    </Option>
                    {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                      (item, index) => (
                        <Option
                          key={index}
                          value={item.login_id}
                          disabled={(groupCode && groupCode !== 'ALL') || !compareType}
                        >
                          {item.name}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className="item">
                  <span className="text-label">分析グループ（成長比較）</span>
                  <Select className="select" placeholder="指定なし" value={groupCode} allowClear>
                    <Option disabled={loginID === 'ALL' || !compareType || segmented} value="ALL">
                      ALL
                    </Option>
                    {selectAnalysisGroup.map((item, index) => (
                      <Option
                        key={index}
                        value={item.group_code}
                        disabled={loginID && loginID !== 'ALL'}
                      >
                        {item.group_name}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="wrap-time-select">
                <div className="radio-item">
                  <div className="three-border" />
                  <div className="radio-select">
                    <Radio value={0} label="２期間比較" checked={compareType === 0} />
                    <Radio value={1} label="推移比較" checked={compareType === 1} />
                  </div>
                </div>
                {!compareType && (
                  <div className="radio-item">
                    <div className="three-border" />
                    <div className="radio-select">
                      <Radio value={0} label="基準日" checked={optionType === 0} />
                      <Radio value={1} label="スキルチェック名" checked={optionType === 1} />
                    </div>
                  </div>
                )}
                {!optionType && !compareType ? (
                  <>
                    <div className="item">
                      <span className="text-label">基準日①</span>
                      <DatePicker
                        allowClear
                        name="date-item"
                        className="date-input"
                        format="YYYY/MM/DD"
                        value={startPeriod ? dayjs(startPeriod) : undefined}
                        disabledDate={(current) =>
                          current && current > dayjs(endPeriod, 'YYYY/MM/DD')
                        }
                      />
                    </div>
                    <div className="item">
                      <span className="text-label">基準日②</span>
                      <DatePicker
                        allowClear
                        name="date-item"
                        className="date-input"
                        format="YYYY/MM/DD"
                        value={endPeriod ? dayjs(endPeriod) : undefined}
                        disabledDate={(current) =>
                          current && current < dayjs(startPeriod, 'YYYY/MM/DD')
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="item">
                      <span className="text-label">スキルチェック①</span>
                      <Select
                        className="select"
                        placeholder="スキルチェック"
                        value={firstSkillcheck}
                        allowClear
                      >
                        {skillcheckByGroup.map((item, index) => (
                          <Option
                            key={index}
                            value={item.code}
                            disabled={item.code === secondSkillcheck}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="item">
                      <span className="text-label">スキルチェック②</span>
                      <Select
                        className="select"
                        placeholder="スキルチェック"
                        value={secondSkillcheck}
                        allowClear
                      >
                        {skillcheckByGroup.map((item, index) => (
                          <Option
                            key={index}
                            value={item.code}
                            disabled={item.code === firstSkillcheck}
                          >
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {segmented ? (
          <p className="note-segmented">
            ※選択したユーザー・スキルチェックの設問別実施結果が表示されています。
          </p>
        ) : null}

        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div className={`segmented-item${!segmented ? ' segmented-item-selected' : ''}`}>
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <button
                  className={`segmented-item${segmented ? ' segmented-item-selected' : ''}`}
                  disabled={compareType === 1 && groupCode === 'ALL'}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </button>
              </div>
              {compareType ? (
                <>
                  <span className="cross-boiled">/</span>
                  <span className="label">比較項目：</span>
                  <div className="item">
                    <Select className="select" placeholder="---" value={itemComparision}>
                      {(!segmented
                        ? ITEM_COMPARISION_USER_ANALYSIS_TABLE
                        : ITEM_COMPARISION_USER_ANALYSIS_CHART
                      ).map(({ label, value }, index) => (
                        <Option key={index} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {!compareType ? (
            <>
              {!optionType ? (
                <MultipleComparison
                  segmented={segmented}
                  userType={userType}
                  startPeriod={startPeriod}
                  endPeriod={endPeriod}
                  groupCode={groupCode}
                  loginID={loginID}
                  exportPdf
                />
              ) : (
                <MultipleComparisonSkillcheck
                  segmented={segmented}
                  userType={userType}
                  firstSkillcheck={firstSkillcheck}
                  secondSkillcheck={secondSkillcheck}
                  groupCode={groupCode}
                  loginID={loginID}
                  exportPdf
                />
              )}
            </>
          ) : (
            <Comparison
              segmented={segmented}
              itemComparision={itemComparision}
              startPeriod={startPeriod}
              endPeriod={endPeriod}
              groupCode={groupCode}
              userType={userType}
              loginID={loginID}
              exportPdf
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default FilePDFExport;

const Radio = ({ label, value, checked }: { label: string; value: number; checked: boolean }) => (
  <div className="radio-option">
    <input type="radio" value={value} checked={checked} />
    <span>{label}</span>
  </div>
);
