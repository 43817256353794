import styled from 'styled-components';

export const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 35px 0;

    font-size: 18px;
    color: #424242;
    .icon {
      color: #08a3a5;
      margin-right: 12px;
      font-size: 28px;
    }
  }
  .btn-confirm {
    width: 140px;
    padding: 10px;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    cursor: pointer;
  }
`;
