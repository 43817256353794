import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Button, Popover, Table } from 'antd';
import dayjs from 'dayjs';
import {
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileTextOutlined,
  FileWordFilled,
  FolderOpenFilled,
  LockOutlined,
  PlayCircleFilled,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { handleUpdateParentFolderManual } from 'libs/utils/manual';
import { settingSelector } from 'containers/AppSettings/selectors';
import { IconLocked, IconPublish, LogoNav } from 'assets';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { setActiveFolder } from 'pages/Manual/slice';
import FileExplorer from 'containers/FileExplorer';
import { formatNumber } from 'libs/utils/format';
import { manualSelector } from '../../selectors';
import { routes } from 'navigations/routes';
import { SectionStyled } from './styles';
import { MANUAL_FOLDER } from 'configs';
import { useAppDispatch } from 'hooks';
import { find, get } from 'lodash';
import { Modal } from 'components';
import * as Types from 'types';
import {
  createManual,
  createManualSection,
  getDataListAffiliation,
  getListFolderPermission,
  getListManual,
  getListManualFile,
  getManualFolder,
} from 'pages/Manual/thunk';

interface Props {
  visible: boolean;
  isMyManual?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalSelectStorageSaveManual: React.FC<Props> = ({ isMyManual, visible, setVisible }) => {
  const [visibleModalComplete, setVisibleModalComplete] = useState<boolean>(false);

  const { values, setFieldValue } = useFormikContext<Types.Manual.ManualFormik>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const tableRef = useRef<HTMLDivElement>(null);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { activeFolder, manualList, manualFileList, listFolderPermissions, dataAffiliations } =
    useSelector(manualSelector);

  const handleCloseModal = () => {
    if (loading) return;
    setVisible(false);
  };

  const accessPermission = useMemo(
    () =>
      (activeFolder &&
        listFolderPermissions.filter(
          (folder) =>
            folder.folder_id === activeFolder.folder_id && userInfo?.login_id === folder.login_id
        ).length) ||
      (activeFolder &&
        dataAffiliations.some((affiliation) => {
          return listFolderPermissions.some(
            (permission) =>
              permission.affiliation_id === affiliation.affiliation_id &&
              permission.folder_id === activeFolder.folder_id
          );
        })),
    [activeFolder, dataAffiliations, listFolderPermissions, userInfo]
  );

  const handleSubmitModal = async () => {
    if (!activeFolder || !userInfo || !accessPermission) return;
    try {
      dispatch(startLoading());
      setFieldValue('folder_id', activeFolder.folder_id);
      const resultAction = await dispatch(
        createManual({
          item: {
            company_id: userInfo.company_id,
            folder_id: activeFolder.folder_id,
            manual_title: values.title,
            description: values.description,
            version: values.version,
            publish: values.publish,
            createdby: userInfo.login_id,
            createdat: new Date(),
          },
          return_item_result: true,
          return_display_id: true,
          realtime_auto_link: true,
        })
      );
      if (createManual.fulfilled.match(resultAction) && resultAction.payload.item) {
        const folder_i_id = get(
          find(resultAction.payload.item.links, { d_id: MANUAL_FOLDER.id }),
          'i_ids[0]',
          undefined
        );

        folder_i_id && handleUpdateParentFolderManual(folder_i_id, dispatch);

        const resultAction2 = await Promise.all(
          values.sections.map((section, index) =>
            dispatch(
              createManualSection({
                item: {
                  company_id: userInfo.company_id,
                  manual_id: resultAction.payload.item?.manual_id,
                  section_name: section.section_name,
                  text: section.text,
                  display_order: index,
                  createdby: userInfo.login_id,
                  createdat: new Date(),
                },
                realtime_auto_link: true,
              })
            )
          )
        );
        if (resultAction2.every((result) => createManualSection.fulfilled.match(result))) {
          setVisibleModalComplete(true);
        }
      }
    } finally {
      dispatch(stopLoading());
      handleCloseModal();
    }
  };

  const handleSubmitCreateComplete = () => {
    navigate(routes.Manual.path);
  };

  useEffect(() => {
    if (!userInfo || !activeFolder || !visible || !accessPermission) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getListManual({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getListManualFile({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [activeFolder, dispatch, isMyManual, userInfo, visible, accessPermission]);

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getListFolderPermission({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getManualFolder({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            ignoreSetActiveFolder: true,
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataListAffiliation({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_links: true,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();

    return () => {
      dispatch(setActiveFolder(undefined));
    };
  }, [dispatch, userInfo, visible]);

  return (
    <>
      <Modal
        title="格納先フォルダ選択"
        visible={visible}
        width="80%"
        onCancel={handleCloseModal}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
      >
        <SectionStyled tableHeight={tableRef.current?.clientHeight}>
          <p className="sub-title">
            {values.publish
              ? 'マニュアルを公開します。左枠内より格納先フォルダを選択し、OKボタンをクリックしてください。'
              : '左枠内より格納先フォルダを選択し、OKボタンをクリックしてください。'}
          </p>
          <div className="wrapper">
            <div className="list-folder">
              <FileExplorer disabled scaleNum={1} />
            </div>
            <div className="divider-resize" />
            <div className="wrap-table">
              <div className="table">
                <div className="header-table">
                  {columns.map((column, index) => (
                    <div
                      key={index}
                      className="column"
                      style={{
                        width: column.width,
                        textAlign: index === 1 ? 'center' : 'left',
                      }}
                    >
                      <span className="title">{column.title}</span>
                    </div>
                  ))}
                </div>
                {accessPermission ? (
                  <Table
                    ref={tableRef}
                    dataSource={
                      activeFolder
                        ? ([
                            ...(activeFolder?.children
                              ? activeFolder.children.map((folder, index) => ({
                                  ...folder,
                                  index,
                                  type: 'folder',
                                  file_title: folder.folder_name,
                                }))
                              : []),
                            ...manualList.map((file, index) => ({
                              ...file,
                              index: index + (activeFolder.children?.length || 0),
                              type: 'manual',
                            })),
                            ...manualFileList.map((file, index) => ({
                              ...file,
                              index:
                                index + manualList.length + (activeFolder.children?.length || 0),
                              type: 'manual_file',
                            })),
                          ] as Types.FileExplorer.DataTableType[])
                        : []
                    }
                    columns={columns}
                    pagination={false}
                    showHeader={false}
                    loading={loading}
                    rowKey="index"
                    scroll={{
                      y: tableRef.current ? tableRef.current.clientHeight - 50 : undefined,
                    }}
                    expandable={{
                      expandRowByClick: false,
                      expandIcon: () => null,
                    }}
                    onRow={(record: Types.FileExplorer.DataTableType) => ({
                      onClick: () => {
                        if (record.type === 'folder' && activeFolder) {
                          const child = activeFolder.children?.find((c) => c.i_id === record.i_id);
                          if (child) {
                            dispatch(setActiveFolder(child));
                          }
                        }
                      },
                    })}
                  />
                ) : !activeFolder ? (
                  <div className="access-denied active">
                    <span className="content">左枠内より格納先フォルダを選択してください。</span>
                  </div>
                ) : (
                  <div className="access-denied">
                    <LockOutlined className="icon-lock" />
                    <span className="content">
                      権限がないため、このフォルダにアクセスできません。
                      <br />
                      管理者に権限をリクエストしてください。
                    </span>
                  </div>
                )}
              </div>
              <div
                className={`footer${!activeFolder || !accessPermission ? ' footer_disabled' : ''}`}
              >
                <div className="folder-selected">
                  {activeFolder ? (
                    <>
                      <div className="folder">
                        <FolderOpenFilled className="icon-folder" />
                        {activeFolder?.folder_name}
                      </div>
                      <span className="title">
                        {values.publish
                          ? 'このフォルダに保存して公開する'
                          : 'このフォルダに一時保存する'}
                      </span>
                    </>
                  ) : null}
                </div>
                <div className="buttons">
                  <Button
                    className="btn btn_submit"
                    onClick={handleSubmitModal}
                    loading={loading}
                    disabled={!activeFolder || !accessPermission}
                  >
                    OK
                  </Button>
                  <Button className="btn btn_close" onClick={handleCloseModal}>
                    キャンセル
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SectionStyled>
      </Modal>
      <CompletedModal
        title={values.publish ? '公開しました' : '一時保存が完了しました'}
        subTitle={values.publish ? undefined : 'ステータスは編集中として表示されます。'}
        visible={visibleModalComplete}
        setVisible={setVisibleModalComplete}
        onSubmit={handleSubmitCreateComplete}
      />
    </>
  );
};

const columns = [
  {
    title: 'マニュアル/ファイル名',
    dataIndex: 'file_title',
    className: 'title',
    key: 'file_title',
    width: '55%',
    render: (title: string, item: Types.FileExplorer.DataTableType) => (
      <>
        {item.type === 'folder' ? (
          <FolderOpenFilled className="icon-folder" />
        ) : item.type === 'manual_file' ? (
          item.extension === 'application/vnd.ms-excel' ||
          item.extension === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
            <FileExcelFilled className="icon-excel" />
          ) : item.extension === 'application/msword' ? (
            <FileWordFilled className="icon-word" />
          ) : item.extension === 'application/vnd.ms-powerpoint' ? (
            <FilePptFilled className="icon-ppt" />
          ) : item.extension === 'application/pdf' ? (
            <FilePdfFilled className="icon-pdf" />
          ) : item.extension.includes('image') ? (
            <FileImageFilled className="icon-image" />
          ) : item.extension.includes('video') ? (
            <PlayCircleFilled className="icon-play" />
          ) : (
            <FileFilled className="icon-file" />
          )
        ) : (
          <img src={LogoNav} className="icon-logo" alt="manual-file" />
        )}
        <div className="item-name">
          <p className="label-name">{title.substring(0, 40) || 'Empty'}</p>
          <p className="label-name">{title.substring(40)}</p>
        </div>
      </>
    ),
  },
  {
    title: 'ステータス',
    dataIndex: 'publish',
    className: 'center',
    key: 'publish',
    width: '10%',
    render: (publish: number, item: Types.FileExplorer.DataTableType) =>
      item.type !== 'folder' ? (
        <img src={publish === 1 ? IconPublish : IconLocked} className="icon" alt="publish-icon" />
      ) : null,
  },
  {
    title: '更新日時',
    dataIndex: 'updatedat',
    key: 'updatedat',
    width: '15%',
    render: (updatedat: string, item: Types.FileExplorer.DataTableType) =>
      updatedat && item.type !== 'folder' ? dayjs(updatedat).format('YYYY/MM/DD') : '-',
  },
  {
    title: 'バージョン',
    dataIndex: 'file_version',
    key: 'file_version',
    width: '15%',
    render: (version: number, item: Types.FileExplorer.DataTableType) =>
      version && item.type !== 'folder' ? formatNumber(version) : '-',
  },
  {
    title: '概要',
    dataIndex: 'file_description',
    key: 'file_description',
    width: '5%',
    render: (description: string, item: Types.FileExplorer.DataTableType, index: number) =>
      description && item.type !== 'folder' ? (
        <Popover
          content={description}
          color="white"
          trigger="click"
          placement="topRight"
          overlayStyle={{
            maxWidth: 640,
            minWidth: 50,
            zIndex: 9999,
          }}
        >
          <FileTextOutlined className="icon" />
        </Popover>
      ) : (
        '-'
      ),
  },
];

export default ModalSelectStorageSaveManual;
