import React from 'react';
import { CheckCircleFilled, LockFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { createKnowLedge, createKnowLedgeTo, updateKnowledge } from 'pages/KnowledgeTop/thunk';
import TreeView from 'containers/KnowledgeCurriculum/NodeTreeView';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { setPublishCurriculum } from '../../thunk';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import { Arrow } from 'assets';
import * as Types from 'types';

interface Props {
  visible: boolean;
  onSubmit: () => void;
  nodeLocked?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data?: Types.TreeItem<Types.CurriculumItemType>;
}

const CurriculumConfirmPublish: React.FC<Props> = ({
  onSubmit,
  visible,
  setVisible,
  nodeLocked,
  data,
}) => {
  const { loading } = useSelector(settingSelector);

  const { userInfo } = useSelector(authSelector);

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const handleCreateKnowledgeCurriculum = async () => {
    const element = document.getElementById('item-knowledge')?.outerHTML;
    if (nodeLocked || !element || !data) return;
    if (!nodeLocked && !data.knowledge_id) {
      const result = await dispatch(
        createKnowLedge({
          item: {
            company_id: userInfo?.company_id,
            knowledge_title: data?.name,
            knowledge_text: element,
            curriculum_code: data?.code,
            createdat: new Date(),
            createdby: userInfo?.login_id,
            knowledge_type: 1,
            likes: 0,
          },
          return_display_id: true,
          return_item_result: true,
        })
      );
      if (createKnowLedge.fulfilled.match(result)) {
        dispatch(
          createKnowLedgeTo({
            item: {
              company_id: userInfo?.company_id,
              knowledge_id: result.payload.item.knowledge_id,
              to_department_id: 'ALL',
              to_user_id: 'ALL',
            },
            return_display_id: true,
            return_item_result: true,
          })
        );
        await dispatch(
          setPublishCurriculum({
            id: data!.i_id!,
            data: {
              item: {
                knowledge_id: result.payload.item.knowledge_id,
              },
              is_force_update: true,
              return_item_result: true,
            },
          })
        );
      }
    } else if (!nodeLocked && data.knowledge_id) {
      await dispatch(
        updateKnowledge({
          id: data.i_id_knowledge!,
          data: {
            item: {
              knowledge_title: data?.name,
              knowledge_text: element,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
    }
  };

  return (
    <Modal
      title={messages['M-21-90']}
      width={720}
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: () => {
          handleCreateKnowledgeCurriculum();
          onSubmit();
        },
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      {nodeLocked ? (
        <SectionStyled>
          <div className="flex">
            <div className="open">
              <span>{messages['M-21-92']}</span>
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="lock scale">
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
              <span>{messages['M-21-91']}</span>
            </div>
          </div>
          <p className="sub-title">
            カリキュラムを編集します。よろしいですか？
            <br />
            編集権限のないユーザーからは非表示となります。
          </p>
        </SectionStyled>
      ) : (
        <SectionStyled>
          <div className="item-knowledge">
            <TreeView treeData={data} tabActive={0} />
          </div>
          <div className="flex">
            <div className="lock">
              <span>{messages['M-21-91']}</span>
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="open scale">
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
              <span>{messages['M-21-92']}</span>
            </div>
          </div>
          <p className="sub-title">{messages['M-21-93']}</p>
        </SectionStyled>
      )}
    </Modal>
  );
};

export default CurriculumConfirmPublish;
