import React, { useEffect, useMemo, useState } from 'react';
import { CheckCircleOutlined, FileSyncOutlined, SyncOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

import { officialCurriculumSelector } from 'pages/OfficialCurriculum/selectors';
import {
  getDataReleaseNoteManagement,
  getDataReleaseNoteID,
  getCurriculumPublishedHistory,
} from 'pages/OfficialCurriculum/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import UpdateConfirmCurriculumNotes from '../UpdateConfirmCurriculumNotes';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  okButtonDisabled?: boolean;
  description?: React.ReactNode;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataItemDetail?: Types.OfficialCurriculumList2.ResponseType;
}

const UpdateConfirm: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  onCancel,
  onSubmit,
  setVisible,
  description,
  okButtonDisabled,
  dataItemDetail,
}) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [selectedReleaseNote, setSelectedRelease] = useState<Types.ReleaseNote.ResponseType>();
  const [latestReleaseVersion, setLatestReleaseVersion] = useState<string>();
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataReleaseNoteManagement,
    dataFuture,
    dataUnUpdate,
    dataKeepUpdate,
    dataReleaseNoteID,
    curriculumPublishedHistory,
  } = useSelector(officialCurriculumSelector);

  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };
  const dataFilter = useMemo(() => {
    return dataReleaseNoteManagement.filter((i) => +i?.use_version === 2);
  }, [dataReleaseNoteManagement]);

  const dataManagement = useMemo(() => {
    const dataMng = curriculumPublishedHistory.map((history) => {
      const releaseNote = dataReleaseNoteID.find(
        (item) => item?.curriculum_publish_history_id === history.history_id
      );

      if (releaseNote && releaseNote.curriculum_publish_history_id) {
        return {
          release_note_version: releaseNote.version,
          publish_start_date: history?.publish_start_date,
          release_note_title: releaseNote.release_note_title,
        };
      }
    });
    const sortedReleaseNote = orderBy(
      dataMng,
      (item) => parseInt(item?.release_note_version || ''),
      'desc'
    );
    return sortedReleaseNote;
  }, [dataReleaseNoteID, curriculumPublishedHistory]);

  useEffect(() => {
    if (dataManagement.length > 0) {
      setLatestReleaseVersion(dataManagement[0]?.release_note_version);
    }
  }, [dataManagement]);

  const dataFutureNote = useMemo(() => {
    return dataFuture.filter(({ release_note_version, published_version }) => {
      return release_note_version > published_version;
    });
  }, [dataFuture]);

  const columnsPublishedNotes: ColumnsType<Types.ReleaseNote.ResponseType> = [
    {
      title: 'バージョン',
      className: 'version',
      dataIndex: 'release_note_version',
      key: 'release_note_version',
      width: '17%',
    },
    {
      title: '（公開日）',
      className: 'date',
      dataIndex: 'publish_start_date ',
      key: 'publish_start_date ',
      width: '17%',
      render: (publish_start_date: string) => (
        <>
          <span>{dayjs(publish_start_date).format('YYYY/MM')}</span>
        </>
      ),
    },
    {
      title: 'リリースノートタイトル',
      className: 'production_curriculum',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      width: '49%',
    },
    {
      title: '内容',
      dataIndex: 'explanation',
      className: 'explanation',
      key: 'explanation',
      width: '17%',
      render: (_, record) => (
        <div
          className="icon-detail"
          onClick={() => {
            setSelectedRelease(record);
            setOpen(true);
          }}
        >
          <FileSyncOutlined className="icon" />
        </div>
      ),
    },
  ];

  const columnFuture: ColumnsType<Types.ReleaseNoteManagement.ResponseType> = [
    {
      title: '今後公開予定',
      className: 'version',
      dataIndex: 'release_schedule_information',
      key: 'release_schedule_information',
      width: '34%',
      render: (value) => (
        <>
          <span className="text-code">{value || '-'}</span>
        </>
      ),
    },
    {
      title: 'リリースノートタイトル',
      className: 'production_curriculum',
      dataIndex: 'release_note_title',
      key: 'release_note_title',
      width: '49%',
    },
    {
      title: '内容',
      dataIndex: 'explanation',
      className: 'explanation',
      key: 'explanation',
      width: '17%',
      render: () => (
        <div className="icon-detail" onClick={() => setOpen(true)}>
          <FileSyncOutlined className="icon" />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataReleaseNoteManagement({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'official_curriculum_code',
                search_value: [dataItemDetail?.official_curriculum_code],
              },
            ],
            sort_fields: [{ id: 'publish_start_date', order: 'desc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataReleaseNoteID({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [dataItemDetail?.official_curriculum_code],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'release_version',
                order: 'desc',
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
            omit_total_items: false,
            omit_fields_data: true,
            use_display_id: true,
          })
        ),
        dispatch(
          getCurriculumPublishedHistory({
            conditions: [
              {
                id: 'official_curriculum_code',
                search_value: [dataItemDetail?.official_curriculum_code],
              },
            ],
            sort_fields: [
              {
                id: 'version',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
            use_display_id: true,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataItemDetail, dispatch, userInfo, visible]);

  return (
    <Modal
      title={<span className="title">{title}</span>}
      open={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: '実行',
        onClick: handleSubmitModal,
        loading,
        disabled: okButtonDisabled,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled hiddenHead={!dataUnUpdate.length}>
        {!dataKeepUpdate.length ? (
          <>
            <p className="sub-title">
              <SyncOutlined className="icon" />
              {subTitle}
            </p>
            <p className="text-content">{description}</p>
            <div className="item">
              <p className="label">利用中のバージョン：</p>
              <p className="number">v.{latestReleaseVersion}</p>
              <p className="date">
                {`（ ${dayjs(dataFilter[0]?.publish_start_date).format('YYYY/MM/DD')} 公開 ）`}
              </p>
            </div>
          </>
        ) : (
          <>
            <p className="sub-title">
              <CheckCircleOutlined className="icon check" />
              OFFICIALカリキュラムのバージョンは最新です。
            </p>
            <p className="text-content">
              アップデート公開予定のリリースノートが公開されています。
              事前にご確認をお願いいたします。
            </p>
          </>
        )}
        {!dataKeepUpdate.length ? (
          <>
            <div className="production-curriculum">
              <div className="title">
                <div className="circle" />
                <span className="label">アップデート内容</span>
              </div>
              <Table
                className="table"
                dataSource={dataManagement.map((item, index) => ({ ...item, index }))}
                columns={columnsPublishedNotes}
                pagination={false}
                rowKey="index"
              />
            </div>
          </>
        ) : null}
        {dataUnUpdate ? (
          <>
            <div className="production-curriculum">
              <div className="title future">
                <div className="circle" />
                <span className="label">今後のアップデート公開予定</span>
              </div>
              <Table
                className="table table-future"
                dataSource={dataFutureNote.map((item, index) => ({ ...item, index }))}
                columns={columnFuture}
                pagination={false}
                rowKey="index"
              />
            </div>
          </>
        ) : null}
      </SectionStyled>
      <UpdateConfirmCurriculumNotes
        visible={open}
        setVisible={setOpen}
        selectedReleaseNote={selectedReleaseNote}
      />
    </Modal>
  );
};

export default UpdateConfirm;
