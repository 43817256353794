import React from 'react';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: () => void | Promise<void>;
}

const ConfirmExport: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <Modal
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#424242',
            margin: 0,
          }}
        >
          エクスポート
        </h3>
      }
      visible={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: onSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <p>エクスポートを実行します。</p>
          <p>出力形式：PDF</p>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default ConfirmExport;
