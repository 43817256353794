import styled from 'styled-components';

export const Wrapper = styled.div`
  .row {
    height: auto;
    display: flex;
    align-items: center;
    .box_icon {
      height: 36px;
      margin-left: 1px;
      border-radius: 1px;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 10px;
    }
    .official {
      margin: 0 5px 0 5px;
      padding: 1px 3px;
      color: #0a9fbf;
      text-align: center;
      font-family: Lato;
      font-size: 11px;
      border-radius: 2px;
      border: 1px solid rgba(31, 185, 219, 0.6);
      background: rgba(31, 185, 219, 0.1);
    }
    .icon {
      margin: 0 2px;
    }
  }
`;
