import React, { useEffect, useState } from 'react';
import { maxBy } from 'lodash';

import { CurriculumItemType, TreeItem } from 'types';
import NodeContentRenderer from '../NodeRenderer';
import { Wrapper } from './styles';
import * as Types from 'types';

interface Props {
  accept: string;
  tabActive: number;
  isPublish: boolean;
  nodeHeight: number;
  columnIndex: number;
  maxSortOrder?: number;
  treeViewIndex: number;
  column: Types.TreeItem<Types.CurriculumItemType>[];
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setItemMoveCopySelected: React.Dispatch<
    React.SetStateAction<Types.ItemMoveCopySelectedType | undefined>
  >;
  onDrop: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropQuestion: () => void;
}

const ColumnRenderer: React.FC<Props> = ({
  column,
  accept,
  onDrop,
  isPublish,
  tabActive,
  nodeHeight,
  columnIndex,
  maxSortOrder,
  treeViewIndex,
  onDropQuestion,
  itemMoveCopySelected,
  setItemMoveCopySelected,
}) => {
  const [publishActive, setPublishActive] = useState<TreeItem<CurriculumItemType> | undefined>();
  useEffect(() => {
    Array.from({
      length: maxBy(column, 'lineIndex')?.lineIndex || 0,
    }).map((__, index) => {
      const row = column.find((r) => r.lineIndex! - 1 === index);
      setPublishActive(row);
    });
  }, [column]);
  return (
    <div
      style={{ flexGrow: columnIndex === 5 ? 1 : 0, paddingLeft: columnIndex === 5 ? '5px' : 0 }}
      className={`column ${
        itemMoveCopySelected?.node.columnIndex! - 1 === columnIndex &&
        publishActive?.node?.publish !== 1
          ? 'column-active'
          : ''
      }`}
    >
      {Array.from({
        length: maxBy(column, 'lineIndex')?.lineIndex || 0,
      }).map((__, index) => {
        const row = column.find((r) => r.lineIndex! - 1 === index);
        if (!row) {
          return <Wrapper key={index} nodeHeight={nodeHeight} className="rst__node" />;
        }

        return (
          <NodeContentRenderer
            node={row}
            key={index}
            index={index}
            accept={accept}
            onDrop={onDrop}
            isPublish={isPublish}
            tabActive={tabActive}
            nodeHeight={nodeHeight}
            maxSortOrder={maxSortOrder}
            treeViewIndex={treeViewIndex}
            onDropQuestion={onDropQuestion}
            itemMoveCopySelected={itemMoveCopySelected}
            setItemMoveCopySelected={setItemMoveCopySelected}
          />
        );
      })}
    </div>
  );
};

export default ColumnRenderer;
