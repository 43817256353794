import React, { useState } from 'react';
import { UploadFile } from 'antd/lib/upload/interface';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import ImgCrop from 'components/ImgCrop';
import { Upload } from 'antd';

import { authSelector } from 'containers/Auth/selectors';
import { FileType } from 'constant/enum.constant';
import UploadImageStyles from './styles';
import { Modal } from 'components';

const { Dragger } = Upload;

interface Props {
  visible: boolean;
  onSubmit: (item: UploadFile<File>) => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const UploadImage: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const [newFile, setFile] = useState<UploadFile<File>>();
  const [fileName, setFileName] = useState<string>('');

  const { userInfo } = useSelector(authSelector);

  const onChange = () => {
    newFile && onSubmit(newFile);
    setVisible(false);
  };

  const beforeUpload = async (file: File) => {
    setFileName(file.name);
    const isJpgOrPngOrMp4 =
      file.type === FileType.PNG || file.type === FileType.JPEG || file.type === FileType.MP4;
    let isAccept = false;
    if (isJpgOrPngOrMp4) {
      isAccept = await new Promise<boolean>((resolve) => {
        const src = URL.createObjectURL(new Blob([file]));
        if (file.type === FileType.PNG || file.type === FileType.JPEG) {
          const image = new Image();
          image.src = src;
          image.onload = function () {
            URL.revokeObjectURL(src);
            resolve(file.size < 10485760);
          };
        }
      });
    }
    return !(!isJpgOrPngOrMp4 || !isAccept);
  };

  const handleToggleModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={860}
      title="プロフィール画像 アップロード"
      visible={visible}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      onCancel={handleToggleModal}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: '40px 40px 20px' }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <UploadImageStyles>
        <div className="container">
          <ImgCrop
            modalWidth={860}
            grid
            rotate={false}
            modalTitle="画像の位置とサイズの変更"
            quality={1}
            modalOk="登録"
            modalCancel="キャンセル"
            onModalOk={handleToggleModal}
            shape="round"
            aspect={3 / 3}
            beforeCrop={beforeUpload}
            fileName={fileName}
            setNewFile={setFile || userInfo?.avatar}
          >
            <Dragger
              className="crop-avt"
              listType="picture-card"
              showUploadList={false}
              onChange={onChange}
              beforeUpload={() => false}
            >
              <div className="item-upload">
                <CloudUploadOutlined className="icon-upload" />
                <p className="text-upload">
                  アップロードするファイルをここにドロップ
                  <br />
                  または
                </p>

                <button className="btn-upload">ファイルを選択</button>
              </div>
            </Dragger>
          </ImgCrop>
        </div>
        <p className="photo-detail">※画像の種別はjpegまたはpngまたはgifで、10MBまでになります。</p>
      </UploadImageStyles>
    </Modal>
  );
};

export default UploadImage;
