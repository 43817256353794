import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  CalendarOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { newSkillCheckSelectionSelector } from '../selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { sharedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { NoImageSkillCheck } from 'assets';
import { getDataCompany } from '../thunk';
import GuidelineStyled from './styles';
import * as Types from 'types';

interface Props {
  handleAddItem: () => Promise<void>;
  fileGuideline?: string;
  dataGuideline?: Types.ItemSkillCheckImpleType;
  setStatusIndex: React.Dispatch<React.SetStateAction<number>>;
  setFileGuideline: React.Dispatch<React.SetStateAction<string | undefined>>;
  setDataGuideline: React.Dispatch<React.SetStateAction<Types.ItemSkillCheckImpleType | undefined>>;
}

const Guideline: React.FC<Props> = ({
  setStatusIndex,
  dataGuideline,
  handleAddItem,
  fileGuideline,
  setFileGuideline,
  setDataGuideline,
}) => {
  const { time_limit, dataCompany } = useSelector(newSkillCheckSelectionSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataCompany({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
              exact_match: true,
            },
          ],

          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      let nodeFileUrl = '';
      if (dataGuideline?.fileID) {
        nodeFileUrl = await sharedFileInMinIO(dataGuideline.fileID);
      }
      setFileGuideline(nodeFileUrl);
      dispatch(stopLoading());
    })();
  }, [dataGuideline?.fileID, dispatch, setFileGuideline]);

  return (
    <GuidelineStyled>
      <p className="title">
        実施要項を確認し、スタートボタンをクリックしてスキルチェックを開始してください。
      </p>
      <div className="item">
        <div className="item-image">
          {fileGuideline ? (
            <div className="wrap-image">
              <img src={fileGuideline} className="image" alt="" />
            </div>
          ) : (
            <div className="wrap-image">
              <div className="wrap-no-image">
                <img src={NoImageSkillCheck} alt="" />
                <span className="text">no image</span>
              </div>
            </div>
          )}
        </div>
        <p className="title-item">
          {dataCompany[0]?.name}_{dataGuideline?.skill_check_name}
        </p>
        <div className="item-body">
          <div className="requirements">
            実施
            <br />
            要項
          </div>
          <div className="content-requirements">
            <div className="execution-time">
              <div className="time-detail-child">
                <CalendarOutlined className="icon" />
                <p className="label">実施期間：</p>
              </div>
              <p className="text-label">
                {dataGuideline?.implementation_settings === 2 ? (
                  '実施可'
                ) : dataGuideline?.implementation_settings === 0 ? (
                  '実施不可'
                ) : (
                  <>
                    {dataGuideline?.start_period &&
                      dayjs(dataGuideline.start_period).format('YYYY年MM月DD日 HH:mm')}
                    {dataGuideline?.end_period && <span>~</span>}
                    {dataGuideline?.end_period &&
                      dayjs(dataGuideline.end_period).format('YYYY年MM月DD日 HH:mm')}
                  </>
                )}
              </p>
            </div>
            <div className="time-detail">
              <div className="time-detail-child">
                <ClockCircleOutlined className="icon" />
                <p className="label">制限時間：</p>
                <p className="text-label">
                  {time_limit.find((e) => Number(e.code) === dataGuideline?.check_time_limit)
                    ?.name || ''}
                </p>
              </div>
              <div className="time-detail-child-center">
                <FileTextOutlined className="icon" />
                <p className="label">設問数：</p>
                <p className="text-label">{dataGuideline?.question_count}</p>
              </div>
              <div className="time-detail-child">
                <UserOutlined className="icon" />
                <p className="label">ユーザー種別：</p>
                <p className="text-label">
                  {dataGuideline?.user_type === 'member' ? '社内ユーザー' : '面接ユーザー'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <p className="text-content">{dataGuideline?.skill_check_description}</p>
        </div>
        <div className="btn">
          <button onClick={handleAddItem} className="btn-start">
            スタート
          </button>
        </div>
      </div>
      <div className="btn-back">
        <button
          onClick={() => {
            setStatusIndex(0);
            setDataGuideline(undefined);
            setFileGuideline(undefined);
          }}
        >
          戻る
        </button>
      </div>
    </GuidelineStyled>
  );
};

export default Guideline;
