import styled from 'styled-components';

export default styled.section`
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  .text-note {
    font-size: 12px;
    color: #424242;
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
  }
  .form-search {
    display: flex;
    align-items: center;
    padding: 25px 0;
    .official-curriculum-name {
      width: 35%;
      margin-right: 8px;
    }
    .usage_situation {
      width: 15%;
      margin-right: 8px;
    }
    .update {
      width: 15%;
      margin-right: 8px;
    }
    .item {
      width: 20%;
      margin-right: 8px;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 8px;
      }
    }
  }
  .ant-card-body {
    padding: 0;
  }
  .card-item {
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0 2px 12px 1px rgba(0, 0, 0, 0.04), 0 0 4px rgba(0, 0, 0, 0.08);
    .picture-item {
      position: relative;
      height: 160px;
      background: #ebebeb;
      justify-content: center;
      display: flex;
      .btn {
        position: absolute;
        right: 10px;
        top: 10px;
        padding: 3px 10px;
        font-size: 11px;
        border: none;
        border-radius: 10px;
        cursor: pointer;
        font-weight: 700;
      }
      .btn-default {
        color: #eaa300;
        background: #ffffff;
      }
      .btn-active {
        color: #08a3a5;
        background: #ffffff;
      }
      .title-picture {
        background: rgba(51, 51, 51, 0.4);
        border-radius: 2px;
        padding: 2px;
        position: absolute;
        left: 10px;
        bottom: 10px;
        font-size: 11px;
        margin: 0;
        color: #ffffff;
      }
      .design-course {
        max-width: 100%;
        height: auto;
        max-height: 160px;
        object-fit: cover;
      }
      .design-course-no {
        max-width: 100%;
        height: auto;
        max-height: 120px;
        object-fit: cover;
      }
    }
    .content-item {
      height: 140px;
      padding: 15px;
      .version-skill {
        font-size: 11px;
        text-align: right;
        margin: 0;
        color: #bbbbbb;
      }
      .name-content {
        font-size: 15px;
        text-decoration-line: underline;
        color: #2a2a2a;
      }
      .title-content {
        font-size: 11px;
        margin: 0;
        color: #999999;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
      }
    }
    .group-btn {
      display: flex;
      justify-content: space-between;
      height: 60px;
      padding: 15px;
      .btn-active {
        width: 100%;
        padding: 5px 0;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border: none;
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
      .disabled {
        width: 100%;
        padding: 5px 0;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border: none;
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #777777;
      }
      .btn-default {
        padding: 5px 30px;
        background: #ffffff;
        border: 1px solid #08a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #08a3a5;
        cursor: pointer;
      }
      .active {
        background: #00a3a5;
        border: 1px solid #08a3a5;
        color: #ffffff;
      }
      .text-btn {
        margin: 0;
        font-size: 12px;
        text-decoration-line: underline;
        color: #00a3a5;
        text-align: right;
      }
    }
    .text {
      justify-content: end;
    }
  }
  .item-list {
    position: relative;
    .text-count {
      position: absolute;
      top: 37px;
      left: 0;
    }
    .select-perpage {
      z-index: 1;
      position: absolute;
      display: flex;
      top: 36px;
      left: 136px;
      .label {
        margin-top: 6px;
      }
    }
  }
  .ant-list-pagination {
    display: flex;
    justify-content: center;
    padding: 30px;
    margin: 0;
  }
  .ant-pagination-item-active a {
    color: #08a3a5;
  }
  .ant-pagination-item-active {
    border-color: #08a3a5;
  }
  .ant-pagination-item a:hover {
    color: #08a3a5;
  }
  .ant-pagination-item:hover {
    border-color: #08a3a5;
  }
`;
