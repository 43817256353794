import { createAsyncThunk } from '@reduxjs/toolkit';

import { ATTACH, QUESTIONS, QUESTION_ASSIGN_LEVEL, SKILL_CHECK_ASSIGN_QUESTION } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionLimit = createAsyncThunk<
  Types.GetDatastoreFieldsResponseType,
  undefined,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getOptionLimit', async (_, { rejectWithValue }) => {
  try {
    const { data } = await services.fields({ dataStoreId: QUESTIONS.name });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTIONS.name, {
      ...req,
      return_display_id: true,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/editQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTIONS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editAttachment = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/editAttachment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteLinkQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTION_ASSIGN_LEVEL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkQuestionAssignLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createLinkQuestionAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_ASSIGN_LEVEL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeActionUploadFile = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/executeActionUploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', QUESTIONS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileInQuestion = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/getFileInQuestion', async (req, { rejectWithValue }) => {
  try {
    if (req.file_id) {
      const { data } = await services.getFile(req);

      return data;
    }

    return new ArrayBuffer(0);
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFile = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  any,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFile = createAsyncThunk<
  Types.UploadFileResponseType,
  Types.UploadFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/uploadFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFile(req);

    return data;
  } catch (error) {
    return rejectWithValue({ file_id: req.uid, error });
  }
});

export const createNewFile = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createNewFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkSkillCheckAssignQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/createLinkSkillCheckAssignQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(SKILL_CHECK_ASSIGN_QUESTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLinkSkillCheckAssignQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('question/thunk/updateLinkSkillCheckAssignQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SKILL_CHECK_ASSIGN_QUESTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
