import styled from 'styled-components';

export const ProductInformationStyled = styled.div`
  padding: 40px;
  height: 700px;
  overflow-y: scroll;
  .item-text {
    .text-label {
      margin: 0;
      font-size: 12px;
      color: #777777;
    }
    .text-content {
      padding: 15px;
      margin: 0;
      background: #ffffff;
      border: 1px solid #ebebeb;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      font-size: 14px;
      color: #424242;
    }
  }
  .item-content {
    padding-top: 15px;
    .text-label {
      margin: 0;
      font-size: 12px;
      color: #777777;
    }
    .text-content {
      padding: 15px 15px 40px 15px;
      margin: 0;
      background: #ffffff;
      border: 1px solid #ebebeb;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      border-radius: 3px;
      font-size: 14px;
      color: #424242;
    }
    .link {
      color: #08a3a5;
      text-decoration: underline;
    }
  }
  .production-curriculum {
    margin-top: 50px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .label {
        font-weight: 700;
        font-size: 15px;
      }
    }
  }
  .production-curriculum .title .label {
    color: #424242;
  }
  .production-curriculum .title .circle {
    background-color: #424242;
  }
  .item-table {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    .label-number {
      margin: 0;
      font-size: 14px;
      color: #424242;
    }
    .label {
      margin: 0;
      color: #999999;
      font-size: 10px;
    }
  }
  .icon {
    cursor: pointer;
    color: #c4c4c4;
    font-size: 18px;
  }
  .ant-switch.switch-icon.ant-switch-checked {
    background: #08a3a5;
  }
  .table {
    .ant-table-thead tr .ant-table-cell {
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      text-align: center;
      ::before {
        display: none;
      }
    }
    .ant-table-tbody tr .ant-table-cell {
      font-size: 13px;
      color: #2a2a2a;
      text-align: center;
    }
    .ant-table-cell.production_curriculum {
      text-align: left !important;
    }
    .ant-table-tbody tr .ant-table-cell.no {
      color: rgba(119, 119, 119, 1) !important;
    }
  }
`;
