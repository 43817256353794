import { createAsyncThunk } from '@reduxjs/toolkit';
import { findIndex, groupBy, uniqWith } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { curriculumService, services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  OPTIONS_SKILL_CHECK_ANALYSIS_GROUP_IMPLEMENT,
  CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2,
  DETAIL_OF_SKILL_CHECK_RESULTS,
  REPORT_SKILL_CHECK_USER_TRANS,
  OPTIONS_SKILL_CHECK_IMPLEMENT,
  DETAIL_OF_CURRICULUM_RESULTS,
  SELECT_SKILL_CHECK_QUESTION,
  SKILL_CHECK_ASSIGN_QUESTION,
  SELECT_CURRICULUM_QUESTION,
  CURRICULUM_HIERARCHY_TRAN,
  REPORT_CURRICULUM_USER,
  SELECT_CURRICULUMS,
} from 'configs';

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OptionsSkillCheckImplements.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('myChartDashboard/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OptionsSkillCheckImplements.ResponseType>(
      OPTIONS_SKILL_CHECK_IMPLEMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_CURRICULUMS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectAnalysisGroup = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OptionsSkillCheckAnalysisGroupImplement.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataSelectAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } =
      await services.filter<Types.OptionsSkillCheckAnalysisGroupImplement.ResponseType>(
        OPTIONS_SKILL_CHECK_ANALYSIS_GROUP_IMPLEMENT.id,
        req
      );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckQuestion.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataSelectSkillCheckQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckQuestion.ResponseType>(
      SELECT_SKILL_CHECK_QUESTION.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculumQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculumQuestion.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataSelectCurriculumQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculumQuestion.ResponseType>(
      SELECT_CURRICULUM_QUESTION.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportCurriculumUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportCurriculumUser.ResponseType> & { login_id: string },
  Types.ReportsItemRequestType,
  Types.requestError
>(
  'myChartDashboard/thunk/getDataReportCurriculumUser',
  async (req, { rejectWithValue, getState }) => {
    try {
      const { data } = await services.filter<Types.ReportCurriculumUser.ResponseType>(
        REPORT_CURRICULUM_USER.id,
        req
      );

      return {
        ...data,
        login_id: (
          getState() as {
            authContainer: {
              userInfo: { login_id: string };
            };
          }
        ).authContainer.userInfo.login_id,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataTableCurriculumUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportCurriculumUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataTableCurriculumUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportCurriculumUser.ResponseType>(
      REPORT_CURRICULUM_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTableCurriculumUserAll = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportCurriculumUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataTableCurriculumUserAll', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportCurriculumUser.ResponseType>(
      REPORT_CURRICULUM_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataChartResult = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataChartResult', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataSkillCheckUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReportSkillCheckAll = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('myChartDashboard/thunk/getDataReportSkillCheckAll', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckUserMyChart = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType> & {
    login_id: string;
  },
  Types.ReportsItemRequestType,
  Types.requestError
>(
  'myChartDashboard/thunk/getDataSkillCheckUserMyChart',
  async (req, { rejectWithValue, getState }) => {
    try {
      const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
        REPORT_SKILL_CHECK_USER_TRANS.id,
        req
      );

      return {
        ...data,
        login_id: (
          getState() as {
            authContainer: {
              userInfo: { login_id: string };
            };
          }
        ).authContainer.userInfo.login_id,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataSortOrder = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('myChartDashboard/thunk/getDataSortOrder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckAssignQuestion.ResponseType>(
      SKILL_CHECK_ASSIGN_QUESTION.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataDetailSkillCheckResult = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DetailOfSkillCheckResults.ResponseType> & {
    login_id: string;
  },
  Types.ReportsItemRequestType,
  Types.requestError
>(
  'myChartDashboard/thunk/getDataDetailSkillCheckResult',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { authContainer } = getState() as RootState;
      const { data } = await services.filter<Types.DetailOfSkillCheckResults.ResponseType>(
        DETAIL_OF_SKILL_CHECK_RESULTS.id,
        req
      );

      const responseStatus = await dispatch(
        getDataSortOrder({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );

      let dataSortOrder: Types.DetailOfSkillCheckResults.ResponseType[] = [];

      if (getDataSortOrder.fulfilled.match(responseStatus)) {
        dataSortOrder = data.report_results.map((e) => {
          const index = findIndex(
            responseStatus.payload.items,
            (val) => val.question_code === e.code && val.skill_check_code === e.skill_check_code
          );
          if (index > -1) {
            return {
              ...e,
              sort_order: Number(responseStatus.payload.items[index].sort_order),
            };
          }

          return e;
        });
      }

      return {
        ...data,
        report_results: [...dataSortOrder.sort((a, b) => a.sort_order - b.sort_order)],
        login_id: (
          getState() as {
            authContainer: {
              userInfo: { login_id: string };
            };
          }
        ).authContainer.userInfo.login_id,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataDetailCurriculumResult = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DetailOfCurriculumResults.ResponseType> & {
    login_id: string;
  },
  Types.ReportsItemRequestType,
  Types.requestError
>(
  'myChartDashboard/thunk/getDataDetailCurriculumResult',
  async (req, { rejectWithValue, getState }) => {
    try {
      const { data } = await services.filter<Types.DetailOfCurriculumResults.ResponseType>(
        DETAIL_OF_CURRICULUM_RESULTS.id,
        req
      );

      return {
        ...data,
        login_id: (
          getState() as {
            authContainer: {
              userInfo: { login_id: string };
            };
          }
        ).authContainer.userInfo.login_id,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurriculumLevelOption = createAsyncThunk<
  { level: number; data: Types.ReportsItemResponseType<Types.SelectLevel.ResponseType> },
  { level: number; payload: Types.ReportsItemRequestType },
  Types.requestError
>('myChartDashboard/thunk/getCurriculumLevelOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.findAllCurriculumLevelOption(req.level, req.payload);

    return { level: req.level, data };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumImplementationStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumHierarchyTran.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('myChartDashboard/thunk/getCurriculumImplementationStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumHierarchyTran.ResponseType>(
      CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTrainingCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportCurriculumUser.UserReportLevelsTable> & {
    level:
      | 'question_code'
      | 'level1_code'
      | 'level2_code'
      | 'level3_code'
      | 'level4_code'
      | 'login_id';
  },
  Types.ReportsItemRequestType & {
    level:
      | 'question_code'
      | 'level1_code'
      | 'level2_code'
      | 'level3_code'
      | 'level4_code'
      | 'login_id';
  },
  Types.ThunkAPI<Types.requestError>
>(
  'myChartDashboard/thunk/getDataTrainingCurriculum',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(startLoading());
      const { authContainer } = getState() as RootState;

      const { data } = await services.filter<Types.CurriculumHierarchyTran.ResponseType>(
        CURRICULUM_HIERARCHY_TRAN.id,
        {
          ...req,
          include_item_ref: true,
          sort_fields: [
            { id: 'curriculum_sort_order', order: 'asc' },
            { id: 'level1_sort_order', order: 'asc' },
            { id: 'level2_sort_order', order: 'asc' },
            { id: 'level3_sort_order', order: 'asc' },
            { id: 'level4_sort_order', order: 'asc' },
            { id: 'createdat', order: 'asc' },
          ],
        }
      );

      const dataUniq = uniqWith(
        data.report_results,
        (dataA, dataB) =>
          dataA.login_id === dataB.login_id &&
          dataA.level4_code === dataB.level4_code &&
          dataA.question_code === dataB.question_code
      );

      const dataGroup = groupBy<Types.CurriculumHierarchyTran.ResponseType>(dataUniq, req.level);

      const responseStatus = await dispatch(
        getCurriculumImplementationStatus({
          conditions: req.conditions,
          sort_fields: [
            {
              id: 'implementation_date',
              order: 'desc',
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );

      const dataReportCurriculumUser: Types.ReportCurriculumUser.UserReportLevelsTable[] = [];

      if (getCurriculumImplementationStatus.fulfilled.match(responseStatus)) {
        const dataGroupImplement = groupBy<Types.CurriculumHierarchyTran.ResponseType>(
          responseStatus.payload.report_results,
          req.level
        );

        Object.keys(dataGroup).map((key) => {
          const itemsAllUser = dataGroup[key];
          const itemsImplementAllUser = dataGroupImplement[key];

          const items = dataGroup[key].filter(
            (item) => item.login_id === authContainer.userInfo?.login_id
          );
          const itemsImplement = dataGroupImplement[key].filter(
            (item) => item.login_id === authContainer.userInfo?.login_id
          );

          const responses_num_all_user = itemsImplementAllUser?.length || 0;
          const correct_answers_num_all_user =
            itemsImplementAllUser?.filter((item) => item.correct).length || 0;
          const question_count_all_user = itemsAllUser.length || 0;

          const responses_num = itemsImplement?.length || 0;
          const correct_answers_num = itemsImplement?.filter((item) => item.correct).length || 0;
          const question_count = items.length || 0;

          const { score } = items.reduce(
            (prev, next) => ({ score: prev.score + (next.score || 0) }),
            {
              score: 0,
            }
          );

          const { answer_time } = itemsAllUser.reduce(
            (prev, next) => ({
              answer_time: prev.answer_time + (next.answer_time || 0),
            }),
            {
              answer_time: 0,
            }
          );

          dataReportCurriculumUser.push({
            user_name: items[0].user_name,
            login_id: items[0].login_id,
            curriculum_name: items[0].curriculum_name,
            curriculum_code: items[0].curriculum_code,
            level1_code: items[0].level1_code || '',
            level1_name: items[0].level1_name || '',
            level2_code: items[0].level2_code || '',
            level2_name: items[0].level2_name || '',
            level3_code: items[0].level3_code || '',
            level3_name: items[0].level3_name || '',
            level4_code: items[0].level4_code || '',
            level4_name: items[0].level4_name || '',
            question_id: items[0]!.item_ref!.question_code.i_id || '',
            user_answer: items[0].user_answer || '',
            answer_time: items[0].answer_time || 0,
            correct_answer: items[0].correct_answer || '',
            question_code: items[0].question_code || '',
            question_name: items[0].question_name || '',
            correct_answers_num,
            incorrect_answer: responses_num - correct_answers_num,
            inexperienced: question_count - responses_num,
            responses_num,
            question_count: question_count,
            score: score,
            accuracy_rate:
              isFinite(correct_answers_num) && itemsImplement
                ? correct_answers_num / question_count
                : 0,
            wrong_rate:
              isFinite(responses_num - correct_answers_num) && itemsImplement
                ? (responses_num - correct_answers_num) / question_count
                : 0,
            inexperienced_rate: isFinite(question_count - responses_num)
              ? (question_count - responses_num) / question_count
              : 0,
            real_accuracy_rate:
              isFinite(correct_answers_num) && itemsImplement
                ? correct_answers_num / responses_num
                : 0,
            progress_rate:
              isFinite(responses_num) && itemsImplement ? responses_num / question_count : 0,
            progress_rate_user_average:
              isFinite(responses_num_all_user) && itemsImplementAllUser
                ? responses_num_all_user / question_count_all_user
                : 0,
            accuracy_rate_user_average:
              isFinite(correct_answers_num_all_user) && itemsImplementAllUser
                ? correct_answers_num_all_user / question_count_all_user
                : 0,
            answer_time_user_average:
              isFinite(answer_time) && itemsImplementAllUser
                ? answer_time / question_count_all_user
                : 0,
            updatedat: items[0].implementation_date,
            implementation_date: items[0].implementation_date!,
          });
        });
      }

      dispatch(stopLoading());

      return {
        ...data,
        report_results: [...dataReportCurriculumUser],
        totalItems: dataUniq.length,
        level: req.level,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
