import styled from 'styled-components';

interface DivProps {
  columnIndex: number;
}

export const DivCustom = styled.div<DivProps>`
  font-weight: 400;
  font-size: 12px;

  .rowWrapper {
    position: relative;
    .label-official {
      position: absolute;
      top: 5px;
      left: 5px;
      padding: 1px 3px;
      color: #0a9fbf;
      text-align: center;
      font-family: Lato;
      font-size: 8px;
      font-weight: 500;
      border-radius: 2px;
      border: 1px solid rgba(31, 185, 219, 0.6);
      background: rgba(31, 185, 219, 0.1);
    }
    .item-image {
      width: 256px;
      height: 144px;
      background: #e1dcdc;
      .image {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .item-no-image {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 256px;
      height: 144px;
      background: #f0f0f0;
      .text {
        color: #6f95aa;
        font-size: 10px;
        font-weight: 700;
      }
    }
    .content {
      padding: 0 10px;
    }
    .rowContentHead {
      display: flex;
      align-items: center;
    }
    .line {
      width: 40px;
      height: 1px;
      background: #dddddd;
      margin: 10px 0 0 15px;
    }
    .status {
      display: flex;
      align-items: center;
      padding: 15px;
      .label {
        color: #2a2a2a;
        font-size: 14px;
        margin-right: 15px;
      }
      .correct {
        color: #2a2a2a;
        font-size: 14px;
        .correct-true {
          display: flex;
          align-items: center;
          .item-circle {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid #44a3d7;
            margin-right: 10px;
          }
        }
        .item-close {
          display: flex;
          align-items: center;
          .icon-close {
            margin-right: 10px;
            font-size: 14px;
            color: #ec7c83;
          }
        }
      }
    }
  }
  .wrap-btn {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    .btn-start {
      width: ${(props) => (props.columnIndex === 5 ? '248px' : '228px')};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 10px 0;
      background-color: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      border: none;
      font-weight: 700;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
        background-color: #eeeeee;
      }
    }
  }
`;

export const Wrapper = styled('div')<{}>`
  padding: 10px 15px;
  .progress-bar {
    justify-content: center;

    .progress-col {
      width: 100%;

      .progress-bar-title {
        align-items: flex-end;
        padding-bottom: 8px;
        .progress-bar-title-text {
          white-space: nowrap;
          font-size: 13px;
          color: #777777;
          .label-name {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
        .progress-bar-count {
          font-size: 14px;
          white-space: nowrap;
          text-align: right;
          color: #777777;
        }
      }

      .progress-bar-number {
        padding-top: 3px;
        .label-number {
          display: flex;
          align-items: center;
          .point {
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background-color: #6ec9ab;
          }
          .point-first {
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background-color: #ddeacd;
          }
          .label {
            font-size: 11px;
            color: #777777;
            padding: 0 3px;
          }
          .number-label {
            font-family: Lato;
            font-size: 22px;
            color: #5bba9b;
          }
          .first {
            color: #ddeacd;
          }
        }
      }
    }

    .progress-bar-display {
      .progress-bar-bars {
        width: 100% !important;

        .ant-progress-steps-item {
          width: 4.4% !important;
          border-radius: 2px;
          margin-right: 1px;
        }
      }
    }
    .ant-progress-steps-item-active {
      background-color: #f4f4f4;
    }
  }
`;
