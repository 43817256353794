import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { Header, Input, InputPassword, SelectField, TextArea } from 'components';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { CreateInterviewUserFormik, SkillCheck } from 'types';
import { createInterviewUserSchema } from 'libs/validations';
import { SkillCheckModal } from '../../InterviewUser/Modal';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { interviewUsersSelector } from '../selectors';
import { CREDIT_CARD_MONTH } from 'constant';
import CreateEmployeeStyled from './styles';
import { routes } from 'navigations/routes';
import { ModalEnlarge } from '../Modal';
import { IconEnalarge } from 'assets';
import {
  createUserAssignSkillCheck,
  encryptionInterviewUser,
  createAffiliationRole,
  getSelectAffiliation,
  createInterviewUser,
  getSelectPosition,
  getSelectRole,
  addUser,
  getUsers,
} from '../thunk';

const { Option } = Select;

const CreateInterviewUsers: React.FC = () => {
  const [visibleSkillSelection, setVisibleSkillSelection] = useState<boolean>(false);
  const [dataSkillCheck, setDataSkillCheck] = useState<Array<SkillCheck.ResponseType>>([]);
  const [visibleEnlarge, setVisibleEnlarge] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [textArea, setTextArea] = useState<string>('');
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });
  const [hiringDate, setHiringDate] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const { dataSelectDepartment, dataSelectPosition } = useSelector(interviewUsersSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const topRef = useRef<HTMLDivElement>(null);

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('dob', `${birthDay.year}${value}${birthDay.day}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const handleSelectHiringDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setHiringDate((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('scheduled_hiring_date', `${hiringDate.year}${e}`);
    } else {
      setHiringDate((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('scheduled_hiring_date', `${e}${hiringDate.month}`);
    }
  };

  const formik = useFormik<CreateInterviewUserFormik>({
    initialValues: {
      manage_code: '',
      name: '',
      name_phonetic: '',
      email_address: '',
      email_address_confirmation: '',
      password: '',
      password_confirm: '',
      dob: '',
      scheduled_hiring_date: '',
      position_code: '',
      affiliation_id: '',
      work_history: '',
    },
    validationSchema: createInterviewUserSchema,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (confirmStatus) {
        dispatch(startLoading());
        const resultAddUserAction = await dispatch(
          addUser({
            user_code: values.email_address,
            email: values.email_address,
            username: values.name,
            passowrd: values.password,
            email_template_id: '621ec0914f2f5cb7f71d0c58',
          })
        );
        if (addUser.fulfilled.match(resultAddUserAction)) {
          const resultAction = await Promise.all([
            dispatch(
              createInterviewUser({
                item: {
                  manage_code: values.manage_code,
                  company_id: userInfo?.company_id,
                  login_id: values.email_address,
                  email: values.email_address,
                  name: values.name,
                  name_furigana: values.name_phonetic,
                  user_type: 'interview',
                  dob: values.dob ? dayjs(values.dob).toDate() : undefined,
                  doj: values.scheduled_hiring_date
                    ? dayjs(values.scheduled_hiring_date).toDate()
                    : undefined,
                  password: values.password,
                  work_history: values.work_history,
                  position_code: values.position_code,
                  createdby: userInfo?.login_id,
                  createdat: new Date(),
                },
              })
            ),
            dispatch(
              createAffiliationRole({
                item: {
                  company_id: userInfo?.company_id,
                  affiliation_id: values.affiliation_id,
                  login_id: values.email_address,
                  positions_code: values.position_code,
                  sort_order: 1,
                  main_role: 'main',
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
              })
            ),
            ...dataSkillCheck.map(({ code }) =>
              dispatch(
                createUserAssignSkillCheck({
                  item: {
                    skill_check_code: code,
                    login_id: values.email_address,
                    company_id: userInfo?.company_id,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                  },
                })
              )
            ),
          ]);
          if (createInterviewUser.fulfilled.match(resultAction[0])) {
            await dispatch(
              encryptionInterviewUser({
                i_id: resultAction[0].payload.item_id,
              })
            );

            setVisibleCompleted(true);
          }
        } else {
          // setShowActionErrorModal(true);
        }
        dispatch(stopLoading());
      } else {
        if (userInfo) {
          const resultAction = await dispatch(
            getUsers({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'manage_code',
                  search_value: [values.manage_code],
                },
              ],
              page: 1,
              per_page: 0,
            })
          );
          if (getUsers.fulfilled.match(resultAction) && resultAction.payload.totalItems) {
            formik.setFieldError('manage_code', '管理番号はすでに存在します。');
          } else {
            formik.setFieldError('manage_code', formik.errors.manage_code);
            setConfirmStatus(true);
          }
        }
      }
      setSubmitting(false);
    },
  });

  const handleDeleteItemSkillCheck = (id: string) => {
    setDataSkillCheck(dataSkillCheck.filter(({ i_id }) => i_id !== id));
  };

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAffiliation({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (confirmStatus && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [confirmStatus]);

  return (
    <>
      <Header title={headerTitle} />
      <CreateEmployeeStyled collapsedMenu={collapsedMenu} ref={topRef}>
        {confirmStatus ? (
          <p className="text-sub-title">
            登録内容の確認画面です。記載の内容でよろしければ、登録ボタンをクリックしてください。
            <br />
            再度入力する場合は戻るをクリックして、新規登録画面から再度入力してください。
          </p>
        ) : (
          <p className="text-sub-title">
            面接ユーザーマスタの新規登録を行う画面です。
            <br />
            情報を入力し、登録ボタンをクリックしてください。
          </p>
        )}

        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            autoComplete="off"
          >
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="wrap-user">
                  <div className="avatar-user">
                    <UserOutlined className="icon-user" />
                    <span className="text-content">面接ユーザー</span>
                  </div>
                </div>
                <div className="form-left">
                  <Form.Item
                    name="manage_code"
                    label={
                      <span className="text-label">
                        管理番号<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="manage_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                      readOnly={confirmStatus}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="全角：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_phonetic"
                    label={
                      <span className="text-label">
                        氏名（フリガナ）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_phonetic"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectBirthDay('year')}
                      allowClear
                      value={birthDay.year || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('month')}
                      placeholder="--"
                      allowClear
                      value={birthDay.month || undefined}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('day')}
                      placeholder="--"
                      allowClear
                      value={birthDay.day || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="email_address"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email_address"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      readOnly={confirmStatus}
                      maxLength={300}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email_address_confirmation"
                    label={
                      <span className="text-label">
                        メールアドレス（確認） <span className="require">*</span>
                      </span>
                    }
                    labelCol={{
                      flex: '30%',
                    }}
                    className="form-input"
                  >
                    <Input
                      name="email_address_confirmation"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      readOnly={confirmStatus}
                      maxLength={300}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={
                      <span className="text-label">
                        パスワード <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      name="password"
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password_confirm"
                    label={
                      <span className="text-label">
                        パスワード（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      name="password_confirm"
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">募集情報</div>
              <div className="body">
                <div className="schedule-hiring-date">
                  <Form.Item
                    name="scheduled_hiring_date"
                    label={
                      <span className="text-label">
                        採用予定年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectHiringDate('year')}
                      allowClear
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 10,
                        },
                        (_, i) => (i + new Date().getFullYear()).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectHiringDate('month')}
                      placeholder="--"
                      allowClear
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                </div>
                <div className="wrap-recruitment">
                  <Form.Item
                    name="affiliation_id"
                    label={
                      <span className="text-label">
                        募集所属 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <SelectField
                      name="affiliation_id"
                      className="pull_down"
                      placeholder="選択してください"
                      disabled={confirmStatus}
                    >
                      {dataSelectDepartment?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="position_code"
                    className="form-input"
                    label={
                      <span className="text-label">
                        募集役職 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="position_code"
                      className="pull_down"
                      placeholder="選択してください"
                      disabled={confirmStatus}
                    >
                      {dataSelectPosition?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
                <div className="work-history">
                  <Form.Item
                    name="work_history"
                    htmlFor=""
                    labelCol={{
                      flex: '100%',
                    }}
                    label={
                      <div className="wrap-text-area">
                        <span>職歴</span>
                        <span className="text-enlarge" onClick={() => setVisibleEnlarge(true)}>
                          <img src={IconEnalarge} alt="enlarge" />
                          入力欄を拡大
                        </span>
                      </div>
                    }
                  >
                    <TextArea
                      name="work_history"
                      className="text-input"
                      placeholder="全角：最大1050文字"
                      rows={4}
                      showCount
                      maxLength={1050}
                      onBlur={(e) => {
                        setTextArea(e.target.value);
                      }}
                      disabled={confirmStatus}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="wrap-skill-check">
              <div className="header">スキルチェック設定状況</div>
              <div className="body">
                <p className="description">
                  スキルチェックを設定することができます。スキルチェックを追加ボタンをクリックしてください。
                </p>
                {dataSkillCheck?.length > 0 ? (
                  dataSkillCheck.map(({ name, i_id }, index) => (
                    <div key={index} className={`item-result${confirmStatus ? ' disabled' : ''}`}>
                      <div>
                        <span className="text-add">{confirmStatus ? index + 1 : '＋'}</span>
                        <span className="content">{name}</span>
                      </div>
                      {!confirmStatus && (
                        <DeleteOutlined
                          className="delete"
                          onClick={() => handleDeleteItemSkillCheck(i_id)}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="empty-text">未設定</div>
                )}
                {!confirmStatus && (
                  <button
                    className="button"
                    type="button"
                    onClick={() => setVisibleSkillSelection(true)}
                  >
                    ＋ スキルチェックを追加
                  </button>
                )}
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit">
                  {confirmStatus ? '登録' : '確認画面へ'}
                </SubmitButton>
                <button
                  className="btn btn_close"
                  onClick={() => navigate(routes.InterviewUser.path)}
                >
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
          <ModalEnlarge
            visible={visibleEnlarge}
            onCancel={() => setVisibleEnlarge(false)}
            onSubmit={() => {}}
            value={textArea}
          />
        </FormikProvider>
        <SkillCheckModal
          visible={visibleSkillSelection}
          setVisible={setVisibleSkillSelection}
          onSubmit={(item) => setDataSkillCheck([...dataSkillCheck, ...item])}
          data={dataSkillCheck}
        />
        <CompletedModal
          title="登録が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() =>
            navigate(generatePath(routes.InterviewUser.path, { entity: 'receiving' }))
          }
        />
      </CreateEmployeeStyled>
    </>
  );
};

export default CreateInterviewUsers;
