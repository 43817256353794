import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #eeeeee;
  .header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 2px solid #eeeeee;
    .icon {
      font-size: 18px;
      color: #c4c4c4;
      margin-right: 5px;
    }
    .title {
      font-size: 13px;
      color: #424242;
    }
  }
  .wrap-items {
    max-height: 20vh;
    overflow-x: auto;
    margin: 0 -2px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .pd-10 {
    padding: 10px;
  }
`;

interface Props {
  knowledge_type: number;
}

export const Item = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: row;
  background: #ffffff;
  border: 2px solid #eeeeee;
  .avt-icon {
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .from-name {
      font-size: 10px;
      color: #999999;
    }
    .content {
      font-size: 12px;
      color: #424242;
    }
  }
  .wrap-label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 65px;
    border-radius: 2px;
    padding: 2px 0;
    background-color: ${(props) => (props.knowledge_type === 2 ? '#0EAF58' : '#1E7ED6')};
    .label {
      font-size: 10px;
      font-weight: 700;
      color: #ffffff;
    }
  }
  &::before {
    position: absolute;
    content: '';
    width: 3px;
    height: 90%;
    border-radius: 10px;
    background-color: ${(props) => (props.knowledge_type === 2 ? '#0EAF58' : '#1E7ED6')};
    top: 3px;
    left: 2px;
  }
`;
