import React from 'react';
import { maxBy } from 'lodash';

import NodeContentRenderer from '../NodeRenderer';
import { Wrapper } from './styles';
import * as Types from 'types';

interface Props {
  accept: string;
  tabActive: number;
  nodeHeight: number;
  countCorrect: number;
  columnIndex?: number;
  setStartTime?: React.Dispatch<string>;
  column: Types.TreeItem<Types.TrainingCurriculumItemType>[];
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setNumberQuestionImplement: React.Dispatch<React.SetStateAction<number>>;
}

const ColumnRenderer: React.FC<Props> = ({
  column,
  accept,
  tabActive,
  nodeHeight,
  columnIndex,
  setChangeTab,
  countCorrect,
  setStartTime,
  setNumberQuestionImplement,
}) => {
  return (
    <div
      style={{ flexGrow: columnIndex === 5 ? 1 : 0, paddingLeft: columnIndex === 5 ? '5px' : 0 }}
      className="column"
    >
      {Array.from({
        length: maxBy(column, 'lineIndex')?.lineIndex || 0,
      }).map((__, index) => {
        const row = column.find((r) => r.lineIndex! - 1 === index);
        if (!row) {
          return <Wrapper key={index} nodeHeight={nodeHeight} className="rst__node" />;
        }
        return (
          <NodeContentRenderer
            node={row}
            key={index}
            index={index}
            accept={accept}
            tabActive={tabActive}
            nodeHeight={nodeHeight}
            setChangeTab={setChangeTab}
            setStartTime={setStartTime}
            countCorrect={countCorrect}
            setNumberQuestionImplement={setNumberQuestionImplement}
          />
        );
      })}
    </div>
  );
};

export default ColumnRenderer;
