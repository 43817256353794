import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { useDrag } from 'react-dnd';

import { Affiliation } from './styles';

interface Props {
  name: string;
  count?: number;
  isFromCreated?: boolean;
}

export const Item: React.FC<Props> = ({ name, count, isFromCreated }) => {
  const [, drag] = useDrag({
    item: {
      name,
      type: 'affiliation',
    },
  });

  return (
    <Affiliation ref={drag}>
      <div className="top-side">
        <p>{name}</p>
      </div>
      {!isFromCreated && (
        <div className="bottom-side">
          {count && (
            <span className="people-quantity">
              {count}
              <small>人</small>
            </span>
          )}
          <DownOutlined className="arrow-icon" />
        </div>
      )}
    </Affiliation>
  );
};
