import { createAsyncThunk } from '@reduxjs/toolkit';

import { sharedFileInMinIO } from 'services/minioService';
import { services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_LEVEL,
  AFFILIATION_ASSIGN_ROLE,
  AFFILIATION_HIERARCHY,
  AFFILIATION_LEVEL,
  EMPLOYEE_USER,
  SELECT_POSITION,
} from 'configs';

export const getAffiliations = createAsyncThunk<
  Types.ReportsItemResponseType<Types.AffiliationHierarchy.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/getAffiliations', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.AffiliationHierarchy.ResponseType>(
      AFFILIATION_HIERARCHY.id,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkAffiliationLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/createLinkAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLinkAffiliationRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/createLinkAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/createAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/updateAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/getUserList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUser.ResponseType>(
      EMPLOYEE_USER.name,
      req
    );

    const dataConvert = await Promise.all(
      data.report_results.map(async (val) => {
        const obj = val;

        if (val.icon_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.icon_fileID);
          Object.assign(obj, {
            ...val,
            icon_file: nodeFileUrl,
          });
        }

        return obj;
      })
    );

    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/deleteAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/deleteLinkAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/deleteLinkAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPositionSelect = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectPosition.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/getPositionSelect', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectPosition.ResponseType>(
      SELECT_POSITION.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editLinkAffiliationLevel = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/editLinkAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editLinkAffiliationRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/editLinkAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationLevel = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/updateAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_LEVEL.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('affiliation/thunk/getAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
