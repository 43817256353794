import styled from 'styled-components';

import * as Types from 'types';

interface Props {
  nodeHeight: number;
  node?: Types.TreeItem<Types.AffiliationItemType>;
}

export const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.nodeHeight}px;

  .rst__node {
    user-select: none;
  }

  .rst__nodeContent {
    width: 220px;
    height: 80%;
  }

  .rst__lineBlock {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .rst__lineHalfHorizontalLeft::before,
  .rst__lineHalfHorizontalLeftVerticalTop::before,
  .rst__lineHalfVerticalTop::after {
    position: absolute;
    content: '';
  }

  .rst__lineHalfHorizontalLeft::before {
    height: 3px;
    top: 50%;
    right: 100%;
    width: 14%;
    background: repeating-linear-gradient(to right, #959fd7 2px, #959fd7 3px);
  }

  .rst__lineHalfHorizontalLeftVerticalTop::before {
    height: 3px;
    top: 50%;
    right: 100%;
    width: 8%;
    background: repeating-linear-gradient(to right, #959fd7 3px, #959fd7 3px);
  }

  .rst__lineHalfVerticalTop::after {
    width: 3px;
    left: -8%;
    bottom: 46%;
    height: ${(props) =>
      props.node ? (props.node.lineIndex! - (props.node.parentNode?.lineIndex || 1)) * 125 : 0}%;
    background: repeating-linear-gradient(to bottom, #959fd7 3px, #959fd7 3px);
  }

  .rowWrapper {
    height: 100%;
  }

  .row {
    width: 100%;
    height: 100%;
    .rowContents {
      width: 100%;
      height: 100%;
      padding: 5px 16px;
      background: #ffffff;
      border: 1px solid #cecfd8;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      cursor: pointer;
      .top-side p {
        font-size: 13px;
        color: #2a2a2a;
        height: 20px;
        margin: 0;
        max-width: 185px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .bottom-side {
        display: flex;
        width: 100%;
        color: #999999;
        justify-content: flex-end;
        align-items: center;
        .people-quantity {
          font-family: Lato;
          font-weight: 400;
          font-size: 12px;
          margin-right: 5px;
        }
        .arrow-icon {
          font-size: 8px;
        }
      }
    }
  }
  .create-button {
    position: absolute;
    right: -6px;
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 5px;
    button {
      width: 20px;
      height: 20px;
      cursor: pointer;
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 3px;
      border: none;
      .size-icon {
        color: #ffffff;
        font-size: 10px;
      }
    }
  }
`;
