import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';
import {
  getDataCurriculumBatch,
  getDataEmployee,
  getDataEmployeeDetail,
  getDataSelectCreator,
  getDataSelectCurriculum,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckCategory,
  getDataSelectSkillCheckCreator,
  getDataSelectSkillCheckGroupCode,
  getDataSelectSkillCheckStartPeriod,
  getDataSelectSkillCheckStatus,
  getDataSkillCheck,
  getSelectDepartment,
  getSelectEmployee,
  getSelectPosition,
  getSelectRole,
  getUserInfoDetail,
  getDataRequiredCurriculumSettings,
  getDataRequiredSkillcheckSettings,
  getAffiliationAssignLevel,
  getDataAllGmail,
} from './thunk';

export interface InitialState {
  total: number;
  dataEmployee: Array<Types.EmployeeUser.ResponseType>;
  dataSelectRole: Array<Types.SelectRole.ResponseType>;
  dataSelectEmployeeName: Array<string>;
  dataSelectEmployeeCode: Array<string>;
  dataSkillCheck: Array<Types.SkillCheck.ResponseType>;
  dataSelectPosition: Array<Types.SelectPosition.ResponseType>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataCurriculumBatch: Array<Types.Curriculums.ResponseType>;
  dataSelectCurriculum: Array<Types.SelectRequiredCurriculum.ResponseType>;
  dataSelectCreator: Array<string>;
  dataSelectSkillCheck: Array<Types.SelectSkillCheck.ResponseType>;
  dataSelectStartPeriod: Array<Types.SelectSkillCheckStartPeriod.ResponseType>;
  dataSelectCategory: Array<Types.SelectSkillCheckCategory.ResponseType>;
  dataSelectGroupingCode: Array<Types.SelectSkillCheckGroupingCode.ResponseType>;
  dataSelectUseStatus: Array<Types.SelectSkillCheckUseStatus.ResponseType>;
  dataSelectCreators: Array<Types.SelectSkillCheckCreator.ResponseType>;
  dataSkillUserCheck: Array<Types.RequiredSkillcheckSettings.ResponseType>;
  dataCurriculumUserCheck: Array<Types.RequiredCurriculumSettings.ResponseType>;
  dataEmployeeDetail?: Types.EmployeeUser.EmployeeDetailResponseType;
  userInfoDetail: Array<Types.Users.ResponseType>;
  dataAffiliationAssignLevel: Array<Types.AffiliationAssignLevel.ResponseType>;
  dataAllGmail: Array<string>;
  countUserMember: number;
}

const initialState: InitialState = {
  total: 0,
  dataEmployee: [],
  dataSkillCheck: [],
  dataSelectRole: [],
  dataSelectPosition: [],
  dataSelectDepartment: [],
  dataSelectEmployeeName: [],
  dataSelectEmployeeCode: [],
  dataCurriculumBatch: [],
  dataSelectCurriculum: [],
  dataSelectCreator: [],
  dataSelectSkillCheck: [],
  dataSelectStartPeriod: [],
  dataSelectCategory: [],
  dataSelectGroupingCode: [],
  dataSelectUseStatus: [],
  dataSelectCreators: [],
  dataCurriculumUserCheck: [],
  dataSkillUserCheck: [],
  userInfoDetail: [],
  dataAffiliationAssignLevel: [],
  dataAllGmail: [],
  countUserMember: 0,
};

export const employeeSlice = createSlice({
  name: 'employee-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataEmployee.fulfilled, (state, action) => {
      state.dataEmployee = action.payload.report_results;
      state.total = action.payload.totalItems;
      action.payload.report_results.forEach((e) => {
        if (e.user_type === 'member') {
          state.countUserMember += 1;
        }
      });
    });

    builder.addCase(getSelectPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.report_results;
    });

    builder.addCase(getSelectDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });

    builder.addCase(getSelectRole.fulfilled, (state, action) => {
      state.dataSelectRole = action.payload.report_results;
    });

    builder.addCase(getSelectEmployee.fulfilled, (state, action) => {
      const newDataSelectEmployeeName: Array<string> = [];
      const newDataSelectEmployeeCode: Array<string> = [];
      action.payload.report_results.forEach((item) => {
        if (item.name) {
          newDataSelectEmployeeName.push(item.name);
        }
        if (item.code) {
          newDataSelectEmployeeCode.push(item.code);
        }
      });
      state.dataSelectEmployeeName = newDataSelectEmployeeName;
      state.dataSelectEmployeeCode = newDataSelectEmployeeCode;
    });

    builder.addCase(getDataSkillCheck.fulfilled, (state, action) => {
      state.dataSkillCheck = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getDataCurriculumBatch.fulfilled, (state, action) => {
      state.dataCurriculumBatch = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getDataSelectCurriculum.fulfilled, (state, action) => {
      state.dataSelectCurriculum = action.payload.report_results;
    });

    builder.addCase(getDataSelectCreator.fulfilled, (state, action) => {
      state.dataSelectCreator = unionBy(action.payload.report_results.map((item) => item.creator));
    });

    builder.addCase(getDataSelectSkillCheck.fulfilled, (state, action) => {
      state.dataSelectSkillCheck = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckStartPeriod.fulfilled, (state, action) => {
      state.dataSelectStartPeriod = unionBy(action.payload.report_results, ({ start_period }) =>
        dayjs(start_period).format('YYYY/MM')
      );
    });

    builder.addCase(getDataSelectSkillCheckCategory.fulfilled, (state, action) => {
      state.dataSelectCategory = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckGroupCode.fulfilled, (state, action) => {
      state.dataSelectGroupingCode = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckStatus.fulfilled, (state, action) => {
      state.dataSelectUseStatus = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckCreator.fulfilled, (state, action) => {
      state.dataSelectCreators = action.payload.report_results;
    });

    builder.addCase(getDataEmployeeDetail.fulfilled, (state, action) => {
      const detail: Types.EmployeeUser.EmployeeDetailResponseType = {
        birthday: action.payload.report_results[0].birthday,
        email: action.payload.report_results[0].email,
        employee_code: action.payload.report_results[0].employee_code,
        hire_date: action.payload.report_results[0].hire_date,
        i_id: action.payload.report_results[0].i_id,
        kana: action.payload.report_results[0].kana,
        name: action.payload.report_results[0].name,
      };

      action.payload.report_results.forEach((value) => {
        Object.assign(detail, {
          ...(value.user_main_role === 'main'
            ? {
                main_position: {
                  position_id: value.item_ref?.department_name.i_id,
                  affiliation_id: value.lookup_items?.department_name.i_id,
                  position_code: value.lookup_items?.position_code.i_id,
                },
              }
            : {
                sub_position: [
                  ...(detail.sub_position || []),
                  {
                    position_id: value.item_ref?.department_name.i_id,
                    affiliation_id: value.department_name,
                    position_code: value.position_code,
                  },
                ],
              }),
        });
      });
      state.dataEmployeeDetail = detail;
    });

    builder.addCase(getUserInfoDetail.fulfilled, (state, action) => {
      state.userInfoDetail = action.payload.items;
    });

    builder.addCase(getDataRequiredCurriculumSettings.fulfilled, (state, action) => {
      state.dataCurriculumUserCheck = action.payload.report_results;
    });

    builder.addCase(getDataRequiredSkillcheckSettings.fulfilled, (state, action) => {
      state.dataSkillUserCheck = action.payload.report_results;
    });

    builder.addCase(getAffiliationAssignLevel.fulfilled, (state, action) => {
      state.dataAffiliationAssignLevel = action.payload.items;
    });
    builder.addCase(getDataAllGmail.fulfilled, (state, action) => {
      state.dataAllGmail = action.payload.items.map((item) => item.email);
    });
  },
});

export const { resetInitialState } = employeeSlice.actions;

export default employeeSlice.reducer;
