import React, { useEffect, useRef, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import zipcodeJa from 'zipcode-ja';

import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import { setInformationRegister } from 'containers/InformationRegister/slice';
import { authSelector } from 'containers/Auth/selectors';
import { getDataCompany } from 'containers/Auth/thunk';
import { secondStepSchema } from 'libs/validations';
import { RegisterInformationFormik } from 'types';
import { Wrapper, WrapTermOfUse } from './styles';
import { useAppDispatch } from 'hooks';
import PopupProvision from './Modal';
import { Input } from 'components';

interface Props {
  nextStep: () => void;
}

const FirstStep: React.FC<Props> = ({ nextStep }) => {
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [isRead, setIsRead] = useState<boolean>(false);

  const { information } = useSelector(informationRegisterSelector);
  const { signedInEmail, dataCompany } = useSelector(authSelector);
  const { userInfo } = useSelector(authSelector);
  const topRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const { messages } = useIntl();
  const formik = useFormik<RegisterInformationFormik>({
    initialValues: dataCompany
      ? {
          name: dataCompany.name || '',
          name_furigana: dataCompany.name_furigana || '',
          postal_code: dataCompany.postal_code || '',
          prefecture: dataCompany.prefecture || '',
          address: dataCompany.address || '',
          plot_number: dataCompany.plot_number || '',
          building_name: dataCompany.building_name || '',
          admin_name: dataCompany.admin_name || '',
          admin_name_furigana: dataCompany.admin_name_furigana || '',
          admin_department: dataCompany.admin_department || '',
          admin_position: dataCompany.admin_position || '',
          admin_phone: dataCompany.admin_phone || '',
          admin_email: dataCompany.admin_email || '',
          admin_email_confirm: dataCompany.admin_email_confirm || '',
        }
      : { ...information, admin_email: signedInEmail || '' },
    validationSchema: secondStepSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(setInformationRegister(values));
      nextStep();
    },
  });

  useEffect(() => {
    (async () => {
      if (!userInfo?.email) return;
      await dispatch(
        getDataCompany({
          conditions: [
            {
              id: 'admin_email',
              search_value: [userInfo?.email],
              exact_match: true,
            },
          ],

          page: 1,
          per_page: 1,
        })
      );
    })();
  }, [userInfo, dispatch]);

  const convertZipCodeToAddress = () => {
    if (formik.values.postal_code) {
      const address = zipcodeJa[formik.values.postal_code as unknown as number]?.address;
      formik.setFieldValue('prefecture', address ? address[0] : '');
      formik.setFieldValue('address', address ? `${address[1]} ${address[2]}` : '');
      if (address) {
        formik.setErrors({
          prefecture: undefined,
          address: undefined,
        });
        formik.setFieldTouched('prefecture', false);
        formik.setFieldTouched('address', false);
      }
    }
  };

  return (
    <Wrapper ref={topRef}>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  {messages['AJ-02-7']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="name" className="input" type="text" placeholder="全角：最大100文字" />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-8']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name_furigana"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  {messages['AJ-02-9']}
                  <span className="require">*</span>
                </span>
              }
              className={`form-input wrap-input-zip-code ${
                formik.touched.postal_code && formik.errors.postal_code && 'error-input-zip-code'
              }`}
            >
              <Input
                name="postal_code"
                className="input"
                type="text"
                maxLength={7}
                placeholder="数字：７文字（ハイフンなし）"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const filteredValue = inputValue.replace(/[^0-9]/g, '');
                  formik.setFieldValue('postal_code', filteredValue);
                }}
              />
              <button type="button" className="btn-check-zipCode" onClick={convertZipCodeToAddress}>
                {messages['AJ-02-10']}
              </button>
            </Form.Item>
            <Form.Item
              name="prefecture"
              label={
                <span className="text-label">
                  {messages['AJ-02-11']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="prefecture"
                type="text"
                placeholder="全角：最大100文字"
                className="input"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  {messages['AJ-02-12']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="address"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
                maxLength={100}
              />
            </Form.Item>
            <Form.Item
              name="plot_number"
              label={
                <span className="text-label">
                  {messages['AJ-02-13']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="plot_number"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">{messages['AJ-02-14']}</span>}
              className="form-input"
            >
              <Input
                name="building_name"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  {messages['AJ-02-15']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-16']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_name_furigana"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_department"
              label={
                <span className="text-label">
                  {messages['AJ-02-17']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_department"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_position"
              label={
                <span className="text-label">
                  {messages['AJ-02-18']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_position"
                className="input"
                type="text"
                maxLength={100}
                placeholder="全角：最大100文字"
              />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  {messages['AJ-02-19']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="admin_phone"
                className="input"
                type="tel"
                placeholder="数字：最大11文字（ハイフン無し）"
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || formik.values.admin_phone!.toString().length > 10) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={<span className="text-label">{messages['AJ-02-20']}</span>}
              className="form-input"
            >
              <Input readOnly name="admin_email" className="input__disabled input" type="text" />
            </Form.Item>
            <WrapTermOfUse>
              <div className="left-side">
                <button className="btn-show" type="button" onClick={() => setIsShow(true)}>
                  {messages['AJ-02-21']}
                </button>
              </div>
              <div className="right-side">
                <p className="text-content">{messages['AJ-02-22']}</p>
                <div className="wrap-checkbox">
                  <input
                    id="term-and-policy"
                    type="checkbox"
                    disabled={!isRead}
                    onChange={(e) => setIsApproved(e.target.checked)}
                  />
                  <label
                    htmlFor="term-and-policy"
                    className={`label ${isRead && 'enable-checkbox'}`}
                  >
                    {messages['AJ-02-23']}
                  </label>
                </div>
              </div>
            </WrapTermOfUse>
          </div>
          <div className="wrap-button">
            <SubmitButton
              disabled={!isApproved && formik.dirty && !formik.isValid}
              className={'btn-submit'}
              onClick={() => {
                if (Object.keys(formik.errors).length > 0) {
                  if (topRef.current) {
                    topRef.current.scrollIntoView({ behavior: 'smooth' });
                  }
                } else {
                  formik.handleSubmit();
                }
              }}
            >
              {messages['AJ-02-24']}
            </SubmitButton>
          </div>
        </Form>
      </FormikProvider>
      {isShow ? (
        <PopupProvision
          company_name={formik.values.name}
          onSubmit={() => {
            setIsShow(false);
            setIsRead(true);
          }}
          onClose={() => setIsShow(false)}
        />
      ) : null}
    </Wrapper>
  );
};

export default FirstStep;
