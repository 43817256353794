import React from 'react';
import { Form } from 'formik-antd';
import { Modal, TextArea } from 'components';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  value: string;
}

const EnlargeInput: React.FC<Props> = ({ visible, onCancel, onSubmit, value }) => {
  return (
    <Modal
      width={860}
      title="解説"
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: onCancel,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: onCancel,
      }}
      onCancel={onCancel}
      onOk={onSubmit}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: '40px 40px 20px' }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <Form.Item name="work-history">
        <TextArea
          name="work-history"
          showCount
          maxLength={1050}
          autoSize={{ minRows: 3, maxRows: 25 }}
          defaultValue={value}
        />
      </Form.Item>
    </Modal>
  );
};

export default EnlargeInput;
