import { createAsyncThunk } from '@reduxjs/toolkit';

import { GROUPS, SKILL_CHECK } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getAnalysisGroup = createAsyncThunk<
  Types.GetItemResponseType<Types.Groups.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/getAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Groups.ResponseType>(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAnalysisGroupOptions = createAsyncThunk<
  Types.GetItemResponseType<Types.Groups.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/getAnalysisGroupOptions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Groups.ResponseType>(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAnalysisGroup = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Groups.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/updateAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAnalysisGroup = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Groups.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/createAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAnalysisGroup = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/deleteAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(GROUPS.id, req);

    return { ...data, item: req.id };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAnalysisGroupCSV = createAsyncThunk<
  Types.GetItemResponseType<Types.Groups.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/getAnalysisGroupCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Groups.ResponseType>(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AnalysisGroup/thunk/getSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
