import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  width: 100%;
  padding: 30px;
  .item-edit {
    display: flex;
    justify-content: start;
    align-items: center;
    .icon {
      margin-right: 8px;
    }
    .label {
      margin: 0;
      font-family: Lato;
      font-size: 13px;
      color: #424242;
    }
  }
  .wrap-item {
    display: flex;
    align-items: center;
    .item {
      width: 18px;
      height: 18px;
      background: #f6ac00;
      border-radius: 2px;
      font-weight: 700;
      font-size: 10px;
      text-align: center;
      color: #ffffff;
      margin: 0 8px;
    }
    .pink {
      background: #f33456;
    }
    .green {
      background: #08a3a5;
    }
    .label {
      margin: 0;
      font-family: Lato;
      font-size: 13px;
      color: #424242;
    }
  }
  .item-type {
    display: flex;
    align-items: center;
    justify-content: start;
    .type {
      width: 18px;
      height: 18px;
      background: #4e83d3;
      border-radius: 2px;
      font-weight: 700;
      font-size: 11px;
      text-align: center;
      color: #ffffff;
      margin: 0 8px;
    }
    .exception {
      background: #f08b84;
    }
    .label {
      margin: 0;
      font-family: Lato;
      font-size: 13px;
      color: #424242;
    }
  }

  .text-note {
    font-size: 12px;
    color: #424242;
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 15px 0;
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;

      font-size: 13px;
      margin-right: 8px;
      font-weight: 700;
      width: 180px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      .size-icon {
        font-size: 18px;
      }
    }
    .btn-disable {
      cursor: not-allowed;
      pointer-events: none;
      background: #cccccc;
      border: 1px solid #cccccc;
      color: #777777;
      font-size: 13px;
      margin-right: 8px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      -webkit-user-select: none;
      user-select: none;
    }
    .btn-outline {
      position: relative;
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      p {
        color: #f1a900;
        margin: 0;
      }
      .icon {
        color: #f6ac00;
        position: absolute;
        right: 15px;
      }
    }
  }
  .text-count {
    margin: 12px 0 -50px 0;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    .item-right {
      display: flex;
      .select-time-limit {
        margin-left: 20px;
      }
      .count {
        padding-left: 20px;
        .text-page {
          padding: 0 5px;
        }
      }
    }
    .select-time-limit {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      background-color: #ebebeb;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
      .label {
        display: flex;
        font-size: 12px;
        color: #424242;
        .question-mark-icon {
          color: #08a3a5;
          font-size: 18px;
          cursor: pointer;
          margin-left: 5px;
        }
      }
      .select-limit {
        width: 80px;
        margin-bottom: 0;
      }
      .btn-search,
      .disabled {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
        border-radius: 5px;
        color: #ffffff;
        margin-left: 10px;
      }
      .disabled {
        background: #cccccc;
        border: 1px solid #cccccc;
        color: #777777;
      }
    }
  }
`;
