import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { Background, BackgroundComplete, MissionComplete } from 'assets';
import { trainingCurriculumSelector } from '../selectors';
import TrainingCongratulationStyled from './styles';
import { useAppDispatch } from 'hooks';

interface Props {
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setCorrectNum: React.Dispatch<React.SetStateAction<number>>;
}

const TrainingCongratulation: React.FC<Props> = ({ setChangeTab, setCorrectNum }) => {
  const { dataAllQuestion } = useSelector(trainingCurriculumSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TrainingCongratulationStyled>
      <img src={Background} className="background" alt="logo-background" />
      <img
        src={BackgroundComplete}
        className="background-complete"
        alt="logo-background-complete"
      />
      <div className="body-status">
        <div className="title-complete">
          <div className="title">
            <p className="complete">CONGURATULATION!</p>
          </div>
          <p className="text">全ての設問に正解しました！</p>
        </div>
        <div className="item">
          <img src={MissionComplete} className="mission-complete" alt="mission-complete" />
          <p className="title">カリキュラム</p>
          <p className="content">{dataAllQuestion[0]?.curriculum_name}</p>
          <div className="text-count">
            {dataAllQuestion.length} 問正解 / {dataAllQuestion.length} 問中
          </div>
          <div className="btn">
            <button
              onClick={() => {
                setChangeTab(0);
                setCorrectNum(0);
              }}
              className="btn-status"
            >
              選択画面に戻る
            </button>
          </div>
        </div>
      </div>
    </TrainingCongratulationStyled>
  );
};

export default TrainingCongratulation;
