import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 20px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  z-index: 1001;
  .container-logo {
    display: flex;
    height: calc(100vh - 40px);
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 36px;
    min-height: 100%;
    border-radius: 5px;
    background: #f9f8f8;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
  }
`;

export const WrapperFirstLoginModal = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .logo {
    width: 732px;
    height: 64px;
  }
  .title {
    margin: 80px 0 60px 0;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    color: #424242;
  }
  .text-content {
    text-align: center;
    color: #777777;
    font-size: 16px;
  }
  .btn-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 15px 100px;
    margin: 28px auto 0 auto;
    background-color: #f6ac00;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    color: #ffffff;
    cursor: pointer;
  }
`;
