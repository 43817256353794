import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from 'containers/Auth/selectors';

export const useCheckUpload = () => {
  const { dataCompany, totalFileSize } = useSelector(authSelector);

  const isDisabledUpload = useMemo(() => {
    return dataCompany?.plan_id === 'standard' && totalFileSize >= 3;
  }, [dataCompany?.plan_id, totalFileSize]);

  return { isDisabledUpload };
};
