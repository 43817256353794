import React, { useState } from 'react';
import { UploadFile as UploadFileAntd, UploadChangeParam } from 'antd/lib/upload/interface';
import saveAs from 'file-saver';
import { Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FileOutlined,
} from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  fileName: string;
  onSubmit: (file: File) => Promise<void> | void;
  headersTemplate?: Array<{ label: string; key: string }>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const UploadCSV: React.FC<Props> = ({
  visible,
  fileName,
  onSubmit,
  setVisible,
  headersTemplate,
}) => {
  const [isUploadSuccessfully, setUploadSuccessfully] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const onClose = () => {
    setVisible(false);
    setFile(undefined);
    setUploadSuccessfully(false);
  };

  const handleDelete = () => {
    setFile(undefined);
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(file as File);
    setUploadSuccessfully(true);
    setFile(undefined);
    setLoading(false);
  };

  // TODO: data has not been converted - not resolved
  const handleExportCSV = () => {
    const csvString = [(headersTemplate || []).map(({ label }) => label)]
      .map((e) => e.join(','))
      .join('\n');
    const bom = '\uFEFF';
    const fileExport = new Blob([bom, csvString], { type: 'application/octet-stream' });
    saveAs(fileExport, fileName);
  };

  return (
    <Modal
      title="カリキュラムマスタ インポート"
      width={720}
      visible={visible}
      okButton={
        file || isUploadSuccessfully
          ? {
              text: isUploadSuccessfully ? 'OK' : 'インポート',
              onClick: isUploadSuccessfully ? onClose : handleSubmit,
              loading,
            }
          : undefined
      }
      cancelButton={
        !isUploadSuccessfully
          ? {
              text: 'キャンセル',
              onClick: onClose,
            }
          : undefined
      }
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {!isUploadSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <div className="file-upload">
                <div>
                  <Dragger
                    accept=".csv"
                    beforeUpload={() => false}
                    onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                      setFile(info.file as unknown as File);
                    }}
                  >
                    {file ? (
                      <div className="info-file">
                        <p className="file-name">{file.name} </p>
                      </div>
                    ) : (
                      <>
                        <CloudUploadOutlined className="icon" />
                        <p className="ant-upload-text">
                          インポートするCSVファイルをここにドロップ
                          <br />
                          または
                        </p>
                      </>
                    )}
                    <div className="wrap-button-upload">
                      <button type="button" className="btn-upload">
                        ファイルを選択
                      </button>
                      {file && (
                        <button
                          className="btn-delete"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                          }}
                        >
                          <DeleteOutlined className="icon-delete-outlined" />
                          <span className="label-delete">ファイルを削除</span>
                        </button>
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
            <p className="text-download-template" onClick={handleExportCSV}>
              <FileOutlined className="icon" />
              テンプレートをダウンロード
            </p>
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadCSV;
