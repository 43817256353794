import React, { useState } from 'react';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';

import { ItemDropDownStyled, StyledPullDown } from './styles';
import { UserAffiliation } from 'types/services/affiliation';
import { adminAvatar } from 'assets';

interface Props {
  index: number;
  user: UserAffiliation;
  affiliation_id?: string;
  setOpenDetail?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemDropDown: React.FC<Props> = ({ index, user, affiliation_id, setOpenDetail }) => {
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);

  return (
    <>
      <Dropdown
        overlay={
          <StyledPullDown>
            <p className="text-main">メイン</p>
            {user?.sub_positions[0]?.user_role_order === 1 && (
              <p className="text-position">
                {user.sub_positions[0]?.user_departments} / {user.sub_positions[0]?.user_positions}
              </p>
            )}
            <p className="text-concurrent-post">兼務</p>
            {user.sub_positions
              ?.filter((item) => item.user_role_order && item.user_role_order > 1)
              .map((subPosition, i) => (
                <p className="text-content" key={i}>
                  <span className="text-number">{i + 1}</span>
                  {subPosition.user_departments} / {subPosition.user_positions}
                </p>
              ))}
            <UpOutlined
              onClick={() => setVisibleDropDown(false)}
              className="text-icon-up-outline"
            />
          </StyledPullDown>
        }
        placement="bottom"
        open={visibleDropDown}
        onOpenChange={setVisibleDropDown}
      >
        <ItemDropDownStyled itemActive={visibleDropDown}>
          {index === 0 && <div className="text-body">責任者</div>}
          <div className="wrapper">
            <Avatar className="avatar" src={user.user_icon_fileID ?? adminAvatar} />
            <div className="user">
              <div className="wrap-concurrent-post">
                {(user.sub_positions[0].user_role_order! > 1 ||
                  user.sub_positions[0].affialition_id !== affiliation_id) && (
                  <span className="text-concurrent-post">兼務</span>
                )}
              </div>
              <div className="user-info">
                <p className="text-position">{user.sub_positions[0]?.user_positions}</p>
                <p className="text-name">{user.user_name}</p>
              </div>
            </div>
            {(user.sub_positions[0].user_role_order! > 1 ||
              user.sub_positions[0].affialition_id !== affiliation_id) && (
              <DeleteOutlined
                className="icon-delete"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  e.stopPropagation();
                }}
              />
            )}
          </div>
          <div className="drop-down">{!visibleDropDown && <DownOutlined />}</div>
        </ItemDropDownStyled>
      </Dropdown>
    </>
  );
};

export default ItemDropDown;
