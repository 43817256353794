import zhCN from 'antd/es/locale/zh_CN';

import { Entries } from 'types/config';
import zhMessages from '../locales/zh_CN.json';

const ZhLang: Entries = {
  messages: {
    ...zhMessages,
  },
  antd: zhCN,
  locale: 'zh',
};

export default ZhLang;
