import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { maxBy } from 'lodash';

import { memoizedGetFlatDataFromTree } from 'libs/utils/explorer/memoized-tree-data-utils';
import { changeNodeAtID, defaultGetNodeKey } from 'libs/utils/explorer/tree-data-utils';
import { setActiveFolder, setManualFolderList } from 'pages/Manual/slice';
import NodeRendererDefault from './node-content-renderer';
import { manualSelector } from 'pages/Manual/selectors';
import { FileExplorerWrapper } from './styles';
import { useAppDispatch } from 'hooks';
import { FileExplorer } from 'types';

interface Props {
  scaleNum: number;
  disabled?: boolean;
  resizeRef?: React.RefObject<HTMLDivElement>;
  handleMoveFolder?: (dragItem: FileExplorer.TreeItem, dropItem: FileExplorer.TreeItem) => void;
  handleMoveFile?: (dragItem: FileExplorer.DataTableType, dropItem: FileExplorer.TreeItem) => void;
}

const TreeNodeContainer: React.FC<Props> = ({
  disabled,
  scaleNum,
  resizeRef,
  handleMoveFile,
  handleMoveFolder,
}) => {
  const { manualFolderList, activeFolder } = useSelector(manualSelector);

  const dispatch = useAppDispatch();

  const rows: FileExplorer.FlatDataItem[] = useMemo(
    () =>
      memoizedGetFlatDataFromTree({
        ignoreCollapsed: true,
        getNodeKey: defaultGetNodeKey,
        treeData: manualFolderList,
      }),
    [manualFolderList]
  );

  const lowerSiblingMax = useMemo(
    () =>
      maxBy(rows, (row: FileExplorer.FlatDataItem) => row.lowerSiblingCounts.length)
        ?.lowerSiblingCounts.length,
    [rows]
  );

  const toggleChildrenVisibility = useCallback(
    (i_id: string) => {
      const newTreeData = changeNodeAtID({
        treeData: manualFolderList,
        i_id,
        newNode: (node: FileExplorer.TreeItem) => ({
          ...node,
          expanded: !node.expanded,
        }),
      });

      dispatch(setManualFolderList(newTreeData));
    },
    [dispatch, manualFolderList]
  );

  const clickChildrenNode = useCallback(
    (i_id: string) => {
      const newTreeData = changeNodeAtID({
        treeData: manualFolderList,
        i_id,
        newNode: (node: FileExplorer.TreeItem) => {
          if (activeFolder?.i_id !== node.i_id && !node.isVirtualCreating) {
            dispatch(setActiveFolder({ ...node }));
          }
          return { ...node };
        },
      });

      dispatch(setManualFolderList(newTreeData));
    },
    [activeFolder, dispatch, manualFolderList]
  );

  return (
    <FileExplorerWrapper>
      {rows.map((row, index) => {
        const { node, lowerSiblingCounts, treeIndex, parentNode } = row;

        return (
          <NodeRendererDefault
            key={index}
            node={node}
            listIndex={index}
            resizeRef={resizeRef}
            disabled={disabled}
            scaleNum={scaleNum}
            treeIndex={treeIndex}
            parentNode={parentNode}
            handleMoveFile={handleMoveFile}
            lowerSiblingMax={lowerSiblingMax}
            handleMoveFolder={handleMoveFolder}
            clickChildrenNode={clickChildrenNode}
            lowerSiblingCounts={lowerSiblingCounts}
            toggleChildrenVisibility={toggleChildrenVisibility}
          />
        );
      })}
    </FileExplorerWrapper>
  );
};

export default TreeNodeContainer;
