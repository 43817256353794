import { createSlice } from '@reduxjs/toolkit';
import { groupBy, mapValues, minBy, unionBy, uniqBy } from 'lodash';
import dayjs from 'dayjs';

import {
  getReportSkillCheckUserTransComparison,
  getReportSkillCheckUserTrans2,
  getReportSkillCheckUserTrans,
} from './thunk';
import * as Types from 'types';

export interface InitialState {
  reportSkillCheckUserTrans2: Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[];
  reportSkillCheckUserTrans: Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[];
  totalReportSkillCheckUserTrans: number;
  totalReportSkillCheckUserTrans2: number;
  userAnalysisComparison: {
    columnName: string[];
    data: Types.ReportSkillCheckUserTrans.UserReportSurfaceTable[];
  };
  groupAnalysisComparison: {
    columnName: string[];
    data: Types.ReportSkillCheckUserTrans.GroupReportSurfaceTable[];
  };
}

const initialState: InitialState = {
  totalReportSkillCheckUserTrans2: 0,
  totalReportSkillCheckUserTrans: 0,
  reportSkillCheckUserTrans2: [],
  reportSkillCheckUserTrans: [],
  userAnalysisComparison: {
    columnName: [],
    data: [],
  },
  groupAnalysisComparison: {
    columnName: [],
    data: [],
  },
};

export const skillCheckUserAnalysisReportSlice = createSlice({
  name: 'skillCheckUserAnalysisReport-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getReportSkillCheckUserTrans.fulfilled, (state, action) => {
      const skillcheckCodeStart = minBy(
        action.payload.report_results.filter(
          (record) =>
            dayjs(action.payload.startPeriod).format('YYYY/MM/DD') ===
            dayjs(record.base_date_for_report).format('YYYY/MM/DD')
        ),
        'skill_check_created_at'
      );

      const skillcheckCodeEnd = minBy(
        action.payload.report_results.filter(
          (record) =>
            dayjs(action.payload.endPeriod).format('YYYY/MM/DD') ===
              dayjs(record.base_date_for_report).format('YYYY/MM/DD') &&
            record.skill_check_code !== skillcheckCodeStart?.skill_check_code
        ),
        'skill_check_created_at'
      );

      const acquisition_score_start: Array<Types.ReportSkillCheckUserTrans.ResponseType> =
        action.payload.report_results.filter(
          (e) => e.skill_check_code === skillcheckCodeStart?.skill_check_code
        );
      const acquisition_score_end: Array<Types.ReportSkillCheckUserTrans.ResponseType> =
        action.payload.report_results.filter(
          (e) => e.skill_check_code === skillcheckCodeEnd?.skill_check_code
        );

      const dataGroupByLoginID = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
        [...acquisition_score_start, ...acquisition_score_end],
        'login_id'
      );

      const response_num_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.responses_num || 0), 0);

      const prob_count_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.probs_count || 0), 0);

      const prob_count_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.probs_count || 0), 0);

      const correct_answer_num_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.correct_answers_num || 0), 0);

      const inexperienced_start = prob_count_start - response_num_start;

      const response_num_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.responses_num || 0), 0);

      const correct_answer_num_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.correct_answers_num || 0), 0);

      const inexperienced_end = prob_count_end - response_num_end;

      const reportSkillCheckUserTrans: Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[] =
        Object.keys(dataGroupByLoginID).flatMap((dataGroupByLoginIDkey) => {
          const dataStartLogin = acquisition_score_start.filter(
            (item) => item.login_id === dataGroupByLoginIDkey
          );

          const dataEndLogin = acquisition_score_end.filter(
            (item) => item.login_id === dataGroupByLoginIDkey
          );

          let start = {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 1,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
            num_skill_check: 0,
            implementation_date: '',
          };
          let end = {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 1,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
            num_skill_check: 0,
            implementation_date: '',
          };

          if (dataStartLogin.length) {
            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
              num_skill_check_start,
              implementation_date,
            } = dataStartLogin.reduce(
              (prev, next) => ({
                implementation_date: prev.implementation_date,
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time + (next.answer_time || 0),
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
                num_skill_check_start: prev.num_skill_check_start,
                num_skill_check_end: prev.num_skill_check_end,
              }),
              {
                implementation_date: dataStartLogin[0].implementation_date,
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: 0,
                correct_answers_score: 0,
                num_skill_check_start: unionBy(dataStartLogin, 'skill_check_code').filter(
                  (e) =>
                    dayjs(action.payload.startPeriod)
                      .add(1, 'day')
                      .diff(dayjs(e.implementation_date)) > 0
                ).length,
                num_skill_check_end: unionBy(dataStartLogin, 'skill_check_code').filter(
                  (e) =>
                    dayjs(action.payload.startPeriod)
                      .add(1, 'day')
                      .diff(dayjs(e.implementation_date)) < 0
                ).length,
              }
            );

            start = {
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / probs_count,
              setting_score,
              acquisition_score,
              acquisition_score_chart: acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              num_skill_check: num_skill_check_start,
              implementation_date: implementation_date || '',
            };
          }

          if (dataEndLogin.length) {
            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
              num_skill_check_end,
              implementation_date,
            } = dataEndLogin.reduce(
              (prev, next) => ({
                implementation_date: prev.implementation_date,
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time,
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
                num_skill_check_start: prev.num_skill_check_start,
                num_skill_check_end: prev.num_skill_check_end,
              }),
              {
                implementation_date: dataEndLogin[0].implementation_date,
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: dayjs(dataEndLogin[0]?.implementation_end_date).diff(
                  dayjs(dataEndLogin[0]?.implementation_date),
                  'seconds'
                ),
                correct_answers_score: 0,
                num_skill_check_start: unionBy(dataEndLogin, 'skill_check_code').filter(
                  (e) =>
                    dayjs(action.payload.startPeriod)
                      .add(1, 'day')
                      .diff(dayjs(e.implementation_date)) > 0
                ).length,
                num_skill_check_end: unionBy(dataEndLogin, 'skill_check_code').filter(
                  (e) =>
                    dayjs(action.payload.startPeriod)
                      .add(1, 'day')
                      .diff(dayjs(e.implementation_date)) < 0
                ).length,
              }
            );

            end = {
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / probs_count,
              setting_score,
              acquisition_score,
              acquisition_score_chart: acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              num_skill_check: num_skill_check_end,
              implementation_date: implementation_date || '',
            };
          }

          return {
            i_id: dataGroupByLoginID[dataGroupByLoginIDkey][0].i_id,
            user_name: dataGroupByLoginID[dataGroupByLoginIDkey][0].user_name,
            login_id: dataGroupByLoginID[dataGroupByLoginIDkey][0].login_id,
            skill_check_name: dataGroupByLoginID[dataGroupByLoginIDkey][0]?.skill_check_name,
            skill_check_code: dataGroupByLoginID[dataGroupByLoginIDkey][0]?.skill_check_code,
            start,
            end,
            change: {
              accuracy_rate: end.accuracy_rate - start.accuracy_rate,
              wrong_rate: end.wrong_rate - start.wrong_rate,
              inexperienced_rate: end.inexperienced_rate - start.inexperienced_rate,
              average_answer_time_per_question:
                end.average_answer_time_per_question - start.average_answer_time_per_question,
              acquisition_score_rate: end.acquisition_score_rate - start.acquisition_score_rate,
              correct_answers_num: end.correct_answers_num - start.correct_answers_num,
              incorrect_answer: end.incorrect_answer - start.incorrect_answer,
              inexperienced: end.inexperienced - start.inexperienced,
              probs_count: end.probs_count - start.probs_count,
              acquisition_score: end.acquisition_score - start.acquisition_score,
              question_time_limit: end.question_time_limit - start.question_time_limit,
              responses_num: end.responses_num - start.responses_num,
              setting_score: end.setting_score - start.setting_score,
              total_answer_time: end.total_answer_time - start.total_answer_time,
              correct_answers_score: end.correct_answers_score - start.correct_answers_score,
            },
            implementation_date: start.implementation_date,
          };
        });

      const dataLength = reportSkillCheckUserTrans.length;
      const { start, end } = reportSkillCheckUserTrans.reduce(
        (prev, next) => ({
          start: {
            accuracy_rate: prev!.start!.accuracy_rate! + next!.start!.accuracy_rate!,
            wrong_rate: prev!.start!.wrong_rate! + next!.start!.wrong_rate!,
            inexperienced_rate: prev!.start!.inexperienced_rate! + next!.start!.inexperienced_rate!,
            average_answer_time_per_question:
              prev!.start!.average_answer_time_per_question! +
              next!.start!.average_answer_time_per_question!,
            acquisition_score_rate:
              prev!.start!.acquisition_score_rate! + next!.start!.acquisition_score_rate!,
            correct_answers_num:
              prev!.start!.correct_answers_num! + next!.start!.correct_answers_num!,
            incorrect_answer: prev!.start!.incorrect_answer! + next!.start!.incorrect_answer!,
            inexperienced: prev!.start!.inexperienced! + next!.start!.inexperienced!,
            probs_count: prev!.start!.probs_count! + next!.start!.probs_count!,
            acquisition_score: prev!.start!.acquisition_score! + next!.start!.acquisition_score!,
            acquisition_score_chart:
              prev!.start!.acquisition_score_chart! + next!.start!.acquisition_score_chart!,
            question_time_limit:
              prev!.start!.question_time_limit! + next!.start!.question_time_limit!,
            responses_num: prev!.start!.responses_num! + next!.start!.responses_num!,
            setting_score: prev!.start!.setting_score! + next!.start!.setting_score!,
            total_answer_time: prev!.start!.total_answer_time! + next!.start!.total_answer_time!,
            correct_answers_score:
              prev!.start!.correct_answers_score! + next!.start!.correct_answers_score!,
          },
          end: {
            accuracy_rate: prev!.end!.accuracy_rate! + next!.end!.accuracy_rate!,
            wrong_rate: prev!.end!.wrong_rate! + next!.end!.wrong_rate!,
            inexperienced_rate: prev!.end!.inexperienced_rate! + next!.end!.inexperienced_rate!,
            average_answer_time_per_question:
              prev!.end!.average_answer_time_per_question! +
              next!.end!.average_answer_time_per_question!,
            acquisition_score_rate:
              prev!.end!.acquisition_score_rate! + next!.end!.acquisition_score_rate!,
            correct_answers_num: prev!.end!.correct_answers_num! + next!.end!.correct_answers_num!,
            incorrect_answer: prev!.end!.incorrect_answer! + next!.end!.incorrect_answer!,
            inexperienced: prev!.end!.inexperienced! + next!.end!.inexperienced!,
            probs_count: prev!.end!.probs_count! + next!.end!.probs_count!,
            acquisition_score: prev!.end!.acquisition_score! + next!.end!.acquisition_score!,
            acquisition_score_chart:
              prev!.end!.acquisition_score_chart! + next!.end!.acquisition_score_chart!,
            question_time_limit: prev!.end!.question_time_limit! + next!.end!.question_time_limit!,
            responses_num: prev!.end!.responses_num! + next!.end!.responses_num!,
            setting_score: prev!.end!.setting_score! + next!.end!.setting_score!,
            total_answer_time: prev!.end!.total_answer_time! + next!.end!.total_answer_time!,
            correct_answers_score:
              prev!.end!.correct_answers_score! + next!.end!.correct_answers_score!,
          },
        }),
        {
          start: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
          },
          end: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
          },
        }
      ) as Types.ReportSkillCheckUserTrans.UserAnalysisReportTable;

      state.reportSkillCheckUserTrans = [
        {
          i_id: '',
          user_name: '',
          skill_check_name: '',
          login_id: '',
          skill_check_code: '',
          start: {
            accuracy_rate: start!.accuracy_rate / dataLength,
            wrong_rate: start!.wrong_rate / dataLength,
            inexperienced_rate: start!.inexperienced_rate / dataLength,
            average_answer_time_per_question: start!.average_answer_time_per_question / dataLength,
            acquisition_score_rate: start!.acquisition_score_rate / dataLength,
            acquisition_score: start!.acquisition_score / dataLength,
            acquisition_score_chart: uniqBy(acquisition_score_start, (v) =>
              JSON.stringify([v.skill_check_code, v.login_id])
            ).reduce((result, item) => result + (item!.acquisition_score || 0), 0),
            question_time_limit: start!.question_time_limit / dataLength,
            responses_num: start!.responses_num / dataLength,
            setting_score: start!.setting_score / dataLength,
            total_answer_time: start!.total_answer_time / dataLength,
            correct_answers_num: correct_answer_num_start,
            incorrect_answer: response_num_start - correct_answer_num_start,
            inexperienced: inexperienced_start / dataLength,
            probs_count: start!.probs_count / dataLength,
            correct_answers_score: start!.correct_answers_score / dataLength,
          },
          end: {
            accuracy_rate: end!.accuracy_rate / dataLength,
            wrong_rate: end!.wrong_rate / dataLength,
            inexperienced_rate: end!.inexperienced_rate / dataLength,
            average_answer_time_per_question: end!.average_answer_time_per_question / dataLength,
            acquisition_score_rate: end!.acquisition_score_rate / dataLength,
            acquisition_score: end!.acquisition_score / dataLength,
            acquisition_score_chart: uniqBy(acquisition_score_end, (v) =>
              JSON.stringify([v.skill_check_code, v.login_id])
            ).reduce((result, item) => result + (item!.acquisition_score || 0), 0),
            question_time_limit: end!.question_time_limit / dataLength,
            responses_num: end!.responses_num / dataLength,
            setting_score: end!.setting_score / dataLength,
            total_answer_time: end!.total_answer_time / dataLength,
            correct_answers_num: correct_answer_num_end / dataLength,
            incorrect_answer: (response_num_end - correct_answer_num_end) / dataLength,
            inexperienced: inexperienced_end / dataLength,
            probs_count: end!.probs_count / dataLength,
            correct_answers_score: end!.correct_answers_score / dataLength,
          },
          change: {
            accuracy_rate: (end!.accuracy_rate - start!.accuracy_rate) / dataLength,
            wrong_rate: (end!.wrong_rate - start!.wrong_rate) / dataLength,
            inexperienced_rate: (end!.inexperienced_rate - start!.inexperienced_rate) / dataLength,
            average_answer_time_per_question:
              (end!.average_answer_time_per_question - start!.average_answer_time_per_question) /
              dataLength,
            acquisition_score_rate:
              (end!.acquisition_score_rate - start!.acquisition_score_rate) / dataLength,
            correct_answers_num:
              ((end!.correct_answers_num || 0) - (start!.correct_answers_num! || 0)) / dataLength,
            incorrect_answer: (end!.incorrect_answer - start!.incorrect_answer) / dataLength,
            inexperienced: (end!.inexperienced - start!.inexperienced) / dataLength,
            probs_count: (end!.probs_count - end!.probs_count) / dataLength,
            acquisition_score: (end!.acquisition_score - start!.acquisition_score) / dataLength,
            question_time_limit:
              (end!.question_time_limit - start!.question_time_limit) / dataLength,
            responses_num: (end!.responses_num - start!.responses_num) / dataLength,
            setting_score: (end!.setting_score - start!.setting_score) / dataLength,
            total_answer_time: (end!.total_answer_time - start!.total_answer_time) / dataLength,
            correct_answers_score:
              (end!.correct_answers_score - start!.correct_answers_score) / dataLength,
          },
        },
        ...(reportSkillCheckUserTrans as Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[]),
      ];
      state.totalReportSkillCheckUserTrans = dataLength;
    });

    builder.addCase(getReportSkillCheckUserTrans2.fulfilled, (state, action) => {
      const acquisition_score_start: Array<Types.ReportSkillCheckUserTrans.ResponseType> =
        action.payload.report_results.filter(
          (e) => e.skill_check_code === action.payload.firstSkillcheck
        );
      const acquisition_score_end: Array<Types.ReportSkillCheckUserTrans.ResponseType> =
        action.payload.report_results.filter(
          (e) => e.skill_check_code === action.payload.secondSkillcheck
        );

      const dataGroupByLoginID = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
        [...acquisition_score_start, ...acquisition_score_end],
        'login_id'
      );

      const response_num_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.responses_num || 0), 0);

      const prob_count_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.probs_count || 0), 0);

      const prob_count_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.probs_count || 0), 0);

      const correct_answer_num_start = uniqBy(acquisition_score_start, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.correct_answers_num || 0), 0);

      const inexperienced_start = prob_count_start - response_num_start;

      const response_num_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.responses_num || 0), 0);

      const correct_answer_num_end = uniqBy(acquisition_score_end, (v) =>
        JSON.stringify([v.skill_check_code, v.login_id])
      ).reduce((result, item) => result + (item!.correct_answers_num || 0), 0);

      const inexperienced_end = prob_count_end - response_num_end;

      const reportSkillCheckUserTrans2: Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[] =
        Object.keys(dataGroupByLoginID).flatMap((dataGroupByLoginIDkey) => {
          const dataStartLogin = acquisition_score_start.filter(
            (item) => item.login_id === dataGroupByLoginIDkey
          );

          const dataEndLogin = acquisition_score_end.filter(
            (item) => item.login_id === dataGroupByLoginIDkey
          );

          let start = {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 1,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
            num_skill_check: 0,
            implementation_date: '',
          };
          let end = {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 1,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
            num_skill_check: 0,
            implementation_date: '',
          };

          if (dataStartLogin.length) {
            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
              implementation_date,
            } = dataStartLogin.reduce(
              (prev, next) => ({
                implementation_date: prev.implementation_date,
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time + (next.answer_time || 0),
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
              }),
              {
                implementation_date: dataStartLogin[0].implementation_date,
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: 0,
                correct_answers_score: 0,
              }
            );

            start = {
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / probs_count,
              setting_score,
              acquisition_score,
              acquisition_score_chart: acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              implementation_date: implementation_date || '',
              num_skill_check: 1,
            };
          }

          if (dataEndLogin.length) {
            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
              implementation_date,
            } = dataEndLogin.reduce(
              (prev, next) => ({
                implementation_date: prev.implementation_date,
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time,
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
              }),
              {
                implementation_date: dataEndLogin[0].implementation_date,
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: dayjs(dataEndLogin[0]?.implementation_end_date).diff(
                  dayjs(dataEndLogin[0]?.implementation_date),
                  'seconds'
                ),
                correct_answers_score: 0,
              }
            );

            end = {
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / probs_count,
              setting_score,
              acquisition_score,
              acquisition_score_chart: acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              implementation_date: implementation_date || '',
              num_skill_check: 1,
            };
          }

          return {
            i_id: dataGroupByLoginID[dataGroupByLoginIDkey][0].i_id,
            user_name: dataGroupByLoginID[dataGroupByLoginIDkey][0].user_name,
            login_id: dataGroupByLoginID[dataGroupByLoginIDkey][0].login_id,
            skill_check_name: dataGroupByLoginID[dataGroupByLoginIDkey][0]?.skill_check_name,
            skill_check_code: dataGroupByLoginID[dataGroupByLoginIDkey][0]?.skill_check_code,
            start,
            end,
            change: {
              accuracy_rate: end.accuracy_rate - start.accuracy_rate,
              wrong_rate: end.wrong_rate - start.wrong_rate,
              inexperienced_rate: end.inexperienced_rate - start.inexperienced_rate,
              average_answer_time_per_question:
                end.average_answer_time_per_question - start.average_answer_time_per_question,
              acquisition_score_rate: end.acquisition_score_rate - start.acquisition_score_rate,
              correct_answers_num: end.correct_answers_num - start.correct_answers_num,
              incorrect_answer: end.incorrect_answer - start.incorrect_answer,
              inexperienced: end.inexperienced - start.inexperienced,
              probs_count: end.probs_count - start.probs_count,
              acquisition_score: end.acquisition_score - start.acquisition_score,
              question_time_limit: end.question_time_limit - start.question_time_limit,
              responses_num: end.responses_num - start.responses_num,
              setting_score: end.setting_score - start.setting_score,
              total_answer_time: end.total_answer_time - start.total_answer_time,
              correct_answers_score: end.correct_answers_score - start.correct_answers_score,
            },
            implementation_date: start.implementation_date,
          };
        });

      const dataLength = reportSkillCheckUserTrans2.length;
      const { start, end } = reportSkillCheckUserTrans2.reduce(
        (prev, next) => ({
          start: {
            accuracy_rate: prev!.start!.accuracy_rate! + next!.start!.accuracy_rate!,
            wrong_rate: prev!.start!.wrong_rate! + next!.start!.wrong_rate!,
            inexperienced_rate: prev!.start!.inexperienced_rate! + next!.start!.inexperienced_rate!,
            average_answer_time_per_question:
              prev!.start!.average_answer_time_per_question! +
              next!.start!.average_answer_time_per_question!,
            acquisition_score_rate:
              prev!.start!.acquisition_score_rate! + next!.start!.acquisition_score_rate!,
            correct_answers_num:
              prev!.start!.correct_answers_num! + next!.start!.correct_answers_num!,
            incorrect_answer: prev!.start!.incorrect_answer! + next!.start!.incorrect_answer!,
            inexperienced: prev!.start!.inexperienced! + next!.start!.inexperienced!,
            probs_count: prev!.start!.probs_count! + next!.start!.probs_count!,
            acquisition_score: prev!.start!.acquisition_score! + next!.start!.acquisition_score!,
            acquisition_score_chart:
              prev!.start!.acquisition_score_chart! + next!.start!.acquisition_score_chart!,
            question_time_limit:
              prev!.start!.question_time_limit! + next!.start!.question_time_limit!,
            responses_num: prev!.start!.responses_num! + next!.start!.responses_num!,
            setting_score: prev!.start!.setting_score! + next!.start!.setting_score!,
            total_answer_time: prev!.start!.total_answer_time! + next!.start!.total_answer_time!,
            correct_answers_score:
              prev!.start!.correct_answers_score! + next!.start!.correct_answers_score!,
          },
          end: {
            accuracy_rate: prev!.end!.accuracy_rate! + next!.end!.accuracy_rate!,
            wrong_rate: prev!.end!.wrong_rate! + next!.end!.wrong_rate!,
            inexperienced_rate: prev!.end!.inexperienced_rate! + next!.end!.inexperienced_rate!,
            average_answer_time_per_question:
              prev!.end!.average_answer_time_per_question! +
              next!.end!.average_answer_time_per_question!,
            acquisition_score_rate:
              prev!.end!.acquisition_score_rate! + next!.end!.acquisition_score_rate!,
            correct_answers_num: prev!.end!.correct_answers_num! + next!.end!.correct_answers_num!,
            incorrect_answer: prev!.end!.incorrect_answer! + next!.end!.incorrect_answer!,
            inexperienced: prev!.end!.inexperienced! + next!.end!.inexperienced!,
            probs_count: prev!.end!.probs_count! + next!.end!.probs_count!,
            acquisition_score: prev!.end!.acquisition_score! + next!.end!.acquisition_score!,
            acquisition_score_chart:
              prev!.end!.acquisition_score_chart! + next!.end!.acquisition_score_chart!,
            question_time_limit: prev!.end!.question_time_limit! + next!.end!.question_time_limit!,
            responses_num: prev!.end!.responses_num! + next!.end!.responses_num!,
            setting_score: prev!.end!.setting_score! + next!.end!.setting_score!,
            total_answer_time: prev!.end!.total_answer_time! + next!.end!.total_answer_time!,
            correct_answers_score:
              prev!.end!.correct_answers_score! + next!.end!.correct_answers_score!,
          },
        }),
        {
          start: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
          },
          end: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            acquisition_score_chart: 0,
            question_time_limit: 0,
            responses_num: 0,
            setting_score: 0,
            total_answer_time: 0,
            correct_answers_score: 0,
          },
        }
      ) as Types.ReportSkillCheckUserTrans.UserAnalysisReportTable;

      state.reportSkillCheckUserTrans2 = [
        {
          i_id: '',
          user_name: '',
          skill_check_name: '',
          login_id: '',
          skill_check_code: '',
          start: {
            accuracy_rate: start!.accuracy_rate / dataLength,
            wrong_rate: start!.wrong_rate / dataLength,
            inexperienced_rate: start!.inexperienced_rate / dataLength,
            average_answer_time_per_question: start!.average_answer_time_per_question / dataLength,
            acquisition_score_rate: start!.acquisition_score_rate / dataLength,
            acquisition_score: start!.acquisition_score / dataLength,
            acquisition_score_chart: uniqBy(acquisition_score_start, (v) =>
              JSON.stringify([v.skill_check_code, v.login_id])
            ).reduce((result, item) => result + (item!.acquisition_score || 0), 0),
            question_time_limit: start!.question_time_limit / dataLength,
            responses_num: start!.responses_num / dataLength,
            setting_score: start!.setting_score / dataLength,
            total_answer_time: start!.total_answer_time / dataLength,
            correct_answers_num: correct_answer_num_start,
            incorrect_answer: response_num_start - correct_answer_num_start,
            inexperienced: inexperienced_start / dataLength,
            probs_count: start!.probs_count / dataLength,
            correct_answers_score: start!.correct_answers_score / dataLength,
          },
          end: {
            accuracy_rate: end!.accuracy_rate / dataLength,
            wrong_rate: end!.wrong_rate / dataLength,
            inexperienced_rate: end!.inexperienced_rate / dataLength,
            average_answer_time_per_question: end!.average_answer_time_per_question / dataLength,
            acquisition_score_rate: end!.acquisition_score_rate / dataLength,
            acquisition_score: end!.acquisition_score / dataLength,
            acquisition_score_chart: uniqBy(acquisition_score_end, (v) =>
              JSON.stringify([v.skill_check_code, v.login_id])
            ).reduce((result, item) => result + (item!.acquisition_score || 0), 0),
            question_time_limit: end!.question_time_limit / dataLength,
            responses_num: end!.responses_num / dataLength,
            setting_score: end!.setting_score / dataLength,
            total_answer_time: end!.total_answer_time / dataLength,
            correct_answers_num: correct_answer_num_end / dataLength,
            incorrect_answer: (response_num_end - correct_answer_num_end) / dataLength,
            inexperienced: inexperienced_end / dataLength,
            probs_count: end!.probs_count / dataLength,
            correct_answers_score: end!.correct_answers_score / dataLength,
          },
          change: {
            accuracy_rate: (end!.accuracy_rate - start!.accuracy_rate) / dataLength,
            wrong_rate: (end!.wrong_rate - start!.wrong_rate) / dataLength,
            inexperienced_rate: (end!.inexperienced_rate - start!.inexperienced_rate) / dataLength,
            average_answer_time_per_question:
              (end!.average_answer_time_per_question - start!.average_answer_time_per_question) /
              dataLength,
            acquisition_score_rate:
              (end!.acquisition_score_rate - start!.acquisition_score_rate) / dataLength,
            correct_answers_num:
              ((end!.correct_answers_num || 0) - (start!.correct_answers_num! || 0)) / dataLength,
            incorrect_answer: (end!.incorrect_answer - start!.incorrect_answer) / dataLength,
            inexperienced: (end!.inexperienced - start!.inexperienced) / dataLength,
            probs_count: (end!.probs_count - end!.probs_count) / dataLength,
            acquisition_score: (end!.acquisition_score - start!.acquisition_score) / dataLength,
            question_time_limit:
              (end!.question_time_limit - start!.question_time_limit) / dataLength,
            responses_num: (end!.responses_num - start!.responses_num) / dataLength,
            setting_score: (end!.setting_score - start!.setting_score) / dataLength,
            total_answer_time: (end!.total_answer_time - start!.total_answer_time) / dataLength,
            correct_answers_score:
              (end!.correct_answers_score - start!.correct_answers_score) / dataLength,
          },
        },
        ...(reportSkillCheckUserTrans2 as Types.ReportSkillCheckUserTrans.UserAnalysisReportTable[]),
      ];
      state.totalReportSkillCheckUserTrans2 = dataLength;
    });

    builder.addCase(getReportSkillCheckUserTransComparison.fulfilled, (state, action) => {
      const dataGroupByImplementationDate = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
        action.payload.report_results,
        (e) => dayjs(e.base_date_for_report).tz('Asia/Tokyo').format('YYYY/MM/DD')
      );

      const processedData = mapValues(dataGroupByImplementationDate, (group) => {
        const skillcheckOlder = minBy(group, 'skill_check_created_at');

        const groupOlder = group.filter(
          (e) => e.skill_check_code === skillcheckOlder?.skill_check_code
        );

        return groupOlder;
      });

      const userAnalysisComparisonData = Object.keys(processedData).flatMap(
        (dataGroupByImplementationDateKey) => {
          const dataGroupByLoginID = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
            processedData[dataGroupByImplementationDateKey],
            'login_id'
          );
          return Object.keys(dataGroupByLoginID).flatMap((dataGroupByLoginIDKey) => {
            const items = dataGroupByLoginID[dataGroupByLoginIDKey];

            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
            } = items.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time + (next.answer_time || 0),
              }),
              {
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: 0,
                correct_answers_score: 0,
              }
            );
            return {
              i_id: items[0].i_id,
              user_name: items[0].user_name,
              login_id: items[0].login_id,
              skill_check_name: items[0].skill_check_name,
              skill_check_code: items[0].login_id,
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / items.length,
              setting_score,
              acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              implementation_date: items[0].implementation_date,
            };
          });
        }
      );

      state.userAnalysisComparison.data = userAnalysisComparisonData;
      state.userAnalysisComparison.columnName = Object.keys(processedData).map((date) =>
        dayjs(date).format('YYYY/MM/DD')
      );

      state.groupAnalysisComparison.data = Object.keys(processedData).flatMap(
        (dataGroupByImplementationDateKey) => {
          const dataGroupByGroupCode = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
            processedData[dataGroupByImplementationDateKey],
            'grouping_code'
          );

          return Object.keys(dataGroupByGroupCode).flatMap((dataGroupByGroupCodeKey) => {
            const items = dataGroupByGroupCode[dataGroupByGroupCodeKey];

            const {
              correct_answers_num,
              acquisition_score,
              setting_score,
              probs_count,
              question_time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
            } = items.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
                correct_answers_score:
                  prev.correct_answers_score +
                  (next.correct_answers_num || 0) * (next.setting_score || 0),
                acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
                setting_score: prev.setting_score + (next.setting_score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time + (next.answer_time || 0),
              }),
              {
                correct_answers_num: 0,
                acquisition_score: 0,
                setting_score: 0,
                probs_count: 0,
                question_time_limit: 0,
                responses_num: 0,
                answer_time: 0,
                correct_answers_score: 0,
              }
            );
            return {
              i_id: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].i_id,
              user_name: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].user_name,
              login_id: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].login_id,
              skill_check_name: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].skill_check_name,
              skill_check_code: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].login_id,
              grouping_code: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].grouping_code,
              grouping_name: dataGroupByGroupCode[dataGroupByGroupCodeKey][0].grouping_name || '',
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / probs_count,
              wrong_rate: (responses_num - correct_answers_num) / probs_count,
              inexperienced_rate: (probs_count - responses_num) / probs_count,
              question_time_limit,
              total_answer_time: answer_time,
              average_answer_time_per_question: answer_time / items.length,
              setting_score,
              acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / setting_score,
              correct_answers_score,
              implementation_date:
                dataGroupByGroupCode[dataGroupByGroupCodeKey][0].implementation_date,
            };
          });
        }
      );
      state.groupAnalysisComparison.columnName = Object.keys(processedData).map((date) =>
        dayjs(date).format('YYYY/MM/DD')
      );
    });
  },
});

export const { resetInitialState } = skillCheckUserAnalysisReportSlice.actions;

export default skillCheckUserAnalysisReportSlice.reducer;
