import React, { useEffect, useRef, useState } from 'react';
import { Input as AntInput, InputRef, Select } from 'antd';
import { Form, Radio, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';

import { usageStatusSelector } from 'pages/UsageStatus/selectors';
import { CREDIT_CARD_MONTH, IMAGE_CREDIT_CARD } from 'constant';
import { setCreditCardInfo } from 'pages/UsageStatus/slice';
import { Input, InputPassword, Modal } from 'components';
import { fourStepSchema } from 'libs/validations';
import PaymentMasterConfirm from './Confirm';
import CreditCardStyled from './styles';
import { useAppDispatch } from 'hooks';
import { SecurityCode } from 'assets';
import * as Types from 'types';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const CreditCard: React.FC<Props> = ({ visible, setVisible }) => {
  const [cardNumber, setCardNumber] = useState<{
    input1: string;
    input2: string;
    input3: string;
    input4: string;
  }>({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });
  const [expiredDate, setExpiredDate] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });
  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { creditCard } = useSelector(usageStatusSelector);

  const cardNumber1 = useRef<InputRef>(null);
  const cardNumber2 = useRef<InputRef>(null);
  const cardNumber3 = useRef<InputRef>(null);
  const cardNumber4 = useRef<InputRef>(null);

  const formik = useFormik<Types.RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fourStepSchema,
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      dispatch(setCreditCardInfo(values));
      setSubmitting(false);
      toggleScreen();
    },
  });

  const handleInputCardNumber = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value } = e.target;

    switch (index) {
      case 1:
        if (parseInt(value[0]) !== 0) {
          setCardNumber((prevState) => ({ ...prevState, input1: value }));
          formik.setFieldValue(
            'cardNumber',
            `${value}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`
          );
          if (value.length === maxLength) {
            cardNumber2.current && cardNumber2.current.focus();
          }
        }
        break;
      case 2:
        setCardNumber((prevState) => ({ ...prevState, input2: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${value}${cardNumber.input3}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber3.current && cardNumber3.current.focus();
        }
        break;
      case 3:
        setCardNumber((prevState) => ({ ...prevState, input3: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${value}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber4.current && cardNumber4.current.focus();
        }
        break;
      case 4:
        setCardNumber((prevState) => ({ ...prevState, input4: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${value}`
        );
        break;
      default:
        break;
    }
  };

  const handleSelectExpiredDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setExpiredDate((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('expirationDate', `${e}${expiredDate.year}`);
    } else {
      setExpiredDate((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('expirationDate', `${expiredDate.month}${e}`);
    }
  };

  const toggleScreen = () => {
    setIsSubmit((prevState) => !prevState);
  };

  useEffect(() => {
    if (creditCard) {
      setCardNumber({
        input1: creditCard.cardNumber.slice(0, 4),
        input2: creditCard.cardNumber.slice(4, 8),
        input3: creditCard.cardNumber.slice(8, 12),
        input4: creditCard.cardNumber.slice(12, 18),
      });
      setExpiredDate({
        month: creditCard.expirationDate.slice(4, 6),
        year: creditCard.expirationDate.slice(0, 4),
      });
    }
  }, [creditCard]);

  return (
    <Modal
      title="クレジットカード再登録"
      width={860}
      visible={visible}
      bodyStyle={{
        backgroundColor: '#F9F8F8;',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <CreditCardStyled>
        {isSubmit ? (
          <PaymentMasterConfirm onBack={toggleScreen} />
        ) : (
          <>
            <FormikProvider value={formik}>
              <Form
                layout="vertical"
                labelCol={{
                  flex: '22%',
                }}
                colon={false}
              >
                <div className="form">
                  <Form.Item
                    name="companyName"
                    label={
                      <span className="text-label">
                        カード会社
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Radio.Group name="companyName" className="radio_group">
                      {IMAGE_CREDIT_CARD.map((item, index) => (
                        <div key={index} className="wrap-radio">
                          <Radio
                            name="companyName"
                            value={item.name}
                            className={`radio_item ${
                              index === 0
                                ? 'border-radius-left'
                                : index >= 1 && index <= 3
                                ? 'border-right'
                                : 'border-radius-right'
                            }`}
                          >
                            <img
                              className="image-card-company"
                              aria-hidden
                              src={item.image}
                              alt={item.name}
                            />
                          </Radio>
                          <p className="label-text">{item.name}</p>
                        </div>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    name="cardNumber"
                    label={
                      <span className="text-label">
                        カード番号
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <AntInput
                      ref={cardNumber1}
                      value={cardNumber.input1}
                      className="input-card-number input_small"
                      type="number"
                      maxLength={4}
                      onChange={handleInputCardNumber(1)}
                      onKeyPress={(e) => {
                        if (isNaN(parseInt(e.key)) || cardNumber.input1.toString().length > 3) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span> - </span>
                    <AntInput
                      ref={cardNumber2}
                      value={cardNumber.input2}
                      className="input-card-number input_small"
                      type="number"
                      maxLength={4}
                      onChange={handleInputCardNumber(2)}
                      onKeyPress={(e) => {
                        if (isNaN(parseInt(e.key)) || cardNumber.input2.toString().length > 3) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span> - </span>
                    <AntInput
                      ref={cardNumber3}
                      value={cardNumber.input3}
                      className="input-card-number input_small"
                      type="number"
                      maxLength={4}
                      onChange={handleInputCardNumber(3)}
                      onKeyPress={(e) => {
                        if (isNaN(parseInt(e.key)) || cardNumber.input3.toString().length > 3) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span> - </span>
                    <AntInput
                      ref={cardNumber4}
                      value={cardNumber.input4}
                      className="input-card-number input_small"
                      type="number"
                      maxLength={4}
                      onChange={handleInputCardNumber(4)}
                      onKeyPress={(e) => {
                        if (isNaN(parseInt(e.key)) || cardNumber.input4.toString().length > 5) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="customerName"
                    label={
                      <span className="text-label">
                        カード名義人
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="customerName"
                      className="input"
                      type="text"
                      placeholder="全角：最大100文字"
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="expirationDate"
                    label={
                      <span className="text-label">
                        有効期限
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      value={expiredDate.month}
                      className="pull_down input_small"
                      onSelect={handleSelectExpiredDate('month')}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 / </span>
                    <Select
                      value={expiredDate.year}
                      className="pull_down input_small"
                      onSelect={handleSelectExpiredDate('year')}
                    >
                      {Array.from(
                        {
                          length: 10,
                        },
                        (_, i) => (i + new Date().getFullYear()).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                  </Form.Item>
                  <Form.Item
                    name="securityNumber"
                    label={
                      <span className="text-label">
                        セキュリティコード
                        <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <div className="flex">
                      <InputPassword
                        name="securityNumber"
                        className="input_small_two"
                        type="number"
                        onKeyPress={(e) => {
                          if (
                            isNaN(parseInt(e.key)) ||
                            formik.values.securityNumber.toString().length > 3
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <p className="content-text">
                        カード裏側のご署名欄に印字された数字の末尾3桁の数字を入力してください。
                        <br />
                        AMEXの場合、はカード表側の4桁を入力してください
                      </p>
                    </div>
                  </Form.Item>
                  <div className="wrap-security">
                    <img src={SecurityCode} alt="security-code" />
                  </div>
                </div>
                <div className="wrap-button">
                  <SubmitButton disabled={!formik.isValid && !formik.dirty} className="btn-submit">
                    確認画面へ
                  </SubmitButton>
                  <button className="btn-cancel" onClick={() => setVisible(false)}>
                    キャンセル
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </>
        )}
      </CreditCardStyled>
    </Modal>
  );
};

export default CreditCard;
