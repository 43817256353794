import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, generatePath } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { Avatar, Badge, Button, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';
import fileSaver from 'file-saver';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import {
  UsergroupDeleteOutlined,
  CloudDownloadOutlined,
  CheckCircleOutlined,
  CaretRightOutlined,
  CaretLeftOutlined,
  PaperClipOutlined,
  MessageOutlined,
  DeleteOutlined,
  CheckOutlined,
  EditOutlined,
  LikeOutlined,
  StarFilled,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import CompletedDelete from 'components/Modal/CompletedDelete';
import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import CreateQuestionAnswer from './createQuestionAnswer';
import { authSelector } from 'containers/Auth/selectors';
import { createMarkup } from 'libs/utils/createMarkup';
import { useAppDispatch, usePermission } from 'hooks';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { knowledgeTopSelector } from '../selectors';
import { extractFileName } from 'libs/utils/format';
import useImagePreview from 'hooks/useImagePreview';
import FileExportPDF from './FileExportPDF';
import { routes } from 'navigations/routes';
import { adminAvatar } from 'assets';
import { resetData } from '../slice';
import { Header } from 'components';
import * as Types from 'types';
import Styled from './styles';
import {
  getKnowledgeLikeHistory,
  deleteReQuestionAnswer,
  updateReQuestionAnswer,
  deleteReQuestion,
  updateReQuestion,
  createReQuestion,
  createReAnswer,
  deleteKnowledge,
  createKnowLedgeLikeHistory,
  updateKnowledge,
  deleteKnowLedgeLikeHistory,
  getKnowledgeFavoriteHistory,
  createFavoriteKnowledge,
  deleteFavoriteKnowledge,
  getKnowledgeRecommendDetailOriginal,
  getKnowledgeRecommendDetailAdditional,
  deleteFileAttachByConditions,
  createFileAttach,
} from '../thunk';
import { every, get } from 'lodash';

const KnowledgeTopRecommend: React.FC = () => {
  const { handlePreviewImage, ImagePreview } = useImagePreview();

  const [actionType, setActionType] = useState<'question' | 'answer' | 'knowledge'>('question');
  const [visibleCreateQuestion, setVisibleCreateQuestion] = useState<boolean>(false);
  const [visibleEditQuestion, setVisibleEditQuestion] = useState<boolean>(false);
  const [visibleEditAnswer, setVisibleEditAnswer] = useState<boolean>(false);
  const [visibleDelete, setVisibleDelete] = useState<boolean>(false);
  const [file, setFile] = useState<UploadFile<File> | undefined>();
  const [completed, setCompleted] = useState<boolean>(false);
  const [idSelected, setIDSelected] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [text, setText] = useState<string>('');

  const ref = useRef(null);

  const dataRecord = useLocation().state as Types.KnowledgeList.ResponseType;
  const { permissionNumber } = usePermission();

  const {
    dataKnowledgeLikeHistory,
    dataKnowledgeFavoriteHistory,
    dataKnowledgeRecommendDetailOriginal,
    dataKnowledgeRecommendDetailAdditional,
  } = useSelector(knowledgeTopSelector);

  const { userInfo } = useSelector(authSelector);

  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const fetchDataKnowledgeRecommendDetailOriginal = useCallback(async () => {
    if (userInfo && dataRecord?.knowledge_id) {
      dispatch(startLoading());
      await dispatch(
        getKnowledgeRecommendDetailOriginal({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, dataRecord?.knowledge_id]);

  const fetchDataKnowledgeRecommendDetailAdditional = useCallback(async () => {
    if (userInfo && dataRecord?.knowledge_id) {
      dispatch(startLoading());
      await dispatch(
        getKnowledgeRecommendDetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, dataRecord?.knowledge_id]);

  const fetchKnowledgeLikeHistory = useCallback(() => {
    if (userInfo && dataRecord?.knowledge_id) {
      dispatch(
        getKnowledgeLikeHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, dataRecord?.knowledge_id]);

  const fetchKnowledgeFavoriteHistory = useCallback(() => {
    if (userInfo && dataRecord?.knowledge_id) {
      dispatch(
        getKnowledgeFavoriteHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
            {
              id: 'favorite_knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, dataRecord?.knowledge_id]);

  const uploadFile = async (fileLocation: string) => {
    let fileID = '';
    if (file) {
      const uploadToMinIo = await uploadFileToMinIO(file);
      await dispatch(
        createFileAttach({
          item: {
            fileID: uploadToMinIo,
            filename: file?.name,
            file_location: fileLocation,
            file_extension: file?.type,
            file_size: `${file?.size}`,
            company_id: userInfo?.company_id,
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
        })
      );
      fileID = uploadToMinIo;
    }
    return fileID;
  };

  const handleCreateQuestion = async () => {
    if (!userInfo || !dataRecord.knowledge_id) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());

    const action = await dispatch(
      createReQuestion({
        item: {
          company_id: userInfo.company_id,
          knowledge_id: dataRecord?.knowledge_id,
          questioner: userInfo.login_id,
          contents_of_question: text,
          answer_status: 0,
          ...(file ? { fileID: fileID } : null),
          createdat: new Date(),
        },
      })
    );
    if (createReQuestion.fulfilled.match(action)) {
      const resultRecommendDetailAdditional = await dispatch(
        getKnowledgeRecommendDetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord?.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      if (getKnowledgeRecommendDetailAdditional.fulfilled.match(resultRecommendDetailAdditional)) {
        const dataAdditional = resultRecommendDetailAdditional.payload.report_results;
        let updateStatus;
        if (
          every(dataAdditional, (item) => get(item, 'question_answer_content')) ||
          (!every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            dataAdditional.length)
        ) {
          updateStatus = 1;
        } else {
          updateStatus = 0;
        }

        await dispatch(
          updateKnowledge({
            id: dataKnowledgeRecommendDetailOriginal[0]!.item_ref!.knowledge_id.i_id,
            data: {
              item: {
                recommendation_status: updateStatus,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        fetchDataKnowledgeRecommendDetailOriginal();
      }
    }
    dispatch(stopLoading());
  };

  const handleCreateAnswer = async (question_id: string) => {
    if (!userInfo || !dataRecord?.knowledge_id) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());

    const action = await dispatch(
      createReAnswer({
        item: {
          company_id: userInfo.company_id,
          knowledge_id: dataRecord?.knowledge_id,
          question_id: question_id,
          question_respondent: userInfo.login_id,
          question_answer_content: text,
          ...(file ? { fileID: fileID } : null),
          createdat: new Date(),
        },
      })
    );
    if (createReAnswer.fulfilled.match(action)) {
      const resultRecommendDetailAdditional = await dispatch(
        getKnowledgeRecommendDetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord?.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      if (getKnowledgeRecommendDetailAdditional.fulfilled.match(resultRecommendDetailAdditional)) {
        const dataAdditional = resultRecommendDetailAdditional.payload.report_results;
        let updateStatus;
        if (
          (every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            dataAdditional.length) ||
          (!every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            !dataAdditional.length)
        ) {
          updateStatus = 1;
        } else {
          updateStatus = 0;
        }

        await dispatch(
          updateKnowledge({
            id: dataKnowledgeRecommendDetailOriginal[0]!.item_ref!.knowledge_id.i_id,
            data: {
              item: {
                recommendation_status: updateStatus,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        fetchDataKnowledgeRecommendDetailOriginal();
      }
    }
    dispatch(stopLoading());
  };
  const handleUpdateQuestionAnswer = async (id: string) => {
    if (!userInfo) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());

    const action = await dispatch(
      updateReQuestionAnswer({
        id: id,
        data: {
          item: {
            question_answer_content: text,
            updatedat: new Date(),
            ...(file ? { fileID: fileID } : null),
          },
          is_force_update: true,
        },
      })
    );
    if (updateReQuestionAnswer.rejected.match(action)) return;
    fetchDataKnowledgeRecommendDetailAdditional();
    dispatch(stopLoading());
  };

  const handleUpdateQuestion = async (id: string) => {
    if (!userInfo) return;
    const fileID = await uploadFile('1');
    dispatch(startLoading());

    const action = await dispatch(
      updateReQuestion({
        id: id,
        data: {
          item: {
            contents_of_question: text,
            updatedat: new Date(),
            ...(file ? { fileID: fileID } : null),
          },
          is_force_update: true,
        },
      })
    );
    if (updateReQuestion.rejected.match(action)) return;

    fetchDataKnowledgeRecommendDetailAdditional();
    dispatch(stopLoading());
  };
  const handleDeleteKnowledge = async (id: string) => {
    if (!id) return;
    dispatch(startLoading());
    const actionResult = await Promise.all([
      dispatch(
        deleteKnowledge({
          id: id,
        })
      ),
      dataKnowledgeRecommendDetailOriginal[0].files?.map((item) => {
        deletedFileInMinIO(item.uid);
        dispatch(
          deleteFileAttachByConditions({
            conditions: [
              {
                id: 'fileID',
                search_value: [item.uid],
              },
            ],
            use_display_id: true,
          })
        );
      }),
    ]);
    if (deleteKnowledge.fulfilled.match(actionResult[0])) {
      navigate(routes.KnowledgeTop.path);
    }
    dispatch(stopLoading());
  };

  const handleExportPDF = () => {
    if (!ref.current) return;
    try {
      html2canvas(ref.current as HTMLElement, {
        useCORS: true,
        scale: 2.5,
        allowTaint: true,
        foreignObjectRendering: false,
      }).then(async (canvas) => {
        const contentDataURL = await canvas.toDataURL('image/png');

        const pdf = await new jsPDF('l', 'px', 'a4');
        let position = 0;

        const imgWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        heightLeft -= pageHeight;
        pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight + 25);
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        pdf.save('ナレッジ [リコメンド].pdf');
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteQuestitonAnswer = async (id: string) => {
    if (!id) return;
    dispatch(startLoading());
    const actionResult = await dispatch(
      deleteReQuestionAnswer({
        id: id,
      })
    );
    if (dataKnowledgeRecommendDetailAdditional[0].question_answer_file) {
      deletedFileInMinIO(dataKnowledgeRecommendDetailAdditional[0].question_answer_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [dataKnowledgeRecommendDetailAdditional[0].question_answer_file.uid],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteReQuestionAnswer.fulfilled.match(actionResult)) {
      setCompleted(true);
      const resultRecommendDetailAdditional = await dispatch(
        getKnowledgeRecommendDetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord?.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      if (getKnowledgeRecommendDetailAdditional.fulfilled.match(resultRecommendDetailAdditional)) {
        const dataAdditional = resultRecommendDetailAdditional.payload.report_results;
        let updateStatus;
        if (
          (every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            dataAdditional.length) ||
          (!every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            !dataAdditional.length)
        ) {
          updateStatus = 1;
        } else {
          updateStatus = 0;
        }

        await dispatch(
          updateKnowledge({
            id: dataKnowledgeRecommendDetailOriginal[0]!.item_ref!.knowledge_id.i_id,
            data: {
              item: {
                recommendation_status: updateStatus,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        fetchDataKnowledgeRecommendDetailOriginal();
      }
    }
    dispatch(stopLoading());
  };

  const handleDeleteQuestiton = async (id: string) => {
    if (!id) return;
    dispatch(startLoading());
    const actionResult = await dispatch(
      deleteReQuestion({
        id: id,
      })
    );
    if (dataKnowledgeRecommendDetailAdditional[0].question_file) {
      deletedFileInMinIO(dataKnowledgeRecommendDetailAdditional[0].question_file.uid);
      dispatch(
        deleteFileAttachByConditions({
          conditions: [
            {
              id: 'fileID',
              search_value: [dataKnowledgeRecommendDetailAdditional[0].question_file.uid],
            },
          ],
          use_display_id: true,
        })
      );
    }
    if (deleteReQuestion.fulfilled.match(actionResult)) {
      setCompleted(true);
      const resultRecommendDetailAdditional = await dispatch(
        getKnowledgeRecommendDetailAdditional({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord?.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      if (getKnowledgeRecommendDetailAdditional.fulfilled.match(resultRecommendDetailAdditional)) {
        const dataAdditional = resultRecommendDetailAdditional.payload.report_results;
        let updateStatus;
        if (
          (every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            dataAdditional.length) ||
          (!every(dataAdditional, (item) => get(item, 'question_answer_content')) &&
            !dataAdditional.length)
        ) {
          updateStatus = 1;
        } else {
          updateStatus = 0;
        }

        await dispatch(
          updateKnowledge({
            id: dataKnowledgeRecommendDetailOriginal[0]!.item_ref!.knowledge_id.i_id,
            data: {
              item: {
                recommendation_status: updateStatus,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        );
        fetchDataKnowledgeRecommendDetailOriginal();
      }
    }
    dispatch(stopLoading());
  };

  // useEffect(() => {
  //   if (userInfo && dataRecord.knowledge_id) {
  //     (async () => {
  //       dispatch(startLoading());
  //       await dispatch(
  //         getKnowledgeLikeHistory({
  //           conditions: [
  //             {
  //               id: 'login_id',
  //               search_value: [userInfo.login_id],
  //             },
  //             {
  //               id: 'knowledge_id',
  //               search_value: [dataRecord.knowledge_id],
  //             },
  //           ],
  //           page: 1,
  //           per_page: 0,
  //         })
  //       );
  //       dispatch(stopLoading());
  //     })();
  //   }
  // }, [dataRecord.knowledge_id, dispatch, userInfo]);

  useEffect(() => {
    (async () => {
      await fetchDataKnowledgeRecommendDetailOriginal();
    })();
  }, [fetchDataKnowledgeRecommendDetailOriginal]);

  useEffect(() => {
    (async () => {
      await fetchDataKnowledgeRecommendDetailAdditional();
    })();
  }, [fetchDataKnowledgeRecommendDetailAdditional]);

  useEffect(fetchKnowledgeLikeHistory, [fetchKnowledgeLikeHistory]);

  useEffect(fetchKnowledgeFavoriteHistory, [fetchKnowledgeFavoriteHistory]);

  return (
    <Styled isCheckUpload={isDisabledUpload}>
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FileExportPDF />
      </div>
      <Header title="ナレッジ [リコメンド]" />
      <div className="container">
        <div className="wrap-label">
          <div className="wrap-title">
            {!dataKnowledgeRecommendDetailOriginal[0]?.recommendation_status ? (
              <div className="label check">
                <CheckCircleOutlined className="icon" />
                回答済み
              </div>
            ) : (
              <div className="label">
                <MessageOutlined className="icon" />
                質問あり
              </div>
            )}

            <div
              className="goback"
              onClick={() => {
                navigate(
                  dataRecord.isScreen === 'favorite'
                    ? routes.KnowledgeFavorite.path
                    : dataRecord.isScreen === 'to_login_id'
                    ? routes.KnowledgeToLoginId.path
                    : dataRecord.isScreen === 'created_by'
                    ? routes.KnowledgeCreatedBy.path
                    : routes.KnowledgeTop.path
                );
                dispatch(resetData());
              }}
            >
              <CaretLeftOutlined className="icon-back" />
              一覧に戻る
            </div>
          </div>
          <div className="wrap-button">
            <Tooltip
              placement="top"
              title={dataKnowledgeLikeHistory.length ? 'いいね！しました' : ''}
            >
              <Button
                className={dataKnowledgeLikeHistory.length ? 'hasLike button' : ''}
                onClick={async () => {
                  dispatch(startLoading());
                  if (!dataKnowledgeLikeHistory.length) {
                    const resultAction = await Promise.all([
                      dispatch(
                        createKnowLedgeLikeHistory({
                          item: {
                            knowledge_id: dataKnowledgeRecommendDetailOriginal[0].knowledge_id,
                            login_id: userInfo?.login_id,
                            created_month: dayjs().format('YYYYMM'),
                          },
                        })
                      ),
                      dispatch(
                        updateKnowledge({
                          id: dataKnowledgeRecommendDetailOriginal[0].item_ref!.knowledge_id.i_id,
                          data: {
                            item: {
                              likes: dataKnowledgeRecommendDetailOriginal[0].number_of_likes + 1,
                              updatedat: new Date(),
                            },
                            is_force_update: true,
                          },
                        })
                      ),
                    ]);
                    if (createKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeRecommendDetailOriginal();
                      fetchKnowledgeLikeHistory();
                    }
                  } else {
                    const resultAction = await Promise.all([
                      dispatch(
                        deleteKnowLedgeLikeHistory({
                          id: dataKnowledgeLikeHistory[0].item_ref!.knowledge_id.i_id,
                        })
                      ),
                      dispatch(
                        updateKnowledge({
                          id: dataKnowledgeRecommendDetailOriginal[0].item_ref!.knowledge_id.i_id,
                          data: {
                            item: {
                              likes:
                                Number(dataKnowledgeRecommendDetailOriginal[0].number_of_likes) - 1,
                              updatedat: new Date(),
                            },
                            is_force_update: true,
                          },
                        })
                      ),
                    ]);
                    if (deleteKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeRecommendDetailOriginal();
                      fetchKnowledgeLikeHistory();
                    }
                  }
                  dispatch(stopLoading());
                }}
              >
                <LikeOutlined className="icon-like" />
                {dataKnowledgeRecommendDetailOriginal[0]?.number_of_likes}
              </Button>
            </Tooltip>
            <Tooltip
              placement="top"
              title={dataKnowledgeFavoriteHistory.length ? 'お気に入りしました' : ''}
            >
              <Button
                className="star button"
                onClick={async () => {
                  dispatch(startLoading());
                  if (!dataKnowledgeFavoriteHistory.length) {
                    const resultAction = await Promise.all([
                      dispatch(
                        createFavoriteKnowledge({
                          item: {
                            company_id: userInfo?.company_id,
                            knowledge_id: dataRecord.knowledge_id,
                            login_id: userInfo?.login_id,
                          },
                        })
                      ),
                    ]);
                    if (createFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeRecommendDetailOriginal();
                      fetchKnowledgeFavoriteHistory();
                    }
                  } else {
                    const resultAction = await Promise.all([
                      dispatch(
                        deleteFavoriteKnowledge({
                          id: dataKnowledgeFavoriteHistory[0].item_ref!.favorite_knowledge_id.i_id,
                        })
                      ),
                    ]);
                    if (deleteFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeRecommendDetailOriginal();
                      fetchKnowledgeFavoriteHistory();
                    }
                  }
                  dispatch(stopLoading());
                }}
              >
                <StarFilled
                  className={dataKnowledgeFavoriteHistory.length ? 'star-gold' : 'icon-star'}
                />
                お気に入り
              </Button>
            </Tooltip>
            <Button className="export button" onClick={handleExportPDF}>
              <CloudDownloadOutlined className="icon-star" /> ダウンロード
            </Button>
          </div>
        </div>
        <div className="recommended">
          <div className="header-recommend">
            <div className="left-side">
              <span>リコメンド</span>
              <br />
              <span>{dataKnowledgeRecommendDetailOriginal[0]?.knowledge_title}</span>
            </div>
            <div className="right-side">
              <div className="from">
                <Badge count={<div className="badge">投稿者</div>} offset={[-42, 0]}>
                  {dataKnowledgeRecommendDetailOriginal[0]?.author_icon_file ? (
                    <Avatar
                      src={dataKnowledgeRecommendDetailOriginal[0].author_icon_file}
                      shape="circle"
                      className="avatar"
                      size={40}
                    />
                  ) : (
                    <Avatar
                      shape="circle"
                      className="avatar"
                      size={40}
                      style={{ backgroundColor: '#f56a00' }}
                    >
                      C
                    </Avatar>
                  )}
                </Badge>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 24px 0 8px',
                  }}
                >
                  <span className="department">
                    {dataKnowledgeRecommendDetailOriginal[0]?.author_affliation_id.replace(
                      /^\d+ /,
                      ''
                    )}
                  </span>
                  <span className="name">
                    {dataKnowledgeRecommendDetailOriginal[0]?.author_name}
                  </span>
                </div>
              </div>
              <CaretRightOutlined className="icon" />
              <div className="from">
                <Badge count={<div className="badge">To</div>} offset={[-35, 0]}>
                  {dataKnowledgeRecommendDetailOriginal[0]?.to_user_icon_file ? (
                    <Avatar
                      src={dataKnowledgeRecommendDetailOriginal[0].to_user_icon_file}
                      shape="circle"
                      className="avatar"
                      size={40}
                    />
                  ) : (
                    <Avatar
                      shape="circle"
                      className="avatar"
                      size={40}
                      icon={<UsergroupDeleteOutlined />}
                    />
                  )}
                </Badge>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 24px 0 8px',
                  }}
                >
                  <span className="department">
                    {dataKnowledgeRecommendDetailOriginal[0]?.to_department_name}
                  </span>
                  <span className="name">
                    {dataKnowledgeRecommendDetailOriginal[0]?.to_user_name
                      ? dataKnowledgeRecommendDetailOriginal[0]?.to_user_name
                      : 'ALL'}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="wrap-content">
              <div className="left-body">
                <div className="user">
                  {dataKnowledgeRecommendDetailOriginal[0]?.author_icon_file ? (
                    <Avatar
                      src={dataKnowledgeRecommendDetailOriginal[0]?.author_icon_file}
                      shape="circle"
                      className="avatar"
                      size={40}
                    />
                  ) : (
                    <img className="avt-icon" src={adminAvatar} alt="avt-user" />
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '0 24px 0 8px',
                    }}
                  >
                    <span className="department">
                      {dataKnowledgeRecommendDetailOriginal[0]?.author_affliation_id.replace(
                        /^\d+ /,
                        ''
                      )}
                    </span>
                    <span className="name">
                      {dataKnowledgeRecommendDetailOriginal[0]?.author_name}
                    </span>
                  </div>
                </div>
                <div className="content">
                  <div
                    style={{
                      display: 'block',
                    }}
                    dangerouslySetInnerHTML={createMarkup(
                      dataKnowledgeRecommendDetailOriginal[0]?.knowledge_text
                    )}
                    onClick={handlePreviewImage}
                  />
                </div>
              </div>
              <div className="time">
                {dayjs(dataKnowledgeRecommendDetailOriginal[0]?.knowledge_updatedat).format(
                  'YYYY/MM HH:mm'
                )}
              </div>
            </div>
            <div className="wrap-action">
              <div className="files">
                {dataKnowledgeRecommendDetailOriginal[0]?.files?.map((e, idex) => (
                  <div
                    className="file"
                    key={idex}
                    onClick={() =>
                      e.originFileObj
                        ? fileSaver(e.originFileObj, extractFileName(e?.name))
                        : undefined
                    }
                  >
                    <PaperClipOutlined /> {e.name}
                  </div>
                ))}
              </div>
              {dataKnowledgeRecommendDetailOriginal[0]?.author_login_id === userInfo?.login_id && (
                <div className="edit-delete">
                  <div
                    className={permissionNumber === 1 ? 'disabled' : 'action'}
                    onClick={() => {
                      setIDSelected(
                        dataKnowledgeRecommendDetailOriginal[0].item_ref!.knowledge_id.i_id
                      );
                      setActionType('knowledge');
                      setVisibleDelete(true);
                    }}
                  >
                    <DeleteOutlined className="icon" />
                    <span className="text">リコメンド削除</span>
                  </div>
                  <div
                    className={permissionNumber === 1 ? 'disabled' : 'action edit'}
                    onClick={() =>
                      navigate(
                        generatePath(routes.EditKnowledgeRecommend.path, {
                          id: dataKnowledgeRecommendDetailOriginal[0].i_id,
                        }),
                        {
                          state: dataKnowledgeRecommendDetailOriginal[0],
                        }
                      )
                    }
                  >
                    <EditOutlined className="icon" />
                    <span className="text">内容を更新する</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="wrap-answer">
          <div className="title-answer">
            <MessageOutlined className="icon-message" />
            <span className="title">質問</span>
          </div>
          {dataKnowledgeRecommendDetailAdditional.map((val, i) => {
            return (
              val.question_content && (
                <div className="body" key={i}>
                  <div className="comment">
                    {val.question_answer_content ? (
                      <div className="has-answer">
                        <CheckOutlined className="icon" />
                        <span>回答済</span>
                      </div>
                    ) : (
                      <div className="not-answer">! 未回答 </div>
                    )}

                    {visibleEditQuestion && index === i ? (
                      <CreateQuestionAnswer
                        action="edit_question"
                        onSubmit={() => handleUpdateQuestion(val.item_ref!.question_content!.i_id)}
                        setVisible={setVisibleEditQuestion}
                        setText={setText}
                        setFile={setFile}
                        file={file}
                        text={text}
                        data={val}
                      />
                    ) : (
                      <>
                        <div className="question">
                          <div className="left-question">
                            <div className="user">
                              {val.questioner_icon_file ? (
                                <Avatar
                                  src={val.questioner_icon_file}
                                  shape="circle"
                                  className="avatar"
                                  size={40}
                                />
                              ) : (
                                <Avatar
                                  shape="circle"
                                  className="avatar"
                                  size={40}
                                  icon={<UsergroupDeleteOutlined />}
                                />
                              )}

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  margin: '0 24px 0 8px',
                                }}
                              >
                                <span className="department">
                                  {val.questioner_affiliation_id.replace(/^\d+ /, '')}
                                </span>
                                <span className="name">{val.questioner_name}</span>
                              </div>
                            </div>
                            <div
                              className="content"
                              style={{
                                display: 'block',
                              }}
                              dangerouslySetInnerHTML={createMarkup(val.question_content)}
                              onClick={handlePreviewImage}
                            />
                          </div>
                          <div className="time">
                            {dayjs(val.question_updatedat).format('YYYY/MM/DD HH:mm')}
                          </div>
                        </div>
                        <div
                          className={
                            val.question_file ? 'has-file has-file-question' : 'wrap-button-add'
                          }
                        >
                          {val.question_file && (
                            <div
                              className="file"
                              onClick={() =>
                                val.question_file?.originFileObj
                                  ? fileSaver(
                                      val.question_file.originFileObj,
                                      extractFileName(val.question_file.name)
                                    )
                                  : undefined
                              }
                            >
                              <PaperClipOutlined /> {val.question_file.name}
                            </div>
                          )}
                          {!val.question_answer_content &&
                          val.questioner_id !== userInfo?.login_id ? (
                            <Button
                              className="add-answer"
                              onClick={() => {
                                setIndex(i);
                                setVisible(true);
                              }}
                            >
                              回答する
                            </Button>
                          ) : val.questioner_id === userInfo?.login_id ? (
                            <div className="edit-delete">
                              <div
                                className={permissionNumber === 1 ? 'disabled' : 'action'}
                                onClick={() => {
                                  setIDSelected(val.item_ref!.question_content.i_id);
                                  setActionType('question');
                                  setVisibleDelete(true);
                                }}
                              >
                                <DeleteOutlined className="icon" />
                                <span className="text">質問削除</span>
                              </div>
                              <button
                                disabled={visibleCreateQuestion}
                                className={permissionNumber === 1 ? 'disabled' : 'action edit'}
                                onClick={() => {
                                  setIndex(i);
                                  setText(val.question_content);
                                  setVisibleEditQuestion(true);
                                }}
                              >
                                <EditOutlined className="icon" />
                                <span className="text">内容を更新する</span>
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}

                    {!!val.question_answer_content && !visibleEditAnswer ? (
                      <>
                        <div className="answer-question">
                          <div className="answer-label">回答</div>
                          <div className="item-answer">
                            <div className="user">
                              {val.question_respondent_icon_file ? (
                                <Avatar
                                  src={val.question_respondent_icon_file}
                                  shape="circle"
                                  className="avatar"
                                  size={40}
                                />
                              ) : (
                                <Avatar
                                  shape="circle"
                                  className="avatar"
                                  size={40}
                                  icon={<UsergroupDeleteOutlined />}
                                />
                              )}

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  margin: '0 24px 0 8px',
                                }}
                              >
                                <span className="department">
                                  {val.question_respondent_affiliation_id.replace(/^\d+ /, '')}
                                </span>
                                <span className="name">{val.question_respondent_name}</span>
                              </div>
                            </div>
                            <div
                              className="content"
                              style={{
                                display: 'block',
                                whiteSpace: 'pre',
                              }}
                              dangerouslySetInnerHTML={createMarkup(val.question_answer_content)}
                              onClick={handlePreviewImage}
                            />
                          </div>
                          <span className="time">2022/08/30 10:10</span>
                        </div>
                        <div className="wrap-action">
                          <div
                            className={val.question_answer_file ? 'has-file' : 'wrap-button-add'}
                          >
                            {val.question_answer_file && (
                              <div
                                className="file"
                                onClick={() =>
                                  val.question_answer_file?.originFileObj
                                    ? fileSaver(
                                        val.question_answer_file.originFileObj,
                                        extractFileName(val.question_answer_file.name)
                                      )
                                    : undefined
                                }
                              >
                                <PaperClipOutlined /> {val.question_answer_file.name}
                              </div>
                            )}
                          </div>
                          {val?.question_respondent_id === userInfo?.login_id && (
                            <div className="edit-delete">
                              <div
                                className={permissionNumber === 1 ? 'disabled' : 'action'}
                                onClick={() => {
                                  if (!val.item_ref?.question_answer_content.i_id) return;
                                  setIDSelected(val.item_ref.question_answer_content.i_id);
                                  setActionType('answer');
                                  setVisibleDelete(true);
                                }}
                              >
                                <DeleteOutlined className="icon" />
                                <span className="text">回答削除</span>
                              </div>
                              <button
                                disabled={visibleCreateQuestion}
                                className={permissionNumber === 1 ? 'disabled' : 'action edit'}
                                onClick={() => {
                                  setIndex(i);
                                  setText(val.question_answer_content);
                                  setVisibleEditAnswer(true);
                                }}
                              >
                                <EditOutlined className="icon" />
                                <span className="text">内容を更新する</span>
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    ) : !!val.question_answer_content && visibleEditAnswer && index === i ? (
                      <CreateQuestionAnswer
                        action="edit_answer"
                        onSubmit={() =>
                          handleUpdateQuestionAnswer(val.item_ref!.question_answer_content!.i_id)
                        }
                        setVisible={setVisibleEditAnswer}
                        setText={setText}
                        setFile={setFile}
                        file={file}
                        text={text}
                        data={val}
                      />
                    ) : null}
                  </div>
                  {visible && i === index && (
                    <CreateQuestionAnswer
                      action="create_answer"
                      onSubmit={() => handleCreateAnswer(val.question_id)}
                      setVisible={setVisible}
                      setText={setText}
                      setFile={setFile}
                      file={file}
                      text={text}
                    />
                  )}
                </div>
              )
            );
          })}
          {visibleCreateQuestion && (
            <div className="body">
              <CreateQuestionAnswer
                action="create_question"
                onSubmit={handleCreateQuestion}
                setVisible={setVisibleCreateQuestion}
                setText={setText}
                setFile={setFile}
                file={file}
                text={text}
              />
            </div>
          )}
          <div className="add-question">
            <Button
              disabled={
                permissionNumber === 1 || visibleEditQuestion || visible || visibleEditAnswer
              }
              className={permissionNumber === 1 ? 'disabled' : ''}
              onClick={() => setVisibleCreateQuestion(true)}
            >
              ＋ 追加質問
            </Button>
          </div>
        </div>
        <ImagePreview />
        <ConfirmDeleteModal
          visible={visibleDelete}
          setVisible={setVisibleDelete}
          subTitle="削除を実行します。"
          onSubmit={() =>
            actionType === 'question'
              ? handleDeleteQuestiton(idSelected)
              : actionType === 'knowledge'
              ? handleDeleteKnowledge(idSelected)
              : handleDeleteQuestitonAnswer(idSelected)
          }
        />
        <CompletedDelete
          visible={completed}
          setVisible={setCompleted}
          onSubmit={() => {
            setCompleted(false);
            fetchDataKnowledgeRecommendDetailAdditional();
          }}
        />
      </div>
    </Styled>
  );
};

export default KnowledgeTopRecommend;
