import styled from 'styled-components';

interface Props {
  isCheckUpload: boolean;
}

export default styled.section<Props>`
  margin: 40px 30px;
  .btn-header {
    display: flex;
    justify-content: left;
    padding-bottom: 21px;
    .btn-back {
      font-size: 14px;
      color: #777777;
      cursor: pointer;
      border: none;
      .icon {
        color: #c4c4c4;
      }
    }
  }
  .jodit-toolbar-button_image,
  .jodit-toolbar-button_insertVideo {
    pointer-events: ${(props) => (props.isCheckUpload ? 'none' : '')};
  }
  .ant-upload.ant-upload-drag {
    text-align: left;
    border: 1px solid #cccccc;
    display: flex;
    .ant-upload {
      padding: 0;
    }
  }
  .body {
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    position: relative;
    .left-side {
      color: #1e7ed6;
      padding: 23px 24px;
      font-size: 16px;
      &:before {
        position: absolute;
        content: '';
        width: 3px;
        height: 61px;
        left: 3px;
        border-radius: 4px;
        top: 4px;
        background: #1e7ed6;
      }
    }
    .right-side {
      display: flex;
      span {
        display: inline-flex;
        align-items: center;
      }
    }
    .wrap-title {
      border-bottom: 1px solid #dddddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
      .wrap-information {
        display: flex;
        align-items: flex-end;
        margin-left: 6px;
        .information {
          display: flex;
          flex-direction: column;
          margin-right: 13px;
          .department {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            font-size: 11px;
            color: #777777;
          }
          .name {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            margin: 0;
            color: #424242;
          }
        }
      }
      .wrap-like {
        color: #08a3a5;
      }
      .icon-like {
        padding-right: 5px;
      }
      .wrap-user {
        display: flex;
        align-items: center;
        margin-left: 6px;
      }
    }
    .form {
      padding: 71px 40px;
      .text-area {
        background: #ffffff;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
      }
      .require {
        color: red;
      }
      .item {
        .ant-select-selector {
          box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
          border-radius: 3px;
          .disable {
            background: #eeeeee;
          }
          .active {
            background: #ffffff;
          }
        }
      }
      .wrap-select {
        display: flex;
        justify-content: space-between;
        .item {
          width: 47%;
        }
      }
      .wrap-jodit {
        .jodit-react-container {
          height: 360px !important;
          .jodit-container {
            height: 100% !important;
          }
        }
      }
      .wrap-upload {
        .file-name {
          border-radius: 3px 0 0 3px;
          width: 52%;
          box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
          padding-left: 13px;
          color: #cccccc;
        }
      }
      .upload {
        display: flex;
        .wrap-dragger {
          width: 40%;
        }
        .file-name {
          display: inline-flex;
          align-items: center;
          .aaaaa {
            width: 25%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .upload-button {
          background: #00a3a5;
          height: 40px;
          display: inline-flex;
          align-items: center;
          padding: 0 25px;
          border-radius: 0 5px 5px 0;
          cursor: pointer;
          span {
            color: #ffffff;
            font-size: 13px;
            font-weight: 700;
          }
        }
      }
      .disabled {
        pointer-events: none;
      }
      .wrap-button {
        display: flex;
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 20px;
        .ant-btn {
          height: 48px;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
        }
        .disable {
          color: #bbbbbb;
          font-weight: 700;
          font-size: 15px;
          margin-right: 8px;
          padding: 0 70px;
          background: #eeeeee;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          border: none;
        }
        .submit {
          color: #ffffff;
          font-weight: 700;
          font-size: 15px;
          margin-right: 8px;
          padding: 0 70px;
          border: 1px solid #eeeeee;
          background: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
        }
        .cancle {
          border: 1px solid #d9d9d9;
          font-size: 13px;
          background: #ffffff;
          color: #777777;
          padding: 0 53px;
        }
      }
      .please {
        font-size: 13px;
        color: #777777;
        text-align: center;
      }
    }
  }
`;
