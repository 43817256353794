import React, { useEffect, useState } from 'react';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { DatePicker, Modal, SelectField } from 'components';
import SkillCheckStyled, { PopoverStyled } from './styles';
import { educationProgramSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { ConditionsType, SkillCheck } from 'types';
import { IconLocked, IconPublish } from 'assets';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getDataSelectSkillCheck,
  getDataSelectSkillCheckCategory,
  getDataSelectSkillCheckCreator,
  getDataSelectSkillCheckGroupCode,
  getDataSelectSkillCheckStartPeriod,
  getDataSelectSkillCheckStatus,
  getDataSkillCheck,
} from '../../thunk';

interface Props {
  visible: boolean;
  onSubmit?: (
    item: Array<SkillCheck.ResponseType>,
    type: 'create' | 'delete'
  ) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PER_PAGE = 10;

const { Option } = Select;

const { RangePicker } = DatePicker;

const SkillCheckModal: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [selectedRow, setSelectedRow] = useState<SkillCheck.ResponseType[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    total,
    dataSkillCheck,
    dataSelectSkillCheck,
    dataSelectGroupingCode,
    dataSelectCreators,
  } = useSelector(educationProgramSelector);

  const handleToggleModal = () => {
    setVisible(false);
    setSelectedRow([]);
  };

  const handleSubmit = async (type: 'create' | 'delete') => {
    handleToggleModal();
    onSubmit && (await onSubmit(selectedRow, type));
  };

  const columns: ColumnsType<SkillCheck.ResponseType> = [
    {
      title: 'コード',
      dataIndex: 'code',
      key: 'code',
      width: '6%',
    },
    {
      title: 'スキルチェック名',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
    },
    {
      title: '説明',
      dataIndex: 'description',
      key: 'description',
      width: '5%',
      render: (text, _, index) =>
        text ? (
          <Tooltip
            trigger={['click']}
            title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
            color="#ffffff"
            onVisibleChange={(v) =>
              setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
            }
          >
            <PopoverStyled visiblePopover={visibleTooltip}>
              <FileTextOutlined
                className="icon"
                style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
              />
            </PopoverStyled>
          </Tooltip>
        ) : null,
    },
    {
      title: '開始年月',
      dataIndex: 'start_period',
      key: 'start_period',
      width: '7%',
      render: (text: string) => text && dayjs(text).format('YYYY/MM'),
    },
    {
      title: '対象種別',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '11%',
      render: (record: string) => (
        <div className="item-type">
          <div className={`type ${record === 'interview' ? 'exception' : ''}`}>
            <span>{record === 'interview' ? '面' : '社'} </span>
          </div>
          <span>{record === 'interview' ? '面接' : '社内'}</span>
        </div>
      ),
    },
    {
      title: '分析グループ',
      dataIndex: 'grouping_code',
      key: 'grouping_code',
      width: '13%',
    },
    {
      title: '設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      width: '7%',
      render: (text: string) => text && `${text}問`,
    },
    {
      title: '所要時間',
      dataIndex: 'question_time_limit',
      key: 'question_time_limit',
      width: '7.5%',
      render: (text: string) => text && `${text}分`,
    },
    {
      title: '利用状況',
      dataIndex: 'publish',
      key: 'publish',
      width: '11%',
      render: (publish: string) => (
        <div className="type-icon">
          <img
            src={publish === '1' ? IconPublish : IconLocked}
            className="icon"
            alt="publish-icon"
          />
          <span>{publish === '1' ? '公開中' : '編集中'}</span>
        </div>
      ),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      width: '10%',
    },
  ];

  const dispatch = useAppDispatch();

  const formik = useFormik<Partial<Types.SkillCheck.ResponseType>>({
    initialValues: {
      code: '',
      name: '',
      user_type: 'interview',
      grouping_code: '',
      publish: '0',
      createdby: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value.toString()],
          });
        }
      });
      await dispatch(
        getDataSkillCheck({
          conditions,
          page: page,
          per_page: PER_PAGE,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
        })
      );
      dispatch(stopLoading());
    },
    onReset: async () => {
      dispatch(startLoading());
      await dispatch(
        getDataSkillCheck({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: page,
          per_page: PER_PAGE,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: page,
            per_page: PER_PAGE,
            include_lookups: true,
            include_item_ref: true,
            use_display_id: true,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [page, dispatch, userInfo, visible]);

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSelectSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckStartPeriod({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCategory({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckGroupCode({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckStatus({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCreator({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, visible]);

  return (
    <Modal
      visible={visible}
      title={<h3 className="title">スキルチェック設定</h3>}
      width={1300}
      okButton={{
        text: '登録',
        onClick: () => handleSubmit('create'),
        disabled: !selectedRow.length,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      othersButton={[
        {
          text: '削除',
          onClick: () => handleSubmit('delete'),
          disabled: !selectedRow.length,
        },
      ]}
      onCancel={handleToggleModal}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
    >
      <SkillCheckStyled dataSkillCheck={!!dataSkillCheck?.length}>
        <p className="sub-title">
          設定するスキルチェックを選択して、登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="code"
                className="item"
                label={<span className="text-label">スキルチェックコード</span>}
              >
                <SelectField
                  name="code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ code, i_id }) => (
                    <Option value={code} key={i_id}>
                      {code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">スキルチェック名</span>}
              >
                <SelectField
                  name="name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ name, i_id }) => (
                    <Option value={name} key={i_id}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="start_period"
                className="item"
                label={<span className="text-label">集計期間</span>}
              >
                <RangePicker
                  allowClear
                  name="date-item"
                  format="YYYY/MM/DD HH:mm"
                  placeholder={['集計開始日', '集計終了日']}
                  onChange={(dates) => {
                    if (dates) {
                      formik.setValues({
                        ...formik.values,
                        start_period: dates[0]?.toDate(),
                        end_period: dates[1]?.toDate(),
                      });
                    } else {
                      formik.setValues({
                        ...formik.values,
                        start_period: undefined,
                        end_period: undefined,
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="user_type"
                className="item"
                label={<span className="text-label">対象種別</span>}
              >
                <SelectField
                  name="user_type"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option key={1} value="member">
                    <div className="text-user-type">
                      <div className="text-icon-type interview">社</div>
                      <span>社内</span>
                    </div>
                  </Option>
                  <Option key={2} value="interview">
                    <div className="text-user-type">
                      <div className="text-icon-type member">面</div>
                      <span>面接</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="grouping_code"
                className="item"
                label={<span className="text-label">分析グループ</span>}
              >
                <SelectField
                  name="grouping_code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectGroupingCode?.map((value, i) => (
                    <Option value={value.grouping_code} key={i}>
                      {value.grouping_code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="publish"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField
                  name="publish"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option value={'1'} key={1}>
                    <div className="type-icon">
                      <img src={IconPublish} className="icon" alt="publish-icon" />
                      <span>公開中</span>
                    </div>
                  </Option>
                  <Option value={'0'} key={0}>
                    <div className="type-icon">
                      <img src={IconLocked} className="icon" alt="publish-icon" />
                      <span>編集中</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="createdby"
                className="item"
                label={<span className="text-label">作成者</span>}
              >
                <SelectField
                  name="createdby"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectCreators?.map((value, i) => (
                    <Option value={value.creator} key={i}>
                      {value.creator}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="text-count">
          {page * PER_PAGE > total ? dataSkillCheck.length : page * PER_PAGE}
          <span className="text-static">件表示 </span> / {dataSkillCheck.length}
          <span className="text-static">名</span>
        </div>
        <Table
          className="table"
          loading={loading}
          dataSource={dataSkillCheck.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
            onChange: (_, data) => setSelectedRow(data),
          }}
          pagination={{
            pageSize: PER_PAGE,
            showSizeChanger: false,
            total: dataSkillCheck.length,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
          rowKey="i_id"
        />
      </SkillCheckStyled>
    </Modal>
  );
};

export default SkillCheckModal;
