import enUS from 'antd/es/locale/en_US';

import { Entries } from 'types/config';
import enMessages from '../locales/en_US.json';

const EnLang: Entries = {
  messages: {
    ...enMessages,
  },
  antd: enUS,
  locale: 'en',
};

export default EnLang;
