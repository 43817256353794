import React, { useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { find } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CompletedModalChangePayment from '../CompleteChangePayment';
import { CHECK_BOX_MODAL_CONFIRM_WITHDRAWAL } from 'constant';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { authSelector } from 'containers/Auth/selectors';
import { updateCreditCardInfo } from '../../thunk';
import { ConfirmWithdrawStyled } from './styles';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import { COMPANIES } from 'configs';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  visible: boolean;
}

const ModalConfirmWithdraw: React.FC<Props> = ({ setVisible, visible }) => {
  const [visibleModalSuccessfully, setVisibleModalSuccessfully] = useState<boolean>(false);
  const [reasons, setReasons] = useState<CheckboxValueType[]>([]);
  const [comment, setComment] = useState('');

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleToggleModal = () => {
    setVisible(!visible);
  };

  const handleSubmit = async () => {
    if (!userInfo) return;
    const id = find(userInfo.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
      ?.i_id;

    dispatch(startLoading());
    const resultAction = await dispatch(
      updateCreditCardInfo({
        id: id || '',
        data: {
          item: {
            reasons: reasons.join(', '),
            comment: comment,
            deletedat: new Date(),
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );
    if (updateCreditCardInfo.fulfilled.match(resultAction)) {
      handleToggleModal();
      setVisibleModalSuccessfully(true);
    }
    dispatch(stopLoading());
  };

  const handleCheckboxChange = (checkedValues: CheckboxValueType[]) => {
    setReasons(checkedValues);
  };

  return (
    <>
      <Modal
        title="SKILL FAMILIARを退会する"
        visible={visible}
        width={720}
        onCancel={handleToggleModal}
        okButton={{
          text: '退会',
          onClick: handleSubmit,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: handleToggleModal,
        }}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
      >
        <ConfirmWithdrawStyled>
          <div className="content">
            <div className="wrap-content-top">
              <div className="wrap-warning-description">
                <ExclamationCircleOutlined className="warning-icon" />
                <span className="warning-description">
                  SKILL FAMILIARを退会すると、
                  <a href="link" target="_blank" className="text-bold">
                    全てのデータが削除
                  </a>
                  されます。
                  <br />
                  削除実行後の復元はできませんのでご注意ください。
                </span>
              </div>
              <span className="warning-note">
                ※サービス利用は退会実行日の属する月の末日までご利用いただけます。
                <br />
                以降はログインができなくなります。
              </span>
            </div>
            <div className="wrap-comment">
              <div className="wrap-description">
                <span className="description">
                  今後のサービス向上のため、退会理由を教えていただけますと幸いでございます。
                  <br />
                  より一層充実したサービスにできるよう努めてまいります。
                </span>
              </div>
              <div className="comment">
                <span className="title">
                  退会理由<small>（複数回答可能です）</small>
                </span>
                <div className="checkbox">
                  <Checkbox.Group onChange={handleCheckboxChange} value={reasons}>
                    {CHECK_BOX_MODAL_CONFIRM_WITHDRAWAL.map((item, index) => (
                      <Checkbox key={index} value={item}>
                        {item}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                  <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                </div>
              </div>
            </div>
          </div>
        </ConfirmWithdrawStyled>
      </Modal>
      <CompletedModalChangePayment
        title="退会手続きが完了しました"
        subTitle={
          <>
            SKILL FAMILIARをご利用いただき
            <br />
            誠にありがとうございました。
          </>
        }
        visible={visibleModalSuccessfully}
        setVisible={setVisibleModalSuccessfully}
      />
    </>
  );
};

export default ModalConfirmWithdraw;
