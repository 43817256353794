import React from 'react';
import { FilterOutlined } from '@ant-design/icons';
import { Select, Checkbox } from 'antd';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs';

import { PUBLISH, USER_TYPE } from 'constant/select.constants';
import { QuestionProps, SkillCheckTreeViewType } from 'types';
import TreeView from 'containers/SkillCheck/TreeView';
import { Header, DatePicker } from 'components';
import SkillCheckStyled from './styles';
import * as Types from 'types';

const { Option } = Select;

interface Props {
  setSkillCheckSelected: React.Dispatch<React.SetStateAction<SkillCheckTreeViewType | undefined>>;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<QuestionProps[]>>;
  filter_option: Types.SkillCheckTreeViewType[];
  skillCheckSelected?: SkillCheckTreeViewType;
  skillCheck: SkillCheckTreeViewType[];
  fetchDataUserSkillCheck: () => void;
  selectedQuestion: QuestionProps[];
  fetchDataSkillCheck: () => void;
  valueSkillCheckCode?: string;
  valueGroupingCode?: string;
  valueStartPeriod?: string;
  valueUserType?: string;
  columnClosed: boolean;
  valuePublish?: number;
  filter_conditions: Types.SkillCheckFilterType & {
    conditions: Types.ConditionsType[];
  };
}

const PDFSkillCheckDocument: React.FC<Props> = ({
  fetchDataUserSkillCheck,
  setSkillCheckSelected,
  valueSkillCheckCode,
  fetchDataSkillCheck,
  setSelectedQuestion,
  skillCheckSelected,
  valueGroupingCode,
  filter_conditions,
  selectedQuestion,
  valueStartPeriod,
  valueUserType,
  filter_option,
  columnClosed,
  valuePublish,
  skillCheck,
}) => {
  return (
    <SkillCheckStyled>
      <Header title="スキルチェックツリー" className="header">
        <div className="filter">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              allowClear
              showSearch
              className="select-input"
              value={valueSkillCheckCode}
              placeholder="スキルチェック名"
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {uniqBy(filter_option, 'skill_check_name').map(
                ({ skill_check_name, skill_check_code }, index) => (
                  <Option key={index} value={skill_check_code} title={skill_check_name}>
                    {skill_check_name}
                  </Option>
                )
              )}
            </Select>
          </div>
          <div className="form-input">
            <Select
              allowClear
              showSearch
              className="select-input"
              placeholder="分析グループ"
              value={valueGroupingCode}
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {uniqBy(filter_option, 'grouping_code2').map((item, index) => (
                <Option key={index} value={item.grouping_code2} title={item.grouping_code2}>
                  {item.grouping_code}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <DatePicker
              allowClear
              className="select-input"
              placeholder="開始日"
              format="YYYY/MM/DD"
              value={valueStartPeriod ? dayjs(valueStartPeriod) : undefined}
            />
          </div>
          <div className="form-input">
            <Select
              allowClear
              className="select-input"
              value={valuePublish}
              placeholder="公開ステータス"
            >
              {PUBLISH.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <Select
              allowClear
              className="select-input"
              value={valueUserType}
              placeholder="ユーザー種別"
            >
              {USER_TYPE.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>

          <div className="form-input">
            <Checkbox checked={!!filter_conditions.createdby}>自作のみ表示</Checkbox>
          </div>
        </div>
      </Header>

      <div className="flex-tree">
        <div className="dashboard">
          <div className="wrap-body">
            {skillCheck.map((treeData, index) => (
              <div
                key={index}
                className={`wrap-tree ${index < skillCheck?.length - 1 ? 'bordered' : ''}`}
              >
                <TreeView
                  treeData={treeData}
                  columnClosed={columnClosed}
                  selectedQuestion={selectedQuestion}
                  skillCheckSelected={skillCheckSelected}
                  setSelectedQuestion={setSelectedQuestion}
                  fetchDataSkillCheck={fetchDataSkillCheck}
                  setSkillCheckSelected={setSkillCheckSelected}
                  fetchDataUserSkillCheck={fetchDataUserSkillCheck}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </SkillCheckStyled>
  );
};

export default PDFSkillCheckDocument;
