import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Col, Row, Table, Tabs } from 'antd';
import { useParams } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  CheckCircleOutlined,
  FileTextOutlined,
  MessageOutlined,
  FormOutlined,
  LikeOutlined,
  MailOutlined,
  ReadOutlined,
  UserOutlined,
  StarFilled,
  ExclamationOutlined,
  LikeFilled,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { CurriculumDescription, EditModal } from './Modal';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { profileSelector } from './selectors';
import { useAppDispatch } from 'hooks';
import ProfileStyled from './styles';
import { adminAvatar } from 'assets';
import { Header } from 'components';
import * as Types from 'types';
import {
  getDataProfileCurriculumTable,
  getDataProfileCurriculum,
  getSelectDepartmentName,
  getDataKnowledgeInfo,
  getUserInfoDetail,
  getDataRankingUserAll,
  getKnowledgeFavoriteHistory,
  getKnowledgeLikeHistory,
  getDataKnowledgeList,
} from './thunk';
import { findIndex } from 'lodash';

const { TabPane } = Tabs;

const PER_PAGE = 10;

const Profile: React.FC = () => {
  const [showCompleteModalCreate, setShowCompleteModalCreate] = useState<boolean>(false);
  const [showCompleteModalEdit, setShowCompleteModalEdit] = useState<boolean>(false);
  const [visibleEditModal, setVisibleEditModal] = useState<boolean>(false);
  const [isCurriculumDes, setIsCurriculumDes] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<string>('1');
  const [pageAll, setPageAll] = useState<number>(1);
  const [page1, setPage1] = useState<number>(1);
  const [page2, setPage2] = useState<number>(1);
  const [page3, setPage3] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [dataItemProfileCurriculum, setDataItemProfileCurriculum] =
    useState<Types.ProfileCurriculum.ResponseType>();

  const {
    dataProfileCurriculumUnRequiredCompleted,
    dataProfileOfficialCurriculumCompleted,
    dataProfileCurriculumRequiredCompleted,
    dataProfileCurriculumUnRequired,
    dataProfileCurriculumRequired,
    dataProfileOfficialCurriculum,
    dataKnowledgeFavoriteHistory,
    dataProfileCurriculumTable,
    dataKnowledgeLikeHistory,
    dataKnowledgeCurriculum,
    dataKnowledgeRecommend,
    dataRankingUserAll,
    dataKnowledgeInfo,
    dataKnowledgeList,
    dataKnowledgeQA,
    departmentNames,
    userInfoDetail,
  } = useSelector(profileSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const { id } = useParams<{ id: string }>();

  const parser = new DOMParser();

  const onChange = (key: string) => {
    setTabActive(key);
    fetchDataTabKeyProfileCurriculum(key);
  };

  const fetchDataTabKeyProfileCurriculum = (key: string) => {
    const user = id ? userInfoDetail : userInfo;
    if (user) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataProfileCurriculumTable({
            conditions: [
              {
                id: 'company_id',
                search_value: [user.company_id],
              },
              {
                id: 'login_id',
                search_value: [user.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_trans_status',
                search_value: ['1'],
                exact_match: true,
              },
              ...(key !== '3' && key !== '1'
                ? [
                    {
                      id: 'official_curriculum',
                      search_value: [key === '2' ? '0' : key === '4' ? '1' : ''],
                    },
                  ]
                : []),
              ...(key !== '4' && key !== '1'
                ? [
                    {
                      id: 'required_curriculum',
                      search_value: [key === '2' ? '0' : key === '3' ? '1' : ''],
                    },
                  ]
                : []),
            ],
            include_lookups: true,
            omit_total_items: false,
            include_item_ref: true,
            use_display_id: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  };

  const fetchDataProfileCurriculum = useCallback(() => {
    const user = id ? userInfoDetail : userInfo;
    if (user) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataProfileCurriculumTable({
            conditions: [
              {
                id: 'company_id',
                search_value: [user.company_id],
              },
              {
                id: 'login_id',
                search_value: [user.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_trans_status',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            omit_total_items: false,
            include_item_ref: true,
            use_display_id: true,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [id, userInfoDetail, userInfo, dispatch]);

  const columns: ColumnsType<Types.ProfileCurriculum.ResponseType> = [
    {
      title: 'カリキュラム名',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      width: '20%',
    },
    {
      title: '概要',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      width: '40%',
    },
    {
      title: '説明',
      dataIndex: 'curriculum_description',
      key: 'curriculum_description',
      width: '10%',
      render: (_, item) => (
        <FileTextOutlined
          onClick={() => {
            setIsCurriculumDes(true);
            setDataItemProfileCurriculum(item);
          }}
          className="icon"
          style={{ color: '#c4c4c4' }}
        />
      ),
    },
    {
      title: '種別',
      dataIndex: 'kinds',
      key: 'kinds',
      width: '15%',
      render: (_, { required_curriculum, official_curriculum }) =>
        !required_curriculum && !official_curriculum
          ? 'カリキュラム'
          : required_curriculum && !official_curriculum
          ? '必修カリキュラム'
          : official_curriculum
          ? 'OFFICIALカリキュラム'
          : required_curriculum && official_curriculum
          ? '必修カリキュラム / OFFICIALカリキュラム'
          : null,
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '15%',
    },
  ];

  const columnsKnowledge: ColumnsType<Types.KnowledgeList.ResponseType> = [
    {
      title: '概要',
      dataIndex: 'knowledge_type',
      key: 'knowledge_type',
      width: '5%',
      render: (icon: number, item) => (
        <div className={`star ${icon === 1 ? '' : icon === 2 ? 'border-green' : 'border-blue'}`}>
          {findIndex(
            dataKnowledgeFavoriteHistory,
            (e) => e.favorite_knowledge_id === item.knowledge_id
          ) > -1 ? (
            <StarFilled className="icon-star yellow" />
          ) : (
            <StarFilled className="icon-star gray" />
          )}
        </div>
      ),
    },
    {
      title: '概要',
      dataIndex: 'knowledge_title',
      key: 'knowledge_title',
      width: '30%',
      ellipsis: true,
      render: (_, item) => (
        <div className="item-title">
          <span className="knowledge-text">{item.knowledge_title}</span>
          <span className="knowledge-text">
            {parser.parseFromString(item.knowledge_text, 'text/html').body.textContent}
          </span>
        </div>
      ),
    },
    {
      title: '種別',
      dataIndex: 'author_name',
      key: 'author_name',
      width: '15%',
      render: (author_name, item) => (
        <div className="item-info">
          <img
            className="avatar"
            src={
              item?.author_avatar
                ? URL.createObjectURL(new Blob([item.author_avatar.originFileObj as Blob]))
                : adminAvatar
            }
            alt="avatar"
          />
          <div className="user-info">
            <span className="department-name">
              {item.author_department_name.replace(/^\d+ /, '')}
            </span>
            <br />
            <span className="username">{author_name}</span>
          </div>
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'receiver',
      key: 'receiver',
      className: 'item-receiver',
      width: '15%',
      render: (_, item) => (
        <div className="receiver">
          <span className="label">
            To.
            {!item.to_department && !item.to_user ? (
              <span className="label-all"> ALL</span>
            ) : (
              <span>&nbsp;{item.to_department}</span>
            )}
          </span>
          {item.to_department && item.to_user ? <span className="text">{item.to_user}</span> : null}
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'knowledge_type',
      key: 'knowledge_type',
      width: '15%',
      render: (knowledge_type: number, item) => (
        <div className="item-recommend">
          <span className="knowledge-label">
            {knowledge_type === 1 ? 'カリキュラム' : knowledge_type === 2 ? 'Q&A' : 'リコメンド'}
          </span>
          {knowledge_type === 2 ? (
            <div className={`qa-status-label ${item.qa_status === 0 ? 'red' : 'blue'}`}>
              {item.qa_status === 0 ? (
                <ExclamationOutlined className="icon-status" />
              ) : item.qa_status === 1 ? (
                <CheckCircleOutlined className="icon-status" />
              ) : (
                <MessageOutlined className="icon-status" />
              )}
              <span className="label">{item.qa_status === 0 ? '未回答' : '回答済み'}</span>
            </div>
          ) : null}
          {knowledge_type === 2 && item.qa_status === 2 ? (
            <div className="qa-status-label extend-question">
              <span className="label">追加質問あり</span>
            </div>
          ) : null}
          {knowledge_type === 3 && item.recommendation_status > 0 ? (
            <div
              className={`recommendation-status-label ${
                item.recommendation_status === 1 ? 'red' : 'blue'
              }`}
            >
              {item.recommendation_status === 1 ? (
                <MessageOutlined className="icon" />
              ) : (
                <CheckCircleOutlined className="icon" />
              )}
              <span className="label">
                {item.recommendation_status === 1 ? '質問あり' : '回答済'}
              </span>
            </div>
          ) : null}
        </div>
      ),
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '20%',
      render: (updatedat: string, item) => (
        <div className="time">
          <span className="number-like">
            {findIndex(dataKnowledgeLikeHistory, (e) => e?.knowledge_id === item.knowledge_id) >
            -1 ? (
              <LikeFilled className="like-icon" />
            ) : (
              <LikeOutlined className="like-icon" />
            )}
            {/* )} */}
            {item.number_of_likes}
          </span>
          <div className="label-time">Last update:</div>
          <div className="detail">
            {updatedat
              ? dayjs(updatedat).format('YYYY/MM/DD HH:mm')
              : dayjs(item.createdat).format('YYYY/MM/DD HH:mm')}
          </div>
        </div>
      ),
    },
  ];

  const indexRankLike = useMemo(() => {
    return dataRankingUserAll.findIndex((item) => userInfo?.login_id === item.author_user_id);
  }, [dataRankingUserAll, userInfo]);

  const fetchDataKnowledgeFavoriteHistory = useCallback(() => {
    if (userInfo) {
      dispatch(startLoading());
      dispatch(
        getKnowledgeFavoriteHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page,
          per_page: PER_PAGE,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, page, userInfo]);

  const fetchDataKnowledgeLikeHistory = useCallback(() => {
    if (userInfo) {
      dispatch(startLoading());
      dispatch(
        getKnowledgeLikeHistory({
          conditions: [
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
          ],
          page,
          per_page: PER_PAGE,
        })
      );
      dispatch(stopLoading());
    }
  }, [dispatch, page, userInfo]);

  useEffect(() => {
    const user = id ? userInfoDetail : userInfo;
    if (user) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataKnowledgeInfo({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [user.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              omit_total_items: false,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, id, userInfoDetail]);

  useEffect(() => {
    if (!id || !userInfo?.company_id) return;
    (async () => {
      dispatch(startLoading());

      await dispatch(
        getUserInfoDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'i_id',
              search_value: [id],
            },
          ],
          page: 1,
          per_page: 10,
          use_display_id: true,
          include_lookups: true,
        })
      );
      dispatch(stopLoading());
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(fetchDataProfileCurriculum, [fetchDataProfileCurriculum]);
  useEffect(fetchDataKnowledgeFavoriteHistory, [fetchDataKnowledgeFavoriteHistory]);
  useEffect(fetchDataKnowledgeLikeHistory, [fetchDataKnowledgeLikeHistory]);

  useEffect(
    () => fetchDataTabKeyProfileCurriculum(tabActive),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page1, page2, page3, dispatch, userInfo, id, tabActive]
  );

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getSelectDepartmentName({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getDataProfileCurriculum({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getDataRankingUserAll({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        sort_fields: [
          {
            id: 'number_of_likes',
            order: 'desc',
          },
          {
            id: 'created_by',
            order: 'asc',
          },
        ],
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getDataKnowledgeList({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'createdBy',
            search_value: [userInfo.login_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        sort_fields: [
          {
            id: 'number_of_likes',
            order: 'desc',
          },
          {
            id: 'created_by',
            order: 'asc',
          },
        ],
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  return (
    <>
      <Header title={headerTitle} />
      <ProfileStyled
        isEmptyData={!!dataKnowledgeInfo.length}
        isEmptyDataCurriculum={!!dataProfileCurriculumTable?.length}
      >
        {!userInfo && !userInfoDetail && (
          <p className="text-note">
            基本情報の入力とプロフィール画像の設定を行い、登録ボタンをクリックしてください。
          </p>
        )}
        <div className="wrap-basic-inf">
          <div className="body">
            <div className="wrap-user">
              <div className="avatar">
                {id && userInfoDetail ? (
                  <>
                    {userInfoDetail?.icon_fileID && userInfoDetail?.avatar?.originFileObj ? (
                      <Avatar
                        size={200}
                        src={URL.createObjectURL(new Blob([userInfoDetail.avatar.originFileObj]))}
                      />
                    ) : (
                      <div className="avatar-user">
                        <UserOutlined className="icon-user" />
                        <span className="text-content">NO PHOTO</span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {userInfo && userInfo.avatar?.originFileObj ? (
                      <Avatar
                        size={200}
                        src={URL.createObjectURL(
                          new Blob([(userInfo.avatar.originFileObj ?? userInfo.avatar) as Blob])
                        )}
                      />
                    ) : (
                      <div className="avatar-user">
                        <UserOutlined className="icon-user" />
                        <span className="text-content">NO PHOTO</span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="user-info">
                <span className="user-name">
                  {id ? userInfoDetail?.name_furigana : userInfo?.name_furigana}
                </span>
                <span className="name-ja">{id ? userInfoDetail?.name : userInfo?.name}</span>
                <hr className="divider" />
                <span className="account-user">{departmentNames?.department_name}</span>
                <div className="mail-item">
                  <MailOutlined className="mail-icon" />
                  <span className="mail-user">{id ? userInfoDetail?.email : userInfo?.email}</span>
                </div>
              </div>
            </div>
            <div className="form-right">
              <div className="form-right-item">
                <div className="profile-item">
                  <span className="profile-item-title">社員番号：</span>
                  <span className="profile-item-content">
                    {id ? userInfoDetail?.employee_code : userInfo?.employee_code}
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">役職：</span>
                  <span className="profile-item-content">
                    {id ? userInfoDetail?.role_code : userInfo?.role_code}
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">年齢：</span>
                  <span className="profile-item-content">
                    {dayjs().diff(id ? userInfoDetail?.dob : userInfo?.dob, 'years', false)}歳
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">入社日：</span>
                  <span className="profile-item-content">
                    {dayjs(id ? userInfoDetail?.doj : userInfo?.doj).format('YYYY年MM月DD日')}
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">出身地：</span>
                  <span className="profile-item-content">
                    {id ? userInfoDetail?.birthplace : userInfo?.birthplace}
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">趣味：</span>
                  <span className="profile-item-content">
                    {id ? userInfoDetail?.hobby : userInfo?.hobby}
                  </span>
                </div>
                <div className="profile-item">
                  <span className="profile-item-title">担当業務：</span>
                  <span className="profile-item-content">
                    {id ? userInfoDetail?.work_in_charge : userInfo?.work_in_charge}
                  </span>
                </div>
              </div>
              {!id && (
                <div className="form-right-btn">
                  <button onClick={() => setVisibleEditModal(true)} className="bth-edit">
                    <FormOutlined className="icon-edit" />
                    編集
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="item-comment">
            <div className="comment">
              <p className="title-comment">Comment</p>
              <p className="content">{id ? userInfoDetail?.comment : userInfo?.comment}</p>
            </div>
          </div>
        </div>
        <div className="training-inf">
          <ReadOutlined className="icon" />
          <p className="tx-training-inf">トレーニング情報</p>
        </div>
        <div className="wrap-training-inf">
          <div className="body">
            <Row>
              <Col className="item-inf" span={8}>
                <p className="text-number-of-curriculum">カリキュラム実施数</p>
                <Row>
                  <Col className="item-child-left" span={12}>
                    <p className="number-curriculum">
                      {dataProfileCurriculumUnRequiredCompleted.length || 0}
                    </p>
                    <p className="confirm-text">コンプリート</p>
                  </Col>
                  <Col className="item-child-right" span={12}>
                    <p className="number-curriculum">
                      {dataProfileCurriculumUnRequired.length || 0}
                    </p>
                    <p className="confirm-text">実施中</p>
                  </Col>
                </Row>
              </Col>
              <Col className="item-inf" span={8}>
                <p className="text-number-of-curriculum">必修カリキュラム実施数</p>
                <Row>
                  <Col className="item-child-left" span={12}>
                    <p className="number-curriculum">
                      {dataProfileCurriculumRequiredCompleted.length || 0}
                    </p>
                    <p className="confirm-text">コンプリート</p>
                  </Col>
                  <Col className="item-child-right" span={12}>
                    <p className="number-curriculum">{dataProfileCurriculumRequired.length || 0}</p>
                    <p className="confirm-text">実施中</p>
                  </Col>
                </Row>
              </Col>
              <Col className="item-inf" span={8}>
                <p className="text-number-of-curriculum">OFFICIALカリキュラム実施数</p>
                <Row>
                  <Col className="item-child-left" span={12}>
                    <p className="number-curriculum">
                      {dataProfileOfficialCurriculumCompleted.length || 0}
                    </p>
                    <p className="confirm-text">コンプリート</p>
                  </Col>
                  <Col className="item-child-right" span={12}>
                    <p className="number-curriculum">{dataProfileOfficialCurriculum.length || 0}</p>
                    <p className="confirm-text">実施中</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        <div className="complete-curriculum">
          <div className="header-complete-curriculum">
            <CheckCircleOutlined className="icon" />
            <p className="tx-complete-curriculum">コンプリートしたカリキュラム</p>
          </div>
          <div className="tab-complete-curriculum">
            <Tabs className="tab-container" defaultActiveKey="1" onChange={onChange}>
              <TabPane tab="全て" key="1" style={{ outline: 'none' }}>
                <Table
                  rowKey="index"
                  className="table"
                  dataSource={dataProfileCurriculumTable?.map((item, index) => ({
                    ...item,
                    index,
                  }))}
                  columns={columns}
                  pagination={{
                    pageSize: PER_PAGE,
                    showSizeChanger: false,
                    position: ['topRight'],
                    onChange: setPageAll,
                    current: pageAll,
                    total: dataProfileCurriculumTable.length,
                  }}
                />
              </TabPane>
              <TabPane tab="カリキュラム" key="2">
                <Table
                  rowKey="index"
                  className="table"
                  dataSource={dataProfileCurriculumTable?.map((item, index) => ({
                    ...item,
                    index,
                  }))}
                  columns={columns}
                  pagination={{
                    pageSize: PER_PAGE,
                    showSizeChanger: false,
                    total: dataProfileCurriculumTable.length,
                    current: page1,
                    onChange: setPage1,
                    position: ['topRight'],
                  }}
                />
              </TabPane>
              <TabPane tab="必修カリキュラム" key="3">
                <Table
                  rowKey="index"
                  className="table"
                  dataSource={dataProfileCurriculumTable?.map((item, index) => ({
                    ...item,
                    index,
                  }))}
                  columns={columns}
                  pagination={{
                    pageSize: PER_PAGE,
                    showSizeChanger: false,
                    total: dataProfileCurriculumTable.length,
                    current: page2,
                    onChange: setPage2,
                    position: ['topRight'],
                  }}
                />
              </TabPane>
              <TabPane tab="OFFICIALカリキュラム" key="4">
                <Table
                  rowKey="index"
                  className="table"
                  dataSource={dataProfileCurriculumTable?.map((item, index) => ({
                    ...item,
                    index,
                  }))}
                  columns={columns}
                  pagination={{
                    pageSize: PER_PAGE,
                    showSizeChanger: false,
                    total: dataProfileCurriculumTable.length,
                    current: page3,
                    onChange: setPage3,
                    position: ['topRight'],
                  }}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
        <div className="header-knowledge-info">
          <MessageOutlined className="icon-mess" />
          <p className="tx-knowledge-info">ナレッジ情報</p>
        </div>
        <div className="knowledge-info">
          <Row>
            <Col className="item-inf" span={6}>
              <p className="text-number-of-curriculum">いいね！ランキング</p>
              <Row className="row-item-inf">
                <Col className="item-inf-left" span={10}>
                  <p className="number-curriculum">{indexRankLike + 1} 位</p>
                </Col>
                <Col className="item-inf-center" span={4}>
                  <div className="border" />
                </Col>
                <Col className="item-inf-right" span={10}>
                  <p className="confirm-text">獲得いいね！</p>
                  <p className="number-curriculum">
                    <LikeOutlined className="like-icon" />
                    {dataRankingUserAll[indexRankLike]?.number_of_likes}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="item-inf" span={6}>
              <p className="text-number-of-curriculum">カリキュラム作成数</p>
              <Row className="row-item-inf">
                <Col className="item-inf-left" span={10}>
                  <p className="number-curriculum">{dataKnowledgeCurriculum.length}</p>
                </Col>
                <Col className="item-inf-center" span={4}>
                  <div className="border" />
                </Col>
                <Col className="item-inf-right" span={10}>
                  <p className="confirm-text">獲得いいね！</p>
                  <p className="number-curriculum">
                    <LikeOutlined className="like-icon" />
                    {dataKnowledgeCurriculum.reduce(
                      (acc, currentItem) => acc + Number(currentItem.number_of_likes),
                      0
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="item-inf" span={6}>
              <p className="text-number-of-curriculum">QA作成数</p>
              <Row className="row-item-inf">
                <Col className="item-inf-left" span={10}>
                  <p className="number-curriculum">{dataKnowledgeQA.length}</p>
                </Col>
                <Col className="item-inf-center" span={4}>
                  <div className="border" />
                </Col>
                <Col className="item-inf-right" span={10}>
                  <p className="confirm-text">獲得いいね！</p>
                  <p className="number-curriculum">
                    <LikeOutlined className="like-icon" />
                    {dataKnowledgeQA.reduce(
                      (acc, currentItem) => acc + Number(currentItem.number_of_likes),
                      0
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="item-inf" span={6}>
              <p className="text-number-of-curriculum">リコメンド作成数</p>
              <Row className="row-item-inf">
                <Col className="item-inf-left" span={10}>
                  <p className="number-curriculum">{dataKnowledgeRecommend.length}</p>
                </Col>
                <Col className="item-inf-center" span={4}>
                  <div className="border" />
                </Col>
                <Col className="item-inf-right" span={10}>
                  <p className="confirm-text">獲得いいね！</p>
                  <p className="number-curriculum">
                    <LikeOutlined className="like-icon" />
                    {dataKnowledgeRecommend.reduce(
                      (acc, currentItem) => acc + Number(currentItem.number_of_likes),
                      0
                    )}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="tab-knowledge-inf">
          <Tabs className="tab-container" defaultActiveKey="ALL">
            <TabPane tab="全ての投稿" key="ALL">
              <Table
                rowKey="index"
                className="table-knowledge-inf"
                showHeader={false}
                dataSource={dataKnowledgeList.map((item, index) => ({ ...item, index }))}
                columns={columnsKnowledge}
                pagination={{
                  pageSize: PER_PAGE,
                  showSizeChanger: false,
                  total: dataKnowledgeList.length,
                  current: page,
                  onChange: setPage,
                  position: ['topRight'],
                }}
              />
            </TabPane>
            <TabPane tab="カリキュラム" key="1">
              <Table
                rowKey="index"
                className="table-knowledge-inf"
                showHeader={false}
                dataSource={dataKnowledgeCurriculum.map((item, index) => ({ ...item, index }))}
                columns={columnsKnowledge}
                pagination={{
                  pageSize: PER_PAGE,
                  showSizeChanger: false,
                  total: dataKnowledgeCurriculum.length,
                  current: page,
                  onChange: setPage,
                  position: ['topRight'],
                }}
              />
            </TabPane>
            <TabPane tab="Q&A" key="2">
              <Table
                rowKey="index"
                className="table-knowledge-inf"
                showHeader={false}
                dataSource={dataKnowledgeQA.map((item, index) => ({ ...item, index }))}
                columns={columnsKnowledge}
                pagination={{
                  pageSize: PER_PAGE,
                  showSizeChanger: false,
                  total: dataKnowledgeQA.length,
                  current: page,
                  onChange: setPage,
                  position: ['topRight'],
                }}
              />
            </TabPane>
            <TabPane tab="リコメンド" key="3">
              <Table
                rowKey="index"
                className="table-knowledge-inf"
                showHeader={false}
                dataSource={dataKnowledgeRecommend.map((item, index) => ({ ...item, index }))}
                columns={columnsKnowledge}
                pagination={{
                  pageSize: PER_PAGE,
                  showSizeChanger: false,
                  total: dataKnowledgeRecommend.length,
                  current: page,
                  onChange: setPage,
                  position: ['topRight'],
                }}
              />
            </TabPane>
          </Tabs>
        </div>
        <CurriculumDescription
          visible={isCurriculumDes}
          setVisible={setIsCurriculumDes}
          item_text={dataItemProfileCurriculum?.curriculum_name}
          text_explanation={dataItemProfileCurriculum?.curriculum_description}
        />
        <EditModal
          visible={visibleEditModal}
          setVisible={setVisibleEditModal}
          onSubmit={() => {
            setShowCompleteModalEdit(true);
          }}
        />
        <CompletedModal
          visible={showCompleteModalCreate}
          setVisible={setShowCompleteModalCreate}
          title="プロフィール設定が完了しました！"
        />
        <CompletedModal
          visible={showCompleteModalEdit}
          setVisible={setShowCompleteModalEdit}
          title="プロフィールを設定しました。"
        />
      </ProfileStyled>
    </>
  );
};

export default Profile;
