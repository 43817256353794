import React from 'react';
import { DoubleLeftOutlined, LeftOutlined } from '@ant-design/icons';

import { StylesContent } from './styles';

const QuestionPopup: React.FC = () => {
  return (
    <StylesContent>
      <p className="text-content">
        全て追加：
        <span className="btn">
          <DoubleLeftOutlined className="label-icon" />
        </span>
        をクリック
      </p>
      <p className="text-content">
        選択した設問のみ追加：
        <span className="btn">
          <LeftOutlined className="label-icon" />
        </span>
        をクリック
      </p>
    </StylesContent>
  );
};

export default QuestionPopup;
