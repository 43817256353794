import React from 'react';
import { Document, Page, View, Text, Font } from '@react-pdf/renderer';

import HanSansk from 'assets/fonts/NotoSansJP-Thin.otf';
import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'HanSansk', src: HanSansk as string });

interface Props {
  data: Array<Types.Positions.ResponseType>;
}

const FileExportInvoicePDF: React.FC<Props> = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.body} wrap>
        <View style={styles.header}>
          <Text style={styles.title}>役職マスタ</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.des}>役職マスタの作成・管理を行う画面です。 </Text>
          <Text style={styles.des}>
            役職マスタは社内ユーザーの役職として社内ユーザーマスタに設定します。
          </Text>
          <View style={styles.line} />
          <Text style={styles.length}>34 件表示 / 34 件中</Text>
          <View style={styles.table}>
            <View style={[styles.headerTable, styles.row]} fixed>
              <View style={styles.column}>
                <Text style={[styles.textTable, styles.bold]}>序列順位</Text>
              </View>
              <View style={styles.column}>
                <Text style={[styles.textTable, styles.bold]}>コード</Text>
              </View>
              <View style={[styles.column, styles.lastColumn]}>
                <Text style={[styles.textTable, styles.bold]}>名称</Text>
              </View>
            </View>
            {data.map((e, index) => (
              <View style={[styles.row]} break={(index + 1) % 18 === 0}>
                <View style={styles.column}>
                  <Text style={styles.textTable}>{e?.rank_order}</Text>
                </View>
                <View style={styles.column}>
                  <Text style={styles.textTable}>{e?.code}</Text>
                </View>
                <View style={[styles.column, styles.lastColumn]}>
                  <Text style={styles.textTable}>{e?.name}</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FileExportInvoicePDF;
