import { createAsyncThunk } from '@reduxjs/toolkit';
import { find, groupBy, maxBy } from 'lodash';

import { sharedFileInMinIO } from 'services/minioService';
import { services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  ATTACH,
  QUESTIONS,
  QUESTION_TRANS2,
  SELECT_DEPARTMENT,
  SELECT_SKILL_CHECK,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_TYPES,
  SELECT_USERS,
  SKILL_CHECK,
  SKILL_CHECK_ASSIGN_QUESTION,
  SKILL_CHECK_IMPLE_STATUS,
  SKILL_CHECK_TRANS2,
  USER_ASSIGN_SKILL_CHECK,
} from 'configs';

export const getDataSelectSkillCheckName = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getReportSearchConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckGroupingCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckGroupingCode.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckSelection/thunk/getDataSelectSkillCheckGroupingCode',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.SelectSkillCheckGroupingCode.ResponseType>(
        SELECT_SKILL_CHECK_GROUPING_CODE.name,
        req
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectUsers.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectUsers.ResponseType>(SELECT_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getSelectDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.SelectTypes.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SelectTypes.ResponseType>(SELECT_TYPES.name, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataItem = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignSkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getDataItem', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
      USER_ASSIGN_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckConvert = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getDataSkillCheckConvert', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataListSkillCheckImple = createAsyncThunk<
  Types.GetItemResponseType<Types.ListSkillCheckTypes>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckSelection/thunk/getDataListSkillCheckImple',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
        USER_ASSIGN_SKILL_CHECK.name,
        req
      );

      const { authContainer } = getState() as RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getDataSkillCheckConvert({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      let resultArray: Array<Types.ListSkillCheckTypes> = [];

      if (getDataSkillCheckConvert.fulfilled.match(responseStatus[0])) {
        const dataSkillCheckConvert = responseStatus[0].payload.items;
        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const matchedSkillCheckItems = dataSkillCheckConvert.filter(
              (c) => c.code === item.skill_check_code
            );
            return matchedSkillCheckItems.flatMap((matchSkillCheck) => ({
              i_id: item.i_id,
              check_time_limit: matchSkillCheck.time_limit || '',
              company_id: item.company_id,
              end_period: matchSkillCheck.end_period,
              fileID: matchSkillCheck.fileID,
              grouping_code: matchSkillCheck.grouping_code,
              implementation_settings: Number(matchSkillCheck.implementation_settings),
              implementation_status: Number(item.implementation_status),
              login_id: item.login_id,
              question_count: Number(matchSkillCheck.probs_count),
              question_time_limit: `${matchSkillCheck.question_time_limit}秒`,
              skill_check_code: matchSkillCheck.code,
              skill_check_description: matchSkillCheck.description,
              skill_check_name: matchSkillCheck.name,
              start_period: matchSkillCheck.start_period,
              user_type: matchSkillCheck.user_type,
            }));
          })
        );
      }
      return {
        ...data,
        items: [...resultArray],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSortOrder = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getDataSortOrder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckAssignQuestion.ResponseType>(
      SKILL_CHECK_ASSIGN_QUESTION.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckSelection/thunk/getDataQuestion',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as RootState;
      const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
        USER_ASSIGN_SKILL_CHECK.name,
        req
      );

      const responseStatus1 = await Promise.all([
        dispatch(
          getDataSkillCheckConvert({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSortOrder({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      let resultArray: Array<any> = [];

      if (
        getDataSkillCheckConvert.fulfilled.match(responseStatus1[0]) &&
        getDataSortOrder.fulfilled.match(responseStatus1[1]) &&
        getDataQues.fulfilled.match(responseStatus1[2])
      ) {
        const dataSkillCheckConvert = responseStatus1[0].payload.items;
        const dataSkillCheckQues = responseStatus1[1].payload.items;
        const dataQues = responseStatus1[2].payload.items;
        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const matchedSkillCheckItems = dataSkillCheckConvert.filter(
              (c) => c.code === item.skill_check_code
            );
            return matchedSkillCheckItems.flatMap((matchSkillCheck) => {
              const mergedObject1 = {
                i_id: item.i_id,
                company_id: item.company_id,
                login_id: item.login_id,
                skill_check_code: matchSkillCheck.code,
                skill_check_description: matchSkillCheck.description,
                skill_check_name: matchSkillCheck.name,
                publish: Number(matchSkillCheck.publish),
                grouping_code: matchSkillCheck.grouping_code,
                implementation_settings: Number(matchSkillCheck.implementation_settings),
                start_period: matchSkillCheck.start_period,
                end_period: matchSkillCheck.end_period,
                check_time_limit: Number(matchSkillCheck.question_time_limit),
                user_type: matchSkillCheck.user_type,
                fileID: matchSkillCheck.fileID,
                implementation_status: Number(item.implementation_status),
                question_count: Number(matchSkillCheck.probs_count),
                question_time_limit: matchSkillCheck.time_limit,
                use_status: Number(matchSkillCheck.use_status),
                answer_d: Number(matchSkillCheck.problems4),
              };
              const matchedSkillCheckQuestion = dataSkillCheckQues.filter(
                (matchQue) => matchQue.skill_check_code === matchSkillCheck.code
              );

              if (!matchedSkillCheckQuestion.length) {
                return mergedObject1;
              }
              return matchedSkillCheckQuestion.flatMap((queAssign) => {
                const mergedObject2 = {
                  ...mergedObject1,
                  question_code: queAssign.question_code,
                  sort_order: Number(queAssign.sort_order),
                };

                const matchQues = dataQues.filter((que) => que.code === queAssign.question_code);

                if (!matchQues.length) {
                  return mergedObject2;
                }

                return matchQues.flatMap((i) => ({
                  ...mergedObject2,
                  question_name: i.name,
                  question_description: i.description,
                  question_attach_fileID1: i.attach_fileID1,
                  question_attach_fileID2: i.attach_fileID2,
                  question_attach_fileID3: i.attach_fileID3,
                  problems1: i.problems1,
                  problems1_attach_fileID: i.problems1_attach_fileID,
                  problems2: i.problems2,
                  problems2_attach_fileID: i.problems2_attach_fileID,
                  problems3: i.problems3,
                  problems3_attach_fileID: i.problems3_attach_fileID,
                  problems4: i.problems4,
                  answer: i.answer,
                  score: Number(i.score),
                }));
              });
            });
          })
        );
      }
      const dataConvert = await Promise.all(
        resultArray.map(async (val) => {
          const obj = val;

          if (
            val.question_attach_fileID1 ||
            val.question_attach_fileID2 ||
            val.question_attach_fileID3
          ) {
            const question_attach_file = await Promise.all(
              [
                val.question_attach_fileID1,
                val.question_attach_fileID2,
                val.question_attach_fileID3,
              ].map(async (e) => {
                if (!e) return;
                const nodeFileUrl = await sharedFileInMinIO(e);

                return nodeFileUrl;
              })
            );

            Object.assign(obj, {
              ...val,
              question_attach_file: question_attach_file.filter(Boolean),
            });
          }

          if (val.problems1_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems1_attach_fileID);
            Object.assign(obj, {
              problems1_attach_file: nodeFileUrl,
            });
          }

          if (val.problems2_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems2_attach_fileID);
            Object.assign(obj, {
              problems2_attach_file: nodeFileUrl,
            });
          }
          if (val.problems3_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems3_attach_fileID);
            Object.assign(obj, {
              problems3_attach_file: nodeFileUrl,
            });
          }

          return obj;
        })
      );
      return { ...data, items: dataConvert.sort((a, b) => a.sort_order - b.sort_order) };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAttachQuestionFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getAttachQuestionFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createSkillCheckTrans = createAsyncThunk<
  Types.CreateItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.CreateItemRequestType<Types.SkillCheckTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/createSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(SKILL_CHECK_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editSkillCheckTrans = createAsyncThunk<
  Types.UpdateItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.UpdateItemRequestType<Types.SkillCheckTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/editSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SKILL_CHECK_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const editUserAssignSillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/editUserAssignSillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getImplementTable = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/getImplementTable', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckTrans2.ResponseType>(
      SKILL_CHECK_TRANS2.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTable = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImpleStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckSelection/thunk/getDataTable',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as RootState;

      const { data } = await services.filter<Types.SkillCheckImpleStatus.ResponseType>(
        SKILL_CHECK_IMPLE_STATUS.id,
        req
      );

      const responseStatus = await dispatch(
        getImplementTable({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          sort_fields: [
            {
              id: 'implementation_date',
              order: 'desc',
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );

      let dataSkillCheckAddDate: Types.SkillCheckImpleStatus.ResponseType[] = [];

      if (getImplementTable.fulfilled.match(responseStatus)) {
        const groupedData = groupBy(responseStatus.payload.items, 'skill_check_code');

        const groupedDataArray = Object.values(groupedData);

        const latestSkillCheckCodesArray = groupedDataArray.map((group) =>
          maxBy(group, (item) => new Date(item.implementation_date))
        );

        dataSkillCheckAddDate = data.report_results.map(
          (item: Types.SkillCheckImpleStatus.ResponseType) => {
            const matchingItem = find(latestSkillCheckCodesArray, {
              skill_check_code: item.skill_check_code,
              login_id: item.login_id,
            });
            if (matchingItem && item) {
              return {
                ...item,
                implementation_date: matchingItem.implementation_date,
                i_id_skillcheck_tid: matchingItem.i_id,
              };
            }
            return item;
          }
        );
      }

      return {
        ...data,
        report_results: [...dataSkillCheckAddDate],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSkillCheckTrans = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType & { id: String },
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(SKILL_CHECK_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestionTrans = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(QUESTION_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestionTrans = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckSelection/thunk/createQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
