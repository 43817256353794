import React, { useState } from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import dayjs from 'dayjs';

import PopupConfirmReceiptExport from '../ConfirmExportPDF';
import { authSelector } from 'containers/Auth/selectors';
import { formatNumber } from 'libs/utils/format';
import FileExportPDF from '../FileExportPDF';
import { UsageDetailStyled } from './styles';
import { LogoRStandard } from 'assets';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  total: number;
  visible: boolean;
  dataUserFeeDetail: Types.UserFeeDetail.DataConverted[];
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceiptExport: React.FC<Props> = ({ visible, setVisible, total, dataUserFeeDetail }) => {
  const [visiblePopupConfirmReceiptExport, setVisiblePopupConfirmReceiptExport] =
    useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  return (
    <Modal
      title="領収書"
      width={860}
      visible={visible}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <UsageDetailStyled>
        <div className="wrap-header">
          <div className="time-label">
            <span className="label">サービス利用年月：</span>
            <span className="time-text">2021年9月</span>
          </div>
          <Button
            className="button-export"
            onClick={() => setVisiblePopupConfirmReceiptExport(true)}
          >
            <CloudDownloadOutlined className="download-icon" />
            エクスポート
          </Button>
        </div>
        <div className="container">
          <div className="sub-container">
            <div className="receipt-info">
              <p className="title">領収書</p>
              <div className="info">
                <span>領収書No.：100021</span>
                <br />
                <span>
                  発行日：
                  {dayjs(dataUserFeeDetail[0]?.paid_date).format('YYYY年MM月DD日')}
                </span>
              </div>
            </div>
            <div className="sent">
              <p>{userInfo?.name}御中</p>
            </div>
            <div className="amount-money">
              <p className="label">金額</p>
              <p className="label-number">
                ￥
                {formatNumber(
                  dataUserFeeDetail[0]?.user_fee +
                    total +
                    ((dataUserFeeDetail[0]?.user_fee + total) * 10) / 100
                ).toLocaleString()}
                -
              </p>
              <p className="label">（税込）</p>
            </div>
            <div className="footer">
              <div className="left-side">
                <p className="text-label">
                  但し SKILL FAMILIAR サービスご利用分として
                  <br />
                  上記正に領収いたしました
                </p>
                <p className="payment-method">
                  お支払い方法：{dataUserFeeDetail[0]?.payment_method_name}
                </p>
                <div className="row">
                  <p className="label">内訳</p>
                </div>
                <div className="row">
                  <p className="label">税抜金額：</p>
                  <p className="value">¥{formatNumber(+dataUserFeeDetail[0]?.user_fee + total)}-</p>
                </div>
                <div className="row">
                  <p className="label">消費税額等：</p>
                  <p className="value">
                    ¥{formatNumber(((dataUserFeeDetail[0]?.user_fee + total) * 10) / 100)}-
                  </p>
                </div>
              </div>
              <div className="right-side">
                <p>
                  株式会社RSTANDARD
                  <br />
                  〒110-0005
                  <br />
                  東京都台東区上野3-16-4 文行同ビル 5F
                </p>
                <img src={LogoRStandard} className="logo" alt="logo" />
              </div>
            </div>
          </div>
        </div>
        <PopupConfirmReceiptExport
          visible={visiblePopupConfirmReceiptExport}
          setVisible={setVisiblePopupConfirmReceiptExport}
          FilePdf={
            <FileExportPDF
              dataUserFeeDetail={dataUserFeeDetail}
              total={total}
              companyName={userInfo?.name}
            />
          }
        />
      </UsageDetailStyled>
    </Modal>
  );
};

export default ReceiptExport;
