import styled from 'styled-components';

export default styled.div`
  position: relative;
  .legend {
    display: flex;
    align-items: center;
    right: 20px;
    .sort-column {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .legend-filter {
        width: 14px;
        height: 14px;
      }
      .blue {
        background: #379bd2;
      }
      .pink {
        background: #ea8c92;
      }
      .gray {
        background: #ebebeb;
      }
      .legend-name {
        font-size: 12px;
        color: #424242;
        margin: 0 5px;
      }
      .btn-sort {
        width: 19px;
        height: 19px;
        border-radius: 4px;
        padding: 0;
        .wrap-sort {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .icon-caret {
            text-align: center;
            font-size: 9px;
            color: #bfbfbf;
          }
          .active {
            color: #424242;
          }
        }
      }
    }
  }
  .container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .wrapper-header {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      padding: 15px 0px;
      background: #fff;
    }

    .header {
      width: 100%;
      padding: 15px;
      background: #ebebeb;
      border: 1px solid #dddddd;
      border-radius: 2px 2px 0 0;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #424242;
      }
    }

    .item {
      position: relative;
      width: 25%;
      text-align: center;
      border: 1px solid #dddddd;
      .number-order {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
      }
      .question-total {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        border-left: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        background: #f9f9f9;
        width: 50px;
        height: 50px;
        top: 0;
        right: 0;
        .label {
          font-size: 11px;
          color: #777777;
        }
        .quantity {
          font-size: 15px;
          color: #2a2a2a;
        }
      }
      .pie {
        padding: 25px 0;
        .wrap-empty-pie {
          width: 100%;
          padding: 20px;
          .empty-pie {
            height: 100%;
          }
        }
      }
      .footer-item {
        display: flex;
        width: 100%;
        border-top: 1px solid #eeeeee;
        .total-answer-time,
        .answer-time,
        .average-answer-time {
          width: 50%;
          padding: 5px;
          .title {
            font-size: 12px;
            color: #777777;
            margin: 0;
          }
          .time {
            font-size: 18px;
            color: #424242;
            margin: 0;
          }
        }
        .total-answer-time,
        .answer-time {
          border-right: 1px solid #eeeeee;
        }
      }
      .skill-check-all {
        .total-answer-time,
        .answer-time,
        .average-answer-time,
        .setting-score {
          padding: 0px;
          .title {
            font-size: 11px;
            color: #777777;
            margin: 0;
          }
          .time {
            font-size: 16px;
            color: #424242;
            margin: 0;
          }
        }
        .total-answer-time,
        .answer-time,
        .answer-time-full,
        .average-answer-time,
        .setting-score {
          width: 50%;
        }
      }
      .border-right {
        border-right: 1px solid #eeeeee;
      }
    }
    .g2-html-annotation {
      top: 100px !important;
    }
  }
`;
