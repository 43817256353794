import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  USERS,
  POSITIONS,
  SELECT_USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  AFFILIATION_LEVEL,
  AFFILIATION_ASSIGN_ROLE,
} from 'configs';

export const getDataAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDataAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType & { position_code?: string; affiliation?: string },
  Types.ThunkAPI<Types.requestError>
>(
  'menuRightUser/thunk/getDataSearchUsers',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { authContainer } = getState() as Types.RootState;

      const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);
      console.log('req', req);

      const responseStatus = await dispatch(
        getDataAffiliationAssignRole({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            ...(req.affiliation
              ? [
                  {
                    id: 'affiliation_id',
                    search_value: [req.affiliation],
                    exact_match: true,
                  },
                ]
              : []),
            ...(req.position_code
              ? [
                  {
                    id: 'positions_code',
                    search_value: [req.position_code],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      let resultArray: Array<Types.Users.ResponseType> = [];

      if (getDataAffiliationAssignRole.fulfilled.match(responseStatus)) {
        const dataAffiliationAssignRole = responseStatus.payload.items;
        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const matchedUserItems = dataAffiliationAssignRole.filter(
              (c) => c.login_id === item.login_id && c.main_role === 'main'
            );
            return matchedUserItems.flatMap((matchedItems) => ({
              ...item,
            }));
          })
        );
      }

      return {
        ...data,
        items: [...resultArray],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSelectUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectUsers.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getSelectUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectUsers.ResponseType>(SELECT_USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPositionUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getPositionUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDepartmentUser = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/getDepartmentUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationLevel.ResponseType>(
      AFFILIATION_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('menuRightUser/thunk/createUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_REQUIRED_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
