import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BarChartOutlined, CloudDownloadOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Select, Radio } from 'antd';
import { useSelector } from 'react-redux';
import { filter, find } from 'lodash';
import dayjs from 'dayjs';

import MultipleComparisonSkillcheck from './MultipleComparisonSkillcheck';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { skillCheckReportPageSelector } from '../../selectors';
import MultipleComparison from './MultipleComparison';
import exportPDF from 'libs/utils/exportPdf';
import { resetInitialState } from './slice';
import FilePDFExport from './FilePDFExport';
import { DatePicker } from 'components';
import { UserType } from 'types/config';
import { useAppDispatch } from 'hooks';
import Comparison from './Comparison';
import Wrapper from './styles';
import {
  ITEM_COMPARISION_USER_ANALYSIS_CHART,
  ITEM_COMPARISION_USER_ANALYSIS_TABLE,
  REPORT_TYPE,
} from 'constant/select.constants';

const { Option } = Select;

interface Props {
  reportType: (typeof REPORT_TYPE)[number]['value'];
  userType: UserType;
}

const UserAnalysisReport: React.FC<Props> = ({ reportType, userType }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [secondSkillcheck, setSecondSkillcheck] = useState<string>();
  const [firstSkillcheck, setFirstSkillcheck] = useState<string>();
  const [compareType, setCompareType] = useState<number>(0);
  const [startPeriod, setStartPeriod] = useState<string>();
  const [optionType, setOptionType] = useState<number>(0);
  const [segmented, setSegmented] = useState<number>(0);
  const [endPeriod, setEndPeriod] = useState<string>();
  const [groupCode, setGroupCode] = useState<string>();
  const [loginID, setLoginID] = useState<string>();
  const [itemComparision, setItemComparision] =
    useState<
      (
        | typeof ITEM_COMPARISION_USER_ANALYSIS_TABLE
        | typeof ITEM_COMPARISION_USER_ANALYSIS_CHART
      )[number]['value']
    >('accuracy_rate');

  const ref = useRef(null);

  const { selectAnalysisGroup, selectEmployeeUser, selectInterviewUser, selectSkillCheck } =
    useSelector(skillCheckReportPageSelector);

  const skillcheckByGroup = useMemo(() => {
    if (!groupCode) return [];

    const groupNameSelect = find(selectAnalysisGroup, { group_code: groupCode })?.group_name;

    if (!groupNameSelect) return [];

    return filter(selectSkillCheck, { group_code: groupNameSelect });
  }, [groupCode, selectSkillCheck, selectAnalysisGroup]);

  const dispatch = useAppDispatch();

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          selectAnalysisGroup={selectAnalysisGroup}
          selectInterviewUser={selectInterviewUser}
          selectEmployeeUser={selectEmployeeUser}
          skillcheckByGroup={skillcheckByGroup}
          secondSkillcheck={secondSkillcheck}
          firstSkillcheck={firstSkillcheck}
          itemComparision={itemComparision}
          compareType={compareType}
          startPeriod={startPeriod}
          reportType={reportType}
          optionType={optionType}
          groupCode={groupCode}
          segmented={segmented}
          endPeriod={endPeriod}
          userType={userType}
          loginID={loginID}
        />
      </div>
    );
  }, [
    selectInterviewUser,
    selectAnalysisGroup,
    selectEmployeeUser,
    skillcheckByGroup,
    secondSkillcheck,
    firstSkillcheck,
    itemComparision,
    compareType,
    startPeriod,
    optionType,
    reportType,
    endPeriod,
    groupCode,
    segmented,
    userType,
    loginID,
  ]);

  const handleExportCSV = (value: string) => {
    dispatch(startLoading());
    if (value === 'pdf') {
      if (!ref.current) return;

      exportPDF(ref, 'スキルチェックレポート.pdf');
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    setGroupCode(undefined);
    setLoginID(undefined);
    dispatch(resetInitialState());
    setSegmented(0);
    return () => {
      setGroupCode(undefined);
      setLoginID(undefined);
      dispatch(resetInitialState());
      setSegmented(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <Wrapper>
      {component}
      <div className="header-container">
        <span className="title">
          {!compareType
            ? 'スキルチェック【社内】の実施結果を、ユーザーと分析グループを基準に集計したレポートです。'
            : 'スキルチェック【社内】の実施結果を、ユーザと分析グループを基準に集計したレポートです。'}
        </span>
        <Button
          className="button-export"
          onClick={() => setShowConfirmExportFileModal(true)}
          icon={<CloudDownloadOutlined />}
        >
          エクスポート
        </Button>
      </div>
      <div className="wrap-filter">
        <span className="label">集計条件</span>
        <div className="aggregation-conditions">
          <div className="form-select">
            <div className="wrap-select">
              <div className="item">
                <span className="text-label">ユーザー</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  value={loginID}
                  allowClear
                  onChange={(value: string) => {
                    if (value === 'ALL' && groupCode === 'ALL') {
                      setGroupCode(undefined);
                    }
                    setLoginID(value);
                  }}
                >
                  <Option disabled={groupCode === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                    (item, index) => (
                      <Option
                        key={index}
                        value={item.login_id}
                        disabled={(groupCode && groupCode !== 'ALL') || !compareType}
                      >
                        {item.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">分析グループ（成長比較）</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  value={groupCode}
                  allowClear
                  onChange={(value: string) => {
                    if (value === 'ALL' && loginID === 'ALL') {
                      setLoginID(undefined);
                    }
                    setSecondSkillcheck(undefined);
                    setFirstSkillcheck(undefined);
                    setGroupCode(value);
                  }}
                >
                  <Option disabled={loginID === 'ALL' || !compareType || segmented} value="ALL">
                    ALL
                  </Option>
                  {selectAnalysisGroup.map((item, index) => (
                    <Option
                      key={index}
                      value={item.group_code}
                      disabled={loginID && loginID !== 'ALL'}
                    >
                      {item.group_name}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="wrap-time-select">
              <div className="radio-item item-before">
                <div className="three-border" />
                <Radio.Group
                  className="radio-select"
                  onChange={(e) => {
                    if (!e.target.value && groupCode === 'ALL') {
                      setGroupCode(undefined);
                      setLoginID(undefined);
                    }
                    setCompareType(e.target.value);
                  }}
                  value={compareType}
                >
                  <Radio value={0}>２期間比較</Radio>
                  <Radio value={1}>推移比較</Radio>
                </Radio.Group>
              </div>
              {!compareType && (
                <div className="radio-item">
                  <div className="three-border" />
                  <Radio.Group
                    className="radio-select"
                    onChange={(e) => {
                      setOptionType(e.target.value);
                    }}
                    value={optionType}
                  >
                    <Radio value={0}>基準日</Radio>
                    <Radio value={1}>スキルチェック名</Radio>
                  </Radio.Group>
                </div>
              )}
              {!optionType && !compareType ? (
                <>
                  <div className="item">
                    <span className="text-label">基準日①</span>
                    <DatePicker
                      allowClear
                      name="date-item"
                      className="date-input"
                      format="YYYY/MM/DD"
                      value={startPeriod ? dayjs(startPeriod) : undefined}
                      disabledDate={(current) =>
                        current && current > dayjs(endPeriod, 'YYYY/MM/DD')
                      }
                      onChange={(value) => setStartPeriod(value?.format('YYYY/MM/DD'))}
                    />
                  </div>
                  <div className="item">
                    <span className="text-label">基準日②</span>
                    <DatePicker
                      allowClear
                      name="date-item"
                      className="date-input"
                      format="YYYY/MM/DD"
                      value={endPeriod ? dayjs(endPeriod) : undefined}
                      disabledDate={(current) =>
                        current && current < dayjs(startPeriod, 'YYYY/MM/DD')
                      }
                      onChange={(value) => setEndPeriod(value?.format('YYYY/MM/DD'))}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="item">
                    <span className="text-label">スキルチェック①</span>
                    <Select
                      className="select"
                      placeholder="スキルチェック"
                      value={firstSkillcheck}
                      allowClear
                      onChange={(value: string) => {
                        setFirstSkillcheck(value);
                      }}
                    >
                      {skillcheckByGroup.map((item, index) => (
                        <Option
                          key={index}
                          value={item.code}
                          disabled={item.code === secondSkillcheck}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="item">
                    <span className="text-label">スキルチェック②</span>
                    <Select
                      className="select"
                      placeholder="スキルチェック"
                      value={secondSkillcheck}
                      allowClear
                      onChange={(value: string) => {
                        setSecondSkillcheck(value);
                      }}
                    >
                      {skillcheckByGroup.map((item, index) => (
                        <Option
                          key={index}
                          value={item.code}
                          disabled={item.code === firstSkillcheck}
                        >
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {segmented ? (
        <p className="note-segmented">
          ※選択したユーザー・スキルチェックの設問別実施結果が表示されています。
        </p>
      ) : null}
      <div className="sub-container">
        <div className="wrap-segmented">
          <div className="left-side">
            <span className="label">レポートタイプ：</span>
            <div className="segmented">
              <div
                className={`segmented-item${!segmented ? ' segmented-item-selected' : ''}`}
                onClick={() => {
                  setSegmented(0);
                  setItemComparision('accuracy_rate');
                }}
              >
                <TableOutlined className="icon" />
                <span>表</span>
              </div>
              <button
                className={`segmented-item${segmented ? ' segmented-item-selected' : ''}`}
                disabled={compareType === 1 && groupCode === 'ALL'}
                onClick={() => {
                  setSegmented(1);
                  setItemComparision('accuracy_rate');
                }}
              >
                <BarChartOutlined className="icon" />
                <span>グラフ</span>
              </button>
            </div>
            {compareType ? (
              <>
                <span className="cross-boiled">/</span>
                <span className="label">比較項目：</span>
                <div className="item">
                  <Select
                    className="select"
                    placeholder="---"
                    onSelect={setItemComparision}
                    value={itemComparision}
                  >
                    {(!segmented
                      ? ITEM_COMPARISION_USER_ANALYSIS_TABLE
                      : ITEM_COMPARISION_USER_ANALYSIS_CHART
                    ).map(({ label, value }, index) => (
                      <Option key={index} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : null}
          </div>
        </div>
        {!compareType ? (
          <>
            {!optionType ? (
              <MultipleComparison
                segmented={segmented}
                userType={userType}
                startPeriod={startPeriod}
                endPeriod={endPeriod}
                groupCode={groupCode}
                loginID={loginID}
              />
            ) : (
              <MultipleComparisonSkillcheck
                segmented={segmented}
                userType={userType}
                firstSkillcheck={firstSkillcheck}
                secondSkillcheck={secondSkillcheck}
                groupCode={groupCode}
                loginID={loginID}
              />
            )}
          </>
        ) : (
          <Comparison
            segmented={segmented}
            itemComparision={itemComparision}
            userType={userType}
            startPeriod={startPeriod}
            endPeriod={endPeriod}
            groupCode={groupCode}
            loginID={loginID}
          />
        )}
      </div>

      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </Wrapper>
  );
};

export default UserAnalysisReport;
