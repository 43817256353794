import React, { useState } from 'react';
import { CheckCircleOutlined, MailOutlined } from '@ant-design/icons';
import { generatePath, Link, useLocation } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { resetPasswordSchema } from 'libs/validations';
import { resetPassword } from 'containers/Auth/thunk';
import AuthLayout from 'containers/Auth/Layout';
import { routes } from 'navigations/routes';
import { ResetPasswordFormik } from 'types';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { Input } from 'components';
import { Centering } from 'assets';
import { config } from 'configs';

const ResetPassword: React.FC = () => {
  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const { messages } = useIntl();

  const formik = useFormik<ResetPasswordFormik>({
    initialValues: {
      email: '',
    },
    validationSchema: resetPasswordSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(startLoading());
      const resultAction = await dispatch(
        resetPassword({
          email: values.email,
          host: window.location.protocol + '//' + window.location.host,
          root_path: location.pathname.replace('/', ''),
          email_templates_id: config.TEMPLATES_ID,
        })
      );
      if (resetPassword.fulfilled.match(resultAction) && resultAction.payload.valid_email) {
        setIsSuccessfully(true);
      }
      dispatch(stopLoading());
    },
  });

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={Centering} alt="logo" className="logo" />
        <p className="title">{messages['P-01-1']}</p>
        {isSuccessfully ? (
          <>
            <CheckCircleOutlined className="check-circle-icon" size={66} />
            <p className="text-content">
              {messages['P-01-6.1']}
              <br />
              {messages['P-01-6.2']}
              <br />
              {messages['P-01-6.3']}
            </p>
            <Link
              className="text-link"
              to={generatePath(routes.Login.path, { entity: 'receiving' })}
            >
              {messages['P-01-7']}
            </Link>
          </>
        ) : (
          <>
            <p className="text-content">
              {messages['P-01-2.1']}
              <br />
              {messages['P-01-2.2']}
            </p>
            <FormikProvider value={formik}>
              <Form className="form">
                <Form.Item name="email">
                  <Input
                    name="email"
                    type="email"
                    placeholder={messages['P-01-3'] as string}
                    className="input"
                    prefix={<MailOutlined className="icon" />}
                  />
                </Form.Item>
                <SubmitButton className="btn-submit">{messages['P-01-4']}</SubmitButton>
              </Form>
            </FormikProvider>
            <p className="text-content-error">{messages['P-01-5']}</p>
            <Link
              className="text-link"
              to={generatePath(routes.Login.path, { entity: 'receiving' })}
            >
              {messages['back']}
            </Link>
          </>
        )}
      </SectionStyled>
    </AuthLayout>
  );
};

export default ResetPassword;
