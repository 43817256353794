import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F9F8F8',
    fontFamily: 'NotosanJP',
  },

  header: {
    padding: 11.7,
    paddingBottom: 12,
    borderBottom: '1px solid #dddddd',
  },

  textHeader: {
    fontSize: 10.5,
    color: '#424242',
  },

  body: {
    paddingHorizontal: 74,
    paddingVertical: 23.4,
    flexDirection: 'column',
    gap: 17.54,
  },

  wrapCard: {
    borderRadius: 2.894,
    backgroundColor: '#FFFF',
    boxShadow: '0px 1.169px 5.847px 1.169px rgba(0, 0, 0, 0.05)',
  },

  headerCard: {
    paddingVertical: 9.94,
    paddingHorizontal: 12,
    borderBottom: '1px solid #ccc',
  },

  textHeaderCard: {
    fontSize: 9.356,
    color: '#2a2a2a',
  },

  wrapContentCard: {
    width: '100%',
    padding: 23.39,
  },

  wrapBasicInfo: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: 23.39,
  },

  wrapInput: {
    width: '100%',
  },

  wrapLabel: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },

  textLabel: {
    fontSize: 7.6,
    color: '#424242',
  },

  require: {
    fontSize: 11.694,
    color: '#f0330a',
    textAlign: 'right',
  },

  input: {
    borderRadius: 2,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0px 0px 3.51px 0.85px rgba(0, 0, 0, 0.01) inset',
    paddingVertical: 6.45,
    paddingHorizontal: 7.04,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxLines: 1,
  },

  value: {
    fontSize: 8.186,
    color: '#2a2a2a',
  },

  wrapAvatar: {
    width: '15%',
  },

  avatar: {
    width: 105,
    height: 105,
    borderRadius: 50,
    backgroundColor: '#F0EFEF',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  textUser: {
    fontSize: 6.432,
    color: '#999',
  },

  avatarImage: {
    width: '100%',
    height: '100%',
    borderRadius: 50,
  },

  formBasicInfo: {
    width: '40%',
    flexDirection: 'column',
    gap: 14.3,
  },

  inputMail: {
    backgroundColor: '#f9f8f8',
  },

  wrapDate: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  textDate: {
    fontSize: 7.6,
    color: '#424242',
    marginRight: 6.62,
    marginLeft: 1.45,
  },

  textDescription: {
    fontSize: 8.2,
    color: '#424242',
    marginBottom: 18.7,
  },
  textDescriptionResults: {
    fontSize: 8.2,
    color: '#424242',
  },

  wrapPosition: {
    flexDirection: 'column',
    gap: 11.7,
    paddingTop: 9.36,
    paddingLeft: 11.7,
    paddingBottom: 11.7,
    paddingRight: 42,
    marginBottom: 25,
    backgroundColor: '#f2f2f2',
    borderRadius: 2.339,
    border: '1px solid #eaeaea',
  },

  position: {
    flexDirection: 'row',
    gap: 7,
  },

  wrapResults: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
  },

  wrapLabelResults: {
    width: '20%',
    paddingVertical: 7.2,
    paddingHorizontal: 3,
    border: '1px solid #ccc',
    borderBottom: 'none',
    borderRadius: 2,
    borderBottomRightRadius: 0,
  },

  textLabelResults: {
    fontSize: 7,
    color: '#424242',
  },

  result: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 2,
    paddingVertical: 8,
    paddingHorizontal: 8,
    border: '1px solid #d9d9d9',
  },

  textCountResult: {
    fontSize: 7.6,
    color: '#999',
    textAlign: 'center',
  },

  textResult: {
    fontSize: 8.2,
    color: '#2a2a2a',
  },
});
