import styled from 'styled-components';

export default styled.section`
  .table-result {
    margin-bottom: 10px;
    .item-table {
      .table-label {
        font-weight: 700;
        font-size: 13px;
        color: #424242;
        margin: 0;
      }
      .row-label {
        height: 55px;
        background-color: #ebebeb;
        border-radius: 2px;
        font-weight: 700;
        font-size: 12px;
        color: #424242;
        .gutter-row {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(85, 85, 85, 0.08);
        }
      }
      .row-number {
        height: 60px;
        background-color: #ffffff;
        border-radius: 2px;
        font-family: Lato;
        font-size: 20px;
        color: #2a2a2a;
        border: 1px solid rgba(85, 85, 85, 0.08);
        .gutter-row {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
          border-right: 1px solid rgba(85, 85, 85, 0.08);
        }
      }
      .item-column {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 20px;
        color: #2a2a2a;
        .label-number {
          margin: 0;
        }
        .label {
          margin: 0;
        }
        .icon-up {
          color: #1788c7;
          margin-right: 10px;
        }
        .icon-down {
          color: #ea3596;
          margin-right: 10px;
        }
      }
    }
  }

  .table-header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #424242;

    .ant-pagination-total-text {
      .text-total-record-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .ant-pagination {
      .ant-pagination-options {
        position: absolute;
        right: 150px;

        ::before {
          content: '表示件数：';
          font-size: 11px;
          padding-right: 3px;
        }
      }
    }
  }

  .ant-pagination {
    .ant-pagination-total-text {
      position: absolute;
      right: 7px;
      left: auto !important;

      .text-total-record-table {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }

    .ant-pagination-options {
      position: absolute;
      right: 150px;

      ::before {
        content: '表示件数：';
        font-size: 11px;
        padding-right: 3px;
      }
    }
  }
  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .table-row-light {
      background: #ffffff;
    }
    .table-row-dark {
      background: #f9f9f9;
    }
    .ant-table-tbody .ant-table-cell.acquisition_score {
      font-family: Lato;
      font-size: 20px;
      text-align: center;
      color: #2a2a2a;
    }
    .ant-table-tbody .ant-table-cell {
      font-family: Lato;
    }
    .ant-table-tbody .ant-table-cell.question {
      font-size: 14px;
      text-align: left;
      color: #424242;
    }
    .ant-table-thead .ant-table-cell {
      font-weight: 700;
      font-size: 12px;
      color: #424242;
    }

    .ant-table-cell {
      text-align: center;
    }
    .item-rate {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: Lato;
      font-size: 14px;
      color: #2a2a2a;
      .label-number {
        margin: 0;
      }
      .label {
        margin: 0;
      }
    }
    .icon {
      color: #c4c4c4;
      font-size: 18px;
      cursor: pointer;
    }
  }
`;
