import React from 'react';
import { useIntl } from 'react-intl';

import { SectionStyled } from './styles';

interface Props {
  company_name: string;
  onSubmit: () => void;
  onClose: () => void;
}

const PopupProvision: React.FC<Props> = ({ company_name, onSubmit, onClose }) => {
  const { messages } = useIntl();
  return (
    <SectionStyled
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="popup">
        <h3 className="title">{messages['modal-AJ-02-1']}</h3>
        <div className="content">
          <iframe
            src="https://service.skillfamiliar.com/terms"
            title="iframe"
            className="iframe"
          ></iframe>
        </div>
        <div className="wrap-btn">
          <button className="btn-submit" onClick={onSubmit}>
            {messages['modal-AJ-02-2']}
          </button>
        </div>
      </div>
    </SectionStyled>
  );
};

export default PopupProvision;
