import React from 'react';

import { ITEM_COMPARISION_ANALYSIS } from 'constant/select.constants';
import BreakdownOfAnswers from './BreakdownOfAnswers';
import AverageRate from './AverageRate';

interface Props {
  itemComparision: (typeof ITEM_COMPARISION_ANALYSIS)[number]['value'];
}

const Comparison: React.FC<Props> = ({ itemComparision }) => {
  return itemComparision !== 'answer_breakdown' ? (
    <AverageRate itemComparision={itemComparision} />
  ) : (
    <BreakdownOfAnswers />
  );
};

export default Comparison;
