import styled from 'styled-components';

export default styled.section`
  background-color: #f8f8f8;
  min-height: 100%;
  .tab-button {
    background-color: #ffffff;
    width: 100%;
    padding: 30px;
    .description {
      font-weight: 400;
      font-size: 13px;
      color: #424242;
    }
    .wrap-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right-side {
        display: flex;
        .btn {
          display: flex;
          align-items: center;
          padding: 5px 12px;
          width: 160px;
          cursor: pointer;
          font-size: 12px;
          font-weight: 700;
          border-radius: 5px;
          color: #f1a900;
          margin-left: 8px;
        }
        .btn-active {
          background: #f6ac00;
          border: 1px solid #f6ac00;
          color: #ffffff;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          text-align: start;
          .size-icon {
            font-size: 14px;
            margin-right: 20px;
          }
        }
        .btn-outline {
          background: #ffffff;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          text-align: end;
          .size-icon-right-outline {
            color: #f6ac00;
            font-size: 10px;
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

export const TabStyled = styled.div`
  width: 100%;
  padding: 30px;
  position: relative;
  .wrap-select-record {
    display: flex;
    column-gap: 60px;
    .label-select {
      font-size: 11px;
      color: #424242;
    }
  }
  .select-option {
    width: 66px;
    height: 28px;
    .ant-select-arrow {
      top: 55%;
    }
    .ant-select-selector {
      border-radius: 3px;
    }
  }
  .right-side {
    display: flex;
    position: absolute;
    top: -62px;
    right: 30px;
    z-index: 1;
    .btn {
      display: flex;
      align-items: center;
      padding: 5px 12px;
      width: 160px;
      cursor: pointer;
      font-size: 12px;
      font-weight: 700;
      border-radius: 5px;
      color: #f1a900;
      margin-left: 8px;
    }
    .btn-active {
      background: #f6ac00;
      border: 1px solid #f6ac00;
      color: #ffffff;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      text-align: start;
      .size-icon {
        font-size: 14px;
        margin-right: 20px;
      }
    }
    .btn-outline {
      background: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      text-align: end;
      .size-icon-right-outline {
        color: #f6ac00;
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
  .form-search {
    display: flex;
    align-items: center;
    .item {
      width: 20%;
      padding: 0 5px;
      .ant-picker {
        width: 100%;
      }
      .text-label {
        font-weight: 400;
        font-size: 12px;
        color: #424242;
      }
      .icon {
        margin-right: 5px;
      }
    }
    .half-width {
      width: 12%;
    }
    .wrap-submit-search {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 14%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-left: 12px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .table {
    .text-count {
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
    }
    .text-content {
      font-size: 13px;
      color: #424242;
      margin: 0;
    }
    .text-content-code {
      font-size: 11px;
      color: #999999;
      margin: 0;
    }
    .spanText {
      margin-left: 5px;
      font-size: 12px;
      color: #424242;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
  }
`;

export const TabExportStyled = styled.div`
  width: 100%;
  .header {
    color: #424242;
    font-family: Lato;
    font-size: 18px;
    padding: 20px;
    background: #f9f8f8;
    border-bottom: 1px solid #bbbbbb;
  }
  .tab-button {
    background-color: #ffffff;
    width: 100%;
    padding: 30px;
    .description {
      font-weight: 400;
      font-size: 13px;
      color: #424242;
    }
    .wrap-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .form-search {
    display: flex;
    align-items: center;
    padding: 10px 30px 0 30px;
    background: #f2f2f2;
    .item {
      width: 20%;
      padding: 0 5px;
      .ant-picker {
        width: 100%;
      }
      .text-label {
        font-weight: 400;
        font-size: 12px;
        color: #424242;
      }
      .icon {
        margin-right: 5px;
      }
    }
    .half-width {
      width: 12%;
    }
    .wrap-submit-search {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 14%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-left: 12px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .table {
    padding: 30px;
    background: #f2f2f2;
    .text-count {
      margin-top: 12px;
      font-family: Lato;
      font-size: 14px;
      color: #424242;
      z-index: 9;
      .text-static {
        font-size: 11px;
        padding-left: 3px;
      }
    }
    .text-content {
      font-size: 13px;
      color: #424242;
      margin: 0;
    }
    .text-content-code {
      font-size: 11px;
      color: #999999;
      margin: 0;
    }
    .spanText {
      margin-left: 5px;
      font-size: 12px;
      color: #424242;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
  }
`;

interface ButtonProps {
  tabActive: number;
  index: number;
}

export const Button = styled.button<ButtonProps>`
  position: relative;
  width: 160px;
  height: 32px;
  cursor: pointer;
  font-weight: ${(props) => (props.tabActive === props.index ? 'bold' : 'unset')};
  font-size: 13px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  border-left: none;
  background-color: ${(props) => (props.tabActive === props.index ? '#00a3a5' : '#ffffff')};
  color: ${(props) => (props.tabActive === props.index ? '#ffffff' : '#00a3a5')};
  border: 1px solid #00a3a5;
  .size-icon {
    position: absolute;
    left: 10px;
    top: 30%;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
  &:nth-child(3) {
    border-radius: 0 3px 3px 0;
  }
`;
