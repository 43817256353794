import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { trainingReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';

import WrapperStyled from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  level1Code?: string;
  level2Code?: string;
  level3Code?: string;
  level4Code?: string;
  exportPdf?: boolean;
}

const TableLevels: React.FC<Props> = ({
  level1Code,
  level2Code,
  level3Code,
  level4Code,
  exportPdf,
}) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const { totalReportCurriculumUser, dataReportCurriculumUser } =
    useSelector(trainingReportSelector);

  const dataColumLevels: {
    title: string;
    key: string;
    code: 'level1_code' | 'level2_code' | 'level3_code' | 'level4_code' | 'question_code';
  } = useMemo(() => {
    if (level4Code) {
      return {
        title: '設問',
        key: 'question_name',
        code: 'question_code',
      };
    }

    if (level3Code) {
      return {
        title: '第4階層',
        key: 'level4_name',
        code: 'level4_code',
      };
    }

    if (level2Code) {
      return {
        title: '第3階層',
        key: 'level3_name',
        code: 'level3_code',
      };
    }

    if (level1Code) {
      return {
        title: '第2階層',
        key: 'level2_name',
        code: 'level2_code',
      };
    }

    return {
      title: '第1階層',
      key: 'level1_name',
      code: 'level1_code',
    };
  }, [level1Code, level2Code, level3Code, level4Code]);

  const columns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> = [
    {
      title: 'No.',
      dataIndex: '',
      key: '',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {index + 1 + (page - 1) * 10}
        </span>
      ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'index',
              align: 'center',
              width: '3%',
              render: (_text, _record, index) => (
                <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
                  {index + 1 + (page - 1) * 10}
                </span>
              ),
            },
          ]
        : undefined,
    },
    {
      title: dataColumLevels.title,
      dataIndex: dataColumLevels.key,
      key: dataColumLevels.key,
      align: 'left',
      render: (text) => <span>{text || '（空白）'}</span>,
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average br-1',
              title: '合計',
              dataIndex: dataColumLevels.key,
              key: dataColumLevels.key,
              align: 'left',
              width: '35%',
              ellipsis: true,
              render: (text) => <span>{text || '（空白）'}</span>,
            },
          ]
        : undefined,
    },
    {
      title: '総設問数',
      dataIndex: 'question_count',
      align: 'center',
      key: 'question_count',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>{value}</span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.question_count)}
                </span>
              ),
              dataIndex: 'question_count',
              key: 'question_count',
              align: 'center',
              width: '6%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(value)}
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>0</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'accuracy_rate',
              key: 'accuracy_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.wrong_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'wrong_rate',
              key: 'wrong_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '未実施率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.inexperienced_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'inexperienced_rate',
              key: 'inexperienced_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '実質正解率',
      dataIndex: 'real_accuracy_rate',
      key: 'real_accuracy_rate',
      sorter: (a, b) => a.real_accuracy_rate - b.real_accuracy_rate,
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.real_accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'real_accuracy_rate',
              key: 'real_accuracy_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '進捗率',
      dataIndex: 'progress_rate',
      key: 'progress_rate',
      sorter: (a, b) => a.progress_rate - b.progress_rate,
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.progress_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'progress_rate',
              key: 'progress_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '正解進捗率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      render: (value, item) =>
        item.updatedat ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {value ? formatNumber(value * 100) : 0}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>-</span>
        ),
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block', textAlign: 'right' }}>
                  {formatNumber(dataReportCurriculumUser[0]?.accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'accuracy_rate',
              key: 'accuracy_rate',
              align: 'center',
              width: '6%',
              render: (value, item) =>
                item.updatedat ? (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {value ? formatNumber(value * 100) : 0}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block', textAlign: 'right' }}>-</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (value: string) => {
        return value ? (
          dayjs(value).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        );
      },
      sorter: (a, b) =>
        a.updatedat && b.updatedat
          ? new Date(a.updatedat).getTime() - new Date(b.updatedat).getTime()
          : -1,
      children: totalReportCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'updatedat',
              key: 'updatedat',
              align: 'center',
              width: '7%',
              render: (value) =>
                value ? (
                  dayjs(value).format('YYYY/MM/DD')
                ) : (
                  <span style={{ color: '#F0330A' }}>未実施</span>
                ),
            },
          ]
        : undefined,
    },
  ];

  return (
    <WrapperStyled>
      {totalReportCurriculumUser ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportCurriculumUser} <span className="text-static"> 件表示</span> /
                {totalReportCurriculumUser}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportCurriculumUser}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        bordered
        dataSource={dataReportCurriculumUser
          .map((item, index) => ({ ...item, index }))
          .slice(1)
          .slice((page - 1) * pageSize, page * pageSize)}
        columns={columns}
        pagination={false}
      />
    </WrapperStyled>
  );
};

export default TableLevels;
