import React, { useState } from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import Wrapper, { Button } from './styles';
import QuestionList from './Question';
import { Header } from 'components';
import BasicList from './Basic';
import UserList from './User';

interface Props {
  setOpenSkillCheckMaster: React.Dispatch<React.SetStateAction<boolean>>;
}

const SkillCheckMaster: React.FC<Props> = ({ setOpenSkillCheckMaster }) => {
  const [tabActive, setTabActive] = useState<number>(0);

  const { headerTitle } = useSelector(settingSelector);

  return (
    <Wrapper>
      <Header title={headerTitle} />
      <div className="tab-button">
        <p className="description">
          スキルチェックに設定されている基本情報・設問・ユーザーをリスト形式で確認できます。
        </p>
        <div className="wrap-button">
          <div className="left-side">
            {['基本情報リスト', '設問リスト', 'ユーザーリスト'].map((item, index) => (
              <Button
                key={index}
                tabActive={tabActive}
                index={index}
                onClick={() => setTabActive(index)}
              >
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {item}
              </Button>
            ))}
          </div>
        </div>
      </div>
      {tabActive === 0 ? (
        <BasicList tabActive={tabActive} setOpenSkillCheckMaster={setOpenSkillCheckMaster} />
      ) : tabActive === 1 ? (
        <QuestionList tabActive={tabActive} setOpenSkillCheckMaster={setOpenSkillCheckMaster} />
      ) : (
        <UserList tabActive={tabActive} setOpenSkillCheckMaster={setOpenSkillCheckMaster} />
      )}
    </Wrapper>
  );
};

export default SkillCheckMaster;
