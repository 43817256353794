import React from 'react';
import { useSelector } from 'react-redux';
import { ColumnsType } from 'antd/lib/table';
import { Bar } from '@ant-design/plots';
import { Table } from 'antd';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckAnalysisReportSelector } from '../../selectors';
import { BarChartStyle } from './styles';
import * as Types from 'types';

interface Props {
  isStart?: boolean;
}

const BarChart: React.FC<Props> = ({ isStart }) => {
  const { reportSkillCheckAnalysis } = useSelector(skillCheckAnalysisReportSelector);

  return (
    <BarChartStyle>
      <Table
        rowKey="index"
        className="table"
        columns={columns}
        dataSource={reportSkillCheckAnalysis.slice(1).map((item, index) => ({
          ...item,
          ...(isStart ? item.start : item.end),
          index,
        }))}
        bordered
        pagination={false}
      />
      <div className="chart">
        <div className="header-chart" />
        <Bar
          data={reportSkillCheckAnalysis.slice(1).flatMap((item) => {
            const data = isStart ? item.start : item.end;
            return [
              {
                question_name: item.question_name,
                value: Number(formatNumber(data.accuracy_rate * 100)),
                type: '未実施',
              },
              {
                question_name: item.question_name,
                value: Number(formatNumber(data.wrong_rate * 100)),
                type: '不正解',
              },
              {
                question_name: item.question_name,
                value: Number(formatNumber(data.inexperienced_rate * 100)),
                type: '正解',
              },
            ];
          })}
          isStack
          xField="value"
          yField="question_name"
          seriesField="type"
          padding={[20, 40, 0, 0]}
          label={{
            position: 'middle',
            formatter: ({ value }) => (!value ? '' : value),
            style: {
              fontSize: 12,
              fill: '#ffffff',
              fontWeight: 'bold',
            },
          }}
          xAxis={{
            position: 'left',
            max: 100,
            min: 0,
            grid: {
              line: {
                style: {
                  stroke: '#ddd',
                  lineDash: [2, 2],
                },
              },
            },
            label: {
              formatter: (_item, _, index) => (index === 5 ? '50%' : index === 10 ? '100%' : ''),
              style: {
                fontSize: 11,
              },
            },
            showLast: true,
            tickCount: 10,
          }}
          yAxis={{
            label: null,
          }}
          color={['#44a3d7', '#ea8d93', '#ebebeb']}
          legend={false}
          maxBarWidth={24}
          style={{
            width: '100%',
            height: 38 * reportSkillCheckAnalysis.slice(1).length + 21,
          }}
        />
      </div>
    </BarChartStyle>
  );
};

const columns: ColumnsType<Types.ReportSkillCheck.AnalysisItemType> = [
  {
    key: 'question_name',
    dataIndex: 'question_name',
    title: '',
    align: 'right',
    width: '40%',
    ellipsis: true,
  },
  {
    key: 'average_answer_time_per_question',
    dataIndex: 'average_answer_time_per_question',
    align: 'center',
    width: '35%',
    title: (
      <span>
        平均
        <br />
        解答時間
      </span>
    ),
    render: (value) => (
      <div className="bg-highlight">
        <span>{formatNumberToMinute(value)}</span>
      </div>
    ),
  },
  {
    key: 'question_score_root',
    dataIndex: 'question_score_root',
    align: 'center',
    width: '25%',
    title: (
      <span>
        設定
        <br />
        スコア
      </span>
    ),
    render: (value: number) => (
      <div className="bg-highlight">
        <span>{value}</span>
      </div>
    ),
  },
];

export default BarChart;
