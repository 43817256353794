import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .select-language {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .language {
      width: 120px;
    }
  }
  .logo {
    width: 150px;
    margin-bottom: 60px;
  }
  .form {
    .ant-form-item {
      margin-bottom: 20px;
      .ant-input-affix-wrapper {
        margin: 0;
      }
      .ant-form-item-explain {
        min-height: 0;
      }
    }
    .input {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      margin: 10px 0;
      &.ant-input-affix-wrapper-focused {
        background: #fff;
        input {
          background: #fff;
          :focus {
            background: #fff;
          }
          :-webkit-autofill:focus,
          :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        }
      }
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        }
      }
    }
    .ant-input-affix-wrapper-status-error {
      input {
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #fffaf9 inset !important;
        }
      }
    }
    .checkbox {
      font-size: 12px;
      color: #888888;
      margin-top: 8px;
      margin-left: 10px;
      .ant-checkbox-inner {
        width: 16px;
        height: 16px;
        border: 1px solid #d9d9d9;
        border-radius: 1px;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        border-color: #2666bf;
      }
    }
    .messages {
      color: rgba(240, 51, 10, 1);
      text-align: center;
      font-size: 12px;
    }
    .ant-input-prefix {
      margin-right: 8px;
    }
    .icon,
    .anticon {
      color: #bbbbbb;
      font-size: 18px;
    }
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;

      font-size: 15px;
      font-weight: bold;
      border: none;
      margin-top: -10px;
      width: 360px;
      height: 48px;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
  .wrap-submited {
    display: flex;
    flex-direction: column;
    .check-circle-icon {
      font-size: 66px;
      color: rgba(8, 163, 165, 1);
      margin: 30px 0;
    }
    .text-sucess {
      color: #424242;
      text-align: center;
      margin: 0;
      font-size: 13px;
    }
  }
  .wrap-bottom {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    .register {
      font-size: 12px;
      text-align: center;
      color: #999;
      margin-bottom: 8px;
    }
    .text-link {
      font-size: 12px;
      text-align: center;
      text-decoration-line: underline;
      color: #00a3a5;
    }
  }
`;
