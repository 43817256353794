import { createAsyncThunk } from '@reduxjs/toolkit';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_ROLE,
  ATTACH,
  GROUPS,
  QUESTIONS,
  SELECT_TYPES,
  SKILL_CHECK,
  SKILL_CHECK_ASSIGN_QUESTION,
  SKILL_CHECK_HIERARCHY,
  SKILL_CHECK_QUESTION,
  SKILL_CHECK_USERS,
  USERS,
  USER_ASSIGN_SKILL_CHECK,
} from 'configs';

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestionFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getAttachQuestionFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckAssignQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getDataSkillCheckAssignQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckAssignQuestion.ResponseType>(
      SKILL_CHECK_ASSIGN_QUESTION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckTree = createAsyncThunk<
  Types.GetItemResponseType<any>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'skillCheck/thunk/getDataSkillCheckTree',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);
      const { authContainer } = getState() as Types.RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getDataSkillCheckAssignQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      let resultArray: Array<any> = [];
      if (
        getDataSkillCheckAssignQuestion.fulfilled.match(responseStatus[0]) &&
        getDataQues.fulfilled.match(responseStatus[1])
      ) {
        const dataSkillCheckAssignQuestion = responseStatus[0].payload.items;
        const dataQues = responseStatus[1].payload.items;
        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const baseObject = {
              ...item,
              skill_check_code: item.code,
              skill_check_name: item.name,
            };
            const matchedSkillCheckAssign = dataSkillCheckAssignQuestion.filter(
              (c) => c.skill_check_code === item.code
            );
            if (!matchedSkillCheckAssign.length) {
              return baseObject;
            }
            return matchedSkillCheckAssign.flatMap((matchAssign) => {
              const mergedObject1 = {
                ...baseObject,
                question_code: matchAssign.question_code,
                sort_order_assign: Number(matchAssign.sort_order),
                createdat_assign: matchAssign.createdat,
                skill_check_code_assign: matchAssign.skill_check_code,
                i_id_skill_check_code_assign: matchAssign.i_id,
                sort_order: Number(matchAssign.sort_order || 0),
              };
              const matchedQuestionItems = dataQues.filter(
                (matchQue) => matchQue.code === matchAssign.question_code
              );
              if (!matchedQuestionItems.length) {
                return mergedObject1;
              }
              return matchedQuestionItems.map((que) => ({
                ...mergedObject1,
                question_code: que.code,
                question_name: que.name,
                time_limit: que.time_limit,
                score: Number(que.score),
                i_id_question_code: que.i_id,
              }));
            });
          })
        );
      }

      return {
        ...data,
        items: [...resultArray],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSkillcheckMaster = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckHierarchy.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSkillcheckMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckHierarchy.ResponseType>(
      SKILL_CHECK_HIERARCHY.id,
      {
        ...req,
        include_lookups: true,
        include_item_ref: true,
        omit_total_items: false,
        sort_fields: [
          { id: 'skill_check_code', order: 'asc' },
          { id: 'grouping_code', order: 'asc' },
        ],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createSkillcheck = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.SkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/createSkillcheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSkillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.SkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/updateSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.SelectTypes.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SelectTypes.ResponseType>(SELECT_TYPES.id, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectGroups = createAsyncThunk<
  Types.GetItemResponseType<Types.Groups.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSelectGroups', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Groups.ResponseType>(GROUPS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkSkillCheckAssignQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/deleteLinkSkillCheckAssignQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(SKILL_CHECK_ASSIGN_QUESTION.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteSkillCheck = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/deleteSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getDataUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getDataAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserAssignRequiredCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignSkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataUserAssignRequiredCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
      USER_ASSIGN_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUsersSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeSkillCheckUser>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getUsersSkillCheck', async (req, { dispatch, getState, rejectWithValue }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search(SKILL_CHECK.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataUserAssignRequiredCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataAffiliationAssignRole({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeSkillCheckUser> = [];
    if (
      getDataUserAssignRequiredCurriculum.fulfilled.match(responseStatus[0]) &&
      getDataAffiliationAssignRole.fulfilled.match(responseStatus[1]) &&
      getDataUser.fulfilled.match(responseStatus[2])
    ) {
      const dataUserAssignRequiredSkillCheck = responseStatus[0].payload.items;
      const dataAffiliationAssignRole = responseStatus[1].payload.items;
      const dataUser = responseStatus[2].payload.items;

      resultArray = await Promise.all(
        data.items.flatMap((item: any) => {
          const baseObject = {
            i_id: item.i_id,
            skill_check_code: item.code,
            skill_check_name: item.name,
            description: item.description,
            publish: item.publish,
            company_id: item.company_id,
            createdby: item.createdby,
            setting_score: item.setting_score,
            fileID: item.fileID,
            question_time_limit: item.question_time_limit,
            question_count: item.question_count,
            user_type: item.user_type,
            start_period: item.start_period,
            end_period: item.end_period,
            skill_check_sort_order: item.sort_order,
            grouping_code: item.grouping_code,
            implementation_settings: item.implementation_settings,
            problems4: item.problems4,
          };
          const matchedUserAssignItems = dataUserAssignRequiredSkillCheck.filter(
            (c) => c.skill_check_code === item.code
          );
          if (!matchedUserAssignItems.length) {
            return baseObject;
          }
          return matchedUserAssignItems.flatMap((userAssign) => {
            const mergedObject1 = {
              ...baseObject,
              login_id: userAssign.login_id,
              skill_check_code_assign: userAssign.skill_check_code,
              skill_check_code_assign_i_id: userAssign.i_id,
            };
            const matchedAffiliationAssignRole = dataAffiliationAssignRole.filter(
              (affiliationRole) =>
                affiliationRole.main_role === 'main' &&
                affiliationRole.login_id === userAssign.login_id
            );
            return matchedAffiliationAssignRole.flatMap((matchAffiliation) => {
              const mergedObject2 = {
                ...mergedObject1,
                department_name: matchAffiliation.affiliation_id,
                department_name_i_id: matchAffiliation.i_id,
                main: 'main',
              };
              const matchUser = dataUser.filter(
                (user) => user.login_id === matchAffiliation.login_id
              );
              return matchUser.map((u) => ({
                ...mergedObject2,
                employee_number: u.employee_code,
                user_name_i_id: u.i_id,
                user_name: u.name,
                email: u.email,
                name_furigana: u.name_furigana,
              }));
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat();
    }
    dispatch(stopLoading());
    return {
      ...data,
      items: [...flattenedResultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignSkillCheck = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/createUserAssignSkillCheck', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.create(USER_ASSIGN_SKILL_CHECK.id, req);
    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteUserAssignSkillCheck = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/deleteUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSkillCheckQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckQuestion.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSkillCheckQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckQuestion.ResponseType>(
      SKILL_CHECK_QUESTION.id,
      {
        ...req,
        omit_total_items: false,
        sort_fields: [{ id: 'skill_check_code', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSkillCheckUsers = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSkillCheckUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckUser.ResponseType>(
      SKILL_CHECK_USERS.id,
      {
        ...req,
        omit_total_items: false,
        sort_fields: [{ id: 'skill_check_code', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/uploadFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttachByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/deleteFileAttachByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteUserAssignSkillCheckByCondition = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/deleteUserAssignSkillCheckByCondition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(USER_ASSIGN_SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
