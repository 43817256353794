import styled from 'styled-components';

export const WrapAlertPermission = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;
  .icon-lock {
    color: #424242;
    font-size: 30px;
    margin: 0 10px;
  }
  .content {
    color: #424242;
    font-size: 13px;
  }
`;
