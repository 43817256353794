import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
  CloudDownloadOutlined,
  CaretLeftOutlined,
  LikeOutlined,
  LikeFilled,
  StarFilled,
} from '@ant-design/icons';

import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { createMarkup } from 'libs/utils/createMarkup';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { knowledgeTopSelector } from '../selectors';
import { Header, SpinLoading } from 'components';
import KnowledgeCurriculumStyled from './styles';
import { useAppDispatch } from 'hooks';
import { adminAvatar } from 'assets';
import * as Types from 'types';
import {
  createFavoriteKnowledge,
  createKnowLedgeLikeHistory,
  deleteFavoriteKnowledge,
  deleteKnowLedgeLikeHistory,
  getDataKnowledgeCurriculum,
  getKnowledgeFavoriteHistory,
  getKnowledgeLikeHistory,
  updateKnowledge,
} from '../thunk';

const KnowledgeCurriculum: React.FC = () => {
  const { headerTitle, loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { dataKnowledgeCurriculum, dataKnowledgeLikeHistory, dataKnowledgeFavoriteHistory } =
    useSelector(knowledgeTopSelector);

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const dataRecord = useLocation().state as Types.KnowledgeListType;

  const fetchKnowledgeLikeHistory = useCallback(() => {
    if (userInfo && dataRecord.knowledge_id) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getKnowledgeLikeHistory({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'knowledge_id',
                search_value: [dataRecord.knowledge_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, dataRecord.knowledge_id, userInfo]);

  const fetchKnowledgeFavoriteHistory = useCallback(() => {
    if (userInfo && dataRecord.knowledge_id) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getKnowledgeFavoriteHistory({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'favorite_knowledge_id',
                search_value: [dataRecord.knowledge_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, dataRecord.knowledge_id, userInfo]);

  const fetchDataKnowledgeCurriculum = useCallback(async () => {
    if (userInfo && dataRecord.knowledge_id) {
      await dispatch(
        getDataKnowledgeCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'knowledge_id',
              search_value: [dataRecord.knowledge_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
    }
  }, [dispatch, dataRecord.knowledge_id, userInfo]);

  useEffect(() => {
    (async () => {
      await fetchDataKnowledgeCurriculum();
    })();
  }, [fetchDataKnowledgeCurriculum]);

  return (
    <SpinLoading loading={loading}>
      <KnowledgeCurriculumStyled>
        <Header title={headerTitle} className="header" />
        <div className="btn-header">
          <div className="group-btn-left">
            <div className="btn-back" onClick={() => navigate(-1)}>
              <CaretLeftOutlined className="icon" />
              一覧に戻る
            </div>
          </div>
          <div className="group-btn-right">
            <Tooltip
              placement="top"
              title={dataKnowledgeLikeHistory.length ? 'いいね！しました' : ''}
            >
              <div
                className="btn like"
                onClick={async () => {
                  dispatch(startLoading());
                  if (!dataKnowledgeLikeHistory.length) {
                    const resultAction = await Promise.all([
                      dispatch(
                        createKnowLedgeLikeHistory({
                          item: {
                            knowledge_id: dataKnowledgeCurriculum[0]?.knowledge_id,
                            login_id: userInfo?.login_id,
                            created_month: dayjs().format('YYYYMM'),
                          },
                        })
                      ),
                      dispatch(
                        updateKnowledge({
                          id: dataKnowledgeCurriculum[0].i_id,
                          data: {
                            item: {
                              likes: Number(dataKnowledgeCurriculum[0].number_of_likes) + 1,
                              updatedat: new Date(),
                            },
                            is_force_update: true,
                          },
                        })
                      ),
                    ]);
                    if (createKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeCurriculum();
                      fetchKnowledgeLikeHistory();
                    }
                  } else {
                    const resultAction = await Promise.all([
                      dispatch(
                        deleteKnowLedgeLikeHistory({
                          id: dataKnowledgeLikeHistory[0].item_ref!.knowledge_id.i_id,
                        })
                      ),
                      dispatch(
                        updateKnowledge({
                          id: dataKnowledgeCurriculum[0].i_id,
                          data: {
                            item: {
                              likes: Number(dataKnowledgeCurriculum[0].number_of_likes) - 1,
                              updatedat: new Date(),
                            },
                            is_force_update: true,
                          },
                        })
                      ),
                    ]);
                    if (deleteKnowLedgeLikeHistory.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeCurriculum();
                      fetchKnowledgeLikeHistory();
                    }
                  }
                  dispatch(stopLoading());
                }}
              >
                {dataKnowledgeLikeHistory.length ? (
                  <LikeFilled className="icon" />
                ) : (
                  <LikeOutlined className="icon" />
                )}

                {dataKnowledgeCurriculum[0]?.number_of_likes}
              </div>
            </Tooltip>
            <Tooltip
              placement="top"
              title={dataKnowledgeFavoriteHistory.length ? 'お気に入りしました' : ''}
            >
              <div
                className="btn star"
                onClick={async () => {
                  dispatch(startLoading());
                  if (!dataKnowledgeFavoriteHistory.length) {
                    const resultAction = await Promise.all([
                      dispatch(
                        createFavoriteKnowledge({
                          item: {
                            company_id: userInfo?.company_id,
                            knowledge_id: dataRecord?.knowledge_id,
                            login_id: userInfo?.login_id,
                          },
                        })
                      ),
                    ]);
                    if (createFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeCurriculum();
                      fetchKnowledgeFavoriteHistory();
                    }
                  } else {
                    const resultAction = await Promise.all([
                      dispatch(
                        deleteFavoriteKnowledge({
                          id: dataKnowledgeFavoriteHistory[0].item_ref!.favorite_knowledge_id.i_id,
                        })
                      ),
                    ]);
                    if (deleteFavoriteKnowledge.fulfilled.match(resultAction[0])) {
                      fetchDataKnowledgeCurriculum();
                      fetchKnowledgeFavoriteHistory();
                    }
                  }
                  dispatch(stopLoading());
                }}
              >
                <StarFilled
                  className={dataKnowledgeFavoriteHistory.length ? 'star-gold' : 'icon-star'}
                />
                お気に入り
              </div>
            </Tooltip>
            <Button className="export-btn">
              <CloudDownloadOutlined className="icon-star" /> ダウンロード
            </Button>
          </div>
        </div>
        <div className="container">
          <div className="wrap-header">
            <div className="title">
              <div className="divider" />
              <div className="title-header">
                <span className="label-QA">カリキュラム</span>
                <span className="content-header">abc</span>
              </div>
            </div>
            <div className="info">
              <div className="divider" />
              <div className="item-from">
                <div className="avatar">
                  <div className="label-QA">投稿者</div>
                  <img className="avt-icon" src={adminAvatar} alt="avt-user" />
                </div>
                <div className="item-name">
                  <div className="label-name">経理部</div>
                  <div className="name">{userInfo?.name}</div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text"
            style={{
              display: 'block',
              whiteSpace: 'pre',
            }}
            dangerouslySetInnerHTML={createMarkup(dataKnowledgeCurriculum[0]?.knowledge_text)}
          />
        </div>
      </KnowledgeCurriculumStyled>
    </SpinLoading>
  );
};

export default KnowledgeCurriculum;
