import { createAsyncThunk } from '@reduxjs/toolkit';

import { convertFileResponse } from 'libs/utils/question';
import { services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_LEVEL,
  ATTACH,
  CARD_COMPANY_NAME,
  COMPANIES,
  GMO,
  PLANS,
  USERS,
} from 'configs';

export const getPlans = createAsyncThunk<
  Types.GetItemResponseType<Types.Plans.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/getPlan', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Plans.ResponseType>(PLANS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const confirmCreditCardThunk = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<{ name: string }>,
  Types.ThunkAPI<Types.ConfirmCreditCardError>
>('informationRegister/confirmCreditCard', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(GMO.name, req);

    return data;
  } catch (err: any) {
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const createCompanies = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/createCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const registerUserThunk = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/RegisterUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateUserInfo = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/thunk/updateUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const isFirstLogin = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/isFirstLogin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    if (data.items[0]?.icon_fileID) {
      const resultActionGetAttach = await services.search<Types.Attach.ResponseType>(ATTACH.id, {
        conditions: [
          {
            id: 'fileID',
            search_value: [data.items[0].icon_fileID],
          },
        ],
        page: 1,
        per_page: 1,
      });

      if (resultActionGetAttach.data.items[0] && resultActionGetAttach.data.items[0]?.file) {
        const fileAction = await services.getFile({
          file_id: resultActionGetAttach.data.items[0].file,
        });

        if (fileAction.data) {
          data.items[0].avatar = convertFileResponse({
            file: fileAction.data,
            fileID: resultActionGetAttach.data.items[0].fileID,
            fileName: resultActionGetAttach.data.items[0].filename,
          });
        }
      }
    }

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLoginTime = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/updateLoginTime', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCardCompanyName = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.GetCardCompanyNameType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/getCardCompanyName', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.GetCardCompanyNameType>(
      CARD_COMPANY_NAME.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addLinkAffiliation = createAsyncThunk<
  Types.AddLinkItemResponseType,
  any,
  Types.ThunkAPI<Types.requestError>
>('informationRegister/addLinkAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addLink(AFFILIATION_ASSIGN_LEVEL.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
