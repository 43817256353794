import styled from 'styled-components';

export default styled.section`
  height: 100%;
  width: 100%;
  background: #f9f8f8;
  .wrap-header {
    border-bottom: 1px solid #bbbbbb;
    padding: 30px 30px 0 30px;
    background: #ffffff;
  }
  .item-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text-note {
      font-size: 12px;
      color: #424242;
      margin: 0;
    }
    .switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #424242;
      font-size: 12px;
      .icon {
        margin-left: 15px;
      }
      .ant-switch-checked {
        background-color: #08a3a5;
      }
    }
  }

  .wrap-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-tab {
      display: flex;
      flex-direction: row;
      width: 27%;
      border-right: 1px solid #dddddd;
      .button {
        width: 200px;
        height: 44px;
        font-size: 14px;
      }
    }
    .group-search {
      width: 70%;
      .form-search {
        display: flex;
        align-items: center;
        padding: 25px 0;
        .curriculum {
          width: 33%;
          margin-right: 8px;
        }
        .author {
          width: 25%;
          margin-right: 8px;
        }
        .authors {
          width: 25%;
          margin-right: 8px;
        }
        .text-label {
          color: #424242;
          font-family: Lato;
          font-size: 12px;
        }
        .ant-select-selection-placeholder {
          color: #ccc;
          font-family: Lato;
          font-size: 13px;
        }

        .label-reset {
          font-size: 12px;
          cursor: pointer;
          text-decoration-line: underline;
          color: #00a3a5;
          display: block;
          margin-left: 10px;
        }
        .btn-search {
          width: 100px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #00a3a5;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-family: 'Noto Sans Javanese';
          font-size: 14px;
          color: #00a3a5;
          cursor: pointer;
          .icon-search {
            font-size: 18px;
            margin-right: 8px;
          }
        }
      }
    }
  }
  .flex-label {
    display: flex;
    justify-content: space-between;
    .label-text {
      font-size: 13px;
      color: #777777;
      cursor: pointer;
      text-align: left;
      user-select: none;
      .icon-label {
        margin-right: 7px;
      }
    }
    .active {
      color: #00a3a5;
      font-weight: 700;
    }
  }

  .wrap-body {
    height: 100vh;
    .wrap-tree {
      width: 100%;
      background: #f2f2f2;
      border: 1px solid rgba(0, 0, 0, 0.05);
      padding: 20px;
    }
    .bordered {
      border-bottom: 4px solid #eeeeee;
    }
  }
  @media (max-width: 1440px) {
    .wrap-button {
      .btn-tab {
        width: 35.5%;
      }
      .group-search {
        width: 63%;
        .form-search {
          .curriculum {
            width: 228px;
          }
          .author {
            width: 160px;
          }
        }
      }
    }
  }
`;

interface ButtonProps {
  tabActive: number;
  index: number;
}

export const Button = styled.button<ButtonProps>`
  cursor: pointer;
  position: relative;
  font-weight: ${(props) => (props.tabActive === props.index ? '700' : '400')};
  font-size: 14px;
  box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
  background-color: ${(props) =>
    props.tabActive === props.index ? (props.tabActive === 1 ? '#009ACB' : '#00a3a5') : '#ffffff'};
  color: ${(props) =>
    props.tabActive === props.index ? '#ffffff' : props.tabActive === 0 ? '#00a3a5' : '#009ACB'};
  border: 1px solid ${(props) => (props.tabActive === 0 ? '#00a3a5' : '#009ACB')};
  .check-icon {
    position: absolute;
    top: 15px;
    left: ${(props) => (props.tabActive === 0 ? '25px' : '10px')};
  }

  &:nth-child(2) {
    border-radius: 0 3px 3px 0;
  }
  &:nth-child(1) {
    border-radius: 3px 0 0 3px;
  }
`;
