import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Popover, Table, Select } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { SubmitButton, Form } from 'formik-antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { find, unionBy } from 'lodash';
import saveAs from 'file-saver';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { HEADER_QUESTION_MASTER_CSV } from 'constant/header.export.constant';
import { CreateQuestion, UploadCSV, UploadManyImageVideo } from './Modal';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { useAppDispatch, usePermission, useCheckUpload } from 'hooks';
import { getOptionLimit } from 'containers/CreateEditQuestion/thunk';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import ActionErrorModal from 'components/Modal/ActionError';
import { deletedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { questionMasterSelector } from './selectors';
import { Header, SelectField } from 'components';
import QuestionMasterStyled from './styles';
import { COMPANIES } from 'configs';
import * as Types from 'types';
import {
  deleteQuestion,
  getQuestionDetail,
  getSelectCurriculum,
  getSelectQuestionCreator,
  getSelectQuestion,
  getSelectTypes,
  updateTimeLimitCompany,
  getCompany,
  getDataQuestionCurriculum,
} from './thunk';
import {
  filterCreator,
  filterCurriculum,
  filterQuestion,
  filterRequiredCurriculumCurriculum,
  filterScore,
} from './slice';

const { Option } = Select;

const PER_PAGE = 100;

const QuestionMaster: React.FC = () => {
  const [visiblePopupUploadImageVideo, setVisiblePopupUploadImageVideo] = useState<boolean>(false);
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [visibleDeletePopupError, setVisibleDeletePopupError] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.TreeTraining[]>([]);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [statusTime, setStatusTime] = useState<boolean>(false);
  const [perPage, setPerpage] = useState<number>(PER_PAGE);
  const [indexTime, setIndexTime] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { isDisabledUpload } = useCheckUpload();
  const { permissionNumber } = usePermission();

  const {
    dataQuestion,
    curriculumCreatorSelect,
    curriculumNameSelect,
    defaultTimeLimit,
    questionSelect,
    scoreSelect,
    timeLimit,
  } = useSelector(questionMasterSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.QuestionFormSearchFormik>({
    initialValues: {
      code: '',
      required_curriculum: '',
      question_code: '',
      creator: '',
      score: '',
    },
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        getDataQuestionCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],

          page: page,
          per_page: 0,
          include_lookups: true,
        })
      );
      if (getDataQuestionCurriculum.fulfilled.match(resultAction)) {
        if (values.code) {
          dispatch(filterCurriculum(values.code));
        }
        if (values.required_curriculum) {
          dispatch(filterRequiredCurriculumCurriculum(values.required_curriculum));
        }
        if (values.creator) {
          dispatch(filterCreator(values.creator));
        }
        if (values.question_code) {
          dispatch(filterQuestion(values.question_code));
        }
        if (values.score) {
          dispatch(filterScore(values.score));
        }
      }
    },
  });

  const columns: ColumnsType<Types.TreeTraining> = [
    {
      title: 'No.',
      dataIndex: 'no',
      key: 'no',
      width: '5%',
      render: (_, _Item, index) => <span>{index + 1}</span>,
    },
    {
      title: '設問コード',
      dataIndex: 'question_code',
      key: 'question_code',
      width: '8%',
    },
    {
      title: '設問名',
      dataIndex: 'question_name',
      key: 'question_name',
      ellipsis: true,
      width: '15%',
    },
    {
      title: '設問内容抜粋',
      dataIndex: 'question_description',
      key: 'question_description',
      ellipsis: true,
      width: '27%',
    },
    {
      title: '製作者',
      dataIndex: 'creator',
      key: 'creator',
      ellipsis: true,
      width: '18%',
    },
    {
      title: 'OFFICIAL',
      dataIndex: 'official_curriculum',
      key: 'official_curriculum',
      ellipsis: true,
      width: '10%',
      render: (_, item) => item.official_curriculum == '1' && <p className="official">OFFICIAL</p>,
    },
    {
      title: 'スコア',
      dataIndex: 'score',
      key: 'score',
      width: '7%',
    },
    {
      title: '詳細・編集',
      dataIndex: 'operation',
      width: '10%',
      render: (_, item) => (
        <FormOutlined
          className="icon"
          onClick={() =>
            permissionNumber !== 1 &&
            setOpenModalCreateQuestion({
              visible: true,
              type: 'edit',
              question_id: item.question_code_i_id,
            })
          }
        />
      ),
    },
  ];

  const handleSubmitSelectTimeLimit = async (time: number) => {
    dispatch(startLoading());
    const id = find(userInfo?.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
      ?.i_id;

    const actionResult = await dispatch(
      updateTimeLimitCompany({
        id: id ?? '',
        data: {
          item: {
            time_limit: time,
          },
          is_force_update: true,
        },
      })
    );

    if (updateTimeLimitCompany.fulfilled.match(actionResult)) {
      setStatusTime(true);
      fetchCompany();
    }
    setTimeout(() => {
      setStatusTime(false);
    }, 1000);
    dispatch(stopLoading());
    //TODO: integrate API
  };

  const fetchQuestion = useCallback(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataQuestionCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],

          page: page,
          per_page: 0,
          include_lookups: true,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, page]);

  const fetchCompany = useCallback(() => {
    if (!userInfo) return;
    (async () => {
      await dispatch(
        getCompany({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    })();
  }, [dispatch]);

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleButtonDelete = () => {
    if (!selectedRow.length) {
      setVisibleDeletePopupError(true);
    } else {
      setVisiblePopupConfirmDelete(true);
    }
  };

  const handleSubmitDelete = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      getQuestionDetail({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'i_id',
            search_value: selectedRow.map((item) => item.question_code_i_id),
          },
        ],
      })
    );
    if (getQuestionDetail.fulfilled.match(resultAction)) {
      await Promise.all(
        resultAction.payload.items
          .map(async (item) => {
            const listFileID: string[] = [
              item.attach_fileID1,
              item.attach_fileID2,
              item.attach_fileID3,
              item.comment_attach_fileID1,
              item.comment_attach_fileID2,
              item.comment_attach_fileID3,
              item.problems1_attach_fileID,
              item.problems2_attach_fileID,
              item.problems3_attach_fileID,
            ];

            await Promise.all(
              listFileID.map((file) => {
                if (file) {
                  return deletedFileInMinIO(file);
                }
                return file;
              })
            );

            return [
              dispatch(
                deleteQuestion({
                  id: item.i_id,
                })
              ),
            ];
          })
          .flat()
      );
      fetchQuestion();
    }
    dispatch(stopLoading());
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        getQuestionDetail({
          page: 1,
          per_page: 0,
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'i_id',
              search_value: selectedRow.map((item) => item.question_code_i_id),
            },
          ],
        })
      );
      if (getQuestionDetail.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.items.map((item) => ({
          name: item.name,
          i_id: item.code,
          description: item.description,
          attach_fileID1: convertAttachFileId(item.attach1, item.attach_fileID1),
          attach_fileID2: convertAttachFileId(item.attach2, item.attach_fileID2),
          attach_fileID3: convertAttachFileId(item.attach3, item.attach_fileID3),
          problems1: item.problems1,
          problems2: item.problems2,
          problems3: item.problems3,
          problems1_attach_fileID: convertAttachFileId(
            item.problems1_attach,
            item.problems1_attach_fileID
          ),
          problems2_attach_fileID: convertAttachFileId(
            item.problems2_attach,
            item.problems2_attach_fileID
          ),
          problems3_attach_fileID: convertAttachFileId(
            item.problems3_attach,
            item.problems3_attach_fileID
          ),
          answer: item.answer,
          comment: item.comment,
          comment_attach_fileID1: convertAttachFileId(
            item.comment_attach1,
            item.comment_attach_fileID1
          ),
          comment_attach_fileID2: convertAttachFileId(
            item.comment_attach2,
            item.comment_attach_fileID2
          ),
          comment_attach_fileID3: convertAttachFileId(
            item.comment_attach3,
            item.comment_attach_fileID3
          ),
          time_limit: item.time_limit,
          score: item.score,
          company_id: item.company_id,
          createdby: item.createdby,
          createdat: item.createdat,
          creator: item.creator,
          provider_id: item.provider_id,
          provider_question_code: item.provider_question_code,
          attach_file_name1: `${item.code}_attach1`,
          attach_file_name2: `${item.code}_attach2`,
          attach_file_name3: `${item.code}_attach3`,
          problems1_attach_file_name: `${item.code}_problems1_attach`,
          problems2_attach_file_name: `${item.code}_problems2_attach`,
          problems3_attach_file_name: `${item.code}_problems3_attach`,
          comment_attach_file_name1: `${item.code}_comment_attach1`,
          comment_attach_file_name2: `${item.code}_comment_attach2`,
          comment_attach_file_name3: `${item.code}_comment_attach3`,
        }));

        const csvString = [
          HEADER_QUESTION_MASTER_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '設問マスタ.csv');
      }
    }
    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const handleCreate = () => setOpenModalCreateQuestion({ visible: true, type: 'create' });

  const handleUploadCsvImageVideo = () => setVisiblePopupUploadImageVideo(true);

  const dataTable = useMemo(() => {
    return unionBy(dataQuestion, 'question_code');
  }, [dataQuestion]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      await Promise.all([
        dispatch(
          getSelectCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'score',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectQuestionCreator({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'creator', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(getOptionLimit()),
      ]);
    })();
  }, [dispatch]);

  useEffect(() => {
    setIndexTime(defaultTimeLimit);
  }, [defaultTimeLimit]);

  useEffect(fetchCompany, [fetchCompany]);

  useEffect(fetchQuestion, [fetchQuestion]);

  return (
    <QuestionMasterStyled collapsedMenu={collapsedMenu} isEmptyData={!dataTable?.length}>
      <Header title={headerTitle} />
      <div className="container">
        <div className="header-container">
          <p className="title">
            設問の作成・編集、作成した設問の一覧を確認できます。
            <br />
            設問に設定するデフォルトの制限時間も設定可能です。
          </p>
          <div className="item-right">
            {!statusTime ? (
              <div className="select-time-limit">
                <div className="label">
                  <span>制限時間の初期値</span>
                  <Popover
                    trigger={['click']}
                    placement="bottomRight"
                    title={
                      <p style={{ color: '#2A2A2A', fontSize: 16 }}>設問制限時間の初期値設定</p>
                    }
                    content={
                      <p style={{ color: '#424242', fontSize: 13, padding: '0 12px' }}>
                        設問制限時間の初期値設定とは、設問ごとに設定する制限時間の初期値（デフォルト値）を設定することです。
                        <br />
                        設問新規作成時に、設定した制限時間が初期表示されます。
                        <br />
                        あくまでも初期値ですので、設問ごとに制限時間は変更可能です。
                        <br />
                      </p>
                    }
                    color="#ffffff"
                  >
                    <QuestionCircleOutlined className="question-mark-icon" />
                  </Popover>
                  ：
                </div>
                <Select
                  className="select-limit"
                  showSearch
                  allowClear
                  value={indexTime}
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={(e) => setIndexTime(e)}
                >
                  {timeLimit.map((e, index) => (
                    <Option value={index}>{e.value}</Option>
                  ))}
                </Select>
                <Button
                  disabled={permissionNumber === 1}
                  className={permissionNumber === 1 ? 'disabled ' : 'btn-search'}
                  onClick={() => handleSubmitSelectTimeLimit(indexTime)}
                >
                  設定
                </Button>
              </div>
            ) : (
              <div className="wrap-notify-successful">
                <CheckCircleOutlined className="icon-successful" />
                設定した時間を初期値として登録しました
              </div>
            )}
          </div>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="code"
                className="curriculum_name"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumNameSelect?.map(({ code, name }, index) => (
                    <Option key={index} value={code}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="required_curriculum"
                className="required_curriculum"
                label={<span className="text-label">カリキュラム種類</span>}
              >
                <SelectField
                  name="required_curriculum"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {['カリキュラム', '必修カリキュラム'].map((name, index) => (
                    <Option key={index} value={`${index}`}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="creator"
                className="creator"
                label={<span className="text-label">制作者</span>}
              >
                <SelectField
                  name="creator"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {curriculumCreatorSelect?.map(({ creator }, index) => (
                    <Option key={index} value={creator}>
                      {creator}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_code"
                className="question_name"
                label={<span className="text-label">設問名</span>}
              >
                <SelectField
                  name="question_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {questionSelect?.map(({ name, code }, index) => (
                    <Option key={index} value={code}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="score"
                className="score"
                label={<span className="text-label">スコア（設問難易度）</span>}
              >
                <SelectField
                  name="score"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {scoreSelect?.map(({ name }, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span
                className="label-reset"
                onClick={() => {
                  formik.resetForm();
                  formik.handleSubmit();
                }}
              >
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="wrap-button">
          <Button
            className="btn btn-outline"
            onClick={handleUploadCsvImageVideo}
            disabled={isDisabledUpload}
          >
            画像・動画インポート
          </Button>
          <Button
            className="btn btn-active"
            icon={<CloudUploadOutlined className="icon" />}
            onClick={() => setVisiblePopupUploadCSV(true)}
          >
            設問インポート
          </Button>
          <Button
            disabled={permissionNumber === 1}
            className={`btn ${permissionNumber === 1 ? 'disabled' : 'btn-active'}`}
            onClick={handleCreate}
          >
            + 設問新規登録
          </Button>
        </div>
        <Table
          rowKey="index"
          className="table"
          dataSource={dataTable.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            onChange: (_, selectedRows) => setSelectedRow(selectedRows),
            getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
          }}
          pagination={{
            pageSize: perPage,
            current: page,
            onChange: setPage,
            showSizeChanger: false,
            position: ['topCenter'],
            showTotal: () => (
              <>
                <div className="wrap-select-record">
                  {perPage === 10 ? (
                    <div className="text-count">
                      {(page - 1) * perPage + 1} - {''}
                      {page * PER_PAGE > dataTable.length ? dataTable.length : page * PER_PAGE}
                      <span className="text-static"> {''}</span> / {dataTable.length}
                      <span className="text-static"> 件</span>
                    </div>
                  ) : (
                    <div className="text-count">
                      {(page - 1) * perPage + 1} - {''}
                      {page * perPage > dataTable.length ? dataTable.length : page * perPage}
                      <span className="text-static"> {''}</span> / {dataTable.length}
                      <span className="text-static"> 件</span>
                    </div>
                  )}
                  <div>
                    <span className="label-select">表示件数：</span>
                    <Select className="select-option" onChange={setPerpage} value={perPage}>
                      {[100, 50, 20, 10].map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </>
            ),
            total: dataTable.length,
          }}
        />
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した設問を処理：
              <Button
                className="btn btn-active"
                icon={<DeleteOutlined className="icon" />}
                onClick={handleButtonDelete}
              >
                設問削除
              </Button>
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
          </div>
        </div>
        <UploadCSV visible={visiblePopupUploadCSV} setVisible={setVisiblePopupUploadCSV} />

        <UploadManyImageVideo
          visible={visiblePopupUploadImageVideo}
          setVisible={setVisiblePopupUploadImageVideo}
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="設問が選択されていません"
          description={
            <>
              エクスポートを実行する対象の設問を選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <ActionErrorModal
          visible={visibleDeletePopupError}
          setVisible={setVisibleDeletePopupError}
          subTitle="設問が選択されていません"
          description={
            <>
              削除を実行する対象の設問を選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="削除確認"
          subTitle="データの削除を実行します。"
          description="データの削除を実行すると、復元できませんのでご注意ください。"
          onSubmit={handleSubmitDelete}
          setVisible={setVisiblePopupConfirmDelete}
        />
        <CreateQuestion
          page={page}
          setVisibleSuccess={setVisibleSuccess}
          defaultTimeLimit={defaultTimeLimit}
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
        />
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="登録が完了しました"
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
      </div>
    </QuestionMasterStyled>
  );
};

const convertAttachFileId = (fileID1: string, fileID2: string) =>
  fileID1 === fileID2
    ? fileID1
    : fileID1 && fileID2
    ? `${fileID1} / ${fileID2}`
    : fileID1 || fileID2 || '';

export default QuestionMaster;
