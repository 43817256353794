import { getDescendantCount, getFlatDataFromTree } from './tree-data-utils';
import { FileExplorer } from 'types';

const memoize = (f: Function): Function => {
  let savedArgsArray: FileExplorer.TreeItem[] = [];
  let savedKeysArray: string[] = [];
  let savedResult: FileExplorer.TreeItem | null = null;

  return (args: FileExplorer.FlatDataFromTreeType): FileExplorer.TreeItem | null => {
    const keysArray = Object.keys(args).sort();
    const argsArray = keysArray.map((key) => args[key as keyof typeof args]);

    if (
      argsArray.length !== savedArgsArray.length ||
      argsArray.some((arg, index) => arg !== savedArgsArray[index]) ||
      keysArray.some((key, index) => key !== savedKeysArray[index])
    ) {
      savedArgsArray = argsArray;
      savedKeysArray = keysArray;
      savedResult = f(args);
    }

    return savedResult;
  };
};

export const memoizedGetFlatDataFromTree = memoize(getFlatDataFromTree);
export const memoizedGetDescendantCount = memoize(getDescendantCount);
