import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import * as Types from 'types';
import {
  getDataCurriculumBatch,
  getDataEducationProgram,
  getDataSelectCreator,
  getDataSelectCurriculum,
  getDataSelectDepartment,
  getDataSelectName,
  getDataSelectPosition,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckCategory,
  getDataSelectSkillCheckCreator,
  getDataSelectSkillCheckGroupCode,
  getDataSelectSkillCheckStartPeriod,
  getDataSelectSkillCheckStatus,
  getDataSkillCheck,
  getSkillCheckTrans2,
  getUserSearch,
} from './thunk';

export interface InitialState {
  dataEducationProgram: Array<Types.RequiredCurriculumSkillCheck.DataConverted>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataSelectPosition: Array<Types.SelectPosition.ResponseType>;
  dataSkillCheck: Array<Types.SkillCheck.ResponseType>;
  dataSelectName: Array<Types.SelectEmployeeUser.ResponseType>;
  dataCurriculumBatch: Array<Types.Curriculums.ResponseType>;
  dataSelectCurriculum: Array<Types.SelectRequiredCurriculum.ResponseType>;
  dataSelectCreator: Array<Types.Users.ResponseDetailType>;
  dataUserSearch: Array<Types.Users.ResponseDetailType>;
  dataSelectSkillCheck: Array<Types.SelectSkillCheck.ResponseType>;
  dataSelectStartPeriod: Array<Types.SelectSkillCheckStartPeriod.ResponseType>;
  dataSelectCategory: Array<Types.SelectSkillCheckCategory.ResponseType>;
  dataSelectGroupingCode: Array<Types.SelectSkillCheckGroupingCode.ResponseType>;
  dataSelectUseStatus: Array<Types.SelectSkillCheckUseStatus.ResponseType>;
  dataSelectCreators: Array<Types.SelectSkillCheckCreator.ResponseType>;
  skillCheckTrans2: Array<Types.SkillCheckTrans2.ResponseType>;
  totalSkillCheck: number;
  total: number;
}

const initialState: InitialState = {
  dataEducationProgram: [],
  dataSkillCheck: [],
  dataSelectName: [],
  dataSelectDepartment: [],
  dataSelectPosition: [],
  totalSkillCheck: 0,
  total: 0,
  dataCurriculumBatch: [],
  dataSelectCurriculum: [],
  dataSelectCreator: [],
  dataSelectSkillCheck: [],
  dataSelectStartPeriod: [],
  dataSelectCategory: [],
  dataSelectGroupingCode: [],
  dataSelectUseStatus: [],
  dataSelectCreators: [],
  skillCheckTrans2: [],
  dataUserSearch: [],
};

export const educationProgramSlice = createSlice({
  name: 'educationProgram-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataEducationProgram.fulfilled, (state, action) => {
      const newDataEducationProgram: Array<Types.RequiredCurriculumSkillCheck.DataConverted> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newDataEducationProgram.findIndex(
          (educationProgram) => educationProgram.i_id === item.i_id
        );
        if (itemIndex >= 0) {
          if (
            item.curriculum_code &&
            !newDataEducationProgram[itemIndex].curriculum_code.some(
              ({ code }) => code === item.curriculum_code
            )
          ) {
            newDataEducationProgram[itemIndex].curriculum_code.push({
              code: item.curriculum_code,
              name: item.curriculum_name,
            });
          }
          if (
            item.skill_check_code &&
            !newDataEducationProgram[itemIndex].skill_check_code.some(
              ({ code }) => code === item.skill_check_code
            )
          ) {
            newDataEducationProgram[itemIndex].skill_check_code.push({
              code: item.skill_check_code,
              name: item.skill_check_name,
            });
          }
        } else {
          newDataEducationProgram.push({
            i_id: item.i_id,
            name: item.name,
            employee_code: item.employee_code,
            kana: item.kana,
            department_name: item.department_name,
            position_code: item.position_code,
            curriculum_code: item.curriculum_code
              ? [{ code: item.curriculum_code, name: item.curriculum_name }]
              : [],
            skill_check_code: item.skill_check_code
              ? [{ code: item.skill_check_code, name: item.skill_check_name }]
              : [],
          });
        }
      });
      state.dataEducationProgram = newDataEducationProgram;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getDataSkillCheck.fulfilled, (state, action) => {
      state.dataSkillCheck = action.payload.items;
      state.totalSkillCheck = action.payload.totalItems;
    });

    builder.addCase(getDataCurriculumBatch.fulfilled, (state, action) => {
      state.dataCurriculumBatch = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getDataSelectName.fulfilled, (state, action) => {
      state.dataSelectName = action.payload.report_results;
    });

    builder.addCase(getDataSelectDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });

    builder.addCase(getDataSelectPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.report_results;
    });

    builder.addCase(getDataSelectCurriculum.fulfilled, (state, action) => {
      state.dataSelectCurriculum = action.payload.report_results;
    });

    builder.addCase(getDataSelectCreator.fulfilled, (state, action) => {
      state.dataSelectCreator = unionBy(action.payload.report_results, 'creator');
    });

    builder.addCase(getDataSelectSkillCheck.fulfilled, (state, action) => {
      state.dataSelectSkillCheck = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckStartPeriod.fulfilled, (state, action) => {
      state.dataSelectStartPeriod = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckCategory.fulfilled, (state, action) => {
      state.dataSelectCategory = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckGroupCode.fulfilled, (state, action) => {
      state.dataSelectGroupingCode = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckStatus.fulfilled, (state, action) => {
      state.dataSelectUseStatus = action.payload.report_results;
    });

    builder.addCase(getDataSelectSkillCheckCreator.fulfilled, (state, action) => {
      state.dataSelectCreators = action.payload.report_results;
    });

    builder.addCase(getSkillCheckTrans2.fulfilled, (state, action) => {
      state.skillCheckTrans2 = action.payload.items;
    });
    builder.addCase(getUserSearch.fulfilled, (state, action) => {
      state.dataUserSearch = action.payload.items;
    });
  },
});

export const { resetInitialState } = educationProgramSlice.actions;

export default educationProgramSlice.reducer;
