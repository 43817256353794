import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { UploadFile, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { useSelector } from 'react-redux';
import { Switch } from 'antd';
import {
  CaretRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { removeNodeLevel4Selected, selectNodeLevel4 } from 'containers/Curriculum/Search/slice';
import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { IconLocked, IconPublish, IconRequired, MoveWhite } from 'assets';
import { setQuestionCurriculumSelected } from 'pages/Settings/Curriculum/slice';
import { deletedFileInMinIO, sharedFileInMinIO } from 'services/minioService';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { deleteLinkQuestion } from 'containers/CreateEditQuestion/thunk';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { CreateQuestion } from 'pages/Settings/QuestionMaster/Modal';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { Button, DivCustom, DivCustomCanDrag } from './styles';
import { deleteKnowledge } from 'pages/KnowledgeTop/thunk';
import { convertFileResponse } from 'libs/utils/question';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { useAppDispatch, usePermission } from 'hooks';
import * as Types from 'types';
import {
  PopupCurriculumConfirmPublish,
  CreateLevelCurriculum,
  CreateEditCurriculum,
  EditLevelCurriculum,
} from 'pages/Settings/Curriculum/Modal';

import {
  deleteLinkUserAssignCurriculum,
  deleteLevelCurriculum,
  setRequiredCurriculum,
  setPublishCurriculum,
  getDataUserSetting,
  deleteCurriculum,
  updateCurriculum,
  getCurriculum,
  deleteUserAssignCurriculum,
} from 'pages/Settings/Curriculum/thunk';

interface Props {
  tabActive: number;
  maxSortOrder?: number;
  visible: boolean;
  node: Types.TreeItem<Types.CurriculumItemType>;
  itemMoveCopySelected?: Types.ItemMoveCopySelectedType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  handleSelectModeDrag: (n: Types.TreeItem<Types.CurriculumItemType>, m: 'move' | 'copy') => void;
  onDrop?: (
    type: 'move' | 'copy',
    currentItem: Types.TreeItem<Types.CurriculumItemType>,
    targetItem: Types.TreeItem<Types.CurriculumItemType>
  ) => void;
  onDropNode?: (currentItem: Types.TreeItem<Types.CurriculumItemType>) => void;
}

const TooltipRowContent = ({
  node,
  onDrop,
  visible,
  tabActive,
  setVisible,
  maxSortOrder,
  itemMoveCopySelected,
  handleSelectModeDrag,
  onDropNode,
}: Props) => {
  const [openModalEditLevelCurriculum, setOpenModalEditLevelCurriculum] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [openModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFileAntd<File>>();
  const [fileId, setFileId] = useState<string>();
  const [openPopupCurriculumConfirmPublish, setOpenPopupCurriculumConfirmPublish] =
    useState<boolean>(false);
  const [openModalCreateLevelCurriculum, setOpenModalCreateLevelCurriculum] =
    useState<boolean>(false);
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { filter_conditions, data_user_setting, questionCurriculum } =
    useSelector(curriculumSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const isChecked = useMemo(() => {
    return Boolean(nodeLevel4Selected && node.node && nodeLevel4Selected?.i_id === node.node?.i_id);
  }, [nodeLevel4Selected, node.node]);

  const handleRequiredItem = useCallback(
    async (checked) => {
      if (node.node) {
        dispatch(startLoading());
        await dispatch(
          setRequiredCurriculum({
            id: node.node.i_id!,
            data: {
              item: {
                required_curriculum: checked ? 1 : 0,
                updatedat: new Date(),
              },
              is_force_update: true,
              return_item_result: true,
            },
          })
        );
        dispatch(stopLoading());
      }
      setVisible(false);
    },
    [dispatch, setVisible, node.node]
  );

  const handlePublishItem = useCallback(async () => {
    if (node.node) {
      dispatch(startLoading());
      await dispatch(
        setPublishCurriculum({
          id: node.node.i_id!,
          data: {
            item: {
              publish: node.node.publish ? 0 : 1,
              updatedat: new Date(),
            },
            is_force_update: true,
            return_item_result: true,
          },
        })
      );
      dispatch(removeNodeLevel4Selected());
      setVisible(false);
      setOpenPopupCurriculumConfirmPublish(false);
      await dispatch(
        getCurriculum({
          conditions: [
            ...filter_conditions.conditions,
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    }
  }, [node.node, dispatch, setVisible, filter_conditions.conditions, userInfo?.company_id]);

  const handleSelectNodeLevel4 = useCallback(
    (checked: boolean) => {
      if (checked && node.node && node.node.curriculum_id) {
        dispatch(
          setQuestionCurriculumSelected({
            curriculum_id: node.node.curriculum_id,
            questionCurriculum: questionCurriculum,
          })
        );
        dispatch(selectNodeLevel4({ ...node.node, maxSortOrder }));
      } else {
        dispatch(
          setQuestionCurriculumSelected({
            curriculum_id: '',
            questionCurriculum: questionCurriculum,
          })
        );
        dispatch(removeNodeLevel4Selected());
      }
      setVisible(false);
    },
    [node.node, setVisible, dispatch, questionCurriculum, maxSortOrder]
  );

  const handleDeleteCurriculum = useCallback(async () => {
    if (node) {
      dispatch(startLoading());
      const listChildItemID: Types.FlatChildrenItemID<Types.CurriculumItemType>[] =
        memoizedGetChildrenItemIDFromTree({
          treeData: node.node!,
        });

      if (node.node?.fileID) {
        deletedFileInMinIO(node.node?.fileID);
      }

      const resultActions = await Promise.all([
        ...listChildItemID.map(async (item) => {
          if (item.columnIndex === 0) {
            const resultDeleteCurriculum = await dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
            if (deleteCurriculum.fulfilled.match(resultDeleteCurriculum)) {
              dispatch(
                deleteUserAssignCurriculum({
                  conditions: [
                    {
                      id: 'curriculum_code',
                      search_value: [item.code],
                    },
                  ],
                  use_display_id: true,
                })
              );
            }
            dispatch(
              deleteKnowledge({
                id: item.i_id_knowledge!,
              })
            );
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
        ...(data_user_setting
          .find((curr) => curr.i_id === node.node?.i_id)
          ?.children?.flatMap((department) =>
            department.children?.map((user) =>
              dispatch(
                deleteLinkUserAssignCurriculum({
                  id: user.i_id!,
                })
              )
            )
          ) ?? []),
      ]);
      const questions_delete_length = resultActions.filter(
        (r) => r && deleteLinkQuestion.fulfilled.match(r)
      ).length;
      if (questions_delete_length) {
        const curriculum = listChildItemID.find((curr) => curr.curriculum_id);
        if (curriculum && !listChildItemID.some((item) => item.columnIndex === 0)) {
          await dispatch(
            updateCurriculum({
              id: curriculum.curriculum_id!,
              data: {
                item: {
                  probs_count:
                    curriculum?.problems_count && curriculum?.problems_count > 0
                      ? curriculum?.problems_count - questions_delete_length
                      : 0,
                  updatedat: new Date(),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }
      }
      setVisible(false);
      setOpenModalConfirmDeleteItem(false);
      await Promise.all([
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUserSetting({
            include_lookups: true,
            include_item_ref: true,
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
                exact_match: true,
              },
              {
                id: 'required_curriculum',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    }
  }, [
    node,
    setVisible,
    data_user_setting,
    dispatch,
    filter_conditions?.conditions,
    userInfo?.company_id,
  ]);

  useEffect(() => {
    (async () => {
      const fileQuestion: UploadFile<File> = { uid: '', name: '' };
      dispatch(startLoading());
      if (node?.node?.fileID && visible && !tabActive && fileId !== node?.node?.fileID) {
        const fileName = extractFileName(node?.node?.fileID);
        const nodeFileUrl = await sharedFileInMinIO(node.node?.fileID);
        const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
        Object.assign(
          fileQuestion,
          convertFileResponse({
            file: fileFromUrl,
            fileID: node.node?.fileID,
            fileName: fileName,
          })
        );
        // const resultActionGetAttach = await dispatch(
        //   getAttachCurriculumFile({
        //     conditions: [
        //       {
        //         id: 'fileID',
        //         search_value: [node.node?.fileID],
        //       },
        //     ],
        //     page: 1,
        //     per_page: 1,
        //   })
        // );
        // if (
        //   getAttachCurriculumFile.fulfilled.match(resultActionGetAttach) &&
        //   resultActionGetAttach.payload.items[0]?.file
        // ) {
        //   const fileAction = await dispatch(
        //     getFileAttach({ file_id: resultActionGetAttach.payload.items[0].file })
        //   );
        //   if (getFileAttach.fulfilled.match(fileAction)) {
        //   }
        // }
        setFileId(node.node?.fileID);
        setFiles(fileQuestion);
      }
      dispatch(stopLoading());
    })();
  }, [dispatch, node?.node?.fileID, tabActive, visible, fileId]);

  return tabActive === 0 ? (
    <DivCustom columnIndex={node.columnIndex!}>
      {node.columnIndex === 0 && (
        <div className="rowWrapper bgGrey">
          <div className="rowContentHead">
            <div className="spanHead">
              {node.node?.publish ? (
                <>
                  <img src={IconPublish} className="icon" alt="publish-icon" />
                  <span className="spanText">公開中</span>
                </>
              ) : (
                <>
                  <img src={IconLocked} className="icon" alt="edit-icon" />
                  <span className="spanText">編集中</span>
                </>
              )}
            </div>
            {!!node.node?.required_curriculum && (
              <div className="spanHead">
                <img src={IconRequired} className="icon" alt="required-icon" />
                <span className="spanText">必修カリキュラム</span>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="rowWrapper">
        {files && node?.node?.fileID ? (
          <div className="item-image">
            <img
              src={URL.createObjectURL(new Blob([files.originFileObj as Blob]))}
              className="image"
              alt={files.name}
            />
          </div>
        ) : null}
        {node?.node?.columnIndex === 0 && node?.node?.official_curriculum_code && (
          <div className="label-official">
            <span className="text-official">OFFICIALカリキュラム</span>
          </div>
        )}
        {node.columnIndex === 0 ? (
          <p className="description">{node.node?.description}</p>
        ) : (
          <p className="label-name">{node.node?.name || '（空白）'}</p>
        )}
        {!node.node?.publish && !node.node?.official_curriculum_code && (
          <div className="rowContent">
            {node.columnIndex! < 4 && (
              <Button
                permission={permissionNumber}
                disabled={permissionNumber === 1}
                onClick={() => setOpenModalCreateLevelCurriculum(true)}
              >
                +第{node.columnIndex! + 1}階層追加
              </Button>
            )}
            <div className="icons">
              <EditOutlined
                className="icon"
                disabled={true}
                onClick={() => {
                  if (permissionNumber !== 1) {
                    if (node.columnIndex === 0) {
                      setOpenModalEditCurriculum(true);
                    } else if (node.columnIndex === 5) {
                      setOpenModalCreateQuestion({
                        visible: true,
                        type: 'edit',
                        question_id: node.node?.i_id,
                      });
                    } else {
                      setOpenModalEditLevelCurriculum(true);
                    }
                  }
                }}
              />
              {node.columnIndex! < 5 ? (
                <DeleteOutlined
                  disabled={permissionNumber === 1}
                  className="icon"
                  onClick={() => permissionNumber !== 1 && setOpenModalConfirmDeleteItem(true)}
                />
              ) : (
                <MinusOutlined
                  className="icon"
                  disabled={permissionNumber === 1}
                  onClick={() => setOpenModalConfirmDeleteItem(true)}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {node.columnIndex === 0 ? (
        <>
          <div className="rowWrapper blOrange">
            <div className="rowContent">
              <span>必修カリキュラムに設定</span>
              <Switch
                checked={!!node.node?.required_curriculum}
                onChange={handleRequiredItem}
                size="small"
              />
            </div>
          </div>
          {node.node?.official_curriculum !== '1' && (
            <div className={`rowWrapper ${node.node?.publish ? 'blGreen' : 'blViolet'}`}>
              <div
                className="rowContent"
                onClick={() => setOpenPopupCurriculumConfirmPublish(true)}
              >
                <span>
                  {node.node?.publish ? 'カリキュラムを編集する' : 'カリキュラムを公開する'}
                </span>
                <div>
                  {node.node?.publish ? (
                    <img src={IconPublish} className="icon" alt="publish-icon" />
                  ) : (
                    <img src={IconLocked} className="icon" alt="edit-icon" />
                  )}
                  <CaretRightOutlined className="caretIcon" />
                  {node.node?.publish ? (
                    <img src={IconLocked} className="icon" alt="edit-icon" />
                  ) : (
                    <img src={IconPublish} className="icon" alt="publish-icon" />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        node.columnIndex === 4 &&
        !node.node?.publish &&
        !node.node?.official_curriculum_code && (
          <div className="rowWrapper blRed">
            <div className="rowContent">
              <span>設問追加先に設定</span>
              <Switch size="small" checked={isChecked} onChange={handleSelectNodeLevel4} />
            </div>
          </div>
        )
      )}
      <CreateLevelCurriculum
        node={node}
        maxSortOrder={maxSortOrder}
        visible={openModalCreateLevelCurriculum}
        setVisible={setOpenModalCreateLevelCurriculum}
        setVisibleTooltip={setVisible}
      />
      <EditLevelCurriculum
        node={node}
        visible={openModalEditLevelCurriculum}
        setVisible={setOpenModalEditLevelCurriculum}
      />
      <CreateQuestion
        setVisibleSuccess={setVisibleSuccess}
        openModalCreateEditQuestion={openModalCreateQuestion}
        setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
      />
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
      <PopupCurriculumConfirmPublish
        onSubmit={handlePublishItem}
        nodeLocked={!!node.node?.publish}
        visible={openPopupCurriculumConfirmPublish}
        data={node?.node}
        setVisible={setOpenPopupCurriculumConfirmPublish}
      />
      <CreateEditCurriculum
        type="edit"
        textSubmit="更新"
        id={node.node?.i_id}
        title="カリキュラム編集"
        name={node.node?.name}
        visible={openModalEditCurriculum}
        data={node?.node}
        description={node.node?.description}
        setVisible={setOpenModalEditCurriculum}
        subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
      />
      <ConfirmDeleteModal
        title="削除確認"
        subTitle="データの削除を実行します。"
        onSubmit={handleDeleteCurriculum}
        visible={openModalConfirmDeleteItem}
        setVisible={setOpenModalConfirmDeleteItem}
        description={
          <>
            データの削除を実行すると、復元できませんのでご注意ください。
            <br />
            <span style={{ color: 'red' }}>※ただし、設問は削除されません</span>
          </>
        }
      />
    </DivCustom>
  ) : node.columnIndex === 0 ? (
    <DivCustom columnIndex={0}>
      <div className="rowWrapper bgGrey">
        <div className="rowContentHead">
          <div className="spanHead">
            {node.node?.publish ? (
              <>
                <img src={IconPublish} className="icon" alt="publish-icon" />
                <span className="spanText">公開中</span>
              </>
            ) : (
              <>
                <img src={IconLocked} className="icon" alt="edit-icon" />
                <span className="spanText">編集中</span>
              </>
            )}
          </div>
          {!!node.node?.required_curriculum && (
            <div className="spanHead">
              <img src={IconRequired} className="icon" alt="required-icon" />
              <span className="spanText">必修カリキュラム</span>
            </div>
          )}
        </div>
      </div>
      <div className="rowWrapper">
        <p className="description">{node.node?.description}</p>

        {permissionNumber !== 1 && (
          <Button
            className="button-copy"
            onClick={() => {
              if (node && onDropNode) {
                setVisible(false);
                onDropNode(node);
              }
            }}
          >
            <PlusOutlined /> &nbsp; コピー追加
          </Button>
        )}
      </div>
    </DivCustom>
  ) : node.parentNode ? (
    itemMoveCopySelected && itemMoveCopySelected.node.columnIndex! - 1 === node.columnIndex ? (
      <DivCustomCanDrag isPublish={node.node?.publish}>
        <div className="wrap-content">
          <p>
            「{itemMoveCopySelected.node.node?.name || '（空白）'}
            」を選択した階層の下にペーストします。
          </p>
        </div>
        <div className="wrap-button-drop">
          <button
            className="button ok button-ok"
            onClick={() => {
              if (node.node && onDrop && node.node?.publish !== 1) {
                setVisible(false);
                onDrop(itemMoveCopySelected.type, itemMoveCopySelected.node, node.node);
              }
            }}
          >
            OK
          </button>
          <button className="button cancel" onClick={() => setVisible(false)}>
            キャンセル
          </button>
        </div>
      </DivCustomCanDrag>
    ) : (
      <DivCustomCanDrag>
        <div className="wrap-content">
          <p>アクションを選択してください</p>
        </div>
        <div className="wrap-button">
          <button
            disabled={node.node?.publish === 1 || permissionNumber === 1}
            className={node.node?.publish || permissionNumber === 1 ? 'disabled' : 'button'}
            onClick={() => handleSelectModeDrag(node, 'move')}
          >
            <img src={MoveWhite} alt="move-icon" />
            移動
          </button>
          <button className={'button'} onClick={() => handleSelectModeDrag(node, 'copy')}>
            <PlusOutlined /> &nbsp; コピー追加
          </button>
        </div>
      </DivCustomCanDrag>
    )
  ) : null;
};

export default TooltipRowContent;
