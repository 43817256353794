import React, { useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { generatePath, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Button, Select, Table } from 'antd';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  SearchOutlined,
  FormOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {
  deleteRoles,
  getDataRoles,
  getDataRolesCSV,
  getDataSearchUsers,
  searchDataRoles,
} from './thunk';
import { HEADER_ROLES_MASTER_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import PDFRolesMasterTableDocument from './PDFRolesMasterTableDocument';
import ConfirmDeleteRoles from 'components/Modal/CompletedDeleteRoles';
import ActionErrorModal from 'components/Modal/ActionError';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { rolesMasterSelector } from './selectors';
import { Header, SelectField } from 'components';
import { routes } from 'navigations/routes';
import RolesMasterStyled from './styles';
import * as Types from 'types';
import { find, get } from 'lodash';

const { Option } = Select;

const RolesMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showWarningDeleteModal, setShowWarningDeleteModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [deleteItemId, setDeleteItemId] = useState<string>('');
  const [searchValue, setSearchValue] = useState<string>();
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const { roles, total, searchResult, list_users } = useSelector(rolesMasterSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const searchFormik = useFormik<Types.SearchRolesMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: ({ name }, { setSubmitting }) => {
      if (!name) {
        setShowActionErrorModal(true);
      } else {
        setSearchValue(name);
        setPage(1);
      }
      setSubmitting(false);
    },
    onReset: () => {
      setSearchValue(undefined);
    },
  });

  const handleDeleteItem = (item: Types.Roles.ResponseType) => {
    const roleFound = find(
      list_users,
      (user) => get(user, 'lookup_items.role_code.i_id') === item.i_id
    );
    setDeleteItemId(!roleFound ? item.i_id : '');
    setOpenModalConfirmDeleteItem(true);
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const handleExportCSV = async (value: string) => {
    if (!['pdf', 'csv'].includes(value)) return;

    if (value === 'csv') {
      dispatch(startLoading());
      const resultAction = await dispatch(
        getDataRolesCSV({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getDataRolesCSV.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.items.map((item) => ({
          code: item.code,
          company_id: item.company_id,
          name: item.name,
          admin_dashboard_permission: item.admin_dashboard_permission,
          departments_master_permission: item.departments_master_permission,
          users_master_permission: item.users_master_permission,
          admin_master_permission: item.admin_master_permission,
          curricullum_master_permission: item.curricullum_master_permission,
          groups_master_permission: item.groups_master_permission,
          skill_check_master_permission: item.skill_check_master_permission,
          bundle_master_permission: item.bundle_master_permission,
          report_view_permission: item.report_view_permission,
          roles_master_permission: item.roles_master_permission,
          positions_master_permission: item.positions_master_permission,
          payment_master_permission: item.payment_master_permission,
          timer_master_permission: item.timer_master_permission,
          question_master_permission: item.question_master_permission,
          skill_check_tree_permission: item.skill_check_tree_permission,
          inter_users_master_permission: item.inter_users_master_permission,
          updatedby: item.updatedby,
          createdby: item.createdby,
        }));

        const csvString = [
          HEADER_ROLES_MASTER_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '役職マスタ.csv');
      }
      dispatch(stopLoading());
      setShowConfirmExportFileModal(false);
    } else {
      const blob = await pdf(<PDFRolesMasterTableDocument roles={roles} />).toBlob();
      saveAs(blob, 'roles_master_table.pdf');
      setShowConfirmExportFileModal(false);
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataRoles({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(searchValue
              ? [
                  {
                    id: 'name',
                    search_value: [searchValue],
                  },
                ]
              : []),
          ],
          page: page,
          per_page: perPage,
        })
      );

      dispatch(stopLoading());
    })();
  }, [dispatch, page, userInfo, searchValue, perPage]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          searchDataRoles({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getDataSearchUsers({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
          })
        ),
      ]);

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <RolesMasterStyled>
      <Header title={messages['M-01-1'] as string} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            {messages['M-01-2']}
            <br />
            {messages['M-01-3']}
          </p>
          <div className="border" />
          <FormikProvider value={searchFormik}>
            <Form layout="vertical" colon={false} className="form-search">
              <Form.Item
                name="name"
                label={<span className="label"> 権限</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder={messages['M-01-5'] as string}
                  name="name"
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {searchResult.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  {messages['M-01-6']}
                </SubmitButton>
                <ResetButton className="label-reset">
                  <span className="label-text"> {messages['M-01-7']}</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          <div className="text-count-general">
            {total > 0 ? (
              <div className="text-count">
                {(page - 1) * perPage + 1} - {''}
                {page * perPage > total ? total : page * perPage} / {total}件
              </div>
            ) : null}
            <div className="page-select">
              <div className="label">
                <span>表示件数</span>：
              </div>
              <Select value={perPage} onSelect={handleSelectChange}>
                {[10, 20, 50, 100].map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="btn-div">
            <Button
              className="btn btn-active"
              disabled={!roles.length}
              icon={<CloudDownloadOutlined className="icon-cloud" />}
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              {messages['M-01-10']}
            </Button>
            <Button
              className="btn btn-active"
              onClick={() => navigate(generatePath(routes.CreateRolesMaster.path))}
              icon={<PlusOutlined className="size-icon" />}
            >
              {messages['M-01-11']}
            </Button>
          </div>
          <Table
            rowKey="index"
            dataSource={roles.map((item, index) => ({ ...item, index }))}
            className={roles.length > 0 ? 'table' : 'table_mr'}
            pagination={{
              pageSize: perPage,
              showSizeChanger: false,
              total: total,
              current: page,
              onChange: setPage,
              position: ['topCenter'],
            }}
          >
            <Table.Column
              key="code"
              className="column"
              width="10%"
              align="center"
              title="コード"
              dataIndex="code"
              render={(item) => <span className="code-column">{item}</span>}
            />
            <Table.Column key="name" className="column" width="74%" title="名称" dataIndex="name" />
            <Table.Column
              key="edit"
              className="column"
              width="8%"
              title="編集"
              align="center"
              render={(item) => (
                <FormOutlined
                  className="icon"
                  onClick={() =>
                    navigate(generatePath(routes.EditRolesMaster.path, { id: item.i_id }))
                  }
                />
              )}
            />
            <Table.Column
              key="delete"
              className="column"
              width="8%"
              title="削除"
              render={(item: Types.Roles.ResponseType) => (
                <DeleteOutlined className="icon" onClick={() => handleDeleteItem(item)} />
              )}
            />
          </Table>
        </div>
      </div>
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="検索するマスタが未選択です"
        description={
          <p className="text-content">
            検索する権限を選択後、
            <br />
            「検索」をクリックしてください。
          </p>
        }
      />
      <ActionErrorModal
        visible={showWarningDeleteModal}
        setVisible={setShowWarningDeleteModal}
        subTitle="削除に失敗しました"
        description="マスタの削除に失敗しました。 再度お試しください。"
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="削除が完了しました"
      />
      <ConfirmDeleteRoles
        deleteItemId={deleteItemId}
        visible={openModalConfirmDeleteItem}
        title={deleteItemId ? '削除確認' : '削除確認'}
        subTitle={deleteItemId ? 'データの削除を実行します' : 'データの削除は実行できません。'}
        description={
          deleteItemId
            ? 'データの削除を実行すると、復元できませんのでご注意ください。'
            : '選択したマスタは社内ユーザーに設定されているため、削除できません。'
        }
        onSubmit={async () => {
          if (!deleteItemId) return;
          dispatch(startLoading());
          const resultAction = await dispatch(deleteRoles({ id: deleteItemId }));
          if (deleteRoles.fulfilled.match(resultAction)) {
            await dispatch(
              getDataRoles({
                conditions: [
                  {
                    id: 'company_id',
                    search_value: [userInfo?.company_id],
                  },
                ],
                page: page,
                per_page: 20,
              })
            );
            setShowCompleteModal(true);
          } else {
            setShowWarningDeleteModal(true);
          }
          dispatch(stopLoading());
        }}
        onCancel={() => setDeleteItemId('')}
        setVisible={setOpenModalConfirmDeleteItem}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </RolesMasterStyled>
  );
};

export default RolesMaster;
