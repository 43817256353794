import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.div<Props>`
  margin: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #cccccc;
    .title {
      margin: 0;
      font-size: 16px;
      color: #2a2a2a;
    }
  }
  .form {
    padding: 40px;
    width: 70%;
    .form-input {
      .radio_group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        .wrap-radio {
          width: 20%;
          .radio_item {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            height: 80px;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            margin-right: 0;
            .ant-radio {
              position: absolute;
              top: 6px;
              left: 6px;
            }
            & > span:nth-child(2) {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80%;
              height: 100%;
            }
          }
          .wrap-radio-image {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 80px;
            border: 1px solid #eaeaea;
            background-color: #ffffff;
            border-radius: 4px;
          }
        }
        .border-right {
          border-right: 1px solid #d9d9d9;
        }
        .border-radius-left {
          border: 1px solid #d9d9d9;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        .border-radius-right {
          border: 1px solid #d9d9d9;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: none;
        }
        .label-text {
          font-size: 11px;
          margin: 5px 0 0 0;
          text-align: center;
          color: #777777;
        }
        .disabled .radio_item {
          border: 1px solid #d9d9d9;
          border-radius: 3px;
          cursor: not-allowed;
        }
      }
      .text-label {
        font-size: 13px;
        color: #424242;
        .require {
          color: #f0330a;
        }
      }
      .input,
      .input_small {
        height: 40px !important;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
      }
      .input_small {
        width: 83px;
      }
      .input_small_two {
        width: 20%;
      }
      .input-card-number {
        height: 40px;
        color: #424242;
        font-size: 14px;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 10px;
      }
      .pull_down {
        font-size: 14px;
        color: #424242;
        border-radius: 4px;
        :not(.ant-select-customize-input) .ant-select-selector {
          width: 100%;
          height: 40px;
          padding: 3px 11px;
        }
      }
      .text-label-content {
        font-size: 12px;
        color: #777777;
        margin: 0 10px 0 7px;
      }
      .ant-input-affix-wrapper-disabled {
        border-color: #eaeaea;
        background-color: #f5f5f5;
      }
      .wrap-security {
        width: 100%;
      }
    }
    .flex {
      .ant-form-item-control-input-content {
        display: flex;
        .content-text {
          font-size: 12px;
          color: #00a3a5;
          margin: 0 0 0 20px;
        }
      }
    }
    .wrap-submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .btn-submit {
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
        font-size: 15px;
        border: none;
        height: 40px;
        padding: 0 50px;
        cursor: pointer;
      }
      .btn-back-info {
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        padding: 8px 51px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
`;
