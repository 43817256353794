import React, { useState } from 'react';
import { UploadFile, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { FormOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { CreateEditCurriculum, EditLevelCurriculum } from '../../Modal';
import { CreateQuestion } from 'pages/Settings/QuestionMaster/Modal';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { sharedFileInMinIO } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import CompletedModal from 'components/Modal/Completed';
import { curriculumSelector } from '../../selectors';
import { ErrorBoundary, Modal } from 'components';
import { VisiblePopupEditType } from '..';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';

interface Props {
  page?: number;
  per_page?: number;
  visible: VisiblePopupEditType;
  setVisible: React.Dispatch<React.SetStateAction<VisiblePopupEditType>>;
}

const EditModal: React.FC<Props> = ({
  page,
  per_page,
  visible: { visible, index, publish },
  setVisible,
}) => {
  const [isOpenModalEditCurriculum, setOpenModalEditCurriculum] = useState<boolean>(false);
  const [isOpenModalEditLevel, setOpenModalEditLevel] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [files, setFiles] = useState<UploadFileAntd<File>>();

  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const [itemEdit, setItemEdit] = useState<{
    id: string;
    name?: string;
    level?: number;
    description?: string;
  }>();

  const { curricullumMaster } = useSelector(curriculumSelector);

  const dispatch = useAppDispatch();

  const handleGetFileCurriculum = async () => {
    const fileQuestion: UploadFile<File> = { uid: '', name: '' };
    dispatch(startLoading());
    if (curricullumMaster[index].fileID) {
      const fileName = extractFileName(curricullumMaster[index].fileID);
      const nodeFileUrl = await sharedFileInMinIO(curricullumMaster[index].fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
      Object.assign(
        fileQuestion,
        convertFileResponse({
          file: fileFromUrl,
          fileID: curricullumMaster[index].fileID,
          fileName: fileName,
        })
      );
      setFiles(fileQuestion);
    }
    dispatch(stopLoading());
  };

  return (
    <Modal
      title={<h3 className="title">カリキュラムマスタ階層編集</h3>}
      visible={visible}
      width={860}
      onCancel={() =>
        setVisible({
          visible: false,
          index: -1,
        })
      }
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: () =>
          setVisible({
            visible: false,
            index: -1,
          }),
      }}
    >
      <SectionStyled publish={publish}>
        <p className="sub-title">編集したい階層の編集ボタンをボタンをクリックしてください。</p>
        {curricullumMaster[index]?.curriculum_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              カリキュラム名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index].curriculum_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.curriculum_code.i_id,
                        name: curricullumMaster[index].curriculum_name,
                        description: curricullumMaster[index].curriculum_description,
                      });
                      setOpenModalEditCurriculum(true);
                      handleGetFileCurriculum();
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level1_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第１階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level1_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.level1_code.i_id,
                        level: 1,
                        name: curricullumMaster[index].level1_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level2_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第２階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level2_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.level2_code.i_id,
                        level: 2,
                        name: curricullumMaster[index].level2_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level3_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第３階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level3_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.level3_code.i_id,
                        level: 3,
                        name: curricullumMaster[index].level3_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.level4_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              第４階層名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.level4_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.level4_code.i_id,
                        level: 4,
                        name: curricullumMaster[index].level4_name,
                      });
                      setOpenModalEditLevel(true);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {curricullumMaster[index]?.question_code && (
          <Row className="row">
            <Col span={4} className="row-field">
              設問名
            </Col>
            <Col span={20} className="row-value">
              <Row>
                <Col span={20} className="text-value">
                  {curricullumMaster[index]?.question_name}
                </Col>
                <Col span={4} className="text-icon">
                  <FormOutlined
                    className="icon"
                    disabled={publish}
                    onClick={() => {
                      if (!curricullumMaster[index].item_ref || publish) return;
                      setItemEdit({
                        id: curricullumMaster[index].item_ref!.question_code.i_id,
                      });
                      setOpenModalCreateQuestion({
                        visible: true,
                        type: 'edit',
                        question_id: curricullumMaster[index].item_ref!.question_code.i_id,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </SectionStyled>
      <CreateEditCurriculum
        page={page}
        per_page={per_page}
        type="edit"
        textSubmit="更新"
        id={itemEdit?.id}
        title="カリキュラム編集"
        name={itemEdit?.name}
        fileCurriculum={files}
        visible={isOpenModalEditCurriculum}
        description={itemEdit?.description}
        data={curricullumMaster[index]}
        setVisible={setOpenModalEditCurriculum}
        subTitle="カリキュラム名・説明の編集が可能です。編集後に更新ボタンをクリックしてください。"
      />
      <EditLevelCurriculum
        node={{ node: { i_id: itemEdit?.id, name: itemEdit?.name }, columnIndex: itemEdit?.level }}
        page={page}
        per_page={per_page}
        visible={isOpenModalEditLevel}
        setVisible={setOpenModalEditLevel}
      />
      <ErrorBoundary>
        <CreateQuestion
          page={page}
          setVisibleSuccess={setVisibleSuccess}
          openModalCreateEditQuestion={openModalCreateQuestion}
          setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
        />
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="登録が完了しました"
          onSubmit={() => {
            setVisibleSuccess(!visibleSuccess);
          }}
        />
      </ErrorBoundary>
    </Modal>
  );
};

export default EditModal;
