import styled from 'styled-components';

export const CompanyExpiredStyled = styled.section`
  width: calc(100% - 40px) !important;
  overflow: auto;
  height: 100vh;
  .ant-modal-content {
    background: rgba(249, 248, 248, 1);
  }
  .form-style {
    padding: 10px 40px;
  }
  .title-header {
    .title {
      position: relative;
      text-align: center;
      font-size: 30px;
      padding-bottom: 50px;
    }
    .title:after {
      position: absolute;
      content: '';
      border-bottom: 1px solid rgba(196, 196, 196, 1);
      top: 90px;
      width: 150px;
      left: 45%;
    }
    .text-center {
      text-align: center;
      color: #777777;
      padding-top: 40px;
    }
  }
  .form {
    border-radius: 5px;
    padding: 40px;
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    .step-top {
      border: 1px solid #ccc;
      height: 485px;
      .step-title {
        display: flex;
        justify-content: space-between;
        padding: 24px 32px;
        .title-left {
          height: 300px;
          background: rgba(255, 255, 255, 1);
          .name {
            text-align: center;
            color: white;
            font-weight: 700;
            height: 28px;
            width: 230px;
            background: rgba(0, 163, 165, 1);
          }
          .title-name-border {
            font-size: 12px;
            color: rgba(0, 163, 165, 1);
            text-align: center;
          }
          .title-border {
            border-bottom: 1px solid #ccc;
            padding-bottom: 20px;
            font-size: 40px;
            color: rgba(0, 163, 165, 1);
            text-align: center;
            .month {
              font-size: 16px;
            }
          }
        }
        .total {
          width: 100px;
          display: flex;
          justify-content: center;
          font-size: 40px;
          color: rgba(246, 172, 0, 1);
          align-items: center;
          height: 310px;
        }
        .total-right {
          margin-top: 65px;
        }
        .gourd-left {
          display: flex;
          .form-step {
            padding: 24px;
            background: rgba(245, 253, 253, 1);
            .title-gourd {
              font-weight: 700;
              font-size: 16px;
              color: rgba(0, 163, 165, 1);
              text-align: center;
            }
          }
          .title {
            display: flex;
          }
        }
      }
      .bottom {
        margin: 0 0 16px 32px;
      }
    }

    .form-input {
      .input-review {
        padding: 16px;
        border: 1px solid rgba(204, 204, 204, 1);
        border-radius: 3px;
      }
      .review {
        margin-right: 24px;
      }
      .review:before {
        content: '';
        position: absolute;
        top: 0;
        left: 155px;
        height: 55px;
        border-right: 1px solid rgba(204, 204, 204, 1);
      }
      .require {
        color: #f0330a;
      }

      .input {
        height: 40px;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;

        &__disabled {
          background-color: #f9f8f8;
        }

        ::placeholder {
          color: #cccccc;
          font-size: 14px;
        }
      }

      .text-label {
        font-size: 13px;
        display: block;
        text-align: right;
        color: #777777;

        .require {
          color: #f0330a;
        }
      }
    }

    .wrap-input-zip-code {
      width: 60%;

      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;

        .input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        .btn-check-zipCode {
          font-size: 12px;
          width: 50%;
          color: #00a3a5;
          border-top: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          background-color: #ffffff;
          border-left: none;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          cursor: pointer;
        }
      }
    }

    .error-input-zip-code {
      .btn-check-zipCode {
        border-color: #ff4d4f !important;
      }
    }
    .btn-submit {
      text-align: center;
      .btn {
        font-weight: 700;
        border-radius: 5px;
        font-size: 15px;
        height: 48px;
        width: 280px;
        color: white;
        background: rgba(246, 172, 0, 1);
      }
    }
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0 20px 0;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
  }
`;
