import React, { useEffect } from 'react';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { generatePath, Link } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { getDataCompany, isFirstLogin, signIn, updateLoginTime } from 'containers/Auth/thunk';
import { getPlans } from 'containers/InformationRegister/thunk';
import { Input, InputPassword, Checkbox } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import AuthLayout from 'containers/Auth/Layout';
import { loginSchema } from 'libs/validations';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { LoginFormik } from 'types';
import { Centering } from 'assets';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const { messages } = useIntl();
  const { isOpenMessages } = useSelector(authSelector);

  const formik = useFormik<LoginFormik>({
    initialValues: {
      email: '',
      password: '',
      save: false,
    },
    validationSchema: loginSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        signIn({
          user_code: values.email,
          password: values.password,
          exclusive_w_id: '655983b2942ff9ef0f4a0081',
        })
      );
      if (signIn.fulfilled.match(resultAction)) {
        localStorage.setItem('email', values.email);
        if (values.save) {
          localStorage.setItem('remember_me', 'saved');
        } else {
          localStorage.removeItem('remember_me');
        }
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [values.email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            page: 1,
            per_page: 1,
          })
        );
        await Promise.all([
          dispatch(
            getDataCompany({
              conditions: [
                {
                  id: 'admin_email',
                  search_value: [values.email],
                  exact_match: true,
                },
              ],
              page: 1,
              per_page: 1,
            })
          ),
          dispatch(
            getPlans({
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        if (
          isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
          resultActionIsFirstLogin.payload.totalItems
        ) {
          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
              un_realtime: true,
            })
          );
        }
      } else {
        formik.setErrors({
          email: '',
          password: messages['L-01-7'] as string,
        });
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('email', localStorage.getItem('email') || '');
    formik.setFieldValue('save', localStorage.getItem('remember_me') === 'saved');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={Centering} alt="logo" className="logo" />
        <FormikProvider value={formik}>
          <Form
            className="form"
            autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
          >
            <Form.Item name="email">
              <Input
                name="email"
                type="email"
                placeholder={messages['L-01-1'] as string}
                className="input"
                autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                prefix={<MailOutlined className="icon" />}
              />
            </Form.Item>
            <Form.Item name="password">
              <InputPassword
                className="input"
                name="password"
                placeholder={messages['L-01-2'] as string}
                autoComplete={localStorage.getItem('remember_me') === 'saved' ? 'on' : 'off'}
                prefix={<LockOutlined className="icon" />}
              />
            </Form.Item>
            {isOpenMessages && (
              <div className="messages">
                ご利用のアカウントは有効ではありません。
                <br />
                ご不明な場合はお問い合わせをお願いいたします。
              </div>
            )}
            <Form.Item name="save">
              <Checkbox name="save" className="checkbox">
                {messages['L-01-3']}
              </Checkbox>
            </Form.Item>
            <SubmitButton className="btn-submit">{messages['L-01-4']}</SubmitButton>
          </Form>
        </FormikProvider>
        <div className="wrap-bottom">
          <p className="register">
            {messages['L-01-5.1']}
            <Link
              className="text-link"
              to={generatePath(routes.Register.path, { entity: 'receiving' })}
            >
              {messages['L-01-5.2']}
            </Link>
          </p>
          <Link
            className="text-link"
            to={generatePath(routes.ResetPassword.path, { entity: 'receiving' })}
          >
            {messages['L-01-6']}
          </Link>
        </div>
      </SectionStyled>
    </AuthLayout>
  );
};

export default Login;
