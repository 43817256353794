import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Col, Progress, Row } from 'antd';
import { useSelector } from 'react-redux';

import { trainingCurriculumSelector } from 'pages/TrainingCurriculum/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { DivCustom, Wrapper } from './styles';
import { NoImageCurriculum } from 'assets';
import * as Types from 'types';

interface Props {
  visible: boolean;
  handleSubmit: () => Promise<void>;
  node: Types.TreeItem<Types.TrainingCurriculumItemType>;
}

const TooltipRowContent: React.FC<Props> = ({ node, handleSubmit, visible }) => {
  const [rateCorrectImple, setRateCorrectImple] = useState<number>(0);
  const [rateCorrect, setRateCorrect] = useState<number>(0);

  const { questionSelected } = useSelector(trainingCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  useEffect(() => {
    const numberQuestion = questionSelected.length;
    if (numberQuestion) {
      const numberCorrectImple = questionSelected.filter(
        (item) => item.correct_true === 0 || item.correct_true
      ).length;
      const numberCorrect = questionSelected.filter((item) => item.correct_true).length;

      setRateCorrectImple((numberCorrectImple / numberQuestion) * 100);
      setRateCorrect((numberCorrect / numberQuestion) * 100);
    }
  }, [questionSelected, userInfo?.login_id]);

  return node.node?.columnIndex !== 5 ? (
    <DivCustom columnIndex={node.columnIndex!}>
      {!node.node?.columnIndex ? (
        <div className="rowWrapper">
          {node.node?.official_curriculum_code && <span className="label-official">OFFICIAL</span>}
          {!node.node?.curriculum_file ? (
            <div className="item-no-image">
              <img src={NoImageCurriculum} className="no-image" alt="" />
              <div className="text">no image</div>
            </div>
          ) : (
            <div className="item-image">
              <img src={node.node.curriculum_file} className="image" alt="" />
            </div>
          )}
        </div>
      ) : (
        <div className="rowWrapper">
          <div className="line" />
        </div>
      )}
      <div className="rowWrapper">
        <Wrapper>
          <Row className="progress-bar">
            <Col className="progress-col">
              <Row className="progress-bar-title">
                <Col span={12} className="progress-bar-title-text">
                  <span className="label-name">{node.node?.name}</span>
                </Col>
                <Col span={12} className="progress-bar-count">
                  <span>
                    {questionSelected.filter((item) => item.correct_true).length}
                    問正解/
                    {questionSelected.length}問中
                  </span>
                </Col>
              </Row>
              <Row className="progress-bar-display">
                <Progress
                  type="line"
                  className="progress-bar-bars"
                  percent={100}
                  steps={20}
                  strokeWidth={20}
                  strokeColor={[
                    ...Array(Math.round(rateCorrect * 0.2)).fill('#6EC9AB'),
                    ...Array(Math.round((rateCorrectImple - rateCorrect) * 0.2)).fill('#DDEACD'),
                  ]}
                  showInfo={false}
                />
              </Row>
              <Row className="progress-bar-number">
                <Col span={13} className="progress-bar-number-text">
                  <div className="label-number">
                    <div className="point" />
                    <span className="label">正解進捗率</span>
                    <span className="number-label">
                      {Math.round(rateCorrect)}
                      <small>%</small>
                    </span>
                  </div>
                </Col>
                <Col span={11} className="progress-bar-number-text">
                  <div className="label-number">
                    <div className="point-first" />
                    <span className="label">進捗率</span>
                    <span className="number-label first">
                      {Math.round(rateCorrectImple)}
                      <small>%</small>
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Wrapper>
      </div>
      <div className="wrap-btn">
        <button className="btn-start" onClick={handleSubmit}>
          トレーニング開始
        </button>
      </div>
    </DivCustom>
  ) : (
    <DivCustom columnIndex={node.columnIndex!}>
      <div className="rowWrapper">
        <div className="line" />
      </div>
      <div className="rowWrapper">
        <div className="status">
          <div className="label">実施状況：</div>
          <div className="correct">
            {node.node.correct_true ? (
              <div className="correct-true">
                <div className="item-circle" />
                正解
              </div>
            ) : node.node.correct_true === 0 ? (
              <div className="item-close">
                <CloseOutlined className="icon-close" />
                不正解
              </div>
            ) : (
              '未実施'
            )}
          </div>
        </div>
      </div>
      <div className="wrap-btn done">
        <button className="btn-start" disabled={!questionSelected.length} onClick={handleSubmit}>
          トレーニング開始
        </button>
      </div>
    </DivCustom>
  );
};

export default TooltipRowContent;
