import React, { useEffect, useState } from 'react';
import { DeleteOutlined, EyeInvisibleOutlined, UserOutlined } from '@ant-design/icons';
import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { differenceBy, maxBy, orderBy, some } from 'lodash';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { downloadFileFromMinio } from 'services/minioService';
import { convertFileResponse } from 'libs/utils/question';
import { authSelector } from 'containers/Auth/selectors';
import { Header, Input, SelectField } from 'components';
import CompletedModal from 'components/Modal/Completed';
import { updateUserSchema } from 'libs/validations';
import { extractFileName } from 'libs/utils/format';
import { AFFILIATION_ASSIGN_ROLE } from 'configs';
import { employeeSelector } from '../selectors';
import { CREDIT_CARD_MONTH } from 'constant';
import { routes } from 'navigations/routes';
import { OrderByType } from 'types/config';
import { useAppDispatch } from 'hooks';
import EditUserStyled from './styles';
import * as Types from 'types';
import {
  getDataRequiredCurriculumSettings,
  getDataRequiredSkillcheckSettings,
  createAffiliationAssignLevel,
  createUserAssignCurriculum,
  createUserAssignSkillCheck,
  updateUserAssignSkillCheck,
  getAffiliationAssignLevel,
  updateAffiliationLevel,
  createAffiliationRole,
  deleteAffiliationRole,
  updateAffiliationRole,
  getSelectDepartment,
  addLinkAffiliation,
  getSelectPosition,
  getUserInfoDetail,
  updateCurriculum,
  getSubPosition,
  updateEmployee,
  getSelectRole,
  deleteAffiliationLevel,
} from '../thunk';
import {
  CurriculumHiddenListModal,
  SkillCheckHiddenListModal,
  CurriculumBatchModal,
  SkillCheckModal,
} from '../Modal';

const { Option } = Select;
// const { Dragger } = Upload;

const EditEmployee: React.FC = () => {
  const [visibleCurriculumBatchModal, setVisibleCurriculumBatchModal] = useState<boolean>(false);
  const [dataSkillCheck, setDataSkillCheck] = useState<Array<Types.SkillCheck.ResponseType>>([]);
  const [visibleSkillCheckModal, setVisibleSkillCheckModal] = useState<boolean>(false);
  const [listOldPosition, setListOldPosition] = useState<Array<Types.PositionType>>([]);
  const [orderTypeCurriculum, setOrderTypeCurriculum] = useState<OrderByType>('asc');
  const [orderTypeSkillCheck, setOrderTypeSkillCheck] = useState<OrderByType>('asc');
  const [userDetail, setUserDetail] = useState<Types.Users.ResponseType>();
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [dataCurriculumBatch, setDataCurriculumBatch] = useState<
    Array<Types.Curriculums.ResponseType>
  >([]);
  const [visibleCurriculumHiddenListModal, setVisibleCurriculumHiddenListModal] =
    useState<boolean>(false);
  const [visibleSkillCheckHiddenListModal, setVisibleSkillCheckHiddenListModal] =
    useState<boolean>(false);
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataCurriculumUserCheck,
    dataSelectDepartment,
    dataSelectPosition,
    dataSkillUserCheck,
    dataSelectRole,
    dataAffiliationAssignLevel,
  } = useSelector(employeeSelector);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<Types.EditEmployeeFormik>({
    initialValues: {
      employee_code: '',
      email: '',
      name: '',
      name_furigana: '',
      role_code: '',
      dob: '',
      doj: '',
      main_position: {
        position_id: '',
        affiliation_id: '',
        position_code: '',
        sort_order: 1,
      },
      sub_position: [
        {
          position_id: '',
          affiliation_id: '',
          position_code: '',
          sort_order: 0,
        },
      ],
    },
    enableReinitialize: true,
    validationSchema: updateUserSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!id) return;
      dispatch(startLoading());

      const resultAction = await Promise.all([
        (!userDetail ||
          userDetail.name !== values.name ||
          userDetail.email !== values.email ||
          userDetail.lookup_items?.role_code.i_id !== values.role_code ||
          userDetail.name_furigana !== values.name_furigana ||
          userDetail.employee_code !== values.employee_code ||
          !dayjs(dayjs(userDetail.dob).toDate()).isSame(dayjs(dayjs(values.dob).toDate())) ||
          !dayjs(dayjs(userDetail.doj).toDate()).isSame(dayjs(dayjs(values.doj).toDate()))) &&
          dispatch(
            updateEmployee({
              id,
              data: {
                item: {
                  dob: dayjs(values.dob).toDate(),
                  doj: dayjs(values.doj).toDate(),
                  name: values.name,
                  role_code: values.role_code,
                  name_furigana: values.name_furigana,
                  employee_code: values.employee_code,
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          ),
        ...dataCurriculumBatch.map(({ code }) =>
          dispatch(
            createUserAssignCurriculum({
              item: {
                curricullum_code: code,
                login_id: userDetail?.email,
                company_id: userDetail?.company_id,
                hidden: 'off',
                createdby: userDetail?.login_id,
                createdat: new Date(),
              },
            })
          )
        ),
        ...dataSkillCheck.map(({ code }) =>
          dispatch(
            createUserAssignSkillCheck({
              item: {
                skill_check_code: code,
                login_id: userDetail?.email,
                company_id: userDetail?.company_id,
                hidden: 'off',
                createdby: userInfo?.login_id,
                createdat: new Date(),
              },
            })
          )
        ),
        listOldPosition[0].main_role === 'main' &&
          (listOldPosition[0].affiliation_id !== values.main_position.affiliation_id ||
            listOldPosition[0].position_code !== values.main_position.position_code) &&
          dispatch(
            updateAffiliationRole({
              id: values.main_position.position_id,
              data: {
                item: {
                  affiliation_id: values.main_position.affiliation_id,
                  positions_code: values.main_position.position_code,
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          ),
        listOldPosition[0].main_role === 'main' &&
          (listOldPosition[0].affiliation_id !== values.main_position.affiliation_id ||
            listOldPosition[0].position_code !== values.main_position.position_code) &&
          dataAffiliationAssignLevel.filter(
            (item) => item.sort_order === values.main_position.sort_order
          ) &&
          dispatch(
            updateAffiliationLevel({
              id: dataAffiliationAssignLevel.filter(
                (item) => item.sort_order === values.main_position.sort_order
              )[0].i_id,
              data: {
                item: {
                  affiliation_id: values.main_position.affiliation_code,
                },
                is_force_update: true,
              },
            })
          ),
        ...differenceBy(listOldPosition.slice(1), values.sub_position, 'position_id').map((value) =>
          dispatch(
            deleteAffiliationRole({
              id: value.position_id,
            })
          )
        ),
        ...differenceBy(listOldPosition.slice(1), values.sub_position, 'position_id').map((value) =>
          dispatch(
            deleteAffiliationLevel({
              id: dataAffiliationAssignLevel.filter(
                (item) => item.sort_order === value.sort_order
              )[0].i_id,
            })
          )
        ),
        ...values.sub_position.map(async (value, index) => {
          if (
            listOldPosition.some(
              (v) =>
                v.position_id === value.position_id &&
                (v.affiliation_id !== value.affiliation_id ||
                  v.position_code !== value.position_code)
            )
          ) {
            Promise.all([
              dispatch(
                updateAffiliationRole({
                  id: value.position_id,
                  data: {
                    item: {
                      affiliation_id: value.affiliation_id,
                      positions_code: value.position_code,
                    },
                    return_item_result: true,
                    is_force_update: true,
                  },
                })
              ),
              dispatch(
                updateAffiliationLevel({
                  id: dataAffiliationAssignLevel.filter(
                    (item) => item.sort_order === value.sort_order
                  )[0].i_id,
                  data: {
                    item: {
                      affiliation_id: value.affiliation_code,
                    },
                    is_force_update: true,
                  },
                })
              ),
            ]);
          } else if (!value.position_id && (value.affiliation_id || value.position_code)) {
            const resultAction1 = await Promise.all([
              dispatch(
                createAffiliationRole({
                  item: {
                    sort_order:
                      Number(maxBy(listOldPosition, 'sort_order')?.sort_order || 1) + index + 1,
                    login_id: values.email,
                    affiliation_id: value.affiliation_id,
                    positions_code: value.position_code,
                    company_id: userInfo?.company_id,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              ),
              dispatch(
                createAffiliationAssignLevel({
                  item: {
                    company_id: userInfo?.company_id,
                    affiliation_id: value.affiliation_code,
                    login_id: values.email,
                    sort_order:
                      Number(maxBy(listOldPosition, 'sort_order')?.sort_order || 1) + index + 1,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              ),
            ]);
            if (
              createAffiliationRole.fulfilled.match(resultAction1[0]) &&
              createAffiliationAssignLevel.fulfilled.match(resultAction1[1])
            ) {
              dispatch(
                addLinkAffiliation({
                  id: resultAction1[1].payload.item_id,
                  data: {
                    link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                    link_item_id: resultAction1[0].payload.item_id,
                  },
                })
              );
            }
          }
          return null;
        }),
      ]);
      dispatch(stopLoading());
      if (some(resultAction, (res) => !!res)) {
        setVisibleCompleted(true);
      }
    },
  });

  const fetchDataRequiredCurriculumSettings = (user: Types.Users.ResponseType) =>
    dispatch(
      getDataRequiredCurriculumSettings({
        conditions: [
          {
            id: 'company_id',
            search_value: [user.company_id],
          },
          {
            id: 'login_id',
            search_value: [user.login_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );

  const fetchDataRequiredSkillcheckSettings = (user: Types.Users.ResponseType) =>
    dispatch(
      getDataRequiredSkillcheckSettings({
        conditions: [
          {
            id: 'company_id',
            search_value: [user.company_id],
          },
          {
            id: 'login_id',
            search_value: [user.login_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
      })
    );

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${e}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('doj', `${e}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (e: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: e, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${e}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('dob', `${e}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: e }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${e}`);
    }
  };

  const handleUpdateCurriculum = async (item_id: string, hidden: 'on' | 'off') => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateCurriculum({
        id: item_id,
        data: {
          item: {
            hidden,
          },
          is_force_update: true,
        },
      })
    );
    if (updateCurriculum.fulfilled.match(resultAction) && userDetail) {
      await fetchDataRequiredCurriculumSettings(userDetail);
    }
    dispatch(stopLoading());
  };

  const handleUpdateUserAssignSkillCheck = async (item_id: string, hidden: 'on' | 'off') => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateUserAssignSkillCheck({
        id: item_id,
        data: {
          item: {
            hidden,
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );
    if (updateUserAssignSkillCheck.fulfilled.match(resultAction) && userDetail)
      await fetchDataRequiredSkillcheckSettings(userDetail);

    dispatch(stopLoading());
  };

  const handleDeleteItemCurriculumBatch = (_id: string) =>
    setDataCurriculumBatch(dataCurriculumBatch.filter(({ i_id }) => i_id !== _id));

  const handleDeleteItemSkillCheck = (_id: string) =>
    setDataSkillCheck(dataSkillCheck.filter(({ i_id }) => i_id !== _id));

  useEffect(() => {
    if (id && userInfo) {
      (async () => {
        dispatch(startLoading());
        const resultAction = await Promise.all([
          dispatch(
            getUserInfoDetail({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'i_id',
                  search_value: [id],
                },
              ],
              page: 1,
              per_page: 10,
              use_display_id: true,
              include_lookups: true,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        if (getUserInfoDetail.fulfilled.match(resultAction[0])) {
          const file: UploadFileAntd<File> = { uid: '', name: '' };
          const user_detail: Types.Users.ResponseType = resultAction[0].payload.items[0];
          setUserDetail(user_detail);

          await fetchDataRequiredCurriculumSettings(user_detail);

          await fetchDataRequiredSkillcheckSettings(user_detail);

          await dispatch(
            getAffiliationAssignLevel({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [user_detail.login_id],
                  exact_match: true,
                },
              ],
              include_links: true,
              page: 1,
              per_page: 0,
            })
          );

          if (user_detail.icon_fileID) {
            const fileName = extractFileName(user_detail.icon_fileID);
            if (fileName) {
              const avatarFile = await downloadFileFromMinio(user_detail.icon_fileID);
              if (avatarFile) {
                Object.assign(
                  file,
                  convertFileResponse({
                    file: avatarFile,
                    fileID: user_detail.icon_fileID,
                    fileName: fileName,
                  })
                );
              }
            }
          }

          // const getAttachInterviewAction = await dispatch(
          //   getAttachInterviewFile({
          //     conditions: [
          //       {
          //         id: 'fileID',
          //         search_value: [user_detail.icon_fileID],
          //       },
          //     ],
          //     page: 1,
          //     per_page: 0,
          //   })
          // );
          // if (
          //   getAttachInterviewFile.fulfilled.match(getAttachInterviewAction) &&
          //   getAttachInterviewAction.payload.items[0]?.file
          // ) {
          //   const getFileAction = await dispatch(
          //     getFileAttach({
          //       file_id: getAttachInterviewAction.payload.items[0].file,
          //     })
          //   );
          //   if (getFileAttach.fulfilled.match(getFileAction)) {
          //     Object.assign(
          //       file,
          //       convertFileResponse({
          //         file: getFileAction.payload,
          //         fileID: getAttachInterviewAction.payload.items[0].fileID,
          //         fileName: getAttachInterviewAction.payload.items[0].filename,
          //       })
          //     );
          //   }
          // }

          const resultAction2 = await dispatch(
            getSubPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [user_detail.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [user_detail.login_id],
                  exact_match: true,
                },
              ],
              sort_fields: [
                {
                  id: 'sort_order',
                  order: 'asc',
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
              include_links: true,
            })
          );
          if (getSubPosition.fulfilled.match(resultAction2)) {
            if (user_detail.dob) {
              setBirthDay({
                year: dayjs(user_detail.dob).year().toString(),
                month: (dayjs(user_detail.dob).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
                day: dayjs(user_detail.dob).date().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }
            if (user_detail.doj) {
              setDateJoinedCompany({
                year: dayjs(user_detail.doj).year().toString(),
                month: (dayjs(user_detail.doj).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }
            const position = resultAction2.payload.items.map((item) => ({
              position_id: item.i_id,
              affiliation_id: item.lookup_items?.affiliation_id?.i_id || '',
              position_code: item.lookup_items?.positions_code?.i_id || '',
              affiliation_code: item.lookup_items?.affiliation_id?.affiliation_id || '',
              sort_order: item.sort_order,
              main_role: item.main_role || '',
            }));
            setListOldPosition(position);
            const sub_position = position.filter((pos) => pos.main_role !== 'main');
            const main_position = position.find((pos) => pos.main_role === 'main');
            formik.setValues({
              ...formik.values,
              employee_code: user_detail.employee_code,
              name: user_detail.name,
              email: user_detail.email,
              name_furigana: user_detail.name_furigana,
              role_code: user_detail.lookup_items!.role_code.i_id,
              dob: user_detail.dob ? dayjs(user_detail.dob).format('YYYYMMDD') : '',
              doj: user_detail.doj ? dayjs(user_detail.doj).format('YYYYMMDD') : '',
              avatar: file,
              main_position: main_position ?? {
                position_id: '',
                affiliation_id: ' ',
                affiliation_code: ' ',
                position_code: '',
                sort_order: 1,
              },
              sub_position: sub_position.length
                ? sub_position
                : [
                    {
                      position_id: '',
                      affiliation_id: '',
                      affiliation_code: ' ',
                      position_code: '',
                      sort_order: 0,
                    },
                  ],
            });
          }
        } else {
          navigate(generatePath(routes.Employee.path, { entity: 'receiving' }));
        }
        dispatch(stopLoading());
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Header title={headerTitle} />
      <EditUserStyled collapsedMenu={collapsedMenu}>
        <p>
          社内ユーザーマスタの新規登録を行う画面です。
          <br />
          情報を入力後に登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="wrap-user">
                  <div className="avatar-user">
                    {formik.values.avatar && formik.values.avatar.uid && !errorImage ? (
                      <img
                        className="avatar"
                        src={URL.createObjectURL(
                          new Blob([
                            (formik.values.avatar.originFileObj ?? formik.values.avatar) as Blob,
                          ])
                        )}
                        alt="avatar"
                        onErrorCapture={() => setErrorImage(true)}
                      />
                    ) : (
                      <>
                        <UserOutlined className="icon-user" />
                        <span className="text-content">
                          プロフィール画面で設定した
                          <br />
                          画像が反映されます
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="全角：最大100文字"
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ） <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={<span className="text-label">メールアドレス</span>}
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      autoComplete="off"
                      readOnly
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      value={birthDay.year || undefined}
                      className="pull_down input_small"
                      onSelect={handleSelectBirthDay('year')}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.month || undefined}
                      onSelect={handleSelectBirthDay('month')}
                      placeholder="--"
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.day || undefined}
                      onSelect={handleSelectBirthDay('day')}
                      placeholder="--"
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onSelect={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      value={dateJoinedCompany.year || undefined}
                      placeholder="----"
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onSelect={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      value={dateJoinedCompany.month || undefined}
                      placeholder="--"
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                    >
                      {dataSelectRole?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">
                  所属・役職情報を設定・編集することができます。兼務枠を増やす場合は兼務所属・役職を追加するボタンをクリックしてください。
                </p>
                <span className="label">メイン所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            メイン所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.affiliation_id"
                          className="pull_down"
                          placeholder="選択してください"
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.affiliation_code',
                              dataSelectDepartment.find((item) => item.i_id === value)
                                ?.affiliation_id
                            );
                            formik.setFieldValue('main_position.affiliation_id', value);
                          }}
                        >
                          {dataSelectDepartment?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                    <div className="item">
                      <Form.Item
                        name="main_position.position_code"
                        label={
                          <span className="text-label">
                            メイン役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <SelectField
                          name="main_position.position_code"
                          className="pull_down"
                          placeholder="選択してください"
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.positions_code',
                              dataSelectPosition.find((item) => item.i_id === value)?.position_code
                            );
                            formik.setFieldValue('main_position.positions_id', value);
                          }}
                        >
                          {dataSelectPosition?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </SelectField>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <span className="label">兼務所属・役職</span>
                <FieldArray
                  name="sub_position"
                  render={({ remove, push }) => (
                    <>
                      <div className="wrap-sub-position">
                        {formik.values.sub_position.map((_value, index) => (
                          <div className="form-row" key={index}>
                            <div className="item">
                              <Form.Item
                                name={`sub_position.${index}.affiliation_id`}
                                label={<span className="text-label">兼務所属 {index + 1}</span>}
                                className="form-input"
                              >
                                <SelectField
                                  name={`sub_position.${index}.affiliation_id`}
                                  className="pull_down"
                                  placeholder="選択してください"
                                  value={formik.values.sub_position[index].affiliation_id || ''}
                                  onClear={() => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_code`,
                                      ''
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_id`,
                                      ''
                                    );
                                  }}
                                  onChange={(value: string) => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_code`,
                                      dataSelectDepartment.find((item) => item.i_id === value)
                                        ?.affiliation_id
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_id`,
                                      value
                                    );
                                  }}
                                >
                                  <Option value="">未選択</Option>
                                  {dataSelectDepartment
                                    ?.filter(
                                      (affiliation) =>
                                        !formik.values.sub_position.some(
                                          (position, someIndex) =>
                                            position.affiliation_id === affiliation.i_id &&
                                            someIndex !== index
                                        ) &&
                                        formik.values.main_position.affiliation_id !==
                                          affiliation.i_id
                                    )
                                    .map(({ i_id, name }, i) => (
                                      <Option value={i_id} key={i}>
                                        {name}
                                      </Option>
                                    ))}
                                </SelectField>
                              </Form.Item>
                            </div>
                            <div className="item">
                              <Form.Item
                                name={`sub_position.${index}.position_code`}
                                label={<span className="text-label">兼務役職{index + 1}</span>}
                                className="form-input"
                              >
                                <SelectField
                                  name={`sub_position.${index}.position_code`}
                                  className="pull_down"
                                  placeholder="選択してください"
                                  value={formik.values.sub_position[index].position_code || ''}
                                  onClear={() => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.positions_code`,
                                      ''
                                    );
                                    formik.setFieldValue(`sub_position.${index}.position_id`, '');
                                  }}
                                  onChange={(value: string) => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.position_code`,
                                      dataSelectPosition.find((item) => item.i_id === value)
                                        ?.position_code
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.position_code`,
                                      value
                                    );
                                  }}
                                >
                                  <Option value="">未選択</Option>
                                  {dataSelectPosition?.map(({ i_id, name }, i) => (
                                    <Option value={i_id} key={i}>
                                      {name}
                                    </Option>
                                  ))}
                                </SelectField>
                              </Form.Item>
                            </div>
                            <DeleteOutlined
                              className="icon-delete"
                              onClick={() => {
                                if (index === 0) {
                                  formik.setFieldValue(
                                    `sub_position.${index}.affiliation_code`,
                                    ''
                                  );
                                  formik.setFieldValue(`sub_position.${index}.affiliation_id`, '');
                                  formik.setFieldValue(`sub_position.${index}.position_code`, '');
                                  formik.setFieldValue(`sub_position.${index}.position_id`, '');
                                } else {
                                  remove(index);
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>
                      {formik.values.sub_position.length < 10 && (
                        <button
                          className="button"
                          type="button"
                          onClick={() =>
                            push({
                              affiliation_id: '',
                              positions_code: '',
                            })
                          }
                        >
                          + 兼務所属・役職を追加
                        </button>
                      )}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="wrap-curriculum-setting">
              <div className="header">必修カリキュラム設定</div>
              <div className="body">
                <p className="description">
                  必修カリキュラムの設定枠を増やす場合は、必修カリキュラムを追加するボタンをクリックしてください。追加後、更新すると確定します。
                  <br />
                  カリキュラムの進捗が100％になると、非表示リストに追加されます。
                </p>
                {!!dataCurriculumUserCheck?.filter((curr) => curr.hidden_curricullum === 'off')
                  .length && (
                  <>
                    <div className="wrap-label-hide">
                      <span
                        className="label-hide"
                        onClick={() => setVisibleCurriculumHiddenListModal(true)}
                      >
                        <EyeInvisibleOutlined className="icon" /> 非表示リスト
                      </span>
                      <Select
                        className="select-sort-order"
                        onChange={setOrderTypeCurriculum}
                        value={orderTypeCurriculum}
                      >
                        <Option value="asc">設定の古い順に表示</Option>
                        <Option value="desc">設定の新しい順に表示</Option>
                      </Select>
                    </div>
                    {orderBy(
                      dataCurriculumUserCheck.filter((curr) => curr.hidden_curricullum === 'off'),
                      ['curriculum_name'],
                      [orderTypeCurriculum]
                    ).map(
                      (item, index) =>
                        item &&
                        item.item_ref?.hidden_curricullum?.i_id && (
                          <div className="item-result" key={index}>
                            <div>
                              <span className="text-add">{index + 1}</span>
                              <span className="content">{item?.curriculum_name}</span>
                            </div>
                            <Tooltip placement="top" title={'非表示にする'}>
                              <EyeInvisibleOutlined
                                className="delete"
                                onClick={() =>
                                  handleUpdateCurriculum(
                                    item.item_ref!.hidden_curricullum!.i_id,
                                    'on'
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        )
                    )}
                  </>
                )}
                {dataCurriculumBatch?.map(({ name, i_id }, index) => (
                  <div key={index} className="item-result-add">
                    <div>
                      <span className="text-add">＋</span>
                      <span className="content">{name}</span>
                    </div>
                    <DeleteOutlined
                      className="delete"
                      onClick={() => handleDeleteItemCurriculumBatch(i_id)}
                    />
                  </div>
                ))}
                {!dataCurriculumUserCheck && !dataCurriculumBatch && (
                  <div className="empty-text">未設定</div>
                )}
                <button
                  className="button"
                  type="button"
                  onClick={() => setVisibleCurriculumBatchModal(true)}
                >
                  + 必修カリキュラムを追加
                </button>
              </div>
            </div>
            <div className="wrap-skill-check">
              <div className="header">スキルチェック設定状況</div>
              <div className="body">
                <p className="description">
                  スキルチェックを設定枠を増やす場合は、スキルチェックを追加ボタンをクリックしてください。追加後、更新すると確定します。
                  <br />
                  スキルチェックの実施後は、非表示リストに追加されます。
                </p>
                {dataSkillUserCheck?.filter((user) => user.hidden_skillcheck === 'off')[0] && (
                  <>
                    <div className="wrap-label-hide">
                      <span
                        className="label-hide"
                        onClick={() => setVisibleSkillCheckHiddenListModal(true)}
                      >
                        <EyeInvisibleOutlined className="icon" /> 非表示リスト
                      </span>
                      <Select
                        className="select-sort-order"
                        onChange={setOrderTypeSkillCheck}
                        value={orderTypeSkillCheck}
                      >
                        <Option value="asc">設定の古い順に表示</Option>
                        <Option value="desc">設定の新しい順に表示</Option>
                      </Select>
                    </div>
                    {orderBy(
                      dataSkillUserCheck?.filter((user) => user.hidden_skillcheck === 'off'),
                      ['skill_check_name'],
                      [orderTypeSkillCheck]
                    ).map(
                      (item, index) =>
                        item &&
                        item.item_ref?.hidden_skillcheck?.i_id && (
                          <div className="item-result" key={index}>
                            <div>
                              <span className="text-add">{index + 1}</span>
                              <span className="content">{item?.skillcheck_name}</span>
                            </div>
                            <Tooltip placement="top" title="非表示にする">
                              <EyeInvisibleOutlined
                                className="delete"
                                onClick={() =>
                                  handleUpdateUserAssignSkillCheck(
                                    item.item_ref!.hidden_skillcheck!.i_id,
                                    'on'
                                  )
                                }
                              />
                            </Tooltip>
                          </div>
                        )
                    )}
                  </>
                )}
                {dataSkillCheck?.map(({ name, i_id }, index) => (
                  <div key={index} className="item-result-add">
                    <div>
                      <span className="text-add">＋</span>
                      <span className="content">{name}</span>
                    </div>
                    <DeleteOutlined
                      className="delete"
                      onClick={() => handleDeleteItemSkillCheck(i_id)}
                    />
                  </div>
                ))}

                {!dataSkillUserCheck && !dataSkillCheck && <div className="empty-text">未設定</div>}
                <button
                  className="button"
                  type="button"
                  onClick={() => setVisibleSkillCheckModal(true)}
                >
                  + スキルチェックを追加
                </button>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton
                  className="btn btn_submit"
                  disabled={!formik.dirty || !formik.isValid}
                >
                  更新
                </SubmitButton>
                <button className="btn btn_close" onClick={() => navigate(routes.Employee.path)}>
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          title="更新が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => navigate(generatePath(routes.Employee.path, { entity: 'receiving' }))}
        />
        <SkillCheckModal
          visible={visibleSkillCheckModal}
          setVisible={setVisibleSkillCheckModal}
          onSubmit={(item) => setDataSkillCheck([...dataSkillCheck, ...item])}
        />
        <CurriculumBatchModal
          visible={visibleCurriculumBatchModal}
          setVisible={setVisibleCurriculumBatchModal}
          onSubmit={(item) => setDataCurriculumBatch([...dataCurriculumBatch, ...item])}
        />
        <CurriculumHiddenListModal
          visible={visibleCurriculumHiddenListModal}
          setVisible={setVisibleCurriculumHiddenListModal}
          onSubmit={handleUpdateCurriculum}
        />
        <SkillCheckHiddenListModal
          visible={visibleSkillCheckHiddenListModal}
          setVisible={setVisibleSkillCheckHiddenListModal}
          onSubmit={handleUpdateUserAssignSkillCheck}
        />
      </EditUserStyled>
    </>
  );
};

export default EditEmployee;
