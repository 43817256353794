import React, { useState } from 'react';
import { FileSearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { RegisterInvoiceInformationFormik } from 'types';
import { authSelector } from 'containers/Auth/selectors';
import InvoicePreview from '../Modal/InvoicePreview';
import { paymentMasterSelector } from '../selectors';
import { invoiceSchema } from 'libs/validations';
import InvoiceInformationStyled from './styles';
import { updateCreditCardInfo } from '../thunk';
import { useAppDispatch } from 'hooks';
import { COMPANIES } from 'configs';
import { Input } from 'components';

interface Props {
  isSubmit: boolean;
  isEditing: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const InvoiceInformation: React.FC<Props> = ({
  isSubmit,
  isEditing,
  setIsSubmit,
  setStatusEditing,
}) => {
  const [visibleInvoicePreview, setVisibleInvoicePreview] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);

  const { invoiceInfo } = useSelector(paymentMasterSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<RegisterInvoiceInformationFormik>({
    initialValues: invoiceInfo,
    validationSchema: invoiceSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (isSubmit) {
        const { billing_address, fax, business_registration_number } = values;

        if (!userInfo || !billing_address || !business_registration_number) return;

        const id = find(userInfo.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)?.items[0]
          ?.i_id;

        dispatch(startLoading());
        const resultAction = await dispatch(
          updateCreditCardInfo({
            id: id || '',
            data: {
              item: {
                billing_address,
                fax,
                business_registration_number,
              },
              return_item_result: true,
              is_force_update: true,
            },
          })
        );
        if (updateCreditCardInfo.fulfilled.match(resultAction)) {
          setDisabled(true);
          setIsSubmit(false);
          setStatusEditing(false);
        }
        dispatch(stopLoading());
      } else {
        setDisabled(true);
        setIsSubmit(true);
      }
      setSubmitting(false);
    },
  });

  const handleChangeStatus = () => {
    if (isEditing) {
      formik.setErrors({
        billing_address: undefined,
        business_registration_number: undefined,
      });
      formik.setFieldTouched('billing_address', false);
      formik.setFieldTouched('business_registration_number', false);
    }
    if (isSubmit) {
      setDisabled(false);
      setIsSubmit(false);
    } else {
      setStatusEditing((prevState) => !prevState);
      setDisabled((prevState) => !prevState);
    }
  };

  return (
    <InvoiceInformationStyled collapsedMenu={collapsedMenu}>
      <div className="header">
        <p className="title">クレジット情報</p>
        <div
          className="btn-preview"
          onClick={() => setVisibleInvoicePreview((prevState) => !prevState)}
        >
          <FileSearchOutlined className="icon" />
          <span>請求書プレビュー</span>
        </div>
      </div>
      <FormikProvider value={formik}>
        <Form layout="vertical" colon={false} className="form">
          <Form.Item
            className="form-input"
            name="billing_address"
            label={
              <span className="text-label">
                宛名{!disabled && <span className="require">*</span>}
              </span>
            }
          >
            <Input name="billing_address" className="input" type="text" disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="post_code"
            label={<span className="text-label">住所</span>}
            className="form-input mr-0"
          >
            <Input
              name="post_code"
              className="input input-small"
              type="text"
              prefix={<span>〒</span>}
              disabled
            />
          </Form.Item>
          <Form.Item name="address" className="form-input">
            <Input name="address" className="input" type="text" disabled />
          </Form.Item>
          <Form.Item
            name="tel"
            label={<span className="text-label">TEL</span>}
            className="form-input"
          >
            <Input name="tel" className="input" type="text" disabled />
          </Form.Item>
          <Form.Item
            name="fax"
            label={<span className="text-label">FAX</span>}
            className="form-input"
          >
            <Input name="fax" className="input" type="text" maxLength={10} disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="business_registration_number"
            label={
              <span className="text-label">
                適格請求書発行事業者 登録番号{!disabled && <span className="require">*</span>}
              </span>
            }
            className="form-input"
          >
            <Input
              name="business_registration_number"
              className="input"
              type="text"
              maxLength={14}
              disabled={disabled}
            />
          </Form.Item>
          <span className="note">
            ※請求書情報は管理者マスタより情報を転機しています。請求書上の記載を変更したい場合に修正してください。
          </span>
          {disabled && !isSubmit ? (
            <div className="wrap-submit-button">
              <button type="button" className="btn-submit" onClick={handleChangeStatus}>
                クレジット情報を変更する
              </button>
            </div>
          ) : (
            <div className="wrap-submit-button">
              <SubmitButton
                disabled={
                  !!formik.errors.billing_address || !!formik.errors.business_registration_number
                }
                className="btn-submit"
              >
                {isSubmit ? '更新' : '確認画面へ'}
              </SubmitButton>
              <button type="button" className="btn-back-info" onClick={handleChangeStatus}>
                戻る
              </button>
            </div>
          )}
        </Form>
      </FormikProvider>
      <InvoicePreview
        visibleInvoicePreview={visibleInvoicePreview}
        setVisibleInvoicePreview={setVisibleInvoicePreview}
      />
    </InvoiceInformationStyled>
  );
};

export default InvoiceInformation;
