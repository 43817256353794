import styled from 'styled-components';

export const SectionStyled = styled.div`
  .content {
    background-color: #f9f8f8;
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
`;
