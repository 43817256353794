import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useModal, { ReceivedProps, Props } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const ModalLayout: React.FC<Props> = ({ handleClose, isOpen }) => {
  return (
    <Modal
      title="削除確認"
      open={isOpen}
      width={720}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
      bodyStyle={{
        background: '#f9f8f8',
      }}
      footerStyle={{
        background: '#f9f8f8',
      }}
    >
      <Styled>
        <div className="wrap-subtitle">
          <ExclamationCircleOutlined className="icon" />
          <span className="subtitle">データの削除は実行できません。</span>
        </div>
        <span className="description">
          選択したマスタはスキルチェックに設定されているため、削除できません。
        </span>
      </Styled>
    </Modal>
  );
};

const NotDelete = (props: ReceivedProps) => {
  return <ModalLayout {...useModal(props)} />;
};

export default NotDelete;
