import styled from 'styled-components';

export default styled.section`
  .ant-input-show-count-suffix {
    position: absolute;
    right: -5px;
    top: 44px;
  }
  .ant-input {
    margin-left: 5px;
  }
  .guide {
    text-align: center;
    color: #424242;
  }
  .wrap-current-email {
    background: #ffffff;
    padding: 0 8px 17px;
    border: 1px solid #eeeeee;
    .label {
      font-size: 11px;
      color: #999999;
    }
    .current-email {
      text-align: center;
      font-size: 16px;
      color: #2a2a2a;
    }
  }
  .label {
    font-size: 13px;
  }
  .require {
    color: red;
    font-size: 18px;
  }
`;
