import React, { useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { routes } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { createAffiliationAssignLevel, createAffiliationRole } from 'pages/Settings/Employee/thunk';
import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import { affiliationSelector } from 'pages/Settings/AffiliationMaster/selectors';
import { updateAffiliationsMain } from 'pages/Settings/AffiliationMaster/slice';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { resetInformation } from 'containers/InformationRegister/slice';
import { logout, updateUserInfoReducer } from 'containers/Auth/slice';
import { createRoles } from 'pages/Settings/RolesMaster/thunk';
import { AFFILIATION_ASSIGN_ROLE, config } from 'configs';
import { RegisterInformationPaymentFormik } from 'types';
import { authSelector } from 'containers/Auth/selectors';
import { fiveStepSchema } from 'libs/validations';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';
import { Logo } from 'assets';
import {
  confirmCreditCardThunk,
  addLinkAffiliation,
  getCardCompanyName,
  registerUserThunk,
  createCompanies,
  updateUserInfo,
} from 'containers/InformationRegister/thunk';
import {
  getAffiliationAssignRole,
  createAffiliationLevel,
  updateAffiliation,
} from 'pages/Settings/AffiliationMaster/thunk';
import {
  createItemPositionMaster,
  updateItemPositionMaster,
} from 'pages/Settings/PositionMaster/thunk';
import {
  postUnauthorizedCall,
  updateLoginTime,
  updateCompany,
  isFirstLogin,
} from 'containers/Auth/thunk';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  success: boolean;
}

const FiveStep: React.FC<Props> = ({ setVisible, setOpen, setSuccess, success }) => {
  const { information, creditCard, plans } = useSelector(informationRegisterSelector);
  const { affiliationsMain } = useSelector(affiliationSelector);
  const {
    isFirstLogin: userFirstLogin,
    signedInEmail,
    dataCompany,
    userInfo,
  } = useSelector(authSelector);

  const { company_id, admin_email } = useParams<{ company_id: string; admin_email: string }>();

  const planPremium = useMemo(() => {
    return plans.find((plan) => plan.plan_name === 'premium');
  }, [plans]);

  const navigate = useNavigate();
  const { messages } = useIntl();
  const dispatch = useAppDispatch();

  const isExpired = useMemo(() => {
    if (dataCompany && dataCompany.plan_expiry_at) {
      const expiryDate = dayjs(dataCompany.plan_expiry_at);
      const currentDate = dayjs();
      const diffInDays = currentDate.diff(expiryDate, 'day');

      if (diffInDays > 10) {
        return true;
      }

      return false;
    }

    return true;
  }, [dataCompany]);

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fiveStepSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSuccess(true);
      setTimeout(() => {
        const { securityNumber, expirationDate, customerName, cardNumber, companyName } = values;
        window.Multipayment.init('tshop00054795');
        window.Multipayment.getToken(
          {
            holdername: customerName,
            cardno: cardNumber,
            expire: expirationDate,
            securitycode: securityNumber,
          },
          async (res) => {
            if (res.resultCode !== '000') {
              alert('購入処理中にエラーが発生しました');
            } else {
              if (!company_id) {
                const result = await dispatch(
                  getCardCompanyName({
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [companyName],
                      },
                    ],
                    page: 1,
                    per_page: 0,
                  })
                );
                if (getCardCompanyName.fulfilled.match(result)) {
                  const company = result.payload.items.find(
                    (data) => data.card_company_name === companyName
                  );

                  if (dataCompany) {
                    const resultActionRegisterInformation = await dispatch(
                      updateCompany({
                        id: dataCompany.i_id,
                        data: {
                          item: {
                            ...information,
                            plan_start_at: dayjs().format('YYYY-MM-DD'),
                            card_company_name: company?.i_id,
                            plan_expiry_at: null,
                            plan_id: config.PREMIUM_PLAN_ID,
                            trial_completed_flag: 1,
                          },
                          return_item_result: true,
                          is_force_update: true,
                        },
                      })
                    );
                    if (updateCompany.fulfilled.match(resultActionRegisterInformation)) {
                      if (userFirstLogin || isExpired) {
                        const resultActionCreateRole = await dispatch(
                          createRoles({
                            item: {
                              name: 'admin',
                              admin_dashboard_permission: 2,
                              skill_check_implementation_permission: 2,
                              training_report_permission: 1,
                              skill_check_report_permission: 1,
                              my_chart_permission: 1,
                              manual_permission: 2,
                              knowledge_permission: 2,
                              official_curriculum_permission: 2,
                              curricullum_master_permission: 2,
                              question_master_permission: 2,
                              skill_check_master_permission: 2,
                              inter_users_master_permission: 2,
                              groups_master_permission: 2,
                              users_master_permission: 2,
                              roles_master_permission: 2,
                              departments_master_permission: 2,
                              positions_master_permission: 2,
                              admin_master_permission: 2,
                              payment_master_permission: 2,
                              report_view_permission: 1,
                              bundle_master_permission: 1,
                              company_id: dataCompany.id,
                              createdby: signedInEmail,
                              createdat: new Date(),
                            },
                            is_force_update: true,
                          })
                        );
                        const resultActions1 = await Promise.all([
                          dispatch(
                            createAffiliationLevel({
                              item: {
                                name: information.admin_department,
                                sort_order: 1,
                                level: 1,
                                createdby: signedInEmail,
                                company_id: dataCompany.id,
                                createdat: new Date(),
                              },
                              return_display_id: true,
                              return_item_result: true,
                            })
                          ),
                          dispatch(
                            createItemPositionMaster({
                              item: {
                                name: information.admin_position,
                                company_id: dataCompany.id,
                                rank_order: 1,
                                createdby: signedInEmail,
                                createdat: new Date(),
                              },
                              return_display_id: true,
                              return_item_result: true,
                            })
                          ),
                          dispatch(
                            registerUserThunk({
                              item: {
                                company_id: dataCompany.id,
                                login_id: signedInEmail,
                                name: information.admin_name,
                                name_furigana: information.admin_name_furigana,
                                email: signedInEmail,
                                user_type: 'admin',
                                admin_phone: information.admin_phone,
                                role_code: createRoles.fulfilled.match(resultActionCreateRole)
                                  ? resultActionCreateRole.payload.item_id
                                  : undefined,
                                createdat: new Date(),
                                createdby: signedInEmail,
                                last_login_at: dayjs().toISOString(),
                              },
                            })
                          ),
                        ]);

                        if (registerUserThunk.fulfilled.match(resultActions1[2])) {
                          let affiliation_id;
                          let positions_id;
                          let affiliation_code;
                          if (
                            createAffiliationLevel.fulfilled.match(resultActions1[0]) &&
                            resultActions1[0].payload.item
                          ) {
                            affiliation_id = resultActions1[0].payload.item_id;
                            affiliation_code = resultActions1[0].payload.item.affiliation_id;
                          }
                          if (
                            createItemPositionMaster.fulfilled.match(resultActions1[1]) &&
                            resultActions1[1].payload.item
                          ) {
                            positions_id = resultActions1[1].payload.item_id;
                          }
                          const resultActions2 = await Promise.all([
                            dispatch(
                              createAffiliationRole({
                                item: {
                                  company_id: dataCompany.id,
                                  affiliation_id: affiliation_id,
                                  login_id: signedInEmail,
                                  positions_code: positions_id,
                                  sort_order: 1,
                                  main_role: 'main',
                                  createdby: signedInEmail,
                                  createdat: new Date(),
                                },
                              })
                            ),
                            dispatch(
                              createAffiliationAssignLevel({
                                item: {
                                  company_id: dataCompany.id,
                                  affiliation_id: affiliation_code,
                                  login_id: signedInEmail,
                                  sort_order: 1,
                                  createdby: signedInEmail,
                                  createdat: new Date(),
                                },
                              })
                            ),
                            dispatch(
                              confirmCreditCardThunk({
                                action_id: 'customerregistration',
                                item: {
                                  name: signedInEmail,
                                },
                                as_params: {
                                  token: res.tokenObject.token,
                                  company_id: dataCompany.id,
                                  company_item_id: dataCompany.i_id,
                                },
                                is_force_update: true,
                              })
                            ),
                          ]);
                          if (
                            createAffiliationRole.fulfilled.match(resultActions2[0]) &&
                            createAffiliationAssignLevel.fulfilled.match(resultActions2[1])
                          ) {
                            dispatch(
                              addLinkAffiliation({
                                id: resultActions2[1].payload.item_id,
                                data: {
                                  link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                                  link_item_id: resultActions2[0].payload.item_id,
                                },
                              })
                            );
                          }
                          if (confirmCreditCardThunk.fulfilled.match(resultActions2[2])) {
                            const resultActionIsFirstLogin = await dispatch(
                              isFirstLogin({
                                conditions: [
                                  {
                                    id: 'email',
                                    search_value: [signedInEmail],
                                    exact_match: true,
                                  },
                                ],
                                include_links: true,
                                include_lookups: true,
                                page: 1,
                                per_page: 1,
                              })
                            );
                            if (
                              isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
                              resultActionIsFirstLogin.payload.totalItems
                            ) {
                              await dispatch(
                                updateLoginTime({
                                  id: resultActionIsFirstLogin.payload.items[0].i_id,
                                  data: {
                                    item: {
                                      last_login_at: dayjs().toISOString(),
                                    },
                                    is_force_update: true,
                                  },
                                  un_realtime: true,
                                })
                              );
                            }
                            dispatch(resetInformation());
                            navigate(
                              generatePath(routes.Dashboard.path, {
                                entity: 'receiving',
                              })
                            );
                            window.location.reload();
                          }
                        }
                      } else {
                        const resultActions1 = await Promise.all([
                          dispatch(
                            updateAffiliation({
                              id: affiliationsMain?.lookup_items?.affiliation_id?.i_id || '',
                              data: {
                                item: {
                                  name: information.admin_department,
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                          dispatch(
                            updateItemPositionMaster({
                              id: affiliationsMain?.lookup_items?.positions_code?.i_id || '',
                              data: {
                                item: {
                                  name: information.admin_position,
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                          dispatch(
                            updateUserInfo({
                              id: userInfo?.i_id || '',
                              data: {
                                item: {
                                  name: information.admin_name,
                                  name_furigana: information.admin_name_furigana,
                                  admin_phone: information.admin_phone,
                                  last_login_at: dayjs().toISOString(),
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                        ]);

                        if (updateUserInfo.fulfilled.match(resultActions1[2])) {
                          dispatch(resetInformation());
                          navigate(
                            generatePath(routes.Dashboard.path, {
                              entity: 'receiving',
                            })
                          );
                          window.location.reload();
                        }
                      }
                    }
                  } else {
                    const resultActionRegisterInformation = await dispatch(
                      createCompanies({
                        item: {
                          ...information,
                          plan_id: config.PREMIUM_PLAN_ID,
                          plan_start_at: dayjs().format('YYYY-MM-DD'),
                          createdat: new Date(),
                          createdby: signedInEmail,
                          card_company_name: company?.i_id,
                        },
                        return_item_result: true,
                        return_display_id: true,
                      })
                    );
                    if (createCompanies.fulfilled.match(resultActionRegisterInformation)) {
                      const { item, item_id } = resultActionRegisterInformation.payload;

                      const resultActionCreateRole = await dispatch(
                        createRoles({
                          item: {
                            name: 'admin',
                            admin_dashboard_permission: 2,
                            skill_check_implementation_permission: 2,
                            training_report_permission: 1,
                            skill_check_report_permission: 1,
                            my_chart_permission: 1,
                            manual_permission: 2,
                            knowledge_permission: 2,
                            official_curriculum_permission: 2,
                            curricullum_master_permission: 2,
                            question_master_permission: 2,
                            skill_check_master_permission: 2,
                            inter_users_master_permission: 2,
                            groups_master_permission: 2,
                            users_master_permission: 2,
                            roles_master_permission: 2,
                            departments_master_permission: 2,
                            positions_master_permission: 2,
                            admin_master_permission: 2,
                            payment_master_permission: 2,
                            report_view_permission: 1,
                            bundle_master_permission: 1,
                            company_id: item.id,
                            createdby: signedInEmail,
                            createdat: new Date(),
                          },
                          is_force_update: true,
                        })
                      );
                      const resultActions1 = await Promise.all([
                        dispatch(
                          createAffiliationLevel({
                            item: {
                              name: information.admin_department,
                              sort_order: 1,
                              level: 1,
                              createdby: signedInEmail,
                              company_id: item.id,
                              createdat: new Date(),
                            },
                            return_display_id: true,
                            return_item_result: true,
                          })
                        ),
                        dispatch(
                          createItemPositionMaster({
                            item: {
                              name: information.admin_position,
                              company_id: item.id,
                              rank_order: 1,
                              createdby: signedInEmail,
                              createdat: new Date(),
                            },
                            return_display_id: true,
                            return_item_result: true,
                          })
                        ),
                        dispatch(
                          registerUserThunk({
                            item: {
                              company_id: item.id,
                              login_id: signedInEmail,
                              name: information.admin_name,
                              name_furigana: information.admin_name_furigana,
                              email: signedInEmail,
                              user_type: 'admin',
                              admin_phone: information.admin_phone,
                              role_code: createRoles.fulfilled.match(resultActionCreateRole)
                                ? resultActionCreateRole.payload.item_id
                                : undefined,
                              createdat: new Date(),
                              createdby: signedInEmail,
                              last_login_at: dayjs().toISOString(),
                            },
                          })
                        ),
                      ]);

                      if (registerUserThunk.fulfilled.match(resultActions1[2])) {
                        let affiliation_id;
                        let positions_id;
                        let affiliation_code;
                        if (
                          createAffiliationLevel.fulfilled.match(resultActions1[0]) &&
                          resultActions1[0].payload.item
                        ) {
                          affiliation_id = resultActions1[0].payload.item_id;
                          affiliation_code = resultActions1[0].payload.item.affiliation_id;
                        }
                        if (
                          createItemPositionMaster.fulfilled.match(resultActions1[1]) &&
                          resultActions1[1].payload.item
                        ) {
                          positions_id = resultActions1[1].payload.item_id;
                        }
                        const resultActions2 = await Promise.all([
                          dispatch(
                            createAffiliationRole({
                              item: {
                                company_id: item.id,
                                affiliation_id: affiliation_id,
                                login_id: signedInEmail,
                                positions_code: positions_id,
                                sort_order: 1,
                                main_role: 'main',
                                createdby: signedInEmail,
                                createdat: new Date(),
                              },
                            })
                          ),
                          dispatch(
                            createAffiliationAssignLevel({
                              item: {
                                company_id: item.id,
                                affiliation_id: affiliation_code,
                                login_id: signedInEmail,
                                sort_order: 1,
                                createdby: signedInEmail,
                                createdat: new Date(),
                              },
                            })
                          ),
                          dispatch(
                            confirmCreditCardThunk({
                              action_id: 'customerregistration',
                              item: {
                                name: signedInEmail,
                              },
                              as_params: {
                                token: res.tokenObject.token,
                                company_id: item.id,
                                company_item_id: item_id,
                              },
                              is_force_update: true,
                            })
                          ),
                        ]);
                        if (
                          createAffiliationRole.fulfilled.match(resultActions2[0]) &&
                          createAffiliationAssignLevel.fulfilled.match(resultActions2[1])
                        ) {
                          dispatch(
                            addLinkAffiliation({
                              id: resultActions2[1].payload.item_id,
                              data: {
                                link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                                link_item_id: resultActions2[0].payload.item_id,
                              },
                            })
                          );
                        }
                        if (confirmCreditCardThunk.fulfilled.match(resultActions2[2])) {
                          const resultActionIsFirstLogin = await dispatch(
                            isFirstLogin({
                              conditions: [
                                {
                                  id: 'email',
                                  search_value: [signedInEmail],
                                  exact_match: true,
                                },
                              ],
                              include_links: true,
                              include_lookups: true,
                              page: 1,
                              per_page: 1,
                            })
                          );
                          if (
                            isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
                            resultActionIsFirstLogin.payload.totalItems
                          ) {
                            await dispatch(
                              updateLoginTime({
                                id: resultActionIsFirstLogin.payload.items[0].i_id,
                                data: {
                                  item: {
                                    last_login_at: dayjs().toISOString(),
                                  },
                                  is_force_update: true,
                                },
                                un_realtime: true,
                              })
                            );
                          }
                          dispatch(resetInformation());
                          navigate(generatePath(routes.Dashboard.path, { entity: 'receiving' }));
                          window.location.reload();
                        }
                      }
                    }
                  }
                }
              } else {
                const result = await dispatch(
                  postUnauthorizedCall({
                    workspace_id: 'skillfamiliar',
                    url: '/api/v0/applications/skillfamiliar/datastores/card_company_name/items/search',
                    method: 'POST',
                    params: {
                      conditions: [
                        {
                          id: 'company_id',
                          search_value: [companyName],
                          exact_match: true,
                        },
                      ],
                      page: 1,
                      per_page: 0,
                      include_lookups: true,
                      include_item_ref: true,
                      use_display_id: true,
                    },
                  })
                );

                if (postUnauthorizedCall.fulfilled.match(result)) {
                  const company = result.payload.items.find(
                    (data: any) => data.card_company_name === companyName
                  );

                  if (dataCompany) {
                    const resultUpdateCompany = await dispatch(
                      postUnauthorizedCall({
                        workspace_id: 'skillfamiliar',
                        url: `/api/v0/applications/skillfamiliar/datastores/companies/items/edit/:${dataCompany.i_id}`,
                        method: 'POST',
                        params: {
                          item: {
                            ...information,
                            plan_start_at: dayjs().format('YYYY-MM-DD'),
                            plan_id: config.PREMIUM_PLAN_ID,
                            card_company_name: company?.i_id,
                            plan_expiry_at: null,
                          },
                          is_force_update: true,
                          realtime_auto_link: true,
                          use_display_id: true,
                          return_item_result: true,
                        },
                      })
                    );

                    if (postUnauthorizedCall.fulfilled.match(resultUpdateCompany)) {
                      if (isExpired) {
                        const resultActionCreateRole = await dispatch(
                          postUnauthorizedCall({
                            workspace_id: 'skillfamiliar',
                            url: '/api/v0/applications/skillfamiliar/datastores/roles/items/new',
                            method: 'POST',
                            params: {
                              item: {
                                name: 'admin',
                                admin_dashboard_permission: 2,
                                skill_check_implementation_permission: 2,
                                training_report_permission: 1,
                                skill_check_report_permission: 1,
                                my_chart_permission: 1,
                                manual_permission: 2,
                                knowledge_permission: 2,
                                official_curriculum_permission: 2,
                                curricullum_master_permission: 2,
                                question_master_permission: 2,
                                skill_check_master_permission: 2,
                                inter_users_master_permission: 2,
                                groups_master_permission: 2,
                                users_master_permission: 2,
                                roles_master_permission: 2,
                                departments_master_permission: 2,
                                positions_master_permission: 2,
                                admin_master_permission: 2,
                                payment_master_permission: 2,
                                report_view_permission: 1,
                                bundle_master_permission: 1,
                                company_id: dataCompany.id,
                                createdby: admin_email || information.admin_email,
                                createdat: new Date(),
                              },
                              is_force_update: true,
                            },
                          })
                        );
                        const resultActions1 = await Promise.all([
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: '/api/v0/applications/skillfamiliar/datastores/affiliation_level/items/new',
                              method: 'POST',
                              params: {
                                item: {
                                  name: information.admin_department,
                                  sort_order: 1,
                                  level: 1,
                                  createdby: admin_email || information.admin_email,
                                  company_id: dataCompany.id,
                                  createdat: new Date(),
                                },
                                return_display_id: true,
                                return_item_result: true,
                              },
                            })
                          ),
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: '/api/v0/applications/skillfamiliar/datastores/positions/items/new',
                              method: 'POST',
                              params: {
                                item: {
                                  name: information.admin_position,
                                  company_id: dataCompany.id,
                                  rank_order: 1,
                                  createdby: admin_email || information.admin_email,
                                  createdat: new Date(),
                                },
                                return_display_id: true,
                                return_item_result: true,
                              },
                            })
                          ),
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: '/api/v0/applications/skillfamiliar/datastores/users/items/new',
                              method: 'POST',
                              params: {
                                item: {
                                  company_id: dataCompany.id,
                                  login_id: admin_email || information.admin_email,
                                  name: information.admin_name,
                                  name_furigana: information.admin_name_furigana,
                                  email: admin_email || information.admin_email,
                                  user_type: 'admin',
                                  admin_phone: information.admin_phone,
                                  role_code: postUnauthorizedCall.fulfilled.match(
                                    resultActionCreateRole
                                  )
                                    ? resultActionCreateRole.payload.item_id
                                    : undefined,
                                  createdat: new Date(),
                                  createdby: admin_email || information.admin_email,
                                  last_login_at: dayjs().toISOString(),
                                },
                              },
                            })
                          ),
                        ]);

                        if (postUnauthorizedCall.fulfilled.match(resultActions1[2])) {
                          let affiliation_id;
                          let positions_id;
                          let affiliation_code;
                          if (
                            postUnauthorizedCall.fulfilled.match(resultActions1[0]) &&
                            resultActions1[0].payload.item
                          ) {
                            affiliation_id = resultActions1[0].payload.item_id;
                            affiliation_code = resultActions1[0].payload.item.affiliation_id;
                          }
                          if (
                            postUnauthorizedCall.fulfilled.match(resultActions1[1]) &&
                            resultActions1[1].payload.item
                          ) {
                            positions_id = resultActions1[1].payload.item_id;
                          }
                          const resultActions2 = await Promise.all([
                            dispatch(
                              postUnauthorizedCall({
                                workspace_id: 'skillfamiliar',
                                url: '/api/v0/applications/skillfamiliar/datastores/affiliation_assign_role/items/new',
                                method: 'POST',
                                params: {
                                  item: {
                                    company_id: dataCompany.id,
                                    affiliation_id: affiliation_id,
                                    login_id: admin_email || information.admin_email,
                                    positions_code: positions_id,
                                    sort_order: 1,
                                    main_role: 'main',
                                    createdby: admin_email || information.admin_email,
                                    createdat: new Date(),
                                  },
                                },
                              })
                            ),
                            dispatch(
                              postUnauthorizedCall({
                                workspace_id: 'skillfamiliar',
                                url: '/api/v0/applications/skillfamiliar/datastores/affiliation_assign_level/items/new',
                                method: 'POST',
                                params: {
                                  item: {
                                    company_id: dataCompany.id,
                                    affiliation_id: affiliation_code,
                                    login_id: admin_email || information.admin_email,
                                    sort_order: 1,
                                    createdby: admin_email || information.admin_email,
                                    createdat: new Date(),
                                  },
                                },
                              })
                            ),
                            dispatch(
                              postUnauthorizedCall({
                                workspace_id: 'skillfamiliar',
                                url: '/api/v0/applications/skillfamiliar/datastores/gmo/items/new',
                                method: 'POST',
                                params: {
                                  action_id: 'customerregistration',
                                  as_params: {
                                    token: res.tokenObject.token,
                                    company_id: dataCompany.id,
                                    company_item_id: dataCompany.i_id,
                                  },
                                  item: {
                                    name: admin_email || information.admin_email,
                                  },
                                  is_force_update: true,
                                  realtime_auto_link: true,
                                },
                              })
                            ),
                          ]);
                          if (
                            postUnauthorizedCall.fulfilled.match(resultActions2[0]) &&
                            postUnauthorizedCall.fulfilled.match(resultActions2[1])
                          ) {
                            dispatch(
                              postUnauthorizedCall({
                                workspace_id: 'skillfamiliar',
                                url: `/api/v0/applications/skillfamiliar/datastores/affiliation_assign_level/items/addlink/${resultActions2[1].payload.item_id}`,
                                method: 'POST',
                                params: {
                                  item: {
                                    link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                                    link_item_id: resultActions2[0].payload.item_id,
                                  },
                                },
                              })
                            );
                          }
                          if (postUnauthorizedCall.fulfilled.match(resultActions2[2])) {
                            dispatch(resetInformation());
                            navigate(
                              generatePath(routes.Login.path, {
                                entity: 'receiving',
                              })
                            );
                            window.location.reload();
                          }
                        }
                      } else {
                        const resultActions1 = await Promise.all([
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: `/api/v0/applications/skillfamiliar/datastores/affiliation_level/items/edit/:${affiliationsMain?.lookup_items?.affiliation_id?.i_id}`,
                              method: 'POST',
                              params: {
                                item: {
                                  name: information.admin_department,
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: `/api/v0/applications/skillfamiliar/datastores/positions/items/edit/:${affiliationsMain?.lookup_items?.positions_code?.i_id}`,
                              method: 'POST',
                              params: {
                                item: {
                                  name: information.admin_position,
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                          dispatch(
                            postUnauthorizedCall({
                              workspace_id: 'skillfamiliar',
                              url: `/api/v0/applications/skillfamiliar/datastores/users/items/edit/:${userInfo?.i_id}`,
                              method: 'POST',
                              params: {
                                item: {
                                  name: information.admin_name,
                                  name_furigana: information.admin_name_furigana,
                                  admin_phone: information.admin_phone,
                                  last_login_at: dayjs().toISOString(),
                                },
                                is_force_update: true,
                              },
                            })
                          ),
                        ]);

                        if (postUnauthorizedCall.fulfilled.match(resultActions1[2])) {
                          dispatch(resetInformation());
                          navigate(
                            generatePath(routes.Login.path, {
                              entity: 'receiving',
                            })
                          );
                          window.location.reload();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        );
      }, 1500);
    },
  });

  const getDataAffiliation = async () => {
    if (!company_id) return;
    const result = await dispatch(
      postUnauthorizedCall({
        workspace_id: 'skillfamiliar',
        url: '/api/v0/applications/skillfamiliar/datastores/affiliation_assign_role/items/search',
        method: 'POST',
        params: {
          conditions: [
            {
              id: 'login_id',
              search_value: [admin_email],
              exact_match: true,
            },
            {
              id: 'main_role',
              search_value: ['main'],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
        },
      })
    );

    if (postUnauthorizedCall.fulfilled.match(result)) {
      dispatch(updateAffiliationsMain(result.payload.items[0]));
    }
  };

  const getUserInfo = async () => {
    if (!company_id) return;
    const result = await dispatch(
      postUnauthorizedCall({
        workspace_id: 'skillfamiliar',
        url: '/api/v0/applications/skillfamiliar/datastores/users/items/search',
        method: 'POST',
        params: {
          conditions: [
            {
              id: 'login_id',
              search_value: [admin_email],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
          use_display_id: true,
        },
      })
    );

    if (postUnauthorizedCall.fulfilled.match(result)) {
      dispatch(updateUserInfoReducer(result.payload.items[0]));
    }
  };

  useEffect(() => {
    getDataAffiliation();
    getUserInfo();
  }, []);

  useEffect(() => {
    if (company_id) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getAffiliationAssignRole({
            conditions: [
              {
                id: 'login_id',
                search_value: [signedInEmail],
                exact_match: true,
              },
              {
                id: 'main_role',
                search_value: ['main'],
              },
            ],
            page: 1,
            per_page: 1,
            include_lookups: true,
            include_item_ref: true,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, []);

  return (
    <Wrapper>
      {success ? (
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
        </p>
      ) : (
        <FormikProvider value={formik}>
          <p className="text-center">{messages['AJ-05-1']}</p>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
          >
            <div className="form">
              <Form.Item
                name="name"
                label={<span className="text-label">料金プラン</span>}
                className="form-input"
              >
                <div className="step-top">
                  <div className="step-title">
                    <div className="gourd-left">
                      <div className="form-step">
                        <p className="title-gourd">基本利用料</p>
                        <div className="title">
                          <div className="title-left">
                            <p className="name">ユーザー利用料</p>
                            <p className="title-name-border">1ユーザー</p>
                            <p className="title-border">
                              {planPremium?.user_monthly_fee || 0}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・SKILL FAMILIARのすべての
                                <br /> 機能が利用できます。
                              </p>
                              <p>
                                ・社内ユーザーの1か月の最大 <br /> 登録人数が対象となります。
                              </p>
                            </div>
                          </div>
                          <div className="total">+</div>
                          <div className="title-left title-left-center">
                            <p className="name">ストレージ利用料</p>
                            <p className="title-name-border">1企業50GBにつき</p>
                            <p className="title-border">
                              {planPremium?.usage_storage_unit_price || 0}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・ストレージ利用量50GBごとに
                                <br /> 自動課金されます。
                              </p>
                              <p>
                                ・ストレージ利用量はいつでも <br /> 確認できます。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="total total-right">+</div>
                    <div className="gourd-left">
                      <div className="form-step">
                        <p className="title-gourd">オプション</p>
                        <div className="title">
                          <div className="title-left">
                            <p className="name">面接スキルチェック利用料</p>
                            <p className="title-name-border">1回実施につき</p>
                            <p className="title-border">
                              {planPremium?.skill_check_unit_price || 0}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・面接スキルチェック1回実施 <br /> に対してカウントされます。
                              </p>
                              <p>
                                ・面接ユーザーは月額利用料に <br /> カウントされません。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="bottom">
                    ※ご利用月の「基本利用料＋オプション」の合計金額を毎月ご請求させていただきます。
                  </p>
                </div>
              </Form.Item>
              <Form.Item
                name="name_furigana"
                label={<span className="text-label">支払方法</span>}
                className="form-input"
              >
                <div className="input-review">
                  クレジットカード、銀行振込
                  <br />
                  ※基本的にはクレジットカードでのお支払い。銀行振込には条件がございますので、お問い合わせください。
                </div>
              </Form.Item>
              <Form.Item
                name="building_name"
                label={<span className="text-label">支払の時期</span>}
                className="form-input"
              >
                <div className="input-review">
                  クレジットカード：代金引き落としの時期は、クレジットカード会社によって異なります。
                  <br />
                  ご利用のクレジットカード会社にお問い合わせをお願いいたします。
                  <br />
                  銀行振込：ご利用月の翌月末までにその月の代金のお振込みをお願いしております。
                </div>
              </Form.Item>
              <Form.Item
                name="admin_name"
                label={<span className="text-label">ご利用開始</span>}
                className="form-input"
              >
                <div className="input-review">ご登録完了後すぐにご利用いただけます。</div>
              </Form.Item>
              <Form.Item
                name="admin_name_furigana"
                label={<span className="text-label">キャンセル・解約について</span>}
                className="form-input"
              >
                <div className="input-review">
                  製品の特性上、利用開始後のキャンセルはできません。
                  <br />
                  解約はいつでもお手続きが可能です。詳細は利用規約をご確認ください。
                </div>
              </Form.Item>
            </div>
            <div className="wrap-button">
              <SubmitButton className="btn-submit">{messages['AJ-05-2']}</SubmitButton>
              <button
                type="button"
                className="go-back"
                onClick={() => {
                  setVisible(false);
                  setOpen && setOpen(false);
                  dispatch(logout());
                }}
              >
                {'<  キャンセル'}
              </button>
            </div>
          </Form>
        </FormikProvider>
      )}
    </Wrapper>
  );
};

export default FiveStep;
