import styled from 'styled-components';

export const UsageDetailStyled = styled.div`
  padding: 20px;
  .container-pdf {
    padding: 12px 20px;
  }
  .row-number-pdf {
    border-bottom: 1px solid #e5e5e5;
  }
  .wrap-header-pdf {
    border-bottom: 1px solid #cccccc;
    text-align: center;
  }
  .header-pdf {
    font-size: 18px;
    color: #2a2a2a;
    margin: 0;
    padding: 12px 0;
  }
  .wrap-table {
    border: 1px solid #e5e5e5;
  }
  .wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .time-label {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #eeeeee;
      padding: 5px 10px;
      .label {
        margin: 0;
        font-size: 11px;
        text-align: center;
        color: #777777;
      }
      .time-text {
        margin: 0;
        font-size: 20px;
        color: #2a2a2a;
      }
      .item-check {
        font-size: 12px;
        color: #f33456;
        .icon {
          padding: 0 5px 0 10px;
        }
      }
      .item-check-time {
        font-size: 12px;
        color: #08a3a5;
        .icon {
          padding: 0 5px 0 10px;
        }
      }
    }
    .btn-header {
      display: flex;
      .btn {
        cursor: pointer;
        font-size: 13px;
        margin-right: 8px;
        font-weight: 700;
        width: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-radius: 5px;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        color: #ffffff;
        .size-icon {
          font-size: 18px;
        }
      }
      .btn-default {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        color: #f1a900;
      }
    }
  }
  .content {
    padding-top: 15px;
    font-size: 11px;
    color: #f33456;
  }
  .user-fee-detail,
  .storage-user-fee-detail,
  .skillcheck-fee-detail,
  .bill-total {
    margin-top: 50px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .label {
        font-weight: 700;
        font-size: 15px;
      }
    }
  }
  .user-fee-detail .title .circle {
    background-color: #ea7f42;
  }
  .user-fee-detail .title .label {
    color: #ea7f42;
  }

  .storage-user-fee-detail .title .circle {
    background-color: #92bbe2;
  }
  .storage-user-fee-detail .title .label {
    color: #92bbe2;
  }
  .skillcheck-fee-detail .title .circle {
    background-color: #08a3a5;
  }
  .skillcheck-fee-detail .title .label {
    color: #08a3a5;
  }
  .bill-total .title .label {
    color: #424242;
  }
  .table {
    .ant-table-container {
      border: 1px solid #e5e5e5;
    }
    .table-footer {
      display: flex;
      height: 55px;
      text-align: end;
      border: 1px solid #e5e5e5;
      background: linear-gradient(0deg, #ffffff, #ffffff), #ffffff;
      .total {
        width: 25%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 0 15px;
        border-left: 1px solid #e5e5e5;
        .item-total {
          display: flex;
          align-items: baseline;
          .number {
            margin: 0;
            font-family: Lato;
            font-size: 18px;
            color: #2a2a2a;
          }
          .text {
            font-size: 10px;
            margin: 0;
            color: #999999;
          }
        }
      }
      .label {
        width: 75%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 0 15px;
        font-weight: 700;
        font-size: 14px;
        color: #000000;
      }
    }
    .ant-table-thead tr .ant-table-cell {
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      text-align: center;
      ::before {
        height: 100% !important;
      }
    }
    .ant-table-tbody .ant-table-cell {
      text-align: end;
      border-left: 1px solid #eaeaea !important;
    }
    .item-table {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: baseline;
      .label-number {
        margin: 0;
        font-size: 14px;
        color: #424242;
      }
      .label {
        margin: 0;
        color: #999999;
        font-size: 10px;
      }
    }
    .item-center {
      .item-table {
        justify-content: center;
      }
    }
    .item-start {
      .item-table {
        justify-content: start;
      }
    }
  }
  .row-label {
    background: #ebebeb;
    border-radius: 2px 2px 0 0;
    height: 50px;
    .gutter-row {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      .text {
        font-size: 10px;
        margin: 0;
        color: #999999;
      }
    }
    .center {
      border-left: 1px solid rgba(85, 85, 85, 0.18);
      border-right: 1px solid rgba(85, 85, 85, 0.18);
    }
  }
  .row-number,
  .row-number-pdf {
    background: #ffffff;
    border-radius: 0 0 3px 3px;
    height: 50px;
    .gutter-row {
      display: flex;
      justify-content: center;
      align-items: center;
      .item-total {
        display: flex;
        align-items: baseline;
        .text {
          font-size: 10px;
          margin: 0;
          color: #999999;
        }
        .number {
          margin: 0;
          color: #999999;
          font-family: Lato;
          font-size: 18px;
          color: #2a2a2a;
        }
      }
      .item-unsettled {
        font-family: Lato;
        font-size: 18px;
        color: #f0330a;
      }
    }
    .center {
      border-left: 1px solid rgba(85, 85, 85, 0.18);
      border-right: 1px solid rgba(85, 85, 85, 0.18);
    }
    .end {
      font-family: Lato;
      font-weight: 700;
      font-size: 20px;
      color: #2a2a2a;
    }
  }
  .ant-table-footer {
    padding: 0;
  }
`;
