import React from 'react';
import { maxBy } from 'lodash';

import NodeContentRenderer from '../NodeRenderer';
import * as Types from 'types';

interface Props {
  column: Types.TreeItem<Types.CurriculumItemType>[];
}

const ColumnRenderer: React.FC<Props> = ({ column }) => {
  return (
    <div>
      {Array.from({
        length: maxBy(column, 'lineIndex')?.lineIndex || 0,
      }).map((__, index) => {
        const row = column.find((r) => r.lineIndex! - 1 === index);
        if (!row) {
          return <div key={index} />;
        }

        return <NodeContentRenderer node={row} key={index} />;
      })}
    </div>
  );
};

export default ColumnRenderer;
