import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import useConfirmCreateUser, { Props, ReceivedProps } from './hook';
import { Modal } from 'components';
import Styled from './styles';

const ConfirmCreateUserLayout: React.FC<Props> = (props) => {
  const { isOpen, handleClose, handleSubmit } = props;

  return (
    <Modal
      title="社内ユーザー新規作成"
      width={720}
      open={isOpen}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
      footerStyle={{
        background: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      bodyStyle={{
        background: '#f9f8f8',
        padding: '32px 0px 28px 0px',
      }}
    >
      <Styled>
        <ExclamationCircleOutlined className="icon" />
        <p className="description">社内ユーザーマスタに移動します。</p>
      </Styled>
    </Modal>
  );
};

const ConfirmCreateUserModal = (props: ReceivedProps) => {
  return <ConfirmCreateUserLayout {...useConfirmCreateUser(props)} />;
};

export default ConfirmCreateUserModal;
