import React from 'react';
import { EditFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import dayjs from 'dayjs';

import { dashboardSelector } from 'pages/Dashboard/selectors';
import { UserType } from 'types/config';
import { Wrapper } from './styles';

interface Props {
  role?: UserType;
}

const SkillCheck: React.FC<Props> = ({ role }) => {
  const { dataSkillCheck, skillCheckInformation } = useSelector(dashboardSelector);

  return (
    <Wrapper>
      <Row className="header pd-10">
        <EditFilled className="icon" />
        <span className="title">スキルチェック情報</span>
      </Row>
      <div className="wrap-item">
        {(role === 'admin' ? dataSkillCheck : skillCheckInformation).map((item, index) => (
          <Row className="items pd-10" key={index}>
            <div className="wrap-content">
              <div className="wrap-date-and-status">
                {item.start_period && (
                  <>
                    <span className="content-date">
                      {dayjs(item.start_period).format('YYYY年MM月 開始')}
                    </span>

                    {item.end_period &&
                    dayjs().isBetween(dayjs(item.start_period), dayjs(item.end_period)) ? (
                      <span className="content-status">実施期間中</span>
                    ) : dayjs(item.start_period).diff(dayjs(), 'day') > 0 ? (
                      <span className="content-status-date">
                        あと
                        <span className="content-status-date-upcase">
                          {dayjs(item.start_period).diff(dayjs(), 'day') + 1}日
                        </span>
                      </span>
                    ) : dayjs(item.start_period).diff(dayjs(), 'hour') > 0 ? (
                      <span className="content-status-date">
                        あと
                        <span className="content-status-date-upcase">
                          {dayjs(item.start_period).diff(dayjs(), 'hour') + 1}時間
                        </span>
                      </span>
                    ) : null}
                  </>
                )}
              </div>
              <div className="content-text">{item.name}</div>
            </div>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default SkillCheck;
