import { omit } from 'lodash';

import { FileExplorer } from 'types';

const getNodeDataAtTreeIndexOrNextIndex = ({
  targetIndex,
  node,
  currentIndex,
  getNodeKey,
  path = [],
  lowerSiblingCounts = [],
  ignoreCollapsed = true,
  isPseudoRoot = false,
}: any) => {
  const selfPath = !isPseudoRoot ? [...path, getNodeKey({ node, treeIndex: currentIndex })] : [];

  if (currentIndex === targetIndex) {
    return {
      node,
      lowerSiblingCounts,
      path: selfPath,
    };
  }

  if (!node.children || (ignoreCollapsed && node.expanded !== true)) {
    return { nextIndex: currentIndex + 1 };
  }

  let childIndex = currentIndex + 1;
  const childCount = node.children.length;
  for (let i = 0; i < childCount; i += 1) {
    const result: any = getNodeDataAtTreeIndexOrNextIndex({
      ignoreCollapsed,
      getNodeKey,
      targetIndex,
      node: node.children[i],
      currentIndex: childIndex,
      lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
      path: selfPath,
    });

    if (result.node) {
      return result;
    }

    childIndex = result.nextIndex;
  }

  return { nextIndex: childIndex };
};

export const getDescendantCount = ({
  node,
  ignoreCollapsed = true,
}: {
  node: FileExplorer.TreeItem;
  ignoreCollapsed?: boolean;
}) => {
  return (
    getNodeDataAtTreeIndexOrNextIndex({
      getNodeKey: () => {},
      ignoreCollapsed,
      node,
      currentIndex: 0,
      targetIndex: -1,
    }).nextIndex - 1
  );
};

const walkDescendants = ({
  callback,
  getNodeKey,
  ignoreCollapsed,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  lowerSiblingCounts = [],
}: any) => {
  const selfPath = isPseudoRoot ? [] : [...path, getNodeKey({ node, treeIndex: currentIndex })];
  const selfInfo = isPseudoRoot
    ? null
    : {
        node,
        parentNode,
        path: selfPath,
        lowerSiblingCounts,
        treeIndex: currentIndex,
      };

  if (!isPseudoRoot) {
    const callbackResult = callback(selfInfo);

    if (callbackResult === false) {
      return false;
    }
  }

  if (!node.children || (node.expanded !== true && ignoreCollapsed && !isPseudoRoot)) {
    return currentIndex;
  }

  let childIndex = currentIndex;
  const childCount = node.children.length;
  if (typeof node.children !== 'function') {
    for (let i = 0; i < childCount; i += 1) {
      childIndex = walkDescendants({
        callback,
        getNodeKey,
        ignoreCollapsed,
        node: node.children[i],
        parentNode: isPseudoRoot ? null : node,
        currentIndex: childIndex + 1,
        lowerSiblingCounts: [...lowerSiblingCounts, childCount - i - 1],
        path: selfPath,
      });

      if (childIndex === false) {
        return false;
      }
    }
  }

  return childIndex;
};

export const walk = ({
  treeData,
  getNodeKey,
  callback,
  ignoreCollapsed = true,
}: FileExplorer.FullTree & {
  getNodeKey: FileExplorer.GetNodeKeyFunction;
  callback: Function;
  ignoreCollapsed?: boolean | undefined;
}) => {
  if (!treeData || treeData.length < 1) {
    return;
  }

  walkDescendants({
    callback,
    getNodeKey,
    ignoreCollapsed,
    isPseudoRoot: true,
    node: { children: treeData },
    currentIndex: -1,
    path: [],
    lowerSiblingCounts: [],
  });
};

export const changeNodeAtID = ({
  treeData,
  i_id,
  newNode,
  ignoreCollapsed = true,
}: FileExplorer.FullTree & {
  i_id: string;
  newNode: Function | any;
  ignoreCollapsed?: boolean | undefined;
}) => {
  const RESULT_MISS = 'RESULT_MISS';

  const traverse = ({ isPseudoRoot = false, node, currentTreeIndex }: any) => {
    if (!isPseudoRoot && !i_id) {
      return RESULT_MISS;
    }

    if (node.i_id === i_id) {
      return typeof newNode === 'function' ? newNode(node, currentTreeIndex) : newNode;
    }

    if (!node.children) {
      return RESULT_MISS;
    }

    let nextTreeIndex = currentTreeIndex + 1;
    for (let i = 0; i < node.children.length; i += 1) {
      const result: any = traverse({
        node: node.children[i],
        currentTreeIndex: nextTreeIndex,
      });

      if (result !== RESULT_MISS) {
        if (result) {
          return {
            ...node,
            children: [...node.children.slice(0, i), result, ...node.children.slice(i + 1)],
          };
        }
        return {
          ...node,
          children: [...node.children.slice(0, i), ...node.children.slice(i + 1)],
        };
      }

      nextTreeIndex += 1 + getDescendantCount({ node: node.children[i], ignoreCollapsed });
    }

    return RESULT_MISS;
  };

  const result = traverse({
    node: { children: treeData },
    currentTreeIndex: -1,
    isPseudoRoot: true,
  });

  if (result === RESULT_MISS) {
    throw new Error('No node found at the given path.');
  }

  return result.children;
};

export const getFlatDataFromTree = ({
  treeData,
  getNodeKey,
  ignoreCollapsed = true,
}: FileExplorer.FullTree & {
  getNodeKey: FileExplorer.GetNodeKeyFunction;
  ignoreCollapsed?: boolean | undefined;
}): FileExplorer.FlatDataItem[] => {
  if (!treeData || treeData.length < 1) {
    return [];
  }

  const flattened: FileExplorer.FlatDataItem[] = [];
  walk({
    treeData,
    getNodeKey,
    ignoreCollapsed,
    callback: (nodeInfo: FileExplorer.FlatDataItem) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

export const defaultGetNodeKey = ({ treeIndex }: { treeIndex: number }) => {
  return treeIndex;
};

export const checkExistItem = ({
  treeData,
  item,
}: {
  treeData: FileExplorer.TreeItem;
  item: FileExplorer.TreeItem;
}) => {
  if (!treeData) {
    return false;
  }

  let flattened = false;
  walkCheckExist({
    node: { children: [treeData] },
    item,
    callback: (nodeInfo: boolean) => {
      flattened = nodeInfo;
    },
  });

  return flattened;
};

const walkCheckExist = ({ node, item, callback }: FileExplorer.FileExplorerWalk): boolean => {
  if (!node.children) {
    return false;
  }

  if (node.i_id && node.i_id === item.i_id) {
    callback(true);
    return true;
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    const result = walkCheckExist({
      callback,
      node: node.children[i],
      item,
    });
    if (result) {
      return true;
    }
  }
  return false;
};

export const getPathFolder = (
  folders: FileExplorer.TreeItem[],
  activeFolder?: FileExplorer.TreeItem
) => {
  if (!folders || !activeFolder) {
    return [];
  }

  const flattened: FileExplorer.TreeItem[] = [];
  walkPathFolder({
    node: { children: folders },
    item: activeFolder,
    callback: (nodeInfo: FileExplorer.TreeItem) => {
      flattened.unshift(nodeInfo);
    },
  });

  return flattened;
};

const walkPathFolder = ({ node, item, callback }: FileExplorer.FileExplorerWalk) => {
  if (!node.children) return;

  if (node.i_id && node.i_id === item.i_id) {
    callback(node);
    return true;
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    const result = walkPathFolder({
      callback,
      node: node.children[i],
      item,
    });
    if (result && node.i_id) {
      callback(node);
      return true;
    }
  }
};

export const getChildrenItemID = ({
  node,
  callback,
}: {
  node: FileExplorer.TreeItem;
  callback: Function;
}) => {
  if (node.i_id) {
    callback(omit(node, ['children']));
  }

  if (!node.children || node.children.length <= 0) {
    return;
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    getChildrenItemID({
      node: node.children[i],
      callback,
    });
  }

  return;
};

export const getChildrenItemIDFromTree = ({
  treeData,
}: {
  treeData: FileExplorer.TreeItem;
}): FileExplorer.FileExplorerType[] => {
  if (!treeData) {
    return [];
  }

  const flattened: FileExplorer.FileExplorerType[] = [];
  getChildrenItemID({
    node: { children: [treeData] },
    callback: (nodeInfo: FileExplorer.FileExplorerType) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

export const addFolderToTree = (
  treeData: FileExplorer.TreeItem[] | FileExplorer.TreeItem,
  el: FileExplorer.TreeItem
): any =>
  Array.isArray(treeData)
    ? 'parent_folder_id' in el
      ? treeData.map((o) => addFolderToTree(o, el))
      : [...treeData, el]
    : treeData.folder_id === el.parent_folder_id
    ? { ...treeData, children: [...(treeData.children || []), el] }
    : {
        ...(treeData as FileExplorer.TreeItem),
        ...('children' in treeData ? { children: addFolderToTree(treeData.children!, el) } : {}),
      };

export const removeFolderFromTree = (treeData: FileExplorer.TreeItem[], id: string): any =>
  treeData.flatMap((o) =>
    o.i_id === id ? [...(o.children || []).map((e) => removeFolder(e, id))] : [removeFolder(o, id)]
  );

export const removeFolder = (
  treeData: FileExplorer.TreeItem[] | FileExplorer.TreeItem,
  id: string
): any => {
  return Array.isArray(treeData)
    ? removeFolderFromTree(treeData, id)
    : {
        ...treeData,
        ...(treeData.children ? { children: removeFolderFromTree(treeData.children, id) } : {}),
      };
};
