import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import { SkillCheck, SkillCheckAnalysis, UserReport, UserAnalysis } from './ReportType';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { REPORT_TYPE } from 'constant/select.constants';
import { UserType } from 'types/config';
import { Header } from 'components';
import Wrapper from './styles';
import {
  getEmployeeUser,
  getInterviewUser,
  getSelectAnalysisGroup,
  getSelectSkillCheck,
  getSelectTypes,
} from './thunk';

const { Option } = Select;

const SkillCheckReport: React.FC = () => {
  const [reportType, setReportType] = useState<(typeof REPORT_TYPE)[number]['value']>('user');
  const [userType, setUserType] = useState<UserType>('member');

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getInterviewUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getEmployeeUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectAnalysisGroup({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <Wrapper>
      <Header title={headerTitle} />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">ユーザー種別</span>
            <Select className="select" placeholder="社内" value={userType} onSelect={setUserType}>
              <Option value="member">社内</Option>
              <Option value="interview">面接ユーザー</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの種類</span>
            <Select className="select" value={reportType} onSelect={setReportType}>
              {REPORT_TYPE.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      {reportType === 'user' ? (
        <UserReport userType={userType} reportType={reportType} />
      ) : reportType === 'user-analysis' ? (
        <UserAnalysis userType={userType} reportType={reportType} />
      ) : reportType === 'skill-check' ? (
        <SkillCheck userType={userType} reportType={reportType} />
      ) : (
        <SkillCheckAnalysis userType={userType} reportType={reportType} />
      )}
    </Wrapper>
  );
};

export default SkillCheckReport;
