import styled from 'styled-components';

import { Modal } from 'components';

export const InvoicePreviewStyled = styled(Modal)`
  .ant-modal-content {
    position: relative;
    background-color: transparent;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    box-shadow: none;
    pointer-events: auto;
  }
  .ant-modal-body {
    padding: 28px 22px 22px 32px;
  }
  .content {
    .content-header {
      .content-header-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 100px;
          height: 68px;
        }
        .content-header-text {
          display: flex;
          flex-direction: column;
          font-size: 11px;
          color: #555555;
          margin-right: 12px;
        }
      }
      .title-modal {
        display: flex;
        font-size: 20px;
        justify-content: center;
        color: #222222;
        letter-spacing: 0.7em;
        line-height: 16px;
      }
      .wrap-title-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 36px;
        .name-company {
          font-size: 14px;
          color: #222222;
        }
        .wrap-content-standard {
          display: flex;
          flex-direction: column;
          .title-standard {
            display: flex;
            justify-content: end;
            font-size: 8px;
            color: #777777;
          }
          .sub-title-standard {
            font-size: 13px;
            color: #222222;
          }
        }
      }
      .info-content {
        display: flex;
        justify-content: space-between;
        .info-content-left {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          color: #222222;
          span {
            line-height: 20px;
          }
        }
        .info-content-right {
          display: flex;
          flex-direction: column;
          text-align: end;
          font-size: 10px;
          color: #222222;
          position: relative;
          img {
            position: absolute;
            right: -8px;
            bottom: -4px;
            width: 74px;
            height: 74px;
          }
          span {
            line-height: 16px;
          }
        }
      }
      .total-header {
        margin-top: 20px;
        width: 45%;
        border: 1px solid #08a3a5;
        padding: 1px 6px 0 12px;
        display: flex;
        flex-direction: column;
        .total-header-title {
          font-size: 8px;
          color: #159193;
        }
        .total-number {
          display: flex;
          justify-content: flex-end;
          color: #222222;
          font-size: 22px;
          align-items: baseline;
          .currency-unit {
            font-size: 12px;
            color: #000000;
            margin-left: 8px;
          }
        }
      }
    }
    .content-body {
      margin-top: 20px;
      table {
        width: 100%;
        border-bottom: 1px solid #08a3a5;
        tr {
          height: 36px;
          th {
            background-color: #08a3a5;
            font-weight: 700;
            font-size: 11px;
            line-height: 22px;
            color: #ffffff;
            text-align: center;
          }
          td {
            text-align: right;
            position: relative;
          }
          td:first-child {
            text-align: left;
            padding: 0 0 0 8px;
          }
          td:last-child {
            padding: 0 16px 0 0;
          }
          td:last-child::after {
            content: '';
            width: 0;
            height: 0;
            right: 0;
          }
          td::after {
            content: '';
            width: 1px;
            height: 24px;
            background-color: #eeeeee;
            position: absolute;
            right: 0;
          }
          td:nth-child(2)::after,
          td:nth-child(3)::after {
            content: '';
            width: 1px;
            height: 24px;
            background-color: #eeeeee;
            position: absolute;
            right: -12px;
          }
        }
        tr:nth-child(even) {
          background-color: #ffffff;
        }
        tr:nth-child(odd) {
          background-color: #fafafa;
        }
        tr:first-child {
          height: auto;
        }
      }
      .sub-table {
        display: flex;
        justify-content: flex-end;
        .wrap-sub-item {
          width: 40%;
          .sub-item {
            border-bottom: 1px solid #08a3a5;
            display: flex;
            justify-content: space-between;
            padding: 7px 0;
            align-items: center;
            .sub-title {
              padding-left: 8px;
              color: #555555;
              font-size: 12px;
            }
            .sub-price {
              padding-right: 8px;
              color: #222222;
              font-size: 16px;
            }
          }
        }
      }
    }
    .content-footer {
      margin-top: 76px;
      baccolor: #08a3a5;
      background-color: rgba(8, 163, 165, 0.06);
      padding: 16px 0 16px 20px;
    }
  }
`;
