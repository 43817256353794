import React, { useEffect, useState } from 'react';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { officialCurriculumSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { getDataProviderInformation } from '../../thunk';
import ProductInformation from '../ProductInformation';
import CurriculumContentUseStyles from './styles';
import CurriculumNotes from '../CurriculumNotes';
import CompleteModal from '../CompleteModal';
import ConfirmModal from '../ConfirmModal';
import { NoImageOfficial } from 'assets';
import { Modal } from 'components';
import * as Types from 'types';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataItemDetail?: Types.OfficialCurriculumList2.ResponseType;
  dataOfficialCurriculumList?: Array<Types.OfficialCurriculumList2.ResponseType>;
  onSubmit?: () => void | Promise<void>;
}

const CurriculumContentUse: React.FC<Props> = ({
  visible,
  setVisible,
  dataItemDetail,
  dataOfficialCurriculumList,
  onSubmit,
}) => {
  const [openProductInformation, setOpenProductInformation] = useState<boolean>(false);
  const [openCurriculumNotes, setOpenCurriculumNotes] = useState<boolean>(false);
  const [isUpdateComplete, setIsUpdateComplete] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const { dataInformationCurriculum } = useSelector(officialCurriculumSelector);
  const dispatch = useAppDispatch();
  const { userInfo } = useSelector(authSelector);
  const handleToggleModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataProviderInformation({
          conditions: [
            {
              id: 'company_id',
              search_value: [dataItemDetail?.provider_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, visible, dataItemDetail]);

  return (
    <Modal
      width={720}
      title="カリキュラム説明"
      open={visible}
      onCancel={handleToggleModal}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: 0 }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <CurriculumContentUseStyles>
        <div className="item-image">
          {dataItemDetail?.file?.originFileObj ? (
            <img
              src={URL.createObjectURL(new Blob([dataItemDetail?.file?.originFileObj as Blob]))}
              alt=""
              className="design-course"
            />
          ) : (
            <img src={NoImageOfficial} alt="" className="design-course-no" />
          )}
        </div>
        <div className="container">
          <div className="item-title">
            <div className="btn-header">
              <button className="btn btn-default">未利用</button>
              <p className="text-btn" onClick={() => setOpenProductInformation(true)}>
                制作者：<span className="label">{dataInformationCurriculum[0]?.creator}</span>
              </p>
            </div>
            <p className="content">{dataItemDetail?.curriculum_name}</p>
          </div>
          <div className="item-content">
            <button className="btn-information" onClick={() => setOpenCurriculumNotes(true)}>
              リリース情報
            </button>
            <div className="content">
              <p className="label">説明：</p>
              <p className="text-content">{dataItemDetail?.description}</p>
            </div>
          </div>
          <div className="group-btn">
            <button onClick={() => setIsConfirmModal(true)} className="btn btn-close">
              利用する
            </button>
            <button onClick={handleToggleModal} className="btn btn-default">
              閉じる
            </button>
          </div>
        </div>
        <ProductInformation
          visible={openProductInformation}
          setVisible={setOpenProductInformation}
          dataItemDetail={dataItemDetail}
          dataOfficialCurriculumList={dataOfficialCurriculumList}
        />
        <CurriculumNotes
          visible={openCurriculumNotes}
          setVisible={setOpenCurriculumNotes}
          dataItemDetail={dataItemDetail}
        />
        <ConfirmModal
          title="OFFICIALカリキュラム 利用開始"
          visible={isConfirmModal}
          setVisible={setIsConfirmModal}
          onSubmit={onSubmit}
          subTitle="選択した OFFICIALカリキュラムの利用を開始します。"
          description={
            <>
              OKをクリックでカリキュラムツリー上に
              <br />
              OFFICIALカリキュラムが追加されます。
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL カリキュラム 利用開始"
          visible={isUpdateComplete}
          setVisible={setIsUpdateComplete}
          subTitle="OFFICIAL カリキュラムが追加されました。"
        />
      </CurriculumContentUseStyles>
    </Modal>
  );
};

export default CurriculumContentUse;
