import React, { useCallback, useEffect, useState } from 'react';

const cursor = {
  vertical: 'ns-resize',
  horizontal: 'ew-resize',
};

export const useResize = (
  ref: React.RefObject<HTMLDivElement>,
  options?: {
    step?: number;
    axis?: 'vertical' | 'horizontal';
    minWidth?: number;
    maxWidth?: number;
    minHeight?: number;
    maxHeight?: number;
  }
) => {
  const { step = 1, axis = 'horizontal', maxHeight, maxWidth, minHeight, minWidth } = options || {};
  const [dims, setDims] = useState({ width: Infinity, height: Infinity });
  const [size, setSize] = useState({ width: Infinity, height: Infinity });
  const [coords, setCoords] = useState({ x: Infinity, y: Infinity });
  const [resizing, setResizing] = useState(false);

  const initResize = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (!ref.current) return;
      setCoords({ x: event.clientX, y: event.clientY });
      const { width, height } = window.getComputedStyle(ref.current);
      setDims({ width: parseInt(width, 10), height: parseInt(height, 10) });
      setResizing(true);
    },
    [ref]
  );

  const getValue = useCallback((input: number) => Math.ceil(input / step) * step, [step]);

  const doDrag = useCallback(
    (event: MouseEvent) => {
      if (!ref.current || !resizing) return;
      setResizing(true);
      const width = getValue(dims.width + event.clientX - coords.x);
      const height = getValue(dims.height + event.clientY - coords.y);

      if ((minWidth && width < minWidth) || (maxWidth && width > maxWidth)) return;
      if ((minHeight && height < minHeight) || (maxHeight && height > maxHeight)) return;

      if (axis === 'horizontal') ref.current.style.width = width + 'px';
      if (axis === 'vertical') ref.current.style.height = width + 'px';
      setSize({ width, height });
    },
    [
      ref,
      resizing,
      getValue,
      dims.width,
      dims.height,
      coords.x,
      coords.y,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      axis,
    ]
  );

  useEffect(() => {
    const stopDrag = () => {
      setResizing(false);
    };

    if (resizing) {
      document.addEventListener('mousemove', doDrag);
      document.addEventListener('mouseup', stopDrag);
    } else {
      document.removeEventListener('mousemove', doDrag);
      document.removeEventListener('mouseup', stopDrag);
    }

    return () => {
      document.removeEventListener('mousemove', doDrag);
      document.removeEventListener('mouseup', stopDrag);
    };
  }, [resizing, doDrag]);

  return { initResize, size, cursor: cursor[axis], resizing };
};
