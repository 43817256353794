import React, { useState } from 'react';
import { Button } from 'antd';

import FormTreeTrial from '../../Forms/FormFreeTrial';
import { FreeTrialStyled } from './styles';
import { Logo } from 'assets';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const TreeTrialModal: React.FC<Props> = ({ visible, setVisible }) => {
  const [formTreeTrial, setFormTreeTrial] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmitModal = () => {
    setLoading(true);
    setFormTreeTrial(true);
    setLoading(false);
  };

  return (
    <FreeTrialStyled open={visible} onCancel={handleCloseModal}>
      <div className="body">
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
        </p>
        <span className="sub-content">
          <span>SKILL FAMILIAR&nbsp;</span>
          <span className="free-day">30日間無料トライアル</span>
          <span>&nbsp;にようこそ!</span>
        </span>
        <div className="body-content">
          <div className="content">
            <div className="content-left">
              <p>● ご利用条件</p>
              <div>
                <Button className="btn-content">ご利用継続の場合</Button>
                <span className="content-footer-right">管理者ユーザー ×1&emsp;</span>
                <span>社内ユーザー ×5&emsp;</span>
                <span>面接ユーザー ×3</span>
              </div>
              <div className="btn-to">
                <Button className="btn-content">ストレージ管理</Button>
                <span className="content-footer-right">3GBまで</span>
              </div>
              <div className="btn-to">
                <Button className="btn-content">トライアル期間</Button>
                <span className="content-footer-right">30日間</span>
              </div>
              <p className="content-footer">● ご利用の留意点</p>
              <div className="content-footer-text">
                <Button className="btn-content">トライアル期間</Button>
                <span className="content-footer-right">
                  無料トライアル期間終了後10日以内にサービス利用を開始していただけると、
                  <br />
                  無料トライアル期間に作成したデータをそのままご利用いただけます。
                  <br />
                  期間を過ぎるとデータは削除されますのでご留意ください。
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-footer">はじめに利用者情報の登録を行います。</div>
        <div className="btn-submit">
          <Button className="btn" onClick={handleSubmitModal} loading={loading}>
            ご利用開始
          </Button>
        </div>
      </div>
      <FormTreeTrial visible={formTreeTrial} setVisible={setFormTreeTrial} />
    </FreeTrialStyled>
  );
};

export default TreeTrialModal;
