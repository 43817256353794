import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DeleteOutlined, EyeInvisibleOutlined, UserOutlined } from '@ant-design/icons';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { UploadFile } from 'antd/lib/upload/interface';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button, Select, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { filter, orderBy } from 'lodash';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import SkillCheckHiddenListModal from '../Modal/SkillCheckHiddenList';
import { settingSelector } from 'containers/AppSettings/selectors';
import InterviewUserDetailPDF from '../PDF/InterviewUserDetailPDF';
import { Header, Input, SelectField, TextArea } from 'components';
import { updateInterviewSchema } from 'libs/validations';
import { ModalEnlarge, SkillCheckModal } from '../Modal';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { getUserDetail } from 'containers/Auth/thunk';
import { interviewUsersSelector } from '../selectors';
import ConfirmExportPDFModal from './ModalExportPDF';
import { CREDIT_CARD_MONTH } from 'constant';
import exportPDF from 'libs/utils/exportPdf';
import { routes } from 'navigations/routes';
import { OrderByType } from 'types/config';
import { useAppDispatch } from 'hooks';
import EditUserStyled from './styles';
import { IconEnalarge } from 'assets';
import * as Types from 'types';
import {
  getDataInterviewDetail,
  getSelectAffiliation,
  getSelectPosition,
  getSelectRole,
  createUserAssignSkillCheck,
  getSubPosition,
  updateSkillCheck,
  updateInterview,
  updateAffiliationRole,
} from '../thunk';

const { Option } = Select;

const EditInterview: React.FC = () => {
  const [dataSkillCheck, setDataSkillCheck] = useState<Array<Types.SkillCheck.ResponseType>>([]);
  const [visibleSKillCheckModal, setVisibleSkillCheckModal] = useState<boolean>(false);
  const [orderTypeSkillCheck, setOrderTypeSkillCheck] = useState<OrderByType>('asc');
  const [userDetail, setUserDetail] = useState<Types.Users.ResponseDetailType>();
  const [visibleEnlarge, setVisibleEnlarge] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [textArea, setTextArea] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [dataCurriculumBatch, setDataCurriculumBatch] = useState<
    Array<Types.Curriculums.ResponseType>
  >([]);
  const [visibleSkillCheckHiddenListModal, setVisibleSkillCheckHiddenListModal] =
    useState<boolean>(false);
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });

  const ref = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { dataSkillUserCheck, dataSelectDepartment, dataSelectPosition } =
    useSelector(interviewUsersSelector);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<Types.EditInterviewFormik>({
    initialValues: {
      manage_code: '',
      email_address: '',
      name: '',
      name_phonetic: '',
      dob: '',
      scheduled_hiring_date: '',
      work_history: '',
      position_code: '',
      affiliation_id: '',
      position_id: '',
    },
    validationSchema: updateInterviewSchema,
    validateOnBlur: false,
    onSubmit: async (values, { setSubmitting }) => {
      if (!id) return;
      if (confirmStatus) {
        dispatch(startLoading());

        await Promise.all([
          dispatch(
            updateInterview({
              id,
              data: {
                item: {
                  dob: dayjs(values.dob).toDate(),
                  doj: dayjs(values.scheduled_hiring_date).toDate(),
                  name: values.name,
                  name_furigana: values.name_phonetic,
                  manage_code: values.manage_code,
                  position_code: values.position_code,
                  work_history: values.work_history,
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          ),
          dispatch(
            updateAffiliationRole({
              id: values.position_id,
              data: {
                item: {
                  affiliation_id: values.affiliation_id,
                  positions_code: values.position_code,
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          ),

          ...dataSkillCheck.map(({ code }) =>
            dispatch(
              createUserAssignSkillCheck({
                item: {
                  skill_check_code: code,
                  login_id: userDetail?.email,
                  company_id: userDetail?.company_id,
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
              })
            )
          ),
        ]);
        dispatch(stopLoading());
        setVisibleCompleted(true);
      } else {
        setConfirmStatus(true);
        setSubmitting(false);
      }
    },
  });

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('dob', `${birthDay.year}${value}${birthDay.day}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const handleSelectHiringDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('scheduled_hiring_date', `${dateJoinedCompany.year}${e}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('scheduled_hiring_date', `${e}${dateJoinedCompany.month}`);
    }
  };

  const handleUpdateSkillCheck = async (item_id: string, hidden: 'on' | 'off') => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateSkillCheck({
        id: item_id,
        data: {
          item: {
            hidden,
          },
          return_item_result: true,
          is_force_update: true,
        },
      })
    );
    if (updateSkillCheck.fulfilled.match(resultAction)) {
      await dispatch(
        getDataInterviewDetail({
          conditions: [
            {
              id: 'company_id',
              search_value: [userDetail?.company_id],
            },
            {
              id: 'email',
              search_value: [userDetail?.email],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
          use_display_id: true,
          include_lookups: true,
          include_item_ref: true,
        })
      );
    }
    dispatch(stopLoading());
  };

  const handleExportPDF = () => {
    if (!ref.current) return;

    exportPDF(ref, '面接ユーザー編集', 'p');
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <InterviewUserDetailPDF
          confirmStatus={confirmStatus}
          birthDay={birthDay}
          dataCurriculumBatch={dataCurriculumBatch}
          dataSkillCheck={dataSkillCheck}
          dataSkillUserCheck={dataSkillUserCheck}
          dateJoinedCompany={dateJoinedCompany}
          orderTypeSkillCheck={orderTypeSkillCheck}
          formik={formik.values}
          dataSelectDepartment={dataSelectDepartment}
          dataSelectPosition={dataSelectPosition}
        />
      </div>
    );
  }, [
    dataSelectPosition,
    formik.values,
    confirmStatus,
    birthDay,
    dataCurriculumBatch,
    dataSkillCheck,
    dataSkillUserCheck,
    dateJoinedCompany,
    orderTypeSkillCheck,
    dataSelectDepartment,
  ]);

  const handleDeleteItemSkillCheck = (_id: string) =>
    setDataSkillCheck(dataSkillCheck.filter(({ i_id }) => i_id !== _id));

  const handleDeleteItemCurriculumBatch = (_id: string) =>
    setDataCurriculumBatch(dataCurriculumBatch.filter(({ i_id }) => i_id !== _id));

  useEffect(() => {
    if (id && userInfo) {
      (async () => {
        dispatch(startLoading());
        const resultAction = await Promise.all([
          dispatch(
            getUserDetail({
              id,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectAffiliation({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        if (getUserDetail.fulfilled.match(resultAction[0])) {
          const file: UploadFile<File> = { uid: '', name: '' };
          const user_detail: Types.Users.ResponseDetailType =
            resultAction[0].payload.field_values.reduce(
              (a, v) => ({ ...a, [v.field_id]: v.value }),
              {}
            );
          setUserDetail(user_detail);

          // const getAttachInterviewAction = await dispatch(
          //   getAttachInterviewFile({
          //     conditions: [
          //       {
          //         id: 'fileID',
          //         search_value: [user_detail.icon_fileID],
          //       },
          //     ],
          //     page: 1,
          //     per_page: 0,
          //   })
          // );
          // if (
          //   getAttachInterviewFile.fulfilled.match(getAttachInterviewAction) &&
          //   getAttachInterviewAction.payload.items[0]?.file
          // ) {
          //   const getFileAction = await dispatch(
          //     getFileAttach({
          //       file_id: getAttachInterviewAction.payload.items[0].file,
          //     })
          //   );
          //   if (getFileAttach.fulfilled.match(getFileAction)) {
          //   }
          // }
          const resultAction2 = await Promise.all([
            dispatch(
              getSubPosition({
                conditions: [
                  {
                    id: 'company_id',
                    search_value: [user_detail.company_id],
                  },
                  {
                    id: 'login_id',
                    search_value: [user_detail.login_id],
                    exact_match: true,
                  },
                ],
                sort_fields: [
                  {
                    id: 'sort_order',
                    order: 'asc',
                  },
                ],
                page: 1,
                per_page: 0,
                use_display_id: true,
                include_lookups: true,
                include_item_ref: true,
              })
            ),
            dispatch(
              getDataInterviewDetail({
                conditions: [
                  {
                    id: 'email',
                    search_value: [user_detail?.email],
                    exact_match: true,
                  },
                ],
                page: 1,
                per_page: 0,
                include_lookups: true,
                include_item_ref: true,
              })
            ),
          ]);
          if (getSubPosition.fulfilled.match(resultAction2[0])) {
            if (user_detail.dob) {
              setBirthDay({
                year: dayjs(user_detail.dob).year().toString(),
                month: (dayjs(user_detail.dob).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
                day: dayjs(user_detail.dob).date().toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }
            if (user_detail.doj) {
              setDateJoinedCompany({
                year: dayjs(user_detail.doj).year().toString(),
                month: (dayjs(user_detail.doj).month() + 1).toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                }),
              });
            }

            const position = resultAction2[0].payload.items.map((item) => ({
              position_id: item.i_id,
              affiliation_id: item.lookup_items?.affiliation_id?.i_id || '',
              positions_code: item.lookup_items?.positions_code?.i_id || '',
              sort_order: item.sort_order,
              main_role: item.main_role,
            }));

            const affiliations = filter(
              position,
              (item) => item.main_role === 'main' && String(item.sort_order) === '1'
            );

            formik.setValues({
              ...formik.values,
              manage_code: user_detail.manage_code,
              name: user_detail.name,
              email_address: user_detail.email,
              name_phonetic: user_detail.name_furigana,
              dob: user_detail.dob ? dayjs(user_detail.dob).format('YYYYMMDD') : '',
              scheduled_hiring_date: dayjs(user_detail.doj).format('YYYYMMDD'),
              work_history: user_detail.work_history,
              position_code: user_detail.position_code?.title,
              affiliation_id: affiliations[0]?.affiliation_id || '',
              position_id: affiliations[0]?.position_id || '',
              avatar: file,
            });
            setTextArea(user_detail.work_history);
          }
        } else {
          navigate(generatePath(routes.InterviewUser.path, { entity: 'receiving' }));
        }
        dispatch(stopLoading());
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      {component}
      <Header title={headerTitle} />
      <EditUserStyled collapsedMenu={collapsedMenu}>
        <div className="wrap-intro">
          <p>
            面接ユーザーマスタの編集を行う画面です。
            <br />
            情報を編集し、更新ボタンをクリックしてください。
          </p>
          <Button className="button-export" onClick={() => setOpen(true)}>
            エクスポート
          </Button>
        </div>
        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="wrap-user">
                  <div className="avatar-user">
                    <UserOutlined className="icon-user" />
                    <span className="text-content">面接ユーザー</span>
                  </div>
                </div>
                <div className="form-left">
                  <Form.Item
                    name="manage-code"
                    label={
                      <span className="text-label">
                        管理番号 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="manage_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                      readOnly={confirmStatus}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="dob"
                    label={<span className="text-label">生年月日</span>}
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      value={birthDay.year || undefined}
                      className="pull_down input_small"
                      onSelect={handleSelectBirthDay('year')}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.month || undefined}
                      onSelect={handleSelectBirthDay('month')}
                      placeholder="--"
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                    <Select
                      className="pull_down input-month-day"
                      value={birthDay.day || undefined}
                      onSelect={handleSelectBirthDay('day')}
                      placeholder="--"
                      disabled={confirmStatus}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="email_address"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email_address"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={true}
                      maxLength={100}
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_phonetic"
                    label={
                      <span className="text-label">
                        氏名（フリガナ） <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_phonetic"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">募集情報</div>
              <div className="body">
                <div className="recruitment-information">
                  <Form.Item
                    name="scheduled_hiring_date"
                    label={
                      <span className="text-label">
                        採用予定年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      allowClear
                      value={dateJoinedCompany.year || undefined}
                      onSelect={handleSelectHiringDate('year')}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 10,
                        },
                        (_, i) => (i + new Date().getFullYear()).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      placeholder="--"
                      allowClear
                      value={dateJoinedCompany.month || undefined}
                      onSelect={handleSelectHiringDate('month')}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                </div>
                <div className="wrap-recruitment">
                  <Form.Item
                    name="affiliation_id"
                    label={
                      <span className="text-label">
                        募集所属 <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <SelectField
                      name="affiliation_id"
                      className="pull_down"
                      placeholder="選択してください"
                      disabled={confirmStatus}
                    >
                      {dataSelectDepartment?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="position_code"
                    className="form-input"
                    label={
                      <span className="text-label">
                        募集役職 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="position_code"
                      className="pull_down"
                      value={formik.values.position_code || undefined}
                      placeholder="選択してください"
                      disabled={confirmStatus}
                    >
                      {dataSelectPosition?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
                <div className="work-history">
                  <Form.Item
                    name="work_history"
                    htmlFor=""
                    label={
                      <div className="wrap-text-area">
                        <span className="label-history">職歴</span>
                        <span className="text-enlarge" onClick={() => setVisibleEnlarge(true)}>
                          <img src={IconEnalarge} alt="enlarge" />
                          入力欄を拡大
                        </span>
                      </div>
                    }
                  >
                    <TextArea
                      name="work_history"
                      className="text-input"
                      placeholder="全角：最大1050文字"
                      rows={4}
                      showCount
                      maxLength={1050}
                      onBlur={(e) => {
                        setTextArea(e.target.value);
                      }}
                      disabled={confirmStatus}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>

            <div className="wrap-skill-check">
              <div className="header">スキルチェック設定状況</div>
              <div className="body">
                <p className="description">
                  スキルチェックを設定することができます。スキルチェックを追加ボタンをクリックしてください。
                </p>
                {dataSkillUserCheck?.length > 0 && (
                  <>
                    <div className="wrap-label-hide">
                      <span
                        className="label-hide"
                        onClick={() => setVisibleSkillCheckHiddenListModal(true)}
                      >
                        <EyeInvisibleOutlined className="icon" /> 非表示リスト
                      </span>
                      <Select
                        className="select-sort-order"
                        onChange={setOrderTypeSkillCheck}
                        value={orderTypeSkillCheck}
                      >
                        <Option value="asc">設定の古い順に表示</Option>
                        <Option value="desc">設定の新しい順に表示</Option>
                      </Select>
                    </div>
                    {orderBy(
                      dataSkillUserCheck.filter((e) => e.hidden_skill_check === 'off'),
                      ['createdAt'],
                      [orderTypeSkillCheck]
                    ).map((item, index) => {
                      return (
                        item &&
                        item.hidden_item_id && (
                          <div className="item-result" key={index}>
                            <div>
                              <span className="text-add">{index + 1}</span>
                              <span className="content">{item?.item_name}</span>
                            </div>
                            <Tooltip placement="top" title={'非表示にする'}>
                              {item.hidden_item === 'on' ? (
                                <EyeInvisibleOutlined
                                  className="delete active"
                                  onClick={() => handleUpdateSkillCheck(item.hidden_item_id, 'off')}
                                />
                              ) : (
                                <EyeInvisibleOutlined
                                  className="delete"
                                  onClick={() => handleUpdateSkillCheck(item.hidden_item_id, 'on')}
                                />
                              )}
                            </Tooltip>
                          </div>
                        )
                      );
                    })}
                  </>
                )}
                {dataSkillCheck?.map(({ name, i_id }, index) => (
                  <div key={index} className="item-result-add">
                    <div>
                      <span className="text-add">＋</span>
                      <span className="content">{name}</span>
                    </div>
                    <DeleteOutlined
                      className="delete"
                      onClick={() => handleDeleteItemSkillCheck(i_id)}
                    />
                  </div>
                ))}
                {dataCurriculumBatch?.map(({ name, i_id }, index) => (
                  <div key={index} className="item-result-add">
                    <div>
                      <span className="text-add">＋</span>
                      <span className="content">{name}</span>
                    </div>
                    <DeleteOutlined
                      className="delete"
                      onClick={() => handleDeleteItemCurriculumBatch(i_id)}
                    />
                  </div>
                ))}
                {!dataSkillUserCheck && !dataSkillCheck && <div className="empty-text">未設定</div>}
                <button
                  className="button"
                  type="button"
                  onClick={() => setVisibleSkillCheckModal(true)}
                >
                  ＋ スキルチェックを追加
                </button>
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit" disabled={!formik.dirty}>
                  {confirmStatus ? '登録' : '確認画面へ'}
                </SubmitButton>
                <button
                  className="btn btn_close"
                  onClick={() => navigate(routes.InterviewUser.path)}
                >
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
          <ModalEnlarge
            visible={visibleEnlarge}
            onCancel={() => setVisibleEnlarge(false)}
            onSubmit={() => {}}
            value={textArea}
          />
        </FormikProvider>
        <CompletedModal
          title="更新が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() =>
            navigate(generatePath(routes.InterviewUser.path, { entity: 'receiving' }))
          }
        />

        <SkillCheckModal
          visible={visibleSKillCheckModal}
          setVisible={setVisibleSkillCheckModal}
          onSubmit={(item: Array<Types.SkillCheck.ResponseType>) =>
            setDataSkillCheck([...dataSkillCheck, ...item])
          }
          data={dataSkillCheck}
        />
        <SkillCheckHiddenListModal
          visible={visibleSkillCheckHiddenListModal}
          setVisible={setVisibleSkillCheckHiddenListModal}
        />
        <ConfirmExportPDFModal isOpen={open} setIsOpen={setOpen} onSubmit={handleExportPDF} />
      </EditUserStyled>
    </>
  );
};

export default EditInterview;
