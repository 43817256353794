import React, { useState } from 'react';

import { FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import dayjs from 'dayjs';

import { myChartDashboardSelector } from 'pages/Reports/MyChart/selectors';
import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import QuestionDetail from 'pages/Reports/SkillCheck/QuestionDetail';
import WrapperStyled from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

const SurfaceTable: React.FC = () => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [modalQuestionDetail, setModalQuestionDetail] = useState<{
    visible: boolean;
    question_id?: string;
  }>({
    visible: false,
  });

  const { dataReportLevelsCurriculumUser, totalReportLevelsCurriculumUser } =
    useSelector(myChartDashboardSelector);

  const columns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> = [
    {
      title: 'No.',
      dataIndex: '',
      key: '',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {index + 1 + (page - 1) * 10}
        </span>
      ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'index',
              align: 'center',
              width: '3%',
              render: (_text, _record, index) => (
                <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
                  {index + 1 + (page - 1) * 10}
                </span>
              ),
            },
          ]
        : undefined,
    },
    {
      title: '設問',
      dataIndex: 'question_name',
      key: 'question_name',
      align: 'left',
      ellipsis: true,
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average br-1',
              title: '合計',
              dataIndex: 'question_name',
              key: 'question_name',
              align: 'left',
              ellipsis: true,
            },
          ]
        : undefined,
    },
    {
      title: '詳細',
      dataIndex: 'question_id',
      key: 'question_id',
      width: '3%',
      children: dataReportLevelsCurriculumUser
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'question_id',
              dataIndex: 'question_id',
              width: '3%',
              render: (question_id) => (
                <FileTextOutlined
                  className="icon"
                  onClick={() =>
                    setModalQuestionDetail({
                      visible: true,
                      question_id,
                    })
                  }
                />
              ),
            },
          ]
        : undefined,
    },
    {
      title: '正答',
      dataIndex: 'correct_answer',
      key: 'correct_answer',
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'correct_answer',
              key: 'correct_answer',
              align: 'center',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '解答',
      dataIndex: 'user_answer',
      key: 'user_answer',
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'user_answer',
              key: 'user_answer',
              align: 'center',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '解答結果',
      dataIndex: 'correct_answers_num',
      key: 'correct_answers_num',
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'correct_answers_num',
              key: 'correct_answers_num',
              align: 'center',
              width: '5%',
              render: (value) => (value ? '正解' : '不正解'),
            },
          ]
        : undefined,
    },
    {
      title: '解答時間',
      dataIndex: 'answer_time',
      key: 'answer_time',
      width: '5%',
      render: (value) => formatNumberToMinute(value),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'answer_time',
              dataIndex: 'answer_time',
              width: '5%',
              render: (value) => formatNumberToMinute(value),
            },
          ]
        : undefined,
    },
    {
      title: (
        <span>
          ユーザー
          <br />
          平均解答時間
        </span>
      ),
      dataIndex: 'answer_time_user_average',
      key: 'answer_time_user_average',
      width: '7%',
      render: (value) => formatNumberToMinute(value),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'answer_time_user_average',
              dataIndex: 'answer_time_user_average',
              width: '7%',
              render: (value) => formatNumberToMinute(value),
            },
          ]
        : undefined,
    },
    {
      title: (
        <span>
          ユーザー
          <br />
          平均正解率
        </span>
      ),
      dataIndex: 'accuracy_rate_user_average',
      key: 'accuracy_rate_user_average',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: '-',
              dataIndex: 'accuracy_rate_user_average',
              key: 'accuracy_rate_user_average',
              align: 'center',
              width: '6%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '設問数',
      dataIndex: 'question_count',
      key: 'question_count',
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: formatNumber(dataReportLevelsCurriculumUser[0]?.question_count),
              dataIndex: 'question_count',
              key: 'question_count',
              align: 'right',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'accuracy_rate',
              key: 'accuracy_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.wrong_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'wrong_rate',
              key: 'wrong_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '未実施率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.inexperienced_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'inexperienced_rate',
              key: 'inexperienced_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: (
        <span>
          実質
          <br />
          正解率
        </span>
      ),
      dataIndex: 'real_accuracy_rate',
      key: 'real_accuracy_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.real_accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'real_accuracy_rate',
              key: 'real_accuracy_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: (
        <span>
          正解
          <br />
          進捗率
        </span>
      ),
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'accuracy_rate',
              key: 'accuracy_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '進捗率',
      dataIndex: 'progress_rate',
      key: 'progress_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block' }}>0%</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.progress_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              dataIndex: 'progress_rate',
              key: 'progress_rate',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>
                    {formatNumber(value * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  <span style={{ display: 'block' }}>0%</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: (
        <span>
          設定
          <br />
          スコア
        </span>
      ),
      dataIndex: 'score',
      key: 'score',
      render: (value) =>
        value ? (
          <span style={{ display: 'block' }}>{formatNumber(value * 100)}</span>
        ) : (
          <span style={{ display: 'block' }}>0</span>
        ),
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: (
                <span style={{ display: 'block' }}>
                  {formatNumber(dataReportLevelsCurriculumUser[0]?.score)}
                </span>
              ),
              dataIndex: 'score',
              key: 'score',
              align: 'center',
              width: '5%',
              render: (value) =>
                value ? (
                  <span style={{ display: 'block' }}>{formatNumber(value)}</span>
                ) : (
                  <span style={{ display: 'block' }}>0</span>
                ),
            },
          ]
        : undefined,
    },
    {
      title: '最終更新日',
      dataIndex: 'implementation_date',
      key: 'implementation_date',
      align: 'center',
      render: (value: string) => {
        return value ? (
          dayjs(value).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        );
      },
      children: totalReportLevelsCurriculumUser
        ? [
            {
              className: 'average',
              title: dayjs(dataReportLevelsCurriculumUser[1]?.implementation_date).format(
                'YYYY/MM/DD'
              ),
              dataIndex: 'implementation_date',
              key: 'implementation_date',
              align: 'center',
              width: '8%',
              render: (value) =>
                value ? (
                  dayjs(value).format('YYYY/MM/DD')
                ) : (
                  <span style={{ color: '#F0330A' }}>未実施</span>
                ),
            },
          ]
        : undefined,
    },
  ];

  return (
    <WrapperStyled>
      <Table
        className="table"
        dataSource={dataReportLevelsCurriculumUser
          .map((item, index) => ({ ...item, index }))
          .slice(1)
          .slice((page - 1) * pageSize, page * pageSize)}
        columns={columns}
        bordered
        rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        rowKey="index"
        pagination={{
          pageSize: pageSize,
          showSizeChanger: true,
          total: totalReportLevelsCurriculumUser,
          current: page,
          onChange: (pageChange: number, pageSizeChange: number) => {
            setPage(pageChange);
            setPageSize(pageSizeChange);
          },
          position: ['topCenter'],
          locale: {
            items_per_page: '',
          },
          showTotal: (total, range) => (
            <div className="text-total-record-table">
              {range[0]} - {range[1]} <span className="text-static"> </span> / {total}
              <span className="text-static"> 件</span>
            </div>
          ),
        }}
      />
      <QuestionDetail visible={modalQuestionDetail} setVisible={setModalQuestionDetail} />
    </WrapperStyled>
  );
};

export default SurfaceTable;
