import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Select } from 'antd';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CaretLeftOutlined,
  CheckOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FilterOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import {
  CurriculumItemType,
  ItemMoveCopySelectedType,
  UserTreeviewType,
} from 'types/services/curriculum';
import { CURRICULUM_TYPE, CURRICULUM_STATUS } from 'constant/select.constants';
import { removeNodeLevel4Selected } from 'containers/Curriculum/Search/slice';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import { setFilterConditions, clearFilterConditions } from '../slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { HEADER_HEIGHT, LIST_LABEL, LIST_TAB_BAR } from 'constant';
import NodeTreeView from 'containers/Curriculum/NodeTreeView';
import UserTreeView from 'containers/Curriculum/UserTreeView';
import MenuRightUser from 'containers/Curriculum/SearchUser';
import SearchCurriculum from 'containers/Curriculum/Search';
import { useAppDispatch, usePermission } from 'hooks';
import { Header } from 'components';
import { curriculumSelector } from '../selectors';
import Wrapper, { Button } from './styles';
import { SortByDesc } from 'assets';
import { TreeItem } from 'types';

const { Option } = Select;

interface Props {
  tabActive: number;
  columnClosed: number | undefined;
  data: TreeItem<CurriculumItemType>[];
  curriculumSelected: UserTreeviewType | undefined;
  itemMoveCopySelected: ItemMoveCopySelectedType | undefined;
  selectedQuestion: {
    i_id: string;
    name: string;
    code: string;
  }[];
}

const Treeview: React.FC<Props> = ({
  data,
  tabActive,
  columnClosed,
  selectedQuestion,
  curriculumSelected,
  itemMoveCopySelected,
}) => {
  const [isOpenMenuRight, setOpenMenuRight] = useState<boolean>(false);
  const [pageYOffset, setPageYOffset] = useState<number>(0);

  const { curricullum, data_user_setting, filter_conditions } = useSelector(curriculumSelector);
  const { nodeLevel4Selected } = useSelector(searchQuestionCurriculumSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { headerTitle } = useSelector(settingSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const handleChangeFilter = (
    type?: keyof typeof CurriculumType,
    status?: keyof typeof CurriculumStatus
  ) => {
    if (type) {
      dispatch(
        setFilterConditions({
          type,
        })
      );
    }
    if (status) {
      dispatch(
        setFilterConditions({
          status,
        })
      );
    }
  };

  const handleChangeCurriculumName = (value: string) => {
    dispatch(
      setFilterConditions({
        name: value,
      })
    );
  };

  const handleScroll = () => {
    if (window.pageYOffset >= HEADER_HEIGHT) {
      setPageYOffset(HEADER_HEIGHT);
    } else {
      setPageYOffset(window.pageYOffset);
    }
  };

  useEffect(() => {
    if (nodeLevel4Selected || curriculumSelected) {
      setOpenMenuRight(true);
    }
  }, [nodeLevel4Selected, curriculumSelected]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      dispatch(removeNodeLevel4Selected());
      window.removeEventListener('scroll', handleScroll);
      dispatch(clearFilterConditions());
    };
  }, [dispatch]);

  return (
    <Wrapper
      isOpenMenuRight={tabActive !== 1 && isOpenMenuRight}
      collapsedMenu={collapsedMenu}
      tabActive={tabActive}
    >
      <Header title={headerTitle} className="header">
        <form className="form">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              className="select-input"
              placeholder={messages['M-21-3']}
              value={filter_conditions.type}
              onChange={(value: keyof typeof CurriculumType) =>
                handleChangeFilter(value, undefined)
              }
            >
              {CURRICULUM_TYPE.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <Select
              className="select-input"
              placeholder={messages['M-21-4']}
              value={filter_conditions.status}
              onChange={(value: keyof typeof CurriculumStatus) =>
                handleChangeFilter(undefined, value)
              }
            >
              {CURRICULUM_STATUS.map((crr, i) => (
                <Option key={i} value={crr.value}>
                  {crr.label}
                </Option>
              ))}
            </Select>
          </div>
          <img src={SortByDesc} className="sortByDesc-icon" alt="sort-by-desc-icon" />
          <div className="form-input">
            <Select
              showSearch
              className="select-input"
              placeholder={messages['M-21-5']}
              value={filter_conditions.name}
              filterOption={(input, option) =>
                JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChangeCurriculumName}
            >
              {data.map((curr, index) => (
                <Option key={index} value={curr.i_id}>
                  {curr.name}
                </Option>
              ))}
            </Select>
          </div>
          <button
            type="button"
            className="text-reset"
            onClick={() => dispatch(clearFilterConditions())}
          >
            {messages['M-21-6']}
          </button>
        </form>
      </Header>
      <div className="wrap-title">
        <div className="wrap-button">
          <div>
            {LIST_TAB_BAR.map((item, index) => (
              <Button key={index} tabActive={tabActive} index={index}>
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {messages[item]}
              </Button>
            ))}
          </div>
          <div className="button-function">
            <button className="btn btn-outline">
              {messages['M-21-108']}
              <RightOutlined className="size-icon-down-outline" />
            </button>
            <button className="btn btn-active">
              <CloudUploadOutlined className="size-icon" />
              {messages['M-21-109']}
            </button>
            <button className="btn btn-active">
              <CloudDownloadOutlined className="size-icon" />
              {messages['M-21-110']}
            </button>
          </div>
        </div>
        <div className="flex-label">
          {tabActive !== 2 ? (
            LIST_LABEL.map((item, index) => (
              <p key={index} className={`label-text${index === columnClosed ? ' active' : ''}`}>
                {index < 5 ? (
                  index === columnClosed ? (
                    <CaretUpOutlined className="icon-label" />
                  ) : (
                    <CaretDownOutlined className="icon-label" />
                  )
                ) : null}
                {messages[item]}
              </p>
            ))
          ) : (
            <p className="label-text">
              {columnClosed === 0 ? (
                <CaretUpOutlined className="icon-label" />
              ) : (
                <CaretDownOutlined className="icon-label" />
              )}
              必修カリキュラム
            </p>
          )}
        </div>
        {tabActive !== 1 && (
          <div className={`setting-border ${pageYOffset >= HEADER_HEIGHT ? 'on-top' : ''}`}>
            <div className="border-green" />
            <div className="setting">
              <div className="title">
                <CaretLeftOutlined className={isOpenMenuRight ? 'opened' : ''} />
                <p>{tabActive === 0 ? messages['M-21-10'] : 'ユーザーリスト'}</p>
              </div>
            </div>
          </div>
        )}
        {tabActive === 0 && (
          <p className="title-add">
            {data?.length === 0 && messages['M-21-41']}
            <button
              disabled={permissionNumber === 1}
              className={permissionNumber === 1 ? 'disabled' : 'btn-add'}
            >
              + {messages['M-21-111']}
            </button>
          </p>
        )}
      </div>
      <div className="flex">
        <div className="dashboard">
          <div className="wrap-body">
            {tabActive === 1 && (
              <div className="wrap-title-tab-1">
                <p className="title">
                  {itemMoveCopySelected ? (
                    <>
                      Step.2 ペースト先の階層を選択してください
                      <span className="sub-title">やり直す</span>
                    </>
                  ) : (
                    'Step.1 移動 or コピーしたいアイテムを選択してください。'
                  )}
                </p>
              </div>
            )}
            {tabActive < 2
              ? [curricullum[0]].map((c, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${index < data?.length - 1 ? 'bordered' : ''}`}
                  >
                    <NodeTreeView
                      treeData={c}
                      treeViewIndex={index}
                      tabActive={tabActive}
                      columnClosed={columnClosed}
                      selectedQuestion={selectedQuestion}
                      itemMoveCopySelected={itemMoveCopySelected}
                      setItemMoveCopySelected={() => {}}
                      setSelectedQuestion={() => {}}
                    />
                  </div>
                ))
              : [data_user_setting[0]]?.map((u, index) => (
                  <div
                    key={index}
                    className={`wrap-tree ${
                      index < data_user_setting?.length - 1 ? 'bordered' : ''
                    }`}
                  >
                    <UserTreeView
                      treeData={u}
                      columnClosed={columnClosed}
                      curriculumSelected={curriculumSelected}
                      setCurriculumSelected={() => {}}
                    />
                  </div>
                ))}
          </div>
        </div>
        {isOpenMenuRight ? (
          tabActive === 2 ? (
            <MenuRightUser
              pageYOffset={pageYOffset}
              curriculumSelected={curriculumSelected}
              setCurriculumSelected={() => {}}
            />
          ) : (
            tabActive === 0 && (
              <SearchCurriculum
                pageYOffset={pageYOffset}
                setOpenModalCreateQuestion={() => {}}
                setSelectedQuestion={() => {}}
                selectedQuestion={selectedQuestion}
              />
            )
          )
        ) : null}
      </div>
    </Wrapper>
  );
};

export default Treeview;
