import React, { useEffect, useState } from 'react';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import { omit, sum } from 'lodash';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckUserAnalysisReportSelector } from '../../selectors';
import { ITEM_COMPARISION_ANALYSIS } from 'constant/select.constants';
import Wrapper from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  itemComparision: (typeof ITEM_COMPARISION_ANALYSIS)[number]['value'];
  exportPdf?: boolean;
}

const UserAnalysisReport: React.FC<Props> = ({ itemComparision, exportPdf }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [tableData, setTableData] = useState<
    Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType[]
  >([]);

  const { userAnalysisComparison } = useSelector(skillCheckUserAnalysisReportSelector);

  const columns: ColumnsType<Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '50px',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {!index ? '-' : exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
    },
    {
      key: 'username',
      title: 'ユーザー',
      dataIndex: 'username',
      width: '300px',
      ellipsis: true,
    },
    ...userAnalysisComparison.columnName.map(
      (item) =>
        ({
          key: item,
          title: item,
          dataIndex: item,
          align: 'center',
          render: (value: number) =>
            value ? (
              itemComparision === 'average_answer_time_per_question' ? (
                <span>{formatNumberToMinute(value)}</span>
              ) : (
                <span>
                  {formatNumber(value * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              )
            ) : (
              '0%'
            ),
        } as ColumnType<Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType>)
    ),
  ];

  const calculateAverage = (
    data: Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType[]
  ): Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType => {
    const averages: Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType = {
      username: 'ユーザー平均',
    };

    Object.keys(omit(data, ['username'])).forEach((key) => {
      const valuesForDay = data.map((obj) => obj[key]).filter((value) => typeof value === 'number');
      const average = sum(valuesForDay) / data.length;
      averages[key] = average;
    });

    return averages;
  };

  useEffect(() => {
    const data: Types.ReportSkillCheckUserTrans.UserAnalysisTableComparisonType[] = [];
    userAnalysisComparison.data.forEach((item) => {
      if (!item || !item.implementation_date || !dayjs(item.implementation_date).isValid()) return;
      const date = dayjs(item.implementation_date).format('YYYY/MM/DD');
      const itemIndex = data.findIndex((d) => d.username === item.user_name);
      let value = 0;
      switch (itemComparision) {
        case 'accuracy_rate':
          value = item.accuracy_rate;
          break;
        case 'wrong_rate':
          value = item.wrong_rate;
          break;
        case 'inexperienced_rate':
          value = item.inexperienced_rate;
          break;
        case 'average_answer_time_per_question':
          value = item.average_answer_time_per_question;
          break;
        case 'acquisition_score_rate':
          value = item.acquisition_score_rate;
          break;
        default:
          break;
      }

      if (itemIndex >= 0) {
        data[itemIndex][date] = value;
      } else {
        data.push({
          username: item.user_name,
          [date]: value,
        });
      }
    });

    if (data.length) {
      const dataAverage = calculateAverage(data);
      data.push(dataAverage);
    }

    setTableData(data.reverse());
  }, [userAnalysisComparison, itemComparision]);

  return (
    <Wrapper>
      {tableData.length ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {tableData.length - 1} <span className="text-static"> 件表示</span> /
                {tableData.length - 1}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={tableData.length - 1}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span>
                  {''} / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          exportPdf
            ? tableData.map((item, index) => ({ ...item, index }))
            : tableData
                .map((item, index) => ({ ...item, index }))
                .slice((page - 1) * pageSize, page * pageSize)
        }
        columns={columns}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={false}
      />
    </Wrapper>
  );
};

export default UserAnalysisReport;
