import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 860px;
  .text-center {
    text-align: center;
    color: #777777;
  }
  .form {
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 40px;
    .form-input {
      .require {
        color: #f0330a;
      }
      .input {
        height: 40px;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        &__disabled {
          background-color: #f9f8f8;
          color: #2a2a2a;
        }
      }
      .text-label {
        font-size: 13px;
        display: block;
        text-align: right;
        color: #777777;
        // padding-top: 10px;
        .require {
          color: #f0330a;
        }
      }
    }
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
    .go-back {
      margin-top: 10px;
      font-size: 13px;
      text-decoration-line: underline;
      border: none;
      background: none;
      color: #00a3a5;
      cursor: pointer;
    }
  }
`;
