import styled from 'styled-components';

import { HomeCardColour } from 'constant/enum.constant';

export const Wrapper = styled.div<{ cardColor: HomeCardColour }>`
  .progress-circle {
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    height: 220px;

    .title {
      font-size: 12px;
      color: #424242;
      text-align: center;
    }

    .progress-inside {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .progress-text {
        color: rgba(102, 102, 102, 0.8);
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 5px;
      }

      .sub-text {
        margin-top: 5px;
      }

      .border-bottom {
        border-bottom: 1px solid;
        width: 50%;
      }
      .progress-presentage {
        color: ${(props) =>
          props.cardColor === HomeCardColour.green
            ? '#3FC2A3'
            : props.cardColor === HomeCardColour.blue
            ? '#089AD4'
            : '#BD85D7'};

        .progress-present-number {
          position: relative;
          font-family: Lato;
          font-weight: 400;
          font-size: 44px;
          text-align: center;
          &:after {
            content: '%';
            position: absolute;
            font-size: 0.7em;
            bottom: 2px;
          }
        }

        .sub-present-number {
          font-size: 24px;
          color: #6181d3cc;
        }
      }
      .title {
        font-size: 13px;
        text-align: center;
        color: #424242;
        margin-bottom: 5px;
      }
    }

    .sub-progress-circle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        text-align: center;
        font-size: 13px;
        color: #424242;
        .sub-title {
          color: #666666;
        }
      }
      .progress-presentage .progress-present-number {
        font-size: 22px;
      }
    }
  }
`;
