import { createAsyncThunk } from '@reduxjs/toolkit';

import { COMPANIES, USERS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const updateInformation = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AdministratorMasterFormik>,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/updateInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserSearch = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getUserSearch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCompanies = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
