import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import * as Types from 'types';
import {
  getKnowledgeLikeHistory,
  getDataRankingLikeUserAll,
  getListDepartment,
  getSelectDepartment,
  getSelectUsers,
  getSelectUser,
  getKnowledgeFavoriteHistory,
  getDataKnowledgeCurriculum,
  getSelectDepartmentUser,
  getDepartmentCreate,
  getKnowledgeRecommendDetailOriginal,
  getKnowledgeRecommendDetailAdditional,
  getKnowledgeQADetailOriginal,
  getKnowledgeQADetailAdditional,
  getDataRankingLikeKnowledgeAll,
  getDataListKnowledge,
} from './thunk';

export interface InitialState {
  dataKnowledgeRecommendDetailAdditional: Array<Types.KnowledgeRecommendDetailAdditional.ResponseType>;
  dataKnowledgeQADetailAdditional: Array<Types.KnowledgeQADetailAdditional.ResponseType>;
  dataKnowledgeRecommendDetailOriginal: Array<Types.KnowledgeRecommendDetailOriginal.ResponseType>;
  dataKnowledgeQADetailOriginal: Array<Types.KnowledgeQADetailOriginal.ResponseType>;
  dataRankingKnowledgeAll: Array<Types.RankingLikesKnowledgeAllType>;
  dataKnowledgeCurriculum: Array<Types.KnowledgeCurriculumDetail.DataConverted>;
  dataKnowledgeFavoriteHistory: Array<Types.KnowledgeFavoriteHistory.ResponseType>;
  dataRankingUserAll: Array<Types.RankingLikeUserAll.ResponseType>;
  dataKnowledgeLikeHistory: Array<Types.KnowledgeLikesHistory.ResponseType>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataDepartmentCreate: Array<Types.SelectDepartment.ResponseType>;
  dataSelectUsers: Array<Types.SelectUsers.ResponseType>;
  dataKnowledgeList: Array<Types.KnowledgeListType>;
  listDepartment: Types.Departments.ResponseType[];
  data_user: Array<Types.SelectDepartmentUser.ResponseType>;
  dataSelectDepartmentUser: Array<Types.KnowledgeDepartment.ResponseType>;
  total: number;
}

const initialState: InitialState = {
  dataKnowledgeRecommendDetailAdditional: [],
  dataKnowledgeQADetailAdditional: [],
  dataKnowledgeRecommendDetailOriginal: [],
  dataKnowledgeQADetailOriginal: [],
  dataKnowledgeFavoriteHistory: [],
  dataKnowledgeLikeHistory: [],
  dataSelectDepartmentUser: [],
  dataKnowledgeCurriculum: [],
  dataSelectUsers: [],
  dataSelectDepartment: [],
  dataDepartmentCreate: [],
  dataRankingKnowledgeAll: [],
  dataRankingUserAll: [],
  dataKnowledgeList: [],
  listDepartment: [],
  data_user: [],
  total: 0,
};

export const knowledgeTopSlice = createSlice({
  name: 'knowledgeTop-page',
  initialState,
  reducers: {
    sortDataKnowledge: (state, action) => {
      state.dataKnowledgeList = orderBy(
        state.dataKnowledgeList,
        [action.payload.sort_field],
        [action.payload.sort_order]
      );
    },
    resetData(state) {
      state.dataKnowledgeRecommendDetailAdditional = [];
      state.dataKnowledgeRecommendDetailOriginal = [];
      state.dataKnowledgeQADetailAdditional = [];
      state.dataKnowledgeQADetailOriginal = [];
      state.dataKnowledgeCurriculum = [];
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataListKnowledge.fulfilled, (state, action) => {
      state.dataKnowledgeList = action.payload.items;
    });
    builder.addCase(getDataRankingLikeKnowledgeAll.fulfilled, (state, action) => {
      state.dataRankingKnowledgeAll = action.payload.items;
    });
    builder.addCase(getDataRankingLikeUserAll.fulfilled, (state, action) => {
      state.dataRankingUserAll = action.payload.items;
    });
    builder.addCase(getListDepartment.fulfilled, (state, action) => {
      state.listDepartment = action.payload.items;
    });

    builder.addCase(getKnowledgeLikeHistory.fulfilled, (state, action) => {
      state.dataKnowledgeLikeHistory = action.payload.report_results;
    });

    builder.addCase(getSelectDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });
    builder.addCase(getDepartmentCreate.fulfilled, (state, action) => {
      state.dataDepartmentCreate = action.payload.report_results;
    });
    builder.addCase(getKnowledgeFavoriteHistory.fulfilled, (state, action) => {
      state.dataKnowledgeFavoriteHistory = action.payload.report_results;
    });

    builder.addCase(getSelectUsers.fulfilled, (state, action) => {
      state.dataSelectUsers = action.payload.report_results;
    });
    builder.addCase(getSelectUser.fulfilled, (state, action) => {
      state.data_user = action.payload.report_results;
    });

    builder.addCase(getDataKnowledgeCurriculum.fulfilled, (state, action) => {
      const newKnowledgeCurriculum: Array<Types.KnowledgeCurriculumDetail.DataConverted> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newKnowledgeCurriculum.findIndex(
          (question) => question.i_id === item.i_id
        );
        if (itemIndex >= 0) {
          if (
            item.question_code &&
            !newKnowledgeCurriculum[itemIndex].question.some(
              ({ code }) => code === item.question_code
            )
          ) {
            newKnowledgeCurriculum[itemIndex].question.push({
              code: item.question_code,
              name: item.question_name,
              content: item.question_content,
            });
          }
        } else {
          newKnowledgeCurriculum.push({
            i_id: item.i_id,
            company_id: item.company_id,
            knowledge_id: item.knowledge_id,
            knowledge_title: item.knowledge_title,
            knowledge_text: item.knowledge_text,
            knowledge_status: item.knowledge_status,
            author_login_id: item.author_login_id,
            author_name: item.author_name,
            author_icon_fileID: item.author_icon_fileID,
            number_of_likes: item.number_of_likes,
            knowledge_updatedat: item.knowledge_updatedat,
            description: item.description,
            question: item.question_code
              ? [
                  {
                    code: item.question_code,
                    name: item.question_name,
                    content: item.question_content,
                  },
                ]
              : [],
          });
        }
      });
      state.dataKnowledgeCurriculum = newKnowledgeCurriculum;
    });
    builder.addCase(getSelectDepartmentUser.fulfilled, (state, action) => {
      state.dataSelectDepartmentUser = action.payload.report_results;
    });
    builder.addCase(getKnowledgeRecommendDetailOriginal.fulfilled, (state, action) => {
      state.dataKnowledgeRecommendDetailOriginal = action.payload.report_results;
    });

    builder.addCase(getKnowledgeRecommendDetailAdditional.fulfilled, (state, action) => {
      const dataAnswered: Array<Types.KnowledgeRecommendDetailAdditional.ResponseType> =
        action.payload.report_results.filter(
          (item) => item.question_respondent_affiliation_main_role === 'main'
        );
      const dataUnAnswered: Array<Types.KnowledgeRecommendDetailAdditional.ResponseType> =
        action.payload.report_results.filter((item) => !item.question_answer_id);
      state.dataKnowledgeRecommendDetailAdditional = [...dataAnswered, ...dataUnAnswered];
    });
    builder.addCase(getKnowledgeQADetailOriginal.fulfilled, (state, action) => {
      state.dataKnowledgeQADetailOriginal = action.payload.report_results;
    });
    builder.addCase(getKnowledgeQADetailAdditional.fulfilled, (state, action) => {
      const dataAnswered: Array<Types.KnowledgeQADetailAdditional.ResponseType> =
        action.payload.report_results.filter(
          (item) =>
            item.question_respondent_affiliation_main_role === 'main' &&
            item.questioner_affiliation_main_role === 'main'
        );
      const dataUnAnswered: Array<Types.KnowledgeQADetailAdditional.ResponseType> =
        action.payload.report_results.filter(
          (item) => !item.question_answer_id && item.questioner_affiliation_main_role === 'main'
        );
      state.dataKnowledgeQADetailAdditional = [...dataAnswered, ...dataUnAnswered];
    });
  },
});

export const { sortDataKnowledge, resetData, resetInitialState } = knowledgeTopSlice.actions;

export default knowledgeTopSlice.reducer;
