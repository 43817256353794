import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import Modal from 'components/Modal/index';
import { SectionStyled } from './styles';

interface Props {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => Promise<void> | void;
  subTitle?: string | React.ReactNode;
  visible: boolean;
  title: string;
}

const CompletedModalChangePayment: React.FC<Props> = ({
  title,
  subTitle,
  visible,
  setVisible,
  onSubmit,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    handleToggleModal();
    onSubmit && (await onSubmit());
  };

  return (
    <Modal
      visible={visible}
      width={450}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
    >
      <SectionStyled>
        <p className="title">
          <CheckCircleOutlined className="icon" />
          {title}
        </p>
        {subTitle && <span className="sub-title">{subTitle}</span>}
      </SectionStyled>
    </Modal>
  );
};

export default CompletedModalChangePayment;
