import React, { useState } from 'react';

import AffiliationDetail from '../AffiliationDetailPDF';
import { WrapperAffiliation } from './styles';
import * as Types from 'types';

interface Props {
  data: Types.TreeItem<Types.AffiliationItemType>[];
}

const FileExportPDF: React.FC<Props> = ({ data }) => {
  const [listItemIndexShrink, setListItemIndexShrink] = useState<number[]>([]);

  return (
    <div className="affiliation-list">
      {data?.map((item, index) => (
        <WrapperAffiliation
          key={index}
          item={item}
          index={index}
          listItemIndexShrink={listItemIndexShrink}
        >
          <div className="rst__nodeContent">
            <div
              className={
                'rst__lineBlock' +
                (item.columnIndex! > 0 ? ' rst__lineHalfHorizontalLeftVerticalTop' : '')
              }
            >
              <AffiliationDetail
                data={item.node!}
                activeCollapse
                handleShrink={() =>
                  setListItemIndexShrink((prevState) => {
                    const newState = [...prevState];
                    const itemIndex = newState.findIndex(
                      (itemSharinkIndex) => itemSharinkIndex === index
                    );
                    if (itemIndex >= 0) {
                      newState.splice(itemIndex, 1);
                    } else {
                      newState.push(index);
                    }
                    return newState;
                  })
                }
              />
            </div>
          </div>
        </WrapperAffiliation>
      ))}
    </div>
  );
};

export default FileExportPDF;
