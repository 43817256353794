import React, { useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { toggleHiddenSidebar } from 'containers/AppSettings/slice';
import SkillInstructionsStyled from './styles';
import { logout } from 'containers/Auth/slice';
import { useAppDispatch } from 'hooks';
import { Header } from 'components';

const SkillCheckInstructions: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SkillInstructionsStyled>
      <Header title="スキルチェック実施要項確認" />
      <div className="item">
        <div className="item-body">
          <div className="time-detail-child">
            <ExclamationCircleOutlined className="icon" />
            <p className="label">実施可能なスキルチェックがありません。</p>
          </div>
          <p className="text-label">ご担当者様にお問い合わせください。</p>
          <div className="btn">
            <button className="btn-button" onClick={() => dispatch(logout())}>
              ログアウトする
            </button>
          </div>
        </div>
      </div>
    </SkillInstructionsStyled>
  );
};

export default SkillCheckInstructions;
