import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  .image-successful {
    margin-top: 20px;
    width: 66px;
    height: 66px;
  }
  .check-circle-icon {
    font-size: 66px;
    color: rgba(8, 163, 165, 1);
    margin: 30px 0;
  }
  .title {
    font-size: 15px;
    text-align: center;
    color: #424242;
    margin: 0;
  }
  .text-content {
    margin: 30px 0;
    font-size: 13px;
    text-align: center;
    color: #424242;
  }
  .text-content-error {
    margin-top: 20px;
    margin-bottom: 12px;
    font-size: 12px;
    text-align: center;
    color: #f0330a;
  }
  .text-link {
    font-size: 12px;
    text-align: center;
    text-decoration-line: underline;
    color: #00a3a5;
  }
  .form {
    .input {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      margin: 10px 0;
      &.ant-input-affix-wrapper {
        margin-bottom: 0;
      }
      &.ant-input-affix-wrapper-focused {
        background: #fff;
        input {
          background: #fff;
          :focus {
            background-color: #ffffff;
          }
          :-webkit-autofill:focus,
          :-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px #fff inset !important;
          }
        }
      }
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
        :focus {
          background-color: #ffffff;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
        }
      }
    }
    .ant-input-affix-wrapper-status-error {
      input {
        :-webkit-autofill,
        :-webkit-autofill:hover {
          -webkit-box-shadow: 0 0 0 30px #fffaf9 inset !important;
        }
      }
    }
    .icon {
      color: #bbbbbb;
      font-size: 18px;
      margin-right: 8px;
    }
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      border: none;
      margin-top: 8px;
      width: 360px;
      height: 48px;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
`;
