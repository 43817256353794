import React from 'react';
import { CheckCircleFilled, LockFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import { SectionStyled } from './styles';
import { Modal } from 'components';
import { Arrow } from 'assets';

interface Props {
  visible: boolean;
  onSubmit: () => void;
  nodeLocked?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmPublish: React.FC<Props> = ({ onSubmit, visible, setVisible, nodeLocked }) => {
  const { loading } = useSelector(settingSelector);

  return (
    <Modal
      title="スキルチェック編集確認"
      width={720}
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: onSubmit,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      {nodeLocked ? (
        <SectionStyled>
          <div className="flex">
            <div className="open">
              <span>公開中</span>
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="lock scale">
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
              <span>編集中</span>
            </div>
          </div>
          <p className="sub-title">
            スキルチェックを編集します。
            <br />
            編集中はスキルチェック選択の画面で非表示となります。
          </p>
        </SectionStyled>
      ) : (
        <SectionStyled>
          <div className="flex">
            <div className="lock">
              <span>編集中</span>
              <div className="box-icon">
                <LockFilled className="icon" />
              </div>
            </div>
            <img className="arrow-icon" src={Arrow} alt="arrow" />
            <div className="open scale">
              <div className="box-icon">
                <CheckCircleFilled className="icon" />
              </div>
              <span>公開中</span>
            </div>
          </div>
          <p className="sub-title">スキルチェックを公開します。よろしいですか？</p>
        </SectionStyled>
      )}
    </Modal>
  );
};

export default ConfirmPublish;
