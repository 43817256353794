import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2px;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #eeeeee;
  .header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 2px solid #eeeeee;
    .icon {
      font-size: 18px;
      color: #c4c4c4;
      margin-right: 5px;
    }
    .title {
      font-size: 13px;
      color: #424242;
    }
  }
  .wrap-item {
    max-height: 20vh;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .items {
      background-color: rgba(255, 255, 255, 1);
      margin: 2px 0;
      .wrap-content {
        width: 100%;
        .wrap-date-and-status {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .content-date {
            display: flex;
            justify-content: center;
            font-size: 13px;
            line-height: 16px;
            color: #999999;
          }
          .content-status {
            border: 1px solid rgba(239, 122, 122, 0.6);
            border-radius: 10px;
            width: 68px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 18px;
            font-size: 11px;
            color: rgba(239, 62, 62, 1);
          }
          .content-status-date {
            border: 1px solid rgba(190, 115, 46, 0.4);
            border-radius: 10px;
            width: 68px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
            height: 18px;
            font-size: 10px;
            color: rgba(190, 115, 46, 1);
            .content-status-date-upcase {
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
        .content-text {
          font-size: 12px;
          line-height: 18px;
          color: #424242;
          margin-top: 4px;
        }
      }
    }
  }

  .pd-10 {
    padding: 13px 10px 10px 11px;
  }
  @media (max-width: 1440px) {
    width: 268px;
    .content-date {
      line-height: 10px !important;
    }
    .content-text {
      line-height: 11px !important;
    }
    .content-status {
      width: 66px !important;
    }
  }
`;
