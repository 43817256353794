import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { RingProgress, measureTextWidth } from '@ant-design/plots';
import { FormikProvider, useFormik } from 'formik';
import { Button, Select, Table, Tabs } from 'antd';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { find, union } from 'lodash';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import {
  CloudDownloadOutlined,
  DownOutlined,
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileWordFilled,
  PlayCircleFilled,
  SearchOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { HEADER_STORAGE_MANAGER } from 'constant/header.export.constant';
import ModalExportFileStorage from '../Modal/ModalExportFileStorage';
import { settingSelector } from 'containers/AppSettings/selectors';
import { extractFileName, formatNumber } from 'libs/utils/format';
import { getDataTableStorage, getSelectStorage } from '../thunk';
import { downloadFileFromMinio } from 'services/minioService';
import ActionErrorModal from 'components/Modal/ActionError';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { EXTENSIONS } from 'constant/select.constants';
import getWidthContainer from 'libs/utils/pieChart';
import { usageStatusSelector } from '../selectors';
import { Header, SelectField } from 'components';
import StorageManagementStyled from './styles';
import { useNavigate } from 'react-router-dom';
import { routes } from 'navigations/routes';
import { ConditionsType } from 'types';
import { IconIdCard } from 'assets';
import * as Types from 'types';
import { ColumnsType } from 'antd/es/table';

const { Option } = Select;
const { TabPane } = Tabs;

const StorageManagement: React.FC = () => {
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number>(100);
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [valueTab, setValueTab] = useState<string>('');
  const [page, setPage] = useState<number>(1);

  const { dataTableStorage, dataSelect } = useSelector(usageStatusSelector);
  const { headerTitle, collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      filename: '',
      file_extension: '',
      file_location: '',
    },
    onSubmit: (values) => {
      if (!userInfo) return;
      const conditions: Array<ConditionsType> = [];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          if (key === 'file_extension') {
            let valueSearch = find(EXTENSIONS, { value: values[key] })?.extension || [];
            if (formik.values.file_extension === '-1') {
              valueSearch = EXTENSIONS.flatMap((e) => e.extension);
            }
            conditions.push({
              id: key,
              search_value: [valueSearch.join('|')],
              ...(formik.values.file_extension === '-1'
                ? { not_match: true }
                : { exact_match: true }),
            });
          } else {
            conditions.push({ id: key, search_value: [values[key as keyof typeof values]] });
          }
        }
      });
      fetchDataStorage(conditions);
    },
    onReset: () => {
      fetchDataStorage([]);
    },
  });

  const storageData = useMemo(() => {
    if (valueTab && valueTab !== 'ALL') {
      return dataTableStorage.filter((e) => e.file_location === valueTab);
    }
    return dataTableStorage;
  }, [valueTab, dataTableStorage]);

  const downloadFile = async (file: string) => {
    const fileSave = await downloadFileFromMinio(file);

    if (fileSave) {
      saveAs(fileSave, extractFileName(file));
    }
  };

  const handleDownloadFiles = () => {
    selectedRow.map((item) => {
      downloadFile(item.fileID);
    });
  };

  const exportStorageData = (value: string) => {
    if (value === 'csv') {
      const csvString = [
        HEADER_STORAGE_MANAGER.map(({ label }) => label),
        ...selectedRow.map((e) => {
          const fileSize = Number(e.file_size) / 1000 || 0;
          return Object.values({
            filename: e.filename,
            createdAt: dayjs(e.createdAt).format('YYYY/MM/DD HH:mm'),
            file_extension: e.file_extension,
            file_size:
              fileSize < 1024
                ? `${fileSize.toFixed(2)} KB`
                : fileSize >= 1024 && fileSize < 1048576
                ? `${(fileSize / 1024).toFixed(2)} MB`
                : `${(fileSize / 1048576).toFixed(2)} GB`,
            file_location:
              e.file_location === '1'
                ? 'カリキュラムマスタ'
                : e.file_location === '2'
                ? '設問マスタ'
                : e.file_location === '3'
                ? 'スキルチェックマスタ'
                : e.file_location === '4'
                ? 'マニュアル'
                : e.file_location === '5'
                ? 'ナレッジ'
                : 'プロフィール',
          });
        }),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'ストレージ管理.csv');
    } else {
      handleDownloadFiles();
    }

    setVisibleModalEdit(false);
  };

  const handleSelectChange = (value: number) => {
    setSelectedValue(value);
  };

  const handleButtonFile = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisibleModalEdit(true);
    }
  };

  const onChange = (key: string) => {
    if (key === 'ALL') {
      setValueTab('');
    } else {
      setValueTab(key);
    }
  };

  const fetchDataStorage = (conditions: Array<ConditionsType>) => {
    if (!userInfo) return;

    dispatch(
      getDataTableStorage({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          ...conditions,
        ],
        page: 1,
        per_page: 0,
      })
    );
  };

  const fileIcon = (fileExtension: string) => {
    const fileType = find(EXTENSIONS, { extension: [fileExtension] })?.value || 'file';

    switch (fileType) {
      case 'excel':
        return <FileExcelFilled className="icon-excel" />;
      case 'word':
        return <FileWordFilled className="icon-word" />;
      case 'powerpoint':
        return <FilePptFilled className="icon-ppt" />;
      case 'pdf':
        return <FilePdfFilled className="icon-pdf" />;
      case 'image':
        return <FileImageFilled className="icon-image" />;
      case 'video':
        return <PlayCircleFilled className="icon-play" />;
      default:
        return <FileFilled className="icon-file" />;
    }
  };

  const columns = [
    {
      title: 'ストレージ内訳',
      dataIndex: 'location',
      key: 'location',
      className: 'storage-breakdown',
      width: '20%',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: '画像',
      dataIndex: 'image',
      key: 'image',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: '動画',
      dataIndex: 'video',
      key: 'video',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: 'Excel',
      dataIndex: 'excel',
      key: 'excel',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: 'Word',
      dataIndex: 'word',
      key: 'word',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: 'PDF',
      dataIndex: 'pdf',
      key: 'pdf',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: 'PowerPoint',
      dataIndex: 'power_point',
      key: 'power_point',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
    {
      title: 'その他',
      dataIndex: 'other',
      key: 'other',
      width: '10%',
      render: (text: number) => (
        <span>
          {text ? Number(text.toFixed(4)) : 0}
          <small className="small">GB</small>
        </span>
      ),
    },
  ];

  const columnsStorage: ColumnsType<Types.StorageManagement.ResponseType> = [
    {
      title: 'ファイル名',
      dataIndex: 'filename',
      key: 'filename',
      className: 'title',
      width: '30%',
      render: (text: string, item: Types.StorageManagement.ResponseType) => (
        <>
          <div className="item-row">
            {fileIcon(item.file_extension)}
            <div className="item-name">
              <span className="label-name">{text || 'Empty'}</span>
            </div>
          </div>
        </>
      ),
    },
    {
      title: (titleProps) => {
        const column1 = titleProps.sortColumns?.find(({ column }) => column.key === 'createdAt');
        return (
          <>
            <div className="item-sort">
              <span>更新日時</span>
              {column1?.order === 'ascend' ? (
                <DownOutlined className="icon" />
              ) : (
                <UpOutlined className="icon" />
              )}
            </div>
          </>
        );
      },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      render: (text: string) => dayjs(text).format('YYYY/MM/DD HH:mm'),
    },
    {
      title: '種類',
      dataIndex: 'file_extension',
      key: 'file_extension',
      width: '20%',
      render: (extension: string) =>
        find(EXTENSIONS, { extension: [extension] })?.label || 'その他',
    },
    {
      title: (titleProps) => {
        const column2 = titleProps.sortColumns?.find(({ column }) => column.key === 'file_size');
        return (
          <>
            <div className="item-sort">
              <span>サイズ</span>
              {column2?.order === 'ascend' ? (
                <DownOutlined className="icon" />
              ) : (
                <UpOutlined className="icon" />
              )}
            </div>
          </>
        );
      },
      dataIndex: 'file_size',
      key: 'file_size',
      width: '10%',
      sortDirections: ['ascend', 'descend', 'ascend'],
      sorter: (a, b) => Number(a.file_size) - Number(b.file_size),

      render: (size: string) => {
        const fileSize = Number(size) / 1000 || 0;
        if (fileSize < 1024) {
          return `${fileSize.toFixed(2)} KB`;
        }
        if (fileSize >= 1024 && fileSize < 1048576) {
          return `${(fileSize / 1024).toFixed(2)} MB`;
        }
        if (fileSize >= 1048576) {
          return `${(fileSize / 1048576).toFixed(2)} GB`;
        }
      },
    },
    {
      title: '利用場所',
      dataIndex: 'file_location',
      key: 'file_location',
      width: '20%',
      render: (location: string) =>
        location === '1'
          ? 'カリキュラムマスタ'
          : location === '2'
          ? '設問マスタ'
          : location === '3'
          ? 'スキルチェックマスタ'
          : location === '4'
          ? 'マニュアル'
          : location === '5'
          ? 'ナレッジ'
          : 'プロフィール',
    },
  ];

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    dispatch(
      getDataTableStorage({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;

    dispatch(
      getSelectStorage({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <StorageManagementStyled collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} className="header">
        <div className="wrap-header">
          <div className="item-storage">
            <Button className="btn-storage" onClick={() => navigate(routes.UsageStatus.path)}>
              <img src={IconIdCard} className="icon-storage" alt="icon" />
              ご利用状況一覧
            </Button>
            <div className="select-time-limit">
              <div className="label">
                <span className="label-select">表示件数：</span>
              </div>
              <Select defaultValue={selectedValue} onChange={handleSelectChange}>
                <Option key={1} value={10}>
                  10
                </Option>
                <Option key={2} value={20}>
                  20
                </Option>
                <Option key={3} value={50}>
                  50
                </Option>
                <Option key={4} value={100}>
                  100
                </Option>
              </Select>
            </div>
          </div>
        </div>
      </Header>
      <div className="container">
        <div className="table-chart">
          <div className="item-left">
            <RingProgress
              {...config}
              pixelRatio={10}
              animation={false}
              statistic={{
                title: {
                  offsetY: 10,
                  customHtml: (container) => {
                    return renderStatistic(getWidthContainer(container, true), 1, 'title', {
                      display: 'flex',
                      fontSize: 30,
                      textShadow: 'none',
                      fontWeight: 400,
                      color: '#777777',
                    });
                  },
                },
                content: {
                  customHtml: (container, _view) => {
                    return renderStatistic(
                      getWidthContainer(container, false),
                      formatNumber(
                        dataSelect.dataTableFile[dataSelect.dataTableFile.length - 1]?.total
                      ),
                      'content',
                      {
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#777777',
                      }
                    );
                  },
                },
              }}
            />
            <RingProgress
              {...configBorder}
              pixelRatio={10}
              animation={false}
              style={{ marginLeft: '-215px' }}
              statistic={{
                title: {
                  offsetY: 10,
                  customHtml: (container) => {
                    return renderStatisticBorder(getWidthContainer(container, true), 1, 'title', {
                      display: 'flex',
                      fontSize: 30,
                      textShadow: 'none',
                      fontWeight: 400,
                      color: '#777777',
                    });
                  },
                },
                content: {
                  customHtml: (container, _view) => {
                    return renderStatisticBorder(
                      getWidthContainer(container, false),
                      formatNumber(
                        dataSelect.dataTableFile[dataSelect.dataTableFile.length - 1]?.total
                      ),
                      'content',
                      {
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#777777',
                      }
                    );
                  },
                },
              }}
            />
          </div>
          <div className="item-right">
            <Table
              className="table"
              dataSource={dataSelect.dataTableFile.map((item, index) => ({ ...item, index }))}
              columns={columns}
              pagination={false}
              rowKey="index"
            />
          </div>
        </div>
        <div className="wrap-select">
          <div className="item-select">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="filename"
                    className="item-file-name"
                    label={<span className="text-label">ファイル名</span>}
                  >
                    <SelectField
                      name="filename"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {union(dataSelect.filename).map(
                        (e, index) =>
                          e && (
                            <Option key={index} value={e}>
                              {e}
                            </Option>
                          )
                      )}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="file_extension"
                    className="item"
                    label={<span className="text-label">種類</span>}
                  >
                    <SelectField
                      name="file_extension"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {EXTENSIONS.map((e, index) => (
                        <Option key={index} value={e.value}>
                          {e.label}
                        </Option>
                      ))}
                      <Option key={'-1'} value="-1">
                        その他
                      </Option>
                    </SelectField>
                  </Form.Item>
                  {/* <Form.Item
                    name="file_location"
                    className="item"
                    label={<span className="text-label">利用場所</span>}
                  >
                    <SelectField
                      name="file_location"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {union(dataSelect.file_location).map(
                        (e, index) =>
                          e && (
                            <Option key={index} value={e}>
                              {e === '1'
                                ? 'カリキュラムマスタ'
                                : e === '2'
                                ? '設問マスタ'
                                : e === '3'
                                ? 'スキルチェックマスタ'
                                : e === '4'
                                ? 'マニュアル'
                                : e === '5'
                                ? 'ナレッジ'
                                : 'プロフィール'}
                            </Option>
                          )
                      )}
                    </SelectField>
                  </Form.Item> */}

                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </Form>
            </FormikProvider>
          </div>
        </div>

        <div className="item-tab">
          <Tabs className="tab-container" defaultActiveKey="ALL" onChange={onChange}>
            <TabPane tab="ALL" key="ALL" />
            <TabPane tab="カリキュラムマスタ" key="1" />
            <TabPane tab="設問マスタ" key="2" />
            <TabPane tab="スキルチェックマスタ" key="3" />
            <TabPane tab="マニュアル" key="4" />
            <TabPane tab="ナレッジ" key="5" />
            <TabPane tab="プロフィール" key="6" />
          </Tabs>
        </div>
        <div className="wrap-table">
          <Table
            rowKey="index"
            className="table-file"
            dataSource={storageData.map((item, index) => ({
              ...item,
              index,
            }))}
            columns={columnsStorage}
            rowSelection={{
              onChange: (_, selectedRows) => setSelectedRow(selectedRows),
            }}
            pagination={{
              pageSize: selectedValue,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topRight'],
              style: { display: 'flex' },
              showTotal: () => (
                <>
                  <div
                    className="text-total-record-table"
                    style={{
                      zIndex: 9999,
                    }}
                  >
                    {page * selectedValue > storageData.length
                      ? storageData.length
                      : page * selectedValue}
                    <span className="text-static"> 件表示</span> /{storageData.length}
                    <span className="text-static"> 件中</span>
                  </div>
                </>
              ),
            }}
          />
        </div>
      </div>
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択したファイルを処理：
            <Button className="btn btn-active" onClick={handleButtonFile}>
              <CloudDownloadOutlined className="icon" />
              <span>エクスポート</span>
            </Button>
          </div>
        </div>
      </div>
      <ActionErrorModal
        visible={visiblePopupError}
        setVisible={setVisiblePopupError}
        subTitle="ファイルが選択されていません"
        description={
          <>
            エクスポートを実行する
            <br />
            対象のファイルを選択し再度実行してください。
          </>
        }
      />
      <ModalExportFileStorage
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        title="エクスポート"
        subTitle="選択したファイルをエクスポートします。"
        onSubmit={exportStorageData}
      />
    </StorageManagementStyled>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex; flex-direction: column;align-items: center;justify-content: center;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="font-size: 13px; color: #424242;">ストレージ総使用量</div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    }; padding-top: 15px; display: flex;flex-direction: column; "><span style="font-weight: 400;font-size: 50px;color: rgba(8, 163, 165, 1);">${text}<small style='font-size: 20px'>GB</small></span></div>`;
  }
};

const renderStatisticBorder = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex; flex-direction: column;align-items: center;justify-content: center;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="font-size: 13px; color: #424242;"></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    }</div>`;
  }
};

const config = {
  height: 230,
  width: 230,
  autoFit: false,
  percent: 0.6,
  color: ['#EBEBEB', '#08A3A5'],
  innerRadius: 0.92,
  radius: 1,
  cornerRadius: 0.1,
};

const configBorder = {
  height: 200,
  width: 200,
  autoFit: false,
  percent: 0,
  color: ['#08A3A5'],
  innerRadius: 0.98,
  radius: 1,
  cornerRadius: 0.1,
};

export default StorageManagement;
