import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { Checkbox } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { createAffiliationLevel, getAffiliations } from '../../thunk';
import { createEditAffiliationSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { CreateEditAffiliationFormik } from 'types';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';

interface Props {
  visible: boolean;
  onSubmit: (name: string) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateAffiliation: React.FC<Props> = ({ visible, onSubmit, setVisible }) => {
  const [checked, setChecked] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const formik = useFormik<CreateEditAffiliationFormik>({
    initialValues: {
      name: '',
    },
    validationSchema: createEditAffiliationSchema,
    validateOnBlur: false,
    onSubmit: async (values, { resetForm }) => {
      checked ? handleCreateRootAffiliation(values.name) : await onSubmit(values.name);
      resetForm();
      onClose();
    },
  });

  const handleCreateRootAffiliation = async (name: string) => {
    if (!userInfo) return;

    dispatch(startLoading());
    const actionResult = await dispatch(
      createAffiliationLevel({
        item: {
          name,
          sort_order: 1,
          level: 1,
          createdby: userInfo.login_id,
          company_id: userInfo.company_id,
          createdat: new Date(),
        },
        return_display_id: true,
        return_item_result: true,
      })
    );
    if (createAffiliationLevel.fulfilled.match(actionResult)) {
      await dispatch(
        getAffiliations({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
    }
    dispatch(stopLoading());
  };

  const onClose = () => {
    setVisible(false);
    formik.resetForm();
    setChecked(false);
  };

  return (
    <Modal
      title="所属 新規作成"
      open={visible}
      width={720}
      okButton={{
        text: '新規作成',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: onClose,
      }}
      onCancel={onClose}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          所属の新規作成が可能です。
          <br />
          所属名称を入力して、新規作成ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            className="form"
          >
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  所属名称
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name"
                className="input"
                type="text"
                placeholder="全角：最大30文字"
                showCount
                maxLength={30}
              />
            </Form.Item>
            <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
              第1階層作成
            </Checkbox>
          </Form>
        </FormikProvider>
      </SectionStyled>
    </Modal>
  );
};

export default CreateAffiliation;
