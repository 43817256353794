import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import UnrelatedQuestionSearch from './UnrelatedQuestionSearch';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import QuestionSearch from './QuestionSearch';
import { resetInitialState } from './slice';
import StyledSearch from './styles';
import {
  getCurriculumLevelOption,
  getCreatorQuestionOption,
  getCurriculumQuestionOption,
  getUnrelatedQuestions,
  getListQuestion,
} from './thunk';

const { TabPane } = Tabs;

interface Props {
  pageYOffset: number;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
  selectedQuestion: {
    i_id: string;
    name: string;
    code: string;
  }[];
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<
      {
        i_id: string;
        name: string;
        code: string;
      }[]
    >
  >;
  openModalCreateQuestion?: {
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: (() => void) | undefined;
  };
}

const SearchCurriculum: React.FC<Props> = ({
  pageYOffset,
  selectedQuestion,
  setSelectedQuestion,
  openModalCreateQuestion,
  setOpenModalCreateQuestion,
}) => {
  const [tabKey, setTabKey] = useState<string>('1');

  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { messages } = useIntl();

  const handleSelectTabKey = (activeKey: string) => {
    if (selectedQuestion.length) {
      setSelectedQuestion([]);
    }
    setTabKey(activeKey);
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getCreatorQuestionOption({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCurriculumQuestionOption({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 1,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 2,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 3,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getCurriculumLevelOption({
            level: 4,
            company_id: userInfo.company_id,
          })
        ),
        dispatch(
          getListQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'code', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getUnrelatedQuestions({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
    return () => {
      setTabKey('1');
      setSelectedQuestion([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUserInfoChanged, userInfo]);

  useEffect(() => {
    return () => {
      dispatch(resetInitialState());
    };
  }, [dispatch]);

  return (
    <StyledSearch tabKey={tabKey} pageYOffset={pageYOffset} screenHeight={window.innerHeight}>
      <Tabs type="card" onChange={handleSelectTabKey}>
        <TabPane tab={messages['M-21-23']} key="1" className="card-tab-pane">
          <QuestionSearch
            setOpenModalCreateQuestion={setOpenModalCreateQuestion}
            openModalCreateQuestion={openModalCreateQuestion}
            setSelectedQuestion={setSelectedQuestion}
            selectedQuestion={selectedQuestion}
          />
        </TabPane>
        <TabPane tab={messages['M-21-24']} key="2" className="card-tab-pane">
          <UnrelatedQuestionSearch
            setOpenModalCreateQuestion={setOpenModalCreateQuestion}
            openModalCreateQuestion={openModalCreateQuestion}
            setSelectedQuestion={setSelectedQuestion}
            selectedQuestion={selectedQuestion}
          />
        </TabPane>
      </Tabs>
    </StyledSearch>
  );
};

export default SearchCurriculum;
