import React, { useEffect, useMemo, useRef, useState } from 'react';
import { differenceWith, findIndex, intersectionWith } from 'lodash';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Select, Table } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';

import { HEADER_EDUCATION_PROGRAM_CSV } from 'constant/header.export.constant';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { SkillCheckModal, CurriculumBatchModal } from './Modal';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { educationProgramSelector } from './selectors';
import { Header, SelectField } from 'components';
import EducationProgramStyled from './styles';
import exportPDF from 'libs/utils/exportPdf';
import { routes } from 'navigations/routes';
import EducationProgramPDF from './PDF';
import * as Types from 'types';
import {
  createUserAssignCurriculum,
  createUserAssignSkillCheck,
  deleteLinkUserAssignCurriculum,
  deleteLinkUserAssignSkillCheck,
} from '../Employee/thunk';
import {
  getDataSelectName,
  getDataSelectPosition,
  getDataEducationProgram,
  getDataSelectDepartment,
  getUserSearch,
  getUserAssignCurriculum,
  getUserAssignSkillCheck,
  getSkillCheckTrans2,
} from './thunk';

const { Option } = Select;

const EducationProgram: React.FC = () => {
  const [visiblePopupCurriculumBatch, setVisiblePopupCurriculumBatch] = useState<boolean>(false);
  const [visiblePopupSkillCheck, setVisiblePopupSkillCheck] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<
    Types.RequiredCurriculumSkillCheck.DataConverted[]
  >([]);

  const ref = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    dataEducationProgram,
    dataSelectName,
    dataSelectDepartment,
    dataSelectPosition,
    skillCheckTrans2,
  } = useSelector(educationProgramSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const dataTable = useMemo(() => {
    if (!checked) {
      const data = dataEducationProgram.map((e) => {
        const skillChecks: Array<{ code: string; name: string }> = [];
        e.skill_check_code.forEach((val) => {
          const index = findIndex(skillCheckTrans2, (o) => o.skill_check_code === val.code);
          if (index > -1) {
            skillChecks.push(val);
          }
        });
        return { ...e, skill_check_code: skillChecks };
      });
      return data;
    }
    return dataEducationProgram;
  }, [checked, skillCheckTrans2, dataEducationProgram]);

  const formik = useFormik<Types.EducationProgramFormSearchFormik>({
    initialValues: {
      name: '',
      employee_code: '',
      position_code: '',
      department_name: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getDataEducationProgram({
          conditions,
          page: 1,
          per_page: 0,
        })
      );
      setPage(1);
      dispatch(stopLoading());
    },
  });

  const columns = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '11%',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '11%',
      ellipsis: true,
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '12%',
      ellipsis: true,
      render: (text: string) => text?.replace(/^\d+ /, ''),
    },
    {
      title: '役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '11%',
      ellipsis: true,
      render: (text: string) => text?.replace(/^\d+ /, ''),
    },
    {
      title: '必修カリキュラム',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      width: '27.5%',
      render: (curriculum_code: [{ i_id: string; name: string }]) =>
        curriculum_code && (
          <ul className="list-item">
            {curriculum_code.map((item, i) => (
              <li key={i}>{item.name}</li>
            ))}
          </ul>
        ),
    },
    {
      title: 'スキルチェック',
      dataIndex: 'skill_check_code',
      key: 'skill_check_code',
      width: '27.5%',
      render: (skill_check_code: [{ i_id: string; name: string }]) =>
        skill_check_code && (
          <ul className="list-item">
            {skill_check_code.map((item, i) => (
              <li key={i}>{item.name}</li>
            ))}
          </ul>
        ),
    },
  ];

  const handleButtonExport = () => {
    if (selectedRow.length > 0) {
      setVisiblePopupConfirmExportFile(true);
    } else {
      setVisiblePopupError(true);
    }
  };

  const handleShowPopupSkillCheck = () => {
    if (selectedRow.length > 0) {
      setVisiblePopupSkillCheck(true);
    } else {
      setVisiblePopupError(true);
    }
  };

  const handleShowPopupCurriculumBatch = () => {
    if (selectedRow.length > 0) {
      setVisiblePopupCurriculumBatch(true);
    } else {
      setVisiblePopupError(true);
    }
  };

  const handleCurriculumBatchModal = async (
    listItem: Array<Types.Curriculums.ResponseType>,
    type: 'create' | 'delete'
  ) => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      getUserSearch({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'i_id',
            search_value: selectedRow.map(({ i_id }) => i_id),
          },
        ],
      })
    );
    if (getUserSearch.fulfilled.match(resultAction)) {
      await Promise.all(
        resultAction.payload.items.map(async (user) => {
          const resultActionGetUserAssignCurriculum = await dispatch(
            getUserAssignCurriculum({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [user.login_id],
                  exact_match: true,
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          );
          if (getUserAssignCurriculum.fulfilled.match(resultActionGetUserAssignCurriculum)) {
            if (type === 'create') {
              return Promise.all(
                differenceWith(
                  listItem,
                  resultActionGetUserAssignCurriculum.payload.items,
                  (curriculum, userAssignCurriculum) =>
                    curriculum.code === userAssignCurriculum.curricullum_code
                ).map(({ code }) =>
                  dispatch(
                    createUserAssignCurriculum({
                      item: {
                        curricullum_code: code,
                        login_id: user.email,
                        company_id: user.company_id,
                        hidden: 'off',
                        createdby: user.login_id,
                        createdat: new Date(),
                      },
                    })
                  )
                )
              );
            } else {
              return Promise.all(
                intersectionWith(
                  resultActionGetUserAssignCurriculum.payload.items,
                  listItem,
                  (userAssignCurriculum, curriculum) =>
                    userAssignCurriculum.curricullum_code === curriculum.code
                ).map(({ i_id }) =>
                  dispatch(
                    deleteLinkUserAssignCurriculum({
                      id: i_id,
                    })
                  )
                )
              );
            }
          }
          return;
        })
      );
      dispatch(stopLoading());
      setVisibleSuccess(true);
    }
  };

  const handleSubmitSkillCheckModal = async (
    listItem: Array<Types.SkillCheck.ResponseType>,
    type: 'create' | 'delete'
  ) => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      getUserSearch({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'i_id',
            search_value: selectedRow.map(({ i_id }) => i_id),
          },
        ],
      })
    );
    if (getUserSearch.fulfilled.match(resultAction)) {
      await Promise.all(
        resultAction.payload.items.map(async (user) => {
          const resultActionGetUserAssignSkillCheck = await dispatch(
            getUserAssignSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [user.login_id],
                  exact_match: true,
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          );
          if (getUserAssignSkillCheck.fulfilled.match(resultActionGetUserAssignSkillCheck)) {
            if (type === 'create') {
              return Promise.all(
                differenceWith(
                  listItem,
                  resultActionGetUserAssignSkillCheck.payload.items,
                  (skillCheck, userAssignSkillCheck) =>
                    skillCheck.code === userAssignSkillCheck.skill_check_code
                ).map(({ code }) =>
                  dispatch(
                    createUserAssignSkillCheck({
                      item: {
                        skill_check_code: code,
                        login_id: user.email,
                        company_id: user.company_id,
                        hidden: 'off',
                        createdby: userInfo?.login_id,
                        createdat: new Date(),
                      },
                    })
                  )
                )
              );
            } else {
              return Promise.all(
                intersectionWith(
                  resultActionGetUserAssignSkillCheck.payload.items,
                  listItem,
                  (userAssignSkillCheck, skillCheck) =>
                    userAssignSkillCheck.skill_check_code === skillCheck.code
                ).map(({ i_id }) =>
                  dispatch(
                    deleteLinkUserAssignSkillCheck({
                      id: i_id,
                    })
                  )
                )
              );
            }
          }
          return;
        })
      );
      dispatch(stopLoading());
      setVisibleSuccess(true);
    }
  };

  const handleSubmiCompletedModal = async () => {
    dispatch(startLoading());
    await dispatch(
      getDataEducationProgram({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        include_lookups: true,
        omit_total_items: false,
      })
    );
    setSelectedRow([]);
    dispatch(stopLoading());
  };

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = dataEducationProgram?.map((item) => ({
        employee_code: item.employee_code,
        name: item.name,
        kana: item.kana,
        department_name: item.department_name,
        position_code: item.position_code,
      }));

      const csvString = [
        HEADER_EDUCATION_PROGRAM_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'education_program_export.csv');
    }

    if (value === 'pdf') {
      exportPDF(ref, `${headerTitle}.pdf`);
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const component = useMemo(() => {
    return (
      <div
        style={{
          width: '100%',
          position: 'absolute',
          left: '-9999px',
        }}
        ref={ref}
      >
        <EducationProgramPDF
          dataEducationProgram={selectedRow}
          dataSelectDepartment={dataSelectDepartment}
          dataSelectName={dataSelectName}
          dataSelectPosition={dataSelectPosition}
          page={page}
          perPage={perPage}
          formik={formik.values}
          checked={checked}
        />
      </div>
    );
  }, [
    selectedRow,
    dataSelectDepartment,
    dataSelectName,
    dataSelectPosition,
    page,
    perPage,
    formik.values,
    checked,
  ]);

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  useEffect(() => {
    (async () => {
      if (userInfo && isUserInfoChanged) {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataEducationProgram({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_item_ref: true,
              include_lookups: true,
              omit_total_items: false,
            })
          ),
          dispatch(
            getDataSelectName({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSkillCheckTrans2({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      }
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <>
      <Header title={headerTitle} />
      {component}
      <EducationProgramStyled
        collapsedMenu={collapsedMenu}
        dataEducationProgram={dataEducationProgram?.length > 0}
      >
        <p className="text-note">
          社内ユーザーに必修カリキュラム・スキルチェックを一括設定する画面です。
          <br />
          社内ユーザーに設定された必修カリキュラム・スキルチェックを確認する一覧画面としても活用できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="employee_code"
                className="item"
                label={<span className="text-label">社員番号</span>}
              >
                <SelectField name="employee_code" showSearch allowClear>
                  {dataSelectName &&
                    dataSelectName.map((item, index) => (
                      <Option key={index} value={item.code}>
                        {item.code}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField name="name" showSearch allowClear>
                  {dataSelectName &&
                    dataSelectName.map((item, index) => (
                      <Option key={index} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectDepartment &&
                    dataSelectDepartment.map((item, index) => (
                      <Option key={index} value={item.i_id}>
                        {item.name}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition &&
                    dataSelectPosition.map((item, index) => (
                      <Option key={index} value={item.i_id}>
                        {item.name}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="table-container">
          <div className="wrap-checkbox">
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />
            <span className="text-checkbox">実施済のスキルチェックを表示する</span>
          </div>

          <Table
            rowKey="i_id"
            className="table"
            rowSelection={{
              selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
              onChange: (_, selectedRows) => setSelectedRow(selectedRows),
              getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
            }}
            dataSource={dataTable.map((item, index) => ({ ...item, index }))}
            columns={columns}
            pagination={{
              pageSize: perPage,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topCenter'],
              showTotal: () => (
                <>
                  <div className="text-count">
                    {(page - 1) * perPage + 1} - {''}
                    {page * perPage > dataEducationProgram.length
                      ? dataEducationProgram.length
                      : page * perPage}
                    <span className="text-static"></span> / {dataEducationProgram.length}
                    <span className="text-static">件</span>
                    <div className="page-select">
                      <div className="label">
                        <span>表示件数</span>：
                      </div>
                      <Select value={perPage} onSelect={handleSelectChange}>
                        {[10, 20, 50, 100].map((value, index) => (
                          <Option key={index} value={value} label={value}>
                            {value}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </>
              ),
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザーを処理：
              <Button className="btn btn-active" onClick={handleShowPopupCurriculumBatch}>
                必修カリキュラム一括設定
              </Button>
              <Button className="btn btn-active" onClick={handleShowPopupSkillCheck}>
                スキルチェック一括設定
              </Button>
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button
              className="btn btn-outline"
              onClick={() => navigate(generatePath(routes.Employee.path, { entity: 'receiving' }))}
            >
              <p>戻る</p>
              <RightOutlined className="icon" />
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="必修カリキュラムが選択されていません"
          description={
            <>
              一括設定行う必修カリキュラムを選択し、
              <br />
              再度実行してください。
            </>
          }
        />
        <SkillCheckModal
          visible={visiblePopupSkillCheck}
          setVisible={setVisiblePopupSkillCheck}
          onSubmit={handleSubmitSkillCheckModal}
        />
        <CurriculumBatchModal
          visible={visiblePopupCurriculumBatch}
          setVisible={setVisiblePopupCurriculumBatch}
          onSubmit={handleCurriculumBatchModal}
        />
        <CompletedModal
          visible={visibleSuccess}
          setVisible={setVisibleSuccess}
          title="登録が完了しました"
          onSubmit={handleSubmiCompletedModal}
        />
      </EducationProgramStyled>
    </>
  );
};

export default EducationProgram;
