import { createAsyncThunk } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';

import { startLoading, stopLoading } from '../../containers/AppSettings/slice';
import { EXTENSIONS } from 'constant/select.constants';
import { services } from 'services';
import * as Types from 'types';
import {
  SKILL_CHECK_FEE_DETAIL,
  STORAGE_MANAGEMENT,
  SKILL_CHECK_IMPLE,
  USER_FEE_DETAIL,
  PAYMENT_STATUS,
  USAGE_LIST,
  COMPANIES,
} from 'configs';

export const getDataUsageList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UsageList.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataUsageList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.UsageList.ResponseType>(USAGE_LIST.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserFeeDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UserFeeDetail.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataUserFeeDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.UserFeeDetail.ResponseType>(
      USER_FEE_DETAIL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckFeeDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckFeeDetails.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataSkillCheckFeeDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckFeeDetails.ResponseType>(
      SKILL_CHECK_FEE_DETAIL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataPaymentStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.PaymentStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataPaymentStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.PaymentStatus.ResponseType>(
      PAYMENT_STATUS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUsageStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataUsageStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckUserFee = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataSkillCheckUserFee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataBillingTotal = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataBillingTotal', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTableStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataTableStorage', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.filter<Types.StorageManagement.ResponseType>(
      STORAGE_MANAGEMENT.name,
      req
    );
    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataTableFile = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getDataTableFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectStorage = createAsyncThunk<
  Types.ReportsItemResponseType<Types.StorageManagement.SelectStorage>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('UsageStatus/thunk/getSelectStorage', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.filter<Types.StorageManagement.ResponseType>(
      STORAGE_MANAGEMENT.name,
      req
    );
    const groupByLocation = groupBy(data.report_results, 'file_location');
    const dataByLocation = Object.keys(groupByLocation).map((key) => {
      return groupByLocation[key].reduce(
        (result, item) => {
          const extensionInfo =
            EXTENSIONS.find((ext) => ext.extension.includes(item.file_extension)) || null;

          return {
            location:
              key === '1'
                ? 'カリキュラムマスタ'
                : key === '2'
                ? '設問マスタ'
                : key === '3'
                ? 'スキルチェックマスタ'
                : key === '4'
                ? 'マニュアル'
                : key === '5'
                ? 'ナレッジ'
                : 'プロフィール',
            image:
              extensionInfo?.value === 'image'
                ? Number(result.image + Number(item.file_size) / 1073741824)
                : Number(result.image / 1073741824),
            video:
              extensionInfo?.value === 'video'
                ? Number(result.video + Number(item.file_size) / 1073741824)
                : Number(result.video / 1073741824),
            excel:
              extensionInfo?.value === 'excel'
                ? Number(result.excel + Number(item.file_size) / 1073741824)
                : Number(result.excel / 1073741824),
            word:
              extensionInfo?.value === 'word'
                ? Number(result.word + Number(item.file_size) / 1073741824)
                : Number(result.word / 1073741824),
            pdf:
              extensionInfo?.value === 'pdf'
                ? Number(result.pdf + Number(item.file_size) / 1073741824)
                : Number(result.pdf / 1073741824),
            power_point:
              extensionInfo?.value === 'power'
                ? Number(result.power_point + Number(item.file_size) / 1073741824)
                : Number(result.power_point / 1073741824),
            other: !extensionInfo
              ? Number(result.other + Number(item.file_size) / 1073741824)
              : Number(result.other / 1073741824),
          };
        },
        {
          location: '',
          image: 0,
          video: 0,
          excel: 0,
          word: 0,
          pdf: 0,
          power_point: 0,
          other: 0,
        }
      );
    });
    const totalByLocation = dataByLocation.map((e) => ({
      ...e,
      total: e.image + e.video + e.excel + e.word + e.pdf + e.power_point + e.other,
    }));
    const total = totalByLocation.reduce(
      (result, item) => {
        return {
          location: result.location,
          image: result.image + item.image,
          video: result.video + item.video,
          excel: result.excel + item.excel,
          word: result.word + item.word,
          pdf: result.pdf + item.pdf,
          power_point: result.power_point + item.power_point,
          other: result.other + item.other,
        };
      },
      {
        location: 'Total',
        image: 0,
        video: 0,
        excel: 0,
        word: 0,
        pdf: 0,
        power_point: 0,
        other: 0,
      }
    );

    const dataSelect = {
      filename: data.report_results.map((e) => e.filename),
      file_extension: data.report_results.map((e) => e.file_extension),
      file_location: data.report_results.map((e) => e.file_location),
      dataTableFile: [
        ...totalByLocation,
        {
          ...total,
          total:
            total.image +
            total.video +
            total.excel +
            total.word +
            total.pdf +
            total.power_point +
            total.other,
        },
      ],
    };
    dispatch(stopLoading());
    return { ...data, report_results: [dataSelect] };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompanies = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('AdministratorMaster/thunk/getCompanies', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
