import styled from 'styled-components';

export default styled.section`
  .item-image {
    display: flex;
    justify-content: center;
    height: 360px;
    .design-course {
      max-width: 100%;
      height: auto;
      max-height: 360px;
      object-fit: cover;
    }
  }
  .container {
    padding: 20px 40px;
    .item-title {
      padding-bottom: 10px;
      border-bottom: 1px solid #cccccc;
      .btn-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 15px;
        .btn {
          font-size: 11px;
          border: none;
          border-radius: 10px;
        }
        .btn-default {
          color: #08a3a5;
          background: #ffffff;
          border: 1px solid #cccccc;
        }
        .btn-active {
          color: #ffffff;
          background: #08a3a5;
          cursor: pointer;
        }
        .text-btn {
          font-size: 12px;
          color: #777777;
          margin: 0;
          cursor: pointer;
          .label {
            color: #424242;
            text-decoration-line: underline;
          }
        }
      }
      .content {
        margin: 0;
        font-size: 15px;
        color: #222222;
      }
    }
    .item-content {
      position: relative;
      padding: 15px 0;
      .btn-information {
        position: absolute;
        top: 15px;
        right: 10px;
        padding: 5px 20px;
        background: #ffffff;
        border: 1px solid #08a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #08a3a5;
        cursor: pointer;
      }
      .content {
        padding-top: 25px;
        .label {
          margin: 0;
          font-size: 12px;
          color: #777777;
        }
        .text-content {
          padding: 10px;
          background: #ffffff;
          border: 1px solid #ebebeb;
          box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
          border-radius: 3px;
          font-size: 14px;
          color: #424242;
        }
      }
    }
    .group-btn {
      display: flex;
      justify-content: center;
      .btn {
        width: 140px;
        height: 40px;
        border-radius: 5px;
        font-size: 13px;
        text-align: center;
        border: none;
        cursor: pointer;
      }
      .btn-close {
        color: #ffffff;
        background: #08a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        margin-right: 10px;
      }
      .btn-default {
        color: #777777;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      }
    }
  }
`;
