import React, { useCallback } from 'react';

export interface ReceivedProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useModal = (props: ReceivedProps) => {
  const { setIsOpen } = props;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    ...props,
    handleClose,
  };
};

export type Props = ReturnType<typeof useModal>;

export default useModal;
