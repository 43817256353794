import { createAsyncThunk } from '@reduxjs/toolkit';

import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { convertFileResponse } from 'libs/utils/question';
import { sharedFileInMinIO } from 'services/minioService';
import { curriculumService, services } from 'services';
import * as Types from 'types';
import {
  SELECT_OFFICIAL_CURRICULUM_PROVIDER,
  OFFICIAL_CURRICULUM_HIERARCHY_LIST,
  SELECT_OFFICIAL_CURRICULUM_NAME,
  OFFICIAL_CURRICULUM_LIST2,
  QUESTION_ASSIGN_LEVEL,
  SKILL_CHECK_IMPLE,
  CURRICULUMS,
  CURRICULUM_HIERARCHY,
  RELEASE_NOTE_MANAGEMENT,
  RELEASE_NOTE_DETAIL,
  ATTACH,
  CURRICULUM_PROVIDER_INFOMATION,
  RELEASE_NOTE,
  CURRICULUM_PUBLISHED_HISTORY,
} from 'configs';

export const getSelectOfficialCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectOfficialCurriculumName.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('OfficialCurriculum/thunk/getSelectOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectOfficialCurriculumName.ResponseType>(
      SELECT_OFFICIAL_CURRICULUM_NAME.name,
      {
        ...req,
        include_item_ref: true,
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectOfficialCurriculumProvider = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectOfficialCurriculumProvider.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'OfficialCurriculum/thunk/getSelectOfficialCurriculumProvider',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.SelectOfficialCurriculumProvider.ResponseType>(
        SELECT_OFFICIAL_CURRICULUM_PROVIDER.name,
        req
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataOfficialCurriculumList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumList2.ResponseType>,
  Types.ReportsItemRequestType & { company_id?: string },
  Types.ThunkAPI<Types.requestError>
>(
  'OfficialCurriculum/thunk/getDataOfficialCurriculumList',
  async (req, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await services.filter<Types.OfficialCurriculumList2.ResponseType>(
        OFFICIAL_CURRICULUM_LIST2.name,
        req
      );
      const dataFilter = data.report_results.filter(
        (d) => d.publish == 2 || d.company_id === req.company_id
      );

      const dataConvert = await Promise.all(
        dataFilter.map(async (val) => {
          const obj = val;

          if (val.fileID) {
            const fileName = extractFileName(val.fileID);
            const nodeFileUrl = await sharedFileInMinIO(val.fileID);
            const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
            Object.assign(obj, {
              ...val,
              file: convertFileResponse({
                file: fileFromUrl,
                fileID: val.fileID,
                fileName: fileName,
              }),
            });
          }

          return obj;
        })
      );
      return { ...data, report_results: dataConvert };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAttachOfficialCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getAttachOfficialCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/createCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevelCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/updateLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.updateLevel(req.level, {
      id: req.id,
      data: {
        ...req.data,
        is_force_update: true,
        return_item_result: true,
      },
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLevelCurriculum = createAsyncThunk<
  Types.CreateItemResponseType<Types.Level.ResponseType>,
  Types.CreateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/createLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.createLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestion = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/createQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/deleteCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(CURRICULUMS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevelCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType & { level: number; realtime_auto_link: boolean },
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/deleteLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.deleteLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/deleteLinkQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTION_ASSIGN_LEVEL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOfficialCurriculumHierarchyList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OfficialCurriculumHierarchyList.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'OfficialCurriculum/thunk/getOfficialCurriculumHierarchyList',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.OfficialCurriculumHierarchyList.ResponseType>(
        OFFICIAL_CURRICULUM_HIERARCHY_LIST.id,
        {
          ...req,
          include_item_ref: true,
          sort_fields: [
            { id: 'curriculum_sort_order', order: 'asc' },
            { id: 'level1_sort_order', order: 'asc' },
            { id: 'level2_sort_order', order: 'asc' },
            { id: 'level3_sort_order', order: 'asc' },
            { id: 'level4_sort_order', order: 'asc' },
            { id: 'question_sort_order', order: 'asc' },
          ],
        }
      );

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumHierarchy.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumHierarchy.ResponseType>(
      CURRICULUM_HIERARCHY.name,
      {
        ...req,
        include_item_ref: true,
        sort_fields: [
          { id: 'curriculum_sort_order', order: 'asc' },
          { id: 'level1_sort_order', order: 'asc' },
          { id: 'level2_sort_order', order: 'asc' },
          { id: 'level3_sort_order', order: 'asc' },
          { id: 'level4_sort_order', order: 'asc' },
          { id: 'question_sort_order', order: 'asc' },
        ],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNoteManagement = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReleaseNoteManagement.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getDataReleaseNoteManagement', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReleaseNoteManagement.ResponseType>(
      RELEASE_NOTE_MANAGEMENT.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNoteDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReleaseNoteDetail.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getDataReleaseNoteDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReleaseNoteDetail.ResponseType>(
      RELEASE_NOTE_DETAIL.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataProductionCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getDataProductionCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataProviderInformation = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTransInformation.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getDataProviderInformation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CurriculumTransInformation.ResponseType>(
      CURRICULUM_PROVIDER_INFOMATION.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataReleaseNoteID = createAsyncThunk<
  Types.GetItemResponseType<Types.ReleaseNote.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getDataReleaseNoteID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ReleaseNote.ResponseType>(RELEASE_NOTE.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getCurriculumPublishedHistory = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumPublishedHistory.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('OfficialCurriculum/thunk/getCurriculumPublishedHistory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CurriculumPublishedHistory.ResponseType>(
      CURRICULUM_PUBLISHED_HISTORY.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
