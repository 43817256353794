import { createSlice } from '@reduxjs/toolkit';

import {
  deleteAnalysisGroup,
  getAnalysisGroup,
  getAnalysisGroupOptions,
  getSkillCheck,
} from './thunk';
import { Groups, SkillCheck } from 'types';

export interface InitialState {
  items: Groups.ResponseType[];
  searchOptions: Groups.ResponseType[];
  skillChecks: SkillCheck.ResponseType[];
  total: number;
}

const initialState: InitialState = {
  items: [],
  searchOptions: [],
  skillChecks: [],
  total: 0,
};

export const analysisGroupSlice = createSlice({
  name: 'AnalysisGroup-slice',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getAnalysisGroup.fulfilled, (state, action) => {
      state.items = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getAnalysisGroupOptions.fulfilled, (state, action) => {
      state.searchOptions = action.payload.items.filter(
        (item, index, arr) => arr.findIndex((i) => i.name === item.name) === index
      );
    });
    builder.addCase(deleteAnalysisGroup.fulfilled, (state, action) => {
      state.items = state.items.filter((item) => item.i_id !== action.payload.item);
    });
    builder.addCase(getSkillCheck.fulfilled, (state, action) => {
      state.skillChecks = action.payload.items;
    });
  },
});

export const { resetInitialState } = analysisGroupSlice.actions;

export default analysisGroupSlice.reducer;
