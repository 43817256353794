import React from 'react';

import { useSelector } from 'react-redux';
import { Button, Checkbox, Select } from 'antd';

import {
  BarChartOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { settingSelector } from 'containers/AppSettings/selectors';
import { OrderByType, UserType } from 'types/config';
import IndividualTable from '../IndividualTable';
import SurfaceTable from '../SurfaceTable';
import { Header } from 'components';
import Wrapper from './styles';
import * as Types from 'types';
import Chart from '../Chart';
import {
  ITEM_COMPARE_BAR_CHART,
  ITEM_COMPARE_PIE_CHART,
  ITEM_GRAPH,
  REPORT_TYPE,
} from 'constant/select.constants';

const { Option } = Select;

interface Props {
  selectInterviewUser: Types.SelectInterviewUser.ResponseType[];
  selectEmployeeUser: Types.SelectEmployeeUser.ResponseType[];
  selectSkillCheck: Types.SelectSkillCheck.ResponseType[];
  reportType: (typeof REPORT_TYPE)[number]['value'];
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  isQuestionCodeTable: boolean;
  questionDifficulty?: string;
  sortByCorrect: OrderByType;
  individualActive: boolean;
  implementDate: boolean;
  answerResult?: string;
  skillCheck?: string;
  userType: UserType;
  segmented: number;
  loginID?: string;
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
}

const FilePDFExport: React.FC<Props> = ({
  isQuestionCodeTable,
  selectInterviewUser,
  selectEmployeeUser,
  questionDifficulty,
  selectSkillCheck,
  individualActive,
  itemComparision,
  implementDate,
  sortByCorrect,
  answerResult,
  skillCheck,
  reportType,
  itemGraph,
  segmented,
  userType,
  loginID,
}) => {
  const { headerTitle } = useSelector(settingSelector);

  return (
    <Wrapper individualActive={individualActive}>
      <Header title={headerTitle} exportPdf />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">ユーザー種別</span>
            <Select className="select" placeholder="社内" value={userType}>
              <Option value="member">社内</Option>
              <Option value="interview">面接ユーザー</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの種類</span>
            <Select className="select" value={reportType}>
              {REPORT_TYPE.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="wrap-body">
        <div className="header-container">
          <span className="title">
            スキルチェック【社内】の実施結果を、スキルチェック・設問を基準に集計したレポートです。
          </span>
        </div>

        <div className="wrap-filter">
          <div className="aggregation-wrap">
            <span className="label">集計条件</span>
            <div className="aggregation-conditions">
              <div className="form-select">
                <div className="item">
                  <span className="text-label">スキルチェック</span>
                  <Select className="select" placeholder="指定なし" value={skillCheck}>
                    <Option disabled={loginID === 'ALL'} value="ALL">
                      ALL
                    </Option>
                    {selectSkillCheck.map((item, index) => (
                      <Option key={index} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="item">
                  <span className="text-label">ユーザー</span>
                  <Select className="select" placeholder="指定なし" value={loginID}>
                    <Option disabled={skillCheck === 'ALL'} value="ALL">
                      ALL
                    </Option>
                    {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                      (item, index) => (
                        <Option key={index} value={item.login_id}>
                          {item.name}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                {skillCheck === 'ALL' && (
                  <div className="item">
                    <Checkbox disabled checked={implementDate}>
                      未実施者を表示する
                    </Checkbox>
                  </div>
                )}
              </div>
            </div>
          </div>
          {individualActive && (
            <div className="question-wrap">
              <span className="label">絞込条件</span>
              <div className="question-conditions">
                <div className="form-select">
                  <div className="answer-result item">
                    <span className="text-label">解答結果</span>
                    <Select
                      className="select"
                      placeholder="指定なし"
                      value={answerResult}
                      allowClear
                    >
                      <Option value={'0'}>不正解</Option>
                      <Option value={'1'}>正解</Option>
                    </Select>
                  </div>
                  <div className="question-difficulty item">
                    <span className="text-label">設問難易度</span>
                    <Select
                      className="select"
                      placeholder="指定なし"
                      value={questionDifficulty}
                      allowClear
                    >
                      <Option value={'1'}>1</Option>
                      <Option value={'2'}>2</Option>
                      <Option value={'3'}>3</Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="sub-container">
          {individualActive ? (
            <div className="individual-title">
              <span className="title">
                ※選択したユーザー・スキルチェックの設問別実施結果が表示されています。
              </span>
            </div>
          ) : (
            <div className="wrap-segmented">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              {segmented && !isQuestionCodeTable ? (
                <>
                  <span className="cross-boiled">/</span>
                  <div className="wrapper-options">
                    <div className="wrapper-option">
                      <span className="label">グラフ：</span>
                      <div className="item">
                        <Select className="select" placeholder="---" value={itemGraph}>
                          {ITEM_GRAPH.map(({ label, value }, index) => (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="wrapper-option">
                      <span className="label">比較項目：</span>
                      <div className="item">
                        <Select className="select" placeholder="---" value={itemComparision}>
                          {(itemGraph === 'bar_chart'
                            ? ITEM_COMPARE_BAR_CHART
                            : ITEM_COMPARE_PIE_CHART
                          ).map(({ label, value }, index) => (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                      {(itemComparision === 'correct_answer_rate' ||
                        itemComparision === 'progress_rate') && (
                        <Button className="btn-sort">
                          <div className="wrap-sort">
                            <CaretUpOutlined
                              className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
                            />
                            <CaretDownOutlined
                              className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
                            />
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          )}
          {individualActive ? (
            <IndividualTable
              questionDifficulty={questionDifficulty}
              answerResult={answerResult}
              skillCheck={skillCheck}
              segmented={segmented}
              userType={userType}
              loginID={loginID}
              exportPdf
            />
          ) : !segmented ? (
            <SurfaceTable isQuestionCodeTable={isQuestionCodeTable} exportPdf />
          ) : (
            <Chart
              isQuestionCodeTable={isQuestionCodeTable}
              itemComparision={itemComparision}
              itemGraph={itemGraph}
              exportPdf
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default FilePDFExport;
