import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  .register {
    color: #424242;
    font-size: 15px;
    margin: 0;
  }
  .wrap-description {
    margin: 30px 0 20px 0;
    .description {
      font-size: 13px;
      color: #424242;
      text-align: center;
      margin: 0;
    }
  }
  .form {
    .input {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
      }
    }
    .ant-input-affix-wrapper-focused {
      background: #ffffff;
      input {
        background: #ffffff;
      }
    }
    .icon,
    .anticon {
      color: #bbbbbb;
      font-size: 18px;
    }
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      border: none;
      margin-top: 8px;
      width: 360px;
      height: 48px;
      font-size: 15px;
      font-weight: bold;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
`;
