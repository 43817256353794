import styled from 'styled-components';

export default styled.div`
  position: relative;
  margin-top: 50px;
  .color-chart {
    display: flex;
    position: absolute;
    flex-direction: row;
    top: -80px;
    right: 100px;
    .item-color {
      display: flex;
      align-items: center;
      margin-left: 20px;
      .color {
        width: 14px;
        height: 14px;
      }
      .correct-answer {
        background: #379bd2;
      }
      .incorrect-answer {
        background: #ea8c92;
      }
      .inexperienced {
        background: #ebebeb;
      }
      .name {
        margin: 0;
        font-size: 12px;
        color: #424242;
        padding: 8px;
      }
    }
  }
`;
