import React, { useMemo } from 'react';

import BreakdownAnswersPerCentage from './BreakdownAnswersPerCentage';
import BreakdownAnswers from './BreakdownAnswers';
import TableActualChart from './TableActualChart';
import TableChart from './TableChart';
import {
  ITEM_COMPARE_BAR_CHART_TRAINING,
  ITEM_COMPARE_PIE_CHART,
  ITEM_GRAPH,
} from 'constant/select.constants';

interface Props {
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART_TRAINING)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  curriculum?: string;
  exportPdf?: boolean;
  username?: string;
}

const Chart: React.FC<Props> = ({
  itemComparision,
  curriculum,
  username,
  exportPdf,
  itemGraph,
}) => {
  const isUserTransTable = useMemo(
    () => !!username && !!curriculum && username === 'ALL' && curriculum !== 'ALL',
    [curriculum, username]
  );

  return itemGraph === 'bar_chart' ? (
    <>
      {itemComparision === 'progress_rate' ? (
        <TableChart isUserTransTable={isUserTransTable} />
      ) : (
        <TableActualChart isUserTransTable={isUserTransTable} />
      )}
    </>
  ) : itemComparision === 'number_of_solutions' ? (
    <BreakdownAnswers isUserTransTable={isUserTransTable} exportPdf={exportPdf} />
  ) : (
    <BreakdownAnswersPerCentage isUserTransTable={isUserTransTable} exportPdf={exportPdf} />
  );
};

export default Chart;
