import styled from 'styled-components';

interface Props {
  itemComparision?: string;
}

export default styled.section<Props>`
  background: #ffffff;
  .top-page {
    background: #08a3a5;
    padding: 12px 8px;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      .type-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        width: 48%;
        .number-type {
          color: rgba(255, 255, 255, 0.3);
          font-size: 24px;
        }
        .label {
          font-size: 12px;
          font-weight: 700;
        }
        .select {
          width: 65%;
          .ant-select-selector {
            border-radius: 4px;
          }
        }
      }
      .close-icon {
        color: #ffffff;
        font-size: 16px;
        margin: 0 8px;
      }
    }
  }
  .container {
    margin: 30px;
    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: 14px;
        color: #424242;
      }
      .button-import {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        border-radius: 5px;
        color: #ffffff;
      }
    }
    .wrap-filter {
      .label {
        font-size: 11px;
        color: #777777;
      }
      .aggregation-conditions {
        background: #f2f2f2;
        padding: 15px 16px 5px 16px;
        border: 1px solid #eaeaea;
        border-radius: 3px;
        width: 100%;
        .form-select {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .item {
            width: 14.5%;
            .select {
              width: 100%;
              .ant-select-selector {
                border-radius: 5px;
              }
            }
            .text-label {
              height: 25px;
            }
            &:nth-child(3) {
              width: 23%;
            }
          }
          .checkbox-filter {
            background: #ffffff;
            border: 1px solid #cccccc;
            height: 32px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
          .divider {
            border-right: 1px solid #dddddd;
            height: 32px;
          }
          .text-label {
            display: block;
            font-size: 13px;
            color: #424242;
            .require {
              color: #f0330a;
            }
          }
        }
        .note {
          color: #999999;
          font-size: 11px;
        }
      }
    }
    .sub-container {
      margin-top: 25px;
      .wrap-segmented {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .left-side {
          display: flex;
          align-items: center;
          .label {
            font-size: 13px;
            color: #424242;
          }
          .ant-select-selector {
            border-radius: 3px;
          }
          .segmented {
            display: flex;
            align-items: center;
            justify-content: center;
            .segmented-item {
              padding: 5px 10px;
              background: #ffffff;
              border: 2px solid #cccccc;
              cursor: pointer;
              color: #424242;
              font-weight: 400;
              font-size: 13px;
              .icon {
                margin-right: 10px;
                color: #c4c4c4;
              }
              &:nth-child(1) {
                border-radius: 3px 0 0 3px;
                border-right-color: #08a3a5;
              }
              &:nth-child(2) {
                border-radius: 0 3px 3px 0;
                border-left-width: 0;
              }
            }
            .segmented-item-selected {
              border-color: #08a3a5;
              color: #08a3a5;
              font-weight: bold;
              .icon {
                color: #08a3a5;
              }
            }
          }
          .cross-boiled {
            margin: 0 10px;
          }
          .item {
            margin-bottom: 0;
            width: 190px;
            .select {
              width: 100%;
            }
          }
        }
        .right-side {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 35%;
          margin-right: 20px;
          .sort-column {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 30%;
            .legend-filter {
              width: 14px;
              height: 14px;
              background: ${({ itemComparision }) =>
                itemComparision === 'progress_rate' ? '#6ec9ab' : '#379BD2'};
            }
            .legend-name {
              font-size: 12px;
              color: #424242;
              margin: 0 5px;
            }
            .btn-sort {
              width: 18px;
              height: 18px;
              border-radius: 4px;
              padding: 0;
              .wrap-sort {
                display: flex;
                flex-direction: column;
                align-items: center;
                .icon-caret {
                  font-size: 9px;
                  color: #bfbfbf;
                }
                .active {
                  color: #424242;
                }
              }
            }
            &:nth-child(2) {
              .legend-filter {
                background: ${({ itemComparision }) =>
                  itemComparision === 'progress_rate' ? '#ddeacd' : '#EA8C92'} !important;
              }
            }
            &:nth-child(3) {
              .legend-filter {
                background: #ebebeb !important;
              }
            }
          }
        }
      }
      .text-total-record-table {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
  }
  .table-header {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    width: 60%;
    right: 0;
    top: 0;

    .ant-pagination-total-text {
      position: absolute;
      right: 0;
      .text-total-record-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .ant-pagination {
      .ant-pagination-options {
        position: absolute;
        right: 130px;

        ::before {
          content: '表示件数：';
          font-size: 11px;
          padding-right: 3px;
        }
      }
    }
  }
  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead tr .average {
      background: #f5fdfd;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
    .ant-table-row:nth-child(even) {
      background: #f9f9f9;
    }
    .ant-table-thead .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr:first-child .ant-table-cell {
      font-size: 12px;
      padding: 15px 7px;
      text-align: center !important;

      &:nth-child(2) {
        text-align: left !important;
      }

      .ant-table-column-sorters {
        justify-content: center;
        .ant-table-column-title {
          flex: none;
        }
      }
    }
  }
`;
