import React, { useEffect, useState } from 'react';

import { setHeaderTitle } from 'containers/AppSettings/slice';
import CurriculumMasterTable from './Table';
import CurriculumTreeview from './Treeview';
import { useAppDispatch } from 'hooks';

const Curriculum: React.FC = () => {
  const [openCurriculumMaster, setOpenCurriculumMaster] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle(openCurriculumMaster ? 'カリキュラムマスタ 階層リスト' : 'カリキュラムツリー')
    );
  }, [openCurriculumMaster, dispatch]);

  return openCurriculumMaster ? (
    <CurriculumMasterTable setOpenCurriculumMaster={setOpenCurriculumMaster} />
  ) : (
    <CurriculumTreeview setOpenCurriculumMaster={setOpenCurriculumMaster} />
  );
};

export default Curriculum;
