import { Locale, CurriculumStatus, CurriculumType } from './enum.constant';
import { SelectType } from 'types/config';
import * as Types from 'types';

export const language: Array<SelectType<keyof typeof Locale>> = [
  { label: 'Japanese', value: 'ja' },
  { label: 'Chinese', value: 'zh' },
  { label: 'English', value: 'en' },
];

export const CURRICULUM_TYPE: Array<SelectType<keyof typeof CurriculumType>> = [
  { label: 'ALL', value: 'all' },
  { label: 'カリキュラム', value: 'curriculum' },
  { label: '必修カリキュラム', value: 'required' },
  {
    label: 'OFFICIALカリキュラム',
    value: 'officialCurriculum',
  },
];

export const CURRICULUM_STATUS: Array<SelectType<keyof typeof CurriculumStatus>> = [
  { label: 'ALL', value: 'all' },
  { label: '公開中', value: 'publish' },
  { label: '編集中', value: 'edit' },
];

export const USER_TYPE: Array<SelectType<Types.SkillCheckFilterType['user_type']>> = [
  { label: '社内', value: 'member' },
  { label: '面接', value: 'interview' },
];

export const PUBLISH: Array<SelectType<Types.SkillCheckFilterType['publish']>> = [
  { label: '非公開', value: 0 },
  { label: '公開中', value: 1 },
];

export const REPORT_TYPE: Array<
  SelectType<'user' | 'user-analysis' | 'skill-check' | 'skill-check-analysis'>
> = [
  {
    label: 'ユーザーレポート',
    value: 'user',
  },
  {
    label: 'ユーザーレポート【分析グループ】',
    value: 'user-analysis',
  },
  {
    label: 'スキルチェックレポート',
    value: 'skill-check',
  },
  {
    label: 'スキルチェックレポート【分析グループ】',
    value: 'skill-check-analysis',
  },
];

export const ITEM_COMPARISION: Array<
  SelectType<'progress_rate' | 'answer_breakdown' | 'answer_breakdown_percentage'>
> = [
  {
    label: '進捗率',
    value: 'progress_rate',
  },
  {
    label: '解答内訳【解答数】',
    value: 'answer_breakdown',
  },
  {
    label: '解答内訳【解答比率】',
    value: 'answer_breakdown_percentage',
  },
];

export const ITEM_COMPARISION_ANALYSIS: Array<
  SelectType<
    | 'accuracy_rate'
    | 'wrong_rate'
    | 'inexperienced_rate'
    | 'average_answer_time_per_question'
    | 'acquisition_score_rate'
    | 'answer_breakdown'
  >
> = [
  {
    label: '正解率',
    value: 'accuracy_rate',
  },
  {
    label: '不正解率',
    value: 'wrong_rate',
  },
  {
    label: '未実施率',
    value: 'inexperienced_rate',
  },
  {
    label: '設問あたり平均解答時間',
    value: 'average_answer_time_per_question',
  },
  {
    label: '獲得スコア率',
    value: 'acquisition_score_rate',
  },
  {
    label: '解答内訳',
    value: 'answer_breakdown',
  },
];

export const ITEM_GRAPH: Array<SelectType<'bar_chart' | 'pie_chart'>> = [
  {
    label: '棒グラフ',
    value: 'bar_chart',
  },
  {
    label: '円グラフ',
    value: 'pie_chart',
  },
];

export const ITEM_COMPARE_BAR_CHART: Array<SelectType<'correct_answer_rate' | 'progress_rate'>> = [
  {
    label: '正解率',
    value: 'correct_answer_rate',
  },
  {
    label: '進捗率',
    value: 'progress_rate',
  },
];

export const ITEM_COMPARE_PIE_CHART: Array<SelectType<'solution_ratio' | 'number_of_solutions'>> = [
  {
    label: '解答比率',
    value: 'solution_ratio',
  },
  {
    label: '解答数',
    value: 'number_of_solutions',
  },
];

export const ITEM_COMPARE_BAR_CHART_TRAINING: Array<
  SelectType<'actual_accuracy_rate' | 'progress_rate'>
> = [
  {
    label: '実質正解率',
    value: 'actual_accuracy_rate',
  },
  {
    label: '正解進捗率/進捗率',
    value: 'progress_rate',
  },
];

export const ITEM_COMPARISION_USER_ANALYSIS_TABLE: Array<
  SelectType<'accuracy_rate' | 'wrong_rate' | 'inexperienced_rate' | 'acquisition_score_rate'>
> = [
  {
    label: '正解率',
    value: 'accuracy_rate',
  },
  {
    label: '不正解率',
    value: 'wrong_rate',
  },
  {
    label: '未実施率',
    value: 'inexperienced_rate',
  },
  {
    label: '獲得スコア率',
    value: 'acquisition_score_rate',
  },
];

export const ITEM_COMPARISION_USER_ANALYSIS_CHART: Array<
  SelectType<
    | 'accuracy_rate'
    | 'wrong_rate'
    | 'inexperienced_rate'
    | 'average_answer_time_per_question'
    | 'acquisition_score_rate'
    | 'answer_breakdown'
  >
> = [
  {
    label: '正解率',
    value: 'accuracy_rate',
  },
  {
    label: '不正解率',
    value: 'wrong_rate',
  },
  {
    label: '未実施率',
    value: 'inexperienced_rate',
  },
  {
    label: '平均解答時間',
    value: 'average_answer_time_per_question',
  },
  {
    label: '獲得スコア率',
    value: 'acquisition_score_rate',
  },
  {
    label: '解答内訳',
    value: 'answer_breakdown',
  },
];

export const EXTENSIONS: Array<{ label: string; value: string; extension: string[] }> = [
  {
    label: '画像',
    value: 'image',
    extension: [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
      'image/x-icon',
    ],
  },
  {
    label: '動画',
    value: 'video',
    extension: [
      'video/mov',
      'video/mp4',
      'video/avi',
      'video/mpeg',
      'video/ogg',
      'video/webm',
      'video/flv',
      'video/3gpp',
    ],
  },
  {
    label: 'Excel',
    value: 'excel',
    extension: [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ],
  },
  {
    label: 'Word',
    value: 'word',
    extension: [
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/msword',
      'application/doc',
      'application/application/ms-doc',
    ],
  },
  {
    label: 'PDF',
    value: 'pdf',
    extension: ['application/pdf'],
  },
  {
    label: 'PowerPoint',
    value: 'power-point',
    extension: [
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.ms-powerpoint',
    ],
  },
];
