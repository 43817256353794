import styled from 'styled-components';

export const SectionStyled = styled.div`
  background-color: #f9f8f8;
  .wrap-title {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-copy {
      font-size: 22px;
      color: #bbbbbb;
    }
    .title {
      font-size: 15px;
      color: #424242;
      margin: 0 0 0 10px;
    }
  }
  .wrap-content {
    margin: 10px;
    padding: 5px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 3px;
    .label {
      font-size: 11px;
      color: #999999;
    }
    .name {
      font-size: 16px;
      text-align: center;
      color: #2a2a2a;
    }
  }
  .caution {
    font-size: 12px;
    text-align: center;
    color: #999999;
  }
`;
