import React, { useEffect, useState } from 'react';
import { EditOutlined, MinusOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { orderBy, sumBy } from 'lodash';
import { Popover } from 'antd';

import { deleteUserAssignSkillCheck, updateSkillCheck } from 'pages/Settings/SkillCheck/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { Wrapper, WrapperToolTip, WrapperColumnRoot } from './styles';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { useAppDispatch, usePermission } from 'hooks';
import { routes } from 'navigations/routes';
import NodeRenderer from './NodeRenderer';
import * as Types from 'types';

interface Props {
  columnClosed: boolean;
  fetchDataSkillCheck: () => void;
  handleAddUserSelected: () => void;
  fetchDataUserSkillCheck: () => void;
  treeData: Types.SkillCheckTreeViewType;
  skillCheckSelected?: Types.SkillCheckTreeViewType;
  setSkillCheckSelected: React.Dispatch<
    React.SetStateAction<Types.SkillCheckTreeViewType | undefined>
  >;
}

const UserTreeView: React.FC<Props> = ({
  treeData,
  columnClosed,
  skillCheckSelected,
  fetchDataSkillCheck,
  handleAddUserSelected,
  setSkillCheckSelected,
  fetchDataUserSkillCheck,
}) => {
  const [skillcheckUserRemove, setSkillcheckUserRemove] = useState<Types.SkillCheckTreeViewType>();
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [itemId, setItemId] = useState<string>('');
  const [userID, setUserId] = useState<string>('');

  const navigate = useNavigate();

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const [, drop] = useDrop<
    {
      type: string;
      item: {
        i_id: string;
        user_id: string;
        login_id: string;
      };
    },
    void,
    void
  >({
    accept: 'move-user',
    canDrop: () => skillCheckSelected?.i_id === treeData.i_id,
    drop: () => {
      skillCheckSelected && handleAddUserSelected();
    },
  });

  const handleRemoveLinkUser = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteUserAssignSkillCheck({
        id: itemId,
      })
    );
    if (deleteUserAssignSkillCheck.fulfilled.match(resultAction)) {
      await dispatch(
        updateSkillCheck({
          id: skillcheckUserRemove?.i_id!,
          data: {
            item: {
              user_count:
                (sumBy(skillcheckUserRemove?.user_children, (department) =>
                  sumBy(department.children, (child) => (child.user_id ? 1 : 0))
                ) || 1) - 1,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      setSkillcheckUserRemove(undefined);
      fetchDataUserSkillCheck();
    }
    dispatch(stopLoading());
  };

  const handleEditLinkUser = () => {
    navigate(
      generatePath(routes.EditEmployee.path, {
        id: userID,
      })
    );
  };

  useEffect(() => {
    return () => {
      setSkillCheckSelected(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      className="rst__tree"
      treeActive={skillCheckSelected !== undefined && skillCheckSelected.i_id === treeData.i_id}
    >
      <WrapperColumnRoot nodeHeight={56} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            data={treeData}
            fetchDataSkillCheck={fetchDataSkillCheck}
            fetchDataUserSkillCheck={fetchDataUserSkillCheck}
            handleSwitch={(value: boolean) => setSkillCheckSelected(value ? treeData : undefined)}
            itemSelected={
              skillCheckSelected !== undefined && skillCheckSelected.i_id === treeData.i_id
            }
          />
        </div>
      </WrapperColumnRoot>
      {!columnClosed && (
        <div ref={drop} className="tree">
          {treeData.user_children.length > 0 ? (
            orderBy(treeData.user_children, 'department_name', 'asc').map(
              ({ department_name, children }, i) => (
                <div key={i}>
                  <p className="text-name">{department_name}</p>
                  <div className="wrap_node_tree">
                    {children?.map((value, index2) => (
                      <Popover
                        key={index2}
                        placement="bottomLeft"
                        overlayStyle={{
                          width: 204,
                          marginTop: '-25px',
                        }}
                        trigger="click"
                        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                        content={
                          <WrapperToolTip>
                            <span className="text-code">{value.employee_number}</span>
                            <br />
                            <span className="text-bold">{value.user_name}</span>
                            &nbsp;&nbsp;&nbsp;
                            <span className="text-bold">{`(${value.name_furigana})`}</span>
                            <div className="text_icon">
                              <EditOutlined
                                className="icon"
                                onClick={() => {
                                  if (permissionNumber !== 1) {
                                    setUserId(value.user_id!);
                                    setVisibleModalEdit(true);
                                  }
                                }}
                              />
                              <MinusOutlined
                                className="icon"
                                onClick={() => {
                                  if (permissionNumber !== 1) {
                                    setSkillcheckUserRemove(treeData);
                                    setItemId(value.i_id!);
                                    setVisibleModalDelete(true);
                                  }
                                }}
                              />
                            </div>
                          </WrapperToolTip>
                        }
                      >
                        <div className="node-item">
                          <span className="text-code">({value.employee_number})</span>
                          <span className="text-bold">{value.user_name}</span>
                        </div>
                      </Popover>
                    ))}
                  </div>
                </div>
              )
            )
          ) : (
            <p className="text-not-set">未設定</p>
          )}
        </div>
      )}
      <ConfirmDeleteModal
        title="ユーザー解除"
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
        onCancel={() => setItemId('')}
        onSubmit={handleRemoveLinkUser}
        subTitle="指定したユーザーのスキルチェック設定を解除します。"
      />
      <ConfirmDeleteModal
        title="社内ユーザー編集"
        visible={visibleModalEdit}
        setVisible={setVisibleModalEdit}
        onSubmit={handleEditLinkUser}
        onCancel={() => setItemId('')}
        subTitle="社内ユーザーマスタに移動します。"
      />
    </Wrapper>
  );
};

export default UserTreeView;
