import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'rgba(221, 221, 221, 1)',
    fontFamily: 'NotosanJP',
  },
  wrapBody: {
    backgroundColor: '#ddd',
    width: '100%',
  },
  content: {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.05)',
    padding: '35px',
  },
  contentHeader: {},
  contentHeaderLogo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoHeader: {
    width: '100px',
    height: '68px',
  },
  contentHeaderText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '11px',
    color: '#555555',
    marginRight: '12px',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleModal: {
    fontSize: '20px',
    color: '#222222',
  },
  wrapTitleHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '36px',
  },
  nameCompany: {
    fontSize: '14px',
    color: '#222222',
  },
  wrapContentStandard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  titleStandard: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '8px',
    color: '#777777',
  },
  subTitleStandard: {
    fontSize: '13px',
    color: '#222222',
  },
  infoContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoContentLeft: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    color: '#222222',
  },
  infoContentRight: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    fontSize: '10px',
    color: '#222222',
    position: 'relative',
  },
  imageLogo: {
    position: 'absolute',
    right: '-8px',
    bottom: '-4px',
    width: '74px',
    height: '74px',
  },
  totalHeader: {
    marginTop: '20px',
    width: '45%',
    border: '1px solid #08a3a5',
    padding: '1px 6px 0 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  totalHeaderTitle: {
    fontSize: '8px',
    color: '#159193',
  },
  textVersion: {
    fontSize: '11px',
    color: '#555',
  },
  totalNumber: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#222222',
    fontSize: '22px',
    alignItems: 'baseline',
  },
  currencyUnit: {
    fontSize: '12px',
    color: '#000000',
    marginLeft: '8px',
  },
  contentBody: {
    marginTop: '20px',
  },
  subTable: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  wrapSubItem: {
    width: '40%',
  },
  subTitle: {
    paddingLeft: '8px',
    color: '#555555',
    fontSize: '12px',
  },
  subPrice: {
    paddingRight: '8px',
    color: '#222222',
    fontSize: '16px',
  },
  subItem: {
    borderBottom: '1px solid #08a3a5',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '7px 0',
    alignItems: 'center',
    flexDirection: 'row',
  },
  contentFooter: {
    marginTop: '76px',
    backgroundColor: 'rgba(8, 163, 165, 0.06)',
    padding: '16px 0 16px 20px',
  },
  itemFooter: {
    display: 'flex',
    flexDirection: 'column',
  },
  textPhone: {
    fontSize: '10px',
    color: '#222',
  },
  textLabel: {
    fontSize: '12px',
    color: '#222',
  },
  itemText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '13px',
    color: '#555',
  },
  textLast: {
    fontSize: '11px',
    color: '#555',
  },
  textFist: {
    fontSize: '13px',
    color: '#222',
  },
  section: {
    flexGrow: 1,
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  tableRowHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    backgroundColor: '#08A3A5',
    color: '#FFFFFF',
    borderBottomColor: '#AAAAAA',
    borderBottomStyle: 'solid',
    alignItems: 'center',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#AAAAAA',
    borderBottomStyle: 'solid',
    alignItems: 'center',
  },
  tableCell1: {
    width: '50%',
    padding: 5,
    fontSize: '14px',
    color: '#222',
  },
  tableCell2: {
    width: '10%',
    padding: 5,
    fontSize: '14px',
    color: '#222',
  },
  tableCell3: {
    width: '30%',
    padding: 5,
    textAlign: 'right',
    fontSize: '14px',
    color: '#222',
  },
});
