import React from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from 'containers/Auth/slice';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { Wrapper } from './styles';

const ModalConfirmMobile: React.FC<{}> = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  return (
    <Wrapper>
      <div className="container">
        <div>Mobile Only Account</div>
        <button
          className="btn-submit"
          onClick={() => {
            navigate(routes.Login.path);
            dispatch(logout());
          }}
        >
          Back to screen Login
        </button>
      </div>
    </Wrapper>
  );
};

export default ModalConfirmMobile;
