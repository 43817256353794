import React, { useState, useEffect } from 'react';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { findIndex } from 'lodash';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { interviewUsersSelector } from '../../selectors';
import { IconLocked, IconPublish } from 'assets';
import { Modal, SelectField } from 'components';
import SkillCheckStyled from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  getDataSelectCheckUseStatus,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckCategory,
  getDataSelectSkillCheckCreator,
  getDataSelectSkillCheckGroupingCode,
  getDataSelectSkillCheckStartPeriod,
  getDataSkillCheck,
} from '../../thunk';

interface Props {
  visible: boolean;
  onSubmit?: (item: Array<Types.SkillCheck.ResponseType>) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  data: Array<Types.SkillCheck.ResponseType>;
}

const { Option } = Select;

const SkillCheckSetting: React.FC<Props> = ({ visible, setVisible, onSubmit, data }) => {
  const [selectedRow, setSelectedRow] = useState<Types.SkillCheck.ResponseType[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);

  const {
    total,
    dataSkillCheckSetting,
    dataSelectSkillCheck,
    dataSelectSkillCheckStartPeriod,
    dataSelectSkillCheckGroupingCode,
    dataSelectSkillCheckCreator,
  } = useSelector(interviewUsersSelector);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    onSubmit && (await onSubmit(selectedRow));
    handleToggleModal();
  };

  const dispatch = useAppDispatch();

  const formik = useFormik<Types.SearchSkillCheckFormik>({
    initialValues: {
      code: '',
      name: '',
      start_period: '',
      grouping_code: '',
      use_status: '',
      created_by: '',
    },
    onSubmit: () => {},
  });

  const { resetForm } = formik;

  const columns: ColumnsType<Types.SkillCheck.ResponseType> = [
    {
      title: 'コード',
      dataIndex: 'code',
      key: 'code',
      width: '7%',
    },
    {
      title: 'スキルチェック名',
      dataIndex: 'name',
      key: 'name',
      width: '22%',
    },
    {
      title: '説明',
      dataIndex: 'explanation',
      key: 'explanation',
      width: '7%',
      render: (text, _, index) => (
        <Tooltip
          trigger={['click']}
          title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
          color="#ffffff"
          onVisibleChange={(v) =>
            setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
          }
        >
          <FileTextOutlined
            className="icon"
            style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </Tooltip>
      ),
    },
    {
      title: '開始年月',
      dataIndex: 'start_period',
      key: 'start_period',
      width: '8%',
      render: (record: string) => record && dayjs(record).format('YYYY/MM'),
    },
    {
      title: '対象種別',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '9%',
      render: (record: string) => (
        <div className="item-type">
          <div className={`type ${record === 'interview' ? 'exception' : ''}`}>
            <span>{record === 'interview' ? '面' : '社'} </span>
          </div>
          <span>{record === 'interview' ? '面接' : '社内'}</span>
        </div>
      ),
    },
    {
      title: '分析グループ',
      dataIndex: 'grouping_code',
      key: 'grouping_code',
      width: '13%',
    },
    {
      title: '設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      width: '7%',
    },
    {
      title: '所要時間',
      dataIndex: 'question_time_limit',
      key: 'question_time_limit',
      width: '9%',
    },
    {
      title: '公開ステータス',
      dataIndex: 'use_status',
      key: 'use_status',
      width: '12%',
      render: (publish: string) => (
        <div className="type-icon">
          <img
            src={publish === '1' ? IconPublish : IconLocked}
            className="icon"
            alt="publish-icon"
          />
          <span>{publish === '1' ? '公開中' : '編集中'}</span>
        </div>
      ),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
    },
  ];

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: page,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
              use_display_id: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckStartPeriod({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCategory({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckGroupingCode({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectCheckUseStatus({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCreator({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [page, dispatch, userInfo, visible]);

  useEffect(() => {
    if (!visible) {
      return () => {
        setSelectedRow([]);
      };
    }
  }, [visible]);

  return (
    <Modal
      visible={visible}
      title={<h3 className="title">スキルチェック設定追加</h3>}
      width={1300}
      okButton={{
        text: '登録',
        onClick: handleSubmit,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      onCancel={handleToggleModal}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
    >
      <SkillCheckStyled dataSkillCheck={true}>
        <p className="sub-title">
          設定するスキルチェックを選択して、登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="code"
                className="item"
                label={<span className="text-label">スキルチェックコード</span>}
              >
                <SelectField
                  name="code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ code }, index) => (
                    <Option value={code} key={index}>
                      {code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">スキルチェック名</span>}
              >
                <SelectField
                  name="name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ name }, index) => (
                    <Option value={name} key={index}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="start_period"
                className="item"
                label={<span className="text-label">開始年月</span>}
              >
                <SelectField
                  name="start_period"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheckStartPeriod?.map(({ start_period }, index) => (
                    <Option key={index} value={start_period}>
                      {dayjs(start_period).format('YYYY/MM/DD')}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="user_type"
                className="item"
                label={<span className="text-label">ユーザー種別</span>}
              >
                <SelectField
                  name="user_type"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option key={1} value="member">
                    <div className="text-user-type">
                      <div className="text-icon-type interview">社</div>
                      <span>社内</span>
                    </div>
                  </Option>
                  <Option key={2} value="interview">
                    <div className="text-user-type">
                      <div className="text-icon-type member">面</div>
                      <span>面接</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="grouping_code"
                className="item"
                label={<span className="text-label">分析グループ</span>}
              >
                <SelectField
                  name="grouping_code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheckGroupingCode?.map(({ grouping_code }, index) => (
                    <Option key={index} value={grouping_code}>
                      {grouping_code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="use_status"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField
                  name="use_status"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option value={1} key={1}>
                    <div className="type-icon">
                      <img src={IconPublish} className="icon" alt="publish-icon" />
                      <span>公開中</span>
                    </div>
                  </Option>
                  <Option value={0} key={0}>
                    <div className="type-icon">
                      <img src={IconLocked} className="icon" alt="publish-icon" />
                      <span>編集中</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="created_by"
                className="item"
                label={<span className="text-label">作成者</span>}
              >
                <SelectField
                  name="created_by"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheckCreator?.map(({ creator }, index) => (
                    <Option value={creator} key={index}>
                      {creator}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="text-count">
          {(page - 1) * perPage + 1} -{' '}
          {page * perPage > dataSkillCheckSetting.length
            ? dataSkillCheckSetting.length
            : page * perPage}
          <span className="text-static"></span> / {total}
          <span className="text-static">件</span>
          <div className="page-select">
            <div className="label">
              <span>表示件数</span>：
            </div>
            <Select value={perPage} onSelect={handleSelectChange}>
              {[10, 20, 50, 100].map((value, index) => (
                <Option key={index} value={value} label={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          loading={loading}
          dataSource={dataSkillCheckSetting.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            type: 'radio',
            selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
            onChange: (_, record) => setSelectedRow(record),
            getCheckboxProps: ({ code }) => ({
              disabled: findIndex(data, (e) => e.code === code) > -1,
            }),
          }}
          pagination={{
            pageSize: perPage,
            showSizeChanger: false,
            total: dataSkillCheckSetting.length,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
        />
      </SkillCheckStyled>
    </Modal>
  );
};

export default SkillCheckSetting;
