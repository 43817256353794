import React from 'react';
import { TextAreaProps } from 'formik-antd';

import TextAreaStyled from './styles';

const TextArea: React.FC<TextAreaProps> = (props) => {
  return <TextAreaStyled {...props} />;
};

export default TextArea;
