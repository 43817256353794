import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Carousel, Col, Image, Progress, Radio, Row } from 'antd';
import { isNumber, get, find, includes } from 'lodash';
import { CarouselRef } from 'antd/lib/carousel';
import { RadioChangeEvent } from 'antd/es';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import {
  ApartmentOutlined,
  CaretRightFilled,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading, toggleHiddenSidebar } from 'containers/AppSettings/slice';
import ModalPreview from 'pages/Settings/SkillCheckSelection/SkillCheckStatus/ModalPreview';
import { settingSelector } from 'containers/AppSettings/selectors';
import { trainingCurriculumSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { ConfirmRight, ConfirmWrong } from '../Modal';
import { IconCompulsory, LogoNav } from 'assets';
import TrainingImplementStyled from './styles';
import { QUESTION_TRANS2 } from 'configs';
import { useAppDispatch } from 'hooks';
import {
  addLinkQuestion,
  createQuestionTrans,
  editCurriculumTrans,
  getData,
  updateQuestionTrans,
  getDataCorrectQues,
} from '../thunk';

interface Props {
  creator?: string;
  correctNum: number;
  startTime?: string;
  visibleTimer: boolean;
  affiliationId?: string;
  curriculumCode?: string;
  numberQuestionImplement: number;
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setCorrectNum: React.Dispatch<React.SetStateAction<number>>;
}

const dataAnswer = ['A', 'B', 'C'];

const TrainingImplement: React.FC<Props> = ({
  creator,
  startTime,
  setChangeTab,
  visibleTimer,
  setCorrectNum,
  affiliationId,
  curriculumCode,
  numberQuestionImplement,
}) => {
  const [numAcquisitionScore, setNumAcquisitionScore] = useState<number>(0);
  const [isPreviewImageAnswer, setPreviewImageAnswer] = useState<number>();
  const [previousEndTime, setPreviousEndTime] = useState<string>();
  const [isPreviewVisible, setPreviewVisible] = useState<number>();
  const [openConfirmRight, setOpenConfirmRight] = useState(false);
  const [openConfirmWrong, setOpenConfirmWrong] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [fileAnswer, setFileAnswer] = useState<string>();
  const [valueQuestion, setValueQuestion] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [reNew, setRenew] = useState<boolean>(false);
  const [correct, setCorrect] = useState<number>(0);
  const [timer, setTimer] = useState(0);

  const {
    dataImplementation,
    dataCreate,
    dataEdit,
    dataCreateQuestionTrans,
    dataQuestionImplement,
    dataAllQuestion,
  } = useSelector(trainingCurriculumSelector);

  const { userInfo } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);

  const dispatch = useAppDispatch();

  const slider = useRef<CarouselRef>(null);
  const interValRef = useRef();
  const requestOngoingRef = useRef(false);

  const onChange = (e: RadioChangeEvent) => {
    setValueQuestion(e.target.value);
    if (e.target.value === 'A') {
      setFileAnswer(dataImplementation[tabIndex]?.problems1_attach_file);
    } else if (e.target.value === 'B') {
      setFileAnswer(dataImplementation[tabIndex]?.problems2_attach_file);
    } else {
      setFileAnswer(dataImplementation[tabIndex]?.problems3_attach_file);
    }
  };

  const toggleSelect = () => {
    setIsSelect(true);
  };

  const handleNext = () => slider.current?.next();

  const handlePrev = () => slider.current?.prev();

  const desiredQuestion = find(dataQuestionImplement, {
    curriculum_assign_code: dataImplementation[tabIndex]?.assign_id,
  });
  const desiredIId = get(desiredQuestion, 'i_id');

  const questionCheck = dataQuestionImplement.filter(
    (item) => item.code === dataImplementation[tabIndex]?.question_code
  )[0]?.correct;

  const num = useMemo(() => {
    const correct_answer = dataImplementation[tabIndex]?.answer === valueQuestion;
    if (
      (!Number(questionCheck) === true && !correct_answer) ||
      (Number(questionCheck) === 1 && correct_answer)
    ) {
      return 0;
    } else if (!Number(questionCheck) === true && correct_answer) {
      return 1;
    } else return -1;
  }, [dataImplementation, tabIndex, questionCheck, valueQuestion]);

  const numScore = useMemo(() => {
    const correct_answer = dataImplementation[tabIndex]?.answer === valueQuestion;
    if (
      (!Number(questionCheck) === true && !correct_answer) ||
      (Number(questionCheck) === 1 && correct_answer)
    )
      return 0;
    else if (!Number(questionCheck) === true && correct_answer)
      return dataImplementation[tabIndex]?.score;
    else return -dataImplementation[tabIndex]?.score;
  }, [dataImplementation, tabIndex, questionCheck, valueQuestion]);

  const numberQuestionTrue = dataQuestionImplement.filter(
    (item) => Number(item.correct) === 1
  ).length;

  const handleSkipCurriculumImplementation = () => {
    setChangeTab(0);
  };

  const handleSubmit = async () => {
    dispatch(startLoading());
    const currentTime = dayjs();
    const calculateElapsedTime = () => {
      if (startTime && !previousEndTime) {
        const elapsedTime = currentTime.diff(startTime, 'seconds');
        return elapsedTime;
      }
      if (previousEndTime) {
        const elapsedNewTime = currentTime?.diff(previousEndTime, 'seconds');
        return elapsedNewTime;
      }
      return 0;
    };

    const correct_answer = dataImplementation[tabIndex]?.answer === valueQuestion;
    correct_answer && setCorrect(correct + 1);
    correct_answer &&
      setNumAcquisitionScore(numAcquisitionScore + dataImplementation[tabIndex]?.score);

    if (correct_answer) {
      setOpenConfirmRight(true);
    } else {
      setOpenConfirmWrong(true);
    }
    if (dataCreate && !dataEdit && dataImplementation) {
      const resultAction = await Promise.all([
        !reNew
          ? dispatch(
              createQuestionTrans({
                item: {
                  company_id: userInfo?.company_id,
                  login_id: userInfo?.login_id,
                  code: dataImplementation[tabIndex]?.question_code,
                  curriculum_trans_id: dataCreate?.item.curriculum_trans_id,
                  curriculum_code: dataImplementation[tabIndex]?.curriculum_code,
                  curriculum_assign_code: dataImplementation[tabIndex]?.assign_id,
                  user_answer: valueQuestion,
                  correct: correct_answer ? 1 : 0,
                  implementation_date: new Date(),
                  answer_time: calculateElapsedTime(),
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
                return_item_result: true,
                return_display_id: true,
              })
            )
          : dispatch(
              updateQuestionTrans({
                id: dataCreateQuestionTrans!.item_id,
                data: {
                  item: {
                    correct: correct_answer ? 1 : 0,
                  },
                  is_force_update: true,
                },
              })
            ),
        dispatch(
          editCurriculumTrans({
            id: dataCreate.item_id,
            data: {
              item: {
                login_id: dataCreate.item.login_id,
                curricullum_code: dataCreate.item.curricullum_code,
                responses_num: tabIndex + 1,
                correct_answers_num: correct_answer ? correct + 1 : correct,
                tran_status:
                  dataCreate.item.responses_num === dataImplementation[0].probs_count ? 1 : 0,
                acquisition_score: numAcquisitionScore + numScore,
              },
              is_force_update: true,
            },
          })
        ),
      ]);
      if (createQuestionTrans.fulfilled.match(resultAction[0])) {
        await dispatch(
          addLinkQuestion({
            id: dataImplementation[tabIndex]!.item_ref!.question_code_for_assign.i_id,
            data: {
              link_datastore_id: QUESTION_TRANS2.id,
              link_item_id: resultAction[0].payload.item_id,
            },
          })
        );
      }
      if (editCurriculumTrans.fulfilled.match(resultAction[1])) {
        await dispatch(
          getDataCorrectQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo?.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_code',
                search_value: [dataCreate.item.curricullum_code],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        );
      }
    }
    if (dataEdit && calculateElapsedTime) {
      const resultAction = await Promise.all([
        desiredIId
          ? dispatch(
              updateQuestionTrans({
                id: desiredIId,
                data: {
                  item: {
                    correct: correct_answer ? 1 : 0,
                    implementation_date: new Date(),
                    user_answer: valueQuestion,
                    answer_time: calculateElapsedTime(),
                  },
                  is_force_update: true,
                },
              })
            )
          : dispatch(
              createQuestionTrans({
                item: {
                  company_id: userInfo?.company_id,
                  login_id: userInfo?.login_id,
                  code: dataImplementation[tabIndex]?.question_code,
                  curriculum_trans_id: dataEdit.curriculum_trans_id,
                  curriculum_code: dataImplementation[tabIndex]?.curriculum_code,
                  curriculum_assign_code: dataImplementation[tabIndex]?.assign_id,
                  user_answer: valueQuestion,
                  correct: correct_answer ? 1 : 0,
                  implementation_date: new Date(),
                  answer_time: calculateElapsedTime(),
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
                return_item_result: true,
                return_display_id: true,
              })
            ),
        dispatch(
          editCurriculumTrans({
            id: dataEdit.i_id,
            data: {
              item: {
                login_id: dataEdit.login_id,
                curricullum_code: dataEdit.curricullum_code,
                responses_num: dataEdit.responses_num + (questionCheck === undefined ? 1 : 0),
                correct_answers_num: numberQuestionTrue + num,
                tran_status: dataEdit.responses_num === dataImplementation[0].probs_count ? 1 : 0,
                acquisition_score: dataEdit.acquisition_score + numScore,
              },
              is_force_update: true,
              return_item_result: true,
            },
          })
        ),
      ]);
      if (createQuestionTrans.fulfilled.match(resultAction[0])) {
        await dispatch(
          addLinkQuestion({
            id: dataImplementation[tabIndex]!.item_ref!.question_code_for_assign.i_id,
            data: {
              link_datastore_id: QUESTION_TRANS2.id,
              link_item_id: resultAction[0].payload.item_id,
            },
          })
        );
      }
      if (editCurriculumTrans.fulfilled.match(resultAction[1])) {
        await dispatch(
          getDataCorrectQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo?.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_code',
                search_value: [dataEdit.curricullum_code],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        );
      }
    }
    dispatch(stopLoading());
  };
  const handleReLoadDataTab = useCallback(
    async (tabIndexValue) => {
      if (!userInfo) return;
      if (requestOngoingRef.current) return;
      requestOngoingRef.current = true;

      const resultAction = await dispatch(
        getData({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'publish',
              search_value: ['1'],
              exact_match: true,
            },
            {
              id: 'required_curriculum',
              search_value: ['0'],
              exact_match: true,
            },
            ...(curriculumCode
              ? [
                  {
                    id: 'code',
                    search_value: [curriculumCode],
                    exact_match: true,
                  },
                ]
              : []),
            ...(creator
              ? [
                  {
                    id: 'createdby',
                    search_value: [creator],
                    exact_match: true,
                  },
                ]
              : []),
            ...(affiliationId
              ? [
                  {
                    id: 'affiliation_id',
                    search_value: [affiliationId],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
      requestOngoingRef.current = false;
      if (getData.fulfilled.match(resultAction)) {
        setChangeTab(tabIndexValue);
        if (tabIndexValue === 0) {
          setCorrectNum(0);
        }
      }
    },
    [affiliationId, creator, curriculumCode, dispatch, setChangeTab, setCorrectNum, userInfo]
  );

  const handleNextTab = useCallback(() => {
    const newTabIndex = tabIndex + 1;
    setCorrectNum((prevState) => {
      const newState = prevState + (dataImplementation[tabIndex]?.answer === valueQuestion ? 1 : 0);
      if (
        dataAllQuestion.length === dataQuestionImplement.length &&
        numberQuestionImplement < dataAllQuestion.length &&
        newTabIndex === dataImplementation.length
      ) {
        handleReLoadDataTab(2);
        setChangeTab(newState === dataQuestionImplement.length ? 3 : 2);
      } else if (
        dataQuestionImplement.filter((item) => Number(item.correct) === 1).length ===
          dataAllQuestion.length &&
        newTabIndex === dataImplementation.length &&
        valueQuestion
      ) {
        handleReLoadDataTab(3);
        setChangeTab(3);
      } else if (newTabIndex === dataImplementation.length) {
        handleReLoadDataTab(0);
      } else {
        setTabIndex(newTabIndex);
      }
      return newState;
    });
    const currentTime = dayjs().toISOString();
    setPreviousEndTime(currentTime);
    setValueQuestion('');
    setOpenConfirmRight(false);
    setOpenConfirmWrong(false);
  }, [
    dataQuestionImplement,
    dataImplementation,
    dataAllQuestion,
    setChangeTab,
    setCorrectNum,
    setPreviousEndTime,
    tabIndex,
    valueQuestion,
    numberQuestionImplement,
    handleReLoadDataTab,
  ]);

  const timeCount = useMemo(() => {
    if (!dataImplementation.length && !visibleTimer) return 0;
    if (includes(dataImplementation[tabIndex]?.question_time_limit, '分')) {
      return Number(dataImplementation[tabIndex]?.question_time_limit.split('分').join('')) * 60;
    }

    return Number(dataImplementation[tabIndex]?.question_time_limit.split('秒').join('')) || 60;
  }, [dataImplementation, tabIndex, visibleTimer]);

  const countDown = useMemo(() => {
    const minuteConvert = Math.floor(timer / 60);
    const miliConvert = timer - minuteConvert * 60;
    return {
      minute: minuteConvert.toString().length < 2 ? `0${minuteConvert}` : minuteConvert,
      mili: miliConvert.toString().length < 2 ? `0${miliConvert}` : miliConvert,
    };
  }, [timer]);

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reNew) {
      setTimer(timeCount);
    }
  }, [reNew, timeCount]);

  useEffect(() => {
    if (!visibleTimer || loading || openConfirmRight || openConfirmWrong) return;
    interValRef.current = setInterval(() => {
      setTimer((time) => {
        const value = time - 1;
        if (value === 0) {
          clearInterval(interValRef.current);
          handleNextTab();
          setTimer(timeCount);
        }
        return value;
      });
    }, 1000) as unknown as undefined;
    return () => {
      clearInterval(interValRef.current);
    };
  }, [timeCount, visibleTimer, tabIndex, loading, openConfirmRight, openConfirmWrong]);

  useEffect(() => {
    if (!visibleTimer) return;
    setTimer(timeCount);
  }, [timeCount, visibleTimer]);

  return (
    <TrainingImplementStyled
      isPreviewVisible={isNumber(isPreviewVisible)}
      isSelect={!!valueQuestion}
    >
      <div className="header">
        <img src={LogoNav} className="image-logo" alt="skill-familiar" />
        <div className="text-header">
          <div className="title">
            {dataImplementation[tabIndex]?.required_curriculum === 1 && (
              <img src={IconCompulsory} className="icon" alt="compulsory-icon" />
            )}
            <p className="text">{dataImplementation[tabIndex]?.curriculum_name}</p>
          </div>
          <div className="ordinal">
            <ApartmentOutlined className="icon" />
            <p className="text-ordinal">
              {dataImplementation[tabIndex]?.curriculum_name}
              <RightOutlined />
              {dataImplementation[tabIndex]?.level1_name
                ? dataImplementation[tabIndex]?.level1_name
                : '空白'}
              <RightOutlined />
              {dataImplementation[tabIndex]?.level2_name
                ? dataImplementation[tabIndex].level2_name
                : '空白'}
              <RightOutlined />
              {dataImplementation[tabIndex]?.level3_name
                ? dataImplementation[tabIndex]?.level3_name
                : '空白'}
              <RightOutlined />
              {dataImplementation[tabIndex]?.level4_name
                ? dataImplementation[tabIndex]?.level4_name
                : '空白'}
              <RightOutlined />
              {dataImplementation[tabIndex]?.question_name
                ? dataImplementation[tabIndex]?.question_name
                : '空白'}
            </p>
          </div>
        </div>
        <button className="btn-header" onClick={handleSkipCurriculumImplementation}>
          終了
        </button>
      </div>
      {visibleTimer && (
        <div className="remain-time-child">
          <Progress
            width={130}
            className="number-time"
            type="circle"
            percent={(timer / timeCount) * 100}
            strokeColor={{
              '0%': '#B1EFE4',
              '100%': '#31BBC3',
            }}
            format={() => {
              return (
                <>
                  <span className="label-time">残り時間</span>
                  <br />
                  <span className="label-number">
                    {countDown?.minute.toString().padStart(2, '0')}:{countDown?.mili}
                  </span>
                </>
              );
            }}
          />
        </div>
      )}
      <div className="body">
        <p className="title">
          設問を読んで、解答選択肢から一つを選択し、Answerボタンをクリックしてください。
        </p>
        <div className="item-body">
          <Row className="wrap-answer">
            <Col span={3} className="number">
              <div className="count-number">
                <p className="count-fist">{tabIndex + 1}</p>
                <p className="count-last">
                  <span>/</span>
                  {dataImplementation.length}
                </p>
              </div>
            </Col>
            <Col span={21} className="label">
              <p className="text-label">{dataImplementation[tabIndex]?.question_name}</p>
            </Col>
          </Row>
          <Row className="wrap-item">
            <Col className="questions" span={14}>
              <p>{dataImplementation[tabIndex]?.question_description}</p>
              <div className="item-question">
                {dataImplementation[tabIndex]?.question_attach_files &&
                dataImplementation[tabIndex]?.question_attach_files?.length > 1 ? (
                  <>
                    <button className="btn-next" onClick={handlePrev}>
                      <LeftOutlined className="icon" />
                    </button>
                    <Carousel
                      beforeChange={(_currentSlide, nextSlide) => {
                        setCarouselIndex(nextSlide);
                      }}
                      className="carousel"
                      ref={slider}
                    >
                      {dataImplementation[tabIndex]?.question_attach_files.map((url, i) => (
                        <div className="wrap-item" key={i}>
                          <div className="item-image">
                            {url.includes('mp4') ? (
                              <>
                                <ReactPlayer
                                  width={350}
                                  height={'100%'}
                                  url={url}
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        disablePictureInPicture: true,
                                        controlsList: 'nodownload noplaybackrate',
                                      },
                                    },
                                  }}
                                />
                              </>
                            ) : url.includes('png') ||
                              url.includes('jpg') ||
                              url.includes('jpeg') ? (
                              <>
                                <Image className="image-attach" src={url} preview={false} />
                                <ModalPreview
                                  index={i}
                                  isPreviewVisible={isPreviewVisible}
                                  item={url}
                                  setPreviewImageAnswer={setPreviewVisible}
                                />
                                <div className="zoom-image">
                                  <ZoomInOutlined
                                    onClick={() => setPreviewVisible(carouselIndex)}
                                    className="icon-zoom"
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                    <button className="btn-next" onClick={handleNext}>
                      <RightOutlined className="icon" />
                    </button>
                  </>
                ) : dataImplementation[tabIndex]?.question_attach_files?.length ? (
                  <>
                    <div className="item-image">
                      {dataImplementation[tabIndex]?.question_attach_files[0].includes('mp4') ? (
                        <>
                          <ReactPlayer
                            width={350}
                            height={'100%'}
                            url={dataImplementation[tabIndex]?.question_attach_files[0]}
                            controls
                            config={{
                              file: {
                                attributes: {
                                  disablePictureInPicture: true,
                                  controlsList: 'nodownload noplaybackrate',
                                },
                              },
                            }}
                          />
                        </>
                      ) : dataImplementation[tabIndex]?.question_attach_files[0].includes('png') ||
                        dataImplementation[tabIndex]?.question_attach_files[0].includes('jpg') ||
                        dataImplementation[tabIndex]?.question_attach_files[0].includes('jpeg') ? (
                        <>
                          <Image
                            className="image-attach"
                            src={dataImplementation[tabIndex]?.question_attach_files[0]}
                            preview={false}
                          />
                          <div className="zoom-image">
                            <ZoomInOutlined
                              onClick={() => setPreviewVisible(0)}
                              className="icon-zoom"
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                    <ModalPreview
                      index={0}
                      isPreviewVisible={isPreviewVisible}
                      setPreviewImageAnswer={setPreviewVisible}
                      item={dataImplementation[tabIndex]?.question_attach_files[0]}
                    />
                  </>
                ) : null}
              </div>
            </Col>
            <Col className="answer" span={10}>
              <p className="title-answer"> - 解答 -</p>
              <Radio.Group onChange={onChange} value={valueQuestion} buttonStyle="solid">
                {dataAnswer.map((answer, index) => (
                  <Radio.Button
                    checked={isSelect}
                    onClick={toggleSelect}
                    value={answer}
                    key={index}
                  >
                    <div className="wrap-question">
                      <div className="text-label">
                        <p className="label-answer">{answer}</p>
                        <p className="content-answer">
                          {answer === 'A'
                            ? dataImplementation[tabIndex]?.problems1
                            : answer === 'B'
                            ? dataImplementation[tabIndex]?.problems2
                            : dataImplementation[tabIndex]?.problems3}
                        </p>
                      </div>
                      {answer === 'A' && dataImplementation[tabIndex]?.problems1_attach_file ? (
                        <div className="img-problem">
                          {dataImplementation[tabIndex]?.problems1_attach_file?.includes('png') ||
                          dataImplementation[tabIndex]?.problems1_attach_file?.includes('jpg') ||
                          dataImplementation[tabIndex]?.problems1_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={dataImplementation[tabIndex]?.problems1_attach_file}
                                alt=""
                              />
                            </div>
                          ) : dataImplementation[tabIndex]?.problems1_attach_file?.includes(
                              'mp4'
                            ) ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={350}
                                  height={'100%'}
                                  url={dataImplementation[tabIndex]?.problems1_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <ModalPreview
                            index={0}
                            isPreviewVisible={isPreviewImageAnswer}
                            item={dataImplementation[tabIndex]?.problems1_attach_file}
                            setPreviewImageAnswer={setPreviewImageAnswer}
                          />
                        </div>
                      ) : answer === 'B' && dataImplementation[tabIndex]?.problems2_attach_file ? (
                        <div className="img-problem">
                          {dataImplementation[tabIndex]?.problems2_attach_file?.includes('png') ||
                          dataImplementation[tabIndex]?.problems2_attach_file?.includes('jpg') ||
                          dataImplementation[tabIndex]?.problems2_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={dataImplementation[tabIndex]?.problems2_attach_file}
                                alt=""
                              />
                            </div>
                          ) : dataImplementation[tabIndex]?.problems2_attach_file?.includes(
                              'mp4'
                            ) ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={350}
                                  height={'100%'}
                                  url={dataImplementation[tabIndex]?.problems2_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <ModalPreview
                            index={1}
                            isPreviewVisible={isPreviewImageAnswer}
                            item={dataImplementation[tabIndex]?.problems2_attach_file}
                            setPreviewImageAnswer={setPreviewImageAnswer}
                          />
                        </div>
                      ) : answer === 'C' && dataImplementation[tabIndex]?.problems3_attach_file ? (
                        <div className="img-problem">
                          {dataImplementation[tabIndex]?.problems3_attach_file?.includes('png') ||
                          dataImplementation[tabIndex]?.problems3_attach_file?.includes('jpg') ||
                          dataImplementation[tabIndex]?.problems3_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={dataImplementation[tabIndex]?.problems3_attach_file}
                                alt=""
                              />
                            </div>
                          ) : dataImplementation[tabIndex]?.problems3_attach_file?.includes(
                              'mp4'
                            ) ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={350}
                                  height={'100%'}
                                  url={dataImplementation[tabIndex]?.problems3_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <ModalPreview
                            index={2}
                            isPreviewVisible={isPreviewImageAnswer}
                            item={dataImplementation[tabIndex]?.problems3_attach_file}
                            setPreviewImageAnswer={setPreviewImageAnswer}
                          />
                        </div>
                      ) : null}
                    </div>
                  </Radio.Button>
                ))}
              </Radio.Group>
              <div className="btn-answer-active">
                <button className="btn" disabled={!valueQuestion} onClick={handleSubmit}>
                  Answer
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <ConfirmRight
        numCorrectQuestion={dataQuestionImplement.filter((item) => item.correct).length}
        setNumAcquisitionScore={setNumAcquisitionScore}
        numAllQuestion={dataAllQuestion.length}
        numQuestion={dataImplementation.length}
        numAcquisitionScore={numAcquisitionScore}
        data={dataImplementation[tabIndex]}
        setVisible={setOpenConfirmRight}
        handleNextTab={handleNextTab}
        visible={openConfirmRight}
        setCorrect={setCorrect}
        fileAnswer={fileAnswer}
        answer={valueQuestion}
        setRenew={setRenew}
        correct={correct}
        tabIndex={tabIndex}
      />
      <ConfirmWrong
        dataQuestionImplement={dataQuestionImplement}
        numberQuestionImplement={numberQuestionImplement}
        setNumAcquisitionScore={setNumAcquisitionScore}
        dataAllQuestion={dataAllQuestion}
        dataImplementation={dataImplementation}
        numAcquisitionScore={numAcquisitionScore}
        data={dataImplementation[tabIndex]}
        setVisible={setOpenConfirmWrong}
        handleNextTab={handleNextTab}
        valueQuestion={valueQuestion}
        visible={openConfirmWrong}
        setRenew={setRenew}
        tabIndex={tabIndex}
      />
    </TrainingImplementStyled>
  );
};

export default TrainingImplement;
