import React, { useEffect, useState } from 'react';
import { UploadFile as UploadFileAntd, UploadChangeParam } from 'antd/lib/upload/interface';
import { parse, ParseResult } from 'papaparse';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import { maxBy } from 'lodash';
import { Upload } from 'antd';
import {
  CheckCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';

import { memoizedConvertFlatDataFromImportFile } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { HEADER_CURRICULUM_CSV } from 'constant/header.export.constant';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { curriculumSelector } from '../../selectors';
import { uploadCSVSchema } from 'libs/validations';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import {
  createCurriculum,
  createLevelCurriculum,
  getCurriculum,
  getDataUserSetting,
} from '../../thunk';
import {
  createLinkQuestionAssignLevel,
  createQuestion,
  executeActionUploadFile,
  getOptionLimit,
} from 'containers/CreateEditQuestion/thunk';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const UploadCSV: React.FC<Props> = ({ visible, setVisible }) => {
  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);
  const [visibleError, setVisibleError] = useState<boolean>(false);
  const [file, setFile] = useState<File>();

  const { filter_conditions, curricullum } = useSelector(curriculumSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const onClose = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    setVisibleError(false);
    setFile(undefined);
  };

  const handleSubmit = () => {
    if (file && file.type === 'text/csv') {
      parse(file, {
        complete: async ({ data }: ParseResult<string>) => {
          dispatch(startLoading());
          const resultsDataCSV = data
            .map((item) =>
              Object.fromEntries(HEADER_CURRICULUM_CSV.map(({ key }, i) => [key, item[i]]))
            )
            .splice(1);
          const isValid = await uploadCSVSchema.isValid(resultsDataCSV);
          let time_limit: Types.TimeLimitType[] = [];
          const resultActionGetOptionLimit = await dispatch(getOptionLimit());
          if (getOptionLimit.fulfilled.match(resultActionGetOptionLimit)) {
            time_limit = resultActionGetOptionLimit.payload.fields[
              '65598664baeaf8d6328c95f3'
            ]?.options?.filter((o: Types.TimeLimitType) => o.enabled);
          }
          if (!isValid) {
            setVisibleError(true);
          } else {
            setVisibleError(false);
            const curriculumMaxOrder = maxBy(curricullum, 'sort_order')?.sort_order || 0;
            const newCurriculum: Array<Types.ImportCurriculumType> = [];
            resultsDataCSV.forEach((item) => {
              const itemIndex = newCurriculum.findIndex((c) => c.code === item.curriculum_code);
              if (itemIndex >= 0) {
                newCurriculum[itemIndex] = memoizedConvertFlatDataFromImportFile({
                  treeData: newCurriculum[itemIndex],
                  item,
                });
              } else {
                newCurriculum.push({
                  code: item.curriculum_code,
                  name: item.curriculum_name,
                  description: item.curriculum_description,
                  level: 0,
                  fileID: item.fileID,
                  children: [
                    {
                      name: item.level1_name,
                      code: item.level1_code,
                      level: 1,
                      children: item.level2_code
                        ? [
                            {
                              name: item.level2_name,
                              code: item.level2_code,
                              level: 2,
                              children: item.level3_code
                                ? [
                                    {
                                      name: item.level3_name,
                                      code: item.level3_code,
                                      level: 3,
                                      children: item.level4_code
                                        ? [
                                            {
                                              name: item.level4_name,
                                              code: item.level4_code,
                                              level: 4,
                                              children: item.question_code
                                                ? [
                                                    {
                                                      code: item.question_code,
                                                      name: item.question_name,
                                                      description: item.question_description,
                                                      problems1: item.problems1,
                                                      problems2: item.problems2,
                                                      problems3: item.problems3,
                                                      answer: item.answer,
                                                      comment: item.comment,
                                                      time_limit: item.time_limit,
                                                      score: Number(item.score),
                                                      problems1_attach_fileID:
                                                        item.problems1_attach_fileID,
                                                      problems2_attach_fileID:
                                                        item.problems2_attach_fileID,
                                                      problems3_attach_fileID:
                                                        item.problems3_attach_fileID,
                                                      problems1_attach: item.problems1_attach,
                                                      problems2_attach: item.problems2_attach,
                                                      problems3_attach: item.problems3_attach,
                                                      question_attach: item.question_attach,
                                                      question2_attach: item.question2_attach,
                                                      question3_attach: item.question3_attach,
                                                      level: 5,
                                                    },
                                                  ]
                                                : [],
                                            },
                                          ]
                                        : [],
                                    },
                                  ]
                                : [],
                            },
                          ]
                        : [],
                    },
                  ],
                });
              }
            });
            await Promise.all(
              newCurriculum.map(async (curriculum, curriculum_index) => {
                const resultActionCreateCurriculum = await dispatch(
                  createCurriculum({
                    item: {
                      company_id: userInfo?.company_id,
                      name: curriculum.name,
                      description: curriculum.description,
                      sort_order: curriculumMaxOrder
                        ? curriculumMaxOrder + curriculum_index + 1
                        : curriculum_index,
                      publish: 0,
                      probs_count: 0,
                      required_curriculum: 0,
                      createdat: new Date(),
                      createdby: userInfo?.login_id,
                      creator: userInfo?.login_id,
                      fileID: curriculum?.fileID,
                      official_curriculum: 0,
                    },
                    return_item_result: true,
                  })
                );
                if (
                  curriculum.children &&
                  createCurriculum.fulfilled.match(resultActionCreateCurriculum) &&
                  resultActionCreateCurriculum.payload.item['code']
                ) {
                  await Promise.all(
                    curriculum.children.map(async (level_1, level1_index) => {
                      const resultActionLevel1 = await dispatch(
                        createLevelCurriculum({
                          level: 1,
                          item: {
                            company_id: userInfo?.company_id,
                            name: level_1.name,
                            sort_order: level1_index,
                            curricullum_code: resultActionCreateCurriculum.payload.item['code'],
                          },
                          return_item_result: true,
                        })
                      );
                      if (
                        level_1.children &&
                        createLevelCurriculum.fulfilled.match(resultActionLevel1) &&
                        resultActionLevel1.payload.item['code']
                      ) {
                        await Promise.all(
                          level_1.children.map(async (level_2, level2_index) => {
                            const resultActionLevel2 = await dispatch(
                              createLevelCurriculum({
                                level: 2,
                                item: {
                                  company_id: userInfo?.company_id,
                                  name: level_2.name,
                                  sort_order: level2_index,
                                  level1_code: resultActionLevel1.payload.item['code'],
                                },
                                return_item_result: true,
                              })
                            );
                            if (
                              level_2.children &&
                              createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
                              resultActionLevel2.payload.item['code']
                            ) {
                              await Promise.all(
                                level_2.children.map(async (level_3, level3_index) => {
                                  const resultActionLevel3 = await dispatch(
                                    createLevelCurriculum({
                                      level: 3,
                                      item: {
                                        company_id: userInfo?.company_id,
                                        name: level_3.name,
                                        sort_order: level3_index,
                                        level2_code: resultActionLevel2.payload.item['code'],
                                      },
                                      return_item_result: true,
                                    })
                                  );
                                  if (
                                    level_3.children &&
                                    createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
                                    resultActionLevel3.payload.item['code']
                                  ) {
                                    await Promise.all(
                                      level_3.children.map(async (level_4, level4_index) => {
                                        const resultActionLevel4 = await dispatch(
                                          createLevelCurriculum({
                                            level: 4,
                                            item: {
                                              company_id: userInfo?.company_id,
                                              name: level_4.name,
                                              sort_order: level4_index,
                                              level3_code: resultActionLevel3.payload.item['code'],
                                            },
                                            return_item_result: true,
                                          })
                                        );
                                        if (
                                          level_4.children &&
                                          createLevelCurriculum.fulfilled.match(resultActionLevel4)
                                        ) {
                                          await Promise.all(
                                            level_4.children.map(
                                              async (question, question_index) => {
                                                const resultCreateQuestion = await dispatch(
                                                  createQuestion({
                                                    item: {
                                                      name: question.name,
                                                      description: question.description,
                                                      problems1: question.problems1,
                                                      problems2: question.problems2,
                                                      problems3: question.problems3,
                                                      answer: question.answer,
                                                      comment: question.comment,
                                                      time_limit: time_limit.find(
                                                        (v) => v.value === question.time_limit
                                                      )?.option_id,
                                                      score: question.score,
                                                      creator: userInfo?.name,
                                                      company_id: userInfo?.company_id,
                                                      problems1_attach_fileID:
                                                        question.problems1_attach_fileID,
                                                      problems2_attach_fileID:
                                                        question.problems2_attach_fileID,
                                                      problems3_attach_fileID:
                                                        question.problems3_attach_fileID,
                                                      createdat: new Date(),
                                                      createdby: userInfo?.login_id,
                                                    },
                                                    return_item_result: true,
                                                  })
                                                );
                                                if (
                                                  createQuestion.fulfilled.match(
                                                    resultCreateQuestion
                                                  )
                                                ) {
                                                  await Promise.all([
                                                    dispatch(
                                                      createLinkQuestionAssignLevel({
                                                        item: {
                                                          company_id: userInfo?.company_id,
                                                          level4_code:
                                                            resultActionLevel4.payload.item['code'],
                                                          code: resultCreateQuestion.payload.item[
                                                            'code'
                                                          ],
                                                          createdat: new Date(),
                                                          createdby: userInfo?.login_id,
                                                        },
                                                      })
                                                    ),
                                                    dispatch(
                                                      executeActionUploadFile({
                                                        itemId:
                                                          resultCreateQuestion.payload.item_id,
                                                        data: {
                                                          changes: [
                                                            {
                                                              id: 'attach1',
                                                              value: [
                                                                question.question_attach || '',
                                                              ],
                                                            },
                                                            {
                                                              id: 'attach2',
                                                              value: [
                                                                question.question2_attach || '',
                                                              ],
                                                            },
                                                            {
                                                              id: 'attach3',
                                                              value: [
                                                                question.question3_attach || '',
                                                              ],
                                                            },
                                                            {
                                                              id: 'problems1_attach',
                                                              value: [
                                                                question.problems1_attach || '',
                                                              ],
                                                            },
                                                            {
                                                              id: 'problems2_attach',
                                                              value: [
                                                                question.problems2_attach || '',
                                                              ],
                                                            },
                                                            {
                                                              id: 'problems3_attach',
                                                              value: [
                                                                question.problems3_attach || '',
                                                              ],
                                                            },
                                                          ],
                                                          is_force_update: true,
                                                        },
                                                      })
                                                    ),
                                                  ]);
                                                }
                                              }
                                            )
                                          );
                                        }
                                      })
                                    );
                                  }
                                })
                              );
                            }
                          })
                        );
                      }
                    })
                  );
                }
              })
            );
            await Promise.all([
              dispatch(
                getDataUserSetting({
                  include_lookups: true,
                  include_item_ref: true,
                  conditions: [
                    ...filter_conditions.conditions,
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                      exact_match: true,
                    },
                    {
                      id: 'required_curriculum',
                      search_value: ['1'],
                      exact_match: true,
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              ),
              dispatch(
                getCurriculum({
                  conditions: [
                    ...filter_conditions.conditions,
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              ),
            ]);
            setIsSuccessfully(true);
          }
          dispatch(stopLoading());
        },
      });
    }
  };

  const handleExportCSV = () => {
    const csvString = [HEADER_CURRICULUM_CSV.map(({ label }) => label)]
      .map((e) => e.join(','))
      .join('\n');
    const bom = '\uFEFF';
    const fileExport = new Blob([bom, csvString], { type: 'application/octet-stream' });
    saveAs(fileExport, 'curriculum_demo_update_file.csv');
  };

  useEffect(() => {
    return () => {
      setIsSuccessfully(false);
    };
  }, []);

  return (
    <Modal
      title="カリキュラムマスタ インポート"
      width={720}
      visible={visible}
      okButton={
        file && !isSuccessfully
          ? {
              text: 'インポート',
              onClick: handleSubmit,
              loading,
            }
          : undefined
      }
      cancelButton={{
        text: isSuccessfully ? 'OK' : 'キャンセル',
        style: isSuccessfully ? { padding: '9px 50px' } : undefined,
        onClick: onClose,
      }}
      onCancel={onClose}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {!isSuccessfully ? (
          <div className="form-upload">
            <div className="form-upload-border">
              <div className="file-upload">
                <div className="flex">
                  <Dragger
                    accept=".csv"
                    beforeUpload={() => false}
                    onChange={(info: UploadChangeParam<UploadFileAntd<File>>) => {
                      setFile(info.file as unknown as File);
                    }}
                  >
                    {file ? (
                      <div className="info-file">
                        <p className="name-file">{file.name} </p>
                      </div>
                    ) : (
                      <>
                        <CloudUploadOutlined className="icon" />
                        <p className="ant-upload-text">
                          インポートするCSVファイルをここにドロップ
                          <br />
                          または
                        </p>
                      </>
                    )}
                    <div className="wrap-button-upload">
                      <button type="button" className="btn-upload">
                        ファイルを選択
                      </button>
                      {file && (
                        <button
                          className="btn-delete"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                          }}
                        >
                          <DeleteOutlined className="icon-delete-outlined" />
                          <span className="text-delete-outlined">ファイルを削除</span>
                        </button>
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
            <p className="text-download-template" onClick={handleExportCSV}>
              <FileOutlined className="icon" />
              テンプレートをダウンロード
            </p>
            {visibleError && (
              <div className="error-message">
                <div className="error-message-title">
                  <p className="text-title">
                    <ExclamationCircleOutlined className="icon" />
                    インポートに失敗しました
                  </p>
                  <p className="text-subtitle">
                    エラー項目をご確認のうえ、修正して再度実行してください。
                  </p>
                </div>
                <div className="error-message-content">
                  <ul>
                    <li className="text-content">１行目 カラム数が不正です。 </li>
                    <li className="text-content">２行目 カラム名が不正です。 </li>
                    <li className="text-content">２行目 カリキュラム名称は必須項目です。 </li>
                    <li className="text-content">３行目 制限時間は半角英数で入力してください。</li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-successful">
            <CheckCircleOutlined className="icon" />
            インポートが完了しました！
          </p>
        )}
      </SectionStyled>
    </Modal>
  );
};

export default UploadCSV;
