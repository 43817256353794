import React, { useEffect, useRef, useState } from 'react';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import PopupCreateAffiliation from 'pages/Settings/AffiliationMaster/Modal/Create';
import { getChildrenItemIDFromTree } from 'libs/utils/affiliation/tree-data-utils';
import AffiliationDetail from 'containers/Affiliation/AffiliationDetail';
import { startLoading, stopLoading } from '../../AppSettings/slice';
import { useAppDispatch, usePermission } from 'hooks';
import { authSelector } from '../../Auth/selectors';
import { Wrapper } from './styles';
import { Modal } from 'components';
import * as Types from 'types';
import {
  editLinkAffiliationLevel,
  editLinkAffiliationRole,
  getAffiliations,
  updateAffiliationLevel,
} from 'pages/Settings/AffiliationMaster/thunk';
import { maxBy } from 'lodash';

export const NodeRenderer: React.FC<{
  nodeHeight: number;
  maxSortOrder: number;
  isFromCreated?: boolean;
  node: Types.TreeItem<Types.AffiliationItemType>;
  onDrop?: (name: string, node: Types.AffiliationItemType, maxSortOrder: number) => void;
}> = ({ node, onDrop, nodeHeight, isFromCreated, maxSortOrder }) => {
  const [showPopupCreateAffiliation, setShowPopupCreateAffiliation] = useState<boolean>(false);
  const [isOpenDetail, setOpenDetail] = useState<boolean>(false);
  const [lineClass, setLineClass] = useState<string>('');
  const [isHover, setIsHover] = useState<boolean>(false);
  const [width, setWidth] = useState<number>();

  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const [{ opacity }, drag, preview] = useDrag({
    item: {
      type: 'move-affiliation-master',
      node,
      height: nodeHeight,
      width: width,
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropAffiliation] = useDrop<
    Types.TreeItem<Types.AffiliationItemType> & { type: string },
    void,
    void
  >({
    accept: 'move-affiliation-master',
    canDrop: (item) =>
      item.node?.node?.affiliation_parent_id !== undefined &&
      item.node?.node?.affiliation_id !== node.node?.affiliation_id &&
      !node?.node?.affiliation_id_root?.includes(item.node?.node?.affiliation_id),
    drop: (item) => {
      (async () => {
        dispatch(startLoading());
        const childNodes = getChildrenItemIDFromTree({ treeData: item.node?.node! });
        const dropResult = await Promise.all([
          ...childNodes.map((e, index) =>
            dispatch(
              updateAffiliationLevel({
                id: e.affiliation_i_id!,
                data: {
                  item: {
                    affiliation_parent_id:
                      index !== 0 ? e.affiliation_parent_id : node.node?.affiliation_id,
                    level: (node!.columnIndex || 0) + index + 1 + 1,
                    sort_order: !index
                      ? (maxBy(node.node?.children, (o) => o.sort_order)?.sort_order || 0) + 1
                      : index,
                  },
                  is_force_update: true,
                  return_item_result: true,
                  use_display_id: true,
                },
              })
            )
          ),
        ]);
        if (updateAffiliationLevel.fulfilled.match(dropResult[dropResult.length - 1])) {
          await dispatch(
            getAffiliations({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          );
        }
        dispatch(stopLoading());
      })();
    },
  });

  const [, drop] = useDrop<{ type: string; name: string }, void, void>({
    accept: 'affiliation',
    drop: (item) => {
      if (item.name && node.node && onDrop) {
        onDrop(item.name, node.node, maxSortOrder);
      }
    },
  });

  const [, dropUserDepartment] = useDrop<
    { type: string; user: Types.UserAffiliation; affiliation_id: string },
    void,
    void
  >({
    accept: 'user-department',
    canDrop: (item) => Boolean(node.node && item.affiliation_id !== node.node.affiliation_i_id),
    drop: (item) => {
      (async () => {
        if (!item.user?.item_ref || !node.node) return;
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            editLinkAffiliationLevel({
              id: item.user.item_ref.user_sort_order.i_id,
              data: {
                item: {
                  affiliation_id: node.node.affiliation_id,
                },
                is_force_update: true,
              },
            })
          ),
          dispatch(
            editLinkAffiliationRole({
              id: item.user.item_ref.user_role_order.i_id,
              data: {
                item: {
                  affiliation_id: node.node.affiliation_i_id,
                },
                is_force_update: true,
              },
            })
          ),
        ]);
        await dispatch(
          getAffiliations({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        );
        dispatch(stopLoading());
      })();
    },
  });

  drop(ref);
  dropUserDepartment(ref);
  drag(dropAffiliation(ref));

  useEffect(() => {
    if (node.columnIndex && node.columnIndex > 0) {
      if (node.lineIndex === node.parentNode?.lineIndex) {
        setLineClass('rst__lineHalfHorizontalLeft');
      } else {
        setLineClass('rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop');
      }
    }
  }, [node.columnIndex, node.lineIndex, node.parentNode?.lineIndex]);

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
    }
    return () => {
      setWidth(undefined);
    };
  }, [ref]);

  return (
    <Wrapper nodeHeight={nodeHeight} node={node}>
      <div className="rst__nodeContent">
        <div className={'rst__lineBlock ' + lineClass}>
          <div
            ref={ref}
            className="rowWrapper"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            style={{ opacity }}
          >
            <div className="row">
              <div className="rowContents" onClick={() => setOpenDetail(true)}>
                <div className="top-side">
                  <p>{node.node?.name}</p>
                </div>
                {!isFromCreated && (
                  <div className="bottom-side">
                    {node.node?.user_children && (
                      <span className="people-quantity">
                        {node.node.user_children.length}
                        <small>人</small>
                      </span>
                    )}
                    <DownOutlined className="arrow-icon" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isHover && permissionNumber !== 1 && (
        <div
          className="create-button"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Button
            htmlType="button"
            icon={<PlusOutlined className="size-icon" />}
            onClick={() => setShowPopupCreateAffiliation(true)}
          />
        </div>
      )}
      <Modal
        visible={isOpenDetail}
        width="80%"
        mask={false}
        contentStyle={{
          background: 'transparent',
          boxShadow: 'unset',
        }}
        bodyStyle={{
          padding: 0,
        }}
        onCancel={() => setOpenDetail(false)}
        maskClosable={true}
      >
        <AffiliationDetail data={node.node!} setOpenDetail={setOpenDetail} />
      </Modal>
      <PopupCreateAffiliation
        visible={showPopupCreateAffiliation}
        setVisible={setShowPopupCreateAffiliation}
        onSubmit={(name) => onDrop && onDrop(name, node.node!, maxSortOrder)}
      />
    </Wrapper>
  );
};
