import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Select, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import {
  CloudDownloadOutlined,
  CaretDownOutlined,
  BarChartOutlined,
  CaretUpOutlined,
  TableOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { resetInitialState, sortDataReportSkillCheck } from './slice';
import { skillCheckReportPageSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { OrderByType, UserType } from 'types/config';
import IndividualTable from './IndividualTable';
import { getReportSkillCheck } from './thunk';
import exportPDF from 'libs/utils/exportPdf';
import FilePDFExport from './FilePDFExport';
import SurfaceTable from './SurfaceTable';
import { useAppDispatch } from 'hooks';
import { ConditionsType } from 'types';
import Wrapper from './styles';
import Chart from './Chart';
import {
  ITEM_COMPARE_BAR_CHART,
  ITEM_COMPARE_PIE_CHART,
  REPORT_TYPE,
  ITEM_GRAPH,
} from 'constant/select.constants';

const { Option } = Select;

interface Props {
  userType: UserType;
  reportType: (typeof REPORT_TYPE)[number]['value'];
}

const SkillCheck: React.FC<Props> = ({ userType, reportType }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [itemGraph, setItemGraph] = useState<(typeof ITEM_GRAPH)[number]['value']>();
  const [sortByCorrect, setSortByCorrect] = useState<OrderByType>('asc');
  const [questionDifficulty, setQuestionDifficulty] = useState<string>();
  const [implementDate, setImplementDate] = useState<boolean>(false);
  const [answerResult, setAnswerResult] = useState<string>();
  const [skillCheck, setSkillCheck] = useState<string>();
  const [segmented, setSegmented] = useState<number>(0);
  const [loginID, setLoginID] = useState<string>();
  const [itemComparision, setItemComparision] = useState<
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value']
  >();

  const ref = useRef(null);

  const { userInfo } = useSelector(authSelector);
  const { selectSkillCheck, selectEmployeeUser, selectInterviewUser } = useSelector(
    skillCheckReportPageSelector
  );

  const dispatch = useAppDispatch();

  const isQuestionCodeTable = useMemo(
    () =>
      (!!loginID && !!skillCheck && loginID === 'ALL' && skillCheck !== 'ALL') ||
      !loginID ||
      !skillCheck,
    [skillCheck, loginID]
  );

  const individualActive = useMemo(
    () => !!loginID && !!skillCheck && loginID !== 'ALL' && skillCheck !== 'ALL',
    [skillCheck, loginID]
  );

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          individualActive={individualActive}
          reportType={reportType}
          userType={userType}
          loginID={loginID}
          selectSkillCheck={selectSkillCheck}
          skillCheck={skillCheck}
          selectInterviewUser={selectInterviewUser}
          selectEmployeeUser={selectEmployeeUser}
          implementDate={implementDate}
          answerResult={answerResult}
          questionDifficulty={questionDifficulty}
          segmented={segmented}
          isQuestionCodeTable={isQuestionCodeTable}
          itemGraph={itemGraph}
          itemComparision={itemComparision}
          sortByCorrect={sortByCorrect}
        />
      </div>
    );
  }, [
    answerResult,
    implementDate,
    individualActive,
    isQuestionCodeTable,
    itemComparision,
    itemGraph,
    loginID,
    questionDifficulty,
    reportType,
    segmented,
    selectEmployeeUser,
    selectInterviewUser,
    selectSkillCheck,
    skillCheck,
    sortByCorrect,
    userType,
  ]);

  const handleExportCSV = (value: string) => {
    dispatch(startLoading());
    if (value === 'pdf') {
      if (!ref.current) return;

      exportPDF(ref, 'スキルチェックレポート.pdf');
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (!userInfo || !loginID || !skillCheck) return;
    (async () => {
      dispatch(startLoading());
      const conditions: ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
        {
          id: 'user_type',
          search_value: [userType],
        },
      ];

      if (loginID && loginID !== 'ALL') {
        conditions.push({
          id: 'login_id',
          search_value: [loginID],
          exact_match: true,
        });
      }
      if (skillCheck && skillCheck !== 'ALL') {
        conditions.push({
          id: 'skill_check_code',
          search_value: [skillCheck],
        });
      }
      if (implementDate) {
        conditions.push({
          id: 'implementation_date',
          search_value: [],
          not_match: true,
        });
      }

      if (individualActive && questionDifficulty) {
        conditions.push({
          id: 'implementation_date',
          search_value: [questionDifficulty],
        });
      }
      if (individualActive && answerResult) {
        conditions.push({
          id: 'implementation_date',
          search_value: [answerResult],
        });
      }

      await dispatch(
        getReportSkillCheck({
          conditions,
          page: 1,
          per_page: 0,
          isQuestionCodeTable: individualActive ? false : isQuestionCodeTable,
          include_item_ref: true,
          sort_fields: [
            {
              id: 'question_name',
              order: 'desc',
            },
          ],
        })
      );
      dispatch(stopLoading());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfo, loginID, skillCheck, implementDate, individualActive]);

  useEffect(() => {
    setImplementDate(false);
    setSkillCheck(undefined);
    setLoginID(undefined);
    setQuestionDifficulty(undefined);
    setAnswerResult(undefined);
    dispatch(resetInitialState());
    setSegmented(0);
    return () => {
      setImplementDate(false);
      setSkillCheck(undefined);
      setLoginID(undefined);
      setQuestionDifficulty(undefined);
      setAnswerResult(undefined);
      dispatch(resetInitialState());
      setSegmented(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <Wrapper individualActive={individualActive}>
      {component}
      <div className="header-container">
        <span className="title">
          スキルチェック【社内】の実施結果を、スキルチェック・設問を基準に集計したレポートです。
        </span>
        <Button
          className="button-export"
          onClick={() => setShowConfirmExportFileModal(true)}
          icon={<CloudDownloadOutlined />}
        >
          エクスポート
        </Button>
      </div>
      <div className="wrap-filter">
        <div className="aggregation-wrap">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <div className="form-select">
              <div className="item">
                <span className="text-label">スキルチェック</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  onSelect={(value: string) => {
                    if (value === 'ALL') {
                      setQuestionDifficulty(undefined);
                      setAnswerResult(undefined);
                      setItemComparision(segmented === 1 ? 'progress_rate' : undefined);
                      if (loginID === 'ALL') {
                        setLoginID(undefined);
                      }
                    }
                    setItemComparision(undefined);
                    setSkillCheck(value);
                  }}
                >
                  <Option disabled={loginID === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {selectSkillCheck.map((item, index) => (
                    <Option key={index} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">ユーザー</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  onSelect={(value: string) => {
                    if (value === 'ALL') {
                      setQuestionDifficulty(undefined);
                      setAnswerResult(undefined);
                      if (skillCheck === 'ALL') {
                        setSkillCheck(undefined);
                      }
                    }
                    setLoginID(value);
                  }}
                >
                  <Option disabled={skillCheck === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                    (item, index) => (
                      <Option key={index} value={item.login_id}>
                        {item.name}
                      </Option>
                    )
                  )}
                </Select>
              </div>
              {skillCheck === 'ALL' && (
                <div className="item">
                  <Checkbox
                    disabled
                    checked={implementDate}
                    onChange={(e) => setImplementDate(e.target.checked)}
                  >
                    未実施者を表示する
                  </Checkbox>
                </div>
              )}
            </div>
          </div>
        </div>
        {individualActive && (
          <div className="question-wrap">
            <span className="label">絞込条件</span>
            <div className="question-conditions">
              <div className="form-select">
                <div className="answer-result item">
                  <span className="text-label">解答結果</span>
                  <Select
                    className="select"
                    placeholder="指定なし"
                    value={answerResult}
                    onSelect={setAnswerResult}
                    onClear={() => setAnswerResult(undefined)}
                    allowClear
                  >
                    <Option value={'0'}>不正解</Option>
                    <Option value={'1'}>正解</Option>
                  </Select>
                </div>
                <div className="question-difficulty item">
                  <span className="text-label">設問難易度</span>
                  <Select
                    className="select"
                    placeholder="指定なし"
                    value={questionDifficulty}
                    onSelect={setQuestionDifficulty}
                    onClear={() => setQuestionDifficulty(undefined)}
                    allowClear
                  >
                    <Option value={'1'}>1</Option>
                    <Option value={'2'}>2</Option>
                    <Option value={'3'}>3</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="sub-container">
        {individualActive ? (
          <div className="individual-title">
            <span className="title">
              ※選択したユーザー・スキルチェックの設問別実施結果が表示されています。
            </span>
          </div>
        ) : (
          <div className="wrap-segmented">
            <span className="label">レポートタイプ：</span>
            <div className="segmented">
              <div
                className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                onClick={() => {
                  setSegmented(0);
                  setItemComparision(undefined);
                }}
              >
                <TableOutlined className="icon" />
                <span>表</span>
              </div>
              <div
                className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                onClick={() => {
                  setSegmented(1);
                  setItemGraph('bar_chart');
                  setItemComparision('correct_answer_rate');
                }}
              >
                <BarChartOutlined className="icon" />
                <span>グラフ</span>
              </div>
            </div>
            {segmented && !isQuestionCodeTable ? (
              <>
                <span className="cross-boiled">/</span>
                <div className="wrapper-options">
                  <div className="wrapper-option">
                    <span className="label">グラフ：</span>
                    <div className="item">
                      <Select
                        className="select"
                        placeholder="---"
                        onSelect={(value) => {
                          setItemGraph(value);
                          setItemComparision(
                            value === 'bar_chart' ? 'correct_answer_rate' : 'solution_ratio'
                          );
                        }}
                        value={itemGraph}
                      >
                        {ITEM_GRAPH.map(({ label, value }, index) => (
                          <Option key={index} value={value}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="wrapper-option">
                    <span className="label">比較項目：</span>
                    <div className="item">
                      <Select
                        className="select"
                        placeholder="---"
                        onSelect={setItemComparision}
                        value={itemComparision}
                      >
                        {(itemGraph === 'bar_chart'
                          ? ITEM_COMPARE_BAR_CHART
                          : ITEM_COMPARE_PIE_CHART
                        ).map(({ label, value }, index) => (
                          <Option key={index} value={value}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {(itemComparision === 'correct_answer_rate' ||
                      itemComparision === 'progress_rate') && (
                      <Button
                        className="btn-sort"
                        onClick={() => {
                          setSortByCorrect((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                          dispatch(
                            sortDataReportSkillCheck({
                              sort_field:
                                itemComparision === 'correct_answer_rate'
                                  ? 'accuracy_rate'
                                  : 'progress_rate',
                              sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                            })
                          );
                        }}
                      >
                        <div className="wrap-sort">
                          <CaretUpOutlined
                            className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
                          />
                          <CaretDownOutlined
                            className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
                          />
                        </div>
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        )}
        {individualActive ? (
          <IndividualTable
            segmented={segmented}
            skillCheck={skillCheck}
            userType={userType}
            loginID={loginID}
            questionDifficulty={questionDifficulty}
            answerResult={answerResult}
          />
        ) : !segmented ? (
          <SurfaceTable isQuestionCodeTable={isQuestionCodeTable} />
        ) : (
          <Chart
            isQuestionCodeTable={isQuestionCodeTable}
            itemComparision={itemComparision}
            itemGraph={itemGraph}
          />
        )}
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </Wrapper>
  );
};

export default SkillCheck;
