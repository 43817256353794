import React, { HTMLAttributes, useEffect, useState } from 'react';
import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';
import { Avatar, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import SendNoticeComplete from '../Modal/SendNoticeComplete';
import { sharedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import ChangePassword from 'containers/ChangePassword';
import { HeaderStyled, ModalContent } from './styles';
import ChangeEmail from 'containers/ChangeEmail';
import { logout } from 'containers/Auth/slice';
import { resetAllInitialState } from 'store';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import Modal from 'components/Modal';

interface Props {
  title: string;
  exportPdf?: boolean;
}

const HeaderDashboard: React.FC<HTMLAttributes<HTMLDivElement> & Props> = ({
  title,
  children,
  exportPdf,
  className,
}) => {
  const [visibleChangePassword, setVisibleChangePassword] = useState<boolean>(false);
  const [visibleChangeEmail, setVisibleChangeEmail] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [changedEmail, setChangedEmail] = useState<boolean>(false);
  const [presignedUrl, setPresignedUrl] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleShare(userInfo?.icon_fileID || '');
  }, [userInfo?.icon_fileID]);

  const handleShare = async (fileName: string) => {
    try {
      const url = await sharedFileInMinIO(fileName);
      setPresignedUrl(url);
    } catch (error) {
      console.error('Error sharing file:', error);
    }
  };

  return (
    <HeaderStyled collapsedMenu={collapsedMenu} className={className}>
      <div className="header__left">
        <p className="page__title">{title}</p>
      </div>
      {children}
      {!exportPdf && (
        <div className="header__right">
          {userInfo?.user_type !== 'interview' && (
            <>
              <Dropdown
                overlay={
                  <Menu
                    items={[
                      {
                        key: '0',
                        label: 'ヘルプセンター',
                        onClick: () => {
                          navigate('https://www.google.com');
                        },
                      },
                    ]}
                  />
                }
                placement="bottomRight"
              >
                <QuestionCircleOutlined className="imageQA" />
              </Dropdown>
              <div className="border" />
            </>
          )}
          <Dropdown
            overlay={
              <>
                {userInfo?.user_type !== 'interview' && (
                  <Menu
                    items={[
                      {
                        key: 'profile',
                        label: 'プロフィール',
                        onClick: () => {
                          navigate(generatePath(routes.Profile.path, { entity: 'receiving' }));
                        },
                      },
                      {
                        key: 'reset-email',
                        label: 'メールアドレス変更',
                        onClick: () => {
                          setVisibleChangeEmail(true);
                          setChangedEmail(true);
                        },
                      },
                      {
                        key: 'reset-password',
                        label: 'パスワード変更',
                        onClick: () => {
                          setVisibleChangePassword(true);
                          setChangedEmail(false);
                        },
                      },
                      {
                        type: 'divider',
                      },
                      {
                        key: 'logout',
                        label: 'ログアウト',
                        onClick: () => setVisible(true),
                      },
                    ]}
                  />
                )}
              </>
            }
            placement="bottomRight"
            className="drop-down"
          >
            <div className="flex">
              <Avatar
                size={{ md: 36, lg: 36 }}
                gap={0}
                src={
                  userInfo?.avatar?.originFileObj
                    ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
                    : presignedUrl
                }
                alt="avatar"
              />
              <div className="drop-down">
                <p>{userInfo?.name}</p>
                <small>さん</small>
                <DownOutlined />
              </div>
            </div>
          </Dropdown>
        </div>
      )}

      <Modal
        title="ログアウト"
        visible={visible}
        width={416}
        closable
        okButton={{
          text: 'OK',
          onClick: () => {
            dispatch(logout());
            dispatch(resetAllInitialState());
            setVisible(false);
          },
        }}
        onCancel={() => setVisible(false)}
      >
        <ModalContent>
          <p className="text-content">ログアウトを実行します。よろしいですか？</p>
        </ModalContent>
      </Modal>
      <ChangeEmail
        visible={visibleChangeEmail}
        setVisible={setVisibleChangeEmail}
        setVisiblelComplete={setVisibleComplete}
      />
      <ChangePassword
        visible={visibleChangePassword}
        setVisible={setVisibleChangePassword}
        setVisiblelComplete={setVisibleComplete}
      />
      <SendNoticeComplete
        title={changedEmail ? 'メールアドレス変更' : 'パスワード変更'}
        changedEmail={changedEmail}
        content={
          changedEmail ? (
            <div className="content">
              <span> sample@mail.address 宛にメールアドレス変更のご案内を送信しました。</span>
              <br />
              メールに記載されているURLにアクセスして、
              <br /> メールアドレス変更を完了してください。
            </div>
          ) : (
            <span className="content">
              パスワードを変更しました。自動的にログアウトしますので、
              <br />
              新しいパスワードで再度ログインしてください。
            </span>
          )
        }
        visible={visibleComplete}
        setVisible={setVisibleComplete}
        onSubmit={() => {
          if (!changedEmail) {
            dispatch(logout());
          }
        }}
      />
    </HeaderStyled>
  );
};

export default HeaderDashboard;
