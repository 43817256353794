import React, { useState } from 'react';
import { Popover } from 'antd';

import { IconLocked, IconPublish, IconEmployee, IconInterview, IconEdit } from 'assets';
import TooltipQuestionSetting from '../Tooltip';
import { SkillCheckTreeViewType } from 'types';
import { Wrapper } from './styles';

const NodeRenderer: React.FC<{
  data: SkillCheckTreeViewType;
  itemSelected: boolean;
  fetchDataSkillCheck: () => void;
  fetchDataUserSkillCheck: () => void;
  handleSwitch: (value: boolean) => void;
}> = ({ data, handleSwitch, itemSelected, fetchDataSkillCheck, fetchDataUserSkillCheck }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Wrapper>
      <Popover
        overlayStyle={{
          width: 340,
        }}
        placement="bottomLeft"
        content={
          <TooltipQuestionSetting
            data={data}
            setVisible={setVisible}
            itemSelected={itemSelected}
            handleSwitch={handleSwitch}
            fetchDataSkillCheck={fetchDataSkillCheck}
            fetchDataUserSkillCheck={fetchDataUserSkillCheck}
          />
        }
        trigger="click"
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
        onOpenChange={setVisible}
        open={visible}
      >
        <div className="row">
          <div className="box_icon">
            {data?.publish ? (
              <img src={IconPublish} className="icon" alt="publish-icon" />
            ) : (
              <img src={IconLocked} className="icon" alt="locked-icon" />
            )}
            {data?.user_type === 'member' ? (
              <img src={IconEmployee} className="icon" alt="employee-icon" />
            ) : (
              <img src={IconInterview} className="icon" alt="interview-icon" />
            )}
            {data?.implementation_settings === 2 && (
              <img src={IconEdit} className="icon" alt="edit-icon" />
            )}
          </div>
          <span className="rowTitle">{data?.skill_check_name || '（空白）'}</span>
        </div>
      </Popover>
    </Wrapper>
  );
};

export default NodeRenderer;
