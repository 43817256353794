import React from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { CreateRolesMasterFormik } from 'types';
import RolesMasterFormStyled from './styles';
import { TextArea } from 'components';
import Item from './Item';

interface Prop {
  formik: FormikProps<CreateRolesMasterFormik>;
  type?: 'create' | 'edit';
}

const RolesMasterForm: React.FC<Prop> = ({ formik, type }) => {
  const navigate = useNavigate();

  const { collapsedMenu } = useSelector(settingSelector);
  const { dataCompany } = useSelector(authSelector);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <RolesMasterFormStyled collapsedMenu={collapsedMenu}>
      <FormikProvider value={formik}>
        <Form colon={false} className="form-setting">
          <div className="block">
            <Form.Item name="name">
              <label className="text-label">
                権限名称
                <span className="require">*</span>
              </label>
              <TextArea
                name="name"
                className="input-textarea-group"
                showCount
                maxLength={120}
                placeholder="最大120文字"
              />
            </Form.Item>
          </div>
          <div className="block">
            <label className="text-label">Home</label>
            <div className="waper-setting">
              <Item name="admin_dashboard_permission" label="管理者ダッシュボード" disabled />
            </div>
          </div>
          <div className="block">
            <label className="text-label">スキルチェック</label>
            <div className="waper-setting">
              <Item name="skill_check_implementation_permission" label="スキルチェック実施状況" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">レポート</label>
            <div className="waper-setting">
              <Item name="training_report_permission" label="トレーニングレポート" disabled />
              <Item name="skill_check_report_permission" label="スキルチェックレポート" disabled />
              <Item name="my_chart_permission" label="MYカルテ" disabled />
            </div>
          </div>
          <div className="block">
            <label className="text-label">マニュアル・ナレッジ</label>
            <div className="waper-setting">
              <Item name="manual_permission" label="マニュアル" />
              <Item name="knowledge_permission" label="ナレッジ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">OFFICIALカリキュラム</label>
            <div className="waper-setting">
              <Item name="official_curriculum_permission" label="OFFICIALカリキュラム" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">カリキュラム管理</label>
            <div className="waper-setting">
              <Item name="curricullum_master_permission" label="カリキュラムマスタ" />
              <Item name="question_master_permission" label="設問マスタ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">スキルチェック管理</label>
            <div className="waper-setting">
              <Item name="skill_check_master_permission" label="スキルチェックマスタ" />
              <Item name="inter_users_master_permission" label="面接ユーザーマスタ" />
              <Item name="groups_master_permission" label="分析グループマスタ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">ユーザー管理</label>
            <div className="waper-setting">
              <Item name="users_master_permission" label="社内ユーザーマスタ" />
              <Item name="roles_master_permission" label="権限マスタ" />
              <Item name="departments_master_permission" label="所属マスタ" />
              <Item name="positions_master_permission" label="役職マスタ" />
              <Item name="admin_master_permission" label="管理者マスタ" />
            </div>
          </div>
          <div className="block">
            <label className="text-label">その他</label>
            <div className="waper-setting">
              <Item
                name="payment_master_permission"
                label="支払マスタ"
                disabled={dataCompany?.plan_id === 'standard'}
                disabled1={dataCompany?.plan_id === 'standard'}
                disabled2={dataCompany?.plan_id === 'standard'}
              />
              <Item
                name="report_view_permission"
                label="ご利用状況"
                disabled
                disabled1={dataCompany?.plan_id === 'standard'}
                disabled2={dataCompany?.plan_id === 'standard'}
              />
            </div>
          </div>
          <div className="wrap-submit">
            {formik.errors.name ? <p className="error-message">※権限名称が未入力です</p> : null}
            <div className="wrap-button">
              <SubmitButton className="btn btn_submit">
                {type === 'edit' ? '更新' : '登録'}
              </SubmitButton>
              <button className="btn btn_close" type="button" onClick={handleBack}>
                戻る
              </button>
            </div>
          </div>
        </Form>
      </FormikProvider>
    </RolesMasterFormStyled>
  );
};

export default RolesMasterForm;
