import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import { getAffiliationAssignRole } from '../../Manual/thunk';
import {
  deleteItemPositionMaster,
  getPositionMaster,
  getRankOrders,
  searchPositionMaster,
  updateItemPositionMaster,
} from './thunk';

export interface InitialState {
  affiliationAssignRole: Types.AffiliationAssignRole.ResponseType[];
  listPositionMaster: Types.Positions.ResponseType[];
  searchResult: Types.Positions.ResponseType[];
  listRankOrder: number[];
  total: number;
}

const initialState: InitialState = {
  affiliationAssignRole: [],
  listPositionMaster: [],
  searchResult: [],
  listRankOrder: [],
  total: 0,
};

export const positionMasterSlice = createSlice({
  name: 'PositionMaster-slice',
  initialState,
  reducers: {
    resetListRankOrder: (state) => {
      state.listRankOrder = [];
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPositionMaster.fulfilled, (state, action) => {
      state.listPositionMaster = action.payload.items;
      state.total = action.payload.totalItems;
    });
    builder.addCase(getRankOrders.fulfilled, (state, action) => {
      state.listRankOrder = Array.from(
        {
          length: Number(action.payload.items[0]?.rank_order) + 1,
        },
        (_, i) => i + 1
      );
    });
    builder.addCase(searchPositionMaster.fulfilled, (state, action) => {
      state.searchResult = action.payload.items;
    });

    builder.addCase(getAffiliationAssignRole.fulfilled, (state, action) => {
      state.affiliationAssignRole = action.payload.items;
    });

    builder.addCase(deleteItemPositionMaster.fulfilled, (state, action) => {
      state.listPositionMaster = state.listPositionMaster.filter(
        (item) => item.i_id !== action.payload
      );
    });
    builder.addCase(updateItemPositionMaster.fulfilled, (state, action) => {
      if (state.listPositionMaster.length) {
        const index = state.listPositionMaster.findIndex(
          ({ i_id }) => i_id === action.payload.item.id
        );
        if (action.payload.item.data.item?.name) {
          state.listPositionMaster[index].name = action.payload.item.data.item.name.toString();
        }
        state.listPositionMaster[index].rank_order =
          action.payload.item.data.item.rank_order.toString();
        state.listPositionMaster = state.listPositionMaster.sort(
          (a, b) => Number(a.rank_order) - Number(b.rank_order)
        );
      }
    });
  },
});

export const { resetListRankOrder, resetInitialState } = positionMasterSlice.actions;

export default positionMasterSlice.reducer;
