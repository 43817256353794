import React from 'react';
import styled from 'styled-components';
import { DocRenderer } from '../../models';
import VideoProxyRenderer from '../video';

const StyledImageRenderer = styled(VideoProxyRenderer)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: white;
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0;
`;

const MP4Renderer: DocRenderer = (props) => <StyledImageRenderer {...props} />;

MP4Renderer.fileTypes = ['mp4', 'video/mp4'];
MP4Renderer.weight = 0;

export default MP4Renderer;
