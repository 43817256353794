import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FolderOpenFilled, RightOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Select, Table } from 'antd';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { getPathFolder } from 'libs/utils/explorer/tree-data-utils';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { manualSelector } from 'pages/Manual/selectors';
import CompletedModal from 'components/Modal/Completed';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import {
  getListAffiliationUser,
  getListDepartment,
  getListFolderPermission,
  removeFolderPermission,
  setFolderPermission,
} from 'pages/Manual/thunk';

const { Option } = Select;
const PER_PAGE = 10;

const columnsUser = [
  {
    title: '所属コード',
    className: 'number',
    dataIndex: 'affiliation_id',
    key: 'affiliation_id',
    width: '15%',
  },
  {
    title: '所属',
    dataIndex: 'affiliation_name',
    className: 'text',
    key: 'affiliation_name',
    width: '20%',
  },
  {
    title: '役職コード',
    className: 'number',
    dataIndex: 'position_code',
    key: 'position_code',
    width: '15%',
    render: (title: string) => <span>{title.match(/^\d+/)?.[0]}</span>,
  },
  {
    title: '役職',
    dataIndex: 'position_code',
    className: 'text',
    key: 'position_code',
    width: '20%',
    render: (title: string) => <span>{title.replace(/^\d+ /, '')}</span>,
  },
  {
    title: '社員番号',
    className: 'number',
    dataIndex: 'employee_code',
    key: 'employee_code',
    width: '15%',
  },
  {
    title: 'ユーザー名',
    dataIndex: 'user_name',
    className: 'text',
    key: 'user_name',
    width: '15%',
  },
];

const columnsDepartment = [
  {
    title: '所属コード',
    dataIndex: 'affiliation_id',
    className: 'number',
    key: 'affiliation_id',
    width: '30%',
  },
  {
    title: '所属',
    dataIndex: 'name',
    className: 'text',
    key: 'name',
    width: '70%',
  },
];

interface Props {
  visible: boolean;
  fileSelected?: Types.FileExplorer.DataTableType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FolderPermissionSetting: React.FC<Props> = ({ fileSelected, visible, setVisible }) => {
  const [tabTable, setTabTable] = useState<'user' | 'department'>('user');
  const [filterAffiliation, setFilterAffiliation] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<
    (Types.ListAffiliationAssignUser | Types.SelectDepartment.ResponseType)[]
  >([]);
  const [folderPermissionList, setFolderPermissionList] = useState<
    Types.ManualFolderPermissions.ResponseType[]
  >([]);
  const [openPopupConfirmPublishComplete, setOpenPopupConfirmPublishComplete] =
    useState<boolean>(false);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { manualFolderList, listUser, listDepartment, listFolderPermissions, totalDepartment } =
    useSelector(manualSelector);

  const dispatch = useAppDispatch();

  const onClose = () => {
    setPage(1);
    setSelectedRow([]);
    setTabTable('user');
    setChecked(false);
    setFolderPermissionList([]);
    setVisible(false);
    setFilterAffiliation(undefined);
  };

  const handleSubmit = useCallback(async () => {
    if (!userInfo || !fileSelected) return;
    dispatch(startLoading());
    if (tabTable === 'user') {
      const createList = selectedRow.filter(
        (folder) =>
          !folderPermissionList.some(
            (row) => row.login_id === (folder as Types.ListAffiliationAssignUser).login_id
          )
      );
      const removeList = folderPermissionList.filter(
        (folder) =>
          !selectedRow.some(
            (row) => (row as Types.ListAffiliationAssignUser).login_id === folder.login_id
          )
      );
      await Promise.all([
        ...createList.map((row) =>
          dispatch(
            setFolderPermission({
              item: {
                company_id: userInfo.company_id,
                folder_id: fileSelected.folder_id,
                login_id: (row as Types.ListAffiliationAssignUser).login_id,
              },
            })
          )
        ),
        ...removeList.map((item) =>
          dispatch(
            removeFolderPermission({
              id: item.i_id,
            })
          )
        ),
      ]);
    } else {
      const createList = selectedRow.filter(
        (folder) =>
          !folderPermissionList.some(
            (row) =>
              row.affiliation_id === (folder as Types.SelectDepartment.ResponseType).affiliation_id
          )
      );
      const removeList = folderPermissionList.filter(
        (folder) =>
          !selectedRow.some(
            (row) =>
              (row as Types.SelectDepartment.ResponseType).affiliation_id === folder.affiliation_id
          )
      );
      await Promise.all([
        ...createList.map((row) =>
          dispatch(
            setFolderPermission({
              item: {
                company_id: userInfo.company_id,
                folder_id: fileSelected.folder_id,
                affiliation_id: (row as Types.SelectDepartment.ResponseType).affiliation_id,
              },
            })
          )
        ),
        ...removeList.map((item) =>
          dispatch(
            removeFolderPermission({
              id: item.i_id,
            })
          )
        ),
      ]);
    }
    await dispatch(
      getListFolderPermission({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    setOpenPopupConfirmPublishComplete(true);
    dispatch(stopLoading());
  }, [dispatch, fileSelected, folderPermissionList, selectedRow, tabTable, userInfo]);

  const onChange = (e: CheckboxChangeEvent) => {
    if (!userInfo || !fileSelected) return;
    setChecked(e.target.checked);
    if (tabTable === 'user') {
      dispatch(
        getListAffiliationUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            ...(e.target.checked
              ? [
                  {
                    id: 'login_id',
                    search_value: [
                      folderPermissionList
                        .map((folder) => folder.login_id)
                        .filter(Boolean)
                        .join('|'),
                    ],
                    exact_match: true,
                  },
                ]
              : []),
            {
              id: 'user_type',
              search_value: ['interview'],
              not_match: true,
            },
          ],
          sort_fields: [
            {
              id: 'employee_code',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    } else {
      dispatch(
        getListDepartment({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            ...(e.target.checked
              ? [
                  {
                    id: 'affiliation_id',
                    search_value: [
                      folderPermissionList
                        .map((folder) => folder.affiliation_id)
                        .filter(Boolean)
                        .join('|'),
                    ],
                  },
                ]
              : []),
          ],
          sort_fields: [
            {
              id: 'affiliation_id',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          omit_total_items: false,
        })
      );
    }
  };

  const folderPath: Types.FileExplorer.TreeItem<Types.FileExplorer.FileExplorerType>[] = useMemo(
    () => getPathFolder(manualFolderList, fileSelected),
    [manualFolderList, fileSelected]
  );

  useEffect(() => {
    if (!userInfo || !fileSelected || !visible) return;
    (async () => {
      dispatch(startLoading());
      if (tabTable === 'user') {
        await dispatch(
          getListAffiliationUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'user_type',
                search_value: ['interview'],
                not_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'employee_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      } else {
        await dispatch(
          getListDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'affiliation_id',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            omit_total_items: false,
          })
        );
      }
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, page, tabTable, fileSelected, visible]);

  useEffect(() => {
    if (
      !visible &&
      !listUser.length &&
      !listDepartment.length &&
      !listFolderPermissions.length &&
      !fileSelected
    )
      return;
    const filterListFolderPermissions = listFolderPermissions.filter(
      (folder) => folder.folder_id === fileSelected?.folder_id
    );
    if (tabTable === 'user' && filterListFolderPermissions.length) {
      const rowSelected = listUser.filter((user) =>
        filterListFolderPermissions.some((folder) => folder.login_id === user.login_id)
      );
      const newListFolderPermissions = filterListFolderPermissions.filter((folder) =>
        listUser.some((user) => user.login_id === folder.login_id)
      );
      setFolderPermissionList(newListFolderPermissions);
      setSelectedRow(rowSelected);
    } else {
      const rowSelected = listDepartment.filter((department) =>
        filterListFolderPermissions.some(
          (folder) => folder.affiliation_id === department.affiliation_id
        )
      );
      const newListFolderPermissions = filterListFolderPermissions.filter((folder) =>
        listDepartment.some((department) => department.affiliation_id === folder.affiliation_id)
      );
      setFolderPermissionList(newListFolderPermissions);
      setSelectedRow(rowSelected);
    }
  }, [tabTable, listUser, listDepartment, listFolderPermissions, visible, fileSelected]);

  return (
    <>
      <Modal
        title="フォルダ権限設定"
        width={1000}
        visible={visible}
        okButton={{
          text: '設定',
          onClick: handleSubmit,
          loading,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: onClose,
        }}
        onCancel={onClose}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
      >
        <SectionStyled>
          <div className="wrap-tree-folder">
            <p className="label">設定対象フォルダ：</p>
            <div className="tree-folder">
              <FolderOpenFilled className="icon-folder" />
              {folderPath.map((item, index) => (
                <div key={index} className="item-label">
                  <RightOutlined className="icon-right" />
                  <p className={`label-tree${index === folderPath.length - 1 ? ' bold' : ''}`}>
                    {item.folder_name}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="item-select">
            <div className="select">
              <p className="label">設定方法</p>
              <Select
                onSelect={(value: 'user' | 'department') => {
                  setSelectedRow([]);
                  setPage(1);
                  setChecked(false);
                  setTabTable(value);
                  setFilterAffiliation(undefined);
                }}
                value={tabTable}
                style={{ width: 210 }}
              >
                <Option value="user">ユーザー単位で設定する</Option>
                <Option value="department">所属単位で設定する</Option>
              </Select>
            </div>
            <div className="select center">
              <p className="label">所属で絞り込み</p>
              <Select
                style={{ width: 210 }}
                allowClear
                placeholder="指定なし"
                onSelect={(affiliation_id: string) => {
                  setFilterAffiliation(affiliation_id);
                }}
                onClear={() => {
                  setFilterAffiliation(undefined);
                }}
              >
                {(tabTable === 'user' ? listUser : listDepartment).map((item, index) => (
                  <Option key={index} value={item.affiliation_id}>
                    {tabTable === 'user'
                      ? (item as Types.ListAffiliationAssignUser).affiliation_name
                      : (item as Types.SelectDepartment.ResponseType).name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="check-box">
              <Checkbox onChange={onChange} checked={checked}>
                <span className="label-check-box">設定済のみ表示</span>
              </Checkbox>
            </div>
          </div>
          <div className="wrap-table">
            {tabTable === 'user' ? (
              <Table
                rowKey="i_id"
                className="table"
                dataSource={listUser
                  .filter((user) =>
                    filterAffiliation ? user.affiliation_id === filterAffiliation : true
                  )
                  .map((item, index) => ({ ...item, index }))}
                columns={columnsUser}
                rowSelection={{
                  selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
                  onChange: (_, selectedRows) => {
                    setSelectedRow(selectedRows);
                  },
                }}
                loading={loading}
                pagination={{
                  pageSize: PER_PAGE,
                  current: page,
                  onChange: setPage,
                  total: listUser.length,
                  showSizeChanger: false,
                  position: ['topRight'],
                  showTotal: () => (
                    <div className="text-count">
                      {page * 10 > listUser.length ? listUser.length : page * 10}
                      <span className="text-static">件表示</span>/{listUser.length}
                      <span className="text-static">名</span>
                    </div>
                  ),
                }}
              />
            ) : (
              <Table
                rowKey="i_id"
                className="table"
                dataSource={listDepartment
                  .filter((department) =>
                    filterAffiliation ? department.affiliation_id === filterAffiliation : true
                  )
                  .map((item, index) => ({ ...item, index }))}
                columns={columnsDepartment}
                rowSelection={{
                  selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
                  onChange: (_, selectedRows) => {
                    setSelectedRow(selectedRows);
                  },
                }}
                loading={loading}
                pagination={{
                  pageSize: PER_PAGE,
                  current: page,
                  onChange: setPage,
                  total: totalDepartment,
                  showSizeChanger: false,
                  position: ['topRight'],
                  showTotal: () => (
                    <div className="text-count">
                      {page * 10 > totalDepartment ? totalDepartment : page * 10}
                      <span className="text-static">件表示</span>/{totalDepartment}
                      <span className="text-static">名</span>
                    </div>
                  ),
                }}
              />
            )}
          </div>
        </SectionStyled>
      </Modal>
      <CompletedModal
        title="設定が完了しました"
        visible={openPopupConfirmPublishComplete}
        setVisible={setOpenPopupConfirmPublishComplete}
        onSubmit={onClose}
      />
    </>
  );
};

export default FolderPermissionSetting;
