import { createSlice } from '@reduxjs/toolkit';

import { getOptionLimit } from './thunk';
import * as Types from 'types';

interface InitialState {
  time_limit: Types.TimeLimitType[];
}

const initialState: InitialState = {
  time_limit: [],
};

export const questionSlice = createSlice({
  name: 'question-Slice',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOptionLimit.fulfilled, (state, action) => {
      state.time_limit =
        action.payload.fields['65598664baeaf8d6328c95f3']?.options
          ?.filter((o: Types.TimeLimitType) => o.enabled)
          .sort((a: Types.TimeLimitType, b: Types.TimeLimitType) => a.sort_id - b.sort_id) || [];
    });
  },
});

export const { resetInitialState } = questionSlice.actions;

export default questionSlice.reducer;
