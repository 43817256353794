import yup from '../yup';

export const createUpdateKnowledgeSchema = yup.object().shape({
  title: yup.string().required(),
  content: yup
    .string()
    .test('is-not-default-content', 'Nội dung không được giá trị mặc định', (value) => {
      return value !== '<p><br></p>';
    })
    .required(),
  to_department: yup.string().required(),
});

export const createUpdateKnowledgeQASchema = yup.object().shape({
  knowledge_title: yup.string().required(),
  knowledge_text: yup
    .string()
    .test('is-not-default-content', 'Nội dung không được giá trị mặc định', (value) => {
      return value !== '<p><br></p>';
    })
    .required(),
  to_department_id: yup.string().required(),
});
