import React, { useEffect, useMemo, useState } from 'react';
import { CloudDownloadOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import { HEADER_SKILL_CHECK_SELECTION_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { skillCheckSelectionSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { getDataListSkillCheckImple } from '../thunk';
import TableSkillCheckStyled from './styles';
import PrivilegedUser from './Privileged';
import { useAppDispatch } from 'hooks';
import GeneralUser from './General';
import * as Types from 'types';

interface Props {
  setStatusIndex: React.Dispatch<React.SetStateAction<number>>;
}

const TableSkillCheck: React.FC<Props> = ({ setStatusIndex }) => {
  const [selectedRow, setSelectedRow] = useState<Types.SkillCheckImpleStatus.ResponseType[]>([]);
  const [visibleModalComplete, setVisibleModalComplete] = useState<boolean>(false);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [perPage, setPerPage] = useState<number>(100);
  const [userType, setUserType] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { dataTableSkillCheckImpleStatus } = useSelector(skillCheckSelectionSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const roleCode = useMemo(() => userInfo?.lookup_items?.role_code, [userInfo]);

  const handleButtonExport = () => {
    setVisiblePopupConfirmExportFile(true);
  };

  const handleButtonDelete = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisibleModalEdit(true);
    }
  };

  const fetchDataSkillCheckImple = async (conditions?: Array<Types.ConditionsType>) => {
    dispatch(startLoading());

    await dispatch(
      getDataListSkillCheckImple({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo?.login_id],
            exact_match: true,
          },
          ...(conditions ?? []),
        ],
        sort_fields: [
          {
            id: 'skill_check_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = dataTableSkillCheckImpleStatus?.map((item) => ({
        skill_check_name: item.skill_check_name,
        period: `${dayjs(item.start_period).format('YYYY/MM/DD HH:mm')}~${dayjs(
          item.end_period
        ).format('YYYY/MM/DD HH:mm')}`,
        department_name: item.department_name,
        login_id: item.login_id,
        implementation_date: dayjs(item.implementation_date).format('YYYY/MM/DD HH:mm'),
      }));

      const csvString = [
        HEADER_SKILL_CHECK_SELECTION_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'skill_check_selection_export.csv');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  useEffect(() => {
    setUserType(userInfo?.user_type);
  }, [userInfo]);

  return (
    <TableSkillCheckStyled collapsedMenu={collapsedMenu}>
      <div className="header-container">
        <p className="text-note">
          スキルチェックの実施状況を確認できます。
          <br />
          スキルチェックの再実施を行う場合は、選択欄にチェックを付けて実施履歴削除ボタンをクリックしてください。スキルチェックが実施可能な場合、再実施が可能になります。
        </p>
      </div>
      <div className="border-line" />
      {userType === 'interview' ? (
        <PrivilegedUser
          setSelectedRow={setSelectedRow}
          setPage={setPage}
          perPage={perPage}
          page={page}
          handleSelectChange={(value) => handleSelectChange(value)}
        />
      ) : (
        <GeneralUser
          handleSelectChange={(value) => handleSelectChange(value)}
          setVisibleModalComplete={setVisibleModalComplete}
          setVisiblePopupError={setVisiblePopupError}
          visibleModalComplete={visibleModalComplete}
          setVisibleModalEdit={setVisibleModalEdit}
          setSelectedRowKeys={setSelectedRowKeys}
          visiblePopupError={visiblePopupError}
          visibleModalEdit={visibleModalEdit}
          selectedRowKeys={selectedRowKeys}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          setPerPage={setPerPage}
          setPage={setPage}
          perPage={perPage}
          page={page}
        />
      )}
      <div className="wrap-bottom">
        <div className="flex">
          <div className="text-label">
            選択した実施状況を処理：
            {userType !== 'interview' && (
              <Button
                className={
                  roleCode?.skill_check_implementation_permission.toString() !== '1'
                    ? 'btn btn-active'
                    : 'btn-disable btn-active'
                }
                onClick={handleButtonDelete}
              >
                実施履歴 削除
              </Button>
            )}
            <Button className="btn btn-active" onClick={handleButtonExport}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>
          </div>
          <Button
            className="btn btn-outline"
            onClick={() => {
              fetchDataSkillCheckImple();
              setStatusIndex(0);
            }}
          >
            <p>スキルチェック選択</p>
            <RightOutlined className="icon" />
          </Button>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={visiblePopupConfirmExportFile}
        setVisible={setVisiblePopupConfirmExportFile}
        onSubmit={handleExportCSV}
      />
    </TableSkillCheckStyled>
  );
};

export default TableSkillCheck;
