import { createAsyncThunk } from '@reduxjs/toolkit';

import { REPORT_SKILL_CHECK } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getReportSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheck.ResponseType> & {
    startPeriod: string;
    endPeriod: string;
  },
  Types.ReportsItemRequestType & { startPeriod: string; endPeriod: string },
  Types.ThunkAPI<Types.requestError>
>('SkillCheckAnalysis/thunk/getReportSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheck.ResponseType>(
      REPORT_SKILL_CHECK.id,
      req
    );

    return { ...data, startPeriod: req.startPeriod, endPeriod: req.endPeriod };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckComparison = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckAnalysis/thunk/getReportSkillCheckComparison', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheck.ResponseType>(
      REPORT_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
