import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 860px;
  .text-center {
    text-align: center;
    color: #777777;
  }
  .form {
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 40px;
    .form-input {
      .require {
        color: #f0330a;
      }
      .input {
        height: 40px;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        &__disabled {
          background-color: #f9f8f8;
        }
        ::placeholder {
          color: #cccccc;
          font-size: 14px;
        }
      }
      .text-label {
        font-size: 13px;
        display: block;
        text-align: right;
        color: #777777;
        // padding-top: 10px;
        .require {
          color: #f0330a;
        }
      }
    }
    .wrap-input-zip-code {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        .input {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .btn-check-zipCode {
          font-size: 12px;
          width: 40%;
          color: #00a3a5;
          border-top: 1px solid #ccc;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          background-color: #ffffff;
          border-left: none;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          cursor: pointer;
        }
      }
    }
    .error-input-zip-code {
      .btn-check-zipCode {
        border-color: #ff4d4f !important;
      }
    }
  }
  .wrap-button {
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 30px 0 20px 0;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
    .go-back {
      margin-top: 10px;
      font-size: 13px;
      text-decoration-line: underline;
      border: none;
      background: none;
      color: #00a3a5;
      cursor: pointer;
    }
  }
`;
