import styled from 'styled-components';

import { HomeCardColour } from 'constant/enum.constant';

interface Props {
  cardColour: HomeCardColour;
  isDoubleProgressBar: boolean;
}

export const Wrapper = styled.div<Props>`
  .progress-bar {
    justify-content: center;

    .progress-col {
      width: ${(props) => (props.isDoubleProgressBar ? 75 : 66)}%;
      .progress-bar-title {
        align-items: flex-end;

        .progress-bar-title-text {
          color: rgba(102, 102, 102, 0.8);
          font-weight: 400;
          font-size: 13px;
          text-align: left;
          margin-bottom: 6px;
        }

        .progress-bar-title-number .title-number {
          position: relative;
          font-family: Lato;
          font-weight: 400;
          font-size: 28px;
          text-align: right;
          color: ${(props) =>
            props.cardColour === HomeCardColour.green
              ? '#3FC2A3'
              : props.cardColour === HomeCardColour.blue
              ? '#089AD4'
              : '#BD85D7'};
          &:after {
            content: '%';
            position: absolute;
            right: -13px;
            bottom: 0;
            font-size: 0.5em;
          }
        }
      }

      .progress-bar-display {
        .progress-bar-bars {
          width: 100%;
          .ant-progress-steps-item {
            width: ${(props) => (props.isDoubleProgressBar ? 8 : 4)}% !important;
            border-radius: 2px;
          }
        }
        .double-progress-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          flex-direction: row;
          .minus {
            font-weight: 700;
            font-size: 16px;
            color: #6181d3;
            margin-right: 5px;
          }
          .bordered {
            position: relative;
            border: 1px solid #cccccc;
            height: 100%;
            margin: 0 5px;
            &:after {
              content: '';
              position: absolute;
              left: -5px;
              top: -10px;
              width: 0;
              height: 0;
              border-right: 5px solid transparent;
              border-left: 5px solid transparent;
              border-top: 5px solid #cccccc;
            }
          }
          .decrease {
            transform: rotateY(180deg);
          }
          .decrease,
          .increase {
            width: 48%;
          }

          .plus {
            font-weight: 700;
            font-size: 16px;
            color: #a367da;
            margin-left: 5px;
          }
        }
      }
    }
  }
`;
