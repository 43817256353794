import { createSlice } from '@reduxjs/toolkit';
import { filter } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';
import {
  getDataSelectSkillCheckName,
  getDataSelectSkillCheckGroupingCode,
  getSelectUser,
  getSelectTypes,
  createSkillCheckTrans,
  editSkillCheckTrans,
  getDataItem,
  getDataQuestion,
  getDataTable,
  getSelectDepartment,
  getDataListSkillCheckImple,
} from './thunk';

export interface InitialState {
  dataSelectTypesImplementationSetting: Array<Types.SelectTypes.ResponseType>;
  dataSelectTypesImplementationStatus: Array<Types.SelectTypes.ResponseType>;
  dataTableSkillCheckImpleStatus: Array<Types.SkillCheckImpleStatus.ResponseType>;
  dataSillCheckImple: Array<Types.ListSkillCheckTypes>;
  dataSelectSkillCheckName: Array<Types.SelectSkillCheck.ResponseType>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataSelectGroupingCode: Array<Types.SelectSkillCheckGroupingCode.ResponseType>;
  dataQuestion: Array<Types.SkillCheckImple.ResponseType>;
  dataCreate?: Types.CreateItemResponseType<Types.SkillCheckTrans2.ResponseType>;
  dataEdit?: Types.UpdateItemResponseType<Types.SkillCheckTrans2.ResponseType>;
  dataSelectUser: Array<Types.SelectUsers.ResponseType>;
  dataItem: Array<Types.UserAssignSkillCheck.ResponseType>;
  time_limit: Array<Types.SelectTypes.ResponseType>;
  total: number;
}

const initialState: InitialState = {
  dataSelectTypesImplementationSetting: [],
  dataSelectTypesImplementationStatus: [],
  dataTableSkillCheckImpleStatus: [],
  dataSelectSkillCheckName: [],
  dataSelectGroupingCode: [],
  dataSelectDepartment: [],
  dataSillCheckImple: [],
  dataSelectUser: [],
  dataQuestion: [],
  time_limit: [],
  dataItem: [],
  total: 0,
};

export const skillCheckSelectionSlice = createSlice({
  name: 'skillCheckSelection-page',
  initialState,
  reducers: {
    filterSkillImpleBySetting: (state, action) => {
      if (action.payload === '1') {
        state.dataSillCheckImple = filter(
          state.dataSillCheckImple,
          (e) =>
            (e.implementation_status === Number(action.payload) || !e.implementation_status) &&
            (e.implementation_settings === 2 ||
              (e.implementation_settings === 1 &&
                dayjs().isBetween(e?.start_period, e?.end_period)))
        );
        state.dataTableSkillCheckImpleStatus = filter(
          state.dataTableSkillCheckImpleStatus,
          (e) =>
            (e.implementation_status === Number(action.payload) || !e.implementation_status) &&
            (e.implementation_settings === 2 ||
              (e.implementation_settings === 1 &&
                dayjs().isBetween(e?.start_period, e?.end_period)))
        );
      } else {
        state.dataSillCheckImple = filter(
          state.dataSillCheckImple,
          (e) =>
            ((e.implementation_status === Number(action.payload) || !e.implementation_status) &&
              e.implementation_settings === 1 &&
              !dayjs().isBetween(e?.start_period, e?.end_period)) ||
            (e.implementation_status === Number(action.payload) && e.implementation_settings === 2)
        );
        state.dataTableSkillCheckImpleStatus = filter(
          state.dataTableSkillCheckImpleStatus,
          (e) =>
            ((e.implementation_status === Number(action.payload) || !e.implementation_status) &&
              e.implementation_settings === 1 &&
              !dayjs().isBetween(e?.start_period, e?.end_period)) ||
            (e.implementation_status === Number(action.payload) && e.implementation_settings === 2)
        );
      }
    },
    filterSkillImpleByStatus: (state, action) => {
      if (action.payload === '1') {
        state.dataSillCheckImple = filter(
          state.dataSillCheckImple,
          (e) =>
            (e.implementation_status === Number(action.payload) || !e.implementation_status) &&
            (e.implementation_settings === 2 ||
              (e.implementation_settings === 1 &&
                dayjs().isBetween(e?.start_period, e?.end_period)))
        );
        state.dataTableSkillCheckImpleStatus = filter(
          state.dataTableSkillCheckImpleStatus,
          (e) =>
            (e.implementation_status === Number(action.payload) || !e.implementation_status) &&
            (e.implementation_settings === 2 ||
              (e.implementation_settings === 1 &&
                dayjs().isBetween(e?.start_period, e?.end_period)))
        );
      }
      if (action.payload === '2') {
        state.dataSillCheckImple = filter(
          state.dataSillCheckImple,
          (e) => e.implementation_status === 2
        );
        state.dataTableSkillCheckImpleStatus = filter(
          state.dataTableSkillCheckImpleStatus,
          (e) => e.implementation_status === 2
        );
      }
      if (action.payload === '0') {
        state.dataSillCheckImple = filter(
          state.dataSillCheckImple,
          (e) =>
            e.implementation_status !== 2 &&
            e.implementation_settings === 1 &&
            !dayjs().isBetween(e?.start_period, e?.end_period)
        );
        state.dataTableSkillCheckImpleStatus = filter(
          state.dataTableSkillCheckImpleStatus,
          (e) =>
            e.implementation_status !== 2 &&
            e.implementation_settings === 1 &&
            !dayjs().isBetween(e?.start_period, e?.end_period)
        );
      }
    },
    resetDataEdit: (state) => {
      state.dataEdit = undefined;
      state.dataCreate = undefined;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataSelectSkillCheckName.fulfilled, (state, action) => {
      state.dataSelectSkillCheckName = action.payload.report_results;
    });
    builder.addCase(getDataSelectSkillCheckGroupingCode.fulfilled, (state, action) => {
      state.dataSelectGroupingCode = action.payload.report_results;
    });
    builder.addCase(getSelectUser.fulfilled, (state, action) => {
      state.dataSelectUser = action.payload.report_results;
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      state.dataSelectTypesImplementationSetting = action.payload.items.filter(
        (item) => item.type === 'implementation_settings'
      );
      state.dataSelectTypesImplementationStatus = action.payload.items.filter(
        (item) => item.type === 'implementation_status'
      );
      state.time_limit = action.payload.items.filter((item) => item.type === 'time_limit');
    });
    builder.addCase(getSelectDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });
    builder.addCase(getDataListSkillCheckImple.fulfilled, (state, action) => {
      state.dataSillCheckImple = action.payload.items;
    });
    builder.addCase(getDataQuestion.fulfilled, (state, action) => {
      state.dataQuestion = action.payload.items;
    });
    builder.addCase(createSkillCheckTrans.fulfilled, (state, action) => {
      state.dataCreate = action.payload;
    });
    builder.addCase(editSkillCheckTrans.fulfilled, (state, action) => {
      state.dataEdit = action.payload;
    });
    builder.addCase(getDataItem.fulfilled, (state, action) => {
      state.dataItem = action.payload.items;
    });
    builder.addCase(getDataTable.fulfilled, (state, action) => {
      state.dataTableSkillCheckImpleStatus = action.payload.report_results;
      state.total = action.payload.totalItems;
    });
  },
});

export const {
  filterSkillImpleBySetting,
  filterSkillImpleByStatus,
  resetInitialState,
  resetDataEdit,
} = skillCheckSelectionSlice.actions;
export default skillCheckSelectionSlice.reducer;
