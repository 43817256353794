import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';

import { usageStatusSelector } from 'pages/UsageStatus/selectors';
import { RegisterInformationPaymentFormik } from 'types';
import CompletedModal from 'components/Modal/Completed';
import { fourStepSchema } from 'libs/validations';
import PaymentMasterConfirmStyled from './styles';
import { IMAGE_CREDIT_CARD } from 'constant';
import { SecurityCode } from 'assets';
import { Input } from 'components';

interface Props {
  onBack: () => void;
}

const PaymentMasterConfirm: React.FC<Props> = ({ onBack }) => {
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [cardNumber, setCardNumber] = useState<{
    input1: string;
    input2: string;
    input3: string;
    input4: string;
  }>({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });

  const { creditCard } = useSelector(usageStatusSelector);

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fourStepSchema,
    validateOnBlur: false,
    onSubmit: () => {
      setShowCompleteModal(true);
    },
  });

  const { isSubmitting } = formik;

  useEffect(() => {
    if (creditCard) {
      setCardNumber({
        input1: creditCard.cardNumber.slice(0, 4),
        input2: creditCard.cardNumber.slice(4, 8),
        input3: creditCard.cardNumber.slice(8, 12),
        input4: creditCard.cardNumber.slice(12, 18),
      });
    }
  }, [creditCard]);

  return (
    <PaymentMasterConfirmStyled>
      <div className="container container-confirm">
        <div className="description">
          <p>編集内容に問題がなければ、更新ボタンをクリックしてください。</p>
        </div>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
          >
            <div className="form">
              <Form.Item
                name="companyName"
                label={<span className="text-label">カード会社</span>}
                className="form-input"
              >
                <div className="wrap-card-company">
                  <div className="wrap-image">
                    <img
                      className="image-card-company"
                      aria-hidden
                      src={
                        IMAGE_CREDIT_CARD.find((i) => i.name === formik.values.companyName)?.image
                      }
                      alt={
                        IMAGE_CREDIT_CARD.find((i) => i.name === formik.values.companyName)?.name
                      }
                    />
                  </div>
                  <p className="card-name">{formik.values.companyName}</p>
                </div>
              </Form.Item>
              <Form.Item
                name="cardNumber"
                label={<span className="text-label">カード番号</span>}
                className="form-input"
              >
                <Input
                  name=""
                  className="input-card-number input_small input__disabled"
                  type="number"
                  disabled
                  value={cardNumber.input1}
                  maxLength={4}
                />
                <span> - </span>
                <Input
                  name=""
                  className="input-card-number input_small input__disabled"
                  type="number"
                  disabled
                  value={cardNumber.input2}
                  maxLength={4}
                />
                <span> - </span>
                <Input
                  name=""
                  className="input-card-number input_small input__disabled"
                  type="number"
                  value={cardNumber.input3}
                  disabled
                  maxLength={4}
                />
                <span> - </span>
                <Input
                  name=""
                  className="input-card-number input_small input__disabled"
                  disabled
                  type="number"
                  value={cardNumber.input4}
                  maxLength={4}
                />
              </Form.Item>
              <Form.Item
                name="customerName"
                label={<span className="text-label">カード名義人</span>}
                className="form-input"
              >
                <Input name="customerName" className="input__disabled" type="text" disabled />
              </Form.Item>
              <Form.Item
                name="expirationDate"
                label={<span className="text-label">有効期限</span>}
                className="form-input"
              >
                <Input
                  name=""
                  value={formik.values.expirationDate.slice(0, 2)}
                  className="input__disabled input_small"
                  type="number"
                  disabled
                />
                <span className="text-label-content">月 / </span>
                <Input
                  name=""
                  value={formik.values.expirationDate.slice(2, 6)}
                  className="input__disabled input_small"
                  type="number"
                  disabled
                />
                <span className="text-label-content">年</span>
              </Form.Item>
              <Form.Item
                name="securityNumber"
                label={<span className="text-label">セキュリティコード</span>}
                className="form-input"
              >
                <div className="flex">
                  <Input
                    name="securityNumber"
                    className="input__disabled input_small input_small_two"
                    type="number"
                    disabled
                  />
                  <p className="content-text">
                    カード裏側のご署名欄に印字された数字の末尾3桁の数字を入力してください。
                    <br />
                    AMEXの場合、はカード表側の4桁を入力してください
                  </p>
                </div>
              </Form.Item>
              <div className="wrap-security">
                <img src={SecurityCode} alt="security-code" />
              </div>
            </div>
            <div className="wrap-button">
              <div className="flex">
                <SubmitButton
                  disabled={!formik.isValid && !formik.dirty}
                  loading={isSubmitting}
                  className="btn-submit"
                >
                  更新
                </SubmitButton>
                <button type="button" className="btn-cancel" onClick={onBack}>
                  戻る
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
      />
    </PaymentMasterConfirmStyled>
  );
};

export default PaymentMasterConfirm;
