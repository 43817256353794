import styled from 'styled-components';

export default styled.div`
  position: relative;
  height: 100%;
  .legend {
    display: flex;
    align-items: center;
    position: absolute;
    top: -40px;
    right: 20px;
    .sort-column {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-left: 20px;
      .legend-filter {
        width: 14px;
        height: 14px;
        background: #ddeacd;
      }
      .green {
        background: #6ec9ab;
      }
      .legend-name {
        font-size: 12px;
        color: #424242;
        margin: 0 5px;
      }
      .btn-sort {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        padding: 0;
        .wrap-sort {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon-caret {
            font-size: 9px;
            color: #bfbfbf;
          }
          .active {
            color: #424242;
          }
        }
      }
    }
  }
  .container-table {
    display: flex;
    flex-direction: row;
    .table {
      width: 30%;
      .ant-table-cell {
        padding: 8px;
      }
      .ant-table-thead tr .average {
        background: #f4fbfb;
      }
      .br-1 {
        border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
      }
      .table-row-hightlight {
        background: #f9f9f9;
      }
      .red {
        color: red;
      }
    }
    .chart {
      width: 70%;
      div:first-child {
        top: -20px;
        border-bottom: 1px solid #dddddd;
      }
      .header-column {
        width: 100%;
        height: 62px;
        background: #f7f7f7;
      }
    }
  }
`;
