import styled from 'styled-components';

interface Props {
  planSelectedIndex: number | undefined;
  success: boolean;
}
export const Wrapper = styled.div<Props>`
  width: 600px;
  display: ${(props) => (props.success ? 'none' : '')};
  .header-information-no {
    display: none;
  }
  .header-information-register {
    .ant-steps-item-active {
      .ant-steps-item-container {
        .ant-steps-item-title {
          color: #00a3a5 !important;
          font-weight: bold;
        }
        .ant-steps-item-icon {
          border-color: #00a3a5 !important;
          background: #00a3a5 !important;
        }
      }
    }
    .ant-steps {
      .ant-steps-item-icon {
        border-color: white;
        background: rgba(196, 196, 196, 1);
      }
    }
    .display-none {
      display: none;
    }
    .site-navigation-steps {
      .ant-steps-item {
        width: ${(props) => (props.planSelectedIndex === 0 ? '35%' : '20%')};
        padding: 0;
        &:first-child .ant-steps-item-container::before,
        &:last-child .ant-steps-item-container::after {
          display: none;
        }
      }
      .ant-steps-item-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after {
          position: absolute;
          top: 19px;
          left: 70%;
          display: block;
          width: 9999px;
          height: 3px;
          background: #e5e5e5;
          content: '';
        }
        &::before {
          position: absolute;
          bottom: 50px;
          right: 69%;
          display: block;
          width: 9999px;
          height: 3px;
          background: #e5e5e5;
          content: '';
        }
        .ant-steps-item-title {
          font-size: 12px;
          color: #999999;
          padding: 0;
          &::after {
            display: none;
          }
        }
        .ant-steps-item-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          margin: 0;
          .ant-steps-icon {
            font-family: Lato;
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
    }
    .wrap-step-info {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 25px 0 40px 0;
      &::after {
        position: absolute;
        display: block;
        bottom: -15px;
        left: 50%;
        width: 40px;
        height: 1px;
        margin-left: -20px;
        background: #c4c4c4;
        content: '';
      }
      .step {
        font-family: Lato;
        font-size: 18px;
        color: #00a3a5;
      }
      .step-name {
        font-size: 24px;
        color: #424242;
        margin-left: 10px;
      }
    }
  }
`;
