import styled from 'styled-components';

export const ChangePaymentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .content {
    width: 100%;
    padding: 0 20px;
    .wrap-content-top {
      display: flex;
      justify-content: center;
      background-color: #ffffff;
      padding: 24px 0;
      .wrap-content-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        .content-item {
          align-items: center;
          display: flex;
          .item-icon {
            margin: 0 16px;
            font-size: 35px;
            color: #cccccc;
          }
          .content-item-text {
            font-size: 16px;
            color: #424242;
          }
        }
      }
    }
    .wrap-content-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content-bottom-note {
        font-size: 13px;
        color: #777777;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
      }
      .content-bottom-subtext {
        font-size: 15px;
        color: #424242;
        margin-top: 32px;
        border-bottom: 1px solid #424242;
      }
      .content-bottom-note-warning {
        background-color: #fffaf9;
        padding: 8px;
        border: 1px solid #f0330a;
        margin-top: 20px;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        .icon-warrning {
          margin-right: 16px;
          color: #f0330acc;
          font-size: 24px;
        }
        .text-warrning {
          color: #f0330acc;
          font-size: 15px;
        }
      }
      .content-bottom-subtext-warning {
        color: #f0330a;
        font-size: 13px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 12px;
      }
    }
  }
`;
