import styled from 'styled-components';

interface Props {
  isPreviewVisible: boolean;
  isSelect: boolean;
}

export default styled.section<Props>`
  position: relative;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: #f9f8f8;

  .header {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid #dddddd;
    .image-logo {
      width: 75px;
      height: 55px;
      margin: 10px 0 0 15px;
      cursor: pointer;
    }
    .text-header {
      font-size: 24px;
      text-align: center;
      color: #2a2a2a;
      margin: 0;
    }
    .btn-header {
      width: 80px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      margin-right: 28px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 13px;
      text-align: center;
      color: #777777;
      cursor: pointer;
    }
  }
  .ant-progress-inner.ant-progress-circle-gradient {
    z-index: 30;
  }
  .remain-time-child {
    position: absolute;
    top: ${(props) => (props.isPreviewVisible ? 15 : 95)}px;
    right: 30px;
    z-index: ${(props) => (props.isPreviewVisible ? 3000 : 1)};
    .label-time {
      font-size: 13px;
      color: #08a3a5;
    }
    .label-number {
      font-size: 32px;
      text-align: center;
      color: #08a3a5;
    }
    &::before {
      content: '';
      width: 155px;
      height: 155px;
      background-color: #ffffff;
      position: absolute;
      top: -12px;
      right: -12px;
      border-radius: 50%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    }
    &::after {
      content: '';
      width: 155px;
      height: 85px;
      background-color: #ffffff;
      position: absolute;
      top: -15px;
      right: -12px;
    }
    .text-time {
      z-index: 2;
      font-size: 13px;
      color: #08a3a5;
      margin: 0;
    }
    .number-time {
      font-family: Lato;
      font-size: 32px;
      text-align: center;
      color: #08a3a5;
      margin: 0;
    }
  }
  .body {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-top: 45px;
    .title {
      font-size: 15px;
      text-align: center;
      color: #424242;
      padding: 0;
      margin: 0;
    }
    .item-body {
      width: 90%;
      height: auto;
      margin: 35px auto;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      background: #ffffff;
      border-radius: 3px;
      .wrap-answer {
        border-bottom: 1px solid #e5e5e5;
        .number {
          align-items: center;
          .count-number {
            display: flex;
            justify-content: center;
            padding: 20px;
            .count-fist {
              margin: 0;
              font-family: Lato;
              font-size: 35px;
              color: #424242;
            }
            .count-last {
              margin: 20px 0 0 0;
              font-family: Lato;
              font-size: 22px;
              color: #999999;
            }
          }
        }
        .label {
          display: flex;
          align-items: center;
          padding: 15px;
          border-left: 1px solid #dddddd;
          .text-label {
            font-size: 20px;
            color: #2a2a2a;
            margin: 0;
          }
        }
      }
      .wrap-item {
        .questions {
          position: relative;
          z-index: 1;
          &::before {
            content: '';
          }
          padding: 60px 50px;
          border-right: 1px solid #e5e5e5;
          p {
            color: #424242;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0.75px;
          }
          .item-question {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            margin-top: 30px;
            cursor: default;
            position: relative;
            .btn-next {
              background: #ffffff;
              border: none;
              cursor: pointer;
              .icon {
                color: #c4c4c4;
                font-size: 20px;
              }
            }
            .carousel {
              width: 550px;
            }
            @media (max-width: 1440px) {
              .carousel {
                width: 384px;
                .item-image {
                  display: flex !important;
                  justify-content: center;
                  flex-direction: column;
                  position: relative;
                }
              }
              .slide {
                width: 350px;
              }
            }
            .zoom-video {
              width: 37px;
              height: 37px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.8);
              align-self: end;
              margin-left: -45px;
              margin-bottom: 5px;
              z-index: 1;
              .icon-zoom {
                color: #aaaaaa;
              }
            }
            .zoom-image {
              width: 37px;
              height: 37px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.8);
              position: absolute;
              bottom: 15px;
              right: 15px;
              .icon-zoom {
                color: #aaaaaa;
              }
            }
          }
        }
        .text-label {
          margin-top: 30px;
          display: flex;
          justify-content: center;
        }
        .answer {
          &::before {
            content: '';
            position: absolute;
            bottom: 280px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 10px solid #ffffff;
            border-bottom: 10px solid #ffffff;
            border-left: 10px solid #dddddd;
          }
          .form-search {
            flex-direction: column;
          }
          .title-answer {
            padding: 60px 50px 0 50px;
            font-size: 14px;
            text-align: center;
            color: #999999;
          }
          .btn-answer-active {
            display: flex;
            justify-content: center;
            margin: 18px auto;
            .btn {
              width: 220px;
              height: 50px;
              font-weight: 700;
              font-size: 18px;
              text-align: center;
              color: ${(props) => (props.isSelect ? '#ffffff' : '#bbbbbb')};
              border: none;
              background: ${(props) => (props.isSelect ? '#f6ac00' : ' #eeeeee')};
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              cursor: pointer;
              &:disabled,
              &[disabled] {
                cursor: not-allowed;
              }
            }
          }
          span {
            width: 100%;
          }
          .wrap-question {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .text-label {
              margin-top: 0px;
              display: flex;
              align-items: center;
            }
            .img-problem {
              width: 99px;
              height: 56px;
              display: flex;
              align-items: center;
              background-color: #e1dcdc;
              border: 1px solid #eeeeee;
              flex-direction: column;
              justify-content: center;
              margin: 0 6px;
              .image-attach {
                width: 96px;
                height: 54px;
                background: #e1dcdc;
                position: relative;
                display: flex;
                justify-content: center;
                .wrap-video {
                  width: 100%;
                  height: 54px;
                  display: flex;
                  justify-content: center;
                  background: #e1dcdc;
                }
                .image-answer {
                  max-width: 100%;
                  max-height: 54px;
                  height: auto;
                  object-fit: contain;
                }
                .icon-play {
                  width: 28px;
                  height: 28px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: #c4c4c4;
                  position: absolute;
                  bottom: 25%;
                  left: 35%;
                  .icon {
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.8);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .ant-progress-text {
    font-size: 13px;
    color: #08a3a5;
  }

  .ant-image-mask {
    align-items: flex-end;
    justify-content: end;
  }
  .ant-image-mask {
    display: none;
  }
  .ant-image-preview-operations {
    display: none;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-radio-group.ant-radio-group-solid {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-button-wrapper {
    line-height: 140%;
    margin: 4px 48px;
    padding: 51px 13px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .label-answer {
      margin: 0;
      font-family: Lato;
      font-size: 18px;
      color: #bbbbbb;
    }
    .content-answer {
      margin: 0;
      padding: 0 10px;
      font-size: 15px;
      color: #2a2a2a;
    }
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #08a3a5;
    border: 1px solid #08a3a5;
    .label-answer {
      color: rgba(255, 255, 255, 0.6);
    }
    .content-answer {
      color: #ffffff;
      font-weight: 700;
    }
  }
  .ant-radio-group-solid.ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
  }
  .ant-radio-button-wrapper:hover {
    color: unset;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #08a3a5;
    border-color: #08a3a5;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #08a3a5;
  }
  .wrap-question {
    display: flex;
  }
  .ant-carousel .slick-dots-bottom {
    bottom: -25px;
  }
  .ant-carousel .slick-dots li.slick-active {
    width: 16px;
  }
  .ant-carousel .slick-dots li.slick-active button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #08a3a5;
  }
  .ant-carousel .slick-dots li button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #dddddd;
  }
  .slick-track {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-image-preview-img {
    min-height: 65vh;
    max-height: 70vh;
  }

  .wrap-item {
    width: unset !important;
    display: flex !important;
    justify-content: center !important;
    .item-image {
      display: flex !important;
      justify-content: center;
      max-height: 216px;
      max-width: 100%;
      position: relative;
      flex-direction: column;
      background: #e1dcdc;
      .video-attach {
        max-height: 216px;
      }
      .ant-image {
        height: 100%;
        .image-attach {
          max-width: 100%;
          height: 216px;
          object-fit: contain;
        }
      }
    }
  }
`;
