import styled from 'styled-components';

export default styled.section`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #f9f8f8;
  .not-implemented,
  .implemented {
    display: flex;
    align-items: center;
  }
  .not-implemented .label-status {
    color: #f33456;
  }
  .icon-edit {
    display: flex;
    align-items: center;
    .label-status {
      color: #f6a200;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .icon-stop {
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
    padding-top: 10px;
    .label-status {
      color: #f33456;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .icon-completed {
    display: flex;
    align-items: center;
    .label-status {
      color: #159193;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .status {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 2px;
    margin: 0 8px 0 0;
    .text-status {
      margin: 0;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .edit-icon {
    background-color: #f6a200;
    span {
      color: #f6a200;
    }
  }
  .stop-icon {
    background-color: #f33456;
  }
  .status-done {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #159193;
    border-radius: 2px;
    margin: 0 8px 0 0;
    .text-status {
      margin: 0;
      font-size: 11px;
      font-weight: bold;
    }
  }
  .text-note {
    font-family: 'Noto Sans Javanese';
    font-size: 12px;
    color: #424242;
  }
  .skill-check-header {
    background-color: #ffffff;
    padding: 30px;
    border-bottom: 1px solid #dddddd;
  }
  .button-check {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    .text-check {
      font-size: 14px;
      color: #2a2a2a;
      &::before {
        content: '';
        border: 1px solid #dddddd;
        width: 40px;
        position: absolute;
        top: 35px;
        left: 0;
      }
    }
    .check-btn {
      width: 180px;
      height: 32px;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      color: #f1a900;
      border-radius: 5px;
      border: 1px solid #f6ac00;
      background: #fff;
      box-shadow: 1px 1px 4px 0px rgba(68, 68, 68, 0.2);
      cursor: pointer;
    }
    .disable-btn {
      cursor: not-allowed;
      pointer-events: none;
      background: #cccccc;
      border: 1px solid #cccccc;
      color: #777777;
      font-weight: 700;
      font-size: 12px;
      text-align: center;
      padding: 10px 7px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      -webkit-user-select: none;
      user-select: none;
    }
  }
  .form-search {
    display: flex;
    align-items: center;
    padding: 25px 0;
    .item {
      width: 20%;
      margin-right: 8px;
      .date-input {
        width: 100%;
      }
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-family: 'Noto Sans Javanese';
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 8px;
      }
    }
  }
  .body {
    background-color: #f9f8f8;
    padding-top: 40px;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px;
      margin-left: 30px;
      margin-right: 30px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      cursor: pointer;
      .edit-item {
        display: flex;
        align-items: center;
        padding-left: 15px;
        .icon {
          margin-right: 8px;
        }
        p {
          margin: 0;
          font-size: 14px;
          color: #999999;
        }
      }
      .btn-stop {
        width: 120px;
        height: 32px;
        color: #f33456;
        background: #f9f8f8;
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        .icon {
          margin-right: 8px;
        }
      }
      .btn-check {
        width: 120px;
        height: 32px;
        color: #159193;
        background: #f9f8f8;
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        .icon {
          margin-right: 8px;
        }
      }
    }
    .hight-light {
      .edit-item {
        p {
          margin: 0;
          font-weight: 700;
          font-size: 14px;
          color: #424242;
        }
      }
      .disabled {
        color: #777777 !important;
        width: 120px;
        height: 32px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        .icon {
          margin-right: 5px;
        }
      }
      .btn-edit {
        width: 120px;
        height: 32px;
        color: #ffffff;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 13px;
        text-align: center;
        cursor: pointer;
        .icon {
          margin-right: 5px;
        }
      }
    }
    .content-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 12px;
      margin: 0 30px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      .item-left {
        width: 50%;
        .item-left-child {
          display: flex;
          .label {
            display: flex;
            align-items: center;
            width: 20%;
            .icon-copy {
              padding-right: 10px;
              color: #aaaaaa;
            }
            .text-label {
              font-size: 12px;
              color: #777777;
              margin: 0;
            }
          }
          .content-label {
            font-size: 13px;
            margin: 0;
            padding: 0 10px;
            color: #424242;
          }
        }
      }
      .item-right {
        width: 50%;
        display: flex;
        flex-direction: row;
        .item-child {
          width: 50%;
          .item-right-child {
            display: flex;
            .label {
              display: flex;
              align-items: center;
              .icon-copy {
                padding-right: 10px;
                color: #aaaaaa;
              }
              .text-label {
                font-size: 12px;
                color: #777777;
                margin: 0;
              }
            }
            .content-label {
              font-size: 13px;
              padding: 0 10px;
              margin: 0;
              color: #424242;
            }
          }
        }
      }
    }
    .content-dropdown {
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 25px 12px;
      margin: 0 30px;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      position: relative;
      &::before {
        content: '';
        height: 90%;
        align-self: flex-start;
        position: absolute;
        top: 10px;
        left: 5px;
      }
      .item-dropdown {
        display: flex;
        width: 100%;
        border-bottom: 1px dashed #dddddd;
      }
      .item-dropdown-last {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .label {
          display: flex;
          align-items: center;
          flex-direction: row;
          align-self: flex-start;
          padding: 8px 0;

          .icon-mess {
            color: #aaaaaa;
            padding-right: 10px;
          }
          .text-label {
            margin: 0;
            font-size: 12px;
            color: #777777;
          }
        }
        .wrap-des {
          width: 100%;
          height: 45px;
          display: inline-block;
          box-sizing: border-box;
          white-space: pre-line;
          word-wrap: break-word;
          .content-label {
            margin: 0;
            padding-right: 10px;
            align-self: flex-start;
            font-size: 13px;
            color: #424242;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
      .item-left {
        width: 50%;
        .item-left-child {
          display: flex;
          padding-bottom: 5px;
          .label {
            display: flex;
            align-items: center;
            width: 15%;
            min-width: 110px;
            .icon-copy {
              padding-right: 10px;
              color: #aaaaaa;
            }
            .text-label {
              min-width: 75px;
              font-size: 12px;
              color: #777777;
              margin: 0;
            }
          }
          .content-label {
            font-size: 13px;
            margin: 0;
            color: #424242;
          }
        }
      }
      .item-right {
        width: 50%;
        display: flex;
        flex-direction: row;
        border-left: 1px solid #eeeeee;
        padding: 0 32px;
        margin-bottom: 10px;
        .item-child {
          width: 50%;
          .item-right-child {
            display: flex;
            padding-bottom: 5px;
            .label {
              display: flex;
              align-items: center;
              .icon-copy {
                padding-right: 10px;
                color: #aaaaaa;
              }
              .text-label {
                font-size: 12px;
                color: #777777;
                margin: 0;
              }
            }
            .content-label {
              font-size: 13px;
              padding: 0 10px;
              margin: 0;
              color: #424242;
            }
            .content-label-status {
              display: flex;
              font-weight: 700;
              font-size: 13px;
              padding: 0 5px;
              .label-status {
                margin: 0;
              }
              .status-done {
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                background-color: #159193;
                border-radius: 2px;
                margin: 0 8px 0 0;
                .text-status {
                  margin: 0;
                }
              }
              .status {
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                border-radius: 2px;
                margin: 0 8px 0 0;
                .text-status {
                  margin: 0;
                }
              }
              .edit-icon {
                background-color: #f6a200;
              }
              .stop-icon {
                background-color: #f33456;
              }
            }
            .edit {
              color: #f6a200;
            }
            .stop {
              color: #f33456;
            }
            .check {
              color: #159193;
            }
          }
        }
      }
      .item-dropdown-right {
        width: 80%;
        padding-left: 20px;
      }
      .item-dropdown-left {
        width: 256px;
        height: 144px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e1dcdc;
        .image {
          width: 256px;
          height: 144px;
          object-fit: contain;
        }
        .wrap-image {
          width: 256px;
          height: 144px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #e1dcdc;
          .wrap-no-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            .text {
              color: #6f95aa;
              font-size: 10px;
              font-weight: 700;
            }
          }
        }
      }
    }
    .edit-item {
      &::before {
        content: '';
        border-left: 2px solid #f6ac00;
      }
    }
    .stop-item {
      &::before {
        content: '';
        border-left: 2px solid #f33456;
      }
    }
    .check-item {
      &::before {
        content: '';
        border-left: 2px solid #08a3a5;
      }
    }
    .ant-collapse-header {
      display: unset;
      padding: 0;
      .ant-collapse-arrow {
        position: absolute;
        left: 40px;
        top: 23px;
      }
    }
    .ant-collapse-content {
      background-color: unset;
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    .ant-collapse.ant-collapse-icon-position-left {
      border: none;
      .ant-collapse-item {
        border: none;
        padding: 4px;
      }
    }
  }
  .ant-form-item-label .text-label {
    color: #424242;
    font-size: 12px;
  }
  .ant-select-selector {
    font-family: Lato;
    font-size: 13px;
  }
  .ant-select-selection-item {
    .text-option {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0;
      align-items: center;
      .text-icon {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        background: #f33456;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
    .text-user-type {
      font-size: 13px;
      .text-icon-type {
        color: #ffffff;
        padding: 3px;
        background: #4e83d3;
        border-radius: 2px;
        margin-right: 5px;
      }
    }
    .icon-edit {
      display: flex;
      align-items: center;
      .icon {
        margin-right: 8px;
      }
    }
  }
  .ant-select-item-option-content {
    .text-option {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0;
      align-items: center;
      .text-icon {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        background: #f33456;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
    .icon-edit {
      .icon {
        margin-right: 8px;
      }
    }
  }
  .body .ant-collapse-content {
    border: none;
  }
  .ant-collapse {
    border: none;
    margin-bottom: 5px;
  }
  .ant-collapse-item {
    border: none;
  }

  @media (max-width: 1440px) {
    .form-search {
      .item {
        width: 200px;
        .date-input {
          width: 100%;
        }
      }
      .implement {
        width: 184px;
      }
    }
  }
`;
