import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const exportPDF = (
  ref: React.RefObject<HTMLElement>,
  fileName?: string,
  orientation?: 'p' | 'l',
  allowTaint?: boolean
) => {
  if (!ref.current) return;

  const name = fileName || '所属マスタ.pdf';

  html2canvas(ref.current, {
    useCORS: true,
    scale: 2.5,
    allowTaint: !allowTaint,
    foreignObjectRendering: false,
  }).then(async (canvas) => {
    const contentDataURL = await canvas.toDataURL('image/png');

    const pdf = await new jsPDF(orientation === 'p' ? 'p' : 'l', 'px', 'a4');
    let position = 0;

    const imgWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;

    heightLeft -= pageHeight;
    pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight + 25);
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight + 25);
      heightLeft -= pageHeight;
    }
    pdf.save(name);
  });
};

export default exportPDF;
