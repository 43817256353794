import { createAsyncThunk } from '@reduxjs/toolkit';
import { filter, findIndex, map, omit, uniq } from 'lodash';

import { services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  REPORT_SKILL_CHECK_USER_TRANS,
  DETAIL_OF_SKILL_CHECK_RESULTS,
  SKILL_CHECK_ASSIGN_QUESTION,
  SKILL_CHECK_USERS,
} from 'configs';

export const getReportSkillCheckUserTrans = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType> & {
    isUserTransTable: boolean;
    isShowUserNotImplement: boolean;
  },
  Types.ReportsItemRequestType & { isUserTransTable: boolean; isShowUserNotImplement: boolean },
  Types.ThunkAPI<Types.requestError>
>('SkillCheckUserReport/thunk/getReportSkillCheckUserTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
      REPORT_SKILL_CHECK_USER_TRANS.id,
      omit(req, ['isUserTransTable', 'isShowUserNotImplement'])
    );

    const dataResults: Types.ReportSkillCheckUserTrans.ResponseType[] = [];

    if (req.isShowUserNotImplement && req.isUserTransTable) {
      const { data: dataSkillCheck } = await services.filter<Types.SkillCheckUser.ResponseType>(
        SKILL_CHECK_USERS.id,
        {
          conditions: filter(
            req.conditions,
            (condition) => condition.id === 'company_id' || condition.id === 'skill_check_code'
          ),
          page: 1,
          per_page: 0,
        }
      );

      const uniqueLoginIds = uniq(map(data.report_results, 'login_id'));
      const nonMatchingEmails = filter(
        dataSkillCheck.report_results,
        (item) => !uniqueLoginIds.includes(item.email)
      );

      nonMatchingEmails.forEach((item) => {
        dataResults.push({
          i_id: '',
          company_id: item.company_id,
          skill_check_code: item.skill_check_code,
          user_type: item.user_type,
          skill_check_name: item.skill_check_name,
          login_id: item.email,
          user_name: item.user_name,
          question_time_limit: item.question_time_limit,
          grouping_code: item.grouping_code,
          incorrect_answer: 0,
          correct_answers_num: 0,
          inexperienced: 0,
          probs_count: item.question_count,
          setting_score: item.setting_score,
        });
      });
    }

    return {
      ...data,
      report_results: [...data.report_results, ...dataResults],
      isUserTransTable: req.isUserTransTable,
      isShowUserNotImplement: req.isShowUserNotImplement,
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckUserTransByLoginID = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckUserReport/thunk/getReportSkillCheckUserTransByLoginID',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
        REPORT_SKILL_CHECK_USER_TRANS.id,
        req
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getReportSkillCheckUserTransBySkillCheckCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheckUserTrans.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckUserReport/thunk/getReportSkillCheckUserTransBySkillCheckCode',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.ReportSkillCheckUserTrans.ResponseType>(
        REPORT_SKILL_CHECK_USER_TRANS.id,
        req
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDataSortOrder = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckUserReport/thunk/getDataSortOrder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckAssignQuestion.ResponseType>(
      SKILL_CHECK_ASSIGN_QUESTION.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailOfSkillCheckResults = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DetailOfSkillCheckResults.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckUserReport/thunk/getDetailOfSkillCheckResults',
  async (req, { rejectWithValue, getState, dispatch }) => {
    try {
      const { authContainer } = getState() as RootState;
      const { data } = await services.filter<Types.DetailOfSkillCheckResults.ResponseType>(
        DETAIL_OF_SKILL_CHECK_RESULTS.id,
        req
      );

      const responseStatus = await dispatch(
        getDataSortOrder({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );

      let dataSortOrder: Types.DetailOfSkillCheckResults.ResponseType[] = [];

      if (getDataSortOrder.fulfilled.match(responseStatus)) {
        dataSortOrder = data.report_results.map((e) => {
          const index = findIndex(
            responseStatus.payload.items,
            (val) => val.question_code === e.code && val.skill_check_code === e.skill_check_code
          );
          if (index > -1) {
            return {
              ...e,
              sort_order: Number(responseStatus.payload.items[index].sort_order),
            };
          }

          return e;
        });
      }

      return {
        ...data,
        report_results: [...dataSortOrder.sort((a, b) => a.sort_order - b.sort_order)],
      };
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getSkillCheckUsers = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('SkillCheckUserReport/thunk/getSkillCheckUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckUser.ResponseType>(
      SKILL_CHECK_USERS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
