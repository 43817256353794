import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
  dataEducationProgram: boolean;
  isExportPDF?: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  .table-container {
    position: relative;
  }
  .wrap-checkbox,
  .wrap-checkbox-pdf {
    position: absolute;
    right: 0;
    top: 30px;
  }
  .wrap-checkbox-pdf {
    top: -28px;
  }
  .text-checkbox {
    font-size: 12px;
    color: #424242;
    margin-left: 6px;
  }
  .text-count,
  .text-count-pdf {
    padding-top: 6px;
    display: flex;
    align-items: flex-end;
    .page-select,
    .page-select-pdf {
      display: flex;
      margin-left: 60px;
    }
    .page-select-pdf {
      align-items: center;
    }
  }

  .text-count-pdf {
    align-items: center;
  }

  .text-note {
    font-size: 12px;
    color: #424242;
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
  }
  .form-search {
    display: flex;
    align-items: center;
    .item {
      width: 23%;
      margin-right: 8px;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
      margin-right: 12px;
      display: block;
      width: 60px;
      margin-left: 10px;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .table,
  .table-pdf {
    position: relative;
    margin-bottom: 80px;
    margin-top: ${(props) => (props.dataEducationProgram ? 0 : 65)}px;
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        text-align: center;
        padding: 11px 16px;
        &:before {
          display: none;
        }
        &:nth-child(6),
        &:nth-child(7) {
          background-color: #00a3a5;
          color: #ffffff;
          border-left: 1px solid #d0e2e2;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: Lato;
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
        word-break: break-word;
        text-align: center;
        padding: 10px 16px;
        vertical-align: top;
        &:nth-child(6),
        &:nth-child(7) {
          color: #159193;
          border-left: 1px solid #d0e2e2;
          text-align: left;
        }
        .list-item {
          padding-left: 10px;
        }
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
    }
    .ant-pagination-item-active {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
  }

  .table-pdf {
    margin-top: 13px;
    &:nth-child(6),
    .ant-table-thead {
      .ant-table-cell {
        &:nth-child(5) {
          background-color: #00a3a5;
          color: #ffffff;
          border-left: 1px solid #d0e2e2;
        }
      }
    }
  }
  .btn-div {
    display: flex;
    position: absolute;
    right: 26px;
    justify-content: space-between;
    margin-top: 10px;
    z-index: 9;
    .btn {
      padding: 0 30px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      margin-right: 8px;
      font-weight: 700;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      display: flex;
      align-items: center;
      .text-icon {
        margin-right: 12px;
        font-size: 18px;
      }
    }
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;

      font-size: 13px;
      margin-right: 8px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .btn-outline {
      position: relative;
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      p {
        color: #f1a900;
        margin: 0;
      }
      .icon {
        color: #f6ac00;
        position: absolute;
        right: 15px;
      }
    }
  }
  .ant-table .ant-table-container::before {
    content: '${(props) => (props.isExportPDF ? '' : 'ALL')}';
    margin-left: 15px;
    margin-top: 9px;

    font-size: 11px;
  }
  .ant-table-thead .ant-checkbox-wrapper {
    padding-top: 10px;
  }
`;
