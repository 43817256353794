import styled from 'styled-components';

interface Props {
  isCheckUpload: boolean;
}

export default styled.section<Props>`
  p {
    margin-right: 5px;
    overflow-wrap: break-word;
    white-space: break-spaces;
  }
  .jodit-toolbar-button_image,
  .jodit-toolbar-button_insertVideo {
    pointer-events: ${(props) => (props.isCheckUpload ? 'none' : '')};
  }
  .container {
    margin: 32px 30px;
    .disabled {
      margin-right: 35px;
      text-decoration-line: underline;
      font-size: 12px;
      cursor: not-allowed !important;
      pointer-events: none !important;
      color: #777777 !important;
      .icon-edit {
        font-size: 16px;
        padding-right: 5px;
      }
      .icon-delete {
        font-size: 16px;
        padding-right: 5px;
      }
    }
    .recommended {
      background: #ffffff;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      .body {
        padding: 24px;
        .wrap-content {
          display: flex;
          justify-content: space-between;
          width: 100%;
          .left-body {
            width: 92%;
            .user {
              display: flex;
              .department {
                color: #777777;
                font-size: 11px;
              }
              .name {
                font-size: 13px;
              }
            }
            .content {
              white-space: break-spaces;
            }
          }
          .time {
            font-size: 12px;
            color: #999999;
          }
        }
        .wrap-action {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 20px;
          .files {
            display: flex;
            margin-left: 48px;
          }
          .action .text {
            text-decoration: underline;
          }
          .file {
            background: #f4f4f4;
            border-radius: 16px;
            padding: 8px 18px;
            color: #08a3a5;
            font-size: 13px;
            text-decoration: underline;
            margin-right: 15px;
            cursor: pointer;
          }
          .edit-delete {
            display: flex;
            margin-right: 8px;
            color: #00989a;
            text-decoration: underline;
            font-size: 12px;
            cursor: pointer;
            .edit {
              margin-left: 24px;
            }
            .icon {
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
        .content {
          white-space: none;
          margin: 20px 0 0 48px;
        }
      }
    }
    .header-recommend {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      .wrap-badge {
        display: flex;
        .to-username {
          display: inline-flex;
          align-items: center;
        }
      }

      .left-side span:nth-of-type(1) {
        color: #1e7ed6;
        font-size: 11px;
      }
      .left-side span:nth-of-type(2) {
        color: #2a2a2a;
        font-size: 17px;
      }
      .icon {
        color: #cccccc;
        margin: 0 16px;
        font-size: 18px;
      }
      .left-side {
        width: 70%;
        position: relative;
        padding: 9px 21px 19px 21px;
        border-right: 1px solid #dddddd;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 95%;
          left: 3px;
          border-radius: 4px;
          top: 2%;
          background: #1e7ed6;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        width: 30%;
        padding: 14px 32px;
        .from {
          display: flex;
          align-items: center;
          .department {
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #777777;
            font-size: 11px;
          }
          .name {
            max-width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
          }
        }
        .badge {
          width: max-content;
          background: #08a3a5;
          color: #ffffff;
          border-radius: 2px;
          padding: 2px;
          font-size: 12px;
        }
      }
    }
    .wrap-title {
      display: flex;
      align-items: center;
      .button {
        border-radius: 3px;
      }
    }
    .wrap-button {
      display: flex;
      align-items: flex-end;
    }
    .wrap-label {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .hasLike {
        color: #08a3a5;
      }
      .star {
        color: #777777;
        margin-left: 4px;
      }
      .icon-star,
      .star-gold {
        font-size: 16px;
      }
      .star-gold {
        color: #f6ac00 !important;
      }

      .export {
        border: 1px solid #f6ac00;
        color: #f6ac00;
        font-weight: 700;
        padding: 8px 13px;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        height: 36px;
        margin-left: 20px;
      }
    }
    .label {
      background: #f03060;
      display: inline;
      padding: 7px 8px;
      color: #ffffff;
      font-weight: 700;
      border-radius: 4px;
      margin-right: 24px;
      .icon {
        margin-right: 8px;
      }
    }
    .check {
      background: #1fb9db;
    }
    .goback {
      margin-right: 8px;
      color: #777777;
      cursor: pointer;
    }
    .title-answer {
      color: #08a3a5;
    }
    .wrap-answer {
      margin-top: 28px;
      .icon-message {
        font-size: 24px;
        margin-right: 9px;
      }
      .title {
        font-size: 16px;
      }
      .add-question {
        width: 100%;
        text-align: center;
        margin-top: 24px;
        button {
          border: 1px solid #00a3a5;
          color: #00a3a5;
          height: 40px;
          width: 180px;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
        }
      }
      .edit-delete {
        display: flex;
        margin-right: 8px;
        color: #00989a;
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        .edit {
          margin-left: 24px;
        }
        .icon {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .body {
        height: 100%;
        background: #ffffff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin-top: 8px;
        padding: 0 24px 24px 24px;
        position: relative;
        margin-bottom: 8px;
        .form-add {
          position: relative;
          border-top: 1px solid #eeeeee;
          .answer-label-additional {
            background: #eeeeee;
            color: #999999;
            font-weight: 700;
            position: absolute;
            top: -18px;
            left: 45%;
            padding: 6px 44px;
          }
        }
        .not-answer {
          background: rgba(240, 48, 96, 0.1);
          border: 1px solid rgba(240, 48, 96, 0.3);
          border-radius: 0 5px;
          padding: 6px 14px;
          color: #f03060;
          position: absolute;
          top: 0;
          right: -24px;
        }
        .user {
          display: flex;
          padding-top: 27px;
        }
        .content {
          margin-top: 20px;
          margin-left: 48px;
          white-space: break-spaces;
        }
        .wrap-input {
          margin-top: 20px;
          padding-left: 48px;
        }
        .wrap-jodit {
          margin-top: 20px;
          padding-left: 48px;
          .jodit-react-container {
            height: 200px !important;
            .jodit-container {
              height: 100% !important;
            }
          }
        }
        .text-area {
          max-width: 100%;
        }
        .answer-question {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding-top: 24px;
          border-top: 1px solid #eeeeee;
          .time {
            font-size: 12px;
            color: #999999;
            margin-top: 20px;
          }
        }
        .comment {
          position: relative;
          .wrap-action {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .action .text {
              text-decoration: underline;
            }
            .edit-delete {
              display: flex;
              align-items: center;
              margin-right: 8px;
              color: #00989a;
              text-decoration: underline;
              font-size: 12px;
              cursor: pointer;
              .edit {
                margin-left: 24px;
                background: unset;
                border: unset;
                cursor: pointer;
              }
              .icon {
                font-size: 16px;
                margin-right: 5px;
              }
            }
          }
          .has-answer {
            background: rgba(31, 185, 219, 0.1);
            border: 1px solid rgba(31, 185, 219, 0.3);
            border-radius: 0 5px;
            padding: 6px 14px;
            color: #1fb9db;
            position: absolute;
            top: 0;
            right: -24px;
            .icon {
              margin-right: 5px;
            }
          }
          .question {
            padding-top: 27px;
            display: flex;
            justify-content: space-between;
            .left-question {
              width: 92%;
            }
            .time {
              font-size: 12px;
              color: #999999;
              margin-top: 20px;
            }
          }
          .answer-label {
            background: #eeeeee;
            color: #999999;
            font-weight: 700;
            position: absolute;
            top: -18px;
            left: 45%;
            padding: 6px 44px;
          }
          .item-answer {
            width: 93%;
          }
          .add-answer {
            border: 1px solid #00a3a5;
            color: #00a3a5;
            padding: 14px 50px;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
          }
          .wrap-button-add,
          .wrap-add-answer {
            display: flex;
            justify-content: end;
            padding-bottom: 32px;
            margin-top: 39px;
            .edit-delete {
              display: flex;
              align-items: center;
              .edit {
                background: unset;
                border: unset;
                cursor: pointer;
              }
            }
            .add {
              display: inline-flex;
              align-items: center;
              background: #f6ac00;
              color: #ffffff;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 60px;
              font-weight: 700;
              font-size: 15px;
              height: 48px;
              margin-right: 8px;
            }
            .disable {
              display: inline-flex;
              align-items: center;
              background: #eeeeee;
              color: #bbbbbb;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 60px;
              font-weight: 700;
              font-size: 15px;
              height: 48px;
              margin-right: 8px;
            }
            .cancle {
              display: inline-flex;
              align-items: center;
              background: #ffffff;
              color: #777777;
              border: 1px solid #d9d9d9;
              height: 48px;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 12px;
            }
          }
          .has-file-question {
            margin-bottom: 20px;
          }
          .has-file {
            display: flex;
            justify-content: space-between;
            margin-left: 48px;
            margin-top: 20px;
            .file {
              background: #f4f4f4;
              border-radius: 16px;
              padding: 8px 18px;
              color: #08a3a5;
              font-size: 13px;
              text-decoration: underline;
              cursor: pointer;
            }
            .edit-delete {
              display: flex;
              align-items: center;
              .edit {
                background: unset;
                border: unset;
                cursor: pointer;
              }
            }
          }
          .wrap-add-answer {
            display: flex;
            justify-content: space-between;
            .wrap-upload {
              width: 30%;
              margin-left: 48px;
              .title {
                text-align: left;
                color: #999999;
                font-size: 13px;
                padding-bottom: 4px;
                span {
                  color: #424242;
                }
              }
              .form-upload {
                display: flex;
                align-items: center;
                width: 100%;
                .ant-upload.ant-upload-drag {
                  background: none !important;
                  border: none !important;
                }
                .wrap-dragger {
                  width: 100%;
                }
              }
              .disabled {
                pointer-events: none;
              }
            }
          }
          .file-name {
            display: flex;
            align-items: center;
            padding-left: 12px;
            width: 100%;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cccccc;
            box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
            border-radius: 3px;
            color: #cccccc;
          }
          .button-upload {
            background: #00a3a5;
            height: 40px;
            width: 15%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .ant-upload {
              width: 100%;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
`;
