import styled from 'styled-components';

export default styled.div`
  position: relative;
  .legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: -30px;
    right: 0;
    .sort-column {
      display: flex;
      align-items: center;
      margin-left: 50px;
      .legend-filter {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
      .blue {
        background-color: #44a3d7;
      }
      .pink {
        background-color: #ea8d93;
      }
      .gray {
        background-color: #ebebeb;
      }
      .legend-name {
        font-size: 12px;
        color: #424242;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    .table {
      width: 30%;
      .ant-table-cell {
        padding: 8px;
      }
      .table-row-hightlight {
        background: #f9f9f9;
      }
    }
    .chart {
      width: 70%;
      div:first-child {
        top: -20px;
        border-bottom: 1px solid #dddddd;
      }
      .header-column {
        width: 100%;
        height: 62px;
        background: #f7f7f7;
      }
      .space-x {
        margin-left: 10px;
      }
    }
  }
`;
