import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  background-color: #f8f8f8;
  min-height: 100%;

  .ant-tooltip .ant-tooltip-inner {
    background-color: #ffffff !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 16px 7px !important;
  }
  .container {
    .wrap-select-record {
      display: flex;
      column-gap: 60px;
      .label-select {
        font-size: 11px;
        color: #424242;
      }
    }
    .select-option {
      width: 66px;
      height: 28px;
      .ant-select-arrow {
        top: 55%;
      }
      .ant-select-selector {
        border-radius: 3px;
      }
    }
    position: relative;
    width: 100%;
    padding: 30px;
    .text-note {
      font-size: 12px;
      color: #424242;
    }
    .border-line {
      border-top: 1px solid #dddddd;
      width: 40px;
      margin: 30px 0;
    }
    .form-search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      .item {
        width: 25%;
        padding: 0 4px;
        margin-bottom: 10px;
        .input-question-name {
          border-radius: 2px;
          height: 32px;
          padding: 0 11px;
          border: 1px solid #d9d9d9;
        }
      }
      .wrap-submit-search {
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-left: 12px;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
    .item-table {
      position: relative;
    }
    .item-checkbox {
      width: 160px;
      padding: 5px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 3px 3px 0 0;
      position: absolute;
      right: 0;
      top: 30px;
    }
    .table {
      padding-bottom: 95px;
      .text-count {
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        z-index: 9;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
      .ant-table .ant-table-container::before {
        content: 'ALL';
        margin-left: 7px;
        margin-top: 9px;
        font-size: 11px;
      }
      .title-table {
        font-size: 10px;
        color: #777777;
        margin-bottom: 0;
        font-weight: 400;
      }

      .wrap-code {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .text-code {
          font-family: Lato;
          font-size: 11px;
          color: #999999;
          margin-bottom: 0;
        }
        .icon {
          margin: 0 2px;
        }
        .icon-required {
          width: 16px;
        }
        .official {
          margin: 0 5px 0 5px;
          padding: 1px 3px;
          color: #0a9fbf;
          text-align: center;
          font-family: Lato;
          font-size: 11px;
          border-radius: 2px;
          border: 1px solid rgba(31, 185, 219, 0.6);
          background: rgba(31, 185, 219, 0.1);
        }
      }
      .text-title {
        font-family: Lato;
        font-size: 10px;
        color: #424242;
        margin-bottom: 0;
      }
      .ant-table-thead .ant-checkbox-wrapper {
        padding-top: 10px;
      }
      .ant-table-thead {
        border-radius: 2px 2px 0 0;
        .ant-table-cell {
          background-color: #ebebeb;
          font-weight: 700;
          font-size: 12px;
          color: #2a2a2a;
          margin: 0 3px;
          &:before {
            display: none;
          }
        }
      }
      .ant-table-thead .ant-table-cell.publish {
        font-size: 10px;
        color: #777777;
      }
      .ant-table-tbody {
        .ant-table-cell {
          font-family: Lato;
          font-size: 13px;
          color: #424242;
          word-wrap: break-word;
        }
        .icon {
          color: #c4c4c4;
          font-size: 17px;
          cursor: pointer;
        }
        & > tr.ant-table-row-selected > td {
          background-color: #fdf7ea;
        }
      }
      .ant-table-tbody .ant-table-cell.publish {
        text-align: center;
      }
    }
    .wrap-bottom {
      height: 95px;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 30px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .flex {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .text-label {
        font-size: 13px;
        color: #777777;
        display: flex;
        align-items: center;
      }
      .btn {
        cursor: pointer;
        font-size: 13px;
        font-weight: 700;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
      }
      .btn-active {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
      }
      .btn-outline {
        background-color: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f1a900;
        .icon {
          color: #f6ac00;
          transform: rotate(-90deg);
        }
      }
    }
  }
`;
