import styled from 'styled-components';

import { HomeCardColour } from 'constant/enum.constant';

interface Props {
  cardColour: HomeCardColour;
}

export const Wrapper = styled.div<Props>`
  @media (max-width: 1440px) {
    .ant-card {
      width: 290px !important;
      height: 747px !important;
    }
    .ant-card-head-title {
      padding: 26px 0;
    }
    .ant-card .ant-card-body .content {
      padding: 0 20px 49px 20px !important;
    }
    .ant-card .ant-card-body .content-purple {
      padding: 0 20px 49px 20px !important;
    }
  }
  .ant-card .ant-card-body .dropdown {
    height: 40px !important;
  }
  .ant-card {
    font-family: 'Oxygen';
    padding: 2px 2px;

    .ant-card-head {
      border-top: 2px solid
        ${(props) =>
          props.cardColour === HomeCardColour.green
            ? '#3FC2A3'
            : props.cardColour === HomeCardColour.blue
            ? '#089AD4'
            : '#BD85D7'};
      // letter-spacing: -0.1em;
      border-bottom: none;
      padding: 0px 13px;
      .header-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title {
          font-size: 18px;
          color: #424242;
          line-height: 14px;
        }
        .sub-title-admin {
          font-size: 12px;
          color: #777777;
        }
      }
    }

    .ant-card-body {
      padding: 0;

      .footer {
        padding: 24px 24px 12px 24px;

        .footer-text {
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 15px 10px 0 10px;
          color: rgba(102, 102, 102, 0.8);
          font-weight: 400;
          font-size: 12px;
        }
      }
      .content-purple {
        padding: 0 20px 26px 20px;
        border-bottom: 1px solid #e5e5e5;
      }
      .content {
        padding: 0 24px 30px 24px;
        border-bottom: 1px solid #e5e5e5;
      }

      .dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background: #ffffff;
        border: 1px solid rgba(57, 72, 72, 0.3);
        border-radius: 2px;
        padding: 7px 10px;
        cursor: pointer;
        .drop-down-label {
          cursor: pointer;
        }
      }

      .shadow {
        width: 55px;
        margin-left: 133px;
        margin-top: 7px;
        height: 6px;
        transform: perspective(3px) rotateX(304deg);
        background: linear-gradient(
          65deg,
          rgba(196, 196, 196, 0.24) 10.69%,
          rgba(196, 196, 196, 0) 72.56%
        );
      }
    }
  }
`;
