import styled from 'styled-components';

import { HEADER_HEIGHT } from 'constant';

interface Props {
  pageYOffset: number;
}

export default styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  position: sticky;
  top: ${HEADER_HEIGHT}px;
  margin-top: -190px;
  right: 0;
  z-index: 99;
  background: #ffffff;
  .ant-form {
    margin-top: 20px;
    .card-tab-pane {
      padding: 0 18px;
      .title-bold {
        font-family: Lato;
        font-size: 12px;
        text-align: center;
        color: #424242;
        margin-bottom: 18px;
        font-weight: 700;
      }
      .text-content {
        font-size: 12px;
        text-align: center;
        color: #424242;
      }
      .form-input {
        margin-bottom: 5px;
        .label {
          font-size: 12px;
          color: #888888;
          &-long {
            letter-spacing: -0.1em;
          }
        }
        .select-input {
          font-family: Lato;
        }
      }
    }
    .wrap-center {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-right: 12px;
        border: none;
        background: none;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
  }
  .result-search {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 450px + ${HEADER_HEIGHT}px);
    padding-top: 56px;
    .left-side {
      width: 12%;
      text-align: center;
      border-right: 1px solid #e5e5e5;
      .imageQA {
        margin-top: 24px;
        font-size: 26px;
        color: #c4c4c4;
        cursor: pointer;
      }
    }
    .right-side {
      width: 88%;
      background: #f5f5f5;
      border-top: 1px solid #e5e5e5;
      .result {
        height: calc(100% - 55px);
        overflow-y: auto;
        .text-result {
          color: #bbbbbb;
          text-align: center;
          margin-top: 10px;
          z-index: -1;
        }
      }
      .footer {
        display: flex;
        align-items: center;
        background-color: #ffffff;
        justify-content: center;
        height: 55px;
        .btn,
        .disabled {
          border: none;
          background: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          cursor: pointer;
          padding: 4px 7px;
          .icon {
            font-size: 15px;
            margin-right: 10px;
          }
        }
        .disabled {
          background: #cccccc !important;
          color: #777777 !important;
        }
      }
    }
  }
  .ant-tabs-tab {
    border: none;
  }
  .ant-tabs-tab-btn {
    font-weight: bold;
    font-size: 12px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #00a3a5;
  }
  .ant-form-item-label {
    text-align: left;
  }
`;

interface ItemProps {
  isDragging: boolean;
  isChecked?: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  background: ${(props) => (props.isChecked ? '#fff8f4' : '#ffffff')};
  border: 1px solid ${(props) => (props.isChecked ? '#f1b796' : '#ffffff')};
  height: 36px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  border-radius: 2px;
  font-size: 11px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isChecked ? '#eb8b55' : '#999999')};
  margin: 6px;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  .text-bold {
    margin-left: 5px;
    font-weight: ${(props) => (props.isChecked ? 'bold' : 'normal')};
    color: ${(props) => (props.isChecked ? '#eb8b55' : '#000000')};
    width: 35%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .text-code {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 30%;
  }
  .icon {
    width: 26px;
  }
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.active ? '#F6AC00' : '#d9d9d9')};
  border-radius: 4px;
  border: none;
  margin: 4px auto;
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
  .label-icon {
    color: ${(props) => (props.active ? '#FFFFFF' : '#666666')};
  }
`;
