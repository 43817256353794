import React, { useEffect, useState } from 'react';
import { CloudUploadOutlined, DeleteOutlined, ZoomInOutlined } from '@ant-design/icons';
import { UploadChangeParam, UploadFile, RcFile } from 'antd/lib/upload/interface';
import { MessageFormatElement, useIntl } from 'react-intl';
import { FormikProvider, useFormik } from 'formik';
import { Image as ImageAntd, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { isEmpty } from 'lodash';

import { deletedFileInMinIO, sharedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { createEditCurriculumSchema } from 'libs/validations';
import { convertFileResponse } from 'libs/utils/question';
import { authSelector } from 'containers/Auth/selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { curriculumSelector } from '../../selectors';
import { Input, Modal, TextArea } from 'components';
import { FileType } from 'constant/enum.constant';
import { AddCurriculumFormik } from 'types';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  createLevelCurriculum,
  getCurriculumMaster,
  createCurriculum,
  createFileAttach,
  deleteFileAttach,
  updateCurriculum,
  getCurriculum,
} from '../../thunk';

interface Props {
  id?: string;
  name?: string;
  page?: number;
  visible: boolean;
  per_page?: number;
  description?: string;
  maxSortOrder?: number;
  title?: React.ReactNode;
  type: 'create' | 'edit';
  subTitle?: React.ReactNode;
  data?: Types.CurriculumItemType;
  fileCurriculum?: UploadFile<File>;
  textSubmit?: string | MessageFormatElement[] | undefined;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Dragger } = Upload;

const CreateEditCurriculum: React.FC<Props> = ({
  id,
  type,
  name,
  data,
  title,
  page,
  visible,
  subTitle,
  per_page,
  textSubmit,
  setVisible,
  description,
  maxSortOrder,
  fileCurriculum,
}) => {
  const [isShowImage, setIsShowImage] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const { isDisabledUpload } = useCheckUpload();
  const { messages } = useIntl();

  const formik = useFormik<AddCurriculumFormik>({
    initialValues: {
      curriculumName: name || '',
      description: description || '',
      file: fileCurriculum,
    },
    enableReinitialize: true,
    validationSchema: createEditCurriculumSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!values.curriculumName) return;
      let fileID = '';

      dispatch(startLoading());
      if (!isEmpty(values.file) && values.file.uid !== data?.fileID) {
        if (data?.fileID) {
          deletedFileInMinIO(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
        const uploadToMinIo = await uploadFileToMinIO(values.file);
        await dispatch(
          createFileAttach({
            item: {
              fileID: uploadToMinIo,
              filename: values.file?.name,
              file_location: '1',
              file_extension: values.file?.type,
              file_size: `${values.file?.size}`,
              company_id: userInfo?.company_id,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
          })
        );
        fileID = uploadToMinIo;

        //   const uploadFileToS3Result = await dispatch(uploadFileToS3({ formData }));
        //   if (uploadFileToS3.fulfilled.match(uploadFileToS3Result)) {
        //     file = uploadFileToS3Result;
        //     const createFileAttachResult = await dispatch(
        //       createFileAttach({
        //         item: {
        //           fileID: uploadFileToS3Result.payload.file_id,
        //           filename: values.file?.name,
        //         },
        //       })
        //     );
        //     if (createFileAttach.fulfilled.match(createFileAttachResult)) {
        //       dispatch(
        //         executeAction({
        //           itemId: createFileAttachResult.payload.item_id,
        //           data: {
        //             changes: [
        //               {
        //                 id: 'file',
        //                 value: [uploadFileToS3Result.payload.file_id],
        //               },
        //             ],
        //             use_display_id: true,
        //             is_force_update: true,
        //           },
        //         })
        //       );
        //     }
        //   }
      }
      if (isEmpty(values.file)) {
        if (data?.fileID) {
          deletedFileInMinIO(data.fileID);
          dispatch(
            deleteFileAttach({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [data.fileID],
                },
              ],
              use_display_id: true,
            })
          );
        }
      }
      if (type === 'create') {
        const resultAction = await dispatch(
          createCurriculum({
            item: {
              company_id: userInfo?.company_id,
              name: values.curriculumName,
              description: values.description,
              publish: 0,
              required_curriculum: 0,
              sort_order: maxSortOrder,
              probs_count: 0,
              createdat: new Date(),
              createdby: userInfo?.login_id,
              creator: userInfo?.login_id,
              fileID: fileID,
              official_curriculum: 0,
            },
            return_item_result: true,
            return_display_id: true,
          })
        );
        if (createCurriculum.fulfilled.match(resultAction) && resultAction.payload.item.code) {
          const resultLevel1 = await dispatch(
            createLevelCurriculum({
              level: 1,
              item: {
                company_id: userInfo?.company_id,
                name: '',
                sort_order: 1,
                curricullum_code: resultAction.payload.item.code,
              },
              return_item_result: true,
              return_display_id: true,
              realtime_auto_link: true,
            })
          );
          if (
            createLevelCurriculum.fulfilled.match(resultLevel1) &&
            resultLevel1.payload.item.code
          ) {
            const resultLevel2 = await dispatch(
              createLevelCurriculum({
                level: 2,
                item: {
                  company_id: userInfo?.company_id,
                  level1_code: resultLevel1.payload.item.code,
                  name: '',
                  sort_order: 1,
                  curricullum_code: resultAction.payload.item.code,
                },
                return_item_result: true,
                return_display_id: true,
                realtime_auto_link: true,
              })
            );
            if (
              createLevelCurriculum.fulfilled.match(resultLevel2) &&
              resultLevel2.payload.item.code
            ) {
              const resultLevel3 = await dispatch(
                createLevelCurriculum({
                  level: 3,
                  item: {
                    company_id: userInfo?.company_id,
                    level2_code: resultLevel2.payload.item.code,
                    name: '',
                    sort_order: 1,
                    curricullum_code: resultAction.payload.item.code,
                  },
                  return_item_result: true,
                  return_display_id: true,
                  realtime_auto_link: true,
                })
              );
              if (
                createLevelCurriculum.fulfilled.match(resultLevel3) &&
                resultLevel3.payload.item.code
              ) {
                await dispatch(
                  createLevelCurriculum({
                    level: 4,
                    item: {
                      company_id: userInfo?.company_id,
                      level3_code: resultLevel3.payload.item.code,
                      name: '',
                      sort_order: 1,
                      curricullum_code: resultAction.payload.item.code,
                    },
                    return_item_result: true,
                    return_display_id: true,
                    realtime_auto_link: true,
                  })
                );
              }
            }
          }
        }
      } else {
        if (id) {
          await dispatch(
            updateCurriculum({
              id,
              data: {
                item: {
                  name: values.curriculumName,
                  description: values.description,
                  ...(fileID ? { fileID: fileID } : !values.file ? { fileID: '' } : null),
                  updatedat: new Date(),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
        }
      }

      await Promise.all([
        dispatch(
          getCurriculum({
            conditions: [
              ...filter_conditions.conditions,
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCurriculumMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: page || 1,
            per_page: per_page || 10,
          })
        ),
      ]);

      await dispatch(stopLoading());
      onClose();
    },
  });

  const onClose = async () => {
    await setVisible(false);
    formik.resetForm();
  };

  const beforeUpload = async (file: RcFile) => {
    const isJpgOrPngOrMp4 = file.type === FileType.PNG || file.type === FileType.JPEG;

    const isAccept = await new Promise<boolean>((resolve) => {
      const src = URL.createObjectURL(new Blob([file]));
      if (file.type === FileType.PNG || file.type === FileType.JPEG) {
        const image = new Image();
        image.src = src;
        image.onload = function () {
          URL.revokeObjectURL(src);
          resolve(file.size < 10485760);
        };
      }
    });

    if (!isJpgOrPngOrMp4 || !isAccept) {
      alert(`※画像はjpegまたはpngである必要があり、最大10MBまで可能です。
ビデオはmp4である必要があり、最大100MBにすることができます。`);
    }

    return isJpgOrPngOrMp4 && isAccept ? false : Upload.LIST_IGNORE;
  };

  useEffect(() => {
    (async () => {
      const fileQuestion: UploadFile<File> = { uid: '', name: '' };
      dispatch(startLoading());
      if (data?.fileID) {
        const fileName = extractFileName(data?.fileID);
        const nodeFileUrl = await sharedFileInMinIO(data?.fileID);
        const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);
        Object.assign(
          fileQuestion,
          convertFileResponse({
            file: fileFromUrl,
            fileID: data?.fileID,
            fileName: fileName,
          })
        );
        formik.setFieldValue('file', fileQuestion);
      }
      dispatch(stopLoading());
    })();
  }, [dispatch, data?.fileID, visible]);

  return (
    <Modal
      title={title}
      visible={visible}
      width={720}
      okButton={{
        text: textSubmit,
        onClick: formik.handleSubmit,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: messages['M-21-54'],
        onClick: onClose,
      }}
      onCancel={onClose}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">{subTitle}</p>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            className="form"
          >
            <Form.Item
              name="curriculumName"
              label={
                <span className="text-label">
                  {messages['M-21-50']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="curriculumName"
                className="input"
                type="text"
                placeholder="全角：最大30文字"
                showCount
                maxLength={30}
              />
            </Form.Item>
            <Form.Item
              name="description"
              label={<span className="text-label"> {messages['M-21-52']}</span>}
              className="form-input"
            >
              <TextArea
                name="description"
                className="input-TextArea"
                rows={4}
                showCount
                maxLength={100}
                placeholder={messages['M-21-51'] as string}
              />
            </Form.Item>
          </Form>
        </FormikProvider>
        <div className="label-image">表紙画像</div>
        <div className="form-upload">
          <div className="file-upload">
            <div>
              <Dragger
                disabled={!isDisabledUpload}
                name="file"
                accept=".jpeg, .png, .mp4"
                beforeUpload={beforeUpload}
                onChange={(info: UploadChangeParam<UploadFile<File>>) => {
                  formik.setFieldValue('file', info.file);
                }}
              >
                {formik.values.file ? (
                  <div className="info-file">
                    <p className="name-image">{formik.values.file.name.split(',')}</p>
                    <ImageAntd
                      onClick={(e) => e.stopPropagation()}
                      className="image-file"
                      src={URL.createObjectURL(
                        new Blob([(formik.values.file.originFileObj ?? formik.values.file) as Blob])
                      )}
                      alt={formik.values.file.name}
                      preview={{
                        visible: isShowImage,
                        src: URL.createObjectURL(
                          new Blob([
                            (formik.values.file.originFileObj ?? formik.values.file) as Blob,
                          ])
                        ),
                        onVisibleChange: (value) => {
                          setIsShowImage(value);
                        },
                        mask: (
                          <ZoomInOutlined
                            className="zoom-image"
                            onClick={() => setIsShowImage(!isShowImage)}
                          />
                        ),
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <CloudUploadOutlined className="icon" />
                    <p className="ant-upload-text">
                      アップロードするファイルをここにドロップ
                      <br />
                      または
                    </p>
                  </>
                )}
                <div className="wrap-button-upload">
                  <button type="button" className="btn-upload">
                    {formik.values.file ? 'ファイルを変更' : 'ファイルを選択'}
                  </button>
                  {formik.values.file ? (
                    <button
                      className="btn-delete"
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        formik.setFieldValue('file', undefined);
                      }}
                    >
                      <DeleteOutlined className="icon-delete-outlined" />
                      <span className="text-delete-outlined">ファイルを削除</span>
                    </button>
                  ) : null}
                </div>
              </Dragger>
            </div>
          </div>
        </div>

        <p className="text-content">
          ※画像は16:4でjpegまたはpngで、10MBまで。
          <br />
          ※画像はユーザがOFFICIALカリキュラムを管理する際に表紙として表示されます。
        </p>
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditCurriculum;
