import React, { useCallback, useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { Formik, FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import { useIntl } from 'react-intl';
import { Select, Table } from 'antd';
import saveAs from 'file-saver';
import {
  CloudDownloadOutlined,
  DeleteOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import ConfirmDeletePositionModal from 'components/Modal/ConfirmDeletePosition';
import { HEADER_JOB_TITLE_MASTER_CSV } from 'constant/header.export.constant';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { getAffiliationAssignRole } from '../../Manual/thunk';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateEditJobTitleSchema } from 'libs/validations';
import { Header, SelectField, TextArea } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { positionMasterSelector } from './selectors';
import FileExportInvoicePDF from './FileExportPDF';
import { resetListRankOrder } from './slice';
import RolesMasterStyled from './styles';
import { services } from 'services';
import { POSITIONS } from 'configs';
import * as Types from 'types';
import {
  createItemPositionMaster,
  deleteItemPositionMaster,
  getPositionMaster,
  getPositionMasterCSV,
  getRankOrders,
  searchPositionMaster,
  updateItemPositionMaster,
} from './thunk';

const { Option } = Select;

const PER_PAGE = 10;

const PositionMaster: React.FC = () => {
  const [openModalConfirmDeleteItem, setOpenModalConfirmDeleteItem] = useState<boolean>(false);
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [itemSelected, setItemSelected] = useState<Types.Positions.ResponseType>();
  const [itemDeleted, setItemDeleted] = useState<Types.Positions.ResponseType>();
  const [completedModalTitle, setCompletedModalTitle] = useState<string>('');
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [idItem, setIdItem] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [actionModalState, setActionModalState] = useState<{
    subTitle: string;
    description: React.ReactNode;
  }>({
    subTitle: '',
    description: '',
  });

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { listPositionMaster, listRankOrder, total, searchResult, affiliationAssignRole } =
    useSelector(positionMasterSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();
  const { messages } = useIntl();

  const formikSearch = useFormik<Types.SearchRolesMasterFormik>({
    initialValues: {
      name: '',
    },
    onSubmit: async ({ name }) => {
      if (!name) {
        setActionModalState({
          subTitle: '検索するマスタが未選択です',
          description: (
            <p className="text-content">
              検索する役職を選択後、
              <br />
              「検索」をクリックしてください。
            </p>
          ),
        });
        setShowActionErrorModal(true);
      } else {
        dispatch(startLoading());
        await dispatch(
          getPositionMaster({
            page: 1,
            per_page: PER_PAGE,
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'name',
                search_value: [name],
                exact_match: true,
              },
            ],
            sort_field_id: 'rank_order',
            sort_order: 'asc',
          })
        );
        dispatch(stopLoading());
      }
    },
    onReset: async () => {
      dispatch(startLoading());
      await dispatch(
        getPositionMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: PER_PAGE,
          sort_field_id: 'rank_order',
          sort_order: 'asc',
        })
      );
      dispatch(stopLoading());
    },
  });

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const isEditing = (record: Types.Positions.ResponseType) => record.i_id === idItem;

  const handleCancel = () => {
    setIdItem('');
    setItemSelected(undefined);
  };

  const handleEditItem = (record: Types.Positions.ResponseType) => {
    const { i_id } = record;
    setIdItem(i_id);
  };

  const handleDeleteItem = (item: Types.Positions.ResponseType) => {
    const index = affiliationAssignRole.findIndex(
      (value: Types.AffiliationAssignRole.ResponseType) =>
        value?.lookup_items?.position_codes?.i_id === item?.i_id
    );
    if (permissionNumber !== 1) {
      setItemDeleted(index <= -1 ? item : undefined);
      setOpenModalConfirmDeleteItem(true);
    }
  };

  const columns: any = [
    {
      key: 'rank_order',
      title: messages['M-07-11'],
      dataIndex: 'rank_order',
      width: '13%',
      align: 'center',
      editable: true,
      sorter: (a: Types.Positions.ResponseType, b: Types.Positions.ResponseType) =>
        Number(a.rank_order) - Number(b.rank_order),
    },
    {
      key: 'code',
      title: messages['M-07-12'],
      dataIndex: 'code',
      width: '10%',
      sorter: (a: Types.Positions.ResponseType, b: Types.Positions.ResponseType) =>
        Number(a.code) - Number(b.code),
    },
    {
      key: 'name',
      title: messages['M-07-13'],
      dataIndex: 'name',
      width: '60%',
      editable: true,
      ellipsis: true,
    },
    {
      title: messages['M-07-14'],
      dataIndex: 'operation',
      align: 'center',
      width: '7%',
      render: (_: string, record: Types.Positions.ResponseType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="wrap-edit-submit">
            <SubmitButton className="btn btn_submit">
              <PlusOutlined className="size-icon" />
              {messages['M-07-21']}
            </SubmitButton>
            <button type="button" className="btn btn_close" onClick={handleCancel}>
              キャンセル
            </button>
          </div>
        ) : (
          <FormOutlined
            className="icon"
            onClick={() => {
              permissionNumber !== 1 && handleEditItem(record);
              setItemSelected(record);
            }}
          />
        );
      },
    },
    {
      title: messages['M-07-15'],
      width: '10%',
      dataIndex: 'operation',
      align: 'left',
      render: (_: string, record: Types.Positions.ResponseType) =>
        idItem !== record.i_id ? (
          <DeleteOutlined className="icon" onClick={() => handleDeleteItem(record)} />
        ) : null,
    },
  ];

  const EditableRow: React.FC<Types.TypeRow> = ({ children, ...restProps }) => {
    return (
      <Formik<Types.CreateEditPositionFormik>
        initialValues={{
          name: itemSelected?.name || '',
          rank_order: itemSelected?.rank_order || 1,
        }}
        validationSchema={CreateEditJobTitleSchema}
        onSubmit={async (values, { resetForm }) => {
          try {
            dispatch(startLoading());
            const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, {
              page: 1,
              per_page: 0,
              conditions: [
                {
                  id: 'rank_order',
                  search_value: listRankOrder
                    .filter((o) => Number(o) >= Number(values.rank_order))
                    .map((i) => i.toString()),
                  exact_match: true,
                },
              ],
              sort_field_id: 'rank_order',
              sort_order: 'asc',
            });
            const resultAction = await dispatch(
              updateItemPositionMaster({
                id: idItem,
                data: {
                  item: values,
                  is_force_update: true,
                },
              })
            );
            if (updateItemPositionMaster.fulfilled.match(resultAction)) {
              if (
                data.totalItems > 0 &&
                data.items.find((i) => i.rank_order === values.rank_order && i.i_id !== idItem)
              ) {
                await Promise.all(
                  data.items
                    .filter((i) => i.i_id !== idItem)
                    .map((i) =>
                      dispatch(
                        updateItemPositionMaster({
                          id: i.i_id,
                          data: {
                            item: {
                              rank_order: Number(i.rank_order) + 1,
                            },
                            is_force_update: true,
                          },
                        })
                      )
                    )
                );
                await dispatch(
                  getRankOrders({
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [userInfo?.company_id],
                      },
                    ],
                    page: 1,
                    per_page: 0,
                    sort_field_id: 'rank_order',
                    sort_order: 'desc',
                  })
                );
              }
              setCompletedModalTitle('更新が完了しました');
              setShowCompleteModal(true);
            } else {
              setActionModalState({
                subTitle: '役職の更新に失敗しました',
                description: (
                  <p className="text-content">
                    役職の更新に失敗しました。
                    <br />
                    もう一度情報を入力し、再度お試しください。
                  </p>
                ),
              });
              setShowActionErrorModal(true);
            }
          } finally {
            setIdItem('');
            resetForm();
            dispatch(stopLoading());
          }
        }}
      >
        <tr {...restProps}>{children}</tr>
      </Formik>
    );
  };

  const EditableCell: React.FC<Types.TypeCell<Types.Positions.ResponseType>> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        <Form>
          {editing ? (
            <Form.Item
              name={dataIndex}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              {inputType === 'selectField' ? (
                <SelectField className="select-input-edit" name="rank_order">
                  {listRankOrder &&
                    listRankOrder.map((rank_order, i) => (
                      <Option value={rank_order} key={i}>
                        {rank_order}
                      </Option>
                    ))}
                </SelectField>
              ) : (
                <TextArea showCount maxLength={120} name="name" />
              )}
            </Form.Item>
          ) : (
            children
          )}
        </Form>
      </td>
    );
  };

  const mergedColumns = columns.map((col: Types.TypeCell<Types.Positions.ResponseType>) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Types.Positions.ResponseType) => ({
        record,
        inputType: col.dataIndex === 'rank_order' ? 'selectField' : 'textArea',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleChangePage = useCallback(
    async (p: number) => {
      dispatch(startLoading());
      setPage(p);
      await dispatch(
        getPositionMaster({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: p,
          per_page: PER_PAGE,
          sort_field_id: 'rank_order',
          sort_order: 'asc',
        })
      );
      dispatch(stopLoading());
    },
    [dispatch, userInfo]
  );

  const handleSearch = async (value: string) => {
    dispatch(startLoading());
    await dispatch(
      searchPositionMaster({
        page: 1,
        per_page: 0,
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'name',
            search_value: [value],
          },
        ],
      })
    );
    dispatch(stopLoading());
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    try {
      if (value === 'csv') {
        const resultAction = await dispatch(
          getPositionMasterCSV({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            sort_field_id: 'rank_order',
            sort_order: 'asc',
          })
        );
        if (getPositionMasterCSV.fulfilled.match(resultAction)) {
          const listCsv = resultAction.payload.items.map((item) => ({
            company_id: item.company_id,
            code: item.code,
            name: item.name,
            rank_order: item.rank_order,
            createdby: item.createdby,
            createdat: item.createdat,
          }));

          const csvString = [
            HEADER_JOB_TITLE_MASTER_CSV.map(({ label }) => label),
            ...listCsv.map((item) => Object.values(item)),
          ]
            .map((e) => e.join(','))
            .join('\n');
          const bom = '\uFEFF';
          const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
          saveAs(file, '役職マスタ.csv');
        }
      } else {
        const blob = await pdf(<FileExportInvoicePDF data={listPositionMaster} />).toBlob();
        saveAs(blob, '役職マスタ.pdf');
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getPositionMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: PER_PAGE,
            sort_field_id: 'rank_order',
            sort_order: 'asc',
          })
        ),
        dispatch(
          searchPositionMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getRankOrders({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            sort_field_id: 'rank_order',
            sort_order: 'desc',
          })
        ),
        dispatch(
          getAffiliationAssignRole({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'display_order',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            use_display_id: true,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
    return () => {
      dispatch(resetListRankOrder());
    };
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <RolesMasterStyled collapsedMenu={collapsedMenu}>
      <Header title={messages['M-07-1'] as string} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            {messages['M-07-2']}
            <br />
            {messages['M-07-3']}
          </p>
          <div className="border" />
          <FormikProvider value={formikSearch}>
            <Form layout="vertical" colon={false} className="form-search">
              <Form.Item
                name="name"
                label={<span className="label"> 役職検索</span>}
                className="form-input"
              >
                <SelectField
                  showSearch
                  className="select-input"
                  placeholder={messages['M-07-5'] as string}
                  onSearch={handleSearch}
                  filterOption={(input, option) =>
                    option!.children!.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="name"
                >
                  {searchResult.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-btn">
                <SubmitButton className="btn-search">
                  <SearchOutlined className="icon-search" />
                  {messages['M-07-6']}
                </SubmitButton>
                <ResetButton className="btn-reset">
                  <span className="label-reset">{messages['M-07-7']}</span>
                </ResetButton>
              </div>
            </Form>
          </FormikProvider>
          {listPositionMaster.length > 0 ? (
            <div className="text-count">
              {(page - 1) * perPage + 1} - {''}
              {page * PER_PAGE > listPositionMaster.length
                ? listPositionMaster.length
                : page * PER_PAGE}
              <span className="text-static"></span> / {listPositionMaster.length}
              <span className="text-static">件</span>
              <div className="page-select">
                <div className="label">
                  <span>表示件数</span>：
                </div>
                <Select value={perPage} onSelect={handleSelectChange}>
                  {[10, 20, 50, 100].map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          ) : null}
          <div className="btn-div">
            <button
              className="btn btn-active btn-download"
              onClick={() => setShowConfirmExportFileModal(true)}
            >
              <div className="title-btn">
                <CloudDownloadOutlined className="size-icon" />
                {messages['M-21-110']}
              </div>
            </button>
          </div>

          <Table
            dataSource={listPositionMaster.map((item, index) => ({ ...item, index }))}
            columns={mergedColumns}
            components={{
              body: {
                row: EditableRow,
                cell: EditableCell,
              },
            }}
            className={listPositionMaster.length > 0 ? 'table' : 'table_mr'}
            pagination={{
              showSizeChanger: false,
              total: total,
              current: page,
              pageSize: 10,
              onChange: handleChangePage,
              position: ['topCenter'],
            }}
            rowKey="index"
          />
        </div>
        <div className="wrap-create">
          <p className="title">{messages['M-07-16']}</p>
          <Formik<Types.CreateEditPositionFormik>
            initialValues={{
              name: '',
              rank_order: listRankOrder[listRankOrder.length - 1] || 1,
            }}
            enableReinitialize
            validationSchema={CreateEditJobTitleSchema}
            onSubmit={async (values, { resetForm }) => {
              dispatch(startLoading());
              const listPositionMasterEdit = listPositionMaster.filter(
                (position) => position.rank_order >= values.rank_order
              );
              const resultAction = await dispatch(
                createItemPositionMaster({
                  item: {
                    ...values,
                    company_id: userInfo?.company_id,
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                  return_item_result: true,
                })
              );
              if (createItemPositionMaster.fulfilled.match(resultAction)) {
                listPositionMasterEdit[0] &&
                  (await Promise.all(
                    listPositionMasterEdit.map((position) =>
                      dispatch(
                        updateItemPositionMaster({
                          id: position.i_id,
                          data: {
                            item: {
                              rank_order: +position.rank_order + 1,
                            },
                            is_force_update: true,
                          },
                        })
                      )
                    )
                  ));
                await dispatch(
                  getPositionMaster({
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [userInfo?.company_id],
                      },
                    ],
                    page: 1,
                    per_page: PER_PAGE,
                    sort_field_id: 'rank_order',
                    sort_order: 'asc',
                  })
                );
                const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, {
                  page: 1,
                  per_page: 0,
                  conditions: [
                    {
                      id: 'rank_order',
                      search_value: listRankOrder
                        .filter((o) => Number(o) >= Number(values.rank_order))
                        .map((i) => i.toString()),
                      exact_match: true,
                    },
                  ],
                  sort_field_id: 'rank_order',
                  sort_order: 'asc',
                });
                if (
                  data.totalItems > 0 &&
                  data.items.find(
                    (i) =>
                      i.rank_order === values.rank_order && i.i_id !== resultAction.payload.item_id
                  )
                ) {
                  await Promise.all(
                    data.items
                      .filter((i) => i.i_id !== resultAction.payload.item_id)
                      .map((i) =>
                        dispatch(
                          updateItemPositionMaster({
                            id: i.i_id,
                            data: {
                              item: {
                                rank_order: Number(i.rank_order) + 1,
                              },
                              is_force_update: true,
                            },
                          })
                        )
                      )
                  );
                }
                setCompletedModalTitle('登録が完了しました');
                setShowCompleteModal(true);
              } else {
                setActionModalState({
                  subTitle: '新規役職の登録に失敗しました',
                  description: (
                    <p className="text-content">
                      新規役職の登録に失敗しました。
                      <br />
                      もう一度情報を入力し、再度お試しください。
                    </p>
                  ),
                });
                setShowActionErrorModal(true);
              }
              await dispatch(
                getRankOrders({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                  sort_field_id: 'rank_order',
                  sort_order: 'desc',
                })
              );
              dispatch(stopLoading());
              resetForm();
            }}
          >
            <Form colon={false} layout="vertical" className="flex">
              <Form.Item
                name="rank_order"
                label={
                  <span className="text-label">
                    {messages['M-07-17']}
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField
                  disabled={permissionNumber === 1}
                  className="select-input"
                  name="rank_order"
                  placement="topLeft"
                >
                  {listRankOrder.map((rank_order, index) => (
                    <Option value={rank_order} key={index}>
                      {rank_order}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    {messages['M-07-18']}
                    <span className="require">*</span>
                  </span>
                }
                className="form-text-area"
              >
                <TextArea
                  disabled={permissionNumber === 1}
                  name="name"
                  showCount
                  maxLength={120}
                  placeholder={messages['M-07-20'] as string}
                />
              </Form.Item>
              <SubmitButton
                disabled={permissionNumber === 1}
                className={`btn ${permissionNumber === 1 ? 'disabled' : 'btn_submit'}`}
              >
                <PlusOutlined className="size-icon" />
                {messages['M-07-19']}
              </SubmitButton>
            </Form>
          </Formik>
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle={actionModalState.subTitle}
        description={actionModalState.description}
      />
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title={completedModalTitle}
      />
      <ConfirmDeletePositionModal
        deleteItemId={itemDeleted}
        title={itemDeleted ? '削除確認' : '削除確認'}
        subTitle={itemDeleted ? 'データの削除を実行します' : 'データの削除は実行できません。123'}
        description={
          itemDeleted
            ? 'データの削除を実行すると、復元できませんのでご注意ください。'
            : '選択したマスタは社内ユーザーに設定されているため、削除できません。'
        }
        visible={openModalConfirmDeleteItem}
        onSubmit={async () => {
          setActionModalState({
            subTitle: '削除に失敗しました',
            description: (
              <p className="text-content">マスタの削除に失敗しました。 再度お試しください。</p>
            ),
          });
          if (!itemDeleted) return;
          dispatch(startLoading());
          const resultAction = await dispatch(deleteItemPositionMaster({ id: itemDeleted.i_id }));
          if (deleteItemPositionMaster.fulfilled.match(resultAction)) {
            const resultActionsDelete = await Promise.all(
              listPositionMaster.slice(itemDeleted.rank_order).map((position) =>
                dispatch(
                  updateItemPositionMaster({
                    id: position.i_id,
                    data: {
                      item: {
                        rank_order: position.rank_order - 1,
                      },
                      is_force_update: true,
                    },
                  })
                )
              )
            );
            if (
              resultActionsDelete.some((action) => updateItemPositionMaster.fulfilled.match(action))
            ) {
              await dispatch(
                getRankOrders({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                  sort_field_id: 'rank_order',
                  sort_order: 'desc',
                })
              );
            }
            setCompletedModalTitle('削除が完了しました');
            setShowCompleteModal(true);
          } else {
            setShowActionErrorModal(true);
          }
          setItemDeleted(undefined);
          dispatch(stopLoading());
        }}
        setVisible={setOpenModalConfirmDeleteItem}
      />
    </RolesMasterStyled>
  );
};

export default PositionMaster;
