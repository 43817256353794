import styled from 'styled-components';

interface Props {
  hiddenHead?: boolean;
}

export const SectionStyled = styled.div<Props>`
  font-size: 13px;
  color: #424242;
  padding-top: 20px;
  text-align: center;
  .sub-title {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
      margin-right: 10px;
    }
    .check {
      color: #08a3a5;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #777777;
  }
  .icon-detail {
    cursor: pointer;
  }
  .item {
    display: flex;
    align-items: center;
    background: #ffffff;
    border: 1px solid #ebebeb;
    padding: 10px 25px;
    .label {
      margin: 0;
      font-size: 12px;
      color: #424242;
    }
    .number {
      margin: 0;
      font-size: 18px;
      color: #424242;
      padding: 0 20px;
    }
    .date {
      margin: 0;
      font-size: 12px;
      color: #777777;
    }
  }
  .production-curriculum {
    margin-top: 50px;
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;
        background-color: #424242;
      }
      .label {
        font-weight: 700;
        font-size: 15px;
        color: #424242;
      }
    }
    .future {
      display: ${(props) => (props.hiddenHead === false ? 'flex' : 'none')};
    }
    .item-table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      border: 1px solid #ebebeb;
      padding: 10px 0 10px 25px;
      .label {
        margin: 0;
        font-size: 12px;
        color: #424242;
      }
      .number {
        margin: 0;
        font-size: 13px;
        color: #424242;
        text-decoration: underline;
      }
      .item-icon {
        width: 17%;
        .icon {
          color: #bbbbbb;
          font-size: 20px;
        }
      }
    }
  }

  .table {
    .ant-table-thead tr .ant-table-cell {
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      text-align: center;
      ::before {
        display: none;
      }
    }
    .ant-table-thead tr .ant-table-cell.production_curriculum {
      text-align: left;
    }
    .ant-table-tbody tr .ant-table-cell {
      font-size: 13px;
      color: #2a2a2a;
      text-align: center;
    }
    .ant-table-tbody tr .ant-table-cell.date {
      font-family: Lato;
      font-size: 14px;
      color: #777777 !important;
    }
    .ant-table-tbody tr .ant-table-cell.production_curriculum {
      font-size: 13px;
      text-align: left !important;
      text-decoration-line: underline;
      color: #424242 !important;
    }
    .ant-table-tbody tr .ant-table-cell.explanation {
      font-size: 20px;
      color: #c4c4c4 !important;
    }
  }
`;
