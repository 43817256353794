import React, { useState } from 'react';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  onSubmit?: () => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PopupConfirmDownloadFile: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
  };

  return (
    <Modal
      title={
        <h3
          style={{
            display: 'flex',
            backgroundColor: '#ffffff',
            fontFamily: 'Noto Sans Javanese',
            fontSize: 18,
            justifyContent: 'center',
            alignItems: 'center',
            color: '#424242',
            margin: 0,
          }}
        >
          エクスポート
        </h3>
      }
      visible={visible}
      width={720}
      onCancel={handleToggleModal}
      okButton={{
        text: 'OK',
        onClick: async () => {
          setSubmitting(true);
          onSubmit && (await onSubmit());
          setSubmitting(false);
          handleToggleModal();
        },
        loading: submitting,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <div className="content">
          <span className="text-content">
            アップロードされたマニュアルのエクスポートは
            <br />
            アップロードされたファイル形式でエクスポートされます。
          </span>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default PopupConfirmDownloadFile;
