import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTER_WITH_SCREEN_PERMISSIONS } from '../constant/screenPermission.constant';
import { authSelector } from '../containers/Auth/selectors';

export const usePermission = () => {
  const { userInfo } = useSelector(authSelector);

  const location = useLocation();

  const permissionName = Object.keys(ROUTER_WITH_SCREEN_PERMISSIONS).find((key) =>
    ROUTER_WITH_SCREEN_PERMISSIONS[key as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS]?.includes(
      location.pathname.split(':')[0]
    )
  );
  const permissionNumber = userInfo?.lookup_items?.role_code
    ? Number(
        userInfo?.lookup_items?.role_code[
          permissionName as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS
        ]
      )
    : 2;
  return { permissionNumber, permissionName };
};
