import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';

import * as Types from 'types';
import {
  getTopSkillCheckImplementStatus,
  getCurriculumGraphGreen,
  getDataSkillCheckGraph,
  getCurriculumGraphBlue,
  getOptionAffiliations,
  getDirectedKnowledge,
  getSelectCurriculum,
  getSelectSkillCheck,
  getAffiliationLevel,
  getSkillCheckUsers,
  getCurriculumData,
  getDataSkillCheck,
} from './thunk';

export interface DataDashboardCardType {
  per_of_correct_answers: number;
  increase_rate: number;
  six_month_ago: {
    title: string;
    value: number;
  }[];
  accuracy_rate_last_month?: number;
}

export interface InitialState {
  curriculum: {
    curriculum_name: string;
    curriculum_code: string;
    users: number;
    total: number;
  }[];
  skillCheck: {
    skill_check_name: string;
    skill_check_code: string;
    users: number;
    total: number;
  }[];
  skillCheckInformation: Types.SkillCheckUser.ResponseType[];
  curriculumNameSelect: Array<Types.SelectCurriculums.ResponseType>;
  skillCheckSelect: Array<Types.SelectSkillCheck.ResponseType>;
  directedKnowledge: Array<Types.DirectedKnowledge.ResponseType>;
  myAffiliations: Array<Types.AffiliationLevel.ResponseType>;
  dataGreenCard: DataDashboardCardType;
  dataBlueCard: DataDashboardCardType;
  dataPurpleCard: DataDashboardCardType;
  optionAffiliation: Array<Types.OptionAffiliation.ResponseType>;
  dataSkillCheck: Array<Types.SkillCheck.ResponseType>;
  optionMenu: {
    skillCheck: boolean;
    skillCheckMenu: boolean;
  };
}

const initialState: InitialState = {
  curriculum: [],
  skillCheck: [],
  skillCheckInformation: [],
  curriculumNameSelect: [],
  skillCheckSelect: [],
  myAffiliations: [],
  dataGreenCard: {
    per_of_correct_answers: 0,
    increase_rate: 0,
    six_month_ago: [],
  },
  dataBlueCard: {
    per_of_correct_answers: 0,
    increase_rate: 0,
    six_month_ago: [],
  },
  dataPurpleCard: {
    per_of_correct_answers: 0,
    increase_rate: 0,
    six_month_ago: [],
  },
  directedKnowledge: [],
  optionAffiliation: [],
  dataSkillCheck: [],
  optionMenu: {
    skillCheck: false,
    skillCheckMenu: false,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard-page',
  initialState,
  reducers: {
    resetOptionModal: (state, action) => {
      state.optionMenu = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getCurriculumData.fulfilled, (state, action) => {
      const grouped = groupBy(action.payload.report_results, 'curriculum_code');

      state.curriculum = Object.keys(grouped).map((key) => {
        const total = Array.from(new Set(grouped[key].map((item) => item.login_id))).length;

        const users = grouped[key].filter(
          (item) =>
            item.probs_count > 0 &&
            item.correct_answers_num > 0 &&
            item.probs_count === item.correct_answers_num
        ).length;

        return {
          curriculum_name: grouped[key][0].curriculum_name || '',
          curriculum_code: key,
          users,
          total,
        };
      });
    });

    builder.addCase(getTopSkillCheckImplementStatus.fulfilled, (state, action) => {
      const grouped = groupBy(action.payload.report_results, 'skill_check_code');

      state.skillCheck = Object.keys(grouped).map((key) => {
        const total = Array.from(new Set(grouped[key].map((item) => item.login_id))).length;

        const users = grouped[key].filter((item) => item.implementation_status === 2).length;

        return {
          skill_check_name: grouped[key][0].skill_check_name || '',
          skill_check_code: key,
          users,
          total,
        };
      });
    });

    builder.addCase(getSelectSkillCheck.fulfilled, (state, action) => {
      state.skillCheckSelect = action.payload.report_results;
    });

    builder.addCase(getDataSkillCheckGraph.fulfilled, (state, action) => {
      const grouped = groupBy(
        action.payload.report_results.map((item) => ({
          ...item,
          implementation_date: dayjs(item.implementation_date).format('M'),
        })),
        'implementation_date'
      );

      const length = action.payload.report_results.length;
      const currentMonth = grouped[dayjs().format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          probs_count: prev.probs_count + next.probs_count,
        }),
        { correct_answers_num: 0, probs_count: 0 }
      ) || { correct_answers_num: 0, probs_count: 0 };
      const prevMonth = grouped[dayjs().subtract(1, 'month').format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          probs_count: prev.probs_count + next.probs_count,
        }),
        { correct_answers_num: 0, probs_count: 0 }
      ) || { correct_answers_num: 0, probs_count: 0 };

      const { correct_answers_num, probs_count } = action.payload.report_results.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          probs_count: prev.probs_count + next.probs_count,
        }),
        { correct_answers_num: 0, probs_count: 0 }
      );

      state.dataPurpleCard = {
        per_of_correct_answers: correct_answers_num / length / (probs_count / length),
        increase_rate:
          currentMonth.probs_count && prevMonth.probs_count
            ? prevMonth.correct_answers_num
              ? (correct_answers_num / length / (probs_count / length) -
                  prevMonth.correct_answers_num / prevMonth.probs_count) /
                (prevMonth.correct_answers_num / prevMonth.probs_count)
              : correct_answers_num / length / (probs_count / length)
            : 0,
        accuracy_rate_last_month: prevMonth.correct_answers_num / prevMonth.probs_count,
        six_month_ago: Array.from({ length: 6 }, (_, i) => dayjs().subtract(i, 'month').format('M'))
          .map((month) => {
            const monthIndex = Object.keys(grouped).find((key) => key === month);
            if (!monthIndex) return { title: `${month}\n月`, value: 0 };

            const calc = grouped[monthIndex]?.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
                probs_count: prev.probs_count + next.probs_count,
              }),
              {
                correct_answers_num: 0,
                probs_count: 0,
              }
            ) || { correct_answers_num: 0, probs_count: 0 };

            return {
              title: `${month}\n月`,
              value: Number(((calc.correct_answers_num / calc.probs_count) * 100).toFixed()),
            };
          })
          .reverse(),
      };
    });

    builder.addCase(getSkillCheckUsers.fulfilled, (state, action) => {
      state.skillCheckInformation = action.payload.report_results.map((result) => ({
        ...result,
        name: result.skill_check_name,
      }));
    });

    builder.addCase(getSelectCurriculum.fulfilled, (state, action) => {
      state.curriculumNameSelect = action.payload.report_results;
    });

    builder.addCase(getCurriculumGraphBlue.fulfilled, (state, action) => {
      const grouped = groupBy(
        action.payload.report_results.map((item) => ({
          ...item,
          implementation_date: dayjs(item.implementation_date).format('M'),
        })),
        'implementation_date'
      );

      const length = action.payload.report_results.length;
      const currentMonth = grouped[dayjs().format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      ) || { correct_answers_num: 0, question_count: 0 };
      const prevMonth = grouped[dayjs().subtract(1, 'month').format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      ) || { correct_answers_num: 0, question_count: 0 };

      const { correct_answers_num, question_count } = action.payload.report_results.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      );

      state.dataBlueCard = {
        per_of_correct_answers: correct_answers_num / length / (question_count / length),
        increase_rate:
          currentMonth.question_count && prevMonth.question_count
            ? (currentMonth.correct_answers_num / currentMonth.question_count -
                prevMonth.correct_answers_num / prevMonth.question_count) /
              prevMonth.correct_answers_num /
              prevMonth.question_count
            : 0,
        six_month_ago: Array.from({ length: 6 }, (_, i) => dayjs().subtract(i, 'month').format('M'))
          .map((month) => {
            const monthIndex = Object.keys(grouped).find((key) => key === month);
            if (!monthIndex) return { title: `${month}\n月`, value: 0 };

            const calc = grouped[monthIndex]?.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
                question_count: prev.question_count + next.question_count,
              }),
              {
                correct_answers_num: 0,
                question_count: 0,
              }
            ) || { correct_answers_num: 0, question_count: 0 };

            return {
              title: `${month}\n月`,
              value: Number(((calc.correct_answers_num / calc.question_count) * 100).toFixed()),
            };
          })
          .reverse(),
      };
    });

    builder.addCase(getCurriculumGraphGreen.fulfilled, (state, action) => {
      const grouped = groupBy(
        action.payload.report_results.map((item) => ({
          ...item,
          implementation_date: dayjs(item.implementation_date).format('M'),
        })),
        'implementation_date'
      );

      const length = action.payload.report_results.length;
      const currentMonth = grouped[dayjs().format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      ) || { correct_answers_num: 0, question_count: 0 };
      const prevMonth = grouped[dayjs().subtract(1, 'month').format('M')]?.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      ) || { correct_answers_num: 0, question_count: 0 };

      const { correct_answers_num, question_count } = action.payload.report_results.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          question_count: prev.question_count + next.question_count,
        }),
        { correct_answers_num: 0, question_count: 0 }
      );

      state.dataGreenCard = {
        per_of_correct_answers: correct_answers_num / length / (question_count / length),
        increase_rate:
          currentMonth.question_count && prevMonth.question_count
            ? (currentMonth.correct_answers_num / currentMonth.question_count -
                prevMonth.correct_answers_num / prevMonth.question_count) /
              prevMonth.correct_answers_num /
              prevMonth.question_count
            : 0,
        six_month_ago: Array.from({ length: 6 }, (_, i) => dayjs().subtract(i, 'month').format('M'))
          .map((month) => {
            const monthIndex = Object.keys(grouped).find((key) => key === month);
            if (!monthIndex) return { title: `${month}\n月`, value: 0 };

            const calc = grouped[monthIndex]?.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
                question_count: prev.question_count + next.question_count,
              }),
              {
                correct_answers_num: 0,
                question_count: 0,
              }
            ) || { correct_answers_num: 0, question_count: 0 };

            return {
              title: `${month}\n月`,
              value: Number(((calc.correct_answers_num / calc.question_count) * 100).toFixed()),
            };
          })
          .reverse(),
      };
    });

    builder.addCase(getDirectedKnowledge.fulfilled, (state, action) => {
      state.directedKnowledge = action.payload.report_results;
    });

    builder.addCase(getAffiliationLevel.fulfilled, (state, action) => {
      const affiliationList: Types.AffiliationLevel.ResponseType[] = [];
      action.payload.items.forEach((item) => {
        if (
          item.affiliation_id === action.payload.myAffiliationID ||
          item.affiliation_parent_id === action.payload.myAffiliationID ||
          affiliationList.some(
            (affiliation) => affiliation.affiliation_id === item.affiliation_parent_id
          )
        ) {
          affiliationList.push(item);
        }
      });
      state.myAffiliations = affiliationList;
    });

    builder.addCase(getOptionAffiliations.fulfilled, (state, action) => {
      state.optionAffiliation = action.payload.report_results;
    });

    builder.addCase(getDataSkillCheck.fulfilled, (state, action) => {
      state.dataSkillCheck = action.payload.items;
    });
  },
});

export const { resetInitialState, resetOptionModal } = dashboardSlice.actions;

export default dashboardSlice.reducer;
