import React, { useCallback, useState } from 'react';
import { CheckCircleFilled, LockFilled } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { manualSelector } from 'pages/Manual/selectors';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import { Arrow } from 'assets';
import * as Types from 'types';
import {
  getListManual,
  getListManualFile,
  updateManual,
  updateManualFile,
} from 'pages/Manual/thunk';

interface Props {
  visible: boolean;
  isMyManual?: boolean;
  node?: Types.FileExplorer.DataTableType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmPublish: React.FC<Props> = ({ isMyManual, visible, setVisible, node }) => {
  const [openPopupComplete, setOpenPopupComplete] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { activeFolder } = useSelector(manualSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const handlePublishItem = useCallback(async () => {
    if (!node || !userInfo) return;
    dispatch(startLoading());
    if (node.type === 'manual_file') {
      const resultAction = await dispatch(
        updateManualFile({
          id: node.i_id_file_id,
          data: {
            item: {
              publish: node.publish ? 0 : 1,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateManualFile.fulfilled.match(resultAction)) {
        setOpenPopupComplete(true);
      }
    } else if (node.type === 'manual') {
      const resultAction = await dispatch(
        updateManual({
          id: node.i_id_file_id,
          data: {
            item: {
              publish: node.publish ? 0 : 1,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateManual.fulfilled.match(resultAction)) {
        setOpenPopupComplete(true);
      }
    }
    dispatch(stopLoading());
  }, [dispatch, node, userInfo]);

  const handleSubmit = useCallback(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getListManual({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder?.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getListManualFile({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder?.folder_id],
              },
            ],
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      setOpenPopupComplete(false);
      setVisible(false);
      dispatch(stopLoading());
    })();
  }, [activeFolder?.folder_id, dispatch, isMyManual, setVisible, userInfo]);

  return (
    <>
      <Modal
        title={node?.publish ? 'マニュアル編集確認' : 'マニュアル公開確認'}
        width={720}
        visible={visible}
        okButton={{
          text: 'OK',
          onClick: handlePublishItem,
          loading,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: () => setVisible(false),
        }}
        onCancel={() => setVisible(false)}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
        headerStyle={{
          borderBottom: '1px solid #CCCCCC',
        }}
      >
        {node?.publish ? (
          <SectionStyled>
            <div className="flex">
              <div className="open">
                <span>公開中</span>
                <div className="box-icon">
                  <CheckCircleFilled className="icon" />
                </div>
              </div>
              <img className="arrow-icon" src={Arrow} alt="arrow" />
              <div className="lock scale">
                <div className="box-icon">
                  <LockFilled className="icon" />
                </div>
                <span>編集中</span>
              </div>
            </div>
            <p className="sub-title">
              マニュアルを編集します。よろしいですか？
              <br />
              編集権限のないユーザーからは非表示となります。
            </p>
          </SectionStyled>
        ) : (
          <SectionStyled>
            <div className="flex">
              <div className="lock">
                <span>編集中</span>
                <div className="box-icon">
                  <LockFilled className="icon" />
                </div>
              </div>
              <img className="arrow-icon" src={Arrow} alt="arrow" />
              <div className="open scale">
                <div className="box-icon">
                  <CheckCircleFilled className="icon" />
                </div>
                <span>公開中</span>
              </div>
            </div>
            <p className="sub-title">マニュアルを公開します。よろしいですか？</p>
          </SectionStyled>
        )}
      </Modal>
      <CompletedModal
        title={node?.publish ? '編集中に変更しました' : '公開しました'}
        visible={openPopupComplete}
        setVisible={setOpenPopupComplete}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default ConfirmPublish;
