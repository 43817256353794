import styled from 'styled-components';

export default styled.section`
  position: relative;
  height: calc(100% - 56px);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f8f8;
  .item {
    width: 60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 80px auto;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: relative;
    .title-item {
      font-size: 24px;
      text-align: center;
      color: #2a2a2a;
      margin: 0;
      padding: 35px 0 15px 0;
    }
    .item-body {
      align-items: center;
      padding: 40px;
    }

    .time-detail-child {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      color: #424242;
      align-items: center;
      .icon {
        margin-right: 8px;
        font-size: 28px;
        color: #bbbbbb;
      }
    }
    .text-label {
      margin: 0;
      padding-top: 70px;
      text-align: center;
      font-size: 20px;
      color: #424242;
    }
    .label {
      margin: 0;
    }
    .btn {
      display: flex;
      padding-top: 50px;
      justify-content: center;
      .btn-button {
        background: #ffffff;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        padding: 15px 60px;
        border: 1px solid #d9d9d9;
        font-weight: 400;
        font-size: 13px;
        text-align: center;
        color: #777777;
        cursor: pointer;
      }
    }
  }
`;
