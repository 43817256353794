import { createAsyncThunk } from '@reduxjs/toolkit';
import { intersectionBy, join } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { sharedFileInMinIO } from 'services/minioService';
import { curriculumService, services } from 'services';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_ROLE,
  ATTACH,
  CURRICULUMS,
  CURRICULUM_EXPORT,
  CURRICULUM_HIERARCHY,
  KNOWLEDGE,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  QUESTIONS,
  QUESTION_ASSIGN_LEVEL,
  USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
} from 'configs';

export const createCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUMS.id, {
      ...req,
      return_display_id: true,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevel1 = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level1s.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(LEVEL_1.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevel2 = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level2s.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(LEVEL_2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevel3 = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level3s.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(LEVEL_3.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateLevel4 = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level4s.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(LEVEL_4.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachCurriculumFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getAttachCurriculumFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getCurriculum', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataLevel1({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel2({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel3({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel4({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQuesLevel({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQues({
          conditions: [
            {
              id: 'company_id',
              search_value: [join([authContainer?.userInfo?.company_id, '000000001'], '|')],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataKnowledge({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeTraining> = [];
    if (
      getDataLevel1.fulfilled.match(responseStatus[0]) &&
      getDataLevel2.fulfilled.match(responseStatus[1]) &&
      getDataLevel3.fulfilled.match(responseStatus[2]) &&
      getDataLevel4.fulfilled.match(responseStatus[3]) &&
      getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
      getDataQues.fulfilled.match(responseStatus[5]) &&
      getDataKnowledge.fulfilled.match(responseStatus[6])
    ) {
      const dataLevel1 = responseStatus[0].payload.items;
      const dataLevel2 = responseStatus[1].payload.items;
      const dataLevel3 = responseStatus[2].payload.items;
      const dataLevel4 = responseStatus[3].payload.items;
      const dataAssignLevel = responseStatus[4].payload.items;
      const dataQues = responseStatus[5].payload.items;
      const dataKnowledge = responseStatus[6].payload.items;

      const filteredItemsWithKnowledge = data.items.map((k) => {
        const matchingItemKnowledge = dataKnowledge.find(
          (matchedKnowledge) => matchedKnowledge.knowledge_id === k.knowledge_id
        );

        if (matchingItemKnowledge) {
          return {
            ...k,
            i_id_knowledge: matchingItemKnowledge.i_id,
          };
        }

        return k;
      });

      resultArray = await Promise.all(
        filteredItemsWithKnowledge.flatMap(async (item) => {
          const url = item.fileID ? await sharedFileInMinIO(item.fileID) : undefined;

          const baseObject = {
            ...item,
            curriculum_code: item.code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_sort_order: Number(item.sort_order),
            knowledge_id: item.knowledge_id,
            curriculum_file: url,
          };

          const matchedLevel1Items = dataLevel1.filter((c) => c.curricullum_code === item.code);

          if (!matchedLevel1Items.length) {
            return baseObject;
          }
          return matchedLevel1Items.flatMap((level1Item) => {
            const mergedObject1 = {
              ...baseObject,
              level1_i_id: level1Item.i_id,
              level1_name: level1Item.name,
              level1_code: level1Item.code,
              official_level1_code: level1Item.official_level1_code,
              level1_sort_order: Number(level1Item.sort_order),
            };

            const matchedLevel2Items = dataLevel2.filter(
              (level2Item) => level2Item.level1_code === level1Item.code
            );

            if (!matchedLevel2Items.length) {
              return mergedObject1;
            }
            return matchedLevel2Items.flatMap((level2Item) => {
              const mergedObject2 = {
                ...mergedObject1,
                level2_i_id: level2Item.i_id,
                level2_name: level2Item.name,
                level2_code: level2Item.code,
                official_level2_code: level2Item.official_level2_code,
                level2_sort_order: Number(level2Item.sort_order),
              };
              const matchedLevel3Items = dataLevel3.filter(
                (level3Item) => level3Item.level2_code === level2Item.code
              );
              if (!matchedLevel3Items.length) {
                return mergedObject2;
              }
              return matchedLevel3Items.flatMap((level3Item) => {
                const mergedObject3 = {
                  ...mergedObject2,
                  level3_i_id: level3Item.i_id,
                  level3_name: level3Item.name,
                  level3_code: level3Item.code,
                  official_level3_code: level3Item.official_level3_code,
                  level3_sort_order: Number(level3Item.sort_order),
                };
                const matchedLevel4Items = dataLevel4.filter(
                  (level4Item) => level4Item.level3_code === level3Item.code
                );

                if (!matchedLevel4Items.length) {
                  return mergedObject3;
                }

                return matchedLevel4Items.flatMap((level4Item) => {
                  const mergedObject4 = {
                    ...mergedObject3,
                    level4_i_id: level4Item.i_id,
                    level4_name: level4Item.name,
                    level4_code: level4Item.code,
                    official_level4_code: level4Item.official_level4_code,
                    level4_sort_order: Number(level4Item.sort_order),
                  };
                  const matchedQuestionItems = dataAssignLevel
                    .filter((que) => que.level4_code === level4Item.code)
                    .map((que) => ({
                      ...que,
                      id: que.id,
                      question_sort_order: Number(que.sort_order),
                      question_assign_level_i_id: que.i_id,
                    }));

                  if (!matchedQuestionItems.length) {
                    return mergedObject4;
                  }
                  const filteredItems = intersectionBy(dataQues, matchedQuestionItems, 'code');

                  const filteredItemsWithId = filteredItems.map((u) => {
                    const matchingItem = matchedQuestionItems.find(
                      (matchedItem) => matchedItem.code === u.code
                    );

                    if (matchingItem) {
                      return {
                        ...u,
                        id: matchingItem.id,
                        question_sort_order: Number(matchingItem.sort_order),
                        question_assign_level_i_id: matchingItem.i_id,
                      };
                    }

                    return u;
                  });

                  if (!filteredItemsWithId.length) {
                    return mergedObject4;
                  }

                  return filteredItemsWithId.map((que) => ({
                    ...mergedObject4,
                    question_code_i_id: que.i_id,
                    question_code: que.code,
                    question_name: que.name,
                    attach_fileID1: que.attach_fileID1,
                    attach_fileID2: que.attach_fileID2,
                    attach_fileID3: que.attach_fileID3,
                    question_description: que.description,
                    question_sort_order: Number(que.question_sort_order),
                    question_assign_level_i_id: que.question_assign_level_i_id,
                  }));
                });
              });
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat().sort((a, b) => {
        if (a.curriculum_sort_order !== b.curriculum_sort_order) {
          return a.curriculum_sort_order - b.curriculum_sort_order;
        } else if (a.level1_sort_order !== b.level1_sort_order) {
          return a.level1_sort_order - b.level1_sort_order;
        } else if (a.level2_sort_order !== b.level2_sort_order) {
          return a.level2_sort_order - b.level2_sort_order;
        } else if (a.level3_sort_order !== b.level3_sort_order) {
          return a.level3_sort_order - b.level3_sort_order;
        } else if (a.level4_sort_order !== b.level4_sort_order) {
          return a.level4_sort_order - b.level4_sort_order;
        } else {
          return a.question_sort_order - b.question_sort_order;
        }
      });
    }
    dispatch(stopLoading());
    return {
      ...data,
      items: [...flattenedResultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataKnowledge = createAsyncThunk<
  Types.GetItemResponseType<Types.Knowledge.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataKnowledge', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Knowledge.ResponseType>(KNOWLEDGE.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1s.ResponseType>(LEVEL_1.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2s.ResponseType>(LEVEL_2.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3s.ResponseType>(LEVEL_3.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4s.ResponseType>(LEVEL_4.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.id,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setRequiredCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/setRequiredCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setPublishCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Curriculums.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/setPublishCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(CURRICULUMS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createLevelCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.createLevel(req.level, {
      ...req,
      return_display_id: true,
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateLevelCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Level.ResponseType> & { level: number },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/updateLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.updateLevel(req.level, {
      id: req.id,
      data: {
        ...req.data,
        is_force_update: true,
        return_item_result: true,
      },
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLevelCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType & { level: number; realtime_auto_link: boolean },
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLevelCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.deleteLevel(req.level, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserAssignRequiredCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataUserAssignRequiredCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignRequiredCurriculum.ResponseType>(
      USER_ASSIGN_REQUIRED_CURRICULUM.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUser = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserSetting = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeUser>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getDataUserSetting', async (req, { dispatch, getState, rejectWithValue }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search(CURRICULUMS.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataUserAssignRequiredCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataAffiliationAssignRole({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeUser> = [];
    if (
      getDataUserAssignRequiredCurriculum.fulfilled.match(responseStatus[0]) &&
      getDataAffiliationAssignRole.fulfilled.match(responseStatus[1]) &&
      getDataUser.fulfilled.match(responseStatus[2])
    ) {
      const dataUserAssignRequiredCurriculum = responseStatus[0].payload.items;
      const dataAffiliationAssignRole = responseStatus[1].payload.items;
      const dataUser = responseStatus[2].payload.items;

      resultArray = await Promise.all(
        data.items.flatMap((item: any) => {
          const baseObject = {
            ...item,
            i_id: item.i_id,
            curricullum_code: item.code,
            curricullum_name: item.name,
            curricullum_description: item.description,
            curricullum_sort_order: item.sort_order,
            publish: item.publish,
            company_id: item.company_id,
          };
          const matchedUserAssignItems = dataUserAssignRequiredCurriculum.filter(
            (c) => c.curricullum_code === item.code
          );
          if (!matchedUserAssignItems.length) {
            return baseObject;
          }
          return matchedUserAssignItems.flatMap((userAssign) => {
            const mergedObject1 = {
              ...baseObject,
              createdby: userAssign.createdby,
              login_id: userAssign.login_id,
              createdat_i_id: userAssign.i_id,
            };
            const matchedAffiliationAssignRole = dataAffiliationAssignRole.filter(
              (affiliationRole) =>
                affiliationRole.main_role === 'main' &&
                affiliationRole.login_id === userAssign.login_id
            );
            return matchedAffiliationAssignRole.flatMap((matchAffiliation) => {
              const mergedObject2 = {
                ...mergedObject1,
                department_name: matchAffiliation.affiliation_id,
                department_name_i_id: matchAffiliation.i_id,
                main: 'main',
              };
              const matchUser = dataUser.filter(
                (user) => user.login_id === matchAffiliation.login_id
              );
              return matchUser.map((u) => ({
                ...mergedObject2,
                employee_number: u.employee_code,
                user_name: u.name,
                user_name_i_id: u.i_id,
              }));
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat();
    }
    dispatch(stopLoading());
    return {
      ...data,
      items: [...flattenedResultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteLinkUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_REQUIRED_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const curriculumExportDataCSV = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumExport.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/curriculumExportDataCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumExport.ResponseType>(
      CURRICULUM_EXPORT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumMaster = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumHierarchy.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/getCurriculumMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumHierarchy.ResponseType>(
      CURRICULUM_HIERARCHY.name,
      {
        ...req,
        include_item_ref: true,
        omit_total_items: false,
        sort_fields: [
          { id: 'curriculum_code', order: 'asc' },
          { id: 'level1_sort_order', order: 'asc' },
          { id: 'level2_sort_order', order: 'asc' },
          { id: 'level3_sort_order', order: 'asc' },
          { id: 'level4_sort_order', order: 'asc' },
          { id: 'question_sort_order', order: 'asc' },
        ],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/uploadFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  any,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteUserAssignCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('curriculum/thunk/deleteUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(USER_ASSIGN_REQUIRED_CURRICULUM.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
