import React, { useEffect, useMemo, useState } from 'react';
import { every, maxBy, some, unionBy } from 'lodash';
import { Form, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import { useIntl } from 'react-intl';
import {
  DeleteOutlined,
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { createLinkQuestionAssignLevel, deleteQuestion } from 'containers/CreateEditQuestion/thunk';
import { memoizedGetFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { getCurriculum, updateCurriculum } from 'pages/Settings/Curriculum/thunk';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import UnableAddQuestionModal from 'components/Modal/UnableAddQuestion';
import { searchQuestionCurriculumSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import { Input, SelectField } from 'components';
import QuestionPopup from '../QuestionPopup';
import { getListQuestion } from '../thunk';
import { Button } from '../styles';
import * as Types from 'types';
import Item from '../Item';
import {
  filterCreatedBy,
  filterCurriculum,
  filterLevel1,
  filterLevel2,
  filterLevel3,
  filterLevel4,
  filterQuestionName,
  removeNodeLevel4Selected,
} from '../slice';

const { Option } = Select;

interface Props {
  selectedQuestion: Array<{
    i_id: string;
    name: string;
    code: string;
  }>;
  setSelectedQuestion: React.Dispatch<
    React.SetStateAction<Array<{ i_id: string; name: string; code: string }>>
  >;
  setOpenModalCreateQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
      onSubmit?: () => void;
    }>
  >;
  openModalCreateQuestion?: {
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: (() => void) | undefined;
  };
}

const QuestionSearch: React.FC<Props> = ({
  selectedQuestion,
  setSelectedQuestion,
  openModalCreateQuestion,
  setOpenModalCreateQuestion,
}) => {
  const [lvl1Option, setLvl1Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl2Option, setLvl2Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl3Option, setLvl3Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [lvl4Option, setLvl4Option] = useState<Types.SelectLevel.ResponseType[]>([]);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [dataPrimitive, setDataPrimitive] = useState<Types.TreeItem<Types.CurriculumItemType>[]>(
    []
  );

  const { userInfo } = useSelector(authSelector);
  const { curricullum, filter_conditions, questionCurriculumSelected } =
    useSelector(curriculumSelector);
  const {
    questionSearch,
    curriculumOption,
    nodeLevel4Selected,
    level1Option,
    level2Option,
    level3Option,
    level4Option,
    creatorOption,
  } = useSelector(searchQuestionCurriculumSelector);

  const dataList = useMemo(() => {
    return unionBy(questionSearch, 'question_code');
  }, [questionSearch]);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const formik = useFormik<Types.QuestionSearchFormik>({
    initialValues: {
      curriculum_code: '',
      level1_code: '',
      level2_code: '',
      level3_code: '',
      level4_code: '',
      created_by: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      const resultAction = await dispatch(
        getListQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],

          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      if (getListQuestion.fulfilled.match(resultAction)) {
        if (values.curriculum_code) {
          dispatch(filterCurriculum(values.curriculum_code));
        }
        if (values.level1_code) {
          dispatch(filterLevel1(values.level1_code));
        }
        if (values.level2_code) {
          dispatch(filterLevel2(values.level2_code));
        }
        if (values.level3_code) {
          dispatch(filterLevel3(values.level3_code));
        }
        if (values.level4_code) {
          dispatch(filterLevel4(values.level4_code));
        }
        if (values.created_by) {
          dispatch(filterCreatedBy(values.created_by));
        }
        if (values.question_name) {
          dispatch(filterQuestionName(values.question_name));
        }
      }
    },
    onReset: () => {
      fetchListQuestion();
    },
  });

  const fetchListQuestion = async (conditions?: Array<Types.GetItemRequestType>) => {
    dispatch(startLoading());
    await dispatch(
      getListQuestion({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          ...(conditions ?? []),
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
      })
    );
    dispatch(stopLoading());
  };

  const showModalCreateEditQuestion = () => {
    setOpenModalCreateQuestion({ visible: true, type: 'create', onSubmit: formik.handleSubmit });
  };

  const handleLinkQuestion = async (type?: 'all') => {
    const selectedQuestionCodes =
      type === 'all'
        ? dataList.map((question) => question.question_code)
        : selectedQuestion.map((question) => question.code);

    const allSelectedQuestionsExist = every(selectedQuestionCodes, (code) =>
      some(questionCurriculumSelected, { question_code: code })
    );

    if (allSelectedQuestionsExist) {
      setShowActionErrorModal(true);
      setSelectedQuestion([]);

      return;
    }

    dispatch(startLoading());
    if (type === 'all') {
      const resultActions = await Promise.all(
        dataList.map(
          (item, i) =>
            !questionCurriculumSelected.some(
              ({ question_code }) => question_code === item.question_code
            ) &&
            dispatch(
              createLinkQuestionAssignLevel({
                item: {
                  company_id: userInfo?.company_id,
                  level4_code: nodeLevel4Selected?.code,
                  code: item.question_code,
                  sort_order:
                    Number(
                      maxBy(nodeLevel4Selected?.children || [], 'sort_order')?.sort_order || 0
                    ) + 1,
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
              })
            )
        )
      );
      await dispatch(removeNodeLevel4Selected());

      await dispatch(
        updateCurriculum({
          id: nodeLevel4Selected?.curriculum_id!,
          data: {
            item: {
              probs_count:
                (nodeLevel4Selected?.problems_count || 0) +
                resultActions.filter((r) => r && createLinkQuestionAssignLevel.fulfilled.match(r))
                  .length,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
    } else {
      const resultActions = await Promise.all(
        selectedQuestion.map(
          (item, i) =>
            !questionCurriculumSelected.some(({ question_code }) => question_code === item.code) &&
            dispatch(
              createLinkQuestionAssignLevel({
                item: {
                  company_id: userInfo?.company_id,
                  level4_code: nodeLevel4Selected?.code,
                  code: item.code,
                  sort_order:
                    Number(
                      maxBy(nodeLevel4Selected?.children || [], 'sort_order')?.sort_order || 0
                    ) + 1,
                  createdat: new Date(),
                  createdby: userInfo?.login_id,
                },
              })
            )
        )
      );
      await dispatch(removeNodeLevel4Selected());

      await dispatch(
        updateCurriculum({
          id: nodeLevel4Selected?.curriculum_id!,
          data: {
            item: {
              probs_count:
                (nodeLevel4Selected?.problems_count || 0) +
                resultActions.filter((r) => r && createLinkQuestionAssignLevel.fulfilled.match(r))
                  .length,
              updatedat: new Date(),
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
    }
    setSelectedQuestion([]);
    await dispatch(
      getCurriculum({
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
    formik.handleSubmit();
  };

  const handleDeleteQuestions = async () => {
    if (selectedQuestion.length) {
      dispatch(startLoading());
      await Promise.all(selectedQuestion.map((i) => dispatch(deleteQuestion({ id: i.i_id }))));
      dispatch(stopLoading());
      formik.handleSubmit();
    }
  };

  const handleSelectCurriculumName = (value: string) => {
    setLvl1Option([]);
    setLvl2Option([]);
    setLvl3Option([]);
    setLvl4Option([]);
    formik.setFieldValue('level1_code', undefined);
    formik.setFieldValue('level2_code', undefined);
    formik.setFieldValue('level3_code', undefined);
    formik.setFieldValue('level4_code', undefined);

    const newDataPrimitive = memoizedGetFlatDataFromTree({
      treeData: curricullum.find((curr) => curr.code === value),
    });
    setDataPrimitive(newDataPrimitive);

    for (let index = 0; index < newDataPrimitive.length; index++) {
      const treeData: Types.TreeItem<Types.CurriculumItemType> = newDataPrimitive[index];
      const newOption = {
        code: treeData.node?.code!,
        company_id: treeData.node?.company_id!,
        i_id: treeData.node?.i_id!,
        name: treeData.node?.name!,
      };
      switch (treeData.columnIndex) {
        case 1:
          setLvl1Option((prevState) => [...prevState, newOption]);
          break;
        case 2:
          setLvl2Option((prevState) => [...prevState, newOption]);
          break;
        case 3:
          setLvl3Option((prevState) => [...prevState, newOption]);
          break;
        case 4:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  const handleSelectCurriculumLevel = (level: number, value: string) => {
    if (!formik.values.curriculum_code) return;
    switch (level) {
      case 1:
        formik.setFieldValue('level2_code', undefined);
        formik.setFieldValue('level3_code', undefined);
        formik.setFieldValue('level4_code', undefined);
        setLvl2Option([]);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 2:
        formik.setFieldValue('level3_code', undefined);
        formik.setFieldValue('level4_code', undefined);
        setLvl3Option([]);
        setLvl4Option([]);
        break;
      case 3:
        formik.setFieldValue('level4_code', undefined);
        setLvl4Option([]);
        break;
      case 4:
        break;
      default:
        break;
    }

    const children = dataPrimitive.find((d) => d.node?.code === value)?.node?.children;
    if (!children) return;

    for (let index = 0; index < children.length; index++) {
      const node: Types.TreeItem<Types.CurriculumItemType> = children[index];
      const newOption = {
        code: node.code || '',
        company_id: node.company_id || '',
        i_id: node.i_id || '',
        name: node.name || '',
      };
      switch (level) {
        case 1:
          setLvl2Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl3Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
                if (node2.children) {
                  for (let index3 = 0; index3 < node2.children.length; index3++) {
                    const node4: Types.TreeItem<Types.CurriculumItemType> = node2.children[index];
                    if (node4) {
                      setLvl4Option((prevState) => [
                        ...prevState,
                        {
                          code: node4.code || '',
                          company_id: node4.company_id || '',
                          i_id: node4.i_id || '',
                          name: node4.name || '',
                        },
                      ]);
                    }
                  }
                }
              }
            }
          }
          break;
        case 2:
          setLvl3Option((prevState) => [...prevState, newOption]);
          if (node.children) {
            for (let index2 = 0; index2 < node.children.length; index2++) {
              const node2: Types.TreeItem<Types.CurriculumItemType> = node.children[index];
              if (node2) {
                setLvl4Option((prevState) => [
                  ...prevState,
                  {
                    code: node2.code || '',
                    company_id: node2.company_id || '',
                    i_id: node2.i_id || '',
                    name: node2.name || '',
                  },
                ]);
              }
            }
          }
          break;
        case 3:
          setLvl4Option((prevState) => [...prevState, newOption]);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getListQuestion({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, openModalCreateQuestion]);

  return (
    <FormikProvider value={formik}>
      <Form
        labelCol={{
          flex: '27%',
        }}
        colon={false}
        className="form"
      >
        <p className="title">{messages['M-21-34']}</p>
        <Form.Item
          name="curriculum_code"
          label={<span className="label">{messages['M-21-25']}</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            className="select-input"
            name="curriculum_code"
            onChange={handleSelectCurriculumName}
            onClear={() => handleSelectCurriculumName('')}
          >
            {curriculumOption.map((curr, index) => (
              <Option key={index} value={curr.code}>
                {curr.name}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level1_code"
          label={<span className="label">{messages['M-21-26']}</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level1_code"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(1, value)}
            onClear={() => handleSelectCurriculumLevel(1, '')}
          >
            {(formik.values.curriculum_code ? lvl1Option : level1Option).map((lvl, index) => (
              <Option key={index} value={lvl.code}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level2_code"
          label={<span className="label">{messages['M-21-27']}</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level2_code"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(2, value)}
            onClear={() => handleSelectCurriculumLevel(2, '')}
          >
            {(formik.values.curriculum_code ? lvl2Option : level2Option).map((lvl, index) => (
              <Option key={index} value={lvl.code}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level3_code"
          label={<span className="label">{messages['M-21-28']}</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level3_code"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(3, value)}
            onClear={() => handleSelectCurriculumLevel(3, '')}
          >
            {(formik.values.curriculum_code ? lvl3Option : level3Option).map((lvl, index) => (
              <Option key={index} value={lvl.code}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="level4_code"
          label={<span className="label">{messages['M-21-29']}</span>}
          className="form-input"
        >
          <SelectField
            allowClear
            name="level4_code"
            className="select-input"
            onChange={(value: string) => handleSelectCurriculumLevel(4, value)}
            onClear={() => handleSelectCurriculumLevel(4, '')}
          >
            {(formik.values.curriculum_code ? lvl4Option : level4Option).map((lvl, index) => (
              <Option key={index} value={lvl.code}>
                {lvl.name || '（空白）'}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="created_by"
          label={<span className="label">{messages['M-21-30']}</span>}
          className="form-input"
        >
          <SelectField allowClear className="select-input" name="created_by">
            {unionBy(creatorOption, 'creator').map(({ creator }, index) => (
              <Option key={index} value={creator}>
                {creator}
              </Option>
            ))}
          </SelectField>
        </Form.Item>
        <Form.Item
          name="question_name"
          label={<span className="label">{messages['M-21-31']}</span>}
          className="form-input"
        >
          <Input className="select-input" name="question_name" />
        </Form.Item>
        <div className="wrap-center">
          <SubmitButton className="btn-search" loading={false}>
            <SearchOutlined className="icon-search" />
            {messages['M-21-36']}
          </SubmitButton>
          <span className="label-reset" onClick={() => formik.resetForm()}>
            {messages['M-21-37']}
          </span>
        </div>
      </Form>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={!!dataList?.length && !!nodeLevel4Selected}
            disabled={!dataList?.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion('all')}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!selectedQuestion.length && !!nodeLevel4Selected}
            disabled={!selectedQuestion.length || !nodeLevel4Selected}
            onClick={() => handleLinkQuestion()}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 300,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {dataList?.length > 0 ? (
              dataList.map((item, index) => (
                <Item
                  key={index}
                  onSubmit={formik.handleSubmit}
                  selectedQuestion={selectedQuestion}
                  setSelectedQuestion={setSelectedQuestion}
                  item={{
                    i_id: item.i_id || '',
                    code: item.question_code,
                    name: item.question_name,
                  }}
                />
              ))
            ) : (
              <p className="text-result">{messages['M-21-40']}</p>
            )}
          </div>
          <div className="footer">
            <Button
              active={permissionNumber !== 1}
              className="btn"
              onClick={showModalCreateEditQuestion}
            >
              <PlusOutlined className="icon" />
              設問新規作成
            </Button>
            <Button
              active={!!selectedQuestion.length}
              className="btn"
              onClick={handleDeleteQuestions}
            >
              <DeleteOutlined className="icon" />
              {messages['M-21-39']}
            </Button>
          </div>
        </div>
      </div>
      <UnableAddQuestionModal
        visible={showActionErrorModal}
        title="設問追加できません"
        subTitle="指定した設問は既に設定先のカリキュラムに設定されています。"
        setVisible={setShowActionErrorModal}
      />
    </FormikProvider>
  );
};

export default QuestionSearch;
