import React, { useState } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckReportSelector } from '../selectors';
import QuestionDetail from '../../../QuestionDetail';
import { ReportSkillCheck } from 'types';
import Wrapper from './styles';

const PER_PAGE = 10;

interface Props {
  exportPdf?: boolean;
}

export const TableByUser: React.FC<Props> = ({ exportPdf }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);
  const [modalQuestionDetail, setModalQuestionDetail] = useState<{
    visible: boolean;
    question_id?: string;
  }>({
    visible: false,
  });

  const { reportSkillCheck, totalReportSkillCheck } = useSelector(skillCheckReportSelector);

  const columns: ColumnsType<ReportSkillCheck.SurfaceTableByUserType> = [
    {
      title: <span style={{ fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '3%',
      children: totalReportSkillCheck
        ? [
            {
              title: '-',
              className: 'average',
              dataIndex: 'index',
              align: 'center',
              width: '3%',
              render: (_text, _record, index) => (
                <span style={{ fontSize: 12, color: '#777777' }}>
                  {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
                </span>
              ),
            },
          ]
        : undefined,
    },
    {
      title: '設問',
      dataIndex: 'question_name',
      key: 'question_name',
      width: '31%',
      className: 'br-1',
      children: totalReportSkillCheck
        ? [
            {
              title: '合計',
              className: 'average br-1',
              key: 'question_name',
              dataIndex: 'question_name',
              width: '31%',
            },
          ]
        : undefined,
    },
    {
      title: '設問詳細',
      dataIndex: 'question_id',
      key: 'question_id',
      align: 'center',
      width: '6%',
      className: 'br-1',
      children: totalReportSkillCheck
        ? [
            {
              title: '-',
              className: 'average br-1',
              dataIndex: 'question_id',
              key: 'question_id',
              align: 'center',
              width: '6%',
              render: (question_id) => (
                <FileTextOutlined
                  className="icon"
                  onClick={() =>
                    setModalQuestionDetail({
                      visible: true,
                      question_id,
                    })
                  }
                />
              ),
            },
          ]
        : undefined,
    },
    {
      title: '正解',
      dataIndex: 'correct_answers_num',
      key: 'correct_answers_num',
      align: 'right',
      width: '5%',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumber(reportSkillCheck[0]?.correct_answers_num),
              className: 'average',
              dataIndex: 'correct_answers_num',
              key: 'correct_answers_num',
              align: 'right',
              width: '5%',
            },
          ]
        : undefined,
      sorter: (a, b) => a.correct_answers_num - b.correct_answers_num,
    },
    {
      title: '不正解',
      dataIndex: 'incorrect_answer',
      key: 'incorrect_answer',
      align: 'right',
      width: '6%',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumber(reportSkillCheck[0]?.incorrect_answer),
              className: 'average',
              dataIndex: 'incorrect_answer',
              key: 'incorrect_answer',
              align: 'right',
              width: '6%',
            },
          ]
        : undefined,
      sorter: (a, b) => a.incorrect_answer - b.incorrect_answer,
    },
    {
      title: '未経験',
      dataIndex: 'inexperienced',
      key: 'inexperienced',
      align: 'right',
      width: '6%',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumber(reportSkillCheck[0]?.inexperienced),
              className: 'average',
              dataIndex: 'inexperienced',
              key: 'inexperienced',
              align: 'right',
              width: '6%',
            },
          ]
        : undefined,
      sorter: (a, b) => a.inexperienced - b.inexperienced,
    },
    {
      title: '合計',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      width: '5%',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumber(reportSkillCheck[0]?.total),
              className: 'average',
              dataIndex: 'total',
              key: 'total',
              align: 'center',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      align: 'center',
      width: '7%',
      children: totalReportSkillCheck
        ? [
            {
              title: (
                <span>
                  {formatNumber(reportSkillCheck[0]?.accuracy_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              className: 'average',
              dataIndex: 'accuracy_rate',
              key: 'accuracy_rate',
              align: 'right',
              width: '7%',
              render: (record) =>
                record ? (
                  <span>
                    {formatNumber(record * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  '0%'
                ),
            },
          ]
        : undefined,
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      align: 'center',
      width: '7%',
      children: totalReportSkillCheck
        ? [
            {
              title: (
                <span>
                  {formatNumber(reportSkillCheck[0]?.wrong_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              className: 'average',
              dataIndex: 'wrong_rate',
              key: 'wrong_rate',
              align: 'right',
              width: '7%',
              render: (record) =>
                record ? (
                  <span>
                    {formatNumber(record * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  '0%'
                ),
            },
          ]
        : undefined,
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
    },
    {
      title: '未経験率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      align: 'center',
      width: '7%',
      className: 'br-1',
      children: totalReportSkillCheck
        ? [
            {
              title: (
                <span>
                  {formatNumber(reportSkillCheck[0]?.inexperienced_rate * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              className: 'average br-1',
              dataIndex: 'inexperienced_rate',
              key: 'inexperienced_rate',
              align: 'right',
              width: '7%',
              render: (record) =>
                record ? (
                  <span>
                    {formatNumber(record * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ) : (
                  '0%'
                ),
            },
          ]
        : undefined,
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
    },
    {
      title: '平均解答時間',
      dataIndex: 'average_answer_time_per_question',
      key: 'average_answer_time_per_question',
      align: 'center',
      width: '9%',
      className: 'br-1',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumberToMinute(reportSkillCheck[0]?.average_answer_time_per_question),
              className: 'average br-1',
              dataIndex: 'average_answer_time_per_question',
              key: 'average_answer_time_per_question',
              align: 'center',
              width: '9%',
              render: (value) => formatNumberToMinute(value),
            },
          ]
        : undefined,
      sorter: (a, b) => a.average_answer_time_per_question - b.average_answer_time_per_question,
    },
    {
      dataIndex: 'question_score',
      key: 'question_score',
      title: '設定スコア',
      align: 'center',
      width: '8%',
      children: totalReportSkillCheck
        ? [
            {
              title: formatNumber(reportSkillCheck[0]?.question_score),
              className: 'average',
              dataIndex: 'question_score',
              key: 'question_score',
              align: 'center',
              width: '8%',
              render: (record) => (record ? <span>{record}</span> : '-'),
            },
          ]
        : undefined,
      sorter: (a, b) => a.score - b.score,
    },
  ];

  return (
    <Wrapper>
      {totalReportSkillCheck ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportSkillCheck} <span className="text-static"> 件表示</span> /
                {totalReportSkillCheck}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportSkillCheck}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> {''} / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          !exportPdf
            ? reportSkillCheck
                .map((item, index) => ({ ...item, index }))
                .slice(1)
                .slice((page - 1) * pageSize, page * pageSize)
            : reportSkillCheck.map((item, index) => ({ ...item, index })).slice(1)
        }
        columns={columns}
        scroll={{ x: 1165 }}
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        bordered
        pagination={false}
      />
      <QuestionDetail visible={modalQuestionDetail} setVisible={setModalQuestionDetail} />
    </Wrapper>
  );
};

export default TableByUser;
