import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { informationRegisterSelector } from '../selectors';
import { RegisterInformationPaymentFormik } from 'types';
import { fourStepSchema } from 'libs/validations';
import { IMAGE_CREDIT_CARD } from 'constant';
import { SecurityCode } from 'assets';
import { Input } from 'components';
import { Wrapper } from './styles';

interface Props {
  previousStep: () => void;
  nextStep: () => void;
}

const FiveStep: React.FC<Props> = ({ previousStep, nextStep }) => {
  const { creditCard } = useSelector(informationRegisterSelector);

  const { messages } = useIntl();

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fourStepSchema,
    validateOnBlur: false,
    onSubmit: () => {
      nextStep();
    },
  });

  return (
    <Wrapper>
      <p className="text-center">{messages['AJ-05-1']}</p>
      <FormikProvider value={formik}>
        <Form layout="vertical" colon={false}>
          <div className="form">
            <Form.Item
              name="companyName"
              label={<span className="text-label">{messages['AJ-04-2']}</span>}
              className="form-input"
            >
              <div className="wrap-card-company">
                <div className="wrap-image">
                  <img
                    className="image-card-company"
                    aria-hidden
                    src={IMAGE_CREDIT_CARD.find((i) => i.name === formik.values.companyName)?.image}
                    alt={IMAGE_CREDIT_CARD.find((i) => i.name === formik.values.companyName)?.name}
                  />
                </div>
                <p className="label-text">
                  {IMAGE_CREDIT_CARD.find((i) => i.name === formik.values.companyName)?.name}
                </p>
              </div>
            </Form.Item>
            <Form.Item
              name="cardNumber"
              label={<span className="text-label">{messages['AJ-04-3']}</span>}
              className="form-input"
            >
              <Input name="cardNumber" className="input__disabled" type="number" readOnly />
            </Form.Item>
            <Form.Item
              name="customerName"
              label={<span className="text-label">{messages['AJ-04-4']}</span>}
              className="form-input"
            >
              <Input name="customerName" className="input__disabled" type="text" readOnly />
            </Form.Item>
            <Form.Item
              name="expirationDate"
              label={<span className="text-label">{messages['AJ-04-5']}</span>}
              className="form-input"
            >
              <Input
                name=""
                value={formik.values.expirationDate.slice(4, 6)}
                className="input__disabled input_small"
                type="number"
                readOnly
              />
              <span className="text-label-content">{messages['month']} / </span>
              <Input
                name=""
                value={formik.values.expirationDate.slice(0, 4)}
                className="input__disabled input_small"
                type="number"
                readOnly
              />
              <span className="text-label-content">{messages['year']}</span>
            </Form.Item>
            <Form.Item
              name="securityNumber"
              label={<span className="text-label">{messages['AJ-04-6']}</span>}
              className="form-input flex"
            >
              <Input
                name="securityNumber"
                className="input__disabled input_small"
                type="number"
                readOnly
              />
              <p className="content-text">
                {messages['AJ-04-7.1']}
                <br />
                {messages['AJ-04-7.2']}
              </p>
            </Form.Item>
            <div className="wrap-security">
              <img src={SecurityCode} alt="security-code" />
            </div>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">{messages['AJ-05-2']}</SubmitButton>
            <button type="button" className="go-back" onClick={previousStep}>
              {`<  ${messages['back']}`}
            </button>
          </div>
        </Form>
      </FormikProvider>
    </Wrapper>
  );
};

export default FiveStep;
