import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { Table } from 'antd';

import { settingSelector } from 'containers/AppSettings/selectors';
import { interviewUsersSelector } from '../../selectors';
import SectionStyled from './styles';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PER_PAGE = 10;

const SkillCheckHiddenListModal: React.FC<Props> = ({ onCancel, visible, setVisible }) => {
  const [dataRecord, setDataRecord] = useState<Types.DataCheckType[]>([]);
  const [page, setPage] = useState<number>(1);

  const { dataSkillUserCheck } = useSelector(interviewUsersSelector);
  const { loading } = useSelector(settingSelector);

  const columns: ColumnsType<Types.DataCheckType> = [
    {
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      width: '80%',
      render: (text, _item, index) => (
        <span>
          <span className="text-index">{PER_PAGE * (page - 1) + index + 1}</span> {text}
        </span>
      ),
    },
    {
      dataIndex: 'operation',
      width: '10%',
      render: () => <span className="text-return">戻す</span>,
    },
  ];

  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  useEffect(() => {
    setDataRecord(dataSkillUserCheck.filter((item) => item.hidden_skill_check === 'on'));
  }, [dataSkillUserCheck]);

  return (
    <Modal
      title={<h3 className="title">スキルチェック 非表示リスト</h3>}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      cancelButton={{
        text: '閉じる',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled dataCurriculumBatch={true} data={dataRecord}>
        <p className="sub-title">表示に戻す場合は戻すをクリックしてください。</p>
        <div className="text-count">
          <span className="text-static">
            {page * PER_PAGE > dataRecord.length ? dataRecord.length : page * PER_PAGE} 件表示 /{' '}
            {dataRecord.length} 名
          </span>
        </div>
        <Table
          className="table"
          dataSource={orderBy(dataRecord, 'createdAt', 'asc').map((item, index) => ({
            ...item,
            index,
          }))}
          columns={columns}
          loading={loading}
          pagination={{
            pageSize: PER_PAGE,
            showSizeChanger: false,
            total: dataRecord.length,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
          rowKey="index"
        />
      </SectionStyled>
    </Modal>
  );
};

export default SkillCheckHiddenListModal;
