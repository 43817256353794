import { ITheme } from './models';

export const defaultTheme: ITheme = {
  primary: '#fff',
  secondary: '#000',
  tertiary: '#ffffff99',
  textPrimary: '#000',
  textSecondary: '#fff',
  textTertiary: '#00000044',
  disableThemeScrollbar: false,
};
