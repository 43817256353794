import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import CurriculumNotAchieved from './CurriculumNotAchieved';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import KnowledgeToYourself from './KnowledgeToYourself';
import WithoutSkillCheck from './WithoutSkillCheck';
import { UserType } from 'types/config';
import SkillCheck from './SkillCheck';
import { Wrapper } from './styles';
import {
  getTopSkillCheckImplementStatus,
  getDirectedKnowledge,
  getSkillCheckUsers,
  getCurriculumData,
  getDataSkillCheck,
} from '../thunk';
import { isEqual } from 'lodash';

interface Props {
  role?: UserType;
}

const RightSideBar: React.FC<Props> = ({ role }) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const [roleUser, setRoleUser] = useState<UserType | undefined>(role);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getTopSkillCheckImplementStatus({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'skill_check_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSkillCheckUsers({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDirectedKnowledge({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'to_user_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'author_name',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        ),
        dispatch(
          getDataSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, isUserInfoChanged, userInfo]);

  useEffect(() => {
    if (!userInfo || isEqual(roleUser, role)) return;
    (async () => {
      dispatch(startLoading());
      setRoleUser(role);
      await dispatch(
        getCurriculumData({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'required_curriculum',
              search_value: ['1'],
            },
            ...(role === 'member'
              ? [
                  {
                    id: 'login_id',
                    search_value: [userInfo.login_id],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          sort_fields: [
            {
              id: 'curriculumn_code',
              order: 'asc',
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, role, userInfo, roleUser]);

  return (
    <Wrapper>
      <CurriculumNotAchieved role={role} />
      {role === 'admin' ? <WithoutSkillCheck /> : null}
      <SkillCheck role={role} />
      {role === 'admin' ? null : <KnowledgeToYourself />}
    </Wrapper>
  );
};

export default RightSideBar;
