export const formatNumber = (num: number | undefined) => {
  if (isNaN(num as number) || typeof num !== 'number') return '0';
  return num.toFixed(2).replace(/\.00$/, '');
};

export const formatNumberToHoursMinutes = (value: string | undefined) => {
  if (value === undefined) return '00:00';
  const [hours, minutes] = value.split('.');
  return `${hours || '00'}:${minutes || '00'}`;
};

export const formatNumberComma = (num: number | undefined) => {
  if (num === undefined) return '0';
  return new Intl.NumberFormat().format(num);
};

export const formatNumberToMinute = (num: number): string => {
  if (isNaN(num)) return '00:00';

  const isNegative = num < -1;
  const absoluteNum = Math.abs(num);

  const minutes = Math.floor(absoluteNum / 60);
  const seconds = Math.floor(absoluteNum % 60);

  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${isNegative ? '-' : ''}${formattedMinutes}:${formattedSeconds}`;
};

export const getFileFromUrl = async (url: string, name: string, defaultType = 'image/jpeg') => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
};

export const extractFileName = (fileNameUuid?: string) => {
  if (fileNameUuid && RegExp(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-/).exec(fileNameUuid)) {
    return fileNameUuid.replace(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}-/, '');
  }
  return '';
};
