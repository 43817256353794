import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { groupBy, reduce, round } from 'lodash';

import * as Types from 'types';
import {
  getDataUsageList,
  getDataPaymentStatus,
  getDataUserFeeDetail,
  getDataSkillCheckFeeDetail,
  getDataTableStorage,
  getDataTableFile,
  getSelectStorage,
  getCompanies,
} from './thunk';

export interface InitialState {
  dataUsageList: Array<Types.UsageList.DataConverted>;
  dataUserFeeDetail: Array<Types.UserFeeDetail.DataConverted>;
  dataPaymentStatus: Array<Types.PaymentStatus.ResponseType>;
  dataSkillCheckFeeDetail: Array<Types.SkillCheckFeeDetails.ResponseType>;
  dataTableStorage: Array<Types.StorageManagement.ResponseType>;
  dataTableFile: Array<any>;
  creditCard: Types.RegisterInformationPaymentFormik;
  dataSelect: Types.StorageManagement.SelectStorage;
  information: Types.AdministratorMasterFormik;
}

const initialState: InitialState = {
  dataSkillCheckFeeDetail: [],
  dataUserFeeDetail: [],
  dataPaymentStatus: [],
  dataUsageList: [],
  dataTableStorage: [],
  dataTableFile: [],
  creditCard: {
    companyName: 'VISA',
    cardNumber: '',
    customerName: '',
    expirationDate: '',
    securityNumber: '',
  },
  dataSelect: {
    file_extension: [],
    file_location: [],
    filename: [],
    dataTableFile: [],
  },
  information: {
    plan_name: '',
    number_of_users: 0,
    name: '',
    name_furigana: '',
    postal_code: '',
    prefecture: '',
    address: '',
    plot_number: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_email_confirm: '',
    admin_department: '',
    admin_position: '',
    admin_phone: '',
    admin_email: '',
  },
};

export const usageStatusSlice = createSlice({
  name: 'usageStatus-page',
  initialState,
  reducers: {
    setCreditCardInfo(state, action: PayloadAction<Types.RegisterInformationPaymentFormik>) {
      state.creditCard = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataUsageList.fulfilled, (state, action) => {
      state.dataUsageList = action.payload.report_results.map((item, index) => {
        const usage_storage = round((item.usage_storage || 10000) / Math.pow(1024, 3), 2);
        const usage_storage_unit_price = item.usage_storage_unit_price || 0;
        const user_fee_storage = usage_storage
          ? Math.max(50, Math.ceil(usage_storage / 50) * usage_storage_unit_price)
          : 0;
        const user_fee = item.monthly_charge || 0;
        const inter_skill_check_count = item.inter_skill_check_count || 0;
        const emp_skill_check_count = item.emp_skill_check_count || 0;
        const unit_price = item.skill_check_unit_price || 0;

        return {
          index,
          i_id: item.i_id,
          contract_date: item.contract_date,
          max_users: item.max_users,
          unit_price_user: item.user_monthly_fee,
          usage_storage,
          user_fee_storage,
          user_fee,
          skill_check_usage_count: emp_skill_check_count + inter_skill_check_count,
          unit_price,
          skill_check_usage_fee: (emp_skill_check_count + inter_skill_check_count) * unit_price,
          total_usage_fee:
            user_fee +
            (emp_skill_check_count + inter_skill_check_count) * unit_price +
            user_fee_storage,
          inter_skill_check_count,
          usage_storage_unit_price,
        };
      });
    });
    builder.addCase(getDataUserFeeDetail.fulfilled, (state, action) => {
      state.dataUserFeeDetail = action.payload.report_results.map((item) => {
        const emp_user_initial = item.emp_user_initial || 0;
        const emp_user_added = item.emp_user_added || 0;
        const inter_user_initial = item.inter_user_initial || 0;
        const inter_user_added = item.inter_user_added || 0;
        const user_monthly_fee = item.user_monthly_fee || 0;
        const emp_user_deleted = item.emp_user_deleted || 0;
        const inter_user_deleted = item.inter_user_deleted || 0;

        return {
          i_id: item.i_id,
          payment_method_name: item.payment_method_name,
          target_month: item.target_month,
          paid_date: item.paid_date,
          number_end_month:
            emp_user_initial +
            emp_user_added +
            inter_user_initial +
            inter_user_added -
            (emp_user_deleted + inter_user_deleted),
          number_user_month_max:
            emp_user_initial + emp_user_added + inter_user_initial + inter_user_added,
          unit_price: user_monthly_fee,
          user_fee:
            (emp_user_initial + emp_user_added + inter_user_initial + inter_user_added) *
            user_monthly_fee,
        };
      });
    });
    builder.addCase(getDataSkillCheckFeeDetail.fulfilled, (state, action) => {
      const array = groupBy(action.payload.report_results, (e) => e.skill_check_code);
      const resultAction = Object.keys(array).map((key) =>
        reduce(
          array[key],
          (result, item) => {
            return {
              ...result,
              company_id: item.company_id,
              skill_check_code: item.skill_check_code,
              skill_check_name: item.skill_check_name,
              skillcheck_tid: item.skillcheck_tid,
              skill_check_unit_price:
                result.skill_check_unit_price + (item.skill_check_unit_price || 0),
              count_skill_check: array[key].length,
              skill_check_user_fee:
                (result.skill_check_unit_price + (item.skill_check_unit_price || 0)) *
                array[key].length,
            };
          },
          {
            company_id: '',
            count_skill_check: 0,
            skill_check_unit_price: 10,
            skill_check_user_fee: 0,
            skill_check_code: '',
            skill_check_name: '',
            skillcheck_tid: '',
          }
        )
      );
      state.dataSkillCheckFeeDetail = resultAction;
    });
    builder.addCase(getDataPaymentStatus.fulfilled, (state, action) => {
      state.dataPaymentStatus = action.payload.report_results.filter((item) => item.paid_flg === 1);
    });
    builder.addCase(getDataTableStorage.fulfilled, (state, action) => {
      state.dataTableStorage = action.payload.report_results;
    });
    builder.addCase(getSelectStorage.fulfilled, (state, action) => {
      state.dataSelect = action.payload.report_results[0];
    });
    builder.addCase(getDataTableFile.fulfilled, (state, action) => {
      state.dataTableFile = [
        {
          company_id: '000000263',
          createdat: '2023-08-17T04:54:17Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/png',
          file_description: 'vvv',
          file_id: '000000142',
          file_title: 'タイトル',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T04:55:19Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/png',
          file_description: 'タイトル（自由設定項目',
          file_id: '000000143',
          file_title: 'タイトル（自由設定項目',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T04:56:43Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/png',
          file_description: 'タイトル（自由設定項目）',
          file_id: '000000144',
          file_title: 'タイトル（自由設定項目）',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T06:27:31Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'text/csv',
          file_description: 'zzzz',
          file_id: '000000145',
          file_title: 'dat csv',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T06:34:02Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/jpeg',
          file_description: 'dat',
          file_id: '000000146',
          file_title: 'dat',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T06:40:19Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'text/csv',
          file_description: 'bbbb',
          file_id: '000000147',
          file_title: 'bbbb',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T07:00:40Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/png',
          file_description: 'タイトル（自由設定項目',
          file_id: '000000148',
          file_title: 'タイトル（自由設定項目',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T07:22:58Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'video/mp4',
          file_description: 'ccccc',
          file_id: '000000149',
          file_title: 'video',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T07:27:13Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'application/msword',
          file_description: 'aaa',
          file_id: '000000150',
          file_title: 'word',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T07:37:33Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'application/pdf',
          file_description: 'pdf',
          file_id: '000000151',
          file_title: 'pdf',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T07:45:25Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'application/vnd.ms-excel',
          file_description: 'excel',
          file_id: '000000152',
          file_title: 'excel',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T08:32:31Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'video/mp4',
          file_description: 'video a',
          file_id: '000000153',
          file_title: 'video a',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T09:28:50Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'application/vnd.ms-powerpoint',
          file_description: 'ppt',
          file_id: '000000154',
          file_title: 'ppt',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-17T09:53:22Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/jpeg',
          file_description: 'ahihi',
          file_id: '000000155',
          file_title: 'ahihi',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-25T01:33:31Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/jpeg',
          file_description: '',
          file_id: '000000156',
          file_title: 'istockphoto-1322277517-612x612.jpeg',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
        {
          company_id: '000000263',
          createdat: '2023-08-25T01:34:14Z',
          createdby: 'hapok10931@tipent.com',
          extension: 'image/jpeg',
          file_description: '',
          file_id: '000000157',
          file_title: 'istockphoto-1322277517-612x612.jpeg',
          size: '12,220KB',
          place_of_use: 'マニュアル',
          publish: 0,
          rev_no: 1,
        },
      ];
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        i_id: action.payload.items[0]?.i_id,
        plan_name: action.payload.items[0]?.plan_id,
        name: action.payload.items[0]?.name,
        name_furigana: action.payload.items[0]?.name_furigana,
        postal_code: action.payload.items[0]?.postal_code,
        prefecture: action.payload.items[0]?.prefecture,
        address: action.payload.items[0]?.address,
        plot_number: action.payload.items[0]?.plot_number,
        admin_position: action.payload.items[0]?.admin_position,
        admin_department: action.payload.items[0]?.admin_department,
        building_name: action.payload.items[0]?.building_name,
        admin_phone: action.payload.items[0]?.admin_phone,
      };
    });
  },
});

export const { setCreditCardInfo, resetInitialState } = usageStatusSlice.actions;

export default usageStatusSlice.reducer;
