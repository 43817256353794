import { AnyObject } from 'types/config';

export * from './axios';

export const toObject = (fields: AnyObject[], key: string): AnyObject => {
  return fields.reduce((acc: any, obj) => {
    acc[obj[key]] = obj;
    return acc;
  }, {});
};

export const extractYupErrorPath = (path: string) => {
  const arr = path.split('.');
  const index = arr[0]?.replace(/[\\[\]']+/g, '');
  return {
    index: Number(index),
    key: arr[1],
  };
};
