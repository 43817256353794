import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { getPlans, isFirstLogin, updateLoginTime } from './thunk';
import { generatePath, useNavigate } from 'react-router-dom';
import { Wrapper, WrapperFirstLoginModal } from './styles';
import { authSelector } from 'containers/Auth/selectors';
import { HeaderInformationRegister } from 'components';
import { routes } from 'navigations/routes';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'hooks';
import SecondStep from './SecondStep';
import FirstStep from './FirstStep';
import ThirdStep from './ThirdStep';
import FourStep from './FourStep';
import FiveStep from './FiveStep';
import SixStep from './SixStep';
import { Logo } from 'assets';
import dayjs from 'dayjs';

const ModalInformationRegister: React.FC<{
  isCloseModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isCloseModal }) => {
  const [planSelectedIndex, setPlanSelectedIndex] = useState<number | undefined>(undefined);
  const [fistLoginContent, setFirstLoginContent] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [success, setSuccess] = useState(false);

  const { userInfo } = useSelector(authSelector);

  const email = localStorage.getItem('email');
  const { messages } = useIntl();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const AddLoginTime = () => {
    (async () => {
      const token = localStorage.getItem('sk_access_token');
      if (email && token) {
        const resultActionIsFirstLogin = await dispatch(
          isFirstLogin({
            conditions: [
              {
                id: 'email',
                search_value: [email],
                exact_match: true,
              },
            ],
            include_lookups: true,
            include_links: true,
            page: 1,
            per_page: 1,
          })
        );
        if (
          isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
          resultActionIsFirstLogin.payload.totalItems
        ) {
          await dispatch(
            updateLoginTime({
              id: resultActionIsFirstLogin.payload.items[0].i_id,
              data: {
                item: {
                  last_login_at: dayjs().toISOString(),
                },
                is_force_update: true,
              },
            })
          );
        }
      }
      navigate(generatePath(routes.Dashboard.path, { entity: 'receiving' }));
    })();
  };

  const previousStep = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevState) => prevState - 1);
    }
  }, [currentStep]);

  const nextStep = useCallback(() => {
    if (currentStep < 5) {
      setCurrentStep((prevState) => prevState + 1);
    }
  }, [currentStep]);

  const fistLoginModal = useMemo(
    () => (
      <WrapperFirstLoginModal>
        <img src={Logo} className="logo" alt="logo" />
        <h1 className="title">{messages['L-02-1']}</h1>
        {userInfo?.user_type !== 'interview'
          ? (!userInfo || userInfo.last_login_at) && (
              <p className="text-content">
                {messages['L-02-2']}
                <br />
                {messages['L-02-3']}
              </p>
            )
          : ''}
        <button
          className="btn-submit"
          onClick={() => {
            if (userInfo?.user_type === 'interview') {
              isCloseModal(false);
              navigate(routes.NewSkillCheckSelection.path);
            } else if (!userInfo || userInfo.last_login_at) {
              setFirstLoginContent(false);
            } else {
              isCloseModal(false);
              AddLoginTime();
            }
          }}
        >
          {messages['L-02-4']}
        </button>
      </WrapperFirstLoginModal>
    ),
    [messages, userInfo, isCloseModal, navigate, AddLoginTime, success]
  );

  const steps = useMemo(() => {
    switch (currentStep) {
      case 0:
        return (
          <FirstStep
            nextStep={nextStep}
            planSelectedIndex={planSelectedIndex}
            setPlanSelectedIndex={setPlanSelectedIndex}
          />
        );
      case 1:
        return <SecondStep nextStep={nextStep} />;
      case 2:
        return (
          <ThirdStep
            nextStep={nextStep}
            previousStep={previousStep}
            planSelectedIndex={planSelectedIndex}
            setSuccess={setSuccess}
            success={success}
          />
        );
      case 3:
        return <FourStep nextStep={nextStep} />;
      case 4:
        return <FiveStep previousStep={previousStep} nextStep={nextStep} />;
      case 5:
        return (
          <SixStep setCurrentStep={setCurrentStep} setSuccess={setSuccess} success={success} />
        );
    }
  }, [currentStep, nextStep, planSelectedIndex, previousStep, success]);

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getPlans({
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch]);

  const screenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    screenRef.current?.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <Wrapper ref={screenRef}>
      <div className={`${success ? 'container-logo' : 'container'}`}>
        {!fistLoginContent && (
          <HeaderInformationRegister
            currentStep={currentStep}
            planSelectedIndex={planSelectedIndex}
            success={success}
          />
        )}
        {fistLoginContent ? fistLoginModal : steps}
      </div>
    </Wrapper>
  );
};

export default ModalInformationRegister;
