import styled from 'styled-components';

export const SectionStyled = styled.div`
  .sub-title {
    font-size: 13px;
    text-align: center;
    color: #424242;
    margin-bottom: 30px;
    font-weight: 400;
  }
  .form {
    .form-input {
      .ant-input-suffix {
        position: absolute;
        top: 40px;
        right: 0;
        z-index: 99;
      }
    }
    .require {
      color: #f0330a;
    }
    .text-label {
      font-size: 13px;
      font-weight: 400;
    }
  }
`;
