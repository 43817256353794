import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #eeeeee;
  .header {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: 2px solid #eeeeee;
    .icon {
      font-size: 18px;
      color: #c4c4c4;
      margin-right: 5px;
    }
    .title {
      font-size: 13px;
      color: #424242;
    }
    .title-sub {
      width: 100%;
      text-align: right;
      font-size: 10px;
      color: #777;
    }
  }
  .wrap-items {
    max-height: 20vh;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .item {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      margin-bottom: 3px;
      .title {
        font-size: 12px;
        color: #424242;
      }
      .wrap-progress {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        .ant-progress-line {
          width: 80%;
        }
        .label {
          width: 15%;
          font-size: 20px;
          color: #eb864d;
          .small {
            font-size: 12px;
          }
        }
      }
    }
  }
  .pd-10 {
    padding: 13px 10px 10px 11px;
  }
  @media (max-width: 1440px) {
    .title {
      line-height: 11px;
    }
    .wrap-progress {
      line-height: 22px !important;
      height: 22px !important;
      .ant-progress-line {
        width: 180px;
      }
    }
  }
`;
