import styled from 'styled-components';

interface PopupProps {
  activeCollapse?: boolean;
  isExpand: boolean;
}

export default styled.div<PopupProps>`
  background-color: #f9f8f8;
  border: 2px solid #cecfd8;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  p {
    margin: 0;
  }
  .header-tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    width: 100%;
    background-color: #ffffff;
    border-bottom: ${(props) => (props.isExpand ? '1px solid #cccccc' : 'unset')};
    border-radius: 4px 4px 0 0;
    .header-title,
    .header-information {
      display: flex;
      align-items: center;
    }
    .header-title {
      width: 52%;
      padding: 0 20px;
    }
    .header-information {
      width: 30%;
      justify-content: flex-end;
    }
    .header-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${(props) => (props.activeCollapse ? '250px' : '18%')};
      height: 100%;
      border-left: 1px solid #cccccc;
      .btn-add-user,
      .disabled {
        height: 100%;
        width: 100%;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        color: #159193;
        cursor: pointer;
        background-color: #ffffff;
      }
      .disabled {
        color: #777777;
      }
    }
    .icon-outlined {
      color: #999999;
      padding-right: 10px;
      cursor: ${(props) => (props.activeCollapse ? 'pointer' : 'default')};
    }
    .header-title .title {
      font-size: 16px;
      color: #2a2a2a;
      cursor: ${(props) => (props.activeCollapse ? 'pointer' : 'default')};
      .text-code {
        color: #999999;
      }
    }
    .icon {
      font-size: 20px;
      color: #c4c4c4;
      margin: 0 8px;
      cursor: pointer;
    }
    .wrap-amount-user {
      border-left: 1px solid #eaeaea;
      padding-left: 10px;
      margin-left: 15px;
      .title {
        font-family: Lato;
        font-size: 20px;
        color: #424242;
      }
      .sub-title {
        color: #666666;
        font-size: 13px;
      }
    }
  }
  .body {
    display: ${(props) => (props.isExpand ? 'block' : 'none')};
    padding-left: 20px;
    padding-bottom: 20px;
    ${(props) =>
      props.activeCollapse
        ? 'height: 130px; overflow-x: auto'
        : 'min-height: 130px; max-height: 300px; overflow-y: auto;'}
    &::-webkit-scrollbar-thumb {
      width: 7px;
      background-color: #c4c4c4;
      border-radius: 10px;
    }
    .wrap-item {
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      height: 100%;
      .item {
        position: relative;
        width: 20%;
        height: 90px;
        margin-top: 20px;
        padding: 5px;
      }
    }
  }
`;

export const StyledPullDown = styled.div`
  margin-top: -5px;
  background-color: #ffffff;
  border: 1px solid #bbbbbb;
  border-top: 1px solid #e1e1e1;
  border-radius: 0 0 5px 5px;
  padding: 12px 12px 5px 12px;
  cursor: pointer;
  .text-main {
    display: inline-block;
    background-color: #fcf4e2;
    font-weight: 700;
    font-size: 11px;
    color: #e7a100;
    margin: 0;
    padding: 0 5px;
  }
  .text-position {
    font-size: 13px;
    color: #424242;
    overflow: hidden;
  }
  .text-concurrent-post {
    width: 42px;
    height: 18px;
    background-color: #e6f6f6;
    font-weight: 700;
    font-size: 11px;
    color: #159193;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-content {
    font-size: 13px;
    color: #424242;
    margin: 0;
    overflow: hidden;
    .text-number {
      color: #cccccc;
      margin-right: 5px;
    }
  }
  .text-icon-up-outline {
    display: block;
    text-align: center;
    color: #bbbbbb;
    font-size: 10px;
  }
`;

export const ItemDropDownStyled = styled.div<{ itemActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid ${(props) => (props.itemActive ? '#bbbbbb' : '#e1e1e1')};
  border-radius: ${(props) => (props.itemActive ? '5px 5px 0 0' : '5px')};
  .text-body {
    position: absolute;
    padding: 0 10px;
    top: -18px;
    left: 5px;
    background-color: #999999;
    border-radius: 2px 2px 0 0;
    font-size: 11px;
    color: #ffffff;
  }
  .wrapper {
    display: flex;
    height: 85%;
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 100%;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .user {
      width: 75%;
      .wrap-concurrent-post {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 14px;
        .text-concurrent-post {
          background-color: rgba(0, 163, 165, 0.1);
          border-radius: 0 4px;
          padding: 0 5px;
          color: #159193;
          font-size: 11px;
        }
      }
      .user-info {
        .text-position {
          max-height: 200px;
          color: #999999;
          font-size: 10px;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 22px;
        }
        .text-name {
          color: #2a2a2a;
          font-size: 14px;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .icon-delete {
      position: absolute;
      font-size: 16px;
      color: #c4c4c4;
      cursor: pointer;
      bottom: 5px;
      right: 5px;
    }
  }
  .drop-down {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 8px;
    height: 15%;
  }
  @media (max-width: 1440px) {
    .wrapper {
      .avatar {
        width: 35%;
      }
      .user {
        width: 65%;
        .user-info {
          .text-position {
            max-width: 140px;
          }
          .text-name {
            max-width: 140px;
          }
        }
      }
    }
  }
`;
