import styled from 'styled-components';

export const SectionStyled = styled.div`
  display: flex;
  flex-direction: column;

  .wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #efeeee;
    padding: 10px 30px;
    .title {
      margin: 0;
      font-size: 15px;
      color: #2a2a2a;
    }

    .select {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .item-title {
        margin: 0;
        font-size: 11px;
        color: #777777;
      }
      .select-item {
        display: flex;
        align-items: center;
        .label {
          margin: 0;
          padding-right: 10px;
          font-size: 12px;
          color: #424242;
        }
      }
    }
  }
  .wrap-body {
    padding: 30px;
    .title-note {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .title {
        font-size: 18px;
        color: #424242;
      }
      .version {
        font-size: 11px;
        color: #777777;
      }
    }
    .item {
      padding-left: 20px;

      .item-left {
        background: linear-gradient(0deg, #ffffff, #ffffff), #efecec;
        border: 1px solid #cccccc;
        border-radius: 5px;
        .number-question {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #cccccc;
          .label-number {
            padding: 20px 15px;
            border-right: 1px solid #cccccc;
            .number {
              margin: 0;
              font-size: 15px;
              color: #2a2a2a;
            }
          }
          .text {
            margin: 0 0 0 15px;
          }
        }
        .content {
          padding: 25px;
          .title {
            font-size: 13px;
            line-height: 180%;
            color: #424242;
          }
          .list {
            background: #f4f4f4;
            border: 1px solid #eeeeee;
            margin: 0;
            padding: 15px 15px 15px 40px;
            font-size: 13px;
            color: #424242;
            li::marker {
              font-size: 70%;
            }
          }
        }
      }
      .item-right {
        .overview {
          .label-overview {
            font-size: 12px;
            color: #424242;
          }
          .overview-content {
            font-size: 12px;
            color: #424242;
            margin: 0;
            padding: 15px 15px 45px 15px;
            background: #ffffff;
            box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
            border-radius: 3px;
            border: 1px solid #cccccc;
          }
        }
        .select-item {
          padding: 25px 0;
          .label-select {
            font-size: 13px;
            color: #424242;
            border-bottom: 1px dashed #cccccc;
            margin-bottom: 15px;
          }
        }
      }
    }
  }
  .ant-radio-group.ant-radio-group-solid {
    display: flex;
    flex-direction: column;
  }
  .ant-radio-group.ant-radio-group-outline {
    width: 100%;
  }
  .ant-radio-button-wrapper {
    margin: 12px 0;
    padding: 20px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.18);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .item {
      display: flex;
      .label-item {
        margin: 0;
        font-family: Lato;
        font-size: 13px;
        color: #777777;
      }
      .item-text {
        margin: 0;
        padding: 0 0 0 10px;
        font-size: 14px;
        color: #2a2a2a;
      }
    }
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
    background: #08a3a5;
    border: 1px solid #08a3a5;
    .item {
      display: flex;
      .label-item {
        color: rgba(255, 255, 255, 0.6);
      }
      .item-text {
        color: #ffffff;
      }
    }
  }
  .ant-radio-group-solid.ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
  }
  .ant-radio-button-wrapper:hover {
    color: unset;
  }
  .ant-radio-button-wrapper-checked:not(
      [class*=' ant-radio-button-wrapper-disabled']
    ).ant-radio-button-wrapper:first-child {
  }
  .ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #08a3a5;
    border-color: #08a3a5;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #08a3a5;
  }
  .ant-radio-button-wrapper::before {
    display: none;
  }
  .ant-select-selector {
    border-radius: 3px !important;
  }
  .ant-col.ant-col-16.item-left {
    padding: 0 !important;
  }
`;
