import styled from 'styled-components';

export const SectionStyled = styled.div`
  background-color: #f9f8f8;
  font-size: 13px;
  color: #424242;
  padding-top: 32px;
  text-align: center;
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 640px;
    height: 88px;
    margin: auto;
    background: #ffffff;
    border: 1px solid #eeeeee;
    .lock {
      display: flex;
      align-items: center;
      font-size: 13px;
      color: #b57fcf;
      margin: 0;
      .box-icon {
        width: 30px;
        height: 30px;
        background: #b57fcf;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 7px;
        .icon {
          font-size: 22px;
          color: #ffffff;
        }
      }
    }
    .arrow-icon {
      margin: 0 30px;
    }
    .open {
      display: flex;
      align-items: center;
      color: #71c131;
      font-size: 13px;
      .box-icon {
        width: 30px;
        height: 30px;
        background-color: #71c131;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 7px;
        .icon {
          font-size: 22px;
          color: #ffffff;
        }
      }
    }
    .scale {
      font-weight: 700;
      .box-icon {
        width: 40px;
        height: 40px;
        .icon {
          font-size: 27.5px;
        }
      }
    }
  }
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #424242;
    margin: 10px 0;
  }
`;
