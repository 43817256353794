import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Col, Row, Select, Switch } from 'antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { uniq } from 'lodash';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CheckOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import TrainingCongratulation from './TrainingCongratulation';
import NodeTreeView from 'containers/Training/NodeTreeView';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { trainingCurriculumSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import TrainingImplement from './TrainingImplement';
import TrainingComplete from './TrainingComplete';
import TreeViewStyled, { Button } from './styles';
import { Header, SelectField } from 'components';
import { LIST_LABEL } from 'constant';
import * as Types from 'types';
import {
  getOptionUsers,
  getOptionCurriculum,
  getOptionDepartment,
  getData,
  getDataRequired,
  getDataAffiliation,
} from './thunk';

const { Option } = Select;

const TrainingCurriculum: React.FC = () => {
  const [numberQuestionImplement, setNumberQuestionImplement] = useState<number>(0);
  const [columnClosed, setColumnClosed] = useState<number | undefined>();
  const [visibleTimer, setVisibleTimer] = useState<boolean>(false);
  const [userLoginId, setUserLoginId] = useState<string>('');
  const [correctNum, setCorrectNum] = useState<number>(0);
  const [tabActive, setTabActive] = useState<number>(0);
  const [changeTab, setChangeTab] = useState<number>(0);
  const [startTime, setStartTime] = useState<string>();

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    dataSelectCurriculum,
    dataSelectDepartment,
    dataSelectCreator,
    dataTrainingCurriculum,
    dataTrainingRequiredCurriculum,
    dataAffiliationAssign,
  } = useSelector(trainingCurriculumSelector);

  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const formik = useFormik<Types.TrainingCurriculumSelectionFormik>({
    initialValues: {
      code: '',
      createdby: '',
      affiliation_id: '',
    },
    onSubmit: async (values) => {
      const creatorAffiliation = dataAffiliationAssign
        .filter((c) => c.affiliation_id === values.affiliation_id)
        .map((item) => item.login_id);
      const formattedEmails = uniq(creatorAffiliation)
        .map((email) => `${email}`)
        .join('|');

      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      if (values.affiliation_id) {
        conditions.push({
          id: 'creator',
          search_value: [formattedEmails],
          exact_match: true,
        });
      }
      Object.keys(values).forEach((key) => {
        if (key !== 'affiliation_id' && values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      if (tabActive) {
        await dispatch(
          getDataRequired({
            conditions: [
              ...conditions,
              {
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              },
              {
                id: 'required_curriculum',
                search_value: ['1'],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      } else {
        dispatch(
          getData({
            conditions: [
              ...conditions,
              {
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              },
              {
                id: 'required_curriculum',
                search_value: ['0'],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
      }
    },
  });

  const onChange = (checked: boolean) => {
    setVisibleTimer(checked);
  };

  useEffect(() => {
    if (!userInfo || userInfo.login_id === userLoginId) return;
    (async () => {
      setUserLoginId(userInfo.login_id);
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getOptionCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'publish',
                search_value: ['1'],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getOptionUsers({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'user_type',
                search_value: ['interview'],
                not_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getOptionDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataAffiliation({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, userLoginId]);

  useEffect(() => {
    if (!userInfo || changeTab) return;
    dispatch(
      getData({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'publish',
            search_value: ['1'],
            exact_match: true,
          },
          {
            id: 'required_curriculum',
            search_value: ['0'],
            exact_match: true,
          },
          ...(formik.values.code
            ? [
                {
                  id: 'code',
                  search_value: [formik.values.code],
                  exact_match: true,
                },
              ]
            : []),
          ...(formik.values.createdby
            ? [
                {
                  id: 'createdby',
                  search_value: [formik.values.createdby],
                  exact_match: true,
                },
              ]
            : []),
          ...(formik.values.affiliation_id
            ? [
                {
                  id: 'affiliation_id',
                  search_value: [formik.values.affiliation_id],
                  exact_match: true,
                },
              ]
            : []),
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, changeTab]);

  useEffect(() => {
    if (!userInfo) return;
    if (tabActive && !changeTab) {
      dispatch(
        getDataRequired({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'publish',
              search_value: ['1'],
              exact_match: true,
            },
            {
              id: 'required_curriculum',
              search_value: ['1'],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, userInfo, isUserInfoChanged, tabActive, changeTab]);

  return changeTab === 1 ? (
    <TrainingImplement
      startTime={startTime}
      correctNum={correctNum}
      setChangeTab={setChangeTab}
      visibleTimer={visibleTimer}
      setCorrectNum={setCorrectNum}
      creator={formik.values.createdby}
      affiliationId={formik.values.affiliation_id}
      curriculumCode={formik.values.code}
      numberQuestionImplement={numberQuestionImplement}
    />
  ) : changeTab === 2 ? (
    <TrainingComplete
      correctNum={correctNum}
      setChangeTab={setChangeTab}
      setCorrectNum={setCorrectNum}
    />
  ) : changeTab === 3 ? (
    <TrainingCongratulation setChangeTab={setChangeTab} setCorrectNum={setCorrectNum} />
  ) : (
    <TreeViewStyled>
      <Header title="トレーニング" />
      <div className="wrap-header">
        <div className="item-switch">
          <p className="text-note">
            実施したいカリキュラムを選択し、任意の階層からトレーニングを開始することができます。
          </p>
          <div className="switch">
            トレーニング中の制限時間の表示
            <Switch className="icon" checked={visibleTimer} onChange={onChange} />
          </div>
        </div>

        <Row className="wrap-button">
          <Col className="btn-tab">
            {[' カリキュラム', '必修カリキュラム'].map((item, index) => (
              <Button
                className="button"
                key={index}
                tabActive={tabActive}
                index={index}
                onClick={() => {
                  setTabActive(index);
                }}
              >
                {tabActive === index ? <CheckOutlined className="check-icon" /> : null}
                {item}
              </Button>
            ))}
          </Col>
          <Col className="group-search">
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="code"
                    className="curriculum"
                    label={<span className="text-label">カリキュラム</span>}
                  >
                    <SelectField
                      name="code"
                      placeholder="カリキュラム名で検索"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataSelectCurriculum?.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="createdby"
                    className="author"
                    label={<span className="text-label">作成者</span>}
                  >
                    <SelectField
                      name="createdby"
                      placeholder="作成者で検索"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataSelectCreator?.map((item, index) => (
                        <Option key={index} value={item.email}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="affiliation_id"
                    className="author"
                    label={<span className="text-label">作成者所属</span>}
                  >
                    <SelectField
                      name="affiliation_id"
                      placeholder="作成者所属で検索"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {dataSelectDepartment?.map((item, index) => (
                        <Option key={index} value={item.affiliation_id}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </Form>
            </FormikProvider>
          </Col>
        </Row>
        <div className="flex-label">
          {tabActive !== 2 ? (
            LIST_LABEL.map((item, index) => (
              <p
                style={{ width: index === 0 ? '8.67%' : '12.67%', flexGrow: index === 0 ? 0 : 1 }}
                key={index}
                className={`label-text${index === columnClosed ? ' active' : ''}`}
                onClick={() =>
                  setColumnClosed((prevState) =>
                    prevState === index || index > 4 ? undefined : index
                  )
                }
              >
                {index < 5 ? (
                  index === columnClosed ? (
                    <CaretUpOutlined className="icon-label" />
                  ) : (
                    <CaretDownOutlined className="icon-label" />
                  )
                ) : null}
                {messages[item]}
              </p>
            ))
          ) : (
            <p
              className="label-text"
              onClick={() => setColumnClosed((prevState) => (prevState === 0 ? undefined : 0))}
            >
              {columnClosed === 0 ? (
                <CaretUpOutlined className="icon-label" />
              ) : (
                <CaretDownOutlined className="icon-label" />
              )}
              必修カリキュラム
            </p>
          )}
        </div>
      </div>
      <div className="wrap-body">
        {tabActive === 0
          ? dataTrainingCurriculum.map((treeData, index) => (
              <div
                key={index}
                className={`wrap-tree ${
                  index < dataTrainingCurriculum?.length - 1 ? 'bordered' : ''
                }`}
              >
                <NodeTreeView
                  treeData={treeData}
                  tabActive={tabActive}
                  columnClosed={columnClosed}
                  setChangeTab={setChangeTab}
                  setStartTime={setStartTime}
                  setNumberQuestionImplement={setNumberQuestionImplement}
                  type="tabActive-0"
                  isIndex={index}
                />
              </div>
            ))
          : dataTrainingRequiredCurriculum.map((treeData, index) => (
              <div
                key={index}
                className={`wrap-tree ${
                  index < dataTrainingRequiredCurriculum?.length - 1 ? 'bordered' : ''
                }`}
              >
                <NodeTreeView
                  treeData={treeData}
                  tabActive={tabActive}
                  columnClosed={columnClosed}
                  setChangeTab={setChangeTab}
                  setStartTime={setStartTime}
                  setNumberQuestionImplement={setNumberQuestionImplement}
                  type="tabActive-1"
                  isIndex={index}
                />
              </div>
            ))}
      </div>
    </TreeViewStyled>
  );
};

export default TrainingCurriculum;
