import styled from 'styled-components';

interface Props {
  dataUsageStatus: boolean;
}

export default styled.section<Props>`
  .container {
    background-color: #f9f8f8;
    width: 100%;
    padding: 30px;
    .wrap-sort {
      display: flex;
      align-items: center;
      .lable-sort {
        color: #424242;
        font-size: 11px;
      }
      .wrap-sort-icon {
        display: flex;
        cursor: pointer;
        flex-direction: column;
        border: 1px solid #eeeeee;
        padding: 3.13px 5.33px;
        border-radius: 3px;
        margin-left: 6px;
        .icon {
          font-size: 10px;
          color: #bfbfbf;
        }
        .icon-active {
          color: #08a3a5;
        }
      }
    }
    .ant-table-column-sorter {
      display: none;
    }
    .item-storage {
      display: flex;
      justify-content: space-between;
      .text-note {
        font-size: 12px;
        color: #424242;
      }
      .btn-storage {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        padding: 20px;
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        .icon-storage {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }
    }
    .border-line {
      border-top: 1px solid #dddddd;
      width: 40px;
      margin: 30px 0;
    }
    .form-search {
      display: flex;
      align-items: center;
      padding-bottom: 25px;
      .item {
        width: 20%;
        margin-right: 8px;
        .text-label {
          font-size: 12px;
        }
        .date-input {
          width: 100%;
        }
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-right: 12px;
        display: block;
        width: 60px;
        margin-left: 10px;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-family: 'Noto Sans Javanese';
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 8px;
        }
      }
      .radio-item {
        display: flex;
        align-items: center;
        margin-right: 30px;
        position: relative;
        .three-border {
          border: 1px solid #000000;
          width: 8px;
          height: 30px;
          border-right: 0;
          margin: 5px;
        }
        .radio-select {
          display: flex;
          flex-direction: column;
          .ant-radio-wrapper {
            padding: 5px 0;
          }
        }
        &::before {
          content: '';
          position: absolute;
          bottom: 15px;
          right: -10px;
          width: 0;
          height: 0;
          border-top: 10px solid #f9f8f8;
          border-bottom: 10px solid #f9f8f8;
          border-left: 10px solid #dddddd;
        }
      }
    }
    .wrap-table {
      position: relative;
      .total-record-table-pdf {
        display: flex;
      }
      .total-record-table {
        position: absolute;
        top: 20px;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        z-index: 9;
        gap: 30px;
        .text-static {
          padding-left: 3px;
        }
        .page-select {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
      .btn-active {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0;
        z-index: 9;
        font-size: 13px;
        padding: 20px;
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        color: #ffffff;
        cursor: pointer;
        .size-icon {
          font-size: 18px;
        }
      }
      .item-table {
        display: flex;
        flex-direction: row;
        justify-content: end;
        align-items: baseline;
        .label-number {
          margin: 0;
          font-size: 14px;
          color: #424242;
        }
        .label {
          margin: 0;
          color: #999999;
          font-size: 10px;
        }
      }
      .table,
      .table-pdf {
        margin-top: ${(props) => (props.dataUsageStatus ? 0 : 65)}px;
        .ant-table-thead tr .ant-table-cell {
          font-weight: 700;
          font-size: 12px;
          color: #2a2a2a;
          text-align: center;
          ::before {
            height: 100% !important;
          }
        }
        .user-fee {
          background: #eb8b55 !important;
          color: #ffffff !important;
        }
        .skill-check {
          background: #08a3a5 !important;
          color: #ffffff !important;
        }

        .storage-user-fee {
          background: linear-gradient(0deg, #92bbe2 0%, #92bbe2 100%), #777;
          color: #ffffff !important;
        }

        .ant-table-thead tr .ant-table-cell.user-fee-item {
          color: #eb8b55;
          background: #f8f0ec;
        }

        .ant-table-thead tr .ant-table-cell.skill-check-item {
          background: #e2f3f3;
          color: #159193;
        }

        .ant-table-thead tr .ant-table-cell.users-storage {
          color: #92bbe2;
          background: linear-gradient(0deg, #d9e8f6 0%, #d9e8f6 100%), #777;
        }

        .ant-table-tbody .ant-table-cell {
          text-align: end;
          border-left: 1px solid #eaeaea !important;
        }
        .ant-table-tbody .ant-table-row .ant-table-cell {
          ::before {
            content: '';
            height: 100% !important;
          }
        }
        .service_usage_date {
          text-align: center !important;
          font-size: 15px;
          color: #2a2a2a;
        }
        .detail {
          text-align: center !important;
          font-size: 15px;
          color: #c4c4c4;
        }
        .icon {
          font-size: 18px;
        }
        .bold {
          font-weight: 700;
          font-size: 15px;
          text-align: right;
          color: #424242;
        }
      }
      .table-pdf {
        margin-top: 12px;
      }
    }
  }
`;
