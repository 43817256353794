import React from 'react';
import { Document, Page, View, Text, Image, Font } from '@react-pdf/renderer';
import dayjs from 'dayjs';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';
import { LogoRStandard } from 'assets';
import * as Types from 'types';
import styles from './styles';

interface Props {
  total: number;
  dataUserFeeDetail: Types.UserFeeDetail.DataConverted[];
  companyName?: string;
}

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const FileExportPDF: React.FC<Props> = ({ total, dataUserFeeDetail, companyName }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.receiptInfo}>
            <Text style={styles.title}>領収書</Text>
            <View style={styles.info}>
              <Text>領収書No.：100021</Text>
              <br />
              <Text>
                発行日：
                {dayjs(dataUserFeeDetail[0]?.paid_date).format('YYYY年MM月DD日')}
              </Text>
            </View>
          </View>
          <View style={styles.sent}>
            <Text>{companyName} 御中</Text>
          </View>
          <View style={styles.amountMoney}>
            <Text style={styles.label}>金額</Text>
            <Text style={styles.labelNumber}>
              ￥
              {(
                dataUserFeeDetail[0]?.user_fee +
                total +
                ((dataUserFeeDetail[0]?.user_fee + total) * 10) / 100
              ).toLocaleString()}
              -
            </Text>
            <Text style={styles.label}>（税込）</Text>
          </View>
          <View style={styles.footer}>
            <View style={styles.leftSide}>
              <Text style={styles.textLabel}>
                但し SKILL FAMILIAR サービスご利用分として
                <br />
                上記正に領収いたしました
              </Text>
              <Text style={styles.paymentMethod}>
                お支払い方法：{dataUserFeeDetail[0]?.payment_method_name}
              </Text>
              <View style={styles.row}>
                <Text style={styles.labelSubLeft}>内訳</Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.labelSubLeft}>税抜金額：</Text>
                <Text style={styles.value}>
                  ¥{(dataUserFeeDetail[0]?.user_fee + total).toLocaleString()}-
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.labelSubLeft}>消費税額等：</Text>
                <Text style={styles.value}>
                  ¥{(((dataUserFeeDetail[0]?.user_fee + total) * 10) / 100).toLocaleString()}-
                </Text>
              </View>
            </View>
            <View style={styles.rightSide}>
              <View>
                <Text>株式会社RSTANDARD </Text>
                <Text>〒110-0005</Text>
                <Text>東京都台東区上野3-16-4 文行同ビル 5F</Text>
              </View>
              <Image src={LogoRStandard} />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default FileExportPDF;
