import { createSlice } from '@reduxjs/toolkit';
import { groupBy, orderBy, unionBy } from 'lodash';

import { getSelectTypes } from '../../thunk';
import * as Types from 'types';
import {
  getReportSkillCheckUserTrans,
  getDetailOfSkillCheckResults,
  getReportSkillCheckUserTransByLoginID,
  getReportSkillCheckUserTransBySkillCheckCode,
} from './thunk';

export interface InitialState {
  reportSkillCheckUserTransBySkillCheckCode: Types.ReportSkillCheckUserTrans.OneLoginIDAndOneSkillCheckCode;
  reportSkillCheckUserTransByLoginID: Types.ReportSkillCheckUserTrans.OneLoginIDAndOneSkillCheckCode;
  reportSkillCheckUserTrans: Types.ReportSkillCheckUserTrans.UserReportSurfaceTable[];
  detailOfSkillCheckResults: Types.DetailOfSkillCheckResults.ResponseType[];
  totalReportSkillCheckUserTrans: number;
  time_limit: Types.SelectTypes.ResponseType[];
}

const initialState: InitialState = {
  totalReportSkillCheckUserTrans: 0,
  reportSkillCheckUserTrans: [],
  detailOfSkillCheckResults: [],
  time_limit: [],
  reportSkillCheckUserTransBySkillCheckCode: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
  reportSkillCheckUserTransByLoginID: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
};

export const skillCheckUserReportSlice = createSlice({
  name: 'skillCheckUserReport-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
    sortDataReportSkillCheckUserTrans: (state, action) => {
      const sortData = orderBy(
        state.reportSkillCheckUserTrans.slice(1),
        (e) => e[action.payload.sort_field as keyof typeof Types.ReportSkillCheckUserTrans],
        [action.payload.sort_order]
      );
      state.reportSkillCheckUserTrans = [state.reportSkillCheckUserTrans[0], ...sortData];
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      state.time_limit = action.payload.items.filter((item) => item.type === 'time_limit');
    });

    builder.addCase(getReportSkillCheckUserTrans.fulfilled, (state, action) => {
      const data = groupBy<Types.ReportSkillCheckUserTrans.ResponseType>(
        action.payload.report_results,
        action.payload.isUserTransTable ? 'login_id' : 'skill_check_code'
      );

      const reportSkillCheckUserTrans: Types.ReportSkillCheckUserTrans.UserReportSurfaceTable[] =
        Object.keys(data).map((key) => {
          const items = data[key];

          const {
            correct_answers_num,
            acquisition_score,
            setting_score,
            probs_count,
            responses_num,
            question_time_limit,
            answer_time,
            correct_answers_score,
          } = items.reduce(
            (prev, next) => ({
              correct_answers_num: prev.correct_answers_num,
              acquisition_score: prev.acquisition_score,
              setting_score: prev.setting_score,
              probs_count: prev.probs_count,
              responses_num: prev.responses_num!,
              question_time_limit: prev.question_time_limit,
              answer_time: prev.answer_time + (next.answer_time || 0),
              correct_answers_score:
                prev.correct_answers_score +
                (next.correct_answers_num || 0) * (next.setting_score || 0),
            }),
            {
              correct_answers_num: items[0].correct_answers_num!,
              acquisition_score: items[0]?.acquisition_score || 0,
              setting_score: items[0]?.setting_score || 0,
              probs_count: items[0]?.probs_count!,
              responses_num: items[0].responses_num!,
              question_time_limit: items[0].question_time_limit!,
              answer_time: 0,
              correct_answers_score: 0,
            }
          );

          return {
            user_name: items[0].user_name,
            login_id: items[0].login_id,
            skill_check_name: items[0].skill_check_name,
            skill_check_code: items[0].skill_check_code,
            correct_answers_num,
            incorrect_answer: responses_num - correct_answers_num,
            inexperienced: probs_count - responses_num!,
            probs_count,
            accuracy_rate: correct_answers_num / probs_count,
            progress_rate: responses_num / probs_count,
            wrong_rate: (responses_num! - correct_answers_num) / probs_count,
            inexperienced_rate: (probs_count - responses_num) / probs_count,
            question_time_limit,
            total_answer_time: answer_time,
            average_answer_time_per_question: answer_time / items.length,
            setting_score,
            acquisition_score,
            responses_num,
            correct_answers_score,
            acquisition_score_rate: acquisition_score / setting_score,
            implementation_date: items[0].implementation_date,
          };
        });

      const dataLength = reportSkillCheckUserTrans.length;

      const {
        correct_answers_num,
        acquisition_score,
        setting_score,
        probs_count,
        question_time_limit,
        total_answer_time,
        average_answer_time_per_question,
        inexperienced_rate,
        wrong_rate,
        accuracy_rate,
        inexperienced,
        incorrect_answer,
        responses_num,
        correct_answers_score,
      } = reportSkillCheckUserTrans.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
          acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
          setting_score: prev.setting_score + (next.setting_score || 0),
          probs_count: prev.probs_count + (next.probs_count || 0),
          question_time_limit: prev.question_time_limit + (next.question_time_limit || 0),
          total_answer_time: prev.total_answer_time + (next.total_answer_time || 0),
          average_answer_time_per_question:
            prev.average_answer_time_per_question + (next.average_answer_time_per_question || 0),
          inexperienced_rate: prev.inexperienced_rate + (next.inexperienced_rate || 0),
          wrong_rate: prev.wrong_rate + (next.wrong_rate || 0),
          accuracy_rate: prev.accuracy_rate + (next.accuracy_rate || 0),
          inexperienced: prev.inexperienced + (next.inexperienced || 0),
          incorrect_answer: prev.incorrect_answer + (next.incorrect_answer || 0),
          responses_num: prev.responses_num + (next.responses_num || 0),
          correct_answers_score: prev.correct_answers_score + (next.correct_answers_score || 0),
        }),
        {
          correct_answers_num: 0,
          acquisition_score: 0,
          setting_score: 0,
          probs_count: 0,
          question_time_limit: 0,
          total_answer_time: 0,
          average_answer_time_per_question: 0,
          inexperienced_rate: 0,
          wrong_rate: 0,
          accuracy_rate: 0,
          inexperienced: 0,
          incorrect_answer: 0,
          responses_num: 0,
          correct_answers_score: 0,
        }
      );

      state.reportSkillCheckUserTrans = [
        {
          user_name: '',
          skill_check_name: '',
          login_id: '',
          skill_check_code: '',
          correct_answers_num: correct_answers_num / dataLength,
          incorrect_answer: incorrect_answer / dataLength,
          inexperienced: inexperienced / dataLength,
          probs_count: probs_count / dataLength,
          accuracy_rate: accuracy_rate / dataLength,
          progress_rate: responses_num / dataLength,
          wrong_rate: wrong_rate / dataLength,
          inexperienced_rate: inexperienced_rate / dataLength,
          question_time_limit: question_time_limit / dataLength,
          total_answer_time: total_answer_time / dataLength,
          average_answer_time_per_question: average_answer_time_per_question / dataLength,
          setting_score: setting_score / dataLength,
          acquisition_score: acquisition_score / dataLength,
          acquisition_score_rate: acquisition_score / setting_score / dataLength,
          responses_num: responses_num / dataLength,
          correct_answers_score: correct_answers_score / dataLength,
        },
        ...reportSkillCheckUserTrans,
      ];
      state.totalReportSkillCheckUserTrans = dataLength;
    });

    builder.addCase(getReportSkillCheckUserTransByLoginID.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            probs_count: prev.probs_count + (next.probs_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            acquisition_score: prev.acquisition_score || 0,
          }),
          {
            correct_answers_num: 0,
            probs_count: 0,
            responses_num: 0,
            acquisition_score: action.payload.report_results[0]?.acquisition_score!,
          }
        );
      state.reportSkillCheckUserTransByLoginID = {
        correct_answers_num,
        accuracy_rate: correct_answers_num / probs_count || 0,
        wrong_rate: (responses_num - correct_answers_num) / probs_count || 0,
        inexperienced_rate: (probs_count - responses_num) / probs_count || 0,
        acquisition_score,
      };
    });

    builder.addCase(getReportSkillCheckUserTransBySkillCheckCode.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } = unionBy(
        action.payload.report_results,
        'login_id'
      ).reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
          probs_count: prev.probs_count + (next.probs_count || 0),
          responses_num: prev.responses_num + (next.responses_num || 0),
          acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
        }),
        {
          correct_answers_num: 0,
          probs_count: 0,
          responses_num: 0,
          acquisition_score: 0,
        }
      );
      const length = unionBy(action.payload.report_results, 'login_id').length;
      state.reportSkillCheckUserTransBySkillCheckCode = {
        correct_answers_num: correct_answers_num / length,
        accuracy_rate: correct_answers_num / probs_count,
        wrong_rate: (responses_num - correct_answers_num) / probs_count,
        inexperienced_rate: (probs_count - responses_num) / probs_count,
        acquisition_score: acquisition_score / length,
      };
    });

    builder.addCase(getDetailOfSkillCheckResults.fulfilled, (state, action) => {
      const accuracy_rate =
        action.payload.report_results.filter((result) => result.correct === 1).length /
        action.payload.report_results.length;
      const average_answer_time =
        action.payload.report_results.reduce(
          (previousValue, currentValue) => previousValue + (Number(currentValue.answer_time) || 0),
          0
        ) / action.payload.report_results.length;

      state.detailOfSkillCheckResults = action.payload.report_results.map((item) => ({
        ...item,
        question_id: item.item_ref?.name.i_id,
        accuracy_rate: accuracy_rate || 0,
        average_answer_time: average_answer_time || 0,
        acquisition_score: item.correct === 1 ? item.score : 0,
      }));
    });
  },
});

export const { resetInitialState, sortDataReportSkillCheckUserTrans } =
  skillCheckUserReportSlice.actions;

export default skillCheckUserReportSlice.reducer;
