import React, { useEffect, useState } from 'react';
import { Form, ResetButton, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Popover, Select } from 'antd';
import {
  DoubleLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { menuRightUserSelector } from 'containers/MenuRightUser/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { curriculumSelector } from 'pages/Settings/Curriculum/selectors';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { getDataUserSetting } from 'pages/Settings/Curriculum/thunk';
import { authSelector } from 'containers/Auth/selectors';
import QuestionPopup from '../Search/QuestionPopup';
import { Input, SelectField } from 'components';
import StyledSearch, { Button } from './styles';
import { routes } from 'navigations/routes';
import * as Types from 'types';
import Item from './Item';
import {
  getSelectUser,
  getPositionUser,
  getDepartmentUser,
  getDataSearchUsers,
  createUserAssignCurriculum,
} from 'containers/MenuRightUser/thunk';
import ConfirmCreateUserModal from '../ModalConfirmCreateUser';

const { Option } = Select;

interface Props {
  pageYOffset: number;
  curriculumSelected?: Types.UserTreeviewType;
  setCurriculumSelected: React.Dispatch<React.SetStateAction<Types.UserTreeviewType | undefined>>;
}

const SearchUserCurriculum: React.FC<Props> = ({
  pageYOffset,
  curriculumSelected,
  setCurriculumSelected,
}) => {
  const [usersSelected, setUsersSelected] = useState<Types.Users.ResponseType[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const { filter_conditions } = useSelector(curriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { list_users, data_user, data_departments, position_code } =
    useSelector(menuRightUserSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik<Types.SearchUserCurriculumFormik>({
    initialValues: {
      name: '',
      email: '',
      position_code: '',
      department_code: '',
      login_id: '',
    },
    onSubmit: async (values: Types.SearchUserCurriculumFormik) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        if (
          values[key as keyof typeof values] &&
          key !== 'position_code' &&
          key !== 'department_code'
        ) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: (key as keyof typeof values) !== 'login_id',
          });
        }
      });
      setUsersSelected([]);
      await dispatch(
        getDataSearchUsers({
          conditions,
          include_lookups: true,
          page: 1,
          per_page: 0,
          sort_fields: [{ id: 'createdat', order: 'asc' }],
          position_code: formik.values.position_code,
          affiliation: formik.values.department_code,
        })
      );
      dispatch(stopLoading());
    },
  });

  const handleAddAllUser = async () => {
    if (!curriculumSelected) return;
    dispatch(startLoading());
    await Promise.all(
      list_users.map((value) => {
        if (
          !curriculumSelected.children?.some(
            (c) =>
              c.department_code === value.lookup_items?.department_code?.code &&
              c.children?.some((user) => user.user_id === value.i_id)
          )
        ) {
          return dispatch(
            createUserAssignCurriculum({
              item: {
                company_id: value.company_id!,
                login_id: value.login_id,
                curricullum_code: curriculumSelected.curricullum_code,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
              realtime_auto_link: true,
            })
          );
        }
      })
    );
    setUsersSelected([]);
    setCurriculumSelected(undefined);
    await dispatch(
      getDataUserSetting({
        include_lookups: true,
        include_item_ref: true,
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'required_curriculum',
            search_value: ['1'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const handleAddUser = async () => {
    if (!curriculumSelected) return;
    dispatch(startLoading());
    await Promise.all(
      usersSelected.map((value) => {
        if (
          !curriculumSelected.children?.some(
            (c) =>
              c.department_code === value.lookup_items?.department_code?.code &&
              c.children?.some((user) => user.user_id === value.i_id)
          )
        ) {
          return dispatch(
            createUserAssignCurriculum({
              item: {
                company_id: value.company_id!,
                login_id: value.login_id,
                curricullum_code: curriculumSelected.curricullum_code,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
              realtime_auto_link: true,
            })
          );
        } else {
          return undefined;
        }
      })
    );
    setUsersSelected([]);
    setCurriculumSelected(undefined);
    await dispatch(
      getDataUserSetting({
        include_lookups: true,
        include_item_ref: true,
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'required_curriculum',
            search_value: ['1'],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdAt', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getPositionUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDepartmentUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSearchUsers({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'createdat', order: 'asc' }],
            include_lookups: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, isUserInfoChanged, userInfo]);

  return (
    <StyledSearch pageYOffset={pageYOffset}>
      <FormikProvider value={formik}>
        <Form
          labelCol={{
            flex: '27%',
          }}
          colon={false}
        >
          <div className="card-tab-pane">
            <p className="title-bold">ユーザー検索</p>
            <p className="text-content">条件を絞ってユーザーを検索できます。</p>
            <Form.Item
              name="name"
              label={<span className="label">氏名</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="name">
                {data_user?.map((value, index) => (
                  <Option value={value.name} key={index}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="email"
              label={<span className="label label-long">メールアドレス</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="email">
                {data_user?.map((value, index) => (
                  <Option value={value.email} key={index}>
                    {value.email}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="position_code"
              label={<span className="label">役職</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="position_code">
                {position_code?.map((value, index) => (
                  <Option key={index} value={value.i_id}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="department_code"
              label={<span className="label">部署</span>}
              className="form-input"
            >
              <SelectField allowClear className="select-input" name="department_code">
                {data_departments?.map((value, index) => (
                  <Option key={index} value={value.i_id}>
                    {value.name}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="login_id"
              label={<span className="label">フリーワード</span>}
              className="form-input"
            >
              <Input className="select-input" name="login_id" />
            </Form.Item>
          </div>
          <div className="wrap-center">
            <SubmitButton className="btn-search" loading={false}>
              <SearchOutlined className="icon-search" />
              検索
            </SubmitButton>
            <ResetButton className="label-reset">リセット</ResetButton>
          </div>
        </Form>
      </FormikProvider>
      <div className="result-search">
        <div className="left-side">
          <Button
            active={!!curriculumSelected && !!list_users.length}
            disabled={!list_users?.length || !curriculumSelected}
            onClick={handleAddAllUser}
          >
            <DoubleLeftOutlined className="label-icon" />
          </Button>
          <Button
            active={!!usersSelected?.length && !!curriculumSelected}
            disabled={!usersSelected?.length || !curriculumSelected}
            onClick={handleAddUser}
          >
            <LeftOutlined className="label-icon" />
          </Button>
          <Popover
            overlayClassName="tooltip-QA"
            content={QuestionPopup}
            overlayStyle={{
              width: 293,
            }}
            trigger="click"
            placement="bottom"
          >
            <QuestionCircleOutlined className="imageQA" />
          </Popover>
        </div>
        <div className="right-side">
          <div className="result">
            {list_users?.length ? (
              list_users.map((item, index) => (
                <Item
                  key={index}
                  item={item}
                  handleAddUser={handleAddUser}
                  usersSelected={usersSelected}
                  setUsersSelected={setUsersSelected}
                  curriculumSelected={curriculumSelected}
                />
              ))
            ) : (
              <p className="text-result">検索してください</p>
            )}
          </div>
          <div className="footer">
            <button
              className={permissionNumber === 1 ? 'disabled' : 'btn'}
              disabled={permissionNumber === 1}
              onClick={() => setOpen(true)}
            >
              <PlusOutlined className="icon" />
              ユーザー新規作成
            </button>
          </div>
        </div>
      </div>
      <ConfirmCreateUserModal
        isOpen={open}
        setIsOpen={setOpen}
        onSubmit={() => navigate(routes.CreateEmployee.path)}
      />
    </StyledSearch>
  );
};

export default SearchUserCurriculum;
