import React from 'react';

import { useSelector } from 'react-redux';

import { paymentMasterSelector } from '../../selectors';
import { formatNumberComma } from 'libs/utils/format';
import { InvoicePreviewStyled } from './styles';
import { LogoNav, LogoRStandard } from 'assets';

interface Props {
  visibleInvoicePreview: boolean;
  setVisibleInvoicePreview: React.Dispatch<React.SetStateAction<boolean>>;
}

const dataTableMock = [
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
  {
    content: 'ユーザー利用料',
    unitPrice: '600',
    quantity: '110',
    total: '66,000',
  },
  {
    content: 'スキルチェック利用料',
    unitPrice: '200',
    quantity: '300',
    total: '60,000',
  },
];

const InvoicePreview: React.FC<Props> = ({ setVisibleInvoicePreview, visibleInvoicePreview }) => {
  const { PaymentMaster } = useSelector(paymentMasterSelector);

  const handleTotalProduct = () => {
    const newArrayTotalProduct = dataTableMock.map((item) => +item.unitPrice * +item.quantity);
    return newArrayTotalProduct.reduce((a, b) => a + b, 0);
  };

  const handleTotalProductFinal = () => {
    return handleTotalProduct() + handleTotalProduct() * 0.1;
  };

  return (
    <InvoicePreviewStyled
      visible={visibleInvoicePreview}
      width={720}
      cancelButton={{
        text: '閉じる',
        onClick: () => {
          setVisibleInvoicePreview(!visibleInvoicePreview);
        },
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <div className="content">
        <div className="content-header">
          <div className="content-header-logo">
            <img src={LogoNav} alt="logo-rs-tandard" />
            <div className="content-header-text">
              <span>請求書No.&nbsp;&nbsp;&nbsp;&nbsp;123456789</span>
              <span>請求日&nbsp;&nbsp;&nbsp;&nbsp;2022年9月30日</span>
            </div>
          </div>
          <span className="title-modal">請求書</span>
          <div className="wrap-title-header">
            <span className="name-company">{PaymentMaster?.company_name}</span>
            <div className="wrap-content-standard">
              <span className="title-standard">アールスタンダード</span>
              <span className="sub-title-standard">株式会社RSTANDARD</span>
            </div>
          </div>
          <div className="info-content">
            <div className="info-content-left">
              <span>〒 {PaymentMaster?.post_code}</span>
              <span>
                {PaymentMaster?.prefecture} - {PaymentMaster?.address} -{PaymentMaster?.plot_number}
                - {PaymentMaster?.building_name}
              </span>
              <span>TEL：{PaymentMaster?.tel}</span>
              <span>FAX：{PaymentMaster?.fax}</span>
              <span>登録番号：{PaymentMaster?.business_registration_number}</span>
            </div>
            <div className="info-content-right">
              <span>〒 123-1234</span>
              <span>東京都台東区上野3-17-7</span>
              <span>G-SQUARE上野4F</span>
              <span>TEL：03-6803-2893</span>
              <span>FAX：03-6803-2894</span>
              <span>登録番号：T1234567890123</span>
              <img src={LogoRStandard} alt="logo-rs-tandard" />
            </div>
          </div>
          <div className="total-header">
            <span className="total-header-title">ご請求金額（税込）</span>
            <span className="total-number">
              {formatNumberComma(handleTotalProductFinal())}
              <span className="currency-unit">円</span>
            </span>
          </div>
        </div>
        <div className="content-body">
          <table>
            <tbody>
              <tr>
                <th>内容</th>
                <th>単価</th>
                <th>数量</th>
                <th>合計</th>
              </tr>
              {dataTableMock.map((item, index) => (
                <tr key={index}>
                  <td>{item.content}</td>
                  <td>{item.unitPrice}</td>
                  <td>{item.quantity}</td>
                  <td>{item.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="sub-table">
            <div className="wrap-sub-item">
              <div className="sub-item">
                <span className="sub-title">小計</span>
                <span className="sub-price">{formatNumberComma(handleTotalProduct())}</span>
              </div>
              <div className="sub-item">
                <span className="sub-title">消費税等（10%）</span>
                <span className="sub-price">{formatNumberComma(handleTotalProduct() * 0.1)}</span>
              </div>
              <div className="sub-item">
                <span className="sub-title">合計</span>
                <span className="sub-price">{formatNumberComma(handleTotalProductFinal())}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="content-footer">
          <div>
            <span>【お振込先】</span>
            <div>
              <span>みずほ銀行（0001）上野支店（107）</span>
              <span>（普通）2689387</span>
              <span>株式会社RSTANDARD</span>
            </div>
            <span>※お振込手数料は御社負担にてお願いいたします。</span>
          </div>
        </div>
      </div>
    </InvoicePreviewStyled>
  );
};

export default InvoicePreview;
