import { filter, omit } from 'lodash';

import * as Types from 'types';

const walkDescendants = ({
  callback,
  isPseudoRoot = false,
  node,
  parentNode = null,
  currentIndex,
  path = [],
  currentLineIndex,
  columnIndex,
}: Types.WalkDescendantsType<Types.AffiliationItemType>): {
  childIndex: number | false;
  childLineIndex: number | false;
} => {
  const selfPath = isPseudoRoot ? [] : [...path, Number(currentIndex)];

  if (!isPseudoRoot) {
    const flattened: number[] = [];
    walkTotalUserCalculator({
      node,
      callback: (total: number) => {
        flattened.push(total);
      },
    });
    const selfInfo = isPseudoRoot
      ? null
      : {
          node: { ...node, total_user: flattened.reduce((a, b) => a + b, 0) },
          parentNode,
          path: selfPath,
          lineIndex: currentLineIndex,
          columnIndex,
        };
    const callbackResult = callback(selfInfo);

    if (callbackResult === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  if (!node.children) {
    return { childIndex: currentIndex, childLineIndex: currentLineIndex };
  }

  let childIndex = currentIndex;
  let childLineIndex = currentLineIndex;

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    const result = walkDescendants({
      callback,
      node: node.children[i],
      parentNode: isPseudoRoot
        ? null
        : ({
            ...node,
            lineIndex: currentLineIndex,
          } as Types.TreeItem<Types.AffiliationItemType>),
      currentIndex: +childIndex + 1,
      path: selfPath,
      columnIndex: isPseudoRoot ? 0 : columnIndex + 1,
      currentLineIndex:
        selfPath[selfPath.length - 1] !== +childIndex || i > 0 || isPseudoRoot
          ? +childLineIndex + 1
          : childLineIndex,
    });

    childIndex = result.childIndex;
    childLineIndex = result.childLineIndex;

    if (childIndex === false || childLineIndex === false) {
      return { childIndex: false, childLineIndex: false };
    }
  }

  return { childIndex, childLineIndex };
};

export const walk = ({ treeData, callback }: Types.WalkType<Types.AffiliationItemType>) => {
  if (!treeData) {
    return;
  }

  walkDescendants({
    callback,
    isPseudoRoot: true,
    node: { children: [treeData] },
    currentIndex: -1,
    currentLineIndex: 0,
    path: [],
    columnIndex: 0,
  });
};

export const getFlatDataFromTree = ({
  treeData,
}: Types.FlatDataFromTreeType<Types.AffiliationItemType>): Array<
  Types.FlatDataItem<Types.AffiliationItemType>
> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatDataItem<Types.AffiliationItemType>> = [];
  walk({
    treeData,
    callback: (nodeInfo: Types.FlatDataItem<Types.AffiliationItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

const convertData = ({
  node,
  item,
  subPosition,
}: {
  node: Types.TreeItem<Types.AffiliationItemType>;
  item: Types.AffiliationHierarchy.ResponseType;
  subPosition: Array<Types.SubPositionType>;
}) => {
  if (!node.children) {
    return;
  }

  if (
    item.i_id &&
    !node.children.some((c) => c.i_id === item.i_id) &&
    item.affiliation_parent_id === node.affiliation_id
  ) {
    node.children.push({
      i_id: item.i_id,
      code: item.code,
      company_id: item.company_id,
      name: item.name,
      columnIndex: node.columnIndex! + 1,
      sort_order: item.sort_order,
      user_role_order: item.user_role_order,
      user_sort_order: item.user_sort_order,
      affiliation_id_root: node.affiliation_id_root
        ? [...node.affiliation_id_root, node.affiliation_id]
        : [node.affiliation_id],
      affiliation_id: item.affiliation_id,
      affiliation_i_id: item.item_ref?.affiliation_id.i_id,
      affiliation_parent_id: item.affiliation_parent_id,
      children: [],
      user_children: [],
    });
  } else if (
    node.user_children &&
    item.item_ref?.user_name?.i_id &&
    item.lookup_items?.user_positions?.rank_order &&
    item.affiliation_id === node.affiliation_id &&
    item.user_role_order === item.user_sort_order &&
    !node.user_children.some((user) => user.i_id === item.item_ref?.user_name?.i_id)
  ) {
    node.user_children.push({
      i_id: item.item_ref?.user_name?.i_id,
      user_name: item.user_name,
      user_icon_fileID: item.user_icon_fileID,
      sub_positions: subPosition,
      user_role_order: item.user_role_order,
      user_sort_order: item.user_sort_order,
      item_ref: {
        user_role_order: item.item_ref.user_role_order,
        user_sort_order: item.item_ref.user_sort_order,
      },
      lookup_items: {
        user_positions: {
          rank_order: item.lookup_items.user_positions?.rank_order,
        },
      },
    });
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertData({
      node: node.children[i],
      item,
      subPosition,
    });
  }

  return;
};

export const convertDataFromTree = ({
  item,
  treeData,
  subPosition,
}: {
  treeData: Types.TreeItem<Types.AffiliationItemType>;
  item: Types.AffiliationHierarchy.ResponseType;
  subPosition: Array<Types.SubPositionType>;
}) => {
  if (!treeData) {
    return {};
  }

  convertData({
    item,
    subPosition,
    node: { children: [treeData] },
  });

  return treeData;
};

export const checkExistItem = ({
  treeData,
  item,
}: {
  treeData: Types.TreeItem<Types.AffiliationItemType>;
  item: Types.AffiliationHierarchy.ResponseType;
}) => {
  if (!treeData) {
    return false;
  }

  let flattened = false;
  walkCheckExist({
    node: { children: [treeData] },
    item,
    callback: (nodeInfo: boolean) => {
      flattened = nodeInfo;
    },
  });

  return flattened;
};

const walkCheckExist = ({ node, item, callback }: Types.CheckExistType) => {
  if (!node.children) {
    return;
  }

  if (node.affiliation_id && node.affiliation_id === item.affiliation_parent_id) {
    callback(true);
    return;
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    walkCheckExist({
      callback,
      node: node.children[i],
      item,
    });
  }
};

const walkTotalUserCalculator = ({ node, callback }: Types.GetTotalUserType) => {
  if (!node.children) {
    return;
  }

  if (node.user_children?.length) {
    callback(node.user_children?.length);
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    walkTotalUserCalculator({
      callback,
      node: node.children[i],
    });
  }
};

const childrenItemID = ({
  node,
  callback,
}: {
  node: Types.TreeItem<Types.AffiliationItemType>;
  callback: Function;
}) => {
  if (node.i_id) {
    callback(omit(node, ['children']));
  }

  if (!node.children || node.children.length <= 0) {
    return;
  }

  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    childrenItemID({
      node: node.children[i],
      callback,
    });
  }

  return;
};

export const getChildrenItemID = ({
  treeData,
  callback,
}: {
  treeData: Types.TreeItem<Types.AffiliationItemType>;
  callback: Function;
}) => {
  if (!treeData) {
    return;
  }

  childrenItemID({
    callback,
    node: { children: [treeData] },
  });
};

export const getChildrenItemIDFromTree = ({
  treeData,
}: {
  treeData: Types.TreeItem<Types.AffiliationItemType>;
}): Array<Types.FlatChildrenItemID<Types.AffiliationItemType>> => {
  if (!treeData) {
    return [];
  }

  const flattened: Array<Types.FlatChildrenItemID<Types.AffiliationItemType>> = [];
  getChildrenItemID({
    treeData,
    callback: (nodeInfo: Types.FlatChildrenItemID<Types.AffiliationItemType>) => {
      flattened.push(nodeInfo);
    },
  });

  return flattened;
};

export const findAffiliations = (data: Types.AffiliationLevel.ResponseType[], parentId: string) => {
  const foundAffiliations = filter(data, { affiliation_parent_id: parentId });
  let result: Types.AffiliationLevel.ResponseType[] = [];

  foundAffiliations.forEach((affiliation) => {
    const childAffiliations = findAffiliations(data, affiliation.affiliation_id);
    result = [...result, affiliation, ...childAffiliations];
  });

  return result;
};
