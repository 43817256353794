import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Button, Col, Table } from 'antd';
import { Row } from 'antd/es';
import dayjs from 'dayjs';
import {
  ExclamationCircleOutlined,
  CloudDownloadOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';

import UsageStatusDetailPDF from 'pages/UsageStatus/PDF/UsageStatusDetailPDF';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { usageStatusSelector } from 'pages/UsageStatus/selectors';
import { authSelector } from 'containers/Auth/selectors';
import PopupConfirmExport from '../ConfirmExport';
import exportPDF from 'libs/utils/exportPdf';
import { UsageDetailStyled } from './styles';
import { PopupReceiptExport } from '..';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import {
  getDataSkillCheckFeeDetail,
  getDataUserFeeDetail,
  getDataPaymentStatus,
} from 'pages/UsageStatus/thunk';
import InvoicePreview from '../InvoicePreview';

interface Props {
  visible: boolean;
  dataSelected?: Types.UsageList.DataConverted;
  onSubmit?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisiblePopupCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
}

const UsageDetail: React.FC<Props> = ({
  visible,
  onSubmit,
  setVisible,
  dataSelected,
  setVisiblePopupCreditCard,
}) => {
  const [visiblePopupReceiptExport, setVisiblePopupReceiptExport] = useState<boolean>(false);
  const [visiblePopupConfirmExport, setVisiblePopupConfirmExport] = useState<boolean>(false);
  const [visibleInvoicePreview, setVisibleInvoicePreview] = useState<boolean>(false);

  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const { dataUserFeeDetail, dataSkillCheckFeeDetail, dataPaymentStatus } =
    useSelector(usageStatusSelector);

  const columnsUserFee: ColumnsType<Types.UserFeeDetail.DataConverted> = [
    {
      title: '月末利用ユーザー数',
      className: 'item-center',
      dataIndex: 'number_end_month',
      key: 'number_end_month',
      width: '25%',
      render: (number_end_month: number) => (
        <div className="item-table">
          <p className="label-number">{number_end_month.toLocaleString()}</p>
          <p className="label">人</p>
        </div>
      ),
    },
    {
      title: '月間最大利用ユーザー数',
      className: 'item-center',
      dataIndex: 'number_user_month_max',
      key: 'number_user_month_max',
      width: '25%',
      render: (number_user_month_max: number) => (
        <div className="item-table">
          <p className="label-number">{number_user_month_max.toLocaleString()}</p>
          <p className="label">人</p>
        </div>
      ),
    },
    {
      title: '単価',
      dataIndex: 'unit_price',
      key: 'unit_price',
      width: '25%',
      render: (unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{unit_price?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: 'ユーザー利用料',
      dataIndex: 'user_fee',
      key: 'user_fee',
      width: '25%',
      render: (user_fee: number) => (
        <div className="item-table">
          <p className="label-number">{user_fee.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsStorageUserFee: ColumnsType<Types.UsageList.DataConverted> = [
    {
      title: '利用ストレージ',
      className: 'item-center',
      dataIndex: 'usage_storage',
      key: 'usage_storage',
      width: '33%',
      render: (usage_storage: number) => (
        <div className="item-table">
          <p className="label-number">{usage_storage?.toLocaleString()}</p>
          <p className="label">GB</p>
        </div>
      ),
    },
    {
      title: '単価',
      className: 'item-center',
      dataIndex: 'usage_storage_unit_price',
      key: 'usage_storage_unit_price',
      width: '33%',
      render: (usage_storage_unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{usage_storage_unit_price?.toLocaleString()}</p>
          <p className="label">円 /50GB</p>
        </div>
      ),
    },
    {
      title: 'ストレージ利用料',
      dataIndex: 'user_fee_storage',
      key: 'user_fee_storage',
      width: '33%',
      render: (user_fee_storage: number) => (
        <div className="item-table">
          <p className="label-number">{user_fee_storage?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsSkillCheckUserFee: ColumnsType<Types.SkillCheckFeeDetails.ResponseType> = [
    {
      title: 'スキルチェック名称',
      className: 'item-start',
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      width: '48%',
      render: (skill_check_name: string) => (
        <div className="item-table">
          <p className="label-number">{skill_check_name}</p>
        </div>
      ),
    },
    {
      title: '実施回数',
      className: 'item-center',
      dataIndex: 'count_skill_check',
      key: 'count_skill_check',
      width: '12%',
      render: (count_skill_check: number) => (
        <div className="item-table">
          <p className="label-number">{count_skill_check}</p>
          <p className="label">回</p>
        </div>
      ),
    },
    {
      title: '単価',
      dataIndex: 'skill_check_unit_price',
      key: 'skill_check_unit_price',
      width: '15%',
      render: (skill_check_unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{skill_check_unit_price}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: 'スキルチェック利用料',
      dataIndex: 'skill_check_user_fee',
      key: 'skill_check_user_fee',
      width: '25%',
      render: (skill_check_user_fee: number) => (
        <div className="item-table">
          <p className="label-number">{skill_check_user_fee}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsPaymentStatus: ColumnsType<Types.PaymentStatus.ResponseType> = [
    {
      title: '支払日',
      className: 'item-center',
      dataIndex: 'paid_date',
      key: 'paid_date',
      width: '33%',
      render: (paid_date: string) => (
        <div className="item-table">
          <p className="label-number">{dayjs(paid_date).format('YYYY年MM月')}</p>
        </div>
      ),
    },
    {
      title: 'カード番号',
      className: 'item-center',
      dataIndex: 'card_number_l4g',
      key: 'card_number_l4g',
      width: '33%',
      render: (card_number_l4g: string) => (
        <div className="item-table">
          <p className="label-number">{card_number_l4g?.replace(/\d(?=\d{4})/g, '*')}</p>
        </div>
      ),
    },
    {
      title: '金額（税込）',
      className: 'item-center',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      width: '33%',
      render: (paid_amount: number) => (
        <div className="item-table">
          <p className="label-number">{paid_amount?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const total = useMemo(
    () =>
      dataSkillCheckFeeDetail
        .map((item) => Number(item.skill_check_user_fee))
        .reduce((previousValue, currentValue) => previousValue + currentValue, 0),
    [dataSkillCheckFeeDetail]
  );

  const timeCheck = useMemo(
    () => dayjs().isSame(dayjs(dataSelected?.contract_date).format('YYYYMM'), 'month'),
    [dataSelected]
  );

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <UsageStatusDetailPDF
          dataPaymentStatus={dataPaymentStatus}
          dataSelected={dataSelected}
          dataUserFeeDetail={dataUserFeeDetail}
          timeCheck={timeCheck}
          total={total}
          dataSkillCheckFeeDetail={dataSkillCheckFeeDetail}
        />
      </div>
    );
  }, [
    dataPaymentStatus,
    dataSelected,
    dataUserFeeDetail,
    timeCheck,
    total,
    dataSkillCheckFeeDetail,
  ]);

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
    setVisiblePopupCreditCard(true);
  };

  const handleExportPDF = () => {
    if (!ref.current) return;

    exportPDF(ref, 'ご利用状況詳細', 'p');
    setVisiblePopupConfirmExport(false);
  };

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataUserFeeDetail({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'target_month',
                search_value: [dataSelected?.contract_date],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSkillCheckFeeDetail({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataPaymentStatus({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'target_month',
                search_value: [dataSelected?.contract_date],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataSelected, dispatch, userInfo, visible]);

  return (
    <Modal
      title="ご利用状況詳細"
      width={860}
      visible={visible}
      okButton={
        dataPaymentStatus.length === 0
          ? {
              text: 'クレジットカード再登録',
              onClick: handleSubmitModal,
            }
          : undefined
      }
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        overflowY: 'scroll',
        padding: 0,
        height: 700,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <UsageDetailStyled>
        {component}
        <div className="wrap-header">
          <div className="time-label">
            <p className="label">サービス利用年月：</p>
            <p className="time-text">{dayjs(dataSelected?.contract_date).format('YYYY年MM月')}</p>

            {timeCheck ? (
              <div className="item-check">
                <ExclamationCircleOutlined className="icon" />
                計算期間中
              </div>
            ) : (
              <div className="item-check-time">
                <CheckCircleOutlined className="icon" />
                確定
              </div>
            )}
          </div>
          <div className="btn-header">
            <Button className="btn btn-active" onClick={() => setVisiblePopupConfirmExport(true)}>
              <CloudDownloadOutlined className="size-icon" />
              エクスポート
            </Button>
            {timeCheck ? null : (
              <Button className="btn btn-default" onClick={() => setVisibleInvoicePreview(true)}>
                領収書
              </Button>
            )}
          </div>
        </div>
        {timeCheck ? (
          <p className="content">
            ※計算期間中のご利用状況詳細は展開時の情報を表示します。確定データではありませんので、ご注意ください。
          </p>
        ) : null}
        <div className="user-fee-detail">
          <div className="title">
            <div className="circle" />
            <span className="label">ユーザー利用料詳細</span>
          </div>
          <Table
            className="table"
            dataSource={dataUserFeeDetail.map((item, index) => ({ ...item, index }))}
            columns={columnsUserFee}
            pagination={false}
            rowKey="index"
          />
        </div>
        <div className="storage-user-fee-detail">
          <div className="title">
            <div className="circle" />
            <span className="label">ストレージ利用料詳細</span>
          </div>
          <Table
            className="table"
            dataSource={dataSelected ? [dataSelected] : []}
            columns={columnsStorageUserFee}
            pagination={false}
            rowKey="index"
          />
        </div>
        <div className="skillcheck-fee-detail">
          <div className="title">
            <div className="circle" />
            <span className="label">スキルチェック利用料詳細</span>
          </div>
          <Table
            className="table"
            dataSource={dataSkillCheckFeeDetail.map((item, index) => ({ ...item, index }))}
            columns={columnsSkillCheckUserFee}
            pagination={false}
            rowKey="index"
            footer={() => (
              <>
                <div className="table-footer">
                  <div className="label">合計</div>
                  <div className="total">
                    <div className="item-total">
                      <p className="number">{total.toLocaleString()}</p>
                      <p className="text">円</p>
                    </div>
                  </div>
                </div>
              </>
            )}
          />
        </div>
        <div className="bill-total">
          <div className="title">
            <span className="label">
              合計利用料（お支払い方法：{dataUserFeeDetail[0]?.payment_method_name}）
            </span>
          </div>
          <Row className="row-label">
            <Col className="gutter-row" span={8}>
              ユーザー利用料
            </Col>
            <Col className="gutter-row center" span={8}>
              スキルチェック利用料
            </Col>
            <Col className="gutter-row" span={8}>
              合計利用料
            </Col>
          </Row>
          <Row className="row-number">
            <Col className="gutter-row" span={8}>
              <div className="item-total">
                <p className="number">{dataUserFeeDetail[0]?.user_fee.toLocaleString()}</p>
                <p className="text">円</p>
              </div>
            </Col>
            <Col className="gutter-row center" span={8}>
              <div className="item-total">
                <p className="number">{total.toLocaleString()}</p>
                <p className="text">円</p>
              </div>
            </Col>
            <Col className="gutter-row end" span={8}>
              <div className="item-total">
                <p className="number">
                  {(dataUserFeeDetail[0]?.user_fee + total).toLocaleString()}
                </p>
                <p className="text">円</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="bill-total">
          <div className="title">
            <span className="label">請求合計</span>
          </div>
          {dataPaymentStatus.length > 0 ? (
            <Table
              className="table"
              dataSource={dataPaymentStatus.map((item, index) => ({ ...item, index }))}
              columns={columnsPaymentStatus}
              pagination={false}
              rowKey="index"
            />
          ) : (
            <>
              <Row className="row-label">
                <Col className="gutter-row" span={8}>
                  支払日
                </Col>
                <Col className="gutter-row center" span={8}>
                  カード番号
                </Col>
                <Col className="gutter-row" span={8}>
                  金額（税込）
                </Col>
              </Row>
              <Row className="row-number">
                <Col className="gutter-row" span={8}>
                  <div className="item-unsettled">未決済</div>
                </Col>
                <Col className="gutter-row center" span={8}>
                  <div className="item-total">-</div>
                </Col>
                <Col className="gutter-row end" span={8}>
                  <div className="item-total">-</div>
                </Col>
              </Row>
            </>
          )}
        </div>
        <PopupConfirmExport
          visible={visiblePopupConfirmExport}
          setVisible={setVisiblePopupConfirmExport}
          onSubmit={handleExportPDF}
        />
        <PopupReceiptExport
          visible={visiblePopupReceiptExport}
          setVisible={setVisiblePopupReceiptExport}
          total={total}
          dataUserFeeDetail={dataUserFeeDetail}
        />
        <InvoicePreview
          dataSelected={dataSelected}
          visibleInvoicePreview={visibleInvoicePreview}
          setVisibleInvoicePreview={setVisibleInvoicePreview}
        />
      </UsageDetailStyled>
    </Modal>
  );
};

export default UsageDetail;
