import styled from 'styled-components';

export const SectionStyled = styled.div`
  background: #f9f8f8;
  padding: 15px 40px;
  .wrap-tree-folder {
    .label {
      font-weight: 700;
      font-size: 11px;
      color: #999999;
      margin: 0;
    }
    .tree-folder {
      display: flex;
      align-items: center;
      background: #ffffff;
      border-radius: 3px;
      padding: 12px;
      overflow-x: auto;
      .icon-folder {
        color: #287dbb;
        font-size: 18px;
      }
      .item-label {
        display: flex;
        align-items: center;
        .icon-right {
          padding: 0 10px;
          font-size: 12px;
          color: #999999;
        }
        .label-tree {
          font-size: 12px;
          color: #424242;
          margin: 0;
          white-space: nowrap;
        }
        .bold {
          font-weight: bold;
        }
      }
    }
  }
  .item-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    .select {
      .label {
        margin: 0;
        font-size: 12px;
        color: #777777;
      }
    }
    .center {
      padding: 0 10px;
    }
    .check-box {
      margin-top: 15px;
      .label-check-box {
        font-size: 12px;
        color: #000000;
      }
    }
  }
  .table {
    .ant-table .ant-table-container::before {
      position: absolute;
      content: 'ALL';
      top: 25px;
      left: 5px;
      font-size: 11px;
      color: #2a2a2a;
    }
    .ant-table-thead .ant-table-cell {
      padding: 15px 5px;
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      ::before {
        display: none;
      }
      .ant-checkbox-wrapper .ant-checkbox-inner,
      .ant-checkbox-wrapper .ant-checkbox-checked::after,
      .ant-checkbox-wrapper .ant-checkbox-input {
        top: -10px;
      }
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 13px;
      color: #2a2a2a;
    }
    .ant-table-tbody .ant-table-cell.number {
      font-family: Lato;
      font-size: 11px;
      color: #777777;
    }
    .ant-table-cell {
      padding: 5px;
    }

    .ant-table-row-selected td {
      background: linear-gradient(0deg, #fdf7ea, #fdf7ea), #ffffff !important;
    }
    .ant-table-row-selected td.ant-table-cell.text {
      font-weight: 700;
    }
    .text-count {
      font-size: 14px;
      color: #424242;
      .text-static {
        font-size: 11px;
      }
    }
  }
`;
