import React, { useCallback, useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Col, Row, Tabs, Select } from 'antd';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { HomeCardColour } from 'constant/enum.constant';
import { useAppDispatch, usePermission } from 'hooks';
import { dashboardSelector } from './selectors';
import HomeDetailCard from './HomeDetailCard';
import { routes } from 'navigations/routes';
import RightSideBar from './RightSideBar';
import { UserType } from 'types/config';
import DashboardStyled from './styles';
import { Header } from 'components';
import {
  getCurriculumGraphGreen,
  getCurriculumGraphBlue,
  getDataSkillCheckGraph,
  getMainAffiliationList,
  getOptionAffiliations,
  getSelectCurriculum,
  getSelectSkillCheck,
} from './thunk';

const { Option } = Select;
const { TabPane } = Tabs;

export interface filterDataType {
  [key: number]: string;
}

const Dashboard: React.FC = () => {
  const [affiliationSelected, setAffiliationSelected] = useState('');
  const [filterDataPurple, setFilterDataPurple] = useState<string>();
  const [filterDataGreen, setFilterDataGreen] = useState<string>();
  const [activeTab, setActiveTab] = useState<UserType>('member');
  const [activeTabValue, setActiveTabValue] = useState<UserType>(activeTab);
  const [filterData, setFilterData] = useState<filterDataType>();
  const [filterDataBlue, setFilterDataBlue] = useState<string>();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    curriculumNameSelect,
    optionAffiliation,
    skillCheckSelect,
    dataPurpleCard,
    dataGreenCard,
    dataBlueCard,
  } = useSelector(dashboardSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { permissionNumber } = usePermission();

  const handleSelectTabKey = (activeKey: string) => {
    setFilterData(undefined);
    setActiveTab(activeKey as UserType);
  };

  const fetchDataGraph = useCallback(
    ({
      filterGreen,
      filterBlue,
      filterPurple,
    }: {
      filterGreen?: string;
      filterBlue?: string;
      filterPurple?: string;
    }) => {
      if (!userInfo || (!filterGreen && !filterBlue && !filterPurple)) return;
      const conditions = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
        ...(filterPurple
          ? [{ id: 'skill_check_code', search_value: [filterPurple] }]
          : [
              {
                id: 'curriculum_code',
                search_value: [filterGreen || filterBlue],
              },
            ]),
        ...(!filterPurple
          ? [
              {
                id: 'required_curriculum',
                search_value: [filterGreen ? '0' : '1'],
                exact_match: true,
              },
            ]
          : []),

        ...(affiliationSelected
          ? [
              {
                id: 'affiliation_id',
                search_value: [affiliationSelected],
              },
            ]
          : []),

        {
          id: 'implementation_date',
          search_value: [
            dayjs().subtract(6, 'month').startOf('month').startOf('day').toISOString(),
            dayjs().endOf('month').endOf('day').toISOString(),
          ],
        },
        ...(activeTab !== 'admin'
          ? [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ]
          : []),
      ];
      const payload = { conditions, include_lookups: true, page: 1, per_page: 0 };
      (async () => {
        dispatch(startLoading());
        filterGreen
          ? await dispatch(getCurriculumGraphGreen(payload))
          : filterBlue
          ? await dispatch(getCurriculumGraphBlue(payload))
          : await dispatch(getDataSkillCheckGraph(payload));
        dispatch(stopLoading());
      })();
    },
    [activeTab, dispatch, affiliationSelected, userInfo]
  );

  const selectAffiliationHandler = (value: string) => {
    setAffiliationSelected(value);
    fetchDataGraph({
      filterGreen: filterDataGreen,
      filterBlue: undefined,
      filterPurple: undefined,
    });

    fetchDataGraph({
      filterGreen: undefined,
      filterBlue: filterDataBlue,
      filterPurple: undefined,
    });

    fetchDataGraph({
      filterGreen: undefined,
      filterBlue: undefined,
      filterPurple: filterDataPurple,
    });
  };

  useEffect(() => {
    if (!userInfo || isEqual(activeTab, activeTabValue)) return;
    (async () => {
      setActiveTabValue(activeTab);
      dispatch(startLoading());
      const resultActions = await Promise.all([
        dispatch(
          getSelectCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(activeTab !== 'admin'
                ? [
                    {
                      id: 'login_id',
                      search_value: [userInfo.login_id],
                      exact_match: true,
                    },
                  ]
                : []),
            ],
            sort_fields: [
              {
                id: 'code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(activeTab !== 'admin'
                ? [
                    {
                      id: 'login_id',
                      search_value: [userInfo.login_id],
                      exact_match: true,
                    },
                  ]
                : []),
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getMainAffiliationList({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'main',
                order: 'desc',
              },
              {
                id: 'createdat',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 1,
          })
        ),
        dispatch(
          getOptionAffiliations({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      setFilterData((prev) => {
        if (
          getSelectCurriculum.fulfilled.match(resultActions[0]) &&
          resultActions[0].payload.report_results.length > 0
        ) {
          const code = resultActions[0].payload.report_results[0].code;
          prev = {
            ...prev,
            [HomeCardColour.green]: code,
            [HomeCardColour.blue]: code,
          };
        }
        if (
          getSelectSkillCheck.fulfilled.match(resultActions[1]) &&
          resultActions[1].payload.report_results.length > 0
        ) {
          const code = resultActions[1].payload.report_results[0].code;
          prev = {
            ...prev,
            [HomeCardColour.purple]: code,
          };
        }
        return prev;
      });
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, activeTab, activeTabValue]);

  useEffect(() => {
    setActiveTab(
      userInfo?.lookup_items?.role_code?.admin_dashboard_permission ? 'admin' : 'member'
    );
  }, [userInfo]);

  useEffect(() => {
    if (!filterData) return;

    if (filterData[HomeCardColour.green] !== filterDataGreen) {
      setFilterDataGreen(filterData[HomeCardColour.green]);
      fetchDataGraph({
        filterGreen: filterData[HomeCardColour.green],
        filterBlue: undefined,
        filterPurple: undefined,
      });
    }

    if (filterData[HomeCardColour.blue] !== filterDataBlue) {
      setFilterDataBlue(filterData[HomeCardColour.blue]);
      fetchDataGraph({
        filterGreen: undefined,
        filterBlue: filterData[HomeCardColour.blue],
        filterPurple: undefined,
      });
    }

    if (filterData[HomeCardColour.purple] !== filterDataPurple) {
      setFilterDataPurple(filterData[HomeCardColour.purple]);
      fetchDataGraph({
        filterGreen: undefined,
        filterBlue: undefined,
        filterPurple: filterData[HomeCardColour.purple],
      });
    }
  }, [fetchDataGraph, filterData, filterDataBlue, filterDataGreen, filterDataPurple, activeTab]);

  useEffect(() => {
    userInfo?.user_type === 'interview' &&
      navigate(generatePath(routes.NewSkillCheckSelection.path, { entity: 'receiving' }));
  }, [navigate, userInfo]);

  return (
    <DashboardStyled>
      <Header title={headerTitle} />
      {userInfo?.lookup_items?.role_code?.admin_dashboard_permission ? (
        <div className="sub-header">
          <Tabs className="tab-container" onChange={handleSelectTabKey}>
            {permissionNumber !== 0 && <TabPane tab="管理者ダッシュボード" key="admin" />}
            <TabPane tab="My ダッシュボード" key="member" />
          </Tabs>
          {activeTab === 'admin' && (
            <div className="filter">
              <span className="label">所属で絞込：</span>
              <Select
                onSelect={selectAffiliationHandler}
                className="select"
                value={affiliationSelected}
              >
                {optionAffiliation?.map((affiliation) => (
                  <Option key={affiliation.affiliation_id} value={affiliation.affiliation_id}>
                    {affiliation.name}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </div>
      ) : null}
      <div className="container">
        <Col span={18}>
          <Row gutter={8}>
            <Col span={8}>
              <HomeDetailCard
                data={dataGreenCard}
                colour={HomeCardColour.green}
                role={activeTab}
                menuItems={curriculumNameSelect.map(({ name, code }) => ({ name, code }))}
                filterData={filterData}
                setFilterData={setFilterData}
                title="トレーニング"
                footerText="進捗率推移"
              />
            </Col>
            <Col span={8}>
              <HomeDetailCard
                data={dataBlueCard}
                colour={HomeCardColour.blue}
                role={activeTab}
                menuItems={curriculumNameSelect.map(({ name, code }) => ({ name, code }))}
                filterData={filterData}
                setFilterData={setFilterData}
                title="トレーニング_必修カリキュラム"
                footerText="進捗率推移"
              />
            </Col>
            <Col span={8}>
              <HomeDetailCard
                data={dataPurpleCard}
                colour={HomeCardColour.purple}
                role={activeTab}
                menuItems={skillCheckSelect.map(({ name, code }) => ({ name, code }))}
                filterData={filterData}
                setFilterData={setFilterData}
                title="スキルチェック"
                footerText="正解率推移"
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <RightSideBar role={activeTab} />
        </Col>
      </div>
    </DashboardStyled>
  );
};

export default Dashboard;
