import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Dictionary, find, get, groupBy } from 'lodash';
import { generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu } from 'antd';
import {
  CaretRightOutlined,
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  LinkOutlined,
  LockOutlined,
} from '@ant-design/icons';

import { getChildrenItemIDFromTree } from 'libs/utils/explorer/tree-data-utils';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { confirmDeleteModalRef } from 'pages/Manual/Modal/ConfirmDelete';
import { executeAction } from 'containers/CreateEditQuestion/thunk';
import { setActiveFolder, setIdDeleted } from 'pages/Manual/slice';
import { handleUpdateParentFolderManual } from 'libs/utils/manual';
import { ContextMenuWrapper, WrapAlertCopied } from './styles';
import { routes as RoutesType } from 'navigations/routes';
import { authSelector } from 'containers/Auth/selectors';
import { copyFileInMinio } from 'services/minioService';
import { manualSelector } from 'pages/Manual/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import { alertCustomRef } from 'components/Alert';
import { IconLocked, IconPublish } from 'assets';
import { MANUAL_FOLDER } from 'configs';
import * as Types from 'types';
import {
  deleteManual,
  getManualFolder,
  getListManualFile,
  deleteManualFile,
  deleteFolder,
  createManual,
  createManualFile,
  createNewFolder,
  deleteAttachManualFile,
  getManualFile,
  getAttachManualFile,
  createFileAttach,
  getManual,
  getManualSection,
  deleteManualSection,
  createManualSection,
  setFolderPermission,
  deleteFileAttachByConditions,
  getListManual,
} from 'pages/Manual/thunk';

interface Props {
  isMyManual?: boolean;
  routes: typeof RoutesType;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenPopupConfirmPublish: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModalFolderPermissionSetting: React.Dispatch<React.SetStateAction<boolean>>;
  setFileSelected: React.Dispatch<
    React.SetStateAction<Types.FileExplorer.DataTableType | undefined>
  >;
  contextMenu: {
    record?: Types.FileExplorer.DataTableType | undefined;
    visible: boolean;
    x?: number | undefined;
    y?: number | undefined;
  };
}

interface ContextMenuRef {
  handleCopyLink: (record?: Types.FileExplorer.DataTableType) => void;
  handleDelete: (record?: Types.FileExplorer.DataTableType) => void;
  handleCopy: (record?: Types.FileExplorer.DataTableType) => void;
}

export const contextMenuRef = React.createRef<ContextMenuRef>();

const ContextMenu = forwardRef<ContextMenuRef, Props>(
  (
    {
      routes,
      setIsEdit,
      isMyManual,
      contextMenu,
      setFileSelected,
      setOpenPopupConfirmPublish,
      setOpenModalFolderPermissionSetting,
    },
    ref
  ) => {
    const { permissionNumber } = usePermission();
    const dispatch = useAppDispatch();

    const { activeFolder, filterOptions } = useSelector(manualSelector);
    const { userInfo } = useSelector(authSelector);

    const removeManual = async (i_id: string | undefined) => {
      if (!i_id) return;
      const resultAction = await dispatch(
        getManual({
          conditions: [
            {
              id: 'i_id',
              search_value: [i_id],
            },
          ],
          page: 1,
          per_page: 1,
        })
      );
      if (getManual.fulfilled.match(resultAction)) {
        await dispatch(
          deleteManual({
            id: i_id,
          })
        );
        const resultActionGetManualSection = await dispatch(
          getManualSection({
            page: 1,
            per_page: 0,
            conditions: [
              {
                id: 'manual_id',
                search_value: [resultAction.payload.items[0].manual_id],
              },
            ],
          })
        );
        if (getManualSection.fulfilled.match(resultActionGetManualSection)) {
          await Promise.all(
            resultActionGetManualSection.payload.items.map((item) =>
              dispatch(
                deleteManualSection({
                  id: item.i_id,
                })
              )
            )
          );
        }
      }
    };

    const removeManualFile = async (i_id: string | undefined) => {
      if (!i_id) return;
      const resultAction = await dispatch(
        getManualFile({
          page: 1,
          per_page: 1,
          conditions: [
            {
              id: 'i_id',
              search_value: [i_id],
            },
          ],
        })
      );
      if (getManualFile.fulfilled.match(resultAction)) {
        const resultAction2 = await dispatch(
          getAttachManualFile({
            page: 1,
            per_page: 1,
            conditions: [
              {
                id: 'fileID',
                search_value: [resultAction.payload.items[0].fileID],
              },
            ],
          })
        );
        await Promise.all([
          dispatch(
            deleteManualFile({
              id: resultAction.payload.items[0].i_id,
            })
          ),
          dispatch(
            deleteFileAttachByConditions({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [resultAction.payload.items[0].fileID],
                },
              ],
              use_display_id: true,
            })
          ),
          getAttachManualFile.fulfilled.match(resultAction2)
            ? dispatch(
                deleteAttachManualFile({
                  id: resultAction2.payload.items[0].i_id,
                })
              )
            : {},
        ]);
      }
    };

    const handleDelete = (record?: Types.FileExplorer.DataTableType) => {
      if (!record || !userInfo) return;
      if (record.type === 'manual' || record.type === 'manual_file') {
        if (!record) return;
        confirmDeleteModalRef.current?.setVisible({
          des: '※削除すると、復元できませんのでご注意ください。',
          sub: 'マニュアルの削除を実行します。',
          t: 'マニュアル削除',
          tComplete: '削除が完了しました',
          onSubmit: async () => {
            try {
              dispatch(startLoading());
              if (record.type === 'manual') {
                await removeManual(record.i_id_file_id);
              } else {
                await removeManualFile(record.i_id_file_id);
              }
            } finally {
              const folder_i_id = get(
                find(record.item_links.links, { d_id: MANUAL_FOLDER.id }),
                'items[0].i_id',
                undefined
              );
              folder_i_id && (await handleUpdateParentFolderManual(folder_i_id, dispatch));
              await fetchData();
              dispatch(stopLoading());
            }
          },
        });
      } else {
        confirmDeleteModalRef.current?.setVisible({
          des: '※削除すると、格納されているマニュアルも削除されます。',
          sub: 'フォルダの削除を実行します。',
          t: 'フォルダ削除',
          tComplete: '削除が完了しました',
          onSubmit: async () => {
            try {
              dispatch(startLoading());
              const listFolder = getChildrenItemIDFromTree({ treeData: record });
              const listManual = await Promise.all(
                listFolder.map((folder) =>
                  dispatch(
                    getListManual({
                      conditions: [
                        {
                          id: 'company_id',
                          search_value: [userInfo.company_id],
                        },
                        {
                          id: 'folder_id',
                          search_value: [folder.folder_id],
                        },
                      ],
                      sort_fields: [{ id: 'display_order', order: 'asc' }],
                      isMyManual: isMyManual,
                      page: 1,
                      per_page: 0,
                    })
                  )
                )
              );
              const listManualFile = await Promise.all(
                listFolder.map((folder) =>
                  dispatch(
                    getListManualFile({
                      conditions: [
                        {
                          id: 'company_id',
                          search_value: [userInfo.company_id],
                        },
                        {
                          id: 'folder_id',
                          search_value: [folder.folder_id],
                        },
                      ],
                      sort_fields: [{ id: 'display_order', order: 'asc' }],
                      isMyManual: isMyManual,
                      page: 1,
                      per_page: 0,
                    })
                  )
                )
              );
              await Promise.all([
                ...listFolder.map(async (folder) => {
                  const deleteAction = await dispatch(
                    deleteFolder({
                      id: folder.i_id!,
                    })
                  );
                  if (deleteFolder.fulfilled.match(deleteAction)) {
                    dispatch(setIdDeleted(folder.i_id));
                    return deleteAction;
                  }
                }),
                ...listManual.flatMap((resultAction) =>
                  getListManual.fulfilled.match(resultAction)
                    ? resultAction.payload.items.map((manual) => removeManual(manual.i_id_file_id))
                    : []
                ),
                ...listManualFile.flatMap((resultAction) =>
                  getListManualFile.fulfilled.match(resultAction)
                    ? resultAction.payload.items.map((manual) =>
                        removeManualFile(manual.i_id_file_id)
                      )
                    : []
                ),
              ]);
            } finally {
              const folder_i_id = get(
                find(record.item_links.links, { d_id: MANUAL_FOLDER.id }),
                'items[0].i_id',
                undefined
              );
              folder_i_id && (await handleUpdateParentFolderManual(folder_i_id, dispatch));
              dispatch(setActiveFolder(undefined));
              await fetchData();
              dispatch(stopLoading());
            }
          },
        });
      }
    };

    const copyManual = async (
      i_id: string | undefined,
      newFolderID?: string,
      record?: Types.FileExplorer.DataTableType
    ) => {
      if (!userInfo || !i_id) return;
      const resultActionGetManual = await dispatch(
        getManual({
          conditions: [
            {
              id: 'i_id',
              search_value: [i_id],
            },
          ],
          page: 1,
          per_page: 1,
        })
      );
      if (getManual.fulfilled.match(resultActionGetManual)) {
        const resultAction = await dispatch(
          createManual({
            item: {
              company_id: userInfo.company_id,
              folder_id: newFolderID || resultActionGetManual.payload.items[0].folder_id,
              manual_title: `${resultActionGetManual.payload.items[0].manual_title}${
                newFolderID ? '' : ' (コピー作成）'
              }`,
              description: resultActionGetManual.payload.items[0].description,
              version: resultActionGetManual.payload.items[0].version,
              publish: resultActionGetManual.payload.items[0].publish,
              createdby: userInfo.login_id,
              createdat: new Date(),
            },
            return_display_id: true,
            return_item_result: true,
          })
        );
        if (createManual.fulfilled.match(resultAction)) {
          const folder_i_id = get(
            find(record?.item_links.links, { d_id: MANUAL_FOLDER.id }),
            'items[0].i_id',
            undefined
          );

          folder_i_id && handleUpdateParentFolderManual(folder_i_id, dispatch);
          const resultActionGetManualSection = await dispatch(
            getManualSection({
              page: 1,
              per_page: 0,
              conditions: [
                {
                  id: 'manual_id',
                  search_value: [resultActionGetManual.payload.items[0].manual_id],
                },
              ],
            })
          );
          if (getManualSection.fulfilled.match(resultActionGetManualSection)) {
            await Promise.all(
              resultActionGetManualSection.payload.items.map((item) =>
                dispatch(
                  createManualSection({
                    item: {
                      company_id: userInfo.company_id,
                      manual_id: resultAction.payload.item?.manual_id,
                      section_name: item.section_name,
                      text: item.text,
                      display_order: item.display_order,
                      createdby: userInfo.login_id,
                      createdat: new Date(),
                    },
                  })
                )
              )
            );
          }
        }
      }
    };

    const copyManualFile = async (
      i_id: string | undefined,
      newFolderID?: string,
      record?: Types.FileExplorer.DataTableType
    ) => {
      if (!userInfo || !i_id) return;
      const resultAction = await dispatch(
        getManualFile({
          page: 1,
          per_page: 1,
          conditions: [
            {
              id: 'i_id',
              search_value: [i_id],
            },
          ],
        })
      );

      if (getManualFile.fulfilled.match(resultAction)) {
        const fileAttach = await dispatch(
          getAttachManualFile({
            page: 1,
            per_page: 1,
            conditions: [
              {
                id: 'fileID',
                search_value: [resultAction.payload.items[0].fileID],
              },
            ],
          })
        );
        const copyFileMinio = await copyFileInMinio(resultAction.payload.items[0].fileID);
        if (copyFileMinio && getAttachManualFile.fulfilled.match(fileAttach)) {
          const resultActionCreateImages = await dispatch(
            createFileAttach({
              item: {
                fileID: copyFileMinio,
                filename: `${fileAttach.payload.items[0].filename}-copy`,
                file_location: '4',
                file_extension: fileAttach.payload.items[0].file_extension,
                file_size: fileAttach.payload.items[0].file_size,
                company_id: userInfo?.company_id,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          );
          if (createFileAttach.fulfilled.match(resultActionCreateImages)) {
            await Promise.all([
              dispatch(
                createManualFile({
                  item: {
                    company_id: resultAction.payload.items[0].company_id,
                    folder_id: newFolderID || resultAction.payload.items[0].folder_id,
                    fileID: copyFileMinio,
                    publish: resultAction.payload.items[0].publish,
                    attachment_title: `${resultAction.payload.items[0].attachment_title}${
                      newFolderID ? '' : ' (コピー作成）'
                    }`,
                    attachment_version: resultAction.payload.items[0].attachment_version,
                    attachment_description: resultAction.payload.items[0].attachment_description,
                    extension: resultAction.payload.items[0].extension,
                    createdby: userInfo.login_id,
                    createdat: new Date(),
                  },
                })
              ),
              dispatch(
                executeAction({
                  itemId: resultActionCreateImages.payload.item_id,
                  data: {
                    changes: [
                      {
                        id: 'file',
                        value: [copyFileMinio],
                      },
                    ],
                    use_display_id: true,
                    is_force_update: true,
                  },
                })
              ),
            ]);
            const folder_i_id = get(
              find(record?.item_links.links, { d_id: MANUAL_FOLDER.id }),
              'items[0].i_id',
              undefined
            );

            folder_i_id && handleUpdateParentFolderManual(folder_i_id, dispatch);
          }
        }
      }
    };

    const handleCopy = async (record?: Types.FileExplorer.DataTableType) => {
      if (!record || !userInfo) return;
      try {
        dispatch(startLoading());
        if (record?.type === 'manual') {
          await copyManual(record.i_id_file_id, undefined, record);
        } else if (record?.type === 'manual_file') {
          await copyManualFile(record.i_id_file_id, undefined, record);
        } else {
          const listFolder = getChildrenItemIDFromTree({ treeData: record });
          const newListFolder: Dictionary<Types.FileExplorer.FileExplorerType[] | string> = groupBy(
            listFolder,
            'parent_folder_id'
          );
          const listParentFolderID: { [key: string]: string } = {};
          for (let index = 0; index < Object.keys(newListFolder).length; index++) {
            const parent_folder_id = Object.keys(newListFolder)[index];
            await Promise.all(
              (newListFolder[parent_folder_id] as Types.FileExplorer.FileExplorerType[]).map(
                async (folder) => {
                  const resultAction = await dispatch(
                    createNewFolder({
                      item: {
                        parent_folder_id:
                          listParentFolderID[folder.parent_folder_id || 'undefined'] ||
                          folder.parent_folder_id,
                        company_id: folder.company_id,
                        folder_name: `${folder.folder_name}${
                          listParentFolderID[folder.parent_folder_id || 'undefined']
                            ? ''
                            : '（コピー作成）'
                        }`,
                        display_order: folder.display_order,
                        createdby: userInfo.login_id,
                        createdat: new Date(),
                      },
                      return_item_result: true,
                      return_display_id: true,
                    })
                  );

                  if (createNewFolder.fulfilled.match(resultAction) && resultAction.payload.item) {
                    const folder_i_id = get(
                      find(record?.item_links.links, { d_id: MANUAL_FOLDER.id }),
                      'items[0].i_id',
                      undefined
                    );

                    folder_i_id && handleUpdateParentFolderManual(folder_i_id, dispatch);
                    listParentFolderID[folder.folder_id] = resultAction.payload.item.folder_id;
                    await dispatch(
                      setFolderPermission({
                        item: {
                          company_id: userInfo.company_id,
                          folder_id: resultAction.payload.item.folder_id,
                          login_id: userInfo.login_id,
                        },
                      })
                    );
                    const fileList = await Promise.all([
                      dispatch(
                        getListManual({
                          conditions: [
                            {
                              id: 'company_id',
                              search_value: [userInfo.company_id],
                            },
                            {
                              id: 'folder_id',
                              search_value: [folder.folder_id],
                            },
                          ],
                          sort_fields: [{ id: 'display_order', order: 'asc' }],
                          isMyManual: isMyManual,
                          page: 1,
                          per_page: 0,
                        })
                      ),
                      dispatch(
                        getListManualFile({
                          conditions: [
                            {
                              id: 'company_id',
                              search_value: [userInfo.company_id],
                            },
                            {
                              id: 'folder_id',
                              search_value: [folder.folder_id],
                            },
                          ],
                          isMyManual: isMyManual,
                          page: 1,
                          per_page: 0,
                        })
                      ),
                    ]);
                    if (getListManual.fulfilled.match(fileList[0])) {
                      await Promise.all(
                        fileList[0].payload.items.map((item) =>
                          copyManual(item.i_id_file_id, resultAction.payload.item?.folder_id)
                        )
                      );
                    }
                    if (getListManualFile.fulfilled.match(fileList[1])) {
                      await Promise.all(
                        fileList[1].payload.items.map((item) =>
                          copyManualFile(item.i_id_file_id, resultAction.payload.item?.folder_id)
                        )
                      );
                    }
                  }
                }
              )
            );
          }
        }
      } finally {
        await fetchData();
        dispatch(stopLoading());
      }
    };

    const fetchData = useCallback(async () => {
      if (!activeFolder || !userInfo) return;
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getManualFolder({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getListManual({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            publish: filterOptions.publish,
            file_title: filterOptions.name,
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getListManualFile({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            publish: filterOptions.publish,
            file_title: filterOptions.name,
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    }, [activeFolder, dispatch, filterOptions.name, filterOptions.publish, isMyManual, userInfo]);

    const handleCopyLink = async (record?: Types.FileExplorer.DataTableType) => {
      const item = record || contextMenu.record;
      if (!item) return;
      if (item.type === 'manual') {
        await navigator.clipboard.writeText(
          window.location.origin +
            generatePath(routes.EditManual.path, {
              id: item.i_id_file_id,
            })
        );
      } else if (item.type === 'manual_file') {
        await navigator.clipboard.writeText(
          window.location.origin +
            generatePath(routes.ViewManual.path, {
              id: item.i_id_file_id,
            })
        );
      } else {
        await navigator.clipboard.writeText(
          window.location.origin +
            generatePath(routes.ManualFolderPath.path, {
              id: item.i_id,
            })
        );
      }
      alertCustomRef.current?.showAlert({
        children: (
          <WrapAlertCopied>
            <CheckCircleOutlined className="icon" />
            <span className="label">Copied</span>
          </WrapAlertCopied>
        ),
      });
    };

    useImperativeHandle(ref, () => ({
      handleCopy(record) {
        handleCopy(record);
      },
      handleDelete(record) {
        handleDelete(record);
      },
      handleCopyLink(record) {
        handleCopyLink(record);
      },
    }));

    return contextMenu.visible ? (
      <ContextMenuWrapper
        permission={permissionNumber}
        coordinate={{ x: contextMenu.x, y: contextMenu.y }}
      >
        <Menu
          className="menu"
          items={[
            {
              key: 'copy',
              icon: <CopyOutlined className="icon-menu" />,
              label: 'コピー',
              onClick: () => permissionNumber !== 1 && handleCopy(contextMenu.record),
            },
            {
              key: 'edit',
              icon: <EditOutlined className="icon-menu" />,
              label: '名称変更',
              onClick: () => {
                if (permissionNumber !== 1) {
                  setFileSelected(contextMenu.record);
                  setIsEdit(true);
                }
              },
            },
            ...(contextMenu.record?.type === 'folder'
              ? [
                  {
                    key: 'lock',
                    icon: <LockOutlined className="icon-menu" />,
                    label: 'フォルダ権限設定',
                    onClick: () => {
                      if (permissionNumber !== 1) {
                        setFileSelected(contextMenu.record);
                        setOpenModalFolderPermissionSetting(true);
                      }
                    },
                  },
                ]
              : []),
            {
              key: 'link',
              icon: <LinkOutlined className="icon-menu" />,
              label: 'リンクを取得',
              onClick: () => permissionNumber !== 1 && handleCopyLink(),
            },
            {
              key: 'delete',
              icon: <DeleteOutlined className="icon-menu" />,
              label: '削除',
              onClick: () => permissionNumber !== 1 && handleDelete(contextMenu.record),
            },
            {
              type: 'divider',
            },
            ...(contextMenu.record?.type !== 'folder'
              ? [
                  {
                    key: 'publish',
                    className: 'publish',
                    label: (
                      <div
                        className={`wrap-publish ${
                          contextMenu.record?.publish ? 'blGreen' : 'blViolet'
                        }`}
                      >
                        <span>{contextMenu.record?.publish ? '編集中にする' : '公開する'}</span>
                        <div className="wrap-icon">
                          {contextMenu.record?.publish ? (
                            <img src={IconPublish} className="icon-small" alt="publish-icon" />
                          ) : (
                            <img src={IconLocked} className="icon-small" alt="edit-icon" />
                          )}
                          <CaretRightOutlined className="caretIcon" />
                          {contextMenu.record?.publish ? (
                            <img src={IconLocked} className="icon" alt="edit-icon" />
                          ) : (
                            <img src={IconPublish} className="icon" alt="publish-icon" />
                          )}
                        </div>
                      </div>
                    ),
                    onClick: () => {
                      if (permissionNumber !== 1) {
                        setFileSelected(contextMenu.record);
                        setOpenPopupConfirmPublish(true);
                      }
                    },
                  },
                ]
              : []),
          ]}
        />
      </ContextMenuWrapper>
    ) : null;
  }
);

export default ContextMenu;
