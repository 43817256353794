import { differenceBy, intersectionBy } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services } from 'services';
import * as Types from 'types';
import {
  ATTACH,
  QUESTIONS,
  QUESTION_SEARCH,
  SELECT_CURRICULUMS,
  SELECT_QUESTION,
  SELECT_TYPES,
  COMPANIES,
  SELECT_QUESTION_CREATOR,
  CURRICULUMS,
  LEVEL_1,
  LEVEL_3,
  LEVEL_2,
  LEVEL_4,
  QUESTION_ASSIGN_LEVEL,
} from 'configs';

export const getDataQuestionCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'Question/thunk/getDataQuestionCurriculum',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      dispatch(startLoading());
      const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      });

      const { authContainer } = getState() as Types.RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getDataLevel1({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel2({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel3({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel4({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuesLevel({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      let resultArray: Array<any> = [];
      let arrayQuestion: Array<any> = [];
      let flattenedResultArray: Array<Types.TreeTraining> = [];
      if (
        getDataLevel1.fulfilled.match(responseStatus[0]) &&
        getDataLevel2.fulfilled.match(responseStatus[1]) &&
        getDataLevel3.fulfilled.match(responseStatus[2]) &&
        getDataLevel4.fulfilled.match(responseStatus[3]) &&
        getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
        getDataQues.fulfilled.match(responseStatus[5])
      ) {
        const dataLevel1 = responseStatus[0].payload.items;
        const dataLevel2 = responseStatus[1].payload.items;
        const dataLevel3 = responseStatus[2].payload.items;
        const dataLevel4 = responseStatus[3].payload.items;
        const dataAssignLevel = responseStatus[4].payload.items;
        const dataQues = responseStatus[5].payload.items;
        resultArray = await Promise.all(
          data.items.flatMap((item) => {
            const baseObject = {
              curriculum_code: item.code,
              curriculum_name: item.name,
              curriculum_description: item.description,
              curriculum_sort_order: item.sort_order,
              required_curriculum: item.required_curriculum,
              official_curriculum: item.official_curriculum,
            };
            const matchedLevel1Items = dataLevel1.filter((c) => c.curricullum_code === item.code);

            return matchedLevel1Items.flatMap((level1Item) => {
              const mergedObject1 = {
                ...baseObject,
                level1_i_id: level1Item.i_id,
                level1_name: level1Item.name,
                level1_code: level1Item.code,
                level1_sort_order: level1Item.sort_order,
              };

              const matchedLevel2Items = dataLevel2.filter(
                (level2Item) => level2Item.level1_code === level1Item.code
              );

              return matchedLevel2Items.flatMap((level2Item) => {
                const mergedObject2 = {
                  ...mergedObject1,
                  level2_i_id: level2Item.i_id,
                  level2_name: level2Item.name,
                  level2_code: level2Item.code,
                  level2_sort_order: level2Item.sort_order,
                };
                const matchedLevel3Items = dataLevel3.filter(
                  (level3Item) => level3Item.level2_code === level2Item.code
                );

                return matchedLevel3Items.flatMap((level3Item) => {
                  const mergedObject3 = {
                    ...mergedObject2,
                    level3_i_id: level3Item.i_id,
                    level3_name: level3Item.name,
                    level3_code: level3Item.code,
                    level3_sort_order: level3Item.sort_order,
                  };
                  const matchedLevel4Items = dataLevel4.filter(
                    (level4Item) => level4Item.level3_code === level3Item.code
                  );

                  return matchedLevel4Items.flatMap((level4Item) => {
                    const mergedObject4 = {
                      ...mergedObject3,
                      level4_i_id: level4Item.i_id,
                      level4_name: level4Item.name,
                      level4_code: level4Item.code,
                      level4_sort_order: level4Item.sort_order,
                    };
                    const matchedQuestionItems = dataAssignLevel
                      .filter((que) => que.level4_code === level4Item.code)
                      .map((que) => ({
                        ...que,
                        id: que.id,
                        question_sort_order: que.sort_order,
                        question_assign_level_i_id: que.i_id,
                      }));

                    const filteredItems = intersectionBy(dataQues, matchedQuestionItems, 'code');

                    return filteredItems.map((que) => ({
                      ...mergedObject4,
                      question_code_i_id: que.i_id,
                      question_code: que.code,
                      question_name: que.name,
                      question_sort_order: que.question_sort_order,
                      question_assign_level_i_id: que.question_assign_level_i_id,
                      creator: que.creator,
                      question_description: que.description,
                      score: que.score,
                    }));
                  });
                });
              });
            });
          })
        );

        flattenedResultArray = resultArray.flat();
        const question = dataQues.map((que) => ({
          ...que,
          curriculum_code: '',
          curriculum_name: '',
          required_curriculum: '',
          question_code_i_id: que.i_id,
          question_code: que.code,
          question_name: que.name,
          question_sort_order: que.question_sort_order,
          question_assign_level_i_id: que.question_assign_level_i_id,
          creator: que.creator,
          question_description: que.description,
          score: que.score,
        }));
        arrayQuestion = differenceBy(question, flattenedResultArray, 'question_code');
      }
      dispatch(stopLoading());
      return {
        ...data,
        items: [...flattenedResultArray, ...arrayQuestion],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1s.ResponseType>(LEVEL_1.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2s.ResponseType>(LEVEL_2.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3s.ResponseType>(LEVEL_3.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4s.ResponseType>(LEVEL_4.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.id,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.QuestionSearch.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.QuestionSearch.ResponseType>(
      QUESTION_SEARCH.name,
      {
        ...req,
        sort_fields: [{ id: 'question_code', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_CURRICULUMS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.SelectTypes.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SelectTypes.ResponseType>(SELECT_TYPES.id, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestionCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectQuestionCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Question/thunk/getSelectQuestionCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectQuestionCreator.ResponseType>(
      SELECT_QUESTION_CREATOR.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectQuestion.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getSelectQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectQuestion.ResponseType>(
      SELECT_QUESTION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createImages', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editQuestion = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Questions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/editQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTIONS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileQuestion = createAsyncThunk<
  any,
  Types.DeleteFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFileQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAttachQuestion = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteAttachQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttach = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/deleteFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getQuestionDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getQuestionDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateTimeLimitCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/updateTimeLimitCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataAllQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
