import styled from 'styled-components';

export default styled('div')`
  position: relative;
  .legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .sort-column {
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-left: 20px;
      .legend-filter {
        width: 14px;
        height: 14px;
      }
      .blue {
        background: #379bd2;
      }
      .pink {
        background: #ea8c92;
      }
      .gray {
        background: #ebebeb;
      }
      .legend-name {
        font-size: 12px;
        color: #424242;
        margin: 0 5px;
      }
      .btn-sort {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        padding: 0;
        .wrap-sort {
          display: flex;
          flex-direction: column;
          align-items: center;
          .icon-caret {
            font-size: 9px;
            color: #bfbfbf;
          }
          .active {
            color: #424242;
          }
        }
      }
    }
  }
  .container-chart {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .item {
      position: relative;
      width: 25%;
      text-align: center;
      border: 1px solid #dddddd;
      .number-order {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        border-right: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        width: 30px;
        height: 30px;
        top: 0;
        left: 0;
      }
      .pie {
        padding: 25px 0;
        .empty-pie {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

  .wrapper-header {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0px;
    background: #fff;
  }

  .footer-item {
    display: flex;
    width: 100%;
    border-top: 1px solid #eeeeee;
    .responses_num,
    .real_accuracy_rate {
      width: 50%;
      padding: 0px;
      .title {
        font-size: 11px;
        color: #777777;
        margin: 0;
      }
      .time {
        font-size: 16px;
        color: #424242;
        margin: 0;
      }
    }
    .responses_num {
      border-right: 1px solid #eeeeee;
    }
  }
  .skill-check-all {
    .responses_num,
    .real_accuracy_rate {
      padding: 0px;
      .title {
        font-size: 11px;
        color: #777777;
        margin: 0;
      }
      .time {
        font-size: 16px;
        color: #424242;
        margin: 0;
      }
    }
    .responses_num,
    .real_accuracy_rate {
      width: 50%;
    }
  }

  .border {
    padding: 15px;
    border: 1px solid #dddddd;
    border-radius: 2px 2px 0 0;
  }

  .header {
    width: 100%;
    background: #fff;

    span {
      font-weight: 400;
      font-size: 14px;
      color: #424242;
    }
    .space {
      margin: 0px 10px;
    }
    .bold {
      font-weight: 700;
      font-size: 14px;
      color: #424242;
    }
  }
  .g2-html-annotation {
    top: 85px !important;
  }
`;
