import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { authSelector } from 'containers/Auth/selectors';
import { resetOptionModal } from '../slice';
import ModalOfficial from './ModalOfficial';
import { FreeTrialStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Logo } from 'assets';

interface Props {
  visible: boolean;
  openOfficial: boolean;
  setOpenOfficial: React.Dispatch<React.SetStateAction<boolean>>;
}

const TreeTrialModalDashboard: React.FC<Props> = ({ visible, openOfficial, setOpenOfficial }) => {
  const { userInfo } = useSelector(authSelector);

  const { totalFileSize, dataCompany } = useSelector(authSelector);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(resetOptionModal({ skillCheckMenu: false, skillCheck: true }));
  };

  const handleSubmitModal = () => {
    setOpenOfficial(true);
  };

  return (
    <FreeTrialStyled open={visible} onCancel={handleCloseModal}>
      <div className="body">
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
        </p>
        <span className="sub-content">
          <span>SKILL FAMILIAR&nbsp;</span>
          <span className="free-day">30日間無料トライアル</span>
          <span>&nbsp;にようこそ!</span>
        </span>
        <div className="body-content">
          <div className="content">
            <div className="content-left">
              <p>● ご利用条件</p>
              <div>
                <Button className="btn-content">ご利用継続の場合</Button>
                <span className="content-footer-right">管理者ユーザー ×1&emsp;</span>
                <span>社内ユーザー ×5&emsp;</span>
                <span>面接ユーザー ×3</span>
              </div>
              <div className="btn-to">
                <Button className="btn-content">ストレージ管理</Button>
                <span className="content-footer-right">3GBまで </span>
                <span>（現在の使用量：{Number(totalFileSize.toFixed(2))}GB / 3GB）</span>
                {dataCompany?.plan_id === 'standard' && totalFileSize >= 3 && (
                  <span className="notice-file-size"> ※ストレージの使用量上限です。</span>
                )}
              </div>
              <div className="btn-to">
                <Button className="btn-content">トライアル期間</Button>
                <span className="content-footer-right">
                  30日間（ご利用可能期間：2024/10/20 - 2024/11/18）
                </span>
              </div>
              <p className="content-footer">● ご利用の留意点</p>
              <div className="content-footer-text">
                <Button className="btn-content">トライアル期間</Button>
                <span className="content-footer-right">
                  無料トライアル期間終了後10日以内にサービス利用を開始していただけると、
                  <br />
                  無料トライアル期間に作成したデータをそのままご利用いただけます。
                  <br />
                  期間を過ぎるとデータは削除されますのでご留意ください。
                </span>
              </div>
            </div>
          </div>
        </div>
        {userInfo?.user_type === 'admin' ? (
          <>
            <div className="sub-footer">無料トライアル期間中でも本登録に切り替えが可能です。</div>
            <div className="btn-submit">
              <Button className="btn" onClick={handleSubmitModal}>
                本登録はこちら
              </Button>
            </div>
          </>
        ) : null}
        <div
          className="sub-footer sub-back"
          onClick={() => dispatch(resetOptionModal({ skillCheckMenu: false, skillCheck: true }))}
        >
          <span className="back">閉じる</span>
        </div>
      </div>
      <ModalOfficial visible={openOfficial} setVisible={setOpenOfficial} />
    </FreeTrialStyled>
  );
};

export default TreeTrialModalDashboard;
