import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BarChartOutlined, TableOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { filter, find, mapValues, maxBy, orderBy, reduce, sumBy, uniqBy } from 'lodash';
import { Button, Checkbox, Select } from 'antd';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { authSelector } from 'containers/Auth/selectors';
import { trainingReportSelector } from '../selectors';
import exportPDF from 'libs/utils/exportPdf';
import FilePDFExport from './FilePDFExport';
import TableLevels from './TableLevels';
import ChartLevels from './ChartLevels';
import { useAppDispatch } from 'hooks';
import UserRpStyled from './styles';
import SurfaceTable from './Table';
import * as Types from 'types';
import Chart from './Chart';
import {
  ITEM_COMPARE_BAR_CHART_TRAINING,
  ITEM_COMPARE_PIE_CHART,
  ITEM_GRAPH,
} from 'constant/select.constants';
import {
  setDataReportCurriculumUser,
  setSelectCurriculums,
  setSelectUsers,
  setDataLevel,
  resetData,
} from '../slice';

const { Option } = Select;

interface Props {
  valueRequiredCurriculum: number;
  type: 'user-report' | 'curriculumn-report';
}

const UserReport: React.FC<Props> = ({ valueRequiredCurriculum, type }) => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [itemGraph, setItemGraph] = useState<(typeof ITEM_GRAPH)[number]['value']>();
  const [curriculum, setCurriculum] = useState<string>();
  const [level1Code, setLevel1Code] = useState<string>();
  const [level2Code, setLevel2Code] = useState<string>();
  const [level3Code, setLevel3Code] = useState<string>();
  const [level4Code, setLevel4Code] = useState<string>();
  const [segmented, setSegmented] = useState<number>(0);
  const [username, setUsername] = useState<string>();
  const [isShowAll, setIsShowAll] = useState(false);
  const [itemComparision, setItemComparision] = useState<
    | (typeof ITEM_COMPARE_BAR_CHART_TRAINING)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value']
  >();

  const ref = useRef(null);

  const { staffs } = useSelector(authSelector);
  const {
    userAssignCurriculum,
    selectCurriculums,
    questionsAssign,
    dataUserReport,
    levelQuestions,
    selectLevel1,
    selectLevel2,
    selectLevel3,
    selectLevel4,
    curriculums,
    selectUsers,
    questions,
    level1s,
    level2s,
    level3s,
    level4s,
  } = useSelector(trainingReportSelector);

  const dispatch = useAppDispatch();

  const isLevelsTransTable = useMemo(
    () => !!username && !!curriculum && username !== 'ALL' && curriculum !== 'ALL',
    [curriculum, username]
  );

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          valueRequiredCurriculum={valueRequiredCurriculum}
          isLevelsTransTable={isLevelsTransTable}
          itemComparision={itemComparision}
          curriculums={selectCurriculums}
          selectLevel1={selectLevel1}
          selectLevel2={selectLevel2}
          selectLevel3={selectLevel3}
          selectLevel4={selectLevel4}
          curriculum={curriculum}
          level1Code={level1Code}
          level2Code={level2Code}
          level3Code={level3Code}
          level4Code={level4Code}
          itemGraph={itemGraph}
          segmented={segmented}
          username={username}
          users={selectUsers}
          type={type}
        />
      </div>
    );
  }, [
    valueRequiredCurriculum,
    isLevelsTransTable,
    selectCurriculums,
    itemComparision,
    selectLevel1,
    selectLevel2,
    selectLevel3,
    selectLevel4,
    selectUsers,
    curriculum,
    level1Code,
    level2Code,
    level3Code,
    level4Code,
    itemGraph,
    segmented,
    username,
    type,
  ]);

  const handleExportCSV = (value: string) => {
    dispatch(startLoading());
    if (value === 'pdf') {
      if (!ref.current) return;

      exportPDF(ref, 'トレーニングレポート.pdf');
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  const getPropsCount = ({
    data,
    curriculumCode,
    lvl1Code,
    lvl2Code,
    lvl3Code,
    lvl4Code,
  }: {
    data: Types.ReportCurriculumUser.DataUserReport[];
    curriculumCode: string;
    lvl1Code?: string;
    lvl2Code?: string;
    lvl3Code?: string;
    lvl4Code?: string;
  }) => {
    return (
      sumBy(
        uniqBy(
          filter(
            data,
            (item) =>
              item.curriculum_code === curriculumCode &&
              (!lvl1Code || item.level1_code === lvl1Code) &&
              (!lvl2Code || item.level2_code === lvl2Code) &&
              (!lvl3Code || item.level3_code === lvl3Code) &&
              (!lvl4Code || item.level4_code === lvl4Code)
          ),
          'level4_code'
        ),
        'question_count_lv4'
      ) || 0
    );
  };

  const handleSetDataReportCurriculumUserByCurriculum = () => {
    if (!username || !curriculum) return;

    const dataReportCurriculumUser: Types.ReportCurriculumUser.UserReportSurfaceTable[] = [];

    const userName = find(selectUsers, { login_id: username })?.name || '';
    const curriculumName = find(selectCurriculums, { code: curriculum })?.name || '';
    const level1Name = find(selectLevel1, { code: level1Code })?.name || '';
    const level2Name = find(selectLevel2, { code: level2Code })?.name || '';
    const level3Name = find(selectLevel3, { code: level3Code })?.name || '';
    const level4Name = find(selectLevel4, { code: level4Code })?.name || '';

    if (curriculum !== 'ALL' && username !== 'ALL') {
      if (level4Code) {
        levelQuestions.map((levelQues) => {
          const questionTransFound = find(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === username &&
              item.level1_code === level1Code &&
              item.level2_code === level2Code &&
              item.level3_code === level3Code &&
              item.level4_code === level4Code &&
              item.question_code === levelQues.code
          );

          const question_count = 1;
          const questionName = find(questions, { code: levelQues.code })?.name || '';

          if (questionTransFound) {
            const responses_num = 1;
            const inexperienced = 0;
            const correct_answers_num = questionTransFound.correct ? 1 : 0;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat =
              questionTransFound.updated_at || questionTransFound.implementation_date;
            const implementation_date = questionTransFound.implementation_date;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              level1_code: level1Code,
              level1_name: level1Name,
              level2_code: level2Code,
              level2_name: level2Name,
              level3_code: level3Code,
              level3_name: level3Name,
              level4_code: level4Code,
              level4_name: level4Name,
              question_code: levelQues.code,
              question_name: questionName,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                level1_code: level1Code,
                level1_name: level1Name,
                level2_code: level2Code,
                level2_name: level2Name,
                level3_code: level3Code,
                level3_name: level3Name,
                level4_code: level4Code,
                level4_name: level4Name,
                question_code: levelQues.code,
                question_name: questionName,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      } else if (level3Code) {
        selectLevel4.map((level4) => {
          const dataFilter = filter(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === username &&
              item.level1_code === level1Code &&
              item.level2_code === level2Code &&
              item.level3_code === level3Code &&
              item.level4_code === level4.code
          );

          const question_count = getPropsCount({
            data: dataUserReport,
            curriculumCode: curriculum,
            lvl1Code: level1Code,
            lvl2Code: level2Code,
            lvl3Code: level3Code,
            lvl4Code: level4.code,
          });

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              level1_code: level1Code,
              level1_name: level1Name,
              level2_code: level2Code,
              level2_name: level2Name,
              level3_code: level3Code,
              level3_name: level3Name,
              level4_code: level4.code,
              level4_name: level4.name,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                level1_code: level1Code,
                level1_name: level1Name,
                level2_code: level2Code,
                level2_name: level2Name,
                level3_code: level3Code,
                level3_name: level3Name,
                level4_code: level4.code,
                level4_name: level4.name,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      } else if (level2Code) {
        selectLevel3.map((level3) => {
          const dataFilter = filter(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === username &&
              item.level1_code === level1Code &&
              item.level2_code === level2Code &&
              item.level3_code === level3.code
          );

          const question_count = getPropsCount({
            data: dataUserReport,
            curriculumCode: curriculum,
            lvl1Code: level1Code,
            lvl2Code: level2Code,
            lvl3Code: level3.code,
          });

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              level1_code: level1Code,
              level1_name: level1Name,
              level2_code: level2Code,
              level2_name: level2Name,
              level3_code: level3.code,
              level3_name: level3.name,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                level1_code: level1Code,
                level1_name: level1Name,
                level2_code: level2Code,
                level2_name: level2Name,
                level3_code: level3.code,
                level3_name: level3.name,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      } else if (level1Code) {
        selectLevel2.map((level2) => {
          const dataFilter = filter(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === username &&
              item.level1_code === level1Code &&
              item.level2_code === level2.code
          );

          const question_count = getPropsCount({
            data: dataUserReport,
            curriculumCode: curriculum,
            lvl1Code: level1Code,
            lvl2Code: level2.code,
          });

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              level1_code: level1Code,
              level1_name: level1Name,
              level2_code: level2.code,
              level2_name: level2.name,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                level1_code: level1Code,
                level1_name: level1Name,
                level2_code: level2.code,
                level2_name: level2.name,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      } else {
        selectLevel1.map((level1) => {
          const dataFilter = filter(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === username &&
              item.level1_code === level1.code
          );

          const question_count = getPropsCount({
            data: dataUserReport,
            curriculumCode: curriculum,
            lvl1Code: level1.code,
          });

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              level1_code: level1.code,
              level1_name: level1.name,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                level1_code: level1.code,
                level1_name: level1.name,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      }
    } else {
      if (curriculum !== 'ALL') {
        const question_count = getPropsCount({
          data: dataUserReport,
          curriculumCode: curriculum,
          lvl1Code: level1Code,
          lvl2Code: level2Code,
          lvl3Code: level3Code,
          lvl4Code: level4Code,
        });

        selectUsers.map((user) => {
          const dataFilter = filter(
            dataUserReport,
            (item) =>
              item.curriculum_code === curriculum &&
              item.login_id === user.login_id &&
              (!level1Code || item.level1_code === level1Code) &&
              (!level2Code || item.level2_code === level2Code) &&
              (!level3Code || item.level3_code === level3Code) &&
              (!level4Code || item.level4_code === level4Code)
          );

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: user.name,
              login_id: user.login_id,
              curriculum_name: curriculumName,
              curriculum_code: curriculum,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: user.name,
                login_id: user.login_id,
                curriculum_name: curriculumName,
                curriculum_code: curriculum,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      } else {
        selectCurriculums.map((cur) => {
          const dataFilter = filter(
            dataUserReport,
            (item) => item.curriculum_code === cur.code && item.login_id === username
          );

          const question_count = getPropsCount({ data: dataUserReport, curriculumCode: cur.code });

          if (dataFilter.length) {
            const responses_num = dataFilter.length;
            const inexperienced = question_count - responses_num;
            const correct_answers_num = filter(dataFilter, { correct: '1' }).length;
            const incorrect_answer = responses_num - correct_answers_num;
            const updatedat = dataFilter.length
              ? maxBy(dataFilter, 'updated_at')?.updated_at ||
                maxBy(dataFilter, 'implementation_date')?.implementation_date
              : undefined;
            const implementation_date = dataFilter[0]?.implementation_date || undefined;

            dataReportCurriculumUser.push({
              user_name: userName,
              login_id: username,
              curriculum_name: cur.name,
              curriculum_code: cur.code,
              responses_num,
              correct_answers_num,
              incorrect_answer,
              inexperienced,
              question_count,
              accuracy_rate: isFinite(correct_answers_num / question_count)
                ? correct_answers_num / question_count
                : 0,
              wrong_rate: isFinite(incorrect_answer / question_count)
                ? incorrect_answer / question_count
                : 0,
              inexperienced_rate: isFinite(inexperienced / question_count)
                ? inexperienced / question_count
                : 0,
              real_accuracy_rate: isFinite(correct_answers_num / responses_num)
                ? correct_answers_num / responses_num
                : 0,
              progress_rate: isFinite(responses_num / question_count)
                ? responses_num / question_count
                : 0,
              updatedat,
              implementation_date,
            });
          } else {
            isShowAll &&
              dataReportCurriculumUser.push({
                user_name: userName,
                login_id: username,
                curriculum_name: cur.name,
                curriculum_code: cur.code,
                correct_answers_num: 0,
                incorrect_answer: 0,
                inexperienced: 0,
                question_count,
                accuracy_rate: 0,
                wrong_rate: 0,
                inexperienced_rate: 0,
                responses_num: 0,
                real_accuracy_rate: 0,
                progress_rate: 0,
              });
          }
        });
      }
    }

    const dataLength = dataReportCurriculumUser.length;

    const dataTotal = reduce(
      dataReportCurriculumUser,
      (prev, next) => ({
        correct_answers_num: prev.correct_answers_num + Number(next.correct_answers_num),
        question_count: prev.question_count + Number(next.question_count || 0),
        inexperienced_rate: prev.inexperienced_rate + Number(next.inexperienced_rate),
        wrong_rate: prev.wrong_rate + Number(next.wrong_rate),
        accuracy_rate: prev.accuracy_rate + Number(next.accuracy_rate),
        inexperienced: prev.inexperienced + Number(next.inexperienced || 0),
        incorrect_answer: prev.incorrect_answer + Number(next.incorrect_answer),
        responses_num: prev.responses_num + Number(next.responses_num),
        real_accuracy_rate: prev.real_accuracy_rate + Number(next.real_accuracy_rate),
        progress_rate: prev.progress_rate + Number(next.progress_rate),
      }),
      {
        correct_answers_num: 0,
        question_count: 0,
        inexperienced_rate: 0,
        wrong_rate: 0,
        accuracy_rate: 0,
        inexperienced: 0,
        incorrect_answer: 0,
        responses_num: 0,
        real_accuracy_rate: 0,
        progress_rate: 0,
      }
    );

    const dataAvg: Partial<Types.ReportCurriculumUser.UserReportSurfaceTable> = mapValues(
      dataTotal,
      (value) => value / dataLength
    );

    const dataTotalWithAvg = {
      user_name: '',
      curriculum_name: '',
      login_id: '',
      curriculum_code: '',
      level1_code: '',
      level1_name: '',
      level2_code: '',
      level2_name: '',
      level3_code: '',
      level3_name: '',
      level4_code: '',
      level4_name: '',
      question_code: '',
      question_name: '',
      updatedat: undefined,
      implementation_date: undefined,
      ...dataAvg,
    };

    dispatch(
      setDataReportCurriculumUser({
        data: [dataTotalWithAvg, ...orderBy(dataReportCurriculumUser, ['updatedat'], ['asc'])],
        length: dataLength,
      })
    );
  };

  useEffect(() => {
    setCurriculum(undefined);
    setLevel1Code(undefined);
    setLevel2Code(undefined);
    setLevel3Code(undefined);
    setLevel4Code(undefined);
    setUsername(undefined);
    setSegmented(0);
    setIsShowAll(false);
    dispatch(resetData());
    return () => {
      setCurriculum(undefined);
      setLevel1Code(undefined);
      setLevel2Code(undefined);
      setLevel3Code(undefined);
      setLevel4Code(undefined);
      setUsername(undefined);
      setSegmented(0);
      setIsShowAll(false);
      dispatch(resetData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, valueRequiredCurriculum]);

  useEffect(() => {
    if (curriculum && username) {
      handleSetDataReportCurriculumUserByCurriculum();
    } else {
      dispatch(
        setDataReportCurriculumUser({
          data: [],
          length: 0,
        })
      );
    }
  }, [
    dispatch,
    username,
    curriculum,
    level1Code,
    level2Code,
    level3Code,
    level4Code,
    isShowAll,
    selectLevel1,
    selectLevel2,
    selectLevel3,
    selectLevel4,
    levelQuestions,
    questions,
    selectUsers,
    selectCurriculums,
    dataUserReport,
  ]);

  useEffect(() => {
    if (valueRequiredCurriculum) {
      const filteredUsers: Types.Users.ResponseType[] = filter(staffs, (user) => {
        const userFound = find(
          userAssignCurriculum,
          (u) =>
            u.login_id === user.login_id &&
            (!curriculum || curriculum === 'ALL' || u.curricullum_code === curriculum)
        );
        return !!userFound;
      });
      dispatch(setSelectUsers({ data: filteredUsers }));
    } else {
      dispatch(setSelectUsers({ data: staffs }));
    }
  }, [valueRequiredCurriculum, staffs, userAssignCurriculum, curriculum, dispatch]);

  useEffect(() => {
    if (valueRequiredCurriculum) {
      const filteredCurriculum: Types.Curriculums.ResponseType[] = filter(curriculums, (curr) => {
        const curriculumFound = find(
          userAssignCurriculum,
          (u) =>
            u.curricullum_code === curr.code &&
            (!username || username === 'ALL' || u.login_id === username)
        );
        return !!curriculumFound;
      });

      dispatch(setSelectCurriculums({ data: filteredCurriculum }));
    } else {
      dispatch(setSelectCurriculums({ data: curriculums }));
    }
  }, [curriculums, valueRequiredCurriculum, username, userAssignCurriculum, dispatch]);

  return (
    <UserRpStyled>
      {component}
      <div className="container">
        <div className="header-container">
          <span className="title">
            カリキュラム実施結果を、ユーザーを基準に集計したレポートを表示しています。
          </span>

          <div className="wrap-button">
            <Checkbox onChange={(e) => setIsShowAll(e.target.checked)} checked={isShowAll}>
              <span className="label-check-box">
                {username !== 'ALL' && curriculum !== 'ALL'
                  ? '未実施のカリキュラムを表示する'
                  : username === 'ALL'
                  ? '未実施者を表示する'
                  : '未実施のカリキュラムを表示する'}
              </span>
            </Checkbox>
            <Button
              className="button-export"
              onClick={() => setShowConfirmExportFileModal(true)}
              icon={<CloudDownloadOutlined />}
            >
              エクスポート
            </Button>
          </div>
        </div>
        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <div className="form-select">
              <div className="item">
                <span className="text-label">ユーザー名</span>
                <Select
                  allowClear
                  className="select"
                  placeholder="指定なし"
                  value={username}
                  onChange={(value: string) => {
                    if (value === 'ALL' && curriculum === 'ALL') {
                      setCurriculum(undefined);
                    }

                    if (
                      valueRequiredCurriculum &&
                      curriculum !== 'ALL' &&
                      !find(userAssignCurriculum, { login_id: value })
                    ) {
                      setCurriculum(undefined);
                    }
                    setUsername(value);
                  }}
                >
                  <Option disabled={curriculum === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {selectUsers.map((item, index) => (
                    <Option key={index} value={item.login_id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="divider" />
              <div className="item">
                <span className="text-label">カリキュラム名</span>
                <Select
                  allowClear
                  className="select"
                  placeholder="指定なし"
                  value={curriculum}
                  onChange={(value: string) => {
                    if (value === curriculum) return;
                    if (value === 'ALL' && username === 'ALL') {
                      setUsername(undefined);
                    }
                    if (
                      valueRequiredCurriculum &&
                      username !== 'ALL' &&
                      !find(userAssignCurriculum, { login_id: username })
                    ) {
                      setUsername(undefined);
                    }
                    setCurriculum(value);
                    dispatch(
                      setDataLevel({
                        level: 1,
                        dataLevel: filter(level1s, { curricullum_code: value }),
                      })
                    );
                    setLevel1Code(undefined);
                    setLevel2Code(undefined);
                    setLevel3Code(undefined);
                    setLevel4Code(undefined);
                  }}
                >
                  <Option disabled={username === 'ALL'} value="ALL">
                    ALL
                  </Option>
                  {selectCurriculums.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第１階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  value={level1Code}
                  disabled={curriculum === 'ALL' || !curriculum}
                  onSelect={(value: string) => {
                    if (value === level1Code) return;
                    setLevel1Code(value);
                    dispatch(
                      setDataLevel({
                        level: 2,
                        dataLevel: filter(level2s, { level1_code: value }),
                      })
                    );
                    setLevel2Code(undefined);
                    setLevel3Code(undefined);
                    setLevel4Code(undefined);
                  }}
                >
                  {selectLevel1?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name || '（空白）'}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第２階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level1Code}
                  value={level2Code}
                  onSelect={(value: string) => {
                    if (value === level2Code) return;
                    setLevel2Code(value);
                    dispatch(
                      setDataLevel({
                        level: 3,
                        dataLevel: filter(level3s, { level2_code: value }),
                      })
                    );
                    setLevel3Code(undefined);
                    setLevel4Code(undefined);
                  }}
                >
                  {selectLevel2?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name || '（空白）'}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第３階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level2Code}
                  value={level3Code}
                  onSelect={(value: string) => {
                    if (value === level3Code) return;
                    setLevel3Code(value);
                    dispatch(
                      setDataLevel({
                        level: 4,
                        dataLevel: filter(level4s, { level3_code: value }),
                      })
                    );
                    setLevel4Code(undefined);
                  }}
                >
                  {selectLevel3?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name || '（空白）'}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className="item">
                <span className="text-label">第４階層</span>
                <Select
                  className="select"
                  placeholder="指定なし"
                  disabled={curriculum === 'ALL' || !level3Code}
                  value={level4Code}
                  onSelect={(value: string) => {
                    if (value === level4Code) return;
                    setLevel4Code(value);
                    dispatch(
                      setDataLevel({
                        level: 5,
                        dataLevel: uniqBy(filter(questionsAssign, { level4_code: value }), 'code'),
                      })
                    );
                  }}
                >
                  {selectLevel4?.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name || '（空白）'}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(0);
                    setItemComparision(undefined);
                  }}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                  onClick={() => {
                    setSegmented(1);
                    setItemGraph('bar_chart');
                    setItemComparision('progress_rate');
                  }}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              {segmented ? (
                <>
                  <span className="cross-boiled">/</span>
                  <div className="wrapper-options">
                    <div className="wrapper-option">
                      <span className="label">グラフ：</span>
                      <div className="item">
                        <Select
                          className="select"
                          placeholder="---"
                          onSelect={(value) => {
                            setItemGraph(value);
                            setItemComparision(
                              value === 'bar_chart' ? 'progress_rate' : 'solution_ratio'
                            );
                          }}
                          value={itemGraph}
                        >
                          {ITEM_GRAPH.map(({ label, value }, index) => (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="wrapper-option">
                      <span className="label">比較項目：</span>
                      <div className="item">
                        <Select
                          className="select"
                          placeholder="---"
                          onSelect={setItemComparision}
                          value={itemComparision}
                        >
                          {(itemGraph === 'bar_chart'
                            ? ITEM_COMPARE_BAR_CHART_TRAINING
                            : ITEM_COMPARE_PIE_CHART
                          ).map(({ label, value }, index) => (
                            <Option key={index} value={value}>
                              {label}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {segmented === 0 ? (
            isLevelsTransTable ? (
              <TableLevels
                level1Code={level1Code}
                level2Code={level2Code}
                level3Code={level3Code}
                level4Code={level4Code}
              />
            ) : (
              <SurfaceTable curriculum={curriculum} username={username} level1Code={level1Code} />
            )
          ) : isLevelsTransTable ? (
            <ChartLevels
              itemComparision={itemComparision}
              itemGraph={itemGraph}
              level1Code={level1Code}
              level2Code={level2Code}
              level3Code={level3Code}
              level4Code={level4Code}
            />
          ) : (
            <Chart
              itemComparision={itemComparision}
              itemGraph={itemGraph}
              curriculum={curriculum}
              username={username}
            />
          )}
        </div>
      </div>
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </UserRpStyled>
  );
};

export default UserReport;
