import React, { useEffect, useState } from 'react';
import { omit } from 'lodash';

import { memoizedGetFlatDataFromTree } from 'libs/utils/trainingCurriculum/memoized-tree-data-utils';
import { Wrapper as WrapperColumnRoot } from './TreeNodeRenderer/styles';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import { Wrapper } from './styles';
import * as Types from 'types';

const NODE_HEIGHT = 45;

interface Props {
  tabActive: number;
  columnClosed?: number;
  setStartTime?: React.Dispatch<string>;
  treeData: Types.TreeItem<Types.TrainingCurriculumItemType>;
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setNumberQuestionImplement: React.Dispatch<React.SetStateAction<number>>;
  type?: string;
  isIndex?: number;
}

const filterTreeDataByColumn = (
  data: Types.TreeItem<Types.TrainingCurriculumItemType>,
  columnClosed: number
): Types.TreeItem<Types.TrainingCurriculumItemType> => {
  if (data.columnIndex! > columnClosed - 1) return omit(data, 'children');

  const filteredChildren: Types.TreeItem<Types.TrainingCurriculumItemType>[] | undefined =
    data.children
      ? data.children.map((child) => filterTreeDataByColumn(child, columnClosed))
      : undefined;

  return {
    ...data,
    children: filteredChildren,
  };
};

const TreeView: React.FC<Props> = ({
  treeData,
  tabActive,
  columnClosed,
  setChangeTab,
  setStartTime,
  setNumberQuestionImplement,
  type,
  isIndex,
}) => {
  const [countCorrect, setCountCorrect] = useState<number>(0);
  const [data, setData] = useState<{
    [key: string]: Types.TreeItem<Types.TrainingCurriculumItemType>[];
  }>({});

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.TrainingCurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });
    dataPrimitive.forEach((item: Types.TreeItem<Types.TrainingCurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    setCountCorrect(
      dataPrimitive.filter(
        (item: Types.TreeItem<Types.TrainingCurriculumItemType>) =>
          item.columnIndex === 5 && item.node?.correct_true
      ).length
    );
    setData(column);
  }, [treeData]);

  useEffect(() => {
    if (!columnClosed) return;

    const column: { [key: string]: Types.TreeItem<Types.TrainingCurriculumItemType>[] } = {};

    const filteredTreeData = filterTreeDataByColumn(treeData, columnClosed);

    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData: filteredTreeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.TrainingCurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });
    setCountCorrect(
      dataPrimitive.filter(
        (item: Types.TreeItem<Types.TrainingCurriculumItemType>) =>
          item.columnIndex === 5 && item.node?.correct_true
      ).length
    );
  }, [treeData, columnClosed]);

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      <WrapperColumnRoot nodeHeight={NODE_HEIGHT} className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            index={0}
            node={data[0][0]}
            tabActive={tabActive}
            nodeHeight={50}
            setChangeTab={setChangeTab}
            setStartTime={setStartTime}
            countCorrect={countCorrect}
            accept={data[0][0].node!.i_id!}
            setNumberQuestionImplement={setNumberQuestionImplement}
            type={type}
            isIndex={isIndex}
          />
        </div>
      </WrapperColumnRoot>
      <div className="wrap_node_tree">
        {Array.from({
          length: columnClosed !== undefined ? columnClosed + 1 : 6,
        }).map((_, index) =>
          index > 0 ? (
            <ColumnRenderer
              key={index}
              column={data[index]}
              columnIndex={index}
              tabActive={tabActive}
              nodeHeight={NODE_HEIGHT}
              setChangeTab={setChangeTab}
              setStartTime={setStartTime}
              countCorrect={countCorrect}
              accept={data[0][0].node!.i_id!}
              setNumberQuestionImplement={setNumberQuestionImplement}
            />
          ) : (
            <div style={{ width: '3.32%', flexGrow: 0 }} className="column" key={index} />
          )
        )}
      </div>
    </Wrapper>
  );
};

export default TreeView;
