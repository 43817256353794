import { createAsyncThunk } from '@reduxjs/toolkit';
import { filter, find, map, uniqBy } from 'lodash';

import { setIdDeleted } from './slice';
import { services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  MANUAL,
  MANUAL_FILE,
  MANUAL_FOLDER,
  ATTACH,
  MANUAL_SECTION,
  SELECT_DEPARTMENT,
  MANUAL_FOLDER_PERMISSIONS,
  USERS,
  AFFILIATION_ASSIGN_ROLE,
  AFFILIATION_LEVEL,
} from 'configs';

export const getManualFolder = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualFolderList.ResponseType> & {
    ignoreSetActiveFolder?: boolean;
    id?: string;
  },
  Types.GetItemRequestType & { ignoreSetActiveFolder?: boolean; id?: string },
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getManualFolder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualFolder.ResponseType>(
      MANUAL_FOLDER.name,
      req
    );

    return { ...data, ignoreSetActiveFolder: req.ignoreSetActiveFolder, id: req.id };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataManualConvert = createAsyncThunk<
  Types.GetItemResponseType<Types.Manual.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataManualConvert', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Manual.ResponseType>(MANUAL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataManualSelect = createAsyncThunk<
  Types.GetItemResponseType<Types.Manual.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataManualSelect', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Manual.ResponseType>(MANUAL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListManual = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualConvertType>,
  Types.GetItemRequestType & { publish?: string; file_title?: string; isMyManual?: boolean },
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getListManual', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.ManualFolder.ResponseType>(MANUAL_FOLDER.name, {
      ...req,
      sort_fields: [{ id: 'createdat', order: 'asc' }],
    });

    const { authContainer } = getState() as RootState;
    const responseStatus = await dispatch(
      getDataManualConvert({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
          ...(req.publish !== undefined
            ? [
                {
                  id: 'publish',
                  search_value: [req.publish],
                  exact_match: true,
                },
              ]
            : []),
          ...(req.file_title
            ? [
                {
                  id: 'manual_title',
                  search_value: [req.file_title],
                },
              ]
            : []),
          ...(req.isMyManual
            ? [
                {
                  id: 'createdby',
                  search_value: [authContainer?.userInfo?.login_id],
                  exact_match: true,
                },
              ]
            : []),
        ],
        include_links: true,
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<Types.ManualConvertType> = [];

    if (getDataManualConvert.fulfilled.match(responseStatus)) {
      const dataManualConvert = responseStatus.payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const matchedFolderItems = dataManualConvert.filter(
            (c) => c.folder_id === item.folder_id
          );
          return matchedFolderItems.flatMap((matchedItems) => ({
            i_id: item.i_id,
            i_id_file_id: matchedItems.i_id,
            company_id: item.company_id,
            folder_id: item.folder_id,
            file_id: matchedItems.manual_id,
            file_title: matchedItems.manual_title,
            publish: Number(matchedItems.publish),
            updatedat: matchedItems.updatedat,
            file_version: Number(matchedItems.version),
            file_description: matchedItems.description,
            item_links: matchedItems.item_links,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataManualFileConvert = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualFile.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataManualFileConvert', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualFile.ResponseType>(MANUAL_FILE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataManualFileSelect = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualFile.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataManualFileSelect', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualFile.ResponseType>(MANUAL_FILE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListManualFile = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualConvertType>,
  Types.GetItemRequestType & { publish?: string; file_title?: string; isMyManual?: boolean },
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getListManualFile', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.ManualFolder.ResponseType>(MANUAL_FOLDER.name, {
      ...req,
      sort_fields: [{ id: 'createdat', order: 'asc' }],
    });

    const { authContainer } = getState() as RootState;
    const responseStatus = await dispatch(
      getDataManualFileConvert({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
          ...(req.publish !== undefined
            ? [
                {
                  id: 'publish',
                  search_value: [req.publish],
                  exact_match: true,
                },
              ]
            : []),
          ...(req.file_title
            ? [
                {
                  id: 'attachment_title',
                  search_value: [req.file_title],
                },
              ]
            : []),
          ...(req.isMyManual
            ? [
                {
                  id: 'createdby',
                  search_value: [authContainer?.userInfo?.login_id],
                  exact_match: true,
                },
              ]
            : []),
        ],
        include_links: true,
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<Types.ManualConvertType> = [];

    if (getDataManualFileConvert.fulfilled.match(responseStatus)) {
      const dataManualFileConvert = responseStatus.payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const matchedFolderItems = dataManualFileConvert.filter(
            (c) => c.folder_id === item.folder_id
          );
          return matchedFolderItems.flatMap((matchedItems) => ({
            i_id: item.i_id,
            i_id_file_id: matchedItems.i_id,
            company_id: item.company_id,
            folder_id: item.folder_id,
            file_id: matchedItems.attachment_id,
            file_title: matchedItems.attachment_title,
            publish: Number(matchedItems.publish),
            createdat: matchedItems.createdat,
            createdby: matchedItems.createdby,
            file_version: Number(matchedItems.attachment_version),
            file_description: matchedItems.attachment_description,
            extension: matchedItems.extension,
            item_links: matchedItems.item_links,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteManualFile = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(MANUAL_FILE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateFolder = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ManualFolder.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/updateFolder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(MANUAL_FOLDER.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFolder = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteFolder', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(setIdDeleted(req.id));
    const { data } = await services.delete(MANUAL_FOLDER.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createNewFolder = createAsyncThunk<
  Types.CreateItemResponseType<Types.FileExplorer.FileExplorerType>,
  Types.CreateItemRequestType<Types.ManualFolder.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createNewFolder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(MANUAL_FOLDER.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateManual = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Manual.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/updateManual', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(MANUAL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const coppyFile = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/coppyFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getManual = createAsyncThunk<
  Types.GetItemResponseType<Types.Manual.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getManual', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Manual.ResponseType>(MANUAL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteManual = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteManual', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(MANUAL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateManualFile = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ManualFile.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/updateManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(MANUAL_FILE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createManual = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Manual.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createManual', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(MANUAL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createManualFile = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ManualFile.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(MANUAL_FILE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createFileAttach = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Attach.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createManualSection = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ManualSection.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/createManualSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(MANUAL_SECTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateManualSection = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.ManualSection.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/updateManualSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(MANUAL_SECTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteManualSection = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteManualSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(MANUAL_SECTION.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getManualSection = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualSection.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getManualSection', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualSection.ResponseType>(
      MANUAL_SECTION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDetailManual = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualDetailType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDetailManual', async (req, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await services.search<Types.Manual.ResponseType>(MANUAL.name, req);

    const { authContainer } = getState() as RootState;
    const responseStatus = await dispatch(
      getManualSection({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'display_order',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<Types.ManualDetailType> = [];

    if (getManualSection.fulfilled.match(responseStatus)) {
      const dataManualSectionConvert = responseStatus.payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const matchedManualDetailItems = dataManualSectionConvert.filter(
            (c) => c.manual_id === item.manual_id
          );

          return matchedManualDetailItems.flatMap((matchedItems) => ({
            company_id: item.company_id,
            i_id: item.i_id,
            i_id_section: matchedItems.i_id,
            description: item.description,
            display_order: Number(matchedItems.display_order),
            folder_id: item.folder_id,
            manual_id: item.manual_id,
            manual_title: item.manual_title,
            publish: Number(item.publish),
            section_id: matchedItems.section_id,
            section_name: matchedItems.section_name,
            text: matchedItems.text,
            version: Number(item.version),
            item_links: item.item_links,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getManualFile = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualFile.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualFile.ResponseType>(MANUAL_FILE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachManualFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getAttachManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAttachManualFile = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteAttachManualFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI
>('manual/thunk/getAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationLevel.ResponseType>(
      AFFILIATION_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataListAffiliation = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataListAffiliation', async (req, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await dispatch(
      getAffiliationLevel({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_links: true,
      })
    );

    let affiliations: Types.AffiliationLevel.ResponseType[] = [];
    if (
      getAffiliationLevel.fulfilled.match(responseStatus) &&
      data.items.length &&
      authContainer.userInfo?.login_id
    ) {
      const dataAffiliations = responseStatus.payload.items;
      const affAssignUsers = filter(data.items, { login_id: authContainer.userInfo?.login_id });

      if (affAssignUsers.length) {
        affAssignUsers.map((aff) => {
          if (aff.item_links?.links?.length) {
            const affLevel = map(
              aff?.item_links.links.find((link) => link.d_id === AFFILIATION_LEVEL.id)?.items || [],
              (levelId) => find(dataAffiliations, { i_id: levelId.i_id }) || {}
            ) as Types.AffiliationLevel.ResponseType[];

            if (affLevel.length) {
              affLevel.map((level) => {
                affiliations = [...affiliations, level];
              });
            }
          }
        });
      }
    }
    return { ...data, items: uniqBy([...affiliations], 'affiliation_id') };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListAffiliationUser = createAsyncThunk<
  Types.GetItemResponseType<Types.ListAffiliationAssignUser>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getListAffiliationUser', async (req, { rejectWithValue, getState, dispatch }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);
    const { authContainer } = getState() as RootState;
    const responseStatus = await dispatch(
      getAffiliationAssignRole({
        conditions: [
          {
            id: 'company_id',
            search_value: [authContainer?.userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'display_order',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );

    let resultArray: Array<Types.ListAffiliationAssignUser> = [];

    if (getAffiliationAssignRole.fulfilled.match(responseStatus)) {
      const dataAffiliationAssignRole = responseStatus.payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const matchedUserItems = dataAffiliationAssignRole.filter(
            (c) => c.login_id === item.login_id && c.main_role === 'main'
          );
          return matchedUserItems.flatMap((matchedItems) => ({
            company_id: item.company_id,
            i_id: item.i_id,
            affiliation_id: matchedItems.affiliation_id.split(' ')[0],
            affiliation_name: matchedItems.affiliation_id.replace(/^\d+ /, ''),
            birth_date: item.dob,
            employee_code: item.employee_code,
            hire_date: item.doj,
            kana: item.name_furigana,
            last_login: item.last_login_at,
            login_id: item.login_id,
            main: 'main',
            position_code: matchedItems.positions_code,
            role_code: item.role_code,
            user_name: item.name,
          }));
        })
      );
    }

    return {
      ...data,
      items: [...resultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getListDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListFolderPermission = createAsyncThunk<
  Types.GetItemResponseType<Types.ManualFolderPermissions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getListFolderPermission', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.ManualFolderPermissions.ResponseType>(
      MANUAL_FOLDER_PERMISSIONS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const setFolderPermission = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.ManualFolderPermissions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/setFolderPermission', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(MANUAL_FOLDER_PERMISSIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeFolderPermission = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/removeFolderPermission', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(MANUAL_FOLDER_PERMISSIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const uploadFileToS3 = createAsyncThunk<
  Types.UploadFileToS3ResponseType,
  Types.UploadFileToS3RequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/uploadFileToS3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.uploadFileToS3(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const executeAction = createAsyncThunk<
  Types.ExecuteActionResponseType,
  Types.ExecuteActionRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/executeAction', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.executeAction('UpdateItem', ATTACH.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataImage = createAsyncThunk<
  Types.UploadFileResponseType,
  any,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/getDataImage', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getRestrictedFile(ATTACH.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteFileAttachByConditions = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('manual/thunk/deleteFileAttachByConditions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(ATTACH.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
