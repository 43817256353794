import styled from 'styled-components';

interface Props {
  individualActive: boolean;
}

export default styled.section<Props>`
  background: #ffffff;
  height: 100%;
  .top-page {
    background: #08a3a5;
    padding: 12px 8px;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      .type-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        width: 48%;
        .number-type {
          color: rgba(255, 255, 255, 0.3);
          font-size: 24px;
        }
        .label {
          font-size: 12px;
          font-weight: 700;
        }
        .select {
          width: 55%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
      .close-icon {
        color: #ffffff;
        font-size: 16px;
        margin: 0 8px;
      }
    }
  }

  .wrap-body {
    margin: 30px;
    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: 14px;
        color: #424242;
      }
      .button-export {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        border-radius: 5px;
        color: #ffffff;
        padding: 0 30px;
      }
    }
    .wrap-filter {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      .aggregation-wrap {
        width: ${(props) => (props.individualActive ? 59 : 100)}%;
        .label {
          font-size: 11px;
          color: #777777;
        }
        .aggregation-conditions {
          background: #f2f2f2;
          padding: 15px;
          border: 1px solid #eaeaea;
          border-radius: 3px;
          width: 100%;
          .form-select {
            display: flex;
            align-items: flex-end;
            .item {
              width: 25%;
              margin-right: 10px;
              .select {
                width: 100%;
                .ant-select-selector {
                  border-radius: 5px;
                }
              }
              .text-label {
                height: 25px;
              }
              .ant-checkbox-wrapper {
                margin: 0 0 7px 10px;
              }
            }
            .text-label {
              display: block;
              font-size: 13px;
              color: #424242;
              .require {
                color: #f0330a;
              }
            }
          }
        }
      }
      .question-wrap {
        width: 40%;
        .label {
          font-size: 11px;
          color: #777777;
        }
        .question-conditions {
          background: #f2f2f2;
          padding: 15px;
          border: 1px solid #eaeaea;
          border-radius: 3px;
          width: 100%;
          .form-select {
            display: flex;
            align-items: flex-end;
            .answer-result {
              width: 30%;
            }
            .question-difficulty {
              width: 20%;
            }
            .item {
              margin-right: 10px;
              .select {
                width: 100%;
                .ant-select-selector {
                  border-radius: 5px;
                }
              }
              .text-label {
                height: 25px;
              }
              .ant-checkbox-wrapper {
                margin: 0 0 7px 10px;
              }
            }
            .text-label {
              display: block;
              font-size: 13px;
              color: #424242;
              .require {
                color: #f0330a;
              }
            }
          }
        }
      }
    }
    .sub-container {
      margin-top: 25px;
      position: relative;
      .individual-title {
        margin: 20px 0;
        .title {
          font-size: 13px;
          color: #424242;
        }
      }
      .wrap-segmented {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        .wrapper-options {
          display: flex;
          gap: 16px;
          align-items: center;

          .wrapper-option {
            display: flex;
            align-items: center;

            .btn-sort {
              margin-left: 6px;
              width: 18px;
              height: 18px;
              border-radius: 3px;
              padding: 0;
              .wrap-sort {
                display: flex;
                flex-direction: column;
                .icon-caret {
                  font-size: 9px;
                  color: #bfbfbf;
                }
                .active {
                  color: #424242;
                }
              }
            }
          }
        }
        .label {
          font-size: 13px;
          color: #424242;
        }
        .segmented {
          display: flex;
          align-items: center;
          jusity-content: center;
          .segmented-item {
            padding: 5px 10px;
            background: #ffffff;
            border: 2px solid #cccccc;
            cursor: pointer;
            color: #424242;
            font-weight: 400;
            font-size: 13px;
            .icon {
              margin-right: 10px;
              color: #c4c4c4;
            }
            &:nth-child(1) {
              border-radius: 3px 0 0 3px;
              border-right-color: #08a3a5;
            }
            &:nth-child(2) {
              border-radius: 0 3px 3px 0;
              border-left-width: 0;
            }
          }
          .segmented-item-selected {
            border-color: #08a3a5;
            color: #08a3a5;
            font-weight: bold;
            .icon {
              color: #08a3a5;
            }
          }
        }
        .cross-boiled {
          margin: 0 16px;
        }
        .item {
          margin-bottom: 0;
          width: 190px;
          .select {
            width: 100%;
            .ant-select-selector {
              border-radius: 3px;
            }
          }
        }
      }
    }
  }
`;
