import styled from 'styled-components';

export default styled.section`
  .table-header {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    width: 50%;
    right: 0;
    top: 0;

    .ant-pagination-total-text {
      position: absolute;
      right: 0;
      .text-total-record-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .ant-pagination {
      .ant-pagination-options {
        position: absolute;
        right: 130px;

        ::before {
          content: '表示件数：';
          font-size: 11px;
          padding-right: 3px;
        }
      }
    }
  }

  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr:first-child .ant-table-cell {
      font-size: 12px;
      padding: 15px 3px;
    }
    .ant-table-tbody .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr .average {
      background: #f4fbfb;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
    .table-row-light {
      background-color: #ffffff;
    }

    .table-row-dark {
      background-color: #f9f9f9;
    }
  }
`;
