import React, { useEffect, useState } from 'react';
import { Button, Table, Tooltip, Select as SelectAntd } from 'antd';
import { Select, SubmitButton, Form } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';

import { DownOutlined, FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { HEADER_EMPLOYEE_CSV } from 'constant/header.export.constant';
import SkillCheckBatchSetting from '../Modal/SkillCheckBatchSetting';
import { settingSelector } from 'containers/AppSettings/selectors';
import RegistrationComplete from '../Modal/RegistrationComplete';
import { employeeSelector } from '../../Employee/selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { interviewUsersSelector } from '../selectors';
import UploadCSV from 'components/Modal/UploadCSV';
import { Header, SelectField } from 'components';
import { routes } from 'navigations/routes';
import BatchSettingStyled from './styles';
import { useAppDispatch, usePermission } from 'hooks';
import * as Types from 'types';
import {
  getDataEmployeeExport,
  getSelectDepartment,
  getSelectPosition,
} from '../../Employee/thunk';
import {
  getDataBatchSetting,
  getDataSelectInterviewUser,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckGroupingCode,
} from '../thunk';

const { Option } = Select;

const BatchSetting: React.FC = () => {
  const [visibleModalCheckBatch, setVisibleModalCheckBatch] = useState<boolean>(false);
  const [visiblePopupUploadCSV, setVisiblePopupUploadCSV] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Array<Types.BatchSettingType>>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [visibleComplete, setVisibleComplete] = useState<boolean>(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [perPage, setPerPage] = useState<number>(100);

  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { dataSelectPosition, dataSelectDepartment } = useSelector(employeeSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataBatchSetting,
    dataSelectSkillCheckGroupingCode,
    dataSelectSkillCheck,
    dataSelectInteviewUser,
  } = useSelector(interviewUsersSelector);

  const navigate = useNavigate();

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const columns: ColumnsType<Types.BatchSettingType> = [
    {
      title: '管理番号',
      dataIndex: 'manage_code',
      key: 'manage_code',
      width: '7%',
      ellipsis: true,
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '11%',
      ellipsis: true,
    },
    {
      title: 'フリガナ',
      dataIndex: 'kana',
      key: 'kana',
      width: '11%',
      ellipsis: true,
    },
    {
      title: '職歴',
      dataIndex: 'work_history',
      key: 'work_history',
      width: '5%',
      render: (text, _, index) => (
        <Tooltip
          trigger={['click']}
          title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
          color="#ffffff"
          onVisibleChange={(v) =>
            setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
          }
        >
          <FileTextOutlined
            className="icon"
            style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </Tooltip>
      ),
    },
    {
      title: '募集所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '10%',
      ellipsis: true,
      render: (title: string) => title.replace(/^\d+ /, ''),
    },
    {
      title: '募集役職',
      dataIndex: 'position_code',
      key: 'position_code',
      ellipsis: true,
      width: '8%',
      render: (title: string) => title.replace(/^\d+ /, ''),
    },

    {
      title: '分析グループ',
      dataIndex: 'grouping_code',
      key: 'grouping_code',
      className: 'analysis_group',
      width: '16%',
      render: (record: Types.BatchSettingType) => record && `・${record}`,
    },

    {
      title: 'スキルチェック',
      dataIndex: 'skill_check_codes',
      className: 'skill_check_name',
      key: 'skill_check_codes',
      width: '30%',
      render: (record: Array<Types.BatchSettingType>) =>
        record && record.map((val, index) => <span key={index}>・{val.name}</span>),
    },
  ];

  const handleImportCSV = (file: File) => {
    // parse(file, {
    //   complete: async ({ data }: ParseResult<string>) => {
    //     const resultsDataCSV: Array<Types.EmployeeExportType> = data
    //       .map((item) =>
    //         Object.fromEntries(HEADER_EMPLOYEE_CSV.map(({ key }, i) => [key, item[i]]))
    //       )
    //       .splice(1) as unknown as Array<Types.EmployeeExportType>;
    //     await Promise.all(
    //       resultsDataCSV.map((item) =>
    //         Promise.all([
    //           dispatch(
    //             createEmployee({
    //               item: {
    //                 ...item,
    //                 user_type: 'member',
    //                 login_id: item.email,
    //                 company_id: userInfo?.company_id,
    //               },
    //             })
    //           ),
    //           dispatch(
    //             createAffiliationRole({
    //               item: {
    //                 company_id: userInfo?.company_id,
    //                 affiliation_id: item.department_name,
    //                 login_id: item.email,
    //                 sort_order: 1,
    //                 main_role: 'main',
    //               },
    //             })
    //           ),
    //         ])
    //       )
    //     );
    //     fetchEmployee();
    //   },
    // });
  };

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataEmployeeExport({
          page: 1,
          per_page: 0,
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'i_id',
              search_value: selectedRow.map((row) => row.i_id),
            },
          ],
          include_lookups: true,
          include_item_ref: true,
        })
      );
      if (getDataEmployeeExport.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.report_results.map((item) => ({
          employee_code: item.employee_code,
          name: item.name,
          kana: item.kana,
          email: item.email,
          password: item.password,
          department_name: item.department_name,
          position_code: item.position_code,
          birthday: item.birthday,
          hire_date: item.hire_date,
          role_code: item.role_code,
        }));

        const csvString = [
          HEADER_EMPLOYEE_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, '内部ユーザー.csv');
      }
    }
    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRow.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const handleResetForm = async () => {
    dispatch(startLoading());
    formik.resetForm();
    await dispatch(
      getDataBatchSetting({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'manage_code',
            order: 'asc',
          },
        ],
        page: page,
        per_page: 0,
        include_item_ref: true,
        include_lookups: true,
        use_display_id: true,
        omit_total_items: false,
      })
    );
    dispatch(stopLoading());
  };

  const handleToggleModalComplete = async () => {
    await setVisibleComplete((prevState) => !prevState);
    await setSelectedRow([]);
  };

  const formik = useFormik<Types.BatchSettingFormSearchFormik>({
    initialValues: {
      manage_code: '',
      name: '',
      department_name: '',
      position_code: '',
      grouping_code: '',
      skill_check_name: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value],
          });
        }
      });
      await dispatch(
        getDataBatchSetting({
          conditions,
          sort_fields: [
            {
              id: 'manage_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: 0,
          include_item_ref: true,
          include_lookups: true,
          use_display_id: true,
          omit_total_items: false,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSelectInterviewUser({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckGroupingCode({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, page, userInfo]);

  useEffect(() => {
    if (!userInfo) return;

    dispatch(
      getDataBatchSetting({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'manage_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
        include_item_ref: true,
        include_lookups: true,
        use_display_id: true,
        omit_total_items: false,
      })
    );
  }, [dispatch, userInfo]);

  return (
    <>
      <Header title={headerTitle} />
      <BatchSettingStyled collapsedMenu={collapsedMenu} isEmptyData={!dataBatchSetting.length}>
        <p className="text-note">
          面接ユーザーにスキルチェックを一括設定する画面です。
          <br />
          面接ユーザーに設定されたスキルチェックを確認する一覧画面としても活用できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
          >
            <div className="form-search">
              <Form.Item
                name="manage_code"
                className="item"
                label={<span className="text-label">管理番号</span>}
              >
                <SelectField
                  name="manage_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectInteviewUser?.map(({ code }, index) => (
                    <Option value={code} key={index}>
                      {code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">氏名</span>}
              >
                <SelectField
                  name="name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectInteviewUser?.map(({ name }, index) => (
                    <Option value={name} key={index}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="department_name"
                className="item"
                label={<span className="text-label">募集所属</span>}
              >
                <SelectField
                  name="department_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectDepartment?.map(({ i_id, name }, index) => (
                    <Option value={i_id} key={index}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="position_code"
                className="item"
                label={<span className="text-label">募集役職</span>}
              >
                <SelectField
                  name="position_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectPosition?.map(({ i_id, name }, index) => (
                    <Option value={i_id} key={index}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
            </div>
            <div className="wrap-form-under">
              <div className="wrap-select">
                <Form.Item
                  name="grouping_code"
                  className="item"
                  label={<span className="text-label">分析グループ</span>}
                >
                  <SelectField
                    name="grouping_code"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                  >
                    {dataSelectSkillCheckGroupingCode?.map(({ grouping_code }, index) => (
                      <Option value={grouping_code} key={index}>
                        {grouping_code}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="skill_check_name"
                  className="item"
                  label={<span className="text-label">スキルチェック</span>}
                >
                  <SelectField name="skill_check_name" showSearch allowClear>
                    {dataSelectSkillCheck?.map(({ name }, index) => (
                      <Option value={name} key={index}>
                        {name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              <div className="wrap-button-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={handleResetForm}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>

        <div className="wrap-table">
          <div className="wrap-select">
            <div className="text-count">
              {dataBatchSetting.length ? (page - 1) * perPage + 1 : ''}{' '}
              {dataBatchSetting.length ? '-' : ''}
              {page * perPage > dataBatchSetting.length
                ? dataBatchSetting.length
                : page * perPage}{' '}
              / {dataBatchSetting.length} 件
            </div>

            <div>
              <span className="label-select">表示件数：</span>
              <SelectAntd className="select-option" onChange={setPerPage} value={perPage}>
                {[100, 50, 20, 10].map((e) => (
                  <Option value={e}>{e}</Option>
                ))}
              </SelectAntd>
            </div>
          </div>
          <Table
            rowKey="i_id"
            className="table"
            dataSource={dataBatchSetting.map((item, index) => ({ ...item, index }))}
            columns={columns}
            rowSelection={{
              selectedRowKeys: selectedRow && selectedRow.map(({ i_id }) => i_id),
              onChange: (_, data) => setSelectedRow(data),
              getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
            }}
            pagination={{
              pageSize: perPage,
              total: dataBatchSetting.length,
              current: page,
              onChange: setPage,
              showSizeChanger: false,
              position: ['topCenter'],
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択したユーザを処理：
              <Button
                className="btn btn-active"
                onClick={() => selectedRow?.length > 0 && setVisibleModalCheckBatch(true)}
              >
                スキルチェック一括設定
              </Button>
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={() => navigate(routes.InterviewUser.path)}>
              <span>戻る</span>
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <UploadCSV
          onSubmit={handleImportCSV}
          visible={visiblePopupUploadCSV}
          setVisible={setVisiblePopupUploadCSV}
          headersTemplate={HEADER_EMPLOYEE_CSV}
          fileName="template_import_internal_user"
        />
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <SkillCheckBatchSetting
          visible={visibleModalCheckBatch}
          setVisible={setVisibleModalCheckBatch}
          setVisibleComplete={() => setVisibleComplete(true)}
          selectBatchRow={selectedRow}
        />
        <RegistrationComplete
          visible={visibleComplete}
          onCancle={handleToggleModalComplete}
          handleSubmit={handleToggleModalComplete}
        />
      </BatchSettingStyled>
    </>
  );
};

export default BatchSetting;
