import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading, toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { Background, NoImageSkillCheck } from 'assets';
import { getDataListSkillCheckImple } from '../thunk';
import { resetDataEdit } from '../slice';
import { useAppDispatch } from 'hooks';
import StatusStyled from './styles';
import * as Types from 'types';

interface Props {
  dataGuideline?: Types.ListSkillCheckTypes;
  setStatusIndex: React.Dispatch<React.SetStateAction<number>>;
  fileGuideline: string | undefined;
}

const Status: React.FC<Props> = ({ setStatusIndex, dataGuideline, fileGuideline }) => {
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSkillCheckSelection = async () => {
    if (!userInfo) return;
    dispatch(startLoading());
    await dispatch(
      getDataListSkillCheckImple({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
        ],
        sort_fields: [
          {
            id: 'skill_check_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  return (
    <StatusStyled>
      <img src={Background} className="background" alt="logo-background" />
      <div className="body-status">
        <div className="item">
          <div className="item-image">
            {fileGuideline ? (
              <div className="wrap-image">
                <img src={fileGuideline} className="image" alt="" />
              </div>
            ) : (
              <div className="wrap-image">
                <div className="wrap-no-image">
                  <img src={NoImageSkillCheck} alt="" />
                  <span className="text">no image</span>
                </div>
              </div>
            )}
          </div>
          <p className="title">{dataGuideline?.skill_check_name}&nbsp;&nbsp;終了</p>
          <p className="content">スキルチェックお疲れさまでした。</p>
          <div className="btn">
            <button
              onClick={() => {
                fetchSkillCheckSelection();
                dispatch(resetDataEdit());
                setStatusIndex(0);
              }}
              className="btn-status"
            >
              選択画面に戻る
            </button>
          </div>
        </div>
      </div>
    </StatusStyled>
  );
};

export default Status;
