import { createAsyncThunk } from '@reduxjs/toolkit';

import { AFFILIATION_ASSIGN_ROLE, POSITIONS } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getPositionMaster = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/getPositionMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchPositionMaster = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/searchPositionMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRankOrders = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/getRankOrders', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateItemPositionMaster = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.CreateEditPositionFormik>,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/updateItemPositionMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(POSITIONS.id, req);

    return { ...data, item: req };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createItemPositionMaster = createAsyncThunk<
  Types.CreateItemResponseType<Types.Positions.ResponseType>,
  Types.CreateItemRequestType<Types.Positions.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/createItemPositionMaster', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemPositionMaster = createAsyncThunk<
  string,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/deletePositionMaster', async (req, { rejectWithValue }) => {
  try {
    await services.delete(POSITIONS.id, req);

    return req.id;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getPositionMasterCSV = createAsyncThunk<
  Types.GetItemResponseType<Types.Positions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMaster/thunk/getPositionMasterCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Positions.ResponseType>(POSITIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRoleSetting = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('PositionMastes/thunk/getAffiliationAssignRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
