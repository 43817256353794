import React, { useMemo, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { trainingReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';
import WrapperStyled from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  username?: string;
  curriculum?: string;
  level1Code?: string;
  exportPdf?: boolean;
}

const SurfaceTable: React.FC<Props> = ({ curriculum, level1Code, exportPdf, username }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const { totalReportCurriculumUser, dataReportCurriculumUser } =
    useSelector(trainingReportSelector);

  const isUserTransTable = useMemo(
    () => !!username && !!curriculum && username === 'ALL' && curriculum !== 'ALL',
    [curriculum, username]
  );

  const columns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> = [
    {
      title: 'No.',
      dataIndex: '',
      key: '',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'index',
                align: 'center',
                width: '3%',
                render: (_text, _record, index) => (
                  <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
                    {index + 1 + (page - 1) * 10}
                  </span>
                ),
              },
            ]
          : undefined,
    },
    {
      title: isUserTransTable ? 'ユーザー名' : 'カリキュラム名',
      dataIndex: isUserTransTable ? 'user_name' : 'curriculum_name',
      key: isUserTransTable ? 'user_name' : 'curriculum_name',
      align: 'left',
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average br-1',
                title: 'ユーザー平均',
                dataIndex: isUserTransTable ? 'user_name' : 'curriculum_name',
                key: isUserTransTable ? 'user_name' : 'curriculum_name',
                align: 'left',
                width: '10%',
                ellipsis: true,
              },
            ]
          : undefined,
    },
    {
      title: '正解',
      dataIndex: 'correct_answers_num',
      key: 'correct_answers_num',
      sorter: (a, b) => a.correct_answers_num - b.correct_answers_num,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(dataReportCurriculumUser[0]?.correct_answers_num),
                dataIndex: 'correct_answers_num',
                key: 'correct_answers_num',
                align: 'right',
                width: '5%',
              },
            ]
          : undefined,
    },
    {
      title: '不正解',
      dataIndex: 'incorrect_answer',
      key: 'incorrect_answer',
      sorter: (a, b) => a.incorrect_answer - b.incorrect_answer,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(dataReportCurriculumUser[0]?.incorrect_answer),
                dataIndex: 'incorrect_answer',
                key: 'incorrect_answer',
                align: 'right',
                width: '6%',
              },
            ]
          : undefined,
    },
    {
      title: '未実施',
      dataIndex: 'inexperienced',
      key: 'inexperienced',
      sorter: (a, b) => a.inexperienced - b.inexperienced,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(dataReportCurriculumUser[0]?.inexperienced),
                dataIndex: 'inexperienced',
                key: 'inexperienced',
                align: 'right',
                width: '6%',
              },
            ]
          : undefined,
    },
    {
      title: '総回答数',
      dataIndex: 'responses_num',
      key: 'responses_num',
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(dataReportCurriculumUser[0]?.responses_num),
                dataIndex: 'responses_num',
                key: 'responses_num',
                align: 'right',
                width: '5%',
              },
            ]
          : undefined,
    },
    {
      title: '総設問数',
      dataIndex: 'question_count',
      key: 'question_count',
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: formatNumber(dataReportCurriculumUser[0]?.question_count),
                dataIndex: 'question_count',
                key: 'question_count',
                align: 'right',
                width: '5%',
              },
            ]
          : undefined,
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.accuracy_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'accuracy_rate',
                key: 'accuracy_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.wrong_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'wrong_rate',
                key: 'wrong_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '未実施率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.inexperienced_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'inexperienced_rate',
                key: 'inexperienced_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '実質正解率',
      dataIndex: 'real_accuracy_rate',
      key: 'real_accuracy_rate',
      sorter: (a, b) => a.real_accuracy_rate - b.real_accuracy_rate,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.real_accuracy_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'real_accuracy_rate',
                key: 'real_accuracy_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '進捗率',
      dataIndex: 'progress_rate',
      key: 'progress_rate',
      sorter: (a, b) => a.progress_rate - b.progress_rate,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.progress_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'progress_rate',
                key: 'progress_rate',
                align: 'center',
                width: '5%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '正解進捗率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
        ),
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: (
                  <span style={{ display: 'block', textAlign: 'right' }}>
                    {formatNumber(dataReportCurriculumUser[0]?.accuracy_rate * 100)}
                    <small style={{ color: '#999999' }}>%</small>
                  </span>
                ),
                dataIndex: 'accuracy_rate',
                key: 'accuracy_rate',
                align: 'center',
                width: '6%',
                render: (value) =>
                  value ? (
                    <span style={{ display: 'block', textAlign: 'right' }}>
                      {formatNumber(value * 100)}
                      <small style={{ color: '#999999' }}>%</small>
                    </span>
                  ) : (
                    <span style={{ display: 'block', textAlign: 'right' }}>0%</span>
                  ),
              },
            ]
          : undefined,
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      render: (value: string) => {
        return value ? (
          dayjs(value).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        );
      },
      sorter: (a, b) =>
        a.updatedat && b.updatedat
          ? new Date(a.updatedat).getTime() - new Date(b.updatedat).getTime()
          : -1,
      children:
        totalReportCurriculumUser && isUserTransTable
          ? [
              {
                className: 'average',
                title: '-',
                dataIndex: 'updatedat',
                key: 'updatedat',
                align: 'center',
                width: '7%',
                render: (value) =>
                  value ? (
                    dayjs(value).format('YYYY/MM/DD')
                  ) : (
                    <span style={{ color: '#F0330A' }}>未実施</span>
                  ),
              },
            ]
          : undefined,
    },
  ];

  return (
    <WrapperStyled>
      {totalReportCurriculumUser ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportCurriculumUser} /{totalReportCurriculumUser}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportCurriculumUser}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        bordered
        dataSource={
          exportPdf
            ? dataReportCurriculumUser.map((item, index) => ({ ...item, index })).slice(1)
            : dataReportCurriculumUser
                .map((item, index) => ({ ...item, index }))
                .slice(1)
                .slice((page - 1) * pageSize, page * pageSize)
        }
        columns={columns}
        pagination={false}
      />
    </WrapperStyled>
  );
};

export default SurfaceTable;
