import { createAsyncThunk } from '@reduxjs/toolkit';
import { intersectionBy } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { sharedFileInMinIO } from 'services/minioService';
import { services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  AFFILIATION_ASSIGN_LEVEL,
  ATTACH,
  COMPULSORY_CURRICULUM_IMPLEMENTATION_LIST,
  CURRICULUMS,
  CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2,
  CURRICULUM_IMPLE,
  CURRICULUM_TRANS,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  QUESTIONS,
  QUESTION_ASSIGN_LEVEL,
  QUESTION_TRANS2,
  SELECT_CURRICULUMS,
  SELECT_DEPARTMENT,
  SELECT_USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
} from 'configs';

export const getData = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getData', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

    const { authContainer } = getState() as RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataLevel1({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel2({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel3({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel4({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQuesLevel({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQues({
          conditions: [
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataCorrectQues({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataCurriculumTrans({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeTraining> = [];
    if (
      getDataLevel1.fulfilled.match(responseStatus[0]) &&
      getDataLevel2.fulfilled.match(responseStatus[1]) &&
      getDataLevel3.fulfilled.match(responseStatus[2]) &&
      getDataLevel4.fulfilled.match(responseStatus[3]) &&
      getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
      getDataQues.fulfilled.match(responseStatus[5]) &&
      getDataCorrectQues.fulfilled.match(responseStatus[6]) &&
      getDataCurriculumTrans.fulfilled.match(responseStatus[7])
    ) {
      const dataLevel1 = responseStatus[0].payload.items;
      const dataLevel2 = responseStatus[1].payload.items;
      const dataLevel3 = responseStatus[2].payload.items;
      const dataLevel4 = responseStatus[3].payload.items;
      const dataAssignLevel = responseStatus[4].payload.items;
      const dataQues = responseStatus[5].payload.items;
      const dataCorrectQues = responseStatus[6].payload.items;
      const dataCurriculumTrans = responseStatus[7].payload.items;
      resultArray = await Promise.all(
        data.items.flatMap(async (item) => {
          const url = item.fileID ? await sharedFileInMinIO(item.fileID) : undefined;

          const curriculum_trans_id = dataCurriculumTrans.find(
            (c) => c.curricullum_code === item.code
          )?.curriculum_trans_id;

          const i_id_item_ref_curriculum_trans = dataCurriculumTrans.find(
            (c) => c.curricullum_code === item.code
          )?.i_id;

          const baseObject = {
            ...item,
            curriculum_code: item.code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_file: url,
            curriculum_sort_order: Number(item.sort_order),
            curriculum_trans_id: curriculum_trans_id,
            i_id_item_ref_curriculum_trans: i_id_item_ref_curriculum_trans,
          };
          const matchedLevel1Items = dataLevel1.filter((c) => c.curricullum_code === item.code);

          if (!matchedLevel1Items.length) {
            return baseObject;
          }
          return matchedLevel1Items.flatMap((level1Item) => {
            const mergedObject1 = {
              ...baseObject,
              level1_i_id: level1Item.i_id,
              level1_name: level1Item.name,
              level1_sort_order: Number(level1Item.sort_order),
              level1_code: level1Item.code,
            };

            const matchedLevel2Items = dataLevel2.filter(
              (level2Item) => level2Item.level1_code === level1Item.code
            );

            if (!matchedLevel2Items.length) {
              return mergedObject1;
            }
            return matchedLevel2Items.flatMap((level2Item) => {
              const mergedObject2 = {
                ...mergedObject1,
                level2_i_id: level2Item.i_id,
                level2_name: level2Item.name,
                level2_sort_order: Number(level2Item.sort_order),
                level2_code: level2Item.code,
              };
              const matchedLevel3Items = dataLevel3.filter(
                (level3Item) => level3Item.level2_code === level2Item.code
              );
              if (!matchedLevel3Items.length) {
                return mergedObject2;
              }
              return matchedLevel3Items.flatMap((level3Item) => {
                const mergedObject3 = {
                  ...mergedObject2,
                  level3_i_id: level3Item.i_id,
                  level3_name: level3Item.name,
                  level3_sort_order: Number(level3Item.sort_order),
                  level3_code: level3Item.code,
                };
                const matchedLevel4Items = dataLevel4.filter(
                  (level4Item) => level4Item.level3_code === level3Item.code
                );

                if (!matchedLevel4Items.length) {
                  return mergedObject3;
                }

                return matchedLevel4Items.flatMap((level4Item) => {
                  const mergedObject4 = {
                    ...mergedObject3,
                    level4_i_id: level4Item.i_id,
                    level4_name: level4Item.name,
                    level4_sort_order: Number(level4Item.sort_order),
                    level4_code: level4Item.code,
                  };
                  const matchedQuestionItems = dataAssignLevel
                    .filter((que) => que.level4_code === level4Item.code)
                    .map((que) => ({
                      ...que,
                      id: que.id,
                      question_sort_order: Number(que.sort_order),
                    }));
                  if (!matchedQuestionItems.length) {
                    return mergedObject4;
                  }
                  const filteredItems = intersectionBy(dataQues, matchedQuestionItems, 'code');

                  const filteredItemsWithId = filteredItems.map((u) => {
                    const matchingItem = matchedQuestionItems.find(
                      (matchedItem) => matchedItem.code === u.code
                    );

                    if (matchingItem) {
                      return {
                        ...u,
                        id: matchingItem.id,
                        question_sort_order: Number(matchingItem.sort_order),
                      };
                    }

                    return u;
                  });

                  filteredItemsWithId.forEach((filteredItem) => {
                    const matchingCorrectQues = dataCorrectQues.find(
                      (correctQues) => correctQues.curriculum_assign_code === filteredItem.id
                    );
                    if (matchingCorrectQues) {
                      filteredItem.correct = matchingCorrectQues.correct;
                    }
                    return filteredItem;
                  });

                  if (!filteredItemsWithId.length) {
                    return mergedObject4;
                  }

                  return filteredItemsWithId.map((que) => ({
                    ...mergedObject4,
                    ...que,
                    question_code_i_id: que.i_id,
                    question_code: que.code,
                    question_name: que.name,
                    question_sort_order: Number(que.question_sort_order),
                  }));
                });
              });
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat().sort((a, b) => {
        if (a.curriculum_sort_order !== b.curriculum_sort_order) {
          return a.curriculum_sort_order - b.curriculum_sort_order;
        } else if (a.level1_sort_order !== b.level1_sort_order) {
          return a.level1_sort_order - b.level1_sort_order;
        } else if (a.level2_sort_order !== b.level2_sort_order) {
          return a.level2_sort_order - b.level2_sort_order;
        } else if (a.level3_sort_order !== b.level3_sort_order) {
          return a.level3_sort_order - b.level3_sort_order;
        } else if (a.level4_sort_order !== b.level4_sort_order) {
          return a.level4_sort_order - b.level4_sort_order;
        } else {
          return a.question_sort_order - b.question_sort_order;
        }
      });
    }
    dispatch(stopLoading());
    return {
      ...data,
      items: [...flattenedResultArray],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1s.ResponseType>(LEVEL_1.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2s.ResponseType>(LEVEL_2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3s.ResponseType>(LEVEL_3.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4s.ResponseType>(LEVEL_4.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCorrectQues = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionTrans2.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataCorrectQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionTrans2.ResponseType>(
      QUESTION_TRANS2.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserAssignCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignRequiredCurriculum.ResponseType>(
      USER_ASSIGN_REQUIRED_CURRICULUM.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumTrans = createAsyncThunk<
  Types.GetItemResponseType<Types.CurriculumTrans.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataCurriculumTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.CurriculumTrans.ResponseType>(
      CURRICULUM_TRANS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRequired = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'TrainingCurriculum/thunk/getDataRequired',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

      const { authContainer } = getState() as RootState;

      const responseStatus = await Promise.all([
        dispatch(
          getDataLevel1({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel2({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel3({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataLevel4({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQuesLevel({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataCorrectQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataUserAssignCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataCurriculumTrans({
            conditions: [
              {
                id: 'company_id',
                search_value: [authContainer?.userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [authContainer?.userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_item_ref: true,
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      let resultArray: Array<any> = [];
      let flattenedResultArray: Array<Types.TreeTraining> = [];
      if (
        getDataLevel1.fulfilled.match(responseStatus[0]) &&
        getDataLevel2.fulfilled.match(responseStatus[1]) &&
        getDataLevel3.fulfilled.match(responseStatus[2]) &&
        getDataLevel4.fulfilled.match(responseStatus[3]) &&
        getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
        getDataQues.fulfilled.match(responseStatus[5]) &&
        getDataCorrectQues.fulfilled.match(responseStatus[6]) &&
        getDataUserAssignCurriculum.fulfilled.match(responseStatus[7]) &&
        getDataCurriculumTrans.fulfilled.match(responseStatus[8])
      ) {
        const dataLevel1 = responseStatus[0].payload.items;
        const dataLevel2 = responseStatus[1].payload.items;
        const dataLevel3 = responseStatus[2].payload.items;
        const dataLevel4 = responseStatus[3].payload.items;
        const dataAssignLevel = responseStatus[4].payload.items;
        const dataQues = responseStatus[5].payload.items;
        const dataCorrectQues = responseStatus[6].payload.items;
        const dataUserAssign = responseStatus[7].payload.items;
        const dataCurriculumTrans = responseStatus[8].payload.items;

        const filteredDataArray = data.items.filter((r) => {
          return dataUserAssign.some((user) => user.curricullum_code === r.code);
        });

        resultArray = await Promise.all(
          filteredDataArray.flatMap(async (item) => {
            const url = item.fileID ? await sharedFileInMinIO(item.fileID) : undefined;

            const curriculum_trans_id = dataCurriculumTrans.find(
              (c) => c.curricullum_code === item.code
            )?.curriculum_trans_id;

            const i_id_item_ref_curriculum_trans = dataCurriculumTrans.find(
              (c) => c.curricullum_code === item.code
            )?.i_id;

            const baseObject = {
              ...item,
              curriculum_code: item.code,
              curriculum_name: item.name,
              curriculum_description: item.description,
              curriculum_file: url,
              curriculum_sort_order: Number(item.sort_order),
              curriculum_trans_id: curriculum_trans_id,
              i_id_item_ref_curriculum_trans: i_id_item_ref_curriculum_trans,
            };
            const matchedLevel1Items = dataLevel1.filter((c) => c.curricullum_code === item.code);

            if (!matchedLevel1Items.length) {
              return baseObject;
            }
            return matchedLevel1Items.flatMap((level1Item) => {
              const mergedObject1 = {
                ...baseObject,
                level1_i_id: level1Item.i_id,
                level1_name: level1Item.name,
                level1_code: level1Item.code,
                level1_sort_order: Number(level1Item.sort_order),
              };

              const matchedLevel2Items = dataLevel2.filter(
                (level2Item) => level2Item.level1_code === level1Item.code
              );

              if (!matchedLevel2Items.length) {
                return mergedObject1;
              }
              return matchedLevel2Items.flatMap((level2Item) => {
                const mergedObject2 = {
                  ...mergedObject1,
                  level2_i_id: level2Item.i_id,
                  level2_name: level2Item.name,
                  level2_code: level2Item.code,
                  level2_sort_order: Number(level2Item.sort_order),
                };
                const matchedLevel3Items = dataLevel3.filter(
                  (level3Item) => level3Item.level2_code === level2Item.code
                );
                if (!matchedLevel3Items.length) {
                  return mergedObject2;
                }
                return matchedLevel3Items.flatMap((level3Item) => {
                  const mergedObject3 = {
                    ...mergedObject2,
                    level3_i_id: level3Item.i_id,
                    level3_name: level3Item.name,
                    level3_code: level3Item.code,
                    level3_sort_order: Number(level3Item.sort_order),
                  };
                  const matchedLevel4Items = dataLevel4.filter(
                    (level4Item) => level4Item.level3_code === level3Item.code
                  );

                  if (!matchedLevel4Items.length) {
                    return mergedObject3;
                  }

                  return matchedLevel4Items.flatMap((level4Item) => {
                    const mergedObject4 = {
                      ...mergedObject3,
                      level4_i_id: level4Item.i_id,
                      level4_name: level4Item.name,
                      level4_code: level4Item.code,
                      level4_sort_order: Number(level4Item.sort_order),
                    };
                    const matchedQuestionItems = dataAssignLevel
                      .filter((que) => que.level4_code === level4Item.code)
                      .map((que) => ({
                        ...que,
                        id: que.id,
                        question_sort_order: Number(que.sort_order),
                      }));
                    if (!matchedQuestionItems.length) {
                      return mergedObject4;
                    }
                    const filteredItems = intersectionBy(dataQues, matchedQuestionItems, 'code');

                    const filteredItemsWithId = filteredItems.map((u) => {
                      const matchingItem = matchedQuestionItems.find(
                        (matchedItem) => matchedItem.code === u.code
                      );

                      if (matchingItem) {
                        return {
                          ...u,
                          id: matchingItem.id,
                          question_sort_order: Number(matchingItem.sort_order),
                        };
                      }

                      return u;
                    });

                    filteredItemsWithId.forEach((filteredItem) => {
                      const matchingCorrectQues = dataCorrectQues.find(
                        (correctQues) => correctQues.curriculum_assign_code === filteredItem.id
                      );
                      if (matchingCorrectQues) {
                        filteredItem.correct = matchingCorrectQues.correct;
                      }
                      return filteredItem;
                    });

                    return filteredItemsWithId.map((que) => ({
                      ...mergedObject4,
                      ...que,
                      question_code_i_id: que.i_id,
                      question_code: que.code,
                      question_name: que.name,
                      question_sort_order: Number(que.question_sort_order),
                    }));
                  });
                });
              });
            });
          })
        );

        flattenedResultArray = resultArray.flat().sort((a, b) => {
          if (a.curriculum_sort_order !== b.curriculum_sort_order) {
            return a.curriculum_sort_order - b.curriculum_sort_order;
          } else if (a.level1_sort_order !== b.level1_sort_order) {
            return a.level1_sort_order - b.level1_sort_order;
          } else if (a.level2_sort_order !== b.level2_sort_order) {
            return a.level2_sort_order - b.level2_sort_order;
          } else if (a.level3_sort_order !== b.level3_sort_order) {
            return a.level3_sort_order - b.level3_sort_order;
          } else if (a.level4_sort_order !== b.level4_sort_order) {
            return a.level4_sort_order - b.level4_sort_order;
          } else {
            return a.question_sort_order - b.question_sort_order;
          }
        });
      }
      return {
        ...data,
        items: [...flattenedResultArray],
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataAffiliation = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getDataAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignLevel.ResponseType>(
      AFFILIATION_ASSIGN_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getOptionCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_CURRICULUMS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionUsers = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectUsers.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getOptionUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectUsers.ResponseType>(SELECT_USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getOptionDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createCurriculumTrans = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.CurriculumTrans.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/createCurriculumTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(CURRICULUM_TRANS.id, {
      ...req,
      return_item_result: true,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAttachQuestionFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getAttachQuestionFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getFileAttach = createAsyncThunk<
  ArrayBuffer,
  Types.GetFileRequestType,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/getFileAttach', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.getFile(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestionTrans = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/createQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateQuestionTrans = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.QuestionTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/updateQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(QUESTION_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editCurriculumTrans = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.CurriculumTrans.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('TrainingCurriculum/thunk/editCurriculumTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(CURRICULUM_TRANS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumImple = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Training/thunk/getCurriculumImple', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.CurriculumImple.ResponseType>(
      CURRICULUM_IMPLE.id,
      req
    );
    const dataConvert = await Promise.all(
      data.report_results.map(async (val) => {
        const obj = val;

        if (
          val.question_attach_fileID1 ||
          val.question_attach_fileID2 ||
          val.question_attach_fileID3
        ) {
          const question_attach_files = await Promise.all(
            [
              val.question_attach_fileID1,
              val.question_attach_fileID2,
              val.question_attach_fileID3,
            ].map(async (e) => {
              if (!e) return;
              const nodeFileUrl = await sharedFileInMinIO(e);

              return nodeFileUrl;
            })
          );

          Object.assign(obj, {
            ...val,
            question_attach_files: question_attach_files.filter(Boolean),
          });
        }

        if (
          val.comment_attach_fileID1 ||
          val.comment_attach_fileID2 ||
          val.comment_attach_fileID3
        ) {
          const comment_attach_files = await Promise.all(
            [
              val.comment_attach_fileID1,
              val.comment_attach_fileID2,
              val.comment_attach_fileID3,
            ].map(async (e) => {
              if (!e) return;
              const nodeFileUrl = await sharedFileInMinIO(e);

              return nodeFileUrl;
            })
          );

          Object.assign(obj, {
            ...val,
            comment_attach_files: comment_attach_files.filter(Boolean),
          });
        }

        if (val.problems1_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems1_attach_fileID);
          Object.assign(obj, {
            problems1_attach_file: nodeFileUrl,
          });
        }

        if (val.problems2_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems2_attach_fileID);
          Object.assign(obj, {
            problems2_attach_file: nodeFileUrl,
          });
        }
        if (val.problems3_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems3_attach_fileID);
          Object.assign(obj, {
            problems3_attach_file: nodeFileUrl,
          });
        }

        return obj;
      })
    );
    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCompulsoryCurriculumImple = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Training/thunk/getDataCompulsoryCurriculumImple', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.CurriculumImple.ResponseType>(
      COMPULSORY_CURRICULUM_IMPLEMENTATION_LIST.id,
      req
    );
    const dataConvert = await Promise.all(
      data.report_results.map(async (val) => {
        const obj = val;

        if (
          val.question_attach_fileID1 ||
          val.question_attach_fileID2 ||
          val.question_attach_fileID3
        ) {
          const question_attach_files = await Promise.all(
            [
              val.question_attach_fileID1,
              val.question_attach_fileID2,
              val.question_attach_fileID3,
            ].map(async (e) => {
              if (!e) return;
              const nodeFileUrl = await sharedFileInMinIO(e);

              return nodeFileUrl;
            })
          );

          Object.assign(obj, {
            ...val,
            question_attach_files: question_attach_files.filter(Boolean),
          });
        }

        if (val.problems1_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems1_attach_fileID);
          Object.assign(obj, {
            problems1_attach_file: nodeFileUrl,
          });
        }

        if (val.problems2_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems2_attach_fileID);
          Object.assign(obj, {
            problems2_attach_file: nodeFileUrl,
          });
        }
        if (val.problems3_attach_fileID) {
          const nodeFileUrl = await sharedFileInMinIO(val.problems3_attach_fileID);
          Object.assign(obj, {
            problems3_attach_file: nodeFileUrl,
          });
        }

        return obj;
      })
    );
    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestionImplement = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumHierarchyTran.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Training/thunk/getDataQuestionImplement', async (req, { rejectWithValue, dispatch }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.filter<Types.CurriculumHierarchyTran.ResponseType>(
      CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2.id,
      req
    );
    dispatch(stopLoading());
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addLinkQuestion = createAsyncThunk<
  Types.AddLinkItemResponseType,
  any,
  Types.ThunkAPI<Types.requestError>
>('Training/thunk/addLinkQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addLink(QUESTION_ASSIGN_LEVEL.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
