import React, { useEffect, useMemo, useState } from 'react';
import type { SubMenuType } from 'rc-menu/lib/interface';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Layout, Menu } from 'antd';
import {
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  FolderOpenOutlined,
  EditFilled,
  ReadOutlined,
  MessageOutlined,
  GlobalOutlined,
  SettingFilled,
  BarChartOutlined,
} from '@ant-design/icons';

import { setCollapsed, setHeaderTitle } from 'containers/AppSettings/slice';
import { resetInitialState } from 'pages/Settings/Curriculum/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { LogoNav, IconMenuLeft, LogoSmallCollapse } from 'assets';
import AlertCustom, { alertCustomRef } from 'components/Alert';
import { updateOpenModal } from '../../containers/Auth/slice';
import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import { resetOptionModal } from 'pages/Dashboard/slice';
import { authSelector } from 'containers/Auth/selectors';
import Loading, { loadingRef } from 'components/Loading';
import { Wrapper, StyleMenuTooltip } from './styles';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';

const { Sider } = Layout;

interface Props {
  accessDenied?: boolean;
  title?: string;
}

const SideBar: React.FC<Props> = ({ children, title, accessDenied }) => {
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  const { collapsedMenu, hiddenSidebar, loading } = useSelector(settingSelector);
  const { dataCompany } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const location = window.location.pathname;
  const navigateAndDispatchReset = (path: string) => {
    navigate(path);
    if (path !== routes.Curriculum.path) {
      dispatch(resetInitialState());
    }
    if (window.location.pathname !== routes.UsageStatus.path) {
      dispatch(updateOpenModal(true));
    }
  };

  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const toggle = () => dispatch(setCollapsed(!collapsedMenu));
  const { userInfo } = useSelector(authSelector);

  const roleCode = useMemo(() => userInfo?.lookup_items?.role_code, [userInfo]);

  const checkPermission = (permission: number | string | undefined) =>
    permission && permission.toString() !== '0';

  const disableSetting =
    checkPermission(roleCode?.curricullum_master_permission) &&
    checkPermission(roleCode?.question_master_permission) &&
    checkPermission(roleCode?.skill_check_master_permission) &&
    checkPermission(roleCode?.inter_users_master_permission) &&
    checkPermission(roleCode?.groups_master_permission) &&
    checkPermission(roleCode?.roles_master_permission) &&
    checkPermission(roleCode?.departments_master_permission) &&
    checkPermission(roleCode?.positions_master_permission) &&
    checkPermission(roleCode?.users_master_permission) &&
    checkPermission(roleCode?.report_view_permission) &&
    checkPermission(roleCode?.admin_master_permission);

  const menuTooltipReports = (
    <StyleMenuTooltip>
      <p className="title">レポート</p>
      <p
        className={`${
          [routes.TrainingReport.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.TrainingReport.path)}
      >
        - トレーニングレポート
      </p>
      <p
        className={`${
          [routes.SkillCheckReport.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.SkillCheckReport.path)}
      >
        - スキルチェックレポート
      </p>
      <p
        className={`${
          [routes.MyChartDashboard.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.MyChartDashboard.path)}
      >
        - MY カルテ
      </p>
    </StyleMenuTooltip>
  );
  const menuTooltipManuals = (
    <StyleMenuTooltip>
      <p className="title">マニュアル</p>
      <p
        className={`${[routes.Manual.path].includes(location) ? 'sub-menu-active' : 'sub-menu'}`}
        onClick={() => navigateAndDispatchReset(routes.Manual.path)}
      >
        - 全てのマニュアル
      </p>
      <p
        className={`${[routes.MyManual.path].includes(location) ? 'sub-menu-active' : 'sub-menu'}`}
        onClick={() => navigateAndDispatchReset(routes.MyManual.path)}
      >
        - 自分のマニュアル
      </p>
    </StyleMenuTooltip>
  );

  const menuTooltipKnowledge = (
    <StyleMenuTooltip>
      <p className="title">ナレッジ</p>
      <p
        className={`${
          [routes.KnowledgeTop.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.KnowledgeTop.path)}
      >
        - 全てのナレッジ
      </p>
      <p
        className={`${
          [routes.KnowledgeFavorite.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.KnowledgeFavorite.path)}
      >
        - お気に入り
      </p>
      <p
        className={`${
          [routes.KnowledgeCreatedBy.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.KnowledgeCreatedBy.path)}
      >
        - 自分が作成したナレッジ
      </p>
      <p
        className={`${
          [routes.KnowledgeToLoginId.path].includes(location) ? 'sub-menu-active' : 'sub-menu'
        }`}
        onClick={() => navigateAndDispatchReset(routes.KnowledgeToLoginId.path)}
      >
        - To 自分
      </p>
    </StyleMenuTooltip>
  );

  const menuTooltipSetting = (
    <StyleMenuTooltip>
      <p className="title">マスタ管理</p>
      <p className="training sub-menu">
        ・トレーニング
        <p className="dropdown-content">
          <p
            className={`${
              [routes.Curriculum.path].includes(location)
                ? 'sub-menu-active-training title-training'
                : 'sub-menu-training title-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.Curriculum.path)}
          >
            - カリキュラムマスタ
          </p>
          <p
            className={`${
              [routes.QuestionMasterPage.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.QuestionMasterPage.path)}
          >
            - 設問マスタ
          </p>
        </p>
      </p>
      <p className="training sub-menu">
        ・スキルチェック
        <p className="dropdown-content">
          <p
            className={`${
              [routes.SkillCheck.path].includes(location)
                ? 'sub-menu-active-training title-training'
                : 'sub-menu-training title-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.SkillCheck.path)}
          >
            - スキルチェックマスタ
          </p>
          <p
            className={`${
              [routes.InterviewUser.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.InterviewUser.path)}
          >
            - 面接ユーザーマスタ
          </p>
          <p
            className={`${
              [routes.AnalysisGroup.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.AnalysisGroup.path)}
          >
            - 分析グループマスタ
          </p>
        </p>
      </p>
      <p className="training sub-menu">
        ・ユーザーマスタ
        <p className="dropdown-content">
          <p
            className={`${
              [routes.RolesMaster.path].includes(location)
                ? 'sub-menu-active-training title-training'
                : 'sub-menu-training title-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.RolesMaster.path)}
          >
            - 権限マスタ
          </p>
          <p
            className={`${
              [routes.AffiliationMaster.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.AffiliationMaster.path)}
          >
            - 所属マスタ
          </p>
          <p
            className={`${
              [routes.PositionMaster.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.PositionMaster.path)}
          >
            - 役職マスタ
          </p>
          <p
            className={`${
              [routes.Employee.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.Employee.path)}
          >
            - 社内ユーザーマスタ
          </p>
          <p
            className={`${
              [routes.AdministratorMaster.path].includes(location)
                ? 'sub-menu-active-training'
                : 'sub-menu-training'
            }`}
            onClick={() => navigateAndDispatchReset(routes.AdministratorMaster.path)}
          >
            - 管理者マスタ
          </p>
        </p>
      </p>
      <p
        className={`${
          [routes.PaymentMaster.path].includes(location)
            ? 'sub-menu-active title-training'
            : 'sub-menu title-training'
        }`}
        onClick={() => navigateAndDispatchReset(routes.PaymentMaster.path)}
      >
        ・支払マスタ
      </p>
    </StyleMenuTooltip>
  );

  const items: ItemType[] = [
    {
      key: routes.Dashboard.path,
      icon: <HomeOutlined />,
      label: <span className="title-submenu">HOME</span>,
      onClick: () => navigateAndDispatchReset(routes.Dashboard.path),
    },

    {
      key: routes.TrainingCurriculum.path,
      icon: <ReadOutlined />,
      label: <span className="title-submenu">トレーニング</span>,
      onClick: () => navigateAndDispatchReset(routes.TrainingCurriculum.path),
    },
    {
      key: routes.SkillCheckSelection.path,
      icon: <EditFilled />,
      label: 'スキルチェック',
      onClick: () => navigateAndDispatchReset(routes.SkillCheckSelection.path),
    },

    ...(checkPermission(roleCode?.training_report_permission) ||
    checkPermission(roleCode?.skill_check_report_permission) ||
    checkPermission(roleCode?.my_chart_permission)
      ? [
          collapsedMenu
            ? {
                key: 'reports',
                icon: <BarChartOutlined />,
                label: menuTooltipReports,
              }
            : {
                key: 'reports',
                icon: <BarChartOutlined />,
                label: 'レポート',
                children: [
                  ...(checkPermission(roleCode?.training_report_permission)
                    ? [
                        {
                          key: routes.TrainingReport.path,
                          label: '-   トレーニングレポート',
                          onClick: () => navigateAndDispatchReset(routes.TrainingReport.path),
                        },
                      ]
                    : []),
                  ...(checkPermission(roleCode?.skill_check_report_permission)
                    ? [
                        {
                          key: routes.SkillCheckReport.path,
                          label: '-   スキルチェックレポート',
                          onClick: () => navigateAndDispatchReset(routes.SkillCheckReport.path),
                        },
                      ]
                    : []),
                  ...(checkPermission(roleCode?.my_chart_permission)
                    ? [
                        {
                          key: routes.MyChartDashboard.path,
                          label: '-   MY カルテ',
                          onClick: () => navigateAndDispatchReset(routes.MyChartDashboard.path),
                        },
                      ]
                    : []),
                ],
              },
        ]
      : []),

    ...(checkPermission(roleCode?.manual_permission)
      ? [
          collapsedMenu
            ? {
                key: 'manuals',
                icon: <FolderOpenOutlined />,
                label: menuTooltipManuals,
              }
            : {
                key: 'manuals',
                icon: <FolderOpenOutlined />,
                label: 'マニュアル',
                children: [
                  {
                    label: '-   全てのマニュアル',
                    key: routes.Manual.path,
                    onClick: () => navigateAndDispatchReset(routes.Manual.path),
                  },
                  {
                    label: '-   自分のマニュアル',
                    key: routes.MyManual.path,
                    onClick: () => navigateAndDispatchReset(routes.MyManual.path),
                  },
                ],
              },
        ]
      : []),
    ...(checkPermission(roleCode?.knowledge_permission)
      ? [
          collapsedMenu
            ? {
                key: 'knowledge',
                icon: <MessageOutlined />,
                label: menuTooltipKnowledge,
              }
            : {
                key: 'knowledge',
                icon: <MessageOutlined />,
                label: 'ナレッジ',
                children: [
                  {
                    label: '-   全てのナレッジ',
                    key: routes.KnowledgeTop.path,
                    onClick: () => navigateAndDispatchReset(routes.KnowledgeTop.path),
                  },
                  {
                    label: '-   お気に入り',
                    key: routes.KnowledgeFavorite.path,
                    onClick: () => navigateAndDispatchReset(routes.KnowledgeFavorite.path),
                  },
                  {
                    label: '-   自分が作成したナレッジ',
                    key: routes.KnowledgeCreatedBy.path,
                    onClick: () => navigateAndDispatchReset(routes.KnowledgeCreatedBy.path),
                  },
                  {
                    label: '-   To 自分',
                    key: routes.KnowledgeToLoginId.path,
                    onClick: () => navigateAndDispatchReset(routes.KnowledgeToLoginId.path),
                  },
                ],
              },
        ]
      : []),
    ...(checkPermission(roleCode?.official_curriculum_permission)
      ? [
          {
            key: routes.OfficialCurriculum.path,
            icon: <GlobalOutlined />,
            label: (
              <span>
                <p className="lable-official">OFFICIAL</p>
                <p className="lable-official">カリキュラム</p>
              </span>
            ),
            onClick: () => navigateAndDispatchReset(routes.OfficialCurriculum.path),
          },
        ]
      : []),
    {
      type: 'divider',
      style: {
        display:
          disableSetting ||
          dataCompany?.plan_id === 'standard' ||
          dataCompany?.plan_id === 'premium'
            ? 'block'
            : 'none',
        marginTop: 12,
        marginBottom: collapsedMenu ? 12 : 20,
        borderWidth: '1px 0 0',
        borderColor: 'rgba(255, 255, 255, 0.25)',
        transition: 'margin-bottom 0.3s',
      },
    },

    {
      key: 'settings',
      className: `sub-menu-setting ${!collapsedMenu ? 'collapse' : ''}`,
      icon: collapsedMenu ? (
        <span>
          <SettingFilled />
        </span>
      ) : undefined,
      type: !collapsedMenu ? 'group' : undefined,
      label: !collapsedMenu ? (
        <>
          <SettingFilled className="icon" /> マスタ管理
        </>
      ) : (
        menuTooltipSetting
      ),
    },

    ...(collapsedMenu
      ? []
      : [
          ...(checkPermission(roleCode?.curricullum_master_permission) ||
          checkPermission(roleCode?.question_master_permission)
            ? [
                {
                  key: 'training',
                  label: '・トレーニング',
                  className: 'submenu-setting',
                  children: [
                    ...(checkPermission(roleCode?.curricullum_master_permission)
                      ? [
                          {
                            label: '- カリキュラムマスタ',
                            key: routes.Curriculum.path,
                            onClick: () => navigateAndDispatchReset(routes.Curriculum.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.question_master_permission)
                      ? [
                          {
                            label: '- 設問マスタ',
                            key: routes.QuestionMasterPage.path,
                            onClick: () => navigateAndDispatchReset(routes.QuestionMasterPage.path),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(checkPermission(roleCode?.skill_check_master_permission) ||
          checkPermission(roleCode?.inter_users_master_permission) ||
          checkPermission(roleCode?.groups_master_permission)
            ? [
                {
                  key: 'skill-check',
                  label: '・スキルチェック',
                  className: 'submenu-setting',
                  children: [
                    ...(checkPermission(roleCode?.skill_check_master_permission)
                      ? [
                          {
                            label: '- スキルチェックマスタ',
                            key: routes.SkillCheck.path,
                            onClick: () => navigateAndDispatchReset(routes.SkillCheck.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.inter_users_master_permission)
                      ? [
                          {
                            label: '- 面接ユーザーマスタ',
                            key: routes.InterviewUser.path,
                            onClick: () => navigateAndDispatchReset(routes.InterviewUser.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.groups_master_permission)
                      ? [
                          {
                            label: '- 分析グループマスタ',
                            key: routes.AnalysisGroup.path,
                            onClick: () => navigateAndDispatchReset(routes.AnalysisGroup.path),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(checkPermission(roleCode?.roles_master_permission) ||
          checkPermission(roleCode?.departments_master_permission) ||
          checkPermission(roleCode?.positions_master_permission) ||
          checkPermission(roleCode?.users_master_permission) ||
          checkPermission(roleCode?.admin_master_permission)
            ? [
                {
                  key: 'user-master',
                  label: '・ユーザーマスタ',
                  className: 'submenu-setting',
                  children: [
                    ...(checkPermission(roleCode?.roles_master_permission)
                      ? [
                          {
                            label: '- 権限マスタ',
                            key: routes.RolesMaster.path,
                            onClick: () => navigateAndDispatchReset(routes.RolesMaster.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.departments_master_permission)
                      ? [
                          {
                            label: '- 所属マスタ',
                            key: routes.AffiliationMaster.path,
                            onClick: () => navigateAndDispatchReset(routes.AffiliationMaster.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.positions_master_permission)
                      ? [
                          {
                            label: '- 役職マスタ',
                            key: routes.PositionMaster.path,
                            onClick: () => navigateAndDispatchReset(routes.PositionMaster.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.users_master_permission)
                      ? [
                          {
                            label: '- 社内ユーザーマスタ',
                            key: routes.Employee.path,
                            onClick: () => navigateAndDispatchReset(routes.Employee.path),
                          },
                        ]
                      : []),
                    ...(checkPermission(roleCode?.admin_master_permission)
                      ? [
                          {
                            label: '- 管理者マスタ',
                            key: routes.AdministratorMaster.path,
                            onClick: () =>
                              navigateAndDispatchReset(routes.AdministratorMaster.path),
                          },
                        ]
                      : []),
                  ],
                },
              ]
            : []),
          ...(checkPermission(roleCode?.payment_master_permission)
            ? [
                {
                  key: 'payment-master',
                  label: '支払マスタ',
                  onClick: () => navigateAndDispatchReset(routes.PaymentMaster.path),
                },
              ]
            : []),
          {
            type: 'divider',
            style: {
              display:
                disableSetting ||
                dataCompany?.plan_id === 'standard' ||
                dataCompany?.plan_id === 'premium'
                  ? 'block'
                  : 'none',
              marginTop: 20,
              marginBottom: collapsedMenu ? 25 : 15,
              borderWidth: '1px 0 0',
              borderColor: 'rgba(255, 255, 255, 0.25)',
              transition: 'margin-bottom 0.3s',
            },
          } as ItemType,
        ]),
    ...(checkPermission(roleCode?.report_view_permission) || dataCompany?.plan_id === 'premium'
      ? [
          {
            key: routes.UsageStatus.path,
            icon: (
              <img
                src={IconMenuLeft}
                alt="icon"
                className={`${collapsedMenu ? 'icon-menu-collapse' : 'icon-menu-left'}`}
              />
            ),
            onClick: () => navigateAndDispatchReset(routes.UsageStatus.path),
            label: <span className="title-home">ご利用状況</span>,
          },
        ]
      : dataCompany?.plan_id === 'standard'
      ? [
          {
            key: routes.UsageStatus.path,
            icon: (
              <img
                src={IconMenuLeft}
                alt="icon"
                className={`${collapsedMenu ? 'icon-menu-collapse' : 'icon-menu-left'}`}
              />
            ),
            onClick: () =>
              userInfo?.login_id === dataCompany?.admin_email
                ? dispatch(resetOptionModal({ skillCheckMenu: true, skillCheck: true }))
                : navigateAndDispatchReset(routes.UsageStatus.path),
            label: (
              <span className="title-home">
                {userInfo?.login_id === dataCompany?.admin_email && '無料トライアル詳細'}
              </span>
            ),
          },
        ]
      : []),
  ];

  useEffect(() => {
    const openKey = items.find((item) =>
      (item as SubMenuType).children?.some(
        (c) => (c as SubMenuType).key === `/${window.location.pathname.split('/')[1]}`
      )
    )?.key as string;
    setOpenKeys(openKey ? [openKey] : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsedMenu]);

  useEffect(() => {
    if (!title) return;
    dispatch(setHeaderTitle(title));
  }, [dispatch, title]);

  useEffect(() => {
    loadingRef.current?.isLoading(accessDenied ? false : loading);
  }, [accessDenied, loading]);

  return (
    <Wrapper
      disableSetting={disableSetting}
      collapsedMenu={collapsedMenu}
      dataCompany={dataCompany?.plan_id}
    >
      <Sider
        width={MENU_WIDTH}
        className="sider"
        trigger={null}
        collapsedWidth={MENU_COLLAPSED_WIDTH}
        collapsible
        collapsed={collapsedMenu}
        hidden={hiddenSidebar}
      >
        <div className="site-layout-background">
          {collapsedMenu ? (
            <MenuUnfoldOutlined className="trigger" onClick={toggle} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={toggle} />
          )}
        </div>
        <div className="wrap-logo">
          <img
            src={collapsedMenu ? LogoSmallCollapse : LogoNav}
            className={collapsedMenu ? 'image-logo' : 'image-logo-large'}
            onClick={() =>
              checkPermission(roleCode?.admin_dashboard_permission)
                ? navigate(routes.Dashboard.path)
                : null
            }
            alt="skill-familiar"
          />
        </div>
        <Menu
          className="menu"
          mode="inline"
          openKeys={openKeys}
          selectedKeys={[window.location.pathname, `/${window.location.pathname.split('/')[1]}`]}
          onOpenChange={onOpenChange}
          items={items}
          inlineIndent={18}
        />
      </Sider>
      <Layout>
        {children}
        <Loading ref={loadingRef} />
        <AlertCustom ref={alertCustomRef} />
      </Layout>
    </Wrapper>
  );
};

export default SideBar;
