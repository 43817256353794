import React, { useEffect, useRef, useCallback } from 'react';

import { Background, Mark } from 'assets';
import SectionStyled from './styles';

const AuthLayout: React.FC = ({ children }) => {
  const requestRef = useRef<number>();

  const animate = useCallback(
    (
      ctx: CanvasRenderingContext2D,
      canvas: HTMLCanvasElement,
      img: HTMLImageElement,
      x: number,
      y: number,
      dx: number,
      dy: number,
      angle: number
    ) => {
      ctx.save();
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.translate(x + img.width, y + img.height);
      ctx.rotate(angle);
      ctx.globalAlpha = 0.4;
      ctx.drawImage(img, -img.width / 2, -img.height / 2, img.width, img.height);
      ctx.restore();

      if (x + dx > canvas.width - 150 || x + dx < -100) {
        dx = -dx;
      }
      if (y + dy > canvas.height - 100 || y + dy < -50) {
        dy = -dy;
      }
      x += dx;
      y += dy;
      angle += 0.03;
      requestRef.current = requestAnimationFrame(() =>
        animate(ctx, canvas, img, x, y, dx, dy, angle)
      );
    },
    []
  );

  useEffect(() => {
    const canvas = document.getElementById('myCanvas') as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (!ctx || !canvas) return;
    const x = 65;
    const y = 800;
    const dx = 2;
    const dy = -2;
    const angle = 0;
    const img = new Image(120, 74);
    img.src = Mark;
    requestRef.current = requestAnimationFrame(() =>
      animate(ctx, canvas, img, x, y, dx, dy, angle)
    );

    return () => {
      cancelAnimationFrame(requestRef.current!);
    };
  }, [animate]);

  return (
    <SectionStyled>
      <img src={Background} className="background" alt="logo-background" />
      <canvas
        id="myCanvas"
        width={window.innerWidth}
        height={window.innerHeight}
        className="canvas"
      />
      <div className="container">{children}</div>
    </SectionStyled>
  );
};

export default AuthLayout;
