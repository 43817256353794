import styled from 'styled-components';

export const Wrapper = styled.div`
  .remind h3 {
    font-size: 16px;
    color: #777777;
    text-align: center;
  }
  .card-plan {
    justify-content: center;
    margin: 20px;
    .card {
      width: 340px;
      margin: 0 10px;
      background: #ffffff;
      box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      transition: opacity 0.2s;
      transition: transform 0.2s;
      .category-plan {
        position: relative;
        font-family: Lato;
        font-size: 16px;
        text-align: center;
        color: #00a3a5;
        margin: 0;
        &::before {
          transition: background 0.2s;
          position: absolute;
          bottom: 35px;
          left: 50%;
          display: block;
          width: 60px;
          height: 6px;
          margin-left: -30px;
          background: #f3f2f2;
          border-radius: 3px;
          content: '';
        }
      }
      .duration {
        font-size: 22px;
        text-align: center;
        letter-spacing: 0.1em;
        color: #424242;
        margin: 0;
      }
      .wrap-container {
        padding: 16px 32px;
        background: rgba(245, 253, 253, 1);
        .title-name-border {
          font-size: 12px;
          color: rgba(0, 163, 165, 1);
          text-align: center;
          margin-top: 12px;
        }
        .title {
          text-align: center;
          color: rgba(0, 163, 165, 1);
          font-size: 14px;
          font-weight: 700;
        }
        .name {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 700;
          height: 28px;
          font-size: 12px;
          background: rgba(0, 163, 165, 1);
        }
        .title-border {
          font-size: 32px;
          color: rgba(0, 163, 165, 1);
          text-align: center;
          .month {
            font-size: 16px;
          }
        }
      }
      .total {
        display: flex;
        justify-content: center;
        font-size: 24px;
        color: rgba(246, 172, 0, 1);
        align-items: center;
      }
      .footer {
        margin-bottom: 16px;
      }
      .btn-submit {
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
        font-size: 15px;
        border: none;
        width: 100%;
        height: 48px;
        cursor: pointer;
      }
    }
    .active {
      transform: scale(1.1);
      .category-plan::before {
        background: #00a3a5;
      }
    }
    .inactive {
      opacity: 0.4;
    }
  }
  .step-top {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    height: 700px;
    .step-title {
      display: flex;
      justify-content: space-between;
      padding: 24px 32px;
      .title-left {
        height: 300px;
        background: rgba(255, 255, 255, 1);
        .name {
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-weight: 700;
          height: 28px;
          width: 230px;
          font-size: 12px;
          background: rgba(0, 163, 165, 1);
        }
        .title-name-border {
          font-size: 12px;
          color: rgba(0, 163, 165, 1);
          text-align: center;
        }
        .title-border {
          border-bottom: 1px solid #ccc;
          padding-bottom: 20px;
          font-size: 40px;
          color: rgba(0, 163, 165, 1);
          text-align: center;
          .month {
            font-size: 16px;
          }
        }
      }
      .total {
        width: 48px;
        display: flex;
        justify-content: center;
        font-size: 40px;
        color: rgba(246, 172, 0, 1);
        align-items: center;
        height: 310px;
      }
      .total-right {
        margin-top: 65px;
      }
      .gourd-left {
        display: flex;
        .form-step {
          padding: 24px;
          background: rgba(245, 253, 253, 1);
          .title-gourd {
            font-weight: 700;
            font-size: 16px;
            color: rgba(0, 163, 165, 1);
            text-align: center;
          }
        }
        .title {
          display: flex;
        }
      }
    }
    .bottom {
      font-size: 12px;
      padding: 0 105px;
      .title-bottom {
        font-size: 13px !important;
      }
    }
    .bottom-text {
      display: flex;
      width: 790px;
      background: rgba(249, 248, 248, 1);
      .name {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-weight: 700;
        height: 28px;
        width: 230px;
        background: rgba(0, 163, 165, 1);
      }
      .text {
        padding: 16px 0 16px 24px;
        color: rgba(66, 66, 66, 1);
      }
    }
  }
  .selected {
    justify-content: center;
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
    .reset {
      margin-top: 10px;
      font-size: 13px;
      text-decoration-line: underline;
      border: none;
      background: none;
      color: #00a3a5;
      cursor: pointer;
    }
  }
`;
