import React, { useEffect, useState } from 'react';

import { setHeaderTitle } from 'containers/AppSettings/slice';
import SkillCheckMasterTable from './Table';
import SkillCheckTreeview from './Treeview';
import { useAppDispatch } from 'hooks';

const SkillCheck: React.FC = () => {
  const [openSkillCheckMaster, setOpenSkillCheckMaster] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setHeaderTitle(
        openSkillCheckMaster ? 'スキルチェックマスタ 設定リスト' : 'スキルチェックツリー'
      )
    );
  }, [openSkillCheckMaster, dispatch]);

  return openSkillCheckMaster ? (
    <SkillCheckMasterTable setOpenSkillCheckMaster={setOpenSkillCheckMaster} />
  ) : (
    <SkillCheckTreeview setOpenSkillCheckMaster={setOpenSkillCheckMaster} />
  );
};

export default SkillCheck;
