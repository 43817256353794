import styled from 'styled-components';

export const FileExplorerWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

interface Props {
  active?: boolean;
  scaleNum: number;
  isDragging: boolean;
  isEditOrCreate: boolean;
  lowerSiblingMax?: number;
}

export const NodeContentWrapper = styled.div<Props>`
  position: relative;
  width: calc(100% + ${(props) => (props.lowerSiblingMax ? props.lowerSiblingMax * 30 : 0)}px);
  padding-right: 50px;
  opacity: ${(props) => (props.isDragging ? 0.4 : 1)};
  cursor: ${(props) => (props.isDragging ? 'move' : 'pointer')};
  background-color: ${(props) => (props.active ? '#F1F6F9' : '#ffffff')};
  .collapseButton,
  .expandButton {
    appearance: none;
    border: none;
    background: transparent;
    padding: 0;
    z-index: 2;
    position: absolute;
    top: 45%;
    transform: translate3d(-50%, -50%, 0);
    cursor: pointer;
    color: #999999;
    .icon {
      font-size: ${(props) => 16 * props.scaleNum}px;
    }
  }
  .lineBlock,
  .absoluteLineBlock {
    height: 100%;
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
  }
  .absoluteLineBlock {
    position: absolute;
    top: 0;
  }
  .rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    font-weight: ${(props) => (props.active ? 700 : 400)};
    &:hover {
      opacity: ${(props) => (props.active ? 1 : 0.8)};
    }
    &:active {
      opacity: 1;
    }
    .row {
      display: flex;
      white-space: nowrap;
      padding: ${(props) => props.scaleNum * 5}px;
      box-sizing: border-box;
      background-color: ${(props) => (props.active ? '#F1F6F9' : '#ffffff')};
    }
    .rowContents,
    .rowLabel,
    .rowToolbar {
      display: inline-block;
      vertical-align: middle;
    }
    .rowContents {
      height: 100%;
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .rowLabel .folder-name {
      padding-left: 10px;
      font-size: ${(props) => props.scaleNum * 12}px;
      color: #424242;
    }
    .rowToolbar .icon-folder {
      font-size: ${(props) => 18 * props.scaleNum}px;
      color: ${(props) => (props.isEditOrCreate ? '#F6AC00' : '#287dbb')};
    }
  }
`;
