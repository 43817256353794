import { configureStore } from '@reduxjs/toolkit';

import { skillCheckAnalysisReportSlice } from 'pages/Reports/SkillCheck/ReportType/SkillCheckAnalysis/slice';
import { skillCheckUserAnalysisReportSlice } from 'pages/Reports/SkillCheck/ReportType/UserAnalysis/slice';
import { skillCheckReportSlice } from 'pages/Reports/SkillCheck/ReportType/SkillCheck/slice';
import { skillCheckUserReportSlice } from 'pages/Reports/SkillCheck/ReportType/User/slice';
import { AdministratorMasterSlice } from 'pages/Settings/AdministratorMaster/slice';
import { skillCheckSelectionSlice } from 'pages/Settings/SkillCheckSelection/slice';
import { newSkillCheckSelectionSlice } from 'pages/NewSkillcheckSelection/slice';
import { menuRightSlice } from 'containers/SkillCheck/TreeView/MenuRight/slice';
import { InformationRegisterSlice } from 'containers/InformationRegister/slice';
import { educationProgramSlice } from 'pages/Settings/EducationProgram/slice';
import { skillCheckReportPageSlice } from 'pages/Reports/SkillCheck/slice';
import { affiliationSlice } from 'pages/Settings/AffiliationMaster/slice';
import { positionMasterSlice } from 'pages/Settings/PositionMaster/slice';
import { questionMasterSlice } from 'pages/Settings/QuestionMaster/slice';
import { trainingCurriculumSlice } from 'pages/TrainingCurriculum/slice';
import { questionSearchSlice } from 'containers/Curriculum/Search/slice';
import { officialCurriculumSlice } from 'pages/OfficialCurriculum/slice';
import { interviewUserSlice } from 'pages/Settings/InterviewUser/slice';
import { analysisGroupSlice } from 'pages/Settings/AnalysisGroup/slice';
import { paymentMasterSlice } from 'pages/Settings/PaymentMaster/slice';
import { menuRightUserSlice } from 'containers/MenuRightUser/slice';
import { questionSlice } from 'containers/CreateEditQuestion/slice';
import { rolesMasterSlice } from 'pages/Settings/RolesMaster/slice';
import { myChartDashboardSlice } from 'pages/Reports/MyChart/slice';
import { trainingReportSlice } from 'pages/Reports/Training/slice';
import { curriculumSlice } from 'pages/Settings/Curriculum/slice';
import { skillCheckSlice } from 'pages/Settings/SkillCheck/slice';
import { employeeSlice } from 'pages/Settings/Employee/slice';
import { knowledgeTopSlice } from 'pages/KnowledgeTop/slice';
import { usageStatusSlice } from 'pages/UsageStatus/slice';
import { dashboardSlice } from 'pages/Dashboard/slice';
import { profileSlice } from 'pages/Profile/slice';
import { authSlice } from 'containers/Auth/slice';
import { manualSlice } from 'pages/Manual/slice';
import * as reducers from './reducers';

export const resetAllInitialState = () => {
  return (dispatch: any) => {
    dispatch(skillCheckUserAnalysisReportSlice.actions.resetInitialState());
    dispatch(skillCheckAnalysisReportSlice.actions.resetInitialState());
    dispatch(newSkillCheckSelectionSlice.actions.resetInitialState());
    dispatch(skillCheckReportPageSlice.actions.resetInitialState());
    dispatch(skillCheckUserReportSlice.actions.resetInitialState());
    dispatch(InformationRegisterSlice.actions.resetInitialState());
    dispatch(AdministratorMasterSlice.actions.resetInitialState());
    dispatch(skillCheckSelectionSlice.actions.resetInitialState());
    dispatch(trainingCurriculumSlice.actions.resetInitialState());
    dispatch(officialCurriculumSlice.actions.resetInitialState());
    dispatch(skillCheckReportSlice.actions.resetInitialState());
    dispatch(educationProgramSlice.actions.resetInitialState());
    dispatch(myChartDashboardSlice.actions.resetInitialState());
    dispatch(questionSearchSlice.actions.resetInitialState());
    dispatch(positionMasterSlice.actions.resetInitialState());
    dispatch(questionMasterSlice.actions.resetInitialState());
    dispatch(trainingReportSlice.actions.resetInitialState());
    dispatch(menuRightUserSlice.actions.resetInitialState());
    dispatch(interviewUserSlice.actions.resetInitialState());
    dispatch(analysisGroupSlice.actions.resetInitialState());
    dispatch(paymentMasterSlice.actions.resetInitialState());
    dispatch(knowledgeTopSlice.actions.resetInitialState());
    dispatch(affiliationSlice.actions.resetInitialState());
    dispatch(rolesMasterSlice.actions.resetInitialState());
    dispatch(usageStatusSlice.actions.resetInitialState());
    dispatch(curriculumSlice.actions.resetInitialState());
    dispatch(skillCheckSlice.actions.resetInitialState());
    dispatch(menuRightSlice.actions.resetInitialState());
    dispatch(dashboardSlice.actions.resetInitialState());
    dispatch(questionSlice.actions.resetInitialState());
    dispatch(employeeSlice.actions.resetInitialState());
    dispatch(profileSlice.actions.resetInitialState());
    dispatch(manualSlice.actions.resetInitialState());
    dispatch(authSlice.actions.resetInitialState());
  };
};

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export { store };
