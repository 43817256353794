import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CloudUploadOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { DatePicker, Form, SubmitButton } from 'formik-antd';
import { Button as ButtonAnt, Select, Table } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import { uniqBy } from 'lodash';
import dayjs from 'dayjs';

import { HEADER_SKILL_CHECK_MASTER_CSV } from 'constant/header.export.constant';
import { IconEmployee, IconInterview, IconLocked, IconPublish } from 'assets';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { PUBLISH, USER_TYPE } from 'constant/select.constants';
import { authSelector } from 'containers/Auth/selectors';
import { skillCheckSelector } from '../../selectors';
import PDFBasicDocument from '../PDFBasicDocument';
import { getDataSkillCheck } from '../../thunk';
import exportPDF from 'libs/utils/exportPdf';
import { SelectField } from 'components';
import { useAppDispatch } from 'hooks';
import { TabStyled } from '../styles';
import * as Types from 'types';

const { Option } = Select;

interface Props {
  tabActive: number;
  setOpenSkillCheckMaster: React.Dispatch<React.SetStateAction<boolean>>;
}

const BasicTable: React.FC<Props> = ({ tabActive, setOpenSkillCheckMaster }) => {
  const [perPage, setPerPage] = useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const { dataSkillCheck, filter_option } = useSelector(skillCheckSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const ref = useRef(null);

  const fetchData = useCallback(
    (conditions?: Types.ConditionsType[]) => {
      if (!userInfo) return;
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              ...(conditions || []),
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    },
    [dispatch, userInfo]
  );

  const formik = useFormik<Types.SearchSkillCheckFormik>({
    initialValues: {
      code: '',
      grouping_code: '',
      start_period: '',
      publish: 'none',
    },
    onSubmit: (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value?.toString() && value !== 'none') {
          conditions.push({
            id: key,
            search_value: [value.toString()],
            exact_match: true,
          });
        }
      });
      fetchData(conditions);
    },
  });

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    if (value === 'csv') {
      const resultAction = await dispatch(
        getDataSkillCheck({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          include_lookups: true,
          page: 1,
          per_page: 0,
        })
      );
      if (getDataSkillCheck.fulfilled.match(resultAction)) {
        const listCsv = resultAction.payload.items.map((item) => ({
          company_id: item.company_id,
          createdby: item.createdby,
          description: item.description,
          end_period: item.end_period,
          grouping_code: item.grouping_code,
          implementation_settings: item.implementation_settings,
          problems4: item.problems4,
          publish: item.publish,
          question_count: item.probs_count,
          question_time_limit: item.question_time_limit,
          skill_check_code: item.code,
          skill_check_name: item.name,
          start_period: item.start_period,
          user_type: item.user_type,
        }));

        const csvString = [
          HEADER_SKILL_CHECK_MASTER_CSV.map(({ label }) => label),
          ...listCsv.map((item) => Object.values(item)),
        ]
          .map((e) => e.join(','))
          .join('\n');
        const bom = '\uFEFF';
        const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
        saveAs(file, 'Skill_Check_Table_export.csv');
      }
    } else {
      if (!ref.current) return;

      exportPDF(ref, '基本情報リスト.pdf');
    }
    dispatch(stopLoading());
    setVisiblePopupConfirmExportFile(false);
  };

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          bottom: 0,
          width: '100%',
        }}
      >
        <PDFBasicDocument
          formik={formik}
          dataSkillCheck={dataSkillCheck}
          filter_option={filter_option}
          tabActive={tabActive}
        />
      </div>
    );
  }, [filter_option, formik, dataSkillCheck, tabActive]);

  useEffect(fetchData, [fetchData]);

  return (
    <TabStyled>
      <div className="right-side">
        <ButtonAnt className="btn btn-outline" onClick={() => setOpenSkillCheckMaster(false)}>
          スキルチェックツリー
          <RightOutlined className="size-icon-right-outline" />
        </ButtonAnt>
        <ButtonAnt
          className="btn btn-active"
          onClick={() => setVisiblePopupConfirmExportFile(true)}
        >
          <CloudUploadOutlined className="size-icon" />
          エクスポート
        </ButtonAnt>
      </div>
      {component}
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="form-search">
            <Form.Item
              name="skill_check_code"
              className="item"
              label={<span className="text-label">スキルチェック名</span>}
            >
              <SelectField
                name="skill_check_code"
                showSearch
                allowClear
                placeholder="スキルチェック名で検索"
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'skill_check_code').map(
                  ({ skill_check_code, skill_check_name }, index) => (
                    <Option key={index} value={skill_check_code}>
                      {skill_check_name}
                    </Option>
                  )
                )}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="grouping_code"
              className="item"
              label={<span className="text-label">分析グループ</span>}
            >
              <SelectField
                name="grouping_code"
                showSearch
                allowClear
                placeholder="分析グループで検索"
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'grouping_code').map(
                  ({ grouping_code2, grouping_code }, index) => (
                    <Option key={index} value={grouping_code2}>
                      {grouping_code}
                    </Option>
                  )
                )}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="start_period"
              className="item"
              label={<span className="text-label">実施期間</span>}
            >
              <DatePicker
                allowClear
                showTime
                name="start_period"
                placeholder="開始日で検索"
                format="YYYY/MM/DD HH:mm"
              />
            </Form.Item>
            <Form.Item
              name="publish"
              className="item half-width"
              label={<span className="text-label">公開ステータス</span>}
            >
              <SelectField name="publish" defaultValue="none">
                <Option value="none">指定なし</Option>
                {PUBLISH.map(({ value, label }, index) => (
                  <Option key={index} value={value}>
                    {Number(value) === 1 ? (
                      <>
                        <img src={IconPublish} className="icon" alt="publish-icon" />
                        <span>{label}</span>
                      </>
                    ) : (
                      <>
                        <img src={IconLocked} className="icon" alt="edit-icon" />
                        <span>{label}</span>
                      </>
                    )}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="user_type"
              className="item half-width"
              label={<span className="text-label">ユーザー種別</span>}
            >
              <SelectField name="user_type" defaultValue="none">
                <Option value="none">指定なし</Option>
                {USER_TYPE.map(({ value, label }, index) => (
                  <Option key={index} value={value}>
                    {value === 'member' ? (
                      <img src={IconEmployee} className="icon" alt="employee-icon" />
                    ) : (
                      <img src={IconInterview} className="icon" alt="interview-icon" />
                    )}
                    <span>{label}</span>
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <div className="wrap-submit-search">
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span
                className="label-reset"
                onClick={() => {
                  formik.resetForm();
                  formik.handleSubmit();
                }}
              >
                リセット
              </span>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <Table
        rowKey="index"
        className="table"
        dataSource={dataSkillCheck.map((item, index) => ({ ...item, index }))}
        columns={columns}
        pagination={{
          pageSize: perPage,
          total: dataSkillCheck.length,
          current: page,
          onChange: setPage,
          showSizeChanger: false,
          position: ['topCenter'],
          showTotal: () => (
            <div className="wrap-select-record">
              <div className="text-count">
                {(page - 1) * perPage + 1} - {''}
                {page * perPage > dataSkillCheck.length ? dataSkillCheck.length : page * perPage}
                <span className="text-static"></span> / {dataSkillCheck.length}
                <span className="text-static">件</span>
              </div>
              <div>
                <span className="label-select">表示件数：</span>
                <Select className="select-option" onChange={setPerPage} value={perPage}>
                  {[100, 50, 20, 10].map((e) => (
                    <Option value={e}>{e}</Option>
                  ))}
                </Select>
              </div>
            </div>
          ),
        }}
      />
      <PopupConfirmExportFile
        visible={visiblePopupConfirmExportFile}
        setVisible={setVisiblePopupConfirmExportFile}
        onSubmit={handleExportCSV}
      />
    </TabStyled>
  );
};

const columns: ColumnsType<Types.SkillCheck.ResponseType> = [
  {
    title: 'コード',
    key: 'code',
    dataIndex: 'code',
    align: 'center',
    width: '2%',
    render: (text) => <p className="text-content-code">{text}</p>,
  },
  {
    title: 'スキルチェック名',
    key: 'name',
    dataIndex: 'name',
    width: '22%',
    render: (text) => <p className="text-content">{text}</p>,
  },
  {
    title: '実施期間',
    key: 'period',
    dataIndex: 'period',
    width: '12%',
    render: (_, item) => (
      <p className="text-content">
        {item.end_period && `${dayjs(item.start_period).format('YYYY年MM月DD日 HH:mm')}〜`}
        <br />
        {item.end_period && dayjs(item.end_period).format('YYYY年MM月DD日 HH:mm')}
      </p>
    ),
  },
  {
    title: 'ユーザー種別',
    key: 'user_type',
    dataIndex: 'user_type',
    align: 'center',
    width: '11%',
    render: (text) =>
      text === 'member' ? (
        <>
          <img src={IconEmployee} className="icon" alt="employee-icon" />
          <span className="spanText">社内</span>
        </>
      ) : (
        <>
          <img src={IconInterview} className="icon" alt="interview-icon" />
          <span className="spanText">面接</span>
        </>
      ),
  },
  {
    title: '分析グループ',
    key: 'grouping_code',
    dataIndex: 'grouping_code',
    width: '12%',
    render: (text) => <p className="text-content">{text}</p>,
  },

  {
    title: '設問数',
    key: 'probs_count',
    dataIndex: 'probs_count',
    align: 'center',
    width: '7%',
    render: (text) => <p className="text-content">{text || 0} 問</p>,
  },
  {
    title: '制限時間',
    key: 'question_time_limit',
    dataIndex: 'question_time_limit',
    align: 'center',
    width: '8%',
    render: (text) => <p className="text-content">{text || 0} 分</p>,
  },
  {
    title: 'ユーザー数',
    key: 'user_count',
    dataIndex: 'user_count',
    align: 'center',
    width: '9%',
    render: (text) => <p className="text-content">{text || 0} 人</p>,
  },
  {
    title: '公開ステータス',
    key: 'publish',
    dataIndex: 'publish',
    align: 'center',
    width: '11.5%',
    render: (text) =>
      Number(text) === 1 ? (
        <>
          <img src={IconPublish} className="icon" alt="publish-icon" />
          <span className="spanText">公開中</span>
        </>
      ) : (
        <>
          <img src={IconLocked} className="icon" alt="edit-icon" />
          <span className="spanText">非公開</span>
        </>
      ),
  },
];

export default BasicTable;
