import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import { includes } from 'lodash';

import { handleGetFileQuestionDetail, handleGetQuestionDetail } from 'libs/utils/question';
import { questionSelector } from 'containers/CreateEditQuestion/selectors';
import { settingSelector } from 'containers/AppSettings/selectors';
import { stopLoading } from 'containers/AppSettings/slice';
import { Modal, SpinLoading } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { useFormik } from 'formik';
import { NoImage2 } from 'assets';
import * as Types from 'types';

interface Props {
  visible: { visible: boolean; question_id?: string };
  setVisible: React.Dispatch<React.SetStateAction<{ visible: boolean; question_id?: string }>>;
}

const QuestionDetail: React.FC<Props> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();

  const { time_limit } = useSelector(questionSelector);
  const { loading } = useSelector(settingSelector);

  const handleCancel = () => {
    setVisible({
      visible: false,
    });
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit: '',
      comment: '',
      score: 0,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
    },
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const renderMediaItem = (url: string, index: number) => {
    const isVideo = includes(url, 'mp4');
    return isVideo ? (
      <div className="image" key={index}>
        <ReactPlayer
          width={'100%'}
          height={'100%'}
          url={url}
          controls
          config={{
            file: {
              attributes: {
                disablePictureInPicture: true,
                controlsList: 'nodownload noplaybackrate',
              },
            },
          }}
        />
      </div>
    ) : (
      <div className="image" key={index}>
        <img src={url || NoImage2} className="image-question" alt={`attach${index}`} />
      </div>
    );
  };

  useEffect(() => {
    if (!visible.visible || !visible.question_id) return;
    dispatch(stopLoading());

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      type: 'edit',
      i_id: visible.question_id,
      formik,
    });
    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      type: 'edit',
      i_id: visible.question_id,
      formik,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attachFileID: [],
        commentAttachFileID: [],
        problems1AttachFileID: [],
        problems2AttachFileID: [],
        problems3AttachFileID: [],
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      title="設問詳細"
      width={860}
      visible={visible.visible}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible({ visible: false }),
      }}
      onCancel={() => setVisible({ visible: false })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <SpinLoading size="large" loading={loading}>
          <div className="indexing">
            <div className="index-number">
              <span>1</span>
            </div>
            <span className="title">設問内容</span>
          </div>
          <div className="item">
            <span className="text-label">設問名</span>
            <div className="wrap-value">
              <span className="value">{formik.values.name}</span>
            </div>
          </div>
          <div className="item">
            <span className="text-label">設問内容</span>
            <div className="wrap-value description">
              <span className="value">{formik.values.description}</span>
            </div>
          </div>
          <div className="item">
            <div className="wrap-value row question-image">
              {[0, 1, 2].map((index) => (
                <div className="image-item" key={index}>
                  <p className="title">{`設問画像・動画 ${
                    index === 2 ? '③' : index === 1 ? '②' : '①'
                  }`}</p>
                  {renderMediaItem(formik.values.attach[index] as unknown as string, index)}
                </div>
              ))}
            </div>
          </div>

          <div className="indexing">
            <div className="index-number">
              <span>2</span>
            </div>
            <span className="title">解答選択肢</span>
          </div>
          {[1, 2, 3].map((index) => {
            const problemKey: 'problems3' | 'problems2' | 'problems1' = `problems${
              index as 1 | 2 | 3
            }`;
            const attachKey:
              | 'problems3_attach'
              | 'problems2_attach'
              | 'problems1_attach' = `problems${index as 1 | 2 | 3}_attach`;
            return (
              <div className="item" key={index}>
                <span className="text-label">
                  選択肢{index === 3 ? 'C' : index === 2 ? 'B' : 'A'}
                </span>
                <div className="wrap-value row answer">
                  <span className="value">{formik.values[problemKey]}</span>
                  {renderMediaItem(formik.values[attachKey][0] as unknown as string, index)}
                </div>
              </div>
            );
          })}
          <div className="item">
            <span className="text-label">解答</span>
            <div className="wrap-value small-width">
              <span className="value">選択肢{formik.values.answer}</span>
            </div>
          </div>

          <div className="indexing">
            <div className="index-number">
              <span>3</span>
            </div>
            <span className="title">解説・その他設定</span>
          </div>
          <div className="item">
            <span className="text-label">解説</span>
            <div className="wrap-value">
              <span className="value description">{formik.values.comment}</span>
            </div>
          </div>
          <div className="item">
            <div className="wrap-value row question-image">
              {[0, 1, 2].map((index) => (
                <div className="image-item" key={index}>
                  <p className="title">{`設問画像・動画 ${
                    index === 2 ? '③' : index === 1 ? '②' : '①'
                  }`}</p>
                  {renderMediaItem(formik.values.comment_attach[index] as unknown as string, index)}
                </div>
              ))}
            </div>
          </div>
          <div className="item">
            <span className="text-label">制限時間</span>
            <div className="wrap-time-limit">
              <div className="wrap-value small-width">
                <span className="value">
                  {time_limit.find((time) => time.option_id === formik.values.time_limit)?.value}
                </span>
              </div>
              <span className="note">※トレーニング用に設定している、設問の制限時間です。 </span>
            </div>
          </div>
          <div className="item">
            <span className="text-label">スコア設定（設問難易度の設定）</span>
            <div className="wrap-value small-width">
              <span className="value">{formik.values.score}</span>
            </div>
          </div>
        </SpinLoading>
      </SectionStyled>
    </Modal>
  );
};

export default QuestionDetail;
