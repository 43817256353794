import React, { useEffect, useState } from 'react';
import isBetween from 'dayjs/plugin/isBetween';
import { useSelector } from 'react-redux';
import { Collapse } from 'antd';
import dayjs from 'dayjs';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  MessageOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { startLoading, stopLoading, toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { createSkillCheckTrans, getDataSkillCheckImple, getSelectTypes } from './thunk';
import { newSkillCheckSelectionSelector } from './selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { sharedFileInMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { IconImpossible, IconPossible } from 'assets';
import NewSkillCheckSelectionStyled from './styles';
import SkillCheckStatus from './SkillCheckStatus';
import { logout } from 'containers/Auth/slice';
import Guideline from './Guideline';
import { Header } from 'components';
import * as Types from 'types';
import Status from './Status';

dayjs.extend(isBetween);

const { Panel } = Collapse;

const NewSkillCheckSelection: React.FC = () => {
  const [dataGuideline, setDataGuideline] = useState<Types.ItemSkillCheckImpleType>();
  const [files, setFiles] = useState<Array<{ file: string; i_id: string }>>([]);
  const [fileGuideline, setFileGuideline] = useState<string>();
  const [statusIndex, setStatusIndex] = useState<number>(0);

  const dispatch = useAppDispatch();

  const { dataSkillCheckImple, time_limit } = useSelector(newSkillCheckSelectionSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const handleAddItem = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      createSkillCheckTrans({
        item: {
          login_id: dataGuideline?.login_id,
          company_id: userInfo?.company_id,
          skill_check_code: dataGuideline?.skill_check_code,
          responses_num: 0,
          correct_answers_num: 0,
          last_action: '0',
          tran_status: 1,
          acquisition_score: 0,
          implementation_date: new Date(),
          createdat: new Date(),
          createdby: userInfo?.login_id,
        },
        return_display_id: true,
        return_item_result: true,
      })
    );
    if (createSkillCheckTrans.fulfilled.match(resultAction)) {
      setStatusIndex(2);
    }
    dispatch(stopLoading());
  };

  const handleGetFileItem = async (
    activeKey: string | string[],
    item: Types.ItemSkillCheckImpleType
  ) => {
    let fileSkillCheck = '';
    dispatch(startLoading());
    const checkExist = files.find((f) => f.i_id === item.i_id);
    if (item.fileID && activeKey && !checkExist) {
      fileSkillCheck = await sharedFileInMinIO(item.fileID);

      setFiles([...files, { file: fileSkillCheck, i_id: item.i_id }]);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSkillCheckImple({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'skill_check_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getSelectTypes({
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusIndex]);

  return (
    <>
      {dataSkillCheckImple.length ? (
        <NewSkillCheckSelectionStyled>
          {statusIndex !== 2 && statusIndex !== 3 && <Header title="スキルチェック選択" />}
          {statusIndex === 1 ? (
            <Guideline
              setStatusIndex={setStatusIndex}
              handleAddItem={handleAddItem}
              dataGuideline={dataGuideline}
              setDataGuideline={setDataGuideline}
              setFileGuideline={setFileGuideline}
              fileGuideline={fileGuideline}
            />
          ) : statusIndex === 2 ? (
            <SkillCheckStatus setStatusIndex={setStatusIndex} dataGuideline={dataGuideline} />
          ) : statusIndex === 3 ? (
            <Status
              dataGuideline={dataGuideline}
              setStatusIndex={setStatusIndex}
              fileGuideline={fileGuideline}
              countStatus={
                dataSkillCheckImple.filter((item) => item.implementation_status === 2).length
              }
              countList={dataSkillCheckImple.length}
            />
          ) : (
            <>
              <div className="skill-check-header">
                <div className="button-check">
                  <p className="text-check">
                    実施したいスキルチェックの実施ボタンをクリックしてください。
                  </p>
                  <button className="check-btn" onClick={() => dispatch(logout())}>
                    ログアウトする
                  </button>
                </div>
              </div>
              <div className="body">
                {dataSkillCheckImple?.map((item: Types.ItemSkillCheckImpleType, index) => (
                  <Collapse
                    key={index}
                    accordion
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <DownOutlined style={{ color: '#999999' }} />
                      ) : (
                        <UpOutlined style={{ color: '#999999' }} />
                      )
                    }
                    onChange={(activeKey) => {
                      handleGetFileItem(activeKey, item);
                    }}
                  >
                    <Panel
                      header={
                        <div
                          key={index}
                          className={`item ${
                            item.implementation_status === 2
                              ? ''
                              : item.implementation_settings === 2 ||
                                (item.implementation_settings === 1 &&
                                  dayjs().isBetween(item?.start_period, item?.end_period))
                              ? 'hight-light'
                              : ''
                          }`}
                        >
                          <div className="edit-item">
                            {item.implementation_status === 2 ? (
                              <img src={IconImpossible} className="icon" alt="possible-icon" />
                            ) : item.implementation_settings === 2 ||
                              (item.implementation_settings === 1 &&
                                dayjs().isBetween(item?.start_period, item?.end_period)) ? (
                              <img src={IconPossible} className="icon" alt="possible-icon" />
                            ) : (
                              <img src={IconImpossible} className="icon" alt="possible-icon" />
                            )}
                            <p>{item.skill_check_name}</p>
                          </div>
                          {item.implementation_status === 2 ? (
                            <button className="btn-check">
                              <CheckCircleOutlined className="icon" /> 実施済
                            </button>
                          ) : item.implementation_settings === 2 ||
                            (item.implementation_settings === 1 &&
                              dayjs().isBetween(item?.start_period, item?.end_period)) ? (
                            <button
                              className="btn-edit"
                              onClick={(event) => {
                                event.stopPropagation();
                                setStatusIndex(1);
                                setDataGuideline(item);
                              }}
                            >
                              <img src={IconPossible} className="icon" alt="possible-icon" />{' '}
                              実施する
                            </button>
                          ) : (
                            <button className="btn-stop">
                              <ExclamationCircleOutlined className="icon" /> 未実施
                            </button>
                          )}
                        </div>
                      }
                      key="1"
                    >
                      <div
                        className={`content-dropdown ${
                          item.implementation_status === 2
                            ? 'check-item'
                            : item.implementation_settings === 2 ||
                              (item.implementation_settings === 1 &&
                                dayjs().isBetween(item?.start_period, item?.end_period))
                            ? 'edit-item'
                            : 'stop-item'
                        }`}
                      >
                        <div className="item-dropdown-left">
                          {files && (
                            <img
                              src={files.find((f) => f.i_id === item.i_id)?.file}
                              className="image"
                              alt=""
                            />
                          )}
                        </div>
                        <div className="item-dropdown-right">
                          <div className="item-dropdown">
                            <div className="item-left">
                              <div className="item-left-child">
                                <div className="label">
                                  <CopyOutlined className="icon-copy" />
                                  <p className="text-label">分析グループ</p>
                                </div>
                                <p className="content-label">: {item.grouping_code}</p>
                              </div>
                              <div className="item-left-child">
                                <div className="label">
                                  <CalendarOutlined className="icon-copy" />
                                  <p className="text-label">実施期間</p>
                                </div>
                                <p className="content-label">
                                  {': '}
                                  {item.implementation_settings === 2 ? (
                                    '実施可'
                                  ) : item.implementation_settings === 0 ? (
                                    '実施不可'
                                  ) : (
                                    <>
                                      {item?.start_period &&
                                        dayjs(item.start_period).format('YYYY年MM月DD日 HH:mm')}
                                      {(item?.start_period || item?.end_period) && <span>~</span>}
                                      {item?.end_period &&
                                        dayjs(item.end_period).format('YYYY年MM月DD日 HH:mm')}
                                    </>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div className="item-right">
                              <div className="item-child">
                                <div className="item-right-child">
                                  <div className="label">
                                    <ClockCircleOutlined className="icon-copy" />
                                    <p className="text-label">制限時間: </p>
                                  </div>
                                  <p className="content-label">
                                    {time_limit[Number(item.check_time_limit) - 1]?.name}
                                  </p>
                                </div>
                                <div className="item-right-child">
                                  <div className="label">
                                    <CheckCircleOutlined className="icon-copy" />
                                    <p className="text-label">実施状況: </p>
                                  </div>
                                  <div
                                    className={`content-label-status ${
                                      item.implementation_status === 2
                                        ? 'check'
                                        : item.implementation_settings === 2 ||
                                          (item.implementation_settings === 1 && dayjs())
                                        ? 'edit'
                                        : 'stop'
                                    }`}
                                  >
                                    {item.implementation_status === 2 ? (
                                      <>
                                        <div className="status-done">
                                          <p className="text-status">済</p>
                                        </div>
                                        <p className="label-status">実施済</p>
                                      </>
                                    ) : (
                                      <div
                                        className={
                                          item.implementation_settings === 2 ||
                                          (item.implementation_settings === 1 &&
                                            dayjs().isBetween(item?.start_period, item?.end_period))
                                            ? 'implemented'
                                            : 'not-implemented'
                                        }
                                      >
                                        <div
                                          className={`status ${
                                            item.implementation_settings === 2 ||
                                            (item.implementation_settings === 1 &&
                                              dayjs().isBetween(
                                                item?.start_period,
                                                item?.end_period
                                              ))
                                              ? 'edit-icon'
                                              : 'stop-icon'
                                          }`}
                                        >
                                          <p className="text-status">未</p>
                                        </div>
                                        <p className="label-status">未実施</p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="item-child">
                                <div className="item-right-child">
                                  <div className="label">
                                    <FileTextOutlined className="icon-copy" />
                                    <p className="text-label">設問数: </p>
                                  </div>
                                  <p className="content-label">{item.question_count}問</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="item-dropdown-last">
                            <div className="label">
                              <MessageOutlined className="icon-mess" />
                              <p className="text-label">説明 :</p>
                            </div>
                            <div className="wrap-des">
                              <p className="content-label">{item.skill_check_description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                ))}
              </div>
            </>
          )}
        </NewSkillCheckSelectionStyled>
      ) : (
        <NewSkillCheckSelectionStyled>
          <Header title="スキルチェック実施要項確認" />
          <div className="item-instruction">
            <div className="item-body">
              <div className="time-detail-child">
                <ExclamationCircleOutlined className="icon" />
                <p className="label">実施可能なスキルチェックがありません。</p>
              </div>
              <p className="text-label">ご担当者様にお問い合わせください。</p>
              <div className="btn">
                <button className="btn-button" onClick={() => dispatch(logout())}>
                  ログアウトする
                </button>
              </div>
            </div>
          </div>
        </NewSkillCheckSelectionStyled>
      )}
    </>
  );
};

export default NewSkillCheckSelection;
