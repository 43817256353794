import styled from 'styled-components';

import { Modal } from 'components';
interface Props {
  collapsedMenu: boolean;
  optionMenu: boolean;
}

interface PropsFileSize {
  collapsedMenu: boolean;
}

export default styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 30px;
  background: #f9f8f8;
  .access-denied {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #cccccc;
    padding: 10px 30px;
    .icon-lock {
      color: #c4c4c4;
      font-size: 30px;
      margin: 0 10px;
    }
    .content {
      color: #424242;
      font-size: 14px;
    }
  }
`;

export const Wrapper = styled.div<Props>`
  .container-none {
    display: none;
  }
  .container-footer {
    display: ${(props) => (props.optionMenu ? 'none' : 'flex')};
    z-index: 9999;
    position: absolute;
    margin-left: ${(props) => (!props.collapsedMenu ? '-196px;' : '-60px')};
    background: rgba(0, 0, 0, 0.25);
    height: 96px;
    box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.08);
    bottom: 0;
    width: 100%;
    padding: 16px;
    justify-content: center;
    .content {
      width: 930px;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 0 2px 0 16px;
      .sub {
        padding: 0 16px;
        height: 48px;
        width: 166px;
        background: rgba(246, 172, 0, 1);
        font-weight: 700;
        color: white;
        font-size: 13px;
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 16px;
        margin-right: 4px;
      }
      .close {
        margin-top: -40px;
        margin-left: 17px;
        .icon-close {
          font-size: 16px;
          right: 4px;
          cursor: pointer;
          svg {
            fill: rgba(129, 127, 127, 1);
          }
        }
      }
      .sub-title {
        padding: 0 16px;
        font-weight: 700;
        color: rgba(101, 99, 96, 1);
        font-size: 13px;
      }
      .check-btn {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #f1a900;
        padding: 5px 49px;
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        cursor: pointer;
      }
    }
    .content-error {
      width: 1018px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px 0 16px;
      .sub {
        padding: 0 16px;
        height: 48px;
        width: 166px;
        background: #4d81ce;
        font-weight: 700;
        color: white;
        font-size: 14px;
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 16px;
        margin-right: 4px;
      }
      .sub-title {
        padding: 0 16px;
        font-weight: 700;
        color: rgba(101, 99, 96, 1);
        font-size: 10px;
      }
      .check-btn {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        color: #4d81ce;
        padding: 5px 49px;
        background: #ffffff;
        border: 1px solid #4d81ce;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const WrapperFileSize = styled.div<PropsFileSize>`
  .container-none {
    display: none;
  }
  .container-footer {
    display: flex;
    z-index: 9999;
    position: absolute;
    margin-left: ${(props) => (!props.collapsedMenu ? '-196px;' : '-60px')};
    background: rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 10px 3px rgba(0, 0, 0, 0.08);
    bottom: 0;
    width: 100%;
    height: 96px;
    padding: 20px;
    justify-content: center;
    .content {
      background: #fff;
      display: flex;
      align-items: center;
      padding: 4px;
      .sub {
        padding: 0 16px;
        height: 48px;
        background: #00caf6;
        font-weight: 700;
        color: white;
        font-size: 12px;
        display: flex;
        align-items: center;
      }
      .icon {
        font-size: 16px;
        margin-right: 4px;
      }
      .close {
        align-self: flex-start;
        margin-right: 1px;
        margin-left: 80px;
        .icon-close {
          font-size: 16px;
          right: 4px;
          cursor: pointer;
          svg {
            fill: #d9d9d9;
            stroke: #817f7f;
          }
        }
      }
      .sub-title {
        padding-left: 11px;
        font-weight: 700;
        color: rgba(101, 99, 96, 1);
        font-size: 13px;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const ModalStyled = styled(Modal)`
   {
    .ant-modal-header {
      display: none;
    }
    .ant-modal-body {
      padding: 32px;
    }
    .content {
      text-align: center;
      color: rgba(66, 66, 66, 1);
      font-family: Noto Sans Javanese;
      .content-body {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .sub-title-modal {
        width: 240px;
        height: 48px;
        font-weight: 700;
        color: white;
        background-color: rgba(240, 51, 10, 1);
        margin-bottom: 28px;
        line-height: 48px;
        .icon {
          font-size: 15px;
          margin: 0 4px 0 0;
        }
      }
      .sub {
        color: rgba(240, 51, 10, 1);
      }
    }
    .button-primary {
      padding: 9px 20px;
    }
  }
`;
