import React, { useState } from 'react';
import { Button } from 'antd';

import FormCondition from '../../Forms/FormOfficial';
import { FreeTrialStyled } from './styles';
import { Logo } from 'assets';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConditionsModal: React.FC<Props> = ({ visible, setVisible }) => {
  const [formCondition, setFormCondition] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmitModal = () => {
    setLoading(true);
    setFormCondition(true);
    setLoading(false);
  };

  return (
    <FreeTrialStyled open={visible} onCancel={handleCloseModal}>
      <div className="body">
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
          <p className="sub-title">SKILL FAMILIARへようこそ!</p>
        </p>
        <span className="sub-content">
          <div className="sub-footer">
            はじめに利用者情報の登録を行います。
            <br /> 会社情報と決済情報（クレジットカード）をご用意ください。
          </div>
          <div className="btn-submit">
            <Button className="btn" onClick={handleSubmitModal} loading={loading}>
              ご利用開始
            </Button>
          </div>
        </span>
      </div>
      <FormCondition visible={formCondition} setVisible={setFormCondition} setOpen={setVisible} />
    </FreeTrialStyled>
  );
};

export default ConditionsModal;
