import { createAsyncThunk } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';

import { sharedFileInMinIO } from 'services/minioService';
import { services } from 'services';
import { RootState } from 'types';
import * as Types from 'types';
import {
  COMPANIES,
  QUESTION_TRANS2,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_TYPES,
  SKILL_CHECK_ASSIGN_QUESTION,
  SKILL_CHECK_IMPLE,
  SKILL_CHECK_TRANS2,
  USER_ASSIGN_SKILL_CHECK,
} from 'configs';

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.SelectTypes.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SelectTypes.ResponseType>(SELECT_TYPES.id, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckImple = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getDataSkillCheckImple', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
      SKILL_CHECK_IMPLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSortOrder = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckAssignQuestion.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getDataSortOrder', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckAssignQuestion.ResponseType>(
      SKILL_CHECK_ASSIGN_QUESTION.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckImple.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'newSkillCheckSelection/thunk/getDataQuestion',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as RootState;
      const { data } = await services.filter<Types.SkillCheckImple.ResponseType>(
        SKILL_CHECK_IMPLE.id,
        {
          ...req,
          sort_fields: [{ id: 'question_code', order: 'asc' }],
        }
      );

      const responseStatus = await dispatch(
        getDataSortOrder({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );

      let dataSortOrder: Types.SkillCheckImple.ResponseType[] = [];

      if (getDataSortOrder.fulfilled.match(responseStatus)) {
        dataSortOrder = data.report_results.map((e) => {
          const index = findIndex(
            responseStatus.payload.items,
            (val) =>
              val.question_code === e.question_code && val.skill_check_code === e.skill_check_code
          );
          if (index > -1) {
            return {
              ...e,
              sort_order: Number(responseStatus.payload.items[index].sort_order),
            };
          }

          return e;
        });
      }

      const dataConvert = await Promise.all(
        dataSortOrder.map(async (val) => {
          const obj = val;

          if (
            val.question_attach_fileID1 ||
            val.question_attach_fileID2 ||
            val.question_attach_fileID3
          ) {
            const question_attach_file = await Promise.all(
              [
                val.question_attach_fileID1,
                val.question_attach_fileID2,
                val.question_attach_fileID3,
              ].map(async (e) => {
                if (!e) return;
                const nodeFileUrl = await sharedFileInMinIO(e);

                return nodeFileUrl;
              })
            );

            Object.assign(obj, {
              ...val,
              question_attach_file: question_attach_file.filter(Boolean),
            });
          }

          if (val.problems1_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems1_attach_fileID);
            Object.assign(obj, {
              problems1_attach_file: nodeFileUrl,
            });
          }

          if (val.problems2_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems2_attach_fileID);
            Object.assign(obj, {
              problems2_attach_file: nodeFileUrl,
            });
          }
          if (val.problems3_attach_fileID) {
            const nodeFileUrl = await sharedFileInMinIO(val.problems3_attach_fileID);
            Object.assign(obj, {
              problems3_attach_file: nodeFileUrl,
            });
          }

          return obj;
        })
      );
      return { ...data, report_results: dataConvert.sort((a, b) => a.sort_order - b.sort_order) };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createSkillCheckTrans = createAsyncThunk<
  Types.CreateItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.CreateItemRequestType<Types.SkillCheckTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/createSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(SKILL_CHECK_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editUserAssignSillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/editUserAssignSillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const editSkillCheckTrans = createAsyncThunk<
  Types.UpdateItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.UpdateItemRequestType<Types.SkillCheckTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/editSkillCheckTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(SKILL_CHECK_TRANS2.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataItem = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignSkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getDataItem', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
      USER_ASSIGN_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createQuestionTrans = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.QuestionTrans2.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/createQuestionTrans', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(QUESTION_TRANS2.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.NewSelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.NewSelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK_GROUPING_CODE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('newSkillCheckSelection/thunk/getDataCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
