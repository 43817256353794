import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getAdminEmail, getPaymentMaster } from './thunk';
import * as Types from 'types';

export interface InitialStatePayment {
  creditCardInfo: Types.RegisterInformationPaymentFormik;
  invoiceInfo: Types.RegisterInvoiceInformationFormik;
  PaymentMaster?: Types.PaymentMaster.ResponseType;
  dataAdminGmail: Array<Types.Companies.ResponseType>;
}

const initialStatePayment: InitialStatePayment = {
  creditCardInfo: {
    companyName: '',
    cardNumber: '',
    customerName: '',
    expirationDate: '',
    securityNumber: '',
  },
  invoiceInfo: {
    tel: '',
    fax: '',
    address: '',
    post_code: '',
    billing_address: '',
    business_registration_number: '',
  },
  dataAdminGmail: [],
};

export const paymentMasterSlice = createSlice({
  name: 'paymentMaster-page',
  initialState: initialStatePayment,
  reducers: {
    setInvoiceInfo(state, action: PayloadAction<Types.RegisterInvoiceInformationFormik>) {
      state.invoiceInfo = action.payload;
    },
    setCreditCardInfo(state, action: PayloadAction<Types.RegisterInformationPaymentFormik>) {
      state.creditCardInfo = action.payload;
    },
    resetInitialState: () => {
      return initialStatePayment;
    },
  },
  extraReducers(builder) {
    builder.addCase(getPaymentMaster.fulfilled, (state, action) => {
      state.PaymentMaster = action.payload.report_results[0];
      if (action.payload.report_results && action.payload.report_results.length > 0) {
        const {
          tel,
          fax,
          address,
          post_code,
          prefecture,
          plot_number,
          card_holder,
          building_name,
          billing_address,
          card_number_l4g,
          card_company_name,
          card_expiration_year,
          card_expiration_month,
          business_registration_number,
        } = action.payload.report_results[0];

        state.creditCardInfo = {
          companyName: card_company_name,
          cardNumber: `123412341234${card_number_l4g}`,
          customerName: card_holder,
          expirationDate: `${
            card_expiration_month.length > 1 ? card_expiration_month : '0' + card_expiration_month
          }${card_expiration_year}`,
          securityNumber: '',
        };

        state.invoiceInfo = {
          tel,
          fax,
          address: `${prefecture} - ${address} - ${plot_number} - ${building_name}`,
          post_code,
          billing_address,
          business_registration_number,
        };
      }
    });
    builder.addCase(getAdminEmail.fulfilled, (state, action) => {
      state.dataAdminGmail = action.payload.items;
    });
  },
});

export const { setInvoiceInfo, setCreditCardInfo, resetInitialState } = paymentMasterSlice.actions;

export default paymentMasterSlice.reducer;
