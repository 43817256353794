import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Select, Table, Slider, Tooltip, AutoComplete, Popover } from 'antd';
import { useDrag, useDrop } from 'react-dnd';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import dayjs from 'dayjs';
import {
  ArrowLeftOutlined,
  FileExcelFilled,
  FileFilled,
  FileImageFilled,
  FilePdfFilled,
  FilePptFilled,
  FileTextOutlined,
  FileWordFilled,
  FilterOutlined,
  FolderAddFilled,
  FolderOpenFilled,
  LockOutlined,
  PlayCircleFilled,
  PlusOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';

import { FolderPermissionSetting, ConfirmDeleteModal, ConfirmPublish, UploadFile } from './Modal';
import { changeNodeAtID, getPathFolder } from 'libs/utils/explorer/tree-data-utils';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { useAppDispatch, usePermission, useResize } from 'hooks';
import { confirmDeleteModalRef } from './Modal/ConfirmDelete';
import { IconLocked, IconPublish, LogoNav } from 'assets';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { contextMenuRef } from 'components/ContextMenu';
import { Header, Input, ContextMenu } from 'components';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { PUBLISH } from 'constant/select.constants';
import FileExplorer from 'containers/FileExplorer';
import { alertCustomRef } from 'components/Alert';
import { formatNumber } from 'libs/utils/format';
import AlertPermission from './AlertPermission';
import { manualSelector } from './selectors';
import { routes } from 'navigations/routes';
import ManualStyled from './styles';
import * as Types from 'types';
import {
  clearFilterConditions,
  setActiveFolder,
  setFilterConditions,
  setFolderCreated,
  setIdDeleted,
  setManualFolderList,
} from './slice';
import {
  updateManualFile,
  updateManual,
  updateFolder,
  getListFolderPermission,
  getManualFolder,
  getListManual,
  getListManualFile,
  getDataManualSelect,
  getDataManualFileSelect,
  getDataListAffiliation,
} from './thunk';

const { Option } = Select;

const MAX_ZOOM = 2;
const MIN_ZOOM = 0.5;
const JUMP_ZOOM = 0.1;

interface Props {
  isMyManual?: boolean;
}

const Manual: React.FC<Props> = ({ isMyManual }) => {
  const [showConfirmImportFileModal, setShowConfirmImportFileModal] = useState<boolean>(false);
  const [openPopupConfirmPublish, setOpenPopupConfirmPublish] = useState<boolean>(false);
  const [fileSelected, setFileSelected] = useState<Types.FileExplorer.DataTableType>();
  const [openModalComplete, setOpenModalComplete] = useState<boolean>(false);
  const [valueInput, setValueInput] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [scaleNum, setScaleNum] = useState<number>(1);
  const [visibleTooltip] = useState<number>(-1);
  const [openModalFolderPermissionSetting, setOpenModalFolderPermissionSetting] =
    useState<boolean>(false);
  const [openPopupConfirmPublishComplete, setOpenPopupConfirmPublishComplete] =
    useState<boolean>(false);
  const [contextMenu, setContextMenu] = useState<{
    record?: Types.FileExplorer.DataTableType;
    visible: boolean;
    x?: number;
    y?: number;
  }>({
    visible: false,
  });

  const isFocus = useRef(false);

  const { permissionNumber } = usePermission();
  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const resizeRef = useRef<HTMLDivElement>(null);

  const { id } = useParams<{ id: string }>();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    manualFolderList,
    manualList,
    manualFileList,
    activeFolder,
    filterOptions,
    listFolderPermissions,
    manualFileSelect,
    manualSelect,
    dataAffiliations,
  } = useSelector(manualSelector);

  const { initResize, size, cursor, resizing } = useResize(resizeRef, {
    minWidth: 150,
    maxWidth: 1000,
  });

  const getFileType = (extension: string) => {
    switch (extension) {
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'Excel';
      case 'application/msword':
      case 'application/doc':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/ms-doc':
        return 'Word';
      case 'application/vnd.ms-powerpoint':
        return 'PPT';
      case 'application/pdf':
        return 'PDF';
      default:
        return extension.includes('image')
          ? 'Image'
          : extension.includes('video')
          ? `Video/${extension[6].toUpperCase() + extension.slice(7)}`
          : 'File';
    }
  };

  const columns = [
    {
      title: 'マニュアル/ファイル名',
      dataIndex: 'file_title',
      className: 'title',
      key: 'file_title',
      width: '53%',
      render: (title: string, item: Types.FileExplorer.DataTableType) => (
        <>
          <div className="item-row">
            {item.type === 'folder' ? (
              <FolderOpenFilled className="icon-folder" />
            ) : item.type === 'manual_file' ? (
              item.extension === 'application/vnd.ms-excel' ||
              item.extension ===
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? (
                <FileExcelFilled className="icon-excel" />
              ) : item.extension === 'application/msword' ||
                item.extension === 'application/doc' ||
                item.extension ===
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
                item.extension === 'application/application/ms-doc' ? (
                <FileWordFilled className="icon-word" />
              ) : item.extension === 'application/vnd.ms-powerpoint' ? (
                <FilePptFilled className="icon-ppt" />
              ) : item.extension === 'application/pdf' ? (
                <FilePdfFilled className="icon-pdf" />
              ) : item.extension.includes('image') ? (
                <FileImageFilled className="icon-image" />
              ) : item.extension.includes('video') ? (
                <PlayCircleFilled className="icon-play" />
              ) : (
                <FileFilled className="icon-file" />
              )
            ) : (
              <img src={LogoNav} className="icon-logo" alt="manual-file" />
            )}
            {isEdit &&
            fileSelected &&
            (fileSelected.type === 'folder'
              ? fileSelected.folder_id === item.folder_id
              : fileSelected.i_id_file_id === item.i_id_file_id) ? (
              <Formik
                initialValues={{ file_name: title }}
                onSubmit={async (values) => {
                  if (!values.file_name || !userInfo || !fileSelected.i_id) return;
                  dispatch(startLoading());
                  if (fileSelected.type === 'manual') {
                    const resultAction = await dispatch(
                      updateManual({
                        id: item.i_id_file_id,
                        data: {
                          item: {
                            manual_title: values.file_name,
                            updatedby: userInfo.login_id,
                            updatedat: new Date(),
                          },
                          is_force_update: true,
                        },
                      })
                    );
                    if (updateManual.fulfilled.match(resultAction)) {
                      await dispatch(
                        getListManual({
                          conditions: [
                            {
                              id: 'company_id',
                              search_value: [userInfo.company_id],
                            },
                            {
                              id: 'folder_id',
                              search_value: [activeFolder?.folder_id],
                            },
                          ],
                          sort_fields: [{ id: 'display_order', order: 'asc' }],
                          isMyManual: isMyManual,
                          page: 1,
                          per_page: 0,
                        })
                      );
                    }
                  } else if (fileSelected.type === 'manual_file') {
                    const resultAction = await dispatch(
                      updateManualFile({
                        id: item.i_id_file_id,
                        data: {
                          item: {
                            attachment_title: values.file_name,
                            updatedby: userInfo.login_id,
                            updatedat: new Date(),
                          },
                          is_force_update: true,
                        },
                      })
                    );
                    if (updateManualFile.fulfilled.match(resultAction)) {
                      await dispatch(
                        getListManualFile({
                          conditions: [
                            {
                              id: 'company_id',
                              search_value: [userInfo.company_id],
                            },
                            {
                              id: 'folder_id',
                              search_value: [activeFolder?.folder_id],
                            },
                          ],
                          sort_fields: [{ id: 'display_order', order: 'asc' }],
                          isMyManual: isMyManual,
                          page: 1,
                          per_page: 0,
                        })
                      );
                    }
                  } else {
                    const resultAction = await dispatch(
                      updateFolder({
                        id: fileSelected.i_id,
                        data: {
                          item: {
                            folder_name: values.file_name,
                            updatedby: userInfo.login_id,
                            updatedat: new Date(),
                          },
                          is_force_update: true,
                        },
                      })
                    );
                    if (updateFolder.fulfilled.match(resultAction)) {
                      await dispatch(
                        getManualFolder({
                          conditions: [
                            {
                              id: 'company_id',
                              search_value: [userInfo.company_id],
                            },
                          ],
                          sort_fields: [{ id: 'display_order', order: 'asc' }],
                          page: 1,
                          per_page: 0,
                        })
                      );
                    }
                  }
                  dispatch(stopLoading());
                  setFileSelected(undefined);
                }}
              >
                <Form
                  onBlur={() => {
                    setFileSelected(undefined);
                    isFocus.current = false;
                  }}
                >
                  <Form.Item name="file_name" style={{ margin: '0 0 0 10px' }}>
                    <Input
                      maxLength={60}
                      name="file_name"
                      style={{ padding: 4 }}
                      autoFocus
                      onFocus={() => {
                        isFocus.current = true;
                      }}
                    />
                  </Form.Item>
                </Form>
              </Formik>
            ) : (
              <div className="item-name">
                <p className="label-name">{title.substring(0, 40)}</p>
                <p className="label-name">{title.substring(40)}</p>
              </div>
            )}
          </div>
        </>
      ),
    },
    {
      title: 'ファイル形式',
      dataIndex: 'extension',
      key: 'extension',
      width: '12%',
      render: (title: string, item: Types.FileExplorer.DataTableType) =>
        item.type === 'folder'
          ? null
          : item.type === 'manual_file'
          ? getFileType(title)
          : 'SKILL FAMILIAR',
    },
    {
      title: 'ステータス',
      dataIndex: 'publish',
      className: 'center',
      key: 'publish',
      width: '10%',
      render: (publish: number, item: Types.FileExplorer.DataTableType) =>
        item.type !== 'folder' ? (
          <img src={publish === 1 ? IconPublish : IconLocked} className="icon" alt="publish-icon" />
        ) : null,
    },
    {
      title: '更新日時',
      dataIndex: 'updatedat',
      key: 'updatedat',
      width: '10%',
      render: (updatedat: string, item: Types.FileExplorer.DataTableType) =>
        updatedat
          ? dayjs(updatedat).format('YYYY/MM/DD')
          : item.createdat
          ? dayjs(item.createdat).format('YYYY/MM/DD')
          : '-',
    },
    {
      title: 'バージョン',
      dataIndex: 'file_version',
      key: 'file_version',
      width: '10%',
      render: (version: number, item: Types.FileExplorer.DataTableType) =>
        version && item.type !== 'folder' ? formatNumber(version) : '-',
    },
    {
      title: '概要',
      dataIndex: 'file_description',
      key: 'file_description',
      className: 'file-description',
      width: '5%',
      ellipsis: {
        showTitle: false,
      },
      render: (description: string, item: Types.FileExplorer.DataTableType, index: number) =>
        description && item.type !== 'folder' ? (
          <Popover
            content={description}
            color="white"
            trigger="click"
            placement="topRight"
            overlayStyle={{
              maxWidth: 840,
              minWidth: 50,
            }}
          >
            <FileTextOutlined
              className="icon"
              style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
            />
          </Popover>
        ) : (
          '-'
        ),
    },
  ];

  const increaseCount = () => {
    setScaleNum((prevState) => (prevState < MAX_ZOOM ? prevState + JUMP_ZOOM : MAX_ZOOM));
  };

  const decreaseCount = () => {
    setScaleNum((prevState) => (prevState > MIN_ZOOM ? prevState - JUMP_ZOOM : MIN_ZOOM));
  };

  const onChange = (defaultValue: number) => {
    setScaleNum(defaultValue);
  };

  const handleCreateFolder = () => {
    if (!userInfo || permissionNumber === 1) return;
    let newTreeData = [
      {
        i_id: 'creating',
        company_id: userInfo.company_id,
        isVirtualCreating: true,
        children: [],
      },
    ];
    if (activeFolder?.i_id) {
      newTreeData = changeNodeAtID({
        treeData: manualFolderList,
        i_id: activeFolder.i_id,
        newNode: (node: Types.FileExplorer.TreeItem): Types.FileExplorer.TreeItem => ({
          ...node,
          expanded: true,
          children: [
            ...(node.children || []),
            {
              i_id: 'creating',
              company_id: node.company_id,
              parent_folder_id: node.folder_id,
              isVirtualCreating: true,
              children: [],
            },
          ],
        }),
      });
    }

    dispatch(setManualFolderList(newTreeData));
  };

  const folderPath: Types.FileExplorer.TreeItem<Types.FileExplorer.FileExplorerType>[] = useMemo(
    () => getPathFolder(manualFolderList, activeFolder),
    [manualFolderList, activeFolder]
  );

  const accessPermission = useMemo(
    () =>
      (activeFolder &&
        listFolderPermissions.filter(
          (folder) =>
            folder.folder_id === activeFolder.folder_id && userInfo?.login_id === folder.login_id
        ).length) ||
      (activeFolder &&
        dataAffiliations.some((affiliation) => {
          return listFolderPermissions.some(
            (permission) =>
              permission.affiliation_id === affiliation.affiliation_id &&
              permission.folder_id === activeFolder.folder_id
          );
        })),
    [activeFolder, dataAffiliations, listFolderPermissions, userInfo]
  );

  const handleBackFolderLeft = useCallback(() => {
    if (folderPath.length > 1) {
      dispatch(setActiveFolder(folderPath[folderPath.length - 2]));
    }
  }, [folderPath, dispatch]);

  const handleMoveFolder = async (
    dragItem: Types.FileExplorer.TreeItem,
    dropItem: Types.FileExplorer.TreeItem
  ) => {
    if (!dragItem.i_id || !userInfo) return;
    dispatch(startLoading());
    const resultAction = await dispatch(
      updateFolder({
        id: dragItem.i_id,
        data: {
          item: {
            parent_folder_id: dropItem.folder_id,
            display_order: (dropItem.children?.length || 0) + 1,
            updatedby: userInfo.login_id,
            updatedat: new Date(),
          },
          is_force_update: true,
          return_item_result: true,
          use_display_id: true,
        },
      })
    );
    if (updateFolder.fulfilled.match(resultAction)) {
      await dispatch(setFolderCreated(resultAction.payload.item));
      await dispatch(setIdDeleted(resultAction.payload.item.i_id));
      await dispatch(
        getManualFolder({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          sort_fields: [{ id: 'display_order', order: 'asc' }],
          page: 1,
          per_page: 0,
        })
      );
    }
    dispatch(stopLoading());
  };

  const handleMoveFile = async (
    dragItem: Types.FileExplorer.DataTableType,
    dropItem: Types.FileExplorer.TreeItem
  ) => {
    if (!dragItem.i_id_file_id || !userInfo) return;
    dispatch(startLoading());
    if (dragItem.type === 'manual') {
      const resultAction = await dispatch(
        updateManual({
          id: dragItem.i_id_file_id,
          data: {
            item: {
              folder_id: dropItem.folder_id,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateManual.fulfilled.match(resultAction)) {
        await dispatch(
          getListManual({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder?.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        );
      }
    } else if (dragItem.type === 'manual_file') {
      const resultAction = await dispatch(
        updateManualFile({
          id: dragItem.i_id_file_id,
          data: {
            item: {
              folder_id: dropItem.folder_id,
              updatedby: userInfo.login_id,
              updatedat: new Date(),
            },
            is_force_update: true,
          },
        })
      );
      if (updateManualFile.fulfilled.match(resultAction)) {
        await dispatch(
          getListManualFile({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder?.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        );
      }
    }
    dispatch(stopLoading());
  };

  const DraggableBodyRow: React.FC<
    { record: Types.FileExplorer.DataTableType } & React.HTMLAttributes<HTMLTableRowElement>
  > = ({ record, ...restProps }) => {
    const ref = useRef<HTMLTableRowElement>(null);

    const [, drop] = useDrop<
      { type: string; record: Types.FileExplorer.DataTableType },
      void,
      void
    >({
      accept: 'move-file',
      canDrop: (item) =>
        record.type === 'folder' &&
        item.record.folder_id !== record.folder_id &&
        !resizeRef &&
        !resizing,
      drop: (item) => {
        if (item.record.type === 'folder') {
          handleMoveFolder(item.record, record);
        } else {
          handleMoveFile(item.record, record);
        }
      },
    });

    const [, drag] = useDrag({
      item: { record, type: 'move-file' },
      canDrag: !resizing,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drop(drag(ref));

    return <tr ref={ref} {...restProps} />;
  };

  useEffect(() => {
    if (!userInfo || !activeFolder || !accessPermission) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getListManual({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            publish: filterOptions.publish,
            file_title: filterOptions.name,
            isMyManual: isMyManual,
            include_links: true,
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getListManualFile({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'folder_id',
                search_value: [activeFolder.folder_id],
              },
            ],
            include_links: true,
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            publish: filterOptions.publish,
            file_title: filterOptions.name,
            isMyManual: isMyManual,
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [activeFolder, dispatch, userInfo, filterOptions, isMyManual, accessPermission]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getListFolderPermission({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getManualFolder({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [{ id: 'display_order', order: 'asc' }],
            page: 1,
            per_page: 0,
            id,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();

    return () => {
      dispatch(setActiveFolder(undefined));
      dispatch(clearFilterConditions());
    };
  }, [dispatch, userInfo, id]);

  useEffect(() => {
    if (!userInfo) return;
    dispatch(startLoading());
    dispatch(
      getDataManualSelect({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(
      getDataManualFileSelect({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(
      getDataListAffiliation({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_links: true,
      })
    );
    dispatch(stopLoading());
  }, [dispatch]);

  return (
    <ManualStyled>
      <Header title={headerTitle} className="header">
        <div className="wrap-filter">
          <FilterOutlined className="filter-icon" />
          <div className="form-input">
            <Select
              placeholder="公開ステータス"
              className="select-input"
              value={filterOptions.publish}
              onChange={(value: string) =>
                dispatch(
                  setFilterConditions({
                    publish: value,
                  })
                )
              }
            >
              {PUBLISH.map(({ value, label }, index) => (
                <Option key={index} value={value?.toString()}>
                  {Number(value) === 1 ? (
                    <>
                      <img src={IconPublish} className="icon" alt="publish-icon" />
                      <span>{label}</span>
                    </>
                  ) : (
                    <>
                      <img src={IconLocked} className="icon" alt="block-icon" />
                      <span>{label}</span>
                    </>
                  )}
                </Option>
              ))}
            </Select>
          </div>
          <div className="form-input">
            <AutoComplete
              style={{ width: 200 }}
              options={[...manualSelect, ...manualFileSelect].map((option) => ({
                value: option.file_title,
              }))}
              placeholder="マニュアル/ファイル名で検索"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().includes(inputValue.toUpperCase())
              }
              value={valueInput}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                let typingTimer;
                if (event.key === 'Enter') {
                  dispatch(setFilterConditions({ name: valueInput }));
                } else {
                  clearTimeout(typingTimer);
                  typingTimer = setTimeout(() => {
                    dispatch(setFilterConditions({ name: valueInput }));
                  }, 1000);
                }
              }}
              onChange={(e: string) => {
                setValueInput(e);
              }}
            />
          </div>
          <button
            type="button"
            className="text-reset"
            onClick={() => {
              dispatch(clearFilterConditions());
              setValueInput('');
            }}
          >
            クリア
          </button>
        </div>
      </Header>
      <div className="wrap-path-folder">
        <div className="option">
          <div className="button-director-folder">
            <button
              className="back-button"
              disabled={folderPath.length < 2}
              onClick={handleBackFolderLeft}
            >
              <ArrowLeftOutlined className="icon-arrow" />
            </button>
          </div>
          <div className="divider" />
          <div className="path-folder">
            <FolderOpenFilled disabled className="icon-folder" />
            {folderPath.map((item, index) => (
              <div key={index} className="folder">
                <RightOutlined className="icon-right" />
                <button className="prev-folder" onClick={() => dispatch(setActiveFolder(item))}>
                  {item.folder_name}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="group-btn">
          <button
            className="btn btn-primary"
            onClick={() => setShowConfirmImportFileModal(true)}
            disabled={!activeFolder || !accessPermission || isDisabledUpload}
          >
            ファイル取込
          </button>
          <button
            disabled={permissionNumber === 1}
            className={`btn ${permissionNumber === 1 ? 'disabled' : 'btn-active'}`}
            onClick={() => {
              navigate(routes.CreateManual.path);
            }}
          >
            <PlusOutlined className="icon-plus" />
            新規作成
          </button>
        </div>
      </div>
      <div className="wrap-body">
        <div ref={resizeRef} className="left-side">
          <div className="slider">
            <div className="item">
              <ZoomOutOutlined className="icon" onClick={decreaseCount} />
              <Slider
                value={scaleNum}
                min={MIN_ZOOM}
                max={MAX_ZOOM}
                className="item-slider"
                step={JUMP_ZOOM}
                onChange={onChange}
              />
              <ZoomInOutlined className="icon" onClick={increaseCount} />
            </div>
            <div className="divider" />
            <Tooltip placement="top" title="フォルダ新規作成">
              <FolderAddFilled
                onClick={handleCreateFolder}
                className={permissionNumber === 1 ? 'icon-add-disabled' : 'icon-add'}
              />
            </Tooltip>
          </div>
          <FileExplorer
            scaleNum={scaleNum}
            resizeRef={resizeRef}
            handleMoveFile={handleMoveFile}
            handleMoveFolder={handleMoveFolder}
          />
        </div>
        <div onMouseDown={initResize} className="divider-resize" style={{ cursor }} />
        <div
          className="right-side"
          style={{
            width: `calc(100% - ${size.width}px - 5px`,
          }}
        >
          <div className="header-table">
            {columns.map((column, index) => (
              <div
                key={index}
                className="column"
                style={{
                  width: column.width,
                  textAlign: index === 2 || index === 5 ? 'center' : 'left',
                }}
              >
                <span className="title">{column.title}</span>
              </div>
            ))}
          </div>
          {accessPermission || !activeFolder ? (
            <Table
              dataSource={
                [
                  ...(activeFolder?.children
                    ? activeFolder.children.map((folder, index) => ({
                        ...folder,
                        index,
                        type: 'folder',
                        file_title: folder.folder_name,
                      }))
                    : []),
                  ...manualList.map((file, index) => ({
                    ...file,
                    index: index + (activeFolder?.children?.length || 0),
                    type: 'manual',
                  })),
                  ...manualFileList.map((file, index) => ({
                    ...file,
                    index: index + manualList.length + (activeFolder?.children?.length || 0),
                    type: 'manual_file',
                  })),
                ] as Types.FileExplorer.DataTableType[]
              }
              columns={columns}
              pagination={false}
              rowKey="index"
              expandable={{
                expandRowByClick: false,
                expandIcon: () => null,
              }}
              showHeader={false}
              onRow={(record: Types.FileExplorer.DataTableType) => ({
                record,
                onClick: (e: any) => {
                  if (isFocus.current) {
                    return;
                  }
                  if (!resizing) {
                    if (record.type === 'folder' && activeFolder) {
                      const child = activeFolder.children?.find((c) => c.i_id === record.i_id);
                      if (child) {
                        dispatch(setActiveFolder(child));
                      }
                    } else if (
                      record.type === 'manual' &&
                      e.target &&
                      typeof e.target.className === 'string' &&
                      !e.target.className.includes('file-description')
                    ) {
                      navigate(
                        generatePath(routes.EditManual.path, {
                          id: record.i_id_file_id,
                        }),
                        { state: permissionNumber }
                      );
                    } else if (
                      record.type === 'manual_file' &&
                      e.target &&
                      typeof e.target.className === 'string' &&
                      !e.target.className.includes('file-description')
                    ) {
                      navigate(
                        generatePath(routes.ViewManual.path, {
                          id: record.i_id_file_id,
                        }),
                        { state: record }
                      );
                    }
                  }
                },
                onContextMenu: (event) => {
                  event.preventDefault();
                  const contextMenuHeight = 180;
                  let newY = event.clientY;
                  if (window.innerHeight - newY < contextMenuHeight) {
                    newY = window.innerHeight - contextMenuHeight;
                  }

                  if (!contextMenu.visible) {
                    document.addEventListener('click', function onClickOutside() {
                      setContextMenu({ visible: false });
                      document.removeEventListener('click', onClickOutside);
                    });
                  }
                  if (
                    listFolderPermissions.some((folder) => folder.folder_id === record.folder_id)
                  ) {
                    setContextMenu({
                      record,
                      visible: true,
                      x: event.clientX,
                      y: newY,
                    });
                  } else {
                    setContextMenu({ visible: false });
                    alertCustomRef.current?.showAlert({
                      children: <AlertPermission />,
                    });
                  }
                },
              })}
              components={{
                body: {
                  row: DraggableBodyRow,
                },
              }}
            />
          ) : (
            <div className="access-denied">
              <LockOutlined className="icon-lock" />
              <span className="content">
                権限がないため、このフォルダにアクセスできません。
                <br />
                管理者に権限をリクエストしてください。
              </span>
            </div>
          )}
        </div>
        <ContextMenu
          routes={routes}
          ref={contextMenuRef}
          setIsEdit={setIsEdit}
          isMyManual={isMyManual}
          contextMenu={contextMenu}
          setFileSelected={setFileSelected}
          setOpenPopupConfirmPublish={setOpenPopupConfirmPublish}
          setOpenModalFolderPermissionSetting={setOpenModalFolderPermissionSetting}
        />
      </div>
      <UploadFile
        isMyManual={isMyManual}
        visible={showConfirmImportFileModal}
        setVisible={setShowConfirmImportFileModal}
      />
      <CompletedModal
        title="アップロードが完了しました"
        visible={openModalComplete}
        setVisible={setOpenModalComplete}
      />
      <ConfirmPublish
        isMyManual={isMyManual}
        node={fileSelected}
        visible={openPopupConfirmPublish}
        setVisible={setOpenPopupConfirmPublish}
      />
      <CompletedModal
        title="設定が完了しました"
        visible={openPopupConfirmPublishComplete}
        setVisible={setOpenPopupConfirmPublishComplete}
        onSubmit={() => setOpenPopupConfirmPublish(false)}
      />
      <FolderPermissionSetting
        fileSelected={fileSelected}
        visible={openModalFolderPermissionSetting}
        setVisible={setOpenModalFolderPermissionSetting}
      />
      <ConfirmDeleteModal ref={confirmDeleteModalRef} />
    </ManualStyled>
  );
};

export default Manual;
