import React, { useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { administratorMasterSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { administratorMasterSelector } from './selectors';
import CompletedModal from 'components/Modal/Completed';
import { AdministratorMasterFormik } from 'types';
import { setInformationRegister } from './slice';
import { updateInformation } from './thunk';
import { useAppDispatch } from 'hooks';
import { Input } from 'components';

interface Props {
  goBack: () => void;
}

const ConfirmAdministratorMaster: React.FC<Props> = ({ goBack }) => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { information } = useSelector(administratorMasterSelector);
  const { messages } = useIntl();

  const formik = useFormik<AdministratorMasterFormik>({
    initialValues: information,
    validationSchema: administratorMasterSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!information.i_id) return;

      dispatch(startLoading());
      const resultAction = await dispatch(
        updateInformation({
          id: information.i_id,
          data: {
            item: values,
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      if (updateInformation.fulfilled.match(resultAction)) {
        dispatch(setInformationRegister(values));
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
      dispatch(stopLoading());
    },
  });

  return (
    <div className="container confirm">
      <div className="description">
        <p>編集内容に問題がなければ、更新ボタンをクリックしてください。</p>
      </div>
      <FormikProvider value={formik}>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
        >
          <div className="form">
            <Form.Item
              name="plan_name"
              label={<span className="text-label">利用プラン</span>}
              className="form-input"
            >
              <Input name="plan_name" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="number_of_users"
              label={<span className="text-label">利用ユーザー数</span>}
              className="form-input wrap-number-users"
            >
              <Input
                name="number_of_users"
                className="input__disabled number-users"
                type="number"
                inputMode="numeric"
                disabled
              />
              <span className="suffix">人</span>
            </Form.Item>
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  {messages['AJ-02-7']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="name" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-8']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="name_furigana" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="postal_code"
              label={
                <span className="text-label">
                  {messages['AJ-02-9']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input wrap-input-zip-code"
            >
              <Input name="postal_code" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="prefecture"
              label={
                <span className="text-label">
                  {messages['AJ-02-11']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="prefecture" type="text" className="input__disabled" disabled />
            </Form.Item>
            <Form.Item
              name="address"
              label={
                <span className="text-label">
                  {messages['AJ-02-12']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="address" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="plot_number"
              label={
                <span className="text-label">
                  {messages['AJ-02-13']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="plot_number" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">{messages['AJ-02-14']}</span>}
              className="form-input"
            >
              <Input name="building_name" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={
                <span className="text-label">
                  {messages['AJ-02-15']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_name" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={
                <span className="text-label">
                  {messages['AJ-02-16']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_name_furigana" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_department"
              label={
                <span className="text-label">
                  {messages['AJ-02-17']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_department" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_position"
              label={
                <span className="text-label">
                  {messages['AJ-02-18']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_position" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_phone"
              label={
                <span className="text-label">
                  {messages['AJ-02-19']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_phone" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_email"
              label={
                <span className="text-label">
                  {messages['AJ-02-20']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_email" className="input__disabled" type="text" disabled />
            </Form.Item>
            <Form.Item
              name="admin_email_confirm"
              label={
                <span className="text-label">
                  管理者メールアドレス（確認)<span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input name="admin_email_confirm" className="input__disabled" type="text" disabled />
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">更新</SubmitButton>
            <button disabled={formik.isSubmitting} className="cancel-btn" onClick={goBack}>
              戻る
            </button>
          </div>
        </Form>
      </FormikProvider>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="カード情報の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </div>
  );
};

export default ConfirmAdministratorMaster;
