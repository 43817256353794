import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { LeftOutlined, CaretRightFilled, RightOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Carousel, Col, Image, Progress, Radio, Row } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { includes, isNumber } from 'lodash';
import { RadioChangeEvent } from 'antd/es';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player';

import { startLoading, stopLoading, toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { newSkillCheckSelectionSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import SkillCheckStatusStyled from './styles';
import ModalPreview from './ModalPreview';
import { resetDataEdit } from '../slice';
import { useAppDispatch } from 'hooks';
import { LogoSF } from 'assets';
import * as Types from 'types';
import {
  editUserAssignSillCheck,
  editSkillCheckTrans,
  getDataItem,
  getDataQuestion,
  createQuestionTrans,
} from '../thunk';

interface Props {
  dataGuideline?: Types.ItemSkillCheckImpleType;
  setStatusIndex: React.Dispatch<React.SetStateAction<number>>;
}

const dataAnswer = ['A', 'B', 'C'];

const SkillCheckStatus: React.FC<Props> = ({ setStatusIndex, dataGuideline }) => {
  const [isPreviewImageAnswer, setPreviewImageAnswer] = useState<number>();
  const [isPreviewVisible, setPreviewVisible] = useState<number>();
  const [carouselIndex, setCarouselIndex] = useState<number>(0);
  const [timeComplete, setTimeComplete] = useState<number>(0);
  const [score, setScore] = useState<number | undefined>(0);
  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [valueQuestion, setValueQuestion] = useState('');
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [timer, setTimer] = useState(0);

  const dispatch = useAppDispatch();

  const slider = useRef<CarouselRef>(null);
  const interValRef = useRef();

  const { dataCreate, dataEdit, dataItem, dataQuestion, time_limit } = useSelector(
    newSkillCheckSelectionSelector
  );

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const questionContent = useMemo(() => dataQuestion[tabIndex], [dataQuestion, tabIndex]);

  const timeCount = useMemo(() => {
    const timeLimit = time_limit.find(
      (e) => Number(e.code) === dataQuestion[tabIndex]?.check_time_limit
    );
    if (!dataQuestion.length) return 0;
    if (includes(timeLimit?.name, '分')) {
      return Number(timeLimit?.name.split('分').join('')) * 60;
    }
    if (includes(timeLimit?.name, '時間')) {
      return Number(timeLimit?.name.split('時間').join('')) * 3600;
    }
    return Number(timeLimit?.name.split('秒').join('')) || 60;
  }, [dataQuestion, tabIndex, time_limit]);

  const countDown = useMemo(() => {
    const minuteConvert = Math.floor(timer / 60);
    const miliConvert = timer - minuteConvert * 60;
    return { minute: minuteConvert, mili: miliConvert };
  }, [timer]);

  const onSubmit = async () => {
    handleNextTab();
    await setTimeComplete(timer);
    const timePass = timeComplete ? timeComplete - timer : timeCount - timer;
    handleEditItem(timePass);
  };
  const handleNext = () => slider.current?.next();

  const handlePrev = () => slider.current?.prev();

  const onChange = (e: RadioChangeEvent) => {
    setValueQuestion(e.target.value);
  };

  const toggleSelect = () => {
    setIsSelect(true);
  };

  const dataEditQuestion = (
    data: Types.CreateItemResponseType,
    answer: number,
    isSkip: boolean
  ) => {
    const updatedCorrect = data.item.correct_answers_num + answer;
    return {
      id: data.item_id,
      data: {
        item: {
          login_id: data.item.login_id,
          skill_check_code: data.item.skill_check_code,
          responses_num:
            isSkip || valueQuestion === 'D' ? data.item.responses_num : data.item.responses_num + 1,
          correct_answers_num: updatedCorrect,
          last_action: String(Number(data.item.last_action) + 1),
          implementation_end_date: new Date(),
          tran_status: data.item.tran_status,
          acquisition_score: data.item.acquisition_score,
        },
        return_item_result: true,
        use_display_id: true,
        is_force_update: true,
      },
    };
  };

  const handleSkipQuestion = async (isSkip: boolean) => {
    setStatusIndex(3);
    const itemFilter = dataItem.filter(
      (item) =>
        item.login_id === dataCreate?.item?.login_id &&
        item.skill_check_code === dataCreate?.item?.skill_check_code
    );
    await Promise.all(
      dataQuestion.slice(tabIndex).map((e) =>
        dispatch(
          createQuestionTrans({
            item: {
              login_id: dataCreate?.item?.login_id,
              skillcheck_tid: dataCreate?.item?.skillcheck_tid,
              code: e.question_code,
              correct: -1,
              implementation_date: new Date(),
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            return_item_result: true,
          })
        )
      )
    );
    await dispatch(
      editSkillCheckTrans(
        dataEditQuestion(
          {
            item_id: dataCreate!.item_id,
            error: dataEdit?.error || dataCreate!.error,
            history_id: dataCreate!.history_id,
            item: {
              ...(dataEdit?.item || dataCreate!.item),
              tran_status: tabIndex === dataQuestion.length - 1 ? 2 : 1,
              acquisition_score:
                valueQuestion === dataQuestion[tabIndex].answer
                  ? dataQuestion[tabIndex].score + score!
                  : score!,
            },
          },
          Number(valueQuestion === questionContent?.answer),
          isSkip
        )
      )
    );
    await dispatch(
      editUserAssignSillCheck({
        id: itemFilter[0].i_id,
        data: {
          item: {
            implementation_status: 2,
          },
          use_display_id: true,
          is_force_update: true,
        },
      })
    );
  };

  const handleEditItem = async (time: number) => {
    if (!dataCreate) return;
    dispatch(startLoading());
    await Promise.all([
      (async () => {
        const actionResult = await dispatch(
          editSkillCheckTrans(
            dataEditQuestion(
              {
                item_id: dataCreate.item_id,
                error: dataEdit?.error || dataCreate.error,
                history_id: dataCreate.history_id,
                item: {
                  ...(dataEdit?.item || dataCreate.item),
                  tran_status: tabIndex === dataQuestion.length - 1 ? 2 : 1,
                  acquisition_score:
                    valueQuestion === dataQuestion[tabIndex].answer
                      ? dataQuestion[tabIndex].score + score!
                      : score!,
                },
              },
              Number(valueQuestion === questionContent?.answer),
              false
            )
          )
        );
        if (editSkillCheckTrans.fulfilled.match(actionResult)) {
          setScore(actionResult.payload.item?.acquisition_score);
        }
        return actionResult;
      })(),
      dispatch(
        createQuestionTrans({
          item: {
            login_id: dataCreate?.item?.login_id,
            skillcheck_tid: dataCreate?.item?.skillcheck_tid,
            code: questionContent?.question_code,
            correct: valueQuestion === 'D' ? -1 : valueQuestion === questionContent?.answer ? 1 : 0,
            implementation_date: new Date(),
            createdat: new Date(),
            createdby: userInfo?.login_id,
            answer_time: time,
            user_answer: valueQuestion,
          },
          return_item_result: true,
        })
      ),
    ]);
    if (tabIndex === dataQuestion.length - 1) {
      const itemFilter = dataItem.filter(
        (item) =>
          item.login_id === dataCreate?.item?.login_id &&
          item.skill_check_code === dataCreate?.item?.skill_check_code
      );
      await dispatch(
        editUserAssignSillCheck({
          id: itemFilter[0].i_id,
          data: {
            item: {
              implementation_status: 2,
            },
            use_display_id: true,
            is_force_update: true,
          },
        })
      );
    }
    dispatch(stopLoading());
  };

  const handleNextTab = useCallback(() => {
    setValueQuestion('');
    setTabIndex((prevState) => {
      const newTabIndex = prevState + 1;
      if (newTabIndex === dataQuestion.length) {
        setStatusIndex(3);
      }
      return newTabIndex;
    });
  }, [dataQuestion, setStatusIndex]);

  useEffect(() => {
    if (!loading) {
      interValRef.current = setInterval(() => {
        setTimer((time) => {
          const value = time - 1;
          if (value === 0) {
            clearInterval(interValRef.current);
            handleSkipQuestion(true);
          }
          return value;
        });
      }, 1000) as unknown as undefined;
      return () => {
        clearInterval(interValRef.current);
      };
    }
  }, [loading, onSubmit, timeCount]);

  useEffect(() => {
    setTimer(timeCount);
  }, [timeCount]);

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
      dispatch(resetDataEdit());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataItem({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        ),
        dispatch(
          getDataQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'skill_check_code',
                search_value: [dataGuideline?.skill_check_code],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataGuideline, dispatch, userInfo]);

  return (
    <SkillCheckStatusStyled
      isPreviewVisible={isNumber(isPreviewVisible)}
      isSelect={!!valueQuestion}
    >
      <div className="header">
        <img src={LogoSF} className="image-logo" alt="skill-familiar" />
        <p className="text-header">{dataGuideline?.skill_check_name}</p>
        <button
          className="btn-header"
          onClick={() => {
            handleSkipQuestion(true);
          }}
        >
          終了
        </button>
      </div>
      <div className="remain-time-child">
        <Progress
          width={130}
          className="number-time"
          type="circle"
          percent={(timer / timeCount) * 100}
          strokeColor={{
            '0%': '#B1EFE4',
            '100%': '#31BBC3',
          }}
          format={() => {
            return (
              <>
                <span className="label-time">残り時間</span>
                <br />
                <span className="label-number">
                  {countDown?.minute.toString().padStart(3, '0')}:{countDown?.mili}
                </span>
              </>
            );
          }}
        />
      </div>
      <div className="body">
        <p className="title">
          設問を読んで、解答選択肢から一つを選択し、Answerボタンをクリックしてください。
        </p>
        <div className="item-body">
          <Row className="wrap-answer">
            <Col span={3} className="number">
              <div className="count-number">
                <p className="count-fist">{tabIndex + 1}</p>
                <p className="count-last">
                  <span>/</span>
                  {dataQuestion.length}
                </p>
              </div>
            </Col>
            <Col span={21} className="label">
              <p className="text-label">{questionContent?.question_name}</p>
            </Col>
          </Row>
          <Row className="wrap-item">
            <Col className="questions" span={14}>
              <p>{questionContent?.question_description}</p>
              <div className="item-question">
                {questionContent?.question_attach_file &&
                questionContent?.question_attach_file?.length > 1 ? (
                  <>
                    <button className="btn-next" onClick={handlePrev}>
                      <LeftOutlined className="icon" />
                    </button>
                    <Carousel
                      beforeChange={(_currentSlide, nextSlide) => {
                        setCarouselIndex(nextSlide);
                      }}
                      className="carousel"
                      ref={slider}
                    >
                      {questionContent?.question_attach_file?.map((url, i) => (
                        <div className="wrap-item" key={i}>
                          <div className="item-image">
                            {url.includes('png') || url.includes('jpg') || url.includes('jpeg') ? (
                              <>
                                <Image className="image-attach" src={url} preview={false} />
                                <ModalPreview
                                  index={i}
                                  isPreviewVisible={isPreviewVisible}
                                  item={url}
                                  setPreviewImageAnswer={setPreviewVisible}
                                />
                                <div className="zoom-image">
                                  <ZoomInOutlined
                                    onClick={() => setPreviewVisible(carouselIndex)}
                                    className="icon-zoom"
                                  />
                                </div>
                              </>
                            ) : url.includes('mp4') ? (
                              <>
                                <ReactPlayer
                                  width={'100%'}
                                  height={'100%'}
                                  url={url}
                                  controls
                                  config={{
                                    file: {
                                      attributes: {
                                        disablePictureInPicture: true,
                                        controlsList: 'nodownload noplaybackrate',
                                      },
                                    },
                                  }}
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      ))}
                    </Carousel>
                    <button className="btn-next" onClick={handleNext}>
                      <RightOutlined className="icon" />
                    </button>
                  </>
                ) : questionContent?.question_attach_file?.length ? (
                  <>
                    <div className="item-image">
                      {questionContent?.question_attach_file[0]?.includes('png') ||
                      questionContent?.question_attach_file[0]?.includes('jpg') ||
                      questionContent?.question_attach_file[0]?.includes('jpeg') ? (
                        <>
                          <Image
                            className="image-attach"
                            src={questionContent?.question_attach_file[0]}
                            preview={false}
                          />
                          <div className="zoom-image">
                            <ZoomInOutlined
                              onClick={() => setPreviewVisible(0)}
                              className="icon-zoom"
                            />
                          </div>
                        </>
                      ) : questionContent?.question_attach_file[0]?.includes('mp4') ? (
                        <ReactPlayer
                          width={'100%'}
                          height={'100%'}
                          url={questionContent?.question_attach_file[0]}
                          controls
                          config={{
                            file: {
                              attributes: {
                                disablePictureInPicture: true,
                                controlsList: 'nodownload noplaybackrate',
                              },
                            },
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <ModalPreview
                      index={0}
                      isPreviewVisible={isPreviewVisible}
                      setPreviewImageAnswer={setPreviewVisible}
                      item={questionContent?.question_attach_file[0]}
                    />
                  </>
                ) : null}
              </div>
              {questionContent?.question_attach_file &&
                questionContent?.question_attach_file?.length > 1 && (
                  <p className="text-label">
                    ファイル{carouselIndex === 0 ? '①' : carouselIndex === 1 ? '②' : '③'}
                  </p>
                )}
            </Col>
            <Col className="answer" span={10}>
              <p className="title-answer"> - 解答 -</p>
              <Radio.Group onChange={onChange} value={valueQuestion} buttonStyle="solid">
                {dataAnswer.map((answer, index) => (
                  <Radio.Button
                    checked={isSelect}
                    onClick={toggleSelect}
                    value={answer}
                    key={index}
                  >
                    <div className="wrap-question">
                      <div className="text-label">
                        <p className="label-answer">{answer}</p>
                        <p className="content-answer">
                          {answer === 'A'
                            ? questionContent?.problems1
                            : answer === 'B'
                            ? questionContent?.problems2
                            : questionContent?.problems3}
                        </p>
                      </div>
                      {answer === 'A' && questionContent?.problems1_attach_file ? (
                        <div className="img-problem">
                          {questionContent?.problems1_attach_file?.includes('png') ||
                          questionContent?.problems1_attach_file?.includes('jpg') ||
                          questionContent?.problems1_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={questionContent?.problems1_attach_file}
                                alt=""
                              />
                            </div>
                          ) : questionContent?.problems1_attach_file?.includes('mp4') ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={'100%'}
                                  height={'100%'}
                                  url={questionContent?.problems1_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <ModalPreview
                            index={0}
                            isPreviewVisible={isPreviewImageAnswer}
                            item={questionContent?.problems1_attach_file}
                            setPreviewImageAnswer={setPreviewImageAnswer}
                          />
                        </div>
                      ) : answer === 'B' && questionContent?.problems2_attach_file ? (
                        <div className="img-problem">
                          {questionContent?.problems2_attach_file?.includes('png') ||
                          questionContent?.problems2_attach_file?.includes('jpg') ||
                          questionContent?.problems2_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={questionContent?.problems2_attach_file}
                                alt=""
                              />
                            </div>
                          ) : questionContent?.problems2_attach_file?.includes('mp4') ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={'100%'}
                                  height={'100%'}
                                  url={questionContent?.problems2_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          <ModalPreview
                            index={1}
                            isPreviewVisible={isPreviewImageAnswer}
                            item={questionContent?.problems2_attach_file}
                            setPreviewImageAnswer={setPreviewImageAnswer}
                          />
                        </div>
                      ) : answer === 'C' && questionContent?.problems3_attach_file ? (
                        <div className="img-problem">
                          {questionContent?.problems3_attach_file?.includes('png') ||
                          questionContent?.problems3_attach_file?.includes('jpg') ||
                          questionContent?.problems3_attach_file?.includes('jpeg') ? (
                            <div className="image-attach">
                              <img
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setPreviewImageAnswer(index);
                                }}
                                className="image-answer"
                                src={questionContent?.problems3_attach_file}
                                alt=""
                              />
                            </div>
                          ) : questionContent?.problems3_attach_file?.includes('mp4') ? (
                            <div
                              className="image-attach"
                              onClick={() => {
                                setPreviewImageAnswer(index);
                              }}
                            >
                              <div className="wrap-video">
                                <ReactPlayer
                                  width={'100%'}
                                  height={'100%'}
                                  url={questionContent?.problems3_attach_file}
                                />
                                <div className="icon-play">
                                  <CaretRightFilled className="icon" />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : null}
                      <ModalPreview
                        index={2}
                        isPreviewVisible={isPreviewImageAnswer}
                        item={questionContent?.problems3_attach_file}
                        setPreviewImageAnswer={setPreviewImageAnswer}
                      />
                    </div>
                  </Radio.Button>
                ))}
                {questionContent?.answer_d && (
                  <Radio.Button checked={isSelect} onClick={toggleSelect} value="D">
                    <div className="wrap-question">
                      <div className="text-label">
                        <p className="label-answer">D</p>
                        <p className="content-answer">未経験</p>
                      </div>
                    </div>
                  </Radio.Button>
                )}
              </Radio.Group>
              <div className="btn-answer-active">
                <button className="btn" disabled={!valueQuestion} onClick={onSubmit}>
                  Answer
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </SkillCheckStatusStyled>
  );
};

export default SkillCheckStatus;
