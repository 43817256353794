import React from 'react';

import { useSelector } from 'react-redux';
import { Select } from 'antd';
import dayjs from 'dayjs';

import { BarChartOutlined, CloseOutlined, TableOutlined } from '@ant-design/icons';

import { settingSelector } from 'containers/AppSettings/selectors';
import MultipleComparison from '../MultipleComparison';
import { DatePicker, Header } from 'components';
import { UserType } from 'types/config';
import Comparison from '../Comparison';
import Wrapper from './styles';
import * as Types from 'types';
import {
  ITEM_COMPARISION_ANALYSIS,
  ITEM_COMPARISION_USER_ANALYSIS_CHART,
  ITEM_COMPARISION_USER_ANALYSIS_TABLE,
  REPORT_TYPE,
} from 'constant/select.constants';

const { Option } = Select;

interface Props {
  selectAnalysisGroup: Types.SelectAnalysisGroup.ResponseType[];
  selectInterviewUser: Types.SelectInterviewUser.ResponseType[];
  selectEmployeeUser: Types.SelectEmployeeUser.ResponseType[];
  reportType: (typeof REPORT_TYPE)[number]['value'];
  startPeriod?: string;
  compareType: number;
  userType: UserType;
  endPeriod?: string;
  groupCode?: string;
  segmented: number;
  loginID?: string;
  itemComparision: (
    | typeof ITEM_COMPARISION_USER_ANALYSIS_TABLE
    | typeof ITEM_COMPARISION_USER_ANALYSIS_CHART
  )[number]['value'];
}

const FilePDFExport: React.FC<Props> = ({
  selectInterviewUser,
  selectAnalysisGroup,
  selectEmployeeUser,
  itemComparision,
  compareType,
  startPeriod,
  reportType,
  endPeriod,
  groupCode,
  segmented,
  userType,
  loginID,
}) => {
  const { headerTitle } = useSelector(settingSelector);

  return (
    <Wrapper>
      <Header title={headerTitle} exportPdf />
      <div className="top-page">
        <div className="wrapper">
          <div className="type-select">
            <span className="number-type">01</span>
            <span className="label">ユーザー種別</span>
            <Select className="select" placeholder="社内" value={userType}>
              <Option value="member">社内</Option>
              <Option value="interview">面接ユーザー</Option>
            </Select>
          </div>
          <CloseOutlined className="close-icon" />
          <div className="type-select">
            <span className="number-type">02</span>
            <span className="label">レポートの種類</span>
            <Select className="select-report-type" value={reportType}>
              {REPORT_TYPE.map(({ label, value }, index) => (
                <Option key={index} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="wrap-body">
        <div className="header-container">
          <span className="title">
            スキルチェック【社内】の実施結果を、分析グループを基準に集計したレポートです。
          </span>
        </div>

        <div className="wrap-filter">
          <span className="label">集計条件</span>
          <div className="aggregation-conditions">
            <div className="form-select">
              <div className="wrap-select">
                <div className="item">
                  <span className="text-label">分析グループ（成長比較）</span>
                  <Select className="select" placeholder="指定なし" value={groupCode}>
                    <Option disabled={loginID === 'ALL'} value="ALL">
                      ALL
                    </Option>
                    {selectAnalysisGroup.map((item, index) => (
                      <Option key={index} value={item.group_code}>
                        {item.group_name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="item">
                  <span className="text-label">ユーザー</span>
                  <Select className="select" placeholder="指定なし" value={loginID}>
                    <Option disabled={groupCode === 'ALL'} value="ALL">
                      ALL
                    </Option>
                    {(userType === 'interview' ? selectInterviewUser : selectEmployeeUser).map(
                      (item, index) => (
                        <Option key={index} value={item.login_id}>
                          {item.name}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
              </div>
              <div className="wrap-time-select">
                <div className="radio-item">
                  <div className="three-border" />
                  <div className="radio-select">
                    <Radio value={0} label="２期間比較" checked={compareType === 0} />
                    <Radio value={1} label="推移比較" checked={compareType === 1} />
                  </div>
                </div>
                <div className="item">
                  <span className="text-label">実施期間①</span>
                  <DatePicker
                    allowClear
                    name="date-item"
                    className="date-input"
                    format="YYYY/MM/DD"
                    value={startPeriod ? dayjs(startPeriod) : undefined}
                  />
                </div>
                <div className="item">
                  <span className="text-label">実施期間②</span>
                  <DatePicker
                    allowClear
                    name="date-item"
                    className="date-input"
                    format="YYYY/MM/DD"
                    value={endPeriod ? dayjs(endPeriod) : undefined}
                    disabledDate={(current) =>
                      current && current < dayjs(startPeriod, 'YYYY/MM/DD').add(1, 'day')
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <p className="note-compareType">
          {compareType === 0 && segmented === 1
            ? '※選択した期間における設問平均と設問別の２期間比較が表示されています。'
            : compareType === 1
            ? '※選択した実施期間における設問別の推移比較が表示されています。比較項目はプルダウンで変更できます。'
            : ''}
        </p>

        <div className="sub-container">
          <div className="wrap-segmented">
            <div className="left-side">
              <span className="label">レポートタイプ：</span>
              <div className="segmented">
                <div
                  className={`segmented-item${segmented === 0 ? ' segmented-item-selected' : ''}`}
                >
                  <TableOutlined className="icon" />
                  <span>表</span>
                </div>
                <div
                  className={`segmented-item${segmented === 1 ? ' segmented-item-selected' : ''}`}
                >
                  <BarChartOutlined className="icon" />
                  <span>グラフ</span>
                </div>
              </div>
              {compareType === 1 ? (
                <>
                  <span className="cross-boiled">/</span>
                  <span className="label">比較項目：</span>
                  <div className="item">
                    <Select className="select" placeholder="---" value={itemComparision}>
                      {ITEM_COMPARISION_ANALYSIS.map(({ label, value }, index) => (
                        <Option key={index} value={value}>
                          {label}推移
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {compareType === 0 ? (
            <MultipleComparison
              segmented={segmented}
              userType={userType}
              startPeriod={startPeriod}
              endPeriod={endPeriod}
              groupCode={groupCode}
              loginID={loginID}
              exportPdf
            />
          ) : (
            <Comparison
              segmented={segmented}
              itemComparision={itemComparision}
              userType={userType}
              startPeriod={startPeriod}
              endPeriod={endPeriod}
              groupCode={groupCode}
              loginID={loginID}
              exportPdf
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default FilePDFExport;

const Radio = ({ label, value, checked }: { label: string; value: number; checked: boolean }) => (
  <div className="radio-option">
    <input type="radio" value={value} checked={checked} />
    <span>{label}</span>
  </div>
);
