import React, { useState } from 'react';
import { CheckCircleOutlined, MailOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';

import { MailRegisterFormik, SignUpUserRequest } from 'types';
import ActionErrorModal from 'components/Modal/ActionError';
import { signUpUser } from 'containers/Auth/thunk';
import { registerSchema } from 'libs/validations';
import AuthLayout from 'containers/Auth/Layout';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { Input } from 'components';
import { Centering } from 'assets';

interface Props {
  screen?: 'register-trial' | 'register-premium';
}

const Register: React.FC<Props> = ({ screen }) => {
  const [submited, isSubmited] = useState<boolean>(false);
  const [errorModel, setErrorModel] = useState<{
    subTitle: string;
    visible: boolean;
  }>({
    subTitle: '',
    visible: false,
  });
  const setVisible = (visibility: boolean) => {
    setErrorModel({
      visible: false,
      subTitle: '',
    });
  };

  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const formik = useFormik<MailRegisterFormik>({
    initialValues: {
      email: '',
    },
    validationSchema: registerSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      const workspace_id = 'skillfamiliar';
      const signUpUrl = '/api/v0/applications/skillfamiliar/datastores/signup/items/new';
      const email = values.email;

      const signUpData: SignUpUserRequest & { screen?: 'register-trial' | 'register-premium' } = {
        workspace_id,
        url: signUpUrl,
        method: 'POST',
        params: {
          action_id: 'signup',
          item: { name: email },
          as_params: { email, username: email },
          is_force_update: true,
        },
        screen,
      };

      const resultActionAddUser = await dispatch(signUpUser(signUpData));

      if (signUpUser.fulfilled.match(resultActionAddUser)) {
        isSubmited(true);
        setErrorModel({ visible: false, subTitle: '' });
      }

      if (signUpUser.rejected.match(resultActionAddUser)) {
        setErrorModel({
          visible: true,
          subTitle: 'サーバーで何かエラーが発生しました。もう一度試してください。',
        });
      }
    },
  });

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={Centering} alt="logo" className="logo" />
        <p className="register">{messages['SK-01-3']}</p>
        {!submited ? (
          <>
            <div className="wrap-description">
              <p className="description">{messages['SK-01-4']}</p>
              <p className="description">{messages['SK-01-5']}</p>
            </div>
            <FormikProvider value={formik}>
              <Form className="form">
                <Form.Item name="email">
                  <Input
                    name="email"
                    type="email"
                    placeholder={messages['SK-01-1'] as string}
                    className="input-email"
                    prefix={<MailOutlined className="mail-icon" />}
                  />
                </Form.Item>
                <SubmitButton className="btn-submit">{messages['SK-01-2']}</SubmitButton>
              </Form>
            </FormikProvider>
            <ActionErrorModal
              setVisible={setVisible}
              subTitle={errorModel.subTitle}
              visible={errorModel.visible}
            />
          </>
        ) : (
          <div className="wrap-submited">
            <CheckCircleOutlined className="check-circle-icon" size={66} />
            <div className="wrap-text-submited">
              <p>
                {formik.values.email} {messages['SK-01-6']}
                <br />
                {messages['SK-01-7']}
                <br />
                {messages['SK-01-8']}
              </p>
            </div>
          </div>
        )}
      </SectionStyled>
    </AuthLayout>
  );
};

export default Register;
