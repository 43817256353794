import React, { useEffect, useRef, useState } from 'react';
import { Input as AntInput, InputRef, Select } from 'antd';
import { Form, Radio, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { find } from 'lodash';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { CREDIT_CARD_MONTH, IMAGE_CREDIT_CARD } from 'constant';
import ActionErrorModal from 'components/Modal/ActionError';
import { RegisterInformationPaymentFormik } from 'types';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { paymentMasterSelector } from '../selectors';
import { Input, InputPassword } from 'components';
import { fourStepSchema } from 'libs/validations';
import CreditInformationStyled from './styles';
import { useAppDispatch } from 'hooks';
import { SecurityCode } from 'assets';
import { COMPANIES } from 'configs';
import {
  getAdminEmail,
  getCardCompanyName,
  getItemIdGMO,
  updateCreditCardInfo,
  updateGMO,
} from '../thunk';

interface Props {
  isSubmit: boolean;
  isEditing: boolean;
  setIsSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const { Option } = Select;

const CreditInformation: React.FC<Props> = ({
  isSubmit,
  isEditing,
  setIsSubmit,
  setStatusEditing,
}) => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [cardNumber, setCardNumber] = useState<{
    input1: string;
    input2: string;
    input3: string;
    input4: string;
  }>({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });
  const [expiredDate, setExpiredDate] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const cardNumber1 = useRef<InputRef>(null);
  const cardNumber2 = useRef<InputRef>(null);
  const cardNumber3 = useRef<InputRef>(null);
  const cardNumber4 = useRef<InputRef>(null);

  const { userInfo } = useSelector(authSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { creditCardInfo, PaymentMaster, dataAdminGmail } = useSelector(paymentMasterSelector);

  const dispatch = useAppDispatch();

  const handleInputCardNumber = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength, value } = e.target;

    switch (index) {
      case 1:
        if (parseInt(value[0]) !== 0) {
          setCardNumber((prevState) => ({ ...prevState, input1: value }));
          formik.setFieldValue(
            'cardNumber',
            `${value}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`
          );
          if (value.length === maxLength) {
            cardNumber2.current && cardNumber2.current.focus();
          }
        }
        break;
      case 2:
        setCardNumber((prevState) => ({ ...prevState, input2: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${value}${cardNumber.input3}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber3.current && cardNumber3.current.focus();
        }
        break;
      case 3:
        setCardNumber((prevState) => ({ ...prevState, input3: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${value}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber4.current && cardNumber4.current.focus();
        }
        break;
      case 4:
        setCardNumber((prevState) => ({ ...prevState, input4: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${value}`
        );
        break;
      default:
        break;
    }
  };

  const handleSelectExpiredDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setExpiredDate((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('expirationDate', `${expiredDate.year}${e}`);
    } else {
      setExpiredDate((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('expirationDate', `${e}${expiredDate.month}`);
    }
  };

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCardInfo,
    validationSchema: fourStepSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const { securityNumber, expirationDate, customerName, companyName } = values;
      window.Multipayment.init('tshop00054795');
      window.Multipayment.getToken(
        {
          holdername: customerName,
          cardno: `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`,
          expire: expirationDate,
          securitycode: securityNumber,
        },
        async (res: { resultCode: string; tokenObject: { token: string } }) => {
          if (res.resultCode !== '000') {
            alert('購入処理中にエラーが発生しました');
          } else {
            if (isSubmit) {
              if (
                !userInfo ||
                !cardNumber.input4 ||
                !expiredDate.month ||
                !expiredDate.year ||
                !companyName ||
                !customerName ||
                !dataAdminGmail.length
              )
                return;

              const id = find(userInfo.item_links?.links, ({ d_id }) => d_id === COMPANIES.id)
                ?.items[0]?.i_id;
              dispatch(startLoading());
              const result = await Promise.all([
                dispatch(
                  getCardCompanyName({
                    conditions: [
                      {
                        id: 'company_id',
                        search_value: [companyName],
                      },
                    ],
                    page: 1,
                    per_page: 0,
                  })
                ),
                dispatch(
                  getItemIdGMO({
                    conditions: [
                      {
                        id: 'name',
                        search_value: [dataAdminGmail[0].admin_email],
                        exact_match: true,
                      },
                      {
                        id: 'status',
                        search_value: ['registration', 'update'],
                      },
                    ],
                    page: 1,
                    per_page: 0,
                  })
                ),
              ]);
              if (
                getCardCompanyName.fulfilled.match(result[0]) &&
                getItemIdGMO.fulfilled.match(result[1])
              ) {
                const company = result[0].payload.items.find(
                  (data) => data.card_company_name === companyName
                );
                const itemIdGMO = result[1].payload.items[0]?.i_id;
                const resultActions = await Promise.all([
                  dispatch(
                    updateCreditCardInfo({
                      id: id || '',
                      data: {
                        item: {
                          card_company_name: company?.i_id,
                          card_holder: customerName,
                          card_number_l4g: cardNumber.input4,
                          card_expiration_month: expiredDate.month,
                          card_expiration_year: expiredDate.year,
                        },
                        return_item_result: true,
                        is_force_update: true,
                      },
                    })
                  ),
                  dispatch(
                    updateGMO({
                      itemId: itemIdGMO || '',
                      data: {
                        as_params: {
                          token: res.tokenObject.token,
                          company_id: userInfo.company_id,
                        },
                        is_force_update: true,
                      },
                    })
                  ),
                ]);
                if (
                  updateCreditCardInfo.fulfilled.match(resultActions[0]) &&
                  updateGMO.fulfilled.match(resultActions[1])
                ) {
                  setDisabled(true);
                  setIsSubmit(false);
                  setStatusEditing(false);
                }
              }
              dispatch(stopLoading());
            } else {
              setDisabled(true);
              setIsSubmit(true);
            }
          }
        }
      );

      setSubmitting(false);
    },
  });

  const handleChangeStatus = () => {
    if (isEditing) {
      formik.setErrors({
        companyName: undefined,
        cardNumber: undefined,
        customerName: undefined,
        expirationDate: undefined,
        securityNumber: undefined,
      });
      formik.setFieldTouched('companyName', false);
      formik.setFieldTouched('cardNumber', false);
      formik.setFieldTouched('customerName', false);
      formik.setFieldTouched('expirationDate', false);
      formik.setFieldTouched('securityNumber', false);
    }
    if (isSubmit) {
      setDisabled(true);
      setIsSubmit(false);
    } else {
      setStatusEditing((prevState) => !prevState);
      setDisabled((prevState) => !prevState);
    }
  };

  useEffect(() => {
    if (!PaymentMaster) return;
    setCardNumber({
      input1: '****',
      input2: '****',
      input3: '****',
      input4: PaymentMaster.card_number_l4g,
    });

    setExpiredDate({
      month:
        PaymentMaster.card_expiration_month.length > 1
          ? PaymentMaster.card_expiration_month
          : '0' + PaymentMaster.card_expiration_month,
      year: PaymentMaster.card_expiration_year,
    });
  }, [PaymentMaster]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getAdminEmail({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo]);

  return (
    <CreditInformationStyled collapsedMenu={collapsedMenu}>
      <div className="header">
        <p className="title">クレジット情報</p>
      </div>
      <FormikProvider value={formik}>
        <Form layout="vertical" colon={false} className="form">
          <div className="from">
            <Form.Item
              name="companyName"
              label={
                <span className="text-label">
                  カード会社
                  {!disabled && <span className="require">*</span>}
                </span>
              }
              className="form-input"
            >
              <Radio.Group name="companyName" className="radio_group">
                {IMAGE_CREDIT_CARD.map((item, index) =>
                  !disabled ? (
                    <div key={index} className="wrap-radio">
                      <Radio
                        name="companyName"
                        value={item.name}
                        className={`radio_item ${
                          index === 0
                            ? 'border-radius-left'
                            : index >= 1 && index <= 3
                            ? 'border-right'
                            : 'border-radius-right'
                        }`}
                      >
                        <img
                          className="image-card-company"
                          aria-hidden
                          src={item.image}
                          alt={item.name}
                        />
                      </Radio>
                      <p className="label-text">{item.name}</p>
                    </div>
                  ) : item.name === formik.values.companyName ? (
                    <div key={index} className="wrap-radio disabled">
                      <Radio name="companyName" value={item.name} className="radio_item">
                        <img
                          className="image-card-company"
                          aria-hidden
                          src={item.image}
                          alt={item.name}
                        />
                      </Radio>
                      <p className="label-text">{item.name}</p>
                    </div>
                  ) : null
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="cardNumber"
              label={
                <span className="text-label">
                  カード番号
                  {!disabled && <span className="require">*</span>}
                </span>
              }
              className="form-input"
            >
              <AntInput
                disabled={disabled}
                ref={cardNumber1}
                value={cardNumber.input1}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(1)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input1.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                disabled={disabled}
                ref={cardNumber2}
                value={cardNumber.input2}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(2)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input2.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                disabled={disabled}
                ref={cardNumber3}
                value={cardNumber.input3}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(3)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input3.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                disabled={disabled}
                ref={cardNumber4}
                value={cardNumber.input4}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(4)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input4.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="customerName"
              label={
                <span className="text-label">
                  カード名義人
                  {!disabled && <span className="require">*</span>}
                </span>
              }
              className="form-input"
            >
              <Input
                disabled={disabled}
                name="customerName"
                className="input"
                type="text"
                placeholder="全角：最大100文字"
                onInput={(e) =>
                  ((e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value.toUpperCase())
                }
                maxLength={100}
              />
            </Form.Item>
            <Form.Item
              name="expirationDate"
              label={
                <span className="text-label">
                  有効期限
                  {!disabled && <span className="require">*</span>}
                </span>
              }
              className="form-input"
            >
              {!disabled ? (
                <div>
                  <Select
                    value={expiredDate.month}
                    className="pull_down input_small"
                    onSelect={handleSelectExpiredDate('month')}
                  >
                    {CREDIT_CARD_MONTH.map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                  <span className="text-label-content">月 / </span>
                  <Select
                    value={expiredDate.year}
                    className="pull_down input_small"
                    onSelect={handleSelectExpiredDate('year')}
                  >
                    {Array.from(
                      {
                        length: 10,
                      },
                      (_, i) => (i + new Date().getFullYear()).toString()
                    ).map((item, index) => (
                      <Option key={index} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                  <span className="text-label-content">年</span>
                </div>
              ) : (
                <div>
                  <Input
                    name="month"
                    value={expiredDate.month}
                    className="pull_down input_small"
                    disabled
                  />
                  <span className="text-label-content">月 / </span>
                  <Input
                    name="year"
                    value={expiredDate.year}
                    className="pull_down input_small"
                    disabled
                  />
                  <span className="text-label-content">年</span>
                </div>
              )}
            </Form.Item>
            <Form.Item
              name="securityNumber"
              label={
                <span className="text-label">
                  セキュリティコード
                  {!disabled && <span className="require">*</span>}
                </span>
              }
              className="form-input flex"
            >
              {disabled ? (
                <Input
                  name="securityNumber"
                  className="input_small_two"
                  type="password"
                  value={formik.values.securityNumber}
                  disabled
                />
              ) : (
                <InputPassword
                  name="securityNumber"
                  className="input_small_two"
                  onKeyPress={(e) => {
                    if (
                      isNaN(parseInt(e.key)) ||
                      formik.values.securityNumber.toString().length > 3
                    ) {
                      e.preventDefault();
                    }
                  }}
                  maxLength={4}
                />
              )}
              {isEditing ? (
                <p className="content-text">
                  カード裏側のご署名欄に印字された数字の末尾3桁の数字を入力してください。
                  <br />
                  AMEXの場合、はカード表側の4桁を入力してください
                </p>
              ) : null}
            </Form.Item>
          </div>
          {isEditing ? (
            <div className="wrap-security">
              <img src={SecurityCode} alt="security-code" />
            </div>
          ) : null}
          {disabled && !isSubmit ? (
            <div className="wrap-submit-button">
              <button type="button" className="btn-submit" onClick={handleChangeStatus}>
                クレジット情報を変更する
              </button>
            </div>
          ) : (
            <div className="wrap-submit-button">
              <SubmitButton className="btn-submit">{isSubmit ? '更新' : '確認画面へ'}</SubmitButton>
              <button type="button" className="btn-back-info" onClick={handleChangeStatus}>
                戻る
              </button>
            </div>
          )}
        </Form>
      </FormikProvider>

      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="更新が完了しました"
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="カード情報の更新に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </CreditInformationStyled>
  );
};

export default CreditInformation;
