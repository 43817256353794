import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import { ROLES, SELECT_ROLE, USERS } from 'configs';
import * as Types from 'types';

export const getDataRoles = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/getDataRoles', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getRoleDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/getRoleDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRolesCSV = createAsyncThunk<
  Types.GetItemResponseType<Types.Roles.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/getDataRolesCSV', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Roles.ResponseType>(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const searchDataRoles = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRole.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/searchDataRoles', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRole.ResponseType>(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/updateRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createRoles = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Roles.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/createRoles', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteRoles = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/thunk/deleteRoles', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(ROLES.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSearchUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('rolesMaster/thunk/getDataSearchUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
