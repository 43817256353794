import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckAnalysisReportSelector } from '../../../selectors';
import { ITEM_COMPARISION_ANALYSIS } from 'constant/select.constants';
import BreakdownStyled from './styles';

interface Props {
  itemComparision: (typeof ITEM_COMPARISION_ANALYSIS)[number]['value'];
}

const AccuracyRate: React.FC<Props> = ({ itemComparision }) => {
  const [tableData, setTableData] = useState<{ date: string; value: number }[]>([]);

  const { skillCheckAnalysisComparison } = useSelector(skillCheckAnalysisReportSelector);

  useEffect(() => {
    const groupData = groupBy(skillCheckAnalysisComparison.dataChart, 'implementation_date');

    setTableData(
      Object.keys(groupData)
        .filter(Boolean)
        .map((key) => {
          const record = groupData[key];
          let value = 0;
          switch (itemComparision) {
            case 'accuracy_rate':
              value = Number(
                formatNumber(
                  (record.reduce((prev, next) => next.accuracy_rate + prev, 0) / record.length) *
                    100
                )
              );
              break;
            case 'wrong_rate':
              value = Number(
                formatNumber(
                  (record.reduce((prev, next) => next.wrong_rate + prev, 0) / record.length) * 100
                )
              );
              break;
            case 'inexperienced_rate':
              value = Number(
                formatNumber(
                  (record.reduce((prev, next) => next.inexperienced_rate + prev, 0) /
                    record.length) *
                    100
                )
              );
              break;
            case 'average_answer_time_per_question':
              value = Number(
                formatNumber(
                  record.reduce((prev, next) => next.average_answer_time_per_question + prev, 0) /
                    record.length
                )
              );
              break;
            case 'acquisition_score_rate':
              value = Number(
                formatNumber(
                  (record.reduce((prev, next) => next.acquisition_score_rate + prev, 0) /
                    record.length) *
                    100
                )
              );
              break;
            default:
              break;
          }

          return { date: dayjs(key).format('YYYY/MM/DD'), value, type: itemComparision };
        })
    );
  }, [skillCheckAnalysisComparison, itemComparision]);

  return (
    <BreakdownStyled>
      <Column
        data={tableData}
        xField="date"
        yField="value"
        maxColumnWidth={40}
        color={['#41a1d6']}
        appendPadding={[0, 100, 0, 0]}
        label={{
          position: 'top',
          formatter: (item) =>
            itemComparision === 'average_answer_time_per_question'
              ? formatNumberToMinute(Number(item.value))
              : `${item.value}%`,
          style: {
            fontSize: 12,
            fill: '#424242',
          },
        }}
        legend={false}
        yAxis={{
          position: 'left',
          max: itemComparision === 'average_answer_time_per_question' ? undefined : 100,
          min: 0,
          line: {
            style: {
              stroke: '#ddd',
            },
          },
          label: {
            style: {
              color: '#999999',
              fontSize: 12,
            },
            formatter: (item, _, index) =>
              `${item}${itemComparision !== 'average_answer_time_per_question' ? '%' : ''}${
                index === 0 ? '\n\n開始日時' : ''
              }`,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [2, 2],
              },
            },
          },
          tickInterval: 10,
          tickLine: null,
          showLast: true,
        }}
        xAxis={{
          label: {
            style: {
              color: '#424242',
              fontSize: 12,
            },
          },
          tickLine: null,
        }}
      />
    </BreakdownStyled>
  );
};

export default AccuracyRate;
