import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';
import * as Types from 'types';

interface Props {
  collapsedMenu: boolean;
  scrollBarWidth: number;
}

export default styled.section<Props>`
  overflow-x: hidden;
  width: calc(100vw - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}) px;
  .tab-isExpand {
    padding-bottom: 100px;
  }
  .wrapTitlePDF {
    padding: 10px;
    border: 1px solid #ddd;
  }
  .titlePDF {
    font-size: 18px;
  }
  .wrapDes {
    margin: 30px 20px;
  }
  .line {
    width: 50px;
    height: 1px;
    background: #ddd;
    margin-bottom: 30px;
    margin-left: 20px;
  }
  .header {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    border-bottom: 1px solid #ebeaea;
    padding: 25px;
    @media (max-width: 1440px) {
      padding: 10px;
    }
    .wrap-title {
      width: 80%;
      p {
        margin: 0;
        font-weight: 400;
        font-size: 13px;
        color: #424242;
      }
    }
    .wrap-button {
      display: flex;
      justify-content: space-between;
      .btn {
        width: 160px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        border-radius: 5px;
        font-weight: bold;
        margin-left: 8px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      }
      .btn-active {
        background: #f6ac00;
        border: 1px solid #f6ac00;
        color: #ffffff;
        .title-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          .size-icon {
            font-size: 22px;
            margin-right: 10px;
          }
        }
      }
      .btn-outline {
        background: #ffffff;
        border: 1px solid #00a3a5;
        color: #159193;
      }
    }
  }
  .body {
    .header-body {
      padding: 15px 25px;
      .affiliation-created {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
        .left-side {
          display: flex;
          align-items: center;
          .note {
            color: #5f6baf;
            margin: 0 0 0 10px;
          }
        }
        .right-side {
          button {
            padding: 0;
            cursor: pointer;
            background: none;
            box-shadow: none;
            border: none;
            color: #5f6baf;
            span {
              font-size: 12px;
              text-decoration-line: underline;
            }
          }
        }
      }
      .btn-create,
      .disabled {
        width: 140px;
        height: 32px;
        cursor: pointer;
        font-size: 13px;
        border-radius: 5px;
        font-weight: bold;
        background: #f6ac00;
        border: 1px solid #f6ac00;
        color: #ffffff;
        padding: 0 10px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        .size-icon {
          font-size: 14px;
          float: left;
          padding: 3px 0;
        }
      }
      .disabled {
        background: #cccccc !important;
        color: #777777 !important;
        border: none !important;
      }
    }
    .tree-view {
      overflow-x: auto;
      white-space: nowrap;
      width: calc(
        100vw - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px -
          ${(props) => props.scrollBarWidth}px
      );
    }
  }
  .affiliation-list {
    padding: 0 25px;
  }
`;

export const Affiliation = styled.div`
  width: 220px;
  height: 56px;
  border-radius: 2px;
  background: #ffffff;
  border: 2px dashed #bcc1dd;
  padding: 5px 16px;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
  .top-side p {
    font-size: 13px;
    color: #2a2a2a;
    margin: 0;
  }
  .bottom-side {
    display: flex;
    width: 100%;
    color: #999999;
    justify-content: flex-end;
    align-items: flex-end;
    .people-quantity {
      font-family: Lato;
      font-weight: 400;
      font-size: 14px;
      margin-right: 5px;
    }
    .arrow-icon {
      font-size: 8px;
    }
  }
`;

interface PropsAfiliation {
  index: number;
  isExpand: boolean;
  item: Types.TreeItem<Types.AffiliationItemType>;
  listItemIndexShrink: number[];
}

export const WrapperAffiliation = styled.div<PropsAfiliation>`
  margin-left: ${(props) => (props.item.columnIndex || 0) * 30}px;
  margin-bottom: 20px;
  .rst__nodeContent {
    height: ${(props) => ((props.item.columnIndex || 0) > 0 ? 182 : 'unset')}px;
  }
  .rst__lineBlock {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .rst__lineHalfHorizontalLeftVerticalTop::before,
  .rst__lineHalfHorizontalLeftVerticalTop::after {
    position: absolute;
    content: '';
  }
  .rst__lineHalfHorizontalLeftVerticalTop::before {
    height: 3px;
    top: ${(props) => (props.listItemIndexShrink.includes(props.index) ? 15 : 50)}%;
    right: 100%;
    width: calc(
      0.5% *
        ${(props) =>
          (props.item.node?.columnIndex || 0) - (props.item.parentNode?.columnIndex || 0) + 1}
    );
    background: repeating-linear-gradient(to right, #959fd7 3px, #959fd7 3px);
  }
  .rst__lineHalfHorizontalLeftVerticalTop::after {
    width: 3px;
    left: calc(
      -0.5% * ${(props) => (props.item.node?.columnIndex || 0) - (props.item.parentNode?.columnIndex || 0) + 1}
    );
    height: ${(props) => {
      const lastIndex = props.item.path![props.item.path!.length - 1];
      const firstIndex = props.item.path![props.item.path!.length - 2];

      return `calc(50% + 20px${
        lastIndex - firstIndex - 1 === 0 ? '' : ` + (100% + 20px) * ${lastIndex - firstIndex - 1}`
      }${
        props.item.node!.columnIndex! !== 1 &&
        props.listItemIndexShrink.includes(firstIndex) &&
        lastIndex === props.index
          ? ' + 130px'
          : ''
      }${props.listItemIndexShrink.includes(props.index) ? ' - 35%' : ''});`;
    }};
    bottom: ${(props) => (props.listItemIndexShrink.includes(props.index) ? 85 : 50)}%;
    background: repeating-linear-gradient(to bottom, #959fd7 3px, #959fd7 3px);
  }
`;
