import { createSlice } from '@reduxjs/toolkit';
import {
  getDataSkillCheckImple,
  getDataQuestion,
  createSkillCheckTrans,
  editSkillCheckTrans,
  getDataItem,
  getSelectTypes,
  getDataCompany,
} from './thunk';

import * as Types from 'types';

export interface InitialState {
  dataSkillCheckImple: Array<Types.ItemSkillCheckImpleType>;
  dataQuestion: Array<Types.SkillCheckImple.ResponseType>;
  dataCreate?: Types.CreateItemResponseType<Types.SkillCheckTrans2.ResponseType>;
  dataEdit?: Types.UpdateItemResponseType<Types.SkillCheckTrans2.ResponseType>;
  dataItem: Array<Types.UserAssignSkillCheck.ResponseType>;
  dataCompany: Array<Types.Companies.ResponseType>;
  time_limit: Array<Types.SelectTypes.ResponseType>;
}

const initialState: InitialState = {
  dataSkillCheckImple: [],
  dataQuestion: [],
  dataItem: [],
  dataCompany: [],
  time_limit: [],
};

export const newSkillCheckSelectionSlice = createSlice({
  name: 'newSkillCheckSelection-page',
  initialState,
  reducers: {
    resetDataEdit: (state) => {
      state.dataEdit = undefined;
      state.dataCreate = undefined;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataCompany.fulfilled, (state, action) => {
      state.dataCompany = action.payload.items;
    });
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      state.time_limit = action.payload.items.filter((item) => item.type === 'time_limit');
    });
    builder.addCase(getDataSkillCheckImple.fulfilled, (state, action) => {
      const newDataSkillCheckImple: Array<Types.ItemSkillCheckImpleType> = [];
      action.payload.report_results.forEach((item) => {
        const itemIndex = newDataSkillCheckImple.findIndex(
          (c) => c.login_id === item.login_id && c.skill_check_code === item.skill_check_code
        );
        if (itemIndex >= 0) {
          if (
            item.skill_check_code &&
            !newDataSkillCheckImple.some(
              ({ skill_check_name }) => skill_check_name === item.skill_check_name
            )
          ) {
            newDataSkillCheckImple.push({
              i_id: item.i_id,
              company_id: item.company_id,
              login_id: item.login_id,
              skill_check_code: item.skill_check_code,
              skill_check_name: item.skill_check_name,
              implementation_settings: item.implementation_settings,
              implementation_status: item.implementation_status,
              grouping_code: item.grouping_code,
              skill_check_description: item.skill_check_description,
              user_type: item.user_type,
              start_period: item.start_period,
              end_period: item.end_period,
              check_time_limit: item.check_time_limit,
              question_count: item.question_count,
              question_time_limit: item.question_time_limit,
              fileID: item.fileID,
            });
          }
        } else {
          newDataSkillCheckImple.push({
            i_id: item.i_id,
            company_id: item.company_id,
            login_id: item.login_id,
            skill_check_code: item.skill_check_code,
            skill_check_name: item.skill_check_name,
            implementation_settings: item.implementation_settings,
            implementation_status: item.implementation_status,
            grouping_code: item.grouping_code,
            skill_check_description: item.skill_check_description,
            user_type: item.user_type,
            start_period: item.start_period,
            end_period: item.end_period,
            check_time_limit: item.check_time_limit,
            question_count: item.question_count,
            question_time_limit: item.question_time_limit,
            fileID: item.fileID,
          });
        }
      });
      state.dataSkillCheckImple = newDataSkillCheckImple;
    });
    builder.addCase(getDataQuestion.fulfilled, (state, action) => {
      state.dataQuestion = action.payload.report_results;
    });
    builder.addCase(createSkillCheckTrans.fulfilled, (state, action) => {
      state.dataCreate = action.payload;
    });
    builder.addCase(editSkillCheckTrans.fulfilled, (state, action) => {
      state.dataEdit = action.payload;
    });
    builder.addCase(getDataItem.fulfilled, (state, action) => {
      state.dataItem = action.payload.items;
    });
  },
});

export const { resetDataEdit, resetInitialState } = newSkillCheckSelectionSlice.actions;

export default newSkillCheckSelectionSlice.reducer;
