import React from 'react';
import { ColumnConfig } from '@ant-design/plots/es/components/column';
import { Column } from '@ant-design/plots';
import { Row } from 'antd';

import { DataDashboardCardType } from 'pages/Dashboard/slice';
import { HomeCardColour } from 'constant/enum.constant';
import { Wrapper } from './styles';

interface ProgressChartProps {
  color: HomeCardColour;
  data: DataDashboardCardType;
}

const ProgressChart: React.FC<ProgressChartProps> = ({ color, data }) => {
  const getColorGradient = () => {
    if (color === HomeCardColour.green) {
      return 'l(270) 0:#b0dad0 1:#36b797';
    } else if (color === HomeCardColour.blue) {
      return 'l(270) 0:#9fcfe3 1:#089cd4';
    } else {
      return 'l(270) 0:#b6b4f2 1:#a56bdb';
    }
  };

  const config: ColumnConfig = {
    data: data.six_month_ago,
    supportCSSTransform: true,
    xField: 'title',
    yField: 'value',
    label: undefined,
    color: getColorGradient(),
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        style: {
          textAlign: 'center',
          fontSize: 11,
          fill: '#394848',
        },
      },
      line: {
        style: {
          lineWidth: 0.3,
        },
      },
      tickLine: null,
      subTickLine: null,
    },
    yAxis: {
      label: null,
      grid: null,
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
    columnStyle: {
      radius: 2,
    },
    minColumnWidth: 11,
    maxColumnWidth: 11,
    width: 250,
    height: 160,
    appendPadding: [15, 15],
    annotations: data.six_month_ago.map((values, index) => ({
      type: 'text',
      position: [index, values.value],
      content: `${values.value}%`,
      style: {
        textAlign: 'center',
        fontSize: 11,
        fill: 'rgba(58, 78, 79, 0.5)',
      },
      offsetY: -10,
    })),
  };

  return (
    <Wrapper>
      <Row className="chart">
        <Column {...config} />
      </Row>
    </Wrapper>
  );
};

export default ProgressChart;
