import React, { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckUserReportSelector } from '../../User/selectors';
import { skillCheckReportSelector } from '../selectors';
import { convertTime } from '../../User/SurfaceTable';
import { ReportSkillCheck } from 'types';
import Wrapper from './styles';

const PER_PAGE = 10;

interface Props {
  exportPdf?: boolean;
}

export const TableBySkillCheck: React.FC<Props> = ({ exportPdf }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const { reportSkillCheck, totalReportSkillCheck } = useSelector(skillCheckReportSelector);
  const { time_limit } = useSelector(skillCheckUserReportSelector);
  const columns: ColumnsType<ReportSkillCheck.SurfaceTableByUserType> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '1%',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
    },
    {
      title: 'スキルチェック',
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      className: 'br-1',
      width: '9%',
    },
    {
      title: '正解',
      dataIndex: 'correct_answers_num',
      key: 'correct_answers_num',
      align: 'right',
      width: '5%',
      sorter: (a, b) => a.correct_answers_num - b.correct_answers_num,
    },
    {
      title: '不正解',
      dataIndex: 'incorrect_answer',
      key: 'incorrect_answer',
      align: 'right',
      width: '6%',
      sorter: (a, b) => a.incorrect_answer - b.incorrect_answer,
    },
    {
      title: '未経験',
      dataIndex: 'inexperienced',
      key: 'inexperienced',
      align: 'right',
      width: '6%',
      sorter: (a, b) => a.inexperienced - b.inexperienced,
    },
    {
      title: '総設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      className: 'br-1',
      align: 'right',
      width: '6%',
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      align: 'center',
      width: '6%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '-'
        ),
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      align: 'right',
      width: '7%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '0%'
        ),
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
    },
    {
      title: '未経験率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      className: 'br-1',
      align: 'right',
      width: '7%',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '0%'
        ),
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
    },
    {
      title: '制限時間',
      dataIndex: 'time_limit',
      key: 'time_limit',
      align: 'center',
      width: '6%',
      render: (record) => convertTime(time_limit[record - 1]?.name) || '-',
    },
    {
      title: '解答時間',
      dataIndex: 'answer_time',
      key: 'answer_time',
      align: 'center',
      width: '6%',
      render: (record) => formatNumberToMinute(record) || '-',
    },
    {
      title: (
        <p className="text-left">
          設問あたり
          <br />
          平均解答時間
        </p>
      ),
      dataIndex: 'average_answer_time_per_question',
      key: 'average_answer_time_per_question',
      align: 'center',
      width: '9%',
      className: 'br-1',
      render: (record) => formatNumberToMinute(record) || '-',
      sorter: (a, b) => a.average_answer_time_per_question - b.average_answer_time_per_question,
    },
    {
      title: (
        <p className="text-left">
          設定
          <br />
          スコア
        </p>
      ),
      dataIndex: 'score',
      key: 'score',
      align: 'center',
      width: '6%',
      render: (record) => formatNumber(record) || '-',
      sorter: (a, b) => a.score - b.score,
    },
    {
      title: (
        <p className="text-left">
          獲得
          <br />
          スコア
        </p>
      ),
      dataIndex: 'acquisition_score',
      key: 'acquisition_score',
      align: 'center',
      width: '6%',
      render: (record) => formatNumber(record) || '-',
      sorter: (a, b) => a.acquisition_score - b.acquisition_score,
    },
    {
      title: (
        <p className="text-left">
          獲得
          <br />
          スコア率
        </p>
      ),
      key: 'acquisition_score_rate',
      dataIndex: 'acquisition_score_rate',
      align: 'right',
      width: '7%',
      className: 'br-1',
      render: (value) =>
        value ? (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {formatNumber(value * 100)}
            <small style={{ color: '#999999' }}>%</small>
          </span>
        ) : (
          '0%'
        ),
      sorter: (a, b) => a.acquisition_score_rate - b.acquisition_score_rate,
    },
    {
      title: '実施状況',
      dataIndex: 'implementation_date',
      key: 'implementation_date',
      width: '7%',
      render: (record) =>
        record ? (
          dayjs(record).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        ),
      sorter: (a, b) =>
        a.implementation_date && b.implementation_date
          ? new Date(a.implementation_date).getTime() - new Date(b.implementation_date).getTime()
          : -1,
    },
  ];

  return (
    <Wrapper>
      {totalReportSkillCheck ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportSkillCheck} <span className="text-static"> 件表示</span> /
                {totalReportSkillCheck}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportSkillCheck}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span>
                  {''} / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          !exportPdf
            ? reportSkillCheck
                .map((item, index) => ({ ...item, index }))
                .slice(1)
                .slice((page - 1) * pageSize, page * pageSize)
            : reportSkillCheck.map((item, index) => ({ ...item, index })).slice(1)
        }
        columns={columns}
        scroll={{ x: 1180 }}
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        bordered
        pagination={false}
      />
    </Wrapper>
  );
};

export default TableBySkillCheck;
