import React, { CSSProperties, useState } from 'react';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { isNil } from 'lodash';
import { Button } from 'antd';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { sortDataReportSkillCheckUserTrans } from '../../slice';
import { skillCheckUserReportSelector } from '../../selectors';
import getWidthContainer from 'libs/utils/pieChart';
import { convertTime } from '../../SurfaceTable';
import { OrderByType } from 'types/config';
import { useAppDispatch } from 'hooks';
import BreakdownStyled from './styles';
import { EmptyPie } from 'assets';

interface Props {
  isUserTransTable: boolean;
  exportPdf?: boolean;
}

const BreakdownAnswersPerCentage: React.FC<Props> = ({ isUserTransTable, exportPdf }) => {
  const [sortByAverageAnswerTime, setSortByAverageAnswerTime] = useState<OrderByType>();
  const [sortByNotImplemented, setSortByNotImplemented] = useState<OrderByType>();
  const [sortByAnswersTime, setSortByAnswersTime] = useState<OrderByType>();
  const [sortByInProgress, setSortByInProgress] = useState<OrderByType>();
  const [sortByCorrect, setSortByCorrect] = useState<OrderByType>();
  const [sortByScore, setSortByScore] = useState<OrderByType>();

  const { reportSkillCheckUserTrans, totalReportSkillCheckUserTrans, time_limit } = useSelector(
    skillCheckUserReportSelector
  );
  const dispatch = useAppDispatch();

  return (
    <BreakdownStyled>
      <div className="container">
        <div className={`wrapper-header ${isUserTransTable && 'border'} `}>
          <div>
            {isUserTransTable && (
              <div className="header">
                <span>【共通情報】</span>
                <span>制限時間 </span>
                <span className="bold">
                  {formatNumberToMinute(reportSkillCheckUserTrans[0]?.question_time_limit)}
                </span>
                <span className="space">/</span>
                <span>問題数 </span>
                <span className="bold">{reportSkillCheckUserTrans[0]?.probs_count}問</span>
                <span className="space">/</span>
                <span>設定スコア </span>
                <span className="bold">{reportSkillCheckUserTrans[0]?.setting_score}</span>
              </div>
            )}
          </div>
          <div className="legend">
            <div className="sort-column">
              <div className="legend-filter blue" />
              <span className="legend-name">正解</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByCorrect((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'correct_answers_num',
                      sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
            <div className="sort-column">
              <div className="legend-filter pink" />
              <span className="legend-name">不正解</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByInProgress((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'incorrect_answer',
                      sort_order: sortByInProgress === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByInProgress === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByInProgress === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
            <div className="sort-column">
              <div className="legend-filter gray" />
              <span className="legend-name">未経験</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByNotImplemented((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'inexperienced',
                      sort_order: sortByNotImplemented === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByNotImplemented === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByNotImplemented === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
            <div className="sort-column">
              <span className="legend-name">解答時間</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByAnswersTime((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'total_answer_time',
                      sort_order: sortByAnswersTime === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByAnswersTime === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByAnswersTime === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
            <div className="sort-column">
              <span className="legend-name">平均解答時間</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByAverageAnswerTime((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'average_answer_time_per_question',
                      sort_order: sortByAverageAnswerTime === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByAverageAnswerTime === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByAverageAnswerTime === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
            <div className="sort-column">
              <span className="legend-name">スコア</span>
              <Button
                className="btn-sort"
                onClick={() => {
                  setSortByScore((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                  dispatch(
                    sortDataReportSkillCheckUserTrans({
                      sort_field: 'acquisition_score',
                      sort_order: sortByScore === 'asc' ? 'desc' : 'asc',
                    })
                  );
                }}
              >
                <div className="wrap-sort">
                  <CaretUpOutlined
                    className={`icon-caret${sortByScore === 'asc' ? ' active' : ''}`}
                  />
                  <CaretDownOutlined
                    className={`icon-caret${sortByScore === 'desc' ? ' active' : ''}`}
                  />
                </div>
              </Button>
            </div>
          </div>
        </div>

        {reportSkillCheckUserTrans.slice(isUserTransTable ? 0 : 1).map((data, index) => (
          <div key={index} className="item">
            {totalReportSkillCheckUserTrans &&
              ((isUserTransTable && index > 0) || !isUserTransTable) && (
                <div className="number-order">
                  <span>{index + (!isUserTransTable ? 1 : 0)}</span>
                </div>
              )}
            {!isUserTransTable && (
              <div className="question-count">
                <p className="title">設問数</p>
                <p className="number">{data ? data.probs_count : '-'}</p>
              </div>
            )}
            <div className="pie">
              <span>
                {isUserTransTable
                  ? index === 0
                    ? 'ユーザー平均'
                    : data.user_name
                  : data.skill_check_name}
              </span>
              {!isNil(data.responses_num) ? (
                <>
                  <Pie
                    appendPadding={10}
                    data={[
                      {
                        label: '正解',
                        value: Number(formatNumber(data.accuracy_rate * 100)),
                      },
                      {
                        label: '不正解',
                        value: Number(formatNumber(data.wrong_rate * 100)),
                      },
                      {
                        label: '未実施',
                        value: Number(formatNumber(data.inexperienced_rate * 100)),
                      },
                    ]}
                    angleField="value"
                    colorField="label"
                    color={['#379BD2', '#EA8C92', '#EBEBEB']}
                    radius={1}
                    innerRadius={0.64}
                    label={{
                      type: 'inner',
                      offset: '-50%',
                      style: {
                        fontSize: 10,
                        textAlign: 'center',
                      },
                      autoRotate: false,
                      content: '{value}%',
                    }}
                    statistic={{
                      title: exportPdf
                        ? {
                            offsetY: 10,
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '16px',
                              fontWeight: 'normal',
                              color: '#2a2a2a',
                              margin: '0px auto 5px auto',
                            },
                            content: `獲得スコア\n${formatNumber(data.acquisition_score)}`,
                          }
                        : {
                            offsetY: 10,
                            customHtml: (container) => {
                              return renderStatistic(
                                getWidthContainer(container, true),
                                formatNumber(data.acquisition_score),
                                'title',
                                {
                                  fontSize: 24,
                                  textShadow: 'none',
                                  fontWeight: 400,
                                  color: '#777777',
                                }
                              );
                            },
                          },
                      content: exportPdf
                        ? {
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '13px',
                              fontWeight: 'normal',
                              color: '#424242',
                              paddingTop: '10px',
                            },
                            content: `${formatNumber(
                              data.acquisition_score_rate * 100
                            )}%\n獲得スコア率`,
                          }
                        : {
                            customHtml: (container, _view) => {
                              return renderStatistic(
                                getWidthContainer(container, false),
                                formatNumber(data.acquisition_score_rate * 100),
                                'content',
                                {
                                  fontSize: 13,
                                  fontWeight: 400,
                                  color: '#777777',
                                }
                              );
                            },
                          },
                    }}
                    animation={false}
                    width={200}
                    height={200}
                    legend={false}
                  />
                </>
              ) : (
                <div>
                  <img src={EmptyPie} className="empty-pie" alt="empty-pie" />
                </div>
              )}
            </div>
            <div className="footer-item skill-check-all">
              {!isUserTransTable && (
                <div className="total-answer-time">
                  <p className="title">制限時間</p>
                  <p className="time">
                    {!isNil(data.responses_num)
                      ? convertTime(time_limit[data.question_time_limit - 1]?.name)
                      : '-'}
                  </p>
                </div>
              )}
              <div className="answer-time">
                <p className="title">解答時間</p>
                <p className="time">
                  {!isNil(data.responses_num) ? formatNumberToMinute(data.total_answer_time) : '-'}
                </p>
              </div>
              <div className={`average-answer-time ${!isUserTransTable && 'border-right'}`}>
                <p className="title">平均解答時間</p>
                <p className="time">
                  {!isNil(data.responses_num)
                    ? formatNumberToMinute(data.average_answer_time_per_question)
                    : '-'}
                </p>
              </div>
              {!isUserTransTable && (
                <div className="setting-score">
                  <p className="title">設定スコア</p>
                  <p className="time">{!isNil(data.responses_num) ? data.setting_score : '-'}</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </BreakdownStyled>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: flex-start;top:100px;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><p style="font-weight: 400;margin: 0;font-size: 11px;color: #777777;">獲得スコア</p><h4 style="font-weight: 400;margin: 0;font-size: 24px;color: #2A2A2A;">${text}</h4><div style="width: 15px; height: 2px; background-color: #DDDDDD;margin: 5px auto 0 auto;" /></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="display: flex; flex-direction: column;padding-top: 5px;gap:10px"><span style="font-weight: 400;font-size: 13px;color: #424242;">${text}%</span><span style="font-weight: 400;font-size: 10px;color: #777;">獲得スコア率</span></div></div>`;
  }
};

export default BreakdownAnswersPerCentage;
