import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DownOutlined, FormOutlined, SearchOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Checkbox, Select, Table, Popconfirm } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';

import { searchQuestionCurriculumSelector } from 'containers/Curriculum/Search/selectors';
import { HEADER_CURRICULUM_MASTER_TABLE_CSV } from 'constant/header.export.constant';
import { useAppDispatch, usePermission, useUserInfoChanged } from 'hooks';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { IconLocked, IconPublish, IconRequired } from 'assets';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { PUBLISH } from 'constant/select.constants';
import { curriculumSelector } from '../selectors';
import { Header, SelectField } from 'components';
import { getCurriculumMaster } from '../thunk';
import EditModal from './Edit';
import * as Types from 'types';
import Wrapper from './styles';
import {
  getCurriculumQuestionOption,
  getCurriculumLevelOption,
  getSelectQuestion,
} from 'containers/Curriculum/Search/thunk';

const { Option } = Select;

interface Props {
  setOpenCurriculumMaster: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface VisiblePopupEditType {
  visible: boolean;
  publish?: boolean;
  index: number;
}

const CurriculumMaster: React.FC<Props> = ({ setOpenCurriculumMaster }) => {
  const [selectedRows, setSelectedRows] = useState<Types.CurriculumHierarchy.ResponseType[]>([]);
  const [visiblePopupError, setVisiblePopupError] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(100);
  const [visibleTooltip] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);
  const [visiblePopupEdit, setVisiblePopupEdit] = useState<VisiblePopupEditType>({
    visible: false,
    index: -1,
  });

  const { curricullumMaster, total } = useSelector(curriculumSelector);
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    curriculumOption,
    questionSelect,
    level1Option,
    level2Option,
    level3Option,
    level4Option,
  } = useSelector(searchQuestionCurriculumSelector);
  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const newDataCurriculum = useMemo(() => {
    const result: Array<Types.CurriculumHierarchy.ResponseType> = Object.values(
      curricullumMaster.reduce((acc: any, curr) => {
        const key = curr.curriculum_code;
        if (!acc[key]) {
          acc[key] = {
            i_id: '',
            company_id: curr.company_id,
            creator: curr.creator,
            curriculum_code: curr.curriculum_code,
            curriculum_description: curr.curriculum_description,
            curriculum_name: curr.curriculum_name,
            curriculum_sort_order: curr.curriculum_sort_order,
            official_curriculum_code: curr.official_curriculum_code,
            required_curriculum: curr.required_curriculum,
            publish: curr.publish,
            problems_count: '',
            level1_code: '',
            level1_name: '',
            level2_code: '',
            level2_name: '',
            level3_code: '',
            level3_name: '',
            level4_code: '',
            level4_name: '',
            question_code: '',
            question_name: '',
            level1_sort_order: '',
            level2_sort_order: '',
            level3_sort_order: '',
            level4_sort_order: '',
            question_sort_order: '',
          };
        }
        return acc;
      }, {})
    );
    return result;
  }, [curricullumMaster]);

  const columns: ColumnsType<Types.CurriculumHierarchy.ResponseType> = [
    {
      title: 'ステータス',
      dataIndex: 'publish',
      key: 'publish',
      className: 'publish',
      width: '6.0%',
      align: 'center',
      render: (text, item) => (
        <>
          {!item.publish ? (
            <img src={IconLocked} className="icon-small" alt="edit-icon" />
          ) : (
            <img src={IconPublish} className="icon-small" alt="publish-icon" />
          )}
        </>
      ),
    },
    {
      title: (
        <>
          <p className="title-table">カリキュラムコード</p>
          <p>カリキュラム名</p>
        </>
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_text, item) => (
        <>
          <div className="wrap-code">
            <p className="text-code">{item.curriculum_code}</p>
            {item.required_curriculum ? (
              <img src={IconRequired} className="icon" alt="required-icon" />
            ) : null}

            {item.official_curriculum_code && <p className="official">OFFICIAL</p>}
          </div>

          <p className="text-title">{item.curriculum_name}</p>
        </>
      ),
    },
    {
      title: '説明',
      dataIndex: 'curriculum_description',
      key: 'curriculum_description',
      width: '3.5%',
      render: (text, _, index) => {
        return (
          <Popconfirm
            title={text}
            color="white"
            icon={null}
            overlayInnerStyle={{
              padding: ' 0 10px',
            }}
            okButtonProps={{
              hidden: true,
            }}
            cancelButtonProps={{
              hidden: true,
            }}
          >
            <FileTextOutlined
              className="icon"
              style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
            />
          </Popconfirm>
        );
      },
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第１階層コード</p>
          <p>第１階層名</p>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_, { level1_code, level1_name }) => (
        <>
          {!visible ? (
            <>
              <p className="title-table">{level1_code}</p>
              <p className="text-title">{level1_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第２階層コード</p>
          <p>第２階層名</p>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_, { level2_name, level2_code }) => (
        <>
          {!visible ? (
            <>
              <p className="title-table">{level2_code}</p>
              <p className="text-title">{level2_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第３階層コード</p>
          <p>第３階層名</p>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_, { level3_code, level3_name }) => (
        <>
          {!visible ? (
            <>
              <p className="title-table">{level3_code}</p>
              <p className="text-title">{level3_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">第４階層コード</p>
          <p>第４階層名</p>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_, { level4_code, level4_name }) => (
        <>
          {!visible ? (
            <>
              <p className="title-table">{level4_code}</p>
              <p className="text-title">{level4_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? (
        <>
          <p className="title-table">設問コード</p>
          <p>設問名</p>
        </>
      ) : (
        ''
      ),
      dataIndex: 'operation',
      width: '14%',
      render: (_, { question_code, question_name }) => (
        <>
          {!visible ? (
            <>
              <p className="title-table">{question_code}</p>
              <p className="text-title">{question_name}</p>
            </>
          ) : null}
        </>
      ),
    },
    {
      title: !visible ? '編集' : '',
      dataIndex: 'operation',
      width: '4%',
      render: (_, item, index: number) => (
        <>
          {!visible ? (
            <FormOutlined
              className="icon"
              onClick={() =>
                permissionNumber !== 1 &&
                setVisiblePopupEdit({
                  visible: true,
                  publish: item.publish === 1,
                  index,
                })
              }
              style={{ color: visiblePopupEdit.index === index ? '#00a3a5' : '#c4c4c4' }}
            />
          ) : null}
        </>
      ),
    },
  ];

  const fetchDataCurriculumHierarchy = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getCurriculumMaster({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo]);

  // TODO: data has not been converted - not resolved
  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = selectedRows?.map((item) => ({
        publish: item.publish,
        curriculum_description: item.curriculum_description,
        creator: item.creator,
        curriculum_code: item.curriculum_code,
        curriculum_name: item.curriculum_name,
        curriculum_sort_order: item.curriculum_sort_order,
        official_curriculum_code: item.official_curriculum_code,
        problems_count: item.problems_count,
        required_curricullum: item.required_curriculum,
      }));

      const csvString = [
        HEADER_CURRICULUM_MASTER_TABLE_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'Curriculum_Master_Table_export.csv');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleButtonExport = () => {
    if (!selectedRows.length) {
      setVisiblePopupError(true);
    } else {
      setVisiblePopupConfirmExportFile(true);
    }
  };

  const formik = useFormik<Types.CurriculumMasterSearchFormik>({
    initialValues: {
      publish: 'none',
      curriculum_name: '',
      level1_name: '',
      level2_name: '',
      level3_name: '',
      level4_name: '',
      question_name: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value?.toString() && value !== 'none') {
          conditions.push({
            id: key,
            search_value: [value.toString()],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getCurriculumMaster({
          conditions,
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(fetchDataCurriculumHierarchy, [fetchDataCurriculumHierarchy]);

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getCurriculumQuestionOption({
              page: 1,
              per_page: 0,
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
            })
          ),
          dispatch(
            getCurriculumLevelOption({
              level: 1,
              company_id: userInfo.company_id,
            })
          ),
          dispatch(
            getCurriculumLevelOption({
              level: 2,
              company_id: userInfo.company_id,
            })
          ),
          dispatch(
            getCurriculumLevelOption({
              level: 3,
              company_id: userInfo.company_id,
            })
          ),
          dispatch(
            getCurriculumLevelOption({
              level: 4,
              company_id: userInfo.company_id,
            })
          ),
          dispatch(
            getSelectQuestion({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              sort_fields: [
                {
                  id: 'code',
                  order: 'asc',
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <Wrapper collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} />
      <div className="container">
        <p className="text-note">
          カリキュラムマスタの階層リストです。階層の確認と編集が可能です。
          <br />
          編集は編集マークの行に設定されている各階層を編集できます。
        </p>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="publish"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField name="publish" defaultValue="none">
                  <Option value="none">指定なし</Option>
                  {PUBLISH.map(({ value, label }, index) => (
                    <Option key={index} value={value}>
                      {Number(value) === 1 ? (
                        <>
                          <img src={IconPublish} className="icon" alt="publish-icon" />
                          <span>{label}</span>
                        </>
                      ) : (
                        <>
                          <img src={IconLocked} className="icon" alt="edit-icon" />
                          <span>{label}</span>
                        </>
                      )}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="curriculum_name"
                className="item"
                label={<span className="text-label">カリキュラム名</span>}
              >
                <SelectField
                  name="curriculum_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {curriculumOption.map((value, index) => (
                    <Option key={index} value={value.name}>
                      {value.name}
                    </Option>
                  ))}
                  <Option value={'test'}>curriculum_name</Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level1_name"
                className="item"
                label={<span className="text-label">第１階層名</span>}
              >
                <SelectField
                  name="level1_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {level1Option.map((value, index) => (
                    <Option key={index} value={value.name || '空白'}>
                      {value.name || '空白'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level2_name"
                className="item"
                label={<span className="text-label">第２階層名</span>}
              >
                <SelectField
                  name="level2_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {level2Option.map((value, index) => (
                    <Option key={index} value={value.name || '空白'}>
                      {value.name || '空白'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level3_name"
                className="item"
                label={<span className="text-label">第３階層名</span>}
              >
                <SelectField
                  name="level3_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {level3Option.map((value, index) => (
                    <Option key={index} value={value.name || '空白'}>
                      {value.name || '空白'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="level4_name"
                className="item"
                label={<span className="text-label">第４階層名</span>}
              >
                <SelectField
                  name="level4_name"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {level4Option.map((value, index) => (
                    <Option key={index} value={value.name || '空白'}>
                      {value.name || '空白'}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="question_code"
                className="item"
                label={<span className="text-label">設問名</span>}
              >
                <SelectField
                  name="question_code"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children)?.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                >
                  {questionSelect.map((value, index) => (
                    <Option key={index} value={value.code}>
                      {value.name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-submit-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={() => formik.resetForm()}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="item-table">
          <div className="item-checkbox">
            <Checkbox
              checked={visible}
              onClick={() => {
                setVisible(!visible);
                setPage(1);
              }}
            >
              階層を折りたたむ
            </Checkbox>
          </div>
          <Table
            rowKey="index"
            className="table"
            dataSource={
              visible
                ? newDataCurriculum.map((item, index) => ({ ...item, index: `${index}_${page}` }))
                : curricullumMaster.map((item, index) => ({ ...item, index: `${index}_${page}` }))
            }
            columns={columns}
            rowSelection={{
              selectedRowKeys,
              onChange: (selectedRowKey, selected) => {
                setSelectedRows(selected);
                setSelectedRowKeys(selectedRowKey);
              },
              getCheckboxProps: () => ({ disabled: permissionNumber === 1 }),
            }}
            pagination={{
              pageSize: perPage,
              total: visible ? newDataCurriculum.length : total,
              current: page,
              onChange: (pageChange) => {
                setPage(pageChange);
                setSelectedRows([]);
                setSelectedRowKeys([]);
              },
              showSizeChanger: false,
              position: ['topCenter'],
              showTotal: () => (
                <div className="wrap-select-record">
                  <div className="text-count">
                    {(page - 1) * perPage + 1} - {''}
                    {page * perPage >
                    (visible ? newDataCurriculum.length : curricullumMaster.length)
                      ? visible
                        ? newDataCurriculum.length
                        : curricullumMaster.length
                      : page * perPage}
                    <span className="text-static"> {''}</span> / {''}
                    {visible ? newDataCurriculum.length : curricullumMaster.length}
                    <span className="text-static"> 件</span>
                  </div>
                  <div>
                    <span className="label-select">表示件数：</span>
                    <Select className="select-option" onChange={setPerPage} value={perPage}>
                      {[100, 50, 20, 10].map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </div>
                </div>
              ),
            }}
          />
        </div>
        <div className="wrap-bottom">
          <div className="flex">
            <div className="text-label">
              選択した階層リストを処理：
              <Button className="btn btn-active" onClick={handleButtonExport}>
                エクスポート
              </Button>
            </div>
            <Button className="btn btn-outline" onClick={() => setOpenCurriculumMaster(false)}>
              <span>カリキュラムツリー</span>
              <DownOutlined className="icon" />
            </Button>
          </div>
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="ユーザーが選択されていません"
          description={
            <>
              必修カリキュラム一括設定を実行する
              <br />
              対象のユーザーを選択し、再度実行してください。
            </>
          }
        />
        <EditModal
          page={page}
          per_page={perPage}
          visible={visiblePopupEdit}
          setVisible={setVisiblePopupEdit}
        />
      </div>
    </Wrapper>
  );
};

export default CurriculumMaster;
