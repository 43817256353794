import { differenceBy, intersectionBy } from 'lodash';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { services, curriculumService } from 'services';
import * as Types from 'types';
import {
  SELECT_QUESTION_CREATOR,
  UNRELATED_QUESTIONS,
  SELECT_CURRICULUMS,
  SELECT_QUESTION,
  CURRICULUMS,
  LEVEL_1,
  LEVEL_2,
  LEVEL_3,
  LEVEL_4,
  QUESTION_ASSIGN_LEVEL,
  QUESTIONS,
} from 'configs';

export const getDataLevel1 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level1s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel1', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level1s.ResponseType>(LEVEL_1.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level2s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level2s.ResponseType>(LEVEL_2.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel3 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level3s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel3', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level3s.ResponseType>(LEVEL_3.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataLevel4 = createAsyncThunk<
  Types.GetItemResponseType<Types.Level4s.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataLevel4', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Level4s.ResponseType>(LEVEL_4.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQuesLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.QuestionAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQuesLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.QuestionAssignLevel.ResponseType>(
      QUESTION_ASSIGN_LEVEL.id,
      {
        ...req,
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataQues = createAsyncThunk<
  Types.GetItemResponseType<Types.Questions.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Question/thunk/getDataQues', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Questions.ResponseType>(QUESTIONS.id, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getListQuestion = createAsyncThunk<
  Types.GetItemResponseType<Types.TreeTraining>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Curriculum/thunk/getListQuestion', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    dispatch(startLoading());
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, {
      ...req,
      sort_fields: [{ id: 'sort_order', order: 'asc' }],
    });

    const { authContainer } = getState() as Types.RootState;

    const responseStatus = await Promise.all([
      dispatch(
        getDataLevel1({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel2({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel3({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataLevel4({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQuesLevel({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
            {
              id: 'login_id',
              search_value: [authContainer?.userInfo?.login_id],
              exact_match: true,
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
      dispatch(
        getDataQues({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer?.userInfo?.company_id],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      ),
    ]);

    let resultArray: Array<any> = [];
    let arrayQuestion: Array<any> = [];
    let flattenedResultArray: Array<Types.TreeTraining> = [];
    if (
      getDataLevel1.fulfilled.match(responseStatus[0]) &&
      getDataLevel2.fulfilled.match(responseStatus[1]) &&
      getDataLevel3.fulfilled.match(responseStatus[2]) &&
      getDataLevel4.fulfilled.match(responseStatus[3]) &&
      getDataQuesLevel.fulfilled.match(responseStatus[4]) &&
      getDataQues.fulfilled.match(responseStatus[5])
    ) {
      const dataLevel1 = responseStatus[0].payload.items;
      const dataLevel2 = responseStatus[1].payload.items;
      const dataLevel3 = responseStatus[2].payload.items;
      const dataLevel4 = responseStatus[3].payload.items;
      const dataAssignLevel = responseStatus[4].payload.items;
      const dataQues = responseStatus[5].payload.items;
      resultArray = await Promise.all(
        data.items.flatMap((item) => {
          const baseObject = {
            curriculum_code: item.code,
            curriculum_name: item.name,
            curriculum_description: item.description,
            curriculum_sort_order: item.sort_order,
            required_curriculum: item.required_curriculum,
          };
          const matchedLevel1Items = dataLevel1.filter((c) => c.curricullum_code === item.code);

          return matchedLevel1Items.flatMap((level1Item) => {
            const mergedObject1 = {
              ...baseObject,
              level1_i_id: level1Item.i_id,
              level1_name: level1Item.name,
              level1_code: level1Item.code,
              level1_sort_order: level1Item.sort_order,
            };

            const matchedLevel2Items = dataLevel2.filter(
              (level2Item) => level2Item.level1_code === level1Item.code
            );

            return matchedLevel2Items.flatMap((level2Item) => {
              const mergedObject2 = {
                ...mergedObject1,
                level2_i_id: level2Item.i_id,
                level2_name: level2Item.name,
                level2_code: level2Item.code,
                level2_sort_order: level2Item.sort_order,
              };
              const matchedLevel3Items = dataLevel3.filter(
                (level3Item) => level3Item.level2_code === level2Item.code
              );

              return matchedLevel3Items.flatMap((level3Item) => {
                const mergedObject3 = {
                  ...mergedObject2,
                  level3_i_id: level3Item.i_id,
                  level3_name: level3Item.name,
                  level3_code: level3Item.code,
                  level3_sort_order: level3Item.sort_order,
                };
                const matchedLevel4Items = dataLevel4.filter(
                  (level4Item) => level4Item.level3_code === level3Item.code
                );

                return matchedLevel4Items.flatMap((level4Item) => {
                  const mergedObject4 = {
                    ...mergedObject3,
                    level4_i_id: level4Item.i_id,
                    level4_name: level4Item.name,
                    level4_code: level4Item.code,
                    level4_sort_order: level4Item.sort_order,
                  };
                  const matchedQuestionItems = dataAssignLevel
                    .filter((que) => que.level4_code === level4Item.code)
                    .map((que) => ({
                      ...que,
                      id: que.id,
                      question_sort_order: que.sort_order,
                      question_assign_level_i_id: que.i_id,
                    }));

                  const filteredItems = intersectionBy(dataQues, matchedQuestionItems, 'code');

                  return filteredItems.map((que) => ({
                    ...mergedObject4,
                    i_id: que.i_id,
                    question_code_i_id: que.i_id,
                    question_code: que.code,
                    question_name: que.name,
                    question_sort_order: que.question_sort_order,
                    question_assign_level_i_id: que.question_assign_level_i_id,
                    creator: que.creator,
                    question_description: que.description,
                    score: que.score,
                  }));
                });
              });
            });
          });
        })
      );

      flattenedResultArray = resultArray.flat();
      const question = dataQues.map((que) => ({
        ...que,
        i_id: que.i_id,
        curriculum_code: '',
        curriculum_name: '',
        required_curriculum: '',
        question_code_i_id: que.i_id,
        question_code: que.code,
        question_name: que.name,
        question_sort_order: que.question_sort_order,
        question_assign_level_i_id: que.question_assign_level_i_id,
        creator: que.creator,
        question_description: que.description,
        score: que.score,
      }));
      arrayQuestion = differenceBy(question, flattenedResultArray, 'question_code');
    }
    dispatch(stopLoading());
    return {
      ...data,
      items: [...flattenedResultArray, ...arrayQuestion],
    };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUnrelatedQuestions = createAsyncThunk<
  Types.ReportsItemResponseType<Types.UnrelatedQuestions.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getUnrelatedQuestions', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.UnrelatedQuestions.ResponseType>(
      UNRELATED_QUESTIONS.name,
      {
        ...req,
        sort_fields: [{ id: 'code', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCreatorQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectQuestionCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getCreatorQuestionOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectQuestionCreator.ResponseType>(
      SELECT_QUESTION_CREATOR.name,
      {
        ...req,
        sort_fields: [{ id: 'creator', order: 'asc' }],
      }
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumQuestionOption = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.requestError
>('Curriculum/Search/getCurriculumQuestionOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_CURRICULUMS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumLevelOption = createAsyncThunk<
  { level: number; data: Types.ReportsItemResponseType<Types.SelectLevel.ResponseType> },
  { level: number; company_id: string },
  Types.requestError
>('Curriculum/Search/getCurriculumLevelOption', async (req, { rejectWithValue }) => {
  try {
    const { data } = await curriculumService.findAllCurriculumLevelOption(req.level, {
      page: 1,
      per_page: 0,
      conditions: [
        {
          id: 'company_id',
          search_value: [req.company_id],
        },
      ],
    });

    return { level: req.level, data };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectQuestion = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectQuestion.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Curriculum/Search/thunk/getSelectQuestion', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectQuestion.ResponseType>(
      SELECT_QUESTION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
