import styled from 'styled-components';

export default styled.section`
  background: #ffffff;
  height: 100%;
  .top-page {
    background: #08a3a5;
    padding: 12px 8px;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      .type-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        width: 48%;
        .number-type {
          color: rgba(255, 255, 255, 0.3);
          font-size: 24px;
        }
        .label {
          font-size: 12px;
          font-weight: 700;
        }
        .select {
          width: 55%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
        .select-report-type {
          width: 70%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
      .close-icon {
        color: #ffffff;
        font-size: 16px;
        margin: 0 8px;
      }
    }
  }

  .wrap-body {
    margin: 30px;
    .header-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: 14px;
        color: #424242;
      }
      .button-export {
        background-color: #f6ac00;
        border: 1px solid #f6ac00;
        border-radius: 5px;
        color: #ffffff;
        padding: 0 30px;
      }
    }
    .wrap-filter {
      .label {
        font-size: 11px;
        color: #777777;
      }
      .aggregation-conditions {
        background: #f2f2f2;
        padding: 15px;
        border: 1px solid #eaeaea;
        border-radius: 3px;
        width: 100%;
        .form-select {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .wrap-select {
            display: flex;
            width: 50%;
            .item {
              width: 100%;
              margin-right: 10px;
              .select {
                width: 100%;
                .ant-select-selector {
                  border-radius: 5px;
                }
              }
              .text-label {
                height: 25px;
              }
              .ant-checkbox-wrapper {
                margin: 0 0 7px 10px;
              }
              .text-label {
                display: block;
                font-size: 13px;
                color: #424242;
                .require {
                  color: #f0330a;
                }
              }
            }
          }
          .wrap-time-select {
            display: flex;
            align-items: center;
            background: #f8f8f8;
            border: 1px solid #e9e9e9;
            padding: 10px;
            .item {
              width: 30%;
              margin-right: 8px;
              .text-label {
                color: #424242;
                font-size: 12px;
              }
              .date-input {
                width: 100%;
              }
            }
            .radio-item {
              display: flex;
              align-items: center;
              margin-right: 30px;
              position: relative;
              .three-border {
                border: 1px solid #000000;
                width: 8px;
                height: 30px;
                border-right: 0;
                margin: 5px;
              }
              .radio-select {
                display: flex;
                flex-direction: column;
                .radio-option {
                  padding: 5px 0;
                  span {
                    font-size: 12px;
                    color: #000000;
                  }
                }
                .ant-radio-wrapper {
                  padding: 5px 0;
                  span {
                    font-size: 12px;
                    color: #000000;
                  }
                }
              }
              &::before {
                content: '';
                position: absolute;
                bottom: 15px;
                right: -10px;
                width: 0;
                height: 0;
                border-top: 10px solid #f9f8f8;
                border-bottom: 10px solid #f9f8f8;
                border-left: 10px solid #dddddd;
              }
            }
          }
        }
      }
    }
    .note-compareType {
      font-size: 13px;
      color: #424242;
      margin: 20px 0;
    }
    .sub-container {
      position: relative;
      margin-top: 25px;
      .wrap-segmented {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        .left-side {
          display: flex;
          align-items: center;
          .label {
            font-size: 13px;
            color: #424242;
          }
          .segmented {
            display: flex;
            align-items: center;
            jusity-content: center;
            .segmented-item {
              padding: 5px 10px;
              background: #ffffff;
              border: 2px solid #cccccc;
              cursor: pointer;
              color: #424242;
              font-weight: 400;
              font-size: 13px;
              .icon {
                margin-right: 10px;
                color: #c4c4c4;
              }
              &:nth-child(1) {
                border-radius: 3px 0 0 3px;
                border-right-color: #08a3a5;
              }
              &:nth-child(2) {
                border-radius: 0 3px 3px 0;
                border-left-width: 0;
              }
            }
            .segmented-item-selected {
              border-color: #08a3a5;
              color: #08a3a5;
              font-weight: bold;
              .icon {
                color: #08a3a5;
              }
            }
          }
          .cross-boiled {
            margin: 0 10px;
          }
          .item {
            margin-bottom: 0;
            width: 230px;
            .select {
              width: 100%;
            }
          }
        }
      }
    }
  }
`;
