import styled from 'styled-components';

export default styled.section`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f9f8f8;
  .background {
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100vw;
  }
  .canvas {
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: auto;
    width: 560px;
    min-height: 560px;
    background: #ffffff;
    padding: 40px 0;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    z-index: 9;
  }
`;
