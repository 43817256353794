import React, { useMemo, useState } from 'react';
import { UploadChangeParam, UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import saveAs from 'file-saver';
import { Upload } from 'antd';
import { CloudUploadOutlined, DeleteOutlined, FileOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';
import { AnyObject, HeaderCSVs } from 'types/config';
import ImportedDataTable from 'components/ImportedDataTable';
import { ArraySchema, ObjectSchema, ValidationError } from 'yup';
import { parse, ParseResult } from 'papaparse';
import { extractYupErrorPath, toObject } from 'libs';
import lodash from 'lodash';
import ConfirmSuccessModal from 'components/Modal/ConfirmSuccess';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  onSubmit: (data: AnyObject[]) => Promise<any>;
  headerCSVs: HeaderCSVs;
  dataSchema: ArraySchema<ObjectSchema<AnyObject>>;
  fileNameTemplate?: string;
}

const { Dragger } = Upload;

const convertCsvData = (data: Array<string[]>, headerCSVs: HeaderCSVs) => {
  const headerCsvObj = toObject(headerCSVs, 'label');
  const headers = data[0];
  return data
    .map((item) =>
      Object.fromEntries(
        headers.map((title, i) => [headerCsvObj[title]?.key || title, item[i] || undefined])
      )
    )
    .splice(1);
};

const UploadCSV: React.FC<Props> = (props) => {
  const {
    visible,
    setVisible,
    title,
    onSubmit,
    headerCSVs,
    dataSchema,
    fileNameTemplate = title,
  } = props;

  const [isSuccessfully, setIsSuccessfully] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [file, setFile] = useState<File>();
  const [dataImported, setDataImported] = useState<AnyObject[]>();
  const [headersImported, setHeadersImported] = useState<string[]>();
  const [yupErrors, setYupErrors] = useState<ValidationError>();

  const onClose = () => {
    setVisible(false);
  };

  const handleDelete = () => {
    setFile(undefined);
    setDataImported(undefined);
    setHeadersImported(undefined);
    setYupErrors(undefined);
    setIsSuccessfully(false);
  };

  const handleSubmit = async () => {
    try {
      setImporting(true);
      const indexErrors = Object.keys(errors || {}).map((idx) => Number(`${idx}`));
      await onSubmit(dataImported!.filter((_, index) => !indexErrors.includes(index)));
      setIsSuccessfully(true);
    } catch (e) {
      console.log(e);
    } finally {
      setImporting(false);
    }
  };

  const errors = useMemo(() => {
    if (!yupErrors) {
      return undefined;
    }
    let errorObj: AnyObject = {};
    yupErrors.inner.forEach((error: any) => {
      const errorPath = extractYupErrorPath(error.path);
      errorObj = {
        ...errorObj,
        [errorPath.index]: {
          ...(errorObj[errorPath.index] || {}),
          [`${errorPath.key}_error`]: error.message,
        },
      };
    });

    return errorObj;
  }, [yupErrors]);

  const okProps = useMemo(() => {
    const diffHeaders = lodash.difference(
      headerCSVs.map((obj) => obj.label),
      headersImported || []
    );
    if (!file || diffHeaders.length) {
      return {
        text: 'インポート',
        disabled: true,
      };
    }
    const total = dataImported?.length || 0;
    const totalErrors = errors ? Object.keys(errors).length : 0;
    if (total === totalErrors) {
      return {
        text: 'インポート',
        disabled: true,
      };
    }
    if (!totalErrors && total) {
      return {
        text: 'インポート',
        disabled: false,
      };
    }
    return {
      text: `${total}件のうち${total - totalErrors}件を インポート`,
      disabled: false,
    };
  }, [errors, dataImported, file, headersImported, headerCSVs]);

  const handleExportCSV = () => {
    const csvString = [headerCSVs.map(({ label }) => label)].map((e) => e.join(',')).join('\n');
    const bom = '\uFEFF';
    const fileExport = new Blob([bom, csvString], { type: 'application/octet-stream' });
    saveAs(fileExport, `テンプレートの${fileNameTemplate}.csv`);
  };

  const onFileChange = (info: UploadChangeParam<UploadFileAntd<File>>) => {
    const inputFile = info.file as unknown as File;
    setFile(inputFile);
    parse(inputFile, {
      complete: async ({ data }: ParseResult<string[]>) => {
        const resultsDataCSV = convertCsvData(data, headerCSVs);
        setDataImported(resultsDataCSV);
        setHeadersImported(data[0]);
        try {
          await dataSchema.validate(resultsDataCSV, {
            abortEarly: false,
          });
        } catch (err) {
          if (err instanceof ValidationError) {
            setYupErrors(err);
          }
        }
      },
      skipEmptyLines: true,
    });
  };

  return (
    <>
      <ConfirmSuccessModal
        visible={isSuccessfully}
        setVisible={setIsSuccessfully}
        subTitle="インポートが完了しました！"
        onSubmit={() => setVisible(false)}
      />
      <Modal
        title={title}
        width={1440}
        open={visible}
        okButton={{
          onClick: handleSubmit,
          loading: importing,
          ...okProps,
        }}
        cancelButton={{
          text: 'キャンセル',
          onClick: onClose,
        }}
        onCancel={onClose}
        bodyStyle={{
          backgroundColor: '#f9f8f8',
          padding: '8px 16px',
        }}
        footerStyle={{
          backgroundColor: '#f9f8f8',
        }}
        headerStyle={{
          justifyContent: 'left',
          fontSize: '18px',
          color: '#2A2A2A',
        }}
        afterClose={handleDelete}
      >
        <SectionStyled>
          <div className="form-upload">
            <p className="text-download-template" onClick={handleExportCSV}>
              <FileOutlined className="icon" />
              テンプレートをダウンロード
            </p>
            <div className="form-upload-border">
              <div className="file-upload">
                <div className="flex">
                  <Dragger accept=".csv" beforeUpload={() => false} onChange={onFileChange}>
                    {file ? (
                      <div className="info-file">
                        <p className="name-file">{file.name} </p>
                      </div>
                    ) : (
                      <>
                        <CloudUploadOutlined className="icon" />
                        <p className="ant-upload-text">インポートするCSVファイルをここにドロップ</p>
                      </>
                    )}
                    <div className="wrap-button-upload">
                      <button type="button" className="btn-upload">
                        ファイルを選択
                      </button>
                      {file && (
                        <button
                          className="btn-delete"
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDelete();
                          }}
                        >
                          <DeleteOutlined className="icon-delete-outlined" />
                          <span className="text-delete-outlined">ファイルを削除</span>
                        </button>
                      )}
                    </div>
                  </Dragger>
                </div>
              </div>
            </div>
            <ImportedDataTable
              headerCSVs={headerCSVs}
              attached={!!file}
              dataImported={dataImported}
              headersImported={headersImported}
              errors={errors}
            />
          </div>
        </SectionStyled>
      </Modal>
    </>
  );
};

export default UploadCSV;
