import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, omit } from 'lodash';

import {
  getConfirmRegistration,
  getUserInfo,
  isFirstLogin,
  signIn,
  getDataCompany,
  getDataUserCompany,
  getAffiliationAssignRole,
  getTotalFileSize,
} from './thunk';
import * as Types from 'types';

export interface InitialState {
  registrationConfirm?: Types.GetConfirmRegistrationRes;
  dataCompany?: Types.Companies.ResponseType;
  userCompany?: Types.Companies.ResponseType;
  staffs: Array<Types.Users.ResponseType>;
  userInfo?: Types.Users.ResponseType;
  signedInEmail?: string;
  isFirstLogin: boolean;
  IDconfirming: boolean;
  isLogin: boolean;
  loading: boolean;
  isOpenMessages: boolean;
  isOpen: boolean;
  err?: string;
  totalFileSize: number;
}

const initialState: InitialState = {
  isLogin: !!(localStorage.getItem('email') && localStorage.getItem('remember_me') === 'saved'),
  isFirstLogin: false,
  IDconfirming: false,
  loading: false,
  isOpen: true,
  isOpenMessages: false,
  staffs: [],
  totalFileSize: 0,
};

export const authSlice = createSlice({
  name: 'auth-container',
  initialState,
  reducers: {
    logout: (state) => {
      state.isLogin = false;
      state.userInfo = undefined;
      state.signedInEmail = undefined;
      localStorage.removeItem('sk_access_token');
      localStorage.removeItem('email');
    },
    updateOpenModal: (state, action) => {
      state.isOpen = action.payload;
    },
    isOpenMessages: (state, action) => {
      state.isOpenMessages = action.payload;
    },
    updateUserInfoReducer: (state, action: PayloadAction<Types.Users.ResponseType>) => {
      state.userInfo = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
    updateDataCompany: (state, action: PayloadAction<Types.Companies.ResponseType>) => {
      state.dataCompany = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.isLogin = true;
      state.signedInEmail = action.payload.signInUrl;
    });
    builder.addCase(signIn.rejected, (state, action) => {
      state.err = action.error.message;
      state.isLogin = false;
    });
    builder.addCase(isFirstLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(isFirstLogin.fulfilled, (state, action) => {
      state.isFirstLogin = !action.payload.totalItems;
      if (action.payload.totalItems) {
        const userInfoExists = isEqual(
          omit({ ...state.userInfo }, ['avatar']),
          omit(action.payload.items[0], ['avatar'])
        );

        if (!userInfoExists) {
          state.userInfo = action.payload.items[0];
        }
        state.isLogin = true;
      } else {
        if (!state.signedInEmail) {
          state.isLogin = false;
        }
      }
      state.loading = false;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const userInfoExists = isEqual(
        omit({ ...state.userInfo }, ['avatar']),
        omit(action.payload.items[0], ['avatar'])
      );

      if (!userInfoExists) {
        state.userInfo = action.payload.items[0];
      }
      state.loading = false;
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(isFirstLogin.rejected, (state) => {
      state.isLogin = false;
      state.loading = false;
    });
    builder.addCase(getConfirmRegistration.pending, (state) => {
      state.IDconfirming = true;
    });
    builder.addCase(getConfirmRegistration.fulfilled, (state, action) => {
      state.registrationConfirm = action.payload;
      state.IDconfirming = false;
    });
    builder.addCase(getConfirmRegistration.rejected, (state) => {
      state.IDconfirming = false;
    });
    builder.addCase(getDataCompany.fulfilled, (state, action) => {
      state.dataCompany = action.payload.items[0];
    });
    builder.addCase(getDataUserCompany.fulfilled, (state, action) => {
      state.userCompany = action.payload.items[0];
    });

    builder.addCase(getAffiliationAssignRole.fulfilled, (state, action) => {
      state.staffs = action.payload.items;
    });
    builder.addCase(getTotalFileSize.fulfilled, (state, action) => {
      state.totalFileSize = action.payload.items.reduce(
        (result, next) => result + Number(next.file_size) / 1073741824,
        0
      );
    });
  },
});

export const {
  logout,
  updateOpenModal,
  isOpenMessages,
  updateUserInfoReducer,
  resetInitialState,
  updateDataCompany,
} = authSlice.actions;
export default authSlice.reducer;
