import yup from '../yup';

const regex = {
  fullwidth: /^[゠-ヿ]+$/,
  halfwidth: /^[0-9]+$/,
};

export const createEmployeeSchema = yup.object().shape({
  employee_code: yup.string().matches(regex.halfwidth, '半角数字のみ入力してください'),
  name: yup.string().required(),
  name_furigana: yup.string().matches(regex.fullwidth, '全角カタカナのみ入力してください'),
  email: yup.string().email().required(),
  email_confirm: yup
    .string()
    .email()
    .required()
    .oneOf([yup.ref('email'), null], 'Email confirmation is not the same as email'),
  password: yup.string().required().max(30).min(4),
  password_confirm: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'パスワードが一致しません')
    .max(30)
    .min(4),
  role_code: yup.string().required(),
  main_position: yup.object({
    affiliation_id: yup.string().required(),
    positions_id: yup.string().required(),
  }),
  sub_position: yup.array().of(
    yup.object().shape({
      affiliation_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.positions_id && value) ||
            (!value && !ctx.parent.positions_id) ||
            (value && !ctx.parent.positions_id)
        ),
      positions_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.affiliation_id && value) ||
            (!ctx.parent.affiliation_id && !value) ||
            (value && !ctx.parent.affiliation_id)
        ),
    })
  ),
});

export const updateUserSchema = yup.object().shape({
  employee_code: yup.string().matches(regex.halfwidth, '半角数字のみ入力してください'),
  name: yup.string().required(),
  name_furigana: yup.string().matches(regex.fullwidth, '全角カタカナのみ入力してください'),
  role_code: yup.string().required(),
  dob: yup.string(),
  doj: yup.string(),
  main_position: yup.object().shape({
    affiliation_id: yup.string().required(),
    position_code: yup.string().required(),
  }),
  sub_position: yup.array().of(
    yup.object().shape({
      affiliation_id: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.positions_code && value) ||
            (!value && !ctx.parent.positions_code) ||
            (value && !ctx.parent.positions_code)
        ),
      position_code: yup
        .string()
        .test(
          'required',
          '値を入力してください',
          (value, ctx) =>
            (ctx.parent.affiliation_id && value) ||
            (!ctx.parent.affiliation_id && !value) ||
            (value && !ctx.parent.positions_code)
        ),
    })
  ),
});
