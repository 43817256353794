import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { v4 as uuidv4 } from 'uuid';
import { Modal } from 'antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { createFileAttach } from '../thunk';
import VideoPopup from '../UploadVideo';
import { useAppDispatch } from 'hooks';
import { adminAvatar } from 'assets';
import * as Types from 'types';

interface Props {
  action?: 'create' | 'edit';
  setVisibleEditQAAnswer: React.Dispatch<React.SetStateAction<boolean>>;
  setTextQAAnswer: React.Dispatch<React.SetStateAction<string>>;
  data?: Types.KnowledgeQADetailOriginal.ResponseType;
  onSubmit: () => void;
  textQAAnswer: string;
}

const CreateQAAnswer: React.FC<Props> = ({
  setVisibleEditQAAnswer,
  onSubmit,
  setTextQAAnswer,
  action,
  data,
  textQAAnswer,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '全角：最大1500文字',
      length: 1500,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block', 'add-new-line'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const base64Array: Array<string> = useMemo(() => {
    if (!textQAAnswer) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(textQAAnswer, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [textQAAnswer]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '5',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = textQAAnswer.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      setTextQAAnswer(replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  return (
    <div className="qa-answer">
      <div className="info-comment">
        <img
          className="avt-icon"
          src={
            userInfo?.avatar?.originFileObj
              ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
              : adminAvatar
          }
          alt="avt-user"
        />
        <div className="item-name">
          <div className="label-name">{data?.to_department_name}</div>
          <div className="name">{userInfo?.name}</div>
        </div>
      </div>
      <div className="form-add-answer">
        <JoditEditor
          value={textQAAnswer}
          config={defaultConfig}
          onBlur={(newContent) => setTextQAAnswer(newContent)}
          onChange={(newContent) => {
            setTextQAAnswer(newContent);
          }}
        />
        {showPopup && (
          <Modal
            visible={showPopup}
            closable={false}
            onOk={handlePopupClose}
            onCancel={handlePopupClose}
            footer={null}
          >
            <VideoPopup
              content={textQAAnswer}
              setContent={setTextQAAnswer}
              setOpen={setShowPopup}
            />
          </Modal>
        )}
        <div className="wrap-add-answer">
          <div className="group-btn">
            <button
              className={`btn ${textQAAnswer ? 'btn-active' : 'btn_submit'}`}
              disabled={!textQAAnswer}
              onClick={() => {
                onSubmit();
              }}
            >
              {action === 'create' ? '回答する！' : '更新する！'}
            </button>
            <button className="btn default" onClick={() => setVisibleEditQAAnswer(false)}>
              キャンセル
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];

export default CreateQAAnswer;
