import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import { Button } from 'antd';
import dayjs from 'dayjs';

import { CloudDownloadOutlined } from '@ant-design/icons';

import { usageStatusSelector } from 'pages/UsageStatus/selectors';
import PopupConfirmReceiptExport from '../ConfirmExportPDF';
import FileExportInvoicePDF from '../FileExportInvoicePDF';
import { formatNumberComma } from 'libs/utils/format';
import { InvoicePreviewStyled } from './styles';
import { LogoNav, LogoRStandard } from 'assets';
import * as Types from 'types';

interface Props {
  visibleInvoicePreview: boolean;
  setVisibleInvoicePreview: React.Dispatch<React.SetStateAction<boolean>>;
  dataSelected?: Types.UsageList.DataConverted;
}

const InvoicePreview: React.FC<Props> = ({
  setVisibleInvoicePreview,
  visibleInvoicePreview,
  dataSelected,
}) => {
  const [visiblePopupConfirmReceiptExport, setVisiblePopupConfirmReceiptExport] =
    useState<boolean>(false);

  const { information } = useSelector(usageStatusSelector);

  const dataTableMock = [
    {
      content: 'ユーザー利用料',
      unitPrice: (dataSelected?.unit_price_user || 0).toLocaleString(),
      quantity: (dataSelected?.max_users || 0).toLocaleString(),
      total: (dataSelected?.user_fee || 0).toLocaleString(),
    },
    {
      content: 'スキルチェック利用料',
      unitPrice: (dataSelected?.usage_storage_unit_price || 0).toLocaleString(),
      quantity: (dataSelected?.usage_storage || 0).toLocaleString(),
      total: (dataSelected?.user_fee_storage || 0).toLocaleString(),
    },
    {
      content: 'ユーザー利用料',
      unitPrice: (dataSelected?.unit_price || 0).toLocaleString(),
      quantity: (dataSelected?.skill_check_usage_count || 0).toLocaleString(),
      total: (dataSelected?.skill_check_usage_fee || 0).toLocaleString(),
    },
  ];

  const handleTotalProduct = () => {
    return sumBy(dataTableMock, (item) => parseFloat(item.total.replace(/,/g, '')));
  };

  const handleTotalProductFinal = () => {
    return handleTotalProduct() + handleTotalProduct() * 0.1;
  };

  return (
    <InvoicePreviewStyled
      title="領収書"
      width={860}
      visible={visibleInvoicePreview}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisibleInvoicePreview(false),
      }}
      onCancel={() => setVisibleInvoicePreview(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <div className="wrap-header">
        <div className="time-label">
          <span className="label">サービス利用年月：</span>
          <span className="time-text">
            {dataSelected && dayjs(dataSelected.contract_date).format('YYYY年MM月')}
          </span>
        </div>
        <Button className="button-export" onClick={() => setVisiblePopupConfirmReceiptExport(true)}>
          <CloudDownloadOutlined className="download-icon" />
          エクスポート
        </Button>
      </div>
      <div className="wrap-body">
        <div className="content">
          <div className="content-header">
            <div className="content-header-logo">
              <img src={LogoNav} alt="logo-rs-tandard" />
              <div className="content-header-text">
                <span>請求書No.&nbsp;&nbsp;&nbsp;&nbsp;{dataSelected?.i_id}</span>
                <span>
                  請求日&nbsp;&nbsp;&nbsp;&nbsp;
                  {dataSelected &&
                    dayjs(dataSelected?.contract_date).endOf('month').format('YYYY年MM月DD日')}
                </span>
              </div>
            </div>
            <span className="title-modal">請求書</span>
            <div className="wrap-title-header">
              <span className="name-company">{information.name} 御中</span>
              <div className="wrap-content-standard">
                <span className="title-standard">アールスタンダード</span>
                <span className="sub-title-standard">株式会社RSTANDARD</span>
              </div>
            </div>
            <div className="info-content">
              <div className="info-content-left">
                <span>
                  〒
                  {information.postal_code.toLocaleString().substring(0, 3) +
                    '-' +
                    information.postal_code.toLocaleString().substring(3)}
                </span>
                <span>
                  {information.prefecture} - {information.address} -{information.plot_number}-{' '}
                  {information.building_name}
                </span>
                <span>TEL：</span>
                <span>FAX：</span>
                <span>登録番号：</span>
              </div>
              <div className="info-content-right">
                <span>〒 123-1234</span>
                <span>東京都台東区上野3-17-7</span>
                <span>G-SQUARE上野4F</span>
                <span>TEL：03-6803-2893</span>
                <span>FAX：03-6803-2894</span>
                <span>登録番号：T1234567890123</span>
                <img src={LogoRStandard} alt="logo-rs-tandard" />
              </div>
            </div>
            <div className="total-header">
              <span className="total-header-title">ご請求金額（税込）</span>
              <span className="total-number">
                {formatNumberComma(handleTotalProductFinal())}
                <span className="currency-unit">円</span>
              </span>
            </div>

            <div className="wrap-usage-date">
              <span className="title">サービス利用年月 : </span>
              <span className="date">
                {dataSelected && dayjs(dataSelected?.contract_date).format('YYYY年MM月')}
              </span>
            </div>
          </div>
          <div className="content-body">
            <table>
              <tbody>
                <tr>
                  <th>内容</th>
                  <th>単価</th>
                  <th>数量</th>
                  <th>合計</th>
                </tr>
                {dataTableMock.map((item, index) => (
                  <tr key={index}>
                    <td>{item.content}</td>
                    <td>{item.unitPrice}</td>
                    <td>{item.quantity}</td>
                    <td>{item.total}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="sub-table">
              <div className="wrap-sub-item">
                <div className="sub-item">
                  <span className="sub-title">小計</span>
                  <span className="sub-price">{formatNumberComma(handleTotalProduct())}</span>
                </div>
                <div className="sub-item">
                  <span className="sub-title">消費税等（10%）</span>
                  <span className="sub-price">{formatNumberComma(handleTotalProduct() * 0.1)}</span>
                </div>
                <div className="sub-item">
                  <span className="sub-title">合計</span>
                  <span className="sub-price">{formatNumberComma(handleTotalProductFinal())}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="content-footer">
            <div>
              <span>【お振込先】</span>
              <div>
                <span>みずほ銀行（0001）上野支店（107）</span>
                <span>（普通）2689387</span>
                <span>株式会社RSTANDARD</span>
              </div>
              <span>※お振込手数料は御社負担にてお願いいたします。</span>
            </div>
          </div>
        </div>
      </div>
      <PopupConfirmReceiptExport
        visible={visiblePopupConfirmReceiptExport}
        setVisible={setVisiblePopupConfirmReceiptExport}
        FilePdf={
          <FileExportInvoicePDF
            information={information}
            dataTableMock={dataTableMock}
            handleTotalProduct={handleTotalProduct}
            handleTotalProductFinal={handleTotalProductFinal}
          />
        }
      />
    </InvoicePreviewStyled>
  );
};

export default InvoicePreview;
