import styled from 'styled-components';

export const SectionStyled = styled.div`
  font-size: 13px;
  color: #424242;
  padding-top: 20px;
  text-align: center;
  .sub-title {
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    .icon {
      color: #bbbbbb;
      font-size: 28px;
      margin-right: 10px;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #777777;
  }
  .btn {
    display: inline-flex;
    .btn-submit {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      padding: 20px 0;
      margin: 28px 3px 20px auto;
      width: 140px;
      background-color: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 15px;
      color: #ffffff;
      font-weight: 400;
      cursor: pointer;
    }
    .btn-close {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #d9d9d9;
      padding: 20px 0;
      width: 140px;
      margin: 28px auto 20px 3px;
      background-color: #ffffff;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 15px;
      font-weight: 400;
      color: #777777;
      cursor: pointer;
    }
  }
`;
