import React from 'react';
import { Progress, Row } from 'antd';

import { DataDashboardCardType } from 'pages/Dashboard/slice';
import { HomeCardColour } from 'constant/enum.constant';
import { formatNumber } from 'libs/utils/format';
import { UserType } from 'types/config';
import { Wrapper } from './styles';

interface ProgressCircleProps {
  role?: UserType;
  color: HomeCardColour;
  data: DataDashboardCardType;
  valueSelect?: string | null;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({ color, data, valueSelect }) => {
  const stockColor: { [key: string]: string } =
    color === HomeCardColour.green
      ? {
          '0%': '#b0dad0',
          '100%': '#3fc2a3',
        }
      : color === HomeCardColour.blue
      ? {
          '0%': '#9fcfe3',
          '100%': '#089AD4',
        }
      : {
          '0%': '#b6b4f2',
          '100%': '#BD85D7',
        };

  return (
    <Wrapper cardColor={color}>
      <Row className="progress-circle">
        {color === HomeCardColour.purple && <span className="">{valueSelect}</span>}
        <Progress
          type="circle"
          strokeLinecap="butt"
          strokeColor={stockColor}
          strokeWidth={7}
          percent={Number((data.per_of_correct_answers * 100).toFixed())}
          width={180}
          format={(percent1) =>
            color === HomeCardColour.purple ? (
              <Progress
                type="circle"
                strokeLinecap="butt"
                strokeColor={{
                  '0%': '#6181d34d',
                  '100%': '#6181d3cc',
                }}
                strokeWidth={5}
                percent={Number(formatNumber(data?.accuracy_rate_last_month || 0 * 100))}
                width={150}
                format={(percent2) => (
                  <div className="progress-inside">
                    <Row className="progress-text">正解率</Row>
                    <Row className="progress-presentage border-bottom">
                      <span className="progress-present-number">{percent1}</span>
                    </Row>
                    <Row className="progress-presentage">
                      <span className="progress-present-number sub-present-number">{percent2}</span>
                    </Row>
                    <Row className="progress-text sub-text">前回正答率</Row>
                  </div>
                )}
              />
            ) : (
              <div className="progress-inside">
                <Row className="progress-text">進捗率</Row>
                <Row className="progress-presentage">
                  <span className="progress-present-number">{percent1}</span>
                </Row>
              </div>
            )
          }
        />
      </Row>
    </Wrapper>
  );
};

export default ProgressCircle;
