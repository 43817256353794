import styled from 'styled-components';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  .content-label-status {
    display: flex;
    align-items: center;
    .label-status {
      margin: 0;
    }
  }
  .not-implemented,
  .implemented {
    display: flex;
    align-items: center;
  }
  .not-implemented .label-status {
    color: #424242;
  }
  .icon-edit {
    display: flex;
    align-items: center;
    .label-status {
      color: #f6a200;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .icon-stop {
    display: flex;
    align-items: center;
    .label-status {
      color: #f33456;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .icon-completed {
    display: flex;
    align-items: center;
    .label-status {
      color: #159193;
      margin: 0;
    }
    .icon {
      margin-right: 8px;
    }
  }
  .status {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    border-radius: 2px;
    margin: 0 8px 0 0;
    .text-status {
      margin: 0;
    }
  }
  .edit-icon {
    background-color: #f6a200;
    span {
      color: #f6a200;
    }
  }
  .stop-icon {
    background-color: #f33456;
  }
  .status-done {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background-color: #159193;
    border-radius: 2px;
    margin: 0 8px 0 0;
    .text-status {
      margin: 0;
    }
  }
  .form-search {
    display: flex;
    flex-direction: column;
    align-items: center;
    .form-first {
      display: flex;
      align-items: center;
      width: 100%;
      .item {
        width: 20%;
        margin-right: 8px;
      }
      .date {
        margin-bottom: 16px;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-right: 12px;
        display: block;
        width: 60px;
        margin-left: 10px;
      }
    }
    .form-second {
      display: flex;
      align-items: center;
      flex-direction: row;
      width: 100%;
      .form-left {
        display: flex;
        flex-direction: row;
        width: 60%;
        .item {
          width: 34%;
          margin-right: 8px;
        }
        .label-reset {
          font-size: 12px;
          cursor: pointer;
          text-decoration-line: underline;
          color: #00a3a5;
          margin-right: 12px;
          display: block;
          width: 60px;
          margin-left: 10px;
        }
      }
      .form-right {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: end;
        width: 40%;
      }

      .item {
        width: 20%;
        margin-right: 8px;
      }
      .label-reset {
        font-size: 12px;
        cursor: pointer;
        text-decoration-line: underline;
        color: #00a3a5;
        margin-right: 12px;
        display: block;
        width: 60px;
        margin-left: 10px;
      }
      .btn-search {
        background: #ffffff;
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-size: 14px;
        color: #00a3a5;
        cursor: pointer;
        .icon-search {
          font-size: 18px;
          margin-right: 12px;
        }
      }
    }
  }
  .table {
    position: relative;
    margin-bottom: 80px;
    .ant-table-thead .ant-table-cell {
      font-weight: 700;
      font-size: 12px;
      color: #2a2a2a;
      ::before {
        display: none;
      }
    }
    .ant-table-tbody .ant-table-cell {
      font-family: Lato;
      font-size: 13px;
      color: #424242;
    }
    .ant-pagination-item-active {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 10px;
      margin-top: 2px;
      font-size: 11px;
    }
  }
  .text-count-general {
    margin: 12px 0 -50px 0;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: fit-content;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
    .page-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
    }
  }
  .text-count-no-data {
    margin: 12px 0 20px 0;
  }
  .date,
  .ant-form-item-label .text-label {
    color: #424242;
    font-size: 12px;
  }
  .ant-select-selection-item {
    .text-option {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0;
      align-items: center;
      .text-icon {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        background: #f33456;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
    .text-user-type {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 13px;
      margin: 0;
      .text-icon-type {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        border-radius: 2px;
        margin-right: 8px;
      }
      .interview {
        background: #4e83d3;
      }
      .member {
        background: #f08b84;
      }
    }
    .icon-edit {
      .icon {
        color: #ffffff;
        width: 18px;
        height: 18px;
        background: #f6ac00;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
  .ant-select-item-option-content {
    .text-option {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0;
      align-items: center;
      .text-icon {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        background: #f33456;
        border-radius: 2px;
        margin-right: 8px;
      }
    }
    .text-user-type {
      display: flex;
      flex-direction: row;
      font-size: 13px;
      margin: 0;
      align-items: center;
      .text-icon-type {
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        padding: 3px;
        border-radius: 2px;
        margin-right: 8px;
      }
      .interview {
        background: #4e83d3;
      }
      .member {
        background: #f08b84;
      }
    }
    .icon-edit {
      .icon {
        color: #ffffff;
        width: 18px;
        height: 18px;
        background: #f6ac00;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }

  @media (max-width: 1440px) {
    .form-search {
      .form-first {
        width: 100%;
        .item {
          width: 226px;
          margin-right: 8px;
        }
        .date {
          margin-bottom: 16px;
        }
      }
      .form-second {
        width: 100%;
        .form-left {
          width: 60%;
          .item {
            width: 226px;
            margin-right: 8px;
          }
        }
        .form-right {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: end;
          width: 40%;
        }

        .item {
          width: 20%;
          margin-right: 8px;
        }
      }
    }
  }
`;
