import { Locale } from 'constant/enum.constant';
import { Entries } from 'types/config';
import EnLang from './entries/en-US';
import JaLang from './entries/ja-JP';
import ZhLang from './entries/zh_CN';

const AppLocale: Record<keyof typeof Locale, Entries> = {
  en: EnLang,
  ja: JaLang,
  zh: ZhLang,
};

export default AppLocale;
