import React, { useMemo } from 'react';

import BreakdownAnswersPerCentage from './BreakdownAnswersPerCentage';
import { ITEM_GRAPH } from 'constant/select.constants';
import TableChart from './TableChart';

interface Props {
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  curriculum?: string;
  username?: string;
  exportPdf?: boolean;
}

const Chart: React.FC<Props> = ({ curriculum, username, itemGraph, exportPdf }) => {
  const isUserTransTable = useMemo(
    () => !!username && !!curriculum && username === 'ALL' && curriculum !== 'ALL',
    [curriculum, username]
  );

  return itemGraph === 'bar_chart' ? (
    <TableChart isUserTransTable={isUserTransTable} />
  ) : (
    <BreakdownAnswersPerCentage
      isUserTransTable={isUserTransTable}
      isAllCurriculumUsers={
        !!username && !!curriculum && username === 'ALL' && curriculum === 'ALL'
      }
      exportPdf={exportPdf}
    />
  );
};

export default Chart;
