import { Input, Modal, SelectField, SpinLoading, TextArea } from 'components';
import { Button, Col, Row, Select, Tooltip } from 'antd';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QuestionCircleOutlined, SelectOutlined } from '@ant-design/icons';
import { UploadFile as UploadFileAntd } from 'antd/lib/upload/interface';
import { isEqual, minBy, includes, isEmpty } from 'lodash';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form, Radio } from 'formik-antd';
import ReactPlayer from 'react-player';
import { UploadFile } from 'antd/es';

import { ErrorModelToOpen, UploadFileToServerModel } from 'types/components';
import { questionSelector } from 'containers/CreateEditQuestion/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import UploadFileToServer from 'components/Modal/UploadFilesToServer';
import { getCompany, getDataQuestionCurriculum } from '../../thunk';
import { settingSelector } from 'containers/AppSettings/selectors';
import { createEditQuestionSchema } from 'libs/validations';
import ActionErrorModal from 'components/Modal/ActionError';
import { authSelector } from 'containers/Auth/selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { FileType } from 'constant/enum.constant';
import { UploadImageVideoQuestion } from '../';
import exportPDF from 'libs/utils/exportPdf';
import QuestionPDF from '../../questionPDF';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import PopupComment from '../Comment';
import { NoImage2 } from 'assets';
import * as Types from 'types';
import {
  handleGetQuestionDetail,
  handleGetFileQuestionDetail,
  handleSubmit,
} from 'libs/utils/question';

interface Props {
  page?: number;
  setVisibleSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  openModalCreateEditQuestion: {
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
  };
  setOpenModalCreateEditQuestion: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      type: 'create' | 'edit';
    }>
  >;
  fetchData?: () => void | Promise<void>;
  defaultTimeLimit?: number;
}

const { Option } = Select;

const initShowActionErrorModal = {
  errorVisible: false,
  title: '',
  subTitle: '',
  description: '',
};

const CreateEditQuestionMaster: React.FC<Props> = ({
  page,
  defaultTimeLimit,
  setVisibleSuccess,
  setOpenModalCreateEditQuestion,
  fetchData,
  openModalCreateEditQuestion: { type, visible, question_id },
}) => {
  const [uploading, setUploading] = useState<UploadFileToServerModel>({ show: false, files: [] });
  const [questionDetail, setQuestionDetail] = useState<Types.CreateEditQuestionFormik>();
  const [showPopupComment, setShowPopupComment] = useState<boolean>(false);
  const [fileIdsToDelete, setFileIdsToDelete] = useState<string[]>([]);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [filesInServer, setFilesInServer] = useState<string[]>([]);
  const [showActionErrorModal, setShowActionErrorModal] =
    useState<ErrorModelToOpen>(initShowActionErrorModal);
  const [showPopupUploadMedia, setShowPopupUploadMedia] = useState<{
    show: boolean;
    data: Array<UploadFileAntd<File>>;
    dataName: Array<string>;
    dataProblem: Array<{
      content: string;
      indexProblem: number;
      file: UploadFileAntd<File>;
      fileID: string;
    }>;

    type: Types.UploadFileFieldType;
    fileAmount?: number;
    fistIndex?: number;
  }>({
    show: false,
    data: [],
    dataName: [],
    dataProblem: [],
    type: 'attach',
    fileAmount: 0,
  });

  const { time_limit } = useSelector(questionSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const { isDisabledUpload } = useCheckUpload();

  const ref = useRef(null);

  const dispatch = useAppDispatch();
  const handleOnSetVisible = (isVisible: boolean) => {
    setShowActionErrorModal({ subTitle: '', title: '', description: '', errorVisible: isVisible });
  };

  const formik = useFormik<Types.CreateEditQuestionFormik>({
    initialValues: {
      name: '',
      description: '',
      problems1: '',
      problems2: '',
      problems3: '',
      answer: '',
      time_limit:
        type === 'create' && time_limit
          ? defaultTimeLimit
            ? time_limit[defaultTimeLimit]?.option_id
            : time_limit[0]?.option_id
          : '',
      comment: '',
      score: 1,
      attach: [],
      comment_attach: [],
      problems1_attach: [],
      problems2_attach: [],
      problems3_attach: [],
      attachFileID: [],
      commentAttachFileID: [],
      problems1AttachFileID: [],
      problems2AttachFileID: [],
      problems3AttachFileID: [],
    },
    enableReinitialize: true,
    validationSchema: createEditQuestionSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      if (isEqual(values, questionDetail)) return;
      handleSubmit({
        dispatch,
        fileIdsToDelete,
        filesInServer,
        handleCancel,
        setFileIdsToDelete,
        setFilesInServer,
        setShowActionErrorModal,
        setUploading,
        type,
        userInfo,
        values,
        i_id: question_id,
        handleFetchData: async () => {
          dispatch(startLoading());
          await dispatch(
            getDataQuestionCurriculum({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: page ?? 1,
              per_page: 0,
              include_lookups: true,
            })
          );
          fetchData && (await fetchData());
          dispatch(stopLoading());
          setVisibleSuccess(true);
        },
      });
    },
  });

  const handleOnChangeVisibleModel = (visibleUploadFileModel: boolean) => {
    setUploading((prevState: UploadFileToServerModel) => ({
      ...prevState,
      show: visibleUploadFileModel,
    }));
  };

  const handleSubmitUploadFiles = (
    data: Array<UploadFileAntd<File> | undefined>,
    fieldType: Types.UploadFileFieldType,
    dataProblem: Array<{
      content: string;
      file: UploadFileAntd<File> | undefined;
    }>,
    dataName: Array<string>,
    index: number
  ) => {
    let fileChange = [];
    if (fieldType === 'problems') {
      fileChange.push(
        ...(formik.values[
          `problems${index + 1}AttachFileID` as keyof Types.CreateEditQuestionFormik
        ] as string[])
      );

      if (!isEmpty(fileChange)) {
        setFileIdsToDelete(fileChange.map((file) => file));
      }

      formik.setFieldValue(
        'problems1_attach',
        dataProblem.find((item) => item.content === 'problems1_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems1_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'problems2_attach',
        dataProblem.find((item) => item.content === 'problems2_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems2_attach')?.file]
          : []
      );
      formik.setFieldValue(
        'problems3_attach',
        dataProblem.find((item) => item.content === 'problems3_attach')?.file
          ? [dataProblem.find((item) => item.content === 'problems3_attach')?.file]
          : []
      );
    } else {
      fileChange = (
        formik.values[fieldType as keyof Types.CreateEditQuestionFormik] as unknown as string[]
      ).filter((file) => !(data as unknown as string[]).some((e) => e === file));

      if (!isEmpty(fileChange)) {
        setFileIdsToDelete(fileChange.map((file) => file));
      }
      formik.setFieldValue(fieldType, data);
    }
  };

  const handleCancel = () => {
    setOpenModalCreateEditQuestion({ visible: false, type });
    formik.resetForm();
  };

  const handleDeleteAllAttach = () => {
    const arrUidImage = formik.values.attachFileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllProblem = () => {
    const uidProblem1 = formik.values.problems1AttachFileID[0];
    const uidProblem2 = formik.values.problems2AttachFileID[0];
    const uidProblem3 = formik.values.problems3AttachFileID[0];

    const arrUidImage = [uidProblem1, uidProblem2, uidProblem3];

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };

  const handleDeleteAllComment = () => {
    const arrUidImage = formik.values.commentAttachFileID.map((item) => item);

    if (arrUidImage.length > 0) {
      setFileIdsToDelete((prevIds) => [...prevIds, ...arrUidImage]);
    }
  };
  // const handleUpdateTimeLimit = async () => {
  //   dispatch(startLoading());
  //   setIsSettingTime(true);
  //   handleUpdateTime && (await handleUpdateTime(indexTime));
  //   dispatch(stopLoading());
  //   setVisibleTooltip(false);
  // };

  const handleShowModalUploadFile = (fieldType: Types.UploadFileFieldType) => {
    const fileAmount = fieldType === 'attach' || fieldType === 'comment_attach' ? 3 : 1;
    const data =
      fieldType === 'problems'
        ? [
            formik.values.problems1_attach[0],
            formik.values.problems2_attach[0],
            formik.values.problems3_attach[0],
          ].filter((file) => file ?? false)
        : formik.values[fieldType];

    const dataName =
      fieldType === 'attach'
        ? formik.values.attachFileID
        : fieldType === 'comment_attach'
        ? formik.values.commentAttachFileID
        : fieldType === 'problems'
        ? [
            formik.values.problems1AttachFileID[0],
            formik.values.problems2AttachFileID[0],
            formik.values.problems3AttachFileID[0],
          ].filter((file) => file ?? false)
        : [];

    const dataProblem = [
      {
        content: 'problems1_attach',
        indexProblem: 0,
        file: formik.values.problems1_attach[0],
        fileID: formik.values.problems1AttachFileID[0],
      },
      {
        content: 'problems2_attach',
        indexProblem: 1,
        file: formik.values.problems2_attach[0],
        fileID: formik.values.problems2AttachFileID[0],
      },
      {
        content: 'problems3_attach',
        indexProblem: 2,
        file: formik.values.problems3_attach[0],
        fileID: formik.values.problems3AttachFileID[0],
      },
    ].filter((file) => file.file !== undefined);
    const minIndexItem = minBy(dataProblem, 'indexProblem')?.indexProblem || 0;

    setShowPopupUploadMedia({
      show: true,
      data: data,
      dataName: dataName,
      type: fieldType,
      fileAmount,
      dataProblem: dataProblem,
      fistIndex: minIndexItem,
    });
  };

  const dirtyEdit = useMemo(
    () => isEqual(formik.values, questionDetail),
    [formik.values, questionDetail]
  );

  useEffect(() => {
    if (!visible) return;

    handleGetQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      type,
      i_id: question_id,
      formik,
      setQuestionDetail,
    });

    handleGetFileQuestionDetail({
      dispatch,
      handleCancel,
      setFilesInServer,
      type,
      i_id: question_id,
      formik,
      setQuestionDetail,
    });

    return () => {
      formik.setValues({
        name: '',
        description: '',
        problems1: '',
        problems2: '',
        problems3: '',
        answer: '',
        time_limit: '',
        comment: '',
        score: Number(0),
        attach: [],
        comment_attach: [],
        problems1_attach: [],
        problems2_attach: [],
        problems3_attach: [],
        attachFileID: [],
        commentAttachFileID: [],
        problems1AttachFileID: [],
        problems2AttachFileID: [],
        problems3AttachFileID: [],
      });
      setFilesInServer([]);
      setFileIdsToDelete([]);
      setUploading({ show: false, files: [] });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, visible]);

  useEffect(() => {
    if (!userInfo || !visible) return;

    dispatch(
      getCompany({
        conditions: [
          {
            id: 'id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, visible]);

  useEffect(() => {
    if (type === 'create' && time_limit) {
      const timeLimit = defaultTimeLimit
        ? time_limit[defaultTimeLimit]?.option_id
        : time_limit[0]?.option_id;
      formik.setFieldValue('time_limit', timeLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTimeLimit, time_limit, type]);

  const imageAttach = useMemo(() => {
    const defaultImageCount = 3;
    const attachCount = formik.values.attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < attachCount) {
        const attach = formik.values.attach[i];
        images.push(
          <div className="image" key={i}>
            {attach?.type === FileType.MP4 || includes(attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : (attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  attach?.originFileObj
                    ? URL.createObjectURL(new Blob([attach?.originFileObj as Blob]))
                    : attach
                    ? (attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [formik.values.attach]);

  const imageComment = useMemo(() => {
    const defaultImageCount = 3;
    const commentImageCount = formik.values.comment_attach.length;

    const images = [];

    for (let i = 0; i < defaultImageCount; i++) {
      if (i < commentImageCount) {
        const comment_attach = formik.values.comment_attach[i];
        images.push(
          <div className="image" key={i}>
            {comment_attach?.type === FileType.MP4 ||
            includes(comment_attach as unknown as string, 'mp4') ? (
              <ReactPlayer
                width={'100%'}
                height={'100%'}
                url={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : (comment_attach as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                src={
                  comment_attach?.originFileObj
                    ? URL.createObjectURL(new Blob([comment_attach?.originFileObj as Blob]))
                    : comment_attach
                    ? (comment_attach as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={comment_attach?.name}
              />
            )}
          </div>
        );
      } else {
        images.push(
          <div className="image" key={i}>
            <img src={NoImage2} className="image-question" alt="No Image" />
          </div>
        );
      }
    }

    return images;
  }, [formik.values.comment_attach]);

  const RenderProblem = (formikValues: Types.CreateEditQuestionFormik, problemKey: number) => {
    const key = `problems${problemKey}_attach` as keyof Types.CreateEditQuestionFormik;

    const problemArray = formikValues[key] as UploadFile<File>[] | undefined;
    const problem = problemArray ? problemArray[0] : undefined;

    const labels = ['A', 'B', 'C'];
    const label = labels[problemKey - 1];

    const isVideo = problem?.type === FileType.MP4 || includes(problem as unknown as string, 'mp4');

    return useMemo(
      () => (
        <>
          <div className="image" key={problemKey}>
            {isVideo ? (
              <ReactPlayer
                crossOrigin="anonymous"
                width={'100%'}
                height={'100%'}
                url={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : (problem as unknown as string)
                }
                controls
                config={{
                  file: {
                    attributes: {
                      disablePictureInPicture: true,
                      controlsList: 'nodownload noplaybackrate',
                    },
                  },
                }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                src={
                  problem?.originFileObj
                    ? URL.createObjectURL(new Blob([problem.originFileObj as Blob]))
                    : problem
                    ? (problem as unknown as string)
                    : NoImage2
                }
                className="image-question"
                alt={problem?.name}
              />
            )}
          </div>
          <p className="label">{`選択肢${label}`}</p>
        </>
      ),
      [
        formik.values.problems1_attach,
        formik.values.problems2_attach,
        formik.values.problems3_attach,
        isVideo,
        problemKey,
      ]
    );
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          width: '100%',
          position: 'absolute',
          right: '9999px',
        }}
      >
        <QuestionPDF
          data={formik.values}
          imageAttach={imageAttach}
          imageComment={imageComment}
          renderProblem={RenderProblem}
          type={type}
          timeLimit={time_limit}
        />
      </div>
    );
  }, [formik.values, imageAttach, imageComment, RenderProblem, type, time_limit]);

  const handleExportPDF = (value: string) => {
    if (!ref.current || value !== 'pdf') return;

    exportPDF(ref, '設問マスタ', 'p', true);
    setVisibleConfirm(false);
  };

  const CustomHeader = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            margin: 0,
          }}
        >
          設問編集
        </p>
        <Button
          style={{
            width: '160px',
            height: '40px',
            borderRadius: '5px',
            background: '#f6ac00',
            color: '#ffffff',
            right: 13,
            position: 'absolute',
            fontWeight: '700',
          }}
          onClick={() => setVisibleConfirm(true)}
        >
          エクスポート
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={type === 'create' ? '設問新規作成' : <CustomHeader />}
      width={860}
      open={visible}
      okButton={{
        text: type === 'create' ? '登録' : '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty || dirtyEdit,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setOpenModalCreateEditQuestion({ visible: false, type }),
      }}
      onCancel={() => setOpenModalCreateEditQuestion({ visible: false, type })}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        {component}
        <p className="sub-title">
          {type === 'create'
            ? '設問の新規作成画面です。入力後に登録ボタンをクリックしてください。'
            : '設問の内容確認と編集用の画面です。編集した場合は更新ボタンをクリックしてください。'}
        </p>
        <SpinLoading size="large" loading={loading}>
          <FormikProvider value={formik}>
            <Form colon={false} layout="vertical" className="form">
              <p className="label-note">
                <span className="number">1</span>設問内容
              </p>
              <Form.Item
                name="name"
                label={
                  <span className="text-label">
                    設問名
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <Input
                  name="name"
                  className="input"
                  type="text"
                  showCount
                  maxLength={30}
                  placeholder="全角：最大30文字"
                />
              </Form.Item>
              <Form.Item
                name="description"
                label={
                  <span className="text-label">
                    設問内容
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="description"
                  className="input-textarea"
                  showCount
                  maxLength={480}
                  placeholder="全角：最大480文字"
                />
              </Form.Item>
              <Form.Item name="attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">設問画像・動画 </p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('attach')}
                    disabled={isDisabledUpload}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageAttach}
                </div>
                <div
                  className={`text-clear${formik.values.attach ? '' : '__disabled'}`}
                  onClick={() => {
                    formik.setFieldValue('attach', []);
                    handleDeleteAllAttach();
                  }}
                >
                  クリア
                </div>
              </div>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">2</span>解答選択肢
              </p>
              <Form.Item
                name="problems1"
                label={
                  <span className="text-label">
                    選択肢A
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems1"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems2"
                label={
                  <span className="text-label">
                    選択肢B
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems2"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item
                name="problems3"
                label={
                  <span className="text-label">
                    選択肢C
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <TextArea
                  name="problems3"
                  className="input-textarea-small"
                  showCount
                  maxLength={60}
                  placeholder={'全角：最大60文字\n選択肢の内容または画像タイトルを入力してください'}
                />
              </Form.Item>
              <Form.Item name="problems" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">解答選択肢画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('problems')}
                    disabled={isDisabledUpload}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {[1, 2, 3].map((problemKey) => (
                    <div key={problemKey}>{RenderProblem(formik.values, problemKey)}</div>
                  ))}
                </div>
                <div
                  className={`text-clear${
                    formik.values.problems1_attach ||
                    formik.values.problems2_attach ||
                    formik.values.problems3_attach
                      ? ''
                      : '__disabled'
                  }`}
                  onClick={() => {
                    formik.setFieldValue('problems1_attach', []);
                    formik.setFieldValue('problems2_attach', []);
                    formik.setFieldValue('problems3_attach', []);
                    handleDeleteAllProblem();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="answer"
                label={
                  <span className="text-label">
                    解答
                    <span className="require">*</span>
                  </span>
                }
                className="form-input form-input-radio"
              >
                <Radio.Group name="answer" className="radio_group">
                  <Radio name="answer" value="A" className="radio-text">
                    選択肢A
                  </Radio>
                  <Radio name="answer" value="B" className="radio-text">
                    選択肢B
                  </Radio>
                  <Radio name="answer" value="C" className="radio-text">
                    選択肢C
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <div className="mr-30" />
              <p className="label-note">
                <span className="number">3</span>解説・その他設定
              </p>
              <Form.Item
                name="comment"
                label={
                  <div className="wrap-label-form-input">
                    <span className="text-label">
                      解説
                      <span className="require">*</span>
                    </span>
                    <span className="text-enlarge" onClick={() => setShowPopupComment(true)}>
                      <SelectOutlined className="icon" />
                      入力欄を拡大
                    </span>
                  </div>
                }
                className="form-input"
              >
                <TextArea
                  name="comment"
                  className="input-textarea"
                  showCount
                  maxLength={1050}
                  placeholder="全角：最大1050文字"
                />
              </Form.Item>
              <Form.Item name="comment_attach" className="form-input">
                <div className="wrap-flex">
                  <p className="text-label">解説画像・動画</p>
                  <button
                    className="btn_primary"
                    type="button"
                    onClick={() => handleShowModalUploadFile('comment_attach')}
                    disabled={isDisabledUpload}
                  >
                    画像・動画を選ぶ
                  </button>
                  <p className="text-note">※画像・動画は設問の下に挿入されます</p>
                </div>
              </Form.Item>
              <div className="wrap-show-image">
                <div className="flex-image">
                  <p className="text-label">設定状況：</p>
                  {imageComment}
                </div>
                <div
                  className={`text-clear ${formik.values.comment_attach ? '' : '__disabled'}`}
                  onClick={() => {
                    formik.setFieldValue('comment_attach', []);
                    handleDeleteAllComment();
                  }}
                >
                  クリア
                </div>
              </div>
              <Form.Item
                name="time_limit"
                label={
                  <span className="text-label">
                    制限時間
                    <span className="require">*</span>
                  </span>
                }
                className="form-input"
              >
                <SelectField name="time_limit" className="time_limit">
                  {time_limit.map((item) => (
                    <Option key={item.sort_id} value={item.option_id}>
                      {item.value}
                    </Option>
                  ))}
                </SelectField>
                {/*<Tooltip*/}
                {/*  trigger={['click']}*/}
                {/*  onVisibleChange={(visb) => setVisibleTooltip(visb)}*/}
                {/*  overlayClassName="tooltip-setup-time-limit"*/}
                {/*  placement="topLeft"*/}
                {/*  title={*/}
                {/*    <>*/}
                {/*      {isSettingTime ? (*/}
                {/*        <div className="text-content">*/}
                {/*          <CheckCircleOutlined className="icon" />*/}
                {/*          設定しました*/}
                {/*        </div>*/}
                {/*      ) : (*/}
                {/*        <>*/}
                {/*          <div className="text-content">*/}
                {/*            現在設定されている制限時間を設問新規作 <br />*/}
                {/*            成時の制限時間初期値として設定します。*/}
                {/*          </div>*/}
                {/*          <div className="box-center">*/}
                {/*            <Button className="btn-set-time" onClick={handleUpdateTimeLimit}>*/}
                {/*              設定する*/}
                {/*            </Button>*/}
                {/*          </div>*/}
                {/*        </>*/}
                {/*      )}*/}
                {/*    </>*/}
                {/*  }*/}
                {/*>*/}
                {/*  <span onClick={() => setVisibleTooltip(true)}>*/}
                {/*    <ClockCircleOutlined className="watch-icon" />*/}
                {/*    <span className="time-setting">制限時間初期値設定</span>*/}
                {/*  </span>*/}
                {/*</Tooltip>*/}
              </Form.Item>
              <Row>
                <Col span={24}>
                  <div className="form-input-radio">
                    <span className="text-label">
                      スコア設定（設問難易度の設定）
                      <span className="require">*</span>
                      <Tooltip
                        trigger={['click']}
                        overlayClassName="question-tooltip"
                        title={
                          <>
                            <h6
                              style={{
                                fontFamily: 'Noto Sans Javanese',
                                fontWeight: '400',
                                fontSize: 16,
                                color: '#2A2A2A',
                              }}
                            >
                              スコア設定（設問難易度の設定）について
                            </h6>
                            <p>
                              スコアは設問の難易度を設定する項目です。
                              <br />
                              設定の際は「１・２・３」の３段階からスコアを選択します。
                              <br />
                              <br />
                              スコアは設問の難易度を表すと共に、獲得スコアとして得点も表します。
                              <br />
                              スキルチェック実施の際に、正解した設問に設定されているスコアを獲得できます。
                              <br />
                              <br />
                              設定したスコアの情報や獲得したスコアの結果はレポートから確認することができます。
                              <br />
                              <br />
                              獲得したスコアから、スキルチェックの結果が難易度の高い設問を多く正解できているか、難易度の低い設問を多く
                              <br />
                              間違えているので、基礎ができていないか、ケアレスミスで間違えている等の分析ができる仕組みです。
                              <br />
                              <br />
                              正解した数や割合だけでなく、質をはかる指標です。
                              <br />
                              <br />
                              スキルチェック実施後は、ぜひスコアを活用してみてください。
                            </p>
                          </>
                        }
                      >
                        <QuestionCircleOutlined className="question-mark-icon" />
                      </Tooltip>
                    </span>
                    <Radio.Group name="score" className="radio_group">
                      <Radio name="score" value={1} className="radio-text">
                        1
                      </Radio>
                      <Radio name="score" value={2} className="radio-text">
                        2
                      </Radio>
                      <Radio name="score" value={3} className="radio-text">
                        3
                      </Radio>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </Form>
          </FormikProvider>
        </SpinLoading>
        <UploadImageVideoQuestion
          visible={showPopupUploadMedia}
          setVisible={setShowPopupUploadMedia}
          onSubmit={handleSubmitUploadFiles}
        />
        <ActionErrorModal
          visible={showActionErrorModal.errorVisible}
          description={showActionErrorModal.description}
          subTitle={showActionErrorModal.subTitle}
          title={showActionErrorModal.title}
          setVisible={handleOnSetVisible}
        />
        <PopupComment
          visible={showPopupComment}
          setVisible={setShowPopupComment}
          commentValues={formik.values.comment}
          onSubmit={(values) => {
            setShowPopupComment(false);
            formik.setFieldValue('comment', values);
          }}
        />
        <UploadFileToServer
          uploadingFiles={uploading.files}
          visible={uploading.show}
          setVisible={(isVisible: boolean) => handleOnChangeVisibleModel(isVisible)}
          title={'アップロード中'}
        />
        <PopupConfirmExportFile
          visible={visibleConfirm}
          setVisible={setVisibleConfirm}
          onSubmit={handleExportPDF}
        />
      </SectionStyled>
    </Modal>
  );
};

export default CreateEditQuestionMaster;
