import styled from 'styled-components';

interface Props {
  coordinate: {
    x?: number;
    y?: number;
  };
  permission: number;
}

export const ContextMenuWrapper = styled.div<Props>`
  position: absolute;
  left: ${(props) => props.coordinate.x}px;
  top: ${(props) => props.coordinate.y}px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  z-index: 1050;
  &::before {
    content: ' ';
    position: absolute;
    top: -4px;
    right: 0;
    bottom: -4px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
  }
  .menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    .ant-menu-item {
      clear: both;
      margin: 0;
      height: unset;
      padding: 5px 12px;
      color: ${(props) => (props.permission === 1 ? '#cccccc' : 'rgba(0, 0, 0, 0.85)')};
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      transition: all 0.3s;
    }
    .ant-menu-item-active {
      background-color: #f5f5f5;
    }
    .publish {
      padding: 2px 5px;
      .wrap-publish {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 0;
        span {
          margin-left: 10px;
          font-weight: 400;
          font-size: 12px;
        }
        .wrap-icon {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .caretIcon {
            color: #bfbfbf;
            margin: 0;
          }
          .icon-small {
            font-size: 13px;
            width: 13px;
          }
          .icon {
            font-size: 16px;
            width: 16px;
          }
        }
      }
      .blGreen {
        border-left: 2px solid #b57fcf;
        .publish {
          cursor: pointer;
        }
      }
      .blViolet {
        border-left: 2px solid #71c131;
        .publish {
          cursor: pointer;
        }
      }
    }
  }
`;

export const WrapAlertCopied = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  .icon {
    color: #424242;
    font-size: 28px;
  }
  .label {
    font-size: 18px;
    color: #424242;
  }
`;
