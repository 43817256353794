import styled from 'styled-components';

export default styled.section`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  @media (max-width: 1440px) {
    width: 1440px;
  }
  background-color: #f9f8f8;
  .background {
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100vw;
  }

  .body-status {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title-complete {
      display: flex;
      flex-direction: column;
      z-index: 1;
      width: 40%;
      margin-bottom: 20px;
      position: relative;
      ::before {
        content: '';
        width: 2px;
        height: 80px;
        background: #08a3a5;
        position: absolute;
        transform: rotate(39.34deg);
        top: 0;
        right: 20%;
      }
      ::after {
        content: '';
        width: 2px;
        height: 80px;
        background: #08a3a5;
        position: absolute;
        transform: rotate(-39.34deg);
        top: 0;
        left: 20%;
      }
      .title {
        .complete {
          font-weight: 700;
          font-size: 34px;
          margin: 0;
          text-align: center;
          color: #08a3a5;
        }
      }
      .text {
        font-size: 18px;
        text-align: center;
        color: #08a3a5;
      }
    }
    .item {
      width: 50%;
      display: flex;
      flex-direction: column;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      z-index: 1;
      &::before {
        content: '';
        width: 100%;
        height: 4px;
        margin: 3px 0;
        background-color: #08a3a5;
      }
      .title {
        font-size: 16px;
        text-align: center;
        margin-top: 40px;
        color: #777777;
      }
      .content {
        font-size: 24px;
        text-align: center;
        color: #2a2a2a;
        margin: 0;
      }
      .text-count {
        width: 60%;
        margin: 30px auto;
        padding: 10px;
        border: 1px solid rgba(8, 163, 165, 0.4);
        font-weight: 700;
        font-size: 22px;
        text-align: center;
        color: #08a3a5;
      }
      .btn {
        display: flex;
        justify-content: center;
        .btn-status {
          width: 220px;
          height: 52px;
          border: none;
          background: #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-weight: 700;
          font-size: 18px;
          text-align: center;
          color: #ffffff;
          margin-bottom: 40px;
          cursor: pointer;
        }
      }
    }
  }
`;
