import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { trainingCurriculumSelector } from '../selectors';
import TrainingCompleteStyled from './styles';
import { useAppDispatch } from 'hooks';
import { Background } from 'assets';

interface Props {
  correctNum: number;
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setCorrectNum: React.Dispatch<React.SetStateAction<number>>;
}

const TrainingComplete: React.FC<Props> = ({ correctNum, setChangeTab, setCorrectNum }) => {
  const { dataImplementation } = useSelector(trainingCurriculumSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TrainingCompleteStyled>
      <img src={Background} className="background" alt="logo-background" />
      <div className="body-status">
        <div className="title-complete">
          <div className="title">
            <p className="complete">COMPLETE!</p>
          </div>
          <p className="text">全ての設問に解答しました！</p>
        </div>
        <div className="item">
          <p className="title">カリキュラム</p>
          <p className="content">{dataImplementation[0]?.curriculum_name}</p>
          <div className="text-count">
            {correctNum} 問正解 / {dataImplementation.length} 問中
          </div>
          <div className="btn">
            <button
              onClick={() => {
                setChangeTab(0);
                setCorrectNum(0);
              }}
              className="btn-status"
            >
              選択画面に戻る
            </button>
          </div>
        </div>
      </div>
    </TrainingCompleteStyled>
  );
};

export default TrainingComplete;
