import styled from 'styled-components';
import Modal from '..';

export const FreeTrialStyled = styled(Modal)`
  width: calc(100% - 40px) !important;
  .ant-modal-content {
    background: rgba(249, 248, 248, 1);
  }
  .body {
    height: calc(100vh - 75px) !important;
    text-align: center;
    display: grid;
    justify-content: center;
    .image-logo {
      padding-top: 170px;
      display: grid;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-weight: 700;
      line-height: 20px;
      .sub-title {
        margin-bottom: 0;
      }
    }
    .sub-content {
      justify-content: center;
    }
    .sub-footer {
      color: rgba(119, 119, 119, 1);
      font-size: 16px;
      font-weight: 400;
    }
    .btn-submit {
      margin-top: 48px;
      text-align: center;
      .btn {
        font-weight: 700;
        border-radius: 5px;
        font-size: 15px;
        height: 48px;
        width: 280px;
        color: white;
        background: rgba(246, 172, 0, 1);
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
`;
