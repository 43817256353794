import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { ITEM_COMPARISION_ANALYSIS } from 'constant/select.constants';
import { getReportSkillCheckComparison } from '../thunk';
import { authSelector } from 'containers/Auth/selectors';
import { UserType } from 'types/config';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Chart from './Chart';
import Table from './Table';

interface Props {
  segmented: number;
  itemComparision: (typeof ITEM_COMPARISION_ANALYSIS)[number]['value'];
  startPeriod?: string;
  exportPdf?: boolean;
  endPeriod?: string;
  groupCode?: string;
  userType: UserType;
  loginID?: string;
}

const Comparison: React.FC<Props> = ({
  itemComparision,
  startPeriod,
  segmented,
  endPeriod,
  groupCode,
  exportPdf,
  userType,
  loginID,
}) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);

  useEffect(() => {
    if (!userInfo || !loginID || !groupCode || !startPeriod || !endPeriod) return;
    (async () => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
        {
          id: 'user_type',
          search_value: [userType],
        },
        {
          id: 'implementation_date',
          search_value: [dayjs(startPeriod).startOf('day').toISOString(), null],
        },
        {
          id: 'implementation_date',
          search_value: [null, dayjs(endPeriod).endOf('day').toISOString()],
        },
      ];

      if (loginID && loginID !== 'ALL') {
        conditions.push({
          id: 'login_id',
          search_value: [loginID],
          exact_match: true,
        });
      }
      if (groupCode && groupCode !== 'ALL') {
        conditions.push({
          id: 'grouping_code',
          search_value: [groupCode],
        });
      }

      await dispatch(
        getReportSkillCheckComparison({
          conditions,
          page: 1,
          per_page: 0,
          sort_fields: [
            {
              id: 'implementation_date',
              order: 'asc',
            },
          ],
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, loginID, groupCode, userType, startPeriod, endPeriod]);

  return segmented === 0 ? (
    <Table itemComparision={itemComparision} exportPdf={exportPdf} />
  ) : (
    <Chart itemComparision={itemComparision} />
  );
};

export default Comparison;
