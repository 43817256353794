import styled from 'styled-components';

interface Props {
  publish?: boolean;
}

export default styled.section<Props>`
  .sub-title {
    text-align: center;
    font-size: 15px;
    color: #424242;
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #777777;
  }
  .icon {
    color: #c4c4c4;
    font-size: 20px;
    cursor: ${(props) => (props.publish ? 'not-allowed' : 'pointer')};
  }
  .row {
    background-color: #ffffff;
    border: 1px solid #cccccc;
    .row-field {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ebebeb;
      font-weight: 700;
      font-size: 13px;
      color: #2a2a2a;
    }
    .ant-row {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding: 16px;
    }
    .text-value {
      text-align: left;
    }
    .text-icon {
      text-align: right;
    }
  }
  .row + .row {
    border-top: none;
  }
`;
