import React from 'react';
import { FormikProps, FormikProvider } from 'formik';
import { CheckOutlined } from '@ant-design/icons';
import { DatePicker, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Form } from 'formik-antd';
import { uniqBy } from 'lodash';

import { IconEmployee, IconInterview, IconLocked, IconPublish } from 'assets';
import { PUBLISH, USER_TYPE } from 'constant/select.constants';
import { Button, TabExportStyled } from '../styles';
import { SelectField } from 'components';
import * as Types from 'types';

const { Option } = Select;

interface Props {
  formik: FormikProps<Types.SkillCheckMasterSearchFormik>;
  skillCheckUsers: Types.SkillCheckUser.ResponseType[];
  filter_option: Types.SkillCheckTreeViewType[];
  tabActive: number;
}

const PDFUserDocument: React.FC<Props> = ({
  skillCheckUsers,
  filter_option,
  tabActive,
  formik,
}) => {
  return (
    <TabExportStyled>
      <p className="header">スキルチェックマスタ 設定リスト</p>
      <div className="tab-button">
        <p className="description">
          スキルチェックに設定されている基本情報・設問・ユーザーをリスト形式で確認できます。
        </p>
        <div className="wrap-button">
          <div className="left-side">
            {['基本情報リスト', '設問リスト', 'ユーザーリスト'].map((item, index) => (
              <Button key={index} tabActive={tabActive} index={index}>
                {tabActive === index ? <CheckOutlined className="size-icon" /> : null}
                {item}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <FormikProvider value={formik}>
        <Form layout="vertical">
          <div className="form-search">
            <Form.Item
              name="skill_check_code"
              className="item"
              label={<span className="text-label">スキルチェック名</span>}
            >
              <SelectField
                name="skill_check_code"
                showSearch
                allowClear
                placeholder="スキルチェック名で検索"
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'skill_check_code').map(
                  ({ skill_check_code, skill_check_name }, index) => (
                    <Option key={index} value={skill_check_code}>
                      {skill_check_name}
                    </Option>
                  )
                )}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="grouping_code2"
              className="item"
              label={<span className="text-label">分析グループ</span>}
            >
              <SelectField
                name="grouping_code2"
                showSearch
                allowClear
                placeholder="分析グループで検索"
                filterOption={(input, option) =>
                  JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {uniqBy(filter_option, 'grouping_code2').map(
                  ({ grouping_code2, grouping_code }, index) => (
                    <Option key={index} value={grouping_code2}>
                      {grouping_code}
                    </Option>
                  )
                )}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="start_period"
              className="item"
              label={<span className="text-label">実施期間</span>}
            >
              <DatePicker
                allowClear
                showTime
                name="start_period"
                placeholder="開始日で検索"
                format="YYYY/MM/DD HH:mm"
              />
            </Form.Item>
            <Form.Item
              name="publish"
              className="item half-width"
              label={<span className="text-label">公開ステータス</span>}
            >
              <SelectField name="publish" defaultValue="none">
                <Option value="none">指定なし</Option>
                {PUBLISH.map(({ value, label }, index) => (
                  <Option key={index} value={value}>
                    {Number(value) === 1 ? (
                      <>
                        <img src={IconPublish} className="icon" alt="publish-icon" />
                        <span>{label}</span>
                      </>
                    ) : (
                      <>
                        <img src={IconLocked} className="icon" alt="edit-icon" />
                        <span>{label}</span>
                      </>
                    )}
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
            <Form.Item
              name="user_type"
              className="item half-width"
              label={<span className="text-label">ユーザー種別</span>}
            >
              <SelectField name="user_type" defaultValue="none">
                <Option value="none">指定なし</Option>
                {USER_TYPE.map(({ value, label }, index) => (
                  <Option key={index} value={value}>
                    {value === 'member' ? (
                      <img src={IconEmployee} className="icon" alt="employee-icon" />
                    ) : (
                      <img src={IconInterview} className="icon" alt="interview-icon" />
                    )}
                    <span>{label}</span>
                  </Option>
                ))}
              </SelectField>
            </Form.Item>
          </div>
        </Form>
      </FormikProvider>
      <Table
        rowKey="index"
        className="table"
        dataSource={skillCheckUsers.map((item, index) => ({ ...item, index }))}
        columns={columns}
        pagination={false}
      />
    </TabExportStyled>
  );
};

const columns: ColumnsType<Types.SkillCheckUser.ResponseType> = [
  {
    title: 'コード',
    key: 'skill_check_code',
    dataIndex: 'skill_check_code',
    align: 'center',
    width: '5%',
    render: (text) => <p className="text-content-code">{text}</p>,
  },
  {
    title: 'スキルチェック名',
    key: 'skill_check_name',
    dataIndex: 'skill_check_name',
    width: '25%',
    render: (text) => <p className="text-content">{text}</p>,
  },
  {
    title: 'ユーザー種別',
    key: 'user_type',
    dataIndex: 'user_type',
    align: 'center',
    width: '10%',
    render: (text) =>
      text === 'member' ? (
        <>
          <img src={IconEmployee} className="icon" alt="employee-icon" />
          <span className="spanText">社内</span>
        </>
      ) : (
        <>
          <img src={IconInterview} className="icon" alt="interview-icon" />
          <span className="spanText">面接</span>
        </>
      ),
  },
  {
    title: '分析グループ',
    key: 'grouping_code',
    dataIndex: 'grouping_code',
    width: '15%',
    ellipsis: true,
  },
  {
    title: 'メールアドレス',
    key: 'email',
    dataIndex: 'email',
    width: '20%',
    ellipsis: true,
  },
  {
    title: 'ユーザー名',
    key: 'user_name',
    dataIndex: 'user_name',
    width: '25%',
    ellipsis: true,
  },
];

export default PDFUserDocument;
