import styled from 'styled-components';

export const WrapAlert = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 15px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  color: rgba(0, 0, 0, 0.85);
  background: lightgrey;
  opacity: 0.8;
`;
