import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  body: {
    marginTop: 20,
  },
  container: {
    margin: 20,
  },
  header: {
    padding: 20,
    borderBottomWidth: 1,
    borderBottomColor: '#dddddd',
  },
  title: {
    fontSize: 18,
    fontFamily: 'HanSansk',
  },
  des: {
    fontSize: 12,
    fontFamily: 'HanSansk',
  },
  headerTable: {
    backgroundColor: '#ebebeb',
    width: '100%',
  },
  table: {
    marginTop: 20,
    // borderWidth: 1,
    // borderColor: '#cccccc'
  },
  row: {
    flexDirection: 'row',
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#cccccc',
    borderTopWidth: 0,
  },
  column: {
    paddingHorizontal: 30,
    width: '30%',
  },
  lastColumn: {
    width: 400,
    overflow: 'hidden',
  },
  textTable: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'HanSansk',
    textOverflow: 'ellipsis',
  },
  line: {
    width: 40,
    backgroundColor: '#ddd',
    height: 1,
    marginTop: 30,
    marginBottom: 10,
  },
  length: {
    fontSize: 11,
    fontFamily: 'HanSansk',
  },
  bold: {
    fontWeight: 700,
  },
});
