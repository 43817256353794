import React, { useState } from 'react';

import { CameraFilled, UserOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { UploadFile } from 'antd';

import { createFileAttach, deleteFileAttachByConditions, updateUserInfo } from '../../thunk';
import { deletedFileInMinIO, uploadFileToMinIO } from 'services/minioService';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { updateUserInfoReducer } from 'containers/Auth/slice';
import { authSelector } from 'containers/Auth/selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { Input, Modal, TextArea } from 'components';
import { getUserInfo } from 'containers/Auth/thunk';
import UploadImage from '../UploadImage';
import { useAppDispatch } from 'hooks';
import EditStyles from './styles';
import * as Types from 'types';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditModal: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [visibleUploadModal, setVisibleUploadModal] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);
  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();

  const formik = useFormik<Types.EditModalFormik>({
    initialValues: {
      birthplace: userInfo?.birthplace,
      hobby: userInfo?.hobby,
      work_in_charge: userInfo?.work_in_charge,
      comment: userInfo?.comment,
      avatar: userInfo?.avatar,
    },
    enableReinitialize: true,
    // validationSchema: createProfileSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!userInfo) return;
      let fileID = '';
      dispatch(startLoading());
      if (values.avatar) {
        if (values.avatar.uid !== userInfo.icon_fileID) {
          if (userInfo.icon_fileID) {
            deletedFileInMinIO(userInfo?.icon_fileID);
            dispatch(
              deleteFileAttachByConditions({
                conditions: [
                  {
                    id: 'fileID',
                    search_value: [userInfo?.icon_fileID],
                  },
                ],
                use_display_id: true,
              })
            );
          }
          const uploadToMinIo = await uploadFileToMinIO(values.avatar);
          await dispatch(
            createFileAttach({
              item: {
                fileID: uploadToMinIo,
                filename: values.avatar?.name,
                file_location: '6',
                file_extension: values.avatar?.type,
                file_size: `${values.avatar?.size}`,
                company_id: userInfo?.company_id,
                createdat: new Date(),
                createdby: userInfo?.login_id,
              },
            })
          );
          fileID = uploadToMinIo;
        }
      }
      // let new_avatar_id = undefined;

      // if (values.avatar) {
      //   const formData = new FormData();
      //   formData.append('filename', values.avatar.name);
      //   formData.append('file', new Blob([values.avatar as unknown as Blob]));

      //   const resultActionsUploadFileToS3 = await dispatch(updateFileToS3({ formData }));

      //   if (updateFileToS3.fulfilled.match(resultActionsUploadFileToS3)) {
      //     const resultActionCreateImages = await dispatch(
      //       createImage({
      //         item: {
      //           fileID: resultActionsUploadFileToS3.payload.file_id,
      //           filename: values.avatar?.name,
      //         },
      //       })
      //     );
      //     if (createImage.fulfilled.match(resultActionCreateImages)) {
      //       await dispatch(
      //         executeAction({
      //           itemId: resultActionCreateImages.payload.item_id,
      //           data: {
      //             changes: [
      //               {
      //                 id: 'file',
      //                 value: [resultActionsUploadFileToS3.payload.file_id],
      //               },
      //             ],
      //             use_display_id: true,
      //             is_force_update: true,
      //           },
      //         })
      //       );
      //     }
      //     new_avatar_id = resultActionsUploadFileToS3.payload.file_id;
      //   }
      // }

      const resultAction = await dispatch(
        updateUserInfo({
          id: userInfo.i_id,
          data: {
            item: {
              ...values,
              avatar: undefined,
              ...(fileID ? { icon_fileID: fileID } : {}),
            },
            is_force_update: true,
          },
        })
      );
      if (updateUserInfo.fulfilled.match(resultAction)) {
        dispatch(
          updateUserInfoReducer({
            ...userInfo,
            ...values,
            avatar: values.avatar
              ? ({
                  uid: values.avatar.uid,
                  name: values.avatar.name,
                  originFileObj: values.avatar,
                } as UploadFile<File>)
              : userInfo.avatar,
          })
        );
        dispatch(
          getUserInfo({
            conditions: [
              {
                id: 'login_id',
                search_value: [userInfo?.login_id],
                exact_match: true,
              },
            ],
            include_lookups: true,
            include_links: true,
            page: 1,
            per_page: 1,
          })
        );
      }
      await dispatch(stopLoading());
      await handleToggleModal();
      onSubmit && onSubmit();
    },
  });

  // useEffect(() => {
  //   if (!userInfo?.company_id) return;
  //   (async () => {
  //     dispatch(startLoading());

  //     await dispatch(
  //       getUserInfoDetail({
  //         conditions: [
  //           {
  //             id: 'company_id',
  //             search_value: [userInfo.company_id],
  //           },
  //           {
  //             id: 'i_id',
  //             search_value: [id],
  //           },
  //         ],
  //         page: 1,
  //         per_page: 10,
  //         use_display_id: true,
  //         include_lookups: true,
  //       })
  //     );
  //     dispatch(stopLoading());
  //   })();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [id]);

  const handleToggleModal = async () => {
    formik.resetForm();
    await setVisible(false);
    // deletedFileInMinIO('file');
  };

  return (
    <Modal
      width={860}
      title="プロフィール設定"
      open={visible}
      onCancel={handleToggleModal}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: '40px 40px 20px' }}
      footer={null}
    >
      <>
        <EditStyles>
          <div className="container">
            <div className="title">
              <p>
                基本情報の入力とプロフィール画像の設定を行い、登録ボタンをクリックしてください。
              </p>
            </div>
            <FormikProvider value={formik}>
              <Form layout="vertical" autoComplete="off">
                <div className="wrap-basic-information">
                  <div className="body">
                    <div className="wrap-user">
                      <div className="avatar-user">
                        {formik.values.avatar ? (
                          <img
                            src={URL.createObjectURL(
                              new Blob([
                                (formik.values.avatar.originFileObj ??
                                  formik.values.avatar) as Blob,
                              ])
                            )}
                            alt="avatar"
                            className="avatar"
                          />
                        ) : (
                          <>
                            <UserOutlined className="icon-user" />
                            <span className="text-content">NO PHOTO</span>
                          </>
                        )}
                        <div
                          className={`icon-camera ${isDisabledUpload ? 'disabled' : ''}`}
                          onClick={() => setVisibleUploadModal(true)}
                        >
                          <CameraFilled />
                        </div>
                      </div>
                      <span className="text-help">
                        カメラマークをクリックして
                        <br />
                        プロフィール画像を設定してください。
                      </span>
                    </div>
                    <div className="form-left">
                      <Form.Item
                        name="birthplace"
                        label={<span className="text-label">出身地</span>}
                        className="form-input"
                      >
                        <Input name="birthplace" className="text-input" maxLength={100} showCount />
                      </Form.Item>
                      <Form.Item
                        name="hobby"
                        label={<span className="text-label">趣味</span>}
                        className="form-input"
                      >
                        <Input name="hobby" className="text-input" maxLength={100} showCount />
                      </Form.Item>
                      <Form.Item
                        name="work_in_charge"
                        label={<span className="text-label">担当業務</span>}
                        className="form-input"
                      >
                        <Input
                          name="work_in_charge"
                          className="text-input"
                          maxLength={100}
                          showCount
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="comment">
                    <span className="label-comment">コメント</span>
                    <TextArea
                      name="comment"
                      className="input-textarea-group"
                      showCount
                      rows={4}
                      maxLength={480}
                      placeholder="最大480文字"
                    />
                  </div>
                </div>
                <div className="group-button">
                  <SubmitButton className="btn-submit">登録</SubmitButton>
                  <button type="button" className="go-back" onClick={handleToggleModal}>
                    閉じる
                  </button>
                </div>
              </Form>
            </FormikProvider>
          </div>
          <UploadImage
            visible={visibleUploadModal}
            setVisible={setVisibleUploadModal}
            onSubmit={(value) => {
              formik.setFieldValue('avatar', value);
            }}
          />
        </EditStyles>
      </>
    </Modal>
  );
};

export default EditModal;
