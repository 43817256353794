import React, { useState } from 'react';

import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckUserAnalysisReportSelector } from '../../selectors';
import Wrapper from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  isUserTransTable: boolean;
  startPeriod?: string;
  endPeriod?: string;
  exportPdf?: boolean;
}

const UserAnalysisReport: React.FC<Props> = ({
  isUserTransTable,
  startPeriod,
  endPeriod,
  exportPdf,
}) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const { reportSkillCheckUserTrans, totalReportSkillCheckUserTrans } = useSelector(
    skillCheckUserAnalysisReportSelector
  );

  const columns: ColumnsType<Types.ReportSkillCheckUserTrans.UserAnalysisReportTable> = [
    {
      title: <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>No.</span>,
      dataIndex: 'index',
      align: 'center',
      width: '3%',
      render: (_text, _record, index) => (
        <span style={{ fontWeight: 400, fontSize: 11, color: '#2A2A2A' }}>
          {!index ? '-' : exportPdf ? index + 1 : index + 1 + (page - 1) * 10}
        </span>
      ),
    },
    {
      title: 'ユーザー',
      dataIndex: 'user_name',
      key: 'user_name',
      className: 'br-1',
      width: '13%',
      ellipsis: true,
      render: (text, _record, index) => (!index ? 'ユーザー平均' : text),
    },
    {
      title: `実施期間①：${startPeriod ? dayjs(startPeriod).format('YYYY年MM月DD日') : ''}`,
      className: 'implementation-period',
      children: [
        {
          title: '正解率',
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType, index) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.start.accuracy_rate - b.start.accuracy_rate,
        },
        {
          title: '不正解率',
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.start.wrong_rate - b.start.wrong_rate,
        },
        {
          title: '未経験率',
          dataIndex: 'start',
          key: 'start',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.start.inexperienced_rate - b.start.inexperienced_rate,
        },
        {
          title: (
            <span style={{ fontWeight: 'bold', fontSize: 11 }}>
              平均
              <br />
              解答時間
            </span>
          ),
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table jc-center">
              <p className="label-number">
                {formatNumberToMinute(item.average_answer_time_per_question)}
              </p>
            </div>
          ),
        },
        {
          title: (
            <>
              獲得
              <br />
              スコア率
            </>
          ),
          className: 'br-1',
          dataIndex: 'start',
          key: 'start',
          align: 'center',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.acquisition_score_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.start.acquisition_score_rate - b.start.acquisition_score_rate,
        },
      ],
    },
    {
      title: `実施期間②：${endPeriod ? dayjs(endPeriod).format('YYYY年MM月DD日') : ''}`,
      className: 'implementation-period',
      children: [
        {
          title: '正解率',
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.accuracy_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.end?.accuracy_rate - b.end?.accuracy_rate,
        },
        {
          title: '不正解率',
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.wrong_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.end?.wrong_rate - b.end?.wrong_rate,
        },
        {
          title: '未経験率',
          dataIndex: 'end',
          key: 'end',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.inexperienced_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.end?.inexperienced_rate - b.end?.inexperienced_rate,
        },
        {
          title: (
            <span style={{ fontWeight: 'bold', fontSize: 11 }}>
              平均
              <br />
              解答時間
            </span>
          ),
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table jc-center">
              <p className="label-number">
                {formatNumberToMinute(item.average_answer_time_per_question)}
              </p>
            </div>
          ),
        },
        {
          title: (
            <>
              獲得
              <br />
              スコア率
            </>
          ),
          className: 'br-1',
          dataIndex: 'end',
          key: 'end',
          align: 'center',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className="item-table">
              <p className="label-number">{formatNumber(item.acquisition_score_rate * 100)}</p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.end?.acquisition_score_rate - b.end?.acquisition_score_rate,
        },
      ],
    },
    {
      title: '増減',
      className: 'order',
      children: [
        {
          title: '正解率',
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className={`item-table color-${item.accuracy_rate < 0 ? 'red' : 'green'}`}>
              <p className="label-number">
                {item.accuracy_rate > 0 ? '+' : ''}
                {formatNumber(item.accuracy_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.change.accuracy_rate - b.change.accuracy_rate,
        },
        {
          title: '不正解率',
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className={`item-table color-${item.wrong_rate > 0 ? 'red' : 'green'}`}>
              <p className="label-number">
                {item?.wrong_rate > 0 ? '+' : ''}
                {formatNumber(item?.wrong_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.change.wrong_rate - b.change.wrong_rate,
        },
        {
          title: '未経験率',
          dataIndex: 'change',
          key: 'change',
          align: 'right',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div className={`item-table color-${item.inexperienced_rate > 0 ? 'red' : 'green'}`}>
              <p className="label-number">
                {item?.inexperienced_rate > 0 ? '+' : ''}
                {formatNumber(item?.inexperienced_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.change.inexperienced_rate - b.change.inexperienced_rate,
        },
        {
          title: (
            <span style={{ fontWeight: 'bold', fontSize: 11 }}>
              平均
              <br />
              解答時間
            </span>
          ),
          dataIndex: 'change',
          key: 'change',
          align: 'center',
          width: '5%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => {
            return (
              <div
                className={`item-table color-${
                  item.average_answer_time_per_question > 0.5 ? 'red' : 'green'
                }`}
              >
                <p className="label-number">
                  {item?.average_answer_time_per_question > 0.5 ? '+' : ''}
                  {formatNumberToMinute(item?.average_answer_time_per_question)}
                </p>
              </div>
            );
          },
        },
        {
          title: (
            <>
              獲得
              <br />
              スコア率
            </>
          ),
          dataIndex: 'change',
          key: 'change',
          align: 'center',
          width: '6%',
          render: (item: Types.ReportSkillCheckUserTrans.UserAnalysisItemType) => (
            <div
              className={`item-table color-${item.acquisition_score_rate < 0 ? 'red' : 'green'}`}
            >
              <p className="label-number">
                {item?.acquisition_score_rate > 0 ? '+' : ''}
                {formatNumber(item?.acquisition_score_rate * 100)}
              </p>
              <p className="label">%</p>
            </div>
          ),
          sorter: (a, b) => a.change.acquisition_score_rate - b.change.acquisition_score_rate,
        },
      ],
    },
  ];

  return (
    <Wrapper>
      {totalReportSkillCheckUserTrans ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportSkillCheckUserTrans} <span className="text-static"> 件表示</span> /
                {totalReportSkillCheckUserTrans}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportSkillCheckUserTrans}
              showTotal={(total, range) => (
                <div className="text-total-record-table">
                  {range[0]} - {range[1]} <span className="text-static"> </span> {''} / {total}
                  <span className="text-static"> 件</span>
                </div>
              )}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        dataSource={
          exportPdf
            ? reportSkillCheckUserTrans.map((item, index) => ({ ...item, index }))
            : reportSkillCheckUserTrans
                .map((item, index) => ({ ...item, index }))
                .slice((page - 1) * pageSize, page * pageSize)
        }
        columns={columns}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        pagination={false}
      />
    </Wrapper>
  );
};

export default UserAnalysisReport;
