import React, { useState } from 'react';
import { Image } from 'antd';

const useImagePreview = () => {
  const [visible, setVisible] = useState(false);
  const [imgPreview, setImgPreview] = useState('');

  const handlePreviewImage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const targetElement = e.target as HTMLImageElement;
    if (targetElement.tagName === 'IMG') {
      setImgPreview(targetElement.src);
      setVisible(true);
    }
  };

  const ImagePreview: React.FC = () => {
    return (
      <Image
        style={{ display: 'none' }}
        src={imgPreview}
        preview={{
          visible: visible,
          scaleStep: 0.5,
          src: imgPreview,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    );
  };

  return { handlePreviewImage, ImagePreview };
};

export default useImagePreview;
