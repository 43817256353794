import React, { useMemo } from 'react';

import BreakdownAnswersPerCentage from './BreakdownAnswersPerCentage';
import { ITEM_GRAPH } from 'constant/select.constants';
import TableChart from './TableChart';

interface Props {
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  level1Code?: string;
  level2Code?: string;
  level3Code?: string;
  level4Code?: string;
  exportPdf?: boolean;
}

const ChartLevels: React.FC<Props> = ({
  level1Code,
  level2Code,
  level3Code,
  level4Code,
  itemGraph,
  exportPdf,
}) => {
  const dataColumLevels: {
    title: string;
    key: 'level1_name' | 'level2_name' | 'level3_name' | 'level4_name' | 'question_name';
    code: 'level1_code' | 'level2_code' | 'level3_code' | 'level4_code' | 'question_code';
  } = useMemo(() => {
    if (level4Code) {
      return {
        title: '設問',
        key: 'question_name',
        code: 'question_code',
      };
    }

    if (level3Code) {
      return {
        title: '第4階層',
        key: 'level4_name',
        code: 'level4_code',
      };
    }

    if (level2Code) {
      return {
        title: '第3階層',
        key: 'level3_name',
        code: 'level3_code',
      };
    }

    if (level1Code) {
      return {
        title: '第2階層',
        key: 'level2_name',
        code: 'level2_code',
      };
    }

    return {
      title: '第1階層',
      key: 'level1_name',
      code: 'level1_code',
    };
  }, [level1Code, level2Code, level3Code, level4Code]);

  return itemGraph === 'bar_chart' ? (
    <TableChart dataColumLevels={dataColumLevels} />
  ) : (
    <BreakdownAnswersPerCentage dataColumLevels={dataColumLevels} exportPdf={exportPdf} />
  );
};

export default ChartLevels;
