import { find } from 'lodash';

import { updateFolder } from 'pages/Manual/thunk';
import { MANUAL_FOLDER } from 'configs';
import { AppDispatch } from 'types';

export const handleUpdateFolderNested = async (folder_i_id: string, dispatch: AppDispatch) => {
  if (!folder_i_id) return;

  const resultActionUpdateFolder = await dispatch(
    updateFolder({
      id: folder_i_id,
      data: {
        item: {
          updatedat: new Date(),
        },
        is_force_update: true,
        return_item_result: true,
      },
    })
  );
  if (updateFolder.fulfilled.match(resultActionUpdateFolder)) {
    if (resultActionUpdateFolder.payload.item.parent_folder_id) {
      const i_id = find(resultActionUpdateFolder.payload.item.links, { d_id: MANUAL_FOLDER.id })
        ?.i_ids[0];

      if (i_id) {
        handleUpdateFolderNested(i_id, dispatch);
      }
    }
  }
};

export const handleUpdateParentFolderManual = (folder_i_id: string, dispatch: AppDispatch) => {
  if (!folder_i_id) return;

  //   const folder_i_id = get(find(links, { d_id: MANUAL_FOLDER.id }), 'i_ids[0]', undefined);

  if (folder_i_id) {
    handleUpdateFolderNested(folder_i_id, dispatch);
  }
};
