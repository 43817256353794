import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { CurriculumStatus, CurriculumType } from 'constant/enum.constant';
import * as Types from 'types';
import {
  deleteCurriculum,
  updateCurriculum,
  getDataUserSetting,
  getCurriculumMaster,
  setPublishCurriculum,
  setRequiredCurriculum,
  curriculumExportDataCSV,
  getCurriculum,
} from './thunk';

export interface InitialState {
  data_curricullum_export: Array<Types.CurriculumExport.ResponseType>;
  curricullumMaster: Array<Types.CurriculumHierarchy.ResponseType>;
  curricullum: Array<Types.TreeItem<Types.CurriculumItemType>>;
  questionCurriculumSelected: Array<Types.CurriculumItemType>;
  questionCurriculum: Array<Types.CurriculumItemType>;
  data_user_setting: Array<Types.UserTreeviewType>;
  total: number;
  filter_conditions: {
    type?: keyof typeof CurriculumType;
    status?: keyof typeof CurriculumStatus;
    name?: string;
    conditions: Array<Types.ConditionsType>;
  };
}

const initialState: InitialState = {
  total: 0,
  curricullum: [],
  curricullumMaster: [],
  data_user_setting: [],
  questionCurriculum: [],
  data_curricullum_export: [],
  questionCurriculumSelected: [],
  filter_conditions: {
    conditions: [],
  },
};

export const curriculumSlice = createSlice({
  name: 'curricullum-page',
  initialState,
  reducers: {
    setFilterConditions(
      state,
      action: PayloadAction<{
        type?: keyof typeof CurriculumType;
        status?: keyof typeof CurriculumStatus;
        name?: string;
      }>
    ) {
      if (action.payload.type) {
        state.filter_conditions.type = action.payload.type;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'required_curriculum'
        );
        const indexOfficial = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'official_curriculum'
        );
        switch (action.payload.type) {
          case 'curriculum':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curriculum',
                search_value: ['0'],
                exact_match: true,
              });
              if (indexOfficial > -1) {
                state.filter_conditions.conditions.splice(indexOfficial, 1);
              }
            }
            break;
          case 'required':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'required_curriculum',
                search_value: ['1'],
                exact_match: true,
              });
              if (indexOfficial > -1) {
                state.filter_conditions.conditions.splice(indexOfficial, 1);
              }
            }
            break;
          case 'officialCurriculum':
            if (indexOfficial > -1) {
              state.filter_conditions.conditions[indexOfficial].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'official_curriculum',
                search_value: ['1'],
                exact_match: true,
              });
              if (findIndex > -1) {
                state.filter_conditions.conditions.splice(findIndex, 1);
              }
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.status) {
        state.filter_conditions.status = action.payload.status;
        const findIndex = state.filter_conditions.conditions.findIndex(
          (value) => value.id === 'publish'
        );
        switch (action.payload.status) {
          case 'publish':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['1'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['1'],
                exact_match: true,
              });
            }
            break;
          case 'edit':
            if (findIndex >= 0) {
              state.filter_conditions.conditions[findIndex].search_value = ['0'];
            } else {
              state.filter_conditions.conditions.push({
                id: 'publish',
                search_value: ['0'],
                exact_match: true,
              });
            }
            break;
          default:
            if (findIndex >= 0) {
              state.filter_conditions.conditions.splice(findIndex, 1);
            }
            break;
        }
      }
      if (action.payload.name) {
        state.filter_conditions.name = action.payload.name;
        const data = [...state.curricullum];
        state.curricullum = data.sort((x, y) =>
          x.i_id === action.payload.name ? -1 : y.i_id === action.payload.name ? 1 : 0
        );
        const new_data_user_setting = [...state.data_user_setting];
        state.data_user_setting = new_data_user_setting.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      }
    },
    clearFilterConditions(state) {
      state.filter_conditions = {
        conditions: [],
        name: undefined,
        status: undefined,
        type: undefined,
      };
    },
    resetInitialState: () => {
      return initialState;
    },
    setQuestionCurriculumSelected(
      state,
      action: PayloadAction<{
        curriculum_id?: string;
        questionCurriculum: Array<Types.CurriculumItemType>;
      }>
    ) {
      if (action.payload.curriculum_id && action.payload.questionCurriculum.length) {
        const questionFilled = action.payload.questionCurriculum.filter(
          (question) => question.item_ref?.curriculum_code.i_id === action.payload.curriculum_id
        );

        state.questionCurriculumSelected = questionFilled;
      } else {
        state.questionCurriculumSelected = [];
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(setRequiredCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        required_curriculum: action.payload.item.required_curriculum,
      };
    });
    builder.addCase(setPublishCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        publish: action.payload.item.publish,
      };
    });
    builder.addCase(updateCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum[curriculumIndex] = {
        ...state.curricullum[curriculumIndex],
        name: action.payload.item.name,
        description: action.payload.item.description,
      };
    });
    builder.addCase(deleteCurriculum.fulfilled, (state, action) => {
      const curriculumIndex = state.curricullum.findIndex(
        (c) => c.i_id === action.payload.item.i_id
      );
      state.curricullum.splice(curriculumIndex, 1);
    });

    builder.addCase(getCurriculum.fulfilled, (state, action) => {
      state.questionCurriculum = action.payload.items;

      const newCurriculum: Array<Types.TreeItem<Types.CurriculumItemType>> = [];
      action.payload.items.forEach((item) => {
        const itemIndex = newCurriculum.findIndex((c) => c.i_id === item.i_id);
        if (itemIndex >= 0) {
          newCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newCurriculum[itemIndex],
            item,
          });
        } else {
          newCurriculum.push({
            i_id: item.i_id,
            code: item.curriculum_code,
            name: item.curriculum_name,
            description: item.curriculum_description,
            sort_order: Number(item.curriculum_sort_order),
            publish: Number(item.publish),
            required_curriculum: Number(item.required_curriculum),
            curriculum_id: item.i_id,
            problems_count: Number(item.probs_count),
            fileID: item.fileID,
            knowledge_id: item.knowledge_id,
            i_id_knowledge: item.i_id_knowledge,
            columnIndex: 0,
            official_curriculum_code: item.official_curriculum_code,
            official_code: item.official_curriculum_code,
            official_curriculum: item.official_curriculum,
            children: item.level1_i_id
              ? [
                  {
                    i_id: item.level1_i_id,
                    curriculum_id: item.i_id,
                    name: item.level1_name,
                    code: item.level1_code,
                    sort_order: item.level1_sort_order,
                    publish: Number(item.publish),
                    required_curriculum: Number(item.required_curriculum),
                    problems_count: Number(item.probs_count),
                    columnIndex: 1,
                    official_curriculum_code: item.official_curriculum_code,
                    official_code: item.official_level1_code,
                    children: item.level2_i_id
                      ? [
                          {
                            i_id: item.level2_i_id,
                            curriculum_id: item.i_id,
                            name: item.level2_name,
                            code: item.level2_code,
                            sort_order: item.level2_sort_order,
                            publish: Number(item.publish),
                            required_curriculum: Number(item.required_curriculum),
                            problems_count: Number(item.probs_count),
                            official_curriculum_code: item.official_curriculum_code,
                            official_code: item.official_level2_code,
                            columnIndex: 2,
                            children: item.level3_i_id
                              ? [
                                  {
                                    i_id: item.level3_i_id,
                                    curriculum_id: item.i_id,
                                    name: item.level3_name,
                                    code: item.level3_code,
                                    sort_order: item.level3_sort_order,
                                    publish: Number(item.publish),
                                    required_curriculum: Number(item.required_curriculum),
                                    problems_count: Number(item.probs_count),
                                    columnIndex: 3,
                                    official_curriculum_code: item.official_curriculum_code,
                                    official_code: item.official_level3_code,
                                    children: item.level4_i_id
                                      ? [
                                          {
                                            i_id: item.level4_i_id,
                                            curriculum_id: item.i_id,
                                            name: item.level4_name,
                                            code: item.level4_code,
                                            sort_order: item.level4_sort_order,
                                            publish: Number(item.publish),
                                            required_curriculum: Number(item.required_curriculum),
                                            problems_count: Number(item.probs_count),
                                            columnIndex: 4,
                                            official_curriculum_code: item.official_curriculum_code,
                                            official_code: item.official_level4_code,
                                            children: item.question_code_i_id
                                              ? [
                                                  {
                                                    i_id: item.question_code_i_id,
                                                    curriculum_id: item.i_id,
                                                    name: item.question_name,
                                                    code: item.question_code,
                                                    attach_fileID1: item.attach_fileID1,
                                                    attach_fileID2: item.attach_fileID2,
                                                    attach_fileID3: item.attach_fileID3,
                                                    question_description: item.question_description,
                                                    sort_order: item.question_sort_order,
                                                    question_assign_level_i_id:
                                                      item.question_assign_level_i_id,
                                                    publish: Number(item.publish),
                                                    required_curriculum: Number(
                                                      item.required_curriculum
                                                    ),
                                                    problems_count: Number(item.probs_count),
                                                    columnIndex: 5,
                                                    official_curriculum_code:
                                                      item.official_curriculum_code,
                                                  },
                                                ]
                                              : [],
                                          },
                                        ]
                                      : [],
                                  },
                                ]
                              : [],
                          },
                        ]
                      : [],
                  },
                ]
              : [],
          });
        }
      });
      if (state.filter_conditions.name) {
        state.curricullum = newCurriculum.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      } else {
        state.curricullum = newCurriculum;
      }
    });

    builder.addCase(getDataUserSetting.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.UserTreeviewType> = [];
      action.payload.items.forEach((item) => {
        const curriculumIndex = newCurriculum.findIndex((curr) => curr.i_id === item.i_id);
        if (curriculumIndex >= 0) {
          const departmentIndex = newCurriculum[curriculumIndex].children?.findIndex(
            (department) => department.department_name === item.department_name
          );
          if (departmentIndex !== undefined && departmentIndex >= 0) {
            if (
              !newCurriculum[curriculumIndex].children![departmentIndex].children?.some(
                (user) => user.user_id === item.user_name_i_id && item.main === 'main'
              )
            ) {
              newCurriculum[curriculumIndex].children![departmentIndex].children?.push({
                i_id: item.department_name_i_id,
                user_name: item.user_name,
                user_id: item.user_name_i_id,
              });
            }
          } else {
            if (item.department_name && item.main === 'main') {
              newCurriculum[curriculumIndex].children?.push({
                department_name: item.department_name,
                department_code: item.employee_number,
                children: [
                  {
                    i_id: item.createdat_i_id,
                    user_name: item.user_name,
                    user_id: item.user_name_i_id,
                  },
                ],
              });
            }
          }
        } else {
          newCurriculum.push({
            i_id: item.i_id,
            curricullum_name: item.curricullum_name,
            curricullum_sort_order: item.curricullum_sort_order,
            curricullum_description: item.curricullum_description,
            publish: Number(item.publish),
            curricullum_code: item.curricullum_code,
            company_id: item.company_id,
            official_curriculum_code: item.official_curriculum_code,
            children:
              item.createdat_i_id && item.main === 'main'
                ? [
                    {
                      department_name: item.department_name,
                      department_code: item.employee_number,
                      children: [
                        {
                          i_id: item.createdat_i_id,
                          user_name: item.user_name,
                          user_id: item.user_name_i_id,
                        },
                      ],
                    },
                  ]
                : [],
          });
        }
      });
      if (state.filter_conditions.name) {
        state.data_user_setting = newCurriculum.sort((x, y) =>
          x.i_id === state.filter_conditions.name
            ? -1
            : y.i_id === state.filter_conditions.name
            ? 1
            : 0
        );
      } else {
        state.data_user_setting = newCurriculum;
      }
    });
    builder.addCase(getCurriculumMaster.fulfilled, (state, action) => {
      state.curricullumMaster = action.payload.report_results;
      state.total = action.payload.totalItems;
    });
    builder.addCase(curriculumExportDataCSV.fulfilled, (state, action) => {
      state.data_curricullum_export = action.payload.report_results;
      const allCode = uniq(state.data_curricullum_export.map((value) => value.curriculum_code));
      state.data_curricullum_export = state.data_curricullum_export.map((value) => {
        const indexCode = allCode.findIndex((code) => value.curriculum_code === code);
        return { flag: indexCode + 1, ...value };
      });
    });
  },
});
export const {
  setQuestionCurriculumSelected,
  clearFilterConditions,
  setFilterConditions,
  resetInitialState,
} = curriculumSlice.actions;

export default curriculumSlice.reducer;
