import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { getConfirmRegistration, signUp } from 'containers/Auth/thunk';
import { authSelector } from 'containers/Auth/selectors';
import { InputPassword, SpinLoading } from 'components';
import { registerFormSchema } from 'libs/validations';
import AuthLayout from 'containers/Auth/Layout';
import { routes } from 'navigations/routes';
import { RegisterFormik } from 'types';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { Centering } from 'assets';
import { config } from 'configs';

const RegisterFormPage: React.FC = () => {
  const { confirmation_id } = useParams<{ confirmation_id: string }>();

  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const navigate = useNavigate();

  const { registrationConfirm, IDconfirming } = useSelector(authSelector);

  const formik = useFormik<RegisterFormik>({
    initialValues: {
      password: '',
      rePassword: '',
    },
    validationSchema: registerFormSchema,
    validateOnMount: true,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!registrationConfirm) return;
      const resultAction = await dispatch(
        signUp({
          confirmation_id: registrationConfirm.user.confirmation_id,
          username: registrationConfirm.user.email,
          email: registrationConfirm.user.email,
          password: values.rePassword,
          workspace: config.WORKSPACE,
          email_templates_id: config.CONFIRM_TEMPLATES_ID,
          send_password_to_email: true,
          hostname: window.location.host,
        })
      );
      if (signUp.fulfilled.match(resultAction)) {
        navigate(routes.Dashboard.path);
      }
    },
  });

  useEffect(() => {
    (async () => {
      if (!confirmation_id) return;
      const resultAction = await dispatch(getConfirmRegistration({ confirmation_id }));
      if (
        getConfirmRegistration.rejected.match(resultAction) ||
        resultAction.payload.user.confirmed ||
        resultAction.payload.user.isElapsed
      ) {
        navigate(routes.Dashboard.path);
      }
    })();
  }, [confirmation_id, dispatch, navigate]);

  return (
    <SpinLoading size="large" loading={IDconfirming}>
      <AuthLayout>
        <SectionStyled>
          <img src={Centering} alt="logo" className="logo" />
          <p className="register">{messages['A-01-1']}</p>
          <div className="wrap-description">
            <p className="description">{messages['A-01-2']}</p>
          </div>
          <FormikProvider value={formik}>
            <Form className="form">
              <Form.Item name="password">
                <InputPassword
                  className="input"
                  name="password"
                  placeholder={messages['A-01-3'] as string}
                  prefix={<LockOutlined className="icon" />}
                />
              </Form.Item>
              <Form.Item name="rePassword">
                <InputPassword
                  className="input"
                  name="rePassword"
                  placeholder={messages['A-01-4'] as string}
                  prefix={<LockOutlined className="icon" />}
                />
              </Form.Item>
              <SubmitButton disabled={!(formik.isValid && formik.dirty)} className="btn-submit">
                {messages['A-01-5']}
              </SubmitButton>
            </Form>
          </FormikProvider>
        </SectionStyled>
      </AuthLayout>
    </SpinLoading>
  );
};

export default RegisterFormPage;
