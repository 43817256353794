import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import Styled from './styles';
import Modal from '../index';

interface Props {
  title: string;
  visible: boolean;
  changedEmail: boolean;
  content: React.ReactNode;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit?: () => void | Promise<void>;
}

const SendNoticeComplete: React.FC<Props> = ({ visible, setVisible, content, title, onSubmit }) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <Modal
      title={title}
      visible={visible}
      width={720}
      headerStyle={{
        borderBottom: '1px solid #cccccc',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      onCancel={handleToggleModal}
    >
      <Styled>
        <CheckCircleOutlined className="icon-checked" />
        <div className="content">{content}</div>
      </Styled>
    </Modal>
  );
};

export default SendNoticeComplete;
