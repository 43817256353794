import React, { CSSProperties, useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import { trainingReportSelector } from 'pages/Reports/Training/selectors';
import { sortDataReportUser } from '../../../slice';
import getWidthContainer from 'libs/utils/pieChart';
import { formatNumber } from 'libs/utils/format';
import { OrderByType } from 'types/config';
import { useAppDispatch } from 'hooks';
import BreakdownStyled from './styles';
import { EmptyPie } from 'assets';

interface Props {
  isUserTransTable?: boolean;
  exportPdf?: boolean;
}

const BreakdownAnswersPerCentage: React.FC<Props> = ({ isUserTransTable, exportPdf }) => {
  const [sortByAverageAnswerTime, setSortByAverageAnswerTime] = useState<OrderByType>();
  const [sortByNotImplemented, setSortByNotImplemented] = useState<OrderByType>();
  const [sortByAnswersTime, setSortByAnswersTime] = useState<OrderByType>();
  const [sortByInProgress, setSortByInProgress] = useState<OrderByType>();
  const [sortByCorrect, setSortByCorrect] = useState<OrderByType>();
  const [sortByScore, setSortByScore] = useState<OrderByType>();

  const dispatch = useAppDispatch();

  const { totalReportCurriculumUser, dataReportCurriculumUser } =
    useSelector(trainingReportSelector);

  return (
    <BreakdownStyled>
      <div className={`wrapper-header ${isUserTransTable && 'border'} `}>
        <div>
          {isUserTransTable && (
            <div className="header">
              <span>【共通情報】</span>
              <span className="space" />
              <span>総設問数 </span>
              <span className="bold">{dataReportCurriculumUser[0]?.question_count}問</span>
            </div>
          )}
        </div>

        <div className="legend">
          <div className="sort-column">
            <div className="legend-filter blue" />
            <span className="legend-name">正解</span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByCorrect((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'correct_answers_num',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByCorrect === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByCorrect === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
          <div className="sort-column">
            <div className="legend-filter pink" />
            <span className="legend-name">不正解</span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByInProgress((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'incorrect_answer',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByInProgress === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByInProgress === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
          <div className="sort-column">
            <div className="legend-filter gray" />
            <span className="legend-name">未経験 </span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByNotImplemented((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'inexperienced',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByNotImplemented === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByNotImplemented === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
          <div className="sort-column">
            <span className="legend-name">進捗率</span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByAnswersTime((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'progress_rate',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByAnswersTime === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByAnswersTime === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
          <div className="sort-column">
            <span className="legend-name">総解答数</span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByAverageAnswerTime((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'responses_num',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByAverageAnswerTime === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByAverageAnswerTime === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
          <div className="sort-column">
            <span className="legend-name">実質正解率</span>
            <Button
              className="btn-sort"
              onClick={() => {
                setSortByScore((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
                dispatch(
                  sortDataReportUser({
                    sort_field: 'real_accuracy_rate',
                    sort_order: sortByCorrect === 'asc' ? 'desc' : 'asc',
                  })
                );
              }}
            >
              <div className="wrap-sort">
                <CaretUpOutlined
                  className={`icon-caret${sortByScore === 'asc' ? ' active' : ''}`}
                />
                <CaretDownOutlined
                  className={`icon-caret${sortByScore === 'desc' ? ' active' : ''}`}
                />
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="container-chart">
        {dataReportCurriculumUser.slice(isUserTransTable ? 0 : 1).map((data, index) => (
          <div key={index} className="item">
            {totalReportCurriculumUser &&
              ((isUserTransTable && index > 0) || !isUserTransTable) && (
                <div className="number-order">
                  <span>{index + (!isUserTransTable ? 1 : 0)}</span>
                </div>
              )}
            <div className="pie">
              <p className="text-none-wrap">
                {isUserTransTable
                  ? !index
                    ? 'ユーザー平均'
                    : data.user_name || '-'
                  : data.curriculum_name}
              </p>
              {data ? (
                <div>
                  <Pie
                    appendPadding={10}
                    data={[
                      {
                        label: '正解',
                        value: Number(formatNumber(+data.accuracy_rate * 100)),
                      },
                      {
                        label: '不正解',
                        value: Number(formatNumber(+data.wrong_rate * 100)),
                      },
                      {
                        label: '未実施',
                        value: Number(formatNumber(+data.inexperienced_rate * 100)),
                      },
                    ]}
                    angleField="value"
                    colorField="label"
                    color={['#379BD2', '#EA8C92', '#EBEBEB']}
                    radius={1}
                    innerRadius={0.64}
                    label={{
                      type: 'inner',
                      offset: '-50%',
                      style: {
                        textAlign: 'center',
                      },
                      autoRotate: false,
                      content: function content(_ref) {
                        return _ref.value ? `${_ref.value}%` : '';
                      },
                    }}
                    statistic={{
                      title: exportPdf
                        ? {
                            offsetY: 10,
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '11px',
                              fontWeight: 'normal',
                              color: '#777777',
                              margin: '0px auto 5px auto',
                            },
                            content: '進捗率',
                          }
                        : {
                            offsetY: 10,
                            customHtml: (container) => {
                              return renderStatistic(
                                getWidthContainer(container, true),
                                '進捗率',
                                'title',
                                {
                                  display: 'flex',
                                  fontSize: 11,
                                  textShadow: 'none',
                                  fontWeight: 400,
                                  color: '#777777',
                                }
                              );
                            },
                          },
                      content: exportPdf
                        ? {
                            style: {
                              whiteSpace: 'pre-wrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              fontSize: '24px',
                              fontWeight: 'normal',
                              color: '#2A2A2A',
                              paddingTop: '10px',
                            },
                            content: `${formatNumber(+data.progress_rate * 100)}%`,
                          }
                        : {
                            customHtml: (container, _view) => {
                              return renderStatistic(
                                getWidthContainer(container, false),
                                formatNumber(+data.progress_rate * 100),
                                'content',
                                {
                                  fontSize: 24,
                                  fontWeight: 400,
                                  color: '#2A2A2A',
                                }
                              );
                            },
                          },
                    }}
                    width={200}
                    height={200}
                    legend={false}
                  />
                </div>
              ) : (
                <img src={EmptyPie} className="empty-pie" alt="empty-pie" />
              )}
            </div>
            <div className="footer-item skill-check-all">
              <div className="responses_num">
                <p className="title">総解答数</p>
                <p className="time">{data ? formatNumber(+data.responses_num) : '-'}</p>
              </div>

              <div className="real_accuracy_rate">
                <p className="title">実質正解率</p>
                <p className="time">
                  {data ? `${formatNumber(+data.real_accuracy_rate * 100)}%` : '-'}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BreakdownStyled>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;top:85px;width:${containerWidth}px;font-size:11px;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><div style="display: flex; align-items: baseline;></div><h4 style="font-weight: 400;margin: 0;font-size: 11px;color: #777;">${text}</h4></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><span style="font-weight: 400;font-size: 24px;color: #2A2A2A;">${text}<span style="font-weight: 400;margin: 0;font-size: 16px;color: #2A2A2A;">%</span></span></div>`;
  }
};

export default BreakdownAnswersPerCentage;
