import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 2px solid #2773ba;
  .bg-blue {
    background-color: #f7f8fa;
  }
  .blBlue {
    border-left: 2px solid #2773ba;
  }
  .blGreen {
    border-left: 2px solid #b57fcf;
    .rowContent {
      cursor: pointer;
    }
  }
  .blViolet {
    border-left: 2px solid #71c131;
    .rowContent {
      cursor: pointer;
    }
  }
  .rowWrapper {
    border-top: 1px solid #e5eaf4;
    padding: 5px 10px;
    width: 100%;
    .item-image {
      width: 100%;
      height: 155px;
      display: flex;
      justify-content: center;
      align-items: center;
      .image {
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
    .rowContentHead {
      display: flex;
      align-items: center;
      .spanHead {
        display: flex;
        align-items: center;
        color: #777777;
        margin-right: 10px;
        .icon {
          margin-right: 2px;
          width: 14px;
          height: 14px;
        }
        .spanText {
          font-size: 10px;
        }
      }
    }
    .text-content {
      font-size: 12px;
      color: #555555;
    }
    .rowContent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .icons {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon {
          font-size: 16px;
          color: #c4c4c4;
          margin-left: 10px;
        }
        .caret-icon {
          color: #bfbfbf;
        }
      }
      .ant-switch-checked {
        background-color: #2773ba;
      }
    }
  }
  .container {
    background: #f8f8f8;
    border: 1px solid #e1e1e1;
    padding: 10px;
    margin: 10px;
    .flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .time-limit,
      .question-count {
        display: flex;
        align-items: center;
        .label {
          font-weight: 400;
          font-size: 12px;
          color: #999999;
          margin: 0;
          .icon {
            color: #777777;
            font-size: 15px;
            margin-right: 2px;
          }
        }
        .content {
          color: #424242;
          font-size: 14px;
          margin: 0;
        }
      }
    }
    .period {
      display: flex;
      .label {
        font-weight: 400;
        font-size: 12px;
        color: #999999;
        margin: 0;
        .icon {
          color: #777777;
          font-size: 15px;
          margin-right: 2px;
        }
      }
      .content {
        color: #424242;
        font-size: 14px;
        margin: 0;
      }
    }
  }
`;

interface Props {
  permission: number;
}
export const Button = styled.button<Props>`
  background-color: ${(props) => (props.permission === 1 ? '#cccccc' : '#f6ac00')};
  font-weight: bold;
  font-size: 12px;
  color: ${(props) => (props.permission === 1 ? '#777777' : '#ffffff')};
  border: none;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  padding: 2px 15px;
`;
