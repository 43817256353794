import React from 'react';
import { Steps } from 'antd';
import { useIntl } from 'react-intl';

import { LIST_TITLE_HEADER_TREE_TRIAL } from 'constant';
import { Wrapper } from './styles';

const { Step } = Steps;

interface Props {
  currentStep: number;
  success: boolean;
}

const HeaderTreeTrial: React.FC<Props> = ({ currentStep, success }) => {
  const { messages } = useIntl();
  return (
    <Wrapper success={success}>
      <div className="header-information-register">
        <Steps current={currentStep} className="site-navigation-steps">
          {LIST_TITLE_HEADER_TREE_TRIAL.map((text, index) => (
            <Step key={index} title={messages[text]} />
          ))}
        </Steps>
        <div className="wrap-step-info">
          <span className="step">Step {currentStep + 1}:</span>
          <span className="step-name">{messages[LIST_TITLE_HEADER_TREE_TRIAL[currentStep]]}</span>
        </div>
      </div>
    </Wrapper>
  );
};

export default HeaderTreeTrial;
