import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EditOutlined, MinusOutlined, StarFilled } from '@ant-design/icons';
import { useDrag, useDrop } from 'react-dnd';
import { differenceBy, maxBy } from 'lodash';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

import {
  createLinkSkillCheckAssignQuestion,
  updateLinkSkillCheckAssignQuestion,
} from 'containers/CreateEditQuestion/thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { Wrapper, WrapperToolTip, WrapperColumnRoot } from './styles';
import { CreateQuestion } from 'pages/Settings/QuestionMaster/Modal';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { QuestionProps, SkillCheckTreeViewType } from 'types';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { useAppDispatch, usePermission } from 'hooks';
import NodeRenderer from './NodeRenderer';
import {
  deleteLinkSkillCheckAssignQuestion,
  getDataSkillCheckTree,
  updateSkillCheck,
} from 'pages/Settings/SkillCheck/thunk';

interface Props {
  columnClosed: boolean;
  isShowMenuRight?: boolean;
  fetchDataSkillCheck: () => void;
  treeData: SkillCheckTreeViewType;
  selectedQuestion: QuestionProps[];
  fetchDataUserSkillCheck: () => void;
  skillCheckSelected?: SkillCheckTreeViewType;
  setSelectedQuestion: React.Dispatch<React.SetStateAction<QuestionProps[]>>;
  setSkillCheckSelected: React.Dispatch<React.SetStateAction<SkillCheckTreeViewType | undefined>>;
}

interface IQuestionItem {
  i_id: string;
  sort_order: number;
  question_code: string;
  question_name: string;
  question_score: number;
  skill_check_code: string;
}

interface QuestionItemProps {
  index: number;
  question: IQuestionItem;
  fetchDataSkillCheck: () => void;
}

const TreeView: React.FC<Props> = ({
  treeData,
  isShowMenuRight,
  columnClosed,
  selectedQuestion,
  skillCheckSelected,
  fetchDataSkillCheck,
  setSelectedQuestion,
  setSkillCheckSelected,
  fetchDataUserSkillCheck,
}) => {
  const [visibleModalDelete, setVisibleModalDelete] = useState<boolean>(false);
  const [visibleSuccess, setVisibleSuccess] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<number>();
  const [itemId, setItemId] = useState<string>('');
  const [openModalCreateQuestion, setOpenModalCreateQuestion] = useState<{
    question_id?: string;
    visible: boolean;
    type: 'create' | 'edit';
    onSubmit?: () => void;
  }>({ visible: false, type: 'create' });

  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const lastSortOrder = useMemo(() => {
    if (sortOrder) return sortOrder;

    return maxBy(skillCheckSelected?.children, 'sort_order')?.sort_order ?? 0;
  }, [skillCheckSelected, sortOrder]);

  const selectedQuestionCanAdd: QuestionProps[] = useMemo(() => {
    if (selectedQuestion[0] && skillCheckSelected) {
      return differenceBy(selectedQuestion, skillCheckSelected.children, 'question_code');
    }
    return [];
  }, [selectedQuestion, skillCheckSelected]);

  const [, drop] = useDrop<
    {
      type: string;
      item: {
        i_id: string;
        name: string;
        question_code: string;
        score?: number;
      };
    },
    void,
    void
  >({
    accept: 'move-question-skill-check',
    canDrop: (item) =>
      skillCheckSelected?.i_id === treeData.i_id &&
      !treeData.children?.some((c) => c.question_code === item.item.question_code),
    drop: () => {
      (async () => {
        dispatch(startLoading());

        if (skillCheckSelected && selectedQuestionCanAdd[0]) {
          const resultActions = await Promise.all(
            selectedQuestionCanAdd.map((item, index) =>
              dispatch(
                createLinkSkillCheckAssignQuestion({
                  item: {
                    company_id: userInfo?.company_id,
                    skill_check_code: skillCheckSelected?.skill_check_code,
                    question_code: item.question_code,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                    sort_order: lastSortOrder + index + 1,
                  },
                })
              )
            )
          );
          await dispatch(
            updateSkillCheck({
              id: skillCheckSelected?.i_id!,
              data: {
                item: {
                  probs_count:
                    (skillCheckSelected?.children.length || 0) +
                    resultActions.filter((r) =>
                      createLinkSkillCheckAssignQuestion.fulfilled.match(r)
                    ).length,
                  setting_score: selectedQuestion.reduce((result, item) => {
                    return result + item.score!;
                  }, skillCheckSelected?.score || 0),
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          );
          setSortOrder(lastSortOrder + selectedQuestionCanAdd.length);
          setSelectedQuestion([]);
          setSkillCheckSelected(undefined);
          fetchDataSkillCheck();
          dispatch(stopLoading());
        }
      })();
    },
  });

  const fetchSkillCheck = async () => {
    await dispatch(
      getDataSkillCheckTree({
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [{ id: 'sort_order', order: 'asc' }],
      })
    );
  };

  const handleRemoveLinkQuestion = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      deleteLinkSkillCheckAssignQuestion({
        id: itemId,
      })
    );
    if (deleteLinkSkillCheckAssignQuestion.fulfilled.match(resultAction)) {
      await dispatch(
        updateSkillCheck({
          id: treeData?.i_id,
          data: {
            item: {
              probs_count:
                treeData?.probs_count && treeData?.probs_count > 0 ? treeData?.probs_count - 1 : 0,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      );
      fetchDataSkillCheck();
      setSkillCheckSelected(undefined);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    return () => {
      setSkillCheckSelected(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper
      className="rst__tree"
      nodeHeight={40}
      treeActive={skillCheckSelected !== undefined && skillCheckSelected.i_id === treeData.i_id}
      isShowMenuRight={isShowMenuRight}
    >
      <WrapperColumnRoot className="rst__node">
        <div className="rst__nodeContent">
          <NodeRenderer
            data={treeData}
            fetchDataSkillCheck={fetchDataSkillCheck}
            fetchDataUserSkillCheck={fetchDataUserSkillCheck}
            handleSwitch={(value: boolean) => setSkillCheckSelected(value ? treeData : undefined)}
            itemSelected={
              skillCheckSelected !== undefined && skillCheckSelected.i_id === treeData.i_id
            }
          />
        </div>
      </WrapperColumnRoot>
      {!columnClosed && (
        <div ref={drop} className="tree">
          <div className="wrap_node_tree">
            {treeData?.children?.length ? (
              treeData.children.map((question, index) => (
                <Popover
                  key={index}
                  placement="bottomLeft"
                  overlayStyle={{
                    width: 204,
                    marginTop: '-25px',
                  }}
                  trigger="click"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                  content={
                    <WrapperToolTip>
                      <p className="name">{question.question_name}</p>
                      <div className="score-action">
                        <div className="score">
                          <span className="label">スコア：</span>
                          <span className="value">
                            {Array.from({ length: 3 }).map((_, i) => (
                              <StarFilled
                                key={i}
                                className={`star-icon${
                                  i < question.question_score ? ' active' : ''
                                }`}
                              />
                            ))}
                          </span>
                        </div>
                        {!treeData.publish && (
                          <div className="action">
                            <EditOutlined
                              className="icon"
                              onClick={() => {
                                if (permissionNumber !== 1) {
                                  setOpenModalCreateQuestion({
                                    visible: true,
                                    type: 'edit',
                                    question_id: question.i_id_question,
                                  });
                                }
                              }}
                            />
                            <MinusOutlined
                              className="icon"
                              onClick={() => {
                                if (permissionNumber !== 1) {
                                  setItemId(question.i_id!);
                                  setVisibleModalDelete(true);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </WrapperToolTip>
                  }
                >
                  <div className="node-item">
                    <QuestionItem
                      index={index}
                      question={question}
                      fetchDataSkillCheck={fetchDataSkillCheck}
                    />
                  </div>
                </Popover>
              ))
            ) : (
              <p className="text-not-set">未設定</p>
            )}
          </div>
        </div>
      )}
      <ConfirmDeleteModal
        title="設問解除"
        visible={visibleModalDelete}
        setVisible={setVisibleModalDelete}
        onCancel={() => setItemId('')}
        onSubmit={handleRemoveLinkQuestion}
        subTitle="指定した設問のスキルチェック設定を解除します。"
      />
      <CreateQuestion
        setVisibleSuccess={setVisibleSuccess}
        openModalCreateEditQuestion={openModalCreateQuestion}
        setOpenModalCreateEditQuestion={setOpenModalCreateQuestion}
        fetchData={fetchSkillCheck}
      />
      <CompletedModal
        visible={visibleSuccess}
        setVisible={setVisibleSuccess}
        title="登録が完了しました"
        onSubmit={() => {
          setVisibleSuccess(!visibleSuccess);
        }}
      />
    </Wrapper>
  );
};

export default TreeView;

const QuestionItem: React.FC<QuestionItemProps> = ({ index, question, fetchDataSkillCheck }) => {
  const { question_name, question_score } = question;
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const handleMoveQuestion = async (dragItem: IQuestionItem, dropItem: IQuestionItem) => {
    dispatch(startLoading());

    const resultActions = await Promise.all([
      dispatch(
        updateLinkSkillCheckAssignQuestion({
          id: dragItem.i_id,
          data: {
            item: {
              sort_order: dropItem.sort_order,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      ),
      dispatch(
        updateLinkSkillCheckAssignQuestion({
          id: dropItem.i_id,
          data: {
            item: {
              sort_order: dragItem.sort_order,
            },
            return_item_result: true,
            is_force_update: true,
          },
        })
      ),
    ]);

    if (
      resultActions.some((action) => updateLinkSkillCheckAssignQuestion.fulfilled.match(action))
    ) {
      fetchDataSkillCheck();
    }

    dispatch(stopLoading());
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'move-question-item', question },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  const [, drop] = useDrop<
    {
      type: string;
      question: IQuestionItem;
    },
    void,
    void
  >({
    accept: 'move-question-item',
    canDrop: (item) =>
      item.question.sort_order !== question.sort_order &&
      item.question.skill_check_code === question.skill_check_code,
    drop: (item) => {
      handleMoveQuestion(item.question, question);
    },
  });

  drag(drop(ref));

  return (
    <div ref={ref} className="node-item-child" style={{ opacity: isDragging ? 0.5 : 1 }}>
      <span className="ordinal-number">{index + 1}</span>
      <p className="name">{question_name}</p>
      <span className="score">
        {Array.from({ length: 3 }).map((_, i) => (
          <StarFilled key={i} className={`star-icon${i < question_score ? ' active' : ''}`} />
        ))}
      </span>
    </div>
  );
};
