import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';

import CompleteStyled from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  onCancle: () => void | Promise<void>;
  handleSubmit: () => void | Promise<void>;
}

const RegistrationComplete: React.FC<Props> = ({ visible, onCancle, handleSubmit }) => {
  return (
    <Modal
      title={
        <div>
          <CheckCircleOutlined style={{ color: '#08A3A5', fontSize: 25 }} />
          <CompleteStyled className="title-complete">登録が完了しました</CompleteStyled>
        </div>
      }
      okButton={{
        text: 'OK',
        onClick: () => handleSubmit(),
      }}
      headerStyle={{
        paddingTop: 40,
      }}
      bodyStyle={{
        padding: 15,
      }}
      visible={visible}
      onCancel={onCancle}
    />
  );
};
export default RegistrationComplete;
