import React from 'react';

import CurriculumDescriptionStyles from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  onSubmit?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  item_text?: string;
  text_explanation?: string;
}

const CurriculumDescription: React.FC<Props> = ({
  visible,
  onSubmit,
  setVisible,
  item_text,
  text_explanation,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  return (
    <Modal
      width={860}
      title="カリキュラム説明"
      open={visible}
      cancelButton={{
        text: '閉じる',
        onClick: handleToggleModal,
      }}
      onOk={onSubmit}
      onCancel={handleToggleModal}
      headerStyle={{ borderBottom: '1px solid #CCCCCC ' }}
      bodyStyle={{ background: '#F9F8F8', padding: '40px 40px 20px' }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <CurriculumDescriptionStyles>
        <div className="item-name">
          <p className="label-name">カリキュラム名：</p>
          <p className="item-text">{item_text}</p>
        </div>
        <div className="explanation">
          <p className="label-name">説明：</p>
          <p className="text-explanation">{text_explanation}</p>
        </div>
      </CurriculumDescriptionStyles>
    </Modal>
  );
};

export default CurriculumDescription;
