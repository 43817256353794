import { ConfigType, DataStoreType } from 'types/config';

export const config: ConfigType = {
  API_URL: process.env.REACT_APP_API_URL ?? '',
  TEMPLATES_ID: process.env.REACT_APP_TEMPLATE_ID ?? '',
  CONFIRM_TEMPLATES_ID: process.env.REACT_APP_CONFIRM_TEMPLATES_ID ?? '',
  APP_ID: process.env.REACT_APP_ID ?? '',
  WORKSPACE: 'SKILL FAMILIAR',
  USER_G_ID: '61ecaeaf94d4b2fcdf59bfe9',
  TRIAL_PLAN_ID: process.env.REACT_APP_TRIAL_PLAN_ID ?? '',
  PREMIUM_PLAN_ID: process.env.REACT_APP_PREMIUM_PLAN_ID ?? '',
};

export const USERS: DataStoreType = {
  id: '655986a5baeaf8d6328c9762',
  name: 'users',
};

export const CURRICULUMS: DataStoreType = {
  id: '655986e3baeaf8d6328c9898',
  name: 'curricullums',
};

export const LEVEL_1: DataStoreType = {
  id: '655986ddbaeaf8d6328c9884',
  name: 'level1s',
};

export const LEVEL_2: DataStoreType = {
  id: '655986dabaeaf8d6328c9870',
  name: 'level2s',
};

export const LEVEL_3: DataStoreType = {
  id: '655986d6baeaf8d6328c985c',
  name: 'level3s',
};

export const LEVEL_4: DataStoreType = {
  id: '655986d2baeaf8d6328c9848',
  name: 'level4s',
};

export const QUESTIONS: DataStoreType = {
  id: '65598664baeaf8d6328c95ea',
  name: 'questions',
};

export const ATTACH: DataStoreType = {
  id: '65598674baeaf8d6328c966e',
  name: 'attach',
};

export const QUESTION_ASSIGN_LEVEL: DataStoreType = {
  id: '655986ccbaeaf8d6328c9835',
  name: 'question_assign_level',
};

export const DEPARTMENTS: DataStoreType = {
  id: '655986c5baeaf8d6328c9809',
  name: 'departments',
};

export const POSITIONS: DataStoreType = {
  id: '655986c1baeaf8d6328c97f8',
  name: 'positions',
};

export const USER_ASSIGN_REQUIRED_CURRICULUM: DataStoreType = {
  id: '6559869bbaeaf8d6328c972d',
  name: 'user_assign_required_curriculum',
};

export const ROLES: DataStoreType = {
  id: '655986babaeaf8d6328c97d2',
  name: 'roles',
};

export const PLANS: DataStoreType = {
  id: '655986c7baeaf8d6328c981a',
  name: 'plans',
};

export const AFFILIATION_LEVEL: DataStoreType = {
  id: '6559865abaeaf8d6328c95c2',
  name: 'affiliation_level',
};

export const AFFILIATION_ASSIGN_ROLE: DataStoreType = {
  id: '65598652baeaf8d6328c959d',
  name: 'affiliation_assign_role',
};

export const AFFILIATION_ASSIGN_LEVEL: DataStoreType = {
  id: '6559865fbaeaf8d6328c95d7',
  name: 'affiliation_assign_level',
};

export const CURRICULUM_HIERARCHY: DataStoreType = {
  id: '655986f9baeaf8d6328cad59',
  name: 'curriculum_hierarchy',
};

export const UNRELATED_QUESTIONS: DataStoreType = {
  id: '655986f9baeaf8d6328cad5a',
  name: 'unrelated_questions',
};

export const CURRICULUM_EXPORT: DataStoreType = {
  id: '655986f9baeaf8d6328cad5b',
  name: 'curriculum_export',
};

export const REQUIRED_CURRICULUM_USERS: DataStoreType = {
  id: '655986f9baeaf8d6328cad5c',
  name: 'required_curriculum_users',
};

export const QUESTION_SEARCH: DataStoreType = {
  id: '655986f9baeaf8d6328cad5d',
  name: 'question_search',
};

export const AFFILIATION_HIERARCHY: DataStoreType = {
  id: '655986f9baeaf8d6328cad5e',
  name: 'affiliation_hierarchy',
};

export const EMPLOYEE_USER: DataStoreType = {
  id: '655986f9baeaf8d6328cad61',
  name: 'employee_user',
};

export const SELECT_AFFILIATION_USER_PERMISSIONS: DataStoreType = {
  id: '655986fabaeaf8d6328cada3',
  name: 'select_affiliation_user_permissions',
};

export const SELECT_USERS: DataStoreType = {
  id: '655986f8baeaf8d6328cad32',
  name: 'select_users',
};

export const SELECT_QUESTION_CREATOR: DataStoreType = {
  id: '655986f8baeaf8d6328cad34',
  name: 'select_question_creator',
};

export const SELECT_CURRICULUMS: DataStoreType = {
  id: '655986f8baeaf8d6328cad35',
  name: 'select_curriculums',
};

export const SELECT_LEVEL1S: DataStoreType = {
  id: '655986f8baeaf8d6328cad37',
  name: 'select_level1s',
};

export const SELECT_LEVEL2S: DataStoreType = {
  id: '655986f8baeaf8d6328cad38',
  name: 'select_level2s',
};

export const SELECT_LEVEL3S: DataStoreType = {
  id: '655986f8baeaf8d6328cad39',
  name: 'select_level3s',
};

export const SELECT_LEVEL4S: DataStoreType = {
  id: '655986f8baeaf8d6328cad3a',
  name: 'select_level4s',
};

export const SELECT_DEPARTMENT: DataStoreType = {
  id: '655986f8baeaf8d6328cad3b',
  name: 'select_department',
};

export const SELECT_POSITION: DataStoreType = {
  id: '655986f8baeaf8d6328cad3c',
  name: 'select_position',
};

export const SELECT_ROLE: DataStoreType = {
  id: '655986f8baeaf8d6328cad3d',
  name: 'select_role',
};

export const GMO: DataStoreType = {
  id: '6559867bbaeaf8d6328c9683',
  name: 'gmo',
};

export const COMPANIES: DataStoreType = {
  id: '655986aebaeaf8d6328c9791',
  name: 'companies',
};

export const EMPLOYEE_USER_EXPORT: DataStoreType = {
  id: '655986f9baeaf8d6328cad5f',
  name: 'employee_user_export',
};

export const REQUIRED_CURRICULUM_SKILL_CHECK: DataStoreType = {
  id: '655986f9baeaf8d6328cad65',
  name: 'required_curriculum_skill_check',
};

export const MANUAL_FOLDER_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad79',
  name: 'manual_folder_list',
};

export const MANUAL_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad7a',
  name: 'manual_list',
};

export const MANUAL_FILE_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad7b',
  name: 'manual_file_list',
};

export const MANUAL_FOLDER: DataStoreType = {
  id: '6559863cbaeaf8d6328c9539',
  name: 'manual_folder',
};

export const MANUAL_FILE: DataStoreType = {
  id: '65598630baeaf8d6328c94f8',
  name: 'manual_file',
};

export const MANUAL: DataStoreType = {
  id: '65598639baeaf8d6328c9523',
  name: 'manual',
};

export const MANUAL_SECTION: DataStoreType = {
  id: '65598635baeaf8d6328c950f',
  name: 'manual_section',
};

export const MANUAL_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad7c',
  name: 'manual_detail',
};

export const SELECT_EMPLOYEE_USER: DataStoreType = {
  id: '655986f9baeaf8d6328cad47',
  name: 'select_employee_user',
};

export const SELECT_REQUIRED_CURRICULUM: DataStoreType = {
  id: '655986f8baeaf8d6328cad3e',
  name: 'select_required_curriculum',
};

export const SELECT_REQUIRED_CURRICULUM_CREATOR: DataStoreType = {
  id: '655986f8baeaf8d6328cad3f',
  name: 'select_required_curriculum_creator',
};

export const SELECT_SKILL_CHECK: DataStoreType = {
  id: '655986f9baeaf8d6328cad40',
  name: 'select_skill_check',
};

export const SELECT_SKILL_CHECK_START_PERIOD: DataStoreType = {
  id: '655986f9baeaf8d6328cad41',
  name: 'select_skill_check_start_period',
};

export const SELECT_SKILL_CHECK_CATEGORY: DataStoreType = {
  id: '655986f9baeaf8d6328cad42',
  name: 'select_skill_check_category',
};

export const SELECT_SKILL_CHECK_GROUPING_CODE: DataStoreType = {
  id: '655986f9baeaf8d6328cad43',
  name: 'select_skill_check_grouping_code',
};

export const SELECT_SKILL_CHECK_USE_STATUS: DataStoreType = {
  id: '655986f9baeaf8d6328cad44',
  name: 'select_skill_check_use_status',
};

export const SELECT_SKILL_CHECK_CREATOR: DataStoreType = {
  id: '655986f9baeaf8d6328cad45',
  name: 'select_skill_check_creator',
};

export const SKILL_CHECK: DataStoreType = {
  id: '65598690baeaf8d6328c96f7',
  name: 'skill_check',
};

export const USER_ASSIGN_SKILL_CHECK: DataStoreType = {
  id: '65598696baeaf8d6328c9719',
  name: 'user_assign_skill_check',
};

export const EMPLOYEE_USER_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad63',
  name: 'employee_user_detail',
};

export const GROUPS: DataStoreType = {
  id: '6559868abaeaf8d6328c96d6',
  name: 'groups',
};

export const INTERVIEW_USER: DataStoreType = {
  id: '655986f9baeaf8d6328cad62',
  name: 'interview_user',
};

export const INTERVIEW_USER_EXPORT: DataStoreType = {
  id: '655986f9baeaf8d6328cad60',
  name: 'interview_user_export',
};

export const SKILL_CHECK_HIERARCHY: DataStoreType = {
  id: '655986f9baeaf8d6328cad67',
  name: 'skill_check_hierarchy',
};

export const SELECT_TYPES: DataStoreType = {
  id: '65598646baeaf8d6328c9578',
  name: 'select_types',
};

export const SKILL_CHECK_ASSIGN_QUESTION: DataStoreType = {
  id: '6559864abaeaf8d6328c9589',
  name: 'skill_check_assign_question',
};

export const INTERVIEW_USER_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad64',
  name: 'interview_user_detail',
};

export const SKILL_CHECK_USERS: DataStoreType = {
  id: '655986f9baeaf8d6328cad68',
  name: 'skill_check_users',
};

export const INTERVIEW_SKILL_CHECK: DataStoreType = {
  id: '655986f9baeaf8d6328cad66',
  name: 'interview_skill_check',
};

export const SELECT_INTERVIEW_USER: DataStoreType = {
  id: '655986f9baeaf8d6328cad48',
  name: 'select_interview_user',
};

export const SELECT_HIRE_DATE: DataStoreType = {
  id: '655986f9baeaf8d6328cad49',
  name: 'select_hire_date',
};

export const SELECT_CURRICULUM_CREATOR: DataStoreType = {
  id: '655986f8baeaf8d6328cad36',
  name: 'select_curriculum_creator',
};

export const SELECT_QUESTION: DataStoreType = {
  id: '655986f8baeaf8d6328cad33',
  name: 'select_question',
};

export const SKILL_CHECK_IMPLE: DataStoreType = {
  id: '655986f9baeaf8d6328cad6a',
  name: 'skill_check_imple',
};

export const SKILL_CHECK_TRANS2: DataStoreType = {
  id: '65598615baeaf8d6328c94bf',
  name: 'skill_check_trans2',
};

export const SKILL_CHECK_QUESTION: DataStoreType = {
  id: '655986f9baeaf8d6328cad69',
  name: 'skill_check_question',
};

export const CURRICULUM_HIERARCHY_TRAN: DataStoreType = {
  id: '655986f9baeaf8d6328cad6e',
  name: 'curriculum_hierarchy_tran',
};

export const CURRICULUM_PROVIDER_INFOMATION: DataStoreType = {
  id: '655985e4baeaf8d6328c9399',
  name: 'provider_infomation',
};

export const CURRICULUM_TRANS: DataStoreType = {
  id: '65598683baeaf8d6328c96ba',
  name: 'curriculum_trans',
};

export const QUESTION_TRANS2: DataStoreType = {
  id: '6559861bbaeaf8d6328c94dc',
  name: 'question_trans2',
};

export const QUESTION_TRANS: DataStoreType = {
  id: '65598640baeaf8d6328c954c',
  name: 'question_trans',
};

export const PROFILE: DataStoreType = {
  id: '655986f9baeaf8d6328cad7f',
  name: 'profile',
};

export const SKILL_CHECK_IMPLE_STATUS: DataStoreType = {
  id: '655986f9baeaf8d6328cad6b',
  name: 'skill_check_imple_status',
};

export const SELECT_ANALYSIS_GROUP: DataStoreType = {
  id: '655986f9baeaf8d6328cad4a',
  name: 'select_analysis_group',
};

export const SELECT_SKILL_CHECK_QUESTION: DataStoreType = {
  id: '655986f9baeaf8d6328cad46',
  name: 'select_skill_check_question',
};

export const SELECT_CURRICULUM_QUESTION: DataStoreType = {
  id: '655986f9baeaf8d6328cad4b',
  name: 'select_curriculum_question',
};

export const REPORT_CURRICULUM_USER: DataStoreType = {
  id: '655986f9baeaf8d6328cad70',
  name: 'report_curriculum_user',
};

export const REPORT_SKILL_CHECK_USER_TRANS: DataStoreType = {
  id: '655986f9baeaf8d6328cad75',
  name: 'report_skill_check_user_trans',
};

export const DETAIL_OF_SKILL_CHECK_RESULTS: DataStoreType = {
  id: '655986f9baeaf8d6328cad85',
  name: 'detail_of_skill_check_results',
};

export const DETAIL_OF_CURRICULUM_RESULTS: DataStoreType = {
  id: '655986f9baeaf8d6328cad86',
  name: 'detail_of_curriculum_results',
};

export const MANUAL_FOLDER_PERMISSIONS: DataStoreType = {
  id: '6559860dbaeaf8d6328c948a',
  name: 'manual_folder_permissions',
};

export const USAGE_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad83',
  name: 'usage_list',
};

export const USER_FEE_DETAIL: DataStoreType = {
  id: '65d2f9535167c09517a52fc0',
  name: 'user_fee_details',
};

export const SKILL_CHECK_FEE_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad87',
  name: 'skill_check_fee_details',
};

export const PAYMENT_STATUS: DataStoreType = {
  id: '655986f9baeaf8d6328cad8b',
  name: 'payment_status',
};

export const OFFICIAL_CURRICULUM_LIST2: DataStoreType = {
  id: '655986f9baeaf8d6328cad81',
  name: 'official_curriculum_list2',
};

export const SELECT_OFFICIAL_CURRICULUM_NAME: DataStoreType = {
  id: '655986f9baeaf8d6328cad4c',
  name: 'select_official_curriculum_name',
};

export const OFFICIAL_CURRICULUM_HIERARCHY_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad82',
  name: 'official_curriculum_hierarchy_list',
};

export const OFFICIAL_CURRICULUM_MASTER: DataStoreType = {
  id: '655985fabaeaf8d6328c9418',
  name: 'official_curriculum_master',
};

export const SELECT_OFFICIAL_CURRICULUM_PROVIDER: DataStoreType = {
  id: '655986f9baeaf8d6328cad4e',
  name: 'select_official_curriculum_provider',
};

export const RELEASE_NOTE_MANAGEMENT: DataStoreType = {
  id: '655986f9baeaf8d6328cad8d',
  name: 'release_note_management',
};

export const RELEASE_NOTE_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad8e',
  name: 'release_note_detail',
};

export const REPORT_SKILL_CHECK: DataStoreType = {
  id: '655986f9baeaf8d6328cad74',
  name: 'report_skill_check',
};

export const KNOWLEDGE_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad92',
  name: 'knowledge_list',
};

export const RANKING_LIKES_KNOWLEDGE_ALL: DataStoreType = {
  id: '655986f9baeaf8d6328cad94',
  name: 'ranking_likes_knowledge_all',
};

export const RANKING_LIKES_KNOWLEDGE_MONTH: DataStoreType = {
  id: '655986f9baeaf8d6328cad95',
  name: 'ranking_likes_knowledge_month',
};

export const RANKING_LIKES_USER_ALL: DataStoreType = {
  id: '655986f9baeaf8d6328cad93',
  name: 'ranking_likes_user_all',
};

export const RANKING_LIKES_USER_MONTH: DataStoreType = {
  id: '655986f9baeaf8d6328cad96',
  name: 'ranking_likes_user_month',
};

export const FAVORITE_KNOWLEDGE: DataStoreType = {
  id: '655985cdbaeaf8d6328c9319',
  name: 'favorite_knowledge',
};

export const KNOWLEDGE_LIKE_HISTORY: DataStoreType = {
  id: '655985cbbaeaf8d6328c930c',
  name: 'knowledge_like_history',
};

export const KNOWLEDGE: DataStoreType = {
  id: '655985debaeaf8d6328c937c',
  name: 'knowledge',
};

export const KNOWLEDGE_TO: DataStoreType = {
  id: '655985d0baeaf8d6328c9327',
  name: 'knowledge_to',
};

export const KNOWLEDGE_QA_DETAIL: DataStoreType = {
  id: '655986f9baeaf8d6328cad97',
  name: 'knowledge_qa_detail',
};

export const KNOWLEDGE_QA_ADDITIONAL_QUESTION = {
  id: '655985d7baeaf8d6328c9354',
  name: 'knowledge_qa_additional_question',
};

export const KNOWLEDGE_QA_ADDITIONAL_QUESTION_ANSWER = {
  id: '655985d4baeaf8d6328c933e',
  name: 'knowledge_qa_additional_question_answer',
};

export const KNOWLEDGE_LIKES_HISTORY = {
  id: '655986fabaeaf8d6328cad9c',
  name: 'knowledge_likes_history',
};

export const KNOWLEDGE_RECOMMEND_DETAIL = {
  id: '655986fabaeaf8d6328cad98',
  name: 'knowledge_recommend_detail',
};

export const KNOWLEDGE_RE_QUESTION_ANSWER = {
  id: '655985c2baeaf8d6328c92d7',
  name: 'knowledge_re_question_answer',
};

export const KNOWLEDGE_RE_QUESTION = {
  id: '655985c7baeaf8d6328c92ed',
  name: 'knowledge_re_question',
};

export const SELECT_DEPARTMENT_USER: DataStoreType = {
  id: '655986fabaeaf8d6328cada0',
  name: 'Select_department_user',
};

export const KNOWLEDGE_QA_ANSWERS: DataStoreType = {
  id: '655985dbbaeaf8d6328c9369',
  name: 'knowledge_qa_answers',
};

export const REPORT_CURRICULUM: DataStoreType = {
  id: '655986f9baeaf8d6328cad71',
  name: 'report_curriculum',
};

export const KNOWLEDGE_FAVORITE_HISTORY: DataStoreType = {
  id: '655986fabaeaf8d6328cad9b',
  name: 'knowledge_favorite_history',
};

export const TOP_CURRICULUM_ACHIEVED_STATUS: DataStoreType = {
  id: '655986f9baeaf8d6328cad91',
  name: 'top_curriculum_achieved_status',
};

export const TOP_SKILL_CHECK_IMPLEMENTATION_STATUS: DataStoreType = {
  id: '655986f9baeaf8d6328cad90',
  name: 'top_skill_check_implementation_status',
};

export const KNOWLEDGE_CURRICULUM_DETAIL: DataStoreType = {
  id: '655986fabaeaf8d6328cad9a',
  name: 'knowledge_curriculum_detail',
};

export const CURRICULUM_GRAPH: DataStoreType = {
  id: '655986f9baeaf8d6328cad6d',
  name: 'curriculum_graph',
};

export const SKILL_CHECK_GRAPH: DataStoreType = {
  id: '655986f9baeaf8d6328cad6c',
  name: 'skill_check_graph',
};

export const DIRECTED_KNOWLEDGE: DataStoreType = {
  id: '655986fabaeaf8d6328cada2',
  name: 'directed_knowledge',
};

export const MAIN_AFFILIATION_LIST: DataStoreType = {
  id: '655986fabaeaf8d6328cadaf',
  name: 'main_affiliation_list',
};

export const CURRICULUM_IMPLE: DataStoreType = {
  id: '655986fabaeaf8d6328cadbb',
  name: 'curriculum_imple',
};

export const CURRICULUM_IMPLEMENTATION_STATUS: DataStoreType = {
  id: '655986fabaeaf8d6328cadc3',
  name: 'curriculum_implementation_status',
};

export const CURRICULUM_HIERARCHY_TRANS_ANSWER_STATUS2: DataStoreType = {
  id: '655986fabaeaf8d6328cadca',
  name: 'curriculum_hierarchy_trans_answer_status2',
};

export const REQUIRED_CURRICULUM_HIERARCHY_TRANS: DataStoreType = {
  id: '655986fabaeaf8d6328cadc4',
  name: 'required_curriculum_hierarchy_trans',
};

export const COMPULSORY_CURRICULUM_IMPLEMENTATION_LIST: DataStoreType = {
  id: '655986f9baeaf8d6328cad6f',
  name: 'compulsory_curriculum_implementation_list',
};

export const OPTION_AFFILIATION: DataStoreType = {
  id: '655986f8baeaf8d6328cad3b',
  name: 'option_affiliation',
};

export const REQUIRED_CURRICULUM_SETTINGS: DataStoreType = {
  id: '655986fabaeaf8d6328cadcb',
  name: 'required_curriculum_settings',
};

export const REQUIRED_SKILLCHECK_SETTINGS: DataStoreType = {
  id: '655986fabaeaf8d6328cadcc',
  name: 'skillcheck_settings',
};

export const PAYMENT_MASTER: DataStoreType = {
  id: '655986fabaeaf8d6328cadc8',
  name: 'payment_master',
};

export const CARD_COMPANY_NAME: DataStoreType = {
  id: '6559854bbaeaf8d6328c910a',
  name: 'card_company_name',
};

export const KNOWLEDGE_RECOMMEND_DETAIL_ORIGINAL: DataStoreType = {
  id: '655986fabaeaf8d6328cadd0',
  name: 'knowledge_recommend_detail_original',
};

export const KNOWLEDGE_RECOMMEND_DETAIL_ADDITIONAL: DataStoreType = {
  id: '655986fabaeaf8d6328cadd2',
  name: 'knowledge_recommend_detail_addtional',
};

export const KNOWLEDGE_QA_DETAIL_ORIGINAL: DataStoreType = {
  id: '655986fabaeaf8d6328cadd3',
  name: 'knowledge_qa_detail_original',
};

export const KNOWLEDGE_QA_DETAIL_ADDITIONAL: DataStoreType = {
  id: '655986fabaeaf8d6328cadd1',
  name: 'knowledge_qa_detail_addtional',
};

export const PROFILE_CURRICULUMS: DataStoreType = {
  id: '655986fabaeaf8d6328cadd9',
  name: 'profile_curriculums',
};

export const OPTIONS_SKILL_CHECK_IMPLEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328caddb',
  name: 'options_skill_check_implements',
};

export const OPTIONS_SKILL_CHECK_ANALYSIS_GROUP_IMPLEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328caddc',
  name: 'options_skill_check_analysis_group_implemented',
};

export const STORAGE_MANAGEMENT: DataStoreType = {
  id: '655986fabaeaf8d6328cadde',
  name: 'storage_management',
};

export const REQUIRED_CURRICULUM_IMPLE3: DataStoreType = {
  id: '655986f9baeaf8d6328cad7d',
  name: 'required_curriculum_imple3',
};

export const RELEASE_NOTE: DataStoreType = {
  id: '65598605baeaf8d6328c945c',
  name: 'release_note',
};

export const CURRICULUM_PUBLISHED_HISTORY: DataStoreType = {
  id: '65598602baeaf8d6328c9448',
  name: 'curriculum_published_history',
};
