import { createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { filter, find, map, some, uniqBy } from 'lodash';

import {
  config,
  USERS,
  COMPANIES,
  AFFILIATION_ASSIGN_ROLE,
  AFFILIATION_LEVEL,
  ATTACH,
} from 'configs';
import { findAffiliations } from 'libs/utils/affiliation/tree-data-utils';
import { extractFileName, getFileFromUrl } from 'libs/utils/format';
import { convertFileResponse } from 'libs/utils/question';
import { sharedFileInMinIO } from 'services/minioService';
import { services, userService } from 'services';
import SetCookie from 'hooks/useSetCookie';
import * as Types from 'types';

export const signIn = createAsyncThunk<
  Types.SignInRes,
  Types.SignInReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signIn', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signIn(req);
    localStorage.setItem('sk_access_token', data.token);

    const cookieName = 'auth-token';
    const currentCookie = Cookies.get(cookieName);

    if (!currentCookie || currentCookie !== data.token) {
      SetCookie(cookieName, data.token);
    }

    return { ...data, signInUrl: req.user_code };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signUp = createAsyncThunk<
  Types.SignUpRes,
  Types.SignUpReq,
  Types.ThunkAPI<Types.requestError>
>('auth/signUp', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.signUp(req);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const signUpUser = createAsyncThunk<
  Types.SignUpUserResponse,
  Types.SignUpUserRequest & { screen?: 'register-trial' | 'register-premium' },
  Types.ThunkAPI<Types.requestError>
>('auth/signUpUser', async (req, { rejectWithValue }) => {
  try {
    if (req.screen) {
      const planId =
        req.screen === 'register-trial' ? config.TRIAL_PLAN_ID : config.PREMIUM_PLAN_ID;
      const companyData = {
        workspace_id: 'skillfamiliar',
        url: '/api/v0/applications/skillfamiliar/datastores/companies/items/new',
        method: 'POST',
        params: {
          item: {
            admin_email: req.params.item.name,
            plan_id: planId,
          },
          realtime_auto_link: true,
          return_display_id: true,
          access_key_updates: {
            roles_to_publish: ['ADMIN', 'MEMBER'],
          },
        },
      };

      const { data: dataCreateCompany } = await userService.postUnauthorizedCall(companyData);
      if (dataCreateCompany) {
        const { data, status } = await userService.signUpUser(req);
        if (status !== 200) {
          throw new Error('Unexpected status code');
        }

        const dataInviteUser: any = {
          workspace_id: 'skillfamiliar',
          url: `/api/v0/applications/skillfamiliar/datastores/signup/items/action/${data.item_id}/userinvite`,
          method: 'POST',
          params: {
            item: req.params.item,
            as_params: {
              users: [
                {
                  email: req.params.item.name,
                  user_code: req.params.item.name,
                  exclusive_w_id: '655983b2942ff9ef0f4a0081',
                },
              ],
              email_templates_id: config.TEMPLATES_ID,
              domain: 'stg-rsweb.hexabase.com',
              invitation_path: 'confirm_email',
            },
            is_force_update: true,
          },
        };

        await userService.inviteUser(dataInviteUser);

        return data;
      }
    }

    const { data, status } = await userService.signUpUser(req);
    if (status !== 200) {
      throw new Error('Unexpected status code');
    }

    const dataInviteUser: any = {
      workspace_id: 'skillfamiliar',
      url: `/api/v0/applications/skillfamiliar/datastores/signup/items/action/${data.item_id}/userinvite`,
      method: 'POST',
      params: {
        item: req.params.item,
        as_params: {
          users: [
            {
              email: req.params.item.name,
              user_code: req.params.item.name,
              exclusive_w_id: '655983b2942ff9ef0f4a0081',
            },
          ],
          email_templates_id: config.TEMPLATES_ID,
          domain: 'stg-rsweb.hexabase.com',
          invitation_path: 'confirm_email',
        },
        is_force_update: true,
      },
    };
    await userService.inviteUser(dataInviteUser);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const resetPassword = createAsyncThunk<
  Types.ResetPasswordRes,
  Types.ResetPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/resetPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.resetPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const isFirstLogin = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/isFirstLogin', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = await sharedFileInMinIO(data.items[0]?.icon_fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

      data.items[0].avatar = convertFileResponse({
        file: fileFromUrl,
        fileID: data.items[0].icon_fileID,
        fileName: fileName,
      });
    }

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const setNewPassword = createAsyncThunk<
  undefined,
  Types.SetNewPasswordReq,
  Types.ThunkAPI<Types.requestError>
>('auth/setNewPassword', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.setNewPassword(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getConfirmRegistration = createAsyncThunk<
  Types.GetConfirmRegistrationRes,
  Types.GetConfirmIdReq,
  Types.ThunkAPI<Types.requestError>
>('auth/getConfirmRegistration', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.confirmRegistration(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const updateLoginTime = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/updateLoginTime', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getUserDetail = createAsyncThunk<
  Types.GetItemDetailResponseType,
  Types.GetItemDetailRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/getUserDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.detail(USERS.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const changePassword = createAsyncThunk<Types.ChangePasswordRes, Types.ChangePasswordReq>(
  'auth/thunk/changePassword',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await userService.changePassword(req);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const changeEmail = createAsyncThunk<
  Types.ChangeEmailRes,
  Types.ChangeEmailReq,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/changeEmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.changeEmail(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfo = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getUserInfo', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    if (data.items[0]?.icon_fileID) {
      const fileName = extractFileName(data.items[0]?.icon_fileID);
      const nodeFileUrl = await sharedFileInMinIO(data.items[0]?.icon_fileID);
      const fileFromUrl = await getFileFromUrl(nodeFileUrl, fileName);

      data.items[0].avatar = convertFileResponse({
        file: fileFromUrl,
        fileID: data.items[0].icon_fileID,
        fileName: fileName,
      });
    }

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfo2 = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getUserInfo2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const postUnauthorizedCall = createAsyncThunk<
  Types.UnauthorizedCallResponse,
  Types.UnauthorizedCallRequest,
  Types.ThunkAPI<Types.requestError>
>('auth/postUnauthorizedCall', async (req, { rejectWithValue }) => {
  try {
    const { data } = await userService.postUnauthorizedCall(req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getDataCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataUserCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getDataUserCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCompany = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Companies.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/updateCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(COMPANIES.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAffiliationLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI
>('auth/thunk/getAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationLevel.ResponseType>(
      AFFILIATION_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignRole = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getAffiliationAssignRole', async (req, { rejectWithValue, dispatch, getState }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    const { authContainer } = getState() as Types.RootState;

    const result = await Promise.all([
      dispatch(getAffiliationLevel(req)),
      dispatch(getUserInfo2(req)),
    ]);

    const usersFound: Types.Users.ResponseType[] = [];

    if (
      getAffiliationLevel.fulfilled.match(result[0]) &&
      getUserInfo2.fulfilled.match(result[1]) &&
      data.items.length &&
      authContainer.userInfo?.login_id
    ) {
      const dataAffiliations = result[0].payload.items;
      const dataUsers = result[1].payload.items;

      const affAssignUsers = filter(data.items, { login_id: authContainer.userInfo?.login_id });

      let affiliations: Types.AffiliationLevel.ResponseType[] = [];
      if (affAssignUsers.length) {
        affAssignUsers.map((aff) => {
          if (aff.item_links?.links?.length) {
            const affLevel = map(
              aff?.item_links.links.find((link) => link.d_id === AFFILIATION_LEVEL.id)?.items || [],
              (levelId) => find(dataAffiliations, { i_id: levelId.i_id }) || {}
            ) as Types.AffiliationLevel.ResponseType[];

            if (affLevel.length) {
              affLevel.map((level) => {
                const affiliationsFound = findAffiliations(dataAffiliations, level.affiliation_id);

                affiliations = [...affiliations, level, ...affiliationsFound];
              });
            }
          }
        });
      }

      uniqBy(affiliations, 'affiliation_id').map((aff) => {
        for (const item of data.items) {
          if (some(item.item_links?.links, (link) => some(link.items, { i_id: aff.i_id }))) {
            if (!find(usersFound, { login_id: item.login_id })) {
              const user = find(dataUsers, { login_id: item.login_id });
              user && usersFound.push(user);
            }
          }
        }
      });
    }

    return { ...data, items: usersFound };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTotalFileSize = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getTotalFileSize', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
