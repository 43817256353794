import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.div<Props>`
  .ant-table-column-sorter {
    display: none;
  }
  .label-select {
    font-size: 11px;
    color: #424242;
  }
  .ant-select-selector {
    border-radius: 4px;
  }
  .ant-table-pagination {
    transform: translateY(-40px);
  }
  .ant-pagination-total-text {
    bottom: -50px;
    margin-right: 0;
    color: #424242;
    left: 13% !important;
  }
  .ant-table-pagination-right {
    position: absolute !important;
    width: fit-content;
    left: '0' !important;
    right: 0 !important;
    top: -63px;
  }
  .header {
    .wrap-header {
      width: 70%;
      display: flex;
      justify-content: flex-end;
      .item-storage {
        display: flex;
        align-items: center;
        .btn-storage {
          display: flex;
          justify-content: center;
          height: 35px;
          align-items: center;
          font-size: 13px;
          padding: 0 10px;
          background-color: #f6ac00;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-weight: 700;
          color: #ffffff;
          cursor: pointer;
          .icon-storage {
            width: 18px;
            height: 18px;
            margin-right: 8px;
          }
        }
        .select-time-limit {
          display: flex;
          margin-left: 20px;
          height: 35px;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          border-radius: 2px;
        }
        .count {
          padding-left: 20px;
          .text-page {
            padding: 0 5px;
          }
        }
      }
    }
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      .icon {
        font-size: 16px;
      }
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
  }
  .container {
    padding: 0 35px;
    padding-bottom: 95px;
    .g2-html-annotation {
      margin-top: -34px;
    }
    .table-chart {
      display: flex;
      padding: 15px 0;
      .item-left {
        width: 20%;
        margin-right: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-right {
        width: 80%;
        align-self: center;
        .table {
          border-radius: 3px;
          border: 1px solid #08a3a5;
          border-collapse: collapse;
          .ant-table-tbody .ant-table-cell {
            font-family: Lato;
            font-size: 14px;
            text-align: right;
            color: #424242;
            border-left: 1px solid rgba(85, 85, 85, 0.18);
          }
          .ant-table-tbody tr td:first-child {
            border-left: none;
          }
          .ant-table-tbody .ant-table-cell.storage-breakdown {
            text-align: left;
          }
          .ant-table-tbody .ant-table-cell {
            padding: 5px 5px;
          }

          .ant-table-thead .ant-table-cell {
            font-weight: 700;
            font-size: 12px;
            color: #ffffff;
            text-align: center;
            background-color: #08a3a5 !important;
            padding: 10px 10px;
            ::before {
              width: 1px !important;
              height: 100% !important;
              background-color: #ffffff !important;
            }
          }
          .small {
            color: #999999;
            font-size: 10px;
            margin-left: 3px;
          }
        }
      }
    }
    .wrap-select {
      display: flex;
      justify-content: space-between;
      position: relative;
      .item-select {
        width: 80%;
        .form-search {
          display: flex;
          align-items: center;
          padding: 25px 0 0 0;
          .item {
            width: 25%;
            margin-right: 8px;
          }
          .item-file-name {
            width: 50%;
            margin-right: 8px;
          }
          .label-reset {
            font-size: 12px;
            cursor: pointer;
            text-decoration-line: underline;
            color: #00a3a5;
            margin-right: 12px;
            display: block;
            width: 60px;
            margin-left: 10px;
          }
          .btn-search {
            background: #ffffff;
            border: 1px solid #00a3a5;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            font-family: 'Noto Sans Javanese';
            font-size: 14px;
            color: #00a3a5;
            cursor: pointer;
            .icon-search {
              font-size: 18px;
              margin-right: 8px;
            }
          }
          .text-label {
            color: #424242;
            font-family: Lato;
            font-size: 12px;
          }
        }
      }
      .text-total-record-table {
        bottom: 10px;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .item-tab {
      width: 80%;
      position: relative;
      z-index: 1;
      .tab-container {
        .ant-tabs-nav {
          margin: 0;
          &::before {
            border: none;
          }
        }
        .ant-tabs-tab-active .ant-tabs-tab-btn {
          color: #ffffff;
        }
        .ant-tabs-tab-btn {
          font-size: 12px;
        }
        .ant-tabs-tab {
          height: 30px;
          padding: 0 30px;
          margin: 0;
          &:hover {
            color: #2a2a2a;
          }
        }
        .ant-tabs-ink-bar {
          display: none;
        }
        .ant-tabs-tab {
          background: #ebebeb;
          border-right: 1px solid #f9f8f8;
        }
        .ant-tabs-tab-active {
          background: #888;
        }
      }
    }
    .wrap-table {
      .table-file {
        .ant-table .ant-table-container::before {
          content: 'ALL';
          margin-left: 7px;
          margin-top: 9px;
          font-size: 11px;
        }
        .ant-table-thead .ant-checkbox-wrapper {
          padding-top: 10px;
        }
        .ant-table-thead {
          border-radius: 2px 2px 0 0;
          .ant-table-cell {
            background-color: #ffffff;
            font-weight: 700;
            font-size: 12px;
            color: #2a2a2a;
            margin: 0 3px;
            border-top: 1px solid #888888;
            ::before {
              width: 1px !important;
              height: 100% !important;
              background-color: #eaeaea !important;
            }
          }
          .item-sort {
            display: flex;
            justify-content: space-between;
            .icon {
              color: #999999;
              cursor: pointer;
            }
          }
        }
        .ant-table-tbody {
          .ant-table-cell {
            cursor: pointer;
            font-size: 13px;
            color: #777777;
            border-bottom: 1px solid #eaeaea;
            word-break: break-word;
          }

          .icon-folder {
            color: #287dbb;
            font-size: 16px;
          }
          .icon-excel {
            color: #107c41;
            font-size: 16px;
          }
          .icon-word {
            color: #185abd;
            font-size: 16px;
          }
          .icon-ppt {
            color: #c43f1b;
            font-size: 16px;
          }
          .icon-pdf {
            color: #b30b00;
            font-size: 16px;
          }
          .icon-image {
            color: #cc9f00;
            font-size: 16px;
          }
          .icon-play {
            color: #5c5b61;
            font-size: 16px;
          }
          .icon-file {
            color: #777777;
            font-size: 16px;
          }
          .icon-logo {
            width: 16px;
            height: 16px;
          }
          .item-row {
            display: flex;
            align-items: center;
            .item-name {
              .label-name {
                padding-left: 10px;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
`;
