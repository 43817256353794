import React from 'react';

import TableBySkillCheck from './TableBySkillCheck';
import TableByUser from './TableByUser';

interface Props {
  isQuestionCodeTable: boolean;
  exportPdf?: boolean;
}

export const SurfaceTable: React.FC<Props> = ({ isQuestionCodeTable, exportPdf }) => {
  return isQuestionCodeTable ? (
    <TableByUser exportPdf={exportPdf} />
  ) : (
    <TableBySkillCheck exportPdf={exportPdf} />
  );
};

export default SurfaceTable;
