import React from 'react';
import { Card, Col, Dropdown, Menu, Row } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { find, get } from 'lodash';

import { HomeCardColour } from 'constant/enum.constant';
import { DataDashboardCardType } from '../slice';
import ProgressCircle from './ProgressCircle';
import ProgressChart from './ProgressChart';
import ProgressBar from './ProgressBar';
import { UserType } from 'types/config';
import { filterDataType } from '..';
import { Wrapper } from './styles';

interface HomeDetailCardProps {
  title: string;
  role?: UserType;
  footerText: string;
  menuItems: { name: string; code: string }[];
  colour: HomeCardColour;
  data: DataDashboardCardType;
  filterData: filterDataType | undefined;
  setFilterData: React.Dispatch<React.SetStateAction<filterDataType | undefined>>;
}

const HomeDetailCard: React.FC<HomeDetailCardProps> = ({
  role,
  data,
  title,
  colour,
  menuItems,
  footerText,
  filterData,
  setFilterData,
}) => {
  const menu = (
    <Menu
      items={menuItems.map((label, index) => ({
        key: index,
        label: label.name,
        onClick: () => {
          setFilterData((prev) => ({ ...prev, [colour]: label.code }));
        },
      }))}
    />
  );

  return (
    <Wrapper cardColour={colour}>
      <Card
        title={
          <div className="header-card">
            <span className="title">{title}</span>
            {role === 'admin' && <span className="sub-title-admin">（実施者平均）</span>}
          </div>
        }
        bordered={false}
      >
        <div className={`${colour === HomeCardColour.purple ? 'content-purple' : 'content'}`}>
          <Row>
            <Dropdown overlay={menu} className="dropdown" trigger={['click']}>
              <div>
                <label className="drop-down-label">
                  {filterData && get(find(menuItems, { code: filterData[colour] }), 'name', null)}
                </label>
                <CaretDownOutlined className="drop-down-button" />
              </div>
            </Dropdown>
            <div className="shadow" />
          </Row>
          <ProgressCircle
            color={colour}
            data={data}
            role={role}
            valueSelect={
              filterData && get(find(menuItems, { code: filterData[colour] }), 'name', null)
            }
          />
          <ProgressBar color={colour} data={data} role={role} />
        </div>
        <div className="footer">
          <ProgressChart color={colour} data={data} />
          <Row className="footer-text">
            <Col>
              {footerText} <br /> {colour === HomeCardColour.purple ? '（同分析グループ）' : ''}
            </Col>
          </Row>
        </div>
      </Card>
    </Wrapper>
  );
};

export default HomeDetailCard;
