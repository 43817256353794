import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker, Form, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import isBetween from 'dayjs/plugin/isBetween';
import { useSelector } from 'react-redux';
import { Collapse, Select } from 'antd';
import dayjs from 'dayjs';
import {
  CalendarOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  CopyOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FileTextOutlined,
  MessageOutlined,
  RightOutlined,
  SearchOutlined,
  UpOutlined,
} from '@ant-design/icons';

import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { filterSkillImpleBySetting, filterSkillImpleByStatus } from './slice';
import { IconImpossible, IconPossible, NoImageCurriculum } from 'assets';
import { settingSelector } from 'containers/AppSettings/selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { sharedFileInMinIO } from 'services/minioService';
import { skillCheckSelectionSelector } from './selectors';
import { authSelector } from 'containers/Auth/selectors';
import SkillCheckStatus from './SkillCheckStatus';
import SkillCheckSelectionStyled from './styles';
import { Header, SelectField } from 'components';
import { routes } from 'navigations/routes';
import TableSkillCheck from './Table';
import Guideline from './Guideline';
import * as Types from 'types';
import Status from './Status';
import {
  getDataSelectSkillCheckGroupingCode,
  getDataSelectSkillCheckName,
  createSkillCheckTrans,
  getSelectTypes,
  getDataListSkillCheckImple,
  editUserAssignSillCheck,
} from './thunk';

dayjs.extend(isBetween);

const { Option } = Select;
const { Panel } = Collapse;

const SkillCheckSelection: React.FC = () => {
  const [dataGuideline, setDataGuideline] = useState<Types.ListSkillCheckTypes>();
  const [files, setFiles] = useState<Array<{ file: string; i_id: string }>>([]);
  const [fileGuideline, setFileGuideline] = useState<string>();
  const [statusIndex, setStatusIndex] = useState<number>(0);

  const dispatch = useAppDispatch();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const roleCode = useMemo(() => userInfo?.lookup_items?.role_code, [userInfo]);

  const { dataSelectSkillCheckName, dataSelectGroupingCode, dataSillCheckImple } = useSelector(
    skillCheckSelectionSelector
  );

  const formik = useFormik<Types.SkillCheckSelectionFormik>({
    initialValues: {
      skill_check_name: '',
      grouping_code: '',
      start_period: '',
      implementation_settings: '',
      implementation_status: '',
    },
    onSubmit: async (values) => {
      const conditions: Types.ConditionsType[] = [];
      Object.keys(values)
        .filter((e) => e !== 'implementation_settings' && e !== 'implementation_status')
        .forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [values[key as keyof typeof values]],
              exact_match: true,
            });
          }
        });

      await fetchDataSkillCheckImple(conditions);
      if (values.implementation_settings) {
        dispatch(filterSkillImpleBySetting(values.implementation_settings));
      }
      if (values.implementation_status) {
        dispatch(filterSkillImpleByStatus(values.implementation_status));
      }
    },
    onReset: () => {
      fetchDataSkillCheckImple();
    },
  });

  const fetchDataSkillCheckImple = async (conditions?: Array<Types.ConditionsType>) => {
    dispatch(startLoading());

    await dispatch(
      getDataListSkillCheckImple({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo?.login_id],
            exact_match: true,
          },
          ...(conditions ?? []),
        ],
        sort_fields: [
          {
            id: 'skill_check_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  const handleAddItem = async () => {
    dispatch(startLoading());
    const resultAction = await Promise.all([
      dispatch(
        createSkillCheckTrans({
          item: {
            login_id: dataGuideline?.login_id,
            company_id: userInfo?.company_id,
            skill_check_code: dataGuideline?.skill_check_code,
            responses_num: 0,
            correct_answers_num: 0,
            last_action: '0',
            tran_status: 1,
            acquisition_score: 0,
            implementation_date: new Date(),
            createdat: new Date(),
            createdby: userInfo?.login_id,
          },
          return_display_id: true,
          return_item_result: true,
        })
      ),
      dataGuideline?.i_id
        ? dispatch(
            editUserAssignSillCheck({
              id: dataGuideline?.i_id,
              data: {
                item: {
                  hidden: 'on',
                },
                return_item_result: true,
                is_force_update: true,
              },
            })
          )
        : null,
    ]);
    if (createSkillCheckTrans.fulfilled.match(resultAction[0])) {
      setStatusIndex(2);
    }
    dispatch(stopLoading());
  };

  const handleGetFileItem = async (
    activeKey: string | string[],
    item: Types.ListSkillCheckTypes
  ) => {
    let fileSkillCheck = '';
    dispatch(startLoading());
    const checkExist = files.find((f) => f.i_id === item.i_id);
    if (item.fileID && activeKey && !checkExist) {
      fileSkillCheck = await sharedFileInMinIO(item.fileID);

      setFiles([...files, { file: fileSkillCheck, i_id: item.i_id }]);
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSelectSkillCheckName({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataSelectSkillCheckGroupingCode({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getSelectTypes({
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataListSkillCheckImple({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            sort_fields: [
              {
                id: 'skill_check_code',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    dispatch(
      setHeaderTitle(
        statusIndex === 1
          ? 'スキルチェック実施要項確認'
          : statusIndex === 4
          ? 'スキルチェック実施状況'
          : routes.SkillCheckSelection.title
      )
    );
  }, [statusIndex, dispatch]);

  return (
    <SkillCheckSelectionStyled>
      {statusIndex !== 2 && statusIndex !== 3 && <Header title={headerTitle} />}
      {statusIndex === 1 ? (
        <Guideline
          setStatusIndex={setStatusIndex}
          handleAddItem={handleAddItem}
          dataGuideline={dataGuideline}
          setDataGuideline={setDataGuideline}
          setFileGuideline={setFileGuideline}
          fileGuideline={fileGuideline}
        />
      ) : statusIndex === 2 ? (
        <SkillCheckStatus setStatusIndex={setStatusIndex} dataGuideline={dataGuideline} />
      ) : statusIndex === 3 ? (
        <Status
          dataGuideline={dataGuideline}
          setStatusIndex={setStatusIndex}
          fileGuideline={fileGuideline}
        />
      ) : statusIndex === 4 ? (
        <TableSkillCheck setStatusIndex={setStatusIndex} />
      ) : (
        <>
          <div className="skill-check-header">
            <div className="button-check">
              <p className="text-check">
                実施したいスキルチェックの実施ボタンをクリックしてください。
              </p>
              <button
                className={
                  roleCode?.skill_check_implementation_permission.toString() !== '0'
                    ? 'check-btn'
                    : 'disable-btn'
                }
                onClick={() => setStatusIndex(4)}
              >
                スキルチェック実施状況 <RightOutlined />
              </button>
            </div>
            <FormikProvider value={formik}>
              <Form layout="vertical">
                <div className="form-search">
                  <Form.Item
                    name="skill_check_name"
                    className="item"
                    label={<span className="text-label">スキルチェック名</span>}
                  >
                    <SelectField
                      name="skill_check_name"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="スキルチェック名で検索"
                    >
                      {dataSelectSkillCheckName?.map((item, index) => (
                        <Option key={index} value={item.name}>
                          {item.name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="grouping_code"
                    className="item"
                    label={<span className="text-label">分析グループ</span>}
                  >
                    <SelectField
                      name="grouping_code"
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        JSON.stringify(option?.children)
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="分析グループで検索"
                    >
                      {dataSelectGroupingCode?.map((value, i) => (
                        <Option value={value.i_id} key={i}>
                          {value.grouping_code}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="date-item"
                    className="item"
                    label={<span className="text-label">実施期間</span>}
                  >
                    <DatePicker
                      allowClear
                      name="date-item"
                      className="date-input"
                      placeholder="実施期間で検索"
                      format="YYYY/MM/DD"
                    />
                  </Form.Item>
                  <Form.Item
                    name="
                implementation_settings"
                    className="item implement"
                    label={<span className="text-label">実施ステータス</span>}
                  >
                    <SelectField name="implementation_settings" placeholder="実施ステータスで検索">
                      <Option value="1">
                        <div key="1" className="icon-edit">
                          <img src={IconPossible} className="icon" alt="possible-icon" />
                          <span>実施可</span>
                        </div>
                      </Option>
                      <Option key="2" value="2">
                        <div className="icon-edit">
                          <img src={IconImpossible} className="icon" alt="possible-icon" />
                          <span>実施不可</span>
                        </div>
                      </Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="implementation_status"
                    className="item implement"
                    label={<span className="text-label">実施状況</span>}
                  >
                    <SelectField name="implementation_status" placeholder="実施状況で検索">
                      <Option key="2" value="2">
                        <div className="icon-completed">
                          <div className="status-done  completed-icon">
                            <p className="text-status">済</p>
                          </div>
                          <p className="label-status">実施済</p>
                        </div>
                      </Option>
                      <Option key="1" value="1">
                        <div className="icon-edit">
                          <div className="status  edit-icon">
                            <p className="text-status">未</p>
                          </div>
                          <p className="label-status">未実施</p>
                        </div>
                      </Option>
                      <Option key="0" value="0">
                        <div className="icon-stop">
                          <div className="status  stop-icon">
                            <p className="text-status">未</p>
                          </div>
                          <p className="label-status">未実施（実施不可）</p>
                        </div>
                      </Option>
                    </SelectField>
                  </Form.Item>
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span className="label-reset" onClick={() => formik.resetForm()}>
                    リセット
                  </span>
                </div>
              </Form>
            </FormikProvider>
          </div>
          <div className="body">
            {dataSillCheckImple?.map((item, index) => (
              <Collapse
                key={index}
                accordion
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <DownOutlined style={{ color: '#999999' }} />
                  ) : (
                    <UpOutlined style={{ color: '#999999' }} />
                  )
                }
                // onChange={(activeKey) => {
                //   (async () => {
                //     const fileSkillCheck = {} as UploadFile<File>;
                //     dispatch(startLoading());
                //     const checkExist = files.find((f) => f.i_id === item.i_id);
                //     if (item.fileID && activeKey && !checkExist) {
                //       const resultActionGetAttach = await dispatch(
                //         getAttachQuestionFile({
                //           conditions: [
                //             {
                //               id: 'fileID',
                //               search_value: [item.fileID],
                //             },
                //           ],
                //           page: 1,
                //           per_page: 1,
                //         })
                //       );
                //       if (
                //         getAttachQuestionFile.fulfilled.match(resultActionGetAttach) &&
                //         resultActionGetAttach.payload.items[0]?.file
                //       ) {
                //         const fileAction = await dispatch(
                //           getFileAttach({ file_id: resultActionGetAttach.payload.items[0].file })
                //         );
                //         if (getFileAttach.fulfilled.match(fileAction)) {
                //           Object.assign(
                //             fileSkillCheck,
                //             convertFileResponse({
                //               file: fileAction.payload,
                //               fileID: resultActionGetAttach.payload.items[0]?.fileID,
                //               fileName: resultActionGetAttach.payload.items[0]?.filename,
                //             })
                //           );
                //         }
                //       }
                //       setFiles([...files, { file: fileSkillCheck, i_id: item.i_id }]);
                //     }
                //     dispatch(stopLoading());
                //   })();
                // }}
                onChange={(activeKey) => {
                  handleGetFileItem(activeKey, item);
                }}
              >
                <Panel
                  header={
                    <div
                      key={index}
                      className={`item ${
                        item.implementation_status === 2
                          ? ''
                          : item.implementation_settings === 2 ||
                            (item.implementation_settings === 1 &&
                              dayjs().isBetween(item?.start_period, item?.end_period))
                          ? 'hight-light'
                          : ''
                      }`}
                    >
                      <div className="edit-item">
                        {item.implementation_status === 2 ? (
                          <img src={IconImpossible} className="icon" alt="possible-icon" />
                        ) : item.implementation_settings === 2 ||
                          (item.implementation_settings === 1 &&
                            dayjs().isBetween(item?.start_period, item?.end_period)) ? (
                          <img src={IconPossible} className="icon" alt="possible-icon" />
                        ) : (
                          <img src={IconImpossible} className="icon" alt="possible-icon" />
                        )}
                        <p>{item.skill_check_name}</p>
                      </div>
                      {item.implementation_status === 2 ? (
                        <button className="btn-check">
                          <CheckCircleOutlined className="icon" /> 実施済
                        </button>
                      ) : item.implementation_settings === 2 ||
                        (item.implementation_settings === 1 &&
                          dayjs().isBetween(item?.start_period, item?.end_period)) ? (
                        <button
                          className="btn-edit"
                          onClick={(event) => {
                            event.stopPropagation();
                            setStatusIndex(1);
                            setDataGuideline(item);
                          }}
                        >
                          <img src={IconPossible} className="icon" alt="possible-icon" /> 実施する
                        </button>
                      ) : (
                        <button className="btn-stop">
                          <ExclamationCircleOutlined className="icon" /> 未実施
                        </button>
                      )}
                    </div>
                  }
                  key="1"
                >
                  <div
                    className={`content-dropdown ${
                      item.implementation_status === 2
                        ? 'check-item'
                        : item.implementation_settings === 2 ||
                          (item.implementation_settings === 1 &&
                            dayjs().isBetween(item?.start_period, item?.end_period))
                        ? 'edit-item'
                        : 'stop-item'
                    }`}
                  >
                    <div className="item-dropdown-left">
                      {files.find((f) => f.i_id === item.i_id)?.file ? (
                        <img
                          src={files.find((f) => f.i_id === item.i_id)?.file}
                          className="image"
                          alt=""
                        />
                      ) : (
                        <div className="wrap-image">
                          <div className="wrap-no-image">
                            <img src={NoImageCurriculum} alt="" />
                            <span className="text">no image</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="item-dropdown-right">
                      <div className="item-dropdown">
                        <div className="item-left">
                          <div className="item-left-child">
                            <div className="label">
                              <CopyOutlined className="icon-copy" />
                              <p className="text-label">分析グループ</p>
                            </div>
                            <p className="content-label">: {item.grouping_code}</p>
                          </div>
                          <div className="item-left-child">
                            <div className="label">
                              <CalendarOutlined className="icon-copy" />
                              <p className="text-label">実施期間</p>
                            </div>
                            <p className="content-label">
                              {': '}
                              {item.implementation_settings === 2 ? (
                                '実施可'
                              ) : item.implementation_settings === 0 ? (
                                '実施不可'
                              ) : (
                                <>
                                  {item?.start_period &&
                                    dayjs(item.start_period).format('YYYY年MM月DD日 HH:mm')}
                                  {(item?.start_period || item?.end_period) && <span>~</span>}
                                  {item?.end_period &&
                                    dayjs(item.end_period).format('YYYY年MM月DD日 HH:mm')}
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="item-right">
                          <div className="item-child">
                            <div className="item-right-child">
                              <div className="label">
                                <ClockCircleOutlined className="icon-copy" />
                                <p className="text-label">制限時間: </p>
                              </div>
                              <p className="content-label">{item.check_time_limit}</p>
                            </div>
                            <div className="item-right-child">
                              <div className="label">
                                <CheckCircleOutlined className="icon-copy" />
                                <p className="text-label">実施状況: </p>
                              </div>
                              <div
                                className={`content-label-status ${
                                  item.implementation_status === 2
                                    ? 'check'
                                    : item.implementation_settings === 2 ||
                                      (item.implementation_settings === 1 && dayjs())
                                    ? 'edit'
                                    : 'stop'
                                }`}
                              >
                                {item.implementation_status === 2 ? (
                                  <>
                                    <div className="status-done">
                                      <p className="text-status">済</p>
                                    </div>
                                    <p className="label-status">実施済</p>
                                  </>
                                ) : (
                                  <div
                                    className={
                                      item.implementation_settings === 2 ||
                                      (item.implementation_settings === 1 &&
                                        dayjs().isBetween(item?.start_period, item?.end_period))
                                        ? 'implemented'
                                        : 'not-implemented'
                                    }
                                  >
                                    <div
                                      className={`status ${
                                        item.implementation_settings === 2 ||
                                        (item.implementation_settings === 1 &&
                                          dayjs().isBetween(item?.start_period, item?.end_period))
                                          ? 'edit-icon'
                                          : 'stop-icon'
                                      }`}
                                    >
                                      <p className="text-status">未</p>
                                    </div>
                                    <p className="label-status">未実施</p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="item-child">
                            <div className="item-right-child">
                              <div className="label">
                                <FileTextOutlined className="icon-copy" />
                                <p className="text-label">設問数: </p>
                              </div>
                              <p className="content-label">{item.question_count}問</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="item-dropdown-last">
                        <div className="label">
                          <MessageOutlined className="icon-mess" />
                          <p className="text-label">説明 :</p>
                        </div>
                        <div className="wrap-des">
                          <p className="content-label">{item.skill_check_description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            ))}
          </div>
        </>
      )}
    </SkillCheckSelectionStyled>
  );
};

export default SkillCheckSelection;
