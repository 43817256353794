import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .pie-chart,
  .bar-chart {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    .wrap-chart {
      width: 49%;
    }
    .text-center {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
      height: 300px;
      width: 2%;
      background: #08a3a5;
      p {
        writing-mode: tb-rl;
        font-weight: 700;
        font-size: 15px;
        color: #ffffff;
        margin: 0;
      }
    }
    .h-380 {
      height: 380px;
    }
  }
  .bar-chart {
    margin-top: 5px;
  }
`;

export const PieChartStyle = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 4px 4px 0 0;
  width: 100%;
  .header {
    width: 100%;
    padding: 15px;
    background: #f8f8f8;
    border-bottom: 1px solid #dddddd;
    border-radius: 4px 4px 0 0;
    text-align: center;
    span {
      font-weight: 700;
      font-size: 15px;
      color: #424242;
    }
  }
  .body-pie {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%;
    .wrap-time {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 20%;
      .answer-time,
      .average-answer-time {
        padding: 30px;
        text-align: center;
        border-top: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
        .title {
          font-size: 12px;
          color: #777777;
          margin: 0;
        }
        .time {
          font-size: 18px;
          color: #424242;
          margin: 0;
        }
      }
    }
    .pie {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;
      height: 300px;
      .empty-pie {
        width: 200px;
        height: 200px;
      }
    }
    .legend {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 20%;
      .row {
        display: flex;
        margin: 10px 0;
        .rec {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .label {
          font-size: 12px;
          color: #424242;
        }
        .green {
          background-color: #379bd2;
        }
        .pink {
          background-color: #ea8c92;
        }
        .gray {
          background-color: #ebebeb;
        }
      }
    }
  }
`;

export const ColumnChartStyle = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dddddd;
  border-radius: 0 0 4px 4px;
  width: 100%;
`;
