import React, { useState } from 'react';

import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { Pagination, Table } from 'antd';
import dayjs from 'dayjs';

import { settingSelector } from 'containers/AppSettings/selectors';
import { trainingReportSelector } from '../../selectors';
import { formatNumber } from 'libs/utils/format';
import WrapperStyled from './styles';
import * as Types from 'types';

const PER_PAGE = 10;

interface Props {
  isAllCurriculumUsers: boolean;
  valueRequiredCurriculum: number;
  exportPdf?: boolean;
}

const SurfaceTable: React.FC<Props> = ({ isAllCurriculumUsers, exportPdf }) => {
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const { totalReportCurriculumUser, dataReportCurriculumUser } =
    useSelector(trainingReportSelector);
  const { loading } = useSelector(settingSelector);

  const dynamicColumns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> =
    !isAllCurriculumUsers
      ? [
          {
            title: '正解',
            dataIndex: 'correct_answers_num',
            key: 'correct_answers_num',
            align: 'right',
            sorter: (a, b) => a.correct_answers_num - b.correct_answers_num,
            render: (record: number, item) => (item.updatedat ? record : '-'),
            width: '5%',
          },
          {
            title: '不正解',
            dataIndex: 'incorrect_answer',
            key: 'incorrect_answer',
            align: 'right',
            sorter: (a, b) => a.incorrect_answer - b.incorrect_answer,
            render: (record: number, item) => (item.updatedat ? record : '-'),
            width: '5%',
          },
          {
            title: '未実施',
            dataIndex: 'inexperienced',
            key: 'inexperienced',
            align: 'right',
            sorter: (a, b) => a.inexperienced - b.inexperienced,
            render: (record: number, item) => (item.updatedat ? record : '-'),
            width: '5%',
          },
          {
            title: '総回答数',
            dataIndex: 'responses_num',
            key: 'responses_num',
            align: 'right',
            width: '6%',
            render: (record: number, item) => (item.updatedat ? record : '-'),
          },
        ]
      : [];

  const columns: ColumnsType<Types.ReportCurriculumUser.UserReportSurfaceTable> = [
    {
      title: 'No.',
      dataIndex: '',
      key: '',
      render: (_text, _record, index) => index + 1,
      width: '3%',
    },
    {
      title: 'カリキュラム',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      ellipsis: true,
      width: '15%',
    },
    ...dynamicColumns,
    {
      title: '総設問数',
      dataIndex: 'question_count',
      key: 'question_count',
      align: 'right',
      width: '6%',
    },
    {
      title: '正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      align: 'right',
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '不正解率',
      dataIndex: 'wrong_rate',
      key: 'wrong_rate',
      align: 'right',
      sorter: (a, b) => a.wrong_rate - b.wrong_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '未実施率',
      dataIndex: 'inexperienced_rate',
      key: 'inexperienced_rate',
      align: 'right',
      sorter: (a, b) => a.inexperienced_rate - b.inexperienced_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '実質正解率',
      dataIndex: 'real_accuracy_rate',
      key: 'real_accuracy_rate',
      align: 'right',
      sorter: (a, b) => a.real_accuracy_rate - b.real_accuracy_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '進捗率',
      dataIndex: 'progress_rate',
      key: 'progress_rate',
      align: 'right',
      sorter: (a, b) => a.progress_rate - b.progress_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '正解進捗率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      align: 'right',
      sorter: (a, b) => a.accuracy_rate - b.accuracy_rate,
      render: (record: number, item) => (item.updatedat ? `${formatNumber(record * 100)}%` : '-'),
    },
    {
      title: '最終更新日',
      dataIndex: 'updatedat',
      key: 'updatedat',
      align: 'center',
      sorter: (a, b) =>
        a.updatedat && b.updatedat
          ? new Date(a.updatedat).getTime() - new Date(b.updatedat).getTime()
          : -1,
      render: (value: string) => {
        return value ? (
          dayjs(value).format('YYYY/MM/DD')
        ) : (
          <span style={{ color: '#F0330A' }}>未実施</span>
        );
      },
      width: '9%',
    },
  ];

  return (
    <WrapperStyled>
      {totalReportCurriculumUser ? (
        <div className="table-header">
          {exportPdf ? (
            <>
              <div />
              <div className="text-total-record-table">
                {totalReportCurriculumUser} <span className="text-static"> 件表示</span> /
                {totalReportCurriculumUser}
                <span className="text-static"> 件</span>
              </div>
            </>
          ) : (
            <Pagination
              total={totalReportCurriculumUser}
              showTotal={(total, range) => {
                return (
                  <div className="text-total-record-table">
                    {range[0]} - {range[1]} <span className="text-static"> </span> / {total}
                    <span className="text-static"> 件</span>
                  </div>
                );
              }}
              pageSize={pageSize}
              defaultCurrent={page}
              showSizeChanger={true}
              onChange={(pageChange: number, pageSizeChange: number) => {
                setPage(pageChange);
                setPageSize(pageSizeChange);
              }}
              locale={{
                items_per_page: '',
              }}
            />
          )}
        </div>
      ) : null}
      <Table
        className="table"
        rowKey="index"
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        bordered
        dataSource={dataReportCurriculumUser
          .map((item, index) => ({ ...item, index }))
          .slice(1)
          .slice((page - 1) * pageSize, page * pageSize)}
        columns={columns}
        loading={loading}
        pagination={false}
      />
    </WrapperStyled>
  );
};

export default SurfaceTable;
