import React from 'react';

import BreakdownAnswersPerCentage from './BreakdownAnswersPerCentage';
import TableChartByQuestionCode from './TableChartByQuestionCode';
import BreakdownAnswers from './BreakdownAnswers';
import TableChart from './TableChart';
import {
  ITEM_COMPARE_BAR_CHART,
  ITEM_COMPARE_PIE_CHART,
  ITEM_GRAPH,
} from 'constant/select.constants';

interface Props {
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  isQuestionCodeTable: boolean;
  exportPdf?: boolean;
}

const Chart: React.FC<Props> = ({ itemComparision, itemGraph, isQuestionCodeTable, exportPdf }) => {
  return isQuestionCodeTable ? (
    <TableChartByQuestionCode />
  ) : itemGraph === 'bar_chart' ? (
    <TableChart itemComparision={itemComparision} />
  ) : itemComparision === 'number_of_solutions' ? (
    <BreakdownAnswers exportPdf={exportPdf} />
  ) : (
    <BreakdownAnswersPerCentage exportPdf={exportPdf} />
  );
};

export default Chart;
