import styled from 'styled-components';

interface Props {
  itemComparision?: string;
}

export default styled.section<Props>`
  background: #ffffff;
  height: 100%;
  .top-page {
    background: #08a3a5;
    padding: 12px 8px;
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      .type-select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #ffffff;
        width: 48%;
        .number-type {
          color: rgba(255, 255, 255, 0.3);
          font-size: 24px;
        }
        .label {
          font-size: 12px;
          font-weight: 700;
        }
        .select {
          width: 55%;
          .ant-select-selector {
            border-radius: 5px;
          }
        }
      }
      .close-icon {
        color: #ffffff;
        font-size: 16px;
        margin: 0 8px;
      }
    }
  }
`;
