import styled from 'styled-components';

export const ConfirmWithdrawStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
  .wrap-content-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .wrap-warning-description {
      display: flex;
      justify-content: center;
      flex-direction: row;
      width: 100%;
      padding: 20px 0;
      background-color: #fffaf9;
      border: 1px solid rgba(240, 51, 10, 0.8);
      border-radius: 2px;
      color: #f0330a;
      .warning-icon {
        font-size: 24px;
        margin-right: 8px;
      }
      .warning-description {
        text-align: center;
        .text-bold {
          text-decoration: underline;
          color: #f0330a;
          font-weight: 700;
        }
      }
    }
    .warning-note {
      text-align: center;
      margin-top: 16px;
      color: #777777;
    }
  }
  .wrap-comment {
    background: #ffffff;
    border: 1px solid #eeeeee;
    margin-top: 30px;
    .wrap-description {
      padding: 20px;
      border-bottom: 1px solid #eeeeee;
      .description {
        font-size: 13px;
        color: #424242;
      }
    }
    .comment {
      padding: 32px;
      .title {
        font-size: 18px;
        color: #424242;
      }
      .checkbox {
        display: flex;
        flex-direction: column;
        margin: 30px 0 0 30px;
        font-size: 14px;
        color: #424242;
        .ant-checkbox-wrapper {
          margin-bottom: 20px;
          margin-left: 0;
          span {
            color: #424242;
          }
        }
      }
      textarea {
        background: #ffffff;
        border: 1px solid #cccccc;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        min-height: 90px;
      }
    }
  }
`;
