import styled from 'styled-components';

export default styled.section`
  .table-header {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    width: 50%;
    right: 0;
    top: 0;

    .ant-pagination-total-text {
      position: absolute;
      right: 0;
      .text-total-record-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .ant-pagination {
      .ant-pagination-options {
        position: absolute;
        right: 130px;

        ::before {
          content: '表示件数：';
          font-size: 11px;
          padding-right: 3px;
        }
      }
    }
  }

  .table {
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead tr .average {
      background: #f4fbfb;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
    .ant-table-tbody tr:first-child {
      background: #f4fbfb;
    }
    .ant-table-thead th,
    .ant-table-tbody td {
      padding: 5px;
    }
    .implementation-period {
      background: #777777 !important;
      font-size: 13px;
      color: #ffffff;
    }
    .ant-table-column-title {
      font-size: 11px !important;
    }
    .order {
      background: #6690e0 !important;
      font-size: 13px;
      color: #ffffff;
    }
    .item-table {
      display: flex;
      flex-direction: row;
      justify-content: end;
      align-items: baseline;
      .label-number {
        margin: 0;
        font-size: 14px;
        color: #424242;
      }
      .label {
        margin: 0;
        color: #999999;
        font-size: 10px;
      }
    }
    .jc-center {
      justify-content: center;
    }
    .color-green {
      .label-number {
        font-weight: 500;
      }
      p {
        color: #5786df !important;
      }
    }
    .color-red {
      .label-number {
        font-weight: 500;
      }
      p {
        color: #ea3596 !important;
      }
    }
  }
`;
