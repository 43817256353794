import React, { useState, useRef, useEffect } from 'react';
import { Input as AntInput, InputRef, Select } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { Form, Radio, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { CREDIT_CARD_MONTH, IMAGE_CREDIT_CARD } from 'constant';
import { informationRegisterSelector } from '../selectors';
import { RegisterInformationPaymentFormik } from 'types';
import { authSelector } from 'containers/Auth/selectors';
import { fourStepSchema } from 'libs/validations';
import { confirmCreditCardThunk } from '../thunk';
import { setCreditCardInfo } from '../slice';
import { useAppDispatch } from 'hooks';
import { SecurityCode } from 'assets';
import { Input } from 'components';
import { Wrapper } from './styles';

const { Option } = Select;

interface Props {
  nextStep: () => void;
}

const FourStep: React.FC<Props> = ({ nextStep }) => {
  const [cardNumber, setCardNumber] = useState<{
    input1: string;
    input2: string;
    input3: string;
    input4: string;
  }>({
    input1: '',
    input2: '',
    input3: '',
    input4: '',
  });
  const [expiredDate, setExpiredDate] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const topRef = useRef<HTMLDivElement>(null);

  const { creditCard, err } = useSelector(informationRegisterSelector);
  const { signedInEmail } = useSelector(authSelector);
  const { loading } = useSelector(settingSelector);

  const cardNumber1 = useRef<InputRef>(null);
  const cardNumber2 = useRef<InputRef>(null);
  const cardNumber3 = useRef<InputRef>(null);
  const cardNumber4 = useRef<InputRef>(null);

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fourStepSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      dispatch(startLoading());
      if (Object.keys(formik.errors).length > 0) {
        if (topRef.current) {
          topRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
      const { securityNumber, expirationDate, customerName } = values;
      window.Multipayment.init('tshop00054795');
      window.Multipayment.getToken(
        {
          holdername: customerName,
          cardno: `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`,
          expire: expirationDate,
          securitycode: securityNumber,
        },
        async (res: { resultCode: string; tokenObject: { token: string } }) => {
          if (res.resultCode !== '000') {
            alert('購入処理中にエラーが発生しました');
          } else {
            const resultAction = await dispatch(
              confirmCreditCardThunk({
                action_id: 'validitycheck',
                item: {
                  name: signedInEmail,
                },
                as_params: {
                  token: res.tokenObject.token,
                  amount: 600,
                },
                is_force_update: true,
              })
            );
            if (confirmCreditCardThunk.fulfilled.match(resultAction)) {
              dispatch(setCreditCardInfo(values));
              nextStep();
            }
          }
        }
      );
      dispatch(stopLoading());
    },
  });

  const handleInputCardNumber = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { maxLength } = e.target;
    const value = toHalfWidth(e.target.value);

    switch (index) {
      case 1:
        if (parseInt(value[0]) !== 0) {
          setCardNumber((prevState) => ({ ...prevState, input1: value }));
          formik.setFieldValue(
            'cardNumber',
            `${value}${cardNumber.input2}${cardNumber.input3}${cardNumber.input4}`
          );
          if (value.length === maxLength) {
            cardNumber2.current && cardNumber2.current.focus();
          }
        }
        break;
      case 2:
        setCardNumber((prevState) => ({ ...prevState, input2: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${value}${cardNumber.input3}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber3.current && cardNumber3.current.focus();
        }
        break;
      case 3:
        setCardNumber((prevState) => ({ ...prevState, input3: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${value}${cardNumber.input4}`
        );
        if (value.length === maxLength) {
          cardNumber4.current && cardNumber4.current.focus();
        }
        break;
      case 4:
        setCardNumber((prevState) => ({ ...prevState, input4: value }));
        formik.setFieldValue(
          'cardNumber',
          `${cardNumber.input1}${cardNumber.input2}${cardNumber.input3}${value}`
        );
        break;
      default:
        break;
    }
  };

  const handleSelectExpiredDate = (type: 'month' | 'year') => (e: string) => {
    if (type === 'month') {
      setExpiredDate((prevState) => ({ ...prevState, month: e }));
      formik.setFieldValue('expirationDate', `${expiredDate.year}${e}`);
    } else {
      setExpiredDate((prevState) => ({ ...prevState, year: e }));
      formik.setFieldValue('expirationDate', `${e}${expiredDate.month}`);
    }
  };

  const toHalfWidth = (str: string) =>
    str.replace(/[！-～]/g, (halfwidthChar) =>
      String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0)
    );

  const handleInputCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.target.value.replace(/[^a-z\s]/gi, '');

    formik.setFieldValue('customerName', toHalfWidth(result).toUpperCase());
  };

  useEffect(() => {
    if (creditCard) {
      setCardNumber({
        input1: creditCard.cardNumber.slice(0, 4),
        input2: creditCard.cardNumber.slice(4, 8),
        input3: creditCard.cardNumber.slice(8, 12),
        input4: creditCard.cardNumber.slice(12, 16),
      });
      setExpiredDate({
        month: creditCard.expirationDate.slice(4, 6),
        year: creditCard.expirationDate.slice(0, 4),
      });
    }
  }, [creditCard]);

  return (
    <Wrapper ref={topRef}>
      {!formik.isValid && loading ? (
        <p className="text-center error">{messages['AJ-04-1.1']}</p>
      ) : (
        <p className="text-center">{messages['AJ-04-1.2']}</p>
      )}
      <FormikProvider value={formik}>
        <Form layout="vertical" colon={false}>
          <div className="form">
            {err && <p className="text-err">{err}</p>}
            <Form.Item
              name="companyName"
              label={
                <span className="text-label">
                  {messages['AJ-04-2']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Radio.Group name="companyName" className="radio_group">
                {IMAGE_CREDIT_CARD.map((item, index) => (
                  <div key={index} className="wrap-radio">
                    <Radio
                      name="companyName"
                      value={item.name}
                      className={`radio_item ${
                        index === 0
                          ? 'border-radius-left'
                          : index >= 1 && index <= 3
                          ? 'border-right'
                          : 'border-radius-right'
                      }`}
                    >
                      <img
                        className="image-card-company"
                        aria-hidden
                        src={item.image}
                        alt={item.name}
                      />
                    </Radio>
                    <p className="label-text">{item.name}</p>
                  </div>
                ))}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="cardNumber"
              label={
                <span className="text-label">
                  {messages['AJ-04-3']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <AntInput
                ref={cardNumber1}
                value={cardNumber.input1}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(1)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input1.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                ref={cardNumber2}
                value={cardNumber.input2}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(2)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input2.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                ref={cardNumber3}
                value={cardNumber.input3}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(3)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input3.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
              <span> - </span>
              <AntInput
                ref={cardNumber4}
                value={cardNumber.input4}
                suffix={<span />}
                className="input-card-number input_small"
                type="text"
                maxLength={4}
                onChange={handleInputCardNumber(4)}
                onKeyPress={(e) => {
                  if (isNaN(parseInt(e.key)) || cardNumber.input4.toString().length > 3) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="customerName"
              label={
                <span className="text-label">
                  {messages['AJ-04-4']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="customerName"
                className="input"
                type="text"
                placeholder="半角：最大50文字"
                onChange={handleInputCustomerNameChange}
                value={formik.values.customerName}
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              name="expirationDate"
              label={
                <span className="text-label">
                  {messages['AJ-04-5']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Select
                value={expiredDate.month}
                className="pull_down input_small"
                onSelect={handleSelectExpiredDate('month')}
              >
                {CREDIT_CARD_MONTH.map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className="text-label-content">{messages['month']} / </span>
              <Select
                value={expiredDate.year}
                className="pull_down input_small"
                onSelect={handleSelectExpiredDate('year')}
              >
                {Array.from(
                  {
                    length: 10,
                  },
                  (_, i) => (i + new Date().getFullYear()).toString()
                ).map((item, index) => (
                  <Option key={index} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className="text-label-content">{messages['year']}</span>
            </Form.Item>
            <Form.Item
              name="securityNumber"
              label={
                <span className="text-label">
                  {messages['AJ-04-6']}
                  <span className="require">*</span>
                </span>
              }
              className="form-input flex"
            >
              <Input
                name="securityNumber"
                className="input_small_two"
                type="number"
                onKeyPress={(e) => {
                  if (
                    isNaN(parseInt(e.key)) ||
                    formik.values.securityNumber.toString().length > 3
                  ) {
                    e.preventDefault();
                  }
                }}
              />
              <p className="content-text">
                {messages['AJ-04-7.1']}
                <br />
                {messages['AJ-04-7.2']}
              </p>
            </Form.Item>
            <div className="wrap-security">
              <img src={SecurityCode} alt="security-code" />
            </div>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">{messages['AJ-04-8']}</SubmitButton>
          </div>
        </Form>
      </FormikProvider>
    </Wrapper>
  );
};

export default FourStep;
