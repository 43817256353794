import jaJP from 'antd/es/locale/ja_JP';

import { Entries } from 'types/config';
import jaMessages from '../locales/ja_JP.json';

const JaLang: Entries = {
  messages: {
    ...jaMessages,
  },
  antd: jaJP,
  locale: 'ja',
};

export default JaLang;
