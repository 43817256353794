import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { createAffiliationAssignLevel, createAffiliationRole } from 'pages/Settings/Employee/thunk';
import { addLinkAffiliation, createCompanies, registerUserThunk } from '../thunk';
import { createAffiliationLevel } from 'pages/Settings/AffiliationMaster/thunk';
import { createItemPositionMaster } from 'pages/Settings/PositionMaster/thunk';
import { isFirstLogin, updateLoginTime } from '../../Auth/thunk';
import { createRoles } from 'pages/Settings/RolesMaster/thunk';
import { generatePath, useNavigate } from 'react-router-dom';
import { informationRegisterSelector } from '../selectors';
import { secondStepSchema } from 'libs/validations';
import { authSelector } from '../../Auth/selectors';
import { RegisterInformationFormik } from 'types';
import { AFFILIATION_ASSIGN_ROLE } from 'configs';
import { resetInformation } from '../slice';
import { routes } from 'navigations/routes';
import { useAppDispatch } from 'hooks';
import { Input } from 'components';
import { Wrapper } from './styles';
import { Logo } from 'assets';

interface Props {
  nextStep: () => void;
  previousStep: () => void;
  success: boolean;
  planSelectedIndex: number | undefined;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdStep: React.FC<Props> = ({
  nextStep,
  previousStep,
  planSelectedIndex,
  setSuccess,
  success,
}) => {
  const { information, plans_selection } = useSelector(informationRegisterSelector);
  const { signedInEmail } = useSelector(authSelector);
  const { messages } = useIntl();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormik<RegisterInformationFormik>({
    initialValues: information,
    validationSchema: secondStepSchema,
    validateOnBlur: false,
    onSubmit: () => {
      if (planSelectedIndex === 1) {
        nextStep();
      } else {
        setSuccess(true);
        setTimeout(async () => {
          const resultAction = await dispatch(
            createCompanies({
              item: {
                ...information,
                plan_id: plans_selection?.i_id,
                plan_start_at: dayjs().format('YYYY-MM-DD'),
                plan_expiry_at: dayjs().add(30, 'day').format('YYYY-MM-DD'),
                trial_start_date: dayjs().format('YYYY-MM-DD'),
                trial_end_date: dayjs().add(30, 'day').format('YYYY-MM-DD'),
                createdat: new Date(),
                createdby: signedInEmail,
              },
              return_item_result: true,
              return_display_id: true,
            })
          );
          if (createCompanies.fulfilled.match(resultAction)) {
            const { item } = resultAction.payload;
            const resultActionCreateRole = await dispatch(
              createRoles({
                item: {
                  name: 'admin',
                  admin_dashboard_permission: 2,
                  skill_check_implementation_permission: 2,
                  training_report_permission: 1,
                  skill_check_report_permission: 1,
                  my_chart_permission: 1,
                  manual_permission: 2,
                  knowledge_permission: 2,
                  official_curriculum_permission: 2,
                  curricullum_master_permission: 2,
                  question_master_permission: 2,
                  skill_check_master_permission: 2,
                  inter_users_master_permission: 2,
                  groups_master_permission: 2,
                  users_master_permission: 2,
                  roles_master_permission: 2,
                  departments_master_permission: 2,
                  positions_master_permission: 2,
                  admin_master_permission: 2,
                  payment_master_permission: 0,
                  report_view_permission: 0,
                  bundle_master_permission: 1,
                  company_id: item.id,
                  createdby: signedInEmail,
                  createdat: new Date(),
                },
                is_force_update: true,
              })
            );
            const resultActions1 = await Promise.all([
              dispatch(
                createAffiliationLevel({
                  item: {
                    name: information.admin_department,
                    sort_order: 1,
                    level: 1,
                    createdby: signedInEmail,
                    company_id: item.id,
                    createdat: new Date(),
                  },
                  return_display_id: true,
                  return_item_result: true,
                })
              ),
              dispatch(
                createItemPositionMaster({
                  item: {
                    name: information.admin_position,
                    company_id: item.id,
                    rank_order: 1,
                    createdby: signedInEmail,
                    createdat: new Date(),
                  },
                  return_display_id: true,
                  return_item_result: true,
                })
              ),
              dispatch(
                registerUserThunk({
                  item: {
                    company_id: item.id,
                    login_id: signedInEmail,
                    name: information.admin_name,
                    name_furigana: information.admin_name_furigana,
                    email: signedInEmail,
                    user_type: 'admin',
                    admin_phone: information.admin_phone,
                    role_code: createRoles.fulfilled.match(resultActionCreateRole)
                      ? resultActionCreateRole.payload.item_id
                      : undefined,
                    createdat: new Date(),
                    createdby: signedInEmail,
                    last_login_at: dayjs().toISOString(),
                  },
                })
              ),
            ]);

            if (registerUserThunk.fulfilled.match(resultActions1[2])) {
              let affiliation_id;
              let positions_id;
              let affiliation_code;
              if (
                createAffiliationLevel.fulfilled.match(resultActions1[0]) &&
                resultActions1[0].payload.item
              ) {
                affiliation_id = resultActions1[0].payload.item_id;
                affiliation_code = resultActions1[0].payload.item.affiliation_id;
              }
              if (
                createItemPositionMaster.fulfilled.match(resultActions1[1]) &&
                resultActions1[1].payload.item
              ) {
                positions_id = resultActions1[1].payload.item_id;
              }
              const resultActions2 = await Promise.all([
                dispatch(
                  createAffiliationRole({
                    item: {
                      company_id: item.id,
                      affiliation_id: affiliation_id,
                      login_id: signedInEmail,
                      positions_code: positions_id,
                      sort_order: 1,
                      main_role: 'main',
                      createdby: signedInEmail,
                      createdat: new Date(),
                    },
                  })
                ),
                dispatch(
                  createAffiliationAssignLevel({
                    item: {
                      company_id: item.id,
                      affiliation_id: affiliation_code,
                      login_id: signedInEmail,
                      sort_order: 1,
                      createdby: signedInEmail,
                      createdat: new Date(),
                    },
                  })
                ),
              ]);
              if (
                createAffiliationRole.fulfilled.match(resultActions2[0]) &&
                createAffiliationAssignLevel.fulfilled.match(resultActions2[1])
              ) {
                dispatch(
                  addLinkAffiliation({
                    id: resultActions2[1].payload.item_id,
                    data: {
                      link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                      link_item_id: resultActions2[0].payload.item_id,
                    },
                  })
                );
              }

              const resultActionIsFirstLogin = await dispatch(
                isFirstLogin({
                  conditions: [
                    {
                      id: 'email',
                      search_value: [signedInEmail],
                      exact_match: true,
                    },
                  ],
                  include_links: true,
                  include_lookups: true,
                  page: 1,
                  per_page: 1,
                })
              );
              if (
                isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
                resultActionIsFirstLogin.payload.totalItems
              ) {
                await dispatch(
                  updateLoginTime({
                    id: resultActionIsFirstLogin.payload.items[0].i_id,
                    data: {
                      item: {
                        last_login_at: dayjs().toISOString(),
                      },
                      is_force_update: true,
                    },
                    un_realtime: true,
                  })
                );
              }
              dispatch(resetInformation());
              navigate(generatePath(routes.Dashboard.path, { entity: 'receiving' }));
            }
          }
        }, 1500);
      }
    },
  });

  return (
    <Wrapper>
      {success ? (
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
        </p>
      ) : (
        <>
          <p className="text-center">{messages['AJ-03-1']}</p>
          <FormikProvider value={formik}>
            <Form
              layout="vertical"
              labelCol={{
                flex: '22%',
              }}
              colon={false}
            >
              <div className="form">
                <Form.Item
                  name="name"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-7']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input readOnly name="name" className="input__disabled input" type="text" />
                </Form.Item>
                <Form.Item
                  name="name_furigana"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-8']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="name_furigana"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="postal_code"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-9']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="postal_code"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="prefecture"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-11']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input readOnly name="prefecture" type="text" className="input__disabled input" />
                </Form.Item>
                <Form.Item
                  name="address"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-12']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input readOnly name="address" className="input__disabled input" type="text" />
                </Form.Item>
                <Form.Item
                  name="plot_number"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-13']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="plot_number"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="building_name"
                  label={<span className="text-label">{messages['AJ-02-14']}</span>}
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="building_name"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_name"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-15']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input readOnly name="admin_name" className="input__disabled input" type="text" />
                </Form.Item>
                <Form.Item
                  name="admin_name_furigana"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-16']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="admin_name_furigana"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_department"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-17']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="admin_department"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_position"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-18']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="admin_position"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_phone"
                  label={
                    <span className="text-label">
                      {messages['AJ-02-19']}
                      <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="admin_phone"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
                <Form.Item
                  name="admin_email"
                  label={<span className="text-label">{messages['AJ-02-20']}</span>}
                  className="form-input"
                >
                  <Input
                    readOnly
                    name="admin_email"
                    className="input__disabled input"
                    type="text"
                  />
                </Form.Item>
              </div>
              <div className="wrap-button">
                <SubmitButton className="btn-submit">OK</SubmitButton>
                <button type="button" className="go-back" onClick={previousStep}>
                  {`<  ${messages['back']}`}
                </button>
              </div>
            </Form>
          </FormikProvider>
        </>
      )}
    </Wrapper>
  );
};

export default ThirdStep;
