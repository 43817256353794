import styled from 'styled-components';

export default styled.section`
  .title {
    text-align: center;
  }
  .wrap-basic-information {
    .body {
      display: flex;
      justify-content: flex-start;
      .wrap-user {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        .avatar-user {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          overflow: hidden;
          background-color: #f0efef;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 4px solid #ffffff;
          box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
          &_confirm {
            cursor: not-allowed;
          }
          .icon-camera {
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 28px;
            background: #ffffff;
            box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
            position: absolute;
            bottom: 70px;
            right: 40px;
            cursor: pointer;
          }
          .disabled {
            pointer-events: none;
          }
          .avatar {
            width: 100%;
            height: 100%;
          }
          .icon-user {
            font-size: 78px;
            color: #cccccc;
          }
          .text-content {
            font-family: Lato;
            font-size: 11px;
            color: #999999;
            text-align: center;
            display: block;
            margin-top: 15px;
          }
          .ant-upload {
            display: flex;
            flex-direction: column;
          }
        }
      }
      .text-help {
        display: flex;
        margin: 15px 0;
        font-size: 11px;
        text-align: center;
        justify-content: center;
        color: #999999;
      }

      .form-left,
      .form-right {
        width: 70%;
        margin-left: 22px;
        .text-label {
          font-size: 13px;
          color: #424242;
        }
        .form-input {
          .input,
          .input-textarea {
            box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
            border-radius: 3px;
            background-color: #ffffff;
            color: #424242;
            ::placeholder {
              color: #cccccc;
              font-size: 14px;
            }
          }
          .ant-input-show-count-suffix {
            position: absolute;
            top: 46px;
            width: 55px;
            right: -5px;
            text-align: right;
          }
          .text-input {
            font-size: 14px;
          }
          .input_small {
            width: 120px;
          }
          .input-month-day {
            width: 80px;
          }
          .text-label-content {
            font-size: 12px;
            color: #777777;
            margin: 0 10px 0 7px;
          }
        }
      }
    }
    .comment {
      margin-top: 33px;
      .input-textarea-group {
        .ant-input {
          resize: vertical;
        }
      }
    }
  }
  .group-button {
    display: flex;
    justify-content: center;
    padding-top: 45px;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 13px;
      font-weight: 700;
      border: none;
      width: 140px;
      height: 40px;
      cursor: pointer;
    }
    .go-back {
      width: 140px;
      height: 40px;
      background-color: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      margin-left: 10px;
      color: #777;
      font-size: 13px;
      cursor: pointer;
    }
  }
`;
