import styled from 'styled-components';

import * as Types from 'types';

interface PropsAfiliation {
  index: number;
  item: Types.TreeItem<Types.AffiliationItemType>;
  listItemIndexShrink: number[];
}

export const WrapperAffiliation = styled.div<PropsAfiliation>`
  margin-left: ${(props) => (props.item.columnIndex || 0) * 30}px;
  margin-bottom: 20px;
  .rst__nodeContent {
    height: ${(props) => ((props.item.columnIndex || 0) > 0 ? 182 : 'unset')}px;
  }
  .rst__lineBlock {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .rst__lineHalfHorizontalLeftVerticalTop:before,
  .rst__lineHalfHorizontalLeftVerticalTop:after {
    position: absolute;
    content: '';
  }
  .rst__lineHalfHorizontalLeftVerticalTop:before {
    height: 3px;
    top: ${(props) => (props.listItemIndexShrink.includes(props.index) ? 15 : 50)}%;
    right: 100%;
    width: calc(
      0.5% *
        ${(props) =>
          (props.item.node?.columnIndex || 0) - (props.item.parentNode?.columnIndex || 0) + 1}
    );
    background: #959fd7;
  }
  .rst__lineHalfHorizontalLeftVerticalTop:after {
    width: 3px;
    left: calc(
      -0.5% * ${(props) => (props.item.node?.columnIndex || 0) - (props.item.parentNode?.columnIndex || 0) + 1}
    );
    height: ${(props) => {
      const lastIndex = props.item.path![props.item.path!.length - 1];
      const firstIndex = props.item.path![props.item.path!.length - 2];

      return `calc(50% + 20px${
        lastIndex - firstIndex - 1 === 0 ? '' : ` + (100% + 20px) * ${lastIndex - firstIndex - 1}`
      }${
        props.item.node!.columnIndex! !== 1 &&
        props.listItemIndexShrink.includes(firstIndex) &&
        lastIndex === props.index
          ? ' + 130px'
          : ''
      }${props.listItemIndexShrink.includes(props.index) ? ' - 35%' : ''});`;
    }};
    bottom: ${(props) => (props.listItemIndexShrink.includes(props.index) ? 85 : 50)}%;
    background: #959fd7;
  }
`;
