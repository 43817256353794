import styled from 'styled-components';

import * as Types from 'types';

interface Props {
  dataCurriculumBatch: boolean;
  data: Array<Types.DataCheckType>;
}

export default styled.div<Props>`
  padding: 8px 30px 0 30px;
  .sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #424242;
    margin-bottom: 40px;
  }
  .ant-pagination {
    align-items: flex-end;
    margin-top: 0;
  }
  .text-count {
    position: absolute;
    margin-top: ${(props) => (props.data.length ? '14px' : '-30px')};
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
  }
  .table {
    position: relative;
    margin-bottom: 80px;
    .flex {
      display: flex;
      align-items: center;
      .text-content {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      display: none;
    }
    .ant-table-tbody {
      .ant-table-row {
        border: 2px solid #d9d9d9;
      }
      .ant-table-cell {
        font-family: Lato;
        font-size: 13px;
        color: #424242;
        word-wrap: break-word;
        padding: 10px 16px;
        .text-index {
          font-family: Lato;
          font-size: 13px;
          color: #999999;
          margin-right: 15px;
        }
      }
      .ant-table-row-selected .ant-table-cell {
        font-weight: 700;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
      .text-return {
        font-family: Lato;
        font-size: 14px;
        text-decoration-line: underline;
        color: #159193;
        cursor: pointer;
      }
    }
    .ant-pagination-item-active,
    .ant-pagination-item:hover {
      border-color: #00a3a5;
      a {
        color: #00a3a5;
      }
    }
  }
`;
