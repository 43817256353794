import React, { useEffect, useMemo, useState } from 'react';

import { CaretLeftOutlined, LikeOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Modal, Select, Upload, UploadFile, Tag } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy } from 'lodash';
import { UploadChangeParam } from 'antd/lib/upload';

import { createUpdateKnowledgeQASchema } from 'libs/validations/knowledgeTop';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { Header, SelectField, TextArea } from 'components';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import { knowledgeTopSelector } from '../../selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import PostKnowledgeQAStyled from './styles';
import { routes } from 'navigations/routes';
import VideoPopup from '../UploadVideo';
import { adminAvatar } from 'assets';
import {
  getSelectDepartment,
  getDepartmentCreate,
  createKnowLedgeTo,
  createFileAttach,
  createKnowLedge,
  getSelectUser,
} from '../../thunk';

const { Option } = Select;
const { Dragger } = Upload;

const CreateKnowledgeQA: React.FC = () => {
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState(false);
  const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

  const { dataSelectDepartment, data_user, dataDepartmentCreate } =
    useSelector(knowledgeTopSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uniqueUsers = useMemo(() => uniqBy(data_user, 'login_id'), [data_user]);

  const formik = useFormik({
    initialValues: {
      knowledge_title: '',
      to_department_id: '',
      to_user_id: '',
      knowledge_text: '',
    },
    enableReinitialize: true,
    validationSchema: createUpdateKnowledgeQASchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!userInfo) return;
      dispatch(startLoading());

      let fileUpload: Array<string | undefined> = [];
      if (fileList.length) {
        fileUpload = await Promise.all(
          fileList
            .map(async (file) => {
              const uploadToMinIo = await uploadFileToMinIO(file.originFileObj as UploadFile<File>);
              await dispatch(
                createFileAttach({
                  item: {
                    fileID: uploadToMinIo,
                    filename: file?.name,
                    file_location: '1',
                    file_extension: file?.type,
                    file_size: `${file?.size}`,
                    company_id: userInfo?.company_id,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                  },
                })
              );
              return uploadToMinIo;
            })
            .filter(Boolean)
        );
      }

      const resultAction = await dispatch(
        createKnowLedge({
          item: {
            company_id: userInfo.company_id,
            knowledge_title: values.knowledge_title,
            knowledge_text: values.knowledge_text,
            createdat: new Date(),
            createdby: userInfo.login_id,
            knowledge_type: 2,
            likes: 0,
            qa_status: 0,
            recommendation_status: undefined,
            ...Array.from({ length: 5, 5: {} }).reduce((result, _, i) => {
              return { ...result, [`fileID${i + 1}`]: fileUpload[i] || '' };
            }, {}),
          },
          return_display_id: true,
          return_item_result: true,
        })
      );
      if (createKnowLedge.fulfilled.match(resultAction)) {
        const resultActionTo = await dispatch(
          createKnowLedgeTo({
            item: {
              company_id: userInfo.company_id,
              knowledge_id: resultAction.payload.item.knowledge_id,
              to_department_id: values.to_department_id !== 'ALL' ? values.to_department_id : 'ALL',
              to_user_id: values.to_department_id !== 'ALL' ? values.to_user_id : 'ALL',
            },
            return_display_id: true,
            return_item_result: true,
          })
        );
        if (createKnowLedgeTo.fulfilled.match(resultActionTo)) {
          setShowCompleteModal(true);
        }
      }
      dispatch(stopLoading());
    },
  });

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '全角：最大1500文字',
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block', 'add-new-line'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const onClose = () => {
    formik.resetForm();
    setShowCompleteModal(false);
    navigate(routes.KnowledgeTop.path);
  };
  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const base64Array: Array<string> = useMemo(() => {
    if (!formik.values.knowledge_text) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.knowledge_text, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [formik.values.knowledge_text]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '5',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.knowledge_text.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      formik.setFieldValue('knowledge_text', replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getSelectDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDepartmentCreate({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
                exact_match: true,
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getSelectUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(formik.values.to_department_id
              ? [
                  {
                    id: 'department_code',
                    search_value: [formik.values.to_department_id],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [formik.values.to_department_id, dispatch, userInfo]);

  const handleClose = (removedTag: UploadFile<File>) => {
    const newTags = fileList.filter((tag) => tag !== removedTag);
    setFileList(newTags);
  };

  return (
    <PostKnowledgeQAStyled isCheckUpload={isDisabledUpload}>
      <Header title={headerTitle} className="header" />
      <FormikProvider value={formik}>
        <div className="btn-header">
          <div className="btn-back" onClick={() => navigate(-1)}>
            <CaretLeftOutlined className="icon" />
            一覧に戻る
          </div>
        </div>
        <div className="container">
          <div className="wrap-header">
            <div className="title">
              <div className="divider" />
              Q&A 新規投稿
            </div>
            <div className="info">
              <p className="label">投稿者：</p>
              <img
                className="avt-icon"
                src={
                  userInfo?.avatar?.originFileObj
                    ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
                    : adminAvatar
                }
                alt="avt-user"
              />
              <div className="label-name">
                <p className="department">
                  {dataDepartmentCreate
                    .find((d) => d.login_id === userInfo?.login_id && d.main_role === 'main')
                    ?.department_name.replace(/^\d+ /, '')}
                </p>
                <p className="name">{userInfo?.name}</p>
              </div>
              <p className="number-like">
                <LikeOutlined className="like-icon" />0
              </p>
            </div>
          </div>
          <div className="form">
            <Form colon={false} className="form-setting">
              <Form.Item name="knowledge_title">
                <label className="text-label">
                  タイトル
                  <span className="require">*</span>
                </label>
                <TextArea
                  name="knowledge_title"
                  className="input-textarea-group"
                  showCount
                  maxLength={100}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <div className="wrap-recruitment">
                <Form.Item name="to_department_id" className="form-input">
                  <label className="text-label">
                    To 所属<span className="require"> *</span>
                  </label>
                  <SelectField
                    name="to_department_id"
                    className="pull_down"
                    placeholder="選択してください"
                    onChange={(value) => {
                      formik.setFieldValue('to_user_id', 'ALL');
                      formik.setFieldValue('to_department_id', value);
                    }}
                  >
                    <Option value="ALL">ALL</Option>
                    {dataSelectDepartment?.map((item, index) => (
                      <Option key={index} value={item.affiliation_id}>
                        {item.name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="to_user_id" className="form-input">
                  <label className="text-label">To ユーザー</label>
                  <SelectField
                    name="to_user_id"
                    className="pull_down"
                    placeholder="選択してください"
                    disabled={formik.values.to_department_id === 'ALL'}
                  >
                    <Option value="ALL">ALL</Option>
                    {uniqueUsers?.map((item, index) => (
                      <Option value={item.login_id} key={index}>
                        {item.user_name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              <Form.Item name="knowledge_text">
                <label className="text-label">
                  内容
                  <span className="require">*</span>
                </label>
                <JoditEditor
                  value={formik.values.knowledge_text}
                  config={defaultConfig}
                  onBlur={(newContent) => formik.setFieldValue('knowledge_text', newContent)}
                  onChange={(newContent) => {
                    formik.setFieldValue('knowledge_text', newContent);
                  }}
                />
                {showPopup && (
                  <Modal
                    visible={showPopup}
                    closable={false}
                    onOk={handlePopupClose}
                    onCancel={handlePopupClose}
                    footer={null}
                  >
                    <VideoPopup formik={formik} setOpen={setShowPopup} />
                  </Modal>
                )}
              </Form.Item>
              <div className="wrap-upload">
                <div className="title">
                  <span>ファイル添付</span>（PDF, Excel, Word, Jpeg, png, gif）※10MBまで
                </div>
                <div className={`form-upload ${isDisabledUpload ? 'disabled' : ''}`}>
                  <div className="wrap-dragger">
                    <Dragger
                      showUploadList={false}
                      beforeUpload={() => false}
                      maxCount={5}
                      onChange={(e: UploadChangeParam<UploadFile<File>>) => {
                        setFileList(e.fileList);
                      }}
                    >
                      <div className="file-name">
                        {fileList.map((item, index) => (
                          <div key={index} className="label-name">
                            <Tag
                              closable
                              onClose={(e) => {
                                e.preventDefault();
                                handleClose(item);
                              }}
                            >
                              {item.name.length > 12 ? `${item.name.slice(0, 13)}...` : item.name}
                            </Tag>
                          </div>
                        ))}
                      </div>
                    </Dragger>
                  </div>

                  <div className="button-upload">
                    <Upload
                      showUploadList={false}
                      beforeUpload={() => false}
                      maxCount={5}
                      onChange={(e: UploadChangeParam<UploadFile<File>>) => {
                        setFileList([...fileList, e.file]);
                      }}
                    >
                      参照
                    </Upload>
                  </div>
                </div>
              </div>
              <div className="group-btn">
                <SubmitButton
                  disabled={!(formik.dirty && formik.isValid)}
                  className={`btn ${formik.dirty && formik.isValid ? 'btn-active' : 'btn_submit'}`}
                >
                  教えて！
                </SubmitButton>
                <button className="btn btn-default" onClick={() => navigate(-1)}>
                  キャンセル
                </button>
              </div>
            </Form>
          </div>
          <div className="text-footer">
            <p className="text">教えて！クリックで、Q&Aの投稿が完了します。</p>
          </div>
        </div>
      </FormikProvider>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="登録が完了しました"
        onSubmit={onClose}
      />
    </PostKnowledgeQAStyled>
  );
};

export default CreateKnowledgeQA;

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];
