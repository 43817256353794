import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
}

export default styled.section<Props>`
  margin: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    border-bottom: 1px solid #cccccc;
    .title {
      margin: 0;
      font-size: 16px;
      color: #2a2a2a;
    }
    .btn-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00989a;
      cursor: pointer;
      font-size: 12px;
      text-decoration: underline;
      .icon {
        margin-right: 6px;
        font-size: 16px;
      }
    }
  }
  .form {
    background: #ffffff;
    padding: 40px;
    width: 80%;
    .form-input {
      .text-label {
        font-size: 13px;
        color: #424242;
        .require {
          color: #f0330a;
        }
      }
      .input {
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        height: 40px;
        input {
          color: rgba(42, 42, 42, 1);
        }
      }
      .input-small {
        margin-bottom: 4px;
        width: 30%;
      }
      .ant-input-affix-wrapper-disabled {
        border: 1px solid #eaeaea;
        background-color: #f9f8f8;
        input {
          background: #f9f8f8;
        }
      }
    }
    .mr-0 {
      margin: 0;
    }
    .note {
      font-size: 13px;
      color: #777777;
    }
    .wrap-submit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
      width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
      transition: width 0.3s;
      background-color: #ffffff;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 10;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
      .btn-submit {
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
        font-size: 15px;
        border: none;
        height: 40px;
        padding: 0 50px;
        cursor: pointer;
      }
      .btn-back-info {
        background-color: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        padding: 8px 51px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
`;
