import styled from 'styled-components';

export default styled.section`
  background-color: #ffff;
  height: 100%;
  width: 100%;
  .skill-check-results {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background: #08a3a5;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    .title {
      margin: 0;
      font-size: 18px;
      color: #ffffff;
    }
    .group-btn {
      display: flex;
      .btn {
        width: 160px;
        height: 36px;
        font-weight: 700;
        font-size: 13px;
        cursor: pointer;
      }
      .btn-result {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f1a900;
        margin-right: 8px;
        .icon-right {
          color: #f1a900;
        }
      }
      .btn-active {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #ffffff;
        border: none;
        .icon-cloud {
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }
  }
  .wrap-body {
    padding: 40px 30px;
    .group-select {
      display: flex;
      align-items: center;
      background: #f2f2f2;
      border: 1px solid #eaeaea;
      border-radius: 3px;
      .select {
        padding: 15px 0;
        .label {
          margin: 0;
          padding: 0 5px;
        }
        .item-select {
          width: 100%;
          padding: 0 5px;
        }
      }
      .skill-check {
        width: 35%;
        padding-left: 15px;
      }
      .question {
        width: 35%;
      }
      .answer-result {
        width: 15%;
      }
      .score {
        width: 15%;
        padding-right: 15px;
      }
    }
    .table-result {
      padding: 30px 0;
      .item-table {
        .table-label {
          font-weight: 700;
          font-size: 13px;
          color: #424242;
          margin: 0;
        }
        .row-label {
          height: 55px;
          background-color: #ebebeb;
          border-radius: 2px;
          font-weight: 700;
          font-size: 12px;
          color: #424242;
          .gutter-row {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(85, 85, 85, 0.08);
          }
        }
        .row-number {
          height: 60px;
          background-color: #ffffff;
          border-radius: 2px;
          font-family: Lato;
          font-size: 20px;
          color: #2a2a2a;
          border: 1px solid rgba(85, 85, 85, 0.08);
          .gutter-row {
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;
            border-right: 1px solid rgba(85, 85, 85, 0.08);
          }
        }
      }
    }
    .skill-check-info {
      margin-top: 16px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      border: 1px solid #ccc;
      width: fit-content;

      p {
        margin-bottom: 0px !important;
        font-size: 12px;
        line-height: 24px;
        font-family: Lato;
        color: #424242;
      }

      .title-info {
        display: flex;
        align-items: center;
        padding: 4px 10px 4px 8px;
        border-right: 1px solid #ccc;
      }

      .wrap-info {
        display: flex;
        align-items: center;
        padding: 4px 13px;
        gap: 8px;
      }
    }
  }
  .item-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-size: 20px;
    color: #2a2a2a;
    .label-number {
      margin: 0;
    }
    .label {
      margin: 0;
    }
    .icon-up {
      color: #1788c7;
      margin-right: 10px;
    }
    .icon-down {
      color: #ea3596;
      margin-right: 10px;
    }
  }
  .item-rate {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #2a2a2a;
    .label-number {
      margin: 0;
    }
    .label {
      margin: 0;
    }
  }
  .icon {
    color: #c4c4c4;
    font-size: 18px;
    cursor: pointer;
  }
  .table-row-dark {
    background: #ffffff;
  }
  .table-row-dark {
    background: #f9f9f9;
  }
  .ant-table-tbody .ant-table-cell.acquisition_score {
    font-family: Lato;
    font-size: 20px;
    text-align: center;
    color: #2a2a2a;
  }
  .ant-table-tbody .ant-table-cell {
    font-family: Lato;
  }
  .ant-table-tbody .ant-table-cell.question {
    font-size: 14px;
    text-align: left;
    color: #424242;
  }
  .ant-table-thead .ant-table-cell {
    font-weight: 700;
    font-size: 12px;
    color: #424242;
  }

  .ant-table-cell {
    text-align: center;
  }

  .ant-table-thead tr .average {
    background: #f4fbfb !important;
  }
  .br-1 {
    border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
  }
`;
