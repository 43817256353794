import React, { CSSProperties, useMemo } from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckUserAnalysisReportSelector } from '../../selectors';
import getWidthContainer from 'libs/utils/pieChart';
import { PieChartStyle } from './styles';
import { EmptyPie } from 'assets';

interface Props {
  time?: string;
  isStart?: boolean;
  exportPdf?: boolean;
}

const PieChart: React.FC<Props> = ({ time, isStart, exportPdf }) => {
  const { reportSkillCheckUserTrans } = useSelector(skillCheckUserAnalysisReportSelector);

  const data = useMemo(
    () => (isStart ? reportSkillCheckUserTrans[0]?.start : reportSkillCheckUserTrans[0]?.end),
    [isStart, reportSkillCheckUserTrans]
  );

  return (
    <PieChartStyle>
      <div className="header">
        <span>
          実施期間{isStart ? '①' : '②'}：{time ? dayjs(time).format('YYYY年MM月DD日') : ''}
        </span>
      </div>
      <div className="body-pie">
        {data && time ? (
          <div className="wrap-time">
            <div className="average-answer-time">
              <p className="title">平均解答時間</p>
              <p className="time">
                {data.average_answer_time_per_question
                  ? formatNumberToMinute(data.average_answer_time_per_question)
                  : '-'}
              </p>
            </div>
            <div className="answer-time">
              <p className="title">解答時間</p>
              <p className="time">
                {data?.total_answer_time ? formatNumberToMinute(data.total_answer_time) : '-'}
              </p>
            </div>
          </div>
        ) : null}
        <div className="pie">
          {data && time ? (
            <Pie
              appendPadding={10}
              data={[
                {
                  label: '正解',
                  value: data.correct_answers_num,
                },
                {
                  label: '不正解',
                  value: data.incorrect_answer,
                },
                {
                  label: '未実施',
                  value: data.inexperienced,
                },
              ]}
              angleField="value"
              colorField="label"
              color={['#379BD2', '#EA8C92', '#EBEBEB']}
              radius={1}
              innerRadius={0.64}
              label={{
                type: 'inner',
                offset: '-50%',
                style: {
                  textAlign: 'center',
                },
                autoRotate: false,
                content: '{value}',
              }}
              statistic={{
                title: exportPdf
                  ? {
                      offsetY: 10,
                      style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '16px',
                        fontWeight: 'normal',
                        color: '#2a2a2a',
                        margin: '0px auto 5px auto',
                      },
                      content: `獲得スコア\n${formatNumber(data.acquisition_score)}`,
                    }
                  : {
                      offsetY: 10,
                      customHtml: (container) => {
                        return renderStatistic(
                          getWidthContainer(container, true),
                          data.acquisition_score_chart!,
                          'title',
                          {
                            fontSize: 24,
                            textShadow: 'none',
                            fontWeight: 400,
                            color: '#777777',
                          }
                        );
                      },
                    },
                content: exportPdf
                  ? {
                      style: {
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '13px',
                        fontWeight: 'normal',
                        color: '#424242',
                        paddingTop: '10px',
                      },
                      content: `${formatNumber(data.acquisition_score_rate * 100)}%`,
                    }
                  : {
                      customHtml: (container, _view) => {
                        return renderStatistic(
                          getWidthContainer(container, false),
                          formatNumber(data.acquisition_score_rate * 100),
                          'content',
                          {
                            fontSize: 13,
                            fontWeight: 400,
                            color: '#777777',
                          }
                        );
                      },
                    },
              }}
              legend={false}
              width={200}
              height={200}
            />
          ) : (
            <img src={EmptyPie} className="empty-pie" alt="empty-pie" />
          )}
        </div>
        {data && time ? (
          <div className="legend">
            <div className="row">
              <div className="rec green" />
              <span className="label">正解</span>
            </div>
            <div className="row">
              <div className="rec pink" />
              <span className="label">不正解</span>
            </div>
            <div className="row">
              <div className="rec gray" />
              <span className="label">未経験</span>
            </div>
          </div>
        ) : null}
      </div>
    </PieChartStyle>
  );
};

const renderStatistic = (
  containerWidth: number,
  text: string | number,
  type: 'title' | 'content',
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }
  if (type === 'title') {
    return `<div style="display: flex;flex-direction: column;align-items: center;justify-content: center;width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><p style="font-weight: 400;margin: 0;font-size: 11px;color: #777777;">獲得スコア</p><h4 style="font-weight: 400;margin: 0;font-size: 24px;color: #2A2A2A;">${text}</h4><div style="width: 15px; height: 2px; background-color: #DDDDDD;margin: 5px auto 0 auto;" /></div>`;
  } else {
    return `<div style="width:${containerWidth}px;font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };"><span style="font-weight: 400;font-size: 13px;color: #424242;">${text}%</span></div>`;
  }
};

export default PieChart;
