import styled from 'styled-components';

interface Props {
  isCheckUpload: boolean;
}

export default styled.section<Props>`
  background-color: #f9f8f8;
  .jodit-toolbar-button_image,
  .jodit-toolbar-button_insertVideo {
    pointer-events: ${(props) => (props.isCheckUpload ? 'none' : '')};
  }
  .btn-header {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    margin: 40px 30px 0 30px;
    .group-btn-left {
      display: flex;
      align-items: center;
      .btn {
        padding: 5px 10px;
        background: #f03060;
        border-radius: 4px;
        font-weight: 700;
        font-size: 12px;
        color: #ffffff;
        .icon {
          font-size: 16px;
          padding-right: 5px;
        }
      }
      .un-answer {
        background: #f03060;
      }
      .check {
        background: #1fb9db;
      }
      .comment {
        background: #ed7200;
        margin-left: 5px;
      }
      .btn-back {
        padding-left: 10px;
        font-size: 14px;
        color: #777777;
        cursor: pointer;
        border: none;
        .icon {
          color: #c4c4c4;
        }
      }
    }
    .group-btn-right {
      display: flex;
      align-items: flex-end;
      .btn {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 3px;
        cursor: pointer;
      }
      .like {
        margin-right: 15px;
        font-size: 13px;
        color: #08a3a5;
        .icon {
          padding-right: 5px;
        }
      }
      .star {
        font-size: 12px;
        color: #777777;
        .icon {
          font-size: 16px;
          padding-right: 5px;
          color: #e1e1e1;
        }
      }
      .icon-star,
      .star-gold {
        font-size: 16px;
        padding-right: 10px;
      }
      .star-gold {
        color: #f6ac00 !important;
      }
      .export-btn {
        border: 1px solid #f6ac00;
        color: #f6ac00;
        font-weight: 700;
        padding: 8px 13px;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        height: 36px;
        margin-left: 20px;
      }
    }
  }
  .container {
    margin: 0 30px 40px 30px;
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .wrap-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      .title {
        display: flex;
        align-items: center;
        padding-left: 3px;
        color: #0eaf58;
        height: 60px;
        .divider {
          height: 95%;
          width: 3px;
          background: #0eaf58;
          margin-right: 15px;
          border-radius: 2px;
        }
      }
      .info {
        display: flex;
        align-items: center;
        padding-right: 10px;
        .label {
          font-size: 11px;
          color: #999999;
          margin: 0;
        }
        .avt-icon {
          border-radius: 50%;
          margin: 0 5px;
          width: 40px;
          height: 40px;
        }
        .label-name {
          .department {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            font-size: 11px;
            color: #777777;
          }
          .name {
            max-width: 90px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 13px;
            margin: 0;
            color: #424242;
          }
        }
        .number-like {
          padding-left: 15px;
          font-size: 11px;
          color: #08a3a5;
          margin-bottom: -13px;
        }
      }
    }
    .form {
      padding: 50px 40px 0 40px;
      .form-setting {
        margin-top: 12px;
        .wrap-upload {
          width: 50%;
          padding-top: 20px;
          .title {
            text-align: left;
            color: #999999;
            font-size: 13px;
            padding-bottom: 4px;
            span {
              color: #424242;
            }
          }
          .form-upload {
            display: flex;
            align-items: center;
            width: 85%;
            .ant-upload.ant-upload-drag {
              background: none !important;
              border: none !important;
            }
            .wrap-dragger {
              width: 100%;
            }
          }
        }
        .file-name {
          display: flex;
          align-items: center;
          padding-left: 12px;
          min-width: 350px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #cccccc;
          box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
          border-radius: 3px 0 0 3px;
          color: #cccccc;
        }
        .button-upload {
          background: #00a3a5;
          height: 40px;
          width: 15%;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 0 3px 3px 0;
          cursor: pointer;
          .ant-upload {
            width: 100%;
            color: #ffffff;
          }
        }
        .ant-form-item {
          margin-bottom: 0;
        }
        .input-textarea-group {
          font-size: 14px;
          color: #2a2a2a;
          ::placeholder {
            font-size: 14px;
            color: #cccccc;
          }
        }
        .content {
          .ant-input {
            min-height: 320px;
          }
        }
        .text-label {
          font-size: 13px;
          color: #222222;
          display: block;
          margin: 8px 0;
          .require {
            color: #f0330a;
            font-size: 20px;
          }
          .detail {
            font-size: 13px;
            color: #999999;
          }
        }
      }
      .wrap-recruitment {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;
        .form-input {
          width: 48%;
          display: flex;
          flex-direction: column;
          .text-input {
            font-size: 14px;
          }
          .text-label-content {
            font-size: 12px;
            color: #777777;
            margin: 0 10px 0 7px;
          }
          .require {
            color: #f0330a;
            font-size: 20px;
          }
        }
      }
      .detail {
        width: 40%;
        .group {
          display: flex;
          .input-image {
            border-radius: 4px 0 0 4px;
          }
          .btn-active {
            width: 80px;
            background: #00a3a5;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 0 5px 5px 0;
            border: none;
            font-size: 13px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
    .group-btn {
      display: flex;
      justify-content: center;
      padding-top: 50px;
      .btn {
        width: 200px;
        height: 50px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
      }
      .btn-active {
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        margin-right: 10px;
        font-weight: 700;
        font-size: 15px;
        color: #ffffff;
      }
      .btn_submit {
        background: #eeeeee;
        margin-right: 10px;
        font-weight: 700;
        font-size: 15px;
        color: #bbbbbb;
      }
      .btn-default {
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        font-size: 13px;
        color: #777777;
      }
    }
    .text-footer {
      text-align: center;
      padding: 10px 0 50px 0;
      .text {
        font-size: 13px;
        color: #777777;
      }
    }
  }
`;
