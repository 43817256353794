import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { getReportSkillCheck } from '../thunk';
import { UserType } from 'types/config';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import Table from './Table';
import Chart from './Chart';

interface Props {
  startPeriod?: string;
  exportPdf?: boolean;
  endPeriod?: string;
  groupCode?: string;
  userType: UserType;
  segmented: number;
  loginID?: string;
}

const MultipleComparison: React.FC<Props> = ({
  startPeriod,
  segmented,
  endPeriod,
  groupCode,
  exportPdf,
  userType,
  loginID,
}) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);

  const isQuestionCodeTable = useMemo(
    () => !!loginID && !!groupCode && loginID === 'ALL' && groupCode !== 'ALL',
    [groupCode, loginID]
  );

  useEffect(() => {
    if (!userInfo || !loginID || !groupCode || !startPeriod || !endPeriod) return;
    (async () => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo.company_id],
        },
        {
          id: 'user_type',
          search_value: [userType],
        },
        {
          conditions: [
            {
              conditions: [
                {
                  id: 'implementation_date',
                  search_value: [dayjs(startPeriod).startOf('day').toISOString(), null],
                },
                {
                  id: 'implementation_date',
                  search_value: [null, dayjs(startPeriod).endOf('day').toISOString()],
                },
              ],
            },
            {
              conditions: [
                {
                  id: 'implementation_date',
                  search_value: [dayjs(endPeriod).startOf('day').toISOString(), null],
                },
                {
                  id: 'implementation_date',
                  search_value: [null, dayjs(endPeriod).endOf('day').toISOString()],
                },
              ],
            },
          ],
          use_or_condition: true,
        },
      ];

      if (loginID && loginID !== 'ALL') {
        conditions.push({
          id: 'login_id',
          search_value: [loginID],
          exact_match: true,
        });
      }
      if (groupCode && groupCode !== 'ALL') {
        conditions.push({
          id: 'grouping_code',
          search_value: [groupCode],
        });
      }

      await dispatch(
        getReportSkillCheck({
          conditions,
          page: 1,
          per_page: 0,
          startPeriod,
          endPeriod,
          sort_fields: [
            {
              id: 'implementation_date',
              order: 'asc',
            },
          ],
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, loginID, groupCode, userType, startPeriod, endPeriod]);

  return segmented === 0 ? (
    <Table
      isQuestionCodeTable={isQuestionCodeTable}
      startPeriod={startPeriod}
      endPeriod={endPeriod}
      exportPdf={exportPdf}
    />
  ) : (
    <Chart startPeriod={startPeriod} endPeriod={endPeriod} exportPdf={exportPdf} />
  );
};

export default MultipleComparison;
