import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { maxBy } from 'lodash';

import { createAffiliationLevel, getAffiliations } from 'pages/Settings/AffiliationMaster/thunk';
import { memoizedGetFlatDataFromTree } from 'libs/utils/affiliation/memoized-tree-data-utils';
import { CustomDragLayer } from '../Curriculum/NodeTreeView/NodeRenderer/CustomDragLayer';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import { Wrapper, EmptyWrapper } from './styles';
import { NodeRenderer } from './NodeRenderer';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  treeData: Types.TreeItem<Types.AffiliationItemType>;
  onResetAffiliationCreated: () => void;
}

const NODE_HEIGHT = 56;

const AffiliationTreeView: React.FC<Props> = ({ treeData, onResetAffiliationCreated }) => {
  const [data, setData] = useState<{ [key: string]: Types.TreeItem<Types.AffiliationItemType>[] }>(
    {}
  );

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const onDrop = async (name: string, node: Types.AffiliationItemType, maxSortOrder: number) => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      createAffiliationLevel({
        item: {
          name,
          sort_order: maxSortOrder,
          level: node.columnIndex! + 2,
          company_id: userInfo?.company_id,
          affiliation_parent_id: node.affiliation_id,
          createdby: userInfo?.login_id,
          createdat: new Date(),
        },
      })
    );
    if (createAffiliationLevel.fulfilled.match(resultAction)) {
      onResetAffiliationCreated();
      await dispatch(
        getAffiliations({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
    }
    dispatch(stopLoading());
  };

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.AffiliationItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });

    dataPrimitive.forEach((item: Types.TreeItem<Types.AffiliationItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });

    setData(column);
  }, [treeData]);

  if (!data[0]) {
    return null;
  }

  return (
    <Wrapper className="rst__tree">
      <div className="wrap_node_tree">
        {Array.from({
          length: Object.keys(data).length,
        }).map((_, columIndex) => (
          <div key={columIndex} className="column">
            {Array.from({
              length: maxBy(data[columIndex], 'lineIndex')?.lineIndex || 0,
            }).map((__, lineIndex) => {
              const row = data[columIndex].find((r) => r.lineIndex! - 1 === lineIndex);
              if (!row) {
                return <EmptyWrapper key={lineIndex} nodeHeight={NODE_HEIGHT} />;
              }

              return (
                <NodeRenderer
                  key={lineIndex}
                  node={row}
                  onDrop={onDrop}
                  nodeHeight={NODE_HEIGHT}
                  maxSortOrder={
                    (maxBy(data[columIndex + 1], (o) => o.node?.sort_order)?.node?.sort_order ||
                      0) + 1
                  }
                />
              );
            })}
          </div>
        ))}
        <CustomDragLayer nodeHeight={NODE_HEIGHT} />
      </div>
    </Wrapper>
  );
};

export default AffiliationTreeView;
