import styled from 'styled-components';

export default styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .logo {
    width: 150px;
    margin-bottom: 20px;
  }
  .register {
    color: #424242;
    font-size: 15px;
    margin: 0;
  }
  .wrap-description {
    margin: 30px auto;
    .description {
      font-size: 13px;
      color: #424242;
      text-align: center;
      margin: 0;
    }
  }
  .form {
    .input-email {
      background: #f5f5f5;
      box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
      margin: 10px 0;
      border-color: #f5f5f5;
      input {
        background: #f5f5f5;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
        :focus {
          background-color: #ffffff;
        }
      }
      &.ant-input-affix-wrapper-focused {
        background: #fff;
      }
    }
    .mail-icon {
      color: #bbbbbb;
      font-size: 16px;
      margin-right: 4px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-input-affix-wrapper {
      margin-bottom: 0;
    }
    .btn-submit {
      background: #f8b824;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-weight: bold;
      border: none;
      margin-top: 32px;
      width: 360px;
      height: 48px;
      &:not([disabled]):hover {
        background: #f8b824;
      }
      &[disabled] {
        background: #eee;
        color: #bbb;
      }
    }
  }
  .wrap-submited {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .check-circle-icon {
      font-size: 66px;
      color: rgba(8, 163, 165, 1);
      margin: 40px 0;
    }
    .wrap-text-submited {
      width: 100%;
      padding: 0 40px;
      text-align: center;
      p {
        color: #424242;
        font-size: 13px;
        margin: 0;
      }
    }
  }
`;
