import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import * as Types from 'types';
import {
  getDataInterviewUser,
  getSelectAffiliation,
  getSelectPosition,
  getSelectRole,
  getDataSkillCheck,
  getDataBatchSetting,
  getDataInterviewDetail,
  getDataCurriculumBatch,
  getDataSelectCreator,
  getDataSelectCurriculum,
  getDataSkillCheckBatch,
  getDataSelectSkillCheckGroupingCode,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckStartPeriod,
  getDataSelectSkillCheckCategory,
  getDataSelectCheckUseStatus,
  getDataSelectSkillCheckCreator,
  getDataSelectInterviewUser,
  getDataSelectHireDate,
  getDataUserAssignSkillCheck,
  getDataCompany,
} from './thunk';

export interface InitialState {
  total: number;
  dataInterviewUser: Array<Types.InterviewUser.ResponseType>;
  dataSkillCheckSetting: Array<Types.SkillCheck.ResponseType>;
  dataSelectRole: Array<Types.SelectRole.ResponseType>;
  dataSelectPosition: Array<Types.SelectPosition.ResponseType>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataBatchSetting: Array<Types.BatchSettingType>;
  dataSkillUserCheck: Array<Types.DataCheckType>;
  dataCurriculumBatch: Array<Types.Curriculums.ResponseType>;
  dataSelectCurriculum: Array<Types.SelectRequiredCurriculum.ResponseType>;
  dataSelectCreator: Array<Types.SelectRequiredCurriculumCreator.ResponseType>;
  dataSelectHireDate: Array<Types.SelectHireDate.ResponseType>;
  dataSkillCheckBatch: Array<Types.SkillCheck.ResponseType>;
  dataSelectSkillCheckGroupingCode: Array<Types.SelectSkillCheckGroupingCode.ResponseType>;
  dataSelectSkillCheck: Array<Types.SelectSkillCheck.ResponseType>;
  dataSelectSkillCheckStartPeriod: Array<Types.SelectSkillCheckStartPeriod.ResponseType>;
  dataSelectSkillCheckCategory: Array<Types.SelectSkillCheckCategory.ResponseType>;
  dataSelectSkillCheckUseStatus: Array<Types.SelectSkillCheckUseStatus.ResponseType>;
  dataSelectSkillCheckCreator: Array<Types.SelectSkillCheckCreator.ResponseType>;
  dataSelectInteviewUser: Array<Types.SelectInterviewUser.ResponseType>;
  dataUserAssignSkillCheck: Array<Types.UserAssignSkillCheck.ResponseType>;
  companies: Array<Types.Companies.ResponseType>;
  countUserInterview: number;
}

const initialState: InitialState = {
  total: 0,
  dataInterviewUser: [],
  dataSkillCheckSetting: [],
  dataSelectRole: [],
  dataSelectPosition: [],
  dataSelectDepartment: [],
  dataBatchSetting: [],
  dataSkillUserCheck: [],
  dataCurriculumBatch: [],
  dataSelectCurriculum: [],
  dataSelectCreator: [],
  dataSkillCheckBatch: [],
  dataSelectSkillCheckGroupingCode: [],
  dataSelectSkillCheck: [],
  dataSelectSkillCheckStartPeriod: [],
  dataSelectSkillCheckCategory: [],
  dataSelectSkillCheckUseStatus: [],
  dataSelectSkillCheckCreator: [],
  dataSelectInteviewUser: [],
  dataSelectHireDate: [],
  dataUserAssignSkillCheck: [],
  companies: [],
  countUserInterview: 0,
};

export const interviewUserSlice = createSlice({
  name: 'interviewUser-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataInterviewUser.fulfilled, (state, action) => {
      state.dataInterviewUser = action.payload.report_results;
      state.total = action.payload.totalItems;
      action.payload.report_results.forEach((e) => {
        if (e.user_type === 'interview') {
          state.countUserInterview += 1;
        }
      });
    });

    builder.addCase(getDataInterviewDetail.fulfilled, (state, action) => {
      state.dataSkillUserCheck = unionBy(
        action.payload.report_results
          .filter((i) => i.item_ref?.hidden_skill_check?.i_id)
          .map((item) => ({
            ...item,
            hidden_item_id: item.item_ref!.hidden_skill_check?.i_id,
            item_code: item.skill_check_code,
            item_name: item.skill_check_name,
            hidden_item: item.hidden_skill_check,
            hidden_skill_check: item.hidden_skill_check,
          })),
        'hidden_item_id'
      );
    });
    builder.addCase(getDataSkillCheck.fulfilled, (state, action) => {
      state.dataSkillCheckSetting = action.payload.items;
      state.total = action.payload.totalItems;
    });
    builder.addCase(getSelectPosition.fulfilled, (state, action) => {
      state.dataSelectPosition = action.payload.report_results;
    });

    builder.addCase(getSelectAffiliation.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });

    builder.addCase(getSelectRole.fulfilled, (state, action) => {
      state.dataSelectRole = action.payload.report_results;
    });

    builder.addCase(getDataBatchSetting.fulfilled, (state, action) => {
      const newDataBatchSetting: Array<Types.BatchSettingType> = [];
      action.payload.report_results.length &&
        action.payload.report_results.forEach((item) => {
          const itemIndex = newDataBatchSetting.findIndex((c) => c.i_id === item.i_id);
          if (itemIndex >= 0) {
            if (
              item.skill_check_code &&
              !newDataBatchSetting[itemIndex].skill_check_codes.some(
                ({ code }) => code === item.skill_check_code
              )
            ) {
              newDataBatchSetting[itemIndex].skill_check_codes.push({
                code: item.skill_check_code,
                name: item.skill_check_name,
              });
            }
          } else {
            newDataBatchSetting.push({
              i_id: item.i_id,
              name: item.name || '',
              manage_code: item.manage_code || '',
              kana: item.kana || '',
              department_name: item.department_name || '',
              position_code: item.position_code || '',
              skill_check_name: item.skill_check_name || '',
              skill_check_codes:
                item.skill_check_code && item.skill_check_name
                  ? [{ code: item.skill_check_code, name: item.skill_check_name }]
                  : [],
              login_id: item.login_id,
              company_id: item.company_id,
              grouping_code: item.grouping_code,
              work_history: item.work_history,
            });
          }
        });
      state.dataBatchSetting = newDataBatchSetting;

      state.total = action.payload.totalItems;
    });
    builder.addCase(getDataSelectCurriculum.fulfilled, (state, action) => {
      state.dataSelectCurriculum = action.payload.report_results;
    });
    builder.addCase(getDataSelectCreator.fulfilled, (state, action) => {
      state.dataSelectCreator = unionBy(action.payload.report_results);
    });
    builder.addCase(getDataCurriculumBatch.fulfilled, (state, action) => {
      state.dataCurriculumBatch = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(getDataSkillCheckBatch.fulfilled, (state, action) => {
      state.dataSkillCheckBatch = action.payload.items;
      state.total = action.payload.totalItems;
    });
    builder.addCase(getDataSelectSkillCheckGroupingCode.fulfilled, (state, action) => {
      state.dataSelectSkillCheckGroupingCode = action.payload.report_results;
    });
    builder.addCase(getDataSelectSkillCheck.fulfilled, (state, action) => {
      state.dataSelectSkillCheck = action.payload.report_results;
    });
    builder.addCase(getDataSelectSkillCheckStartPeriod.fulfilled, (state, action) => {
      state.dataSelectSkillCheckStartPeriod = action.payload.report_results;
    });
    builder.addCase(getDataSelectSkillCheckCategory.fulfilled, (state, action) => {
      state.dataSelectSkillCheckCategory = action.payload.report_results;
    });
    builder.addCase(getDataSelectCheckUseStatus.fulfilled, (state, action) => {
      state.dataSelectSkillCheckUseStatus = action.payload.report_results;
    });
    builder.addCase(getDataSelectSkillCheckCreator.fulfilled, (state, action) => {
      state.dataSelectSkillCheckCreator = action.payload.report_results;
    });
    builder.addCase(getDataSelectInterviewUser.fulfilled, (state, action) => {
      state.dataSelectInteviewUser = action.payload.report_results;
    });
    builder.addCase(getDataSelectHireDate.fulfilled, (state, action) => {
      state.dataSelectHireDate = action.payload.report_results;
    });
    builder.addCase(getDataUserAssignSkillCheck.fulfilled, (state, action) => {
      state.dataUserAssignSkillCheck = action.payload.items;
    });
    builder.addCase(getDataCompany.fulfilled, (state, action) => {
      state.companies = action.payload.items;
    });
  },
});

export const { resetInitialState } = interviewUserSlice.actions;

export default interviewUserSlice.reducer;
