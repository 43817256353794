import React, { useEffect, useState } from 'react';
import { Column } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import dayjs from 'dayjs';

import { skillCheckUserAnalysisReportSelector } from '../../../selectors';
import { formatNumber } from 'libs/utils/format';
import BreakdownStyled from './styles';

const BreakdownOfAnswers: React.FC = () => {
  const [tableData, setTableData] = useState<{ date: string; value: number; type: string }[]>([]);

  const { userAnalysisComparison } = useSelector(skillCheckUserAnalysisReportSelector);

  useEffect(() => {
    const groupData = groupBy(
      userAnalysisComparison.data,
      (e) => e.implementation_date?.split('T')[0]
    );

    setTableData(
      Object.keys(groupData)
        .filter(Boolean)
        .flatMap((key) => {
          const record = groupData[key];

          return [
            {
              date: dayjs(key).format('YYYY/MM/DD'),
              value: Number(
                formatNumber(
                  (record.reduce((prev, next) => next.inexperienced_rate + prev, 0) /
                    record.length) *
                    100
                )
              ),
              type: '未経験率',
            },
            {
              date: dayjs(key).format('YYYY/MM/DD'),
              value: Number(
                formatNumber(
                  (record.reduce((prev, next) => next.wrong_rate + prev, 0) / record.length) * 100
                )
              ),
              type: '不正解率',
            },
            {
              date: dayjs(key).format('YYYY/MM/DD'),
              value: Number(
                formatNumber(
                  (record.reduce((prev, next) => next.accuracy_rate + prev, 0) / record.length) *
                    100
                )
              ),
              type: '正解率',
            },
          ];
        })
    );
  }, [userAnalysisComparison]);

  return (
    <BreakdownStyled>
      <div className="color-chart">
        <div className="item-color">
          <div className="color correct-answer"></div>
          <p className="name">正解率</p>
        </div>
        <div className="item-color">
          <div className="color incorrect-answer"></div>
          <p className="name">不正解率</p>
        </div>
        <div className="item-color">
          <div className="color inexperienced"></div>
          <p className="name">未経験率</p>
        </div>
      </div>
      <Column
        isStack
        data={tableData}
        xField="date"
        yField="value"
        seriesField="type"
        maxColumnWidth={40}
        color={['#EBEBEB', '#ea959b', '#41a1d6']}
        appendPadding={[0, 100, 0, 0]}
        label={{
          position: 'middle',
          formatter: (item) => `${item.value}%`,
          style: {
            fontSize: 12,
            fill: '#424242',
          },
        }}
        legend={false}
        yAxis={{
          position: 'left',
          max: 100,
          min: 0,
          line: {
            style: {
              stroke: '#ddd',
            },
          },
          label: {
            style: {
              color: '#999999',
              fontSize: 12,
            },
            formatter: (item) => `${item}%`,
          },
          grid: {
            line: {
              style: {
                stroke: '#ddd',
                lineDash: [2, 2],
              },
            },
          },
          tickInterval: 10,
          tickLine: null,
          showLast: true,
        }}
        xAxis={{
          label: {
            style: {
              color: '#424242',
              fontSize: 12,
            },
          },
          tickLine: null,
        }}
      />
    </BreakdownStyled>
  );
};

export default BreakdownOfAnswers;
