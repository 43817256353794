import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  USERS,
  SELECT_ROLE,
  SELECT_POSITION,
  SELECT_DEPARTMENT,
  AFFILIATION_ASSIGN_ROLE,
  USER_ASSIGN_SKILL_CHECK,
  INTERVIEW_USER,
  INTERVIEW_USER_EXPORT,
  CURRICULUMS,
  SELECT_REQUIRED_CURRICULUM_CREATOR,
  SELECT_REQUIRED_CURRICULUM,
  INTERVIEW_USER_DETAIL,
  SKILL_CHECK,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_SKILL_CHECK,
  INTERVIEW_SKILL_CHECK,
  SELECT_SKILL_CHECK_START_PERIOD,
  SELECT_SKILL_CHECK_CATEGORY,
  SELECT_SKILL_CHECK_USE_STATUS,
  SELECT_SKILL_CHECK_CREATOR,
  SELECT_INTERVIEW_USER,
  SELECT_HIRE_DATE,
  ATTACH,
  COMPANIES,
} from 'configs';

export const getDataInterviewUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.InterviewUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.InterviewUser.ResponseType>(
      INTERVIEW_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectInterviewUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.InterviewUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getSelectInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.InterviewUser.ResponseType>(
      INTERVIEW_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectPosition = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectPosition.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getSelectPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectPosition.ResponseType>(
      SELECT_POSITION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectAffiliation = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getSelectAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectRole = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRole.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getSelectRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRole.ResponseType>(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteInterviewUser = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/thunk/deleteInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USERS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createInterviewUser = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/createInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/createAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUser = createAsyncThunk<
  Types.AddUserResponse,
  Types.AddUserRequest,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/addUserTest', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addUser(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getDataBatchSetting = createAsyncThunk<
  Types.ReportsItemResponseType<Types.InterviewSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/batchSetting', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.InterviewSkillCheck.ResponseType>(
      INTERVIEW_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createUserAssignSkillCheck = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/createUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataInterviewExport = createAsyncThunk<
  Types.ReportsItemResponseType<Types.InterviewUserExport.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataInterviewExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.InterviewUserExport.ResponseType>(
      INTERVIEW_USER_EXPORT.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataInterviewDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.InterviewUserDetail.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataInteviewDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.InterviewUserDetail.ResponseType>(
      INTERVIEW_USER_DETAIL.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumBatch = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculums.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataCurriculumBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculumCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculumCreator.ResponseType>(
      SELECT_REQUIRED_CURRICULUM_CREATOR.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculum.ResponseType>(
      SELECT_REQUIRED_CURRICULUM.name,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getSubPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateSkillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/updateSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_SKILL_CHECK.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateInterview = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/updateInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/updateAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckBatch = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSkillCheckBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckGroupingCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckGroupingCode.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/get/getDataSelectSkillCheckGroupingCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckGroupingCode.ResponseType>(
      SELECT_SKILL_CHECK_GROUPING_CODE.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectSkillCheckStartPeriod = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckStartPeriod.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectSkillCheckStartPeriod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckStartPeriod.ResponseType>(
      SELECT_SKILL_CHECK_START_PERIOD.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectSkillCheckCategory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCategory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectSkillCheckCategory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCategory.ResponseType>(
      SELECT_SKILL_CHECK_CATEGORY.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectCheckUseStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckUseStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectCheckUseStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckUseStatus.ResponseType>(
      SELECT_SKILL_CHECK_USE_STATUS.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectSkillCheckCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thumk/getDataSelectSkillCheckCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCreator.ResponseType>(
      SELECT_SKILL_CHECK_CREATOR.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectInterviewUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectInterviewUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataSelectInterviewUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectInterviewUser.ResponseType>(
      SELECT_INTERVIEW_USER.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const linkUser = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/linkUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_SKILL_CHECK.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataSelectHireDate = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectHireDate.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/selectHireDate', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectHireDate.ResponseType>(
      SELECT_HIRE_DATE.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getDataUserAssignSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignSkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
      USER_ASSIGN_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deleteLinkUser = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/deleteLinkUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_SKILL_CHECK.id, req);

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getAttachInterviewFile = createAsyncThunk<
  Types.GetItemResponseType<Types.Attach.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getAttachInterviewFile', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Attach.ResponseType>(ATTACH.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUsers = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const removeAffiliationInterview = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/removeAffiliationInterview', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const sendLoginUrl = createAsyncThunk<
  any,
  Types.SendLoginUrlRequest,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/sendLoginUrl', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.sendLoginUrl(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCompany = createAsyncThunk<
  Types.GetItemResponseType<Types.Companies.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/getDataCompany', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Companies.ResponseType>(COMPANIES.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const encryptionInterviewUser = createAsyncThunk<
  any,
  Types.EncryptionRequest,
  Types.ThunkAPI<Types.requestError>
>('Interview/thunk/encryptionInterviewUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.encryption(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
