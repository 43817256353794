import { createSlice } from '@reduxjs/toolkit';
import { unionBy } from 'lodash';

import { memoizedConvertFlatDataFromTree } from 'libs/utils/trainingCurriculum/memoized-tree-data-utils';
import * as Types from 'types';
import {
  createCurriculumTrans,
  getOptionCurriculum,
  getOptionUsers,
  getOptionDepartment,
  createQuestionTrans,
  getDataCompulsoryCurriculumImple,
  getCurriculumImple,
  editCurriculumTrans,
  getData,
  getDataRequired,
  getDataAffiliation,
  getDataCorrectQues,
} from './thunk';

export interface InitialState {
  dataTrainingRequiredCurriculum: Types.TreeItem<Types.TrainingCurriculumItemType>[];
  dataTrainingCurriculum: Types.TreeItem<Types.TrainingCurriculumItemType>[];
  questionSelected: Types.TreeItem<Types.TrainingCurriculumItemType>[];
  dataSelectCurriculum: Types.SelectCurriculums.ResponseType[];
  dataCurriculumImple: Types.RequiredCurriculumImple3.ResponseType[];
  dataSelectDepartment: Types.SelectDepartment.ResponseType[];
  dataCreate?: Types.CreateItemResponseType;
  dataEdit?: Types.CurriculumTrans.ResponseType;
  dataSelectCreator: Types.SelectUsers.ResponseType[];
  dataImplementation: Array<Types.CurriculumImple.ResponseType>;
  dataAllQuestion: Array<Types.CurriculumImple.ResponseType>;
  dataCreateQuestionTrans?: Types.CreateItemResponseType;
  dataQuestionImplement: Types.QuestionTrans2.ResponseType[];
  dataAffiliationAssign: Types.AffiliationAssignLevel.ResponseType[];
}

const initialState: InitialState = {
  dataSelectCurriculum: [],
  dataSelectDepartment: [],
  dataSelectCreator: [],
  dataTrainingCurriculum: [],
  dataTrainingRequiredCurriculum: [],
  dataCurriculumImple: [],
  questionSelected: [],
  dataImplementation: [],
  dataAllQuestion: [],
  dataQuestionImplement: [],
  dataAffiliationAssign: [],
};

export const trainingCurriculumSlice = createSlice({
  name: 'trainingCurriculum-page',
  initialState,
  reducers: {
    setQuestionSelected: (state, action) => {
      state.questionSelected = action.payload;
    },
    resetDataEdit: (state) => {
      state.dataEdit = undefined;
    },
    resetDataCreate: (state) => {
      state.dataCreate = undefined;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getOptionCurriculum.fulfilled, (state, action) => {
      state.dataSelectCurriculum = action.payload.report_results;
    });
    builder.addCase(getOptionUsers.fulfilled, (state, action) => {
      state.dataSelectCreator = action.payload.report_results;
    });
    builder.addCase(getOptionDepartment.fulfilled, (state, action) => {
      state.dataSelectDepartment = action.payload.report_results;
    });

    builder.addCase(getDataAffiliation.fulfilled, (state, action) => {
      state.dataAffiliationAssign = action.payload.items;
    });

    builder.addCase(getData.fulfilled, (state, action) => {
      const newCurriculum: Array<Types.TreeItem<Types.TrainingCurriculumItemType>> = [];
      action.payload.items.forEach((item) => {
        const objectTrainingCurriculum: Types.TreeItem<any> = {
          i_id: item.i_id,
          curriculum_code: item.curriculum_code,
          name: item.curriculum_name,
          description: item.curriculum_description,
          publish: item.publish,
          required_curriculum: item.required_curriculum,
          columnIndex: 0,
          correct_true: Number(item.correct),
          login_id: item.login_id,
          curriculum_file: item.curriculum_file,
          curriculum_trans_id: item.curriculum_trans_id,
          i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
          implementation_date: item.implementation_date,
          official_curriculum: item.official_curriculum,
          official_curriculum_code: item.official_curriculum_code,
          children: item.level1_i_id
            ? [
                {
                  i_id: item.level1_i_id,
                  name: item.level1_name,
                  code: item.level1_code,
                  curriculum_code: item.curriculum_code,
                  publish: item.publish,
                  required_curriculum: item.required_curriculum,
                  columnIndex: 1,
                  curriculum_name: item.curriculum_name,
                  login_id: item.login_id,
                  curriculum_trans_id: item.curriculum_trans_id,
                  i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
                  implementation_date: item.implementation_date,
                  children: item.level2_i_id
                    ? [
                        {
                          i_id: item.level2_i_id,
                          name: item.level2_name,
                          code: item.level2_code,
                          curriculum_name: item.curriculum_name,
                          curriculum_code: item.curriculum_code,
                          publish: item.publish,
                          required_curriculum: item.required_curriculum,
                          columnIndex: 2,
                          login_id: item.login_id,
                          curriculum_trans_id: item.curriculum_trans_id,
                          i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
                          implementation_date: item.implementation_date,
                          children: item.level3_i_id
                            ? [
                                {
                                  i_id: item.level3_i_id,
                                  name: item.level3_name,
                                  code: item.level3_code,
                                  curriculum_name: item.curriculum_name,
                                  curriculum_code: item.curriculum_code,
                                  publish: item.publish,
                                  required_curriculum: item.required_curriculum,
                                  columnIndex: 3,
                                  login_id: item.login_id,
                                  curriculum_trans_id: item.curriculum_trans_id,
                                  i_id_item_ref_curriculum_trans:
                                    item.i_id_item_ref_curriculum_trans,
                                  implementation_date: item.implementation_date,
                                  children: item.level4_i_id
                                    ? [
                                        {
                                          i_id: item.level4_i_id,
                                          name: item.level4_name,
                                          code: item.level4_code,
                                          curriculum_name: item.curriculum_name,
                                          curriculum_code: item.curriculum_code,
                                          publish: item.publish,
                                          required_curriculum: item.required_curriculum,
                                          columnIndex: 4,
                                          login_id: item.login_id,
                                          curriculum_trans_id: item.curriculum_trans_id,
                                          i_id_item_ref_curriculum_trans:
                                            item.i_id_item_ref_curriculum_trans,
                                          implementation_date: item.implementation_date,
                                          children: item.question_code_i_id
                                            ? [
                                                {
                                                  i_id: item.question_code_i_id,
                                                  curriculum_code: item.curriculum_code,
                                                  name: item.question_name,
                                                  curriculum_name: item.curriculum_name,
                                                  code: item.question_code,
                                                  level4_code: item.level4_code,
                                                  publish: item.publish,
                                                  correct_true: Number(item.correct),
                                                  curriculum_trans_id: item.curriculum_trans_id,
                                                  i_id_item_ref_curriculum_trans:
                                                    item.i_id_item_ref_curriculum_trans,
                                                  login_id: item.login_id,
                                                  implementation_date: item.implementation_date,
                                                  required_curriculum: item.required_curriculum,
                                                  columnIndex: 5,
                                                },
                                              ]
                                            : [],
                                        },
                                      ]
                                    : [],
                                },
                              ]
                            : [],
                        },
                      ]
                    : [],
                },
              ]
            : [],
        };
        const itemIndex = newCurriculum.findIndex(
          (c) => c.curriculum_code === item.curriculum_code
        );
        if (itemIndex >= 0) {
          newCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newCurriculum[itemIndex],
            item,
          });
        } else {
          newCurriculum.push(objectTrainingCurriculum);
        }
      });
      state.dataTrainingCurriculum = newCurriculum;
    });

    builder.addCase(getDataRequired.fulfilled, (state, action) => {
      const newRequiredCurriculum: Array<Types.TreeItem<Types.TrainingCurriculumItemType>> = [];
      action.payload.items.forEach((item) => {
        const objectTrainingCurriculum: Types.TreeItem<any> = {
          i_id: item.i_id,
          curriculum_code: item.curriculum_code,
          name: item.curriculum_name,
          description: item.curriculum_description,
          publish: item.publish,
          required_curriculum: item.required_curriculum,
          columnIndex: 0,
          correct_true: Number(item.correct),
          login_id: item.login_id,
          curriculum_file: item.curriculum_file,
          curriculum_trans_id: item.curriculum_trans_id,
          i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
          implementation_date: item.implementation_date,
          official_curriculum_code: item.official_curriculum_code,
          children: item.level1_i_id
            ? [
                {
                  i_id: item.level1_i_id,
                  name: item.level1_name,
                  code: item.level1_code,
                  curriculum_code: item.curriculum_code,
                  publish: item.publish,
                  required_curriculum: item.required_curriculum,
                  columnIndex: 1,
                  curriculum_name: item.curriculum_name,
                  correct_true: Number(item.correct),
                  login_id: item.login_id,
                  curriculum_trans_id: item.curriculum_trans_id,
                  i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
                  implementation_date: item.implementation_date,
                  children: item.level2_i_id
                    ? [
                        {
                          i_id: item.level2_i_id,
                          name: item.level2_name,
                          code: item.level2_code,
                          curriculum_name: item.curriculum_name,
                          curriculum_code: item.curriculum_code,
                          publish: item.publish,
                          required_curriculum: item.required_curriculum,
                          columnIndex: 2,
                          correct_true: Number(item.correct),
                          login_id: item.login_id,
                          curriculum_trans_id: item.curriculum_trans_id,
                          i_id_item_ref_curriculum_trans: item.i_id_item_ref_curriculum_trans,
                          implementation_date: item.implementation_date,
                          children: item.level3_i_id
                            ? [
                                {
                                  i_id: item.level3_i_id,
                                  name: item.level3_name,
                                  code: item.level3_code,
                                  curriculum_name: item.curriculum_name,
                                  curriculum_code: item.curriculum_code,
                                  publish: item.publish,
                                  required_curriculum: item.required_curriculum,
                                  columnIndex: 3,
                                  correct_true: Number(item.correct),
                                  login_id: item.login_id,
                                  curriculum_trans_id: item.curriculum_trans_id,
                                  i_id_item_ref_curriculum_trans:
                                    item.i_id_item_ref_curriculum_trans,
                                  implementation_date: item.implementation_date,
                                  children: item.level4_i_id
                                    ? [
                                        {
                                          i_id: item.level4_i_id,
                                          name: item.level4_name,
                                          code: item.level4_code,
                                          curriculum_name: item.curriculum_name,
                                          curriculum_code: item.curriculum_code,
                                          publish: item.publish,
                                          required_curriculum: item.required_curriculum,
                                          columnIndex: 4,
                                          correct_true: Number(item.correct),
                                          login_id: item.login_id,
                                          curriculum_trans_id: item.curriculum_trans_id,
                                          i_id_item_ref_curriculum_trans:
                                            item.i_id_item_ref_curriculum_trans,
                                          implementation_date: item.implementation_date,
                                          children: item.question_code_i_id
                                            ? [
                                                {
                                                  i_id: item.question_code_i_id,
                                                  curriculum_code: item.curriculum_code,
                                                  name: item.question_name,
                                                  curriculum_name: item.curriculum_name,
                                                  code: item.question_code,
                                                  level4_code: item.level4_code,
                                                  publish: item.publish,
                                                  correct_true: Number(item.correct),
                                                  curriculum_trans_id: item.curriculum_trans_id,
                                                  i_id_item_ref_curriculum_trans:
                                                    item.i_id_item_ref_curriculum_trans,
                                                  login_id: item.login_id,
                                                  implementation_date: item.implementation_date,
                                                  required_curriculum: item.required_curriculum,
                                                  columnIndex: 5,
                                                },
                                              ]
                                            : [],
                                        },
                                      ]
                                    : [],
                                },
                              ]
                            : [],
                        },
                      ]
                    : [],
                },
              ]
            : [],
        };
        const itemIndex = newRequiredCurriculum.findIndex(
          (c) => c.curriculum_code === item.curriculum_code
        );
        if (itemIndex >= 0) {
          newRequiredCurriculum[itemIndex] = memoizedConvertFlatDataFromTree({
            treeData: newRequiredCurriculum[itemIndex],
            item,
          });
        } else {
          newRequiredCurriculum.push(objectTrainingCurriculum);
        }
      });
      state.dataTrainingRequiredCurriculum = newRequiredCurriculum;
    });

    builder.addCase(getCurriculumImple.fulfilled, (state, action) => {
      state.dataImplementation = state.questionSelected
        .map((item) => {
          const curriculum = action.payload.report_results.find(
            (val) => val.question_code === item.code && val.level4_code === item.level4_code
          );

          return curriculum;
        })
        .filter(Boolean) as Types.CurriculumImple.ResponseType[];
      state.dataAllQuestion = unionBy(action.payload.report_results, 'question_code');
    });

    builder.addCase(getDataCompulsoryCurriculumImple.fulfilled, (state, action) => {
      state.dataImplementation = state.questionSelected
        .map((item) => {
          const curriculum = action.payload.report_results.find(
            (val) => val.question_code === item.code && val.level4_code === item.level4_code
          );

          return curriculum;
        })
        .filter(Boolean) as Types.CurriculumImple.ResponseType[];
      state.dataAllQuestion = unionBy(action.payload.report_results, 'question_code');
    });

    builder.addCase(createCurriculumTrans.fulfilled, (state, action) => {
      state.dataCreate = action.payload;
    });
    builder.addCase(editCurriculumTrans.fulfilled, (state, action) => {
      state.dataEdit = action.payload.item;
    });
    builder.addCase(createQuestionTrans.fulfilled, (state, action) => {
      state.dataCreateQuestionTrans = action.payload;
    });

    builder.addCase(getDataCorrectQues.fulfilled, (state, action) => {
      state.dataQuestionImplement = action.payload.items;
    });
  },
});

export const { setQuestionSelected, resetDataEdit, resetDataCreate, resetInitialState } =
  trainingCurriculumSlice.actions;

export default trainingCurriculumSlice.reducer;
