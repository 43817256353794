import React from 'react';
import { ReadOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  visible: boolean;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmModal: React.FC<Props> = ({
  title,
  visible,
  subTitle,
  onCancel,
  onSubmit,
  setVisible,
  description,
}) => {
  const handleCloseModal = async () => {
    onCancel && (await onCancel());
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    onSubmit && (await onSubmit());
    setVisible(false);
  };

  return (
    <Modal
      title={<span className="title">{title}</span>}
      open={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ReadOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};

export default ConfirmModal;
