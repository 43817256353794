import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Radio, RadioChangeEvent, Select, Table } from 'antd';
import { Form, SubmitButton } from 'formik-antd';
import { FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';
import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloudDownloadOutlined,
  FileTextOutlined,
  SearchOutlined,
} from '@ant-design/icons';

import { HEADER_USAGE_STATUS_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { PopupCreditCard, PopupUsageDetail } from './Modal';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { getCompanies, getDataUsageList } from './thunk';
import UsageStatusPDF from './PDF/UsageStatusPDF';
import { usageStatusSelector } from './selectors';
import { DatePicker, Header } from 'components';
import exportPDF from 'libs/utils/exportPdf';
import { routes } from 'navigations/routes';
import UsageStatusStyled from './styles';
import { IconStorage } from 'assets';
import * as Types from 'types';

const { RangePicker } = DatePicker;
const { Option } = Select;

const PER_PAGE = 10;

const UsageStatus: React.FC = () => {
  const [visiblePopupCreditCard, setVisiblePopupCreditCard] = useState<boolean>(false);
  const [dataSelected, setDataSelected] = useState<Types.UsageList.DataConverted>();
  const [openPopupUsageDetail, setOpenPopupUsageDetail] = useState<boolean>(false);
  const [selectTypesDate, setSelectTypesDate] = useState(1);
  const [perPage, setPerPage] = useState<number>(PER_PAGE);
  const [active, setActive] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const ref = useRef(null);

  const { dataUsageList } = useSelector(usageStatusSelector);
  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const getDateArray = (start: Date, end: Date) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const arr = [];
    endDate.setMonth(endDate.getMonth());

    while (dayjs(startDate).isSameOrBefore(dayjs(endDate), 'months')) {
      arr.push(
        selectTypesDate === 2
          ? dayjs(new Date(startDate)).subtract(1, 'month').format('YYYYMM')
          : dayjs(new Date(startDate)).format('YYYYMM')
      );
      startDate.setMonth(startDate.getMonth() + 1);
    }
    return arr;
  };

  const formik = useFormik<{
    start_period: Date | undefined;
    end_period: Date | undefined;
  }>({
    initialValues: {
      start_period: undefined,
      end_period: undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!values.start_period || !values.end_period) return;
      dispatch(startLoading());

      await dispatch(
        getDataUsageList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
            {
              id: 'contract_date',
              search_value: [
                getDateArray(values.start_period, values.end_period)
                  .toString()
                  .split(',')
                  .join('|'),
              ],
            },
          ],
          include_item_ref: true,
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    },
    onReset: async () => {
      dispatch(startLoading());
      await dispatch(
        getDataUsageList({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    },
  });

  const columns: ColumnsType<Types.UsageList.DataConverted> = [
    {
      title: selectTypesDate === 1 ? 'サービス利用年月' : '請求年月',
      className: 'contract_date',
      dataIndex: 'contract_date',
      key: 'contract_date',
      width: '8%',
      render: (contract_date: string) => (
        <p className="label-number">
          {selectTypesDate === 1
            ? dayjs(contract_date).format('YYYY年MM月')
            : dayjs(contract_date).add(1, 'months').format('YYYY年MM月')}
        </p>
      ),
      sorter: (a, b) => dayjs(a.contract_date).unix() - dayjs(b.contract_date).unix(),
      sortOrder: !active ? 'ascend' : 'descend',
    },
    {
      title: 'ユーザー利用料',
      className: 'user-fee',
      children: [
        {
          title: 'ユーザー数',
          className: 'user-fee-item',
          dataIndex: 'max_users',
          key: 'max_users',
          width: '6%',
          render: (max_users: number) => (
            <div className="item-table">
              <p className="label-number">{max_users.toLocaleString()}</p>
              <p className="label">人</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'user-fee-item',
          dataIndex: 'unit_price_user',
          key: 'unit_price_user',
          width: '5%',
          render: (unit_price_user: number) => (
            <div className="item-table">
              <p className="label-number">{unit_price_user.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'user-fee-item',
          dataIndex: 'user_fee',
          key: 'user_fee',
          width: '10%',
          render: (user_fee: number) => (
            <div className="item-table">
              <p className="label-number">{user_fee.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: 'ストレージ利用料',
      className: 'storage-user-fee',
      children: [
        {
          title: '利用ストレージ',
          className: 'users-storage',
          dataIndex: 'usage_storage',
          key: 'usage_storage',
          width: '9%',
          render: (usage_storage: number) => (
            <div className="item-table">
              <p className="label-number">{usage_storage}</p>
              <p className="label">GB</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'users-storage',
          dataIndex: 'usage_storage_unit_price',
          key: 'usage_storage_unit_price',
          width: '10%',
          render: (usage_storage_unit_price: number) => (
            <div className="item-table">
              <p className="label-number">{usage_storage_unit_price?.toLocaleString()}</p>
              <p className="label">円 / 50GB</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'users-storage',
          dataIndex: 'user_fee_storage',
          key: 'user_fee_storage',
          width: '9%',
          render: (user_fee_storage: number) => (
            <div className="item-table">
              <p className="label-number">{user_fee_storage}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: '面接スキルチェック利用料',
      className: 'skill-check',
      children: [
        {
          title: '利用回数',
          className: 'skill-check-item',
          dataIndex: 'skill_check_usage_count',
          key: 'skill_check_usage_count',
          width: '12%',
          render: (skill_check_usage_count: number) => (
            <div className="item-table">
              <p className="label-number">{skill_check_usage_count.toLocaleString()}</p>
              <p className="label">回</p>
            </div>
          ),
        },
        {
          title: '単価',
          className: 'skill-check-item',
          dataIndex: 'unit_price',
          key: 'unit_price',
          width: '5%',
          render: (unit_price: number) => (
            <div className="item-table">
              <p className="label-number">{unit_price.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
        {
          title: '利用料',
          className: 'skill-check-item',
          dataIndex: 'skill_check_usage_fee',
          key: 'skill_check_usage_fee',
          width: '12%',
          render: (skill_check_usage_fee: number) => (
            <div className="item-table">
              <p className="label-number">{skill_check_usage_fee.toLocaleString()}</p>
              <p className="label">円</p>
            </div>
          ),
        },
      ],
    },
    {
      title: '合計利用料（税抜）',
      dataIndex: 'total_usage_fee',
      key: 'total_usage_fee',
      width: '9%',
      render: (total_usage_fee: number) => (
        <div className="item-table">
          <p className="label-number bold">{total_usage_fee.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: '詳細',
      className: 'detail',
      dataIndex: 'detail',
      key: 'detail',
      width: '5%',
      render: (_, item) => (
        <FileTextOutlined
          onClick={() => {
            setDataSelected(item);
            setOpenPopupUsageDetail(true);
          }}
          className="icon"
        />
      ),
    },
  ];

  const onChange = (e: RadioChangeEvent) => {
    setSelectTypesDate(e.target.value);
  };

  const handleSelectChange = (value: number) => {
    setPerPage(value);
    setPage(1);
  };

  const handleButtonExport = () => {
    setVisiblePopupConfirmExportFile(true);
  };

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = dataUsageList?.map((item) => ({
        service_usage_date: dayjs(item.contract_date).format('YYYY年MM月'),
        number_show: `${item.max_users}人`,
        unit_price_user_show: `${item.unit_price_user}円`,
        user_show: `${item.user_fee}円`,
        skill_check_usage_count_show: `${item.skill_check_usage_count}回`,
        unit_price_show: `${item.unit_price}円`,
        skill_check_usage_fee_show: `${item.skill_check_usage_fee}円`,
        total_usage_fee_show: `${item.total_usage_fee}円`,
      }));

      const csvString = [
        HEADER_USAGE_STATUS_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'usage_status_export.csv');
    }
    if (value === 'pdf') {
      exportPDF(ref, 'ご利用状況一');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const component = useMemo(() => {
    return (
      <div
        ref={ref}
        style={{
          position: 'absolute',
          right: '9999px',
          width: '100%',
        }}
      >
        <UsageStatusPDF dataUsageList={dataUsageList} page={page} perPage={perPage} />
      </div>
    );
  }, [dataUsageList, page, perPage]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataUsageList({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            sort_fields: [
              {
                id: 'contract_date',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getCompanies({
            conditions: [
              {
                id: 'id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 1,
          })
        ),
      ]);

      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <UsageStatusStyled dataUsageStatus={dataUsageList.length > 0}>
      {component}
      <Header title={headerTitle} />
      <div className="container">
        <div className="item-storage">
          <p className="text-note">
            期間を選択してご利用明細の一覧を確認することができます。
            <br />
            期間はサービス利用年月と請求年月を選択して検索することができます。
          </p>
          <Button className="btn-storage" onClick={() => navigate(routes.StorageManagement.path)}>
            <img src={IconStorage} className="icon-storage" alt="icon" />
            ストレージ管理
          </Button>
        </div>
        <div className="border-line" />
        <FormikProvider value={formik}>
          <Form layout="vertical" className="form-search">
            <div className="radio-item">
              <div className="three-border" />
              <Radio.Group className="radio-select" onChange={onChange} value={selectTypesDate}>
                <Radio value={1}>サービス利用年月</Radio>
                <Radio value={2}>請求年月</Radio>
              </Radio.Group>
            </div>
            <Form.Item
              name="date-item"
              className="item"
              label={<span className="text-label">期間</span>}
            >
              <RangePicker
                allowClear
                name="date-item"
                className="date-input"
                format="YYYY/MM"
                picker="month"
                placeholder={['開始年月', '終了年月']}
                value={
                  formik.values.start_period && formik.values.end_period
                    ? [dayjs(formik.values.start_period), dayjs(formik.values.end_period)]
                    : undefined
                }
                onChange={(dates) => {
                  if (dates) {
                    formik.setValues({
                      ...formik.values,
                      start_period: dates[0]?.toDate(),
                      end_period: dates[1]?.toDate(),
                    });
                  } else {
                    formik.setValues({
                      ...formik.values,
                      start_period: undefined,
                      end_period: undefined,
                    });
                  }
                }}
              />
            </Form.Item>

            <SubmitButton className="btn-search" loading={false}>
              <SearchOutlined className="icon-search" />
              検索
            </SubmitButton>
            <span className="label-reset" onClick={() => formik.resetForm()}>
              リセット
            </span>
          </Form>
        </FormikProvider>
        <div className="wrap-table">
          <Button className="btn-active" onClick={handleButtonExport}>
            <CloudDownloadOutlined className="size-icon" />
            エクスポート
          </Button>
          <div className="total-record-table">
            <span className="text-static">
              {dataUsageList.length ? `${(page - 1) * perPage + 1} - {''}` : ''}
              {page * perPage > dataUsageList.length ? dataUsageList.length : page * perPage}
              <small> </small> / {dataUsageList.length}
              <small> 件</small>
            </span>
            <div className="page-select">
              <div className="label">
                <span>表示件数</span>：
              </div>
              <Select value={perPage} onSelect={handleSelectChange}>
                {[10, 20, 50, 100].map((value, index) => (
                  <Option key={index} value={value} label={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="wrap-sort" onClick={() => setActive(!active)}>
              <span className="lable-sort">並び替え</span>
              <div className="wrap-sort-icon">
                <CaretUpOutlined className={`icon ${!active ? 'icon-active' : ''}`} />
                <CaretDownOutlined className={`icon ${active ? 'icon-active' : ''}`} />
              </div>
            </div>
          </div>
          <Table
            className="table"
            dataSource={dataUsageList}
            columns={columns}
            rowKey="index"
            pagination={{
              pageSize: perPage,
              showSizeChanger: false,
              total: dataUsageList.length || 1,
              current: page,
              onChange: setPage,
              position: ['topCenter'],
            }}
          />
        </div>
        <PopupConfirmExportFile
          visible={visiblePopupConfirmExportFile}
          setVisible={setVisiblePopupConfirmExportFile}
          onSubmit={handleExportCSV}
        />
        <PopupUsageDetail
          dataSelected={dataSelected}
          visible={openPopupUsageDetail}
          setVisible={setOpenPopupUsageDetail}
          setVisiblePopupCreditCard={setVisiblePopupCreditCard}
        />
        <PopupCreditCard visible={visiblePopupCreditCard} setVisible={setVisiblePopupCreditCard} />
      </div>
    </UsageStatusStyled>
  );
};

export default UsageStatus;
