import React from 'react';
import { LockOutlined } from '@ant-design/icons';

import { WrapAlertPermission } from './styles';

const AlertPermission: React.FC = () => (
  <WrapAlertPermission>
    <LockOutlined className="icon-lock" />
    <span className="content">
      権限がないため、このフォルダにアクセスできません。
      <br />
      管理者に権限をリクエストしてください。
    </span>
  </WrapAlertPermission>
);

export default AlertPermission;
