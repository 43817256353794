import React, { useEffect, useState } from 'react';

import { memoizedGetFlatDataFromTree } from 'libs/utils/trainingCurriculum/memoized-tree-data-utils';
import ColumnRenderer from './TreeNodeRenderer';
import NodeRenderer from './NodeRenderer';
import * as Types from 'types';

interface Props {
  tabActive: number;
  columnClosed?: number;
  treeData?: Types.TreeItem<Types.CurriculumItemType>;
}

const TreeView: React.FC<Props> = ({ treeData, columnClosed }) => {
  const [data, setData] = useState<{
    [key: string]: Types.TreeItem<Types.CurriculumItemType>[];
  }>({});

  useEffect(() => {
    const column: { [key: string]: Types.TreeItem<Types.CurriculumItemType>[] } = {};
    const dataPrimitive = memoizedGetFlatDataFromTree({
      treeData,
    });
    dataPrimitive.forEach((item: Types.TreeItem<Types.CurriculumItemType>) => {
      column[item.columnIndex!] = [...(column[item.columnIndex!] || []), item];
    });

    setData(column);
  }, [treeData]);

  if (!data[0]) {
    return null;
  }

  return (
    <div id="item-knowledge">
      <div>
        <div>
          <NodeRenderer node={data[0][0]} />
        </div>
      </div>
      <div>
        {Array.from({
          length: columnClosed !== undefined ? columnClosed + 1 : 6,
        }).map((_, index) =>
          index > 0 ? <ColumnRenderer key={index} column={data[index]} /> : <div key={index} />
        )}
      </div>
    </div>
  );
};

export default TreeView;
