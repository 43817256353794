import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { startLoading, stopLoading, toggleHiddenSidebar } from 'containers/AppSettings/slice';
import { getDataCompany, getDataSkillCheckImple } from '../thunk';
import { newSkillCheckSelectionSelector } from '../selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import { Background, NoImageSkillCheck } from 'assets';
import { logout } from 'containers/Auth/slice';
import { resetDataEdit } from '../slice';
import StatusStyled from './styles';
import * as Types from 'types';

interface Props {
  dataGuideline?: Types.ItemSkillCheckImpleType;
  setStatusIndex: React.Dispatch<React.SetStateAction<number>>;
  fileGuideline: string | undefined;
  countStatus: number;
  countList: number;
}

const Status: React.FC<Props> = ({
  setStatusIndex,
  dataGuideline,
  fileGuideline,
  countStatus,
  countList,
}) => {
  const { dataCompany } = useSelector(newSkillCheckSelectionSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(toggleHiddenSidebar(true));
    return () => {
      dispatch(toggleHiddenSidebar(false));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataCompany({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
              exact_match: true,
            },
          ],

          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  const fetchSkillCheckSelection = async () => {
    if (!userInfo) return;
    dispatch(startLoading());
    await dispatch(
      getDataSkillCheckImple({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
        ],
        sort_fields: [
          {
            id: 'skill_check_code',
            order: 'asc',
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    dispatch(stopLoading());
  };

  return (
    <StatusStyled>
      <img src={Background} className="background" alt="logo-background" />
      <div className="body-status">
        <div className="item">
          <div className="item-image">
            {fileGuideline ? (
              <div className="wrap-image">
                <img src={fileGuideline} className="image" alt="" />
              </div>
            ) : (
              <div className="wrap-image">
                <div className="wrap-no-image">
                  <img src={NoImageSkillCheck} alt="" />
                  <span className="text">no image</span>
                </div>
              </div>
            )}
          </div>
          <p className="title">
            {dataCompany[0]?.name}_{dataGuideline?.skill_check_name}
            &nbsp;終了
          </p>
          <p className="content">スキルチェックお疲れさまでした。</p>
          <div className="btn">
            {countStatus + 1 === countList ? (
              <button
                onClick={() => {
                  dispatch(logout());
                }}
                className="btn-status"
              >
                ログアウトする
              </button>
            ) : (
              <button
                onClick={() => {
                  fetchSkillCheckSelection();
                  dispatch(resetDataEdit());
                  setStatusIndex(0);
                }}
                className="btn-status"
              >
                選択画面に戻る
              </button>
            )}
          </div>
        </div>
      </div>
    </StatusStyled>
  );
};

export default Status;
