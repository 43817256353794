import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { SectionStyled } from './styles';
import Modal from '..';

interface Props {
  visible: boolean;
  onSubmit?: () => void;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  description?: React.ReactNode;
  setVisible: (visible: boolean) => void;
}

const ActionErrorModal: React.FC<Props> = ({
  visible,
  title,
  subTitle,
  description,
  setVisible,
  onSubmit,
}) => {
  const handleToggleModal = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    handleToggleModal();
  };

  return (
    <Modal
      title={title && <h3 className="title">{title}</h3>}
      visible={visible}
      width={416}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      onCancel={handleToggleModal}
      bodyStyle={{
        paddingBottom: 0,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
};
export default ActionErrorModal;
