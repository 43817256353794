import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Switch, Table } from 'antd';

import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { deleteUserAssignCurriculum, getCurriculum } from '../../../Settings/Curriculum/thunk';
import { officialCurriculumSelector } from 'pages/OfficialCurriculum/selectors';
import { curriculumSelector } from '../../../Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import CurriculumExplanation from '../CurriculumExplanation';
import { authSelector } from 'containers/Auth/selectors';
import { ProductInformationStyled } from './styles';
import CurriculumNotes from '../CurriculumNotes';
import CompleteModal from '../CompleteModal';
import ConfirmModal from '../ConfirmModal';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import {
  createCurriculum,
  createLevelCurriculum,
  createQuestion,
  deleteCurriculum,
  deleteLevelCurriculum,
  deleteLinkQuestion,
  getDataOfficialCurriculumList,
  getDataProviderInformation,
} from 'pages/OfficialCurriculum/thunk';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataOfficialCurriculumList?: Array<Types.OfficialCurriculumList2.ResponseType>;
  dataItemDetail?: Types.OfficialCurriculumList2.ResponseType;
}

const ProductInformation: React.FC<Props> = ({
  visible,
  setVisible,
  dataOfficialCurriculumList,
  dataItemDetail,
}) => {
  const [isUpdateCompleteActive, setIsUpdateCompleteActive] = useState<boolean>(false);
  const [openCurriculumNotes, setOpenCurriculumNotes] = useState<boolean>(false);
  const [isUpdateComplete, setIsUpdateComplete] = useState<boolean>(false);
  const [isCurriculumDes, setIsCurriculumDes] = useState<boolean>(false);
  const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
  const [visibleModalCurriculumSuspension, setVisibleModalCurriculumSuspension] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  const { dataInformationCurriculum, officialCurriculumHierarchyList, dataCurriculumHierarchy } =
    useSelector(officialCurriculumSelector);
  const { userInfo } = useSelector(authSelector);
  const { curricullum } = useSelector(curriculumSelector);

  const handleChangeCurricullum = useMemo(() => {
    return curricullum.filter(
      (i) => i.official_curriculum_code === dataItemDetail?.official_curriculum_code
    );
  }, [dataItemDetail, curricullum]);

  const dataList = useMemo(() => {
    return dataOfficialCurriculumList?.filter((i) => i.provider_id === dataItemDetail?.provider_id);
  }, [dataItemDetail?.provider_id, dataOfficialCurriculumList]);
  const handleCreateCurriculum = async () => {
    dispatch(startLoading());
    await Promise.all(
      officialCurriculumHierarchyList.map(async (curriculum) => {
        const resultActionCreateCurriculum = await dispatch(
          createCurriculum({
            item: {
              company_id: userInfo?.company_id,
              name: dataItemDetail?.curriculum_name,
              description: dataItemDetail?.description,
              sort_order: 1,
              required_curriculum: 0,
              publish: 1,
              probs_count: officialCurriculumHierarchyList[0].probs_count,
              official_curriculum: 1,
              official_curriculum_code: dataItemDetail?.official_curriculum_code,
              fileID: dataItemDetail?.fileID,
              version: dataItemDetail?.published_version,
              createdat: new Date(),
              createdby: userInfo?.login_id,
            },
            return_item_result: true,
            return_display_id: true,
            realtime_auto_link: true,
          })
        );
        if (
          curriculum.children &&
          createCurriculum.fulfilled.match(resultActionCreateCurriculum) &&
          resultActionCreateCurriculum.payload.item.code
        ) {
          await Promise.all(
            curriculum.children.map(async (level_1) => {
              const resultActionLevel1 = await dispatch(
                createLevelCurriculum({
                  level: 1,
                  item: {
                    company_id: userInfo?.company_id,
                    name: level_1.name,
                    sort_order: Number(level_1.sort_order) + 1,
                    curricullum_code: resultActionCreateCurriculum.payload.item.code,
                    official_level1_code: level_1.code,
                  },
                  return_item_result: true,
                  return_display_id: true,
                  realtime_auto_link: true,
                })
              );
              if (
                level_1.children &&
                createLevelCurriculum.fulfilled.match(resultActionLevel1) &&
                resultActionLevel1.payload.item?.code
              ) {
                await Promise.all(
                  level_1.children.map(async (level_2) => {
                    const resultActionLevel2 = await dispatch(
                      createLevelCurriculum({
                        level: 2,
                        item: {
                          company_id: userInfo?.company_id,
                          name: level_2.name,
                          sort_order: Number(level_2.sort_order) + 1,
                          level1_code: resultActionLevel1.payload.item?.code,
                          official_level2_code: level_2.code,
                        },
                        return_item_result: true,
                        return_display_id: true,
                        realtime_auto_link: true,
                      })
                    );
                    if (
                      level_2.children &&
                      createLevelCurriculum.fulfilled.match(resultActionLevel2) &&
                      resultActionLevel2.payload.item?.code
                    ) {
                      await Promise.all(
                        level_2.children.map(async (level_3) => {
                          const resultActionLevel3 = await dispatch(
                            createLevelCurriculum({
                              level: 3,
                              item: {
                                company_id: userInfo?.company_id,
                                name: level_3.name,
                                sort_order: Number(level_3.sort_order) + 1,
                                level2_code: resultActionLevel2.payload.item?.code,
                                official_level3_code: level_3.code,
                              },
                              return_item_result: true,
                              return_display_id: true,
                              realtime_auto_link: true,
                            })
                          );
                          if (
                            level_3.children &&
                            createLevelCurriculum.fulfilled.match(resultActionLevel3) &&
                            resultActionLevel3.payload.item?.code
                          ) {
                            await Promise.all(
                              level_3.children.map(async (level_4) => {
                                const resultActionLevel4 = await dispatch(
                                  createLevelCurriculum({
                                    level: 4,
                                    item: {
                                      company_id: userInfo?.company_id,
                                      name: level_4.name,
                                      sort_order: Number(level_4.sort_order) + 1,
                                      level3_code: resultActionLevel3.payload.item?.code,
                                      official_level4_code: level_4.code,
                                    },
                                    return_item_result: true,
                                    return_display_id: true,
                                    realtime_auto_link: true,
                                  })
                                );
                                if (
                                  level_4.children &&
                                  createLevelCurriculum.fulfilled.match(resultActionLevel4) &&
                                  resultActionLevel4.payload.item?.code
                                ) {
                                  await Promise.all(
                                    level_4.children.map(async (question) => {
                                      await dispatch(
                                        createQuestion({
                                          item: {
                                            company_id: userInfo?.company_id,
                                            level4_code: resultActionLevel4.payload.item?.code,
                                            code: question.code,
                                            sort_order: question.sort_order,
                                            createdby: userInfo?.login_id,
                                            createdat: new Date(),
                                          },
                                        })
                                      );
                                    })
                                  );
                                }
                              })
                            );
                          }
                        })
                      );
                    }
                  })
                );
              }
            })
          );
        }
      })
    );
    fetchDataOfficialCurriculumList();
    setIsUpdateComplete(true);
    dispatch(stopLoading());
  };

  const handleDeleteCurriculum = useCallback(async () => {
    if (handleChangeCurricullum && dataItemDetail) {
      dispatch(startLoading());
      const listChildItemID = memoizedGetChildrenItemIDFromTree({
        treeData: handleChangeCurricullum[0],
      });

      await Promise.all([
        ...listChildItemID.map(async (item: Types.FlatChildrenItemID<Types.CurriculumItemType>) => {
          if (item.columnIndex === 0) {
            const resultAction = await dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
            if (deleteCurriculum.fulfilled.match(resultAction)) {
              deleteUserAssignCurriculum({
                conditions: [
                  {
                    id: 'curriculum_code',
                    search_value: [item.code],
                  },
                ],
              });
            }
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
      ]);
      setIsUpdateCompleteActive(true);
      fetchDataOfficialCurriculumList();
      dispatch(stopLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurriculumHierarchy, dispatch]);

  const columnsProductionCurriculum: ColumnsType<Types.OfficialCurriculumList2.ResponseType> = [
    {
      title: 'No.',
      className: 'no',
      dataIndex: 'i_id',
      key: 'i_id',
      width: '3%',
      render: (_, _record, index) => index + 1,
    },
    {
      title: 'OFFICAILカリキュラム名',
      className: 'production_curriculum',
      dataIndex: 'curriculum_name',
      key: 'curriculum_name',
      width: '30%',
    },
    {
      title: '説明',
      dataIndex: 'description',
      key: 'description',
      width: '18%',
    },
    {
      title: '設問数',
      className: 'number_question',
      dataIndex: 'probs_count',
      key: 'probs_count',
      width: '13%',
      render: (probs_count: number) => (
        <div className="item-table">
          <p className="label-number">{probs_count?.toLocaleString()}</p>
          <p className="label">問</p>
        </div>
      ),
    },
    {
      title: '利用/停止',
      className: 'use_status',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      width: '13%',
      //TODO: integrate API
      render: (curriculum_code: number) => (
        <Switch
          className="switch-icon"
          onClick={() =>
            curriculum_code ? setVisibleModalCurriculumSuspension(true) : setIsConfirmModal(true)
          }
          checked={!!curriculum_code}
          size="small"
        />
      ),
    },
  ];

  const fetchDataOfficialCurriculumList = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataOfficialCurriculumList({
            conditions: [],
            page: 1,
            per_page: 0,
            company_id: userInfo?.company_id || '',
            sort_fields: [
              { id: 'official_curriculum_code', order: 'asc' },
              { id: 'publish_start_date', order: 'desc' },
            ],
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataProviderInformation({
          conditions: [
            {
              id: 'company_id',
              search_value: [dataItemDetail?.provider_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      await dispatch(
        getCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, visible, dataItemDetail]);
  useEffect(fetchDataOfficialCurriculumList, [fetchDataOfficialCurriculumList]);

  const handleToggleModal = () => {
    setIsUpdateCompleteActive(false);
    setTimeout(() => {
      setVisible(false);
    }, 200);
  };

  return (
    <Modal
      title="制作者情報"
      width={860}
      open={visible}
      cancelButton={{
        text: '閉じる',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
        padding: 0,
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <ProductInformationStyled>
        <div className="item-text">
          <p className="text-label">制作者：</p>
          <p className="text-content">{dataInformationCurriculum[0]?.creator}</p>
        </div>
        <div className="item-content">
          <p className="text-label">基本情報：</p>
          <p className="text-content">{dataInformationCurriculum[0]?.basic_info}</p>
        </div>
        <div className="production-curriculum">
          <div className="title">
            <div className="circle" />
            <span className="label">制作OFFICIALカリキュラム</span>
          </div>
          <Table
            className="table"
            dataSource={dataList?.map((item, index) => ({ ...item, index }))}
            columns={columnsProductionCurriculum}
            pagination={false}
            rowKey="index"
          />
        </div>
        <CurriculumExplanation
          visible={isCurriculumDes}
          setVisible={setIsCurriculumDes}
          item_text="一般常識と時事 これだけマスター！"
          text_explanation={
            <>
              このカリキュラムで一般常識と最新時事をマスター！
              <br />
              一般常識【頻出】7分野の問題を収録！
              <br />
              解説には詳しい時事用語の解説を多く収録！
              <br />
              問題には【よく出る】【基本】【最重要】【難しい】のマークがあり、大事なところがすぐにわかります。
              <br />
              ーーーーーーーーーーーーーーー
              <br />
              設問数：1550問
              <br />
              トレーニング時間目安：50時間
              <br />
              制作：実践教育研究所
            </>
          }
        />
        <ConfirmDeleteModal
          title="OFFICIALカリキュラム 利用停止"
          visible={visibleModalCurriculumSuspension}
          setVisible={setVisibleModalCurriculumSuspension}
          onSubmit={handleDeleteCurriculum}
          subTitle="選択した OFFICIALカリキュラムの利用を停止します。"
          description={
            <>
              OFFICIALカリキュラムの利用を停止すると、カリキュラムマスタから削除されます。
              <br />
              カリキュラムに設定されていた設問、コピー作成したカリキュラムは削除されずに残ります。
              <br />
              再度利用開始は可能ですが、ユーザーの実施履歴は引き継がれませんのでご注意ください。
              <br />
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL OFFICIALカリキュラム 利用停止"
          visible={isUpdateCompleteActive}
          setVisible={setIsUpdateCompleteActive}
          handleToggleModalComplete={handleToggleModal}
          subTitle="OFFICIAL OFFICIALカリキュラムの利用停止が完了しました。"
        />
        <ConfirmModal
          title="OFFICIALカリキュラム 利用開始"
          visible={isConfirmModal}
          setVisible={setIsConfirmModal}
          onSubmit={handleCreateCurriculum}
          subTitle="選択した OFFICIALカリキュラムの利用を開始します。"
          description={
            <>
              OKをクリックでカリキュラムツリー上に
              <br />
              OFFICIALカリキュラムが追加されます。
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL カリキュラム 利用開始"
          visible={isUpdateComplete}
          handleToggleModalComplete={handleToggleModal}
          setVisible={setIsUpdateComplete}
          subTitle="OFFICIAL カリキュラムが追加されました。"
        />
        <CurriculumNotes visible={openCurriculumNotes} setVisible={setOpenCurriculumNotes} />
      </ProductInformationStyled>
    </Modal>
  );
};

export default ProductInformation;
