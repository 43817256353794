import React from 'react';
import { TeamOutlined, LikeOutlined, CrownFilled } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router-dom';

import { ItemKnowledgeComponent } from './styles';
import { routes } from 'navigations/routes';
import { LabelNumber } from 'assets';
import * as Types from 'types';

interface Props {
  index: number;
  item: Types.RankingLikesKnowledgeAll.ResponseType | Types.RankingLikesKnowledgeMonth.ResponseType;
}

const KnowledgeComponent: React.FC<Props> = ({ index, item }) => {
  const navigate = useNavigate();

  return (
    <ItemKnowledgeComponent>
      <div className="left-side">
        <p className="numerical-order-label">{index + 1}</p>
      </div>
      {index === 0 ? (
        <div className="right-side top">
          <div className="wrapper">
            <CrownFilled className="crown-icon" />
            <div className="item-label">
              <div
                className={`circle${
                  item?.knowledge_type === 1 ? '' : item?.knowledge_type === 2 ? ' green' : ' blue'
                }`}
              />
              <span className="text">
                {item?.knowledge_type === 1
                  ? 'カリキュラム'
                  : item?.knowledge_type === 2
                  ? 'Q&A'
                  : 'リコメンド'}
              </span>
            </div>
            <span className="title">{item?.knowledge_title}</span>
            <div className="ribbon">
              <img className="ribbon-image" src={LabelNumber} alt="ribbon" />
              <span className="number-of-likes">{item?.number_of_likes}</span>
            </div>
          </div>
          <div
            className="user-info"
            onClick={() => {
              item.item_ref?.user_name.i_id &&
                navigate(
                  generatePath(routes.UserProfile.path, {
                    id: item.item_ref.user_name.i_id,
                    entity: 'receiving',
                  })
                );
            }}
          >
            {'fileAvatar' in item ? (
              <img
                src={URL.createObjectURL(new Blob([item.fileAvatar?.originFileObj as Blob]))}
                className="avatar"
                alt="avatar"
              />
            ) : (
              <div className="empty-avatar">
                <TeamOutlined className="icon" />
              </div>
            )}
            <span className="user-name">{item?.user_name}</span>
          </div>
        </div>
      ) : (
        <div className="right-side">
          <div className="recommend">
            <div
              className={`label ${
                item.knowledge_type === 1 ? '' : item.knowledge_type === 2 ? 'green' : 'blue'
              }`}
            >
              <p className="text">
                {item.knowledge_type === 1
                  ? 'カリキュラム'
                  : item.knowledge_type === 2
                  ? 'Q&A'
                  : 'リコメンド'}
              </p>
            </div>
            <div className="text">{item.knowledge_title}</div>
            <div className="wrap-count-like">
              <LikeOutlined className="like-icon" />
              <span className="count-like">{item.number_of_likes}</span>
            </div>
          </div>
        </div>
      )}
    </ItemKnowledgeComponent>
  );
};

export default KnowledgeComponent;
