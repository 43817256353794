import React from 'react';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { Table, Row, Col } from 'antd';
import dayjs from 'dayjs';

import { UsageDetailStyled } from 'pages/UsageStatus/Modal/UsageDetail/styles';
import * as Types from 'types';

interface Props {
  dataSelected: Types.UsageList.DataConverted | undefined;
  timeCheck: boolean;
  dataUserFeeDetail: Types.UserFeeDetail.DataConverted[];
  dataPaymentStatus: Types.PaymentStatus.ResponseType[];
  dataSkillCheckFeeDetail: Types.SkillCheckFeeDetails.ResponseType[];
  total: number;
}

const UsageStatusDetailPDF: React.FC<Props> = ({
  dataPaymentStatus,
  dataSelected,
  dataUserFeeDetail,
  timeCheck,
  total,
  dataSkillCheckFeeDetail,
}) => {
  const columnsUserFee: ColumnsType<Types.UserFeeDetail.DataConverted> = [
    {
      title: '月末利用ユーザー数',
      className: 'item-center',
      dataIndex: 'number_end_month',
      key: 'number_end_month',
      width: '25%',
      render: (number_end_month: number) => (
        <div className="item-table">
          <p className="label-number">{number_end_month.toLocaleString()}</p>
          <p className="label">人</p>
        </div>
      ),
    },
    {
      title: '月間最大利用ユーザー数',
      className: 'item-center',
      dataIndex: 'number_user_month_max',
      key: 'number_user_month_max',
      width: '25%',
      render: (number_user_month_max: number) => (
        <div className="item-table">
          <p className="label-number">{number_user_month_max.toLocaleString()}</p>
          <p className="label">人</p>
        </div>
      ),
    },
    {
      title: '単価',
      dataIndex: 'unit_price',
      key: 'unit_price',
      width: '25%',
      render: (unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{unit_price?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: 'ユーザー利用料',
      dataIndex: 'user_fee',
      key: 'user_fee',
      width: '25%',
      render: (user_fee: number) => (
        <div className="item-table">
          <p className="label-number">{user_fee.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsStorageUserFee: ColumnsType<Types.UsageList.DataConverted> = [
    {
      title: '利用ストレージ',
      className: 'item-center',
      dataIndex: 'usage_storage',
      key: 'usage_storage',
      width: '33%',
      render: (usage_storage: number) => (
        <div className="item-table">
          <p className="label-number">{usage_storage?.toLocaleString()}</p>
          <p className="label">GB</p>
        </div>
      ),
    },
    {
      title: '単価',
      className: 'item-center',
      dataIndex: 'usage_storage_unit_price',
      key: 'usage_storage_unit_price',
      width: '33%',
      render: (usage_storage_unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{usage_storage_unit_price?.toLocaleString()}</p>
          <p className="label">円 /50GB</p>
        </div>
      ),
    },
    {
      title: 'ストレージ利用料',
      dataIndex: 'user_fee_storage',
      key: 'user_fee_storage',
      width: '33%',
      render: (user_fee_storage: number) => (
        <div className="item-table">
          <p className="label-number">{user_fee_storage?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsSkillCheckUserFee: ColumnsType<Types.SkillCheckFeeDetails.ResponseType> = [
    {
      title: 'スキルチェック名称',
      className: 'item-start',
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      width: '48%',
      render: (skill_check_name: string) => (
        <div className="item-table">
          <p className="label-number">{skill_check_name}</p>
        </div>
      ),
    },
    {
      title: '実施回数',
      className: 'item-center',
      dataIndex: 'count_skill_check',
      key: 'count_skill_check',
      width: '12%',
      render: (count_skill_check: number) => (
        <div className="item-table">
          <p className="label-number">{count_skill_check}</p>
          <p className="label">回</p>
        </div>
      ),
    },
    {
      title: '単価',
      dataIndex: 'skill_check_unit_price',
      key: 'skill_check_unit_price',
      width: '15%',
      render: (skill_check_unit_price: number) => (
        <div className="item-table">
          <p className="label-number">{skill_check_unit_price}</p>
          <p className="label">円</p>
        </div>
      ),
    },
    {
      title: 'スキルチェック利用料',
      dataIndex: 'skill_check_user_fee',
      key: 'skill_check_user_fee',
      width: '25%',
      render: (skill_check_user_fee: number) => (
        <div className="item-table">
          <p className="label-number">{skill_check_user_fee}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];

  const columnsPaymentStatus: ColumnsType<Types.PaymentStatus.ResponseType> = [
    {
      title: '支払日',
      className: 'item-center',
      dataIndex: 'paid_date',
      key: 'paid_date',
      width: '33%',
      render: (paid_date: string) => (
        <div className="item-table">
          <p className="label-number">{dayjs(paid_date).format('YYYY年MM月')}</p>
        </div>
      ),
    },
    {
      title: 'カード番号',
      className: 'item-center',
      dataIndex: 'card_number_l4g',
      key: 'card_number_l4g',
      width: '33%',
      render: (card_number_l4g: string) => (
        <div className="item-table">
          <p className="label-number">{card_number_l4g?.replace(/\d(?=\d{4})/g, '*')}</p>
        </div>
      ),
    },
    {
      title: '金額（税込）',
      className: 'item-center',
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      width: '33%',
      render: (paid_amount: number) => (
        <div className="item-table">
          <p className="label-number">{paid_amount?.toLocaleString()}</p>
          <p className="label">円</p>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="wrap-header-pdf">
        <p className="header-pdf">ご利用状況詳細</p>
      </div>
      <UsageDetailStyled>
        <div className="container-pdf">
          <div className="wrap-header">
            <div className="time-label">
              <p className="label">サービス利用年月：</p>
              <p className="time-text">{dayjs(dataSelected?.contract_date).format('YYYY年MM月')}</p>

              {timeCheck ? (
                <div className="item-check">
                  <ExclamationCircleOutlined className="icon" />
                  計算期間中
                </div>
              ) : (
                <div className="item-check-time">
                  <CheckCircleOutlined className="icon" />
                  確定
                </div>
              )}
            </div>
          </div>
          {timeCheck ? (
            <p className="content">
              ※計算期間中のご利用状況詳細は展開時の情報を表示します。確定データではありませんので、ご注意ください。
            </p>
          ) : null}
          <div className="user-fee-detail">
            <div className="title">
              <div className="circle" />
              <span className="label">ユーザー利用料詳細</span>
            </div>
            <Table
              className="table"
              dataSource={dataUserFeeDetail.map((item, index) => ({ ...item, index }))}
              columns={columnsUserFee}
              pagination={false}
              rowKey="index"
            />
          </div>
          <div className="storage-user-fee-detail">
            <div className="title">
              <div className="circle" />
              <span className="label">ストレージ利用料詳細</span>
            </div>
            <Table
              className="table"
              dataSource={dataSelected ? [dataSelected] : []}
              columns={columnsStorageUserFee}
              pagination={false}
              rowKey="index"
            />
          </div>
          <div className="skillcheck-fee-detail">
            <div className="title">
              <div className="circle" />
              <span className="label">スキルチェック利用料詳細</span>
            </div>
            <Table
              className="table"
              dataSource={dataSkillCheckFeeDetail.map((item, index) => ({ ...item, index }))}
              columns={columnsSkillCheckUserFee}
              pagination={false}
              rowKey="index"
              footer={() => (
                <>
                  <div className="table-footer">
                    <div className="label">合計</div>
                    <div className="total">
                      <div className="item-total">
                        <p className="number">{total.toLocaleString()}</p>
                        <p className="text">円</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
          <div className="bill-total">
            <div className="title">
              <span className="label">
                合計利用料（お支払い方法：{dataUserFeeDetail[0]?.payment_method_name}）
              </span>
            </div>
            <div className="wrap-table">
              <Row className="row-label">
                <Col className="gutter-row" span={8}>
                  ユーザー利用料
                </Col>
                <Col className="gutter-row center" span={8}>
                  スキルチェック利用料
                </Col>
                <Col className="gutter-row" span={8}>
                  合計利用料
                </Col>
              </Row>
              <Row className="row-number-pdf">
                <Col className="gutter-row" span={8}>
                  <div className="item-total">
                    <p className="number">{dataUserFeeDetail[0]?.user_fee.toLocaleString()}</p>
                    <p className="text">円</p>
                  </div>
                </Col>
                <Col className="gutter-row center" span={8}>
                  <div className="item-total">
                    <p className="number">{total.toLocaleString()}</p>
                    <p className="text">円</p>
                  </div>
                </Col>
                <Col className="gutter-row end" span={8}>
                  <div className="item-total">
                    <p className="number">
                      {(dataUserFeeDetail[0]?.user_fee + total).toLocaleString()}
                    </p>
                    <p className="text">円</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="bill-total">
            <div className="title">
              <span className="label">請求合計</span>
            </div>
            {dataPaymentStatus.length > 0 ? (
              <Table
                className="table"
                dataSource={dataPaymentStatus.map((item, index) => ({ ...item, index }))}
                columns={columnsPaymentStatus}
                pagination={false}
                rowKey="index"
              />
            ) : (
              <div className="wrap-table">
                <Row className="row-label">
                  <Col className="gutter-row" span={8}>
                    支払日
                  </Col>
                  <Col className="gutter-row center" span={8}>
                    カード番号
                  </Col>
                  <Col className="gutter-row" span={8}>
                    金額（税込）
                  </Col>
                </Row>
                <Row className="row-number">
                  <Col className="gutter-row" span={8}>
                    <div className="item-unsettled">未決済</div>
                  </Col>
                  <Col className="gutter-row center" span={8}>
                    <div className="item-total">-</div>
                  </Col>
                  <Col className="gutter-row end" span={8}>
                    <div className="item-total">-</div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </div>
      </UsageDetailStyled>
    </div>
  );
};

export default UsageStatusDetailPDF;
