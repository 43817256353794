import styled from 'styled-components';

export default styled.section`
  .description {
    margin-bottom: 30px;
    p {
      font-size: 13px;
      text-align: center;
      color: #424242;
      margin: 0;
    }
  }
  .form {
    width: 100%;
    border-radius: 5px;
    padding: 40px;
    .form-input {
      .radio_group {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        width: 80%;
        .wrap-radio {
          width: 25%;
          .radio_item {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;
            height: 80px;
            border-top: 1px solid #d9d9d9;
            border-bottom: 1px solid #d9d9d9;
            margin-right: 0;
            .ant-radio {
              position: absolute;
              top: 6px;
              left: 6px;
            }
            & > span:nth-child(2) {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80%;
              height: 100%;
            }
          }
        }
        .border-right {
          border-right: 1px solid #d9d9d9;
        }
        .border-radius-left {
          border: 1px solid #d9d9d9;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
        .border-radius-right {
          border: 1px solid #d9d9d9;
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          border-left: none;
        }
        .label-text {
          font-size: 11px;
          margin: 5px 0 0 0;
          text-align: center;
          color: #777777;
        }
      }
      .input,
      .input_small {
        height: 40px !important;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        border-radius: 3px;
        background-color: #ffffff;
        ::placeholder {
          color: #cccccc;
          font-size: 13px;
        }
      }
      .input_small {
        width: 83px;
      }
      .input_small_two {
        width: 20%;
      }
      .input-card-number {
        height: 40px;
        color: #424242;
        font-size: 14px;
        border: 1px solid #e6e6e6;
        border-radius: 4px;
        background-color: #ffffff;
        padding: 10px;
      }
      .pull_down {
        font-size: 14px;
        color: #424242;
        border-radius: 4px;
        :not(.ant-select-customize-input) .ant-select-selector {
          width: 100%;
          height: 40px;
          padding: 3px 11px;
        }
      }
      .text-label-content {
        font-size: 12px;
        color: #777777;
        margin: 0 10px 0 7px;
      }
      .text-label {
        font-size: 13px;
        color: #777777;
        .require {
          color: #f0330a;
        }
      }
    }
    .flex {
      display: flex;
      .content-text {
        font-size: 12px;
        color: #00a3a5;
        margin: 0 0 0 20px;
      }
    }
    .wrap-security {
      width: 100%;
    }
  }
  .wrap-button {
    padding: 20px 0;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      height: 40px;
      padding: 0 50px;
      cursor: pointer;
    }
    .btn-cancel {
      background: #ffffff;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #777777;
      font-size: 15px;
      border: 1px solid #d9d9d9;
      height: 40px;
      padding: 0 50px;
      cursor: pointer;
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }
  .container-confirm {
    .form-input {
      .input__disabled {
        background-color: #f9f8f8 !important;
        color: #424242;
        height: 40px;
      }
      .wrap-card-company {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        width: 20%;
        .card-name {
          font-size: 11px;
          color: #777777;
        }
        .wrap-image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 80px;
          border: 1px solid #d9d9d9;
          border-radius: 3px;
          .ant-radio-group {
            width: 100%;
          }
          .wrap-radio {
            .radio_item {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              position: relative;
              height: 80px;
              padding-top: 5px;
              width: 100%;
              .ant-radio {
                position: absolute;
                top: 6px;
                left: 6px;
              }
              & > span:nth-child(2) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80%;
                height: 100%;
              }
            }
          }
        }
        .label-text {
          font-size: 11px;
          margin: 5px 0 0 0;
          text-align: center;
          color: #777777;
        }
      }
    }
  }
`;
