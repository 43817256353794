import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useDrag } from 'react-dnd';

import { ItemWrapper } from './styles';
import * as Types from 'types';

interface PropsItemUserResult {
  item: Types.Users.ResponseType;
  handleAddUser: () => Promise<void>;
  curriculumSelected?: Types.UserTreeviewType;
  usersSelected: Array<Types.Users.ResponseType>;
  setUsersSelected: React.Dispatch<React.SetStateAction<Array<Types.Users.ResponseType>>>;
}

const Item: React.FC<PropsItemUserResult> = ({
  item,
  usersSelected,
  handleAddUser,
  setUsersSelected,
  curriculumSelected,
}) => {
  const [{ isDragging }, dragRef] = useDrag({
    item: {
      type: 'move-user',
      user_id: item.i_id,
      department_name: item.department_code,
    },
    end: () => {
      handleAddUser();
    },
    canDrag: Boolean(curriculumSelected) && usersSelected.some((i) => i.i_id === item.i_id),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleSelect = () => {
    setUsersSelected((prevState) => {
      if (prevState.some((i) => i.i_id === item.i_id)) {
        return prevState.filter((i) => i.i_id !== item.i_id);
      } else {
        return [...prevState, item];
      }
    });
  };

  return (
    <ItemWrapper
      ref={dragRef}
      isDragging={isDragging}
      isChecked={usersSelected.some((i) => i.i_id === item.i_id)}
      onClick={handleSelect}
    >
      {usersSelected.some((i) => i.i_id === item.i_id) ? (
        <CheckOutlined className="icon" />
      ) : (
        <div className="icon" />
      )}
      <span className="text-code">{item.lookup_items?.department_code?.code}</span>
      <span className="text-bold">{item.name} </span>
    </ItemWrapper>
  );
};

export default Item;
