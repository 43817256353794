import styled from 'styled-components';

interface Props {
  visibleEditQAAnswer: boolean;
}

export default styled.section<Props>`
  .container {
    margin: 20px 30px;
    .disabled {
      margin-right: 35px;
      text-decoration-line: underline;
      font-size: 12px;
      cursor: not-allowed !important;
      pointer-events: none !important;
      color: #777777 !important;
      .icon-edit {
        font-size: 16px;
        padding-right: 5px;
      }
      .icon-delete {
        font-size: 16px;
        padding-right: 5px;
      }
    }
    .btn-header {
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      .group-btn-left {
        display: flex;
        align-items: center;
        .btn {
          padding: 5px 10px;
          background: #f03060;
          border-radius: 4px;
          font-weight: 700;
          font-size: 12px;
          color: #ffffff;
          .icon {
            font-size: 16px;
            padding-right: 5px;
          }
        }
        .un-answer {
          background: #f03060;
        }
        .check {
          background: #1fb9db;
        }
        .comment {
          background: #ed7200;
          margin-left: 5px;
        }
        .btn-back {
          padding-left: 10px;
          font-size: 14px;
          color: #777777;
          cursor: pointer;
          border: none;
          .icon {
            color: #c4c4c4;
          }
        }
      }
      .group-btn-right {
        display: flex;
        align-items: flex-end;
        .btn {
          display: flex;
          align-items: center;
          height: 30px;
          padding: 0 10px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          border-radius: 3px;
          cursor: pointer;
        }
        .like {
          margin-right: 15px;
          font-size: 13px;
          color: #08a3a5;
          .icon {
            padding-right: 5px;
          }
        }
        .star {
          font-size: 12px;
          color: #777777;
          .icon {
            font-size: 16px;
            padding-right: 5px;
            color: #e1e1e1;
          }
        }
        .icon-star,
        .star-gold {
          font-size: 16px;
          padding-right: 10px;
        }
        .star-gold {
          color: #f6ac00 !important;
        }
        .export-btn {
          border: 1px solid #f6ac00;
          color: #f6ac00;
          font-weight: 700;
          padding: 8px 13px;
          display: inline-flex;
          align-items: center;
          border-radius: 5px;
          height: 36px;
          margin-left: 20px;
        }
      }
    }
    .recommended {
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      padding-bottom: 30px;
      .wrap-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dddddd;
        .title {
          display: flex;
          align-items: center;
          padding-left: 3px;
          width: 70%;
          position: relative;
          border-right: 1px solid #dddddd;
          &::before {
            position: absolute;
            content: '';
            width: 4px;
            height: 95%;
            left: 3px;
            border-radius: 4px;
            top: 2%;
            background: #0eaf58;
          }

          .title-header {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 10px 15px;
            .label-QA {
              font-size: 11px;
              color: #0eaf58;
            }
            .content-header {
              font-size: 17px;
              color: #2a2a2a;
            }
          }
        }
        .info {
          display: flex;
          align-items: center;
          padding: 20px 50px;
          width: 30%;
          .item-from {
            display: flex;
            align-items: center;
            .avatar {
              position: relative;
              .label-QA {
                width: 36px;
                height: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #08a3a5;
                border-radius: 2px;
                font-size: 11px;
                color: #ffffff;
                position: absolute;
                top: 0;
                left: -15px;
              }
              .to {
                left: -25px;
              }
              .avt-icon {
                border-radius: 50%;
                width: 40px;
                height: 40px;
              }
              .avt {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: #eaeaea;
                .icon {
                  color: #bbbbbb;
                }
              }
            }
            .item-name {
              padding-left: 10px;
              .label-name {
                font-size: 11px;
                color: #777777;
                max-width: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .name {
                font-size: 13px;
                color: #424242;
                max-width: 130px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .icon-caret {
            color: #cccccc;
            font-size: 16px;
            padding: 0 30px 0 15px;
          }
        }
      }
      .item-content {
        padding: 30px 40px 10px 40px;
        .item-info {
          display: flex;
          justify-content: space-between;
          .info {
            display: flex;
            align-items: center;
            .avt {
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #eaeaea;
              border-radius: 50%;
              margin-right: 5px;
              .icon {
                color: #bbbbbb;
              }
            }
            .avt-icon {
              border-radius: 50%;
              width: 40px;
              height: 40px;
            }
            .item-name {
              padding-left: 10px;
              .label-name {
                font-size: 11px;
                color: #777777;
              }
              .name {
                font-size: 13px;
                color: #424242;
              }
            }
          }
        }
        .date {
          font-size: 12px;
          color: #999999;
        }
      }
      .comment {
        padding: 0 80px 20px 80px;
        .text {
          color: #424242;
          font-size: 14px;
          margin-bottom: 14px;
          p {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
        .group-btn-paper {
          display: flex;
          justify-content: space-between;
          .btn-paper {
            background: #f4f4f4;
            border-radius: 16px;
            border: none;
            padding: 3px 15px;
            font-size: 13px;
            text-decoration-line: underline;
            color: #08a3a5;
            cursor: pointer;
            margin-right: 10px;
            .icon {
              color: #08a3a5;
              font-size: 16px;
              padding-right: 10px;
            }
          }
          .btn-right {
            display: flex;
            align-items: center;
            .btn-delete {
              font-size: 12px;
              text-decoration-line: underline;
              color: #00989a;
              padding-right: 35px;
              cursor: pointer;
              .icon-delete {
                font-size: 16px;
                padding-right: 5px;
              }
            }
            .btn-edit {
              font-size: 12px;
              text-decoration-line: underline;
              color: #00989a;
              cursor: pointer;
              .icon-edit {
                font-size: 16px;
                padding-right: 5px;
              }
            }
            .disabled {
              text-decoration-line: underline;
              font-size: 12px;
              cursor: not-allowed !important;
              pointer-events: none !important;
              color: #777777 !important;
              .icon-edit {
                font-size: 16px;
                padding-right: 5px;
              }
            }
          }
          .btn-add-answer {
            width: 160px;
            height: 40px;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 1px solid #00a3a5;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            font-weight: 700;
            font-size: 13px;
            color: #00a3a5;
            cursor: pointer;
          }
        }
        .group-btn {
          display: flex;
          justify-content: end;
          .btn-paper {
            background: #f4f4f4;
            border-radius: 16px;
            border: none;
            padding: 3px 15px;
            font-size: 13px;
            text-decoration-line: underline;
            color: #08a3a5;
            cursor: pointer;
            margin-right: 10px;
            .icon {
              color: #08a3a5;
              font-size: 16px;
              padding-right: 10px;
            }
          }
          .btn-right {
            display: flex;
            align-items: center;
            .btn-delete {
              font-size: 12px;
              text-decoration-line: underline;
              color: #00989a;
              padding-right: 35px;
              cursor: pointer;
              .icon-delete {
                font-size: 16px;
                padding-right: 5px;
              }
            }

            .btn-edit {
              font-size: 12px;
              text-decoration-line: underline;
              color: #00989a;
              cursor: pointer;
              .icon-edit {
                font-size: 16px;
                padding-right: 5px;
              }
            }

            .disabled {
              text-decoration-line: underline;
              font-size: 12px;
              cursor: not-allowed !important;
              pointer-events: none !important;
              color: #777777 !important;
            }
          }
        }
      }
      .text-footer {
        text-align: center;
        padding: 10px 0 50px 0;
        .text {
          font-size: 13px;
          color: #777777;
        }
      }
      .no-comment {
        width: 640px;
        height: 45px;
        display: flex;
        background: #eaeaea;
        border-radius: 2px;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: #777777;
      }
      .btn-answer {
        padding: 20px 30px;
        position: relative;
        .divider {
          width: 100%;
          height: 1px;
          background: #eeeeee;
        }
        .answer {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 25px;
          text-align: center;
          background: #eeeeee;
          font-weight: 700;
          font-size: 13px;
          color: #999999;
          position: absolute;
          left: 50%;
          top: 9px;
        }
      }
      .info-comment {
        display: flex;
        align-items: center;
        padding: 20px 40px 0 40px;
        .avt-icon {
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
        .item-name {
          padding-left: 10px;
          .label-name {
            font-size: 11px;
            color: #777777;
          }
          .name {
            font-size: 13px;
            color: #424242;
          }
        }
      }
      .form-add-answer {
        padding: 20px 30px 0 85px;
        .wrap-add-answer {
          display: flex;
          justify-content: space-between;
          align-items: end;
          width: 100%;
          .wrap-upload {
            width: 30%;
            padding-top: 30px;
            .title {
              text-align: left;
              color: #999999;
              font-size: 13px;
              padding-bottom: 4px;
              span {
                color: #424242;
              }
            }
            .form-upload {
              display: flex;
              align-items: center;
              width: 100%;
              .ant-upload.ant-upload-drag {
                background: none !important;
                border: none !important;
              }
              .wrap-dragger {
                width: 100%;
              }
            }
          }
          .group-btn {
            .btn {
              width: 200px;
              height: 50px;
              border-radius: 5px;
              border: none;
              cursor: pointer;
            }
            .btn-active {
              background: #f6ac00;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              font-weight: 700;
              font-size: 15px;
              color: #ffffff;
            }
            .btn_submit {
              background: #eeeeee;
              font-weight: 700;
              font-size: 15px;
              color: #bbbbbb;
            }
            .default {
              display: ${(props) => (props.visibleEditQAAnswer ? '' : 'none')};
              margin-left: 15px;
              background: #ffffff;
              border: 1px solid #d9d9d9;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              font-size: 13px;
              color: #777777;
            }
          }
          .file-name {
            display: flex;
            align-items: center;
            padding-left: 12px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #cccccc;
            box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
            border-radius: 3px 0 0 3px;
            color: #cccccc;
          }
          .button-upload {
            background: #00a3a5;
            height: 40px;
            width: 15%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 0 3px 3px 0;
            cursor: pointer;
            .ant-upload {
              width: 100%;
              color: #ffffff;
            }
          }
        }
        .ant-form-item {
          margin-bottom: 0;
        }
        .input-textarea-group {
          font-size: 14px;
          color: #2a2a2a;
          ::placeholder {
            font-size: 14px;
            color: #cccccc;
          }
        }
        .content {
          .ant-input {
            min-height: 320px;
          }
        }
        .text-label {
          font-size: 13px;
          color: #222222;
          display: block;
          margin: 8px 0;
          .require {
            color: #f0330a;
            font-size: 20px;
          }
          .detail {
            font-size: 13px;
            color: #999999;
          }
        }
        .detail {
          padding: 40px 0;
          .group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .input {
              width: 40%;
              height: 35px;
              display: flex;
              .input-image {
                border-radius: 4px 0 0 4px;
              }
              .btn-active {
                width: 80px;
                background: #00a3a5;
                box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
                border-radius: 0 5px 5px 0;
                border: none;
                font-size: 13px;
                color: #ffffff;
                cursor: pointer;
              }
            }
            .btn {
              width: 200px;
              height: 50px;
              border-radius: 5px;
              border: none;
              font-weight: 700;
              font-size: 15px;
              cursor: pointer;
            }
            .btn_submit {
              color: #bbbbbb;
              background: #eeeeee;
            }
            .btn-active {
              color: #ffffff;
              background: #f6ac00;
            }
          }
        }
        .jodit-react-container {
          height: 320px !important;
          .jodit-container {
            height: 100% !important;
            .jodit-workplace {
              min-height: 278px !important;
            }
          }
        }
      }
      .body {
        padding: 40px;
        .wrap-content {
          display: flex;
          justify-content: space-between;
          .user {
            display: flex;
          }
          .time {
            font-size: 12px;
            color: #999999;
          }
        }
        .wrap-action {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 20px;
          .files {
            display: flex;
            margin-left: 48px;
          }
          .action .text {
            text-decoration: underline;
          }
          .file {
            background: #f4f4f4;
            border-radius: 16px;
            padding: 8px 18px;
            color: #08a3a5;
            font-size: 13px;
            text-decoration: underline;
            margin-right: 15px;
            cursor: pointer;
          }
          .edit-delete {
            display: flex;
            margin-right: 8px;
            color: #00989a;
            font-size: 12px;
            cursor: pointer;
            text-decoration: underline;
            .edit {
              margin-left: 24px;
            }
            .icon {
              font-size: 16px;
              margin-right: 5px;
            }
          }
        }
        .content {
          white-space: pre;
          margin: 20px 0 0 48px;
          p {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }
    .header-recommend {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dddddd;
      .wrap-badge {
        display: flex;
        .to-username {
          display: inline-flex;
          align-items: center;
        }
      }

      .left-side span:nth-of-type(1) {
        color: #1e7ed6;
        font-size: 11px;
      }
      .left-side span:nth-of-type(2) {
        color: #2a2a2a;
        font-size: 17px;
      }
      .icon {
        color: #cccccc;
        margin: 0 24px;
        font-size: 18px;
      }
      .left-side {
        width: 80%;
        position: relative;
        padding: 9px 21px 19px 21px;
        border-right: 1px solid #dddddd;
        &::before {
          position: absolute;
          content: '';
          width: 4px;
          height: 95%;
          left: 3px;
          border-radius: 4px;
          top: 2%;
          background: #1e7ed6;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        width: 25%;
        padding: 14px 32px;
        .from {
          display: flex;
          align-items: center;
          .department {
            color: #777777;
            font-size: 11px;
          }
          .name {
            font-size: 13px;
          }
        }
        .badge {
          width: max-content;
          background: #08a3a5;
          color: #ffffff;
          border-radius: 2px;
          padding: 2px;
          font-size: 12px;
        }
      }
    }
    .wrap-title {
      display: flex;
      align-items: center;
      .button {
        border-radius: 3px;
      }
    }
    .wrap-button {
      display: flex;
      align-items: flex-end;
    }
    .wrap-label {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .hasLike {
        color: #08a3a5;
      }
      .star {
        color: #777777;
        margin-left: 4px;
      }
      .icon-star,
      .star-gold {
        font-size: 16px;
      }
      .star-gold {
        color: #f6ac00 !important;
      }

      .export {
        border: 1px solid #f6ac00;
        color: #f6ac00;
        font-weight: 700;
        padding: 8px 13px;
        display: inline-flex;
        align-items: center;
        border-radius: 5px;
        height: 36px;
        margin-left: 20px;
      }
    }
    .label {
      background: #f03060;
      display: inline;
      padding: 7px 8px;
      color: #ffffff;
      font-weight: 700;
      border-radius: 4px;
      .icon {
        margin-right: 8px;
      }
    }
    .goback {
      margin-right: 8px;
      color: #777777;
      margin-left: 24px;
      cursor: pointer;
    }
    .title-answer {
      color: #08a3a5;
    }
    .wrap-answer {
      margin-top: 28px;
      .icon-message {
        font-size: 24px;
        margin-right: 9px;
      }
      .title {
        font-size: 16px;
      }

      .edit-delete {
        display: flex;
        margin-right: 8px;
        color: #00989a;
        font-size: 12px;
        cursor: pointer;
        text-decoration: underline;
        .edit {
          margin-left: 24px;
        }
        .icon {
          font-size: 16px;
          margin-right: 5px;
        }
      }
      .body {
        height: 100%;
        background: #ffffff;
        box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        margin-top: 8px;
        padding: 0 40px 24px 40px;
        position: relative;
        margin-bottom: 8px;
        .form-add {
          position: relative;
          border-top: 1px solid #eeeeee;
          .answer-label-additional {
            background: #eeeeee;
            color: #999999;
            font-weight: 700;
            position: absolute;
            top: -18px;
            left: 45%;
            padding: 6px 44px;
          }
        }
        .not-answer {
          background: rgba(240, 48, 96, 0.1);
          border: 1px solid rgba(240, 48, 96, 0.3);
          border-radius: 0 5px;
          padding: 6px 14px;
          color: #f03060;
          position: absolute;
          top: 0;
          right: -24px;
          z-index: 1;
        }
        .user {
          display: flex;
          padding-top: 27px;
          .avt {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #eaeaea;
            border-radius: 50%;
            margin-right: 5px;
            .icon {
              color: #bbbbbb;
            }
          }
          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          .avt-icon {
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }
        }
        .content {
          margin-top: 20px;
          margin-left: 48px;
          white-space: pre-wrap;
          p {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
        .wrap-input {
          margin-top: 20px;
          padding-left: 48px;
        }
        .wrap-jodit {
          margin-top: 20px;
          padding-left: 48px;
          .jodit-react-container {
            height: 200px !important;
            .jodit-container {
              height: 100% !important;
            }
          }
        }
        .text-area {
          max-width: 100%;
        }
        .answer-question {
          position: relative;
          display: flex;
          justify-content: space-between;
          padding-top: 24px;
          border-top: 1px solid #eeeeee;
          .time {
            font-size: 12px;
            color: #999999;
            margin-top: 20px;
          }
        }
        .comment {
          position: relative;
          .wrap-action {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .action .text {
              text-decoration: underline;
            }
            .edit-delete {
              display: flex;
              margin-right: 8px;
              color: #00989a;
              font-size: 12px;
              cursor: pointer;
              text-decoration: underline;
              .edit {
                margin-left: 24px;
              }
              .icon {
                font-size: 16px;
                margin-right: 5px;
              }
            }
          }
          .has-answer {
            background: rgba(31, 185, 219, 0.1);
            border: 1px solid rgba(31, 185, 219, 0.3);
            border-radius: 0 5px;
            padding: 6px 14px;
            color: #1fb9db;
            position: absolute;
            top: 0;
            right: -24px;
            z-index: 1;
          }
          .question {
            padding-top: 27px;
            display: flex;
            justify-content: space-between;
            .left-question {
              width: 92%;
              .user {
                .avatar {
                  border-radius: 50%;
                }
              }
            }
            .time {
              font-size: 12px;
              color: #999999;
              margin-top: 20px;
            }
          }
          .answer-label {
            background: #eeeeee;
            color: #999999;
            font-weight: 700;
            position: absolute;
            top: -18px;
            left: 45%;
            padding: 6px 44px;
          }
          .add-answer {
            border: 1px solid #00a3a5;
            color: #00a3a5;
            padding: 14px 50px;
            font-weight: 700;
            display: inline-flex;
            align-items: center;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
          }
          .wrap-action,
          .wrap-button-add {
            .edit-delete {
              .edit {
                background: unset;
                border: unset;
              }
            }
          }
          .wrap-button-add,
          .wrap-add-answer {
            text-align: right;
            padding-bottom: 32px;
            margin-top: 39px;
            display: flex;
            justify-content: end;
            .add {
              display: inline-flex;
              align-items: center;
              background: #f6ac00;
              color: #ffffff;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 60px;
              font-weight: 700;
              font-size: 15px;
              height: 48px;
              margin-right: 8px;
            }
            .disable {
              display: inline-flex;
              align-items: center;
              background: #eeeeee;
              color: #bbbbbb;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 60px;
              font-weight: 700;
              font-size: 15px;
              height: 48px;
              margin-right: 8px;
            }
            .cancel {
              display: inline-flex;
              align-items: center;
              background: #ffffff;
              color: #777777;
              border: 1px solid #d9d9d9;
              height: 48px;
              box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
              border-radius: 5px;
              padding: 17px 12px;
            }
          }
          .has-file-question {
            margin-bottom: 20px;
          }
          .has-file {
            display: flex;
            margin-left: 48px;
            justify-content: space-between;
            margin-top: 20px;
            .file {
              background: #f4f4f4;
              border-radius: 16px;
              padding: 8px 18px;
              color: #08a3a5;
              font-size: 13px;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          .wrap-add-answer {
            display: flex;
            justify-content: space-between;
            .wrap-upload {
              width: 30%;
              margin-left: 48px;
              .title {
                text-align: left;
                color: #999999;
                font-size: 13px;
                padding-bottom: 4px;
                span {
                  color: #424242;
                }
              }
              .form-upload {
                display: flex;
                align-items: center;
                width: 100%;
                .ant-upload.ant-upload-drag {
                  background: none !important;
                  border: none !important;
                }
                .wrap-dragger {
                  width: 100%;
                }
              }
            }
            .file-name {
              display: flex;
              align-items: center;
              padding-left: 12px;
              height: 40px;
              background: #ffffff;
              border: 1px solid #cccccc;
              box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
              border-radius: 3px 0 0 3px;
              color: #cccccc;
              word-wrap: break-word;
            }
            .button-upload {
              background: #00a3a5;
              height: 40px;
              width: 15%;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              border-radius: 0 3px 3px 0;
              cursor: pointer;
              .ant-upload {
                width: 100%;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  .add-question {
    width: 100%;
    text-align: center;
    margin-top: 24px;
    button {
      border: 1px solid #00a3a5;
      color: #00a3a5;
      height: 40px;
      width: 180px;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
    }
  }
`;
