import styled from 'styled-components';

export default styled.div`
  padding-bottom: 80px;
  .wrap-information {
    margin: 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      border-bottom: 1px solid #cccccc;
      .title {
        margin: 0;
        font-size: 16px;
        color: #2a2a2a;
      }
    }
    .container {
      padding: 40px;
      .item {
        display: flex;
        flex-direction: row;
        background-color: #ebebeb;
        margin-bottom: 12px;
        padding: 4px;
        .wrap-label {
          width: 35%;
          padding: 10px 16px;
          display: flex;
          align-items: center;
          .label {
            font-size: 14px;
            color: #2a2a2a;
          }
        }
        .wrap-value {
          display: flex;
          align-items: center;
          flex-direction: row;
          width: 100%;
          background-color: #ffffff;
          padding: 0 15px;
          .label {
            font-size: 18px;
            color: #2a2a2a;
          }
          .time {
            margin: 0 15px;
            font-size: 14px;
            color: #777777;
          }
          .warning {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .icon-warning {
              font-size: 18px;
              color: #f0330a;
              margin: 6px;
            }
            .content {
              color: #f0330a;
              align-items: center;
              font-size: 14px;
            }
          }
          .button {
            padding: 4px 16px;
            color: #00a3a5;
            background: #ffffff;
            border: 1px solid #00a3a5;
            box-shadow: 1px 1px 4px rgb(68 68 68 / 20%);
            border-radius: 5px;
            white-space: nowrap;
            cursor: pointer;
          }
        }
        .jc-sb {
          justify-content: space-between;
        }
      }
    }
  }
  .wrap-withdrawal-button {
    margin: 30px;
    .btn-withdrawal {
      text-decoration: underline;
      border: none;
      background-color: transparent;
      font-size: 12px;
      color: #00989a;
      cursor: pointer;
    }
  }
`;
