import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { memoizedGetChildrenItemIDFromTree } from 'libs/utils/curriculum/memoized-tree-data-utils';
import { deleteUserAssignCurriculum, getCurriculum } from '../../../Settings/Curriculum/thunk';
import { curriculumSelector } from '../../../Settings/Curriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { officialCurriculumSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import ProductInformation from '../ProductInformation';
import CurriculumNotes from '../CurriculumNotes';
import CurriculumContentStyles from './styles';
import CompleteModal from '../CompleteModal';
import UpdateConfirm from '../UpdateConfirm';
import { useSelector } from 'react-redux';
import { NoImageOfficial } from 'assets';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';
import {
  deleteCurriculum,
  deleteLevelCurriculum,
  deleteLinkQuestion,
  getDataOfficialCurriculumList,
  getDataProviderInformation,
} from '../../thunk';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  dataItemDetail?: Types.OfficialCurriculumList2.ResponseType;
}

const CurriculumContent: React.FC<Props> = ({ visible, setVisible, dataItemDetail }) => {
  const [openUpdateCompleteActive, setOpenUpdateCompleteActive] = useState<boolean>(false);
  const [isUpdateConfirmComplete, setIsUpdateConfirmComplete] = useState<boolean>(false);
  const [openProductInformation, setOpenProductInformation] = useState<boolean>(false);
  const [openCurriculumNotes, setOpenCurriculumNotes] = useState<boolean>(false);
  const [isUpdateConfirm, setIsUpdateConfirm] = useState<boolean>(false);
  const [openModalCurriculumSuspension, setOpenModalCurriculumSuspension] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { userInfo } = useSelector(authSelector);
  const { curricullum } = useSelector(curriculumSelector);
  const { dataCurriculumHierarchy, dataInformationCurriculum } = useSelector(
    officialCurriculumSelector
  );
  const handleToggleModal = () => {
    setOpenUpdateCompleteActive(false);
    setTimeout(() => {
      setVisible(false);
    }, 200);
  };

  const handleChangeCurricullum = useMemo(() => {
    return curricullum.filter(
      (i) => i.official_curriculum_code === dataItemDetail?.official_curriculum_code
    );
  }, [dataItemDetail, curricullum]);

  const handleDeleteCurriculum = useCallback(async () => {
    if (handleChangeCurricullum && dataItemDetail) {
      dispatch(startLoading());
      const listChildItemID = memoizedGetChildrenItemIDFromTree({
        treeData: handleChangeCurricullum[0],
      });

      await Promise.all([
        ...listChildItemID.map(async (item: Types.FlatChildrenItemID<Types.CurriculumItemType>) => {
          if (item.columnIndex === 0) {
            const resultAction = await dispatch(
              deleteCurriculum({
                id: item.i_id!,
              })
            );
            if (deleteCurriculum.fulfilled.match(resultAction)) {
              dispatch(
                deleteUserAssignCurriculum({
                  conditions: [
                    {
                      id: 'curriculum_code',
                      search_value: [item.code],
                    },
                  ],
                  use_display_id: true,
                })
              );
            }
          } else if (item.columnIndex < 5) {
            return dispatch(
              deleteLevelCurriculum({
                id: item.i_id!,
                level: item.columnIndex,
                realtime_auto_link: true,
              })
            );
          } else {
            return dispatch(
              deleteLinkQuestion({
                id: item.question_assign_level_i_id!,
              })
            );
          }
        }),
      ]);
      setOpenUpdateCompleteActive(true);
      fetchDataOfficialCurriculumList();
      dispatch(stopLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCurriculumHierarchy, dispatch]);

  const fetchDataOfficialCurriculumList = useCallback(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataOfficialCurriculumList({
            conditions: [],
            page: 1,
            per_page: 0,
            company_id: userInfo?.company_id || '',
            sort_fields: [
              { id: 'official_curriculum_code', order: 'asc' },
              { id: 'publish_start_date', order: 'desc' },
            ],
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [userInfo, dispatch]);

  useEffect(() => {
    if (!userInfo || !visible) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        await dispatch(
          getDataProviderInformation({
            conditions: [
              {
                id: 'company_id',
                search_value: [dataItemDetail?.provider_id],
                exact_match: true,
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        await dispatch(
          getCurriculum({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, visible, dataItemDetail]);

  return (
    <Modal
      width={720}
      title="カリキュラム説明"
      open={visible}
      onCancel={handleToggleModal}
      headerStyle={{ borderBottom: '1px solid #CCCCCC' }}
      bodyStyle={{ background: '#F9F8F8', padding: 0 }}
      footerStyle={{ background: '#F9F8F8' }}
    >
      <CurriculumContentStyles>
        <div className="picture-item">
          {dataItemDetail?.file?.originFileObj ? (
            <img
              src={URL.createObjectURL(new Blob([dataItemDetail?.file?.originFileObj as Blob]))}
              alt=""
              className="design-course"
            />
          ) : (
            <img src={NoImageOfficial} alt="" className="design-course-no" />
          )}
        </div>
        <div className="container">
          <div className="item-title">
            <div className="btn-title">
              <button className="btn btn-active">利用中</button>
              <p className="text-btn" onClick={() => setOpenProductInformation(true)}>
                制作者：<span className="label">{dataInformationCurriculum[0]?.creator}</span>
              </p>
            </div>
            <div className="content">
              <p className="text-content">{dataItemDetail?.curriculum_name}</p>
              <p className="version">v {dataItemDetail?.release_version}</p>
            </div>
          </div>
          <div className="item-content">
            <button className="btn-information" onClick={() => setOpenCurriculumNotes(true)}>
              リリース情報
            </button>
            <div className="content">
              <p className="label">説明：</p>
              <p className="text-content">{dataItemDetail?.description}</p>
            </div>
          </div>
          <div className="group-btn">
            <button onClick={() => setIsUpdateConfirm(true)} className="btn btn-active">
              利用する
            </button>
            <button
              onClick={() => setOpenModalCurriculumSuspension(true)}
              className="btn btn-default"
            >
              利用停止
            </button>
            <button onClick={handleToggleModal} className="btn btn-close">
              閉じる
            </button>
          </div>
        </div>
        <ProductInformation
          visible={openProductInformation}
          setVisible={setOpenProductInformation}
        />
        <CurriculumNotes
          visible={openCurriculumNotes}
          setVisible={setOpenCurriculumNotes}
          dataItemDetail={dataItemDetail}
        />
        <ConfirmDeleteModal
          title="OFFICIALカリキュラム 利用停止"
          visible={openModalCurriculumSuspension}
          setVisible={setOpenModalCurriculumSuspension}
          onSubmit={handleDeleteCurriculum}
          subTitle="選択した OFFICIALカリキュラムの利用を停止します。"
          description={
            <>
              OFFICIALカリキュラムの利用を停止すると、カリキュラムマスタから削除されます。
              <br />
              カリキュラムに設定されていた設問、コピー作成したカリキュラムは削除されずに残ります。
              <br />
              再度利用開始は可能ですが、ユーザーの実施履歴は引き継がれませんのでご注意ください。
              <br />
            </>
          }
        />
        <CompleteModal
          title="OFFICIAL OFFICIALカリキュラム 利用停止"
          visible={openUpdateCompleteActive}
          setVisible={setOpenUpdateCompleteActive}
          handleToggleModalComplete={handleToggleModal}
          subTitle="OFFICIAL OFFICIALカリキュラムの利用停止が完了しました。"
        />
        <UpdateConfirm
          title="アップデート"
          visible={isUpdateConfirm}
          setVisible={setIsUpdateConfirm}
          dataItemDetail={dataItemDetail}
          onSubmit={() => setIsUpdateConfirmComplete(true)}
          subTitle="OFFICIALカリキュラムのアップデートを実行します。"
          description={
            <>
              実行すると、実施中のユーザーがいる場合も
              <br />
              カリキュラムがアップデートされますのでご注意ください。
            </>
          }
        />
        <CompleteModal
          title="アップデート"
          visible={isUpdateConfirmComplete}
          setVisible={setIsUpdateConfirmComplete}
          subTitle="OFFICIALカリキュラムのアップデートが完了しました！"
        />
      </CurriculumContentStyles>
    </Modal>
  );
};

export default CurriculumContent;
