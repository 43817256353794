import React from 'react';
import { UserOutlined, EyeInvisibleOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { orderBy } from 'lodash';

import { settingSelector } from 'containers/AppSettings/selectors';
import EditUserStyled from '../../Edit/styles';
import { OrderByType } from 'types/config';
import { IconEnalarge } from 'assets';
import { Header } from 'components';
import * as Types from 'types';

interface Props {
  confirmStatus: boolean;
  birthDay: {
    day: string;
    month: string;
    year: string;
  };
  dateJoinedCompany: {
    month: string;
    year: string;
  };
  dataSkillUserCheck: Types.DataCheckType[];
  orderTypeSkillCheck: OrderByType;
  dataSkillCheck: Types.SkillCheck.ResponseType[];
  dataCurriculumBatch: Array<Types.Curriculums.ResponseType>;
  formik: Types.EditInterviewFormik;
  dataSelectDepartment: Types.SelectDepartment.ResponseType[];
  dataSelectPosition: Types.SelectPosition.ResponseType[];
}

const InterviewUserDetailPDF: React.FC<Props> = ({
  confirmStatus,
  birthDay,
  dateJoinedCompany,
  dataSkillUserCheck,
  dataCurriculumBatch,
  dataSkillCheck,
  orderTypeSkillCheck,
  formik,
  dataSelectDepartment,
  dataSelectPosition,
}) => {
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  return (
    <>
      <Header title={headerTitle} />
      <EditUserStyled collapsedMenu={collapsedMenu}>
        <Form layout="vertical" autoComplete="off">
          <div className="wrap-basic-information">
            <div className="header">基本情報</div>
            <div className="body">
              <div className="wrap-user">
                <div className="avatar-user">
                  <UserOutlined className="icon-user" />
                  <span className="text-content">面接ユーザー</span>
                </div>
              </div>
              <div className="form-left">
                <Form.Item
                  label={
                    <span className="text-label">
                      管理番号 <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    className="text-input"
                    placeholder="半角数字：最大10文字"
                    maxLength={10}
                    value={formik.manage_code}
                  />
                </Form.Item>
                <Form.Item
                  label={<span className="text-label">生年月日</span>}
                  className="form-input"
                >
                  <Select
                    placeholder="----"
                    value={birthDay.year || undefined}
                    className="pull_down input_small"
                    disabled={confirmStatus}
                  ></Select>
                  <span className="text-label-content">年</span>
                  <Select
                    className="pull_down input-month-day"
                    value={birthDay.month || undefined}
                    placeholder="--"
                    disabled={confirmStatus}
                  ></Select>
                  <span className="text-label-content">月 </span>
                  <Select
                    className="pull_down input-month-day"
                    value={birthDay.day || undefined}
                    placeholder="--"
                    disabled={confirmStatus}
                  ></Select>
                  <span className="text-label-content">日</span>
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-label">
                      メールアドレス <span className="require" />
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="email_address"
                    className="text-input"
                    placeholder="全角カナ：最大100文字"
                    readOnly={true}
                    maxLength={100}
                    value={formik.email_address}
                  />
                </Form.Item>
              </div>
              <div className="form-right">
                <Form.Item
                  label={
                    <span className="text-label">
                      氏名 <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="name"
                    className="text-input"
                    placeholder="全角カナ：最大100文字"
                    readOnly={confirmStatus}
                    maxLength={100}
                    value={formik.name}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="text-label">
                      氏名（フリガナ） <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Input
                    name="name_phonetic"
                    className="text-input"
                    placeholder="全角カナ：最大100文字"
                    readOnly={confirmStatus}
                    maxLength={100}
                    value={formik.name_phonetic}
                  />
                </Form.Item>
              </div>
            </div>
          </div>
          <div className="wrap-department">
            <div className="header">募集情報</div>
            <div className="body">
              <div className="recruitment-information">
                <Form.Item
                  label={
                    <span className="text-label">
                      採用予定年月 <span className="require" />
                    </span>
                  }
                  className="form-input"
                >
                  <Select
                    placeholder="----"
                    className="pull_down input_small"
                    allowClear
                    value={dateJoinedCompany.year || undefined}
                    disabled={confirmStatus}
                  ></Select>
                  <span className="text-label-content">年</span>
                  <Select
                    className="pull_down input-month-day"
                    placeholder="--"
                    allowClear
                    value={dateJoinedCompany.month || undefined}
                    disabled={confirmStatus}
                  ></Select>
                  <span className="text-label-content">月 </span>
                </Form.Item>
              </div>
              <div className="wrap-recruitment">
                <Form.Item
                  label={
                    <span className="text-label">
                      募集所属 <span className="require">*</span>
                    </span>
                  }
                  className="form-input"
                >
                  <Select
                    className="pull_down"
                    placeholder="選択してください"
                    disabled={confirmStatus}
                    value={
                      (dataSelectDepartment || []).find((e) => e.i_id === formik.affiliation_id)
                        ?.name || ''
                    }
                  ></Select>
                </Form.Item>
                <Form.Item
                  className="form-input"
                  label={
                    <span className="text-label">
                      募集役職 <span className="require">*</span>
                    </span>
                  }
                >
                  <Select
                    className="pull_down"
                    value={
                      dataSelectPosition.find((e) => e.i_id === formik.position_code) || undefined
                    }
                    placeholder="選択してください"
                    disabled={confirmStatus}
                  ></Select>
                </Form.Item>
              </div>
              <div className="work-history">
                <Form.Item
                  htmlFor=""
                  label={
                    <div className="wrap-text-area">
                      <span className="label-history">職歴</span>
                      <span className="text-enlarge">
                        <img src={IconEnalarge} alt="enlarge" />
                        入力欄を拡大
                      </span>
                    </div>
                  }
                >
                  <TextArea
                    name="work_history"
                    className="text-input"
                    placeholder="全角：最大1050文字"
                    rows={4}
                    showCount
                    maxLength={1050}
                    disabled={confirmStatus}
                    value={formik.work_history}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="wrap-skill-check">
            <div className="header">スキルチェック設定状況</div>
            <div className="body">
              <p className="description">
                スキルチェックを設定することができます。スキルチェックを追加ボタンをクリックしてください。
              </p>
              {dataSkillUserCheck?.length > 0 && (
                <>
                  <div className="wrap-label-hide">
                    <span className="label-hide">
                      <EyeInvisibleOutlined className="icon" /> 非表示リスト
                    </span>
                    <Select
                      className="select-sort-order"
                      value={
                        orderTypeSkillCheck === 'asc'
                          ? '設定の古い順に表示'
                          : '設定の新しい順に表示'
                      }
                    ></Select>
                  </div>
                  {orderBy(dataSkillUserCheck, ['skill_check_name'], [orderTypeSkillCheck]).map(
                    (item, index) => {
                      return (
                        item &&
                        item.hidden_item_id && (
                          <div className="item-result" key={index}>
                            <div>
                              <span className="text-add">{index + 1}</span>
                              <span className="content">{item?.item_name}</span>
                            </div>
                            <Tooltip placement="top" title={'非表示にする'}>
                              {item.hidden_item === 'on' ? (
                                <EyeInvisibleOutlined className="delete active" />
                              ) : (
                                <EyeInvisibleOutlined className="delete" />
                              )}
                            </Tooltip>
                          </div>
                        )
                      );
                    }
                  )}
                </>
              )}
              {dataSkillCheck?.map(({ name, i_id }, index) => (
                <div key={index} className="item-result-add">
                  <div>
                    <span className="text-add">＋</span>
                    <span className="content">{name}</span>
                  </div>
                  <DeleteOutlined
                    className="delete"
                    //   onClick={() => handleDeleteItemSkillCheck(i_id)}
                  />
                </div>
              ))}
              {dataCurriculumBatch?.map(({ name, i_id }, index) => (
                <div key={index} className="item-result-add">
                  <div>
                    <span className="text-add">＋</span>
                    <span className="content">{name}</span>
                  </div>
                  <DeleteOutlined className="delete" />
                </div>
              ))}
              {!dataSkillUserCheck && !dataSkillCheck && <div className="empty-text">未設定</div>}
              <button className="button" type="button">
                ＋ スキルチェックを追加
              </button>
            </div>
          </div>
          <div className="wrap-submit">
            <div className="wrap-button">
              <Button className="btn btn_submit">{confirmStatus ? '登録' : '確認画面へ'}</Button>
              <button className="btn btn_close">キャンセル</button>
            </div>
          </div>
        </Form>
      </EditUserStyled>
    </>
  );
};

export default InterviewUserDetailPDF;
