import React, { useCallback, useEffect, useState } from 'react';
import { CloseCircleFilled, ExclamationCircleOutlined, LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { values } from 'lodash';
import dayjs from 'dayjs';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
  generatePath,
} from 'react-router-dom';

import { getDataCompany, getDataUserCompany, getTotalFileSize } from 'containers/Auth/thunk';
import { ROUTER_WITH_SCREEN_PERMISSIONS } from 'constant/screenPermission.constant';
import AccessDeniedStyled, { Wrapper, ModalStyled, WrapperFileSize } from './styles';
import { isOpenMessages, logout, updateOpenModal } from 'containers/Auth/slice';
import ModalOfficial from '../pages/Dashboard/Modal/ModalOfficial';
import { settingSelector } from 'containers/AppSettings/selectors';
import InformationRegister from 'containers/InformationRegister';
import { dashboardSelector } from '../pages/Dashboard/selectors';
import TreeTrialModalDashboard from '../pages/Dashboard/Modal';
import { privateRoutes, publicRoutes, routes } from './routes';
import { resetOptionModal } from '../pages/Dashboard/slice';
import OfficialModal from 'components/Modal/OfficialModal';
import ModalConfirmMobile from 'containers/ConfirmMobile';
import { Header, SideBar, SpinLoading } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import FreeTrialModal from 'components/Modal/FreeTrial';
import { useAppDispatch } from '../hooks';
import * as Types from 'types';
import { Button } from 'antd';

interface GuardRouteProps {
  isLogin: boolean;
  isFirstLogin: boolean;
  isPrivate: boolean;
  redirectPath: string;
  title: string;
  isOpen: boolean;
  children: React.ReactElement;
  userInfo: Types.Users.ResponseType | undefined;
  dataCompany?: Types.Companies.ResponseType;
  userCompany?: Types.Companies.ResponseType;
}

export const Navigations: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLogin, loading, isFirstLogin, userInfo, dataCompany, userCompany, isOpen } =
    useSelector(authSelector);

  useEffect(() => {
    if (!userInfo) return;
    Promise.all([
      dispatch(
        getDataCompany({
          conditions: [
            {
              id: 'admin_email',
              search_value: [userInfo.email],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 1,
        })
      ),
      dispatch(
        getDataUserCompany({
          conditions: [
            {
              id: 'id',
              search_value: [userInfo.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 1,
        })
      ),
    ]);
  }, [dispatch, userInfo]);

  const renderRoute = useCallback(
    (route, isPrivate?: boolean) => {
      if (!route || !values(route)) {
        return undefined;
      }
      return values(route)?.map(({ route: subRoute, element, title, ...props }, index) => (
        <Route
          key={index}
          {...props}
          element={
            <GuardRoute
              isLogin={isLogin}
              isFirstLogin={isFirstLogin}
              isOpen={isOpen}
              dataCompany={dataCompany}
              userCompany={userCompany}
              isPrivate={!!isPrivate}
              userInfo={userInfo}
              title={title}
              redirectPath={
                isPrivate
                  ? routes.Login.path
                  : userInfo?.user_type === 'interview'
                  ? '/new-skill-check-selection'
                  : '/'
              }
            >
              {element}
            </GuardRoute>
          }
          children={renderRoute(subRoute)}
        />
      ));
    },
    [isLogin, isFirstLogin, isOpen, dataCompany, userCompany, userInfo]
  );

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100vw',
          height: '100vh',
        }}
      >
        <SpinLoading size="large" loading />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {renderRoute(publicRoutes)}
          {renderRoute(privateRoutes, true)}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

const GuardRoute = ({
  title,
  userInfo,
  children,
  isLogin,
  isPrivate,
  redirectPath,
  isFirstLogin,
  dataCompany,
  userCompany,
  isOpen,
}: GuardRouteProps) => {
  const { headerTitle } = useSelector(settingSelector);
  const { optionMenu } = useSelector(dashboardSelector);
  const { collapsedMenu } = useSelector(settingSelector);
  const { totalFileSize } = useSelector(authSelector);

  const location = useLocation();

  const [closeBannerTotalSize, setCloseBannerTotalSize] = useState(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(true);
  const [closeFooter, setCloseFooter] = useState(false);
  const [openOfficial, setOpenOfficial] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!dataCompany || !dataCompany.plan_expiry_at) return;
    const expiryDate = dayjs(dataCompany.plan_expiry_at);
    const currentDate = dayjs();
    const diffInDays = currentDate.diff(expiryDate, 'day');

    if (diffInDays > 0) {
      setCloseFooter(true);
      setOpenOfficial(true);
    }
  }, [dataCompany]);

  useEffect(() => {
    if (
      (userInfo?.user_type !== 'admin' &&
        userCompany?.suspension_flg === '1' &&
        dayjs().isAfter(dayjs(userCompany?.suspension_date).format('YYYY-MM-DD'))) ||
      (userCompany?.forced_withdrawal_flg === '1' &&
        dayjs().isAfter(dayjs(userCompany?.deletedat).format('YYYY-MM-DD')))
    ) {
      dispatch(logout());
      dispatch(isOpenMessages(true));
    }
  }, [dispatch, userCompany, userInfo]);

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    dispatch(
      getTotalFileSize({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, window.location.pathname, userInfo]);

  if (isPrivate && isLogin) {
    const permissionName = Object.keys(ROUTER_WITH_SCREEN_PERMISSIONS)
      .splice(1)
      .find((key) =>
        ROUTER_WITH_SCREEN_PERMISSIONS[
          key as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS
        ]?.includes(location.pathname.split(':')[0])
      );

    if (
      permissionName &&
      userInfo?.lookup_items?.role_code &&
      !Number(
        userInfo?.lookup_items?.role_code[
          permissionName as keyof typeof ROUTER_WITH_SCREEN_PERMISSIONS
        ]
      ) &&
      permissionName !== 'skill_check_implementation_permission'
    ) {
      return (
        <SideBar accessDenied>
          <Header title={headerTitle} />
          <AccessDeniedStyled>
            <div className="access-denied">
              <LockOutlined className="icon-lock" />
              <span className="content">
                権限がないためページを表示できません。管理者にお問い合わせください。
              </span>
            </div>
          </AccessDeniedStyled>
        </SideBar>
      );
    }

    return (
      <SideBar title={title}>
        {children}
        {userInfo?.user_type === 'mobile' ? (
          <ModalConfirmMobile />
        ) : (
          (!userInfo?.last_login_at || isFirstLogin) &&
          isShowModal && (
            <>
              {dataCompany?.plan_id === 'standard' ? (
                <FreeTrialModal visible={isShowModal} setVisible={setIsShowModal} />
              ) : dataCompany?.plan_id === 'premium' ? (
                <OfficialModal visible={isShowModal} setVisible={setIsShowModal} />
              ) : (
                <InformationRegister isCloseModal={setIsShowModal} />
              )}
            </>
          )
        )}
        {userInfo?.user_type === 'admin' &&
          userCompany?.suspension_flg === '1' &&
          dayjs().isAfter(dayjs(userCompany?.suspension_date).format('YYYY-MM-DD')) && (
            <ModalStyled
              title={<span className="title">{title}</span>}
              open={isOpen}
              width={800}
              onCancel={() => dispatch(updateOpenModal(false))}
              okButton={{
                text: 'ご利用状況を確認する',
                onClick: () => {
                  if (routes.UsageStatus.path) {
                    navigate(generatePath(routes.UsageStatus.path));
                    dispatch(updateOpenModal(false));
                  }
                },
              }}
              cancelButton={{
                text: 'ログアウト',
                onClick: () => {
                  dispatch(logout());
                },
              }}
              headerStyle={{
                borderBottom: '1px solid #CCCCCC',
              }}
              bodyStyle={{
                backgroundColor: '#f9f8f8',
              }}
              footerStyle={{
                backgroundColor: '#f9f8f8',
              }}
            >
              <div className="content">
                <div className="content-body">
                  <div className="sub-title-modal">
                    <ExclamationCircleOutlined className="icon" />
                    重要なお知らせ
                  </div>
                </div>
                <div>
                  <span>お支払期限を過ぎた利用料金があります。お支払いをお願いいたします。</span>
                  <br />
                  <span>
                    なお、一定期間お支払いの確認が取れなかったため、
                    <span className="sub">ご利用を停止</span>
                    させていただいております。
                  </span>
                  <br />
                  <span>お支払いの確認が取れ次第、ご利用を再開させていただきます。</span>
                </div>
                <br />
                <span>
                  ※今後お支払いの確認が取れない状態が続いた場合には、
                  <span className="sub">強制解約</span>
                  の手続きを取らせていただく場合がございます。
                </span>
                <br />
                <span>
                  強制解約になった場合には全てのデータが削除され、復元できませんのでご注意下さい。
                </span>
              </div>
            </ModalStyled>
          )}

        {userCompany?.unpaid_alert_flg === '1' &&
          dayjs().isAfter(dayjs(userCompany?.unpaid_alert_date).format('YYYY-MM-DD')) && (
            <Wrapper collapsedMenu={collapsedMenu} optionMenu={optionMenu.skillCheckMenu}>
              <div className={`${closeFooter ? 'container-none' : 'container-footer'}`}>
                <div className="content-error">
                  <span className="sub">
                    <ExclamationCircleOutlined className="icon" />
                    重要なお知らせ
                  </span>
                  <span className="sub-title">
                    お支払い期限を過ぎた利用料金がございます。至急お支払いをお願いいたします。
                    <br />
                    ※一定期間お支払いの確認が取れない場合、
                    <span className="span-error">ご利用を停止</span>
                    させていただく場合がございますので、ご注意下さい。
                  </span>
                  <Button
                    className="check-btn"
                    onClick={() => {
                      setCloseFooter(true);
                      navigate(generatePath(routes.UsageStatus.path, { entity: 'receiving' }));
                    }}
                  >
                    ご利用状況を確認する
                  </Button>
                </div>
              </div>
            </Wrapper>
          )}
        {dataCompany?.plan_id === 'standard' && totalFileSize >= 3 && (
          <WrapperFileSize collapsedMenu={collapsedMenu}>
            <div className={`${closeBannerTotalSize ? 'container-none' : 'container-footer'}`}>
              <div className="content">
                <span className="sub">
                  <ExclamationCircleOutlined className="icon" />
                  ストレージ使用量上限です。
                </span>
                <div className="sub-title">
                  <span>
                    ストレージを利用するデータの登録が制限されます。登録したデータを削除することで、新規に登録が可能になります。
                  </span>
                  <span>無料トライアル詳細メニューで使用量を確認できます。</span>
                </div>

                <div
                  className="close"
                  onClick={() => {
                    setCloseBannerTotalSize(true);
                  }}
                >
                  <CloseCircleFilled className="icon-close" />
                </div>
              </div>
            </div>
          </WrapperFileSize>
        )}
        {dataCompany?.plan_id === 'standard' &&
          userInfo?.login_id === dataCompany.admin_email &&
          (totalFileSize >= 3 ? closeBannerTotalSize : !closeBannerTotalSize) && (
            <Wrapper collapsedMenu={collapsedMenu} optionMenu={optionMenu.skillCheckMenu}>
              <div className={`${closeFooter ? 'container-none' : 'container-footer'}`}>
                <div className="content">
                  <span className="sub">
                    <ExclamationCircleOutlined className="icon" />
                    無料トライアル中
                  </span>
                  <span className="sub-title">
                    無料トライアル終了後10日以内に本登録を行うとデータを引き継ぐことができます。
                  </span>
                  <Button
                    className="check-btn"
                    onClick={() => {
                      setCloseFooter(true);
                      setOpenOfficial(true);
                    }}
                  >
                    本登録はこちら
                  </Button>
                  <div
                    className="close"
                    onClick={() => {
                      setCloseFooter(true);
                      dispatch(resetOptionModal({ skillCheck: true }));
                    }}
                  >
                    <CloseCircleFilled className="icon-close" />
                  </div>
                </div>
                <ModalOfficial visible={openOfficial} setVisible={setOpenOfficial} />
                <TreeTrialModalDashboard
                  visible={optionMenu.skillCheckMenu}
                  openOfficial={openOfficial}
                  setOpenOfficial={setOpenOfficial}
                />
              </div>
            </Wrapper>
          )}
      </SideBar>
    );
  } else if (!isPrivate && !isLogin) {
    return children;
  } else {
    return <Navigate to={redirectPath} state={{ from: location }} replace={true} />;
  }
};
