import React, { useEffect, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';

import { getDataCurriculumBatch, getDataSelectCreator, getDataSelectCurriculum } from '../../thunk';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { educationProgramSelector } from '../../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { ConditionsType, Curriculums } from 'types';
import { Modal, SelectField } from 'components';
import CurriculumBatchStyled from './styles';
import { useAppDispatch } from 'hooks';

interface Props {
  visible: boolean;
  onSubmit?: (
    item: Array<Curriculums.ResponseType>,
    type: 'create' | 'delete'
  ) => Promise<void> | void;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const PER_PAGE = 10;

const { Option } = Select;

const CurriculumBatchModal: React.FC<Props> = ({ visible, setVisible, onSubmit }) => {
  const [selectedRow, setSelectedRow] = useState<Curriculums.ResponseType[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const { dataCurriculumBatch, total, dataSelectCurriculum, dataSelectCreator, dataUserSearch } =
    useSelector(educationProgramSelector);

  const columns: ColumnsType<Curriculums.ResponseType> = [
    {
      title: 'コード',
      dataIndex: 'code',
      key: 'code',
      width: '10%',
    },
    {
      title: '必修カリキュラム名',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: '説明',
      dataIndex: 'description',
      key: 'description',
      width: '55%',
      render: (text, _, index) =>
        text ? (
          <div className="flex">
            <Tooltip
              trigger={['click']}
              title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
              color="#ffffff"
              onVisibleChange={(v) =>
                setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
              }
            >
              <FileTextOutlined
                className="icon"
                style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
              />
            </Tooltip>
            <span className="text-content">{text}</span>
          </div>
        ) : null,
    },
    {
      title: '作成者',
      dataIndex: '',
      key: '',
      width: '10%',
      render: (_, record) => dataUserSearch.find((e) => e.email === record.createdby)?.name || '',
    },
  ];

  const dispatch = useAppDispatch();

  const handleToggleModal = () => {
    setVisible(false);
    setSelectedRow([]);
  };

  const handleSubmit = async (type: 'create' | 'delete') => {
    handleToggleModal();
    onSubmit && (await onSubmit(selectedRow, type));
  };

  const formik = useFormik({
    initialValues: {
      code: '',
      name: '',
      createdby: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
        {
          id: 'required_curriculum',
          search_value: ['1'],
        },
      ];
      Object.keys(values).forEach((key) => {
        if (values[key as keyof typeof values]) {
          conditions.push({
            id: key,
            search_value: [values[key as keyof typeof values]],
            exact_match: true,
          });
        }
      });
      await dispatch(
        getDataCurriculumBatch({
          conditions,
          page: page,
          per_page: PER_PAGE,
        })
      );
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSelectCurriculum({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectCreator({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, visible]);

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataCurriculumBatch({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'required_curriculum',
                search_value: ['1'],
              },
            ],
            page: page,
            per_page: PER_PAGE,
            include_lookups: true,
            include_item_ref: true,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, page, userInfo, visible]);

  return (
    <Modal
      visible={visible}
      title={<h3 className="title">必修カリキュラム一括設定</h3>}
      width={1300}
      okButton={{
        text: '登録',
        onClick: () => handleSubmit('create'),
        disabled: !selectedRow.length,
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      othersButton={[
        {
          text: '削除',
          onClick: () => handleSubmit('delete'),
          disabled: !selectedRow.length,
        },
      ]}
      onCancel={handleToggleModal}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
    >
      <CurriculumBatchStyled dataCurriculumBatch={!!dataCurriculumBatch?.length}>
        <p className="sub-title">
          一括設定する必修カリキュラムを選択して、登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="code"
                className="item"
                label={<span className="text-label">必修カリキュラムコード</span>}
              >
                <SelectField
                  name="code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectCurriculum &&
                    dataSelectCurriculum.map(({ code }, i) => (
                      <Option value={code} key={i}>
                        {code}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">必修カリキュラム名称</span>}
              >
                <SelectField
                  name="name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectCurriculum &&
                    dataSelectCurriculum.map(({ name }, i) => (
                      <Option value={name} key={i}>
                        {name}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="createdby"
                className="item"
                label={<span className="text-label">作成者</span>}
              >
                <SelectField
                  name="createdby"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dataSelectCreator &&
                    dataSelectCreator.map((value, i) => (
                      <Option value={value.email} key={i}>
                        {value.name}
                      </Option>
                    ))}
                </SelectField>
              </Form.Item>
              <SubmitButton className="btn-search" loading={false}>
                <SearchOutlined className="icon-search" />
                検索
              </SubmitButton>
              <span className="label-reset" onClick={() => formik.resetForm()}>
                リセット
              </span>
            </div>
          </Form>
        </FormikProvider>
        <div className="text-count">
          {page * PER_PAGE > total ? total : page * PER_PAGE}
          <span className="text-static">件表示</span> / {total}
          <span className="text-static">名</span>
        </div>
        <Table
          className="table"
          loading={loading}
          rowSelection={{
            selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
            onChange: (_, rows) => setSelectedRow(rows),
          }}
          dataSource={dataCurriculumBatch.map((item, index) => ({ ...item, index }))}
          columns={columns}
          pagination={{
            pageSize: PER_PAGE,
            showSizeChanger: false,
            total: total,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
          rowKey="i_id"
        />
      </CurriculumBatchStyled>
    </Modal>
  );
};

export default CurriculumBatchModal;
