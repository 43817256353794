import { createSlice } from '@reduxjs/toolkit';

import * as Types from 'types';
import {
  getSkillCheckQuestion,
  getSkillCheckUsers,
  getUsersSkillCheck,
  getDataSkillCheck,
  getSelectGroups,
  getSelectTypes,
  getDataSkillCheckTree,
} from './thunk';

export interface InitialState {
  total: number;
  skillCheckUsersTotal: number;
  skillCheckQuestionTotal: number;
  groups: Types.Groups.ResponseType[];
  skillCheck: Types.SkillCheckTreeViewType[];
  userSkillCheck: Types.SkillCheckTreeViewType[];
  filter_option: Types.SkillCheckTreeViewType[];
  time_limit: Types.SelectTypes.ResponseType[];
  dataSkillCheck: Array<Types.SkillCheck.ResponseType>;
  skillCheckUsers: Types.SkillCheckUser.ResponseType[];
  skillCheckQuestion: Types.SkillCheckQuestion.ResponseType[];
  filter_conditions: Types.SkillCheckFilterType & {
    conditions: Types.ConditionsType[];
  };
}

const initialState: InitialState = {
  skillCheckQuestionTotal: 0,
  skillCheckUsersTotal: 0,
  skillCheckQuestion: [],
  skillCheckUsers: [],
  dataSkillCheck: [],
  userSkillCheck: [],
  filter_option: [],
  skillCheck: [],
  time_limit: [],
  groups: [],
  total: 0,
  filter_conditions: {
    conditions: [],
  },
};

export const skillCheckSlice = createSlice({
  name: 'skillCheck-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSelectTypes.fulfilled, (state, action) => {
      state.time_limit = action.payload.items.filter((item) => item.type === 'time_limit');
    });
    builder.addCase(getSelectGroups.fulfilled, (state, action) => {
      state.groups = action.payload.items;
    });

    builder.addCase(getDataSkillCheckTree.fulfilled, (state, action) => {
      const newData: Array<Types.SkillCheckTreeViewType> = [];
      action.payload.items.forEach((item) => {
        const index = newData.findIndex((data) => data.i_id === item.i_id);
        if (index >= 0) {
          if (
            item.question_code &&
            newData[index].children &&
            !newData[index].children?.some(
              (question) => question.i_id === item.i_id_skill_check_code_assign
            )
          ) {
            newData[index].children!.push({
              i_id: item.i_id_skill_check_code_assign,
              i_id_question: item.i_id_question_code,
              skill_check_code: item.skill_check_code,
              question_code: item.question_code,
              question_name: item.question_name,
              sort_order: item.sort_order,
              question_score: Number(item.score),
            });
            newData[index].score = newData[index].score + item.score;
          }
        } else {
          newData.push({
            i_id: item.i_id,
            company_id: item.company_id,
            description: item.description,
            start_period: item.start_period,
            end_period: item.end_period,
            implementation_settings: Number(item.implementation_settings),
            grouping_code: item.grouping_code,
            grouping_code2: item.group_code,
            settings_score: Number(item.setting_score),
            problems4: Number(item.problems4),
            skill_check_code: item.skill_check_code,
            skill_check_name: item.skill_check_name,
            publish: Number(item.publish),
            user_type: item.user_type,
            probs_count: Number(item.probs_count) || 0,
            score: item.score || 0,
            question_time_limit: Number(item.question_time_limit),
            createdby: item.createdby,
            fileID: item.fileID,
            lookup_items: item.lookup_items,
            user_children: [],
            file_skill_check: item.file_skill_check,
            base_date_for_report: item.base_date_for_report,
            children:
              item.question_code && item.i_id_skill_check_code_assign
                ? [
                    {
                      i_id: item.i_id_skill_check_code_assign,
                      i_id_question: item.i_id_question_code,
                      skill_check_code: item.skill_check_code,
                      question_code: item.question_code,
                      question_name: item.question_name,
                      sort_order: item.sort_order,
                      question_score: item.score,
                    },
                  ]
                : [],
          });
        }
      });
      state.filter_option = newData;
      state.skillCheck = newData;
    });

    builder.addCase(getUsersSkillCheck.fulfilled, (state, action) => {
      const newUsersSkillcheck: Array<Types.SkillCheckTreeViewType> = [];
      action.payload.items.forEach((item) => {
        const index = newUsersSkillcheck.findIndex((skillCheck) => skillCheck.i_id === item.i_id);
        if (index >= 0) {
          const departmentIndex = newUsersSkillcheck[index].user_children.findIndex(
            (user) => user.department_name === (item.department_name || '')
          );
          if (departmentIndex >= 0) {
            if (
              !newUsersSkillcheck[index].user_children[departmentIndex].children?.some(
                (user) => user.user_id === item.user_name_i_id
              ) &&
              item.skill_check_code_assign_i_id &&
              item.main === 'main'
            ) {
              newUsersSkillcheck[index].user_children[departmentIndex].children?.push({
                i_id: item.skill_check_code_assign_i_id,
                user_name: item.user_name,
                user_id: item.user_name_i_id,
                employee_number: item.employee_number,
                name_furigana: item.name_furigana,
              });
            }
          } else {
            if (item.skill_check_code_assign_i_id && item.main === 'main') {
              newUsersSkillcheck[index].user_children.push({
                department_name: item.department_name || '',
                children: [
                  {
                    i_id: item.skill_check_code_assign_i_id,
                    user_name: item.user_name,
                    user_id: item.user_name_i_id,
                    employee_number: item.employee_number,
                    name_furigana: item.name_furigana,
                  },
                ],
              });
            }
          }
        } else {
          newUsersSkillcheck.push({
            i_id: item.i_id,
            skill_check_code: item.skill_check_code,
            skill_check_name: item.skill_check_name,
            start_period: item.start_period,
            publish: Number(item.publish),
            company_id: item.company_id,
            grouping_code: item.grouping_code,
            skill_check_sort_order: Number(item.skill_check_sort_order),
            user_type: item.user_type,
            createdby: item.createdby,
            description: item.description,
            end_period: item.end_period,
            implementation_settings: Number(item.implementation_settings),
            probs_count: Number(item.question_count),
            score: Number(item.setting_score),
            fileID: item.fileID,
            question_time_limit: Number(item.question_time_limit),
            problems4: Number(item.problems4),
            children: [],

            user_children:
              item.skill_check_code_assign_i_id && item.main === 'main'
                ? [
                    {
                      department_name: item.department_name || '',
                      children: [
                        {
                          i_id: item.skill_check_code_assign_i_id,
                          user_name: item.user_name,
                          user_id: item.user_name_i_id,
                          employee_number: item.employee_number,
                          name_furigana: item.name_furigana,
                        },
                      ],
                    },
                  ]
                : [],
          });
        }
      });
      state.userSkillCheck = newUsersSkillcheck;
    });

    builder.addCase(getSkillCheckQuestion.fulfilled, (state, action) => {
      action.payload.report_results.forEach((item, index) => {
        if (
          action.payload.report_results[index - 1]?.skill_check_code === item.skill_check_code &&
          action.payload.report_results[index - 1]?.question_no
        ) {
          item.question_no = action.payload.report_results[index - 1]?.question_no + 1;
        } else {
          item.question_no = 1;
        }
      });
      state.skillCheckQuestion = action.payload.report_results;
      state.skillCheckQuestionTotal = action.payload.totalItems;
    });
    builder.addCase(getSkillCheckUsers.fulfilled, (state, action) => {
      state.skillCheckUsers = action.payload.report_results;
      state.skillCheckUsersTotal = action.payload.totalItems;
    });
    builder.addCase(getDataSkillCheck.fulfilled, (state, action) => {
      state.dataSkillCheck = action.payload.items;
    });
  },
});

export const { resetInitialState } = skillCheckSlice.actions;

export default skillCheckSlice.reducer;
