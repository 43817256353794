import { pick, omit } from 'lodash';

import * as Pages from 'pages';

export const routes = {
  Login: {
    path: '/login',
    title: '',
    element: <Pages.LoginPage />,
  },
  Register: {
    path: '/register',
    title: '',
    element: <Pages.RegisterPage />,
  },
  RegisterTrial: {
    path: '/register-trial',
    title: '',
    element: <Pages.RegisterPage screen="register-trial" />,
  },
  RegisterPremium: {
    path: '/register-premium',
    title: '',
    element: <Pages.RegisterPage screen="register-premium" />,
  },
  RegisterForm: {
    path: '/confirm_email/:confirmation_id',
    title: '',
    element: <Pages.RegisterFormPage />,
  },
  Dashboard: {
    index: true,
    element: <Pages.DashboardPage />,
    title: 'Home / Dashboard',
    path: '/',
  },
  CompanyExpired: {
    element: <Pages.CompanyExpiredPage />,
    title: '',
    path: '/company/:company_id/:admin_email',
  },
  ResetPassword: {
    path: '/pwd_reset',
    title: '',
    element: <Pages.ResetPassword />,
  },
  ResetPasswordForm: {
    path: '/pwd_reset/:confirmation_id',
    title: '',
    element: <Pages.ResetPasswordForm />,
  },
  Curriculum: {
    path: '/curriculum',
    title: 'カリキュラムツリー',
    element: <Pages.CurriculumPage />,
  },
  RolesMaster: {
    path: '/roles-master',
    title: '権限マスタ',
    element: <Pages.RolesMasterPage />,
  },
  CreateRolesMaster: {
    path: '/roles-master/create',
    title: '権限マスタ 新規登録',
    element: <Pages.CreateRolesMasterPage />,
  },
  PositionMaster: {
    path: '/position-master',
    title: '役職マスタ',
    element: <Pages.PositionMasterPage />,
  },
  EditRolesMaster: {
    path: '/roles-master/edit/:id',
    title: '権限マスタ 編集',
    element: <Pages.EditRolesMasterPage />,
  },
  AdministratorMaster: {
    path: '/administrator-master',
    title: '管理者マスタ',
    element: <Pages.AdministratorMasterPage />,
  },
  PaymentMaster: {
    path: '/payment-master',
    title: '支払マスタ',
    element: <Pages.PaymentMasterPage />,
  },
  AffiliationMaster: {
    path: '/affiliation-master',
    title: '所属マスタ',
    element: <Pages.AffiliationMasterPage />,
  },
  Employee: {
    path: '/employee',
    title: '社内ユーザーマスタ',
    element: <Pages.EmployeePage />,
  },
  InterviewUser: {
    path: '/interview-user',
    title: '面接ユーザーマスタ',
    element: <Pages.InterviewUsersPage />,
  },
  CreateEmployee: {
    path: '/employee/create',
    title: '社内ユーザー新規登録',
    element: <Pages.CreateEmployeePage />,
  },
  CreateInterviewUser: {
    path: '/interview-user/create',
    title: '面接ユーザー新規登録',
    element: <Pages.CreateInterviewUsersPage />,
  },
  EditEmployee: {
    path: '/employee/edit/:id',
    title: '社内ユーザー編集',
    element: <Pages.EditEmployeePage />,
  },
  EditInterview: {
    path: '/interview-user/edit/:id',
    title: '面接ユーザー編集',
    element: <Pages.EditInterviewPage />,
  },
  EducationProgram: {
    path: '/employee/batch-setting',
    title: '必修カリキュラム・スキルチェック一括設定',
    element: <Pages.EducationProgramPage />,
  },
  InterviewBatchSetting: {
    path: '/interview-user/batch-setting',
    title: '面接ユーザー スキルチェック一括設定',
    element: <Pages.BatchSettingPage />,
  },
  AnalysisGroup: {
    path: '/analysis-group',
    title: '分析グループマスタ',
    element: <Pages.AnalysisGroupPage />,
  },
  QuestionMasterPage: {
    path: '/question-master',
    title: '設問マスタ',
    element: <Pages.QuestionMasterPage />,
  },
  Profile: {
    path: '/profile',
    title: 'プロフィール',
    element: <Pages.ProfilePage />,
  },
  UserProfile: {
    path: '/profile/:id',
    title: 'プロフィール',
    element: <Pages.ProfilePage />,
  },
  SkillCheck: {
    path: '/skill-check',
    title: 'スキルチェックツリー',
    element: <Pages.SkillCheckPage />,
  },
  SkillCheckSelection: {
    path: '/skill-check-selection',
    title: 'スキルチェック選択',
    element: <Pages.SkillCheckSelectionPage />,
  },
  TrainingReport: {
    path: '/training-report',
    title: 'トレーニングレポート',
    element: <Pages.TrainingReportPage />,
  },
  UsageStatus: {
    path: '/usage-status',
    title: 'ご利用状況一覧',
    element: <Pages.UsageStatusPage />,
  },
  OfficialCurriculum: {
    path: '/official-curriculum',
    title: 'OFFICIALカリキュラム管理',
    element: <Pages.OfficialCurriculumPage />,
  },
  TrainingCurriculum: {
    path: '/training-curriculum',
    title: 'トレーニング',
    element: <Pages.TrainingCurriculumPage />,
  },
  SkillCheckReport: {
    path: '/skillcheck-report',
    title: 'スキルチェックレポート',
    element: <Pages.SkillCheckReportPage />,
  },
  MyChartDashboard: {
    path: '/my-chart-dashboard',
    title: 'MY カルテ',
    element: <Pages.MyChartReportPage />,
  },
  Manual: {
    path: '/manual',
    title: 'マニュアル',
    exact: true,
    element: <Pages.ManualPage />,
  },
  ManualFolderPath: {
    path: '/manual/:id',
    title: 'マニュアル',
    element: <Pages.ManualPage />,
  },
  MyManual: {
    path: '/my-manual',
    title: 'マニュアル',
    element: <Pages.ManualPage isMyManual />,
  },
  CreateManual: {
    path: '/manual/create',
    title: 'マニュアル',
    element: <Pages.CreateEditManualPage />,
  },
  EditManual: {
    path: '/manual/edit/:id',
    title: 'マニュアル',
    element: <Pages.CreateEditManualPage />,
  },
  ViewManual: {
    path: '/manual/file/:id',
    title: 'マニュアル',
    element: <Pages.ManualViewPage />,
  },
  KnowledgeTop: {
    path: '/knowledge-top',
    title: 'ナレッジ',
    element: <Pages.KnowledgeTopPage />,
  },
  KnowledgeCreatedBy: {
    path: '/knowledge-created-by',
    title: 'ナレッジ',
    element: <Pages.KnowledgeTopPage isScreen="created_by" />,
  },
  KnowledgeFavorite: {
    path: '/knowledge-favorite',
    title: 'ナレッジ',
    element: <Pages.KnowledgeTopPage isScreen="favorite" />,
  },
  KnowledgeToLoginId: {
    path: '/knowledge-to-login-id',
    title: 'ナレッジ',
    element: <Pages.KnowledgeTopPage isScreen="to_login_id" />,
  },
  KnowledgeTopRecommend: {
    path: '/knowledge-top/recommend',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.KnowledgeTopRecommendPage />,
  },
  KnowledgeTopRecommendCreatedBy: {
    path: '/knowledge-created-by/recommend',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.KnowledgeTopRecommendPage />,
  },
  KnowledgeTopRecommendFavorite: {
    path: '/knowledge-favorite/recommend',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.KnowledgeTopRecommendPage />,
  },
  KnowledgeTopRecommendToLoginId: {
    path: '/knowledge-to-login-id/recommend',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.KnowledgeTopRecommendPage />,
  },
  EditKnowledgeRecommend: {
    path: '/knowledge-top-recommend/:id',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.EditKnowledgeRecommend />,
  },
  CreateKnowledgeRecommend: {
    path: '/knowledge-top-recommend/create',
    title: 'ナレッジ [リコメンド]',
    element: <Pages.CreateKnowledgeRecommend />,
  },
  CreateKnowledgeQA: {
    path: '/knowledge-top-QA/create',
    title: 'ナレッジ [Q&A]',
    element: <Pages.CreateKnowledgeQA />,
  },
  EditKnowledgeQA: {
    path: '/knowledge-top-QA/edit/:id',
    title: 'ナレッジ [Q&A]',
    element: <Pages.EditKnowledgeQA />,
  },
  Expanding: {
    path: '/knowledge-top/expanding',
    title: 'ナレッジ [Q&A]',
    element: <Pages.ExpandingPage />,
  },
  ExpandingCreatedBy: {
    path: '/knowledge-created-by/expanding',
    title: 'ナレッジ [Q&A]',
    element: <Pages.ExpandingPage />,
  },
  ExpandingFavorite: {
    path: '/knowledge-favorite/expanding',
    title: 'ナレッジ [Q&A]',
    element: <Pages.ExpandingPage />,
  },
  ExpandingToLoginId: {
    path: '/knowledge-to-login-id/expanding',
    title: 'ナレッジ [Q&A]',
    element: <Pages.ExpandingPage />,
  },
  KnowledgeCurriculum: {
    path: '/knowledge-top/knowledge-curriculum',
    title: 'ナレッジ [カリキュラム]',
    element: <Pages.KnowledgeCurriculumPage />,
  },
  SkillCheckInstructions: {
    element: <Pages.SkillCheckInstructions />,
    title: '',
    path: '/skill-check-instructions',
  },
  NewSkillCheckSelection: {
    element: <Pages.NewSkillCheckSelection />,
    title: '',
    path: '/new-skill-check-selection',
  },
  StorageManagement: {
    path: '/storage-management',
    title: 'ストレージ管理',
    element: <Pages.StorageManagementPage />,
  },
};

export type Routes = typeof routes;

export type RouteKeys = keyof Routes;

const publicKeys: RouteKeys[] = [
  'Login',
  'Register',
  'RegisterForm',
  'RegisterTrial',
  'ResetPassword',
  'CompanyExpired',
  'RegisterPremium',
  'ResetPasswordForm',
];

export const publicRoutes = pick<Routes, RouteKeys>(routes, publicKeys);

export const privateRoutes = omit<Routes, RouteKeys>(routes, publicKeys);
