import styled from 'styled-components';

export const SectionStyled = styled.div`
  .sub-title {
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #424242;
    .icon {
      color: #bbbbbb;
      margin-right: 12px;
      font-size: 28px;
    }
  }
  .text-content {
    font-size: 13px;
    text-align: center;
    color: #424242;
    margin-top: 25px;
    margin-bottom: 12px;
  }
`;
