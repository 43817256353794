import { routes } from 'navigations/routes';
import { Roles } from 'types';

type RouterWithScreenPermissionsType = {
  [key in keyof Omit<
    Roles.ResponseType,
    | 'i_id'
    | 'company_id'
    | 'code'
    | 'name'
    | 'deletedat'
    | 'updatedat'
    | 'updatedby'
    | 'createdat'
    | 'createdby'
    | 'timer_master_permission'
    | 'bundle_master_permission'
  >]: string[];
};

export const ROUTER_WITH_SCREEN_PERMISSIONS: RouterWithScreenPermissionsType = {
  admin_dashboard_permission: [routes.Dashboard.path],
  roles_master_permission: [
    routes.RolesMaster.path,
    routes.CreateRolesMaster.path,
    routes.EditRolesMaster.path,
  ],
  departments_master_permission: [routes.AffiliationMaster.path],
  positions_master_permission: [routes.PositionMaster.path],
  users_master_permission: [
    routes.Employee.path,
    routes.CreateEmployee.path,
    routes.EditEmployee.path,
  ],
  admin_master_permission: [routes.AdministratorMaster.path],
  // timer_master_permission: [],
  curricullum_master_permission: [routes.Curriculum.path],
  question_master_permission: [routes.QuestionMasterPage.path],
  groups_master_permission: [routes.AnalysisGroup.path],
  skill_check_report_permission: [routes.SkillCheckReport.path],
  skill_check_master_permission: [routes.SkillCheck.path],
  knowledge_permission: [
    routes.KnowledgeCurriculum.path,
    routes.KnowledgeTopRecommend.path,
    routes.Expanding.path,
    routes.KnowledgeTop.path,
    routes.KnowledgeTopRecommend.path,
  ],
  skill_check_implementation_permission: [routes.SkillCheckSelection.path],
  manual_permission: [routes.Manual.path, routes.EditManual.path],
  my_chart_permission: [routes.MyChartDashboard.path],
  official_curriculum_permission: [routes.OfficialCurriculum.path],
  training_report_permission: [routes.TrainingReport.path],
  inter_users_master_permission: [
    routes.InterviewUser.path,
    routes.EditInterview.path,
    routes.CreateInterviewUser.path,
    routes.InterviewBatchSetting.path,
  ],
  payment_master_permission: [routes.PaymentMaster.path],
  // bundle_master_permission: [],
};
