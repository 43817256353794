import React from 'react';
import { Bar, Tooltip } from '@ant-design/plots';
import { ColumnsType } from 'antd/es/table';
import { Datum } from '@ant-design/charts';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import { skillCheckReportSelector } from '../../selectors';
import StyledTableChart from './styles';
import * as Types from 'types';

const TableChartByQuestionCode: React.FC = () => {
  const { reportSkillCheck, totalReportSkillCheck } = useSelector(skillCheckReportSelector);

  return (
    <StyledTableChart>
      <div className="legend">
        <div className="sort-column">
          <div className="legend-filter blue" />
          <span className="legend-name">正解</span>
        </div>
        <div className="sort-column">
          <div className="legend-filter pink" />
          <span className="legend-name">不正解</span>
        </div>
        <div className="sort-column">
          <div className="legend-filter gray" />
          <span className="legend-name">未経験</span>
        </div>
      </div>
      <div className="container">
        <Table
          rowKey="index"
          className="table"
          columns={columns}
          dataSource={reportSkillCheck.slice(1).map((item, index) => ({ ...item, index }))}
          bordered
          pagination={false}
          rowClassName={(_, index) => (index % 2 === 1 ? 'table-row-hightlight' : '')}
        />
        <div className="chart">
          <div className="header-column" />
          <Bar
            data={reportSkillCheck
              .slice(1)
              .map((item, index) => [
                {
                  index,
                  question_name: item.question_name,
                  value: Number(formatNumber(item.accuracy_rate * 100)),
                  type: '正解',
                },
                {
                  index,
                  question_name: item.question_name,
                  value: Number(formatNumber(item.wrong_rate * 100)),
                  type: '不正解',
                },
                {
                  index,
                  question_name: item.question_name,
                  value: Number(formatNumber(item.inexperienced_rate * 100)),
                  type: '未経験',
                },
              ])
              .flat()}
            isStack
            xField="value"
            yField="index"
            seriesField="type"
            padding={[20, 0, 0, 0]}
            tooltip={
              {
                showTitle: false,
                fields: ['type', 'value'],
                formatter: (datum: Datum) => ({
                  name: datum.type,
                  value: datum.value + '%',
                }),
              } as Tooltip
            }
            xAxis={{
              position: 'left',
              max: 100,
              min: 0,
              grid: {
                line: {
                  style: {
                    stroke: '#ddd',
                    lineDash: [2, 2],
                  },
                },
              },
              label: {
                formatter: (_item, _, index) =>
                  index === 5 ? '50%' : index === 10 ? '100%            ' : '',
                style: {
                  fontSize: 11,
                },
              },
              showLast: true,
              tickCount: 10,
            }}
            yAxis={{
              label: null,
            }}
            color={['#44a3d7', '#ea8d93', '#ebebeb']}
            legend={false}
            maxBarWidth={12}
            style={{
              width: '100%',
              height: 39 * totalReportSkillCheck + 19,
            }}
          />
        </div>
      </div>
    </StyledTableChart>
  );
};

const columns: ColumnsType<Types.ReportSkillCheck.SurfaceTableByUserType> = [
  {
    title: 'No.',
    align: 'center',
    width: '10%',
    render: (_, _record, index) => index + 1,
  },
  {
    key: 'question_name',
    dataIndex: 'question_name',
    title: '設問',
    width: '50%',
    ellipsis: true,
  },
  {
    key: 'average_answer_time_per_question',
    dataIndex: 'average_answer_time_per_question',
    align: 'center',
    width: '20%',
    title: (
      <span>
        平均
        <br />
        解答時間
      </span>
    ),
    render: (value) => formatNumberToMinute(value),
  },
  {
    key: 'question_score',
    dataIndex: 'question_score',
    align: 'center',
    width: '20%',
    title: (
      <span>
        設定
        <br />
        スコア
      </span>
    ),
  },
];

export default TableChartByQuestionCode;
