import { createSlice } from '@reduxjs/toolkit';
import { findIndex, groupBy } from 'lodash';
import dayjs from 'dayjs';

import { getReportSkillCheck, getReportSkillCheckComparison } from './thunk';
import * as Types from 'types';

export interface InitialState {
  reportSkillCheckAnalysis: Types.ReportSkillCheck.SkillCheckAnalysisReportTable[];
  totalReportSkillCheckAnalysis: number;
  skillCheckAnalysisComparison: {
    columnName: string[];
    data: Types.ReportSkillCheck.SurfaceTableByUserType[];
    dataChart?: Types.ReportSkillCheck.SurfaceTableByUserType[];
  };
}

const initialState: InitialState = {
  reportSkillCheckAnalysis: [],
  totalReportSkillCheckAnalysis: 0,
  skillCheckAnalysisComparison: {
    columnName: [],
    data: [],
  },
};

export const skillCheckAnalysisReportSlice = createSlice({
  name: 'skillCheckAnalysisReport-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getReportSkillCheck.fulfilled, (state, action) => {
      const dataGroupByQuestionCode = groupBy<Types.ReportSkillCheck.ResponseType>(
        action.payload.report_results,
        'question_code'
      );

      const reportSkillCheck: Types.ReportSkillCheck.SkillCheckAnalysisReportTable[] = Object.keys(
        dataGroupByQuestionCode
      ).map((dataGroupByQuestionCodeKey) => {
        const dataGroupByImplementationDate = groupBy<Types.ReportSkillCheck.ResponseType>(
          dataGroupByQuestionCode[dataGroupByQuestionCodeKey],
          (e) => e.implementation_date.split('T')[0]
        );

        let start: Types.ReportSkillCheck.AnalysisItemType = {
          accuracy_rate: 0,
          wrong_rate: 0,
          inexperienced_rate: 0,
          average_answer_time_per_question: 0,
          acquisition_score_rate: 0,
          correct_answers_num: 0,
          incorrect_answer: 0,
          inexperienced: 0,
          probs_count: 0,
          acquisition_score: 0,
          time_limit: 0,
          responses_num: 0,
          score: 0,
          answer_time: 0,
          correct_answers_score: 0,
          question_score_rate: 0,
          question_score_root: 0,
        };
        let end: Types.ReportSkillCheck.AnalysisItemType = {
          accuracy_rate: 0,
          wrong_rate: 0,
          inexperienced_rate: 0,
          average_answer_time_per_question: 0,
          acquisition_score_rate: 0,
          correct_answers_num: 0,
          incorrect_answer: 0,
          inexperienced: 0,
          probs_count: 0,
          acquisition_score: 0,
          time_limit: 0,
          responses_num: 0,
          score: 0,
          answer_time: 0,
          correct_answers_score: 0,
          question_score_rate: 0,
          question_score_root: 0,
        };
        Object.keys(dataGroupByImplementationDate).forEach((dataGroupByImplementationDateKey) => {
          const items = dataGroupByImplementationDate[dataGroupByImplementationDateKey];

          const {
            correct_answers_num,
            acquisition_score,
            score,
            probs_count,
            time_limit,
            responses_num,
            answer_time,
            correct_answers_score,
            incorrect_answer_num,
            question_score,
            question_score_rate,
            question_score_root,
          } = items.reduce(
            (prev, next) => ({
              correct_answers_num: prev.correct_answers_num + (next.correct ? 1 : 0),
              acquisition_score: prev.acquisition_score + (next.correct ? next.score : 0),
              score: prev.score + (next.score || 0),
              probs_count: prev.probs_count + (next.probs_count || 0),
              time_limit: 0,
              responses_num: prev.responses_num + (next.responses_num || 0),
              answer_time: prev.answer_time + (next.answer_time || 0),
              correct_answers_score:
                prev.correct_answers_score + (next.correct_answers_num || 0) * (next.score || 0),
              incorrect_answer_num: prev.incorrect_answer_num + (!next.correct ? 1 : 0),
              question_score: prev.question_score + (next.question_score || 0),
              question_score_rate:
                prev.question_score_rate + (next.question_score || 0) * next.correct,
              question_score_root: prev.question_score_root,
            }),
            {
              correct_answers_num: 0,
              acquisition_score: 0,
              score: 0,
              probs_count: 0,
              time_limit: 0,
              responses_num: 0,
              answer_time: 0,
              correct_answers_score: 0,
              incorrect_answer_num: 0,
              question_score: 0,
              question_score_rate: 0,
              question_score_root: items[0]?.question_score,
            }
          );

          if (
            dayjs(action.payload.startPeriod)
              .add(1, 'day')
              .diff(dayjs(dataGroupByImplementationDateKey)) > 0
          ) {
            start = {
              correct_answers_num,
              incorrect_answer: incorrect_answer_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / items.length,
              wrong_rate: incorrect_answer_num / items.length,
              inexperienced_rate:
                (items.length - (correct_answers_num + incorrect_answer_num)) / items.length,
              time_limit,
              answer_time,
              average_answer_time_per_question: answer_time / items.length,
              score,
              acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / score,
              correct_answers_score,
              question_score,
              question_score_rate,
              question_score_root,
            };
          } else {
            end = {
              correct_answers_num,
              incorrect_answer: incorrect_answer_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / items.length,
              wrong_rate: incorrect_answer_num / items.length,
              inexperienced_rate:
                (items.length - (correct_answers_num + incorrect_answer_num)) / items.length,
              time_limit,
              answer_time,
              average_answer_time_per_question: answer_time / items.length,
              score,
              acquisition_score,
              responses_num,
              acquisition_score_rate: acquisition_score / score,
              correct_answers_score,
              question_score,
              question_score_rate,
              question_score_root,
            };
          }
        });

        return {
          i_id: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].i_id,
          user_name: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].user_name,
          login_id: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].login_id,
          skill_check_name: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].skill_check_name,
          skill_check_code: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].skill_check_code,
          question_code: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].question_code,
          question_name: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].question_name,
          question_id:
            dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].item_ref?.question_name?.i_id,
          start,
          end,
          change: {
            accuracy_rate: end.accuracy_rate - start.accuracy_rate,
            wrong_rate: end.wrong_rate - start.wrong_rate,
            inexperienced_rate: end.inexperienced_rate - start.inexperienced_rate,
            average_answer_time_per_question:
              end.average_answer_time_per_question - start.average_answer_time_per_question,
            acquisition_score_rate: end.acquisition_score_rate - start.acquisition_score_rate,
            correct_answers_num: end.correct_answers_num - start.correct_answers_num,
            incorrect_answer: end.incorrect_answer - start.incorrect_answer,
            inexperienced: end.inexperienced - start.inexperienced,
            probs_count: end.probs_count - start.probs_count,
            acquisition_score: end.acquisition_score - start.acquisition_score,
            time_limit: end.time_limit - start.time_limit,
            responses_num: end.responses_num - start.responses_num,
            score: end.score - start.score,
            answer_time: end.answer_time - start.answer_time,
            correct_answers_score: end.correct_answers_score - start.correct_answers_score,
          },
          implementation_date:
            dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].implementation_date,
        };
      });

      const dataLength = reportSkillCheck.length;

      const { start, end } = reportSkillCheck.reduce(
        (prev, next) => ({
          start: {
            accuracy_rate: prev.start.accuracy_rate + next.start.accuracy_rate,
            wrong_rate: prev.start.wrong_rate + next.start.wrong_rate,
            inexperienced_rate: prev.start.inexperienced_rate + next.start.inexperienced_rate,
            average_answer_time_per_question:
              prev.start.average_answer_time_per_question +
              next.start.average_answer_time_per_question,
            acquisition_score_rate:
              prev.start.acquisition_score_rate + next.start.acquisition_score_rate,
            correct_answers_num: prev.start.correct_answers_num + next.start.correct_answers_num,
            incorrect_answer: prev.start.incorrect_answer + next.start.incorrect_answer,
            inexperienced: prev.start.inexperienced + next.start.inexperienced,
            probs_count: prev.start.probs_count + next.start.probs_count,
            acquisition_score: prev.start.acquisition_score + next.start.acquisition_score,
            time_limit: prev.start.time_limit + next.start.time_limit,
            responses_num: prev.start.responses_num + next.start.responses_num,
            score: prev.start.score + next.start.score,
            answer_time: prev.start.answer_time + next.start.answer_time,
            correct_answers_score:
              prev.start.correct_answers_score + next.start.score * next.start.correct_answers_num,
            question_score: prev.start.question_score + (next.start.question_score || 0),
            question_score_rate:
              prev.start.question_score_rate + (next.start.question_score_rate || 0),
            question_score_root: prev.start.question_score_root,
          },
          end: {
            accuracy_rate: prev.end.accuracy_rate + next.end.accuracy_rate,
            wrong_rate: prev.end.wrong_rate + next.end.wrong_rate,
            inexperienced_rate: prev.end.inexperienced_rate + next.end.inexperienced_rate,
            average_answer_time_per_question:
              prev.end.average_answer_time_per_question + next.end.average_answer_time_per_question,
            acquisition_score_rate:
              prev.end.acquisition_score_rate + next.end.acquisition_score_rate,
            correct_answers_num: prev.end.correct_answers_num + next.end.correct_answers_num,
            incorrect_answer: prev.end.incorrect_answer + next.end.incorrect_answer,
            inexperienced: prev.end.inexperienced + next.end.inexperienced,
            probs_count: prev.end.probs_count + next.end.probs_count,
            acquisition_score: prev.end.acquisition_score + next.end.acquisition_score,
            time_limit: prev.end.time_limit + next.end.time_limit,
            responses_num: prev.end.responses_num + next.end.responses_num,
            score: prev.end.score + next.end.score,
            answer_time: prev.end.answer_time + next.end.answer_time,
            correct_answers_score:
              prev.end.correct_answers_score + next.end.score * next.end.correct_answers_num,
            question_score: prev.end.question_score + (next.end.question_score || 0),
            question_score_rate: prev.end.question_score_rate + (next.end.question_score_rate || 0),
            question_score_root: prev.end.question_score_root,
          },
        }),
        {
          start: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            time_limit: 0,
            responses_num: 0,
            score: 0,
            answer_time: 0,
            correct_answers_score: 0,
            question_score: 0,
            question_score_rate: 0,
            question_score_root: reportSkillCheck[0]?.question_score_root,
          },
          end: {
            accuracy_rate: 0,
            wrong_rate: 0,
            inexperienced_rate: 0,
            average_answer_time_per_question: 0,
            acquisition_score_rate: 0,
            correct_answers_num: 0,
            incorrect_answer: 0,
            inexperienced: 0,
            probs_count: 0,
            acquisition_score: 0,
            time_limit: 0,
            responses_num: 0,
            score: 0,
            answer_time: 0,
            correct_answers_score: 0,
            question_score: 0,
            question_score_rate: 0,
            question_score_root: reportSkillCheck[0]?.question_score_root,
          },
        }
      );

      state.reportSkillCheckAnalysis = [
        {
          i_id: '',
          user_name: '',
          skill_check_name: '',
          login_id: '',
          skill_check_code: '',
          question_code: '',
          question_name: '',
          start: {
            accuracy_rate: start.accuracy_rate / dataLength,
            wrong_rate: start.wrong_rate / dataLength,
            inexperienced_rate: start.inexperienced_rate / dataLength,
            average_answer_time_per_question: start.average_answer_time_per_question / dataLength,
            acquisition_score_rate: start.acquisition_score_rate / dataLength,
            acquisition_score: start.acquisition_score / dataLength,
            time_limit: start.time_limit / dataLength,
            responses_num: start.responses_num / dataLength,
            score: start.score / dataLength,
            answer_time: start.answer_time / dataLength,
            correct_answers_num: start.correct_answers_num,
            incorrect_answer: start.incorrect_answer,
            inexperienced: start.inexperienced,
            probs_count: start.probs_count / dataLength,
            correct_answers_score: start.correct_answers_score / dataLength,
            question_score: start.question_score,
            question_score_root: start.question_score_root,
          },
          end: {
            accuracy_rate: end.accuracy_rate / dataLength,
            wrong_rate: end.wrong_rate / dataLength,
            inexperienced_rate: end.inexperienced_rate / dataLength,
            average_answer_time_per_question: end.average_answer_time_per_question / dataLength,
            acquisition_score_rate: end.acquisition_score_rate / dataLength,
            acquisition_score: end.acquisition_score / dataLength,
            time_limit: end.time_limit / dataLength,
            responses_num: end.responses_num / dataLength,
            score: end.score / dataLength,
            answer_time: end.answer_time / dataLength,
            correct_answers_num: end.correct_answers_num,
            incorrect_answer: end.incorrect_answer,
            inexperienced: end.inexperienced,
            probs_count: end.probs_count / dataLength,
            correct_answers_score: end.correct_answers_score / dataLength,
            question_score: end.question_score,
            question_score_root: end.question_score_root,
          },
          change: {
            accuracy_rate: (end.accuracy_rate - start.accuracy_rate) / dataLength,
            wrong_rate: (end.wrong_rate - start.wrong_rate) / dataLength,
            inexperienced_rate: (end.inexperienced_rate - start.inexperienced_rate) / dataLength,
            average_answer_time_per_question:
              (end.average_answer_time_per_question - start.average_answer_time_per_question) /
              dataLength,
            acquisition_score_rate:
              (end.acquisition_score_rate - start.acquisition_score_rate) / dataLength,
            correct_answers_num: (end.correct_answers_num - start.correct_answers_num) / dataLength,
            incorrect_answer: (end.incorrect_answer - start.incorrect_answer) / dataLength,
            inexperienced: (end.inexperienced - start.inexperienced) / dataLength,
            probs_count: (end.probs_count - start.probs_count) / dataLength,
            acquisition_score: (end.acquisition_score - start.acquisition_score) / dataLength,
            time_limit: (end.time_limit - start.time_limit) / dataLength,
            responses_num: (end.responses_num - start.responses_num) / dataLength,
            score: (end.score - start.score) / dataLength,
            answer_time: (end.answer_time - start.answer_time) / dataLength,
            correct_answers_score:
              (end.correct_answers_score - start.correct_answers_score) / dataLength,
          },
        },
        ...reportSkillCheck,
      ];
      state.totalReportSkillCheckAnalysis = dataLength;
    });

    builder.addCase(getReportSkillCheckComparison.fulfilled, (state, action) => {
      const dataGroupByQuestionCode = groupBy<Types.ReportSkillCheck.ResponseType>(
        action.payload.report_results,
        'question_code'
      );
      const dataGroupByDate = groupBy(
        action.payload.report_results,
        (e) => e.implementation_date.split('T')[0]
      );

      const data = Object.keys(dataGroupByQuestionCode).flatMap((dataGroupByQuestionCodeKey) => {
        const dataGroupByImplementationDate = groupBy<Types.ReportSkillCheck.ResponseType>(
          dataGroupByQuestionCode[dataGroupByQuestionCodeKey],
          (e) => e.implementation_date.split('T')[0]
        );

        return Object.keys(dataGroupByImplementationDate).map(
          (dataGroupByImplementationDateKey) => {
            const items = dataGroupByImplementationDate[dataGroupByImplementationDateKey];

            const {
              correct_answers_num,
              acquisition_score,
              score,
              probs_count,
              time_limit,
              responses_num,
              answer_time,
              correct_answers_score,
              incorrect_answer_num,
            } = items.reduce(
              (prev, next) => ({
                correct_answers_num: prev.correct_answers_num + (next.correct ? 1 : 0),
                correct_answers_score:
                  prev.correct_answers_score + (next.correct_answers_num || 0) * (next.score || 0),
                acquisition_score: prev.correct + next.correct * (next.question_score || 0),
                score: prev.score + (next.score || 0),
                probs_count: prev.probs_count + (next.probs_count || 0),
                time_limit: 0,
                responses_num: prev.responses_num + (next.responses_num || 0),
                answer_time: prev.answer_time + (next.answer_time || 0),
                incorrect_answer_num: prev.incorrect_answer_num + (!next.correct ? 1 : 0),
                correct: prev.correct + next.correct,
                question_score: prev.question_score + (next.question_score || 0),
              }),
              {
                correct_answers_num: 0,
                acquisition_score: 0,
                score: 0,
                probs_count: 0,
                time_limit: 0,
                responses_num: 0,
                answer_time: 0,
                correct_answers_score: 0,
                incorrect_answer_num: 0,
                correct: 0,
                question_score: 0,
              }
            );

            return {
              i_id: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].i_id,
              user_name: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].user_name,
              login_id: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].login_id,
              skill_check_name:
                dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].skill_check_name,
              skill_check_code: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].login_id,
              question_code: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].question_code,
              question_name: dataGroupByQuestionCode[dataGroupByQuestionCodeKey][0].question_name,
              correct_answers_num,
              incorrect_answer: responses_num - correct_answers_num,
              inexperienced: probs_count - responses_num,
              probs_count,
              accuracy_rate: correct_answers_num / items.length,
              wrong_rate: incorrect_answer_num / items.length,
              inexperienced_rate:
                (items.length - (incorrect_answer_num + correct_answers_num)) / items.length,
              time_limit,
              answer_time,
              average_answer_time_per_question: answer_time / items.length,
              score,
              acquisition_score,
              responses_num,
              acquisition_score_rate: correct_answers_num / items.length,
              correct_answers_score,
              implementation_date: items[0]?.implementation_date,
            };
          }
        );
      });

      const newData: Types.ReportSkillCheck.SurfaceTableByUserType[] = [];
      data.forEach((e) => {
        const index = findIndex(newData, (val) => val.question_code === e.question_code);
        if (index >= 0) {
          newData[index] = {
            ...newData[index],
            date: {
              ...newData[index].date,
              [e.implementation_date]: {
                accuracy_rate: e.accuracy_rate,
                correct_answers_num: e.correct_answers_num,
                incorrect_answer: e.incorrect_answer,
                inexperienced: e.inexperienced,
                probs_count: e.probs_count,
                acquisition_score: e.acquisition_score,
                responses_num: e.responses_num,
                score: e.score,
                answer_time: e.answer_time,
                correct_answers_score: e.correct_answers_score,
                question_score: e.score,
                inexperienced_rate: e.inexperienced_rate,
                average_answer_time_per_question: e.average_answer_time_per_question,
                acquisition_score_rate: e.acquisition_score_rate,
                wrong_rate: e.wrong_rate,
                time_limit: e.time_limit,
              },
            },
          };
        } else {
          newData.push({
            ...e,
            date: {
              [e.implementation_date]: {
                accuracy_rate: e.accuracy_rate,
                correct_answers_num: e.correct_answers_num,
                incorrect_answer: e.incorrect_answer,
                inexperienced: e.inexperienced,
                probs_count: e.probs_count,
                acquisition_score: e.acquisition_score,
                responses_num: e.responses_num,
                score: e.score,
                answer_time: e.answer_time,
                correct_answers_score: e.correct_answers_score,
                question_score: e.score,
                inexperienced_rate: e.inexperienced_rate,
                average_answer_time_per_question: e.average_answer_time_per_question,
                acquisition_score_rate: e.acquisition_score_rate,
                wrong_rate: e.wrong_rate,
                time_limit: e.time_limit,
              },
            },
          });
        }
      });

      state.skillCheckAnalysisComparison.data = newData;
      state.skillCheckAnalysisComparison.dataChart = data;
      state.skillCheckAnalysisComparison.columnName = Object.keys(dataGroupByDate).map((date) =>
        dayjs(date).format('YYYY/MM/DD')
      );
    });
  },
});

export const { resetInitialState } = skillCheckAnalysisReportSlice.actions;

export default skillCheckAnalysisReportSlice.reducer;
