import React, {
  useState,
  ReactNode,
  useEffect,
  CSSProperties,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Pie, measureTextWidth, Column, Bar, Tooltip } from '@ant-design/plots';
import { Col, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Datum } from '@ant-design/charts';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import {
  CloudDownloadOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  RightOutlined,
  ReadOutlined,
  EditFilled,
} from '@ant-design/icons';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { RecordSkillCheck, RecordTraining } from './Table';
import { authSelector } from 'containers/Auth/selectors';
import { myChartDashboardSelector } from './selectors';
import getWidthContainer from 'libs/utils/pieChart';
import { formatNumber } from 'libs/utils/format';
import { Header, DatePicker } from 'components';
import exportPDF from 'libs/utils/exportPdf';
import FilePDFExport from './FilePDFExport';
import { useAppDispatch } from 'hooks';
import { ModalExport } from './Modal';
import Wrapper from './styles';
import * as Types from 'types';
import {
  getDataReportCurriculumUser,
  getDataSelectAnalysisGroup,
  getDataSelectCurriculum,
  getDataSelectSkillCheck,
  getDataChartResult,
  getDataSkillCheckUserMyChart,
} from './thunk';

const { RangePicker } = DatePicker;
const { Option } = Select;

const MyChart: React.FC = () => {
  const [endPeriod, setEndPeriod] = useState<string>(dayjs().subtract(7, 'day').toISOString());
  const [valueRequiredCurriculum, setValueRequiredCurriculum] = useState<string>('ALL');
  const [startPeriod, setStartPeriod] = useState<string>(dayjs().toISOString());
  const [isShowPopupDelete, setShowPopupDelete] = useState<boolean>(false);
  const [valueSkillCheckCode, setValueSkillCheckCode] = useState<string>();
  const [valueCurriculum, setValueCurriculum] = useState<string>('ALL');
  const [valueGroupingCode, setValueGroupingCode] = useState<string>();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const { headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    dataChartReportCurriculumUser,
    dataReportCurriculumUser,
    dataSelectAnalysisGroup,
    curriculumNameSelect,
    dataSelectSkillCheck,
    dataSkillCheckUser,
    dataChartResult,
    dataCircleChart,
    dataAllUser,
    dataBefore,
    dataAfter,
    dataUser,
  } = useSelector(myChartDashboardSelector);

  const newDataSkillCheckUser = dataSkillCheckUser.filter(
    (item) => item.login_id === userInfo?.login_id
  );

  const differenceRate = formatNumber(
    Number(dataBefore?.accuracy_rate) - Number(dataAfter?.accuracy_rate)
  );

  const {
    differenceCorrectAnswerNum,
    differenceAnswerNum,
    differenceQuestionExtant,
    differenceAnswerTime,
    differenceInexperienced,
  } = useMemo(
    () => ({
      differenceCorrectAnswerNum: formatNumber(
        Number(dataUser?.correct_answers_num) - Number(dataAllUser?.correct_answers_num)
      ),
      differenceAnswerNum: formatNumber(
        Number(dataUser?.acquisition_score) - Number(dataAllUser?.acquisition_score)
      ),
      differenceQuestionExtant: formatNumber(
        Number(dataUser?.incorrect_answer) - Number(dataAllUser?.incorrect_answer)
      ),
      differenceAnswerTime: formatNumber(
        Number(dataUser?.answer_time) - Number(dataAllUser?.answer_time)
      ),
      differenceInexperienced: formatNumber(
        Number(dataUser?.inexperienced) - Number(dataAllUser?.inexperienced)
      ),
    }),
    [dataAllUser, dataUser]
  );

  const dataColumnChart = useMemo(() => {
    return (
      <div>
        <Column
          data={dataChartResult}
          isStack
          xField="year"
          yField="value"
          annotations={
            !dataChartResult.length
              ? [
                  {
                    type: 'text',
                    content: 'データがありません',
                    position: ['50%', '50%'],
                    style: {
                      display: 'none',
                      textAlign: 'center',
                      fill: 'rgba(0,0,0,0.85)',
                      fontSize: 20,
                    },
                  },
                ]
              : []
          }
          seriesField="type"
          maxColumnWidth={40}
          label={{
            position: 'middle',
            content: (item) => {
              if (item.value !== 0) {
                return `${formatNumber(item.value)}%`;
              } else {
                return '';
              }
            },
            style: {
              fontSize: 12,
              fill: 'white',
            },
          }}
          legend={false}
          color={['#41a1d6', '#ea959b', '#C4C4C4']}
          xAxis={{
            label: {
              style: {
                color: '#424242',
                fontSize: 12,
              },
            },
            tickLine: null,
          }}
          yAxis={{
            position: 'left',
            line: {
              style: {
                stroke: '#ddd',
              },
            },
            label: {
              style: {
                color: '#999999',
                fontSize: 12,
              },
              formatter: (item) => `${item}%`,
            },
            grid: {
              line: {
                style: {
                  stroke: '#ddd',
                  lineDash: [2, 2],
                },
              },
            },
            tickInterval: 10,
            showLast: true,
          }}
          tooltip={
            {
              showTitle: false,
              fields: ['type', 'value'],
              formatter: (datum: Datum) => ({
                name: datum.type,
                value: formatNumber(datum.value) + '%',
              }),
            } as Tooltip
          }
        />
      </div>
    );
  }, [dataChartResult]);

  const dataTableChart = useMemo(() => {
    return (
      <div className="chart">
        <div className="header-column" />
        <Bar
          data={dataChartReportCurriculumUser}
          isStack
          isGroup
          xField="value"
          yField="name"
          groupField="type_group"
          seriesField="type"
          autoFit
          padding={[20, 0, 0, 0]}
          color={['#6EC9AB', '#DDEACD', '#E4AA38']}
          xAxis={{
            position: 'left',
            max: 100,
            min: 0,
            grid: {
              line: {
                style: {
                  stroke: '#ddd',
                  lineDash: [2, 2],
                },
              },
            },
            label: {
              offsetX: 10,
              formatter: (item, _, index) =>
                index === 10 ? `${item}%    ` : index === 5 ? `${item}%` : '',
              style: {
                fontSize: 11,
                textAlign: 'right',
              },
            },
            showLast: true,
            tickCount: 10,
          }}
          yAxis={{
            label: null,
          }}
          legend={false}
          maxBarWidth={12}
          style={{
            width: '100%',
            height: 39 * dataReportCurriculumUser.length + 19,
          }}
          tooltip={
            {
              showTitle: false,
              fields: ['type', 'value'],
              formatter: (datum: Datum) => ({
                name: datum.type,
                value: formatNumber(datum.value) + '%',
              }),
            } as Tooltip
          }
        />
      </div>
    );
  }, [dataChartReportCurriculumUser, dataReportCurriculumUser.length]);

  const handleChangeSkillCheck = useCallback(
    async (value: string) => {
      setValueSkillCheckCode(value);
      if (userInfo) {
        dispatch(startLoading());
        await dispatch(
          getDataSkillCheckUserMyChart({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'skill_check_code',
                search_value: [value],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      }
    },
    [dispatch, userInfo]
  );

  const handleChangeCurriculum = (value: string) => {
    setValueCurriculum(value);
  };

  const handleChangeRequireCurriculum = (value: string) => {
    setValueRequiredCurriculum(value);
    if (curriculumNameSelect.find((item) => item.code !== valueCurriculum)) {
      setValueCurriculum('ALL');
    }
  };

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          differenceCorrectAnswerNum={differenceCorrectAnswerNum}
          differenceQuestionExtant={differenceQuestionExtant}
          differenceInexperienced={differenceInexperienced}
          valueRequiredCurriculum={valueRequiredCurriculum}
          differenceAnswerTime={differenceAnswerTime}
          valueSkillCheckCode={valueSkillCheckCode}
          differenceAnswerNum={differenceAnswerNum}
          valueGroupingCode={valueGroupingCode}
          valueCurriculum={valueCurriculum}
          differenceRate={differenceRate}
          startPeriod={startPeriod}
          endPeriod={endPeriod}
        />
      </div>
    );
  }, [
    differenceCorrectAnswerNum,
    differenceQuestionExtant,
    differenceInexperienced,
    valueRequiredCurriculum,
    differenceAnswerTime,
    valueSkillCheckCode,
    differenceAnswerNum,
    valueGroupingCode,
    valueCurriculum,
    differenceRate,
    startPeriod,
    endPeriod,
  ]);

  const handleExport = () => {
    if (!ref.current) return;
    dispatch(startLoading());

    exportPDF(ref, 'MYカルテ.pdf');

    dispatch(stopLoading());
    setShowPopupDelete(false);
  };

  useEffect(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataReportCurriculumUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              ...(valueRequiredCurriculum && valueRequiredCurriculum !== 'ALL'
                ? [
                    {
                      id: 'required_curriculum',
                      search_value: [valueRequiredCurriculum],
                      exact_match: true,
                    },
                  ]
                : []),
              ...(valueCurriculum && valueCurriculum !== 'ALL'
                ? [
                    {
                      id: 'curriculum_code',
                      search_value: [valueCurriculum],
                    },
                  ]
                : []),
            ],
            page: 1,
            per_page: 0,
            sort_fields: [{ id: 'implementation_date', order: 'desc' }],
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, valueCurriculum, valueRequiredCurriculum]);

  useEffect(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSelectSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [userInfo.login_id],
                },
              ],
              page: 1,
              per_page: 0,
              sort_fields: [
                {
                  id: 'code',
                  order: 'asc',
                },
              ],
            })
          ),
          dispatch(
            getDataSelectAnalysisGroup({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
                {
                  id: 'login_id',
                  search_value: [userInfo.login_id],
                },
              ],
              sort_fields: [
                {
                  id: 'group_name',
                  order: 'asc',
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getDataSelectCurriculum({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(valueRequiredCurriculum && valueRequiredCurriculum !== 'ALL'
              ? [
                  {
                    id: 'required_curriculum',
                    search_value: [valueRequiredCurriculum],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
    }
  }, [dispatch, userInfo, valueRequiredCurriculum]);

  useEffect(() => {
    if (userInfo) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataSkillCheckUserMyChart({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'skill_check_code',
                search_value: [dataSelectSkillCheck[0]?.code],
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dataSelectSkillCheck, dispatch]);

  useEffect(() => {
    if (userInfo && valueGroupingCode) {
      (async () => {
        dispatch(startLoading());
        await dispatch(
          getDataChartResult({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'grouping_code',
                search_value: [valueGroupingCode],
                exact_match: true,
              },
              {
                id: 'implementation_date',
                search_value: [startPeriod, null],
              },
              {
                id: 'implementation_date',
                search_value: [null, endPeriod],
              },
            ],
            sort_fields: [
              {
                id: 'implementation_date',
                order: 'asc',
              },
            ],
            page: 1,
            per_page: 0,
          })
        );
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, endPeriod, startPeriod, userInfo, valueGroupingCode]);

  useEffect(() => {
    dataSelectAnalysisGroup.length && setValueGroupingCode(dataSelectAnalysisGroup[0].group_code);
  }, [dataSelectAnalysisGroup]);

  const noData = useMemo(() => {
    const checkData =
      dataUser?.incorrect_answer === 0 &&
      dataAllUser?.correct_answers_num === 0 &&
      dataUser?.answer_time === 0 &&
      dataUser?.acquisition_score === 0 &&
      dataUser?.accuracy_rate === 0 &&
      dataUser?.question_time_limit === 0 &&
      dataAllUser?.inexperienced === 0 &&
      dataUser?.probs_count === 0 &&
      dataUser?.responses_num === 0;
    if (checkData) {
      return undefined;
    }
    return dataUser;
  }, [dataUser, dataAllUser]);

  return (
    <Wrapper>
      <Header title={headerTitle} />
      {tabIndex === 1 ? (
        <RecordSkillCheck setTabIndex={setTabIndex} />
      ) : tabIndex === 2 ? (
        <RecordTraining setTabIndex={setTabIndex} />
      ) : (
        <>
          {component}
          <div className="container">
            <div className="dashboard-title">
              <p className="text">ダッシュボード</p>
              <button className="btn btn-active" onClick={() => setShowPopupDelete(true)}>
                <CloudDownloadOutlined className="size-icon" />
                エクスポート
              </button>
            </div>
            <div className="circle-chart">
              <div className="title-chart">
                <EditFilled className="icon" />
                <p className="title">スキルチェック実施結果</p>
              </div>
              <div className="item-select">
                <div className="select">
                  <p className="label">スキルチェック選択</p>
                  <Select
                    value={valueSkillCheckCode || dataSelectSkillCheck[0]?.code}
                    onChange={handleChangeSkillCheck}
                    style={{ width: 700 }}
                    allowClear
                  >
                    {dataSelectSkillCheck?.map((item, index) => (
                      <Option value={item.code} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <button className="btn-result" onClick={() => setTabIndex(1)}>
                  実施結果詳細 <RightOutlined className="icon" />
                </button>
              </div>
              <div className="chart-item">
                {noData ? (
                  <Row gutter={4} className="wrap-chart">
                    <Col span={5}>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">正解数</p>
                          <div
                            className={`label-number ${
                              Number(differenceCorrectAnswerNum) < 0 ? 'down' : 'up'
                            }`}
                          >
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceCorrectAnswerNum) > 0 ? <span>+</span> : null}
                                {differenceCorrectAnswerNum}
                              </p>
                            </div>
                            {Number(differenceCorrectAnswerNum) < 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataUser?.correct_answers_num)}
                              <small>
                                <small>/ {formatNumber(dataUser?.probs_count)}</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">ユーザー平均：</p>
                            <p className="number">
                              {formatNumber(dataAllUser?.correct_answers_num)}
                            </p>
                          </div>
                        </div>
                      </Row>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">獲得スコア</p>
                          <div
                            className={`label-number ${
                              Number(differenceAnswerNum) < 0 ? 'down' : 'up'
                            }`}
                          >
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceAnswerNum) > 0 ? <span>+</span> : null}
                                {differenceAnswerNum}
                              </p>
                            </div>
                            {Number(differenceCorrectAnswerNum) < 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataUser?.acquisition_score)}
                              <small>
                                <small>/ {formatNumber(dataUser?.setting_score)}</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">ユーザー平均：</p>
                            <p className="number">{formatNumber(dataAllUser?.acquisition_score)}</p>
                          </div>
                        </div>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">不正解数</p>
                          <div
                            className={`label-number ${
                              Number(differenceQuestionExtant) < 0 ? 'up' : 'down'
                            }`}
                          >
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceQuestionExtant) > 0 ? <span>+</span> : null}
                                {differenceQuestionExtant}
                              </p>
                            </div>
                            {Number(differenceQuestionExtant) > 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataUser?.incorrect_answer)}
                              <small>
                                <small>/ {formatNumber(dataUser?.probs_count)}</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">ユーザー平均：</p>
                            <p className="number">{formatNumber(dataAllUser?.incorrect_answer)}</p>
                          </div>
                        </div>
                      </Row>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">回答時間（分）</p>
                          <div className="label-number reduce">
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceAnswerTime) > 0 ? <span>+</span> : null}
                                {differenceAnswerTime}
                              </p>
                            </div>
                            {Number(differenceAnswerTime) > 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataUser?.answer_time)}
                              <small>
                                <small>/ {formatNumber(dataUser?.question_time_limit)}</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">ユーザー平均：</p>
                            <p className="number">{formatNumber(dataAllUser?.answer_time)}</p>
                          </div>
                        </div>
                      </Row>
                    </Col>
                    <Col span={5}>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">未経験数</p>
                          <div
                            className={`label-number ${
                              Number(differenceInexperienced) > 0 ? 'down' : 'up'
                            }`}
                          >
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceInexperienced) > 0 ? <span>+</span> : null}
                                {differenceInexperienced}
                              </p>
                            </div>
                            {Number(differenceInexperienced) > 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataUser?.inexperienced)}
                              <small>
                                <small>/ {formatNumber(dataUser?.probs_count)}</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">ユーザー平均：</p>
                            <p className="number">{formatNumber(dataAllUser?.inexperienced)}</p>
                          </div>
                        </div>
                      </Row>
                      <Row className="item-count">
                        <div className="wrap-item">
                          <p className="label-text">対前回正解率（同分析グループ）</p>
                          <div
                            className={`label-number ${Number(differenceRate) > 0 ? 'down' : 'up'}`}
                          >
                            <div className="number-change">
                              <p className="label">
                                {Number(differenceRate) > 0 ? <span>+</span> : null}
                                {differenceRate}
                              </p>
                            </div>
                            {Number(differenceRate) > 0 ? (
                              <ArrowDownOutlined className="icon" />
                            ) : (
                              <ArrowUpOutlined className="icon" />
                            )}
                            <p className="number-ratio">
                              {formatNumber(dataBefore?.accuracy_rate)}
                              <small>
                                <small>%</small>
                              </small>
                            </p>
                          </div>
                          <div className="number-result">
                            <p className="label">前回正解率: </p>
                            <p className="number">{formatNumber(dataAfter?.accuracy_rate)}</p>
                          </div>
                        </div>
                      </Row>
                    </Col>
                    <Col span={8} className="chart">
                      <div className="pie">
                        <Pie
                          appendPadding={10}
                          data={dataCircleChart}
                          angleField="value"
                          colorField="type"
                          radius={1}
                          innerRadius={0.55}
                          meta={{
                            value: {
                              formatter: (v: string) => `${v} %`,
                            },
                          }}
                          label={{
                            type: 'inner',
                            offset: '-50%',
                            style: {
                              textAlign: 'center',
                            },
                            autoRotate: false,
                            content: (item) => {
                              if (item.value !== 0) {
                                return `${formatNumber(item.value)}%`;
                              } else {
                                return '';
                              }
                            },
                          }}
                          statistic={{
                            title: {
                              offsetY: -4,
                              style: {
                                fontSize: '11px',
                                textShadow: 'none',
                                fontWeight: 400,
                                color: '#77777',
                              },
                              customHtml: (container, _, datum) => {
                                const text = datum ? datum.type : '獲得スコア率';
                                return renderStatistic(getWidthContainer(container, true), text, {
                                  fontSize: 24,
                                  color: '#424242',
                                });
                              },
                            },
                            content: {
                              offsetY: 4,
                              style: {
                                fontSize: '24px',
                                fontWeight: 400,
                                color: '#2a2a2a',
                              },
                              customHtml: (container) => {
                                return renderStatistic(
                                  getWidthContainer(container, false),
                                  Number(newDataSkillCheckUser[0]?.probs_count) || 0,
                                  {
                                    color: '#777777',
                                  }
                                );
                              },
                            },
                          }}
                          color={['#41a1d6', '#ea959b', '#C4C4C4']}
                          height={200}
                          legend={false}
                          animation={false}
                        />
                      </div>
                      <div className="color-chart">
                        <div className="item-color">
                          <div className="color correct-answer" />
                          <p className="name">正解</p>
                        </div>
                        <div className="item-color">
                          <div className="color incorrect-answer" />
                          <p className="name">不正解</p>
                        </div>
                        <div className="item-color">
                          <div className="color inexperienced" />
                          <p className="name">未経験</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <p className="no-data">データがありません</p>
                )}
              </div>
            </div>
            <div className="column-chart">
              <div className="title-chart">
                <EditFilled className="icon" />
                <p className="title">スキルチェック実施結果_推移比較</p>
              </div>
              <div className="item-chart">
                <div className="item-select">
                  <div className="select">
                    <p className="label">分析グループ選択</p>
                    <Select
                      value={valueGroupingCode}
                      onChange={setValueGroupingCode}
                      style={{ width: 360 }}
                      allowClear
                    >
                      {dataSelectAnalysisGroup?.map((item, index) => (
                        <Option value={item.group_code} key={index}>
                          {item.grouping_code}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="select">
                    <p className="label">期間</p>
                    <RangePicker
                      allowClear
                      className="date-input"
                      format="YYYY/MM/DD"
                      defaultValue={[dayjs(startPeriod), dayjs(endPeriod)]}
                      onChange={(dates) => {
                        if (dates) {
                          setStartPeriod(dates[0]?.toISOString() || '');
                          setEndPeriod(dates[1]?.toISOString() || '');
                        } else {
                          setStartPeriod('');
                          setEndPeriod('');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="chart">
                  <div className="color-chart">
                    <div className="item-color">
                      <div className="color correct-answer" />
                      <p className="name">正解</p>
                    </div>
                    <div className="item-color">
                      <div className="color incorrect-answer" />
                      <p className="name">不正解</p>
                    </div>
                    <div className="item-color">
                      <div className="color inexperienced" />
                      <p className="name">未経験</p>
                    </div>
                  </div>
                  {dataColumnChart}
                </div>
              </div>
            </div>
            <div className="training-progress-chart">
              <div className="title-chart">
                <ReadOutlined className="icon" />
                <p className="title">トレーニング進捗</p>
              </div>
              <div className="item-chart">
                <div className="item-select">
                  <div className="group-select">
                    <div className="select">
                      <p className="label">カリキュラム選択</p>
                      <Select
                        style={{ width: 360 }}
                        allowClear
                        value={valueCurriculum}
                        onChange={handleChangeCurriculum}
                      >
                        <Option value="ALL">ALL</Option>
                        {curriculumNameSelect?.map((item, index) => (
                          <Option value={item.code} key={index}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div className="select">
                      <p className="label">カリキュラム種類</p>
                      <Select
                        onChange={handleChangeRequireCurriculum}
                        value={valueRequiredCurriculum}
                        className="item-select"
                        allowClear
                        style={{ width: 200 }}
                      >
                        <Option value="ALL">ALL</Option>
                        <Option value="0">カリキュラム</Option>
                        <Option value="1">必修カリキュラム</Option>
                      </Select>
                    </div>
                  </div>
                  <button className="btn-result" onClick={() => setTabIndex(2)}>
                    実施結果詳細 <RightOutlined className="icon" />
                  </button>
                </div>
                <div className="color-chart">
                  <div className="item-color">
                    <div className="color correct-answer" />
                    <p className="name">進捗率</p>
                  </div>
                  <div className="item-color">
                    <div className="color incorrect-answer" />
                    <p className="name">正解進捗率</p>
                  </div>
                  <div className="item-color">
                    <div className="color inexperienced" />
                    <p className="name">ユーザー平均正解進捗率</p>
                  </div>
                </div>
                <div className="wrap-group-chart">
                  <Table
                    className="table"
                    rowKey="index"
                    size="small"
                    rowClassName={(_, index) =>
                      index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                    }
                    columns={columns}
                    dataSource={dataReportCurriculumUser.map((item, index) => ({ ...item, index }))}
                    bordered
                    pagination={false}
                  />
                  {dataTableChart}
                </div>
              </div>
            </div>
          </div>
          <ModalExport
            visible={isShowPopupDelete}
            setVisible={setShowPopupDelete}
            title="エクスポート"
            subTitle="MY カルテ ダッシュボードをエクスポートします。"
            description=" 出力形式：PDF"
            onSubmit={handleExport}
          />
        </>
      )}
    </Wrapper>
  );
};

const columns: ColumnsType<Types.ReportCurriculumUser.ResponseType> = [
  {
    title: 'No.',
    dataIndex: '',
    key: '',
    width: '3%',
    render: (_, _record, index) => index + 1,
  },
  {
    title: 'カリキュラム',
    dataIndex: 'curriculum_name',
    key: 'curriculum_name',
    align: 'left',
  },
];

const renderStatistic = (
  containerWidth: number,
  text: string | ReactNode,
  style: CSSProperties
) => {
  const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
  const R = containerWidth / 2;
  let scale = 1;

  if (containerWidth < textWidth) {
    scale = Math.min(
      Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))),
      1
    );
  }

  const textStyleStr = `width:${containerWidth}px;`;
  return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
    scale < 1 ? 1 : 'inherit'
  };">${text}</div>`;
};

export default MyChart;
