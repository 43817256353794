import styled from 'styled-components';

export default styled.section`
  background: #ffffff;
  height: 100%;
  user-select: none;
  .menu-right-click {
    width: 200px;
    padding: 5px 0;
    background: #ffffff;
    box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.05), 0 1px 2px 1px rgba(66, 66, 66, 0.08);
  }
  .header {
    .filter-icon {
      font-size: 18px;
      color: #aaaaaa;
      margin-right: 5px;
    }
    .wrap-filter {
      display: flex;
      align-items: center;
      .form-input {
        margin-bottom: unset;
        .select-input {
          width: 140px;
        }
        .name {
          width: 250px;
        }
      }
      .text-reset {
        border: none;
        background-color: unset;
        text-decoration-line: underline;
        color: #08a3a5;
        cursor: pointer;
      }
    }
  }

  .wrap-path-folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
    height: 55px;
    .option {
      display: flex;
      flex-direction: row;
      align-items: center;
      .button-director-folder {
        display: flex;
        justify-content: center;
        width: 100px;
        .back-button {
          background: none;
          border: none;
          cursor: pointer;
          &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          .icon-arrow {
            color: #424242;
          }
        }
      }
      .divider {
        width: 1px;
        height: 20px;
        background: #dddddd;
      }
      .path-folder {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 30px;
        .icon-folder {
          color: #287dbb;
          font-size: 18px;
        }
        .folder {
          display: flex;
          align-items: center;
          .icon-right {
            margin: 0 10px;
            font-size: 12px;
            color: #999999;
          }
          .prev-folder {
            font-size: 12px;
            color: #424242;
            white-space: nowrap;
            background: none;
            border: none;
            padding: 4px 0;
            cursor: pointer;
          }
        }
      }
    }
    .group-btn {
      display: flex;
      justify-content: flex-end;
      margin-right: 12px;
      .btn {
        width: 125px;
        height: 35px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: 700;
        font-size: 13px;
      }
      .btn-primary {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        color: #f1a900;
        margin-right: 8px;
        &:disabled {
          cursor: not-allowed;
          color: #959595;
          border-color: #959595;
        }
      }
      .btn-active,
      .disabled {
        text-align: center;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border: none;
        color: #ffffff;
        .icon-plus {
          padding-right: 10px;
        }
      }
      .disabled {
        background: #cccccc;
        color: #777777;
      }
    }
  }
  .wrap-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 5px solid #f0f2f5;
    height: calc(100vh - 111px);
    .left-side {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      overflow: auto;
      width: 250px;
      height: initial;
      .slider {
        display: flex;
        align-items: center;
        padding: 11px 0;
        border-bottom: 1px solid #eeeeee;
        .item {
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0 10px;
          .icon {
            color: #777777;
            font-size: 18px;
          }
        }
        .item-slider {
          width: 100%;
        }
      }
      .divider {
        width: 1px;
        height: 20px;
        background: #dddddd;
        margin: 0 10px;
      }
      .icon-add {
        font-size: 18px;
        color: #287dbb;
        margin-right: 15px;
        &-disabled {
          color: #cccccc;
          font-size: 18px;
        }
      }
    }
    .divider-resize {
      width: 5px;
      height: initial;
      margin: 0 20px;
      background: #efefef;
    }
    .right-side {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: calc(100% - 250px);
      .header-table {
        display: flex;
        flex-direction: row;
        width: 100%;
        background: #ffffff;
        border-bottom: 1px solid #eeeeee;
        .column {
          padding: 16px;
          .title {
            font-size: 11px;
            color: #777777;
          }
        }
      }
      .access-denied {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #f9f8f8;
        border: 1px solid #cccccc;
        margin-top: 20px;
        width: 98%;
        padding: 10px 5px;
        .icon-lock {
          color: #c4c4c4;
          font-size: 30px;
          margin: 0 10px;
        }
        .content {
          color: #424242;
          font-size: 13px;
        }
      }
    }
    .ant-table-wrapper {
      width: 100%;
      height: 100%;
      max-height: calc(100% - 70px);
      overflow: auto;
      .ant-table-tbody {
        .center {
          text-align: center;
        }
      }
      .ant-table-cell.file-description {
        text-align: center;
      }

      .ant-table-tbody {
        .ant-table-cell {
          cursor: pointer;
          font-size: 13px;
          color: #777777;
          border: none;
          word-break: break-word;
        }
        .title {
          align-items: center;
          .icon-folder {
            color: #287dbb;
            font-size: 16px;
          }
          .icon-excel {
            color: #107c41;
            font-size: 16px;
          }
          .icon-word {
            color: #185abd;
            font-size: 16px;
          }
          .icon-ppt {
            color: #c43f1b;
            font-size: 16px;
          }
          .icon-pdf {
            color: #b30b00;
            font-size: 16px;
          }
          .icon-image {
            color: #cc9f00;
            font-size: 16px;
          }
          .icon-play {
            color: #5c5b61;
            font-size: 16px;
          }
          .icon-file {
            color: #777777;
            font-size: 16px;
          }
          .icon-logo {
            width: 16px;
            height: 16px;
          }
          .item-row {
            display: flex;
            align-items: center;
            .item-name {
              .label-name {
                padding-left: 10px;
                margin: 0;
              }
            }
          }
        }
        .file-description {
          padding: 16px 0;
        }
      }
    }
  }
`;
