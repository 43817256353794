import React, { Dispatch, SetStateAction } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { RegisterInformationPaymentFormik } from 'types';
import { FormikProvider, useFormik } from 'formik';
import { fiveStepSchema } from 'libs/validations';
import { Form, SubmitButton } from 'formik-antd';
import { routes } from 'navigations/routes';
import { Wrapper } from './styles';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

import { createAffiliationAssignLevel, createAffiliationRole } from 'pages/Settings/Employee/thunk';
import { createAffiliationLevel } from 'pages/Settings/AffiliationMaster/thunk';
import { createItemPositionMaster } from 'pages/Settings/PositionMaster/thunk';
import { isFirstLogin, updateLoginTime } from '../../Auth/thunk';
import { createRoles } from 'pages/Settings/RolesMaster/thunk';
import { informationRegisterSelector } from '../selectors';
import { authSelector } from 'containers/Auth/selectors';
import { AFFILIATION_ASSIGN_ROLE } from 'configs';
import { useSelector } from 'react-redux';
import { resetInformation } from '../slice';
import { useAppDispatch } from 'hooks';
import { Logo } from 'assets';
import {
  confirmCreditCardThunk,
  getCardCompanyName,
  addLinkAffiliation,
  registerUserThunk,
  createCompanies,
} from '../thunk';

interface Props {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  success: boolean;
}

const SixStep: React.FC<Props> = ({ setCurrentStep, setSuccess, success }) => {
  const { signedInEmail } = useSelector(authSelector);
  const { creditCard } = useSelector(informationRegisterSelector);
  const { plans_selection, information } = useSelector(informationRegisterSelector);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { messages } = useIntl();

  const formik = useFormik<RegisterInformationPaymentFormik>({
    initialValues: creditCard,
    validationSchema: fiveStepSchema,
    validateOnBlur: false,
    onSubmit: (values) => {
      setSuccess(true);
      setTimeout(() => {
        const { securityNumber, expirationDate, customerName, cardNumber, companyName } = values;
        window.Multipayment.init('tshop00054795');
        let plan_expiry_at = '9999-12-31';
        if (plans_selection?.plan_expiry_month && plans_selection?.plan_expiry_days) {
          plan_expiry_at = dayjs()
            .add(Number(plans_selection.plan_expiry_month), 'months')
            .add(Number(plans_selection.plan_expiry_days), 'days')
            .format('YYYY-MM-DD');
        } else if (plans_selection?.plan_expiry_month) {
          plan_expiry_at = dayjs()
            .add(Number(plans_selection.plan_expiry_month), 'months')
            .format('YYYY-MM-DD');
        } else if (plans_selection?.plan_expiry_days) {
          plan_expiry_at = dayjs()
            .add(Number(plans_selection.plan_expiry_days), 'days')
            .format('YYYY-MM-DD');
        }
        window.Multipayment.getToken(
          {
            holdername: customerName,
            cardno: cardNumber,
            expire: expirationDate,
            securitycode: securityNumber,
          },
          async (res) => {
            if (res.resultCode !== '000') {
              alert('購入処理中にエラーが発生しました');
            } else {
              const result = await dispatch(
                getCardCompanyName({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [companyName],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                })
              );
              if (getCardCompanyName.fulfilled.match(result)) {
                const company = result.payload.items.find(
                  (data) => data.card_company_name === companyName
                );
                const resultActionRegisterInformation = await dispatch(
                  createCompanies({
                    item: {
                      ...information,
                      plan_id: plans_selection?.i_id,
                      plan_start_at: dayjs().format('YYYY-MM-DD'),
                      plan_expiry_at: plan_expiry_at,
                      createdat: new Date(),
                      createdby: signedInEmail,
                      card_company_name: company?.i_id,
                    },
                    return_item_result: true,
                    return_display_id: true,
                  })
                );
                if (createCompanies.fulfilled.match(resultActionRegisterInformation)) {
                  const { item, item_id } = resultActionRegisterInformation.payload;

                  //TODO: check field name
                  const resultActionCreateRole = await dispatch(
                    createRoles({
                      item: {
                        name: 'admin',
                        admin_dashboard_permission: 2,
                        skill_check_implementation_permission: 2,
                        training_report_permission: 1,
                        skill_check_report_permission: 1,
                        my_chart_permission: 1,
                        manual_permission: 2,
                        knowledge_permission: 2,
                        official_curriculum_permission: 2,
                        curricullum_master_permission: 2,
                        question_master_permission: 2,
                        skill_check_master_permission: 2,
                        inter_users_master_permission: 2,
                        groups_master_permission: 2,
                        users_master_permission: 2,
                        roles_master_permission: 2,
                        departments_master_permission: 2,
                        positions_master_permission: 2,
                        admin_master_permission: 2,
                        payment_master_permission: 2,
                        report_view_permission: 1,
                        bundle_master_permission: 1,
                        company_id: item.id,
                        createdby: signedInEmail,
                        createdat: new Date(),
                      },
                      is_force_update: true,
                    })
                  );
                  const resultActions1 = await Promise.all([
                    dispatch(
                      createAffiliationLevel({
                        item: {
                          name: information.admin_department,
                          sort_order: 1,
                          level: 1,
                          createdby: signedInEmail,
                          company_id: item.id,
                          createdat: new Date(),
                        },
                        return_display_id: true,
                        return_item_result: true,
                      })
                    ),
                    dispatch(
                      createItemPositionMaster({
                        item: {
                          name: information.admin_position,
                          company_id: item.id,
                          rank_order: 1,
                          createdby: signedInEmail,
                          createdat: new Date(),
                        },
                        return_display_id: true,
                        return_item_result: true,
                      })
                    ),
                    dispatch(
                      registerUserThunk({
                        item: {
                          company_id: item.id,
                          login_id: signedInEmail,
                          name: information.admin_name,
                          name_furigana: information.admin_name_furigana,
                          email: signedInEmail,
                          user_type: 'admin',
                          admin_phone: information.admin_phone,
                          role_code: createRoles.fulfilled.match(resultActionCreateRole)
                            ? resultActionCreateRole.payload.item_id
                            : undefined,
                          createdat: new Date(),
                          createdby: signedInEmail,
                          last_login_at: dayjs().toISOString(),
                        },
                      })
                    ),
                  ]);

                  if (registerUserThunk.fulfilled.match(resultActions1[2])) {
                    let affiliation_id;
                    let positions_id;
                    let affiliation_code;
                    if (
                      createAffiliationLevel.fulfilled.match(resultActions1[0]) &&
                      resultActions1[0].payload.item
                    ) {
                      affiliation_id = resultActions1[0].payload.item_id;
                      affiliation_code = resultActions1[0].payload.item.affiliation_id;
                    }
                    if (
                      createItemPositionMaster.fulfilled.match(resultActions1[1]) &&
                      resultActions1[1].payload.item
                    ) {
                      positions_id = resultActions1[1].payload.item_id;
                    }
                    const resultActions2 = await Promise.all([
                      dispatch(
                        createAffiliationRole({
                          item: {
                            company_id: item.id,
                            affiliation_id: affiliation_id,
                            login_id: signedInEmail,
                            positions_code: positions_id,
                            sort_order: 1,
                            main_role: 'main',
                            createdby: signedInEmail,
                            createdat: new Date(),
                          },
                        })
                      ),
                      dispatch(
                        createAffiliationAssignLevel({
                          item: {
                            company_id: item.id,
                            affiliation_id: affiliation_code,
                            login_id: signedInEmail,
                            sort_order: 1,
                            createdby: signedInEmail,
                            createdat: new Date(),
                          },
                        })
                      ),
                      dispatch(
                        confirmCreditCardThunk({
                          action_id: 'customerregistration',
                          item: {
                            name: signedInEmail,
                          },
                          as_params: {
                            token: res.tokenObject.token,
                            company_id: item.id,
                            company_item_id: item_id,
                          },
                          is_force_update: true,
                        })
                      ),
                    ]);
                    if (
                      createAffiliationRole.fulfilled.match(resultActions2[0]) &&
                      createAffiliationAssignLevel.fulfilled.match(resultActions2[1])
                    ) {
                      dispatch(
                        addLinkAffiliation({
                          id: resultActions2[1].payload.item_id,
                          data: {
                            link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                            link_item_id: resultActions2[0].payload.item_id,
                          },
                        })
                      );
                    }
                    if (confirmCreditCardThunk.fulfilled.match(resultActions2[2])) {
                      const resultActionIsFirstLogin = await dispatch(
                        isFirstLogin({
                          conditions: [
                            {
                              id: 'email',
                              search_value: [signedInEmail],
                              exact_match: true,
                            },
                          ],
                          include_links: true,
                          include_lookups: true,
                          page: 1,
                          per_page: 1,
                        })
                      );
                      if (
                        isFirstLogin.fulfilled.match(resultActionIsFirstLogin) &&
                        resultActionIsFirstLogin.payload.totalItems
                      ) {
                        await dispatch(
                          updateLoginTime({
                            id: resultActionIsFirstLogin.payload.items[0].i_id,
                            data: {
                              item: {
                                last_login_at: dayjs().toISOString(),
                              },
                              is_force_update: true,
                            },
                            un_realtime: true,
                          })
                        );
                      }
                      dispatch(resetInformation());
                      navigate(generatePath(routes.Dashboard.path, { entity: 'receiving' }));
                    }
                  }
                }
              }
            }
          }
        );
      }, 1500);
    },
  });

  return (
    <Wrapper>
      {success ? (
        <p className="image-logo">
          <img src={Logo} alt="logo" className="logo" />
        </p>
      ) : (
        <FormikProvider value={formik}>
          <p className="text-center">{messages['AJ-05-1']}</p>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
          >
            <div className="form">
              <Form.Item
                name="name"
                label={<span className="text-label">料金プラン</span>}
                className="form-input"
              >
                <div className="step-top">
                  <div className="step-title">
                    <div className="gourd-left">
                      <div className="form-step">
                        <p className="title-gourd">基本利用料</p>
                        <div className="title">
                          <div className="title-left">
                            <p className="name">ユーザー利用料</p>
                            <p className="title-name-border">1ユーザー</p>
                            <p className="title-border">
                              {plans_selection?.user_monthly_fee}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・SKILL FAMILIARのすべての
                                <br /> 機能が利用できます。
                              </p>
                              <p>
                                ・社内ユーザーの1か月の最大 <br /> 登録人数が対象となります。
                              </p>
                            </div>
                          </div>
                          <div className="total">+</div>
                          <div className="title-left title-left-center">
                            <p className="name">ストレージ利用料</p>
                            <p className="title-name-border">1企業50GBにつき</p>
                            <p className="title-border">
                              {plans_selection?.usage_storage_unit_price}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・ストレージ利用量50GBごとに
                                <br /> 自動課金されます。
                              </p>
                              <p>
                                ・ストレージ利用量はいつでも <br /> 確認できます。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="total total-right">+</div>
                    <div className="gourd-left">
                      <div className="form-step">
                        <p className="title-gourd">オプション</p>
                        <div className="title">
                          <div className="title-left">
                            <p className="name">面接スキルチェック利用料</p>
                            <p className="title-name-border">1回実施につき</p>
                            <p className="title-border">
                              {plans_selection?.skill_check_unit_price}
                              <span className="month">円</span>
                            </p>
                            <div>
                              <p>
                                ・面接スキルチェック1回実施 <br /> に対してカウントされます。
                              </p>
                              <p>
                                ・面接ユーザーは月額利用料に <br /> カウントされません。
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                name="name_furigana"
                label={<span className="text-label">支払方法</span>}
                className="form-input"
              >
                <div className="input-review">
                  クレジットカード、銀行振込
                  <br />
                  ※基本的にはクレジットカードでのお支払い。銀行振込には条件がございますので、お問い合わせください。
                </div>
              </Form.Item>
              <Form.Item
                name="building_name"
                label={<span className="text-label">支払の時期</span>}
                className="form-input"
              >
                <div className="input-review">
                  クレジットカード：代金引き落としの時期は、クレジットカード会社によって異なります。
                  <br />
                  ご利用のクレジットカード会社にお問い合わせをお願いいたします。
                  <br />
                  銀行振込：ご利用月の翌月末までにその月の代金のお振込みをお願いしております。
                </div>
              </Form.Item>
              <Form.Item
                name="admin_name"
                label={<span className="text-label">ご利用開始</span>}
                className="form-input"
              >
                <div className="input-review">ご登録完了後すぐにご利用いただけます。</div>
              </Form.Item>
              <Form.Item
                name="admin_name_furigana"
                label={<span className="text-label">キャンセル・解約について</span>}
                className="form-input"
              >
                <div className="input-review">
                  製品の特性上、利用開始後のキャンセルはできません。
                  <br />
                  解約はいつでもお手続きが可能です。詳細は利用規約をご確認ください。
                </div>
              </Form.Item>
            </div>
            <div className="wrap-button">
              <SubmitButton className="btn-submit">{messages['AJ-05-2']}</SubmitButton>
              <button type="button" className="go-back" onClick={() => setCurrentStep(0)}>
                {'<  キャンセル'}
              </button>
            </div>
          </Form>
        </FormikProvider>
      )}
    </Wrapper>
  );
};

export default SixStep;
