import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
  showButton: boolean;
  errors: string;
  isCheckUpload: boolean;
}

export default styled.div<Props>`
  width: 100%;
  height: 100vh;
  form {
    height: calc(100vh - ${(props) => (props.showButton ? 151 : 56)}px);
    overflow-y: scroll;
  }
  .jodit-toolbar-button_image,
  .jodit-toolbar-button_insertVideo {
    pointer-events: ${(props) => (props.isCheckUpload ? 'none' : '')};
  }

  .file-info {
    background: #ffffff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left-side {
      display: flex;
      padding-right: 30px;
      width: 65%;
      .icon-status {
        margin-right: 10px;
      }
      .wrap-info {
        .info {
          display: flex;
          align-items: center;
          .logo-file {
            height: 24px;
            font-size: 24px;
          }
          .file-name {
            font-size: 20px;
            margin-left: 5px;
          }
        }
        .description {
          word-break: break-word;
          font-size: 12px;
          color: #777777;
          margin: 0;
        }
      }
    }
    .right-side {
      display: flex;
      align-items: center;
      width: 35%;
      justify-content: end;
      .version {
        color: #999999;
        font-size: 10px;
        margin-right: 15px;
      }
      .btn {
        background: #ffffff;
        border: 1px solid #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        color: #f6ac00;
        font-size: 13px;
        &-edit {
          background: #f6ac00;
          color: #ffffff;
          margin-left: 8px;
        }
      }
      .disabled {
        background: #cccccc !important;
        color: #777777 !important;
        border: 1px solid #cccccc;
        margin-left: 8px;
      }
      .btn-close {
        background: unset;
        border: unset;
        box-shadow: unset;
        margin: 0 10px;
        padding: 0;
        cursor: pointer;
        .close-up {
          font-size: 13px;
          color: #777777;
        }
        .close-icon {
          color: #777777;
          margin: 0 10px;
        }
      }
    }
  }
  .while-editing {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #ffffff;
    padding: 10px;
    .wrap-input {
      width: 96.5%;
      .wrap-input-version {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;
        .ant-form-item {
          margin-bottom: 0;
        }
        .title {
          width: 70%;
          .ant-form-item-label {
            display: flex;
            justify-content: center;
            align-items: center;
            .logo-label {
              height: 32px;
              padding-left: 10px;
            }
            label::after {
              content: '';
            }
          }
          .ant-input-suffix {
            display: none;
          }
          &_error {
            .ant-form-item-control {
              .ant-input-affix-wrapper {
                background: #fffaf9 !important;
                border-color: #ff4d4f;
                input {
                  background: #fffaf9 !important;
                }
              }
            }
          }
        }
        .version .ant-form-item-label {
          display: flex;
          align-items: center;
          margin-bottom: ${(props) => (props.errors ? '17px' : 0)};
        }
        .version-errors {
          font-size: 11px;
          color: #ff4d4f;
        }
      }
      .description {
        margin-bottom: 0;
        .ant-form-item-label {
          width: 50px;
          label::after {
            content: '';
          }
        }
        textarea {
          resize: vertical;
        }
      }
    }
  }
  .wrap-editor {
    display: flex;
    margin: 20px;
    height: calc(100% - 170px);
    .left-side {
      height: auto;
      width: 70%;
      background: #ffffff;
      .title-editor {
        display: flex;
        background: #ffffff;
        align-items: center;
        border: 1px solid #dddddd;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        .no-editor {
          padding: 0 15px;
        }
        .input {
          width: 100%;
          padding: 15px 10px;
          box-shadow: unset;
          border: unset;
          border-left: 1px solid #dddddd;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          &:disabled {
            background: #ffffff;
            color: #000000;
          }
        }
        &-error {
          background: #fffaf9 !important;
          border-color: #ff4d4f;
          .input {
            border-color: #ff4d4f;
            background: #fffaf9 !important;
          }
        }
        .ant-input-affix-wrapper-focused {
          box-shadow: unset;
        }
      }
      .jodit-react-container {
        height: calc(100% - 111px);
        .jodit-container {
          height: 100% !important;
          border-top: unset;
          border-bottom: unset;
          border-left: 1px solid #dddddd;
          border-right: 1px solid #dddddd;
          border-radius: 0;
        }
        .jodit-workplace > div > jodit-media > video {
          z-index: ${(props) => (props.showButton ? 0 : 1)} !important;
        }
      }
      .wrap-bottom-editor {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px;
        border: 1px solid #dddddd;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        .wrap-button-editor {
          display: flex;
          align-items: center;
          .btn {
            border-radius: 5px;
            border: none;
            &:disabled {
              background: #eeeeee;
              color: #bbbbbb;
            }
            color: #ffffff;
          }
          .button-prev {
            background: #08a3a5;
          }
          .button-next {
            background: #08a3a5;
            margin-left: 8px;
          }
        }
        .delete-editor {
          display: flex;
          align-items: center;
          color: #00989a;
          border: unset;
          background: unset;
          cursor: pointer;
          &:disabled {
            color: #bbbbbb;
            cursor: not-allowed;
          }
          .icon-delete {
            font-size: 19px;
          }
          span {
            text-decoration: underline;
            margin-left: 5px;
          }
        }
        .disabled {
          color: #00989a;
        }
      }
      .wrap-view-jodit {
        height: 664px;
        overflow-y: auto;
        padding: 8px;
      }
    }
    .right-side {
      width: 30%;
      padding: 0 20px;
      .add-section {
        border: 1px solid #00a3a5;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        background: #ffffff;
        color: #00a3a5;
        font-weight: 700;
      }
      .title-right {
        font-size: 13px;
        color: #424242;
        font-weight: 400;
        padding-left: 4px;
      }
      .dashed-line {
        border: 1px dashed #cccccc;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 24px;
      }
      .wrap-button-add {
        text-align: center;
        margin-top: 28px;
      }
      .list-section {
        width: 100%;
        .label-radio {
          display: inline-flex;
          align-items: center;
          padding: 0 15px;
          background: #ffffff;
          color: #777777;
          border-right: 1px solid #cccccc;
          border-radius: 4px 0 0 4px;
          font-size: 13px;
        }
        .input-radio {
          display: flex;
          border: 1px solid #cccccc;
          height: 40px;
          background: #ffffff;
          border-radius: 3px;
          margin-bottom: 10px;
          .ant-input-affix-wrapper-borderless {
            border: none;
          }
        }
        .section-checked {
          border: 1px solid #00a3a5 !important;
          .label-radio {
            background: #00a3a5;
            color: #ffffff;
            border-radius: 0;
            border-color: #00a3a5 !important;
          }
        }
        .error {
          border-color: #ff4d4f;
          .label-radio {
            border-color: #ff4d4f;
          }
          .ant-input-affix-wrapper {
            background-color: #fffaf9;
            input {
              background-color: #fffaf9;
            }
          }
        }
      }
      .view-mode {
        width: 100%;
        cursor: pointer;
        .input-radio {
          display: flex;
          align-items: center;
          border: 1px solid #cccccc;
          height: 40px;
          background: #ffffff;
          border-radius: 3px;
          margin-bottom: 10px;
          .label-radio {
            display: flex;
            align-items: center;
            padding: 0 15px;
            background: #ffffff;
            color: #777777;
            border-radius: 4px 0 0 4px;
            font-size: 13px;
            height: 100%;
          }
          .ant-radio-wrapper-checked .add-section {
            border-color: #00a3a5;
            border-radius: 5px;
            font-size: 12px;
            color: #00a3a5;
            font-weight: 700;
          }
          .section-name {
            margin-left: 10px;
            font-size: 14px;
          }
        }
        .section-checked {
          border: 1px solid #00a3a5;
          .label-radio {
            background: #00a3a5;
            color: #ffffff;
            border-radius: 0;
          }
        }
      }
      .wrap-radio {
        max-height: calc(100% - ${(props) => (props.showButton ? 95 : 60)}px);
        overflow: auto;
      }
      .right-top {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }
  .wrap-submit {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .wrap-button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      .btn {
        border-radius: 5px;
        border: none;
        width: 30%;
        height: 40px;
        cursor: pointer;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        &_submit {
          color: #ffffff;
          background-color: #f6ac00;
        }
        &_close {
          background: #ffffff;
          border: 1px solid #d9d9d9;
          color: #777777;
        }
        &_outline {
          background-color: #ffffff;
          border: 1px solid #f6ac00;
          color: #f1a900;
        }
      }
    }
  }
`;
