import styled from 'styled-components';

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    color: #bbbbbb;
    font-size: 28px;
  }
  .description {
    margin: 0;
    font-size: 15px;
    color: #424242;
    margin-left: 8px;
  }
`;
