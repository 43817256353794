import React, { useState, forwardRef, createRef, useImperativeHandle } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import DeleteComplete from '../DeleteComplete';
import { SectionStyled } from './styles';
import { Modal } from 'components';

interface ConfirmDeleteModalRef {
  setVisible: ({
    t,
    sub,
    des,
    tComplete,
    onSubmit,
  }: {
    t: string;
    sub: string;
    tComplete: string;
    des: string;
    onSubmit?: () => Promise<void> | void;
  }) => void;
}

export const confirmDeleteModalRef = createRef<ConfirmDeleteModalRef>();

const ConfirmDeleteModal = forwardRef<ConfirmDeleteModalRef>((_, ref) => {
  const [submitFn, setSubmitFn] = useState<() => Promise<void> | void>();
  const [completeTitle, setCompleteTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [visible, setVisible] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [subTitle, setSubTitle] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const handleCloseModal = () => {
    setVisible(false);
  };

  const handleSubmitModal = async () => {
    setLoading(true);
    submitFn && (await submitFn());
    setLoading(false);
    setVisible(false);
    setSuccess(true);
  };

  useImperativeHandle(ref, () => ({
    setVisible: ({ des, sub, t, tComplete, onSubmit }) => {
      setDescription(des);
      setSubTitle(sub);
      setTitle(t);
      setVisible(true);
      setCompleteTitle(tComplete);
      setSubmitFn(() => onSubmit);
    },
  }));

  return success ? (
    <DeleteComplete title={completeTitle} visible={success} setVisible={setSuccess} />
  ) : (
    <Modal
      title={title}
      visible={visible}
      width={720}
      onCancel={handleCloseModal}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmitModal,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleCloseModal,
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          <ExclamationCircleOutlined className="icon" />
          {subTitle}
        </p>
        <p className="text-content">{description}</p>
      </SectionStyled>
    </Modal>
  );
});

export default ConfirmDeleteModal;
