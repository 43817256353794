import * as Types from 'types';

const convertData = ({
  node,
  item,
}: {
  node: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
}) => {
  if (!node.children || node.columnIndex === 5) {
    return;
  }

  const currentItem = node.children.find((i) =>
    Object.keys(item).some((key) => item[key] === i.code)
  );
  if (!currentItem && node.columnIndex !== undefined) {
    if (
      node.columnIndex < 4 &&
      (item[`level${node.columnIndex}_code`] === node.code || item.curriculum_code === node.code)
    ) {
      node.children.push({
        code: item[`level${node.columnIndex + 1}_code`],
        columnIndex: node.columnIndex + 1,
        sort_order: item[`level${node.columnIndex + 1}_sort_order`],
        name: item[`level${node.columnIndex + 1}_name`],
        publish: node.publish,
        required_curriculum: node.required_curriculum,
        children: [],
      });
    } else if (item.level4_code === node.code) {
      node.children.push({
        code: item.question_code,
        columnIndex: 5,
        sort_order: item.question_sort_order,
        publish: node.publish,
        required_curriculum: node.required_curriculum,
        name: item.question_name,
      });
    }
  }

  // Get all descendants
  const childCount = node.children.length;

  for (let i = 0; i < childCount; i += 1) {
    convertData({
      node: node.children[i],
      item,
    });
  }

  return;
};

export const convertDataFromTree = ({
  treeData,
  item,
}: {
  treeData: Types.TreeItem<Types.CurriculumItemType>;
  item: any;
}) => {
  if (!treeData) {
    return {};
  }

  convertData({
    item,
    node: { children: [treeData] },
  });

  return treeData;
};
