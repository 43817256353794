import React from 'react';
import ReactPlayer from 'react-player';
import { Image } from 'antd';

import { SectionStyled } from './styles';
import { Modal } from 'components';

interface Props {
  index?: number;
  item?: string;
  isPreviewVisible?: number;
  onSubmit?: () => Promise<void> | void;
  setPreviewImageAnswer: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const ModalPreview: React.FC<Props> = ({
  item,
  index,
  onSubmit,
  isPreviewVisible,
  setPreviewImageAnswer,
}) => {
  const handleToggleModal = () => {
    setPreviewImageAnswer(undefined);
  };

  return (
    <Modal
      centered
      width={800}
      open={isPreviewVisible === index}
      footer={null}
      onCancel={() => handleToggleModal()}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      destroyOnClose
      closable={false}
      className="modal-preview-img"
    >
      <SectionStyled>
        {item?.includes('png') || item?.includes('jpg') || item?.includes('jpeg') ? (
          <Image className="file-attach" src={item} preview={false} />
        ) : item?.includes('mp4') ? (
          <ReactPlayer
            className="file-video"
            url={item}
            height={'100%'}
            controls
            config={{
              file: {
                attributes: {
                  disablePictureInPicture: true,
                  controlsList: 'nodownload noplaybackrate',
                },
              },
            }}
          />
        ) : null}
      </SectionStyled>

      <button
        onClick={() => {
          setPreviewImageAnswer(undefined);
        }}
        className="close-img"
      >
        閉じる
      </button>
    </Modal>
  );
};

export default ModalPreview;
