import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { getFileAttach, getProfileAttachFile } from 'pages/Profile/thunk';
import { convertFileResponse } from 'libs/utils/question';
import { services } from 'services';
import * as Types from 'types';
import {
  TOP_SKILL_CHECK_IMPLEMENTATION_STATUS,
  TOP_CURRICULUM_ACHIEVED_STATUS,
  MAIN_AFFILIATION_LIST,
  OPTION_AFFILIATION,
  SELECT_CURRICULUMS,
  SELECT_SKILL_CHECK,
  DIRECTED_KNOWLEDGE,
  SKILL_CHECK_USERS,
  SKILL_CHECK_GRAPH,
  AFFILIATION_LEVEL,
  CURRICULUM_GRAPH,
  SKILL_CHECK,
} from 'configs';

export const getCurriculumData = createAsyncThunk<
  Types.ReportsItemResponseType<Types.TopCurriculumAchievedStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getCurriculumData', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(TOP_CURRICULUM_ACHIEVED_STATUS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getTopSkillCheckImplementStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.TopSkillCheckImplementationStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getTopSkillCheckImplementStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter(TOP_SKILL_CHECK_IMPLEMENTATION_STATUS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSkillCheckUsers = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getSkillCheckUsers', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckUser.ResponseType>(
      SKILL_CHECK_USERS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumGraphGreen = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumGraph.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getCurriculumGraphGreen', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumGraph.ResponseType>(
      CURRICULUM_GRAPH.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectCurriculums.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectCurriculums.ResponseType>(
      SELECT_CURRICULUMS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheckGraph = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SkillCheckGraph.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataSkillCheckGraph', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SkillCheckGraph.ResponseType>(
      SKILL_CHECK_GRAPH.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getCurriculumGraphBlue = createAsyncThunk<
  Types.ReportsItemResponseType<Types.CurriculumGraph.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getCurriculumGraphBlue', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.CurriculumGraph.ResponseType>(
      CURRICULUM_GRAPH.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getMainAffiliationList = createAsyncThunk<
  Types.ReportsItemResponseType<Types.MainAffiliationList.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getMainAffiliationList', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.MainAffiliationList.ResponseType>(
      MAIN_AFFILIATION_LIST.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationLevel.ResponseType> & { myAffiliationID?: string },
  Types.GetItemRequestType & { myAffiliationID?: string },
  Types.ThunkAPI
>('dashboard/thunk/getAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    if (!req.myAffiliationID) return { totalItems: 0, items: [] };
    const { data } = await services.search<Types.AffiliationLevel.ResponseType>(
      AFFILIATION_LEVEL.id,
      omit(req, 'myAffiliationID')
    );

    return { ...data, myAffiliationID: req.myAffiliationID };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDirectedKnowledge = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DirectedKnowledge.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getDirectedKnowledge', async (req, { rejectWithValue, dispatch }) => {
  try {
    const { data } = await services.filter<Types.DirectedKnowledge.ResponseType>(
      DIRECTED_KNOWLEDGE.id,
      req
    );

    const dataConvert = await Promise.all(
      data.report_results.map(async (val) => {
        if (val.author_file_icon) {
          const resultActionGetAttach = await dispatch(
            getProfileAttachFile({
              conditions: [
                {
                  id: 'fileID',
                  search_value: [val.author_file_icon],
                },
              ],
              page: 1,
              per_page: 1,
            })
          );
          if (
            getProfileAttachFile.fulfilled.match(resultActionGetAttach) &&
            resultActionGetAttach.payload.items[0]?.file
          ) {
            const fileAction = await dispatch(
              getFileAttach({ file_id: resultActionGetAttach.payload.items[0].file })
            );
            if (getFileAttach.fulfilled.match(fileAction)) {
              return {
                ...val,
                author_avt: convertFileResponse({
                  file: fileAction.payload,
                  fileID: resultActionGetAttach.payload.items[0]?.fileID,
                  fileName: resultActionGetAttach.payload.items[0]?.filename,
                }),
              };
            }
          }
        }
        return val;
      })
    );

    return { ...data, report_results: dataConvert };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getOptionAffiliations = createAsyncThunk<
  Types.ReportsItemResponseType<Types.OptionAffiliation.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI
>('dashboard/thunk/getOptionAffiliations', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.OptionAffiliation.ResponseType>(
      OPTION_AFFILIATION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('dashboard/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
