import React from 'react';
import { CopyOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

import { createLinkSkillCheckAssignQuestion } from 'containers/CreateEditQuestion/thunk';
import { downloadFileFromMinio, uploadFileToMinIO } from 'services/minioService';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { createSkillcheck, getDataSkillCheckTree } from '../../thunk';
import { settingSelector } from 'containers/AppSettings/selectors';
import { authSelector } from 'containers/Auth/selectors';
import { skillCheckSelector } from '../../selectors';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import { Modal } from 'components';
import * as Types from 'types';

interface Props {
  visible: boolean;
  data: Types.SkillCheckTreeViewType;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}

const DuplicateSkillCheck: React.FC<Props> = ({ data, visible, setVisible, setVisibleTooltip }) => {
  const { filter_conditions } = useSelector(skillCheckSelector);
  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    dispatch(startLoading());
    let fileId = '';
    if (data.fileID) {
      const nodeFile = await downloadFileFromMinio(data.fileID);
      if (nodeFile) {
        fileId = await uploadFileToMinIO(nodeFile);
      }
    }
    const resultAction = await dispatch(
      createSkillcheck({
        item: {
          company_id: data.company_id,
          createdby: data.createdby,
          description: data.description,
          grouping_code: data.lookup_items?.grouping_code.i_id,
          group_code: data.grouping_code2,
          implementation_settings: data.implementation_settings,
          fileID: fileId,
          problems4: data.problems4,
          publish: data.publish,
          question_time_limit: data.question_time_limit,
          name: `${data.skill_check_name} コピー`,
          ...(data.start_period && { start_period: data.start_period }),
          ...(data.end_period && { end_period: data.end_period }),
          probs_count: data.probs_count,
          user_count: data.user_count,
          user_type: data.user_type,
          setting_score: data.settings_score,
          createdat: new Date(),
        },
        return_item_result: true,
        return_display_id: true,
      })
    );
    if (
      createSkillcheck.fulfilled.match(resultAction) &&
      data.children &&
      data.children.length > 0
    ) {
      await Promise.all(
        data.children.map((question) =>
          dispatch(
            createLinkSkillCheckAssignQuestion({
              item: {
                company_id: data.company_id,
                skill_check_code: resultAction.payload.item.code,
                question_code: question.question_code,
                createdat: new Date(),
                createdby: userInfo?.login_id,
                sort_order: question?.sort_order,
              },
            })
          )
        )
      );
    }
    await dispatch(
      getDataSkillCheckTree({
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
        conditions: [
          ...filter_conditions.conditions,
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'skill_check_code',
            order: 'asc',
          },
          {
            id: 'grouping_code',
            order: 'asc',
          },
          {
            id: 'sort_order',
            order: 'asc',
          },
        ],
      })
    );
    dispatch(stopLoading());
    setVisible(false);
    setVisibleTooltip(false);
  };

  return (
    <Modal
      title="スキルチェック コピー追加"
      width={720}
      visible={visible}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
        loading,
      }}
      cancelButton={{
        text: 'キャンセル',
        onClick: () => setVisible(false),
      }}
      onCancel={() => setVisible(false)}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
    >
      <SectionStyled>
        <div className="wrap-title">
          <CopyOutlined className="icon-copy" />
          <p className="title">スキルチェックのコピー追加を実行します。</p>
        </div>
        <div className="wrap-content">
          <span className="label">コピー元：</span>
          <p className="name">{data.skill_check_name}</p>
        </div>
      </SectionStyled>
    </Modal>
  );
};

export default DuplicateSkillCheck;
