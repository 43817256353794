import { createSlice } from '@reduxjs/toolkit';

import { getDataRoles, getDataSearchUsers, searchDataRoles } from './thunk';
import { Roles, SelectRole, Users } from 'types';

export interface InitialState {
  searchResult: SelectRole.ResponseType[];
  list_users: Array<Users.ResponseType>;
  userRole: Roles.ResponseType[];
  itemEdit?: Roles.ResponseType;
  roles: Roles.ResponseType[];
  total: number;
}

const initialState: InitialState = {
  searchResult: [],
  list_users: [],
  userRole: [],
  roles: [],
  total: 0,
};

export const rolesMasterSlice = createSlice({
  name: 'rolesMaster-slice',
  initialState,
  reducers: {
    itemEditRolesMaster(state, action) {
      state.itemEdit = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getDataRoles.fulfilled, (state, action) => {
      state.roles = action.payload.items;
      state.total = action.payload.totalItems;
    });

    builder.addCase(searchDataRoles.fulfilled, (state, action) => {
      state.searchResult = action.payload.report_results;
    });

    builder.addCase(getDataSearchUsers.fulfilled, (state, action) => {
      state.list_users = action.payload.items;
    });
  },
});

export const { itemEditRolesMaster, resetInitialState } = rolesMasterSlice.actions;

export default rolesMasterSlice.reducer;
