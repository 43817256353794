import React from 'react';

import { Modal } from 'components';
import useConfirmSendURL, { Props, ReceivedProps } from './hook';

const ConfirmSendURLLayout: React.FC<Props> = (props) => {
  const { isOpen, handleClose, handleSubmit } = props;

  return (
    <Modal
      title="ログインURL送信"
      open={isOpen}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleClose,
      }}
      okButton={{
        text: 'OK',
        onClick: handleSubmit,
      }}
      headerStyle={{
        borderBottom: '1px solid #dddddd',
      }}
      bodyStyle={{
        marginTop: 20,
        textAlign: 'center',
        color: '#2a2a2a',
        fontSize: 16,
      }}
    >
      <p>選択したユーザーにログインURLを送信します。</p>
    </Modal>
  );
};

const ConfirmModal = (props: ReceivedProps) => {
  return <ConfirmSendURLLayout {...useConfirmSendURL(props)} />;
};

export default ConfirmModal;
