import React, { useCallback } from 'react';
import Gradient from 'javascript-color-gradient';
import { Col, Progress, Row } from 'antd';

import { DataDashboardCardType } from 'pages/Dashboard/slice';
import { HomeCardColour } from 'constant/enum.constant';
import { UserType } from 'types/config';
import { Wrapper } from './styles';

interface ProgressBarProps {
  role?: UserType;
  color: HomeCardColour;
  data: DataDashboardCardType;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ color, data, role }) => {
  const gradientArrayGreen = useCallback(() => {
    if (color === HomeCardColour.green) {
      return new Gradient().setColorGradient('#9dd68f', '#36b797').setMidpoint(20).getColors();
    } else if (color === HomeCardColour.blue) {
      return new Gradient().setColorGradient('#08b9d4', '#089cd4').setMidpoint(20).getColors();
    } else {
      return new Gradient()
        .setColorGradient('#d1b4ed', '#a56bdb')
        .setMidpoint(role !== 'admin' ? 10 : 20)
        .getColors();
    }
  }, [color, role]);

  return (
    <Wrapper
      cardColour={color}
      isDoubleProgressBar={color === HomeCardColour.purple && role !== 'admin'}
    >
      <Row className="progress-bar">
        <Col className="progress-col">
          <Row className="progress-bar-title">
            <Col span={18} className="progress-bar-title-text">
              <span>
                {color === HomeCardColour.purple ? (
                  <span>
                    正解増減率<span style={{ fontSize: '11px' }}>（対前回）</span>
                  </span>
                ) : (
                  '正解進捗率'
                )}
              </span>
            </Col>
            <Col span={6} className="progress-bar-title-number">
              <span className="title-number">
                {color === HomeCardColour.purple ? '+' : ''}
                {Math.round(data.increase_rate * 100)}
              </span>
            </Col>
          </Row>
          <Row className="progress-bar-display">
            {color === HomeCardColour.purple && role !== 'admin' ? (
              <div className="double-progress-bar">
                <span className="minus">-</span>
                <Progress
                  type="line"
                  className="progress-bar-bars decrease"
                  percent={100}
                  steps={10}
                  strokeWidth={20}
                  strokeColor={[
                    ...new Gradient()
                      .setColorGradient('#b1aef4', '#a97dde')
                      .setMidpoint(10)
                      .getColors()
                      .splice(
                        0,
                        Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 10)
                      ),
                    ...Array(
                      10 - Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 10)
                    ).fill('#f3f3f3'),
                  ]}
                  showInfo={false}
                />
                <div className="bordered" />
                <Progress
                  type="line"
                  className="progress-bar-bars increase"
                  percent={100}
                  steps={10}
                  strokeWidth={20}
                  strokeColor={[
                    ...gradientArrayGreen().splice(
                      0,
                      Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 10)
                    ),
                    ...Array(
                      10 - Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 10)
                    ).fill('#f3f3f3'),
                  ]}
                  showInfo={false}
                />
                <span className="plus">+</span>
              </div>
            ) : (
              <Progress
                type="line"
                className="progress-bar-bars"
                percent={100}
                steps={20}
                strokeWidth={20}
                strokeColor={[
                  ...gradientArrayGreen().splice(
                    0,
                    Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 20)
                  ),
                  ...Array(
                    20 - Math.round((isFinite(data.increase_rate) ? data.increase_rate : 0) * 20)
                  ).fill('#f3f3f3'),
                ]}
                showInfo={false}
              />
            )}
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default ProgressBar;
