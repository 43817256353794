import React, { useState } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined, FileTextOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Col, Row, Table } from 'antd';

import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import QuestionDetail from 'pages/Reports/SkillCheck/QuestionDetail';
import { skillCheckUserReportSelector } from '../selectors';
import IndividualTableStyled from './styles';
import * as Types from 'types';

interface Props {
  exportPdf?: boolean;
}

const PER_PAGE = 10;

const IndividualTable: React.FC<Props> = ({ exportPdf }) => {
  const [modalQuestionDetail, setModalQuestionDetail] = useState<{
    visible: boolean;
    question_id?: string;
  }>({
    visible: false,
  });
  const [pageSize, setPageSize] = useState<number>(PER_PAGE);
  const [page, setPage] = useState<number>(1);

  const {
    detailOfSkillCheckResults,
    reportSkillCheckUserTransByLoginID,
    reportSkillCheckUserTransBySkillCheckCode,
  } = useSelector(skillCheckUserReportSelector);

  const columns: ColumnsType<Types.DetailOfSkillCheckResults.ResponseType> = [
    {
      title: 'No.',
      align: 'center',
      width: '2%',
      render: (_, _record, index) => index + 1,
    },
    {
      title: '設問',
      className: 'question',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: '設問詳細',
      dataIndex: 'question_id',
      key: 'question_id',
      width: '8%',
      render: (question_id) => (
        <FileTextOutlined
          className="icon"
          onClick={() =>
            setModalQuestionDetail({
              visible: true,
              question_id,
            })
          }
        />
      ),
    },
    {
      title: '解答',
      dataIndex: 'answer',
      key: 'answer',
      width: '5%',
    },
    {
      title: '解答結果',
      dataIndex: 'correct',
      key: 'correct',
      width: '8%',
      render: (value) => (value === 1 ? '正解' : value === -1 ? '未経験' : '不正解'),
    },
    {
      title: '解答時間',
      dataIndex: 'answer_time',
      key: 'answer_time',
      width: '9%',
      sorter: (a, b) => (a.answer_time || 0) - (b.answer_time || 0),
      render: (value) => formatNumberToMinute(value),
    },
    {
      title: 'ユーザー平均解答時間',
      dataIndex: 'average_answer_time',
      key: 'average_answer_time',
      width: '15%',
      sorter: (a, b) => (a.average_answer_time || 0) - (b.average_answer_time || 0),
      render: (value) => formatNumberToMinute(value),
    },
    {
      title: '設定スコア',
      dataIndex: 'score',
      key: 'score',
      width: '9%',
    },
    {
      title: 'ユーザー平均正解率 ',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      width: '14%',
      sorter: (a, b) => (a.accuracy_rate || 0) - (b.accuracy_rate || 0),
      render: (accuracy_rate) => (
        <div className="item-rate">
          <p className="label-number">{formatNumber(accuracy_rate * 100)}</p>
          <small>%</small>
        </div>
      ),
    },
  ];

  return (
    <IndividualTableStyled>
      <Row className="table-result" gutter={[5, 5]}>
        <Col className="item-table" span={8}>
          <p className="table-label">実施結果サマリ</p>
          <Row className="row-label">
            <Col className="gutter-row" span={6}>
              獲得スコア
            </Col>
            <Col className="gutter-row" span={6}>
              正解率
            </Col>
            <Col className="gutter-row" span={6}>
              不正解率
            </Col>
            <Col className="gutter-row" span={6}>
              未経験率
            </Col>
          </Row>
          <Row className="row-number">
            <Col className="gutter-row" span={6}>
              {Math.round(reportSkillCheckUserTransByLoginID.acquisition_score)}
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransByLoginID.accuracy_rate * 100)}
              <small>%</small>
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransByLoginID.wrong_rate * 100)}
              <small>%</small>
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransByLoginID.inexperienced_rate * 100)}
              <small>%</small>
            </Col>
          </Row>
        </Col>
        <Col className="item-table" span={8}>
          <p className="table-label">ユーザー平均 実施結果サマリ</p>
          <Row className="row-label">
            <Col className="gutter-row" span={6}>
              獲得スコア
            </Col>
            <Col className="gutter-row" span={6}>
              正解率
            </Col>
            <Col className="gutter-row" span={6}>
              不正解率
            </Col>
            <Col className="gutter-row" span={6}>
              未経験率
            </Col>
          </Row>
          <Row className="row-number">
            <Col className="gutter-row" span={6}>
              {Math.round(reportSkillCheckUserTransBySkillCheckCode.acquisition_score)}
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransBySkillCheckCode.accuracy_rate * 100)}
              <small>%</small>
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransBySkillCheckCode.wrong_rate * 100)}
              <small>%</small>
            </Col>
            <Col className="gutter-row" span={6}>
              {formatNumber(reportSkillCheckUserTransBySkillCheckCode.inexperienced_rate * 100)}
              <small>%</small>
            </Col>
          </Row>
        </Col>
        <Col className="item-table" span={8}>
          <p className="table-label">対ユーザー平均比較</p>
          <Row className="row-label">
            <Col className="gutter-row" span={6}>
              獲得スコア
            </Col>
            <Col className="gutter-row" span={6}>
              正解率
            </Col>
            <Col className="gutter-row" span={6}>
              不正解率
            </Col>
            <Col className="gutter-row" span={6}>
              未経験率
            </Col>
          </Row>
          <Row className="row-number">
            <Col className="gutter-row" span={6}>
              <div className="item-column">
                {Number(
                  reportSkillCheckUserTransByLoginID.acquisition_score -
                    reportSkillCheckUserTransBySkillCheckCode.acquisition_score
                ) < 0 ? (
                  <ArrowDownOutlined className="icon-down" />
                ) : (
                  <ArrowUpOutlined className="icon-up" />
                )}
                <p className="label-number">
                  {Math.round(
                    Math.abs(
                      reportSkillCheckUserTransByLoginID.acquisition_score -
                        reportSkillCheckUserTransBySkillCheckCode.acquisition_score
                    )
                  )}
                </p>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="item-column">
                {Number(
                  reportSkillCheckUserTransByLoginID.accuracy_rate -
                    reportSkillCheckUserTransBySkillCheckCode.accuracy_rate
                ) < 0 ? (
                  <ArrowDownOutlined className="icon-down" />
                ) : (
                  <ArrowUpOutlined className="icon-up" />
                )}
                <p className="label-number">
                  {formatNumber(
                    Math.abs(
                      reportSkillCheckUserTransByLoginID.accuracy_rate -
                        reportSkillCheckUserTransBySkillCheckCode.accuracy_rate
                    ) * 100
                  )}
                </p>
                <small>%</small>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="item-column">
                {Number(
                  reportSkillCheckUserTransByLoginID.wrong_rate -
                    reportSkillCheckUserTransBySkillCheckCode.wrong_rate
                ) > 0 ? (
                  <ArrowDownOutlined className="icon-down" />
                ) : (
                  <ArrowUpOutlined className="icon-up" />
                )}
                <p className="label-number">
                  {formatNumber(
                    Math.abs(
                      reportSkillCheckUserTransByLoginID.wrong_rate -
                        reportSkillCheckUserTransBySkillCheckCode.wrong_rate
                    ) * 100
                  )}
                </p>
                <small>%</small>
              </div>
            </Col>
            <Col className="gutter-row" span={6}>
              <div className="item-column">
                {Number(
                  reportSkillCheckUserTransByLoginID.inexperienced_rate -
                    reportSkillCheckUserTransBySkillCheckCode.inexperienced_rate
                ) > 0 ? (
                  <ArrowDownOutlined className="icon-down" />
                ) : (
                  <ArrowUpOutlined className="icon-up" />
                )}
                <p className="label-number">
                  {formatNumber(
                    Math.abs(
                      reportSkillCheckUserTransByLoginID.inexperienced_rate -
                        reportSkillCheckUserTransBySkillCheckCode.inexperienced_rate
                    ) * 100
                  )}
                </p>
                <small>%</small>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      {exportPdf && (
        <div className="table-header">
          <div className="text-total-record-table">
            {detailOfSkillCheckResults.length} <span className="text-static"> 件表示</span> /
            {detailOfSkillCheckResults.length}
            <span className="text-static"> 件</span>
          </div>
        </div>
      )}

      <Table
        rowKey="index"
        className="table"
        dataSource={detailOfSkillCheckResults.map((item, index) => ({ ...item, index }))}
        columns={columns}
        bordered
        rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
        locale={{
          emptyText: <p className="custom-empty-text">集計条件を選択してください</p>,
        }}
        pagination={
          !exportPdf
            ? {
                pageSize: pageSize,
                showSizeChanger: true,
                total: detailOfSkillCheckResults.length,
                current: page,
                onChange: (pageChange: number, pageSizeChange: number) => {
                  setPage(pageChange);
                  setPageSize(pageSizeChange);
                },
                position: ['topLeft'],
                locale: {
                  items_per_page: '',
                },
                showTotal: (total, range) => (
                  <div className="text-total-record-table">
                    {range[0]} - {range[1]} <span className="text-static"> </span>
                    {''} / {total}
                    <span className="text-static"> 件</span>
                  </div>
                ),
              }
            : false
        }
      />
      <QuestionDetail visible={modalQuestionDetail} setVisible={setModalQuestionDetail} />
    </IndividualTableStyled>
  );
};

export default IndividualTable;
