import styled, { createGlobalStyle } from 'styled-components';

import * as Types from 'types';

interface Props {
  nodeHeight?: number;
  node?: Types.TreeItem<Types.TrainingCurriculumItemType>;
  clientY?: number | string;
  isIndex?: number;
}

export const Wrapper = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.nodeHeight}px;

  .rst__node {
    user-select: none;
  }
  .icon-officical {
    border: 1px solid #6fd2e8;
    color: #029abb;
    background: #e8f7fc;
    font-size: 8px;
    padding: 2px;
    margin: 0;
    border-radius: 2px;
  }
  .rst__nodeContent {
    width: ${(props) =>
      !props.node || !props.node.parentNode || props.node?.columnIndex === 5 ? '100%' : '90.91%'};
    height: 80%;
    cursor: pointer;
  }

  .rst__lineBlock {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .rst__lineHalfHorizontalLeft::before,
  .rst__lineHalfHorizontalLeftVerticalTop::before,
  .rst__lineHalfHorizontalLeftParentNode::before,
  .rst__lineHalfVerticalTopParentNode::after,
  .rst__lineHalfVerticalTop::after {
    position: absolute;
    content: '';
  }

  .rst__lineHalfHorizontalLeftParentNode::before {
    height: 2px;
    top: 50%;
    right: 100%;
    width: 10%;
    background: repeating-linear-gradient(
      to right,
      #bbbbbb 0,
      #bbbbbb 3px,
      transparent 0,
      transparent 5px
    );
  }

  .rst__lineHalfHorizontalLeft::before {
    height: 2px;
    top: 50%;
    right: 100%;
    width: ${(props) => (props.node?.columnIndex === 5 ? 7 : 12)}%;
    background: repeating-linear-gradient(
      to right,
      #bbbbbb 0,
      #bbbbbb 3px,
      transparent 0,
      transparent 5px
    );
  }

  .rst__lineHalfHorizontalLeftVerticalTop::before {
    height: 2px;
    top: 50%;
    right: 100%;
    width: ${(props) => {
      if (props.node?.columnIndex === 5) {
        return 4;
      } else if (props.node?.columnIndex === 1) {
        return 10;
      } else {
        return 6;
      }
    }}%;
    background: repeating-linear-gradient(
      to right,
      #bbbbbb 0,
      #bbbbbb 3px,
      transparent 0,
      transparent 5px
    );
  }

  .rst__lineHalfVerticalTop::after {
    width: 2px;
    left: ${(props) => {
      if (props.node?.columnIndex === 5) {
        return -4;
      } else if (props.node?.columnIndex === 1) {
        return -10;
      } else {
        return -6;
      }
    }}%;
    bottom: 46%;
    height: ${(props) =>
      props.node ? (props.node.lineIndex! - (props.node.parentNode?.lineIndex || 1)) * 125 : 0}%;
    background: repeating-linear-gradient(
      to bottom,
      #bbbbbb 0,
      #bbbbbb 3px,
      transparent 0,
      transparent 5px
    );
  }

  .rst__lineHalfVerticalTopParentNode::after {
    width: 2px;
    left: -10%;
    bottom: 50%;
    height: 80%;
    background: repeating-linear-gradient(
      to bottom,
      #bbbbbb 0,
      #bbbbbb 3px,
      transparent 0,
      transparent 5px
    );
  }

  .rowWrapper {
    height: 100%;
  }

  .rowContentsDragDisabled {
    justify-content: flex-start !important;
  }

  .row {
    width: 100%;
    height: 100%;
    .rowContents {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: 100%;
      background: ${(props) => (props.node?.columnIndex === 5 ? '#F5FDFD' : '#ffffff')};
      border: ${(props) =>
        props.node?.columnIndex === 5
          ? props.node?.node!.correct_true === 1
            ? '1px solid rgba(123, 190, 191, 0.4)'
            : '1px solid #7BBEBF'
          : '1px solid #BBBBBB'};
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      padding: 0 10px;
      .icon-dragging {
        position: absolute;
        left: 10px;
      }
      .item-name {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rowTitle {
        width: 100%;
        display: block;
        font-weight: 400;
        font-size: ${(props) => (props.node?.columnIndex === 0 ? '14px' : '13px')};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tooltip {
        width: 90%;
      }
      .official {
        margin: 0 5px 0 5px;
        padding: 1px 3px;
        color: #0a9fbf;
        text-align: center;
        font-family: Lato;
        font-size: 8px;
        font-weight: 500;
        border-radius: 2px;
        border: 1px solid rgba(31, 185, 219, 0.6);
        background: rgba(31, 185, 219, 0.1);
      }
      .hover {
      }
      .group-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .count {
          font-size: 15px;
          color: #08a3a5;
        }
        .icon {
          color: #44a3d7;
        }
      }
      .item {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .rowTitle {
          width: 100%;
          color: ${(props) => (props.node?.columnIndex === 5 ? '#159193' : '#2a2a2a')};
        }
        .rowTitleNone {
          text-align: center;
          color: #999999;
        }
      }
      .itemcheck {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #2a2a2a;
        .icon {
          color: #44a3d7;
        }
      }
      .itemcircle {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #159193;
        .item-circle {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 1px solid #44a3d7;
        }
      }
      .itemclose {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #159193;
        .icon-close {
          font-size: 14px;
          color: #ec7c83;
        }
        .rowTitle {
          color: #159193;
        }
      }
    }
  }
`;

export default createGlobalStyle<Props>`
  body {
    .tooltip-group-${(props) => props.isIndex} {
      left: ${(props) => props.clientY}px !important;
    }
  }
`;
