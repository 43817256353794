import styled from 'styled-components';

export default styled.section`
  display: flex;
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f9f8f8;
  .background {
    position: absolute;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 100vh;
    max-width: 100vw;
  }
  .body-status {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .item {
    width: 50%;
    min-width: 800px;
    width: 800px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    z-index: 1;
    .item-image {
      display: flex;
      justify-content: center;
      max-height: 360px;
      border-radius: 2px 2px 0px 0px;
      background: #f4f4f4;
      .wrap-image {
        width: 640px;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e1dcdc;
        .image {
          max-width: 100%;
          height: auto;
          max-height: 360px;
          object-fit: contain;
        }
        .wrap-no-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text {
            color: #6f95aa;
            font-size: 22px;
            font-weight: 700;
          }
        }
      }
    }
    .title {
      font-size: 24px;
      text-align: center;
      color: #2a2a2a;
      padding: 35px 0 0 0;
    }
    .content {
      font-size: 16px;
      text-align: center;
      color: #777777;
      padding-bottom: 20px;
    }
    .btn {
      display: flex;
      justify-content: center;
      .btn-status {
        width: 220px;
        height: 52px;
        border: none;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 80px;
        cursor: pointer;
      }
    }
  }
`;
