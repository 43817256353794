import React, { useState, useEffect } from 'react';
import { FileTextOutlined, SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import { find, some } from 'lodash';
import dayjs from 'dayjs';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { DatePicker, Modal, SelectField } from 'components';
import { authSelector } from 'containers/Auth/selectors';
import { interviewUsersSelector } from '../../selectors';
import SkillCheckBatchSettingStyled from './styles';
import { IconLocked, IconPublish } from 'assets';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  deleteLinkUser,
  getDataBatchSetting,
  getDataSelectCheckUseStatus,
  getDataSelectSkillCheck,
  getDataSelectSkillCheckCategory,
  getDataSelectSkillCheckCreator,
  getDataSelectSkillCheckGroupingCode,
  getDataSelectSkillCheckStartPeriod,
  getDataSkillCheckBatch,
  getDataUserAssignSkillCheck,
  linkUser,
} from '../../thunk';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleComplete: React.Dispatch<React.SetStateAction<boolean>>;
  selectBatchRow: Array<Types.BatchSettingType>;
}

const PER_PAGE = 10;

const { Option } = Select;
const { RangePicker } = DatePicker;

const SkillCheckModal: React.FC<Props> = ({
  visible,
  setVisible,
  setVisibleComplete,
  selectBatchRow,
}) => {
  const [visiblePopupConfirmDelete, setVisiblePopupConfirmDelete] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<Types.SkillCheck.ResponseType[]>([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number>(-1);
  const [page, setPage] = useState<number>(1);

  const { loading } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const {
    total,
    dataSkillCheckBatch,
    dataSelectSkillCheck,
    dataSelectSkillCheckGroupingCode,
    dataSelectSkillCheckCreator,
  } = useSelector(interviewUsersSelector);

  const dispatch = useAppDispatch();

  const handleToggleModal = () => {
    setVisible((prevState) => !prevState);
    setSelectedRow([]);
  };

  const handleSubmit = async () => {
    dispatch(startLoading());
    setVisible(false);
    await Promise.all(
      selectBatchRow.map(({ company_id, login_id, skill_check_codes }) => {
        Promise.all([
          selectedRow?.map(async ({ code }) => {
            if (!some(skill_check_codes, ['code', code])) {
              await dispatch(
                linkUser({
                  item: {
                    company_id: company_id,
                    login_id: login_id,
                    skill_check_code: code,
                    createdat: new Date(),
                    createdby: userInfo?.login_id,
                  },
                })
              );
              await dispatch(
                getDataBatchSetting({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  sort_fields: [
                    {
                      id: 'manage_code',
                      order: 'asc',
                    },
                  ],
                  page: page,
                  per_page: PER_PAGE,
                  include_item_ref: true,
                  include_lookups: true,
                  use_display_id: true,
                  omit_total_items: false,
                })
              );
              setVisibleComplete(true);
            }
          }),
        ]);
      })
    );
    setSelectedRow([]);
    dispatch(stopLoading());
  };

  const handleDeleteLinkUser = async () => {
    dispatch(startLoading());
    const resultAction = await dispatch(
      getDataUserAssignSkillCheck({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
          {
            id: 'login_id',
            search_value: [selectBatchRow[0].login_id],
            exact_match: true,
          },
        ],
        page: 1,
        per_page: PER_PAGE,
      })
    );
    if (getDataUserAssignSkillCheck.fulfilled.match(resultAction)) {
      await Promise.all(
        selectedRow.map((val) => {
          const item = find(resultAction.payload.items, (e) => e.skill_check_code === val.code);
          if (item) {
            return dispatch(
              deleteLinkUser({
                id: item.i_id,
              })
            );
          }
        })
      );

      await dispatch(
        getDataBatchSetting({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          sort_fields: [
            {
              id: 'manage_code',
              order: 'asc',
            },
          ],
          page: page,
          per_page: PER_PAGE,
          include_item_ref: true,
          include_lookups: true,
          use_display_id: true,
          omit_total_items: false,
        })
      );
    }
    dispatch(stopLoading());
  };

  const handleResetForm = async () => {
    formik.resetForm();
    dispatch(startLoading());
    await dispatch(
      getDataSkillCheckBatch({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        sort_fields: [
          {
            id: 'manage_code',
            order: 'asc',
          },
        ],
        page: page,
        per_page: PER_PAGE,
        include_item_ref: true,
        include_lookups: true,
        use_display_id: true,
      })
    );
    dispatch(stopLoading());
  };

  const columns: ColumnsType<Types.SkillCheck.ResponseType> = [
    {
      title: 'コード1',
      dataIndex: 'code',
      key: 'code',
      width: '5%',
      className: 'color-code',
    },
    {
      title: 'スキルチェック名',
      dataIndex: 'name',
      key: 'name',
      width: '16%',
    },
    {
      title: '説明',
      dataIndex: 'description',
      key: 'description',
      width: '5%',
      render: (text, _, index) => (
        <Tooltip
          trigger={['click']}
          title={<p style={{ color: '#000000', fontSize: 13 }}>{text}</p>}
          color="#ffffff"
          onVisibleChange={(v) =>
            setVisibleTooltip((prevState) => (v && prevState !== index ? index : -1))
          }
        >
          <FileTextOutlined
            className="icon"
            style={{ color: visibleTooltip === index ? '#00a3a5' : '#c4c4c4' }}
          />
        </Tooltip>
      ),
    },
    {
      title: '実施期間',
      dataIndex: 'start_period',
      key: 'start_period',
      width: '18%',
      className: 'date',
      render: (record, { end_period }) =>
        record &&
        `${dayjs(record).format('YYYY年MM月DD日 HH:mm')}〜${dayjs(end_period).format(
          'YYYY年MM月DD日 HH:mm'
        )}`,
    },
    {
      title: 'ユーザー種別',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '11%',
      render: (record: string) => (
        <div className="item-type">
          <div className={`type ${record === 'interview' ? 'exception' : ''}`}>
            <span>{record === 'interview' ? '面' : '社'} </span>
          </div>
          <span>{record === 'interview' ? '面接' : '社内'}</span>
        </div>
      ),
    },
    {
      title: '分析グループ',
      dataIndex: 'grouping_code',
      key: 'grouping_code',
      width: '10%',
    },
    {
      title: '設問数',
      dataIndex: 'probs_count',
      key: 'probs_count',
      width: '7%',
    },
    {
      title: '制限時間',
      dataIndex: 'question_time_limit',
      key: 'question_time_limit',
      width: '8%',
    },
    {
      title: '公開ステータス',
      key: 'use_status',
      dataIndex: 'use_status',
      width: '8%',
      render: (publish: string) => (
        <div className="type-icon">
          <img
            src={publish === '1' ? IconPublish : IconLocked}
            className="icon"
            alt="publish-icon"
          />
          <span>{publish === '1' ? '公開中' : '編集中'}</span>
        </div>
      ),
    },
    {
      title: '作成者',
      dataIndex: 'createdby',
      key: 'createdby',
      ellipsis: true,
      width: '12%',
    },
  ];

  const formik = useFormik<Partial<Types.SkillCheck.ResponseType>>({
    initialValues: {
      code: '',
      name: '',
      user_type: 'interview',
      grouping_code: '',
      use_status: 0,
      createdby: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values).forEach((key) => {
        const value = values[key as keyof typeof values];
        if (value) {
          conditions.push({
            id: key,
            search_value: [value.toString()],
          });
        }
      });
      await Promise.all([
        dispatch(
          getDataSkillCheckBatch({
            conditions,
            sort_fields: [
              {
                id: 'manage_code',
                order: 'asc',
              },
            ],
            page: page,
            per_page: PER_PAGE,
            include_item_ref: true,
            include_lookups: true,
            use_display_id: true,
          })
        ),
        dispatch(
          getDataUserAssignSkillCheck({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: PER_PAGE,
          })
        ),
      ]);
      dispatch(stopLoading());
    },
  });

  useEffect(() => {
    if (visible && userInfo) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getDataSkillCheckBatch({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: page,
              per_page: PER_PAGE,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheck({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckStartPeriod({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCategory({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckGroupingCode({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectCheckUseStatus({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataSelectSkillCheckCreator({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [page, dispatch, userInfo, visible]);

  return (
    <Modal
      visible={visible}
      title={<h3 className="title">スキルチェック設定追加</h3>}
      width={1300}
      okButton={{
        text: '追加',
        onClick: handleSubmit,
      }}
      othersButton={[
        {
          text: '削除',
          onClick: () => setVisiblePopupConfirmDelete(true),
          disabled: !selectedRow.length,
        },
      ]}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
      onCancel={handleToggleModal}
      cancelButton={{
        text: 'キャンセル',
        onClick: handleToggleModal,
      }}
    >
      <SkillCheckBatchSettingStyled dataSkillCheck={true}>
        <p className="sub-title">
          設定するスキルチェックを選択して、登録ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <Form.Item
                name="code"
                className="item"
                label={<span className="text-label">スキルチェックコード</span>}
              >
                <SelectField
                  name="code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ code }, index) => (
                    <Option value={code} key={index}>
                      {code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="name"
                className="item"
                label={<span className="text-label">スキルチェック名</span>}
              >
                <SelectField
                  name="name"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheck?.map(({ name }, index) => (
                    <Option value={name} key={index}>
                      {name}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="date-item"
                className="item"
                label={<span className="text-label">開始年月</span>}
              >
                <RangePicker
                  allowClear
                  name="date-item"
                  format="YYYY/MM/DD HH:mm"
                  placeholder={['集計開始日', '集計終了日']}
                  onChange={(dates) => {
                    if (dates) {
                      formik.setValues({
                        ...formik.values,
                        start_period: dates[0]?.toDate(),
                        end_period: dates[1]?.toDate(),
                      });
                    } else {
                      formik.setValues({
                        ...formik.values,
                        start_period: undefined,
                        end_period: undefined,
                      });
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="user_type"
                className="item"
                label={<span className="text-label">ユーザー種別</span>}
              >
                <SelectField
                  name="user_type"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option key={1} value="member">
                    <div className="text-user-type">
                      <div className="text-icon-type interview">社</div>
                      <span>社内</span>
                    </div>
                  </Option>
                  <Option key={2} value="interview">
                    <div className="text-user-type">
                      <div className="text-icon-type member">面</div>
                      <span>面接</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="grouping_code"
                className="item"
                label={<span className="text-label">分析グループ</span>}
              >
                <SelectField
                  name="grouping_code"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheckGroupingCode?.map(({ grouping_code }, index) => (
                    <Option key={index} value={grouping_code}>
                      {grouping_code}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <Form.Item
                name="use_status"
                className="item"
                label={<span className="text-label">公開ステータス</span>}
              >
                <SelectField
                  name="use_status"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  <Option value={1} key={1}>
                    <div className="type-icon">
                      <img src={IconPublish} className="icon" alt="publish-icon" />
                      <span>公開中</span>
                    </div>
                  </Option>
                  <Option value={0} key={0}>
                    <div className="type-icon">
                      <img src={IconLocked} className="icon" alt="publish-icon" />
                      <span>編集中</span>
                    </div>
                  </Option>
                </SelectField>
              </Form.Item>
              <Form.Item
                name="createdby"
                className="item"
                label={<span className="text-label">作成者</span>}
              >
                <SelectField
                  name="createdby"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="指定なし"
                >
                  {dataSelectSkillCheckCreator?.map(({ creator }, index) => (
                    <Option key={index} value={creator}>
                      {creator}
                    </Option>
                  ))}
                </SelectField>
              </Form.Item>
              <div className="wrap-search">
                <SubmitButton className="btn-search" loading={false}>
                  <SearchOutlined className="icon-search" />
                  検索
                </SubmitButton>
                <span className="label-reset" onClick={handleResetForm}>
                  リセット
                </span>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div className="text-count">
          {page * PER_PAGE > total ? total : page * PER_PAGE}
          <span className="text-static">件表示 </span> / {total}
          <span className="text-static">名</span>
        </div>
        <Table
          rowKey="i_id"
          className="table"
          loading={loading}
          dataSource={dataSkillCheckBatch.map((item, index) => ({ ...item, index }))}
          columns={columns}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRow.map(({ i_id }) => i_id),
            onChange: (_, data) => setSelectedRow(data),
          }}
          pagination={{
            pageSize: PER_PAGE,
            showSizeChanger: false,
            total,
            current: page,
            onChange: setPage,
            position: ['topRight'],
          }}
        />
        <ConfirmDeleteModal
          visible={visiblePopupConfirmDelete}
          title="スキルチェック設定解除"
          subTitle="指定したスキルチェックの設定を解除します。"
          description=""
          onSubmit={handleDeleteLinkUser}
          onCancel={() => {}}
          setVisible={setVisiblePopupConfirmDelete}
        />
      </SkillCheckBatchSettingStyled>
    </Modal>
  );
};

export default SkillCheckModal;
