import styled from 'styled-components';

import { MENU_COLLAPSED_WIDTH, MENU_WIDTH } from 'constant';

interface Props {
  collapsedMenu: boolean;
  isEmptyData: boolean;
}

export default styled.section<Props>`
  position: relative;
  background-color: #f9f8f8;
  height: 100%;
  width: 100%;
  padding: 30px;
  .text-note {
    font-size: 12px;
    color: #424242;
  }
  .border-line {
    border-top: 1px solid #dddddd;
    width: 40px;
    margin: 30px 0;
  }
  .form-search-pdf {
    display: flex;
    justify-content: flex-start;
    column-gap: 15px;
    .item {
      width: 17%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .text-count-general {
    margin: 12px 0 -50px 0;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: fit-content;
    z-index: 9;
    .text-static {
      font-size: 11px;
      padding-left: 3px;
    }
    .page-select {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 30px;
    }
  }
  .text-count-no-data {
    margin: 12px 0 20px 0;
  }
  .form-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .item {
      width: 17%;
    }
    .label-reset {
      font-size: 12px;
      cursor: pointer;
      text-decoration-line: underline;
      color: #00a3a5;
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .table,
  .table-pdf {
    padding-bottom: 95px;
    margin-top: 0px;
    .text-count {
      font-family: Lato;
      font-size: 14px;
      color: #424242;
    }
    .ant-table .ant-table-container::before {
      content: 'ALL';
      margin-left: 7px;
      margin-top: 9px;
      font-size: 11px;
    }
    .ant-table-thead .ant-checkbox-wrapper {
      padding-top: 10px;
    }
    .ant-table-thead {
      border-radius: 2px 2px 0 0;
      .ant-table-cell {
        background-color: #ebebeb;
        text-align: center;
        font-weight: 700;
        font-size: 12px;
        color: #2a2a2a;
        margin: 0 3px;
        &:before {
          display: none;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: Lato;
        font-size: 13px;
        text-align: center;
        color: #424242;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon {
        color: #c4c4c4;
        font-size: 20px;
        cursor: pointer;
      }
      & > tr.ant-table-row-selected > td {
        background-color: #fdf7ea;
      }
      .ant-table-cell.name {
        max-width: 180px;
      }
      .ant-table-cell.department {
        max-width: 100px;
      }
      .ant-table-cell.email {
        max-width: 200px;
      }
    }
  }
  .table-pdf {
    @extend .table;
    margin-top: ${(props) => (props.isEmptyData ? 0 : 64)}px;
  }
  .wrap-button {
    position: absolute;
    right: 0;
    margin: 12px 30px;
    z-index: 9;
    .btn {
      width: 160px;
      height: 40px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      margin-left: 10px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      .icon {
        margin-right: 12px;
        font-size: 18px;
      }
    }
    .disabled {
      background: #cccccc !important;
      color: #777777 !important;
      border-radius: 5px;
    }
    .ant-btn[disabled] {
      width: 100%;
      height: 100%;
      background: #cccccc !important;
      border-radius: 5px;
      color: #ffffff;
      font-weight: 700;
      .anticon {
        font-size: 18px;
      }
    }
  }
  .wrap-bottom {
    height: 95px;
    width: calc(100% - ${(props) => (props.collapsedMenu ? MENU_COLLAPSED_WIDTH : MENU_WIDTH)}px);
    transition: width 0.3s;
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
    .flex {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .text-label {
      font-size: 13px;
      color: #777777;
      display: flex;
      align-items: center;
    }
    .btn {
      cursor: pointer;
      font-size: 13px;
      font-weight: 700;
      width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 0;
    }
    .disabled {
      background: #cccccc !important;
      color: #777777 !important;
      border-radius: 5px;
    }
    .btn-active {
      background-color: #f6ac00;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
    }
    .ml-5 {
      margin-left: 5px;
    }
    .btn-outline {
      background-color: #ffffff;
      border: 1px solid #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #f1a900;
      .icon {
        color: #f6ac00;
        transform: rotate(-90deg);
      }
    }
  }
  @media (max-width: 1440px) {
    .table {
      .ant-table-tbody {
        .ant-table-cell.name {
          max-width: 100px;
        }
        .ant-table-cell.department {
          max-width: 40px;
        }
        .ant-table-cell.email {
          max-width: 130px;
        }
      }
    }
  }
`;

interface PopoverProps {
  visiblePopover: number;
}

export const PopoverStyled = styled.section<PopoverProps>`
  .icon {
    color: ${(props) => (props.visiblePopover ? '#00a3a5' : '#c4c4c4')};
    font-size: 18px !important;
    cursor: pointer;
    margin-right: 10px;
    z-index: 999999;
    &:hover {
      color: #00a3a5;
      font-size: 20px;
    }
  }
`;
