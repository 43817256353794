import React from 'react';

import { Document, Page, View, Text, Font, Svg, Path, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import dayjs from 'dayjs';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

const pageSize = 'A4';
const pageOrientation = 'landscape';

const pathUserOutline =
  'M40.4959 36.4028C39.5357 34.1284 38.1423 32.0624 36.3932 30.32C34.6495 28.5726 32.5839 27.1794 30.3104 26.2173C30.2901 26.2071 30.2697 26.202 30.2493 26.1919C33.4205 23.9013 35.4821 20.1701 35.4821 15.9605C35.4821 8.98693 29.8319 3.33679 22.8583 3.33679C15.8847 3.33679 10.2346 8.98693 10.2346 15.9605C10.2346 20.1701 12.2961 23.9013 15.4673 26.197C15.447 26.2071 15.4266 26.2122 15.4063 26.2224C13.1258 27.1845 11.0796 28.5639 9.32345 30.3251C7.57603 32.0689 6.18281 34.1345 5.22074 36.4079C4.27559 38.6336 3.76586 41.0199 3.71912 43.4375C3.71776 43.4918 3.72729 43.5459 3.74715 43.5965C3.767 43.6471 3.79678 43.6932 3.83473 43.7321C3.87268 43.771 3.91803 43.8019 3.96812 43.823C4.0182 43.8441 4.07199 43.8549 4.12634 43.8549H7.18047C7.40444 43.8549 7.5826 43.6768 7.58769 43.4579C7.68949 39.5282 9.26746 35.848 12.0569 33.0586C14.943 30.1724 18.776 28.5843 22.8583 28.5843C26.9407 28.5843 30.7736 30.1724 33.6598 33.0586C36.4492 35.848 38.0272 39.5282 38.129 43.4579C38.1341 43.6818 38.3122 43.8549 38.5362 43.8549H41.5903C41.6447 43.8549 41.6985 43.8441 41.7486 43.823C41.7986 43.8019 41.844 43.771 41.8819 43.7321C41.9199 43.6932 41.9497 43.6471 41.9695 43.5965C41.9894 43.5459 41.9989 43.4918 41.9976 43.4375C41.9466 41.0044 41.4427 38.6374 40.4959 36.4028ZM22.8583 24.7157C20.5219 24.7157 18.323 23.8046 16.6686 22.1502C15.0143 20.4959 14.1032 18.2969 14.1032 15.9605C14.1032 13.6241 15.0143 11.4251 16.6686 9.77082C18.323 8.1165 20.5219 7.20536 22.8583 7.20536C25.1947 7.20536 27.3937 8.1165 29.048 9.77082C30.7024 11.4251 31.6135 13.6241 31.6135 15.9605C31.6135 18.2969 30.7024 20.4959 29.048 22.1502C27.3937 23.8046 25.1947 24.7157 22.8583 24.7157Z';

const PDFSingleEmployeeDocument = ({
  employee,
}: {
  employee: Types.EmployeeUser.EmployeeExportPDF;
}) => {
  return (
    <Document>
      <Page size={pageSize} orientation={pageOrientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>社内ユーザーマスタ</Text>
        </View>
        <View style={styles.body}>
          <Card title="基本情報">
            <View style={styles.wrapBasicInfo}>
              <View style={styles.wrapAvatar}>
                <View style={styles.avatar}>
                  {employee.srcAvatar ? (
                    <Image src={employee.srcAvatar} style={styles.avatarImage} />
                  ) : (
                    <>
                      <Svg width={46} height={47} viewBox="0 0 46 47">
                        <Path d={pathUserOutline} fill="#CCCCCC" />
                      </Svg>
                      <Text style={styles.textUser}>プロフィール画面で設定した</Text>
                      <Text style={styles.textUser}>画像が反映されます</Text>
                    </>
                  )}
                </View>
              </View>

              <View style={styles.formBasicInfo}>
                <Input required textLabel="社員番号" value={employee.employee_code} />
                <Input required textLabel="氏名" value={employee.name} />
                <Input required textLabel="氏名（フリガナ）" value={employee.kana} />
                <Input
                  required
                  textLabel="メールアドレス"
                  value={employee.login_id}
                  styleInput={styles.inputMail}
                />
              </View>

              <View style={styles.formBasicInfo}>
                <View style={styles.wrapDate}>
                  <Input
                    textLabel="生年月日"
                    value={dayjs(employee.birthday).year().toString()}
                    width={70}
                  />
                  <Text style={styles.textDate}>年</Text>

                  <Input
                    value={(dayjs(employee.birthday).month() + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    width={46}
                  />
                  <Text style={styles.textDate}>月</Text>

                  <Input
                    value={dayjs(employee.birthday).date().toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    width={46}
                  />
                  <Text style={styles.textDate}>日</Text>
                </View>

                <View style={styles.wrapDate}>
                  <Input
                    textLabel="入社年月"
                    value={dayjs(employee.hire_date).year().toString()}
                    width={70}
                  />
                  <Text style={styles.textDate}>年</Text>

                  <Input
                    value={(dayjs(employee.hire_date).month() + 1).toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                      useGrouping: false,
                    })}
                    width={46}
                  />
                  <Text style={styles.textDate}>月</Text>
                </View>
                <Input required textLabel="権限" value={employee.role_code} />
              </View>
            </View>
          </Card>

          <Card title="所属・役職情報">
            <Text style={styles.textDescription}>
              所属・役職情報を設定・編集することができます。兼務枠を増やす場合は兼務所属・役職を追加するボタンをクリックしてください。
            </Text>

            <Text style={[styles.textLabel, { fontWeight: 'bold' }]}>メイン所属・役職</Text>
            <View style={styles.wrapPosition}>
              <View style={styles.position}>
                <Input
                  required
                  textLabel="メイン所属"
                  value={employee.main_position.affiliation_name}
                />
                <Input
                  required
                  textLabel="メイン役職"
                  value={employee.main_position.position_name}
                />
              </View>
            </View>

            {employee.sub_position.length && (
              <>
                <Text style={[styles.textLabel, { fontWeight: 'bold' }]}>兼務所属・役職</Text>
                <View style={styles.wrapPosition}>
                  {employee.sub_position.map((position, index) => (
                    <View key={index} style={styles.position}>
                      <Input textLabel={`兼務所属${index + 1}`} value={position.affiliation_name} />
                      <Input textLabel={`兼務役職${index + 1}`} value={position.position_name} />
                    </View>
                  ))}
                </View>
              </>
            )}
          </Card>

          <Card title="必修カリキュラム設定">
            <Text style={styles.textDescriptionResults}>
              必修カリキュラムの設定枠を増やす場合は、必修カリキュラムを追加するボタンをクリックしてください。追加後、更新すると確定します。
            </Text>
            <Text style={styles.textDescriptionResults}>
              カリキュラムの進捗が100％になると、非表示リストに追加されます。
            </Text>

            {employee.curriculum.length && (
              <View style={styles.wrapResults}>
                <View style={styles.wrapLabelResults}>
                  <Text style={styles.textLabelResults}>設定の古い順に表示</Text>
                </View>
                {employee.curriculum.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.result,
                      {
                        borderBottom:
                          index !== employee.curriculum.length - 1 ? 'none' : '1px solid #d9d9d9',
                      },
                    ]}
                  >
                    <Text style={styles.textCountResult}>{(index + 1).toString()}</Text>
                    <Text style={styles.textResult}>{item.curriculum_name}</Text>
                  </View>
                ))}
              </View>
            )}
          </Card>

          <Card title="スキルチェック設定状況">
            <Text style={styles.textDescriptionResults}>
              スキルチェックを設定枠を増やす場合は、スキルチェックを追加ボタンをクリックしてください。追加後、更新すると確定します。
            </Text>
            <Text style={styles.textDescriptionResults}>
              スキルチェックの実施後は、非表示リストに追加されます。
            </Text>

            {employee.skillcheck.length && (
              <View style={styles.wrapResults}>
                <View style={styles.wrapLabelResults}>
                  <Text style={styles.textLabelResults}>設定の古い順に表示</Text>
                </View>
                {employee.skillcheck.map((item, index) => (
                  <View
                    key={index}
                    style={[
                      styles.result,
                      {
                        borderBottom:
                          index !== employee.skillcheck.length - 1 ? 'none' : '1px solid #d9d9d9',
                      },
                    ]}
                  >
                    <Text style={styles.textCountResult}>{(index + 1).toString()}</Text>
                    <Text style={styles.textResult}>{item.skillcheck_name}</Text>
                  </View>
                ))}
              </View>
            )}
          </Card>
        </View>
      </Page>
    </Document>
  );
};

export default PDFSingleEmployeeDocument;

const Card = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <View style={styles.wrapCard}>
    <View style={styles.headerCard}>
      <Text style={styles.textHeaderCard}>{title}</Text>
    </View>
    <View style={styles.wrapContentCard}>{children}</View>
  </View>
);

const Input = ({
  styleInput,
  textLabel,
  required,
  value,
  width,
}: {
  textLabel?: string;
  required?: boolean;
  styleInput?: Style;
  width?: number;
  value: string;
}) => (
  <View style={width ? [styles.wrapInput, { width: width }] : styles.wrapInput}>
    {textLabel && (
      <View style={styles.wrapLabel}>
        <Text style={styles.textLabel}>{textLabel}</Text>
      </View>
    )}

    <View style={styleInput ? [styles.input, styleInput] : styles.input}>
      <Text style={styles.value}>{value}</Text>
    </View>
  </View>
);
