import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Progress, Row } from 'antd';

import { dashboardSelector } from 'pages/Dashboard/selectors';
import { UserType } from 'types/config';
import { Wrapper } from './styles';

interface Props {
  role?: UserType;
}

const CurriculumNotAchieved: React.FC<Props> = ({ role }) => {
  const { curriculum } = useSelector(dashboardSelector);

  return (
    <Wrapper>
      <Row className="header pd-10">
        <ExclamationCircleOutlined className="icon" />
        <span className="title">必修カリキュラム実施状況</span>
        <span className="title-sub">（全問正解人数）</span>
      </Row>
      <div className="wrap-items">
        {curriculum.map((value, index) => (
          <Row key={index} className="item pd-10">
            <span className="title">{value.curriculum_name}</span>
            <div className="wrap-progress">
              <Progress
                showInfo={false}
                percent={(value.users / value.total) * 100}
                strokeColor={{ '0%': '#E8B088', '100%': '#F58F55' }}
              />
              <span className="label">
                {value.users}
                <small className="small">/{value.total}人</small>
              </span>
            </div>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default CurriculumNotAchieved;
