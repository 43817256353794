import React, { useEffect, useMemo, useState } from 'react';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { informationRegisterSelector } from 'containers/InformationRegister/selectors';
import { getDataCompany } from 'pages/NewSkillcheckSelection/thunk';
import { getPlans } from 'containers/InformationRegister/thunk';
import { authSelector } from 'containers/Auth/selectors';
import { fiveStepSchema } from 'libs/validations';
import { FreeTrialStyled } from './styles';
import ModalDashboard from '../FreeTrial';
import { useAppDispatch } from 'hooks';

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalOfficial: React.FC<Props> = ({ visible, setVisible }) => {
  const [showOfficial, setShowOfficial] = useState<boolean>(false);
  const { creditCard, plans } = useSelector(informationRegisterSelector);
  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const { company_id } = useParams<{ company_id: string; admin_email: string }>();

  const planPremium = useMemo(() => {
    return plans.find((plan) => plan.plan_name === 'premium');
  }, [plans]);

  const handleCloseModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (!userInfo || company_id) return;
    Promise.all([
      dispatch(
        getDataCompany({
          conditions: [
            {
              id: 'admin_email',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 1,
        })
      ),
      dispatch(
        getPlans({
          page: 1,
          per_page: 0,
        })
      ),
    ]);
  }, []);

  const formik = useFormik({
    initialValues: creditCard,
    validationSchema: fiveStepSchema,
    validateOnBlur: false,
    onSubmit: () => {
      setShowOfficial(true);
    },
  });

  return (
    <FreeTrialStyled open={visible} onCancel={handleCloseModal}>
      <FormikProvider value={formik}>
        <div className="title-header">
          <div className="title">SKILL FAMILIAR 本登録ページ</div>
          <div className="text-center">
            申込内容をご確認いただき、登録開始ボタンをクリックしてください。
          </div>
        </div>
        <Form
          layout="vertical"
          labelCol={{
            flex: '22%',
          }}
          colon={false}
          className="form-style"
        >
          <div className="form">
            <Form.Item
              name="name"
              label={<span className="text-label">料金プラン</span>}
              className="form-input"
            >
              <div className="step-top">
                <div className="step-title">
                  <div className="gourd-left">
                    <div className="form-step">
                      <p className="title-gourd">基本利用料</p>
                      <div className="title">
                        <div className="title-left">
                          <p className="name">ユーザー利用料</p>
                          <p className="title-name-border">1ユーザー</p>
                          <p className="title-border">
                            {planPremium?.user_monthly_fee}
                            <span className="month">円</span>
                          </p>
                          <div>
                            <p>
                              ・SKILL FAMILIARのすべての
                              <br /> 機能が利用できます。
                            </p>
                            <p>
                              ・社内ユーザーの1か月の最大 <br /> 登録人数が対象となります。
                            </p>
                          </div>
                        </div>
                        <div className="total">+</div>
                        <div className="title-left title-left-center">
                          <p className="name">ストレージ利用料</p>
                          <p className="title-name-border">1企業50GBにつき</p>
                          <p className="title-border">
                            {planPremium?.usage_storage_unit_price}
                            <span className="month">円</span>
                          </p>
                          <div>
                            <p>
                              ・ストレージ利用量50GBごとに
                              <br /> 自動課金されます。
                            </p>
                            <p>
                              ・ストレージ利用量はいつでも <br /> 確認できます。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="total total-right">+</div>
                  <div className="gourd-left">
                    <div className="form-step">
                      <p className="title-gourd">オプション</p>
                      <div className="title">
                        <div className="title-left">
                          <p className="name">面接スキルチェック利用料</p>
                          <p className="title-name-border">1回実施につき</p>
                          <p className="title-border">
                            {planPremium?.skill_check_unit_price}
                            <span className="month">円</span>
                          </p>
                          <div>
                            <p>
                              ・面接スキルチェック1回実施 <br /> に対してカウントされます。
                            </p>
                            <p>
                              ・面接ユーザーは月額利用料に <br /> カウントされません。
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="bottom">
                  ※ご利用月の「基本利用料＋オプション」の合計金額を毎月ご請求させていただきます。
                </p>
              </div>
            </Form.Item>
            <Form.Item
              name="name_furigana"
              label={<span className="text-label">支払方法</span>}
              className="form-input"
            >
              <div className="input-review">
                クレジットカード、銀行振込
                <br />
                ※基本的にはクレジットカードでのお支払い。銀行振込には条件がございますので、お問い合わせください。
              </div>
            </Form.Item>
            <Form.Item
              name="building_name"
              label={<span className="text-label">支払の時期</span>}
              className="form-input"
            >
              <div className="input-review">
                クレジットカード：代金引き落としの時期は、クレジットカード会社によって異なります。
                <br />
                ご利用のクレジットカード会社にお問い合わせをお願いいたします。
                <br />
                銀行振込：ご利用月の翌月末までにその月の代金のお振込みをお願いしております。
              </div>
            </Form.Item>
            <Form.Item
              name="admin_name"
              label={<span className="text-label">ご利用開始</span>}
              className="form-input"
            >
              <div className="input-review">ご登録完了後すぐにご利用いただけます。</div>
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={<span className="text-label">キャンセル・解約について</span>}
              className="form-input"
            >
              <div className="input-review">
                製品の特性上、利用開始後のキャンセルはできません。
                <br />
                解約はいつでもお手続きが可能です。詳細は利用規約をご確認ください。
              </div>
            </Form.Item>
            <Form.Item
              name="admin_name_furigana"
              label={<span className="text-label">30日間無料トライアル データ引継ぎについて</span>}
              className="form-input"
            >
              <div className="input-review review-footer">
                <span className="review">データ引継ぎ：あり</span>
                <span>
                  データ引継ぎ期間中のため30日間無料トライアルのデータを引き継いでご利用できます。
                </span>
              </div>
            </Form.Item>
          </div>
          <div className="wrap-button">
            <SubmitButton className="btn-submit">登録開始</SubmitButton>
          </div>
        </Form>
      </FormikProvider>
      <ModalDashboard visible={showOfficial} setVisible={setShowOfficial} />
    </FreeTrialStyled>
  );
};

export default ModalOfficial;
