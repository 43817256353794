import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  SELECT_ANALYSIS_GROUP,
  SELECT_EMPLOYEE_USER,
  SELECT_INTERVIEW_USER,
  SELECT_SKILL_CHECK,
  SELECT_TYPES,
} from 'configs';

export const getSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReportPage/thunk/getSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getInterviewUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectInterviewUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReportPage/thunk/getInterviewUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectInterviewUser.ResponseType>(
      SELECT_INTERVIEW_USER.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getEmployeeUser = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectEmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReportPage/thunk/getEmployeeUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectEmployeeUser.ResponseType>(
      SELECT_EMPLOYEE_USER.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSelectAnalysisGroup = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectAnalysisGroup.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReportPage/thunk/getSelectAnalysisGroup', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectAnalysisGroup.ResponseType>(
      SELECT_ANALYSIS_GROUP.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getSelectTypes = createAsyncThunk<
  Types.GetItemResponseType<Types.SelectTypes.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('skillCheck/thunk/getSelectTypes', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SelectTypes.ResponseType>(SELECT_TYPES.id, {
      ...req,
      sort_fields: [{ id: 'code', order: 'asc' }],
    });

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
