import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .wrap-subtitle {
    margin-top: 12px;
    margin-bottom: 37px;
    display: flex;
    align-items: center;
  }
  .subtitle {
    font-size: 15px;
    color: #2a2a2a;
  }
  .description {
    color: #424242;
    font-size: 13px;
  }
  .icon {
    font-size: 28px;
    color: #bbbbbb;
    margin-right: 8px;
  }
`;
