import React from 'react';

import BreakdownAnswersPerCentage from './BreakdownAnswersPerCentage';
import BreakdownAnswers from './BreakdownAnswers';
import TableChart from './TableChart';
import {
  ITEM_COMPARE_BAR_CHART,
  ITEM_COMPARE_PIE_CHART,
  ITEM_GRAPH,
} from 'constant/select.constants';

interface Props {
  itemComparision?:
    | (typeof ITEM_COMPARE_BAR_CHART)[number]['value']
    | (typeof ITEM_COMPARE_PIE_CHART)[number]['value'];
  itemGraph?: (typeof ITEM_GRAPH)[number]['value'];
  isUserTransTable: boolean;
  exportPdf?: boolean;
}

const Chart: React.FC<Props> = ({ itemComparision, itemGraph, isUserTransTable, exportPdf }) => {
  return itemGraph === 'bar_chart' ? (
    <TableChart isUserTransTable={isUserTransTable} itemComparision={itemComparision} />
  ) : itemComparision === 'number_of_solutions' ? (
    <BreakdownAnswers isUserTransTable={isUserTransTable} exportPdf={exportPdf} />
  ) : (
    <BreakdownAnswersPerCentage isUserTransTable={isUserTransTable} exportPdf={exportPdf} />
  );
};

export default Chart;
