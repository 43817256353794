import styled from 'styled-components';

export const SectionStyled = styled.div`
  width: 100%;
  margin-top: 16px;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    padding: 6px 0;
    .dataTitle {
      flex: 1;
      text-align: center;
    }
  }

  .ant-table-cell {
    .rowNumber {
      display: flex;
      justify-content: space-between;
      .rowIndex {
        flex: 1;
        text-align: right;
      }
    }
  }

  .empty-error {
    color: red;
  }

  .cell-error {
    background-color: rgba(217, 155, 255, 0.4) !important;
  }
`;
