import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row, Select, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useSelector } from 'react-redux';
import saveAs from 'file-saver';
import dayjs from 'dayjs';

import {
  CloudDownloadOutlined,
  ArrowDownOutlined,
  FileTextOutlined,
  ArrowUpOutlined,
  RightOutlined,
} from '@ant-design/icons';

import { HEADER_MY_CHART_DASH_BROARD_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { formatNumber, formatNumberToMinute } from 'libs/utils/format';
import QuestionDetail from 'pages/Reports/SkillCheck/QuestionDetail';
import { myChartDashboardSelector } from '../../selectors';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import RecordSkillCheckStyled from './styles';
import exportPDF from 'libs/utils/exportPdf';
import FilePDFExport from './FilePDFExport';
import { ConfirmExport } from '../../Modal';
import * as Types from 'types';
import {
  getDataSelectSkillCheckQuestion,
  getDataDetailSkillCheckResult,
  getDataReportSkillCheckAll,
  getDataSelectSkillCheck,
  getDataSkillCheckUser,
} from '../../thunk';

const { Option } = Select;

interface Props {
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
}

const RecordSkillCheck: React.FC<Props> = ({ setTabIndex }) => {
  const [valueSelectQuestion, setValueSelectQuestion] = useState<string>('');
  const [valueSelectName, setValueSelectName] = useState<string>();
  const [valueCorrect, setValueCorrect] = useState<string>();
  const [valueScore, setValueScore] = useState<string>();
  const [modalQuestionDetail, setModalQuestionDetail] = useState<{
    visible: boolean;
    question_id?: string;
  }>({
    visible: false,
  });
  const [visiblePopupConfirmExportFile, setVisiblePopupConfirmExportFile] =
    useState<boolean>(false);

  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const {
    dataSelectSkillCheckQuestion,
    totalDetailSkillCheckResult,
    dataDetailSkillCheckResult,
    dataSelectSkillCheck,
    dataTabUser,
    dataTabAll,
  } = useSelector(myChartDashboardSelector);

  const columns: ColumnsType<Types.DetailOfSkillCheckResults.ResponseType> = [
    {
      title: 'No.',
      align: 'center',
      width: '1%',
      render: (_, _record, index) => index + 1,
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '-',
              className: 'average br-1',
              align: 'center',
              width: '1%',
              render: (_, _record, index) => index + 1,
            },
          ]
        : undefined,
    },
    {
      title: '設問',
      className: 'question',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '23%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '合計',
              className: 'average br-1',
              key: 'name',
              dataIndex: 'name',
              width: '23%',
              align: 'left',
            },
          ]
        : undefined,
    },
    {
      title: '詳細',
      dataIndex: 'question_id',
      key: 'question_id',
      width: '5%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'question_id',
              dataIndex: 'question_id',
              width: '5%',
              render: (question_id) => (
                <FileTextOutlined
                  className="icon"
                  onClick={() =>
                    setModalQuestionDetail({
                      visible: true,
                      question_id,
                    })
                  }
                />
              ),
            },
          ]
        : undefined,
    },
    {
      title: '正答',
      dataIndex: 'answer',
      key: 'answer',
      width: '5%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'answer',
              dataIndex: 'answer',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '解答',
      dataIndex: 'user_answer',
      key: 'user_answer',
      width: '5%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'user_answer',
              dataIndex: 'user_answer',
              width: '5%',
            },
          ]
        : undefined,
    },
    {
      title: '解答結果',
      dataIndex: 'correct',
      key: 'correct',
      width: '8%',
      render: (value) => (value === 1 ? '正解' : value === -1 ? '未経験' : '不正解'),
      children: totalDetailSkillCheckResult
        ? [
            {
              title: '-',
              className: 'average br-1',
              key: 'correct',
              dataIndex: 'correct',
              width: '8%',
              render: (value) => (value === 1 ? '正解' : value === -1 ? '未経験' : '不正解'),
            },
          ]
        : undefined,
    },
    {
      title: '解答時間',
      dataIndex: 'answer_time',
      key: 'answer_time',
      width: '8%',
      render: (value) => formatNumberToMinute(value),
      children: totalDetailSkillCheckResult
        ? [
            {
              title: formatNumberToMinute(dataDetailSkillCheckResult[0].answer_time),
              className: 'average br-1',
              key: 'answer_time',
              dataIndex: 'answer_time',
              width: '8%',
              render: (value) => formatNumberToMinute(value),
            },
          ]
        : undefined,
    },
    {
      title: 'ユーザー平均解答時間',
      dataIndex: 'average_answer_time',
      key: 'average_answer_time',
      width: '14%',
      render: (value) => formatNumberToMinute(value),
      children: totalDetailSkillCheckResult
        ? [
            {
              title: formatNumberToMinute(dataDetailSkillCheckResult[0].average_answer_time || 0),
              className: 'average br-1',
              key: 'average_answer_time',
              dataIndex: 'average_answer_time',
              width: '14%',
              render: (value) => formatNumberToMinute(value),
            },
          ]
        : undefined,
    },
    {
      title: 'ユーザー平均正解率',
      dataIndex: 'accuracy_rate',
      key: 'accuracy_rate',
      width: '13%',
      render: (value) => (
        <div className="item-rate">
          <p className="label-number">{formatNumber(value * 100)}</p>
          <small>%</small>
        </div>
      ),
      children: totalDetailSkillCheckResult
        ? [
            {
              title: (
                <span>
                  {formatNumber((dataDetailSkillCheckResult[0].accuracy_rate || 0) * 100)}
                  <small style={{ color: '#999999' }}>%</small>
                </span>
              ),
              className: 'average br-1',
              key: 'accuracy_rate',
              dataIndex: 'accuracy_rate',
              width: '13%',
              render: (value) => (
                <div className="item-rate">
                  <p className="label-number">{formatNumber(value * 100)}</p>
                  <small>%</small>
                </div>
              ),
            },
          ]
        : undefined,
    },
    {
      title: '設定スコア',
      dataIndex: 'score',
      key: 'score',
      width: '9%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: formatNumber(dataDetailSkillCheckResult[0].score),
              className: 'average br-1',
              key: 'score',
              dataIndex: 'score',
              width: '9%',
            },
          ]
        : undefined,
    },
    {
      title: '獲得スコア',
      dataIndex: 'acquisition_score',
      key: 'acquisition_score',
      width: '11%',
      children: totalDetailSkillCheckResult
        ? [
            {
              title: formatNumber(dataDetailSkillCheckResult[0].acquisition_score),
              className: 'average',
              key: 'acquisition_score',
              dataIndex: 'acquisition_score',
              width: '11%',
            },
          ]
        : undefined,
    },
  ];

  const handleButtonExport = () => {
    setVisiblePopupConfirmExportFile(true);
  };

  const handleExportCSV = (value: string) => {
    if (value === 'csv') {
      const listCsv = dataDetailSkillCheckResult?.map((item) => ({
        name: item.name,
        answer: item.answer,
        user_answer: item.user_answer,
        accuracy_rate: item.accuracy_rate || 0,
        answer_time: item.answer_time,
        average_answer_time: item.average_answer_time,
        correct: item.correct,
        score: item.score,
        acquisition_score: item.acquisition_score,
      }));

      const csvString = [
        HEADER_MY_CHART_DASH_BROARD_CSV.map(({ label }) => label),
        ...listCsv.map((item) => Object.values(item)),
      ]
        .map((e) => e.join(','))
        .join('\n');
      const bom = '\uFEFF';
      const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
      saveAs(file, 'record_skill_check_export.csv');
    } else {
      if (!ref.current) return;

      exportPDF(ref, 'MYカルテ.pdf');
    }
    setVisiblePopupConfirmExportFile(false);
  };

  const handleChange = useCallback(
    async (value: string) => {
      setValueSelectName(value);
      if (!userInfo) return;
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSkillCheckUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'skill_check_code',
                search_value: [value],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataReportSkillCheckAll({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'skill_check_code',
                search_value: [value],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);

      dispatch(stopLoading());
    },
    [dispatch, userInfo]
  );

  const handleChangeQuestion = (value: string) => {
    setValueSelectQuestion(value);
  };

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <FilePDFExport
          valueSelectQuestion={valueSelectQuestion}
          valueSelectName={valueSelectName}
          valueCorrect={valueCorrect}
          valueScore={valueScore}
        />
      </div>
    );
  }, [valueSelectQuestion, valueSelectName, valueCorrect, valueScore]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataSelectSkillCheck({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSelectSkillCheckQuestion({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'skill_check_code',
                search_value: [valueSelectName || dataSelectSkillCheck[0]?.code],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataSkillCheckUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo.login_id],
                exact_match: true,
              },
              {
                id: 'skill_check_code',
                search_value: [valueSelectName || dataSelectSkillCheck[0]?.code],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
        dispatch(
          getDataReportSkillCheckAll({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
              {
                id: 'skill_check_code',
                search_value: [valueSelectName || dataSelectSkillCheck[0]?.code],
              },
            ],
            page: 1,
            per_page: 0,
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dataSelectSkillCheck, dispatch, userInfo, valueSelectName]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getDataDetailSkillCheckResult({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            {
              id: 'login_id',
              search_value: [userInfo.login_id],
              exact_match: true,
            },
            {
              id: 'skill_check_code',
              search_value: [valueSelectName || dataSelectSkillCheck[0]?.code],
            },
            ...(valueSelectQuestion
              ? [
                  {
                    id: 'code',
                    search_value: [valueSelectQuestion],
                  },
                ]
              : []),
            ...(valueCorrect
              ? [
                  {
                    id: 'correct',
                    search_value: [valueCorrect],
                    exact_match: true,
                  },
                ]
              : []),
            ...(valueScore
              ? [
                  {
                    id: 'score',
                    search_value: [valueScore],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    })();
  }, [
    dataSelectSkillCheck,
    dispatch,
    userInfo,
    valueCorrect,
    valueScore,
    valueSelectName,
    valueSelectQuestion,
  ]);

  return (
    <RecordSkillCheckStyled>
      {component}
      <div className="skill-check-results">
        <p className="title">スキルチェック実施結果詳細</p>
        <div className="group-btn">
          <button className="btn btn-result" onClick={() => setTabIndex(0)}>
            ダッシュボード
            <RightOutlined className="icon-right" />
          </button>
          <Button className=" btn btn-active" onClick={handleButtonExport}>
            <CloudDownloadOutlined className="icon-cloud" />
            エクスポート
          </Button>
        </div>
      </div>
      <div className="wrap-body">
        <div className="group-select">
          <div className="select skill-check">
            <p className="label">スキルチェック選択</p>
            <Select
              value={valueSelectName || dataSelectSkillCheck[0]?.code}
              onChange={handleChange}
              className="item-select"
              allowClear
            >
              {dataSelectSkillCheck?.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select question">
            <p className="label">設問</p>
            <Select
              className="item-select"
              placeholder="指定なし"
              onChange={handleChangeQuestion}
              allowClear
            >
              {dataSelectSkillCheckQuestion?.map((item, index) => (
                <Option value={item.question_code} key={index}>
                  {item.question_name}
                </Option>
              ))}
            </Select>
          </div>
          <div className="select answer-result">
            <p className="label">解答結果</p>
            <Select onChange={(value) => setValueCorrect(value)} className="item-select" allowClear>
              <Option value="0">不正解</Option>
              <Option value="1">正解</Option>
            </Select>
          </div>
          <div className="select score">
            <p className="label">スコア（設問難易度）</p>
            <Select onChange={(value) => setValueScore(value)} className="item-select" allowClear>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </div>
        </div>

        <div className="skill-check-info">
          <div className="title-info">
            <p>スキルチェック情報</p>
          </div>
          <div className="wrap-info">
            <p>制限時間：{dataTabUser.question_time_limit || 0}分</p>
            <p>/</p>
            <p>設問数：{dataTabUser.probs_count || 0}問</p>
            <p>/</p>
            <p>設定スコア：{dataTabUser.setting_score || 0}</p>
            <p>/</p>
            <p>
              実施日：
              {dataTabUser.implementation_date &&
                dayjs(dataTabUser.implementation_date).format('YYYY年MM月DD日')}
            </p>
            {}
          </div>
        </div>
        <Row className="table-result" gutter={[5, 5]}>
          <Col className="item-table" span={8}>
            <p className="table-label">実施結果サマリ</p>
            <Row className="row-label">
              <Col className="gutter-row" span={6}>
                獲得スコア率
              </Col>
              <Col className="gutter-row" span={6}>
                正解率
              </Col>
              <Col className="gutter-row" span={6}>
                不正解率
              </Col>
              <Col className="gutter-row" span={6}>
                未経験率
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={6}>
                {formatNumber(
                  (dataTabUser.acquisition_score / (dataTabUser.probs_count || 0)) * 100
                )}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabUser.accuracy_rate * 100)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabUser.wrong_rate * 100)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabUser.inexperienced_rate * 100)}
                <small>%</small>
              </Col>
            </Row>
          </Col>
          <Col className="item-table" span={8}>
            <p className="table-label">ユーザー平均 実施結果サマリ</p>
            <Row className="row-label">
              <Col className="gutter-row" span={6}>
                獲得スコア率
              </Col>
              <Col className="gutter-row" span={6}>
                正解率
              </Col>
              <Col className="gutter-row" span={6}>
                不正解率
              </Col>
              <Col className="gutter-row" span={6}>
                未経験率
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={6}>
                {dataTabAll.acquisition_score}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabAll.accuracy_rate * 100)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabAll.wrong_rate * 100)}
                <small>%</small>
              </Col>
              <Col className="gutter-row" span={6}>
                {formatNumber(dataTabAll.inexperienced_rate * 100)}
                <small>%</small>
              </Col>
            </Row>
          </Col>
          <Col className="item-table" span={8}>
            <p className="table-label">対ユーザー平均比較</p>
            <Row className="row-label">
              <Col className="gutter-row" span={6}>
                獲得スコア率
              </Col>
              <Col className="gutter-row" span={6}>
                正解率
              </Col>
              <Col className="gutter-row" span={6}>
                不正解率
              </Col>
              <Col className="gutter-row" span={6}>
                未経験率
              </Col>
            </Row>
            <Row className="row-number">
              <Col className="gutter-row" span={6}>
                <div className="item-column">
                  {Number(dataTabUser.acquisition_score) - Number(dataTabAll.acquisition_score) <
                  0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {Math.abs(
                      Number(formatNumber(dataTabUser.acquisition_score)) -
                        Number(formatNumber(dataTabAll.acquisition_score))
                    )}
                  </p>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="item-column">
                  {Number(dataTabUser.accuracy_rate) - Number(dataTabAll.accuracy_rate) < 0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(
                        Number(dataTabUser.accuracy_rate) - Number(dataTabAll.accuracy_rate)
                      ) * 100
                    )}
                  </p>
                  <small>%</small>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="item-column">
                  {Number(dataTabUser.wrong_rate) - Number(dataTabAll.wrong_rate) < 0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(Number(dataTabUser.wrong_rate) - Number(dataTabAll.wrong_rate)) * 100
                    )}
                  </p>
                  <small>%</small>
                </div>
              </Col>
              <Col className="gutter-row" span={6}>
                <div className="item-column">
                  {Number(dataTabUser.inexperienced_rate) - Number(dataTabAll.inexperienced_rate) <
                  0 ? (
                    <ArrowDownOutlined className="icon-down" />
                  ) : (
                    <ArrowUpOutlined className="icon-up" />
                  )}
                  <p className="label-number">
                    {formatNumber(
                      Math.abs(
                        Number(dataTabAll.inexperienced_rate) -
                          Number(dataTabUser.inexperienced_rate)
                      ) * 100
                    )}
                  </p>
                  <small>%</small>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          className="table"
          dataSource={dataDetailSkillCheckResult
            .map((item, index) => ({ ...item, index }))
            .slice(1)}
          columns={columns}
          bordered
          rowClassName={(_, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
          pagination={false}
          rowKey="index"
        />
      </div>

      <ConfirmExport
        visible={visiblePopupConfirmExportFile}
        setVisible={setVisiblePopupConfirmExportFile}
        onSubmit={handleExportCSV}
      />
      <QuestionDetail visible={modalQuestionDetail} setVisible={setModalQuestionDetail} />
    </RecordSkillCheckStyled>
  );
};

export default RecordSkillCheck;
