import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { settingSelector } from 'containers/AppSettings/selectors';
import WrapperStyled from './styles';
import { Header } from 'components';
import * as Types from 'types';

interface Props {
  items: Types.Groups.ResponseType[];
  total: number;
  page: number;
  perPage: number;
}

const AnalysisGroupPDF: React.FC<Props> = ({ items, page, total, perPage }) => {
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const columns: any = [
    {
      key: 'code',
      title: 'コード',
      dataIndex: 'code',
      width: '13%',
      align: 'center',
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      width: '60%',
      editable: true,
    },
  ];
  return (
    <WrapperStyled collapsedMenu={collapsedMenu}>
      <Header title={headerTitle} className="header" exportPdf />
      <div className="container-pdf">
        <div className="description">
          <p className="content-pdf">
            分析グループマスタの作成・管理を行う画面です。作成した分析グループをスキルチェックに設定します。レポート機能を使用し、同一の分析グループが設定されたスキルチェックでは比較分析が可能になります。
            <br />
            また、定期テスト等で同じ設問のスキルチェックを実施する際に前回比較で成長率等を確認することができます。
          </p>
          <div className="border" />

          {total > 0 ? (
            <div className="wrap-select-record-pdf">
              <div className="text-count">
                {(page - 1) * perPage + 1} - {''}
                {page * perPage > total ? total : page * perPage} / {total}件
              </div>
            </div>
          ) : null}

          <Table
            dataSource={items.map((item, index) => ({ ...item, index }))}
            columns={columns}
            className={items.length > 0 ? 'table' : 'table_mr'}
            rowKey="index"
            pagination={false}
          />
        </div>
      </div>
    </WrapperStyled>
  );
};

export default AnalysisGroupPDF;
