import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import saveAs from 'file-saver';
import { Button } from 'antd';

import { memoizedGetFlatDataFromTree } from 'libs/utils/affiliation/memoized-tree-data-utils';
import { HEADER_AFFILIATION_MASTER_CSV } from 'constant/header.export.constant';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import PopupConfirmExportFile from 'components/Modal/ConfirmExportFile';
import { settingSelector } from 'containers/AppSettings/selectors';
import { createAffiliationLevel, getAffiliations } from './thunk';
import { sharedFileInMinIO } from 'services/minioService';
import AffiliationTreeView from 'containers/Affiliation';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch, usePermission } from 'hooks';
import PopupCreateAffiliation from './Modal/Create';
import { extractFileName } from 'libs/utils/format';
import { affiliationSelector } from './selectors';
import AffiliationUser from './AffiliationUser';
import exportPDF from 'libs/utils/exportPdf';
import FileExportPDF from './FileExportPDF';
import { Header } from 'components';
import Wrapper from './styles';
import * as Types from 'types';
import { Item } from './Item';

const AffiliationMaster: React.FC = () => {
  const [showConfirmExportFileModal, setShowConfirmExportFileModal] = useState<boolean>(false);
  const [showPopupCreateAffiliation, setShowPopupCreateAffiliation] = useState<boolean>(false);
  const [userChild, setUserChild] = useState<Types.TreeItem<Types.AffiliationItemType>[]>([]);
  const [affiliationName, setAffiliationName] = useState<string>();
  const [isExpand, setIsExpand] = useState<boolean>(false);

  const ref = useRef(null);

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { affiliations } = useSelector(affiliationSelector);
  const { userInfo } = useSelector(authSelector);

  const { permissionNumber } = usePermission();
  const dispatch = useAppDispatch();

  const { messages } = useIntl();

  const handleExportCSV = async (value: string) => {
    dispatch(startLoading());
    try {
      if (value === 'csv') {
        const resultAction = await dispatch(
          getAffiliations({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            include_lookups: true,
            include_item_ref: true,
          })
        );
        if (getAffiliations.fulfilled.match(resultAction)) {
          const listCsv = resultAction.payload.report_results.map((item) => ({
            affiliation_id: item.affiliation_id,
            affiliation_parent_id: item.affiliation_parent_id,
            code: item.code,
            company_id: item.company_id,
            level: item.level,
            login_id: item.login_id,
            name: item.name,
            sort_order: item.sort_order,
            user_icon_fileID: item.user_icon_fileID,
            user_name: item.user_name,
            user_sort_order: item.user_sort_order,
          }));

          const csvString = [
            HEADER_AFFILIATION_MASTER_CSV.map(({ label }) => label),
            ...listCsv.map((item) => Object.values(item)),
          ]
            .map((e) => e.join(','))
            .join('\n');
          const bom = '\uFEFF';
          const file = new Blob([bom, csvString], { type: 'application/octet-stream' });
          saveAs(file, 'Affiliation_export.csv');
        }
      } else {
        if (!ref.current) return;

        exportPDF(ref, '所属マスタ.pdf');
      }
    } catch (error) {
      console.log(error);
    }
    dispatch(stopLoading());
    setShowConfirmExportFileModal(false);
  };

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getAffiliations({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
          include_lookups: true,
          include_item_ref: true,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo?.company_id]);

  useEffect(() => {
    (async () => {
      const dataConvert = await Promise.all(
        affiliations.map(async (e) => {
          return {
            ...e,
            user_children: await Promise.all(
              (e.user_children || []).map(async (val) => {
                if (extractFileName(val.user_icon_fileID) && val.user_icon_fileID) {
                  const fileID = await sharedFileInMinIO(val.user_icon_fileID);
                  return { ...val, user_icon_fileID: fileID };
                }
                return val;
              })
            ),
          };
        })
      );
      setUserChild(dataConvert);
    })();
  }, [affiliations, setUserChild]);

  const component = useMemo(() => {
    return (
      <div
        id="file"
        ref={ref}
        style={{
          position: 'absolute',
          right: 9999,
          width: '100%',
        }}
      >
        <div className="wrapTitlePDF">
          <p className="titlePDF">所属マスタ</p>
        </div>
        <div className="wrapDes">
          <p>所属を作成し、任意の階層構造にすることができます。</p>
          <p>
            作成した所属に設定しているユーザーの確認、ユーザーの所属移動、兼務ユーザーの作成が可能です。
          </p>
        </div>
        <div className="line" />
        {userChild &&
          userChild.map((e, index) => (
            <FileExportPDF
              data={memoizedGetFlatDataFromTree({
                treeData: e,
              })}
              key={index}
            />
          ))}
      </div>
    );
  }, [userChild]);

  return (
    <Wrapper
      collapsedMenu={collapsedMenu}
      scrollBarWidth={window.innerWidth - document.documentElement.offsetWidth}
    >
      <Header title={headerTitle} className="header-affiliation" />

      {component}
      <div className="header">
        <div className="wrap-title">
          <p>
            所属を作成し、任意の階層構造にすることができます。
            <br />
            作成した所属に設定しているユーザーの確認、ユーザーの所属移動、兼務ユーザーの作成が可能です。
          </p>
        </div>
        <div className="wrap-button">
          <button
            className="btn btn-outline"
            onClick={() => {
              setAffiliationName(undefined);
              setIsExpand((prevState) => !prevState);
            }}
          >
            {isExpand ? 'ユーザー格納' : 'ユーザー全展開'}
          </button>
          <button className="btn btn-active" onClick={() => setShowConfirmExportFileModal(true)}>
            <div className="title-btn">
              <CloudDownloadOutlined className="size-icon" />
              {messages['M-21-110']}
            </div>
          </button>
        </div>
      </div>
      {isExpand ? (
        <div className="body tab-isExpand">
          <div className="header-body">
            <Button
              disabled={permissionNumber === 1}
              className={permissionNumber === 1 ? 'disabled' : 'btn-create'}
              onClick={() => setShowPopupCreateAffiliation(true)}
              htmlType="button"
            >
              <PlusOutlined className="size-icon" />
              <span>所属追加</span>
            </Button>
          </div>
          {affiliations &&
            affiliations.map((data: Types.TreeItem<Types.AffiliationItemType>, index) => (
              <AffiliationUser key={index} treeData={data} isExpand={isExpand} />
            ))}
        </div>
      ) : (
        <div className="body">
          <div className="header-body" style={{ background: affiliationName ? '#EAEBEF' : 'none' }}>
            {affiliationName ? (
              <div className="affiliation-created">
                <div className="left-side">
                  <Item name={affiliationName} isFromCreated />
                  <p className="note">
                    ※追加した所属をツリー内の任意の位置にドラッグ＆ドロップしてください。
                  </p>
                </div>
                <div className="right-side">
                  <Button
                    className="btn-cancel"
                    htmlType="button"
                    onClick={() => setAffiliationName(undefined)}
                  >
                    キャンセル
                  </Button>
                </div>
              </div>
            ) : (
              <Button
                disabled={permissionNumber === 1}
                className={permissionNumber === 1 ? 'disabled' : 'btn-create'}
                onClick={() => setShowPopupCreateAffiliation(true)}
                icon={<PlusOutlined className="size-icon" />}
                htmlType="button"
              >
                所属追加
              </Button>
            )}
          </div>
          <div className="tree-view">
            {affiliations &&
              affiliations.map((aff, index) => (
                <AffiliationTreeView
                  key={index}
                  treeData={aff}
                  onResetAffiliationCreated={() => setAffiliationName(undefined)}
                />
              ))}
          </div>
        </div>
      )}
      <PopupCreateAffiliation
        visible={showPopupCreateAffiliation}
        setVisible={setShowPopupCreateAffiliation}
        onSubmit={async (name) => {
          if (affiliations.length > 0) {
            if (isExpand) {
              setIsExpand(false);
            }
            setAffiliationName(name);
          } else {
            dispatch(startLoading());
            const resultAction = await dispatch(
              createAffiliationLevel({
                item: {
                  name,
                  sort_order: 1,
                  level: 1,
                  createdby: userInfo?.login_id,
                  company_id: userInfo?.company_id,
                  createdat: new Date(),
                },
              })
            );
            if (createAffiliationLevel.fulfilled.match(resultAction)) {
              await dispatch(
                getAffiliations({
                  conditions: [
                    {
                      id: 'company_id',
                      search_value: [userInfo?.company_id],
                    },
                  ],
                  page: 1,
                  per_page: 0,
                  include_lookups: true,
                  include_item_ref: true,
                })
              );
            }
            dispatch(stopLoading());
          }
        }}
      />
      <PopupConfirmExportFile
        visible={showConfirmExportFileModal}
        setVisible={setShowConfirmExportFileModal}
        onSubmit={handleExportCSV}
      />
    </Wrapper>
  );
};

export default AffiliationMaster;
