import { createAsyncThunk } from '@reduxjs/toolkit';
import { omit } from 'lodash';

import { DETAIL_OF_SKILL_CHECK_RESULTS, REPORT_SKILL_CHECK } from 'configs';
import { services } from 'services';
import * as Types from 'types';

export const getReportSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheck.ResponseType> & {
    isQuestionCodeTable: boolean;
  },
  Types.ReportsItemRequestType & {
    isQuestionCodeTable: boolean;
  },
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReport/thunk/getReportSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheck.ResponseType>(
      REPORT_SKILL_CHECK.id,
      omit(req, 'isQuestionCodeTable')
    );

    return { ...data, isQuestionCodeTable: req.isQuestionCodeTable };
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckByLoginID = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReport/thunk/getReportSkillCheckByLoginID', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.ReportSkillCheck.ResponseType>(
      REPORT_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getReportSkillCheckBySkillCheckCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.ReportSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'SkillCheckReport/thunk/getReportSkillCheckBySkillCheckCode',
  async (req, { rejectWithValue }) => {
    try {
      const { data } = await services.filter<Types.ReportSkillCheck.ResponseType>(
        REPORT_SKILL_CHECK.id,
        req
      );

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getDetailOfSkillCheckResults = createAsyncThunk<
  Types.ReportsItemResponseType<Types.DetailOfSkillCheckResults.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('SkillCheckReport/thunk/getDetailOfSkillCheckResults', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.DetailOfSkillCheckResults.ResponseType>(
      DETAIL_OF_SKILL_CHECK_RESULTS.id,
      req
    );

    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});
