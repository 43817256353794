import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  CURRICULUMS,
  EMPLOYEE_USER,
  REQUIRED_CURRICULUM_SKILL_CHECK,
  SELECT_DEPARTMENT,
  SELECT_EMPLOYEE_USER,
  SELECT_POSITION,
  SELECT_REQUIRED_CURRICULUM,
  SELECT_REQUIRED_CURRICULUM_CREATOR,
  SELECT_SKILL_CHECK,
  SELECT_SKILL_CHECK_CATEGORY,
  SELECT_SKILL_CHECK_CREATOR,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_SKILL_CHECK_START_PERIOD,
  SELECT_SKILL_CHECK_USE_STATUS,
  SKILL_CHECK,
  SKILL_CHECK_TRANS2,
  USERS,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  USER_ASSIGN_SKILL_CHECK,
} from 'configs';

export const getDataEducationProgram = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RequiredCurriculumSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataEducationProgram', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.RequiredCurriculumSkillCheck.ResponseType>(
      REQUIRED_CURRICULUM_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataStudyProgram = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataStudyProgram', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUser.ResponseType>(EMPLOYEE_USER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectName = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectEmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectName', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectEmployeeUser.ResponseType>(
      SELECT_EMPLOYEE_USER.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectPosition = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectPosition.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectPosition.ResponseType>(
      SELECT_POSITION.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumBatch = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculums.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataCurriculumBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculumCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectCurriculumCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculum.ResponseType>(
      SELECT_REQUIRED_CURRICULUM.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculum.ResponseType>(
      SELECT_REQUIRED_CURRICULUM.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.Users.ResponseDetailType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>(
  'EducationProgram/thunk/getDataSelectCreator',
  async (req, { rejectWithValue, dispatch, getState }) => {
    try {
      const { authContainer } = getState() as Types.RootState;

      const creators: Array<Types.Users.ResponseDetailType> = [];

      const { data } = await services.filter<Types.SelectRequiredCurriculumCreator.ResponseType>(
        SELECT_REQUIRED_CURRICULUM_CREATOR.name,
        req
      );

      const actionResult = await dispatch(
        getUserSearch({
          conditions: [
            {
              id: 'company_id',
              search_value: [authContainer.userInfo?.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      if (getUserSearch.fulfilled.match(actionResult)) {
        data.report_results.forEach((e) => {
          const findIndex = actionResult.payload.items.findIndex((val) => e.creator === val.email);
          if (findIndex > -1) {
            creators.push(actionResult.payload.items[findIndex]);
          }
        });
      }
      return { ...data, report_results: creators };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStartPeriod = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckStartPeriod.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheckStartPeriod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckStartPeriod.ResponseType>(
      SELECT_SKILL_CHECK_START_PERIOD.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCategory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCategory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheckCategory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCategory.ResponseType>(
      SELECT_SKILL_CHECK_CATEGORY.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckGroupCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckGroupingCode.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheckGroupCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckGroupingCode.ResponseType>(
      SELECT_SKILL_CHECK_GROUPING_CODE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckUseStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheckStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckUseStatus.ResponseType>(
      SELECT_SKILL_CHECK_USE_STATUS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getDataSelectSkillCheckCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCreator.ResponseType>(
      SELECT_SKILL_CHECK_CREATOR.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserSearch = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseDetailType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getUserSearch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseDetailType>(USERS.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserAssignCurriculum = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignRequiredCurriculum.ResponseType>(
      USER_ASSIGN_REQUIRED_CURRICULUM.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserAssignSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.UserAssignSkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.UserAssignSkillCheck.ResponseType>(
      USER_ASSIGN_SKILL_CHECK.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSkillCheckTrans2 = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheckTrans2.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('EducationProgram/thunk/getSkillCheckTrans2', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheckTrans2.ResponseType>(
      SKILL_CHECK_TRANS2.id,
      req
    );
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
