import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Header } from 'components';
import { useFormik } from 'formik';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import ActionErrorModal from 'components/Modal/ActionError';
import { CreateRolesMasterSchema } from 'libs/validations';
import RolesMasterFrom from 'containers/RolesMasterForm';
import { authSelector } from 'containers/Auth/selectors';
import CompletedModal from 'components/Modal/Completed';
import RolesMasterRegisterStyled from './styles';
import { CreateRolesMasterFormik } from 'types';
import { routes } from 'navigations/routes';
import { createRoles } from '../thunk';
import { useAppDispatch } from 'hooks';

const RolesMasterRegister: React.FC = () => {
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [showCompleteModal, setShowCompleteModal] = useState<boolean>(false);

  const { userInfo } = useSelector(authSelector);

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const formik = useFormik<CreateRolesMasterFormik>({
    initialValues: {
      name: '',
      admin_dashboard_permission: 1,
      skill_check_implementation_permission: 1,
      training_report_permission: 1,
      skill_check_report_permission: 1,
      my_chart_permission: 1,
      manual_permission: 1,
      knowledge_permission: 1,
      official_curriculum_permission: 1,
      curricullum_master_permission: 1,
      question_master_permission: 1,
      skill_check_master_permission: 1,
      inter_users_master_permission: 1,
      groups_master_permission: 1,
      users_master_permission: 1,
      roles_master_permission: 1,
      departments_master_permission: 1,
      positions_master_permission: 1,
      admin_master_permission: 1,
      payment_master_permission: 1,
      report_view_permission: 1,
    },
    validationSchema: CreateRolesMasterSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      dispatch(startLoading());
      const resultAction = await dispatch(
        createRoles({
          item: {
            ...values,
            company_id: userInfo?.company_id,
            createdby: userInfo?.login_id,
            createdat: new Date(),
          },
          is_force_update: true,
        })
      );
      if (createRoles.fulfilled.match(resultAction)) {
        setShowCompleteModal(true);
      } else {
        setShowActionErrorModal(true);
      }
      dispatch(stopLoading());
    },
  });

  return (
    <RolesMasterRegisterStyled>
      <Header title={messages['M-02-1'] as string} className="header" />
      <div className="container">
        <div className="description">
          <p className="content">
            {messages['M-02-2']}
            <br />
            {messages['M-02-3']}
          </p>
        </div>
        <RolesMasterFrom formik={formik} type="create" />
      </div>
      <CompletedModal
        visible={showCompleteModal}
        setVisible={setShowCompleteModal}
        title="登録が完了しました"
        onSubmit={() => navigate(generatePath(routes.RolesMaster.path, { entity: 'receiving' }))}
      />
      <ActionErrorModal
        visible={showActionErrorModal}
        setVisible={setShowActionErrorModal}
        subTitle="新規権限の登録に失敗しました"
        description={
          <p className="text-content">
            新規権限の登録に失敗しました。
            <br />
            もう一度情報を入力し、再度お試しください。
          </p>
        }
      />
    </RolesMasterRegisterStyled>
  );
};

export default RolesMasterRegister;
