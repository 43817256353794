import React from 'react';
import { useIntl } from 'react-intl';

import { SectionStyled } from './styles';

interface Props {
  company_name: string;
  onSubmit: () => void;
  onClose: () => void;
}

const PopupProvision: React.FC<Props> = ({ company_name, onSubmit, onClose }) => {
  const { messages } = useIntl();
  return (
    <SectionStyled
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="popup">
        <h3 className="title">{messages['modal-AJ-02-1']}</h3>
        <div className="content">
          <p className="content-text">
            この利用規約（以下，「本規約」といいます。）は，{company_name || '＿＿＿＿＿'}
            （以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
          </p>
          <p className="content-text">
            第1条（適用）
            <br />
            本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
            <br />
            当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
            <br />
            本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。
            <br />
            第2条（利用登録）
            <br />
            本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
          </p>
          <div className="wrap-btn">
            <button className="btn-submit" onClick={onSubmit}>
              {messages['modal-AJ-02-2']}
            </button>
          </div>
        </div>
      </div>
    </SectionStyled>
  );
};

export default PopupProvision;
