import styled from 'styled-components';

interface Props {
  nodeHeight: number;
  treeActive: boolean;
  isShowMenuRight?: boolean;
}

export const Wrapper = styled.div<Props>`
  .rst__node {
    background-color: ${(props) => (props.treeActive ? '#2773BA' : '#ffffff')} !important;
    border: 1px solid ${(props) => (props.treeActive ? '#2773BA' : '#ccc')} !important;
    height: ${(props) => props.nodeHeight}px;
    cursor: pointer;
    .rowTitle {
      color: ${(props) => (props.treeActive ? '#ffffff' : '#2a2a2a')} !important;
      font-size: 13px;
      white-space: nowrap;
    }
    .box_icon {
      background-color: ${(props) => (props.treeActive ? '#ffffff' : null)} !important;
    }
  }
  .tree {
    background-color: #eee;
    border: 1px solid ${(props) => (props.treeActive ? '#2773BA' : '#cfcfcf')};
    border-radius: 4px;
    padding: 12px 12px 15px 12px;
    margin-left: 35px;
    margin-top: 26px;
    position: relative;
    .wrap_node_tree {
      display: flex;
      flex-wrap: wrap;
      .node-item {
        display: flex;
        align-items: center;
        position: relative;
        background: #ffffff;
        width: 24.25%;
        height: 36px;
        border-radius: 2px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        margin: 4px 3px;
        cursor: pointer;
        .node-item-child {
          display: flex;
          align-items: center;
          position: relative;
          width: 100%;
          height: 100%;
          min-width: ${(props) => (!props.isShowMenuRight ? '230px' : '190px')};
        }
        .ordinal-number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 10%;
          height: 100%;
          background: #f1fefe;
          font-size: 11px;
          color: #159193;
          border: 1px solid #e6f5f5;
          border-radius: 2px 0 0 2px;
          font-style: italic;
          font-family: Lato;
          font-weight: 600;
        }
        .name {
          display: block;
          align-items: center;
          width: 195px;
          margin: 0 0 0 5px;
          color: #424242;
          font-size: 13px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .score {
          display: flex;
          position: absolute;
          bottom: 3px;
          right: 5px;
          .star-icon {
            font-size: 8.5px;
            color: #dddddd;
          }
          .active {
            color: #08a3a5;
          }
        }
      }
    }
    .text-not-set {
      font-size: 13px;
      color: #999999;
      margin: 0;
    }
    .column {
      width: 16.67%;
    }
    .column-active {
      background: #f8f8f8;
      border-radius: 4px;
    }
    &:before {
      position: absolute;
      content: '';
      top: -25px;
      left: -19px;
      width: 1px;
      height: 35px;
      background: repeating-linear-gradient(
        to bottom,
        #8b8b8b 0,
        #8b8b8b 3px,
        transparent 0,
        transparent 5px
      );
    }
    &:after {
      position: absolute;
      content: '';
      top: 10px;
      left: -19px;
      width: 19px;
      height: 1px;
      background: repeating-linear-gradient(
        to right,
        #8b8b8b 0,
        #8b8b8b 3px,
        transparent 0,
        transparent 5px
      );
    }
  }
`;

export const WrapperToolTip = styled.div`
  border-top: 2px solid #00a3a5;
  padding: 10px;
  .name {
    font-size: 12px;
    color: #424242;
  }
  .score-action {
    display: flex;
    justify-content: space-between;
    .score {
      display: flex;
      align-items: center;
      .label {
        font-size: 10px;
        color: #999999;
      }
      .value {
        display: flex;
        .star-icon {
          font-size: 8.5px;
          color: #dddddd;
        }
        .active {
          color: #08a3a5;
        }
      }
    }
    .action {
      display: flex;
      justify-content: flex-end;
    }
    .icon {
      color: #c4c4c4;
      font-size: 18px;
      margin-left: 10px;
      &:hover {
        color: #009acb;
      }
    }
  }
`;

export const WrapperColumnRoot = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  .rst__nodeContent {
    width: 100%;
  }
`;
