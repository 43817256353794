import styled from 'styled-components';

export default styled.section`
  background: #f9f8f8;
  .container {
    background: #f9f8f8;
    .dashboard-title {
      display: flex;
      align-items: center;
      padding: 5px 30px;
      justify-content: space-between;
      background: linear-gradient(0deg, #ffffff, #ffffff), #08a3a5;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
      .text {
        font-size: 18px;
        margin: 0;
        color: #2a2a2a;
      }
      .btn {
        width: 140px;
        height: 40px;
        cursor: pointer;
        font-size: 13px;
        border-radius: 5px;
        font-weight: bold;
        margin-left: 8px;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      }
      .btn-active {
        background: #f6ac00;
        border: 1px solid #f6ac00;
        color: #ffffff;
        .size-icon {
          font-size: 14px;
          margin-right: 20px;
        }
      }
    }
    .circle-chart {
      padding: 15px 30px;
      .title-chart {
        display: flex;
        align-items: center;
        color: #08a3a5;
        padding: 10px 0;
        .title {
          margin: 0;
          margin-left: 10px;
          font-size: 16px;
        }
        .icon {
          font-size: 22px;
        }
      }
      .item-select {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 5px 5px 0 0;
        padding: 20px;
        .select {
          .label {
            margin: 0;
            font-size: 12px;
            color: #777777;
          }
        }
        .btn-result {
          width: 180px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f6ac00;
          box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
          border-radius: 5px;
          font-weight: 700;
          font-size: 13px;
          text-align: center;
          color: #f1a900;
          cursor: pointer;
          .icon {
            font-size: 13px;
            margin-left: 15px;
          }
        }
      }
      .chart-item {
        background: #f2f2f2;
        border: 1px solid #eeeeee;
        border-radius: 0 0 5px 5px;
        padding: 20px 0 10px 0;
        .wrap-chart {
          justify-content: center;
          margin-right: 0 !important;
          .item-count {
            background: #ffffff;
            border: 1px solid #eeeeee;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            margin-bottom: 10px;
            .wrap-item {
              padding: 12px;
              display: flex;
              flex-direction: column;
              .label-text {
                font-size: 14px;
                color: #424242;
                margin: 0;
              }
              .label-number {
                display: flex;
                align-items: center;
                .number-change {
                  padding: 0 10px;
                  border: 1px solid rgba(22, 113, 197, 0.3);
                  border-radius: 2px;
                  .label {
                    font-family: Lato;
                    font-weight: 700;
                    margin: 0;
                    font-size: 15px;
                  }
                }
                .icon {
                  font-size: 13px;
                  margin: 0 10px;
                }
                .number-ratio {
                  font-family: Lato;
                  font-size: 40px;
                  color: #1671c5;
                  margin: 0;
                }
              }
              .up {
                color: #1671c5;
              }
              .down {
                color: #ea3596;
                .number-change {
                  border: 1px solid rgba(234, 53, 150, 0.3);
                }
                .number-ratio {
                  color: #ea3596;
                }
              }
              .reduce {
                color: #ea3596;
                .number-change {
                  border: 1px solid rgba(234, 53, 150, 0.3);
                }
                .number-ratio {
                  color: #ea3596;
                }
              }
              .number-result {
                display: flex;
                align-items: center;
                .label {
                  font-size: 13px;
                  color: #777777;
                  margin: 0;
                }
                .number {
                  margin: 0;
                  font-size: 13px;
                }
              }
            }
          }
          .chart {
            display: flex;
            align-items: center;
            background: #ffffff;
            border: 1px solid #eeeeee;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            margin: 0 0 10px 5px;
            .pie {
              width: 70%;
            }
            .color-chart {
              .item-color {
                display: flex;
                align-items: center;
                .color {
                  width: 14px;
                  height: 14px;
                }
                .correct-answer {
                  background: #379bd2;
                }
                .incorrect-answer {
                  background: #ea8c92;
                }
                .inexperienced {
                  background: #ebebeb;
                }
                .name {
                  margin: 0;
                  font-size: 12px;
                  color: #424242;
                  padding: 8px;
                }
              }
            }
          }
        }
      }
    }
    .column-chart {
      padding: 15px 30px;
      .title-chart {
        display: flex;
        align-items: center;
        color: #08a3a5;
        padding: 10px 0;
        .title {
          font-size: 16px;
          margin: 0;
          margin-left: 10px;
        }
        .icon {
          font-size: 22px;
        }
      }
      .item-chart {
        background: #ffffff;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        .item-select {
          display: flex;
          align-items: center;
          background: #ffffff;
          border-radius: 5px 5px 0 0;
          padding: 20px;
          .select {
            margin-right: 15px;
            .label {
              margin: 0;
              font-size: 12px;
              color: #777777;
            }
          }
        }
        .chart {
          padding: 0 40px 40px 40px;
          .color-chart {
            display: flex;
            flex-direction: row;
            justify-content: end;
            .item-color {
              display: flex;
              align-items: center;
              .color {
                width: 14px;
                height: 14px;
              }
              .correct-answer {
                background: #379bd2;
              }
              .incorrect-answer {
                background: #ea8c92;
              }
              .inexperienced {
                background: #ebebeb;
              }
              .name {
                margin: 0;
                font-size: 12px;
                color: #424242;
                padding: 8px;
              }
            }
          }
        }
      }
    }
    .training-progress-chart {
      padding: 15px 30px;
      .title-chart {
        display: flex;
        align-items: center;
        color: #08a3a5;
        padding: 10px 0;
        .title {
          margin: 0;
          font-size: 16px;
          margin-left: 10px;
        }
        .icon {
          font-size: 22px;
        }
      }
      .item-chart {
        background: #ffffff;
        padding: 30px;
        .item-select {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .group-select {
            display: flex;
            .select {
              padding-right: 10px;
              .label {
                margin: 0;
                font-size: 12px;
                color: #777777;
              }
            }
          }
          .btn-result {
            width: 180px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f6ac00;
            box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
            border-radius: 5px;
            font-weight: 700;
            font-size: 13px;
            text-align: center;
            color: #f1a900;
            cursor: pointer;
            .icon {
              font-size: 13px;
              margin-left: 15px;
            }
          }
        }
        .color-chart {
          display: flex;
          justify-content: end;
          .item-color {
            display: flex;
            align-items: center;
            .color {
              width: 14px;
              height: 14px;
            }
            .correct-answer {
              background: #ddeacd;
            }
            .incorrect-answer {
              background: #6ec9ab;
            }
            .inexperienced {
              background: #e4aa38;
            }
            .name {
              margin: 0;
              font-size: 12px;
              color: #424242;
              padding: 8px;
            }
          }
        }
        .wrap-group-chart {
          display: flex;
          border-radius: 3px;
          .table {
            width: 20%;
            .ant-table-thead .ant-table-cell {
              font-size: 11px;
              padding: 0 8px;
              height: 41px;
              border-bottom: 1px solid #f0f0f0;
            }
          }
          .chart {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 80%;
            div:first-child {
              top: -20px;
              border-bottom: 1px solid #f0f0f0;
            }
            .header-column {
              width: 100%;
              height: 42px;
              background: #f7f7f7;
            }
            .space-x {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .table-row-dark {
      background: #ffffff;
    }
    .table-row-dark {
      background: #f9f9f9;
    }
  }
`;
