import React, { useEffect, useMemo, useState } from 'react';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { SearchOutlined } from '@ant-design/icons';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import timezone from 'dayjs/plugin/timezone';
import { ColumnsType } from 'antd/lib/table';
import { useSelector } from 'react-redux';
import { Select, Table } from 'antd';
import utc from 'dayjs/plugin/utc';
import { some } from 'lodash';
import dayjs from 'dayjs';

import { filterSkillImpleBySetting, filterSkillImpleByStatus } from '../../slice';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import ConfirmDeleteModal from 'components/Modal/ConfirmDelete';
import { skillCheckSelectionSelector } from '../../selectors';
import ActionErrorModal from 'components/Modal/ActionError';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { authSelector } from 'containers/Auth/selectors';
import ModalDeleteComplete from '../ModalDeleteComplete';
import { IconImpossible, IconPossible } from 'assets';
import { SelectField, DatePicker } from 'components';
import GeneralUserStyled from './styles';
import * as Types from 'types';
import {
  getDataSelectSkillCheckGroupingCode,
  getDataSelectSkillCheckName,
  editUserAssignSillCheck,
  deleteSkillCheckTrans,
  getSelectDepartment,
  deleteQuestionTrans,
  getImplementTable,
  getSelectUser,
  getDataTable,
} from '../../thunk';

interface Props {
  setSelectedRow: React.Dispatch<React.SetStateAction<Types.SkillCheckImpleStatus.ResponseType[]>>;
  setVisibleModalComplete: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  setVisiblePopupError: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleModalEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  selectedRow: Types.SkillCheckImpleStatus.ResponseType[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  handleSelectChange: (page: number) => void;
  visibleModalComplete: boolean;
  selectedRowKeys: React.Key[];
  visiblePopupError: boolean;
  visibleModalEdit: boolean;
  perPage: number;
  page: number;
}

// const PER_PAGE = 10;
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const { Option } = Select;

const GeneralUser: React.FC<Props> = ({
  setVisibleModalComplete,
  setVisiblePopupError,
  visibleModalComplete,
  setVisibleModalEdit,
  setSelectedRowKeys,
  handleSelectChange,
  visiblePopupError,
  visibleModalEdit,
  selectedRowKeys,
  setSelectedRow,
  selectedRow,
  setPerPage,
  setPage,
  perPage,
  page,
}) => {
  const [startPeriodFilter, setStartPeriodFilter] = useState<string>();
  const [startPeriod, setStartPeriod] = useState<string>();

  const dispatch = useAppDispatch();

  const { collapsedMenu } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const roleCode = useMemo(() => userInfo?.lookup_items?.role_code, [userInfo]);

  const {
    dataSelectSkillCheckName,
    dataSelectGroupingCode,
    dataSelectUser,
    dataSelectDepartment,
    dataTableSkillCheckImpleStatus,
    total,
  } = useSelector(skillCheckSelectionSelector);

  const formik = useFormik({
    initialValues: {
      skill_check_code: '',
      grouping_code: '',
      login_id: '',
      department_name: '',
      user_type: '',
      implementation_setting: '',
      implementation_status: '',
    },
    onSubmit: async (values) => {
      dispatch(startLoading());
      const conditions: Types.ConditionsType[] = [
        {
          id: 'company_id',
          search_value: [userInfo?.company_id],
        },
      ];
      Object.keys(values)
        .filter((e) => e !== 'implementation_settings' && e !== 'implementation_status')
        .forEach((key) => {
          if (values[key as keyof typeof values]) {
            conditions.push({
              id: key,
              search_value: [
                values[key as keyof typeof values] === 'member'
                  ? 'member|admin'
                  : values[key as keyof typeof values],
              ],
              use_or_condition: true,
              exact_match: true,
            });
          }
        });
      setStartPeriodFilter(startPeriod);
      await dispatch(
        getDataTable({
          conditions,
          page: 1,
          per_page: 0,
          include_item_ref: true,
          sort_fields: [
            { id: 'skill_check_code', order: 'asc' },
            { id: 'login_id', order: 'asc' },
          ],
        })
      );
      if (values.implementation_setting) {
        dispatch(filterSkillImpleBySetting(values.implementation_setting));
      }
      if (values.implementation_status) {
        dispatch(filterSkillImpleByStatus(values.implementation_status));
      }
      dispatch(stopLoading());
    },
  });

  const dataSkillCheck = useMemo(() => {
    if (startPeriodFilter) {
      return dataTableSkillCheckImpleStatus.filter(
        (row) =>
          row.start_period &&
          row.end_period &&
          dayjs(startPeriodFilter).isSameOrAfter(row.start_period, 'day') &&
          dayjs(startPeriodFilter).isSameOrBefore(row.end_period, 'day')
      );
    }
    return dataTableSkillCheckImpleStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableSkillCheckImpleStatus]);

  const columns: ColumnsType<Types.SkillCheckImpleStatus.ResponseType> = [
    {
      title: 'スキルチェック',
      dataIndex: 'skill_check_name',
      key: 'skill_check_name',
      width: '15%',
    },
    {
      title: '分析グループ',
      dataIndex: 'grouping_code',
      key: 'grouping_code',
      width: '9%',
      ellipsis: true,
    },
    {
      title: '実施期間',
      dataIndex: 'implementation_period',
      key: 'implementation_period',
      width: '13%',
      render: (_: string, item: Types.SkillCheckImpleStatus.ResponseType) => (
        <p className="text-content">
          {item.start_period && `${dayjs(item.start_period).format('YYYY/MM/DD HH:mm')}〜`}
          <br />
          {item.end_period && dayjs(item.end_period).format('YYYY/MM/DD HH:mm')}
        </p>
      ),
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '9%',
      ellipsis: true,
      render: (department_name: string) => <span>{department_name.replace(/^\d+ /, '')}</span>,
    },
    {
      title: 'ユーザー名',
      dataIndex: 'user_name',
      key: 'user_name',
      width: '9%',
      ellipsis: true,
    },
    {
      title: '実施ステータス',
      dataIndex: 'implementation_settings',
      key: 'implementation_settings',
      width: '12%',
      render: (_, item: Types.SkillCheckImpleStatus.ResponseType) => (
        <div className="item-edit">
          {item.implementation_status === 2 ? (
            <div className="item-type">
              <img src={IconImpossible} className="icon" alt="possible-icon" />
              実施不可
            </div>
          ) : item.implementation_settings === 2 ||
            (item.implementation_settings === 1 &&
              dayjs().isBetween(item?.start_period, item?.end_period)) ? (
            <div className="item-type">
              <img src={IconPossible} className="icon" alt="possible-icon" />
              実施可
            </div>
          ) : (
            <div className="item-type">
              <img src={IconImpossible} className="icon" alt="possible-icon" />
              実施不可
            </div>
          )}
        </div>
      ),
    },
    {
      title: 'ユーザー種別',
      dataIndex: 'user_type',
      key: 'user_type',
      width: '10%',
      render: (_, item: Types.SkillCheckImpleStatus.ResponseType) => (
        <div className="item-type">
          <div className={`type ${item.user_type === 'interview' ? 'exception' : ''}`}>
            {item.user_type === 'interview' ? <p>面</p> : <p>社</p>}
          </div>
          {item.user_type === 'interview' ? <span>面接</span> : <span>社内</span>}
        </div>
      ),
    },
    {
      title: '実施状況',
      dataIndex: 'implementation_status',
      key: 'implementation_status',
      width: '10%',
      render: (_, item: Types.SkillCheckImpleStatus.ResponseType) => (
        <div
          className={`content-label-status ${
            item.implementation_status === 2
              ? 'check'
              : item.implementation_settings === 2 ||
                (item.implementation_settings === 1 && dayjs())
              ? 'edit'
              : 'stop'
          }`}
        >
          {item.implementation_status === 2 ? (
            <>
              <div className="status-done">
                <p className="text-status">済</p>
              </div>
              <p className="label-status">実施済</p>
            </>
          ) : (
            <div
              className={
                item.implementation_settings === 2 ||
                (item.implementation_settings === 1 &&
                  dayjs().isBetween(item?.start_period, item?.end_period))
                  ? 'implemented'
                  : 'not-implemented'
              }
            >
              <div
                className={`status ${
                  item.implementation_settings === 2 ||
                  (item.implementation_settings === 1 &&
                    dayjs().isBetween(item?.start_period, item?.end_period))
                    ? 'edit-icon'
                    : 'stop-icon'
                }`}
              >
                <p className="text-status">未</p>
              </div>
              <p className="label-status">未実施</p>
            </div>
          )}
        </div>
      ),
    },
    {
      title: '実施日時',
      dataIndex: 'implementation_date',
      key: 'implementation_date',
      width: '13%',
      render: (_: string, item: Types.SkillCheckImpleStatus.ResponseType) => (
        <p className="text-content">
          {item.implementation_date && dayjs(item.implementation_date).format('YYYY/MM/DD HH:mm')}
        </p>
      ),
    },
  ];

  const handleDeleteSkillCheck = async () => {
    dispatch(startLoading());
    const conditions: Types.ConditionsType[] = [
      {
        id: 'company_id',
        search_value: [userInfo?.company_id],
      },
    ];
    Object.keys(formik.values)
      .filter((e) => e !== 'implementation_settings' && e !== 'implementation_status')
      .forEach((key) => {
        if (formik.values[key as keyof typeof formik.values]) {
          conditions.push({
            id: key,
            search_value: [
              formik.values[key as keyof typeof formik.values] === 'member'
                ? 'member|admin'
                : formik.values[key as keyof typeof formik.values],
            ],
            use_or_condition: true,
            exact_match: true,
          });
        }
      });
    const result = await Promise.all(
      selectedRow.map((item) => {
        return dispatch(
          deleteSkillCheckTrans({
            conditions: [
              {
                id: 'login_id',
                search_value: [item?.login_id],
                exact_match: true,
              },
              {
                id: 'skill_check_code',
                search_value: [item?.skill_check_code],
              },
            ],
            use_display_id: true,
            id: item.i_id_skillcheck_tid,
          })
        );
      })
    );
    const deletedItems = selectedRow.filter((item) => {
      return some(
        result,
        (res) =>
          deleteSkillCheckTrans.fulfilled.match(res) && res.meta.arg.id === item.i_id_skillcheck_tid
      );
    });
    if (deletedItems.length > 0) {
      await Promise.all(
        deletedItems.map((item) => {
          dispatch(
            editUserAssignSillCheck({
              id: item.item_ref!.implementation_status.i_id,
              data: {
                item: {
                  implementation_status: null,
                  hidden: 'off',
                },
                use_display_id: true,
                is_force_update: true,
              },
            })
          );
          dispatch(
            deleteQuestionTrans({
              conditions: [
                {
                  id: 'login_id',
                  search_value: [item?.login_id],
                  exact_match: true,
                },
                {
                  id: 'skill_check_code',
                  search_value: [item?.skill_check_code],
                },
              ],
              use_display_id: true,
            })
          );
        })
      );
    }

    await setSelectedRow([]);
    await setSelectedRowKeys([]);
    await dispatch(
      getDataTable({
        conditions,
        page: 1,
        per_page: 0,
        omit_total_items: false,
        include_item_ref: true,
        sort_fields: [
          { id: 'skill_check_code', order: 'asc' },
          { id: 'login_id', order: 'asc' },
        ],
      })
    );
    if (formik.values.implementation_setting) {
      dispatch(filterSkillImpleBySetting(formik.values.implementation_setting));
    }
    if (formik.values.implementation_status) {
      dispatch(filterSkillImpleByStatus(formik.values.implementation_status));
    }
    dispatch(stopLoading());
  };

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    rowSelections: Types.SkillCheckImpleStatus.ResponseType[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRow(rowSelections);
  };

  const rowSelection = {
    selectedRowKeys,
    selectedRow,
    onChange: onSelectChange,
    getCheckboxProps: () => {
      return {
        disabled: roleCode?.skill_check_implementation_permission.toString() === '1',
      };
    },
  };

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await Promise.all([
        dispatch(
          getDataSelectSkillCheckName({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataSelectSkillCheckGroupingCode({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getSelectUser({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getSelectDepartment({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
          })
        ),
        dispatch(
          getDataTable({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo.company_id],
              },
            ],
            page: 1,
            per_page: 0,
            omit_total_items: false,
            include_item_ref: true,
            sort_fields: [
              { id: 'skill_check_code', order: 'asc' },
              { id: 'login_id', order: 'asc' },
            ],
          })
        ),
      ]);
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getImplementTable({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <>
      <GeneralUserStyled collapsedMenu={collapsedMenu}>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <div className="form-search">
              <div className="form-first">
                <Form.Item
                  name="skill_check_code"
                  className="item"
                  label={<span className="text-label">スキルチェック</span>}
                >
                  <SelectField
                    name="skill_check_code"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    placeholder="スキルチェックで検索"
                  >
                    {dataSelectSkillCheckName?.map((item, index) => (
                      <Option key={index} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="grouping_code"
                  className="item"
                  label={<span className="text-label">分析グループ</span>}
                >
                  <SelectField
                    name="grouping_code"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    placeholder="分析グループで検索"
                  >
                    {dataSelectGroupingCode?.map((item, index) => (
                      <Option key={index} value={item.i_id}>
                        {item.grouping_code}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <div className="item date">
                  <span className="text-label">実施期間</span>
                  <DatePicker
                    allowClear
                    name="date-item"
                    className="date-input"
                    format="YYYY/MM/DD"
                    value={startPeriod ? dayjs(startPeriod) : undefined}
                    onChange={(value) => setStartPeriod(value?.format('YYYY/MM/DD'))}
                    placeholder="実施期間で検索"
                  />
                </div>
                <Form.Item
                  name="login_id"
                  className="item"
                  label={<span className="text-label">ユーザー名</span>}
                >
                  <SelectField
                    name="login_id"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    placeholder="ユーザー名で検索"
                  >
                    {dataSelectUser?.map((value, i) => (
                      <Option value={value.email} key={i}>
                        {value.name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item
                  name="department_name"
                  className="item"
                  label={<span className="text-label">所属</span>}
                >
                  <SelectField
                    name="department_name"
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      JSON.stringify(option?.children).toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    placeholder="所属で検索"
                  >
                    {dataSelectDepartment?.map((item, i) => (
                      <Option value={item.i_id} key={i}>
                        {item.name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              <div className="form-second">
                <div className="form-left">
                  <Form.Item
                    name="implementation_setting"
                    className="item"
                    label={<span className="text-label">実施ステータス</span>}
                  >
                    <SelectField
                      name="implementation_setting"
                      allowClear
                      placeholder="実施ステータスで検索"
                    >
                      <Option key={1} value="1">
                        <div className="icon-edit">
                          <img src={IconPossible} className="icon" alt="possible-icon" />
                          <span>実施可</span>
                        </div>
                      </Option>
                      <Option key={2} value="2">
                        <div className="icon-edit">
                          <img src={IconImpossible} className="icon" alt="possible-icon" />
                          <span>実施不可</span>
                        </div>
                      </Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="user_type"
                    className="item"
                    label={<span className="text-label">ユーザー種別</span>}
                  >
                    <SelectField name="user_type" allowClear placeholder="ユーザー種別で検索">
                      <Option key={1} value="member">
                        <div className="text-user-type">
                          <div className="text-icon-type interview">社</div>
                          <span>社内</span>
                        </div>
                      </Option>
                      <Option key={2} value="interview">
                        <div className="text-user-type">
                          <div className="text-icon-type member">面</div>
                          <span>面接</span>
                        </div>
                      </Option>
                    </SelectField>
                  </Form.Item>
                  <Form.Item
                    name="implementation_status"
                    className="item"
                    label={<span className="text-label">実施状況</span>}
                  >
                    <SelectField
                      name="implementation_status"
                      allowClear
                      placeholder="実施状況で検索"
                    >
                      <Option key="1" value="1">
                        <div className="icon-edit">
                          <div className="status  edit-icon">
                            <p className="text-status">未</p>
                          </div>
                          <p className="label-status">未実施</p>
                        </div>
                      </Option>
                      <Option key="0" value="0">
                        <div className="icon-stop">
                          <div className="status  stop-icon">
                            <p className="text-status">未</p>
                          </div>
                          <p className="label-status">未実施（実施不可）</p>
                        </div>
                      </Option>
                      <Option key="2" value="2">
                        <div className="icon-completed">
                          <div className="status-done  completed-icon">
                            <p className="text-status">未</p>
                          </div>
                          <p className="label-status">実施済</p>
                        </div>
                      </Option>
                    </SelectField>
                  </Form.Item>
                </div>
                <div className="form-right">
                  <SubmitButton className="btn-search" loading={false}>
                    <SearchOutlined className="icon-search" />
                    検索
                  </SubmitButton>
                  <span
                    className="label-reset"
                    onClick={() => {
                      formik.resetForm();
                      setStartPeriod(undefined);
                    }}
                  >
                    リセット
                  </span>
                </div>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <div
          className={
            dataSkillCheck.length > 0
              ? 'text-count-general'
              : 'text-count-general text-count-no-data'
          }
        >
          {(page - 1) * perPage + 1} - {''}
          {page * perPage > dataSkillCheck.length ? dataSkillCheck.length : page * perPage}
          <span className="text-static">&nbsp;</span> / {dataSkillCheck.length}
          <span className="text-static">件</span>
          <div className="page-select">
            <div className="label">
              <span>表示件数</span>：
            </div>
            <Select value={perPage} onSelect={handleSelectChange}>
              {[10, 20, 50, 100].map((value, index) => (
                <Option key={index} value={value} label={value}>
                  {value}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <Table
          className="table"
          rowSelection={rowSelection}
          dataSource={dataSkillCheck.map((item, index) => ({ ...item, index }))}
          columns={columns}
          pagination={{
            total,
            pageSize: perPage,
            onChange: setPage,
            showSizeChanger: false,
            current: page,
            position: ['topCenter'],
          }}
          rowKey="index"
        />

        <ConfirmDeleteModal
          title="スキルチェック実施履歴 削除"
          visible={visibleModalEdit}
          setVisible={setVisibleModalEdit}
          setVisibleDeleted={setVisibleModalComplete}
          subTitle="選択したスキルチェック実施履歴を削除します"
          description="削除すると、もとに戻せませんのでご注意ください。"
          onSubmit={() => handleDeleteSkillCheck()}
        />
        <ModalDeleteComplete visible={visibleModalComplete} setVisible={setVisibleModalComplete} />
        <ActionErrorModal
          visible={visiblePopupError}
          setVisible={setVisiblePopupError}
          subTitle="実施履歴が選択されていません"
          description={<>実施履歴を選択し、再度実行してください。</>}
        />
      </GeneralUserStyled>
    </>
  );
};

export default GeneralUser;
