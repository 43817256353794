import React, { useEffect, useMemo, useState } from 'react';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import { Avatar, Button, Upload, Modal } from 'antd';
import JoditEditor from 'jodit-pro-react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { createFileAttach, getSelectDepartmentUser } from '../thunk';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import { knowledgeTopSelector } from '../selectors';
import VideoPopup from '../UploadVideo';
import { adminAvatar } from 'assets';
import * as Types from 'types';

interface Props {
  action: 'create_question' | 'create_answer' | 'edit_answer' | 'edit_question';
  setFile: React.Dispatch<React.SetStateAction<UploadFile<File> | undefined>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  data?: Types.KnowledgeRecommendDetailAdditional.ResponseType;
  file: UploadFile<File> | undefined;
  onSubmit: () => void;
  text: string;
}

const { Dragger } = Upload;

const CreateQuestionAnswer: React.FC<Props> = ({
  setVisible,
  onSubmit,
  setText,
  action,
  setFile,
  file,
  data,
  text,
}) => {
  const dispatch = useAppDispatch();

  const { dataSelectDepartmentUser } = useSelector(knowledgeTopSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);

  const [showPopup, setShowPopup] = useState(false);

  const { isDisabledUpload } = useCheckUpload();

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block', 'add-new-line'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      // readonly: !isEdit && isViewMode,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const base64Array: Array<string> = useMemo(() => {
    if (!text) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [text]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '5',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = text.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      setText(replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    dispatch(
      getSelectDepartmentUser({
        conditions: [
          {
            id: 'login_id',
            search_value: [userInfo.login_id],
            exact_match: true,
          },
          {
            id: 'company_id',
            search_value: [userInfo.company_id],
          },
        ],
        page: 1,
        per_page: 0,
      })
    );
  }, [dispatch, userInfo, isUserInfoChanged]);

  return (
    <div className="comment form-add">
      {action === 'create_answer' && <div className="answer-label-additional">回答</div>}
      <div className="user">
        <Avatar
          src={
            userInfo?.avatar?.originFileObj
              ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
              : adminAvatar
          }
          shape="circle"
          className="avatar"
          size={40}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            margin: '0 24px 0 8px',
          }}
        >
          <span className="department">
            {action === 'edit_answer'
              ? data?.question_respondent_affiliation_id.replace(/^\d+ /, '')
              : action === 'edit_question'
              ? data?.questioner_affiliation_id.replace(/^\d+ /, '')
              : dataSelectDepartmentUser[0]?.department_name.replace(/^\d+ /, '')}
          </span>
          <span className="name">{userInfo?.name}</span>
        </div>
      </div>
      <div className="wrap-jodit">
        <JoditEditor
          value={text}
          config={defaultConfig}
          onBlur={(newContent) => setText(newContent)}
          onChange={(newContent) => {
            setText(newContent);
          }}
        />
        {showPopup && (
          <Modal
            open={showPopup}
            closable={false}
            onOk={handlePopupClose}
            onCancel={handlePopupClose}
            footer={null}
          >
            <VideoPopup setOpen={setShowPopup} content={text} setContent={setText} />
          </Modal>
        )}
      </div>

      <div
        className="wrap-button-add wrap-add-answer"
        style={{
          justifyContent: 'space-between',
        }}
      >
        <div className="wrap-upload">
          <div className="title">
            <span>ファイル添付</span>（PDF, Excel, Word, Jpeg, png, gif）※10MBまで
          </div>
          <div className={`form-upload ${isDisabledUpload ? 'disabled' : ''}`}>
            <div className="wrap-dragger">
              <Dragger
                showUploadList={false}
                beforeUpload={() => false}
                onChange={(e: UploadChangeParam<UploadFile<File>>) => {
                  setFile(e.file as unknown as UploadFile<File>);
                }}
              >
                <div className="file-name">
                  {file
                    ? file!.name
                    : action === 'edit_answer' && data?.question_answer_file
                    ? data?.question_answer_file.name
                    : action === 'edit_question' && data?.question_file
                    ? data?.question_file.name
                    : ''}
                </div>
              </Dragger>
            </div>
            <div className="button-upload">
              <Upload
                showUploadList={false}
                beforeUpload={() => false}
                onChange={(e: UploadChangeParam<UploadFile<File>>) => {
                  setFile(e.file as unknown as UploadFile<File>);
                }}
              >
                参照
              </Upload>
            </div>
          </div>
        </div>
        <div>
          <Button
            className={!text || text === '<p><br></p>' ? 'disable' : 'add'}
            disabled={!text || text === '<p><br></p>'}
            onClick={() => {
              onSubmit();
              setVisible(false);
              setText('');
              setFile(undefined);
            }}
          >
            {action === 'create_question' || action === 'edit_question' ? '質問する' : '回答する'}
          </Button>
          <Button
            className="cancle"
            onClick={() => {
              setVisible(false);
              setFile(undefined);
              setText('');
            }}
          >
            キャンセル
          </Button>
        </div>
      </div>
    </div>
  );
};

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];

export default CreateQuestionAnswer;
