import React from 'react';
import styled from 'styled-components';
import { DocRenderer } from '../../models';

const VideoProxyRenderer: DocRenderer = (props) => {
  const {
    mainState: { currentDocument },
    children,
  } = props;

  if (!currentDocument) return null;

  return (
    <Container {...props}>
      {children || (
        <Video controls>
          <source
            src={(currentDocument.fileData as string) || currentDocument.uri}
            type="video/mp4"
          />
        </Video>
      )}
    </Container>
  );
};

export default VideoProxyRenderer;

VideoProxyRenderer.fileTypes = [];
VideoProxyRenderer.weight = 0;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
`;

const Video = styled.video`
  max-width: 95%;
  max-height: 95%;
`;
