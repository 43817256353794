import React from 'react';
import { Select, Table, Form, Checkbox } from 'antd';
import { useSelector } from 'react-redux';

import { settingSelector } from 'containers/AppSettings/selectors';
import EducationProgramStyled from '../styles';
import { Header } from 'components';
import * as Types from 'types';

const { Option } = Select;

interface Props {
  page: number;
  perPage: number;
  dataEducationProgram: Array<Types.RequiredCurriculumSkillCheck.DataConverted>;
  dataSelectName: Array<Types.SelectEmployeeUser.ResponseType>;
  dataSelectDepartment: Array<Types.SelectDepartment.ResponseType>;
  dataSelectPosition: Array<Types.SelectPosition.ResponseType>;
  formik: Types.EducationProgramFormSearchFormik;
  checked: boolean;
}

const EducationProgramPDF: React.FC<Props> = ({
  page,
  perPage,
  dataEducationProgram,
  dataSelectName,
  dataSelectDepartment,
  dataSelectPosition,
  formik,
  checked,
}) => {
  const { collapsedMenu, headerTitle } = useSelector(settingSelector);

  const columns = [
    {
      title: '社員番号',
      dataIndex: 'employee_code',
      key: 'employee_code',
      width: '11%',
    },
    {
      title: '氏名',
      dataIndex: 'name',
      key: 'name',
      width: '11%',
      ellipsis: true,
    },
    {
      title: '所属',
      dataIndex: 'department_name',
      key: 'department_name',
      width: '12%',
      ellipsis: true,
      render: (text: string) => text?.replace(/^\d+ /, ''),
    },
    {
      title: '役職',
      dataIndex: 'position_code',
      key: 'position_code',
      width: '11%',
      ellipsis: true,
      render: (text: string) => text?.replace(/^\d+ /, ''),
    },
    {
      title: '必修カリキュラム',
      dataIndex: 'curriculum_code',
      key: 'curriculum_code',
      width: '27.5%',
      render: (curriculum_code: [{ i_id: string; name: string }]) =>
        curriculum_code && (
          <ul className="list-item">
            {curriculum_code.map((item, i) => (
              <li key={i}>{item.name}</li>
            ))}
          </ul>
        ),
    },
    {
      title: 'スキルチェック',
      dataIndex: 'skill_check_code',
      key: 'skill_check_code',
      width: '27.5%',
      render: (skill_check_code: [{ i_id: string; name: string }]) =>
        skill_check_code && (
          <ul className="list-item">
            {skill_check_code.map((item, i) => (
              <li key={i}>{item.name}</li>
            ))}
          </ul>
        ),
    },
  ];

  return (
    <>
      <Header title={headerTitle} exportPdf />
      <EducationProgramStyled
        collapsedMenu={collapsedMenu}
        dataEducationProgram={dataEducationProgram?.length > 0}
        isExportPDF
      >
        <p className="text-note">
          社内ユーザーに必修カリキュラム・スキルチェックを一括設定する画面です。
          <br />
          社内ユーザーに設定された必修カリキュラム・スキルチェックを確認する一覧画面としても活用できます。
        </p>
        <div className="border-line" />
        <Form layout="vertical">
          <div className="form-search">
            <Form.Item className="item" label={<span className="text-label">社員番号</span>}>
              <Select value={formik.employee_code}>
                {dataSelectName &&
                  dataSelectName.map((item, index) => (
                    <Option key={index} value={item.code}>
                      {item.code}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="item" label={<span className="text-label">氏名</span>}>
              <Select showSearch allowClear value={formik.name}>
                {dataSelectName &&
                  dataSelectName.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="item" label={<span className="text-label">所属</span>}>
              <Select showSearch allowClear value={formik.department_name}>
                {dataSelectDepartment &&
                  dataSelectDepartment.map((item, index) => (
                    <Option key={index} value={item.i_id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item className="item" label={<span className="text-label">役職</span>}>
              <Select showSearch allowClear value={formik.position_code}>
                {dataSelectPosition &&
                  dataSelectPosition.map((item, index) => (
                    <Option key={index} value={item.i_id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
        <>
          {dataEducationProgram.length ? (
            <div className="text-count-pdf">
              {(page - 1) * perPage + 1} - {''}
              {page * perPage > dataEducationProgram.length
                ? dataEducationProgram.length
                : page * perPage}
              <span className="text-static"></span> / {dataEducationProgram.length}
              <span className="text-static">件</span>
              <div className="page-select-pdf">
                <div className="label">
                  <span>表示件数</span>：
                </div>
                <Select value={perPage}>
                  {[10, 20, 50, 100].map((value, index) => (
                    <Option key={index} value={value} label={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
          ) : null}
        </>
        <div className="table-container">
          <div className="wrap-checkbox-pdf">
            <Checkbox checked={checked} />
            <span className="text-checkbox">実施済のスキルチェックを表示する</span>
          </div>

          <Table
            rowKey="i_id"
            className="table-pdf"
            dataSource={dataEducationProgram.map((item, index) => ({ ...item, index }))}
            columns={columns}
            pagination={false}
          />
        </div>
      </EducationProgramStyled>
    </>
  );
};

export default EducationProgramPDF;
