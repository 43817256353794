import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 860px;
  .text-center {
    text-align: center;
    color: #777777;
    font-size: 13px;
  }
  .form {
    width: 860px;
    margin-top: 30px;
    padding: 60px;
    background: #ffffff;
    box-shadow: 0 2px 10px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    .form-input {
      .wrap-card-company {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: inset 0 0 6px 1px rgba(0, 0, 0, 0.01);
        width: 25%;
        .wrap-image {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 72px;
          border: 1px solid #d9d9d9;
          border-radius: 3px;
        }
        .label-text {
          font-size: 11px;
          margin: 5px 0 0 0;
          height: 20px;
          color: #777777;
          line-height: 1;
        }
      }
      .input__disabled {
        background-color: #f9f8f8;
        color: #2a2a2a;
        height: 40px;
        cursor: not-allowed;
        input {
          cursor: not-allowed;
        }
      }
      .input_small {
        width: 83px;
      }
      .content-text {
        line-height: 160%;
      }
      .text-label-content {
        font-size: 12px;
        color: #777777;
        margin: 0 10px 0 7px;
      }
      .text-label {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 13px;
        color: #777777;
      }
    }
    .flex {
      .ant-form-item-control-input-content {
        display: flex;
        .content-text {
          font-size: 12px;
          color: #00a3a5;
          margin: 0 0 0 20px;
        }
      }
    }
    .wrap-security {
      width: 100%;
    }
  }
  .wrap-button {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
    .btn-submit {
      background: #f6ac00;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      border: none;
      width: 338px;
      height: 48px;
      cursor: pointer;
    }
    .go-back {
      margin-top: 10px;
      font-size: 13px;
      text-decoration-line: underline;
      border: none;
      background: none;
      color: #00a3a5;
      cursor: pointer;
    }
  }
`;
