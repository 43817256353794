import styled from 'styled-components';

import { HEADER_HEIGHT } from 'constant';

interface Props {
  pageYOffset: number;
}

export default styled.div<Props>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 320px;
  background: #ffffff;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  position: sticky;
  top: ${HEADER_HEIGHT}px;
  margin-top: -190px;
  right: 0;
  @media (max-width: 1439px) {
    height: calc(100vh - 15px - ${HEADER_HEIGHT}px);
  }
  .form {
    padding: 0 18px;
    .title {
      font-size: 14px;
      text-align: center;
      color: #424242;
      margin: 10px 0;
    }
    .sub-title {
      font-size: 12px;
      text-align: center;
      color: #424242;
      margin: 10px 0;
    }
    .form-input {
      margin-bottom: 5px;
      .label {
        font-size: 12px;
        color: #888888;
      }
      .select-input {
        font-family: Lato;
      }
    }
  }
  .wrap-center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .label-reset {
      cursor: pointer;
      border: none;
      background: none;
      span {
        font-size: 12px;
        text-decoration-line: underline;
        color: #00a3a5;
      }
    }
    .btn-search {
      background: #ffffff;
      border: 1px solid #00a3a5;
      box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
      border-radius: 5px;
      font-size: 14px;
      color: #00a3a5;
      cursor: pointer;
      .icon-search {
        font-size: 18px;
        margin-right: 12px;
      }
    }
  }
  .result-search {
    display: flex;
    flex-direction: row;

    height: calc(100vh - 460px);
    .left-side {
      width: 12%;
      text-align: center;
      border-right: 1px solid #e5e5e5;
      .imageQA {
        margin-top: 24px;
        font-size: 26px;
        color: #c4c4c4;
        cursor: pointer;
      }
    }
    .right-side {
      width: 88%;
      background: #f5f5f5;
      border-top: 1px solid #e5e5e5;
      .result {
        height: calc(100% - 55px);
        overflow-y: auto;
        .text-result {
          color: #bbbbbb;
          text-align: center;
          z-index: -1;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        height: 55px;
        .btn {
          border-radius: 5px;
          font-weight: bold;
          font-size: 12px;
          color: #ffffff;
          cursor: pointer;
          width: 120px;
          height: 32px;
          .icon {
            font-size: 15px;
            margin-right: 10px;
          }
        }
      }
    }
  }
`;

interface ItemProps {
  isDragging: boolean;
  isChecked: boolean;
}

export const ItemWrapper = styled.div<ItemProps>`
  background: ${(props) => (props.isChecked ? '#F1FEFE' : '#ffffff')};
  border: 1px solid ${(props) => (props.isChecked ? '#7ED6D7' : '#ffffff')};
  height: 36px;
  box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  border-radius: 2px;
  font-size: 11px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin: 6px;
  cursor: pointer;
  opacity: ${(props) => (props.isDragging ? 0.5 : 1)};
  .text-bold {
    margin-left: 5px;
    font-weight: ${(props) => (props.isChecked ? 700 : 'normal')};
    color: ${(props) => (props.isChecked ? '#00A3A5' : '#424242')};
  }
  .icon {
    color: ${(props) => (props.isChecked ? '#00A3A5' : 'unset')};
    width: 26px;
  }
`;

interface ButtonProps {
  active: boolean;
}

export const Button = styled.button<ButtonProps>`
  background: ${(props) => (props.active ? '#F6AC00' : '#d9d9d9')};
  border-radius: 4px;
  border: none;
  margin: 4px auto;
  cursor: ${(props) => (props.active ? 'pointer' : 'not-allowed')};
  .label-icon {
    color: ${(props) => (props.active ? '#FFFFFF' : '#666666')};
  }
`;

export const StylesContent = styled.div`
  padding: 6px 15px;
  .text-content {
    display: flex;
    align-items: center;
    font-family: Lato;
    font-size: 13px;
    color: #424242;
    margin: 0;
    .btn {
      background: #f6ac00;
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: none;
      margin: 0 5px;
      .label-icon {
        color: #ffffff;
      }
    }
  }
`;
