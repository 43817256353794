import { createSlice } from '@reduxjs/toolkit';
import { groupBy, orderBy } from 'lodash';

import * as Types from 'types';
import {
  getReportSkillCheck,
  getReportSkillCheckByLoginID,
  getDetailOfSkillCheckResults,
  getReportSkillCheckBySkillCheckCode,
} from './thunk';

export interface InitialState {
  reportSkillCheckBySkillCheckCode: Types.ReportSkillCheck.OneLoginIDAndOneSkillCheckCode;
  reportSkillCheckByLoginID: Types.ReportSkillCheck.OneLoginIDAndOneSkillCheckCode;
  detailOfSkillCheckResults: Types.DetailOfSkillCheckResults.ResponseType[];
  reportSkillCheck: Types.ReportSkillCheck.SurfaceTableByUserType[];
  totalReportSkillCheck: number;
}

const initialState: InitialState = {
  detailOfSkillCheckResults: [],
  totalReportSkillCheck: 0,
  reportSkillCheck: [],
  reportSkillCheckBySkillCheckCode: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
  reportSkillCheckByLoginID: {
    accuracy_rate: 0,
    correct_answers_num: 0,
    inexperienced_rate: 0,
    wrong_rate: 0,
    acquisition_score: 0,
  },
};

export const skillCheckReportSlice = createSlice({
  name: 'skillCheckReport-page',
  initialState,
  reducers: {
    resetInitialState: () => {
      return initialState;
    },
    sortDataReportSkillCheck: (state, action) => {
      const sortData = orderBy(
        state.reportSkillCheck.slice(1),
        (e) => e[action.payload.sort_field as keyof typeof Types.ReportSkillCheckUserTrans],
        [action.payload.sort_order]
      );
      state.reportSkillCheck = [state.reportSkillCheck[0], ...sortData];
    },
  },
  extraReducers(builder) {
    builder.addCase(getReportSkillCheck.fulfilled, (state, action) => {
      const dataConvert = action.payload.report_results.reduce(
        (result, item) => {
          if (
            !result.some(
              (obj) =>
                obj.login_id === item.login_id &&
                obj.skill_check_code === item.skill_check_code &&
                obj.question_code === item.question_code
            )
          ) {
            result.push(item);
          }
          return result;
        },
        [action.payload.report_results[0]]
      );
      const dataGroup = groupBy<Types.ReportSkillCheck.ResponseType>(
        dataConvert,
        action.payload.isQuestionCodeTable ? 'question_code' : 'skill_check_code'
      );
      const reportSkillCheckByUser: Types.ReportSkillCheck.SurfaceTableByUserType[] = Object.keys(
        dataGroup
      )
        .filter((key) => key !== 'undefined')
        .map((key) => {
          const items = dataGroup[key];

          const {
            answer_time,
            correct_answers_num,
            probs_count,
            responses_num,
            score,
            acquisition_score,
            correct_answers_score,
            question_score,
            time_limit,
            incorrect_answer_num,
            inexperienced,
          } = items.reduce(
            (prev, next) => ({
              correct_answers_num: prev.correct_answers_num + (next.correct === 1 ? 1 : 0),
              score: prev.score,
              probs_count: prev.probs_count,
              answer_time: prev.answer_time + (next.answer_time || 0),
              responses_num: prev.responses_num,
              acquisition_score: prev.acquisition_score,
              correct_answers_score:
                prev.correct_answers_score + next.correct_answers_num * next.score,
              time_limit: prev.time_limit,
              question_score: prev.question_score,
              incorrect_answer_num: prev.incorrect_answer_num + (next.correct ? 0 : 1),
              inexperienced: prev.inexperienced + (next.correct === -1 ? 1 : 0),
            }),
            {
              correct_answers_num: 0,
              score: items[0]?.score || 0,
              probs_count: items[0]?.probs_count || 0,
              time_limit: items[0]?.time_limit!,
              responses_num: items[0]?.responses_num,
              acquisition_score: items[0]?.acquisition_score || 0,
              correct_answers_score: 0,
              question_score: items[0]?.question_score,
              answer_time: 0,
              incorrect_answer_num: 0,
              inexperienced: 0,
            }
          );

          return {
            user_name: items[0].user_name,
            login_id: items[0].login_id,
            question_score,
            skill_check_name: items[0].skill_check_name,
            skill_check_code: items[0].skill_check_code,
            question_code: items[0].question_code,
            question_name: items[0].question_name,
            question_id: items[0].item_ref?.question_name?.i_id,
            correct_answers_num,
            incorrect_answer: incorrect_answer_num,
            inexperienced,
            probs_count,
            accuracy_rate:
              correct_answers_num / (inexperienced + correct_answers_num + incorrect_answer_num),
            progress_rate:
              responses_num / (inexperienced + correct_answers_num + incorrect_answer_num),
            wrong_rate:
              incorrect_answer_num / (inexperienced + correct_answers_num + incorrect_answer_num),
            inexperienced_rate:
              inexperienced / (inexperienced + correct_answers_num + incorrect_answer_num),
            answer_time,
            average_answer_time_per_question: answer_time / items.length,
            score,
            time_limit,
            acquisition_score,
            correct_answers_score,
            responses_num,
            total: inexperienced + correct_answers_num + incorrect_answer_num,
            acquisition_score_rate: acquisition_score / score,
            implementation_date: items[0].implementation_date,
          };
        });

      const dataLength = reportSkillCheckByUser.length;

      const {
        accuracy_rate,
        answer_time,
        average_answer_time_per_question,
        correct_answers_num,
        incorrect_answer,
        inexperienced,
        inexperienced_rate,
        probs_count,
        score,
        wrong_rate,
        acquisition_score,
        time_limit,
        correct_answers_score,
        responses_num,
        total,
        question_score,
      } = reportSkillCheckByUser.reduce(
        (prev, next) => ({
          correct_answers_num: prev.correct_answers_num + next.correct_answers_num,
          accuracy_rate: prev.accuracy_rate + next.accuracy_rate,
          answer_time: prev.answer_time + next.answer_time,
          average_answer_time_per_question:
            prev.average_answer_time_per_question + next.average_answer_time_per_question,
          incorrect_answer: prev.incorrect_answer + next.incorrect_answer,
          inexperienced: prev.inexperienced + next.inexperienced,
          inexperienced_rate: prev.inexperienced_rate + next.inexperienced_rate,
          probs_count: prev.probs_count + next.probs_count,
          score: prev.score + next.score,
          wrong_rate: prev.wrong_rate + next.wrong_rate,
          acquisition_score: prev.acquisition_score + next.acquisition_score,
          time_limit: prev.time_limit + next.time_limit,
          correct_answers_score: prev.correct_answers_score + next.correct_answers_score,
          responses_num: prev.responses_num + next.responses_num,
          question_score: prev.question_score + (next.question_score || 0),
          total: prev.total + (next.total || 0),
        }),
        {
          correct_answers_num: 0,
          accuracy_rate: 0,
          answer_time: 0,
          average_answer_time_per_question: 0,
          incorrect_answer: 0,
          inexperienced: 0,
          inexperienced_rate: 0,
          probs_count: 0,
          score: 0,
          wrong_rate: 0,
          acquisition_score: 0,
          time_limit: 0,
          correct_answers_score: 0,
          responses_num: 0,
          total: 0,
          question_score: 0,
        }
      );

      state.reportSkillCheck = [
        {
          user_name: '',
          skill_check_name: '',
          login_id: '',
          skill_check_code: '',
          question_code: '',
          question_name: '',
          question_id: '',
          correct_answers_num: correct_answers_num,
          incorrect_answer: incorrect_answer,
          inexperienced: inexperienced,
          probs_count: probs_count / dataLength,
          accuracy_rate: accuracy_rate / dataLength,
          progress_rate: responses_num / dataLength,
          wrong_rate: wrong_rate / dataLength,
          inexperienced_rate: inexperienced_rate / dataLength,
          answer_time: answer_time / dataLength,
          average_answer_time_per_question: average_answer_time_per_question / dataLength,
          score: score / dataLength,
          time_limit: time_limit / dataLength,
          acquisition_score: acquisition_score / dataLength,
          acquisition_score_rate: acquisition_score / score / dataLength,
          correct_answers_score: correct_answers_score / dataLength,
          responses_num: responses_num / dataLength,
          total: total,
          question_score: question_score / dataLength,
        },
        ...reportSkillCheckByUser,
      ];
      state.totalReportSkillCheck = dataLength;
    });

    builder.addCase(getReportSkillCheckByLoginID.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            probs_count: prev.probs_count + (next.probs_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            acquisition_score: prev.acquisition_score || 0,
          }),
          {
            correct_answers_num: 0,
            probs_count: 0,
            responses_num: 0,
            acquisition_score: action.payload.report_results[0]?.acquisition_score!,
          }
        );
      state.reportSkillCheckByLoginID = {
        correct_answers_num,
        accuracy_rate: correct_answers_num / probs_count || 0,
        wrong_rate: (responses_num - correct_answers_num) / probs_count || 0,
        inexperienced_rate: (probs_count - responses_num) / probs_count || 0,
        acquisition_score,
      };
    });

    builder.addCase(getReportSkillCheckBySkillCheckCode.fulfilled, (state, action) => {
      const { correct_answers_num, probs_count, responses_num, acquisition_score } =
        action.payload.report_results.reduce(
          (prev, next) => ({
            correct_answers_num: prev.correct_answers_num + (next.correct_answers_num || 0),
            probs_count: prev.probs_count + (next.probs_count || 0),
            responses_num: prev.responses_num + (next.responses_num || 0),
            acquisition_score: prev.acquisition_score + (next.acquisition_score || 0),
          }),
          {
            correct_answers_num: 0,
            probs_count: 0,
            responses_num: 0,
            acquisition_score: 0,
          }
        );
      const length = action.payload.report_results.length;
      state.reportSkillCheckBySkillCheckCode = {
        correct_answers_num: correct_answers_num / length,
        accuracy_rate: correct_answers_num / length / probs_count || 0,
        wrong_rate: (responses_num - correct_answers_num) / length / probs_count || 0,
        inexperienced_rate: (probs_count - responses_num) / length / probs_count || 0,
        acquisition_score: acquisition_score / length,
      };
    });

    builder.addCase(getDetailOfSkillCheckResults.fulfilled, (state, action) => {
      const accuracy_rate =
        action.payload.report_results.filter((result) => result.correct === 1).length /
        action.payload.report_results.length;
      const average_answer_time =
        action.payload.report_results.reduce(
          (previousValue, currentValue) => previousValue + (Number(currentValue.answer_time) || 0),
          0
        ) / action.payload.report_results.length;

      state.detailOfSkillCheckResults = action.payload.report_results.map((item) => ({
        ...item,
        question_id: item.item_ref?.name.i_id,
        accuracy_rate,
        average_answer_time,
        acquisition_score: item.correct === 1 ? item.score : 0,
      }));
    });
  },
});

export const { resetInitialState, sortDataReportSkillCheck } = skillCheckReportSlice.actions;

export default skillCheckReportSlice.reducer;
