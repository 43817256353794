import { createSlice } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import * as Types from 'types';
import { getUserAssignCurriculum, getDataUserReport } from './thunk';

export interface InitialState {
  dataReportCurriculumUser: Array<Types.ReportCurriculumUser.UserReportSurfaceTable>;
  userAssignCurriculum: Array<Types.UserAssignRequiredCurriculum.ResponseType>;
  dataUserReport: Array<Types.ReportCurriculumUser.DataUserReport>;
  questionsAssign: Array<Types.QuestionAssignLevel.ResponseType>;
  levelQuestions: Array<Types.QuestionAssignLevel.ResponseType>;
  selectCurriculums: Array<Types.Curriculums.ResponseType>;
  selectLevel1: Array<Types.SelectLevel.ResponseType>;
  selectLevel2: Array<Types.SelectLevel.ResponseType>;
  selectLevel3: Array<Types.SelectLevel.ResponseType>;
  selectLevel4: Array<Types.SelectLevel.ResponseType>;
  curriculums: Array<Types.Curriculums.ResponseType>;
  questions: Array<Types.Questions.ResponseType>;
  selectUsers: Array<Types.Users.ResponseType>;
  level1s: Array<Types.Level1s.ResponseType>;
  level2s: Array<Types.Level2s.ResponseType>;
  level3s: Array<Types.Level3s.ResponseType>;
  level4s: Array<Types.Level4s.ResponseType>;
  totalReportCurriculumUser: number;
}

const initialState: InitialState = {
  totalReportCurriculumUser: 0,
  dataReportCurriculumUser: [],
  userAssignCurriculum: [],
  selectCurriculums: [],
  questionsAssign: [],
  levelQuestions: [],
  dataUserReport: [],
  selectLevel1: [],
  selectLevel2: [],
  selectLevel3: [],
  selectLevel4: [],
  selectUsers: [],
  curriculums: [],
  questions: [],
  level1s: [],
  level2s: [],
  level3s: [],
  level4s: [],
};

export const trainingReportSlice = createSlice({
  name: 'trainingReport-page',
  initialState,
  reducers: {
    resetData(state) {
      state.totalReportCurriculumUser = 0;
      state.dataReportCurriculumUser = [];
    },
    sortDataReportUser: (state, action) => {
      const sortData = orderBy(
        state.dataReportCurriculumUser.slice(1),
        (e) => e[action.payload.sort_field as keyof typeof Types.ReportCurriculumUser],
        [action.payload.sort_order]
      );
      state.dataReportCurriculumUser = [state.dataReportCurriculumUser[0], ...sortData];
    },
    sortDataReportUserTableChart: (state, action) => {
      const sortData = orderBy(
        state.dataReportCurriculumUser.slice(1),
        (e) => e.progress_rate - e.accuracy_rate,
        [action.payload.sort_order]
      );
      state.dataReportCurriculumUser = [state.dataReportCurriculumUser[0], ...sortData];
    },

    setDataLevel: (state, action) => {
      switch (action.payload.level) {
        case 1:
          state.selectLevel1 = action.payload.dataLevel;
          break;
        case 2:
          state.selectLevel2 = action.payload.dataLevel;
          break;
        case 3:
          state.selectLevel3 = action.payload.dataLevel;
          break;
        case 4:
          state.selectLevel4 = action.payload.dataLevel;
          break;
        case 5:
          state.levelQuestions = action.payload.dataLevel;
          break;
        default:
          break;
      }
    },
    setDataReportCurriculumUser: (state, action) => {
      state.dataReportCurriculumUser = action.payload.data;
      state.totalReportCurriculumUser = action.payload.length;
    },
    setSelectCurriculums: (state, action) => {
      state.selectCurriculums = action.payload.data;
    },
    setSelectUsers: (state, action) => {
      state.selectUsers = action.payload.data;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserAssignCurriculum.fulfilled, (state, action) => {
      state.userAssignCurriculum = action.payload.items;
    });

    builder.addCase(getDataUserReport.fulfilled, (state, action) => {
      state.questionsAssign = action.payload.dataQuesAssign;
      state.curriculums = action.payload.dataCurriculums;
      state.questions = action.payload.dataQuestions;
      state.dataUserReport = action.payload.items;
      state.level1s = action.payload.dataLevel1s;
      state.level2s = action.payload.dataLevel2s;
      state.level3s = action.payload.dataLevel3s;
      state.level4s = action.payload.dataLevel4s;
    });
  },
});

export const {
  sortDataReportUserTableChart,
  setDataReportCurriculumUser,
  setSelectCurriculums,
  sortDataReportUser,
  resetInitialState,
  setSelectUsers,
  setDataLevel,
  resetData,
} = trainingReportSlice.actions;

export default trainingReportSlice.reducer;
