import styled from 'styled-components';

export default styled.section`
  position: relative;
  height: calc(100% - 56px);
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f9f8f8;
  .title {
    font-size: 14px;
    text-align: center;
    color: #424242;
    padding-top: 65px;
  }
  .item {
    width: 80%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: relative;
    .item-image {
      display: flex;
      justify-content: center;
      max-height: 360px;
      border-radius: 2px 2px 0px 0px;
      background: #f4f4f4;
      .wrap-image {
        width: 640px;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #e1dcdc;
        .image {
          max-width: 100%;
          height: auto;
          max-height: 360px;
          object-fit: contain;
        }
        .wrap-no-image {
          display: flex;
          flex-direction: column;
          align-items: center;
          .text {
            color: #6f95aa;
            font-size: 22px;
            font-weight: 700;
          }
        }
      }
    }
    .image-logo {
      position: absolute;
      top: 18px;
      left: 20px;
    }
    .title-item {
      font-size: 20px;
      text-align: center;
      color: #2a2a2a;
      margin: 0;
      padding: 35px 0 15px 0;
    }
    .item-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 60px 10px 60px;
      .requirements {
        background: rgba(8, 163, 165, 0.1);
        padding: 10px 20px;
        font-size: 16px;
        color: #08a3a5;
      }
      .content-requirements {
        padding-left: 30px;
      }
      .execution-time {
        display: flex;
        flex-direction: row;
        padding: 5px 0px;
        .text-label {
          margin: 0;
          font-size: 18px;
          color: #424242;
        }
      }
      .time-detail {
        display: flex;
        flex-direction: row;
      }
    }
    .content {
      margin: 0 60px 60px 60px;
      border: 3px solid #e1e1e1;
      border-radius: 2px;
      .text-content {
        padding: 25px 25px;
        font-size: 14px;
        color: #424242;
      }
    }
    .time-detail-child {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      color: #777777;
      align-items: center;
      .icon {
        margin-right: 5px;
      }
      .text-label {
        margin: 0;
        font-size: 18px;
        color: #424242;
      }
      .label {
        margin: 0;
      }
    }
    .time-detail-child-center {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      padding: 5px 80px;
      color: #777777;
      align-items: center;
      .icon {
        margin-right: 5px;
      }
      .text-label {
        margin: 0;
        font-size: 18px;
        color: #424242;
      }
      .label {
        margin: 0;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      .btn-start {
        width: 240px;
        height: 48px;
        background: #f6ac00;
        box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
        border-radius: 5px;
        border: none;
        font-weight: 700;
        font-size: 18px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }
  .btn-back {
    margin: 50px auto;
    button {
      width: 120px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      background: #fff;
      box-shadow: 1px 1px 4px 0px rgba(68, 68, 68, 0.2);
      border: none;
      font-size: 13px;
      text-align: center;
      color: #777777;
      cursor: pointer;
    }
  }
`;
