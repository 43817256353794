import React, { useEffect, useRef, useState } from 'react';
import { DeleteOutlined, EyeInvisibleOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import { generatePath, useNavigate } from 'react-router-dom';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import { Select, Avatar } from 'antd';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

import { setHeaderTitle, startLoading, stopLoading } from 'containers/AppSettings/slice';
import { Header, Input, InputPassword, SelectField } from 'components';
import { settingSelector } from 'containers/AppSettings/selectors';
import { CurriculumBatchModal, SkillCheckModal } from '../Modal';
import ActionErrorModal from 'components/Modal/ActionError';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { createEmployeeSchema } from 'libs/validations';
import CompletedModal from 'components/Modal/Completed';
import { AFFILIATION_ASSIGN_ROLE } from 'configs';
import { employeeSelector } from '../selectors';
import { CREDIT_CARD_MONTH } from 'constant';
import CreateEmployeeStyled from './styles';
import { routes } from 'navigations/routes';
import * as Types from 'types';
import {
  addLinkAffiliation,
  addUser,
  createAffiliationAssignLevel,
  createAffiliationRole,
  createEmployee,
  createUserAssignCurriculum,
  createUserAssignSkillCheck,
  getDataAllGmail,
  getSelectDepartment,
  getSelectPosition,
  getSelectRole,
} from '../thunk';

const { Option } = Select;

const CreateEmployee: React.FC = () => {
  const [visibleCurriculumBatchModal, setVisibleCurriculumBatchModal] = useState<boolean>(false);
  const [dataSkillCheck, setDataSkillCheck] = useState<Array<Types.SkillCheck.ResponseType>>([]);
  const [visibleSkillCheckModal, setVisibleSkillCheckModal] = useState<boolean>(false);
  const [showActionErrorModal, setShowActionErrorModal] = useState<boolean>(false);
  const [confirmStatus, setConfirmStatus] = useState<boolean>(false);
  const [visibleCompleted, setVisibleCompleted] = useState(false);
  const [dataCurriculumBatch, setDataCurriculumBatch] = useState<
    Array<Types.Curriculums.ResponseType>
  >([]);
  const [birthDay, setBirthDay] = useState<{
    day: string;
    month: string;
    year: string;
  }>({
    day: '',
    month: '',
    year: '',
  });
  const [dateJoinedCompany, setDateJoinedCompany] = useState<{
    month: string;
    year: string;
  }>({
    month: '',
    year: '',
  });

  const { collapsedMenu, headerTitle } = useSelector(settingSelector);
  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { dataSelectPosition, dataSelectDepartment, dataSelectRole, dataAllGmail } =
    useSelector(employeeSelector);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const topRef = useRef<HTMLDivElement>(null);

  const handleSelectDateJoinedCompany = (type: 'month' | 'year') => (value: string) => {
    if (type === 'month') {
      setDateJoinedCompany((prevState) => ({ ...prevState, month: value }));
      formik.setFieldValue('doj', `${dateJoinedCompany.year}${value}`);
    } else {
      setDateJoinedCompany((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('doj', `${value}${dateJoinedCompany.month}`);
    }
  };

  const handleSelectBirthDay = (type: 'month' | 'year' | 'day') => (value: string) => {
    if (type === 'month') {
      setBirthDay((prevState) => ({ ...prevState, month: value, day: '' }));
      formik.setFieldValue('dob', `${birthDay.year}${value}`);
    } else if (type === 'year') {
      setBirthDay((prevState) => ({ ...prevState, year: value }));
      formik.setFieldValue('dob', `${value}${birthDay.month}${birthDay.day}`);
    } else {
      setBirthDay((prevState) => ({ ...prevState, day: value }));
      formik.setFieldValue('dob', `${birthDay.year}${birthDay.month}${value}`);
    }
  };

  const formik = useFormik<Types.CreateEmployeeFormik>({
    initialValues: {
      employee_code: '',
      name: '',
      name_furigana: '',
      email: '',
      email_confirm: '',
      password: '',
      password_confirm: '',
      role_code: '',
      dob: '',
      doj: '',
      main_position: {
        affiliation_id: '',
        affiliation_code: '',
        positions_id: '',
        positions_code: '',
      },
      sub_position: [
        {
          affiliation_id: '',
          affiliation_code: '',
          positions_id: '',
          positions_code: '',
        },
      ],
    },
    validationSchema: createEmployeeSchema,
    validateOnBlur: false,
    validate: (values) => {
      const errors: Partial<Types.CreateEmployeeFormik> = {};
      if (isEmailDuplicate(values.email, dataAllGmail)) {
        errors.email = 'このメールアドレスはすでに存在します。';
      }

      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      if (confirmStatus) {
        dispatch(startLoading());
        let fileAction = '';
        const resultAddUserAction = await dispatch(
          addUser({
            user_code: values.email,
            email: values.email,
            username: values.name,
            passowrd: values.password,
            email_template_id: '621ec0914f2f5cb7f71d0c58',
          })
        );
        if (
          addUser.fulfilled.match(resultAddUserAction) &&
          (resultAddUserAction.payload.added || !resultAddUserAction.payload.exists)
        ) {
          if (values.avatar) {
            // const formData = new FormData();
            // formData.append('filename', values.avatar?.name);
            // formData.append('file', new Blob([values.avatar as unknown as Blob]));
            // const resultActionsUploadFileToS3 = await dispatch(updateFileToS3({ formData }));

            // if (updateFileToS3.fulfilled.match(resultActionsUploadFileToS3)) {
            //   fileAction = resultActionsUploadFileToS3;
            //   const resultActionCreateImages = await dispatch(
            //     createImage({
            //       item: {
            //         fileID: resultActionsUploadFileToS3.payload.file_id,
            //         filename: values.avatar?.name,
            //       },
            //     })
            //   );
            //   if (createImage.fulfilled.match(resultActionCreateImages)) {
            //     await dispatch(
            //       executeAction({
            //         itemId: resultActionCreateImages.payload.item_id,
            //         data: {
            //           changes: [
            //             {
            //               id: 'file',
            //               value: [resultActionsUploadFileToS3.payload.file_id],
            //             },
            //           ],
            //           use_display_id: true,
            //           is_force_update: true,
            //         },
            //       })
            //     );
            //   }
            // }
            fileAction = await uploadFileToMinIO(values.avatar);
          }
          const resultAction2 = await Promise.all([
            dispatch(
              createAffiliationRole({
                item: {
                  company_id: userInfo?.company_id,
                  affiliation_id: values.main_position.affiliation_id,
                  login_id: values.email,
                  positions_code: values.main_position.positions_id,
                  sort_order: 1,
                  main_role: 'main',
                  createdby: userInfo?.login_id,
                  createdat: new Date(),
                },
              })
            ),
            dispatch(
              createAffiliationAssignLevel({
                item: {
                  company_id: userInfo?.company_id,
                  affiliation_id: values.main_position.affiliation_code,
                  login_id: values.email,
                  sort_order: 1,
                  createdby: userInfo?.login_id,
                  createdat: new Date(),
                },
              })
            ),
          ]);
          if (
            createAffiliationAssignLevel.fulfilled.match(resultAction2[1]) &&
            createAffiliationRole.fulfilled.match(resultAction2[0])
          ) {
            await dispatch(
              addLinkAffiliation({
                id: resultAction2[1].payload.item_id,
                data: {
                  link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                  link_item_id: resultAction2[0].payload.item_id,
                },
              })
            );
          }
          const resultAction = await Promise.all([
            dispatch(
              createEmployee({
                item: {
                  employee_code: values.employee_code,
                  company_id: userInfo?.company_id,
                  login_id: values.email,
                  email: values.email,
                  name: values.name,
                  name_furigana: values.name_furigana,
                  user_type: 'member',
                  dob: values.dob ? dayjs(values.dob).toDate() : undefined,
                  doj: values.doj ? dayjs(values.doj).toDate() : undefined,
                  role_code: values.role_code,
                  password: values.password,
                  createdby: userInfo?.login_id,
                  createdat: new Date(),
                  ...(!isEmpty(fileAction) ? { icon_fileID: fileAction } : {}),
                },
              })
            ),
            ...values.sub_position.map(
              async ({ affiliation_id, positions_id, affiliation_code }, index) => {
                if (affiliation_id && positions_id) {
                  const [affiliationRoleResult, affiliationAssignLevelResult] = await Promise.all([
                    dispatch(
                      createAffiliationRole({
                        item: {
                          affiliation_id,
                          positions_code: positions_id,
                          login_id: values.email,
                          sort_order: index + 2,
                          company_id: userInfo?.company_id,
                          createdby: userInfo?.login_id,
                          createdat: new Date(),
                        },
                      })
                    ),
                    dispatch(
                      createAffiliationAssignLevel({
                        item: {
                          company_id: userInfo?.company_id,
                          affiliation_id: affiliation_code,
                          login_id: values.email,
                          sort_order: index + 2,
                          createdby: userInfo?.login_id,
                          createdat: new Date(),
                        },
                      })
                    ),
                  ]);

                  if (
                    affiliationRoleResult &&
                    affiliationAssignLevelResult &&
                    createAffiliationRole.fulfilled.match(affiliationRoleResult) &&
                    createAffiliationAssignLevel.fulfilled.match(affiliationAssignLevelResult)
                  ) {
                    await dispatch(
                      addLinkAffiliation({
                        id: affiliationAssignLevelResult.payload.item_id,
                        data: {
                          link_datastore_id: AFFILIATION_ASSIGN_ROLE.id,
                          link_item_id: affiliationRoleResult.payload.item_id,
                        },
                      })
                    );
                  }
                }
              }
            ),
            ...dataCurriculumBatch.map(({ code }) =>
              dispatch(
                createUserAssignCurriculum({
                  item: {
                    curricullum_code: code,
                    login_id: values.email,
                    company_id: userInfo?.company_id,
                    hidden: 'off',
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              )
            ),
            ...dataSkillCheck.map(({ code }) =>
              dispatch(
                createUserAssignSkillCheck({
                  item: {
                    skill_check_code: code,
                    login_id: values.email,
                    company_id: userInfo?.company_id,
                    hidden: 'off',
                    createdby: userInfo?.login_id,
                    createdat: new Date(),
                  },
                })
              )
            ),
          ]);
          if (createEmployee.fulfilled.match(resultAction[0])) {
            setVisibleCompleted(true);
          }
        } else {
          setShowActionErrorModal(true);
        }
        dispatch(stopLoading());
      } else {
        setConfirmStatus(true);
      }
      setSubmitting(false);
    },
  });

  const isEmailDuplicate = (email: string, allEmails: Array<string>) => {
    return allEmails.includes(email);
  };

  const handleDeleteItemCurriculumBatch = (id: string) =>
    setDataCurriculumBatch(dataCurriculumBatch.filter(({ i_id }) => i_id !== id));

  const handleDeleteItemSkillCheck = (id: string) =>
    setDataSkillCheck(dataSkillCheck.filter(({ i_id }) => i_id !== id));

  useEffect(() => {
    if (userInfo && isUserInfoChanged) {
      (async () => {
        dispatch(startLoading());
        await Promise.all([
          dispatch(
            getSelectPosition({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectDepartment({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getSelectRole({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
          dispatch(
            getDataAllGmail({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo.company_id],
                },
              ],
              page: 1,
              per_page: 0,
            })
          ),
        ]);
        dispatch(stopLoading());
      })();
    }
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    dispatch(
      setHeaderTitle(confirmStatus ? '社内ユーザー新規登録確認画面' : '社内ユーザー新規登録')
    );
  }, [confirmStatus, dispatch]);

  useEffect(() => {
    if (confirmStatus && topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [confirmStatus]);

  return (
    <>
      <Header title={headerTitle} />
      <CreateEmployeeStyled collapsedMenu={collapsedMenu} ref={topRef}>
        {!confirmStatus ? (
          <p className="text-sub-title">
            社内ユーザーマスタの新規登録を行う画面です。
            <br />
            情報を入力後に登録ボタンをクリックしてください。
          </p>
        ) : (
          <p className="text-sub-title">
            この内容でよければ「登録」ボタンをクリックしてください。
            <br />
            入力したメールアドレス宛に登録完了のメールが送信されます。
          </p>
        )}

        <FormikProvider value={formik}>
          <Form layout="vertical" autoComplete="off">
            <div className="wrap-basic-information">
              <div className="header">基本情報</div>
              <div className="body">
                <div className="wrap-user">
                  <div className="avatar-user">
                    {formik.values.avatar ? (
                      <Avatar
                        size={200}
                        src={URL.createObjectURL(
                          new Blob([formik.values.avatar as unknown as Blob])
                        )}
                        alt="avatar"
                      />
                    ) : (
                      <>
                        <UserOutlined className="icon-user" />
                        <span className="text-content">
                          プロフィール画面で設定した
                          <br />
                          画像が反映されます
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="form-left">
                  <Form.Item
                    name="employee_code"
                    label={
                      <span className="text-label">
                        社員番号<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="employee_code"
                      className="text-input"
                      placeholder="半角数字：最大10文字"
                      readOnly={confirmStatus}
                      maxLength={10}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label={
                      <span className="text-label">
                        氏名<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name"
                      className="text-input"
                      placeholder="全角：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="name_furigana"
                    label={
                      <span className="text-label">
                        氏名（フリガナ）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="name_furigana"
                      className="text-input"
                      placeholder="全角カナ：最大100文字"
                      readOnly={confirmStatus}
                      maxLength={100}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label={
                      <span className="text-label">
                        メールアドレス <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email"
                      type="email"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      autoComplete="off"
                      readOnly={confirmStatus}
                      maxLength={300}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email_confirm"
                    label={
                      <span className="text-label">
                        メールアドレス（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <Input
                      name="email_confirm"
                      className="text-input"
                      placeholder="＠を含む半角英数字：最大300文字"
                      readOnly={confirmStatus}
                      maxLength={300}
                    />
                  </Form.Item>
                </div>
                <div className="form-right">
                  <Form.Item
                    name="dob"
                    label={
                      <span className="text-label">
                        生年月日 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      placeholder="----"
                      className="pull_down input_small"
                      onChange={handleSelectBirthDay('year')}
                      allowClear
                      value={birthDay.year || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 100,
                        },
                        (_, i) => (i + (new Date().getFullYear() - 100)).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('month')}
                      placeholder="--"
                      allowClear
                      value={birthDay.month || undefined}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月</span>
                    <Select
                      className="pull_down input-month-day"
                      onChange={handleSelectBirthDay('day')}
                      placeholder="--"
                      allowClear
                      value={birthDay.day || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from({
                        length: dayjs(`${birthDay.year}-${birthDay.month}`).daysInMonth(),
                      }).map((_item, index) => (
                        <Option
                          key={index}
                          value={(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        >
                          {(index + 1).toLocaleString('en-US', {
                            minimumIntegerDigits: 2,
                            useGrouping: false,
                          })}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">日</span>
                  </Form.Item>
                  <Form.Item
                    name="doj"
                    label={
                      <span className="text-label not-require">
                        入社年月 <span className="require" />
                      </span>
                    }
                    className="form-input"
                  >
                    <Select
                      onChange={handleSelectDateJoinedCompany('year')}
                      className="pull_down input_small"
                      placeholder="----"
                      allowClear
                      value={dateJoinedCompany.year || undefined}
                      disabled={confirmStatus}
                    >
                      {Array.from(
                        {
                          length: 21,
                        },
                        (_, i) => (i + new Date().getFullYear() - 20).toString()
                      ).map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">年</span>
                    <Select
                      onChange={handleSelectDateJoinedCompany('month')}
                      className="pull_down input-month-day"
                      placeholder="--"
                      allowClear
                      value={dateJoinedCompany.month || undefined}
                      disabled={confirmStatus}
                    >
                      {CREDIT_CARD_MONTH.map((item, index) => (
                        <Option key={index} value={item}>
                          {item}
                        </Option>
                      ))}
                    </Select>
                    <span className="text-label-content">月 </span>
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label={
                      <span className="text-label">
                        パスワード <span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      autoComplete="new-password"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      name="password"
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="password_confirm"
                    label={
                      <span className="text-label">
                        パスワード（確認）<span className="require">*</span>
                      </span>
                    }
                    className="form-input"
                  >
                    <InputPassword
                      name="password_confirm"
                      iconRender={(visible) =>
                        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
                      }
                      className="text-input"
                      placeholder="半角英数字、記号：４〜30文字"
                      readOnly={confirmStatus}
                    />
                  </Form.Item>
                  <Form.Item
                    name="role_code"
                    className="pull_down"
                    label={
                      <span className="text-label">
                        権限 <span className="require">*</span>
                      </span>
                    }
                  >
                    <SelectField
                      name="role_code"
                      className="pull_down"
                      placeholder="選択してください"
                      allowClear
                      disabled={confirmStatus}
                    >
                      {dataSelectRole?.map(({ i_id, name }) => (
                        <Option value={i_id} key={i_id}>
                          {name}
                        </Option>
                      ))}
                    </SelectField>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="wrap-department">
              <div className="header">所属・役職情報</div>
              <div className="body">
                <p className="description">
                  所属・役職情報を設定・編集することができます。兼務枠を増やす場合は兼務所属・役職を追加するボタンをクリックしてください。
                </p>
                <span className="label">メイン所属・役職</span>
                <div className="wrap-main-position">
                  <div className="form-row">
                    <div className="item">
                      <Form.Item
                        name="main_position.affiliation_id"
                        label={
                          <span className="text-label">
                            メイン所属 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <Select
                          className="pull_down"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.affiliation_code',
                              dataSelectDepartment.find((item) => item.i_id === value)
                                ?.affiliation_id
                            );
                            formik.setFieldValue('main_position.affiliation_id', value);
                          }}
                        >
                          {dataSelectDepartment?.map(({ i_id, name }) => (
                            <Option value={i_id} key={i_id}>
                              {name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="item">
                      <Form.Item
                        name="main_position.positions_id"
                        label={
                          <span className="text-label">
                            メイン役職 <span className="require">*</span>
                          </span>
                        }
                        className="form-input"
                      >
                        <Select
                          className="pull_down"
                          placeholder="選択してください"
                          disabled={confirmStatus}
                          onChange={(value: string) => {
                            formik.setFieldValue(
                              'main_position.positions_code',
                              dataSelectPosition.find((item) => item.i_id === value)?.position_code
                            );
                            formik.setFieldValue('main_position.positions_id', value);
                          }}
                        >
                          {dataSelectPosition &&
                            dataSelectPosition.map(({ i_id, name }) => (
                              <Option value={i_id} key={i_id}>
                                {name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <span className="label">兼務所属・役職</span>
                <FieldArray
                  name="sub_position"
                  render={({ remove, push }) => (
                    <div>
                      <div className="wrap-sub-position">
                        {formik.values.sub_position.map((_, index) => (
                          <div className="form-row" key={index}>
                            <div className="item">
                              <Form.Item
                                name={`sub_position.${index}.affiliation_id`}
                                label={<span className="text-label">兼務所属 {index + 1}</span>}
                                className="form-input"
                              >
                                <Select
                                  className="pull_down"
                                  placeholder="選択してください"
                                  value={formik.values.sub_position[index].affiliation_id || ''}
                                  disabled={confirmStatus}
                                  allowClear={index === 0}
                                  onClear={() => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_code`,
                                      ''
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_id`,
                                      ''
                                    );
                                  }}
                                  onChange={(value: string) => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_code`,
                                      dataSelectDepartment.find((item) => item.i_id === value)
                                        ?.affiliation_id
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_id`,
                                      value
                                    );
                                  }}
                                >
                                  <Option value="">未選択</Option>
                                  {dataSelectDepartment
                                    ?.filter(
                                      (affiliation) =>
                                        !formik.values.sub_position.some(
                                          (position, someIndex) =>
                                            position.affiliation_id === affiliation.i_id &&
                                            someIndex !== index
                                        ) &&
                                        formik.values.main_position.affiliation_id !==
                                          affiliation.i_id
                                    )
                                    .map(({ i_id, name }, i) => (
                                      <Option value={i_id} key={i}>
                                        {name}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </div>
                            <div className="item">
                              <Form.Item
                                name={`sub_position.${index}.positions_id`}
                                label={<span className="text-label">兼務役職{index + 1}</span>}
                                className="form-input"
                              >
                                <Select
                                  className="pull_down"
                                  placeholder="選択してください"
                                  value={formik.values.sub_position[index].positions_id || ''}
                                  disabled={confirmStatus}
                                  allowClear={index === 0}
                                  onClear={() => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.positions_code`,
                                      ''
                                    );
                                    formik.setFieldValue(`sub_position.${index}.positions_id`, '');
                                  }}
                                  onChange={(value: string) => {
                                    formik.setFieldValue(
                                      `sub_position.${index}.positions_code`,
                                      dataSelectPosition.find((item) => item.i_id === value)
                                        ?.position_code
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.positions_id`,
                                      value
                                    );
                                  }}
                                >
                                  <Option value="">未選択</Option>
                                  {dataSelectPosition?.map(({ i_id, name }, i) => (
                                    <Option value={i_id} key={i}>
                                      {name}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                            {!confirmStatus && (
                              <DeleteOutlined
                                className="icon-delete"
                                onClick={() => {
                                  if (index === 0) {
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_code`,
                                      ''
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.affiliation_id`,
                                      ''
                                    );
                                    formik.setFieldValue(
                                      `sub_position.${index}.positions_code`,
                                      ''
                                    );
                                    formik.setFieldValue(`sub_position.${index}.positions_id`, '');
                                  } else {
                                    remove(index);
                                  }
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                      {formik.values.sub_position.length < 10 && !confirmStatus && (
                        <button
                          className="button"
                          type="button"
                          onClick={() => {
                            push({
                              affiliation_id: '',
                              positions_code: '',
                            });
                            formik.setFieldTouched(
                              `sub_position.${formik.values.sub_position.length}.affiliation_id`
                            );
                            formik.setFieldTouched(
                              `sub_position.${formik.values.sub_position.length}.positions_id`
                            );
                          }}
                        >
                          ＋ 兼務所属・役職を追加
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
            </div>

            <div className="wrap-curriculum-setting">
              <div className="header">必修カリキュラム設定</div>
              <div className="body">
                <p className="description">
                  必修カリキュラムを設定することができます。必修カリキュラムを追加ボタンをクリックしてください。
                </p>
                {dataCurriculumBatch && !!dataCurriculumBatch.length ? (
                  dataCurriculumBatch.map(({ name, i_id }, index) => (
                    <div key={index} className={`item-result${confirmStatus ? ' disabled' : ''}`}>
                      <div>
                        <span className="text-add">{confirmStatus ? index + 1 : '＋'}</span>
                        <span className="content">{name}</span>
                      </div>
                      {!confirmStatus && (
                        <DeleteOutlined
                          className="delete"
                          onClick={() => handleDeleteItemCurriculumBatch(i_id)}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="empty-text">未設定</div>
                )}
                {!confirmStatus && (
                  <button
                    className="button"
                    type="button"
                    onClick={() => setVisibleCurriculumBatchModal(true)}
                  >
                    ＋ 必修カリキュラムを追加
                  </button>
                )}
              </div>
            </div>
            <div className="wrap-skill-check">
              <div className="header">スキルチェック設定状況</div>
              <div className="body">
                <p className="description">
                  スキルチェックを設定することができます。スキルチェックを追加ボタンをクリックしてください。
                </p>
                {dataSkillCheck && !!dataSkillCheck.length ? (
                  dataSkillCheck.map(({ name, i_id }, index) => (
                    <div key={index} className={`item-result${confirmStatus ? ' disabled' : ''}`}>
                      <div>
                        <span className="text-add">{confirmStatus ? index + 1 : '＋'}</span>
                        <span className="content">{name}</span>
                      </div>
                      {!confirmStatus && (
                        <DeleteOutlined
                          className="delete"
                          onClick={() => handleDeleteItemSkillCheck(i_id)}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="empty-text">未設定</div>
                )}
                {!confirmStatus && (
                  <button
                    className="button"
                    type="button"
                    onClick={() => setVisibleSkillCheckModal(true)}
                  >
                    ＋ スキルチェックを追加
                  </button>
                )}
              </div>
            </div>
            <div className="wrap-submit">
              <div className="wrap-button">
                <SubmitButton className="btn btn_submit">
                  {confirmStatus ? '登録' : '確認画面へ'}
                </SubmitButton>
                <button
                  className="btn btn_close"
                  type="button"
                  onClick={() =>
                    confirmStatus ? setConfirmStatus(false) : navigate(routes.Employee.path)
                  }
                >
                  キャンセル
                </button>
              </div>
            </div>
          </Form>
        </FormikProvider>
        <CompletedModal
          title="登録が完了しました"
          visible={visibleCompleted}
          setVisible={setVisibleCompleted}
          onSubmit={() => navigate(generatePath(routes.Employee.path, { entity: 'receiving' }))}
        />
        <ActionErrorModal
          visible={showActionErrorModal}
          setVisible={setShowActionErrorModal}
          onSubmit={() => setConfirmStatus(false)}
          subTitle="Email exists"
          description="Email exists"
        />
        <SkillCheckModal
          visible={visibleSkillCheckModal}
          setVisible={setVisibleSkillCheckModal}
          onSubmit={(item) => setDataSkillCheck([...dataSkillCheck, ...item])}
        />
        <CurriculumBatchModal
          visible={visibleCurriculumBatchModal}
          setVisible={setVisibleCurriculumBatchModal}
          onSubmit={(item) => setDataCurriculumBatch([...dataCurriculumBatch, ...item])}
        />
      </CreateEmployeeStyled>
    </>
  );
};

export default CreateEmployee;
