import { Document, Page, View, Text, Font } from '@react-pdf/renderer';

import NotosanJP from 'assets/fonts/NotoSansJP-Regular.otf';

import * as Types from 'types';
import styles from './styles';

Font.register({ family: 'NotosanJP', src: NotosanJP as string });

interface IItem {
  label: string;
  checked: number;
}

interface IDataPage {
  title: string;
  items: IItem[];
}

const textItems = [
  { text: '編集権限', value: 2 },
  { text: '閲覧権限', value: 1 },
  { text: '非表示', value: 0 },
];

const pageSize = 'A4';
const orientation = 'landscape';

const PDFDocument = ({ role }: { role: Types.Roles.ResponseType }) => {
  const dataFirstPage: IDataPage[] = [
    {
      title: 'Home',
      items: [
        {
          label: '管理者ダッシュボード',
          checked: role.admin_dashboard_permission,
        },
      ],
    },
    {
      title: 'スキルチェック',
      items: [
        {
          label: 'スキルチェック実施状況',
          checked: role.skill_check_implementation_permission,
        },
      ],
    },
    {
      title: 'レポート',
      items: [
        {
          label: 'トレーニングレポート',
          checked: role.training_report_permission,
        },
        {
          label: 'スキルチェックレポート',
          checked: role.skill_check_report_permission,
        },
        {
          label: 'MYカルテ',
          checked: role.my_chart_permission,
        },
      ],
    },
    {
      title: 'マニュアル・ナレッジ',
      items: [
        {
          label: 'マニュアル',
          checked: role.manual_permission,
        },
        {
          label: 'ナレッジ',
          checked: role.knowledge_permission,
        },
      ],
    },
    {
      title: 'OFFICIALカリキュラム',
      items: [
        {
          label: 'OFFICIALカリキュラム',
          checked: role.official_curriculum_permission,
        },
      ],
    },
  ];

  const dataSecondPage: IDataPage[] = [
    {
      title: 'カリキュラム管理',
      items: [
        {
          label: 'カリキュラムマスタ',
          checked: role.curricullum_master_permission,
        },
        {
          label: '設問マスタ',
          checked: role.question_master_permission,
        },
      ],
    },
    {
      title: 'スキルチェック管理',
      items: [
        {
          label: 'スキルチェックマスタ',
          checked: role.skill_check_master_permission,
        },
        {
          label: '面接ユーザーマスタ',
          checked: role.inter_users_master_permission,
        },
        {
          label: '分析グループマスタ',
          checked: role.groups_master_permission,
        },
      ],
    },
    {
      title: 'ユーザー管理',
      items: [
        {
          label: '社内ユーザーマスタ',
          checked: role.users_master_permission,
        },
        {
          label: '権限マスタ',
          checked: role.roles_master_permission,
        },
        {
          label: '所属マスタ',
          checked: role.departments_master_permission,
        },
        {
          label: '役職マスタ',
          checked: role.positions_master_permission,
        },
        {
          label: '管理者マスタ',
          checked: role.admin_master_permission,
        },
      ],
    },
    {
      title: 'その他',
      items: [
        {
          label: '支払マスタ',
          checked: role.payment_master_permission,
        },
        {
          label: 'ご利用状況',
          checked: role?.report_view_permission!,
        },
      ],
    },
  ];

  const renderItems = (items: IItem[]) => {
    return items.map((item, index) => (
      <View style={styles.item} key={index}>
        <View style={styles.wrapLabel}>
          <Text style={styles.label}>{item.label}</Text>
        </View>
        <View style={styles.wrapRadio}>
          {textItems.map((textItem, textIndex) => (
            <View style={styles.radioItem} key={textIndex}>
              <View
                style={[
                  styles.radioButton,
                  { borderColor: Number(item.checked) === textItem.value ? '#1890ff' : '#ebebeb' },
                ]}
              >
                {Number(item.checked) === textItem.value ? (
                  <View style={styles.radioSelected} />
                ) : null}
              </View>
              <Text style={styles.textItem}>{textItem.text}</Text>
              <View />
            </View>
          ))}
        </View>
      </View>
    ));
  };

  const renderDataPage = (dataPages: IDataPage[]) => {
    return dataPages.map((data, index) => (
      <View key={index}>
        <Text style={styles.title}>{data.title}</Text>
        <View style={styles.wrapItem}>{renderItems(data.items)}</View>
      </View>
    ));
  };

  return (
    <Document>
      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.textHeader}>権限マスタ</Text>
        </View>

        <View style={styles.subscription}>
          <Text style={styles.textSubscription}>権限マスタの編集を行う画面です。</Text>
          <Text style={styles.textSubscription}>編集完了後に更新ボタンをクリックしてください</Text>
        </View>
        <View style={styles.lineBorder} />

        <View style={styles.body}>{renderDataPage(dataFirstPage)}</View>
      </Page>

      <Page size={pageSize} orientation={orientation} style={styles.page}>
        <View style={styles.body}>{renderDataPage(dataSecondPage)}</View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
