import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, unionBy } from 'lodash';

import * as Types from 'types';
import {
  getCurriculumQuestionOption,
  getCreatorQuestionOption,
  getCurriculumLevelOption,
  getUnrelatedQuestions,
  getSelectQuestion,
  getListQuestion,
} from './thunk';

interface InitialState {
  unrelatedQuestions: Array<Types.UnrelatedQuestions.ResponseType>;
  creatorOption: Array<Types.SelectQuestionCreator.ResponseType>;
  nodeLevel4Selected?: Types.TreeItem<Types.CurriculumItemType>;
  curriculumOption: Array<Types.SelectCurriculums.ResponseType>;
  questionSearch: Array<Types.TreeTraining>;
  questionSelect: Array<Types.SelectQuestion.ResponseType>;
  level1Option: Array<Types.SelectLevel.ResponseType>;
  level2Option: Array<Types.SelectLevel.ResponseType>;
  level3Option: Array<Types.SelectLevel.ResponseType>;
  level4Option: Array<Types.SelectLevel.ResponseType>;
}

const initialState: InitialState = {
  unrelatedQuestions: [],
  curriculumOption: [],
  questionSearch: [],
  questionSelect: [],
  creatorOption: [],
  level1Option: [],
  level2Option: [],
  level3Option: [],
  level4Option: [],
};

export const questionSearchSlice = createSlice({
  name: 'questionSearch-Slice',
  initialState,
  reducers: {
    filterCurriculum: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(
          state.questionSearch,
          (e) => e.curriculum_code === action.payload
        );
      }
    },
    filterLevel1: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(
          state.questionSearch,
          (e) => e.level1_code === action.payload
        );
      }
    },
    filterLevel2: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(
          state.questionSearch,
          (e) => e.level2_code === action.payload
        );
      }
    },
    filterLevel3: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(
          state.questionSearch,
          (e) => e.level3_code === action.payload
        );
      }
    },
    filterLevel4: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(
          state.questionSearch,
          (e) => e.level4_code === action.payload
        );
      }
    },
    filterCreatedBy: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(state.questionSearch, (e) => e.creator === action.payload);
      }
    },
    filterQuestionName: (state, action) => {
      if (action.payload) {
        state.questionSearch = filter(state.questionSearch, (e) =>
          e.question_name.includes(action.payload)
        );
      }
    },
    selectNodeLevel4(
      state,
      action: PayloadAction<Types.CurriculumItemType & { maxSortOrder?: number }>
    ) {
      state.nodeLevel4Selected = action.payload;
    },
    removeNodeLevel4Selected(state) {
      state.nodeLevel4Selected = undefined;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListQuestion.fulfilled, (state, action) => {
      state.questionSearch = action.payload.items;
    });
    builder.addCase(getUnrelatedQuestions.fulfilled, (state, action) => {
      state.unrelatedQuestions = action.payload.report_results;
    });
    builder.addCase(getCreatorQuestionOption.fulfilled, (state, action) => {
      state.creatorOption = action.payload.report_results;
    });
    builder.addCase(getCurriculumQuestionOption.fulfilled, (state, action) => {
      state.curriculumOption = action.payload.report_results;
    });
    builder.addCase(getCurriculumLevelOption.fulfilled, (state, action) => {
      const dataReport = unionBy(action.payload.data.report_results, 'name');
      switch (action.payload.level) {
        case 1:
          state.level1Option = dataReport;
          break;
        case 2:
          state.level2Option = dataReport;
          break;
        case 3:
          state.level3Option = dataReport;
          break;
        default:
          state.level4Option = dataReport;
          break;
      }
    });

    builder.addCase(getSelectQuestion.fulfilled, (state, action) => {
      state.questionSelect = action.payload.report_results;
    });
  },
});

export const {
  resetInitialState,
  selectNodeLevel4,
  removeNodeLevel4Selected,
  filterCurriculum,
  filterLevel1,
  filterLevel2,
  filterLevel3,
  filterLevel4,
  filterCreatedBy,
  filterQuestionName,
} = questionSearchSlice.actions;

export default questionSearchSlice.reducer;
