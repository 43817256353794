import styled from 'styled-components';

export default styled.section`
  .table-header {
    display: flex;
    position: absolute;
    justify-content: space-between;
    align-items: center;
    font-family: Lato;
    font-size: 14px;
    color: #424242;
    width: 60%;
    right: 0;
    top: 0;

    .ant-pagination-total-text {
      position: absolute;
      right: 0;
      .text-total-record-table {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Lato;
        font-size: 14px;
        color: #424242;
        .text-static {
          font-size: 11px;
          padding-left: 3px;
        }
      }
    }
    .ant-pagination {
      .ant-pagination-options {
        position: absolute;
        right: 150px;

        ::before {
          content: '表示件数：';
          font-size: 11px;
          padding-right: 3px;
        }
      }
    }
  }
  .table {
    margin-top: 15px;
    .custom-empty-text {
      padding: 50px 0;
    }
    .ant-table-thead .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr:first-child .ant-table-cell {
      font-size: 12px;
      padding: 15px 7px;
      text-align: center !important;

      .ant-table-column-sorters {
        justify-content: center;
        .ant-table-column-title {
          flex: none;
        }
      }
    }
    .ant-table-tbody .ant-table-cell {
      padding-left: 8px;
      padding-right: 8px;
    }
    .ant-table-thead tr .average {
      background: #f4fbfb !important;
    }
    .br-1 {
      border-right: 1px solid rgba(85, 85, 85, 0.18) !important;
    }
    .ant-table-tbody .ant-table-cell .icon {
      font-size: 18px;
      color: #c4c4c4;
    }
    .ant-table-bordered .ant-table-thead > tr:nth-of-type(2) > th {
      background: white;
      font-weight: 700;
      font-size: 14px;
      color: #424242;
    }
    .table-row-light {
      background: #ffffff;
    }
    .table-row-dark {
      background: #f9f9f9;
    }
  }
  .text-left {
    text-align: left;
    margin: 0;
  }
`;
