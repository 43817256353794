import React from 'react';
import ReactPlayer from 'react-player';

import * as Types from 'types';

const NodeRenderer: React.FC<{
  node: Types.TreeItem<Types.CurriculumItemType>;
}> = ({ node }) => {
  return (
    <div>
      <div>
        {node.node?.columnIndex ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 700,
              color: '#2A2A2A',
              paddingLeft: node.node?.columnIndex ? `${node.node?.columnIndex * 15}px` : '0px',
            }}
          >
            <span>{node.node?.name || '（空白）'}</span>
          </div>
        ) : null}
        {node.node?.columnIndex === 5 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: '13px',
              color: '#2A2A2A',
              paddingLeft: '90px',
            }}
          >
            <span>{node.node?.question_description}</span>
            <div style={{ display: 'flex', flexDirection: 'row', margin: '10px 0' }}>
              {node.node?.attach_fileID1?.includes('mp4') && node.node?.attach_fileID1 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID1}`}
                    controls
                  />
                </div>
              ) : (node.node?.attach_fileID1?.includes('png') ||
                  node.node?.attach_fileID1?.includes('jpg') ||
                  node.node?.attach_fileID1?.includes('jpeg')) &&
                node.node?.attach_fileID1 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <img
                    src={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID1}`}
                    alt=""
                    style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                  />
                </div>
              ) : null}
              {node.node?.attach_fileID2?.includes('mp4') && node.node?.attach_fileID2 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID2}`}
                    controls
                  />
                </div>
              ) : (node.node?.attach_fileID2?.includes('png') ||
                  node.node?.attach_fileID2?.includes('jpg') ||
                  node.node?.attach_fileID2?.includes('jpeg')) &&
                node.node?.attach_fileID2 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <img
                    src={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID2}`}
                    alt=""
                    style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                  />
                </div>
              ) : null}
              {node.node?.attach_fileID3?.includes('mp4') && node.node?.attach_fileID3 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <ReactPlayer
                    width={'100%'}
                    height={'100%'}
                    url={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID3}`}
                    controls
                  />
                </div>
              ) : (node.node?.attach_fileID3?.includes('png') ||
                  node.node?.attach_fileID3?.includes('jpg') ||
                  node.node?.attach_fileID3?.includes('jpeg')) &&
                node.node?.attach_fileID3 ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '80px',
                    width: '128px',
                    margin: '0 6px',
                    backgroundColor: '#efefef',
                  }}
                >
                  <img
                    src={`https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${node.node.attach_fileID3}`}
                    alt=""
                    style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NodeRenderer;
