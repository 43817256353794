import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCompanies, getUserSearch } from './thunk';
import { AdministratorMasterFormik } from 'types';

export interface InitialState {
  information: AdministratorMasterFormik;
}

const initialState: InitialState = {
  information: {
    plan_name: '',
    number_of_users: 0,
    name: '',
    name_furigana: '',
    postal_code: '',
    prefecture: '',
    address: '',
    plot_number: '',
    building_name: '',
    admin_name: '',
    admin_name_furigana: '',
    admin_email_confirm: '',
    admin_department: '',
    admin_position: '',
    admin_phone: '',
    admin_email: '',
  },
};

export const AdministratorMasterSlice = createSlice({
  name: 'AdministratorMaster-container',
  initialState,
  reducers: {
    setInformationRegister(state, action: PayloadAction<AdministratorMasterFormik>) {
      state.information = action.payload;
    },
    resetInitialState: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getUserSearch.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        number_of_users: action.payload.totalItems,
        admin_name: action.payload.items[0]?.name,
        admin_name_furigana: action.payload.items[0]?.name_furigana,
        admin_email: action.payload.items[0]?.email,
        admin_email_confirm: action.payload.items[0]?.email,
      };
    });
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.information = {
        ...state.information,
        i_id: action.payload.items[0]?.i_id,
        plan_name: action.payload.items[0]?.plan_id,
        name: action.payload.items[0]?.name,
        name_furigana: action.payload.items[0]?.name_furigana,
        postal_code: action.payload.items[0]?.postal_code,
        prefecture: action.payload.items[0]?.prefecture,
        address: action.payload.items[0]?.address,
        plot_number: action.payload.items[0]?.plot_number,
        admin_position: action.payload.items[0]?.admin_position,
        admin_department: action.payload.items[0]?.admin_department,
        building_name: action.payload.items[0]?.building_name,
        admin_phone: action.payload.items[0]?.admin_phone,
      };
    });
  },
});

export const { setInformationRegister, resetInitialState } = AdministratorMasterSlice.actions;
export default AdministratorMasterSlice.reducer;
