import React from 'react';
import { PasswordProps } from 'formik-antd';

import SelectStyled from './styles';

const InputPassword: React.FC<PasswordProps> = ({ ...props }) => {
  return <SelectStyled suffix={<span />} {...props} />;
};

export default InputPassword;
