import React, { useEffect, useRef, useState } from 'react';
import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import dayjs from 'dayjs';

import { memoizedGetQuestionsFromTree } from 'libs/utils/trainingCurriculum/memoized-tree-data-utils';
import { trainingCurriculumSelector } from 'pages/TrainingCurriculum/selectors';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { authSelector } from 'containers/Auth/selectors';
import TooltipRowContent from '../TooltipRowContent';
import GlobalStyle, { Wrapper } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';
import {
  resetDataCreate,
  resetDataEdit,
  setQuestionSelected,
} from 'pages/TrainingCurriculum/slice';
import {
  getDataCompulsoryCurriculumImple,
  getDataCorrectQues,
  createCurriculumTrans,
  editCurriculumTrans,
  getCurriculumImple,
} from 'pages/TrainingCurriculum/thunk';

const NodeRenderer: React.FC<{
  index: number;
  accept: string;
  tabActive: number;
  nodeHeight: number;
  countCorrect: number;
  setStartTime?: React.Dispatch<string>;
  node: Types.TreeItem<Types.TrainingCurriculumItemType>;
  nodeInitial?: Types.TreeItem<Types.TrainingCurriculumItemType>;
  setChangeTab: React.Dispatch<React.SetStateAction<number>>;
  setNumberQuestionImplement: React.Dispatch<React.SetStateAction<number>>;
  type?: string;
  isIndex?: number;
}> = ({
  node,
  nodeHeight,
  setChangeTab,
  tabActive,
  setStartTime,
  setNumberQuestionImplement,
  nodeInitial,
  type,
  isIndex,
}) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [lineClass, setLineClass] = useState<string>('');
  const [clientY, setClientY] = useState<number | string>(300);
  const [questions, setQuestions] = useState<Types.TreeItem<Types.TrainingCurriculumItemType>[]>(
    []
  );

  const { questionSelected } = useSelector(trainingCurriculumSelector);
  const { userInfo } = useSelector(authSelector);

  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const handleSubmit = async () => {
    dispatch(startLoading());
    const resultAction1 = await (!tabActive
      ? dispatch(
          getCurriculumImple({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [node!.node?.curriculum_code],
              },
            ],
            page: 1,
            per_page: 0,
            use_display_id: true,
            omit_total_items: false,
            include_item_ref: true,
          })
        )
      : dispatch(
          getDataCompulsoryCurriculumImple({
            conditions: [
              {
                id: 'curriculum_code',
                search_value: [node!.node?.curriculum_code],
              },
            ],
            page: 1,
            per_page: 0,
            use_display_id: true,
            omit_total_items: false,
            include_item_ref: true,
          })
        ));
    if (
      !node.node?.curriculum_trans_id &&
      (getDataCompulsoryCurriculumImple.fulfilled.match(resultAction1) ||
        getCurriculumImple.fulfilled.match(resultAction1)) &&
      resultAction1.payload.totalItems > 0
    ) {
      const resultAction2 = await dispatch(
        createCurriculumTrans({
          item: {
            login_id: userInfo?.login_id,
            curricullum_code: node.node!.curriculum_code,
            responses_num: 0,
            correct_answers_num: 0,
            implementation_date: new Date(),
            tran_status: 0,
            createdat: new Date(),
            createdby: userInfo?.login_id,
            company_id: userInfo?.company_id,
            acquisition_score: 0,
          },
          return_item_result: true,
          return_display_id: true,
        })
      );
      dispatch(resetDataEdit());
      if (createCurriculumTrans.fulfilled.match(resultAction2)) {
        setChangeTab(1);
        const resultQuestionImplement = await dispatch(
          getDataCorrectQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo?.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_code',
                search_value: [node.node?.curriculum_code],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        );
        if (
          getDataCorrectQues.fulfilled.match(resultQuestionImplement) &&
          resultQuestionImplement.payload.items.length
        ) {
          setNumberQuestionImplement(resultQuestionImplement.payload.items.length);
        }
      }
    }
    if (node.node?.curriculum_trans_id && node.node!.i_id_item_ref_curriculum_trans) {
      const resultAction3 = await dispatch(
        editCurriculumTrans({
          id: node.node!.i_id_item_ref_curriculum_trans,
          data: {
            item: {
              implementation_date: new Date(),
            },
            is_force_update: true,
            return_item_result: true,
            use_display_id: true,
          },
        })
      );
      dispatch(resetDataCreate());
      if (editCurriculumTrans.fulfilled.match(resultAction3) && questionSelected.length) {
        setChangeTab(1);
        const resultQuestionImplement = await dispatch(
          getDataCorrectQues({
            conditions: [
              {
                id: 'company_id',
                search_value: [userInfo?.company_id],
              },
              {
                id: 'login_id',
                search_value: [userInfo?.login_id],
                exact_match: true,
              },
              {
                id: 'curriculum_code',
                search_value: [node.node?.curriculum_code],
              },
            ],
            page: 1,
            per_page: 0,
            include_item_ref: true,
          })
        );
        if (
          getDataCorrectQues.fulfilled.match(resultQuestionImplement) &&
          resultQuestionImplement.payload.items.length
        ) {
          setNumberQuestionImplement(resultQuestionImplement.payload.items.length);
        }
      }
    }
    dispatch(stopLoading());
    const currentTime = dayjs().toISOString();
    setStartTime && setStartTime(currentTime);
  };

  useEffect(() => {
    if (node.columnIndex === 1) {
      if (node.lineIndex === 1) {
        setLineClass('rst__lineHalfHorizontalLeftParentNode rst__lineHalfVerticalTopParentNode');
      } else {
        setLineClass('rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop');
      }
    } else if (node.columnIndex! > 1) {
      if (node.lineIndex === node.parentNode?.lineIndex) {
        setLineClass('rst__lineHalfHorizontalLeft');
      } else {
        setLineClass('rst__lineHalfHorizontalLeftVerticalTop rst__lineHalfVerticalTop');
      }
    }

    setQuestions(
      memoizedGetQuestionsFromTree({
        treeData: node.node,
      })
    );
  }, [node, nodeInitial]);

  return (
    <Wrapper className="rst__node" nodeHeight={nodeHeight} node={node}>
      <div className="rst__nodeContent">
        <div className={'rst__lineBlock ' + lineClass}>
          <div ref={ref} className="rowWrapper">
            <div className="row">
              <Popover
                overlayClassName="popover-tab-custom"
                overlayStyle={{
                  width: node.node?.columnIndex === 5 ? 280 : 260,
                  zIndex: 98,
                }}
                placement="bottomLeft"
                getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                content={TooltipRowContent({
                  node,
                  visible,
                  handleSubmit,
                })}
                trigger="click"
                onVisibleChange={(v) => {
                  setVisible(v);
                  dispatch(setQuestionSelected(questions));
                }}
                visible={visible}
              >
                <div className="rowContents">
                  {node.node?.columnIndex === 0 ? (
                    <div className="group-title hover">
                      {type ? (
                        <div className="tooltip" id="tooltip-hover">
                          <GlobalStyle clientY={clientY} isIndex={isIndex} />
                          <Tooltip
                            title={node.node?.description}
                            overlayClassName={`tooltip-group-${isIndex}`}
                            onOpenChange={() =>
                              (window.onmousemove = (e) => {
                                const tooltipSpan = document.getElementById('tooltip-hover');
                                const x = e.clientX;
                                if (tooltipSpan) {
                                  const leftOffset = type === 'tabActive-0' ? x - 80 : x - 20;
                                  tooltipSpan.style.left = `${leftOffset}px`;
                                  setClientY(leftOffset.toString());
                                }
                              })
                            }
                          >
                            <div className="item-name">
                              {node.node?.official_curriculum_code && (
                                <span className="official">OFFICIAL</span>
                              )}
                              <span className="rowTitle">{node.node?.name || '（空白）'}</span>
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        <Tooltip placement="top" title={node.node?.description}>
                          <span className="rowTitle">{node.node?.name || '（空白）'}</span>
                        </Tooltip>
                      )}

                      {questions.filter((item) => item.correct_true).length === questions.length ? (
                        <CheckCircleFilled className="icon" />
                      ) : (
                        <span className="count">
                          {questions.filter((item) => item.correct_true).length}{' '}
                          <small>問正解</small> /{questions.length}
                          <small>問中</small>
                        </span>
                      )}
                    </div>
                  ) : (
                    <div
                      className={
                        'item' +
                        (node.node?.columnIndex !== 5 &&
                        questions.filter((item) => item.correct_true).length === questions.length
                          ? 'check'
                          : node.node?.columnIndex === 5 && node.node?.correct_true
                          ? 'circle'
                          : node.node?.columnIndex === 5 && node.node?.correct_true === 0
                          ? 'close'
                          : '')
                      }
                    >
                      <span className={`rowTitle ${node.node?.name ? '' : 'rowTitleNone'}`}>
                        {node.node?.name || '（空白）'}
                      </span>
                      {node.node?.columnIndex !== 5 &&
                      questions.filter((item) => item.correct_true).length === questions.length &&
                      questions.length !== 0 ? (
                        <CheckCircleFilled className="icon" />
                      ) : node.node?.columnIndex === 5 && node.node?.correct_true ? (
                        <div className="item-circle" />
                      ) : node.node?.columnIndex === 5 && node.node?.correct_true === 0 ? (
                        <CloseOutlined className="icon-close" />
                      ) : null}
                    </div>
                  )}
                </div>
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NodeRenderer;
