import React, { useCallback } from 'react';

export interface ReceivedProps {
  isOpen: boolean;
  onSubmit: () => void;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useConfirmCreateUser = (props: ReceivedProps) => {
  const { setIsOpen, onSubmit } = props;

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleSubmit = useCallback(() => {
    onSubmit();
    handleClose();
  }, [onSubmit, handleClose]);

  return {
    ...props,
    handleClose,
    handleSubmit,
  };
};

export type Props = ReturnType<typeof useConfirmCreateUser>;

export default useConfirmCreateUser;
