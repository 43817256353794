import styled from 'styled-components';

export default styled.section`
  text-align: center;
  .icon-checked {
    font-size: 66px;
    color: #08a3a5;
    margin-bottom: 32px;
    margin-top: 19px;
  }
  .content {
    font-size: 13px;
    color: #424242;
    font-weight: 400;
  }
`;
