import React from 'react';
import { FormikProvider, useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { Form } from 'formik-antd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { getAffiliations, updateAffiliation } from '../../thunk';
import { createEditAffiliationSchema } from 'libs/validations';
import { authSelector } from 'containers/Auth/selectors';
import { Input, Modal } from 'components';
import { SectionStyled } from './styles';
import { useAppDispatch } from 'hooks';
import * as Types from 'types';

interface Props {
  visible: boolean;
  data: Types.TreeItem<Types.AffiliationItemType>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Edit: React.FC<Props> = ({ data, setVisible, visible }) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useSelector(authSelector);

  const formik = useFormik<Types.CreateEditAffiliationFormik>({
    initialValues: {
      name: data.name || '',
    },
    validationSchema: createEditAffiliationSchema,
    validateOnBlur: false,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      if (data.i_id) {
        dispatch(startLoading());
        const resultAction = await dispatch(
          updateAffiliation({
            id: data.i_id,
            data: {
              item: {
                name: values.name,
              },
              is_force_update: true,
            },
          })
        );
        if (updateAffiliation.fulfilled.match(resultAction)) {
          await dispatch(
            getAffiliations({
              conditions: [
                {
                  id: 'company_id',
                  search_value: [userInfo?.company_id],
                },
              ],
              page: 1,
              per_page: 0,
              include_lookups: true,
              include_item_ref: true,
            })
          );
          resetForm();
          onClose();
        }
        dispatch(stopLoading());
      }
    },
  });

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="所属 編集"
      visible={visible}
      width={720}
      okButton={{
        text: '更新',
        onClick: formik.handleSubmit,
        loading: formik.isSubmitting,
        disabled: !formik.isValid || !formik.dirty,
      }}
      cancelButton={{
        text: '閉じる',
        onClick: onClose,
      }}
      onCancel={onClose}
      headerStyle={{
        borderBottom: '1px solid #CCCCCC',
      }}
      bodyStyle={{
        backgroundColor: '#f9f8f8',
      }}
      footerStyle={{
        backgroundColor: '#f9f8f8',
      }}
    >
      <SectionStyled>
        <p className="sub-title">
          所属の編集が可能です。編集完了後、更新ボタンをクリックしてください。
        </p>
        <FormikProvider value={formik}>
          <Form
            layout="vertical"
            labelCol={{
              flex: '22%',
            }}
            colon={false}
            className="form"
          >
            <Form.Item
              name="code"
              label={<span className="text-label">所属コード</span>}
              className="form-input"
            >
              <Input
                name="code"
                className="input"
                type="text"
                value={data.affiliation_id}
                readOnly
              />
            </Form.Item>
            <Form.Item
              name="name"
              label={
                <span className="text-label">
                  所属名称 <span className="require">*</span>
                </span>
              }
              className="form-input"
            >
              <Input
                name="name"
                className="input"
                type="text"
                placeholder="全角：最大30文字"
                showCount
                maxLength={30}
              />
            </Form.Item>
          </Form>
        </FormikProvider>
      </SectionStyled>
    </Modal>
  );
};

export default Edit;
