import React from 'react';
import { CheckOutlined } from '@ant-design/icons';
import { useDrag } from 'react-dnd';

import { ItemWrapper } from './styles';
import * as Types from 'types';

interface Props {
  item: Types.Users.ResponseType;
  usersSelected: Array<Types.Users.ResponseType>;
  setUsersSelected: React.Dispatch<React.SetStateAction<Array<Types.Users.ResponseType>>>;
  skillCheckSelected?: Types.SkillCheckTreeViewType;
}

const Item: React.FC<Props> = ({ item, usersSelected, setUsersSelected, skillCheckSelected }) => {
  const handleSelect = () => {
    setUsersSelected((prevState) => {
      if (prevState.some((i) => i.i_id === item.i_id)) {
        return prevState.filter((i) => i.i_id !== item.i_id);
      } else {
        return [...prevState, item];
      }
    });
  };

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'move-user',
      item,
    },
    canDrag: Boolean(skillCheckSelected) && usersSelected.some((i) => i.i_id === item.i_id),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <ItemWrapper
      ref={drag}
      isDragging={isDragging}
      isChecked={usersSelected.some((i) => i.i_id === item.i_id)}
      onClick={handleSelect}
    >
      {usersSelected.some((i) => i.i_id === item.i_id) ? (
        <CheckOutlined className="icon" />
      ) : (
        <div className="icon" />
      )}
      <span className="text-code">{item.lookup_items?.department_code?.code} </span>
      <span className="text-bold">{item.name} </span>
    </ItemWrapper>
  );
};

export default Item;
