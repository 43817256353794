import React, { useEffect, useState } from 'react';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Avatar, Dropdown } from 'antd';
import { useDrag } from 'react-dnd';

import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { ItemDropDownStyled, StyledPullDown } from './styles';
import { UserAffiliation } from 'types/services/affiliation';
import { authSelector } from 'containers/Auth/selectors';
import { useAppDispatch } from 'hooks';
import { adminAvatar } from 'assets';
import {
  deleteLinkAffiliationLevel,
  deleteLinkAffiliationRole,
  getAffiliations,
} from 'pages/Settings/AffiliationMaster/thunk';
import { sharedFileInMinIO } from 'services/minioService';
import { extractFileName } from 'libs/utils/format';

interface Props {
  index: number;
  user: UserAffiliation;
  affiliation_id?: string;
  setOpenDetail?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ItemDropDown: React.FC<Props> = ({ index, user, affiliation_id, setOpenDetail }) => {
  const [visibleDropDown, setVisibleDropDown] = useState<boolean>(false);
  const [avatarUrl, setAvatarUrl] = useState<string>();

  const { userInfo } = useSelector(authSelector);

  const dispatch = useAppDispatch();

  const [{ opacity }, drag] = useDrag({
    item: {
      user,
      affiliation_id,
      type: 'user-department',
    },
    begin: () => {
      if (setOpenDetail) {
        setOpenDetail(false);
      }
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const handleDeleteUser = async () => {
    if (!user.item_ref) return;
    dispatch(startLoading());
    await Promise.all([
      dispatch(
        deleteLinkAffiliationLevel({
          id: user.item_ref.user_sort_order.i_id,
        })
      ),
      dispatch(
        deleteLinkAffiliationRole({
          id: user.item_ref.user_role_order.i_id,
        })
      ),
    ]);
    await dispatch(
      getAffiliations({
        conditions: [
          {
            id: 'company_id',
            search_value: [userInfo?.company_id],
          },
        ],
        page: 1,
        per_page: 0,
        include_lookups: true,
        include_item_ref: true,
      })
    );
    dispatch(stopLoading());
  };

  const getUserAvatar = async (avatarId: string) => {
    if (extractFileName(avatarId)) {
      try {
        const url = await sharedFileInMinIO(avatarId);
        setAvatarUrl(url);
      } catch (error) {
        throw new Error(error as string);
      }
    }
  };

  useEffect(() => {
    if (user.user_icon_fileID) {
      getUserAvatar(user.user_icon_fileID);
    }
  }, [user.user_icon_fileID]);

  return (
    <>
      <Dropdown
        overlay={
          <StyledPullDown>
            <p className="text-main">メイン</p>
            {user.sub_positions[0].user_role_order === 1 && (
              <p className="text-position">
                {user.sub_positions[0]?.user_departments} / {user.sub_positions[0]?.user_positions}
              </p>
            )}
            <p className="text-concurrent-post">兼務</p>
            {user.sub_positions
              ?.filter((item) => item.user_role_order && item.user_role_order > 1)
              .map((subPosition, i) => (
                <p className="text-content" key={i}>
                  <span className="text-number">{i + 1}</span>
                  {subPosition.user_departments} / {subPosition.user_positions}
                </p>
              ))}
            <UpOutlined
              onClick={() => setVisibleDropDown(false)}
              className="text-icon-up-outline"
            />
          </StyledPullDown>
        }
        placement="bottom"
        trigger={['click']}
        open={visibleDropDown}
        getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
        onOpenChange={setVisibleDropDown}
      >
        <ItemDropDownStyled itemActive={visibleDropDown} style={{ opacity }}>
          {index === 0 && <div className="text-body">責任者</div>}
          <div className="wrapper" ref={drag}>
            <Avatar className="avatar" src={avatarUrl ?? adminAvatar} />
            <div className="user">
              <div className="wrap-concurrent-post">
                {(user.sub_positions[0].user_role_order! > 1 ||
                  user.sub_positions[0].affialition_id !== affiliation_id) && (
                  <span className="text-concurrent-post">兼務</span>
                )}
              </div>
              <div className="user-info">
                <p className="text-position">{user.sub_positions[0]?.user_positions}</p>
                <p className="text-name">{user.user_name}</p>
              </div>
            </div>
            {(user.sub_positions[0].user_role_order! > 1 ||
              user.sub_positions[0].affialition_id !== affiliation_id) && (
              <DeleteOutlined
                className="icon-delete"
                onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
                  e.stopPropagation();
                  handleDeleteUser();
                }}
              />
            )}
          </div>
          <div className="drop-down">{!visibleDropDown && <DownOutlined />}</div>
        </ItemDropDownStyled>
      </Dropdown>
      {/* <ConfirmDeleteModal
        visible={isShowPopupDelete}
        setVisible={setShowPopupDelete}
        onSubmit={handleDeleteUser}
        title="所属削除確認"
        subTitle="所属の削除を実行します。"
        description={
          <>
            削除を実行すると、復元できませんのでご注意ください。
            <br />
            ※メイン所属のユーザーが設定されている場合削除できません。所属移動後に再度実行してください。
          </>
        }
      /> */}
    </>
  );
};

export default ItemDropDown;
