import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import {
  CaretLeftOutlined,
  MessageOutlined,
  StarFilled,
  ExclamationOutlined,
  LikeFilled,
  CheckCircleOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import { Form, SubmitButton } from 'formik-antd';
import { useSelector } from 'react-redux';
import JoditEditor from 'jodit-pro-react';
import { Modal, Select } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy } from 'lodash';

import { createUpdateKnowledgeQASchema } from 'libs/validations/knowledgeTop';
import { startLoading, stopLoading } from 'containers/AppSettings/slice';
import { settingSelector } from 'containers/AppSettings/selectors';
import { convertBase64ToFile } from 'libs/utils/convertBase64';
import { Header, SelectField, TextArea } from 'components';
import { useAppDispatch, useUserInfoChanged } from 'hooks';
import { uploadFileToMinIO } from 'services/minioService';
import { authSelector } from 'containers/Auth/selectors';
import { knowledgeTopSelector } from '../../selectors';
import { useCheckUpload } from 'hooks/useCheckUpload';
import EditKnowledgeQAStyled from './styles';
import { routes } from 'navigations/routes';
import VideoPopup from '../UploadVideo';
import { adminAvatar } from 'assets';
import * as Types from 'types';
import {
  getSelectDepartment,
  updateKnowledgeTo,
  createFileAttach,
  updateKnowledge,
  getSelectUser,
} from '../../thunk';

const { Option } = Select;

const EditKnowledge: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);

  const state = useLocation().state as Types.KnowledgeQADetail.ResponseType;

  const { dataSelectDepartment, data_user } = useSelector(knowledgeTopSelector);

  const { userInfo } = useSelector(authSelector);
  const isUserInfoChanged = useUserInfoChanged(userInfo);
  const { headerTitle } = useSelector(settingSelector);

  const { isDisabledUpload } = useCheckUpload();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const uniqueUsers = useMemo(() => uniqBy(data_user, 'login_id'), [data_user]);

  const formik = useFormik({
    initialValues: {
      knowledge_title: state.knowledge_title,
      to_department_id: state.to_department_id || 'ALL',
      to_user_id: state.to_user_name || 'ALL',
      knowledge_text: state.knowledge_text,
    },
    enableReinitialize: true,
    validationSchema: createUpdateKnowledgeQASchema,
    validateOnBlur: false,
    onSubmit: async ({ knowledge_title, to_department_id, to_user_id, knowledge_text }) => {
      if (!userInfo || !state) return;
      dispatch(startLoading());

      const resultAction = await Promise.all([
        dispatch(
          updateKnowledge({
            id: state.i_id,
            data: {
              item: {
                knowledge_title: knowledge_title,
                knowledge_text: knowledge_text,
                updatedat: new Date(),
              },
              is_force_update: true,
            },
          })
        ),
        ...(state.item_ref?.to_department_id.i_id
          ? [
              dispatch(
                updateKnowledgeTo({
                  id: state.item_ref.to_department_id.i_id,
                  data: {
                    item: {
                      ...(to_department_id !== 'ALL'
                        ? { to_department_id: to_department_id }
                        : { to_department_id: '' }),
                      ...(to_user_id !== 'ALL' &&
                        to_department_id !== 'ALL' && { to_user_id: to_user_id }),
                    },
                    is_force_update: true,
                  },
                })
              ),
            ]
          : []),
      ]);
      if (updateKnowledge.fulfilled.match(resultAction[0])) {
        navigate(
          state.isScreen === 'favorite'
            ? routes.ExpandingFavorite.path
            : state.isScreen === 'to_login_id'
            ? routes.ExpandingToLoginId.path
            : state.isScreen === 'created_by'
            ? routes.ExpandingCreatedBy.path
            : routes.Expanding.path,
          {
            state: {
              knowledge_id: state.knowledge_id,
              isScreen: state.isScreen,
            },
          }
        );
      }
      dispatch(stopLoading());
    },
  });

  const handleExtraButtonClick = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const defaultConfig = useMemo(
    () => ({
      license: process.env.REACT_APP_JODIT_EDITOR_KEY ?? '',
      placeholder: '本文を入力...',
      height: 550,
      statusbar: false,
      allowResizeX: false,
      allowResizeY: false,
      defaultLineHeight: 1,
      disablePlugins: ['tune-block'],
      buttons,
      buttonsMD: buttons,
      buttonsSM: buttons,
      buttonsXS: buttons,
      uploader: {
        insertImageAsBase64URI: true,
        insertVideoAsBase64URI: true,
      },
      preview: true,
      // readonly: !isEdit && isViewMode,
      disableEnter: true,
      extraButtons: {
        name: 'insertVideo',
        icon: 'video',
        tooltip: 'Insert Video',
        exec: handleExtraButtonClick,
      },
    }),
    []
  );

  const base64Array: Array<string> = useMemo(() => {
    if (!formik.values.knowledge_text) return [];

    const parser = new DOMParser();
    const doc = parser.parseFromString(formik.values.knowledge_text, 'text/html');
    const imgElements = doc.querySelectorAll('img');

    return Array.from(imgElements)
      .map((img) => (img.src.startsWith('data:image') ? img.src : null))
      .filter(Boolean) as string[];
  }, [formik.values.knowledge_text]);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;
      dispatch(startLoading());
      let fileUpload: Array<string | undefined> = [];

      if (base64Array.length) {
        fileUpload = await Promise.all(
          base64Array
            .map(async (item) => {
              const imageSrc = await convertBase64ToFile(item, `file${uuidv4()}`);
              const uploadFileToMinio = await uploadFileToMinIO(imageSrc);
              if (uploadFileToMinio) {
                await dispatch(
                  createFileAttach({
                    item: {
                      fileID: uploadFileToMinio,
                      filename: `file${uuidv4()}`,
                      file_location: '5',
                      file_extension: imageSrc.type,
                      file_size: `${imageSrc.size}`,
                      company_id: userInfo.company_id,
                      createdat: new Date(),
                      createdby: userInfo.login_id,
                    },
                  })
                );

                return `https://api.hexabase.com/api/v0/applications/skillfamiliar/functions/presignedurl?param=${uploadFileToMinio}`;
              }
            })
            .filter(Boolean)
        );
      }

      const replacedHTML = formik.values.knowledge_text?.replace(
        /src="data:image\/(png|jpg|jpeg|gif);base64,([^"]+)"/g,
        (match, base64) => {
          const index = Math.floor(Math.random() * fileUpload.length);
          return `src="${fileUpload[index]}"`;
        }
      );
      formik.setFieldValue('knowledge_text', replacedHTML);
      dispatch(stopLoading());
    })();
  }, [dispatch, base64Array]);

  useEffect(() => {
    if (!userInfo || !isUserInfoChanged) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getSelectDepartment({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
              exact_match: true,
            },
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [dispatch, userInfo, isUserInfoChanged]);

  useEffect(() => {
    if (!userInfo) return;
    (async () => {
      dispatch(startLoading());
      await dispatch(
        getSelectUser({
          conditions: [
            {
              id: 'company_id',
              search_value: [userInfo.company_id],
            },
            ...(formik.values.to_department_id
              ? [
                  {
                    id: 'department_code',
                    search_value: [formik.values.to_department_id],
                    exact_match: true,
                  },
                ]
              : []),
          ],
          page: 1,
          per_page: 0,
        })
      );
      dispatch(stopLoading());
    })();
  }, [formik.values.to_department_id, dispatch, userInfo]);

  return (
    <EditKnowledgeQAStyled isCheckUpload={isDisabledUpload}>
      <Header title={headerTitle} className="header" />
      <FormikProvider value={formik}>
        <div className="btn-header">
          <div className="group-btn-left">
            {!state.qa_status ? (
              <div className="btn un-answer">
                <ExclamationOutlined className="icon" />
                未回答
              </div>
            ) : state.qa_status === 1 ? (
              <>
                <div className="btn un-answer">
                  <ExclamationOutlined className="icon" />
                  未回答
                </div>
                <div className="btn comment">
                  <MessageOutlined className="icon" />
                  追加質問あり
                </div>
              </>
            ) : state.qa_status === 2 ? (
              <>
                <div className="btn check">
                  <CheckCircleOutlined className="icon" />
                  回答済み
                </div>
                <div className="btn comment">
                  <MessageOutlined className="icon" />
                  追加質問あり
                </div>
              </>
            ) : state.qa_status === 3 ? (
              <div className="btn check">
                <CheckCircleOutlined className="icon" />
                回答済み
              </div>
            ) : null}
            <div className="btn-back" onClick={() => navigate(-1)}>
              <CaretLeftOutlined className="icon" />
              一覧に戻る
            </div>
          </div>
          <div className="group-btn-right">
            <div className="btn like">
              <LikeFilled className="icon" />

              {state.number_of_likes}
            </div>

            <div className="btn star">
              <StarFilled className={'icon-star'} />
              お気に入り
            </div>
          </div>
        </div>
        <div className="container">
          <div className="wrap-header">
            <div className="title">
              <div className="divider" />
              Q&A
            </div>
            <div className="info">
              <p className="label">投稿者：</p>
              <img
                className="avt-icon"
                src={
                  userInfo?.avatar?.originFileObj
                    ? URL.createObjectURL(new Blob([userInfo.avatar.originFileObj]))
                    : adminAvatar
                }
                alt="avt-user"
              />
              <div className="label-name">
                <p className="department">{state?.author_affiliation_id.replace(/^\d+ /, '')}</p>
                <p className="name">{userInfo?.name}</p>
              </div>
              <p className="number-like">
                <LikeOutlined className="like-icon" />
                {state?.number_of_likes}
              </p>
            </div>
          </div>

          <div className="form">
            <Form layout="vertical" className="form-setting">
              <Form.Item name="knowledge_title">
                <label className="text-label">
                  タイトル
                  <span className="require">*</span>
                </label>
                <TextArea
                  name="knowledge_title"
                  className="input-textarea-group"
                  showCount
                  maxLength={100}
                  placeholder="全角：最大100文字"
                />
              </Form.Item>
              <div className="wrap-recruitment">
                <Form.Item
                  name="to_department_id"
                  className="form-input"
                  label={
                    <span>
                      To 所属 <span className="require"> *</span>
                    </span>
                  }
                >
                  <SelectField
                    name="to_department_id"
                    className="pull_down"
                    placeholder="選択してください"
                    onChange={() => {
                      formik.setFieldValue('to_user_id', 'ALL');
                    }}
                  >
                    <Option value="ALL">ALL</Option>
                    {dataSelectDepartment?.map((item, index) => (
                      <Option key={index} value={item.affiliation_id}>
                        {item.name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
                <Form.Item name="to_user_id" className="form-input">
                  <label className="text-label">To ユーザー</label>
                  <SelectField
                    name="to_user_id"
                    className="pull_down"
                    placeholder="選択してください"
                    disabled={formik.values.to_department_id === 'ALL'}
                  >
                    <Option value="ALL">ALL</Option>
                    {uniqueUsers?.map((item, index) => (
                      <Option value={item.login_id} key={index}>
                        {item.user_name}
                      </Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </div>
              {/* <Form.Item name="content">
                <label className="text-label">
                  内容
                  <span className="require">*</span>
                </label>
                <TextArea
                  name="content"
                  className="input-textarea-group content"
                  showCount
                  maxLength={1500}
                  placeholder="全角：最大1500文字"
                />
              </Form.Item> */}
              <div className="wrap-jodit">
                <JoditEditor
                  value={formik.values.knowledge_text}
                  config={defaultConfig}
                  onBlur={(newContent) => formik.setFieldValue('knowledge_text', newContent)}
                  onChange={(newContent) => {
                    formik.setFieldValue('knowledge_text', newContent);
                  }}
                />
              </div>
              {showPopup && (
                <Modal
                  open={showPopup}
                  closable={false}
                  onOk={handlePopupClose}
                  onCancel={handlePopupClose}
                  footer={null}
                >
                  <VideoPopup formik={formik} setOpen={setShowPopup} />
                </Modal>
              )}
              <div className="group-btn">
                <SubmitButton
                  disabled={!formik.dirty && !formik.isValid}
                  className={`btn ${formik.dirty && formik.isValid ? 'btn-active' : 'btn_submit'}`}
                >
                  更新する！
                </SubmitButton>
                <button className="btn btn-default" onClick={() => navigate(-1)}>
                  キャンセル
                </button>
              </div>
            </Form>
          </div>
          <div className="text-footer">
            <p className="text">内容を修正して、投稿を更新することができます。</p>
          </div>
        </div>
      </FormikProvider>
    </EditKnowledgeQAStyled>
  );
};

const buttons = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  'ul',
  'ol',
  'fontsize',
  'paragraph',
  'brush',
  'superscript',
  'subscript',
  'link',
  'emoji',
  'image',
  'table',
  'iframeEditor',
  'indent',
  'outdent',
  'left',
  'center',
  'right',
  'justify',
  'undo',
  'redo',
  'preview',
];

export default EditKnowledge;
