import React, { FC, useContext } from 'react';

import PDFSinglePage from './PDFSinglePage';
import { PDFContext } from '../../state';

interface Props {
  pageNum?: number;
}

export const PDFAllPages: FC<Props> = () => {
  const {
    state: { numPages },
  } = useContext(PDFContext);

  const PagesArray = [];
  for (let i = 0; i < numPages; i++) {
    PagesArray.push(<PDFSinglePage index={i} key={i + 1} pageNum={i + 1} />);
  }

  return <>{PagesArray}</>;
};
