import { createAsyncThunk } from '@reduxjs/toolkit';

import { services } from 'services';
import * as Types from 'types';
import {
  USERS,
  CURRICULUMS,
  SKILL_CHECK,
  SELECT_ROLE,
  EMPLOYEE_USER,
  SELECT_POSITION,
  SELECT_DEPARTMENT,
  SELECT_SKILL_CHECK,
  EMPLOYEE_USER_DETAIL,
  EMPLOYEE_USER_EXPORT,
  USER_ASSIGN_SKILL_CHECK,
  AFFILIATION_ASSIGN_ROLE,
  SELECT_SKILL_CHECK_CREATOR,
  SELECT_REQUIRED_CURRICULUM,
  SELECT_SKILL_CHECK_CATEGORY,
  SELECT_SKILL_CHECK_USE_STATUS,
  SELECT_SKILL_CHECK_START_PERIOD,
  USER_ASSIGN_REQUIRED_CURRICULUM,
  SELECT_SKILL_CHECK_GROUPING_CODE,
  SELECT_REQUIRED_CURRICULUM_CREATOR,
  AFFILIATION_ASSIGN_LEVEL,
  SELECT_EMPLOYEE_USER,
  REQUIRED_CURRICULUM_SETTINGS,
  REQUIRED_SKILLCHECK_SETTINGS,
} from 'configs';

export const getDataEmployee = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUser.ResponseType>(EMPLOYEE_USER.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectEmployee = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectEmployeeUser.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectEmployeeUser.ResponseType>(
      SELECT_EMPLOYEE_USER.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectPosition = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectPosition.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectPosition.ResponseType>(
      SELECT_POSITION.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectDepartment = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectDepartment.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectDepartment', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectDepartment.ResponseType>(
      SELECT_DEPARTMENT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSelectRole = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRole.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSelectRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRole.ResponseType>(SELECT_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteEmployee = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/thunk/deleteEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USERS.id, req);

    return { ...data, item: { i_id: req.id } };
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemByConditionsAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(AFFILIATION_ASSIGN_ROLE.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemByConditionsAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(AFFILIATION_ASSIGN_LEVEL.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemByConditionsCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(
      USER_ASSIGN_REQUIRED_CURRICULUM.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteItemByConditionsSkillCheck = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemByConditionsRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteItemByConditionsSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.deleteItemByConditions(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataEmployeeExport = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUserExport.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataEmployeeExport', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUserExport.ResponseType>(
      EMPLOYEE_USER_EXPORT.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createEmployee = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationRole = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_ROLE.id, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addUser = createAsyncThunk<
  Types.AddUserResponse,
  Types.AddUserRequest,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/addUser', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addUser(req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getSubPosition = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignRole.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getSubPosition', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignRole.ResponseType>(
      AFFILIATION_ASSIGN_ROLE.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getAffiliationAssignLevel = createAsyncThunk<
  Types.GetItemResponseType<Types.AffiliationAssignLevel.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getAffiliationAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.AffiliationAssignLevel.ResponseType>(
      AFFILIATION_ASSIGN_LEVEL.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateEmployee = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.Users.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateEmployee', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationRole = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignRole.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAffiliationRole = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteAffiliationRole', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_ROLE.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteAffiliationLevel = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSkillCheck = createAsyncThunk<
  Types.GetItemResponseType<Types.SkillCheck.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.SkillCheck.ResponseType>(SKILL_CHECK.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataCurriculumBatch = createAsyncThunk<
  Types.GetItemResponseType<Types.Curriculums.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataCurriculumBatch', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Curriculums.ResponseType>(CURRICULUMS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculumCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCurriculumCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculum.ResponseType>(
      SELECT_REQUIRED_CURRICULUM.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCurriculum = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculum.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculum.ResponseType>(
      SELECT_REQUIRED_CURRICULUM.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectRequiredCurriculumCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectRequiredCurriculumCreator.ResponseType>(
      SELECT_REQUIRED_CURRICULUM_CREATOR.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheck = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheck.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheck.ResponseType>(
      SELECT_SKILL_CHECK.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStartPeriod = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckStartPeriod.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckStartPeriod', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckStartPeriod.ResponseType>(
      SELECT_SKILL_CHECK_START_PERIOD.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCategory = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCategory.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckCategory', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCategory.ResponseType>(
      SELECT_SKILL_CHECK_CATEGORY.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckGroupCode = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckGroupingCode.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckGroupCode', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckGroupingCode.ResponseType>(
      SELECT_SKILL_CHECK_GROUPING_CODE.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckStatus = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckUseStatus.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckStatus', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckUseStatus.ResponseType>(
      SELECT_SKILL_CHECK_USE_STATUS.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataSelectSkillCheckCreator = createAsyncThunk<
  Types.ReportsItemResponseType<Types.SelectSkillCheckCreator.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataSelectSkillCheckCreator', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.SelectSkillCheckCreator.ResponseType>(
      SELECT_SKILL_CHECK_CREATOR.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignCurriculum = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignCurriculum = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteLinkUserAssignCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateCurriculum = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignRequiredCurriculum.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateCurriculum', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_REQUIRED_CURRICULUM.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createUserAssignSkillCheck = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const deleteLinkUserAssignSkillCheck = createAsyncThunk<
  Types.DeleteItemResponseType,
  Types.DeleteItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/deleteLinkUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.delete(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateUserAssignSkillCheck = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.UserAssignSkillCheck.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateUserAssignSkillCheck', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(USER_ASSIGN_SKILL_CHECK.name, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataEmployeeDetail = createAsyncThunk<
  Types.ReportsItemResponseType<Types.EmployeeUserDetail.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataEmployeeDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.EmployeeUserDetail.ResponseType>(
      EMPLOYEE_USER_DETAIL.name,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const createAffiliationAssignLevel = createAsyncThunk<
  Types.CreateItemResponseType,
  Types.CreateItemRequestType<Types.AffiliationAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/createAffiliationAssignLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.create(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateAffiliationLevel = createAsyncThunk<
  Types.UpdateItemResponseType,
  Types.UpdateItemRequestType<Types.AffiliationAssignLevel.ResponseType>,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/updateAffiliationLevel', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.update(AFFILIATION_ASSIGN_LEVEL.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getUserInfoDetail = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('auth/thunk/getUserInfoDetail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRequiredCurriculumSettings = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RequiredCurriculumSettings.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataRequiredCurriculumSettings', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.RequiredCurriculumSettings.ResponseType>(
      REQUIRED_CURRICULUM_SETTINGS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataRequiredSkillcheckSettings = createAsyncThunk<
  Types.ReportsItemResponseType<Types.RequiredSkillcheckSettings.ResponseType>,
  Types.ReportsItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataRequiredSkillcheckSettings', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.filter<Types.RequiredSkillcheckSettings.ResponseType>(
      REQUIRED_SKILLCHECK_SETTINGS.id,
      req
    );

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const addLinkAffiliation = createAsyncThunk<
  Types.AddLinkItemResponseType,
  any,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/addLinkAffiliation', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.addLink(AFFILIATION_ASSIGN_LEVEL.name, req);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const getDataAllGmail = createAsyncThunk<
  Types.GetItemResponseType<Types.Users.ResponseType>,
  Types.GetItemRequestType,
  Types.ThunkAPI<Types.requestError>
>('Employee/thunk/getDataAllGmail', async (req, { rejectWithValue }) => {
  try {
    const { data } = await services.search<Types.Users.ResponseType>(USERS.id, req);

    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
