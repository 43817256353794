import React, { useContext } from 'react';
import styled from 'styled-components';

import { IStyledProps } from '../../../models';
import { PDFContext } from '../state';

const PDFPagination: React.FC = () => {
  const {
    state: { currentPage, numPages, zoomLevel },
  } = useContext(PDFContext);

  return (
    <Container id="pdf-pagination">
      <PageTag id="pdf-pagination-info">
        {currentPage}/{numPages} {''} ページ
        <span className="zoomLevel">{Number(((100 * zoomLevel) / 0.6).toFixed(0))}%</span>
      </PageTag>
    </Container>
  );
};

export default PDFPagination;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const PageTag = styled.div`
  color: ${(props: IStyledProps) => props.theme.textPrimary};
  font-size: 14px;
  text-align: left;

  .zoomLevel {
    margin: 0 20px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
