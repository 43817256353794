import React, { useState } from 'react';
import { CheckCircleOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { useIntl } from 'react-intl';

import { setNewPassword } from 'containers/Auth/thunk';
import { registerFormSchema } from 'libs/validations';
import AuthLayout from 'containers/Auth/Layout';
import { routes } from 'navigations/routes';
import { InputPassword } from 'components';
import { RegisterFormik } from 'types';
import { useAppDispatch } from 'hooks';
import SectionStyled from './styles';
import { Centering } from 'assets';
import { config } from 'configs';

const ResetPasswordForm: React.FC = () => {
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);

  const { confirmation_id } = useParams<{ confirmation_id: string }>();

  const navigate = useNavigate();

  const { messages } = useIntl();

  const dispatch = useAppDispatch();

  const formik = useFormik<RegisterFormik>({
    initialValues: {
      password: '',
      rePassword: '',
    },
    validationSchema: registerFormSchema,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!confirmation_id) return;
      const resultAction = await dispatch(
        setNewPassword({
          new_password: values.rePassword,
          confirm_password: values.rePassword,
          id: confirmation_id,
          email_templates_id: config.CONFIRM_TEMPLATES_ID,
          send_password_to_email: true,
        })
      );
      if (setNewPassword.fulfilled.match(resultAction)) {
        setIsSuccessful(true);
      }
    },
  });

  return (
    <AuthLayout>
      <SectionStyled>
        <img src={Centering} alt="logo" className="logo" />
        <p className="title">{messages['P-03-1']}</p>
        {isSuccessful ? (
          <>
            <CheckCircleOutlined className="check-circle-icon" size={66} />
            <p className="text-content">
              {messages['P-03-6.1']}
              <br />
              {messages['P-03-6.2']}
              <br />
              {messages['P-03-6.3']}
            </p>
            <button className="btn-submit" onClick={() => navigate(routes.Dashboard.path)}>
              OK
            </button>
          </>
        ) : (
          <>
            <p className="text-content">{messages['P-03-2']}</p>
            <FormikProvider value={formik}>
              <Form className="form">
                <Form.Item name="password">
                  <InputPassword
                    className="input"
                    name="password"
                    placeholder={messages['P-03-3'] as string}
                    prefix={<LockOutlined className="icon" />}
                  />
                </Form.Item>
                <Form.Item name="rePassword">
                  <InputPassword
                    className="input"
                    name="rePassword"
                    placeholder={messages['P-03-4'] as string}
                    prefix={<LockOutlined className="icon" />}
                  />
                </Form.Item>
                <SubmitButton className="btn-submit">{messages['P-03-5']}</SubmitButton>
              </Form>
            </FormikProvider>
          </>
        )}
      </SectionStyled>
    </AuthLayout>
  );
};

export default ResetPasswordForm;
