import styled from 'styled-components';

import { BackgroundGray } from 'assets';

export const SectionStyled = styled.div`
  .header {
    height: 55px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 4px 4px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 18px;
      color: #2a2a2a;
      .small-text {
        color: #aaaaaa;
        font-size: 70%;
      }
    }
  }
  .section {
    background-color: #888888;
    height: 52px;
    display: flex;
    .section-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-left {
        width: 52.3%;
        background-image: url(${BackgroundGray});
      }
      &-right {
        width: 47.7%;
      }
      .label-number {
        margin-left: 20px;
        font-family: Lato;
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.3);
      }
      .label-text {
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
  .body {
    display: flex;
    height: calc(100% - 107px);
    .user-search {
      width: 50%;
      padding: 25px 20px;
      .input-select {
        width: 100%;
      }
      .text-note {
        font-size: 11px;
        color: #999999;
        text-align: center;
        margin-top: 13px;
      }
      .wrap-list-user {
        background-color: #ffffff;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        height: 458px;
        overflow-y: auto;
        padding: 12px;
        .item-user {
          height: 56px;
          background-color: #ffffff;
          border: 1px solid #e1e1e1;
          box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .avatar {
            margin-left: 10px;
          }
          .name {
            font-size: 14px;
            color: #424242;
            margin-bottom: 0;
            max-width: 185px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .department_name {
            font-size: 11px;
            color: #999999;
            width: 100px;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .active {
          border: 3px solid #08a3a5;
        }
      }
    }
    .selected-user {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50%;
      background-color: #ffffff;
      border-left: 1px solid #cccccc;
      padding: 25px 20px;
      .wrap-info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: #ffffff;
        border: 2px solid #eeeeee;
        border-radius: 5px;
        width: 310px;
        height: 450px;
        padding: 25px;
        .text-not-selected {
          font-size: 13px;
          color: #bbbbbb;
        }
        .item-select {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .avatar {
          height: 140px;
          width: 140px;
        }
        .name {
          margin-top: 20px;
          font-size: 16px;
          color: #424242;
        }
        .main {
          width: 100%;
          background-color: #fcf4e2;
          padding: 0 8px 8px 8px;
          margin-bottom: 8px;
          .label {
            font-weight: 700;
            font-size: 11px;
            color: #e7a100;
          }
          .wrap-item {
            background-color: #ffffff;
            padding: 12px;
            .text-item {
              font-size: 13px;
              color: #424242;
              display: block;
              .text-number {
                font-size: 13px;
                color: #cccccc;
                margin-right: 10px;
              }
            }
          }
        }
        .concurrent-post {
          width: 100%;
          background-color: #e6f6f6;
          padding: 0 8px 8px 8px;
          .label {
            font-weight: 700;
            font-size: 11px;
            color: #159193;
          }
          .wrap-item {
            background-color: #ffffff;
            padding: 0 10px;
            max-height: 95px;
            overflow-y: auto;
            .text-item {
              font-size: 13px;
              color: #424242;
              margin: 5px 0;
              .text-number {
                font-size: 13px;
                color: #cccccc;
                margin-right: 10px;
              }
            }
          }
        }
      }
      .j-start {
        overflow-y: auto;
        justify-content: flex-start;
      }
      .wrap-select-sub-position {
        width: 308px;
        margin-top: 30px;
        .label-form {
          font-family: Lato;
          font-size: 12px;
          color: #424242;
        }
        .required {
          color: #f0330a;
          font-size: 13px;
        }
        .input-select {
          width: 100%;
        }
      }
    }
    .text-content {
      font-size: 13px;
      color: #424242;
      text-align: center;
    }
  }
  .btn {
    padding: 8px 32px;
    font-size: 13px;
    border-radius: 5px;
    font-weight: bold;
    display: block;
    margin: 40px auto;
    height: 40px;
    cursor: pointer;
  }
  .btn-active {
    background-color: #f6ac00;
    border: 1px solid #f6ac00;
    color: #ffffff;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
  }
  .btn-cancel {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 1px 1px 4px rgba(68, 68, 68, 0.2);
    color: #777777;
  }
  .btn-disabled {
    cursor: not-allowed;
    background-color: #eeeeee;
    color: #bbbbbb;
    border: none;
  }
`;
